import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name

export const RDC = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('RAC');

  const researchAdvisoryCommittee = [



    {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. V. T. Somasekhar",
        // department: "-",
        person1: "Dean (Research & Development)",
        image: "https://nitw.ac.in/api/static/files/Prof._Somasekhar_V._T._2024-3-7-17-37-11.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ee-sekhar",
      },
      

    {
      // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
      Name: "Prof. A. Venu Gopal",
      // department: "-",
      person1: "Professor (HAG), Department of Mechanical Engineering",
      image: "https://nitw.ac.in/api/static/files/Prof._A._Venu_Gopal_2024-5-21-18-10-36.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/me-venu",
    },
    

    {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. P. Ratish Kumar",
        // department: "-",
        person1: "Professor, Department of Civil Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._P_Ratish_Kumar_2024-5-21-18-11-40.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ce-rateeshp",
      },
      


      {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. N. Narasaiah",
        // department: "-",
        person1: "Professor, Department of Metallurgical and Materials Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._N_Narasaiah_2024-5-21-18-12-35.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/mm-nn",
      },
      


      {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. P. Sreehari Rao",
        // department: "-",
        person1: "Professor, Department of Electronics and Communication Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._P_Sreehari_Rao_2024-5-21-18-14-13.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ec-patri",
      },
      

      {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. Rashmi Ranjan Rout",
        // department: "-",
        person1: "Professor, Department of Computer Science and Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._Rashmi_Ranjan_Rout_2024-5-21-18-15-21.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/cs-rashrr",
      },


      {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. Sonawane Shirish Hari",
        // department: "-",
        person1: "Professor, Department of Chemical Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._Sonawane_Shirish_Hari_2024-5-21-18-16-23.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ch-shirish",
      },

      {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. V. Vasu",
        // department: "-",
        person1: "Professor, Department of Mechanical Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._V._Vasu_2024-5-21-18-17-56.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/me-vasu",
      },


      {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. T. Kishore Kumar",
        // department: "-",
        person1: "Professor, Department of Electronics and Communication Engineering",
        image: "https://wsdc.nitw.ac.in/facultynew/media/16291/16291.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ec-kishoret",
      },


      {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. P. Venkata Sri Laxmi",
        // department: "-",
        person1: "Professor, Department of Chemistry",
        image: "https://nitw.ac.in/api/static/files/Prof._P_Venkata_Sri_Laxmi_2024-5-21-18-19-28.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi",
      },



    // ... other RAC members
  ];

  const racStaff = [
    // {
    //   Name: "Dr. Ramanuj Narayan",
    //   person1: "Director, CSIR-IMMT, Bhubaneshwar",
    //   image: "https://nitw.ac.in/api/static/files/Dr._Ramanuj_Narayan_2023-7-17-11-50-26.jpg",
    //   link: "https://example.com/profile/john-doe",
    // },
    // {
    //   Name: "Dr. Tata Narasinga Rao",
    //   person1: "Director, ARCI, Hyderabad",
    //   image: "https://nitw.ac.in/api/static/files/Dr._Tata_Narasinga_Rao_2023-7-17-11-51-1.jpg",
    //   link: "https://example.com/profile/john-doe",
    // },
    // ... other RAC Staff members
  ];

  const staff = [
    // {
    //   // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
    //   Name: "Dr. Ramanuj Narayan",
    //   // department: "-",
    //   person1: "Director, CSIR-IMMT, Bhubaneshwar",
    //   image: "https://nitw.ac.in/api/static/files/Dr._Ramanuj_Narayan_2023-7-17-11-50-26.jpg",
    //   link: "https://example.com/profile/john-doe",
    // },
    // {
    //   // Designation: "Director, ARCI, Hyderabad",
    //   Name: "Dr. Tata Narasinga Rao",
    //   // department: "-",
    //   person1: "Director, ARCI, Hyderabad",
    //   image: "https://nitw.ac.in/api/static/files/Dr._Tata_Narasinga_Rao_2023-7-17-11-51-1.jpg",
    //   link: "https://example.com/profile/john-doe",
    // },
    // ... other staff members
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setHoveredIndex(null);
  };

  const handleHover = (index) => {
    // setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    // setHoveredIndex(null);
  };

  let activeData = researchAdvisoryCommittee;

  if (activeTab === 'RAC Staff') {
    activeData = racStaff;
  } else if (activeTab === 'Staff') {
    activeData = staff;
  }

  return (
    <div className="page text-center font-change-applicable static-facilities">
      <PageHeader dept="/RDC" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'RAC' ? 'active' : ''}`}
            onClick={() => handleTabClick('RAC')}
          >
           Research Development Committee (RDC)
          </button>
          {/* <button
            className={`tab ${activeTab === 'RAC Staff' ? 'active' : ''}`}
            onClick={() => handleTabClick('RAC Staff')}
          >
            RAC Minutes
          </button> */}
          {/* <button
            className={`tab ${activeTab === 'Staff' ? 'active' : ''}`}
            onClick={() => handleTabClick('Staff')}
          >
            Staff
          </button> */}
        </div>
        <div className="columns-wrapper row">
          {activeData.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img src={person.image} alt={person.Name} />
                </a>
              </div>
              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
              <h2>{person.Name}</h2>
                <div className="details-wrapper">
                  {person.Designation && (
                    <div className="details-row">
                      <span className="details-label">Name:</span>
                      <span className="details-value">{person.Designation}</span>
                    </div>
                  )}
                  {person.phoneNumber && (
                    <div className="details-row">
                      <span className="details-label">Phone:</span>
                      <span className="details-value">{person.phoneNumber}</span>
                    </div>
                  )}
                  {person.email && (
                    <div className="details-row">
                      <span className="details-label">Email:</span>
                      <span className="details-value">{person.email}</span>
                    </div>
                  )}
                  {person.department && (
                    <div className="details-row">
                      <span className="details-label">Department:</span>
                      <span className="details-value">{person.department}</span>
                    </div>
                  )}
                  {person.institute && (
                    <div className="details-row">
                      <span className="details-label">Institute:</span>
                      <span className="details-value">{person.institute}</span>
                    </div>
                  )}
                     {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person2}</span>
                    </div>
                  )}
                   {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person1}</span>
                    </div>
                  )}
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RDC;