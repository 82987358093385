import { MainServerUrl, OldServerUrl } from "../BasePath";

//for oldweb.nitw.ac.in to nitw.ac.in
export const changeBaseUrl = (url) => {
    if(url.search("/api") === -1){
        return url;
    }
    var array = url.split("/api");
    url = MainServerUrl + "/api" + array[1];
    // console.log(url);
    return url;
  };

//for nitw.ac.in to oldweb.nitw.ac.in
  export const changeBaseUrl1 = (url) => {
    if(url.search("/media") === -1){
        return url;
    }
    var array = url.split("/media");
    url = OldServerUrl + "/media" + array[1];
    // console.log(url);
    return url;
  };

  export const changeBaseUrlOfData = (data) => {
    
    var text = data;
    // console.log(text);
    
    if(text.search("/media") === -1){
        return text;
    }
    var array = text.split("/media");
    var base = text.split("https");
    var add = OldServerUrl.substring(5);
    var result = base[0] + "https" + add + "/media" + array[1]; 
    // console.log(result);
    
    return result;
  }