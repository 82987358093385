import React from "react";

const Avatar = (props) => {
  return (
    <div
      style={{
        backgroundImage: props.image,
        display: "block",
        width: "8rem",
        height: "8rem",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        borderRadius: "50%",
        margin: "auto",
        marginTop: 0,
        marginBottom: 0,
      }}
    ></div>
  );
};

export default Avatar;