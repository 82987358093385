import React, { useState } from 'react';
import '../css/FacultyPositions.css'; // Import the new CSS file

export const NonTeachingPositions = () => {
  const [activeTab, setActiveTab] = useState('announcements'); // Set default tab

  const announcements = [
    // {
    //   Title: "24 Jan 2024: Staff Recruitment Form - 2024",
    //   Content: "-",
    //   Date: "-",
    //   Link: "https://careers.nitw.ac.in/register/?next=/",
    // },

   
    {
      Title: "Previous Recruitment Details",
      Content: "-",
      Date: "-",
      Link: "https://nitw.ac.in/page/?url=/jobsrecruitment/Nonteaching",
    },
    // Add more announcements
    

  ];

  const notices = [
    // Add more notices
    {
        Title: "-",
        Content: "-",
        Date: "-",
        Link: "-",
      },
  ];

  const Applyonlineportal = [
    {
      Title: "Apply online portal",
      Content: "-",
      Date: "-",
      Link: "https://recruit.nitw.ac.in/register?next=/",
    },
    // Add more Apply online portal items
  ];

 // ... rest of the code

const renderTabContent = () => {
  let tabContent = null;

  if (activeTab === 'announcements') {
    tabContent = announcements.map((announcement, index) => (
      <li key={index} >
      {/* <li key={index}  style={{ marginBottom: '50px' }}> */}
        <h4 style={{ fontSize: '20px', marginBottom: '80px' }}>
          <a href={announcement.Link} target="_blank" rel="noopener noreferrer">
            {announcement.Title}
          </a>
        </h4>
        <hr />
        
      </li>
      
    ));
  } else if (activeTab === 'notices') {
    tabContent = notices.map((notice, index) => (
      <li key={index}>
       <h4 style={{ fontSize: '20px' }}>
          <a href={notice.Link} target="_blank" rel="noopener noreferrer">
            {notice.Title}
          </a>
        </h4>
        <hr />
      </li>
    ));
  } else if (activeTab === 'applyOnline') {
    tabContent = Applyonlineportal.map((item, index) => (
      <li key={index}>
       <h4 style={{ fontSize: '20px' }}>
          <a href={item.Link} target="_blank" rel="noopener noreferrer">
            {item.Title}
          </a>
        </h4>
        <hr />
      </li>
    ));
  }

  return <ul>{tabContent}</ul>;
};

// ... rest of the code


  const handleTabClick = (tab) => {
    if (tab === 'applyOnline') {
      window.open("https://recruit.nitw.ac.in/", '_blank'); // Open the online portal link in a new tab
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className="page text-left font-change-applicable static-facilities">
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div>
          <h3><b>Non-Teaching Positions</b></h3>
          <p style={{ textAlign: 'justify' }}>
          NITW, invites application from Indian nationals, possessing excellent academic background along with commitment to work dynamically and efficiently for the continuous development of the Institute.          </p>
        </div>
        <br />
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'announcements' ? 'active' : ''}`}
            onClick={() => handleTabClick('announcements')}
          >
           Latest Announcements
          </button>
          {/* <button
            className={`tab ${activeTab === 'notices' ? 'active' : ''}`}
            onClick={() => handleTabClick('notices')}
          >
            Notices
          </button>
          <button
            className={`tab ${activeTab === 'applyOnline' ? 'active' : ''}`}
            onClick={() => handleTabClick('applyOnline')}
          >
            Apply Online Portal
          </button> */}
        </div>
        <br />
        {/* <br /> */}
        <div className="tab-content">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default NonTeachingPositions;