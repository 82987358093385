import { Typography } from "@mui/material";
import React from "react";
import "../index.css";
import "../css/removeScroll.css";
import Blink from "react-blink-text";
import { Link } from "react-router-dom";
import "../components/rederers/css/fixes.css";
import { BaseUrl } from "../BasePath";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NewsScrollCard from './NewsScrollCard';
import '../css/NewsScroll.css'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

var letters = [
  {
    img: "https://nitw.ac.in/media/files/img1.jpg",
    // name: "Newsletter (The BEACON RAY, Volume 4, Issue-1) from Jan - March 2023",
    title: "The BEACON RAY",
    vol: "Volume 4, Issue-1",
    time: "Jan - March 2023",
    link: "https://nitw.ac.in/api/static/files/BEACONRAYVolume4Issue1JanMarch2023_2023-4-5-20-47-25.pdf",
  },

  {
    img: "https://nitw.ac.in/media/files/img2.jpg",
    name: "Newsletter (The BEACON RAY, Volume 3, Issue-4) from Oct - Dec 2022",
    title: "The BEACON RAY",
    vol: "Volume 3, Issue-4",
    time: "Oct - Dec 2022",
    link: "https://nitw.ac.in/api/static/files/BEACON_RAY_Volume_3_Issue_4_v1_2023-1-11-17-8-52.pdf",
  },

  {
    img: "https://nitw.ac.in/media/files/img3.jpg",
    name: "Newsletter (The BEACON RAY, Volume 3, Issue-3) from July - Sept 2023",
    title: "The BEACON RAY",
    vol: "Volume 3, Issue-3",
    time: "July - Sept 2022",
    link: "https://nitw.ac.in/api/static/files/Newsletter_Volume3-Issue_3_2022-10-7-11-18-17.pdf",
  },

  {
    img: "https://nitw.ac.in/media/files/img4.jpg",
    name: "Newsletter (The BEACON RAY, Special Issue) September 2022",
    title: "The BEACON RAY",
    vol: "Special Issue",
    time: "September 2022",
    link: "https://nitw.ac.in/api/static/files/Newsletter_Special_Issue_Septermber_2022_2022-9-26-14-48-11.pdf",
  },
];

const Newsletter = () => {
  const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    }
};
  return (
    <React.Fragment>
    <Typography
            variant="h3"
            fontWeight="bold"
            margin="auto"
            textAlign="center"
            color="#b80924"
            fontFamily="Merriweather"
            paddingTop="4vh"
            paddingBottom="5vh"
          >
            Newsletters
          </Typography>
    {/* <Carousel
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                keyBoardControl={true}
                containerclassName="carousel-container"
                removeArrowOnDeviceType={[]}
                deviceType={[]}
                dotListclassName="custom-dot-list-style"
                itemclassName="carousel-item-padding-0-px"
                customTransition={'transform 500ms ease 0s'}
                centerMode={false}
                focusOnSelect={true}
            > */}
                {letters.map((item) => {
                    return (<NewsScrollCard title={item.title} content={item.name} url={item.link} image={item.img ? item.img : ""} />)
                })}
            {/* </Carousel> */}
      {/* <ThemeProvider theme={theme}>
        <div style={{ marginTop: "5vh", backgroundColor: "#FFFFFF" }}>
          <Typography
            variant="h3"
            fontWeight="bold"
            margin="auto"
            textAlign="center"
            color="#b80924"
            paddingTop="3vh"
          >
            Newsletters
          </Typography>
          <hr className="divider bg-madison" />
          <div className="offset-top-10">
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                textAlign: "right",
                paddingRight: "6vh",
              }}
            >
              <b>
                <Link
                  to={{
                    pathname: `/page/?url=/newsletter`,
                  }}
                  onClick={() =>
                    (window.location.href = `${BaseUrl}/page/?url=/newsletter`)
                  }
                >
                  <Blink color="#060526" text="Read More >>" fontSize="20" />
                </Link>
              </b>
            </div>
            <div className="text-start justify-text-class">
              <div
                className="flex items-center justify-center"
                style={{ padding: "2vh 6vh 2vh 2vh" }}
              >
                <div className="container">
                  <div className="row">
                    {letters.map((letter) => {
                      return (
                        <div className="col-lg-3 col-md-6 col-sm-12 items-center">
                          <div
                            className="group h-96 w-auto"
                            style={{ margin: "3vh auto" }}
                          >
                            <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                              <div className="absolute inset-0">
                                <img
                                  className="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40"
                                  src={letter.img}
                                  alt=""
                                  style={{ position: "absolute" }}
                                />
                                <p
                                  style={{
                                    position: "relative",
                                    padding: "2vh",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {letter.name}
                                </p>
                              </div>
                              <div className="absolute inset-0 h-full w-full rounded-xl bg-black px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                                <div className="flex min-h-full flex-col items-center justify-center">
                                  <h1
                                    className="text-3xl"
                                    style={{ color: "white" }}
                                  >
                                    {letter.title}
                                  </h1>
                                  <p className="text-lg">{letter.vol}</p>
                                  <p className="text-base">{letter.time}</p>
                                  <a href={letter.link}>
                                    <button className="mt-2 rounded-md bg-neutral-800 py-1 px-2 text-sm hover:bg-neutral-900">
                                      Read More
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    ;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider> */}
    </React.Fragment>
  );
};

export default Newsletter;
