import React from 'react';
import { TEAM_MEMBERS, BACKGROUND_IMAGE } from '../constants/TeamMembers';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
import { GitHub, LinkedIn } from '@mui/icons-material';

export const TeamPage = () => {
    return (
        <>
        <PageHeader dept={'Student Developers Team'} />
        <div className="p-4 container">
            <center>
                <h3 style={{fontWeight: 'bold'}}>Student Developers Team</h3>
                <p>Students Contributed for the website development (2023)</p>
            </center>
            <div className="row">
                {_.map(TEAM_MEMBERS, (teamMember,index) =>
                    <div className="col-sm-6 col-md-4 col-lg-3 member">
                        <div className="card profile-card-3">
                            <div className="background-block">
                                <img src={BACKGROUND_IMAGE[index%BACKGROUND_IMAGE.length]} alt="profile-sample1" className="background"/>
                            </div>
                            <div className="profile-thumb-block">
                                <img src={teamMember.image} alt={teamMember.name} className="profile"/>
                            </div>
                            <div className="card-content">
                            <h2 >{teamMember.name}</h2>
                            <span>Reg. No: {teamMember.regNo}</span><br/>
                            <big>{teamMember.role}</big>
                            <div className="icon-block">
                                <a href={teamMember.github} target="_blank" rel="noreferrer"><GitHub /></a>
                                <a href={teamMember.linkedin} target="_blank" rel="noreferrer"><LinkedIn /></a>
                            </div>
                            </div>
                        </div>
                    </div>
            )}
            </div>
        </div>
        </>
    )
}