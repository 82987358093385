// import React, { useState } from 'react';
// import '../css/FacultyPositions.css'; // Import the new CSS file

// export const FacultyPositions = () => {
//   const [activeTab, setActiveTab] = useState('announcements'); // Set default tab

//   const announcements = [
//     // {
//     //   Title: "10 Jan 2024: Rolling Advertisement for Faculty Recruitment",
//     //   Content: "-",
//     //   Date: "-",
//     //   Link: "https://recruit.nitw.ac.in/",
//     // },
//     {
//       Title: "Previous Recruitment Details",
//       Content: "-",
//       Date: "-",
//       Link: "https://nitw.ac.in/page/?url=/jobrecruitment/FacultyRecruitments",
//     },
//     // Add more announcements
//   ];

//   const notices = [
//     // Add more notices
//     {
//         Title: "-",
//         Content: "-",
//         Date: "-",
//         Link: "-",
//       },
//   ];

//   const Applyonlineportal = [
//     {
//       Title: "Apply online portal",
//       Content: "-",
//       Date: "-",
//       Link: "https://recruit.nitw.ac.in/register?next=/",
//     },
//     // Add more Apply online portal items
//   ];

//   const renderTabContent = () => {
//     let tabContent = null;

//     if (activeTab === 'announcements') {
//       tabContent = announcements.map((announcement, index) => (
//         <li key={index}>
//           <h4>
//             <a href={announcement.Link} target="_blank" rel="noopener noreferrer">
//               {announcement.Title}
//             </a>
//           </h4>
//           <hr />
//         </li>
//       ));
//     } else if (activeTab === 'notices') {
//       tabContent = notices.map((notice, index) => (
//         <li key={index}>
//           <h4>
//             <a href={notice.Link} target="_blank" rel="noopener noreferrer">
//               {notice.Title}
//             </a>
//           </h4>
//           <hr />
//         </li>
//       ));
//     } else if (activeTab === 'applyOnline') {
//       tabContent = Applyonlineportal.map((item, index) => (
//         <li key={index}>
//           <h4>
//             <a href={item.Link} target="_blank" rel="noopener noreferrer">
//               {item.Title}
//             </a>
//           </h4>
//           <hr />
//         </li>
//       ));
//     }

//     return <ul>{tabContent}</ul>;
//   };

//   const handleTabClick = (tab) => {
//     if (tab === 'applyOnline') {
//       window.open("https://recruit.nitw.ac.in/", '_blank'); // Open the online portal link in a new tab
//     } else {
//       setActiveTab(tab);
//     }
//   };

//   return (
//     <div className="page text-left font-change-applicable static-facilities">
//       <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
//         <div>
//           <h3><b>Faculty Positions</b></h3>
//           <p style={{ textAlign: 'justify' }}>
//             NITW, an Institute of national importance in
//             teaching, research and development, invites application from Indian nationals,
//             possessing excellent academic background along with commitment to quality teaching and
//             potential for carrying out outstanding research for faculty position at the level of
//             Professor, Associate Professor, and Assistant Professor. NIT Warangal has been
//             instrumental for providing opportunity to every faculty in achieving academic excellence,
//             developing all-round personality and realizing his or her full potential.
//           </p>
//         </div>
//         <br />
//         <div className="tabs">
//           <button
//             className={`tab ${activeTab === 'announcements' ? 'active' : ''}`}
//             onClick={() => handleTabClick('announcements')}
//           >
//             Announcements
//           </button>
//           {/* <button
//             className={`tab ${activeTab === 'notices' ? 'active' : ''}`}
//             onClick={() => handleTabClick('notices')}
//           >
//             Notices
//           </button> */}
//           <button
//             className={`tab ${activeTab === 'applyOnline' ? 'active' : ''}`}
//             onClick={() => handleTabClick('applyOnline')}
//           >
//             Apply Online Portal
//           </button>
//         </div>
//         <br />
//         <div className="tab-content">
//           {renderTabContent()}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FacultyPositions;


import React, { useState } from 'react';
import '../css/FacultyPositions.css'; // Import the new CSS file

export const FacultyPositions = () => {
  const [activeTab, setActiveTab] = useState('notices'); // Set default tab

  const announcements = [
    {
      Title: "Previous Recruitment Details",
      Content: "-",
      Date: "-",
      Link: "https://nitw.ac.in/page/?url=/jobrecruitment/FacultyRecruitments",
    },
    // Add more announcements
  ];

  const notices = [
    {
      Title: "- Rolling Faculty Recruitment -Notification",
      Content: "-.",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Rolling_Faculty_Recruitment_-Notification_2024-6-7-10-12-41.pdf",
    },
    {
      Title: "- Instructions for Filling Online Application Form",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Instructions_for_Filling_OAF_2024-6-7-10-15-19.pdf",
    },
    {
      Title: "- Implementation of Recruitment Rules for Faculty of NITS and IIEST- issue of clarifications as per recommendations of the Oversight Committe",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/R2024_clarification_2024-6-7-10-17-57.pdf", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "- Department-wise vacancies",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Department-wise_vacancies_2024-6-7-10-18-54.pdf", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "1. To candidates who have submitted applications: Please keep visiting the portal for further updates. However, no queries can be entertained regarding the further course of recruitment.",
      Content: "-",
      Date: "June 7, 2024",
      Link: "#", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "2. Inviting applications for Faculty positions will be on hold until further notice.",
      Content: "-",
      Date: "June 7, 2024",
      Link: "#", // You can provide a link if needed or use "#" for no link
    },
    // Add more notices if needed
  ];


  const Applyonlineportal = [
    {
      Title: "Apply online portal",
      Content: "-",
      Date: "-",
      Link: "https://recruit.nitw.ac.in/register?next=/",
    },
    // Add more Apply online portal items
  ];

  const renderTabContent = () => {
    let tabContent = null;

    if (activeTab === 'announcements') {
      tabContent = announcements.map((announcement, index) => (
        <li key={index}>
          <h4>
            <a href={announcement.Link} target="_blank" rel="noopener noreferrer">
              {announcement.Title}
            </a>
          </h4>
          <hr />
        </li>
      ));
    } else if (activeTab === 'notices') {
      tabContent = (
        // <li key={index}>

        //   <h4>
        //     <a href={notice.Link} target="_blank" rel="noopener noreferrer">
        //       {notice.Title}

        //     </a>

        //   </h4>
        //   <br/>
        // </li>
        <div>
        <ol >
          <li>
            <h4>
              <a href="https://nitw.ac.in/api/static/files/Rolling_Faculty_Recruitment_-Notification_2024-6-7-10-12-41.pdf" target='_blank'>
                1. Rolling Faculty Recruitment -Notification

              </a>
            </h4>
          </li>
          <li>
            <h4>
              <a href="https://nitw.ac.in/api/static/files/Instructions_for_Filling_OAF_2024-6-7-10-15-19.pdf" target='_blank'>
                2. Instructions for Filling Online Application Form

              </a>
            </h4>
          </li>
          <li>
            <h4>
              <a href="https://nitw.ac.in/api/static/files/R2024_clarification_2024-6-7-10-17-57.pdf" target='_blank'>
                3. Implementation of Recruitment Rules for Faculty of NITS and IIEST- issue of clarifications as per recommendations of the Oversight Committe
              </a>
            </h4>
          </li>
          <li>
            <h4 style={{color:"#FF5833", animation: "blinker 2.0s linear infinite"}}>
              <a href="https://nitw.ac.in/api/static/files/Department-wise_vacancies_2024-6-7-10-18-54.pdf" target='_blank'>
                4. Department-wise vacancies

              </a>
            </h4>
          </li>
        </ol>


        <h3 style={{color:"red"}}><u>28-04-2024</u></h3>
        <ul >
          <li className='pt-2'><h5 style={{color:"green"}}>1. To candidates who have submitted applications: Please keep visiting the portal for further updates. However, 
            no queries can be entertained regarding the further course of recruitment.</h5></li>
          <li className='pt-2'><h5 style={{color:"green"}}>2.Inviting applications for Faculty positions will be on hold until further notice.</h5></li>

        </ul>
        </div>

      );
    } else if (activeTab === 'applyOnline') {
      tabContent = Applyonlineportal.map((item, index) => (
        <li key={index}>
          <h4>
            <a href={item.Link} target="_blank" rel="noopener noreferrer">
              {item.Title}
            </a>
          </h4>
          <hr />
        </li>
      ));
    }

    return <ul>{tabContent}</ul>;
  };

  const handleTabClick = (tab) => {
    if (tab === 'applyOnline') {
      window.open("https://recruit.nitw.ac.in/", '_blank'); // Open the online portal link in a new tab
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className="page text-left font-change-applicable static-facilities">
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div>
          <h3><b>Faculty Positions</b></h3>
          <p style={{ textAlign: 'justify' }}>
            NITW, an Institute of national importance in
            teaching, research and development, invites application from Indian nationals,
            possessing excellent academic background along with commitment to quality teaching and
            potential for carrying out outstanding research for faculty position at the level of
            Professor, Associate Professor, and Assistant Professor. NIT Warangal has been
            instrumental for providing opportunity to every faculty in achieving academic excellence,
            developing all-round personality and realizing his or her full potential.
          </p>
        </div>
        <br />
        <div className="tabs">

          <button
            className={`tab ${activeTab === 'notices' ? 'active' : ''}`}
            onClick={() => handleTabClick('notices')}
          >
            Faculty Recruitment 2024
          </button>

          <button
            className={`tab ${activeTab === 'applyOnline' ? 'active' : ''}`}
            onClick={() => handleTabClick('applyOnline')}
          >
            Apply Online Portal
          </button>

          <button
            className={`tab ${activeTab === 'announcements' ? 'active' : ''}`}
            onClick={() => handleTabClick('announcements')}
          >
            Announcements
          </button>


        </div>
        <br />
        <div className="tab-content">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default FacultyPositions;