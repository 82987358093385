import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

import TLR from "../components/nirf/TLR";
import GO from "../components/nirf/GO";
import RP from "../components/nirf/RP";
import OI from "../components/nirf/OI";
import PR from "../components/nirf/PR";
import { nirf2022} from "../constants/nirf/nirf2022";
import "../css/button.css";
import { useParams } from "react-router";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

function Nirf() {
  const params = useParams();
  console.log(params.yr);
  return (
    <ThemeProvider theme={theme}>
      <Grid style={{ width: "50%", margin: "5vh auto" }}>
        <Grid>
          <Typography variant="h4" fontWeight="bold" textAlign="center">
            Performance of NITW in NIRF Parameters for the year {nirf2022.year}
          </Typography>
        </Grid>
        <Grid className="row">
          <Grid className="col-md-6 col-sm-6 col-xs-12">
            <TLR data={nirf2022} />
          </Grid>
          <Grid className="col-md-6 col-sm-6 col-xs-12">
            <RP data={nirf2022} />
          </Grid>
          <Grid className="col-md-6 col-sm-4 col-xs-12">
            <GO data={nirf2022} />
          </Grid>
          <Grid className="col-md-6 col-sm-6 col-xs-12">
            <OI data={nirf2022} />
          </Grid>
          <Grid className="col-md-3 col-sm-3 col-xs-12"></Grid>
          <Grid className="col-md-6 col-sm-6 col-xs-12">
            <PR data={nirf2022} />
          </Grid>
        </Grid>
        <Grid>
          <Typography variant="h4" fontWeight="bold" textAlign="center">
            NIT Warangal NIRF Reports {nirf2022.year}
          </Typography>
        </Grid>
        <Grid id="buttons">
          <a href={nirf2022.overall} className="btn blue">
            NIRF Overall {nirf2022.year}
          </a>
          <a href={nirf2022.engineering} className="btn green">
            NIRF Engineering {nirf2022.year}
          </a>
          <a href={nirf2022.annual_report} className="btn red">
            Annual Report {nirf2022.year}
          </a>
        </Grid>
        <Grid>
          <Typography variant="h5" fontWeight="bold" textAlign="center">
            Contact Details for NIRF Comments and Feedback
          </Typography>
        </Grid>
        <Typography variant="h6" fontWeight="bold" textAlign="center">
        Prof. M. Chandrasekhar
        </Typography>
        <Typography variant="h6" textAlign="center">
          Dean, Planning & Development
        </Typography>
        <Typography variant="h6" textAlign="center">
          National Institute of Technology
        </Typography>
        <Typography variant="h6" textAlign="center">
          WARANGAL - 506 004
        </Typography>
        <Typography variant="h6" textAlign="center">
          Phone: +91 0872462013; 9490165363
        </Typography>
        <Typography variant="h6" textAlign="center">
          Email: dean_pl@nitw.ac.in
        </Typography>
      </Grid>
    </ThemeProvider>
  );
}

export default Nirf;
