import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
import { oldportaldocData } from '../constants/oldportaldocdata'; // Corrected import name
import styles from '../css/kdd.css'; // Import the CSS module

const OldPortalDoc = () => { // Changed component name to OldPortalDoc
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedNewsletter(index);
  };

  const handleMouseOut = () => {
    setSelectedNewsletter(null);
  };

  return (
    <div className="page text-center font-change-applicable">
      <PageHeader dept="/oldpandd" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="row">
          {_.map(oldportaldocData, (newsletter, index) => (
            <div
              className="col-sm-4 col-md-6 col-lg-3 member"
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={newsletter.link} target="_self" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <div className={`${styles.card} ${selectedNewsletter === index ? styles.hovered : ''}`}>
                  <img
                    src={newsletter.image}
                    alt={newsletter.name}
                    className={styles['rounded-image']}
                    style={{ maxWidth: '100%', height: 'auto', border:"3px solid #f16127" }}
                  />
                  <div className={styles['card-body']}>
                    <h5 className={styles['card-title']}>{newsletter.name}</h5>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OldPortalDoc;
 // Export the component