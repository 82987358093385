import React ,{useState} from "react";
import Chart from "react-apexcharts";
import '../css/PlacementCharts.css';



const responsive = {
  desktop: {
      breakpoint: { max: 3000, min: 1024 },
      withdx: 400,
  },
  tablet: {
      breakpoint: { max: 1024, min: 464 },
      withdx: 400,

  },
  mobile: {
      breakpoint: { max: 464, min: 0 },
      withdx: 400,

  }
};

function PlacementCharts() {
    const [state,setState]=useState({
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [2020,2021,2022,2023]
        }
      },
      series: [
        {
          name: "Highest-CTC (in ₹Lpa)",
          data: [43.31,51,62.5,88]
        }
      ],
      fill: {
        opacity: 1,
        colors: ['#fff']
      
      },
      legend: {
        show: true,
        showForSingleSeries: true,
    }
    })
    const [state2,setState2]=useState({
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: [2020,2021,2022,2023]
        }
      },
      series: [
        {
          name: "Student Placed",
          data: [792,815,1108,1404]
        }
      ]
    })
  return (
    <div className="chartsDisplay">
    <div className="charts">
    <Chart
              options={state.options}
              series={state.series}
              type="bar"
              width="350"
              color="#f16127"
            />
      <p style={{textAlign:"center",marginTop:"-5px",marginBottom:"10px",fontWeight:"800"}}>Highest CTC (in ₹Lpa)</p>
    </div>
    <div >
    <Chart className="charts" 
              options={state2.options}
              series={state2.series}
              type="bar"
              width="350"
            />
      <p style={{textAlign:"center",marginTop:"-5px",marginBottom:"10px",fontWeight:"800"}}>Student Placed</p>
    </div>
    </div>
  )
}

export default PlacementCharts