import { BaseUrl, MainServerUrl } from "../BasePath";

export const LatestResearchData = [
  {
    title: "NITW SIEMENS",
    // subtitle: "Center of Excellence",
    img: `${MainServerUrl}/media/files/Upload_research_2022-0-17-21-51-45.jpg`,
    link: `${BaseUrl}/path/?dept=/siemens`,
  },
  {
    title: "105 PhDs awarded",
    // subtitle: "21 MED, 12 ECE, 12 MME",
    img: `${MainServerUrl}/media/files/scholars_image.jpeg`,
    link: `${BaseUrl}/page/?url=/convocationDetaiils/phd2021`,
  },
  {
    title: "Another patent to NITW",
    // subtitle: "A Web-Enabled Smart Home Gateway",
    img: `${MainServerUrl}/media/files/patent_2022-0-20-20-11-58.jpg`,
    link: `${BaseUrl}/page/?url=/research/patent`,
  },
  // {
  //   title: "Nobel to NITW",
  //   subtitle: "Chemical Dept clinches Nobel for yr 2022",
  //   img: `${MainServerUrl}/media/files/research_2022-0-4-23-39-1.jpg`,
  //   link: `${BaseUrl}`,
  // },
  {
    title: "Partnership",
    // subtitle: "",
    img: `${MainServerUrl}/media/files/research_2022-0-4-23-56-16.jpg`,
    link: `${BaseUrl}/page/?url=/nitwMOU/MOU/`,
  },
];
