import { useState } from "react";
import '../css/cseDeptStyle.css';
const EEEDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img
                            src="https://nitw.ac.in/api/static/files/EED_banner_2023-10-4-13-12-46.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected="false">
                                    <span>About</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Society </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a>
                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Laboratory & Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>
                            </div>
                            <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/ee-nvs"
                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Srikanth_N_V_2024-3-7-17-38-37.jpg" alt="" className="img-fluid" width="190" height="220" />
                                <h6>Prof. N V Srikanth</h6>
                            </a>
                                <p className="justified-text"> Head of the Department <br /> <a href="mailto:eee_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>eee_hod@nitw.ac.in</a> <br /> 9490165342 </p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">
                                        <p className="justified-text">
                                            The Department of Electrical Engineering is one of the oldest departments of the National Institute of Technology, Warangal (NITW). Established as one of the major departments of the Institute, in 1959, the department has been actively engaged in teaching and research in diverse fields of Electrical Engineering. With excellent faculty, the department offers Under Graduate (B.Tech) program in Electrical and Electronics Engineering and Post Graduate (M.Tech) programmes in “Power Electronics & Drives”, “Power Systems Engineering” ,“Smart Electric Grid” and also offers Ph.D. programme in Electrical Engineering.
                                        </p>

                                        <p className="justified-text">

                                            The department has well-equipped state-of-the-art laboratories to augment the coursework and enhance the research potentials. The department has a dynamic group of faculties with profound experience in academics, research and industry, dedicated in teaching-learning process and actively engaged in the cutting-edge R&D activities with broad areas of expertise like; Power Electronic & Drives, Application of Power Electronics to Energy Efficient Lighting Systems, DSP controlled Industrial Drives, Electric Vehicle & Wireless Power Transfer and Power Quality Improvement, State Estimation and Real Time Control of Power Systems, Applications of ANN and Fuzzy Logic in Power Systems, Power System Deregulation, Power System Transients, Artificial Intelligence & Machine Learning etc.
                                        </p>
                                        <p className="justified-text">
                                            The department is strongly supported by external research projects and training programmes funded by Government of India through various prestigious schemes like; IMPRINT-India, SPARC, DST, SERB, CPRI, DRDO, MNRE, MeitY, GIAN, AICTE-ATAL, E&ICT, PMMMNMTT etc.
                                        </p>

                                        <p className="justified-text">
                                            The department has strong Industry interaction and has been involved in constantly handling various consultancy projects for various Industries and Government of Telangana and Andhra Pradesh. It has an MOU with Hitachi Energy India, Defence Research and Development Organization (DRDO), Central Power Research Institute (CPRI), POWERGRID, Grid India to carry out the collaborative projects.
                                        </p>

                                        <p className="justified-text">
                                            Throughout the department’s history, its faculty, students, and alumni have made major contributions and advancement in the fields of academia, industry, research and development.
                                        </p>

                                        <img src="https://nitw.ac.in/api/static/files/Electrical_Engineering_:_History_2023-10-12-16-19-16.png" alt="" className="img-fluid mb-5" />
                                    </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>
                                            <p className="  mb-4">To be recognized as an  excellent knowledge Hub for high quality educational programs, research and consultancy  in tune with the vision of the Institute.
                                            </p> <img src="CSEDeptAssets/images/cse.jpg" alt=""
                                                className="img-fluid" />
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">
                                                To create high quality Educational environment in which students are prepared to meet the challenges of modern Electrotechnical industry to fulfill the societal aspirations, by equipping them with<br></br>

                                                - Analytical and practical skills<br></br>

                                                - Management competence<br></br>
                                                - Innovative and creative approach to work</p>
                                            <p className="  mb-2">    To strengthen interaction with industry through lasting partnership focusing on <br></br>


                                                - Curriculum development<br></br>
                                                - Research and Consultancy
                                                <br></br>
                                                - Continuing Education Programs

                                            </p>


                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardEE/ElectricalEngineering"> Notices/Announcements</a>
                                        </h4>
                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                                    {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div className="row">


                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Courses Offered</h3>
                                                    </div>
                                                    <p className="justified-text">The department offers B.Tech in Electrical and Electronics Engineering with an intake of 175 students and M.Tech programme in three streams namely Power Systems Engineering,
                                                     Power Electronics & Drives and Smart Electric Grid with an annual intake of 32 Students, 33 Students and 30 Students respectively.</p>
                                                     <p className="justified-text">In addition to these programmes the department also offers Ph.D programme in all the areas of Electrical Engineering with 50 scholars pursuing their doctoral studies currently and 68 Doctorate degrees are awarded as on June 2020.</p>
                                                     <p className="justified-text">The department has also introduced courses on Artificial Intelligence Techniques in Power System and Power System Deregulation at M.Tech level for the first time in the state of Andhra Pradesh/Telangana.</p>


                                              
                                                   
                                                </div>
                                            </div>
                                        </div> */}






                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>B. Tech </h3>




                                                    </div>

                                                    <h5>Honors Programs </h5>
                                                    <p className="justified-text">
                                                        Honors is an additional credential a student may earn if she/he opts for the extra 18 credits
                                                        needed for this in her/his own discipline. The concerned department specifies the set of courses
                                                        for earning Honors. Honors is not indicative of class.<br></br>
                                                        Students with a minimum of 8.00 CGPA without any “F” Grade / backlog are only allowed to
                                                        register for Honors program offered by that Department.<br></br>
                                                        Students, satisfying the eligibility requirements may be permitted to do one minor/one honours
                                                        or two minors or one minor and one Honours.<br></br>
                                                        Number of credits for Honors program is 18 with 6 courses or (5 courses + 1 project for 3
                                                        credits) as prescribed by that Department.
                                                        <br></br>
                                                        Courses for Honors Program start from 5thSemester and the student is required to plan
                                                        registration for Honors courses, in order to complete all the six courses by the end of eighth
                                                        semester.
                                                        <br></br>
                                                        Students registering for Honors program shall be prepared to write more than one exam in a day.
                                                        <br></br>
                                                        After successful completion of the Honors program, the student will be awarded a degree in
                                                        “name of the discipline” with “Honors”.
                                                    </p>

                                                    <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2022/02/15/honors_electrical-and-electronics-engineering.pdf"
                                                        target="_blank">Honors Programs Scheme and Syllabus     <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                    <h5>Minor Programs </h5>

                                                    <p className="justified-text">
                                                        Minor program is an additional credential a student may earn if she/he does 18 credits worth of
                                                        additional courses in a discipline other than her/his major discipline of B.Tech. degree. All
                                                        academic departments in the Institute offer minors in their disciplines, and will prescribe a
                                                        specific set of courses and/or projects necessary for earning a minor in that discipline.

                                                        <br></br>
                                                        Students with a minimum of 7.00 CGPA without any “F” Grade / backlog are only allowed to
                                                        register for Minor program offered by any other Department.
                                                        <br></br>
                                                        Students, satisfying the eligibility requirements may be permitted to do one minor/one honours
                                                        or two minors or one minor and one Honours.
                                                        <br></br>
                                                        Number of credits for earning Minor specialization is 18 with 6 courses or (5 courses + 1 project
                                                        for 3 credits), as prescribed by that Department.
                                                        <br></br>
                                                        Courses for Minor specialization start from 3rd Semester and the student is required to register
                                                        for one minor specialization course in every semester.
                                                        <br></br>
                                                        Minimum and maximum number of students for any Minor specialization is 10 and 60
                                                        respectively
                                                        <br></br>
                                                        Students registering for Minor program shall be prepared to write more than one exam in a day
                                                        <br></br>
                                                        After successful completion of the Minor specialization requirements, the student will be
                                                        awarded a degree in “name of the discipline” with minor specialization in “name of the minor
                                                        specialization”.


                                                    </p>


                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Minor_Programs_2022-7-29-12-26-12.pdf"
                                                        target="_blank">Minor Programs Scheme and Syllabus    <i
                                                            className="fa fa-angle-right"></i> </a> </p>





                                                </div>
                                            </div>
                                        </div>





                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="card-4">
                                                        <div className="card-detail">
                                                            <div className="head-bx">

                                                                <h5>Open Elective Courses </h5>
                                                                <p className="justified-text">
                                                                    Open Elective Courses can be in any of the following areas: Basic Sciences, Engineering Science
                                                                    Courses, Humanities & Social Sciences and Management.
                                                                    <br></br>
                                                                    A student will not be permitted to register open elective course offered by the parent department.
                                                                    <br></br>
                                                                    The allotment of the open elective course shall be based on CGPA and the preference of the
                                                                    student.
                                                                    <br></br>
                                                                    Open Elective Courses list
                                                                </p>


                                                                <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2021/11/12/open-elective-1_vi-sem-1.pdf"
                                                                    target="_blank">Open Elective - I VI Semester Scheme and Syllabus     <i
                                                                        className="fa fa-angle-right"></i> </a> </p>


                                                                <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2021/11/12/open-elective-2_vii-sem.pdf"
                                                                    target="_blank">Open Elective - II VII Semester Scheme and Syllabus     <i
                                                                        className="fa fa-angle-right"></i> </a> </p>

                                                                <h3>B.Tech  </h3>



                                                                <p className="more"> <a className="more-info-link"
                                                                    href="https://nitw.ac.in/api/static/files/Electrical_Engineering_B.Tech_v1_2022-10-3-15-37-5.pdf"
                                                                    target="_blank">UG Scheme and Syllabus (w.e.f. 2021-22)<i className="fa fa-angle-right"></i> </a> </p>

                                                                <p className="more"> <a className="more-info-link"
                                                               
                                                                    href="https://nitw.ac.in/api/static/files/B.Tech_Electrical_and_Electronics_Engineering-Curriculum-new-2024-25_2024-7-19-15-44-57.pdf"
                                                                    target="_blank">UG Scheme and Syllabus (w.e.f. 2024-25)<i className="fa fa-angle-right"></i> </a> </p>
                                                            </div>





                                                        </div>
                                                    </div>

                                                    <div className="head-bx">

                                                        <h3>M.Tech </h3>
                                                        <h5>Power Electronics and Drives  </h5>

                                                    </div>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/MTech-PED-complete_Syllabi_2022-10-11-8-36-32.pdf"
                                                        target="_blank">PED Scheme and Syllabus (w.e.f. 2021-22)  <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/1_M.Tech_Power_Electronics_and_Drives-curriculum-new-2024-25_2024-7-19-16-39-58.pdf"
                                                        target="_blank">PED Scheme and Syllabus (w.e.f. 2024-25)  <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    <h5>Power Systems Engineering</h5>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/MTech-PSE-Complete_Syllabi_2022-10-11-8-37-31.pdf"
                                                        target="_blank">PSE Scheme and Syllabus (w.e.f. 2021-22)     <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/2_M.Tech_Power_Systems_Engineering-curriculum-new-2024-25_2024-7-19-16-40-41.pdf"
                                                        target="_blank">PSE Scheme and Syllabus (w.e.f. 2024-25)     <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    <h5>Smart Electric Grid </h5>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/MTech-SEG-Complete_Syllabi_2022-10-11-8-38-21.pdf"
                                                        target="_blank">SEG Scheme and Syllabus (w.e.f. 2021-22)     <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/3_M.Tech_Smart_Electric_Grid-curriculum-new-2024-25_2024-7-19-16-41-29.pdf"
                                                        target="_blank">SEG Scheme and Syllabus (w.e.f. 2024-25)    <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                    <h5>Control and Automation </h5>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/4_M.Tech_Control_and_Automation-curriculum-new-2024-25_2024_2024-7-19-16-42-17.pdf"
                                                        target="_blank">CA Scheme and Syllabus (w.e.f. 2024-25)     <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                </div>
                                            </div>





                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D </h3>

                                                        <p className="more"> <a className="buttton more-info-link"
                                                            href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                                                            target="_blank"> Rules and Regulations of Ph.D. Program (w.e.f. 2021-22) <i
                                                                className="fa fa-angle-right"></i> </a> </p>
                                                         <p className="more"> <a className="buttton more-info-link"
                                                            href="https://nitw.ac.in/api/static/files/PhD_Regulations_(2024-25)_2024-7-17-11-38-22.pdf"
                                                            target="_blank"> Rules and Regulations of Ph.D. Program (w.e.f. 2024-25) <i
                                                                className="fa fa-angle-right"></i> </a> </p>
                                                    </div>





                                                </div>
                                            </div>





                                        </div>











                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D.</h3>
                                                     <h5>Total number  of Ph.D. degrees awarded : 74</h5>
                                                     
                                                     <h5>Total number of Ph.D. scholars ongoing  : 61</h5>
                                                        <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Ph.D._Degree_Awarded_2022-7-29-12-43-13.pdf"
                                                        target="_blank">List of Ph.D. Degree Awarded    <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                              <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/phd_ongoing_2022-7-29-12-59-4.pdf"
                                                        target="_blank">List of Ph.D. Scholars:- Ongoing    <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                      
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>

                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div className="row laboratory-facilities">
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                {/* <h3 className="">Electrical Machines Lab</h3> */}

                                                <img src="https://nitw.ac.in/api/static/files/Laboratories_EED_1_2023-10-12-16-29-40.png" alt="" className="img-fluid mb-5" />
                                                <img src="https://nitw.ac.in/api/static/files/Laboratories_EED_2_2023-10-12-16-30-34.png" alt="" className="img-fluid mb-5" />

                                                {/* <h3 className=""></h3> */}
                                            </header>
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP EliteDesk 800 g1 Small Form Factor
                                                                Systemsi7 @3.40GHz, RAM 4GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 40</p>
                                                            <li className="text-black">HP Proliant 8300 Small Form Factor systems
                                                                i7@ 3.40GHz, RAM 8GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 5</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2"> Software</h4>
                                                         <p className="justified-text">Dual boot with Windows 7 and Ubuntu 14.04, NetBeans 7, MSDN, Dev C++
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/comp-laboratory.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Data Engineering Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP EliteDesk 800 g1 SFF Systems i7 @3.40GHz,
                                                                4GB RAM, 500GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 50</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                         <p className="justified-text">Cognos BIClementine Version 11.0, Oracle 11g (Unlimited user
                                                            Licenses), MSDN Academic Alliance Software, Informatica (25 user
                                                            licenses )</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/data-eng.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div> */}







                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">


                                            <section id="profile-info">

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ee-kiruba81 "
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._A._Kirubakaran_2024-3-7-17-4-33.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-kiruba81 "
                                                                        target="_blank">Prof. A. Kirubakaran</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9603722359 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kiruba81@nitw.ac.in ">kiruba81@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text">Power Electronics-DC-DC Converters, DC-AC Inverters, Multilevel Inverters, Power Quality and Grid interface, Renewable Energy and Distributed Generation.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ee-giridhar"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._A._V._Giridhar_2024-3-7-17-6-11.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-giridhar"
                                                                        target="_blank">Prof. A. V. Giridhar</a>
                                                                    </h5> <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9000742219 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:giridhar@nitw.ac.in">giridhar@nitw.ac.in   </a>
                                                                    </p>
                                                                    <p className="justified-text"> High Voltage Engineering, Condition Monitoring & Diagnosis of Power Equipments, Pulsed Power Technology, Nano Dielectrics.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ee-altafbadar"
                                                            target="_blank" className="img-fluid">
                                                            <img src="https://nitw.ac.in/api/static/files/Prof._Altaf_Q_H_Badar_2024-3-7-17-7-56.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-altafbadar"
                                                                        target="_blank">Prof. Altaf Q H Badar</a> </h5>
                                                                    <span>Assistant Professor </span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9890068893 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:altafbadar@nitw.ac.in  ">altafbadar@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text">Artificial Intelligence Applications to Power Systems, Energy Management Systems, Energy Trading</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href=" https://erp.nitw.ac.in/ext/profile/ee-bkmurthy"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Bhagwan_Krishna_Murthy_2024-3-7-17-9-34.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href=" https://erp.nitw.ac.in/ext/profile/ee-bkmurthy"
                                                                            target="_blank">Prof. Bhagwan Krishna Murthy</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9848995181 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:bkmurthy@nitw.ac.in  ">bkmurthy@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">Wind and wave energy; Doubly fed induction machine; Power electronic controls for electrical drives; Digital Signal Processing</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-nagubhukya"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Bhookya_Nagu_2024-3-7-17-10-43.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-nagubhukya"
                                                                            target="_blank">Prof. Bhookya Nagu</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i>8332969287 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:nagubhukya@nitw.ac.in  ">nagubhukya@nitw.ac.in </a> </p>
                                                                        <p className="justified-text"> Solar PV , Fuel Cell , Wind Power, Smart Grid Technologies, Power System Stability, HVDC and AI Techniques...
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17098"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17098/17098.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17098"
                                                                            target="_blank">Prof. Bidyadhar Subudhi</a> </h5>
                                                                        <span>Professor (HAG) and Director, NITW</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7008118559 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:bidyadhar@nitw.ac.in ">bidyadhar@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">-</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-venugopalreddy"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._B._Venugopal_Reddy_2024-3-7-17-13-5.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-venugopalreddy"
                                                                            target="_blank">Prof. B. Venugopal Reddy</a>
                                                                        </h5> <span>Associate Professor</span>
                                                                        <p className="contact-info">
                                                                            <i className="fa fa-mobile fa-lg"
                                                                                aria-hidden="true"></i> 9404527890 <br /> <i
                                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                        href="mailto:venugopalreddy@nitw.ac.in ">venugopalreddy@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> Multilevel Inverters, Renewable energy systems,Open-end Winding Induction Motor Drives.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href=" https://erp.nitw.ac.in/ext/profile/ee-rnitchinthamalla"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Ch._Ramulu_2024-3-7-17-14-17.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href=" https://erp.nitw.ac.in/ext/profile/ee-rnitchinthamalla"
                                                                            target="_blank">Prof. Ch. Ramulu</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9866561691,8332969291 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:rnitchinthamalla@nitw.ac.in ">rnitchinthamalla@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power electronics and drives, application of power electronics to nonconventional energy conditioning, AC-DC electrolytic capacitor-less single-phase LED drivers, and high gain DC-DC Converters.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-ch.venkaiah"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Chintham_Venkaiah_2024-3-7-17-15-29.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-ch.venkaiah"
                                                                            target="_blank">Prof. Chintham Venkaiah</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969285 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ch.venkaiah@nitw.ac.in ">ch.venkaiah@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text">AI Technique applications to Power and Energy Systems;Power System Deregulation and Restructuring; Distribution Generation Technology; Economics and Financing of Renewable Energy Technologies;Power Procurement Strategy and Power Exchanges;Smart Grid Technologies;ICT applications to Power and Energy Systems;Effectual Entrepreneurship and Technology based Entrepreneurship Development.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-dpanda"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Debasmita_Panda_2024-3-7-17-16-48.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-dpanda"
                                                                            target="_blank">Prof. Debasmita Panda</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7899852334 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:dpanda@nitw.ac.in ">dpanda@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Restructured Power Systems, Power Market Risk Management, Portfolio Optimization, Energy Trading in Local Energy Market, Transactive Energy Market.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-srinudee"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._D_Sreenivasarao_2024-3-7-17-18-5.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-srinudee"
                                                                            target="_blank">Prof. D Sreenivasarao</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7842001002 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:srinudee@nitw.ac.in ">srinudee@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power quality, Custom Power Devices (CPDs), Flexible AC Transmission System (FACTS), Multilevel inverters, Improved power quality converters.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-gsivakumar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._G._Siva_Kumar_2024-3-7-17-19-15.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-gsivakumar"
                                                                            target="_blank">Prof. G. Siva Kumar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7702716890 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:gsivakumar@nitw.ac.in ">gsivakumar@nitw.ac.in
                                                                            </a> </p>
                                                                        <p className="justified-text"> Power electronics application to the power system, renewable system, and AC microgrid.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-anilnaik205"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Kanasottu_Anil_Naik_2024-3-7-17-20-12.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-anilnaik205"
                                                                            target="_blank">Prof. Kanasottu Anil Naik</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8630384437, 9690314482 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:anilnaik205@nitw.ac.in ">anilnaik205@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Wind energy system grid integration issues; Partial shading in PV solar; intelligent controller applications in power system; and Micro-grid stability and control. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-mnalam"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Mahamad_Nabab_Alam_2024-3-7-17-22-16.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-mnalam"
                                                                            target="_blank">Prof. Mahamad Nabab Alam</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i>9897998728<br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mnalam@nitw.ac.in"> mnalam@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">Protection coordination, power system protection and optimization, adaptive protection using phasor measurement units, networked microgrids analysis, protection, and optimization, distribution system state estimation, hydroelectrical engineering, distribution automation, artificial intelligence techniques, numerical and metaheuristic optimization techniques</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-ub"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._M_Udaya_Bhasker_2024-3-7-17-23-48.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-ub"
                                                                            target="_blank">Prof. M Udaya Bhasker</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702462241 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ub@nitw.ac.in   ">
                                                                                ub@nitw.ac.in    </a> </p>
                                                                        <p className="justified-text">Bi-directional DC-DC converters, Energy Storage system, Digital Control system.SCOPUS ID: 57193699704, Google Scholar ID: IXIEeysAAAAJ https://orcid.org/0000-0002-5919-4482. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-blnraju"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Narasimharaju_B._L_2024-3-7-17-25-17.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-blnraju"
                                                                            target="_blank">Prof. B. L Narasimharaju</a>
                                                                        </h5> <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 0870-246-2247 (O)
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:blnraju@nitw.ac.in"> blnraju@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">LED Drivers for Lighting Systems; Multilevel power converters; Grid integration of solar energy ; Switched Reluctance Motor drives; Active Front End Power Converters ; Open End Winding Induction Motor Drives ; Modulation and switching techniques ; AI Techniques to Control of Power Converters ;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-pmishra"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Palash_Mishra_2024-3-7-17-26-58.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-pmishra"
                                                                            target="_blank">Prof. Palash Mishra</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9566160482
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: pmishra@nitw.ac.in "> pmishra@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text"> High Voltage DC/AC Systems; Nano Dielectrics; Condition Monitoring of Power Apparatus; Artificial Intelligence Techniques; Polymeric Insulation. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-selvi"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Porpandiselvi_S_2024-3-7-17-28-55.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-selvi"
                                                                            target="_blank">Prof. Porpandiselvi S</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969288
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: selvi@nitw.ac.in  "> selvi@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text"> PV fed LED drivers, Induction Heating Applications, High frequency Soft-Switched Inverters. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>






                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-rkrishan"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Ram_Krishan_2024-3-7-17-30-18.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-rkrishan"
                                                                            target="_blank">Prof. Ram Krishan</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9555605636
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: rkrishan@nitw.ac.in "> rkrishan@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power system stability and Optimization, Integration of Renewable Energy Sources, Distribution system analysis, Electrical Energy Storage System. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-jey"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._R._Jeyasenthil_2024-3-7-17-32-6.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-jey"
                                                                            target="_blank">Prof. R. Jeyasenthil</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9360153302
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: jey@nitw.ac.in  "> jey@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Control System Design: Linear and Nonlinear Control; Robust Control: Quantitative Feedback Theory (QFT), H-infinity Control; Optimization-based Control.Application areas: Control of Power electronic converters and Power system, Mechatronics. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-sailaja"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16234/16234.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-sailaja"
                                                                            target="_blank">Prof. Sailaja Kumari M</a> </h5>
                                                                        <span> Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> -
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: sailaja@nitw.ac.in "> sailaja@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power Markets, Distributed Generations in Power Markets, Synchrophasor applications in Smart Electric Grids, Power System Optimization, Artificial Neural networks, Evolutionary Algorithms and applications to power systems, Grid Integration of Renewable energy sources, Energy storage systems optimization. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>






                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-drinjetisatishkumar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Satish_Kumar_I_2024-3-7-17-34-18.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-drinjetisatishkumar"
                                                                            target="_blank">Prof. Satish Kumar I</a> </h5>
                                                                        <span> Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9581371537
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: drinjetisatishkumar@nitw.ac.in"> drinjetisatishkumar@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text"> Network restructuring; optimal allocation of energy sources; economic dispatch; automatic generation control; load frequency control; the small signal stability of AC Micro Grids; Soft Computing applications to Power Systems, Electric Drives, Image and Radar Signal Processing. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-sharmili"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Sharamili_Das_2024-4-3-11-10-22.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-sharmili"
                                                                            target="_blank">Prof. Sharmili Das</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9456786514
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: sharmili@nitw.ac.in  "> sharmili@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text"> Machine Drives and Power Electronics </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-dvss"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Siva_Sarma_D_V_S_S_2024-3-7-17-35-44.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-dvss"
                                                                            target="_blank">Prof. Siva Sarma D V S S</a> </h5>
                                                                        <span> Professor (HAG)</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849434415
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: dvss@nitw.ac.in "> dvss@nitw.ac.in     </a>
                                                                        </p>
                                                                        <p className="justified-text">  Power Quality, Power System Protection, Condition Monitoring of Electrical Equipment, Switching Transients. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-sekhar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Somasekhar_V._T._2024-3-7-17-37-11.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-sekhar"
                                                                            target="_blank">Prof. Somasekhar V. T.</a> </h5>
                                                                        <span> Professor (HAG)</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702462231
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: sekhar@nitw.ac.in  "> sekhar@nitw.ac.in      </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power Electrionics and Drives. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-nvs"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Srikanth_N_V_2024-3-7-17-38-37.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-nvs"
                                                                            target="_blank">Prof. Srikanth N V</a> </h5>
                                                                        <span> Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9247881601
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: nvs@nitw.ac.in   "> nvs@nitw.ac.in       </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power System Stability and control, HVDC and FACTS, AI Techniques applications to Power Systems, Real-time control of power systems. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-ssr"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Srinivasa_Rao_Sandepudi_2024-3-7-17-40-43.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-ssr"
                                                                            target="_blank">Prof. Srinivasa Rao Sandepudi</a> </h5>
                                                                        <span> Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702462233
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: ssr@nitw.ac.in    "> ssr@nitw.ac.in       </a>
                                                                        </p>
                                                                        <p className="justified-text"> Renewable Energy Systems; Power Quality; Power Electronic Converters; Induction Motor Drives; Unipolar and Bipolar DC Microgrids, Digital Signal Processor Controlled Drives. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-spradabane"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16240/16240.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-spradabane"
                                                                            target="_blank">Prof. Srinivasan Pradabane</a> </h5>
                                                                        <span> Assistant Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969289
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: spradabane@nitw.ac.in"> spradabane@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> 1. Multilevel inverters. 2. DC drives. 3. AC drives. 4. Electric propulsion. 5. Integration of renewables. 6. Driver circuits. 7. Intelligent control. 8. IoT applications. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-drsureshperli"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Suresh_Babu_Perli_2024-3-7-17-42-44.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-drsureshperli"
                                                                            target="_blank">Prof. Suresh Babu Perli</a> </h5>
                                                                        <span> Associate Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8977123345
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: drsureshperli@nitw.ac.in "> drsureshperli@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power System Protection, Electric Vehicles, Smart Grid Protection, Digital Filtering Algorithms Design. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-swatikjm"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Swati_Devabhaktuni_2024-3-7-17-44-6.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-swatikjm"
                                                                            target="_blank">Prof. Swati Devabhaktuni</a> </h5>
                                                                        <span> Assistant Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9618371888
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: swatikjm@nitw.ac.in  "> swatikjm@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power Electronics, Induction motor drive, Linear induction motor and alternator. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-vinaykumar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Vinay_Kumar_T_2024-3-7-17-45-17.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-vinaykumar"
                                                                            target="_blank">Prof. Vinay Kumar T</a> </h5>
                                                                        <span> Assistant Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9885281148
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: vinaykumar@nitw.ac.in   "> vinaykumar@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text">  Power Electronics and Drives. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-nvn"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Vishwanathan_N_2024-3-7-17-46-39.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-nvn"
                                                                            target="_blank">Prof. Vishwanathan N</a> </h5>
                                                                        <span> Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702468232
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: nvn@nitw.ac.in "> nvn@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text">  Switched Mode Power Supplies, Induction Heating Applications and Electrical Drives. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>











                                                </div>

                                            </section>





                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/G._Veda_Prakash_2024-3-7-16-26-4.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">G. Veda Prakash</h6> <span>Assistant (SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462201/ 8332969540 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gvpnitw@nitw.ac.in">gvpnitw@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/A._Nagabhushanan_2024-3-7-16-27-37.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">A. Nagabhushanan</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462262/ 8332969348 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:nagabhushanam.98@nitw.ac.in">nagabhushanam.98@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Pooja_Kurdekar_2023-10-12-15-56-36.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Pooja Kurdekar</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462262/ 7204279549 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:poojak@nitw.ac.in">poojak@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>





                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sabavath_Rahul_Naik_2024-3-7-16-29-2.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sabavath Rahul Naik</h6>
                                                                <span>Senior Technician </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462264/ 9701317684 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:sabavathr@nitw.ac.in">sabavathr@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Bollam_Venu_2024-3-7-16-30-5.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Bollam Venu</h6>
                                                                <span>Senior Technician </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9014042461 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bollamv@nitw.ac.in">bollamv@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Angali_Sai_Kumar_2024-3-7-16-36-29.jpg" alt="" className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Angali Sai Kumar</h6>
                                                                <span>Senior Technician </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462261/ 9603947427 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:angalis@nitw.ac.in">angalis@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>












                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/T._Veeranna_2024-3-7-16-37-46.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">T. Veeranna</h6> <span>Technician (SG-I)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462260/ 8332969303 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/D._Veeraiah_2024-3-7-16-38-57.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">D. Veeraiah</h6> <span>Technician (SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462261/ 8332969482 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/K._Rajender_2023-10-6-12-37-58.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">K. Rajender</h6> <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969570 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/A._Srinivas_2024-3-7-16-41-25.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">A. Srinivas</h6> <span>Technician (UG)
                                                                </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462065/ 8332969278 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bottusin786@gmail.com">bottusin786@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/G._Murali_2023-10-6-12-38-49.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">G. Murali</h6>
                                                                <span>Technician (UG)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462201/ 8332969727 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CHEDeptAssets/images/st5.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Mohd. Afzal pasha</h6> <span>Caretaker SG-II</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                        <i className="fa fa-phone" aria-hidden="true"></i> 8332969648
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>








                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/K._Venkataiah_2023-10-12-16-8-21.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">K. Venkataiah</h6>
                                                                <span>Senior care taker</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462262/ 8179835314 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ashishzone$18@gmail">ashishzone$18@gmail</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mohd_Asifuddin_2024-3-7-16-43-47.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Mohd Asifuddin</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 6303974920 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mohammeda@nitw.ac.in">mohammeda@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src=" https://nitw.ac.in/api/static/files/Oddem_Praveen_2024-3-7-16-47-2.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Oddem Praveen</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462263/9177799895 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:oddemp@nitw.ac.in">oddemp@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Banoth_Ravinder_2023-10-12-16-13-35.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Banoth Ravinder</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9490113065 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:banothr@nitw.ac.in">banothr@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}





                                            </div>

                                        </div>







                                        <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                            aria-labelledby="nav-AdjunctFaculty-tab">

                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">


                                                    {/* <div className="d-flex align-items-center">
                                                       
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Dr. Surajit Sannigrahi </h6>
                                                            <div className=" mt-2">
                                                                <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:surajit710@yahoo.in">surajit710@yahoo.in</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div> */}



                                                </div>
                                            </div>






                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">


                                                    <div className="d-flex align-items-center">
                                                        <div className="image"> <img src="https://nitw.ac.in/api/static/files/Akshay_Kumar_Rathore_2023-10-16-16-19-45.jpg" alt=""
                                                            className="img-fluid" width="150" height="190" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Akshay Kumar Rathore, IEEE Fellow</h6>
                                                            <span>Professor (Engineering) and Program Leader (Electrical Power Engineering)
                                                            </span>
                                                            <p className="justified-text">Singapore Institute of Technology, Singapore
                                                            </p>

                                                            <div className=" mt-0">
                                                                <span className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: akshaykumar.rathore@singaporetech.edu.sg"> akshaykumar.rathore@singaporetech.edu.sg</a>
                                                                </span><br></br>
                                                                <span className="justified-text"><b> For more details follow link: </b> <a href="https://www.singaporetech.edu.sg/directory/faculty/akshay-kumar-rathore">https://www.singaporetech.edu.sg/directory/faculty/akshay-kumar-rathore</a></span>


                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>




                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">


                                                    <div className="d-flex align-items-center">
                                                        <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof.N_D_R_Sarma_2023-10-16-16-21-15.jpg" alt=""
                                                            className="img-fluid" width="150" height="180" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Sarma (NDR) Nuthalapati, PhD </h6>
                                                            <span>NDRS SEMS Consultancy, LLC,
                                                            </span>
                                                            <p className="justified-text">Adjunct Professor, Texas A&M University, College Station, TX.
                                                            </p>
                                                            <div className=" mt-0">
                                                                <span className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ndrs.sems.consultancy@gmail.com "> ndrsarma@ieee.org,    ndrs.sems.consultancy@gmail.com   </a>
                                                                </span><br></br>
                                                                <span className="justified-text"><b> For more details follow link: </b> <a href=" www.ndrsarma.com "> www.ndrsarma.com </a></span>

                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
























                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">
                                            {/*<h4 className="">Ph.D. Awardee</h4>
                     <p className="justified-text">
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}






                                            <div className="row phd-std-row">






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Bhavin_Salvi_2023-10-16-17-9-38.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Bhavin Salvi</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -<br /> <span> <a href="mailto:-">-</a></span> </p>


                                                    </div>
                                                </div>











                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/c_venkata_subba_reddy_2023-10-16-17-11-21.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">C Venkata Subba Reddy</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>








                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Valluru_Chandrasekhar_2023-10-16-17-12-34.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Valluru Chandrasekhar
                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/A._Bhanuchandar_2023-10-16-17-13-45.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> A. Bhanuchandar

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Gotte_Vikram_Raju_2023-10-16-17-14-34.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Gotte Vikram Raju</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Ramesh_Daravath_2023-10-16-17-19-54.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ramesh Daravath </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Dodda_Satish_Reddy_2023-10-16-17-21-18.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Dodda Satish Reddy </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Ankeshwarapu_Sunil_2023-10-16-17-22-40.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ankeshwarapu Sunil </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/M_F_Baba_2023-10-16-17-24-1.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> M F Baba </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kanithi_Ashok_Kumar_2023-10-16-17-31-38.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kanithi Ashok Kumar </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kalvapalli_Srikanth_Reddy_2023-10-16-17-34-26.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kalvapalli Srikanth Reddy </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/T_Phani_Swecha_2023-10-16-17-35-27.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> T Phani Swecha
                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Gajangi_Arun_Kumar_2023-10-16-17-36-6.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Gajangi Arun Kumar
                                                        </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/T_Murali_Krishna_2023-10-16-17-37-0.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> T Murali Krishna
                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Rayappa_David_Amar_Raj_2023-10-16-17-37-51.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Rayappa David Amar Raj
                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Dharavath_Anusha_2023-10-16-17-38-32.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Dharavath Anusha
                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Ramesh_Junju_2023-10-16-17-39-16.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ramesh Junju
                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Koyyana_Srinivasa_Rao_2023-10-16-17-40-2.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Koyyana Srinivasa Rao
                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Choudhary_Om_Prakash_2023-10-16-17-41-2.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Choudhary Om Prakash
                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Sheri_Abhishek_Reddy_2023-10-16-17-42-0.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Sheri Abhishek Reddy

                                                        </h6>
                                                        <span>Part-Time
                                                            Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/U.Vijaya_Laxmi_2023-10-16-17-42-53.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> U.Vijaya Laxmi

                                                        </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Karthika_Mangamuri_2023-10-16-17-43-35.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Karthika Mangamuri

                                                        </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Madavena_Kumaraswamy_2023-10-16-17-44-14.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Madavena Kumaraswamy

                                                        </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Sudipta_Mitra_2023-10-16-17-45-0.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Sudipta Mitra

                                                        </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Pentam_Sirisha_2023-10-16-17-47-0.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Pentam Sirisha

                                                        </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Murali_A_2023-10-16-17-48-0.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Murali A

                                                        </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/T._Deepthi_Reddy_2023-10-16-17-48-55.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> T. Deepthi Reddy

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Reddimalla_mounika_2023-10-16-17-50-32.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Reddimalla mounika

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Syed_Abdul_Mujeer_2023-10-16-17-51-22.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Syed Abdul Mujeer

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Dhanunjaya_Naidu_V_2023-10-16-17-52-9.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Dhanunjaya Naidu V

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kanapala_Rosaiah_2023-10-16-17-53-6.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kanapala Rosaiah

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Naresh_Rayavarapu_2023-10-16-17-54-31.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Naresh Rayavarapu

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/G._Ravi_Kumar_2023-10-16-17-56-2.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> G. Ravi Kumar

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Velmajala_Krishna_2023-10-16-17-56-43.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Velmajala Krishna

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kirlampalli_Harija_Rani_2023-10-16-17-57-24.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kirlampalli Harija Rani

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Bhukya_Mounika_2023-10-16-17-58-12.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Bhukya Mounika

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kuraganti_Manikanta_2023-10-16-18-2-16.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kuraganti Manikanta

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kamal_Singh_2023-10-16-18-3-2.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kamal Singh

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Merugu_Arpitha_2023-10-16-18-3-42.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Merugu Arpitha

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/T._Pavani_2023-10-16-18-4-31.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> T. Pavani

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Vangipuram_Srinivasa_Raghavan_2023-10-16-18-5-28.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Vangipuram Srinivasa Raghavan

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Narlapati.Chandrasekhar_Azad_2023-10-16-18-6-9.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Narlapati. Chandrasekhar Azad

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>







                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Fabia_Akbar_2023-10-16-18-6-52.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Fabia Akbar

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Palakurthi_Ravali_2023-10-16-18-7-37.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Palakurthi Ravali

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kallepalli_Sravan_Kumar_2023-10-16-18-8-18.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kallepalli Sravan Kumar

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kattamuri_V_V_D_Ramana_Kumar_2023-10-16-18-9-18.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kattamuri V V D Ramana Kumar

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Pujari_Rahul_2023-10-16-18-10-19.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Pujari Rahul

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/B_Avinash_2023-10-16-18-11-5.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> B Avinash

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Anil_Kumar_Maddali_2023-10-16-18-12-15.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Anil Kumar Maddali

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Pottabathula._Raviteja_2023-10-16-18-13-1.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Pottabathula. Raviteja

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Boda_Bixam_2023-10-16-18-13-45.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Boda Bixam

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/P_Sai_Nandini_2023-10-16-18-14-31.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> P Sai Nandini

                                                        </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/R_Laxman_2023-10-16-18-15-20.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> R Laxman </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kodithala_Vani_2023-10-16-18-16-3.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kodithala Vani </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Ravi_Kumar_K_2023-10-16-18-16-53.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ravi Kumar K </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Bomma_Siddhartha_2023-10-16-18-17-34.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Bomma Siddhartha  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/B._Jatoth_Varun_2023-10-16-18-18-26.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> B. Jatoth Varun   </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Sai_Lakshmi_S_2023-10-16-18-19-35.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Sai Lakshmi S   </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Paruchuri_Rajendra_Bhanu_Teja_2023-10-16-18-20-19.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Paruchuri Rajendra Bhanu Teja   </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/M_Prathima_2023-10-16-18-21-7.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> M Prathima   </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Varipalli_Krupakar_2023-10-16-18-21-53.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Varipalli Krupakar  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/K._V._Gayatri_2023-10-16-18-22-43.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> K. V. Gayatri  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Jayasri_Boda_2023-10-16-18-23-26.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Jayasri Boda  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Y_Naomi_2023-10-16-18-24-10.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Y Naomi  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Swapna_Boddepalli_2023-10-16-18-25-1.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Swapna Boddepalli  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/CH_Rajender_2023-10-16-18-25-56.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> CH Rajender   </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Yezarla_Narayana_Rao_2023-10-16-18-26-41.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Yezarla Narayana Rao </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Banoth_Chandrasingh_2023-10-16-18-27-27.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Banoth Chandrasingh </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Ogirala_Rakesh_Pal_2023-10-16-18-28-9.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>-</b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ogirala Rakesh Pal </h6>
                                                        <span>JRF/SRF
                                                        </span>
                                                        <hr />

                                                        <p className="contact-info">  -
                                                            <br />  <span><a href="mailto:-" >-</a></span></p>

                                                    </div>
                                                </div>




                                            </div>



                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div className="row std-society">
                                        {/* <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                                                target="_blank"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                                                    target="_blank">
                                                    <h6>Prof. R. Padmavathy</h6> <span> Faculty Advisor </span>
                                                    <hr />
                                                </a>
                                                     <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9490165347 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:cse_hod@nitw.ac.in"
                                                            style={{ color: "#808080" }}>cse_hod@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Prof. U. S. N. Raju</h6> <span> Treasurer </span>
                                                    <hr />
                                                </a>
                                                     <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


                                    <div className="row notable-alumni">




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/V.A._Sastry_2023-11-2-10-35-50.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>V.A. Sastry</h6>
                                                    <p className="justified-text"><b>Batch: </b>1964<br />
                                                        <b>Present Position: </b>Entrepreneur
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/P._Sudhakar_2023-11-2-18-26-23.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>P. Sudhakar</h6>
                                                    <p className="justified-text"><b>Batch: </b>1964<br />
                                                        <b>Present Position: </b>CMD,ECIL
                                                    </p>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Rao_Remala_2023-11-2-18-27-16.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Rao Remala</h6>
                                                    <p className="justified-text"><b>Batch: </b>1970<br />
                                                        <b>Present Position: </b>First non American employee of MICROSOFT
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/RP_Singh_2023-11-2-18-27-50.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>R P Singh</h6>
                                                    <p className="justified-text"><b>Batch: </b>1979 <br />
                                                        <b>Present Position: </b>CMD, SJVN Ltd
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Satya_Bolli_2023-11-2-18-28-44.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Satya Bolli</h6>
                                                    <p className="justified-text"><b>Batch: </b>1978 <br />
                                                        <b>Present Position: </b>Entrepreneur
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/N._Venu_2023-11-2-18-29-16.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>N. Venu</h6>
                                                    <p className="justified-text"><b>Batch: </b>1988 <br />
                                                        <b>Present Position: </b>CMD, Hitachi Energy
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/R_S_T_Sai_2023-11-2-18-29-59.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>R S T Sai</h6>
                                                    <p className="justified-text"><b>Batch: </b>1977 <br />
                                                        <b>Present Position: </b>CMD, NHPC
                                                    </p>


                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/K._Linga_Reddy_2023-11-2-18-30-44.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>K. Linga Reddy</h6>
                                                    <p className="justified-text"><b>Batch: </b>1970 <br />
                                                        <b>Present Position: </b>CEO, PETE Transformers
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Praveen_Ghanta_2023-11-2-18-31-21.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Praveen Ghanta</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Synopsys Inc
                                                    </p>


                                                </div>
                                            </div>
                                        </div>





                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Biju_Philipose_2023-11-2-18-31-51.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Biju Philipose</h6>
                                                    <p className="justified-text"><b>Batch: </b>1994 <br />
                                                        <b>Present Position: </b>Managing Director at Sevana Packaging Systems Pvt Ltd
                                                    </p>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof_K_Shanti_Swarup_2023-11-2-18-32-56.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. K Shanti Swarup</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Professor in Electrical Engineering, IIT Chennai
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._Lalit_Goal_2023-11-2-18-33-25.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. Lalit Goal</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Director Renaissance Engineering Programme (REP) at Nanyang Technological University Singapore
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._T_V_Prabhakar_2023-11-2-18-34-8.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. T V Prabhakar</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Professors in CSE, IIT Kanpur
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._N_D_R_Sarma_2023-11-2-18-34-40.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. N D R Sarma</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Adjunt Faculty, Electrical & Computer Engineering Texas A&M University
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._M_Veerachary_2023-11-2-18-35-15.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. M Veerachary</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Professor in Electrical Engineering, IIT Delhi
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._Srinivas_2023-11-2-18-35-43.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. Srinivas</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Professor in Electrical Engineering, IIT Chennai
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Ganesh_Sahay_2023-11-2-18-36-51.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Ganesh Sahay</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Divisional Railway Manager, Chennai at INDIAN RAILWAYS
                                                    </p>


                                                </div>
                                            </div>
                                        </div>









                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Mutyala_Raju_Revu_2023-11-2-18-37-34.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Mutyala Raju Revu</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Stood first in IAS in 2008
                                                    </p>


                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Amarjot_Singh_2023-11-2-18-38-2.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Amarjot Singh</h6>
                                                    <p className="justified-text"><b>Batch: </b>2011  <br />
                                                        <b>Present Position: </b>Founder & CEO at SkyLark Labs
                                                    </p>


                                                </div>
                                            </div>
                                        </div>





                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/P._Satish_Reddy_2023-11-2-18-38-46.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>P. Satish Reddy</h6>
                                                    <p className="justified-text"><b>Batch: </b>2012 <br />
                                                        <b>Present Position: </b>AIR 97, IAS-UPSC-2014
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Satish_Kumar_2023-11-2-18-41-0.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Satish Kumar</h6>
                                                    <p className="justified-text"><b>Batch: </b> <br />
                                                        <b>Present Position: </b>AIR 58, IES, Assistant Director, Ministry of Power, GoI
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Kavali_Meghana_2023-11-2-18-41-33.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Kavali Meghana</h6>
                                                    <p className="justified-text"><b>Batch: </b> 2016<br />
                                                        <b>Present Position: </b>AIR 83, IAS-UOSC 2020
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Shrikrishan_Yadav_2023-11-2-18-44-24.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Shrikrishan Yadav</h6>
                                                    <p className="justified-text"><b>Batch: </b> 2018<br />
                                                        <b>Present Position: </b>Assistant Director in Military Engineer Services(MES) ,IDSE Cadre
                                                    </p>


                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Bhoopendra_Singh_Meena_2023-11-2-18-46-3.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Bhoopendra Singh Meena</h6>
                                                    <p className="justified-text"><b>Batch: </b> 2018<br />
                                                        <b>Present Position: </b>Assistant Director in Military Engineer Services(MES) ,IDSE Cadre
                                                    </p>


                                                </div>
                                            </div>
                                        </div>





                                        {/* <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CSEDeptAssets/images/na1.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Dr. Pushmeet Kohli</h6>

                                                     <p className="justified-text"><b>Batch :</b> B.Tech (CSE) (2004)<br />
                                                        <b>Present Position :</b> Principal Scientist and Team Lead, DeepMind,
                                                        London, UK
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. Pavithra Prabhakar</h6>

                                                     <p className="justified-text"><b>Batch :</b> B.Tech (CSE) (2004)<br />
                                                        <b>Present Position :</b> Professor, Kansas State University, Manhattan,
                                                        United States
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/vrajanna.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>V Rajanna</h6>



                                                     <p className="justified-text">
                                                        <b>Present Position :</b> GM & VP, TCS- Hyderabad


                                                    </p>

                                           
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/aarthi.jpg  " alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Aarthi Subramanyan</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Group Chief Digital Officer, Tata Sons Private
                                                        Limited, Mumbai
                                                    </p>

                                              
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/murali.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. Murali Annavaram</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Professor at University of Southern California

                                                    </p>

                                                
                                                </div>
                                            </div>
                                        </div> */}




                                        {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Sridhar Muppala</h6>



                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CEO, Silicon Valley

                                                    </p>

                                               
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Movva Ramu</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Microsoft Research Centre, USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>K V Sridhar</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CEO Analytics CA, USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Jitendra Lakotia</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Former VP of Cognos. USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Amarnadh Reddy</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CTO, CGG, Hyderabad
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}


                                        {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Feroz khan</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Dy. Director, SAP Labs

                                                    </p>

                                                </div>
                                            </div>
                                        </div> */}


                                    </div>
                                </div>

                                {/* <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Big Data Analytics, Artificial Intelligence / Machine Learning, Data Science
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/5.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. RBV Subramanyam</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9491346969 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rbvs66@nitw.ac.in">rbvs66@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/6.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Radhakrishna</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9704988816 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:prkrishna@nitw.ac.in">prkrishna@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/11.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Ramakrishnudu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9866876842 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:trk@nitw.ac.in">trk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/14.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.V.Kadambari</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9705460461<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kvkadambari@nitw.ac.in">kvkadambari@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/22.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. M Srinivas</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8897064421<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msv@nitw.ac.in">msv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/27.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Venkateswara Rao Kagita</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 6281746931 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:venkat.kagita@nitw.ac.in">venkat.kagita@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Security</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R Padmavathy</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9381265691 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rpadma@nitw.ac.in">rpadma@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/21.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.M.Sandhya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9394468554<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msandhya@nitw.ac.in">msandhya@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/23.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Kaveti Ilaiah</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9848916272 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ilaiahkavati@nitw.ac.in">ilaiahkavati@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Networks</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Software Engineering</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/16.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Priyanka Chawla</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7982750652 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:priyankac@nitw.ac.in">priyankac@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/18.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Manjubala Bisi</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9502940360 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">manjubalabisi@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/29.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sangharatna Godboley</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7013306805<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:cse_hod@nitw.ac.in">sanghu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Cloud & Distributed Computing, IOT</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/8.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ch Sudhakar</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440647945 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:chapram@nitw.ac.in">chapram@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/25.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sanjaya Kumar Panda</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9861126947<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sanjay@nitw.ac.in">sanjay@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Algorithms, BioInformatics, Computer Vision, Image Processing, Model-driven
                                                    Frameworks, Soft Computing</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/12.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raju Bhukya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9700553922 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raju@nitw.ac.in">raju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/15.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P V Subba Reddy</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8790590436<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsr@nitw.ac.in">pvsr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/13.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. U S N Raju</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440149146 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:usnraju@nitw.ac.in">usnraju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/19.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ijjina Earnest Paul</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9494466490<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:iep@nitw.ac.in">iep@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/24.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sujit Das</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8536009758<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sujit.das@nitw.ac.in">sujit.das@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/28.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ramalingaswamy Cherukku</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9773827143 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rmlswamy@nitw.ac.in">rmlswamy@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        {/* <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span> */}
                                    </div>


                                    {/* <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/> 

                                <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5"/> */}





                                </div>






                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </div >
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                 <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default EEEDeptPage;