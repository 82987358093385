// MilestonesTimeline.js
import React, { useState } from 'react';
import '../css/MilestonesTimeline.css';

const milestonesData = [
    {
        date: "October 10, 1959",
        description: "Pandit Jawaharlal Nehru laid foundation stone for the College, the first in the chain of NITs (formerly RECs) in the country."
    },

    {
        date: "November 10, 1959",
        description: "Registration of the Society of Regional Engineering College, Warangal."
    },
    {
        date: "August, 1964",
        description: "Commencement of UNESCO assistance project."
    },
   
    {
        date: "October, 1972",
        description: "Change of affiliation of the College from Osmania University to Jawaharlal Nehru Technological University. Academic autonomy conferred on the College."
    },

    {
        date: "August 19, 1976",
        description: "Change of affiliation of the College from JNTU to Kakatiya University."
    },

    {
        date: "August, 1981",
        description: "Admission for B.Tech. through Common Entrance Examination for students from Andhra Pradesh."
    },


    {
        date: "June, 1987",
        description: "	Establishment of Entrepreneurship Development Cell."
    },


    {
        date: "January, 1990",
        description: "The College is selected for assistance under UK-INDIA Project."
    },

    {
        date: "April, 1994",
        description: "Commencement of UK - INDIA RECs Project."
    },

    {
        date: "January, 1995",
        description: "Campus LAN installed. E-Mail facility provided by dial up connectivity through Central University, Hyderabad."
    },

    {
        date: "October, 1996",
        description: "Commissioning of a dedicated Internet connectivity through VSAT dish. Browsing and E-mail facility provided."
    },

    {
        date: "November, 1996",
        description: "The AICTE recommends to the M.H.R.D and U.G.C the award of the Deemed to be University Status to REC Warangal."
    },
    {
        date: "July 20, 2001",
        description: "AICTE grants the status of QIP (Quality Improvement Programme) Centre to the following departments:(i)Ph.D. Programme – Departments of Mechanical Engineering, Chemical Engineering and Mathematics(ii)M.Tech. Programme – Departments of Electrical Engineering and the Department of Electronics and Communication Engineering.  "
    },


    {
        date: "September, 2001",
        description: "Inauguration of MSIT course in A.P. by the Hon’ble Chief Minister of Andhra Pradesh at Hyderabad with a Learning Centre at Rec, Warangal."
    },

    {
        date: "July, 2002",
        description: "Board of Governors resolved to approve the new Memorandum Of Association (MOA) for conversion of Regional Engineering College, Warangal as National Institute of Technology (NIT), Warangal."
    },

    {
        date: "September, 2002",
        description: "Institute renamed as National Institute of Technology, Warangal and offered the status of Deemed University."
    },

    {
        date: "July, 2003",
        description: "Commencement of admission of students for B.Tech through AIEEE stream."
    },

    {
        date: "August 4, 2003",
        description: "Prof. Deba Kumar Tripathy takes charge as the first Director of NIT Warangal."
    },

    {
        date: "August, 2003",
        description: "Change over to IIT Pattern in Curriculum: Revision of syllabi and rules of regulations for the UG and PG courses and introduction of semester system for B.Tech and MBA at the first year level. Change over of evaluation pattern to Credits and GPA system."
    },

    {
        date: "June, 2004",
        description: "The Institute receives an assistance of Rs. 11.41 crores from the world bank under TEQIP (Technical Education Quality Improvement Programme)."
    },

    {
        date: "March 1, 2004",
        description: "Ist Convocation of NIT Warangal. Prof. S.K. Joshi, Chairman, Board of Governors, IIT Roorkee and Former Director General, CSIR delivers the First Convocation address."
    },


    {
        date: "January 22, 2005",
        description: "2nd Convocation of NIT Warangal. Dr. Anil Kakodkar, Chairman, Atomic Energy Commission and Secretary, Department of Atomic Energy, Government of India delivers the Second Convocation address."
    },


    {
        date: "December 7, 2006",
        description: "NIT, Warangal entered into MoU with National Academy of Construction (NAC), Hyderabad with regard to implementation of academic continuing education and training programmes in construction engineering of mutual interest and benefit."
    },


    {
        date: "January 3, 2007",
        description: "Inauguration of Centre for Rural Development building by Prof. Y.V.Rao, Director, NITW"
    },


    {
        date: "January 15, 2007",
        description: "International Kalam Guest House was inaugurated by Prof. K.Koteswara Rao, former Principal, RECW"
    },

    {
        date: "January 18, 2007",
        description: "Foundation stone laid for Centre for Automation & Instrumentation by Prof. R.A. Yadav, Vice-Chairman, AICTE, New Delhi"
    },


    {
        date: "August 24, 2007",
        description: "Inauguration to initiate concrete activity for the construction of Mega Hostel."
    },

    {
        date: "January 25, 2008",
        description: "Dr. Abdul Kalam, Former President visits Campus during TECHNOZION Honourable Minister Purandeswari, MHRD inaugurates New Computer Centre and lays foundation stone for ultra Mega Hostel."
    },


    {
        date: "August 18, 2008",
        description: "Halls of Residence 1600 student’s capacity (Ultra-Mega Hostel) Bhoomipuja."
    },

    {
        date: "October 2, 2008",
        description: "Inauguration of Renovated Administrative Block with the State of the Art facilities and MIS."
    },

    {
        date: "December 12 - 14, 2008",
        description: "Inauguration of Renovated Administrative Block with the State of the Art facilities and MIS."
    },


    {
        date: "January 21, 2009",
        description: "Bhoomi Puja:- 1) Girls Hostel (350 capacity) – by Shri Ashok Thakur, Addl. Secretary, MHRD, New Delhi 2) Campus Landscaping."
    },


    {
        date: "March 3, 2009",
        description: "The MHRD, New Delhi constituted CCB-2009 with Director, NIT, Warangal as its Chairman and NIT, Warangal as its Headquarters."
    },

    {
        date: "June 13, 2009",
        description: "NIT, Warangal has been selected as a “Leading Technology Adopter of the year 2009” (It is an award for excellence in Technology Adopter in the Education Sector)."
    },


    {
        date: "June 22, 2009",
        description: "Inauguration of INSPIRE programme by Dr. N.R. Shetty, Former Vice-Chancellor, Bangalore University."
    },


    {
        date: "December 28, 2009",
        description: "Inauguration of Dr. V.A. Sastry Innovation and Development Centre at the Institute."
    },


    {
        date: "April 11, 2010",
        description: "Dr. S.S. Gokhale, Director, VNIT, Nagpur has taken over charge as Director (Addl. Charge) of the Institute."
    },


    {
        date: "May 27, 2011",
        description: "Prof. T. Srinivasa Rao, assumed charge as the Director of the Institute. It is a happy and momentous coincidence that he is an alumnus of this Institute."
    },



    {
        date: "October 26, 2012",
        description: "Dr. Krishna M. Ella takes charge as the Chairman, Board of Governors, NITW."
    },

    {
        date: "October 23 , 2017",
        description: "Prof. N.V. Ramana Rao has taken over the charge of director NIT warangal."
    },



    {
        date: "Sep 10, 2018",
        description: "Diamond Jubilee Celebrations."
    },

    // {
    //     date: "October 28, 2022",
    //     description: "Prof. N.V. Ramana Rao, has assumed the temporary charge of the post of Director of NIT, Warangal"
    // },

    {
        date: "April 27, 2023",
        description: "Prof. Bidyadhar Subudhi from IIT Goa has taken over charge of the post of Director, NIT Warangal on 27.04.2023. (F.N.)"
    },


    // Add your other milestones here



    
];

const MilestonesTimeline = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <div className="milestones-container">
            <h1 className="milestones-title">Milestones Timeline</h1>
            <div className="milestones-list">
                {milestonesData.map((milestone, index) => (
                    <div
                        className={`milestone-box ${index === hoveredIndex ? 'hovered' : ''}`}
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        {index !== milestonesData.length - 1 && (
                            <div className="arrow"></div>
                        )}
                        <div className="milestone-content">
                            <div className="milestone-date">{milestone.date}</div>
                            <div className="milestone-description">{milestone.description}</div>
                        </div>
                        {index !== milestonesData.length - 1 && (
                            <div className="connector"></div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MilestonesTimeline;
