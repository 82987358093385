import { Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sampleNotificationsData } from "../constants/sampleAnnouncements";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import "../components/Styles.css";
import { getCategoryWiseNotifications } from "../utils/getDeptData";
import Loading from "../components/Loading";
import AnnouncementItem from "../components/AnnouncementItem";
import { MainServerUrl } from "../BasePath";
import { useLocation } from 'react-router-dom'
import WorkshopCalender from "../components/WorkshopCalender";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);
const NotificationPage = (props) => {
  const location = useLocation()
  const { start = 0 } = location.state || {};
  if(start > 2 || start < 0)start = 0;
  // console.log(start);
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState();
  const [tab, setTab] = useState(start);
  const [filteredData, setFilteredData] = useState();
  const [showArchieve, setShowArchieve] = useState(false);

  function compare(a, b) {
    if (a.start_date > b.start_date) {
      return -1;
    }
    if (a.start_date < b.start_date) {
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    if (data !== undefined) {
      setFilteredData(data);
      setIsReady(true);
    }
  }, [data]);
  useEffect(() => {
    if (!data) return;
    console.log(showArchieve);
    const fData = {};
    const now = new Date();
    fData.news = data.news.filter(
      (ele) =>
        !showArchieve ||
        (new Date(ele.end_date).getTime() > now.getTime() && ele.is_published)
    );
    fData.workshops = data.workshops.filter(
      (ele) =>
        !showArchieve ||
        (new Date(ele.end_date).getTime() > now.getTime() && ele.is_published)
    );
    fData.tenders = data.tenders.filter(
      (ele) =>
        !showArchieve ||
        (new Date(ele.end_date).getTime() > now.getTime() && ele.is_published)
    );
    setFilteredData(fData);
    console.log(fData);
  }, [showArchieve, data]);

  useEffect(() => {
    // const controller = new AbortController();
    // const id = setTimeout(() => controller.abort(), 5000);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // signal: controller.signal,
    };
    fetch(`${MainServerUrl}/api/notification/getOnesAlso`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("response is not ok");
        }
        if (response.status !== 200) {
          throw new Error("Status code is not 200");
        }
        return response.json();
      })
      .then((data) => setData(getCategoryWiseNotifications(data)))
      .catch((err) => {
        console.log("falling to alternate server");
        fetch(
          `https://insti-web-backend.herokuapp.com/api/notification/getOnesAlso`,
          requestOptions
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("response is not ok");
            }
            if (response.status !== 200) {
              throw new Error("Status code is not 200");
            }
            return response.json();
          })
          .then((data) => setData(getCategoryWiseNotifications(data)))
          .catch((err) => {
            console.log(err);
          });
      });
      // .finally(() => clearTimeout(id));
  }, []);
  const allNotifications = sampleNotificationsData;
  const typeOfNotifications = props.typeOfNotifications || "some title";
  if (!allNotifications || !typeOfNotifications) return "";
  return (
    <React.Fragment>
      {isReady ? (
        <ThemeProvider theme={theme}>
          <Grid container direction="column" md={8} xs={10} margin="auto">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked
                    value={showArchieve}
                    onChange={() => setShowArchieve(!showArchieve)}
                  />
                }
                label="Show Archieves"
              />
            </FormGroup>
            <Grid style={{ backgroundColor: "brown", height: "5px" }}></Grid>
            <Grid item container direction="row" margin="1vh">
              <Grid item md={4} xs={8} style={{ margin: "auto" }}>
                <Typography
                  variant="h4"
                  textAlign="center"
                  style={tab === 0 ? {} : { opacity: "0.5", cursor: "pointer" }}
                  onClick={() => setTab(0)}
                >
                  News
                </Typography>
              </Grid>
              <Grid item md={4} xs={8} style={{ margin: "auto" }}>
                <Typography
                  variant="h4"
                  textAlign="center"
                  style={tab === 1 ? {} : { opacity: "0.5", cursor: "pointer" }}
                  onClick={() => setTab(1)}
                >
                  Workshops
                </Typography>
              </Grid>
              <Grid item md={4} xs={8} style={{ margin: "auto" }}>
                <Typography
                  variant="h4"
                  textAlign="center"
                  style={tab === 2 ? {} : { opacity: "0.5", cursor: "pointer" }}
                  onClick={() => setTab(2)}
                >
                  Tenders
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ backgroundColor: "brown", height: "3px" }}></Grid>

            
            {isReady &&
              tab === 0 &&
              filteredData.news.sort(compare).map((item) => {
                return <AnnouncementItem item={item} />;
              })}
            {isReady &&
              tab === 2 &&
              filteredData.tenders.sort(compare).map((item) => {
                return <AnnouncementItem item={item} />;
              })}
              <WorkshopCalender/>
              {isReady &&
              tab === 1 &&
              filteredData.workshops.sort(compare).map((item) => {
                return <AnnouncementItem item={item} />;
              })}
          </Grid>
        </ThemeProvider>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  );
};

export default NotificationPage;
