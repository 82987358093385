import { useState } from "react";
import '../css/cseDeptStyle.css';


const IhcDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-Awards");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div class="dept-wrapper">
            <div class="container">
                <section class="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" class="img-fluid"/>*/}
                        <img
                            src="https://nitw.ac.in/api/static/files/IHC_2024-5-6-11-7-31.jpg" class="img-fluid" />
                    </header>
                    <div class="row">
                        <div class="col-md-3">
                            {/* Tabs nav */}
                            <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a class="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a class={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected={activeTab === 'v-pills-Awards' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Awards' ? '#c20315' : '#00FFFF' }}> <span>About</span> </a>



                                <a class={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected={activeTab === 'v-pills-Faculty' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Faculty' ? '#c20315' : '#00FFFF' }}>
                                    <span>People </span>
                                </a>
                                <a class={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected={activeTab === 'v-pills-visionandmission' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-visionandmission' ? '#c20315' : '#00FFFF' }}>
                                    <span>Facilities</span>
                                </a>


                                <a class={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected={activeTab === 'v-pills-AcademicResearchGroup' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-AcademicResearchGroup' ? '#c20315' : '#00FFFF' }}> <span>Timing</span>
                                </a>

                                <a class={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected={activeTab === 'v-pills-Students' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Students' ? '#c20315' : '#00FFFF' }}>
                                    <span>Empanelled Hospitals </span>
                                </a>

                                <a class={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected={activeTab === 'v-pills-NotableAlumni' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-NotableAlumni' ? '#c20315' : '#00FFFF' }}>
                                    <span>Various Activities</span>
                                </a>
                                <a class={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected={activeTab === 'vv-pills-academics' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-academics' ? '#c20315' : '#00FFFF' }}>
                                    <span>Notifications/Advisory</span>
                                </a>



                                {/*<a class="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                {/* <a class={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Remote Access Service</span>
                                </a>
                                <a class={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>General Rules</span> </a> */}


                                {/* <a class={`nav-link ${activeTab === 'v-pills-AYL' ? 'active' : ''}`} id="v-pills-labs-AYL" data-toggle="pill" onClick={() => handleTabClick('v-pills-AYL')} role="tab"
                                    aria-controls="v-pills-AYL" aria-selected="false">
                                    <span>Ask your Librarian</span>
                                </a> */}

                                {/* <a class={`nav-link ${activeTab === 'v-pills-FIS' ? 'active' : ''}`} id="v-pills-labs-FIS" data-toggle="pill" onClick={() => handleTabClick('v-pills-FIS')} role="tab"
                                    aria-controls="v-pills-FIS" aria-selected="false">
                                    <span>Faculty Information System</span>
                                </a>


                                <a class={`nav-link ${activeTab === 'v-pills-E-Books' ? 'active' : ''}`} id="v-pills-labs-E-Books" data-toggle="pill" onClick={() => handleTabClick('v-pills-E-Books')} role="tab"
                                    aria-controls="v-pills-E-Books" aria-selected="false">
                                    <span>New E-Books</span>
                                </a> */}





                            </div>
                            <div class="head_of_dept"> <a href="#"
                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Dr._Radha_Rukmini_Ganneni_2024-5-7-17-38-9.jpg" alt="" class="img-fluid" width="190" height="220" />
                                <h6>Dr. Radha Rukmini Ganneni</h6>
                            </a>
                                <p class="justified-text">Head, Insitute Health Centre <br /> <a href="mailto:radharukmini@nitw.ac.in"
                                    style={{ color: "#808080" }}>radharukmini@nitw.ac.in</a> <br /> 9866994444 </p>
                            </div>
                        </div>
                        <div class="col-md-9">
                            {/* Tabs content */}
                            <div class="tab-content" id="v-pills-tabContent">
                                {/*<div class="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div class={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'}  p-2`} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div class="intro-section">
                                        {/* <h4 class=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                                        <h3 align='center'> Ambulance</h3>
                                        <p style={{ textAlign: 'justify' }}>
                                            Ambulance service is provided 24X7. Ambulance is equipped with oxygen cylinder, nebulizer and first aid box with routine medicines, essential life saving drugs.
                                            <br></br>
                                            {/* <img
                                                src="https://nitw.ac.in/api/static/files/Ambulance_2023-5-23-15-21-35.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '450px' }} // Customize width and height
                                            /> */}
                                            <table style={{ padding: "0px, 15px" }}>
                                                    <tbody>


                                                        {/* Add your table rows here */}
                                                        <tr>

                                                            <td><img src="https://nitw.ac.in/api/static/files/Ambulance_2024-5-21-18-30-13.jpg" alt="Hospital Image 1" width="400" height="300" /></td>
                                                            <td><img src="https://nitw.ac.in/api/static/files/Ambulance_2024-5-21-18-28-52.jpeg" alt="Hospital Image 1" width="400" height="300" /></td> {/* Add the image */}
                                                        </tr>

                                                        {/* ...add more rows as needed... */}
                                                    </tbody>
                                                </table><br />


                                            <br></br>
                                            <br></br>
                                            Ambulance can be contacted from the Phone numbers given below.<br></br>
                                            <b>Phone:</b> 0870-2462099<br></br>
                                            <b>Mobile:</b> 9491065006<br></br>
                                            Emergency phone number is displayed in all buildings and hostels.

                                            <br></br>
                                            <br></br>
                                            <h3 align='center'>Biochemistry Analyzer and Blood Mixer</h3>
                                            <br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/Biochemistry_Analyzer_and_Hematology_Analyzer_2023-5-23-13-5-11.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '400px' }} // Customize width and height
                                            /><br></br>
                                            <br></br>

                                            <h3 align='center'>Hematology Analyzer, Urine Analyze and R-8C Laboratory Centrifu</h3><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/R-8C_Laboratory_Centrifu_2023-5-23-13-14-5.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '400px' }} // Customize width and height // Customize width and height
                                            />
                                            <br></br>

                                            {/* We have a Five bed facility for observation and emergency first aid of patients.<br></br><br></br> */}

                                            <h3 align='center'>Physiotherapy Equipments</h3>

                                            {/* The physiotherapy unit is equipped with all the latest modalities. tens muscle stimulator machine, interferential therapy, Ultra sound therapy machine, hydro collator packs. */}



                                            <h3 align='center'>Tens Muscle Stimulator Machine and Interferential Therapy</h3><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/Tens_Muscle_Stimulator_Machine_2023-5-23-15-14-44.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '400px' }} // Customize width and height // Customize width and height
                                            />


                                            <br></br><br></br>
                                            <h3 align='center'>
                                                Ultra Sound Therapy Machine, Hydro Collator Pack and D-Trac and Mobilization Machine</h3> <br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/Machine_2023-5-23-15-18-53.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '400px' }} // Customize width and height // Customize width and height
                                            />

                                            <br></br><br></br>

                                            {/* We have Two physiotherapists from 11am to 6pm. The physiotherapy unit has all the electrotherapy modalities and exercise therapy for rehabilitation and chronic and acute pain management.
                                            <br></br>


                                            <b>Waste Disposal:</b><br></br>

                                            We are also following the rules of Bio medical waste as per Ministry of Environment and Forests.

                                            <br></br>
                                            <b>Medical Screening:</b><br></br>

                                            During admission process, medical screening is being handled by Health Centre for all the students.
                                            <br></br><br></br>

                                            <b>Various clinical activities and camps undertaken in 2019 are as Follows:</b><br></br>

                                            1. Medical service is provided at the hostel itself for first 3 months to make the stay of students comfortable and safe including Saturday, Sunday, festivals.<br></br>

                                            2. Induction lecture and psychology counseling for the students with interactive session.<br></br>

                                            3. Muddunur camp: Around 700 village patients are benefited with the camp in a village near Warangal, which is the village adopted by NITWarangal with free distribution of medicines.
                                            <br></br>
                                            4. Cardiology camp around 110 patients have undergone cardiology consultation and 2Decho is done for the patients who needed.

                                            <br></br> */}



                                            <br></br>


                                        </p>



                                    </div>

                                </div>










                                <div class={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade    p-2" id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 class="mb-4">Academic Programmes</h4>*/}
                                    {/*<div class="row">
  <div class="col-md-12">
    <h3 class="mb-3">PhD</h3>
  </div>
</div>*/}
                                     <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="bar" >
                                                <a href="https://nitw.ac.in/api/static/files/Advisory_on_recent_covid_-19_variant_2023-12-20-14-46-52.pdf" target="_blank" className="scroll-card-wrapper flex newmarquee"> Institute Health Centre: Advisory on recent covid -19 variant  </a>
                                                <a href="https://nitw.ac.in/api/static/files/CONJUNCTIVITIS_2023-7-4-11-47-11.pdf" target="_blank" className="scroll-card-wrapper flex newmarquee">This Monsoon Protect Your Eyes from ”CONJUNCTIVITIS” - Insitute Health Centre(IHC) NITW  </a>
                                                <a href="https://nitw.ac.in/api/static/files/Dengue_2023-7-23-15-49-11.pdf" target="_blank" className="scroll-card-wrapper flex newmarquee"> Health Advisory on Dengue fever - Insitute Health Centre(IHC) NITW </a>
                                                <a href="https://nitw.ac.in/api/static/files/Corona_Virus_2024-5-11-18-3-20.pdf" target="_blank" className="scroll-card-wrapper flex newmarquee">Corona Virus and preventive measures</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>




                                <div class={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div class="row laboratory-facilities">
                                        <div class="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 class="" align='center'>Remote Access Service</h3>
                                            </header>

                                            <p class="justified-text">Central Library, NITW introduced a new service called "Remote Access Services - MyLOFT (My Library On Fingertips)" for the benefit of Research Scholars and Faculty. MyLOFT is a mobile App. Through MyLOFT research scholars and faculty can access e-resources subscribed by the NITW anywhere and anytime. Hence, all the P.G. students, Ph.D. students and faculty are requested to utilize the facility to enhance the quality Engineering Education and Research.
                                                <br></br>
                                                <br></br>
                                                <b> Remote Access Services Request Form: </b>
                                                <a href="https://forms.gle/3UxbXJ3q9MEqY3PCA">
                                                    https://forms.gle/3UxbXJ3q9MEqY3PCA </a></p>


                                        </div>

                                    </div>
                                </div>






                                <div class={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade   p-2`} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 class=" mb-4">Phd</h4> <a href="" target="_blank"> <span class="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i class="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div class={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            style={{ backgroundColor: peopleTab === 'Faculty' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("Faculty") }}>Medical Officers</a>


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty"
                                            style={{ backgroundColor: peopleTab === 'AdjunctFaculty' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}>Visiting Doctors</a>


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff"
                                            style={{ backgroundColor: peopleTab === 'NonTeachingStaff' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>
                                            Staff</a>
                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars"
                                            style={{ backgroundColor: peopleTab === 'ResearchScholars' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Medical Management Team</a>


                                        {/* 
                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a> */}


                                    </div>



                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent" style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel" aria-labelledby="nav-faculty-tab">
                                            <section id="profile-info">
                                                <div className="container" style={{ display: 'grid', gridTemplateColumns: '209px auto', alignItems: 'start', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                    <div className="left" style={{ marginRight: '10px' }}>
                                                        <img src="https://nitw.ac.in/api/static/files/Dr._Radha_Rukmini_Ganneni_2024-5-7-17-38-9.jpg" alt="Photo" style={{ width: '209px', height: '209px', borderRadius: '50%' }} />
                                                    </div>
                                                    <div className="right">
                                                        <h6>Dr. Radha Rukmini Ganneni</h6>
                                                        <p>(Senior Medical Officer)</p>
                                                        <p><strong>Qualification</strong>: M.B.B.S, Diploma in Anaesthesiology</p>
                                                        <p className="contact-info">
                                                            <i className="fa fa-phone" aria-hidden="true"></i> 0870-2452091<br />
                                                            <i className="fa fa-mobile fa-lg" aria-hidden="true"></i> 9490165359, 9866994444 <br />
                                                            <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:radharukmini@nitw.ac.in">radharukmini@nitw.ac.in</a>
                                                        </p>
                                                    </div>
                                                </div>



                                                <div className="container" style={{ display: 'grid', gridTemplateColumns: '209px auto', alignItems: 'start', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                    <div className="left" style={{ marginRight: '10px' }}>
                                                        <img src="https://nitw.ac.in/api/static/files/Dr._Chintala_Karthik_2024-5-17-18-23-24.jpg" alt="Photo" style={{ width: '209px', height: '209px', borderRadius: '50%' }} />
                                                    </div>
                                                    <div className="right">
                                                        <h6>Dr. Chintala Karthik</h6>
                                                        <p>(Medical Officer)</p>
                                                        <p><strong>Qualification</strong>: M.B.B.S (SVS Medical college Mahabubnagar), in the year 2015</p>
                                                        <p className="contact-info">
                                                            <i className="fa fa-phone" aria-hidden="true"></i> 0870-2452090<br />
                                                            <i className="fa fa-mobile fa-lg" aria-hidden="true"></i> 8332969468, 9676075075  <br />
                                                            <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:karthik@nitw.ac.in">karthik@nitw.ac.in</a>
                                                        </p>
                                                    </div>
                                                </div>


                                                <div className="container" style={{ display: 'grid', gridTemplateColumns: '209px auto', alignItems: 'start', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                    <div className="left" style={{ marginRight: '10px' }}>
                                                        <img src="https://nitw.ac.in/api/static/files/Dr._Pradeep_Dodda_2024-5-17-18-23-52.jpg" alt="Photo" style={{ width: '209px', height: '209px', borderRadius: '50%' }} />
                                                    </div>
                                                    <div className="right">
                                                        <h6>Dr. Pradeep Dodda</h6>
                                                        <p>(Medical Officer)</p>
                                                        <p><strong>Qualification</strong>: M.B.B.S (Guntur Medical College Guntur), in the year 2013</p>
                                                        <p className="contact-info">
                                                            <i className="fa fa-phone" aria-hidden="true"></i> 0870-2452096<br />
                                                            <i className="fa fa-mobile fa-lg" aria-hidden="true"></i> 8332969354, 9550680609  <br />
                                                            <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:pradeep@nitw.ac.in">pradeep@nitw.ac.in</a>
                                                        </p>
                                                    </div>
                                                </div>



                                                <div className="container" style={{ display: 'grid', gridTemplateColumns: '209px auto', alignItems: 'start', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                    <div className="left" style={{ marginRight: '10px' }}>
                                                        <img src="https://nitw.ac.in/api/static/files/Dr._Anchoori_Karthik_2024-5-17-18-24-16.jpg" alt="Photo" style={{ width: '209px', height: '209px', borderRadius: '50%' }} />
                                                    </div>
                                                    <div className="right">
                                                        <h6>Dr. Anchoori Karthik</h6>
                                                        <p>(Medical Officer)</p>
                                                        <p><strong>Qualification</strong>: M.B.B.S (Chalmeda Anand Rao Institute of Medical Sciences Karimnagar), in the year 2018</p>
                                                        <p className="contact-info">
                                                            <i className="fa fa-phone" aria-hidden="true"></i> 0870-2452098<br />
                                                            <i className="fa fa-mobile fa-lg" aria-hidden="true"></i> 8332969392, 8886004167  <br />
                                                            <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:karuanchoori@nitw.ac.in">karuanchoori@nitw.ac.in</a>
                                                        </p>
                                                    </div>
                                                </div>



                                            </section>
                                        </div>
                                     <div   class={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                    aria-labelledby="nav-AdjunctFaculty-tab">
                                         

                                    <div class="col-md-12 profile-info-individual">
                                        <div class="row">
                                            <table className="table table-bordered">
                                                <tbody>

                                                    <tr>
                                                        <td>
                                                            Doctor
                                                        </td>
                                                        <td>
                                                            Specialist
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Dr. B. Jagadeesh Babu
                                                        </td>
                                                        <td>
                                                            Psychiatrist
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Dr. B. Sandhya Rani
                                                        </td>
                                                        <td>
                                                            Gynaecologist
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Dr. Sudhakar Reddy
                                                        </td>
                                                        <td>
                                                            Cardiologist
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Dr. G. Vidya Reddy
                                                        </td>
                                                        <td>
                                                            Dermatologist
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Dr. J. Sowmya
                                                        </td>
                                                        <td>
                                                            Pulmonologist (chest Physician)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Dr. P. Prathik
                                                        </td>
                                                        <td>
                                                            Dental Doctor
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Dr. P. Sumanth
                                                        </td>
                                                        <td>
                                                            Paediatrician
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>








                                        </div>
                                    </div>


                                </div>




















                                        <div class={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div class="row academic_group">
                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/K._Rameeja_Bee_2024-5-8-11-19-24.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">K. Rameeja Bee</h6> <span>Technical assistant(Staff Nurse)</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 8332969240 <br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rameejabee@nitw.ac.in">rameejabee@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/Y._Bikshapathy_2024-5-8-11-18-42.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">Y. Bikshapathy</h6> <span>Junior Assistant (UG)</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 8332969306 <br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bixapathi@nitw.ac.in">bixapathi@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/Inqushaf_Ali_Md._2024-5-8-11-17-59.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">Md. Inqushaf Ali</h6> <span>Junior Assistant</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 8332969543<br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:inqushafali@nitw.ac.in">inqushafali@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/M._Rajanna_2024-5-8-11-13-46.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">Rajanna M</h6> <span>Senior Assistant</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 8332969552<br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mraju0353@nitw.ac.in">mraju0353@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>








                                            </div>
                                        </div>
                                        <div class={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">

                                            <div class="row phd-std-row">
                                            <table className="table table-bordered">
                                                <tbody>

                                                    <tr>
                                                        <td>
                                                            Name
                                                        </td>
                                                        <td>
                                                           Position
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        Prof. N. Narsaiah
                                                        </td>
                                                        <td>
                                                        Chairperson
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        Shri. G. Ramesh
                                                        </td>
                                                        <td>
                                                        Member
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        Dr. G. Radha Rukmini
                                                        </td>
                                                        <td>
                                                        Member and convener 
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        Prof. P. Hari Prasad Reddy
                                                        </td>
                                                        <td>
                                                        Member
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        Ms. Billu Shilpa
                                                        </td>
                                                        <td>
                                                        Member 
                                                        </td>
                                                    </tr>
                                                   
                                                </tbody>
                                            </table>


                                            </div>


                                        </div>







                                    </div>
                                </div>
                                <div class={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div class="row std-society">
                                        <div class={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                            aria-labelledby="v-pills-Students-tab">
                                            <div class="row std-society">
                                                <div class="col-md-3 col-6">

                                                </div>
                                                <div class="col-md-3 col-6">

                                                </div>


                                                <h4>The list of empanelled hospitals from 01.07.2023 to 30.06.2024:</h4><br />


                                                <h5 className="pt-2">1. Super Speciality Hospitals at warangal</h5><br />


                                                <table style={{ padding: "0px, 15px" }}>
                                                    <tbody>


                                                        {/* Add your table rows here */}
                                                        <tr>

                                                            <td>Rohini Super Specialty Hospital<img src="https://nitw.ac.in/api/static/files/Rohini_Super_Specialty_Hospital_2024-5-16-16-42-24.jpg" alt="Hospital Image 1" width="400" height="300" /></td>
                                                            <td>Samraksha Super Specialty Hospital<img src="https://nitw.ac.in/api/static/files/Samraksha_Super_Specialty_Hospital_2024-5-16-17-18-33.jpg" alt="Hospital Image 1" width="400" height="300" /></td> {/* Add the image */}
                                                        </tr>

                                                        {/* ...add more rows as needed... */}
                                                    </tbody>
                                                </table><br />



                                                <h5 className="pt-2">2. General Hospitals at Warangal</h5>


                                                <table>
                                                    <tbody>


                                                        {/* Add your table rows here */}
                                                        <tr>

                                                            <td>Jaya Hospital<img src="https://nitw.ac.in/api/static/files/Jaya_Hospital_2024-5-16-17-24-11.jpg" alt="Hospital Image 1" width="400" height="300" /></td>
                                                            <td>Guardian Multi-Speciality Hospital<img src="https://nitw.ac.in/api/static/files/Guardian_Multi-Speciality_Hospital_2024-5-16-17-29-18.jpg" alt="Hospital Image 1" width="400" height="300" /></td> {/* Add the image */}
                                                        </tr>
                                                        <tr>

                                                            <td>Max Care Hospitals<img src="https://nitw.ac.in/api/static/files/Max_Care_Hospitals_2024-5-16-17-33-12.jpg" alt="Hospital Image 1" width="400" height="300" /></td>
                                                            <td>Pramoda Hospital<img src="https://nitw.ac.in/api/static/files/Pramoda_Hospital_2024-5-16-17-36-26.jpg" alt="Hospital Image 1" width="400" height="300" /></td> {/* Add the image */}
                                                        </tr>
                                                        <tr>

                                                            <td>Sharat Laser Eye Hospital<img src="https://nitw.ac.in/api/static/files/Sharat_Laser_Eye_Hospital_2024-5-16-17-39-51.jpg" alt="Hospital Image 1" width="400" height="300" /></td>
                                                            <td>Bharathi Nursing Home<img src="https://nitw.ac.in/api/static/files/Bharathi_Nursing_Home_2024-5-16-17-42-11.jpg" alt="Hospital Image 1" width="400" height="300" /></td> {/* Add the image */}
                                                        </tr>

                                                        <tr>

                                                            <td>Sri Laxmi Narasimha Hospital<img src="https://nitw.ac.in/api/static/files/Sri_Laxmi_Narasimha_Hospital_2024-5-16-17-45-28.jpg" alt="Hospital Image 1" width="400" height="300" /></td>

                                                        </tr>


                                                        {/* ...add more rows as needed... */}
                                                    </tbody>
                                                </table><br />













                                                <h5 className="pt-2">3. Super Speciality Hospitals at Hyderabad</h5>




                                                <table>
                                                    <tbody>


                                                        {/* Add your table rows here */}
                                                        <tr>

                                                            <td>Basavatarakam Indo American Cancer Hospital
                                                                <img src="https://nitw.ac.in/api/static/files/Basavatarakam_Indo_American_Cancer_Hospital_&_Research_Institute_2024-5-16-17-57-26.jpg" alt="Hospital Image 1" width="400" height="300" /></td>
                                                            <td>Krishna Institute of Medical Sciences Ltd.<img src="https://nitw.ac.in/api/static/files/Krishna_Institute_of_Medical_Sciences_Ltd._2024-5-16-18-1-37.jpg" alt="Hospital Image 1" width="400" height="300" /></td> {/* Add the image */}
                                                        </tr>
                                                        <tr>

                                                            <td>Sunshine Hospitals
                                                                <img src="https://nitw.ac.in/api/static/files/Sunshine_Hospitals_2024-5-16-18-6-3.jpg" alt="Hospital Image 1" width="400" height="300" /></td>
                                                            <td>CARE Super Speciality Hospitals<img src="https://nitw.ac.in/api/static/files/CARE_Super_Speciality_Hospitals_2024-5-16-18-7-51.jpg" alt="Hospital Image 1" width="400" height="300" /></td> {/* Add the image */}
                                                        </tr>

                                                        {/* ...add more rows as needed... */}
                                                    </tbody>
                                                </table><br /><br />




                                                <h5 className="pt-2">4. Empanelled Hospitals list for Students at Warangal</h5>


                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th>S.NO</th>
                                                            <th>NAME OF HOSPITAL</th>
                                                        </tr>

                                                        {/* Add your table rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>M/s Jaya Hospitals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>M/s Rohini Medicare (Pvt.) Ltd.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>M/s Pramoda Hospital</td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>M/s Bharathi Nursing Home</td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>M/s CARE Hospitals<br></br> (Quality Care India Limited)</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6</td>
                                                            <td>M/s KIMS Hospitals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>7</td>
                                                            <td>M/s KIMS-Sunshine Hospitals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>8</td>
                                                            <td>M/s Basavatarakam Indo-American Cancer Hospital & Research Institute</td>
                                                        </tr>
                                                        <tr>
                                                            <td>9</td>
                                                            <td>M/s Sharat Maxivision Super Speciality Eye Hospitals<br></br>(Dr. Sharat Maxivision Eye Hospitals LLP)</td>
                                                        </tr>
                                                        <tr>
                                                            <td>10</td>
                                                            <td>M/s Samraksha Super Speciality Hospital</td>
                                                        </tr>
                                                        <tr>
                                                            <td>11</td>
                                                            <td>M/s Ekashilaa Hospitals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>12</td>
                                                            <td>M/s Guardian Multi Speciality Hospital</td>
                                                        </tr>
                                                        <tr>
                                                            <td>13</td>
                                                            <td>M/s Laxmi Narasimha Hospital</td>
                                                        </tr>
                                                        <tr>
                                                            <td>14</td>
                                                            <td>M/s Ajara Hospitals</td>
                                                        </tr>
                                                        {/* ...add more rows as needed... */}
                                                    </tbody>
                                                </table>



                                                <br></br><br></br>


                                                <br></br>


                                                <br></br>



                                            </div>
                                        </div>
                                    </div>
                                </div>

















                                <div class={`tab-panel ${activeTab === 'v-pills-FIS' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-FIS" role="tabpanel"
                                    aria-labelledby="v-pills-FIS-tab">
                                    <div class="row FIS">

                                        {/* <h4 align='center'>Faculty Information System</h4><br></br><br></br> */}
                                        <p class="justified-text">
                                            The Central Library has implemented a new “Faculty Information System” under the Indian Research Information Network System (IRINS) to showcase the academic and research activities of the institute faculty members and provide an opportunity to create a scholarly research network.</p>
                                        <br></br>
                                        <p class="justified-text">
                                            The NITW- Indian Research Information Network System (IRINS) will support the integration of the existing research management system such as HR system, course management, grant management system, institutional repository, Open and commercial citation databases, scholarly publishers, etc. This will be integrated with the academic identities such as ORCID ID, Scopus ID, Research ID, Microsoft Academic ID, and Google Scholar ID for ingesting the scholarly publication from various sources. This Faculty Information System can become the source for the NIRF, NAC and other international raking systems. The Faculty Information System can be accessed by everyone through the following link:
                                        </p>

                                        <p class="justified-text"><b>Website: </b><a href="https://nitw.irins.org/">https://nitw.irins.org/</a></p>



                                    </div>
                                </div>




                                <div class={`tab-panel ${activeTab === 'v-pills-E-Books' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-E-Books" role="tabpanel"
                                    aria-labelledby="v-pills-E-Books-tab">
                                    <div class="row E-Books">

                                        <p class="more"> <a class="more-info-link"
                                            href="https://nitw.ac.in/api/static/files/New_E-Books_2023-1-3-12-9-10.pdf" target="_blank">New E-Books <i class="fa fa-angle-right"></i> </a> </p>


                                    </div>
                                </div>

















                                <div class={`tab-panel ${activeTab === 'v-pills-AYL' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-AYL" role="tabpanel"
                                    aria-labelledby="v-pills-AYL-tab">
                                    <div class="row AYL">
                                        <p class="justified-text">
                                            <img src="https://nitw.ac.in/api/static/files/Ask_a_Libraria_2022-7-22-10-26-13.png" alt="profile-image" class="profile" /><br></br>
                                            Due to the Covid-19 pandemic, the Central library of NIT Warangal was close down for the public. However, we do not want the students and faculty suffer from getting any information or help from the library. Hence, the central library has created an online, real time ‘virtual reference service’ facility in the library webpage. ‘Ask a Librarian’ is a free online web-based service through email.

                                        </p>
                                    </div>
                                </div>


















                                <div class={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div class="row academic_group">
                                        <div class="col-md-12">

                                            <header>
                                                {/* <h4 align='center'>Timings

                                                </h4> */}
                                            </header>
                                        </div>
                                        <p style={{ textAlign: 'justify' }} fontSize='12px'>
                                            <b>On Working Days :</b> 9:00 am to 1:00 pm and 3:00 pm to 7:00 pm. <br></br>
                                            <b>On Saturday and Sunday :</b> 9:30 am to 11:30 am.<br></br><br></br>
                                            <b>Specialist Services:</b><br></br>
                                            The Health Centre has  visiting Doctors in the department of <br />
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th>Specialist</th>
                                                        <th>Day</th>
                                                        <th>Time</th>
                                                    </tr>
                                                    {/* Add your table rows here */}
                                                    <tr>
                                                        <td>Psychiatrist</td>
                                                        <td>Wednesday</td>
                                                        <td>5:00 pm - 6:00 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gynaecologist</td>
                                                        <td>Tuesday</td>
                                                        <td>4:00 pm - 5:00 pm</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Cardiologist</td>
                                                        <td>1<sup>st</sup> Thursday</td>
                                                        <td>10:00 am - 3:00 pm</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Dermatologist</td>
                                                        <td> Every Friday</td>
                                                        <td>4:00 pm - 5:30 pm</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Pulmonologist (Chest Physician)</td>
                                                        <td> Every Monday and Thursday</td>
                                                        <td>4:00 pm - 6:00 pm</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Dental Doctor</td>
                                                        <td> Every Thursday</td>
                                                        <td>3:00 pm - 5:00 pm</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Pediatrician</td>
                                                        <td> 1<sup>st</sup> Wednesday Every month</td>
                                                        <td>10:00 am - 12:00 pm</td>
                                                    </tr>

                                                    {/* ...add more rows as needed... */}
                                                </tbody>
                                            </table>
                                        </p>
                                    </div>
                                </div>


                                



                                <div class={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">

                                    {/* <h3>Web OPAC </h3> */}
                                    <div class="row notable-alumni">

                                        {/* <h3 align='center'>Various Activities</h3><br></br> */}
                                        <img
                                            src="https://nitw.ac.in/api/static/files/BLOOD_DONATION_CAMP_2024-5-17-14-38-52.jpg"
                                            alt="Image 1"
                                            style={{ width: '100%', height: '350px' }} // // Customize width and height
                                        />
                                        <br></br>

                                        <p style={{ textAlign: 'justify' }}>

                                            NIT Warangal Makes a Life-Saving Impact!<br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/BLOOD_DONATION_CAMP_2024-5-17-14-39-24.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                            <br></br>
                                            National Service Scheme (NSS) and the Patriotic Club at NIT Warangal organised a "BLOOD DONATION CAMP," (11th February, 2024) a proactive step to address the critical need for blood. The event was inaugurated by Prof. Bidyadhar Subudhi, Director, NIT Warangal, who commended the NSS and Patriotic Club for their impactful initiative.
                                            Prof. Subudhi expressed his appreciation and hopes that today's event will not only benefit many lives but also inspire others to contribute to this noble cause. Around 400 generous donors participated, making this camp a tremendous success!
                                            A heartfelt thank you to everyone involved for making a difference and saving lives. Let's continue to be the change we wish to see in the world!
                                        </p>

                                        <img
                                            src="https://nitw.ac.in/api/static/files/cardiologist_2024-5-17-14-30-11.jpg"
                                            alt="Image 1"
                                            style={{ width: '100%', height: '350px' }} // // Customize width and height
                                        />
                                        <br></br>

                                        <p style={{ textAlign: 'justify' }}>

                                            Dr. Srinivas Ramaka, cardiologist, has delivered a talk on "out-of-hospital cardiac arrest and survival" (19th - 23rd February, 2024) in an ongoing program sponsored by MSME at NIT, Warangal. After his talk he trained NIT faculty, staff and course participants on CPR procedures.

                                        </p>

                                        <img
                                            src="https://nitw.ac.in/api/static/files/cardiologist_1_2024-5-17-14-30-39.jpg"
                                            alt="Image 1"
                                            style={{ width: '100%', height: '350px' }} // // Customize width and height
                                        />
                                        <br></br>
                                        <img
                                            src="https://nitw.ac.in/api/static/files/camp_v1_2023-5-26-13-34-2.jpg"
                                            alt="Image 1"
                                            style={{ width: '100%', height: '350px' }} // // Customize width and height
                                        />
                                        <br></br>
                                        <p style={{ textAlign: 'justify' }}>
                                            Muddunoor Village Medical Camp: Medical Camp in Muddunoor Village was held on 21st April 2023. 200 people attended and OPD Consultation and free Medicines given.
                                            <br></br>


                                            <div className="image-container" style={{ margin: '40px 0' }}>

                                                <img
                                                    src="https://nitw.ac.in/api/static/files/camp_v2_2023-5-26-13-34-55.jpg"
                                                    alt="Image 1"
                                                    style={{ width: '100%', height: '350px' }} // // Customize width and height
                                                />

                                            </div>


                                            ENT CAMP :  Was organized at the Institute Health Centre by Dr.Gouda Ramesh ENT Center was held 26th May -2023.

                                            Ear Wax removal.

                                            Ear, Nose, Throat Endoscopy.

                                            More than 100 Employees & students utilized the facility.

                                            <img
                                                src="https://nitw.ac.in/api/static/files/ENT_CAMP_2023-5-26-13-37-12.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />

                                            <br></br><br></br>

                                            KANTI VELUGU :

                                            Telangana State Government “Kanti Velugu (Eye Checkup)” Program is being organized at the Institute Health Centre from 17th to 30 April2023.

                                            1. near Vision, 2. Distance Vision, 3. Spectacles given. 1908 Employees & students utilized the facility. 209 Reading glasses provided.
                                            <br></br><br></br>

                                            MEDICAL CAMP -2023

                                            1.EYE CAMP:- Eye Camp was organised at the   Institute Health Centre by Vasan Eye Care on 17th July -2023.
                                            <br></br>
                                            The following services were provided :

                                            1) Specialist Consultation, 2) Vision Screening, 3) Eye Check-up by  Auto Refractometer, 4) Refraction. 150 Employees & students utilized the facility.

                                            <br></br><br></br>

                                            {/* <img
                                                src="https://nitw.ac.in/api/static/files/MEDICAL_CAMP_-2023_v1_2023-7-23-15-58-7.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                            <br></br><br></br> */}
                                            <img
                                                src="https://nitw.ac.in/api/static/files/MEDICAL_CAMP_-2023_v2_2023-7-23-15-58-41.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />

                                            <br></br><br></br>

                                            <img
                                                src="https://nitw.ac.in/api/static/files/MEDICAL_CAMP_-2023_v3_2023-7-23-15-58-58.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />


                                            <br></br>

                                            <h4 align='center'>MEDICAL CAMP -2023</h4><br></br>

                                            Health Check-up Camp : Health Check-up Camp was organized at the   Institute Health Centre by Vijaya Diagnostic Centre Hanamkonda on 14th   July -2023.<br></br>

                                            The following services were provided.<br></br>

                                            1) Blood Pressure, 2) Random Blood Sugar, 3) Heart Rate, 4) Oxygen Saturation (SPO2), 5) Body Fat%, 6) Muscle Mass, 7) Bone Mass, 8) Body Mass Index (BMI),

                                            9) DCI/BMR, 10) Metabolic Age, 11) Body water %, 12) Visceral Fat, 250 Employees & students utilized the facility.<br></br>


                                            <br></br>

                                            <img
                                                src="https://nitw.ac.in/api/static/files/MEDICAL_CAMP_-2023_v4_2023-7-23-15-59-54.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />


                                            <br></br><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/MEDICAL_CAMP_-2023_v6_2023-7-23-16-1-44.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                            <br></br><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/MEDICAL_CAMP_-2023_v5_2023-7-23-16-0-36.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />



                                        </p>

                                        <br></br><br></br>
                                        {/* <h3 align='center'>Various Activities</h3> */}
                                        <div className="image-container" style={{ margin: '40px 0' }}>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/OMEGA_BANNU_HOSPITAL_2023-5-26-13-18-41.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                        </div>
                                        <p style={{ textAlign: 'justify', fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>

                                            1. OMEGA BANNU HOSPITAL : Free Cancer Screening Camp for 2days(4.08.2021 and 5.8.2021), 1. Mammography For Breast Cancer (age above 40 years),
                                            2. Pap Smear for Cervical Cancer (age18 years above), 3. Blood Pressure, 4. ECG, 5. Doctor Consultation checkups done. More than 100 Employees & students utilized the facilities.
                                            <br></br><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/OMEGA_BANNU_HOSPITAL_v1_2023-5-26-13-22-41.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />

                                            <br></br><br></br>

                                            2. SHARAT MAX VISION EYE HOSPITAL : Sharat Maxivision Eye Hospital conducted a Medical camp for 2 days ( 29th, 30th November, 2021). Auto Refraction (Computer Eye Testing), Subjective Refraction,. and counselling . More than 150 employees and students utilised the facilities.
                                            <br></br><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/SHARAT_MAX_2023-5-26-13-24-52.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />

                                            <br></br><br></br>

                                            {/* <h3 align='center'>Various Activities in 2022</h3> */}
                                            <br></br><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/Support_Ambulance_sponsored_2023-5-26-13-26-10.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                            <br></br><br></br>
                                            3. Advance Life Support Ambulance sponsored by 1982 - 86 batch : Ambulance was inaugurated on March 12th 2022
                                            <br></br><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/amulance_1_2023-5-26-13-28-21.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                            <br></br><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/amulance_2_2023-5-26-13-29-52.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />




                                            <br></br><br></br>

                                            4. BLOOD DONATION CAMP : Voluntary blood Donation camp was held on 17th August 2022.More than 150 students donated blood. Assisted by Medical Officers and Paramedical staff NITW.
                                            <br></br><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/BLOOD_DONATION_CAMP_2023-5-26-13-30-51.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                            <br></br><br></br>

                                            <b>5. COVID -19 VACCINATION:</b><br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/Booster_Dose_2023-5-26-13-32-23.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                            <br></br><br></br>
                                            Booster Dose Covid -19 Vaccination was held on Sep 2nd 2022 for employees and students. Assisted by Medical Officers and Paramedical staff NITW.
                                        </p>
                                        <br></br><br></br>






                                        {/* <h3 align='center'>Various Activities in 2020 - 21</h3> */}
                                        <div className="image-container" style={{ margin: '40px 0' }}>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/hsp_1_2023-5-23-15-26-16.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />
                                        </div>

                                        <p style={{ textAlign: 'justify', fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
                                            {/* Add your paragraph content here */}
                                            {/* For example: */}
                                            <b>Prof. N.V. Ramana Rao</b>, Director NITW, inaugurated the health center with more medical facilities (Hematology analyzer, Biochemistry analyzer, and physiotherapy equipment) on 28th April 2021.
                                            <br /><br />
                                            <b>Sri. S. Govardhan Rao</b>, Registrar NITW, Institute Health Centre Doctors, and staff attended the program.


                                            <br /><br />

                                            <b>Laboratory:</b> The Laboratory is equipped with the latest equipment.
                                            <br /><br />
                                            <b>Physiotherapy:</b> The Physiotherapy unit is equipped with all the latest equipment. Prof. N.V. Ramana Rao, Director NITW, inaugurated the Health Centre Physiotherapy unit with D-Trac and mobilization machine on 1.11.2021. The D-Trac machine is used to treat chronic back and neck pain without any irritation to release the spasms and stiffness.

                                            <div className="image-container" style={{ margin: '40px 0' }}>
                                                <img
                                                    src="https://nitw.ac.in/api/static/files/Physiotherapy_2023-5-23-16-21-38.jpg"
                                                    alt="Image 1"
                                                    style={{ width: '100%', height: '350px' }} // // Customize width and height
                                                />
                                                <br></br><br></br>
                                                <img
                                                    src="https://nitw.ac.in/api/static/files/Physiotherapy_v1_2023-5-23-16-25-57.jpg"
                                                    alt="Image 1"
                                                    style={{ width: '100%', height: '350px' }} // // Customize width and height
                                                />
                                            </div>




                                            <h4 align='center'>Covid -19 vaccination</h4><br></br>
                                            Covid -19 vaccination drive is arranged at NIT warangal. Faculty, staff and family dependents  have utilized the opportunity and got vaccinated.<br></br>
                                            <img
                                                src="https://nitw.ac.in/api/static/files/Physiotherapy_v2_2023-5-23-16-27-26.jpg"
                                                alt="Image 1"
                                                style={{ width: '100%', height: '350px' }} // // Customize width and height
                                            />


                                            <h4 align='center'>Tackling Covid-19 during the PANDEMIC </h4> <br></br>
                                            1. Tele Consultation: Tele Consultation was done round the clock (24*7). Around 100 Covid-19 positive cases were treated by our Institute Health Centre doctors during the Covid-19 2nd wave . <br></br>
                                            2. Testing: Covid testing has been arranged whenever required and also reached to the nearest Primary Health Center (Boda Gutta) for immediate testing/vaccination, wherever possible.
                                            <br></br>
                                            3. Arranged quarantine at the Punnami Guest House employees who require isolation.
                                            <br></br>
                                            4. For emergency Covid patient’s - beds have been arranged in hospitals whenever required.
                                            <br></br>
                                            5. Monitoring of post Covid patients by physiotherapist is arranged to discuss the techniques that increase the lung capacity.
                                            <br>
                                            </br>
                                            6. In the process of Covid and during post Covid Rehabilitation, patients are being counselled regarding their condition, techniques to increase their lung capacity and mental wellbeing which improve their immunity and ability to fight the diseases.
                                            <br></br>
                                            7. Health Centre has taken initiative for the post COVID recovering patients in the form of arranging OXYGEN CONCENTRATOR which can deliver 7 Litres of OXYGEN.
                                            <br></br>
                                            8. Diet: Dietary advice was given to entire family members.
                                            <br></br>
                                            9. Patients were encouraged to be STRONG ENOUGH. They were also counselled to take all the medicines in time and NOT PANIC by reading the WhatsApp forwarded messages and google search.
                                            <br></br>
                                            10. Preventive measures were  being given in R.O frequently.
                                            <br></br>
                                            11. Regular medicines were given for one month in order to decrease the visit / exposure to the health Centre and even issuing emergency drugs for cold, cough, fever.
                                            <br></br>
                                            12. The IHC staff are exposed to heavy load of positive cases and we continue to give the services at the best possible way.
                                            <br></br><br></br>
                                            <b>Medical Team with a Doctor and a paramedical staff were present at the 60th National Open Athletic Championship 2021 for Emergency services.</b>

                                            {/* ...previous code... */}
                                        </p>


                                    </div>
                                </div>



                                <div class={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">
                                    <p style={{ textAlign: 'justify' }}>

                                        NITW Health Centre is a full fledged unit with Four Full time regular medical officers, who are assisted by paramedical staff (Staff Nurse, ANM, OPD Assistant, Dresser, Lab Technician).
                                        The Health Centre has attached pharmacy with the turn over of medicines of more than 50 lakhs per annum.
                                        The students, employees and their dependents almost 11000 members are getting benefited with the daily OPD of around 150 and above.

                                    </p>
                                    {/* <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardBioIHC/IHC" target="_blank"> Notices/Announcements</a>
                                        </h4>
                                    </div> */}

                                </div>

                                <div class={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">

                                </div>

                                <div class={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div class="details">




                                        {/* <h5>General Rules</h5> */}
                                        <p class="justified-text">
                                            -Library is a place for study and research. Strict silence is to be observed.
                                            <br></br>
                                            -A user has to show identity card whenever he / she enters the Library.
                                            <br></br>
                                            -All the personal belongings should be deposited at the counter.
                                            <br></br>
                                            -The user has to pay Rs. 10/- towards loss of token of personal belongings counter.
                                            <br></br>
                                            -Users are requested to allow frisking of person at the Library exit gate while leaving the Library.
                                            <br></br>
                                            -Personal books, printed material, photocopy material are not allowed inside the Library.
                                            <br></br>
                                            -Outsiders are not allowed into the Library.
                                            <br></br>
                                            -Smoking is strictly prohibited.

                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </div >

        </>
    );
}

export default IhcDeptPage;