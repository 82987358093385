import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deanrandc.css';

export const DeanRAC = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('researchAdvisoryCommittee'); // Set the initial active tab to 'researchAdvisoryCommittee'

  const researchAdvisoryCommittee = [
    // ... Your RAC data here
    {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Dr. Ramanuj Narayan",
        // department: "-",
        person1: "Director, CSIR-IMMT, Bhubaneshwar",
        image: "https://nitw.ac.in/api/static/files/Dr._Ramanuj_Narayan_2023-7-17-11-50-26.jpg",
        link: "https://example.com/profile/john-doe",
      },
      {
        // Designation: "Director, ARCI, Hyderabad",
        Name: "Dr. Tata Narasinga Rao",
        // department: "-",
        person1: "Director, ARCI, Hyderabad",
        image: "https://nitw.ac.in/api/static/files/Dr._Tata_Narasinga_Rao_2023-7-17-11-51-1.jpg",
        link: "https://example.com/profile/john-doe",
      },
  
  
      {
        // Designation: "Department of Electrical Engineering, IIT Bombay",
        Name: "Prof. Vivek Agarwal",
        person2: " Professor, Electrical Engineering",
        person1: " IIT Bombay",
        image: "https://nitw.ac.in/api/static/files/Prof._Vivek_Agarwal_2023-7-17-11-51-32.jpg",
        link: "https://example.com/profile/john-doe",
      },
  
  
      {
        // Designation: "Department of Electrical Engineering, IIT Bombay",
        Name: "Prof. Amit Prashant",
        person2: " Professor, Civil Engineering",
        person1: " IIT Gandhinagar",
        image: "https://nitw.ac.in/api/static/files/Prof._Amit_Prashant_2023-7-17-11-51-54.jpg",
        link: "https://example.com/profile/john-doe",
      },
  
  
  
      {
        // Designation: "Department of Mechanical Engineering, IIT Guwahati",
        Name: "Prof. Santosha K Dwivedy",
        person2: " Professor, Mechanical Engineering",
        person1: " IIT Guwahati",
        image: "https://nitw.ac.in/api/static/files/Prof._Santosha_K_Dwivedy_2023-7-17-11-52-13.jpg",
        link: "https://example.com/profile/john-doe",
      },
  
  
  
      {
        // Designation: "Department of Computer Science and Engg, IIT Kharagpur",
        Name: "Prof. Sudip Misra",
        person2: " Professor, Computer Science and Engg",
        person1: " IIT Kharagpur",
        image: "https://nitw.ac.in/api/static/files/Prof._Sudeep_Misra_2023-7-17-11-52-35.jpg",
        link: "https://example.com/profile/john-doe",
      },
  
      {
        // Designation: "Director, IIM Visakhapatnam",
        Name: "Prof. M. Chandrasekhar",
        // department: " Professor, Computer Science and Engg",
        person1: " Director, IIM Visakhapatnam",
        image: "https://nitw.ac.in/api/static/files/Prof._M._Chandrasekhar_2023-7-17-11-53-2.jpg",
        link: "https://example.com/profile/john-doe",
      },
  
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setHoveredIndex(null);
  };

  const handleHover = (index) => {
    // setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    // setHoveredIndex(null);
  };

  return (
    <div className="page text-center font-change-applicable static-facilities">
      <PageHeader dept="/RAC" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'researchAdvisoryCommittee' ? 'active' : ''}`}
            onClick={() => handleTabClick('researchAdvisoryCommittee')}
          >
            Research Advisory Committee (RAC)
          </button>
        </div>
        <div className="columns-wrapper row">
          {researchAdvisoryCommittee.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img src={person.image} alt={person.Name} />
                </a>
              </div>
              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
              <h2>{person.Name}</h2>
                <div className="details-wrapper">
                  {person.Designation && (
                    <div className="details-row">
                      <span className="details-label">Name:</span>
                      <span className="details-value">{person.Designation}</span>
                    </div>
                  )}
                  {person.phoneNumber && (
                    <div className="details-row">
                      <span className="details-label">Phone:</span>
                      <span className="details-value">{person.phoneNumber}</span>
                    </div>
                  )}
                  {person.email && (
                    <div className="details-row">
                      <span className="details-label">Email:</span>
                      <span className="details-value">{person.email}</span>
                    </div>
                  )}
                  {person.department && (
                    <div className="details-row">
                      <span className="details-label">Department:</span>
                      <span className="details-value">{person.department}</span>
                    </div>
                  )}
                  {person.institute && (
                    <div className="details-row">
                      <span className="details-label">Institute:</span>
                      <span className="details-value">{person.institute}</span>
                    </div>
                  )}
                     {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person2}</span>
                    </div>
                  )}
                   {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person1}</span>
                    </div>
                  )}
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeanRAC;




