// export const getDepartmentData = (text) => {
//   // return text to be shoen in Page Header
//   if (text === "/mathematics") return "Mathematics";
//   if (text === "/cse") return "Computer Science & Engineering";
//   if (text === "/cs") return "Computer Science and Engineering";
//   if (text === "/eee") return "Electrical Engineering";
//   if (text === "/ece") return "Electronics & Communication Engineering";
//   if (text === "/bt") return "Biotechnology";
//   if (text === "/cy") return "Chemistry";
//   if (text === "/che") return "Chemical Engineering";
//   if (text === "/ce") return "Civil Engineering";
//   if (text === "/me") return "Mechanical Engineering";
//   if (text === "/pe") return "Physical Education";
//   if (text === "/physics") return "Physics";
//   if (text === "/sm") return "School of Management";
//   if (text === "/mme") return "Metallurgical and Materials Engineering";
//   if (text === "/facilities/library") return "Library";
//   if (text === "/hss") return "Humanities and Social Science";
//   if (text === "/rnd") return "Research and Development";
//   if (text === "/placement") return "Placements";
//   if (text === "/administration") return "Administration";
//   if (text === "/aa") return "Academic Administration";
//   if (text === "/ao") return "Administrative Officers";
//   if (text === " /oc") return "Organization Chart";
//   if (text === "/nitwForms") return "NITW Forms";
//   if (text === "/AcademicCalender") return "Academic Calender";
//   if (text === "/ad2021") return "Admissions";
//   if (text === "/eb") return "Examination Section";
//   if (text === "/ihc") return "Institute Health Centre";
//   if (text === "/centres") return "Centres";
//   if (text === "/hostel") return "Hostel";
//   if (text === "/sw") return "Student Welfare";
//   if (text === "/sc") return "Student Council";
//   if (text === "/nitwcentres") return "CRIF";
//   let res = text.split("/");
//   if (res.length > 1) {
//     return res[1].charAt(0).toUpperCase() + res[1].slice(1);
//   }
//   return res[0];
// };

// export const getHODdetails = (dept) => {
//   // return object if you want to show HOD details
//   if (dept === "/mathematics")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16396/16396.jpg",
//       name: "Dr. HARI PONNAMMA RANI",
//       email: "maths_hod@nitw.ac.in",
//       phone: "9908143247",
//     };
//   if (dept === "/cse")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16342/16342.jpg",
//       name: "Dr. S. RAVI CHANDRA",
//       email: "cse_hod@nitw.ac.in",
//       phone: "8702462730",
//     };
//   if (dept === "/cs")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16342/16342.jpg",
//       name: "Dr. S. RAVI CHANDRA",
//       email: "cse_hod@nitw.ac.in",
//       phone: "8702462730",
//     };
//   if (dept === "/eee")
//     return {
//       image:
//         "https://nitw.ac.in/api/static/files/Prof._Narasimharaju_B._L_2024-3-7-17-25-17.jpg",
//       name: "Dr. Narasimharaju B. L",
//       email: "eee_hod@nitw.ac.in",
//       phone: "08702462247",
//     };
//   if (dept === "/ece")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16301/16301.jpg",
//       name: "Dr. P. Sreehari Rao",
//       email: "ece_hod@nitw.ac.in",
//       phone: "08702462400",
//     };
//   if (dept === "/bt")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16351/16351.jpg",
//       name: "Dr. KORRAPATI NARASIMHULU",
//       email: "biotech_hod@nitw.ac.in",
//       phone: "9985470286",
//     };
//   if (dept === "/cy")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16379/16379.jpg",
//       name: "Dr. D. Kashinath",
//       email: "chemistry_hod@nitw.ac.in",
//       phone: "8332969502,7013242201",
//     };
//   if (dept === "/che")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16329/16329.jpg",
//       name: "Dr. P. V. Suresh",
//       email: "chemical_hod@nitw.ac.in",
//       phone: "8702462628",
//     };
//   if (dept === "/ce")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16211/16211.jpg",
//       name: "Dr. Gunneswara Rao T D",
//       email: "civil_hod@nitw.ac.in",
//       phone: "8332969249",
//     };
//   if (dept === "/me")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16268/16268.jpg",
//       name: "Dr. V Suresh Babu",
//       email: "mech_hod@nitw.ac.in",
//       phone: "9000416646, 9490165343",
//     };
//   if (dept === "/pe")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16810/16810.jpg",
//       name: "Dhyanithi R",
//       email: "rdaya@nitw.ac.in",
//       phone: "8332969528",
//     };
//   if (dept === "/physics")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg",
//       name: "Dr. T. Venkatappa Rao",
//       email: "physics_hod@nitw.ac.in",
//       phone: "9248667047",
//     };
//   if (dept === "/sm")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16939/16939.jpg",
//       name: "Dr. Rama Devi V",
//       email: "som_hod@nitw.ac.in",
//       phone: "9000453743",
//     };
//   if (dept === "/mme")
//     return {
//       image:
//         "https://nitw.ac.in/api/static/files/Prof._Mahesh_Kumar_Talari_2024-4-25-10-40-0.jpg",
//       name: "Mahesh Kumar Talari",
//       email: "mme_hod@nitw.ac.in",
//       phone: "8179048491",
//     };
//   if (dept === "/hss")
//     return {
//       image:
//         "https://wsdc.nitw.ac.in/facultynew/media/16617/16617.jpg",
//       name: "Dr. Madhavi Reddy Kesari",
//       email: "humanities_hod@nitw.ac.in",
//       phone: "8332969522",
//     };

//   return {};
// };

// export const getFacultyPage = (dept) => {
//   // return link to the faculty page of particular department
//   if (dept === "/mathematics")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/mat";
//   if (dept === "/cse")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/cse";
//   if (dept === "/cs")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/cse";
//   if (dept === "/eee")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/ee";
//   if (dept === "/ece")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/ece";
//   if (dept === "/bt")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/bt";
//   if (dept === "/cy")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/cy";
//   if (dept === "/che")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/che";
//   if (dept === "/ce")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/ce";
//   if (dept === "/me")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/me";
//   if (dept === "/pe")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/pe";
//   if (dept === "/physics")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/phy";
//   if (dept === "/sm")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/sm";
//   if (dept === "/mme")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/mme";
//   if (dept === "/hss")
//     return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/hss";
//   return "";
// };




export const getDepartmentData = (text) => {
  // return text to be shoen in Page Header
  if (text === "/mathematics") return "Mathematics";
  if (text === "/cse") return "Computer Science & Engineering";
  if (text === "/cs") return "Computer Science and Engineering";
  if (text === "/eee") return "Electrical Engineering";
  if (text === "/ece") return "Electronics & Communication Engineering";
  if (text === "/bt") return "Biotechnology";
  if (text === "/cy") return "Chemistry";
  if (text === "/che") return "Chemical Engineering";
  if (text === "/ce") return "Civil Engineering";
  if (text === "/me") return "Mechanical Engineering";
  if (text === "/pe") return "Physical Education";
  if (text === "/physics") return "Physics";
  if (text === "/sm") return "School of Management";
  if (text === "/mme") return "Metallurgical and Materials Engineering";
  if (text === "/facilities/library") return "Library";
  if (text === "/hss") return "Humanities and Social Science";
  if (text === "/rnd") return "Research and Development";
  if (text === "/placement") return "Placements";
  if (text === "/administration") return "Administration";
  if (text === "/aa") return "Academic Administration";
  if (text === "/ao") return "Administrative Officers";
  if (text === " /oc") return "Organization Chart";
  if (text === "/nitwForms") return "NITW Forms";
  if (text === "/AcademicCalender") return "Academic Calender";
  if (text === "/ad2021") return "Admissions";
  if (text === "/eb") return "Examination Branch";
  if (text === "/ihc") return "Institute Health Centre";
  if (text === "/centres") return "Centres";
  if (text === "/hostel") return "Hostel";
  if (text === "/sw") return "Student Welfare";
  if (text === "/sc") return "Student Council";
  if (text === "/nitwcentres") return "CRIF";
  if (text === "/DeanAcademic") return "DeanAcademic";
  // if (text === "/Institute_Facilities") return "Institute Facilities";
  let res = text.split("/");
  if (res.length > 1) {
    return res[1].charAt(0).toUpperCase() + res[1].slice(1);
  }
  return res[0];
};

export const getHODdetails = (dept) => {
  // return object if you want to show HOD details
  if (dept === "/mathematics")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16397/16397.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16397">Dr. A. Benerji Babu</a>,
      email: "maths_hod@nitw.ac.in",
      phone: "9490165350",
    };
  if (dept === "/cse")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16342/16342.jpg",
      name: "Dr. S. RAVI CHANDRA",
      email: "cse_hod@nitw.ac.in",
      phone: "8702462730",
    };
  if (dept === "/cs")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16345/16345.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345">	Prof. R. Padmavathy</a>,
      email: "cse_hod@nitw.ac.in",
      phone: "9490165347",
    };
  if (dept === "/eee")
    return {
      image:
        "https://nitw.ac.in/api/static/files/Prof._Narasimharaju_B._L_2024-3-7-17-25-17.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16242">Prof. B. L. Narasimharaju </a>,
      email: "eee_hod@nitw.ac.in",
      phone: "08702462247",
    };
  if (dept === "/ece")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16303/16303.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16303">Prof. D. Vakula</a>,
      email: "ece_hod@nitw.ac.in",
      phone: "08702462400",
    };
  if (dept === "/bt")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16425/16425.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16425">Dr. Prakash Saudagar</a>,
      email: "biotech_hod@nitw.ac.in",
      phone: "9490165348 ",
    };
  if (dept === "/cy")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16379/16379.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16379">Dr. D. Kashinath</a>,
      email: "chemistry_hod@nitw.ac.in",
      phone: "8332969502,7013242201",
    };
  if (dept === "/che")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16329/16329.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16329">Dr. P. V. Suresh</a>,
      email: "chemical_hod@nitw.ac.in",
      phone: "8702462628",
    };
  // if (dept === "/ce")
  //   return {
  //     image:
  //       "https://wsdc.nitw.ac.in/facultynew/media/16211/16211.jpg",
  //     name: "Dr. Gunneswara Rao T D",
  //     email: "civil_hod@nitw.ac.in",
  //     phone: "8332969249",
  //   };


  if (dept === "/ce")
  return {
    image: "https://wsdc.nitw.ac.in/facultynew/media/16211/16211.jpg",
    name: <a href="https://erp.nitw.ac.in/ext/profile/ce-tdg">Dr. Gunneswara Rao T D</a>,
    email: "civil_hod@nitw.ac.in",
    phone: "8332969249",
  };


  if (dept === "/me")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16268/16268.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16268">Dr. V. Suresh Babu</a>,
      email: "mech_hod@nitw.ac.in",
      phone: "9000416646, 9490165343",
    };
  if (dept === "/pe")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16810/16810.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16810">Dr. R. Dhayanidhi</a>,
      email: "rdaya@nitw.ac.in",
      phone: "8332969528",
    };
  if (dept === "/physics")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg",
      name: <a href="https://erp.nitw.ac.in/ext/profile/ph-tvraokmm">Dr. T. Venkatappa Rao</a>,
      email: "physics_hod@nitw.ac.in",
      phone: "9248667047",
    };
  if (dept === "/sm")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16939/16939.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16939">Dr. V. Rama Devi</a>,
      email: "som_hod@nitw.ac.in",
      phone: "9000453743",
    };
  if (dept === "/mme")
    return {
      image:
        "https://nitw.ac.in/api/static/files/Prof._Kishore_Babu_Nagumothu_2024-4-25-10-39-13.jpg",
      name: <a href="https://erp.nitw.ac.in/ext/profile/mm-kishorebabu"> Dr. Kishore Babu Nagumothu</a>,
      email: "mme_hod@nitw.ac.in",
      phone: "9490165345",
    };
  if (dept === "/hss")
    return {
      image:
        "https://wsdc.nitw.ac.in/facultynew/media/16617/16617.jpg",
      name: <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16617">Dr. K. Madhavi Reddy</a>,
      email: "humanities_hod@nitw.ac.in",
      phone: "8332969522",
    };

  return {};
};

export const getFacultyPage = (dept) => {
  // return link to the faculty page of particular department
  if (dept === "/mathematics")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/mat";
  if (dept === "/cse")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/cse";
  if (dept === "/cs")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/cse";
  if (dept === "/eee")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/ee";
  if (dept === "/ece")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/ece";
  if (dept === "/bt")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/bt";
  if (dept === "/cy")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/cy";
  if (dept === "/che")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/che";
  if (dept === "/ce")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/ce";
  if (dept === "/me")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/me";
  if (dept === "/pe")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/pe";
  if (dept === "/physics")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/phy";
  if (dept === "/sm")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/sm";
  if (dept === "/mme")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/mme";
  if (dept === "/hss")
    return "https://wsdc.nitw.ac.in/facultynew/dept/faculty_profiles/hss";
  return "";
};