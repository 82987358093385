import { Typography } from "@mui/material";
import React from "react";
import Slideshow from "./SlideShow";
import "@brainhubeu/react-carousel/lib/style.css";
import imagesData from '../constants/placements/placementScrollImages'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

import PlacementCharts from "./PlacementCharts";

import "../components/Styles.css";
import { OldServerUrl } from "../BasePath";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);




var placement_stats = [
  {
    year: 2023,
    avg: 17.29,
    placed: 1404,
    max: 88.0,
    link: "https://oldweb.nitw.ac.in/media/uploads/2023/04/28/impetus.pdf",
  },

  {
    year: 2022,
    avg: 17.0,
    placed: 1108,
    max: 62.5,
    link: "https://oldweb.nitw.ac.in/media/uploads/2022/06/06/placement-statistics-2021-2022docx.pdf",
  },
  // {
  //   year: 2021,
  //   avg: 13.0,
  //   max: 51.0,
  //   link: "https://nitw.ac.in/media/uploads/2021/07/15/placement-statistics-2020-2021_to-upload.pdf",
  // },
  // {
  //   year: 2020,
  //   avg: 17,
  //   max: 43.31,
  //   link: "https://nitw.ac.in/media/uploads/2020/10/18/2019-20-placement-stats_website.pdf",
  // },
  // {
  //   year: 2019,
  //   avg: 16,
  //   max: 45,
  //   link: `${OldServerUrl}/main/placemtents/ay18-19/`,
  // },
  // {
  //   year: 2018,
  //   avg: 17,
  //   max: 39,
  //   link: `${OldServerUrl}/main/Placement/Statistics2017-18/`,
  // },
];

// No of Students Placed in 2019 - 20 : 792
// No of Students Placed in 2020 - 21 : 815
// No of Students Placed in 2021 - 22 : 1108

// No of Students Placed in 2022 - 23 : 1404

const placementStat2=  [
   {
    year: 2021,
    avg: 13.0,
    max: 51.0,
    placed: 815,
    link: "https://nitw.ac.in/media/uploads/2021/07/15/placement-statistics-2020-2021_to-upload.pdf",
  },
  {
    year: 2020,
    avg: 17,
    max: 43.31,
    placed: 792,
    link: "https://nitw.ac.in/media/uploads/2020/10/18/2019-20-placement-stats_website.pdf",
  },
]


const PlacementCard = () => {
  
  return (
    <ThemeProvider theme={theme}>
      <div style={{ marginTop: "5vh", backgroundColor: "#FFF5EE" }}>
        <Typography
          variant="h3"
          fontWeight="bold"
          margin="auto"
          textAlign="center"
          color="#b80924"
          paddingTop="3vh"
        >
          Our Placements
        </Typography>
        <hr className="divider bg-madison" />
        <div className="row"> 
          <div className="col-md-12 col-lg-6"> 
          <div className="row">
          <div className="col-12"> 
           {/* Placement Stats */}
           <div className="offset-top-30">
          <div
            data-lightgallery="group"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "3vh",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {placement_stats.map((stats) => {
              var newUrl = stats.link;
              return (
                <div className="col-sm-10 col-md-6 col-lg-6">
                  <figure
                    className="thumbnail-classic"
                    style={{
                      margin: "0 2vh",
                      border: "solid",
                      borderColor: "black",
                      borderWidth: "0.3vh",
                      opacity: "1",
                    }}
                  >
                    <div
                      className="thumbnail-classic-img-wrap"
                      style={{
                        minHeight: "18vh",
                        backgroundColor: "#FFF5EE",
                        marginBottom: 20,
                      }}
                    >
                      <div className=" pt-3">
                        <Typography
                          variant="h5"
                          className="text-danger  d-flex justify-content-center"
                        >
                          Placement Stats {stats.year}
                        </Typography>
                        <div className="offset-top-20">
                          <center>
                            {/* <p>
                              <Typography variant="h6">
                                <strong>Average CTC</strong> : {stats.avg} lpa
                              </Typography>
                            </p> */}
                            <p>
                              <Typography variant="h6">
                                <strong>Highest CTC</strong> : {stats.max} lpa
                              </Typography>
                            </p>
                            <p>
                              <Typography variant="h6">
                                <strong>Students Placed</strong> : {stats.placed}
                              </Typography>
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>
                    <figcaption className="thumbnail-classic-caption text-center">
                      <div>
                        <h4 className="thumbnail-classic-title">
                          Stats {stats.year}
                        </h4>
                      </div>

                      <a
                        className="icon icon-xxs fa fa-search-plus"
                        href={newUrl}
                      >
                        {" "}
                      </a>
                    </figcaption>
                  </figure>
                </div>
              );
            })}
          </div>
        </div>
          </div>
          <div className="col-12"> 
           {/* Placement Stats */}
           <div className="offset-top-30">
          <div
            data-lightgallery="group"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "5vh",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {placementStat2.map((stats) => {
              var newUrl = stats.link;
              return (
                <div className="col-sm-10 col-md-6 col-lg-6">
                  <figure
                    className="thumbnail-classic"
                    style={{
                      margin: "0 2vh",
                      border: "solid",
                      borderColor: "black",
                      borderWidth: "0.3vh",
                      opacity: "1",
                    }}
                  >
                    <div
                      className="thumbnail-classic-img-wrap"
                      style={{
                        minHeight: "18vh",
                        backgroundColor: "#FFF5EE",
                        marginBottom: 20,
                      }}
                    >
                      <div className=" pt-3">
                        <Typography
                          variant="h5"
                          className="text-danger  d-flex justify-content-center"
                        >
                          Placement Stats {stats.year}
                        </Typography>
                        <div className="offset-top-20">
                          <center>
                          
                            <p>
                              <Typography variant="h6">
                                <strong>Highest CTC</strong> : {stats.max} lpa
                              </Typography>
                            </p>
                            <p>
                              <Typography variant="h6">
                                <strong>Students Placed</strong> : {stats.placed}
                              </Typography>
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>
                    <figcaption className="thumbnail-classic-caption text-center">
                      <div>
                        <h4 className="thumbnail-classic-title">
                          Stats {stats.year}
                        </h4>
                      </div>

                      <a
                        className="icon icon-xxs fa fa-search-plus"
                        href={newUrl}
                      >
                        {" "}
                      </a>
                    </figcaption>
                  </figure>
                </div>
              );
            })}
          </div>
        </div>
          </div>
          </div>
         
          
           </div>
          
           <div className="col-md-12 col-lg-6"> 
          <div className="offset-top-30">
          <div
            data-lightgallery="group"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "5vh",
              marginTop: "-1.3rem", 
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
           {/* Add slideshow here */}
           <PlacementCharts/>
           {/* <Slideshow images={imagesData} width={'100%'}/> */}
          </div>
        </div>
          
           </div>
        </div>
        
      </div>
    </ThemeProvider>
  );
};

export default PlacementCard;
