export const registrationData = [ // Change the array name to registrationData
//   {
//     name: 'Padma Bhushan Dr. V. K. Saraswat',
//     image: 'https://nitw.ac.in/api/static/files/21st_convocation_Chief_Guest_2023-7-23-13-1-55.png',
//     link: 'https://nitw.ac.in/api/static/files/convocation_2023_2023-7-22-19-11-53.pdf'
    
//   },
  {
    name: 'B.Tech',
    image: 'https://nitw.ac.in/api/static/files/B.Tech_2023-7-29-13-20-19.jpg',
    link: 'https://wsdc.nitw.ac.in',
    isCircle: true
  },
  {
    name: 'M.Tech',
    image: 'https://nitw.ac.in/api/static/files/M.Tech_2023-7-29-13-21-49.jpg',
    link: 'https://erp.nitw.ac.in',
    isCircle: true
  },

  {
    name: 'Ph.D',
    image: 'https://nitw.ac.in/api/static/files/registration_Ph.D_2023-7-25-10-42-58.jpg',
    link: 'https://wsdc.nitw.ac.in',
    isCircle: true
  },

  {
    name: 'M C A',
    image: 'https://nitw.ac.in/api/static/files/M.C.A_2023-7-29-13-24-10.jpg',
    link: 'https://wsdc.nitw.ac.in',
    isCircle: true
  },
  {
    name: 'M B A',
    image: 'https://nitw.ac.in/api/static/files/MBA_2023-7-29-13-25-2.jpg',
    link: 'https://erp.nitw.ac.in',
    isCircle: true
  },
  // {
  //   name: 'M.Sc.',
  //   image: 'https://nitw.ac.in/api/static/files/registration_Ph.D_2023-7-25-10-42-58.jpg',
  //   link: 'https://wsdc.nitw.ac.in',
  //   isCircle: true
  // },
  
  {
    name: 'M.Sc. Tech',
    image: 'https://nitw.ac.in/api/static/files/M.Sc_Tech_(Physics)_2023-7-29-13-15-31.jpg',
    link: 'https://wsdc.nitw.ac.in',
    isCircle: true
  },
  {
    name: 'M.Sc.',
    image: 'https://nitw.ac.in/api/static/files/MSc_Chemistry_2023-7-29-13-17-38.jpg',
    link: 'https://erp.nitw.ac.in',
    isCircle: true
  },
  {
    name: 'M.Sc.',
    image: 'https://nitw.ac.in/api/static/files/MSc_Mathematics_2023-7-29-13-18-17.jpg',
    link: 'https://erp.nitw.ac.in',
    isCircle: true
  },
  // Add more support centers as needed
];
