import React,{useState,useEffect} from "react";
import styled from "styled-components";
import SearchTab from './SearchTab.js'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


const Wrapper = styled.div`
  position: fixed;
  margin-top: 80vh;
  width: 780px;
  max-width: 1170px;
  max-height: 600px;
  overflow-y: scroll;
  background: #fff;
  border: 3px solid #f04a49;
  z-index: 20;
  display: ${(props) => (props.$display ? "block" : "none")};
  @media (min-width: 1800px) {
    left: 25vw;
    width: 1200px;
    max-height: 800px;
  }
`;


function SearchBar() {
  const [display, setDisplay] = useState(false);
  const [searchData, setsearchData] = useState("");
  const [arrayData, setarrayData] = useState([]);
  const [curArrayData, setcurArrayData] = useState([]);

  useEffect(() => {
    fetch("https://nitw.ac.in/api/nerp/getAll")
      .then(function (response) {
        return response.json();
      })
      .then((jsondata) => {
        setarrayData(jsondata);
      });
  }, []);

  const search_res = (e) => {
    setsearchData(e.target.value.toLowerCase());
    // console.log(searchData);
  };

  const show_res = () => {
    window.location.href = "https://cse.google.com/cse?cx=a7820341473124233#gsc.tab=0&gsc.q=" + searchData
    const curr = arrayData.filter((obj) => {
      return (
        obj.type.toLowerCase().includes(searchData) == true ||
        obj.title.toLowerCase().includes(searchData) == true ||
        obj.content.toLowerCase().includes(searchData) == true
      );
    });
    if (searchData == "") setcurArrayData([]);
    else setcurArrayData(curr);
    // console.log(curArrayData);
  };


  return (
    <>
      <button className="search_button" onClick={() => setDisplay(true)}>
        <SearchIcon />
      </button>

      <Wrapper $display={display}>
        <div className="popup" id="popup">
        <button
            className="search_button_close_top"
            onClick={() => setDisplay(false)}
          >
            <CloseIcon />
          </button>
          <h1 className="head_seach_text">
            <span>What are you looking for?</span>
          </h1>
          <div>
          </div>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              name="search_all"
              onChange={search_res}
              id="search_all"
              className="search_all"
              placeholder="Search here..."
            />
            <button className="search_button" onClick={show_res} style={{ fontSize: "1.3rem" }}>
              Search
            </button>
          </div>
          <SearchTab items={curArrayData} />
          <button
            className="search_button_close"
            onClick={() => setDisplay(false)}
            style={{ paddingBottom: "20px", fontSize: "20px" }}
          >
            Close
          </button>
        </div>

      </Wrapper>
    </>
  );
}

export default SearchBar;
