import React, { useState, useEffect } from "react";
import DelimiterRenderer from "./rederers/DelimiterRenderer";
import { Helmet } from "react-helmet";
import TableRenderer from "./rederers/TableRenderer";
import Output from "editorjs-react-renderer";
import ListRenderer from "./rederers/ListRenderer";
import HeaderRenderer from "./rederers/HeaderRenderer";
import ImageRenderer from "./rederers/ImageRenderer";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";
import queryString from "query-string";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import "../components/Styles.css";
import Paragraph from "./rederers/ParagraphRenderer";
import { MainServerUrl } from "../BasePath";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

const config = {
  header: {
    disableDefaultStyle: true,
  },
  image: {
    disableDefaultStyle: true,
  },
  paragraph: {
    disableDefaultStyle: true,
  },
  list: {
    disableDefaultStyle: true,
  },
  table: {
    disableDefaultStyle: true,
  },
};

const classes = {
  header: "offset-top-20",
  list: {
    container: "list list-marked",
  },
  // table: {
  //   table: "table table-custom table-fixed bordered-table",
  // },
};

const renderers = {
  header: HeaderRenderer,
  delimiter: DelimiterRenderer,
  table: TableRenderer,
  list: ListRenderer,
  image: ImageRenderer,
  paragraph: Paragraph,
};

const EditorJSContentRenderer = (props) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = queryString.parse(location.search);
  if (params.url === undefined) {
    params.url = "/home/about";
  }
  useEffect(() => {
    setIsLoading(false);
  }, [data]);
  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ url: params.url }),
    };
    fetch(`${MainServerUrl}/api/page/view`, requestOptions)
      .then((response) => response.json())
      .then((data) => setData(data));

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [params.url]);

  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>

      {isLoading ? (
        <Loading></Loading>
      ) : (
        <ThemeProvider theme={theme}>
          <Output
            data={data}
            classNames={classes}
            config={config}
            renderers={renderers}
          ></Output>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default EditorJSContentRenderer;
