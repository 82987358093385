export const TT = [
    {
      name: 'Odd Semester',
      image: 'https://nitw.ac.in/api/static/files/odd_sem_2023-11-17-15-41-50.png',
      link: 'https://www.nitw.ac.in/page/?url=/Academic%20Year2023-24/BTechandIntegratedMSC'
    },
    {
      name: 'Even Semester',
      image: 'https://nitw.ac.in/api/static/files/Even_Sem_2023-11-17-15-38-5.png',
      link: 'https://nitw.ac.in/page/?url=/ES2022/2023'
    },
    {
      name: 'Old Time - Tables',
      image: 'https://nitw.ac.in/api/static/files/old_tt_2023-11-17-15-43-28.png',
      link: 'https://nitw.ac.in/page/?url=/oldTimeTable/listTT'
    },
   
    // Add more support centers as needed
  ];