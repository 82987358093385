import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
import { StaticAcademicCalendarData } from '../constants/StaticAcademicCalendarData'; // Corrected import
import '../css/AcademicCalendarPage.css'; // Updated import
// import 

const AcademicCalendarPage = () => {
  const [hoveredSemester, setHoveredSemester] = useState(null);
  const [selectedAcademicCalendar, setSelectedAcademicCalendar] = useState(null);

  const handleMouseOver = (semesterIndex, dataIndex) => {
    setHoveredSemester(semesterIndex);
    setSelectedAcademicCalendar(dataIndex);
  };

  const handleMouseOut = () => {
    setHoveredSemester(null);
    setSelectedAcademicCalendar(null);
  };

  return (
    <div className="academic-calendar-page">
      <PageHeader dept="/AC" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="row">
          {StaticAcademicCalendarData.map(({ heading, data }, semesterIndex) => (
            <div key={semesterIndex} className="col-12">
              <h3 style={{ textAlign: 'left' }}>{heading}</h3>
              <div className="row">
                {data.map(({ name, link, image }, dataIndex) => (
                  <div
                    key={dataIndex}
                    className="col-sm-6 col-md-3 mb-4"
                    onMouseOver={() => handleMouseOver(semesterIndex, dataIndex)}
                    onMouseOut={handleMouseOut}
                  >
                    <a href={link} target="_self" rel="noreferrer">
                      <div
                        className={`card profile-card-4 ${
                          hoveredSemester === semesterIndex && selectedAcademicCalendar === dataIndex
                            ? 'hovered'
                            : ''
                        }`}
                      >
                        <div>
                          <img src={image} alt="profile-sample1" className="rounded-image" />
                        </div>
                        {/* Uncomment the following lines to display the name */}
                        {/* <div style={{ marginTop: '2px' }} className="card-content">
                          <h2 style={{ backgroundColor: '#FFBF00' }}>
                            <a href={link} target="_self" rel="noreferrer">
                              {name}
                            </a>
                          </h2>
                        </div> */}
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AcademicCalendarPage;