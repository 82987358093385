import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
import { SUPPORT_CENTRES } from '../constants/supportcentredata'; // Correct the import path

export const SupportCentresPage = () => {
  const [selectedSupportCentre, setSelectedSupportCentre] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedSupportCentre(index);
  };

  const handleMouseOut = () => {
    setSelectedSupportCentre(null);
  };

  return (
    <div className="page text-center font-change-applicable">
      <PageHeader dept="/Support Centres" /> {/* Update the header text accordingly */}
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="row">
          {_.map(SUPPORT_CENTRES, (supportCentre, index) => (
            <div
              className="col-sm-12 col-md-6 col-lg-4 member"
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={supportCentre.link} target="_self" rel="noreferrer">
                <div
                  className={`card profile-card-4 ${
                    selectedSupportCentre === index ? 'hovered' : ''
                  }`}
                >
                  <div>
                    <img
                      src={supportCentre.image}
                      alt="profile-sample1"
                      className="rounded-image"
                    />
                  </div>
                  <div style={{ marginTop: '2px' }} className="card-content">
                    <h2>
                      <a href={supportCentre.link} target="_self" rel="noreferrer">
                        {supportCentre.name}
                      </a>
                    </h2>
                    <p>{supportCentre.department}</p>
                    <p>{supportCentre.designation}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};