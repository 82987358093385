import { useState } from "react";
import '../css/cseDeptStyle.css';

import '../css/ccpd.css';
// import BarChart from "../chartss/Barchart";
const CcpdPage = () => {

  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [placementRecordTab, setPlacementRecordTab] = useState("placementstats");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePlacementRecordTabClick = (tabId) => {
    setPlacementRecordTab(tabId);
    console.log(tabId)
  }







  return (
    <><div className="dept-wrapper">
      <div className="container">
        <section className="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
            <img src="CCPDAssets/images/ccpd_banner.jpg" className="img-fluid" />
          </header>
          <div className="row">
            
            
            <div className="col-md-3">
              {/* Tabs nav */}
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected="false">
                  <span>Home</span>
                </a>


                
                <a className={`nav-link ${activeTab === 'v-pills-aboutnitw' ? 'active' : ''}`} id="v-pills-aboutnitw-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-aboutnitw')} role="tab"
                  aria-controls="v-pills-aboutnitw" aria-selected="false">
                  <span>About NITW</span>
                </a>

                <a className={`nav-link ${activeTab === 'v-pills-placementprocess' ? 'active' : ''}`} id="v-pills-placementprocess-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-placementprocess')} role="tab"
                  aria-controls="v-pills-placementprocess" aria-selected="false">
                  <span>Placement Process</span>
                </a>

                <a className={`nav-link ${activeTab === 'v-pills-extoffers' ? 'active' : ''}`} id="v-pills-extoffers-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-extoffers')} role="tab"
                  aria-controls="v-pills-extoffers" aria-selected="false">
                  <span>Extendable Offers</span>
                </a>

                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected="false">
                  <span>Academic Programmes</span>
                </a>

                <a className={`nav-link ${activeTab === 'v-pills-demographics' ? 'active' : ''}`} id="v-pills-demographics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-demographics')} role="tab"
                  aria-controls="v-pills-demographics" aria-selected="false">
                  <span>Batch Demographics</span>
                </a>

                <a className={`nav-link ${activeTab === 'v-pills-stdactivities' ? 'active' : ''}`} id="v-pills-stdactivities-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-stdactivities')} role="tab"
                  aria-controls="v-pills-stdactivities" aria-selected="false">
                  <span>Student Activities</span>
                </a>

                <a className={`nav-link ${activeTab === 'v-pills-placementstats' ? 'active' : ''}`} id="v-pills-placementstats-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-placementstats')}
                  role="tab" aria-controls="v-pills-placementstats" aria-selected="false">
                  <span>Placement Records</span>
                </a>


                <a className={`nav-link ${activeTab === 'v-pills-recruiters' ? 'active' : ''}`} id="v-pills-recruiters-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-recruiters')} role="tab"
                  aria-controls="v-pills-recruiters" aria-selected="false">
                  <span>Past Recruiters</span>
                </a>


                <a className={`nav-link ${activeTab === 'v-pills-contact' ? 'active' : ''}`} id="v-pills-contact-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-contact')} role="tab"
                  aria-controls="v-pills-contact" aria-selected="false">
                  <span>Contact Us</span>
                </a>

                <a className={`nav-link ${activeTab === 'v-pills-ccpdteam' ? 'active' : ''}`} id="v-pills-ccpdteam-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ccpdteam')} role="tab"
                  aria-controls="v-pills-ccpdteam" aria-selected="false">
                  <span>Placement Team</span>
                </a>
               
                
               
                



                
                
                
                
                



              </div>
              <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/me-harikumar"
                target="_blank"> <img src="CCPDAssets/images/harikumar.jpg" alt="" className="img-fluid" />
                <h6>Prof. V Hari Kumar</h6>
              </a>
                <p style={{textAlign:"left"}}>Head of Centre for Career Planning & Development <br /> <a href="mailto:taps[AT]nitw[DOT]ac[DOT]in"
                  style={{ color: "#808080" }}>taps[AT]nitw[DOT]ac[DOT]in</a> <br /> 94901 65357 </p>
              </div>
            </div>




            <div className="col-md-9">
              {/* Tabs content */}
              <div className="tab-content" id="v-pills-tabContent">
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div className="intro-section">
                    
                    <p>The National Institute of Technology Warangal, is a premier engineering school in India with an excellent academic record. The Centre for Career Planning and Development (CCPD) facilitates student placements in top organizations across various fields such as software, core industries, and management.</p>
                    <p>The CCPD has strong relationships with a wide range of companies, leading to impressive placement records. Each year, about 150-180 companies visit the campus, offering students significant opportunities. The Institute values the trust and continued relationships with these organizations.</p>
                    <p>We are immensely elated to invite you to NIT Warangal for the
placement and internship drive 2024-2025. We understand the
importance of youth in shaping the future of the world and so our
mission extends beyond academic excellence to encompass a holistic
development.</p>
                  
                  </div>



                  
                 
                    <div class="container">
                       
                        <div class="row nirf-ranking-counter text-center">
                          
                            <div class="col-lg-6 col-6 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200" style={{borderRight:"2px solid gray"}}>
                                <div class="">
                                    <h1 class="mb-0" ><span class="counter" style={{color:"#c20315"}}>21</span></h1>
                                    <h3 class="mt-0" >NIRF</h3>
                                    <h6 class="mt-0"><b>Engineering Ranking (2023)</b></h6>
                                </div>
                            </div>
                           
                            <div class="col-lg-6 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                                <div class="">
                                    <h1 class="mb-0"><span class="counter" style={{color:"#c20315"}}>53</span></h1>
                                    <h3 class="mt-0" >NIRF</h3>
                                    <h6 class="mt-0"><b>Overall Ranking (2023)</b></h6>
                                </div>
                            </div>
                          
                        </div>
                    </div>
               







                 
                  
                </div>



                <div className={`tab-pane ${activeTab === 'v-pills-aboutnitw' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-aboutnitw" role="tabpanel"
                  aria-labelledby="v-pills-aboutnitw-tab">
                    
                  
<p>The National Institute of Technology, Warangal (NIT
Warangal), located in Telangana, India, is a premier
engineering institution. Established in 1959
as the first of the 31 NITs in the country.
</p>
<p>It was initially known as the Regional Engineering College.</p>
<p>Renowned for its strong emphasis on research and
innovation, NIT Warangal offers undergraduate,
postgraduate, and doctoral programs in various
engineering, science, and management disciplines.</p>
<p>The institute is known for its state-of-the-art infrastructure,
experienced faculty, and active student community.</p>
<p>It consistently ranks among the top engineering colleges in
India and has a strong alumni network contributing to
diverse fields globally.
</p>


<h4 style={{color:"#1b4cc8"}}>Why Recruit at NIT Warangal?</h4>
<img src="CCPDAssets/images/why-recruit_nit.jpg" alt="" className="img-fluid" />
              </div>

                <div className={`tab-pane ${activeTab === 'v-pills-placementprocess' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-placementprocess" role="tabpanel"
                  aria-labelledby="v-pills-placementprocess-tab">
                  

                  <img src="CCPDAssets/images/recruit_nit.jpg" className="img-fluid" alt="" />
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-extoffers' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-extoffers" role="tabpanel"
                  aria-labelledby="v-pills-extoffers-tab">
                  

                  <div class="row nitw_ccpd">
  
    
    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">B.Tech</h3>
          <p className="mb-0"><b>Pre-final year (2026 Batch) :</b></p>
        <p><b>2 months Summer Internship
          Final year (2025 Batch) :</b></p>
          
        </div>
        <div class="nitw_ccpd-card-body">

        


          <ul className="offers-list">
            <li> <i class="fa fa-angle-double-right" aria-hidden="true"></i>  6 months Internship+FTE Offers</li>
            <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Full Time Equivalent(FTE) Offers</li>
          </ul>
          
        </div>
      </div>

    </div>







    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">M.Tech</h3>
          <p className="mb-0"><b>Pre-final year (2026 Batch) :</b></p>
        <p><b>10-12 months Internship
          Final year (2025 Batch) :</b></p>
          
        </div>
        <div class="nitw_ccpd-card-body">

        

          <ul className="offers-list">
            
            <li> <i class="fa fa-angle-double-right" aria-hidden="true"></i>  Full Time Equivalent(FTE) Offers</li>
          </ul>
          
        </div>
      </div>

    </div>



    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">MCA</h3>
          <p className="mb-0"><b>Pre-final year (2026 Batch) :</b></p>
        <p><b>2 months Summer Internship
          Final year (2025 Batch):</b></p>
          
        </div>
        <div class="nitw_ccpd-card-body">


        

          <ul className="offers-list">
            <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> 6 months Internship+Full-time</li>
            <li> <i class="fa fa-angle-double-right" aria-hidden="true"></i> Full-time Equivalent Offers</li>
          </ul>
          
        </div>
      </div>

    </div>


    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">MBA, MSc</h3>
          <p className="mb-0"><b>Pre-final year (2026 Batch, Only MBA) :</b></p>
          <p><b>2 months Summer Internship
          Final year (2025 Batch) :</b></p>
          
        </div>
        <div class="nitw_ccpd-card-body">

        
          <ul className="offers-list">
            <li> <i class="fa fa-angle-double-right" aria-hidden="true"></i> Full-time Equivalent Offers</li>
          </ul>
          
        </div>
      </div>

    </div>



    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Integrated MSc</h3>
          <p className="mb-0"><b>Pre-final year (2026 Batch) :</b></p>
          
          
        </div>
        <div class="nitw_ccpd-card-body">
        
          <ul className="offers-list">
            <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> 2 months Summer Internship</li>
          </ul>
          
        </div>
      </div>

    </div>







  
                  </div>


                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-demographics' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-demographics" role="tabpanel"
                  aria-labelledby="v-pills-demographics-tab">
                  
                  
                  <div class="row">

                  <header>
                  <h2>Batch Demographics 2025</h2>
                  <h3>Program wise distribution</h3>
                  </header>


							<div class="col col-md-8">
								
										B.Tech <span class="pull-right strong">1013</span>
										 <div class="progress">
											<div class="progress-bar bg-primary"  style={{width:"84%"}}></div>
										</div>
									
										M.Tech <span class="pull-right strong">584</span>
										 <div class="progress">
											<div class="progress-bar bg-primary"  style={{width:"48%"}}></div>
										</div>
									
										M.Sc <span class="pull-right strong">158</span>
										 <div class="progress">
											<div class="progress-bar bg-primary"  style={{width:"13%"}}></div>
										</div>

                    MCA <span class="pull-right strong">56</span>
										 <div class="progress">
											<div class="progress-bar bg-primary"  style={{width:"4%"}}></div>
										</div>


                    MBA <span class="pull-right strong">39</span>
										 <div class="progress">
											<div class="progress-bar bg-primary" style={{width:"3.2%"}}></div>
										</div>

                    Phd <span class="pull-right strong">193</span>
										 <div class="progress">
											<div class="progress-bar bg-primary" role="progressbar" aria-valuenow="8"aria-valuemin="0" aria-valuemax="100" style={{width:"16%"}}></div>
										</div>  


							</div>

              <div className="col-md-4">




             
              <div class="row">
            <div class="col-md-12">
                <div class="tile">
                    
                        <header><h3>Gender Ratio</h3></header>

                        

                        

                        <div class="stats">
                        <div className="row">
                        <div className="col-md-6 col-6 text-center"><i class="fa fa-male fa-5x" aria-hidden="true"></i>  <h4 className="text-center"> 76%  </h4></div>
                        <div className="col-md-6 col-6 text-center"><i class="fa fa-female fa-5x" aria-hidden="true"></i>  <h4 className="text-center"> 24%  </h4></div>
                        </div>
                   
                        </div>

                        <div class="stats">
                        <div className="row">
                        <div className="col-md-12 text-center"><strong>TOTAL STUDENTS </strong> <h4 className="text-center"> 2043 </h4></div>
    
                        </div>
                   
                        </div>

                        

                        

                        
                    
                </div> 
            </div>

            
        </div>
                      
                   


                

              </div>


<div className="row">
<header>
                  
                  <h3>Branch wise distribution</h3>
                  </header>
  <div className="col-md-4 progress-seperator">

  <div class="row">

  <div className="col-md-12 mb-3">

<div class="stats" style={{border:"2px solid gray"}}>
    <div className="row">
    <div className="col-md-12 text-center"><h3 style={{color:"#000"}}><strong style={{color:"#000"}}>B.Tech </strong><small>Total No of Students</small></h3> <h4 className="text-center"> 1013</h4></div>

    </div>

    </div>
    </div>





        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CH 11%</div>
            </div>
        </div>
        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">ME  14%</div>
            </div>
        </div>

        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">MME 8%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CSE 17%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">ECE 16%</div>
            </div>
        </div>

        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CE 11%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">EEE 15%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">BT 8%</div>
            </div>
        </div>



    </div>



  </div>


  <div className="col-md-4 progress-seperator">


  <div class="row">


  <div className="col-md-12 mb-3">

<div class="stats" style={{border:"2px solid gray"}}>
    <div className="row">
    <div className="col-md-12 text-center"><h3 style={{color:"#000"}}><strong style={{color:"#000"}}>M.Tech </strong><small>Total No of Students</small></h3> <h4 className="text-center"> 584 </h4></div>

    </div>

    </div>
    </div>






        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CH 3%</div>
            </div>
        </div>
        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">ME  20%</div>
            </div>
        </div>

        


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CSE 10%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">ECE 15%</div>
            </div>
        </div>

        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CE 33%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">EEE 16%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">BT 3%</div>
            </div>
        </div>



    </div>




  </div>


  <div className="col-md-4 ">


  <div class="row">

    <div className="col-md-12 mb-3">

  <div class="stats" style={{border:"2px solid gray"}}>
      <div className="row">
      <div className="col-md-12 text-center"><h3 style={{color:"#000"}}><strong style={{color:"#000"}}>Integrated MSc </strong><small>Total No of Students</small></h3> <h4 className="text-center"> 43 </h4></div>

      </div>
 
      </div>
      </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">Maths 37%</div>
            </div>
        </div>
        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">PHY  30%</div>
            </div>
        </div>

        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CHE 33%</div>
            </div>
        </div>


        



    </div>


  </div>
</div>

						
            
            
            
            </div>






            

<div class="row">


<header>
            <h2>Batch Demographics 2026</h2>
            <h3>Program wise distribution</h3>
            </header>
<div class="col col-md-8">




  B.Tech <span class="pull-right strong">989</span>
   <div class="progress">
    <div class="progress-bar bg-primary"  style={{width:"82%"}}></div>
  </div>

  M.Tech <span class="pull-right strong">584</span>
   <div class="progress">
    <div class="progress-bar bg-primary"  style={{width:"48%"}}></div>
  </div>

  Integrated M.Sc <span class="pull-right strong">43</span>
   <div class="progress">
    <div class="progress-bar bg-primary"  style={{width:"3.5%"}}></div>
  </div>

  MCA <span class="pull-right strong">58</span>
   <div class="progress">
    <div class="progress-bar bg-primary"  style={{width:"4.8%"}}></div>
  </div>


  MBA <span class="pull-right strong">39</span>
   <div class="progress">
    <div class="progress-bar bg-primary" style={{width:"3.2%"}}></div>
  </div>

   


</div>

<div className="col-md-4">





<div class="row">
<div class="col-md-12">
<div class="tile">
  
      <header><h3>Gender Ratio</h3></header>

      

      

      <div class="stats">
      <div className="row">
      <div className="col-md-6 col-6 text-center"><i class="fa fa-male fa-5x" aria-hidden="true"></i>  <h4 className="text-center"> 77%  </h4></div>
      <div className="col-md-6 col-6 text-center"><i class="fa fa-female fa-5x" aria-hidden="true"></i>  <h4 className="text-center"> 23%  </h4></div>
      </div>
 
      </div>

      <div class="stats">
      <div className="row">
      <div className="col-md-12 text-center"><strong>TOTAL STUDENTS </strong> <h4 className="text-center"> 1713 </h4></div>

      </div>
 
      </div>

      

      

      
  
</div> 
</div>


</div>
    
 




</div>




<div className="row">
<header>
                  
                  <h3>Branch wise distribution</h3>
                  </header>
  <div className="col-md-4 progress-seperator">

  <div class="row">

  <div className="col-md-12 mb-3">

<div class="stats" style={{border:"2px solid gray"}}>
    <div className="row">
    <div className="col-md-12 text-center"><h3 style={{color:"#000"}}><strong style={{color:"#000"}}>B.Tech </strong><small>Total No of Students</small></h3> <h4 className="text-center"> 989</h4></div>

    </div>

    </div>
    </div>





        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CH 11%</div>
            </div>
        </div>
        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">ME  14%</div>
            </div>
        </div>

        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">MME 8%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CSE 17%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">ECE 17%</div>
            </div>
        </div>

        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CE 11%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">EEE 14%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">BT 8%</div>
            </div>
        </div>



    </div>



  </div>


  <div className="col-md-4 progress-seperator">


  <div class="row">


  <div className="col-md-12 mb-3">

<div class="stats" style={{border:"2px solid gray"}}>
    <div className="row">
    <div className="col-md-12 text-center"><h3 style={{color:"#000"}}><strong style={{color:"#000"}}>M.Tech </strong><small>Total No of Students</small></h3> <h4 className="text-center"> 584 </h4></div>

    </div>

    </div>
    </div>






        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CH 3%</div>
            </div>
        </div>
        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">ME  20%</div>
            </div>
        </div>

        


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CSE 10%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">ECE 15%</div>
            </div>
        </div>

        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CE 33%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">EEE 16%</div>
            </div>
        </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">BT 3%</div>
            </div>
        </div>



    </div>




  </div>


  <div className="col-md-4">


  <div class="row">

    <div className="col-md-12 mb-3">

  <div class="stats" style={{border:"2px solid gray"}}>
      <div className="row">
      <div className="col-md-12 text-center"><h3 style={{color:"#000"}}><strong style={{color:"#000"}}>Integrated MSc </strong><small>Total No of Students</small></h3> <h4 className="text-center"> 43 </h4></div>

      </div>
 
      </div>
      </div>


        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">Maths 37%</div>
            </div>
        </div>
        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">PHY  30%</div>
            </div>
        </div>

        <div class=" col-sm-6 col-6">
            <div class="rounded-progress green">
                <span class="rounded-progress-left">
                    <span class="rounded-progress-bar"></span>
                </span>
                <span class="rounded-progress-right">
                    <span class="rounded-progress-bar"></span>
                </span>
                <div class="rounded-progress-value">CHE 33%</div>
            </div>
        </div>


        



    </div>


  </div>
</div>




</div>







                 {/* <BarChart/> */}


                  
                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-stdactivities' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-stdactivities" role="tabpanel"
                  aria-labelledby="v-pills-stdactivities-tab">
                  <h2 class="ccpd_header">Technical Activities</h2>
                  <p>NITW is not just a place of academic excellence but also a hub for innovation, creativity, and technical
prowess. Our campus hosts a vibrant ecosystem of technical clubs like robotics club, students developers
club, business club, innovation garage, Finwiz, EA & HAM, TEDxNITW, Quiz club, etc that play a crucial role
in shaping the future of our students. These clubs provide a platform for students to explore, experiment,
and enhance their technical skills by conducting different hackathons, coding competitions, group
contests, quizzes and many more fostering a sense of community and collaboration.
</p>


<div class="row nitw_ccpd">
  
    
    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Innovation Garage</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">
        <img src="CCPDAssets/images/1.jpg" class="img-fluid" />
          <p>People working on ideas to provide the necessary
facilities- mentorship, seed funding & and resources to
convert innovative ideas into independent business
models or products in line with our entrepreneurial
vision.
</p>
          
        </div>
      </div>

    </div>


    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Robotics Club</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">

        <img src="CCPDAssets/images/2.jpg" class="img-fluid" />
          <p>The Robotics Club was established to
introduce robotics to NITW, inspire enthusiasts
to pursue it as a career and create a supportive
community. We achieve this through workshops,
training, and competitions in Robotics contests
held across India each year, sessions,
presentations, and case-based learning.
</p>
          
        </div>
      </div>

    </div>





    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Business and Finance Club</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">

        <img src="CCPDAssets/images/7.jpg" class="img-fluid" /> 
          <p>The BUSINESS CLUB is a student group
dedicated to promoting financial literacy through
interactions, discussions, presentations, and casebased learning. It emerged from the need for
financial education and fosters open discussions
about business and finance topics.
</p>
          
        </div>
      </div>

    </div>





    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">EA & HAM</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">

        <img src="CCPDAssets/images/3.jpg" class="img-fluid" />
          <p>A group of techies who love to explore new
domains of technology that aim to attain and
impart knowledge. Members of EA & HAM are
working in Data Science, Machine Learning,
Robotics, Blockchain, Websites and Apps
Development, IOT, Core Hardware and software
oriented fields and many more</p>
          
        </div>
      </div>

    </div>





  
                  </div>






                  <h2 class="ccpd_header">Cultural Activities</h2>
                  <p>The demanding academic and extracurricular environment at NIT Warangal keeps students
active, but it's essential to take time to relax and recharge. Cultural clubs at NITW provide the
perfect forum for this, helping students discover and showcase their talents through various
competitions, talent hunts, social events, and workshops. Clubs include the Dance and Dramatics
Club, Photography Club, Music Club, Film Committee, Media Club, Yoga Club, Nature Club,
Painting Club, and more. The cultural fest, Spring Spree, attracts young talents from across the
country, offering a vibrant platform for performance and skill showcasing.
</p>


<div class="row nitw_ccpd">
  
    
    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Music club</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">

        <img src="CCPDAssets/images/4.jpg" class="img-fluid" />
          <p>The music club is a society of music enthusiasts
living and experimenting through various forms of of
music. The club provides students with a stage to
perform, to get rid of their stage fear, and to hone their
stage presentation skills.</p>
          
        </div>
      </div>

    </div>


    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Dance and dramatics club</h3 >
          
          
        </div>
        <div class="nitw_ccpd-card-body">

        <img src="CCPDAssets/images/5.jpg" class="img-fluid" />
          <p>The club is the flag bearer of the rich culture
of drama and street drama play at NIT Warangal. It is
a family of theatre lovers and dancing enthusiasts.
The club always aspires to be the real mirror of the
society.</p>
          
        </div>
      </div>

    </div>





    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Photography club</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">
        <img src="CCPDAssets/images/6.jpg" class="img-fluid" />
          <p>The main objective of the club is to bring
together all photography enthusiasts and lead them
towards collective learning through workshops,
photo walks and and other activities.
</p>
          
        </div>
      </div>

    </div>





    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Literary and Debating
          club</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">

        <img src="CCPDAssets/images/8.jpg" class="img-fluid" /> 
          <p>The club has been striving hard to drive the
creative youth towards literature and arts through its
events and workshops. It has been focussing on the
importance of communication, presentation, and
involvement in creative vision through various events
like debates and ice-breaking sessions.</p>
          
        </div>
      </div>

    </div>





  
                  </div>





                  <h2 class="ccpd_header">Social Activities</h2>
                  <p>Some social clubs like Nature Club, NSS NITW, NCC NITW, Youth Red Cross Club, and Value
Education Club are also there to enlighten students about community welfare and foster growth
among them. SpringSpree is the cultural fest of NIT, Warangal filled with exuberance, attracting
a large pool of young talents from all over the country to perform, showcase their skills, and
triumph in the spotlight.</p>


<div class="row nitw_ccpd">
  
    
    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Nature Club</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">
        <img src="CCPDAssets/images/9.jpg" class="img-fluid" />
          <p>With the motto of "We care, we share", the
Nature Club encourages students who join to take
part in various conservation activities and guides
them to have an appreciation for nature.</p>
          
        </div>
      </div>

    </div>


    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">NSS NITW</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">
        <img src="CCPDAssets/images/10.jpg" class="img-fluid" />
          <p>The NSS unit organizes several events,
campaigns, awareness programs, and drives inside
as well as outside the campus imbuing volunteers
with the idea of 'Not me, but you'.</p>
          
        </div>
      </div>

    </div>





    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">NCC NITW</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">
        <img src="CCPDAssets/images/11.jpg" class="img-fluid" />
          <p>Embracing the notion of Unity and
Discipline, NCC NITW is a government organization
exclusively for the students of NIT, Warangal.</p>
          
        </div>
      </div>

    </div>





    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Youth Red Cross Club</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">
        <img src="CCPDAssets/images/12.jpg" class="img-fluid" />
          <p>Youth Red Cross being a techno-social
hub, awakens the social responsibilities of
young engineers.</p>
          
        </div>
      </div>

    </div>




    <div class="col-md-6 individual-card">
      <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
        <div class="nitw_ccpd-card-top">
          <h3 class="nitw_ccpd-card-title">Value Education Club</h3>
          
          
        </div>
        <div class="nitw_ccpd-card-body">
        <img src="CCPDAssets/images/13.jpg" class="img-fluid" />
          <p>Center for Value Education, NIT Warangal promotes
education, keeping values and ethics in mind.
</p>
          
        </div>
      </div>

    </div>





  
                  </div>
























                  
                </div>



                <div className={`tab-pane ${activeTab === 'v-pills-recruiters' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-recruiters" role="tabpanel"
                  aria-labelledby="v-pills-recruiters-tab">
                  





                 

	<div className="mb-3" >
	<h4>A</h4>
	<ul className="recruiters-list">
		<li>Akash Byju</li>
<li>Aarvee Associates         </li>
<li>Accelarise360</li>
<li>Accenture</li>
<li>Accolite</li>
<li>Addverb</li>
<li>Adobe</li>
<li>Afcons</li>
<li>Amadeus</li>
<li>Amazon</li>
<li>AWS</li>
<li>AMD</li>
<li>AMD Hyderabad</li>
<li>Amdocs</li>
<li>American Express</li>
<li>Analog Devices</li>
<li>Analog Devices Inc. </li>
<li>Apollo 24/7 </li>
<li>Applied Materials </li>
<li>Aquasecurity </li>
<li>ArcadisArcesium </li>
<li>Arista Network </li>
<li>Ather Energy </li>
<li>Avanti </li>
<li>Axis Bank </li>
<li>Axis My India </li>
<li>Axtria    </li>
	</ul>
  </div>
	

  <div className="mb-3" >
	<h4>B</h4>
	<ul className="recruiters-list">
<li>Bajaj Auto            </li>
<li>Bajaj Auto Limited</li>
<li>Barclays</li>
<li>Base Education</li>
<li>Baxter</li>
<li>Becton Dickinson</li>
<li>Belzabir</li>
<li>BEL</li>
<li>Biocon</li>
<li>BizAcuity</li>
	</ul>
  </div>

  <div className="mb-3" >
  <h4>C</h4>
  <ul className="recruiters-list">
<li>C Dot(PSU)                    </li>
<li>C_DOT</li>
<li>Cadence</li>
<li>Cairn(Vedanta)</li>
<li>Cashfree</li>
<li>Caterpillar</li>
<li>Ceremorphic</li>
<li>Chetak Technology Limited</li>
<li>Chronus</li>
<li>Citi Bank</li>
<li>Citrix</li>
<li>CloudAngles</li>
<li>Cogoport</li>
<li>Collins Aerospace</li>
<li>Controlytics</li>
<li>Cummins</li>
<li>Cyient</li>
  </ul>
</div>



<div className="mb-3" >
  <h4>D</h4>
  <ul className="recruiters-list">
<li>D E Shaw          </li>
<li>Daimler rucks</li>
<li>Danieli </li>
<li>Datawrkz</li>
<li>DeliverooDELL</li>
<li>Deloitte</li>
<li>Deutsche Bank</li>
<li>DP World</li>
<li>Dr. Reddy's Labs</li>
<li>Druva</li>
</ul>
</div>




<div className="mb-3" >
  <h4>E</h4>
  <ul className="recruiters-list">

<li>Edgistfy      </li>
<li>Elucidata</li>
<li>Emergy</li>
<li>Emphase</li>
<li>Energy</li>
<li>Everest</li>
<li>Excellencia</li>
<li>EXL</li>
<li>ExxonMobil </li>
<li>EY GDS </li>
<li>EY INDIA</li>



</ul>
</div>


<div className="mb-3" >
  <h4>F</h4>
  <ul className="recruiters-list">

<li>Factspan analytics    </li>
<li>Federal Bank</li>
<li>FIITJEE</li>
<li>Fischer Jordan</li>
<li>Fluence Energy</li>
<li>Fractal</li>

</ul>
</div>


<div className="mb-3" >
  <h4>G</h4>
  <ul className="recruiters-list">


  <li>GAIL                    </li>
  <li>Ganit Business Sol </li>
  <li>GE Digital </li>
  <li>GE HealthCare </li>
  <li>Genpact </li>
  <li>GEPGKN </li>
  <li>Global Logic Hitachi </li>
  <li>Godrej Properties </li>
  <li>Goldman Sachs </li>
  <li>GREENKO</li>

</ul>
</div>


<div className="mb-3" >
  <h4>H</h4>
  <ul className="recruiters-list">

<li>Havells                       </li>
<li>HCL</li>
<li>HCL Tech</li>
<li>HDFC</li>
<li>HERE</li>
<li>Hero Fincorp</li>
<li>Hexagon Capability centre </li>
<li>Hindustan Unilever</li>
<li>Hitachi </li>
<li>Hitachi Energy</li>
<li>Honey Well </li>
<li>HPCL </li>
<li>HSBC</li>

</ul>
</div>


<div className="mb-3" >
  <h4>I</h4>
  <ul className="recruiters-list">


  <li>IBM                       </li>
  <li>ICICI </li>
  <li>ICICI Prudential Life </li>
  <li>Insurance </li>
  <li>IDBI </li>
  <li>Idea Forge </li>
  <li>Ikarus 3D </li>
  <li>Impact Analytics </li>
  <li>Incture Infineon </li>
  <li>Infinity Learn </li>
  <li>Infor </li>
  <li>Intuit </li>
  <li>Iqvia </li>
  <li>ITC</li>


</ul>
</div>


<div className="mb-3" >
  <h4>J</h4>
  <ul className="recruiters-list">


<li>Jacobs            </li>
<li>JIO</li>
<li>John Deere</li>
<li>Johnson Matthey</li>
<li>JPMC</li>
<li>Justdial</li>

</ul>
</div>


<div className="mb-3" >
  <h4>K</h4>
  <ul className="recruiters-list">


<li>Kagool                        </li>
<li>Khumbu Systems</li>
<li>Kolamandalam MSR Service Ltd. </li>
<li>Kotak Mahindra Bank</li>
<li>Kubeangles</li>

</ul>
</div>




<div className="mb-3" >
  <h4>L</h4>
  <ul className="recruiters-list">


<li>L&T                     </li>
<li>L&T Infrastructure</li>
<li>Legato</li>
<li>Leoforce</li>
<li>Lookout</li>
<li>Loyality Juggernaut</li>


</ul>
</div>




<div className="mb-3" >
  <h4>M</h4>
  <ul className="recruiters-list">

<li>Magicbricks                     </li>
<li>Mahindra and Mahindra</li>
<li>MAQ Software</li>
<li>Maruti Suzuki </li>
<li>MasterCard </li>
<li>Mathworks </li>
<li>MBRDI </li>
<li>Mechanical </li>
<li>MediaTek </li>
<li>MediaTek Technology banglore </li>
<li>Merilytics </li>
<li>Microchip </li>
<li>Micron </li>
<li>Microsoft </li>
<li>Morgan Stanley</li>




</ul>
</div>


<div className="mb-3" >
  <h4>N</h4>
  <ul className="recruiters-list">


<li>Narayana        </li>
<li>Natwest</li>
<li>Netcracker</li>
<li>Netradyne</li>
<li>Novartis</li>
<li>Numeros Motors</li>
<li>NUTANIX</li>
<li>NXP</li>

</ul>
</div>


<div className="mb-3" >
  <h4>O</h4>
  <ul className="recruiters-list">


<li>09 SOLUTIONS      </li>
<li>OFSS</li>
<li>Oliver Wyman</li>
<li>Oracle</li>
<li>Orient</li>
<li>Electric</li>

</ul>
</div>


<div className="mb-3" >
  <h4>P</h4>
  <ul className="recruiters-list">


<li>Paytm               </li>
<li>PayU</li>
<li>Pepperfry</li>
<li>PharmaACE</li>
<li>Physics Wallah</li>
<li>Pine Labs</li>
<li>Piramal Group</li>
<li>Providence</li>
<li>Publicissapient</li>
<li>PV Insights</li>
<li>PWC India</li>

</ul>
</div>


<div className="mb-3" >
  <h4>Q</h4>
  <ul className="recruiters-list">


<li>Qualcomm              </li>
<li>Quantiphi Analytics </li>
<li>QuickSell </li>
<li>Quixy</li>

</ul>
</div>


<div className="mb-3" >
  <h4>R</h4>
  <ul className="recruiters-list">


<li>  Radisys           </li>
<li>Ramboll </li>
<li>RAPIDO </li>
<li>Regal Rexnord</li>
<li>Reliance</li>
<li>Reliance Industry</li>
<li>Reliance Retail </li>
<li>Resonance</li>

</ul>
</div>


<div className="mb-3" >
  <h4>S</h4>
  <ul className="recruiters-list">



<li>  Saarthi                             </li>                         
<li>  SaaS Labs</li>
<li>Salesforce</li>
<li>Samagra Governance</li>
<li>Samsung</li>
<li>Samsung Electronics</li>
<li>SAP Labs</li>
<li>Sapiens</li>
<li>Saras Analytics</li>
<li>SBI Life</li>
<li>Schlumberger</li>
<li>Schneider Electric</li>
<li>Sedemac</li>
<li>Servicenow</li>
<li>Siemens</li>
<li>Simerics</li>
<li>Simplotel </li>
<li>Siris Energy Solution Pvt. Ltd</li>
<li>SISW</li>
<li>Spectrum techno consultancy</li>
<li>Sri Chaitanya</li>
<li>Synopsys</li>
<li>SYSTRA</li>







</ul>
</div>


<div className="mb-3" >
  <h4>T</h4>
  <ul className="recruiters-list">



<li>  Tata Iqvia            </li>
<li>Tata Motors</li>
<li>Tata Projects Limited</li>
<li>TCE</li>
<li>TCS</li>
<li>Texas Instruments</li>
<li>ThoughtSpot</li>
<li>Trane</li>
<li>TRUMINDS</li>
<li>TVS</li>

</ul>
</div>


<div className="mb-3" >
  <h4>U</h4>
  <ul className="recruiters-list">


<li>  UBER            </li>
<li>United Breweries</li>
<li>Upgrad Insofe</li>

</ul>
</div>


<div className="mb-3" >
  <h4>V</h4>
  <ul className="recruiters-list">

<li>  Valeo         </li>
<li>VCONSTRUCT</li>
<li>VECV</li>
<li>Vedanta</li>
<li>Vesuvius</li>
<li>VISA</li>
<li>Vistex</li>
<li>Vodafone</li>


</ul>
</div>


<div className="mb-3" >
  <h4>W</h4>
  <ul className="recruiters-list">


<li>  WabTec          </li>
<li>WCB Robotics</li>
<li>Wells Fargo</li>
<li>Western Digital </li>
<li>WIPRO </li>
<li>WSP</li>


</ul>
</div>










<div className="mb-3" >
  <h4>Z</h4>
  <ul className="recruiters-list">

<li>Zentree           </li>
<li>Zentree Labs</li>
<li>ZF</li>
<li>Zifo R&D</li>
<li>ZL Technologies</li>
<li>Zscaler</li>

</ul>
</div>









                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-contact' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-contact" role="tabpanel"
                  aria-labelledby="v-pills-recruiters-tab">
                  


                  <div className="col-md-12 profile-info-individual">
                  <div className="row">
                  {/* <div className="col-md-3"> 
                  <a href="https://erp.nitw.ac.in/ext/profile/ma-tpshukla" target="_blank"> 
                  <img src="CCPDAssets/images/harikumar.jpg" alt="" className="img-fluid" /> 
                  </a> 
                 </div> */}
                  <div className="col-md-9">
                  <div className="card ">
                  <div className="data">
                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-tpshukla" target="_blank" style={{fontSize:"1.5rem"}}>Prof. V Hari Kumar</a>
                  </h5> <span style={{color:"gray"}}>Professor</span><br/><span>Head of Centre for Career Planning &
                  Development</span>
                  <hr/>
                  <p className="contact-info" style={{marginBottom:"0px"}}>
                 <i className="fa fa-mobile fa-lg"
                  aria-hidden="true"></i> 94901 65357</p> 
                  <p style={{marginBottom:"0px"}}><i className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:taps[AT]nitw[DOT]ac[DOT]in">taps[AT]nitw[DOT]ac[DOT]in</a> , <a
                  href="mailto:ccpd_hod[AT]nitw[DOT]ac[DOT]in">ccpd_hod[AT]nitw[DOT]ac[DOT]in</a></p>
                 <p> <i class="fa fa-linkedin" aria-hidden="true"></i> <a href="https://www.linkedin.com/in/training-placement-section-nit-warangal/" target="_blank"> Training & Placement Section NIT Warangal </a>

          </p>
        </div>
      </div>
    </div>
  </div>
</div>






                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-ccpdteam' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-ccpdteam" role="tabpanel"
                  aria-labelledby="v-pills-ccpdteam-tab">
                 





 <section id="profile-info">

<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/me-jd" target="_blank"> 
      <img src="CCPDAssets/images/joseph.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-jd" target="_blank" style={{fontSize:"1.5rem"}}>Prof. M Joseph Davidson</a>
          </h5> <span style={{color:"gray"}}>Professor</span><br/><span>Department of Mechanical Engineering</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-246<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 8332969324 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:jd[AT]nitw[DOT]ac[DOT]in">jd[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>



<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj" target="_blank"> 
      <img src="CCPDAssets/images/sevdasuraj.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Surajbhan Sevda</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Biotechnology</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2464206<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9929565697 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:sevdasuraj[AT]nitw[DOT]ac[DOT]in">sevdasuraj[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>



<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/ch-rajmohan" target="_blank"> 
      <img src="CCPDAssets/images/ks_rajmohan.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-rajmohan" target="_blank" style={{fontSize:"1.5rem"}}>Prof. K S Raj Mohan</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Chemical Engineering</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2462631<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9994646610 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:rajmohan[AT]nitw[DOT]ac[DOT]in">rajmohan[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/cs-venkat.kagita" target="_blank"> 
      <img src="CCPDAssets/images/venkat_k.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-venkat.kagita" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Venkateswara Rao Kagita</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Computer Science and Engineering</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2464279<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9966456713 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:venkat[DOT]kagita[AT]nitw[DOT]ac[DOT]in">venkat[DOT]kagita[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>






<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/ec-g.arun" target="_blank"> 
      <img src="CCPDAssets/images/gande_arun.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-g.arun" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Gande Arun Kumar</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Electronics and Communication Engineering</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2462429<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9477304602 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:g[DOT]arun[AT]nitw[DOT]ac[DOT]in">g[DOT]arun[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/ce-skyamsani" target="_blank"> 
      <img src="CCPDAssets/images/sudheer_yamsani.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-skyamsani" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Sudheer Kumar Yamsani</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Civil Engineering</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2462122<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 8486367769 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:skyamsani[AT]nitw[DOT]ac[DOT]in">skyamsani[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>



<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/mm-bharathb" target="_blank"> 
      <img src="CCPDAssets/images/bharathb.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-bharathb" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Bharath Bandi</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Metallurgical and Materials Engineering</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-246<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 7918757323 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:bharathb[AT]nitw[DOT]ac[DOT]in">bharathb[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/sm-prcgopal" target="_blank"> 
      <img src="CCPDAssets/images/prcgopal.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-prcgopal" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Ramachandra Gopal P</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Management Studies</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2462875<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9843716992 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:prcgopal[AT]nitw[DOT]ac[DOT]in">prcgopal[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/ee-pmishra" target="_blank"> 
      <img src="CCPDAssets/images/pmishra.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-pmishra" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Palash Mishra</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Electrical Engineering</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2464246<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9566160482 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:pmishra[AT]nitw[DOT]ac[DOT]in">pmishra[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>



<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/me-vijaychoyal" target="_blank"> 
      <img src="CCPDAssets/images/vijaychoyal.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-vijaychoyal" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Vijay Choyal</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Mechanical Engineering</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2462339<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9303505188 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:vijaychoyal[AT]nitw[DOT]ac[DOT]in">vijaychoyal[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>



<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/cy-jugun" target="_blank"> 
      <img src="CCPDAssets/images/jugun.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-jugun" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Jugun Prakash Chinta</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Chemistry</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-246<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9898625503 <br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:jugun[AT]nitw[DOT]ac[DOT]in">jugun[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/ph-bhitesh" target="_blank"> 
      <img src="CCPDAssets/images/bhitesh.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-bhitesh" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Hitesh Borkar</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Physics</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-2462582<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 8447287127<br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:bhitesh[AT]nitw[DOT]ac[DOT]in">bhitesh[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="col-md-12 profile-info-individual">
  <div className="row">
    <div className="col-md-3"> 
      <a href="https://erp.nitw.ac.in/ext/profile/ma-tpshukla" target="_blank"> 
      <img src="CCPDAssets/images/tpshukla.jpg" alt="" className="img-fluid" /> 
      </a> 
    </div>
    <div className="col-md-9">
      <div className="card ">
        <div className="data">
          <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-tpshukla" target="_blank" style={{fontSize:"1.5rem"}}>Prof. Triveni Prasad Shukla</a>
          </h5> <span style={{color:"gray"}}>Assistant Professor</span><br/><span>Department of Mathematics</span>
          <hr/>
          <p className="contact-info">
            <i className="fa fa-phone"
              aria-hidden="true"></i> +91-870-246<br />
            <i className="fa fa-mobile fa-lg"
              aria-hidden="true"></i> 9702961319<br /> <i
                className="fa fa-envelope" aria-hidden="true"></i> <a
                  href="mailto:tpshukla[AT]nitw[DOT]ac[DOT]in">tpshukla[AT]nitw[DOT]ac[DOT]in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>







</section>








                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">
                  <div className="row">


                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            
                            <h2 className="ccpd_header">UG Programmes</h2>
                            
                            




                            <div class="row nitw_ccpd">
  
    
  <div class="col-md-6 individual-card">
    <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
      <div class="nitw_ccpd-card-top">
        <h3 class="nitw_ccpd-card-title">B.Tech</h3>
        <p><b>The Admission is based on JEE Mains</b></p>
        
        
      </div>
      <div class="nitw_ccpd-card-body">

      
        <ul>
         

        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Electrical engineering                      </li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Computer Science & Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Mechanical Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Civil Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Electronics & Communication Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Metallurgical & Materials Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Biotechnology</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Chemical Engineering</li>
        </ul>
        
      </div>
    </div>

  </div>




  <div class="col-md-6 individual-card">
    <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
      <div class="nitw_ccpd-card-top">
        <h3 class="nitw_ccpd-card-title">Integrated M.Sc.</h3>
        <p><b>The Admission is based on JEE Mains</b></p>
        
      </div>
      <div class="nitw_ccpd-card-body">

      
        <ul>
         

        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Mathematics </li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Physics</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Chemistry</li>
       
        </ul>
        
      </div>
    </div>

  </div>








  <div class="col-md-12 individual-card">
    <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
      <div class="nitw_ccpd-card-top">
        <h3 class="nitw_ccpd-card-title">Minor Programs</h3>
        <p><b>A minor is an extra credential a B.Tech. Student can earn by
completing 18 credits in a different discipline, with each academic unit
prescribing specific courses or projects required for the minor.</b></p>
        
      </div>
      <div class="nitw_ccpd-card-body">


      
        <ul className="recruiters-list">
         
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Civil Engineering </li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Electrical Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Metallurgical & Materials Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Computer Science & Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Chemical Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> ECE for Non circuit Branches</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Biotechnology</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Mechanical Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Mathematics</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Physics</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Chemistry</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> School of Management</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Humanities & Social Sciences</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> NCC</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Electronics & Communication Engineering(ECE)</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> ECE for circuit Branches</li>
       
        </ul>
        
      </div>
    </div>

  </div>


 












                </div>



                <h2 className="ccpd_header">PG Programmes</h2>



                <div class="row nitw_ccpd">
  
    
  <div class="col-md-12 individual-card">
    <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
      <div class="nitw_ccpd-card-top">
        <h3 class="nitw_ccpd-card-title">M.Tech</h3>
        <p><b>The Admission is based on GATE</b></p>
        
      </div>
      <div class="nitw_ccpd-card-body">
      
        <div className="row">
      <div class="col-md-6 mb-3">
        <h5>Civil Engineering</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Construction Technology and Management</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Engineering Structures</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Environmental Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Geotechnical Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Remote Sensing & GIS</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Transportation Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Water Resources Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> Waste Management</li>
        </ul>
    

  </div>



  <div class="col-md-6 mb-3">
        <h5>Mechanical Engineering</h5>

        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Thermal Engineering   </li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Manufacturing Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Computer Integrated Manufacturing</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Machine Design</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Automobile Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Materials and Systems Engineering Design</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Additive Manufacturing</li>
        </ul>

  </div>




  <div class="col-md-6 mb-3">
        <h5>Electrical Engineering</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Power Electronics and Drives</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Power Systems Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Smart Electric Grid</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Control and Automation</li>
        </ul>
  </div>


  <div class="col-md-6 mb-3">
        <h5>Biotechnology</h5>

        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Biotechnology</li>
        </ul>   

  </div>


  <div class="col-md-6 mb-3">
        <h5>Mechanical Engineering</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Thermal Engineering   </li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Manufacturing Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Computer Integrated Manufacturing</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Machine Design</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Automobile Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Materials and Systems Engineering Design</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Additive Manufacturing</li>
    
        </ul>
  </div>



  <div class="col-md-6 mb-3">
        <h5>Electronics and Communication Engineering</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Electronic Instrumentation and Embedded Systems</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  VLSI-System Design</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Advanced Communication Systems</li>
        </ul>
  </div>


  <div class="col-md-6 mb-3">
        <h5>Metallurgical and Materials Engineering</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Industrial Metallurgy</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Materials Technology</li>
        </ul>
  </div>


  <div class="col-md-6 mb-3">
        <h5>Chemical Engineering</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Chemical Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Systems and Control Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Sustainable Energy Generation & Storage Technologies</li>
        </ul>
  </div>


  <div class="col-md-6 mb-3">
        <h5>Computer Science and
        Engineering</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Computer Science and Engineering</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Computer Science and Information Security</li>
        </ul>
  </div>









  </div>






       
        
      </div>
    </div>

  </div>




  <div class="col-md-12 individual-card">
    <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
      <div class="nitw_ccpd-card-top">
        <h3 class="nitw_ccpd-card-title">M.Sc</h3>
        <p><b>The admission based on IIT Jam</b></p>
        
      </div>
      <div class="nitw_ccpd-card-body">

      
       <div className="row">
        <div className="col-md-4">
        <h5>Mathematics</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  M.Sc. (Applied Mathematics)</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  M.Sc. (Mathematics & Scientific Computing)</li>
        </ul>
        </div>
        <div className="col-md-4">
        <h5>Physics</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  M.Sc.(Tech) Engineering Physics</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Integrated M.Sc. Physics</li>
        </ul>
        </div>
        <div className="col-md-4">

        <h5>Chemistry</h5>
        <ul>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  M.Sc. (Analytical Chemistry)</li>
        <li><i class="fa fa-angle-double-right" aria-hidden="true"></i>  M.Sc. (Organic Chemistry)</li>
        </ul>
        </div>
       </div>
        
      </div>
    </div>

  </div>








  <div class="col-md-6 individual-card">
    <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
      <div class="nitw_ccpd-card-top">
        <h3 class="nitw_ccpd-card-title">MBA</h3>
        <p><b>The admissions are done on the basis of Common Admission
      Test (CAT) scores.</b></p>
        
      </div>
      
    </div>

  </div>



  <div class="col-md-6 individual-card">
    <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
      <div class="nitw_ccpd-card-top">
        <h3 class="nitw_ccpd-card-title">MCA</h3>
        <p><b>The admission to this programme is based on NIMCET .The
      course duration is three years.</b></p>
        
      </div>
      
    </div>

  </div>


  <div class="col-md-6 individual-card">
    <div class=" nitw_ccpd-card nitw_ccpd-card_bg">
      <div class="nitw_ccpd-card-top">
        <h3 class="nitw_ccpd-card-title">Ph.D.</h3>
        <p><b>Every academic department is actively involved in Ph.D.
      programs.</b></p>
        
      </div>
    </div>

  </div>


 












                </div>


                            
                          </div>
                          
                        </div>
                      </div>
                    </div>


                    



                   




                   



                    

                  </div>

                </div>
                

                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                
                
                <div className={`tab-pane ${activeTab === 'v-pills-placementstats' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-placementstats" role="tabpanel"
                  aria-labelledby="v-pills-placementstats-tab">
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                    <a className={`nav-item subtabs tab-bold nav-link ${placementRecordTab === 'placementstats' ? 'active' : ''} `} id="nav-placementstats-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-placementstats"
                      aria-selected="true" onClick={() => { handlePlacementRecordTabClick("placementstats") }}>Placement Statistics</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${placementRecordTab === 'placementpolicy' ? 'active' : ''} `}
                      id="nav-placementpolicy-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-placementpolicy" aria-selected="false" onClick={() => { handlePlacementRecordTabClick("placementpolicy") }}> Placement Policy</a>






                  </div>
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    
                    
                    <div className={`tab-pane ${placementRecordTab === 'placementstats' ? 'show active' : ''} fade`} id="nav-placementstats" role="tabpanel"
                      aria-labelledby="nav-placementstats-tab">
                    

                   
                            <div class="row">
                            
                            
                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/AY_2022-23_2024-3-19-9-49-2.pdf" target="_blank">Placement Statistics for the AY 2022-23   <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">28 April 2023</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/AY_2021-22_2024-3-19-9-49-45.pdf" target="_blank">Placement statistics for the AY 2021-22  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">06 Jun 2022</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/AY_2020-21_2024-3-19-9-50-17.pdf" target="_blank">Placement statistics for AY 2020-21   <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">15 Jul 2021</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/AY_2021-22_2024-3-19-9-50-47.pdf" target="_blank">Details of Placements for AY 2021-22   <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">15 Jul 2021</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/DETAILS_2019-20_2024-3-19-9-51-19.pdf" target="_blank">PLACEMENT DETAILS 2019-20   <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">18 oct 2020</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/PLACEMENT_YEAR_2019-20_2024-3-19-9-52-14.pdf" target="_blank">Placement statistics for the PLACEMENT YEAR 2019-20   <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">18 oct 2020</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/BTech_Programs_during_AY_2019-20_2024-3-19-9-52-57.pdf" target="_blank">Placement statistics of all BTech Programs during AY 2019-20  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">03 oct 2020</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Post_Graduate_Programs_for_the_past_Five_Years_2024-3-19-9-53-35.pdf" target="_blank">Placement Statistics of Post Graduate Programs for the past Five Years  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">28 oct 2019</p>
                            <hr />
                            </div>

                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Under_Graduate_Programs_for_the_past_Five_Years_2024-3-19-9-54-13.pdf" target="_blank">Placement Statistics of Under Graduate Programs for the past Five Years  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">28 oct 2019</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Detailed_Placement_Statistics_of_all_Programs_during_AY_2018-19_2024-3-21-16-22-15.pdf" target="_blank">Detailed Placement Statistics of all Programs during AY 2018-19   <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">30 Sep 2019</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Detailed_Placement_Statistics_of_all_Programs_during_AY_2017-18_2024-3-21-16-23-59.pdf" target="_blank">Detailed Placement Statistics of all Programs during AY 2017-18  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">08 Aug 2018</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Graduate_Programs_for_the_past_Four_Years_2024-3-19-9-55-40.pdf" target="_blank">Placement Statistics of Under Graduate Programs for the past Four Years  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">07 Aug 2018</p>
                            <hr />
                            </div>

                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Post_Graduate_Programs_for_the_past_Four_Years_2024-3-19-9-56-10.pdf" target="_blank">Placement Statistics of Post Graduate Programs for the past Four Years  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">07 Aug 2018</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Detailed_Placement_Statistics_of_all_Programs_during_AY_2016-17_2024-3-21-16-25-59.pdf" target="_blank">Detailed Placement Statistics of all Programs during AY 2016-17  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">6 July 2018</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Detailed_Placement_Statistics_of_all_Programs_during_AY_2015-16_2024-3-21-16-28-3.pdf" target="_blank">Detailed Placement Statistics of all Programs during AY 2015-16  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">3 July 2018</p>
                            <hr />
                            </div>

                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Detailed_Placement_Statistics_of_all_Programs_during_AY_2014-15_2024-3-21-16-29-35.pdf" target="_blank">  Detailed Placement Statistics of all Programs during AY 2014-15  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">1 July 2018</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/PG_Programs_for_the_Past_Three_Years_2024-3-19-9-57-2.pdf" target="_blank">   Placement Statistics of PG Programs for the Past Three Years  <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0">18 jun 2018</p>
                            <hr />
                            </div>


                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Graduate_Programs_for_the_Past_Three_Years_2024-3-19-9-57-50.pdf" target="_blank">   Placement Statistics of Under Graduate Programs for the Past Three Years <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            <p class="mt-0 pb-0"> 2 jun 2018</p>
                            <hr />
                            </div>


                        


                       
                        </div>
                        


                    </div>

                    <div className={`tab-pane ${placementRecordTab === 'placementpolicy' ? 'show active' : ''} fade`} id="nav-placementpolicy" role="tabpanel"
                      aria-labelledby="nav-placementpolicy-tab">

                            
                            
                            <div className="row">
                            <div class="col-sm-10 col-12 placements-stats">
                            <h5 className="mb-0"><a href="CCPDAssets/docs/Placement_Policy_NITW_2024-7-31-12-11-17.pdf" target="_blank">   Placement Policy <i class="fa fa-external-link-square" aria-hidden="true"></i></a></h5> 
                            
                            </div>
                            </div>



                    </div>

                    
                    


                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  `} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">
                  <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                    target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                      className="fa fa-link " aria-hidden="true"></i> </a>
                  <h4 className="">Ph.D. On Roll</h4> <a
                    href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                      className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                        className="fa fa-link " aria-hidden="true"></i> </a>
                </div>



                


              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      
    </>
  );
}

export default CcpdPage;