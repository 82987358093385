import React from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name

const Resultpage = () => {
    return (

        <div class="container">

            <div class="row">

                <p>


                    <h3 align='center'>
                        NATIONAL INSTITUTE OF TECHNOLOGY, WARANGAL
                    </h3>
                    <h4 align='center'>
                        Ph.D. ADMISSIONS: JULY 2024 SESSION
                    </h4>
                    <h5 align='center'><u>
                        LIST OF PROVISIONALLY SELECTED CANDIDATES</u>
                    </h5>
                    <p>
                        The interviews for the shortlisted candidates were conducted during 29th April to 2nd May, 2024. Based on overall performance, the following candidates are provisionally selected for Ph.D. admissions of July 2024 session. <b>
                            <i><u>Instructions for Physical Reporting, Fee Payment Details and Other necessary Instructions will be published shortly in the Institute Website</u></i></b>
                    </p>
                    <h5><b>Department of Civil Engineering:</b></h5>
                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24001008
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                16
                            </td>
                            <td>
                                PH24001012
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24001027
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                17
                            </td>
                            <td>
                                PH24001013
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24001030
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                18
                            </td>
                            <td>
                                PH24001023
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24001032
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                19
                            </td>
                            <td>
                                PH24001051
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24001038
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                20
                            </td>
                            <td>
                                PH24001056
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24001048
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                21
                            </td>
                            <td>
                                PH24001058
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24001050
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                22
                            </td>
                            <td>
                                PH24001059
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24001055
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                23
                            </td>
                            <td>
                                PH24001062
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9
                            </td>
                            <td>
                                PH24001060
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                24
                            </td>
                            <td>
                                PH24001075
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                10
                            </td>
                            <td>
                                PH24001096
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                25
                            </td>
                            <td>
                                PH24001095
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11
                            </td>
                            <td>
                                PH24001121
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                26
                            </td>
                            <td>
                                PH24001104
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12
                            </td>
                            <td>
                                PH24001125
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                27
                            </td>
                            <td>
                                PH24001110
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                13
                            </td>
                            <td>
                                PH24001068
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                28
                            </td>
                            <td>
                                PH24001122
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                14
                            </td>
                            <td>
                                PH24001089
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                29
                            </td>
                            <td>
                                PH24001123
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                15
                            </td>
                            <td>
                                PH24001126
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>
                   </tbody> </table>


                    <h5><b>Department of Electrical Engineering:</b></h5>


                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24002011
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                12
                            </td>
                            <td>
                                PH24002071
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24002029
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                13
                            </td>
                            <td>
                                PH24002008
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24002038
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                14
                            </td>
                            <td>
                                PH24002020
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24002039
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                15
                            </td>
                            <td>
                                PH24002024
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24002043
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                16
                            </td>
                            <td>
                                PH24002051
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24002066
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                17
                            </td>
                            <td>
                                PH24002052
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24002067
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                18
                            </td>
                            <td>
                                PH24002060
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24002087
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                19
                            </td>
                            <td>
                                PH24002061
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9
                            </td>
                            <td>
                                PH24002016
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                20
                            </td>
                            <td>
                                PH24002072
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                10
                            </td>
                            <td>
                                PH24002042
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                21
                            </td>
                            <td>
                                PH24002081
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11
                            </td>
                            <td>
                                PH24002044
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>
                   </tbody> </table>

                    <h5><b>Department of Mechanical Engineering:</b></h5>

                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24003016
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                14
                            </td>
                            <td>
                                PH24003017
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24003030
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                15
                            </td>
                            <td>
                                PH24003025
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24003038
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                16
                            </td>
                            <td>
                                PH24003029
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24003041
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                17
                            </td>
                            <td>
                                PH24003040
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24003044
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                18
                            </td>
                            <td>
                                PH24003045
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24003058
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                19
                            </td>
                            <td>
                                PH24003047
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24003061
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                20
                            </td>
                            <td>
                                PH24003053
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24003064
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                21
                            </td>
                            <td>
                                PH24003055
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9
                            </td>
                            <td>
                                PH24003080
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                22
                            </td>
                            <td>
                                PH24003056
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                10
                            </td>
                            <td>
                                PH24003050
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                23
                            </td>
                            <td>
                                PH24003063
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11
                            </td>
                            <td>
                                PH24003011
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                24
                            </td>
                            <td>
                                PH24003084
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12
                            </td>
                            <td>
                                PH24003013
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                25
                            </td>
                            <td>
                                PH24003088
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                13
                            </td>
                            <td>
                                PH24003014
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                26
                            </td>
                            <td>
                                PH24003096
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                   </tbody> </table>


                    <h5><b>Department of Electronics and Communications Engineering:</b></h5>



                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24004013
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                17
                            </td>
                            <td>
                                PH24004031
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24004015
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                18
                            </td>
                            <td>
                                PH24004032
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24004047
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                19
                            </td>
                            <td>
                                PH24004035
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24004052
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                20
                            </td>
                            <td>
                                PH24004036
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24004053
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                21
                            </td>
                            <td>
                                PH24004038
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24004075
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                22
                            </td>
                            <td>
                                PH24004043
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24004091
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                23
                            </td>
                            <td>
                                PH24004072
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24004127
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                24
                            </td>
                            <td>
                                PH24004074
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9
                            </td>
                            <td>
                                PH24004128
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                25
                            </td>
                            <td>
                                PH24004089
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                10
                            </td>
                            <td>
                                PH24004121
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                26
                            </td>
                            <td>
                                PH24004090
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11
                            </td>
                            <td>
                                PH24004059
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                27
                            </td>
                            <td>
                                PH24004099
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12
                            </td>
                            <td>
                                PH24004004
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                28
                            </td>
                            <td>
                                PH24004110
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                13
                            </td>
                            <td>
                                PH24004016
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                29
                            </td>
                            <td>
                                PH24004112
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                14
                            </td>
                            <td>
                                PH24004017
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                30
                            </td>
                            <td>
                                PH24004117
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                15
                            </td>
                            <td>
                                PH24004018
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                31
                            </td>
                            <td>
                                PH24004119
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                16
                            </td>
                            <td>
                                PH24004029
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                32
                            </td>
                            <td>
                                PH24004126
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                   </tbody> </table>




                    <h5><b>Department of Metallurgical and Materials Engineering:</b></h5>

                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24005006
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                7
                            </td>
                            <td>
                                PH24005028
                            </td>
                            <td>
                                External-ARCI
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24005025
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                8
                            </td>
                            <td>
                                PH24005033
                            </td>
                            <td>
                                External-ARCI
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24005012
                            </td>
                            <td>
                                External-ARCI
                            </td>
                            <td>
                            </td>
                            <td>
                                9
                            </td>
                            <td>
                                PH24005010
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24005015
                            </td>
                            <td>
                                External-ARCI
                            </td>
                            <td>
                            </td>
                            <td>
                                10
                            </td>
                            <td>
                                PH24005020
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24005021
                            </td>
                            <td>
                                External-ARCI
                            </td>
                            <td>
                            </td>
                            <td>
                                11
                            </td>
                            <td>
                                PH24005030
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24005026
                            </td>
                            <td>
                                External-ARCI
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>
                   </tbody> </table>



                    <h5><b>Department of Chemical Engineering:</b></h5>



                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24006012
                            </td>
                            <td>
                                DST-Inspire
                            </td>
                            <td>
                            </td>
                            <td>
                                4
                            </td>
                            <td>
                                PH24006004
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24006016
                            </td>
                            <td>
                                Under Project
                            </td>
                            <td>
                            </td>
                            <td>
                                5
                            </td>
                            <td>
                                PH24006008
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24006003
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>
                   </tbody> </table>



                    <h5><b>Department of Computer Science and Engineering:</b></h5>

                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24007066
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                28
                            </td>
                            <td>
                                PH24007142
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24007083
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                29
                            </td>
                            <td>
                                PH24007145
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24007094
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                30
                            </td>
                            <td>
                                PH24007156
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24007159
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                31
                            </td>
                            <td>
                                PH24007161
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24007174
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                32
                            </td>
                            <td>
                                PH24007175
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24007255
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                33
                            </td>
                            <td>
                                PH24007192
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24007228
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                34
                            </td>
                            <td>
                                PH24007212
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24007086
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                35
                            </td>
                            <td>
                                PH24007214
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9
                            </td>
                            <td>
                                PH24007141
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                36
                            </td>
                            <td>
                                PH24007224
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                10
                            </td>
                            <td>
                                PH24007217
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                37
                            </td>
                            <td>
                                PH24007225
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11
                            </td>
                            <td>
                                PH24007302
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                38
                            </td>
                            <td>
                                PH24007231
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12
                            </td>
                            <td>
                                PH24007014
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                39
                            </td>
                            <td>
                                PH24007244
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                13
                            </td>
                            <td>
                                PH24007016
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                40
                            </td>
                            <td>
                                PH24007247
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                14
                            </td>
                            <td>
                                PH24007025
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                41
                            </td>
                            <td>
                                PH24007251
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                15
                            </td>
                            <td>
                                PH24007029
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                42
                            </td>
                            <td>
                                PH24007254
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                16
                            </td>
                            <td>
                                PH24007030
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                PH24007256
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                17
                            </td>
                            <td>
                                PH24007047
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                44
                            </td>
                            <td>
                                PH24007283
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                18
                            </td>
                            <td>
                                PH24007051
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                45
                            </td>
                            <td>
                                PH24007285
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                19
                            </td>
                            <td>
                                PH24007063
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                46
                            </td>
                            <td>
                                PH24007287
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                20
                            </td>
                            <td>
                                PH24007064
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                47
                            </td>
                            <td>
                                PH24007292
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                21
                            </td>
                            <td>
                                PH24007084
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                48
                            </td>
                            <td>
                                PH24007293
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                22
                            </td>
                            <td>
                                PH24007090
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                49
                            </td>
                            <td>
                                PH24007295
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                23
                            </td>
                            <td>
                                PH24007098
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                50
                            </td>
                            <td>
                                PH24007297
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                24
                            </td>
                            <td>
                                PH24007099
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                51
                            </td>
                            <td>
                                PH24007298
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                25
                            </td>
                            <td>
                                PH24007114
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                52
                            </td>
                            <td>
                                PH24007299
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                26
                            </td>
                            <td>
                                PH24007120
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                53
                            </td>
                            <td>
                                PH24007304
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                27
                            </td>
                            <td>
                                PH24007122
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                54
                            </td>
                            <td>
                                PH24007309
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                   </tbody> </table>





                    <h5><b>Department of Biotechnology:</b></h5>


                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24008001
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                9
                            </td>
                            <td>
                                PH24008058
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24008010
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                10
                            </td>
                            <td>
                                PH24008060
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24008024
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                11
                            </td>
                            <td>
                                PH24008062
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24008025
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                12
                            </td>
                            <td>
                                PH24008076
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24008030
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                13
                            </td>
                            <td>
                                PH24008005
                            </td>
                            <td>
                                Under Project
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24008031
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                14
                            </td>
                            <td>
                                PH24008042
                            </td>
                            <td>
                                INSPIRE Fellowship
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24008038
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                15
                            </td>
                            <td>
                                PH24008046
                            </td>
                            <td>
                                INSPIRE Fellowship
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24008051
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>
                   </tbody> </table>




                    <h5><b>Department of Mathematics:</b></h5>



                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24011022
                            </td>
                            <td>
                                UGC
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24011029
                            </td>
                            <td>
                                INSPIRE
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24011058
                            </td>
                            <td>
                                CSIR
                            </td>
                        </tr>
                   </tbody> </table>



                    <h5><b>Department of Physics:</b></h5>



                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24009018
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                13
                            </td>
                            <td>
                                PH24009038
                            </td>
                            <td>
                                CSIR
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24009028
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                14
                            </td>
                            <td>
                                PH24009001
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24009041
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                15
                            </td>
                            <td>
                                PH24009004
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24009043
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                16
                            </td>
                            <td>
                                PH24009009
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24009054
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                17
                            </td>
                            <td>
                                PH24009010
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24009023
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                18
                            </td>
                            <td>
                                PH24009021
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24009050
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                19
                            </td>
                            <td>
                                PH24009024
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24009062
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                20
                            </td>
                            <td>
                                PH24009025
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9
                            </td>
                            <td>
                                PH24009022
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                21
                            </td>
                            <td>
                                PH24009034
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                10
                            </td>
                            <td>
                                PH24009055
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                22
                            </td>
                            <td>
                                PH24009052
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11
                            </td>
                            <td>
                                PH24009008
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                23
                            </td>
                            <td>
                                PH24009059
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12
                            </td>
                            <td>
                                PH24009056
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                24
                            </td>
                            <td>
                                PH24009061
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                   </tbody> </table>



                    <h5><b>Department of Chemistry:</b></h5>






                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24010013
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                16
                            </td>
                            <td>
                                PH24010014
                            </td>
                            <td>
                                R&amp;D Project
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24010020
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                17
                            </td>
                            <td>
                                PH24010017
                            </td>
                            <td>
                                R&amp;D Organization
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24010022
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                18
                            </td>
                            <td>
                                PH24010029
                            </td>
                            <td>
                                R&amp;D Organization
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24010023
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                19
                            </td>
                            <td>
                                PH24010038
                            </td>
                            <td>
                                R&amp;D Project
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24010031
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                20
                            </td>
                            <td>
                                PH24010059
                            </td>
                            <td>
                                R&amp;D-Inspire
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24010036
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                21
                            </td>
                            <td>
                                PH24010002
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24010039
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                22
                            </td>
                            <td>
                                PH24010005
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24010044
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                23
                            </td>
                            <td>
                                PH24010006
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9
                            </td>
                            <td>
                                PH24010046
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                24
                            </td>
                            <td>
                                PH24010008
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                10
                            </td>
                            <td>
                                PH24010033
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                25
                            </td>
                            <td>
                                PH24010011
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11
                            </td>
                            <td>
                                PH24010050
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                26
                            </td>
                            <td>
                                PH24010018
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12
                            </td>
                            <td>
                                PH24010057
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                27
                            </td>
                            <td>
                                PH24010025
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                13
                            </td>
                            <td>
                                PH24010063
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                28
                            </td>
                            <td>
                                PH24010042
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                14
                            </td>
                            <td>
                                PH24010021
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                29
                            </td>
                            <td>
                                PH24010056
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                15
                            </td>
                            <td>
                                PH24010028
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                30
                            </td>
                            <td>
                                PH24010062
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                   </tbody> </table>






                    <h5><b>Department of Management Studies:</b></h5>


                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24013012
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                9
                            </td>
                            <td>
                                PH24013033
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                PH24013057
                            </td>
                            <td>
                                Institute Fellowship
                            </td>
                            <td>
                            </td>
                            <td>
                                10
                            </td>
                            <td>
                                PH24013037
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                PH24013020
                            </td>
                            <td>
                                IF-Waiting List
                            </td>
                            <td>
                            </td>
                            <td>
                                11
                            </td>
                            <td>
                                PH24013042
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                PH24013026
                            </td>
                            <td>
                                Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                12
                            </td>
                            <td>
                                PH24013043
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                PH24013002
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                13
                            </td>
                            <td>
                                PH24013045
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                PH24013009
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                14
                            </td>
                            <td>
                                PH24013046
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7
                            </td>
                            <td>
                                PH24013010
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                                15
                            </td>
                            <td>
                                PH24013059
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8
                            </td>
                            <td>
                                PH24013011
                            </td>
                            <td>
                                Part Time
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>
                   </tbody> </table>


                    <h5><b>Department of Humanities and Social Sciences:</b></h5>

                     <table className="table table-bordered"> <tbody>
                        <tr>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                            <td>
                            </td>
                            <td>
                                Sl. No.
                            </td>
                            <td>
                                Application ID
                            </td>
                            <td>
                                Type of Selection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PH24012022
                            </td>
                            <td>
                            Self-Finance
                            </td>
                            <td>
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                PH24012026
                            </td>
                            <td>
                                Part Time
                            </td>
                        </tr>
                   </tbody> </table>


<ol>
    <li><a href='https://nitw.ac.in/api/static/files/Phd_Programme_July_Session_2024_2024-6-11-18-9-40.pdf' target='_blank'>1. Instructions and Physical Reporting schedule for the provisionally selected candidates for Phd Programme July Session 2024.</a></li>
    
</ol>




                    {/* 


List of Eligible Candidates for Written Test in the Department of CSE, M. Tech. (Self-Finance)<br></br>
 <table className="table table-bordered"> <tbody>
  <tbody>
    <tr>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
    </tr>
    <tr>
      <td>1</td>
      <td>-</td>
      <td>41</td>
      <td>-</td>
      <td>81</td>
      <td>-</td>
    </tr>
    <tr>
      <td>2</td>
      <td>-</td>
      <td>42</td>
      <td>-</td>
      <td>82</td>
      <td>-</td>
    </tr>
  
  </tbody>
</table> 


<br></br>
List of Eligible Candidates for Written Test in the Department of BT, M. Tech. (Self-Finance)
<br></br>
 <table className="table table-bordered"> <tbody>
  <tbody>
    <tr>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
    </tr>
    <tr>
      <td>1</td>
      <td>-</td>
      <td>41</td>
      <td>-</td>
      <td>81</td>
      <td>-</td>
    </tr>
    <tr>
      <td>2</td>
      <td>-</td>
      <td>42</td>
      <td>-</td>
      <td>82</td>
      <td>-</td>
    </tr>
  
  </tbody>
</table>


<br></br>
List of Eligible Candidates for Written Test in the Department of MME, M. Tech. (Self-Finance)<br></br>
 <table className="table table-bordered"> <tbody>
  <tbody>
    <tr>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
    </tr>
    <tr>
      <td>1</td>
      <td>-</td>
      <td>41</td>
      <td>-</td>
      <td>81</td>
      <td>-</td>
    </tr>
    <tr>
      <td>2</td>
      <td>-</td>
      <td>42</td>
      <td>-</td>
      <td>82</td>
      <td>-</td>
    </tr>
  
  </tbody>
</table>


<br></br>
List of Eligible Candidates for Written Test in the Department of ECE, M. Tech. (Self-Finance)<br></br>
 <table className="table table-bordered"> <tbody>
  <tbody>
    <tr>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
    </tr>
    <tr>
      <td>1</td>
      <td>-</td>
      <td>41</td>
      <td>-</td>
      <td>81</td>
      <td>-</td>
    </tr>
    <tr>
      <td>2</td>
      <td>-</td>
      <td>42</td>
      <td>-</td>
      <td>82</td>
      <td>-</td>
    </tr>
  
  </tbody>
</table>


<br></br>
List of Eligible Candidates for Written Test in the Department of CH, M. Tech. (Self-Finance)<br></br>
 <table className="table table-bordered"> <tbody>
  <tbody>
    <tr>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
    </tr>
    <tr>
      <td>1</td>
      <td>-</td>
      <td>41</td>
      <td>-</td>
      <td>81</td>
      <td>-</td>
    </tr>
    <tr>
      <td>2</td>
      <td>-</td>
      <td>42</td>
      <td>-</td>
      <td>82</td>
      <td>-</td>
    </tr>
  
  </tbody>
</table>

<br></br>
List of Eligible Candidates for Written Test in the Department of CIVIL, M. Tech. (Self-Finance)<br></br>
 <table className="table table-bordered"> <tbody>
  <tbody>
    <tr>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
    </tr>
    <tr>
      <td>1</td>
      <td>-</td>
      <td>41</td>
      <td>-</td>
      <td>81</td>
      <td>-</td>
    </tr>
    <tr>
      <td>2</td>
      <td>-</td>
      <td>42</td>
      <td>-</td>
      <td>82</td>
      <td>-</td>
    </tr>
  
  </tbody>
</table>
<br></br>


List of Eligible Candidates for Written Test in the Department of EEE, M. Tech. (Self-Finance) <table className="table table-bordered"> <tbody>
  <tbody>
    <tr>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
    </tr>
    <tr>
      <td>1</td>
      <td>-</td>
      <td>41</td>
      <td>-</td>
      <td>81</td>
      <td>-</td>
    </tr>
    <tr>
      <td>2</td>
      <td>-</td>
      <td>42</td>
      <td>-</td>
      <td>82</td>
      <td>-</td>
    </tr>
  
  </tbody>
</table>
<br></br>



List of Eligible Candidates for Written Test in the Department of ME, M. Tech. (Self-Finance)

 <table className="table table-bordered"> <tbody>
  <tbody>
    <tr>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
      <td><b>S.No</b></td>
      <td>Application No.</td>
    </tr>
    <tr>
      <td>1</td>
      <td>-</td>
      <td>41</td>
      <td>-</td>
      <td>81</td>
      <td>-</td>
    </tr>
    <tr>
      <td>2</td>
      <td>-</td>
      <td>42</td>
      <td>-</td>
      <td>82</td>
      <td>-</td>
    </tr>
  
  </tbody>
</table>
*/}
                    <br></br>
                    <br></br>
                    <b>Associate Dean Admissions      </b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>DEAN, ACADEMIC</b>
                    <br></br>
                    <br></br>
                </p>

            </div>
        </div>
    );
};

export default Resultpage;