export const getGOstats = (data) => {
    var numerator = data.GO.GPH.score + data.GO.GUE.score + data.GO.MS.score + data.GO.GPHD.score;
    var denominator = data.GO.GPH.total + data.GO.GUE.total + data.GO.MS.total + data.GO.GPHD.total;
    return (numerator/denominator)*100;
}

export const getTLRstats = (data) => {
    var numerator = data.TLR.SS.score + data.TLR.FSR.score + data.TLR.FQE.score + data.TLR.FRU.score;
    var denominator = data.TLR.SS.total + data.TLR.FSR.total + data.TLR.FQE.total + data.TLR.FRU.total;
    return (numerator/denominator)*100;
}

export const getRPstats = (data) => {
    var numerator = data.RP.PU.score + data.RP.QP.score + data.RP.IPR.score + data.RP.FPPP.score;
    var denominator = data.RP.PU.total + data.RP.QP.total + data.RP.IPR.total + data.RP.FPPP.total;
    return (numerator/denominator)*100;
}

export const getOIstats = (data) => {
    var numerator = data.OI.RD.score + data.OI.WD.score + data.OI.ESCS.score + data.OI.PCS.score;
    var denominator = data.OI.RD.total + data.OI.WD.total + data.OI.ESCS.total + data.OI.PCS.total;
    return (numerator/denominator)*100;
}

export const getPRstats = (data) => {
    var numerator = data.PR.PR.score;
    var denominator = data.PR.PR.total;
    return (numerator/denominator)*100;
}