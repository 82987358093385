import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deanrandc.css';


export const DeanRandC = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('deans');

  const deans = [
    // {
    //   Designation: " Dr. V. T. Somasekhar",
    //   Name: "Dean (Research & Development)",
    //   phoneNumber: " 9490165364",
    //   email: " sekhar@nitw.ac.in", 
    //   image: "https://nitw.ac.in/api/static/files/Prof._Somasekhar_V._T._2024-3-7-17-37-11.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ee-sekhar",
    // },
    {
      Name: "Dean (Research & Development)",
      Designation: "Prof. Sonawane Shirish Hari",
      phoneNumber: "9490165364",
      email: "dean_rc@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ch-shirish",
      link: "https://erp.nitw.ac.in/ext/profile/ch-shirish",
    },
    // ... other deans
  ];

  const associateDeans = [
    // {
    //   Designation: " Dr. V. Vasu",
    //   Name: "Associate Dean (Industrial Consultancy)",
    //   phoneNumber: " 8019789214",
    //   email: " ad_ic@nitw.ac.in ", 
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16276/16276.jpg",
    //   link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16276",
    // },
  //   {
  //   Designation: " Prof. K Venkata Reddy",
  //   Name: "Associate Dean (Industrial Consultancy)",
  //   phoneNumber: "8332969245",
  //   email: " ad_ic@nitw.ac.in ", 
  //   image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ce-kvreddy",
  //   link: "https://erp.nitw.ac.in/ext/profile/ce-kvreddy",
  // },

  {
    Name: "Associate Dean (Industrial Consultancy)",
    Designation: " Prof. P Subhash Chandra Bose",    
    phoneNumber: "8332969245",
    email: " ad_ic@nitw.ac.in ", 
    // image: "https://wsdc.nitw.ac.in/facultynew/media/16273/16273.jpg",
    image: "https://erp.nitw.ac.in/ext/profile/getUserImage/me-subhash",
    link: "https://erp.nitw.ac.in/ext/profile/me-subhash",
  },
    // {
    //   Designation: " Dr. Sonawane Shirish Hari",
    //   Name: "Associate Dean (Incubation Translation & Start-ups)",
    //   phoneNumber: " 8702468626",
    //   email: " shirish@nitw.ac.in ", 
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ch-shirish",
    // },
    // ... other associate deans
  ];

  const researchAdvisoryCommittee = [
    {
      // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
      Name: "Dr. Ramanuj Narayan",
      // department: "-",
      person1: "Director, CSIR-IMMT, Bhubaneshwar",
      image: "https://nitw.ac.in/api/static/files/Dr._Ramanuj_Narayan_2023-7-17-11-50-26.jpg",
      link: "#",
    },
    {
      // Designation: "Director, ARCI, Hyderabad",
      Name: "Dr. Tata Narasinga Rao",
      // department: "-",
      person1: "Director, ARCI, Hyderabad",
      image: "https://nitw.ac.in/api/static/files/Dr._Tata_Narasinga_Rao_2023-7-17-11-51-1.jpg",
      link: "#",
    },


    {
      // Designation: "Department of Electrical Engineering, IIT Bombay",
      Name: "Prof. Vivek Agarwal",
      person2: " Professor, Electrical Engineering",
      person1: " IIT Bombay",
      image: "https://nitw.ac.in/api/static/files/Prof._Vivek_Agarwal_2023-7-17-11-51-32.jpg",
      link: "#",
    },


    {
      // Designation: "Department of Electrical Engineering, IIT Bombay",
      Name: "Prof. Amit Prashant",
      person2: " Professor, Civil Engineering",
      person1: " IIT Gandhinagar",
      image: "https://nitw.ac.in/api/static/files/Prof._Amit_Prashant_2023-7-17-11-51-54.jpg",
      link: "#",
    },



    {
      // Designation: "Department of Mechanical Engineering, IIT Guwahati",
      Name: "Prof. Santosha K Dwivedy",
      person2: " Professor, Mechanical Engineering",
      person1: " IIT Guwahati",
      image: "https://nitw.ac.in/api/static/files/Prof._Santosha_K_Dwivedy_2023-7-17-11-52-13.jpg",
      link: "#",
    },



    {
      // Designation: "Department of Computer Science and Engg, IIT Kharagpur",
      Name: "Prof. Sudip Misra",
      person2: " Professor, Computer Science and Engg",
      person1: " IIT Kharagpur",
      image: "https://nitw.ac.in/api/static/files/Prof._Sudeep_Misra_2023-7-17-11-52-35.jpg",
      link: "#",
    },

    {
      // Designation: "Director, IIM Visakhapatnam",
      Name: "Prof. M. Chandrasekhar",
      // department: " Professor, Computer Science and Engg",
      person1: " Director, IIM Visakhapatnam",
      image: "https://nitw.ac.in/api/static/files/Prof._M._Chandrasekhar_2023-7-17-11-53-2.jpg",
      link: "#",
    },

    // ... more RAC members
  ];

  const officeStaff = [
    {
      // Designation: "Director, IIM Visakhapatnam",
      Name: " Assistant Registrar ",
      // department: " Professor, Computer Science and Engg",
      Designation: "Shri. Adesh Srivastava ",
      phoneNumber: " 9996447803 ",
      email: " ar_sric@nitw.ac.in ", 
      image: "https://nitw.ac.in/api/static/files/Adesh_Srivastava_2023-10-18-12-18-51.jpg",
      link: "#",
    },
    {
   
      Name: " Superintendent ",
   
      Designation: " Shri. Sathish Mandala ",
      phoneNumber: " 0870-2462968 ",
      email: "  sathishm@nitw.ac.in ", 
      image: "https://nitw.ac.in/api/static/files/Shri.Sathish_Mandala_2024-5-9-10-29-8.jpg",
      link: "#",
    },

    // {
    //   Name: " Superintendent ",
    //   Designation: " Shri. Raghuveer veera  ",
    //   phoneNumber: " 8970321334 ",
    //   email: " raghuveerveer@nitw.ac.in ", 
    //   image: "https://nitw.ac.in/api/static/files/Shri_Raghuveer_veera_2023-8-11-17-23-49.jpg",
    //   link: "#",
    // },
    // {
    //   Name: " Superintendent ",
    //   Designation: " Shri. Raghuveer veera  ",
    //   phoneNumber: " 8970321334 ",
    //   email: " raghuveerveer@nitw.ac.in ", 
    //   image: "https://nitw.ac.in/api/static/files/Shri_Raghuveer_veera_2023-8-11-17-23-49.jpg",
    //   link: "#",
    // },
    



    {
      // Designation: "Director, IIM Visakhapatnam",
      Name: " Senior Assistant ",
      // department: " Professor, Computer Science and Engg",
      Designation: " Shri. T. Bhaskar ",
      phoneNumber: " 8332969571 ",
      email: " tbkhar66@nitw.ac.in ", 
      image: "https://nitw.ac.in/api/static/files/Shri._T._Bhaskar_2023-8-11-17-29-57.jpg",
      link: "#",
    },
    {   
      Name: " Junior Assistant ",   
      Designation: " Shri. Mallikarjun Reddy M ",
      phoneNumber: " - ",
      email: " - ", 
      image: "https://nitw.ac.in/api/static/files/Shri._Mallikarjun_Reddy_M_2024-5-8-17-14-27.jpg",
      link: "#",
    },
   
    

  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setHoveredIndex(null);
  };

  const handleHover = (index) => {
    // setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    // setHoveredIndex(null);
  };

  const activeData =
    activeTab === 'deans'
      ? deans
      : activeTab === 'associateDeans'
      ? associateDeans
      : activeTab === 'researchAdvisoryCommittee'
      ? researchAdvisoryCommittee
      : officeStaff;
  const facilitiesPerColumn = Math.ceil(activeData.length / 2);

  return (
    <div className="page text-left font-change-applicable static-facilities">
      <PageHeader dept="/DeanRandC" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'deans' ? 'active' : ''}`}
            onClick={() => handleTabClick('deans')}
          >
            Dean
          </button>
          <button
            className={`tab ${activeTab === 'associateDeans' ? 'active' : ''}`}
            onClick={() => handleTabClick('associateDeans')}
          >
            Associate Deans
          </button>
          {/* <button
            className={`tab ${activeTab === 'researchAdvisoryCommittee' ? 'active' : ''}`}
            onClick={() => handleTabClick('researchAdvisoryCommittee')}
          >
            Research Advisory Committee (RAC)
          </button> */}
          <button
            className={`tab ${activeTab === 'officeStaff' ? 'active' : ''}`}
            onClick={() => handleTabClick('officeStaff')}
          >
            Office Staff
          </button>
        </div>
        <div className="columns-wrapper row">
          {activeData.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img className="custom-circle-image"  src={person.image} alt={person.Name} />
                </a>
              </div>
              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
                <h2>{person.Name}</h2>
                <div className="details-wrapper">
                  {person.Designation && (
                    <div className="details-row">
                      <span className="details-label">Name:</span>
                      <span className="details-value">{person.Designation}</span>
                    </div>
                  )}
                  {person.phoneNumber && (
                    <div className="details-row">
                      <span className="details-label">Phone:</span>
                      <span className="details-value">{person.phoneNumber}</span>
                    </div>
                  )}
                  {person.email && (
                    <div className="details-row">
                      <span className="details-label">Email:</span>
                      <span className="details-value">{person.email}</span>
                    </div>
                  )}
                  {person.department && (
                    <div className="details-row">
                      <span className="details-label">Department:</span>
                      <span className="details-value">{person.department}</span>
                    </div>
                  )}
                  {person.institute && (
                    <div className="details-row">
                      <span className="details-label">Institute:</span>
                      <span className="details-value">{person.institute}</span>
                    </div>
                  )}
                     {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person2}</span>
                    </div>
                  )}
                   {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person1}</span>
                    </div>
                  )}
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default DeanRandC;
