// Opportunities.js
import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
// import '../css/Careers.css'; // Import a CSS file for styling (create if not existing)
import '../css/Careers.css';

const Careers = () => {
  const [selectedCareer, setSelectedCareer] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedCareer(index);
  };

  const handleMouseOut = () => {
    setSelectedCareer(null);
  };

  return (
    <div className="page text-center font-change-applicable">
      {PageHeader && <PageHeader dept="/careers" />}
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        {/* Welcome Heading and Description */}
        <div>
        <h3 style={{ fontWeight: 'bold', color: '#333', marginBottom: '20px', textAlign: 'left' }}>CAREERS @ NITW</h3>

          {/* <p style={{ textAlign: 'justify', color: '#555', fontSize: '1.1em' }}>
          National Institute of Technology Warangal was established in 1959. Pandit Jawaharlal Nehru laid the foundation stone for this institute on October 10, 1959, the first in the chain of 31 NITs (formerly known as RECs) in the country.          </p> */}
        </div>
        {/* Existing Content */}
        <div className="row">
          {[
            {
              title: 'Teaching/Faculty Positions',
              description: 'NITW, invites application from Indian nationals, possessing excellent academic background along with commitment to quality teaching and potential for carrying out outstanding research for faculty position.',
              link: 'https://nitw.ac.in/faculty',
              image: 'https://nitw.ac.in/api/static/files/tfp_3_2023-12-21-18-28-14.png',
            },
            {
              title: 'Non-Teaching Positions',
              description: 'NITW, invites application from Indian nationals, possessing excellent academic background along with commitment to work dynamically and efficiently for the continuous development of the Institute.',
              // link: 'https://nitw.ac.in/NonTeaching',
              link: 'https://nitw.ac.in/page/?url=/jobsrecruitment/Nonteaching',
              image: 'https://nitw.ac.in/api/static/files/npt_v1_2023-12-21-18-29-31.png',
            },
            {
              title: 'Project/Contractual Staff',
              // description: 'Applications are invited from Indian citizens with an excellent academic background for contractual appointments under different research projects undertaken in the various departments of NITW.',
              description: 'Applications are invited from Indian citizens for various contractual positions including project staff in  the various departments/centers/sections of NITW.',
              link: 'https://nitw.ac.in/page/?url=/careersnitw/Jobs/',
              image: 'https://nitw.ac.in/api/static/files/pcs_v1_2023-12-21-18-30-30.png',
            },
            // Repeat for other sections as needed
            // ...
          ].map((career, index) => (
            <div
              className={`col-lg-4 col-md-4 member ${selectedCareer === index ? 'selected' : ''}`}
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              {/* Existing Content (unchanged) */}
              <div className="container creative-card1">
                <img src={career.image} alt="Career Image" className="image" />
                <div className={`overlay1 ${selectedCareer === index ? 'hovered' : ''}`}>
                  <div className="text">
                    {/* <h4>{career.title}</h4> */}
                    <p>{career.description}</p>
                    <a
                      href={career.link}
                      className={`cource-btn border-btn1 ${selectedCareer === index ? 'slide-in' : ''}`}
                    >
                      <span>Click here to view</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Careers;
