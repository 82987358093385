import React from "react";
import Events from "../constants/Events";
import Calendar from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../css/table.css";

moment.locale("en-GB");
const loacalizer=Calendar.momentLocalizer(moment);
console.log(Calendar.momentLocalizer(moment))

function WorkshopCalender() {
  
    return (
      <div className="calender">
      <Calendar
        views={["month"]}
        selectable
        defaultDate={new Date()}
        defaultView="month"
        events={Events}
        allDay={Events.allDay}
        style={{ height: "70vh"}}
        onSelectEvent={(event) => window.location.href =event.desc}
      />
    </div>
    )
}

export default WorkshopCalender