import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Typography } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import "../../components/Styles.css";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

const HeaderRenderer = ({ data }) => {
  if (!data) return "";
  let content = null;

  if (typeof data === "string") content = data;
  else if (
    typeof data === "object" &&
    data.text &&
    typeof data.text === "string"
  )
    content = data.text;

  if (!content) return "";
  if (
    typeof data === "object" &&
    data.level &&
    typeof data.level === "number"
  ) {

    switch (data.level) {
      case 1:
        return (
          <ThemeProvider theme={theme}>
            <Typography
              variant="h2"
              className="overflow-manage give_merry margin-manage-h2"
            >
              {ReactHtmlParser(content)}
            </Typography>
          </ThemeProvider>
        );
      case 2:
        return (
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              className="overflow-manage give_merry margin-manage-h3"
              style={{
                marginTop: "4px",
                textAlign: "center",
              }}
            >
              {ReactHtmlParser(content)}
            </Typography>
          </ThemeProvider>
        );
      case 3:
        return (
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              className="overflow-manage give_merry margin-manage-h3"
              style={{
                textAlign: "left",
                margin: "4px 0",
              }}
            >
              {ReactHtmlParser(content)}
            </Typography>
          </ThemeProvider>
        );
      case 4:
        return (
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              className="overflow-manage give_merry margin-manage-h4"
              style={{
                margin: "4px 0",
                textAlign: "left",
              }}
            >
              {ReactHtmlParser(content)}
            </Typography>
          </ThemeProvider>
        );
      case 5:
        return (
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              className="overflow-manage give_merry margin-manage-h5"
              style={{
                marginTop: "4px",
                textAlign: "left",
              }}
            >
              {ReactHtmlParser(content)}
            </Typography>
          </ThemeProvider>
        );
      case 6:
        return (
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              className="overflow-manage give_merry give_merry margin-manage-h5"
              style={{
                marginTop: "4px",
                textAlign: "center",
              }}
            >
              {ReactHtmlParser(content)}
            </Typography>
          </ThemeProvider>
        );
      default:
        return (
          <ThemeProvider theme={theme}>
            style={{ marginTop: "4px" }}
            <Typography variant="h5" className="overflow-manage give_merry">
              {ReactHtmlParser(content)}
            </Typography>
          </ThemeProvider>
        );
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h5" className="overflow-manage give_merry">
        {ReactHtmlParser(content)}
      </Typography>
    </ThemeProvider>
  );
};

export default HeaderRenderer;
