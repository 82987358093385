import React from "react";
import "../css/Search.css";
import ShowSearchItems from "./ShowSearchItems";


function SearchTab(props) {
  console.log(props.items);
  return (
    <>
      <div>
        <h1 className="head_content_text">Content:{props.items.length}</h1>
        <div>
          {props.items.map((item) => {
            return <ShowSearchItems items={item} />;
          })}
        </div>
      </div>
    </>
  );
}

export default SearchTab;
