import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Typography } from "@mui/material";
import "../rederers/css/fixes.css";

const validListStyles = ["ordered", "unordered"];
// const supportedKeys = ['container_ordered', 'container_unordered', 'listItem'];

const ListRenderer = ({ data }) => {
  if (!data) return "";

  let content = [],
    listType = "unordered";
  if (typeof data === "string") content.push(data);
  else if (typeof data === "object") {
    if (data.items && Array.isArray(data.items))
      content = data.items.map((item, index) => (
        <li key={index}>
          <Typography className="justify-text-class">
            {ReactHtmlParser(item)}
          </Typography>
        </li>
      ));
    if (data.style && validListStyles.includes(data.style))
      listType = data.style;
  }
  // console.log(content);
  if (content.length <= 0) return "";
  if (listType === "ordered")
    return (
      <ol className={"list list-ordered text-start justify-text-class"}>
        {content}
      </ol>
    );

  return <ul className="list-marked2 list offset-top-10">{content}</ul>;
};

export default ListRenderer;
