import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name

export const SGR = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('RAC');

  const researchAdvisoryCommittee = [
    {
      // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
      Name: "Prof. G Amba Prasad Rao",
      // department: "-",
      person1: "Chairman, Students' Grievance Redressal ",
      image: "https://nitw.ac.in/api/static/files/Prof._G_Amba_Prasad_Rao_2024-3-30-11-11-22.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/me-gap",
    },
    {
      // Designation: "Director, ARCI, Hyderabad",
      Name: "Prof. J V Ramana Murthy",
      // department: "-",
      person1: "Department of Mathematics",
      image: "https://nitw.ac.in/api/static/files/Prof._J_V_Ramana_Murthy_2024-3-30-11-14-30.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ma-jvr",
    },


    {
        // Designation: "Director, ARCI, Hyderabad",
        Name: "Prof. B. Venugopal Reddy",
        // department: "-",
        person1: "Department of Electrical Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._B._Venugopal_Reddy_2024-3-30-11-17-2.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ee-venugopalreddy",
      },


      {
        // Designation: "Director, ARCI, Hyderabad",
        Name: "Prof. Asim Bikas Das",
        // department: "-",
        person1: "Department of Biotechnology",
        image: "https://nitw.ac.in/api/static/files/Prof._Asim_Bikas_Das_2024-3-30-11-18-30.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/bt-asimbikas",
      },





    {
      // Designation: "Department of Electrical Engineering, IIT Bombay",
      Name: "Prof. Varam Sreedevi ",
    //   person2: " Department of Chemistry",
      person1: "Department of Metallurgical and Materials Engineering",
      image: "https://nitw.ac.in/api/static/files/Prof._Varam_Sreedevi_2024-3-30-11-20-2.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/mm-sreedeviv",
    },


    {
      // Designation: "Department of Electrical Engineering, IIT Bombay",
      Name: "Prof. Vijay Choyal",
    //   person2: " Professor, Civil Engineering",
      person1: " Department of Mechanical Engineering",
      image: "https://nitw.ac.in/api/static/files/Prof._Vijay_Choyal_2024-3-30-11-21-56.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/me-vijaychoyal",
    },





    // ... other RAC members
  ];

  const racStaff = [
    // {
    //   Name: "Dr. Ramanuj Narayan",
    //   person1: "Director, CSIR-IMMT, Bhubaneshwar",
    //   image: "https://nitw.ac.in/api/static/files/Dr._Ramanuj_Narayan_2023-7-17-11-50-26.jpg",
    //   link: "https://example.com/profile/john-doe",
    // },
    // {
    //   Name: "Dr. Tata Narasinga Rao",
    //   person1: "Director, ARCI, Hyderabad",
    //   image: "https://nitw.ac.in/api/static/files/Dr._Tata_Narasinga_Rao_2023-7-17-11-51-1.jpg",
    //   link: "https://example.com/profile/john-doe",
    // },
    // ... other RAC Staff members
  ];

  const staff = [
    {
      // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
      Name: "Dr. Ramanuj Narayan",
      // department: "-",
      person1: "Director, CSIR-IMMT, Bhubaneshwar",
      image: "https://nitw.ac.in/api/static/files/Dr._Ramanuj_Narayan_2023-7-17-11-50-26.jpg",
      link: "https://example.com/profile/john-doe",
    },
    {
      // Designation: "Director, ARCI, Hyderabad",
      Name: "Dr. Tata Narasinga Rao",
      // department: "-",
      person1: "Director, ARCI, Hyderabad",
      image: "https://nitw.ac.in/api/static/files/Dr._Tata_Narasinga_Rao_2023-7-17-11-51-1.jpg",
      link: "https://example.com/profile/john-doe",
    },
    // ... other staff members
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setHoveredIndex(null);
  };

  const handleHover = (index) => {
    // setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    // setHoveredIndex(null);
  };

  let activeData = researchAdvisoryCommittee;

  if (activeTab === 'RAC Staff') {
    activeData = racStaff;
  } else if (activeTab === 'Staff') {
    activeData = staff;
  }

  return (
    <div className="page text-center font-change-applicable static-facilities">
      <PageHeader dept="/sgr" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'RAC' ? 'active' : ''}`}
            onClick={() => handleTabClick('RAC')}
          >
         Students' Grievance Redressal Members 
          </button>
          {/* <button
            className={`tab ${activeTab === 'RAC Staff' ? 'active' : ''}`}
            onClick={() => handleTabClick('RAC Staff')}
          >
            RAC Minutes
          </button> */}
          {/* <button
            className={`tab ${activeTab === 'Staff' ? 'active' : ''}`}
            onClick={() => handleTabClick('Staff')}
          >
            Staff
          </button> */}
        </div>
        <div className="columns-wrapper row">
          {activeData.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img src={person.image} alt={person.Name} />
                </a>
              </div>
              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
              <h2>{person.Name}</h2>
                <div className="details-wrapper">
                  {person.Designation && (
                    <div className="details-row">
                      <span className="details-label">Name:</span>
                      <span className="details-value">{person.Designation}</span>
                    </div>
                  )}
                  {person.phoneNumber && (
                    <div className="details-row">
                      <span className="details-label">Phone:</span>
                      <span className="details-value">{person.phoneNumber}</span>
                    </div>
                  )}
                  {person.email && (
                    <div className="details-row">
                      <span className="details-label">Email:</span>
                      <span className="details-value">{person.email}</span>
                    </div>
                  )}
                  {person.department && (
                    <div className="details-row">
                      <span className="details-label">Department:</span>
                      <span className="details-value">{person.department}</span>
                    </div>
                  )}
                  {person.institute && (
                    <div className="details-row">
                      <span className="details-label">Institute:</span>
                      <span className="details-value">{person.institute}</span>
                    </div>
                  )}
                     {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person2}</span>
                    </div>
                  )}
                   {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person1}</span>
                    </div>
                  )}
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SGR;