// technozion2023.js
import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import { technozion2023 } from '../constants/Technozion2023Data'; // Update import statement
import '../css/convocation2023.css'; // Import your custom CSS for styling

const Technozion2023Page = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedItem(index);
  };

  const handleMouseOut = () => {
    setSelectedItem(null);
  };

  return (
    <div className="page text-center font-change-applicable">
      {PageHeader && <PageHeader dept="/technozion23" />} {/* Update dept to "/technozion2023" */}
      <div className="p-3 container" style={{ width: '100%', marginTop: '20px' }}>
        {/* Add the new sentence with left justification */}
        <h3 style={{ textAlign: 'left' }}>
          ABOUT TECHNOZION
        </h3>
        <p style={{ textAlign: 'justify' }}>
          NIT Warangal celebrates its heart and soul in the form of TECHNOZION, the annual technical festival, organized by student fraternity at National Institute of Technology, Warangal. Established in 2006, Technozion has been at the forefront of fostering technological advancements and providing a platform for young minds to showcase their brilliance at the largest technical fest of India. Technozion has grown exponentially to become the largest technical fest in south India. It includes the conduction of 60+ top class events with the aim of gathering engineering students from various regions and providing them opportunity to showcase their abilities and talents. With every passing year, the events at Technozion grow bigger and better.
        </p>

        <h4 style={{ textAlign: 'left' }}>
          Technozion organizes :
        </h4>
        <p style={{ textAlign: 'justify' }}>

          <li style={{ marginBottom: '5px' }}>
            <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
            Most innovative and advanced workshops based on the various prominent current technologies to nurture the abilities of the participant. Inceptive initiatives to make society a better place to live in.
          </li>


          <li style={{ marginBottom: '5px' }}>
            <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
            Informative guest lectures to know about how eminent personalities address an issue in their respective field of study. </li>


          <li style={{ marginBottom: '5px' }}>
            <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
            Illustrative exhibits to update the participant about the never ending development taking place in the engineering industry. </li>



          <li style={{ marginBottom: '5px' }}>
            <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
            Incredible shows to illustrate and enjoy the beauty of technology.
          </li>

          <br />

          Technozion has manifested the in-depth meaning of its motto: <b> ‘Create, Conceive and Conquer.’</b>



          <br /><br />


          <li style={{ marginBottom: '5px' }}>
            <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
            With over 800 institutes participating, the fest provides an assemblage to the technical skills of 15000+ students over the course of 3 days in the institute. With the great responsibility of quenching the thirst for knowledge, we set off to rebuild the Technozion experience once again this year
          </li>



          <br />
          As we eagerly anticipate the unfolding chapters, get ready for the latest installment in the saga - Technozion ’23, themed <b>'INGENIOUS.'</b> During <b>January 19 - 21, 2024</b>
          <br /><br />
          The theme for this year’s Technozion is INGENIOUS, a concept that encapsulates the spirit of exploration, creativity, and the transformative power of technology. It is not merely a tagline; it is a call to action, an invitation to delve into the realms of possibility and redefine the boundaries of what we can achieve. INGENIOUS is more than just a phrase; it is a philosophy that encourages us to push the limit of our imagination, to question the status quo, and to embrace the cutting edge of technological advancements. Its serves as a beacon guiding us towards innovative solutions, collaborative endeavors, and a future where technology is not just a tool but a force for positive charge

          <br />
          <br />
          <b>For events registration and accommodation - </b><a href="https://linktr.ee/technozion23">Click here</a>
          {/* <br />
          <br /> */}



          <br />
          <br />
          <a href="https://nitw.ac.in/api/static/files/Technozion_23_2024-1-5-18-2-4.pdf">
            <img src="https://nitw.ac.in/api/static/files/view_2024-1-5-18-46-0.png" alt="INGENIOUS" width="200" height="50" />
          </a>



        </p>


        <div className="row justify-content-center">
          {technozion2023.map((item, index) => (
            <div
              className={`col-md-6 col-lg-4 my-3 member ${index === 0 ? 'first-tab' : 'other-tab'
                } ${item.name.toLowerCase()}-tab`}
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={item.link} target="_blank" rel="noreferrer">
                <div
                  className={`card custom-profile-card ${selectedItem === index ? 'hovered' : ''
                    } ${item.isCircle ? 'circle-tab' : ''}`}
                >
                  <div className="circle-tab-content">
                    <img
                      src={item.image}
                      alt={`Item ${index + 1}`}
                      className={`circle-tab-image ${selectedItem === index ? 'hovered' : ''
                        }`}
                    />
                    <p className={`circle-tab-label`}>
                      {item.name}
                      {index === 0 && <br /> /* Add line break only for Chief Guest */}
                      {index === 0 && '' /* Display Chief Guest for the first tab */}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Technozion2023Page;
