import React, { useState, useEffect } from "react";
import DelimiterRenderer from "../components/rederers/DelimiterRenderer";
import { Helmet } from "react-helmet";
import TableRenderer from "../components/rederers/TableRenderer";
import Output from "editorjs-react-renderer";
import ListRenderer from "../components/rederers/ListRenderer";
import HeaderRenderer from "../components/rederers/HeaderRenderer";
import ImageRenderer from "../components/rederers/ImageRenderer";
import { useLocation } from "react-router-dom";
import Loading from "../components/Loading";
import queryString from "query-string";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import "../components/Styles.css";
import Paragraph from "../components/rederers/ParagraphRenderer";
import { BaseUrl, MainServerUrl } from "../BasePath";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

const config = {
  header: {
    disableDefaultStyle: true,
  },
  image: {
    disableDefaultStyle: true,
  },
  paragraph: {
    disableDefaultStyle: true,
  },
  list: {
    disableDefaultStyle: true,
  },
  table: {
    disableDefaultStyle: true,
  },
};

const classes = {
  header: "offset-top-20",
  list: {
    container: "list list-marked",
  },
  table: {
    table: "table table-custom table-fixed bordered-table",
  },
};

const renderers = {
  header: HeaderRenderer,
  delimiter: DelimiterRenderer,
  table: TableRenderer,
  list: ListRenderer,
  image: ImageRenderer,
  paragraph: Paragraph,
};

const NormalPage = (props) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = queryString.parse(location.search);
  if (params.url === undefined) {
    params.url = "/home/about";
  }

  //   console.log(thisPageData);
  useEffect(() => {
    console.log(Date.now());
    console.log(data);
    setIsLoading(false);
  }, [data]);
  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    // const urlMap = new Map(JSON.parse(localStorage.wsdcURLMap));
    // const thisPageData = urlMap.get(params.url);
    // const blocks = [];
    // thisPageData.blocks.forEach((block) => {
    //   blocks.push(JSON.parse(block));
    // });
    // thisPageData.blocks = blocks;
    // setData(thisPageData);
    const controller = new AbortController();
    let id = setTimeout(() => controller.abort(), 10000);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ url: params.url }),
      signal: controller.signal,
    };
    fetch(`${MainServerUrl}/api/page/view`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("response is not ok");
        }
        if (response.status !== 200) {
          throw new Error("Status code is not 200");
        }
        return response.json();
      })
      .then((data) => setData(data))
      .catch((err) => {
        console.log(err);
        id = setTimeout(() => controller.abort(), 10000);
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ url: params.url }),
          signal: controller.signal,
        };
        fetch(`${MainServerUrl}/api/page/view`, requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error("response is not ok");
            }
            if (response.status !== 200) {
              throw new Error("Status code is not 200");
            }
            return response.json();
          })
          .then((data) => setData(data))
          .catch((err) => {
            window.location.href = `${BaseUrl}`;
          });
      })
      .finally(() => clearTimeout(id));

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [params.url]);

  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>

      {isLoading ? (
        <Loading></Loading>
      ) : (
        <section className={"section text-md-start section-xl bg-default"} style={{paddingTop: "30px"}}>
          <div className="container">
            <div className="row">
              <div
                className="col-sm-10 col-xl-8 inset-xl-right-10 order-xl-1"
                style={{ width: "120%", textAlign: "justify",color:"black"}}
              >
                <ThemeProvider theme={theme}>
                  <Output
                    data={data}
                    classNames={classes}
                    config={config}
                    renderers={renderers}
                  ></Output>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default NormalPage;
