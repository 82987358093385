import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name



export const DeanSW = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('deans');

  const deans = [
    {
      Name: "Dean (Student Welfare)",
      Designation: " Prof. D. Srinivasacharya",
      phoneNumber: " 9490165362",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16393/16393.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ma-dsc",
      email:" dean_sa@nitw.ac.in",
    },
  
    // ... other deans
  ];

  const associateDeans = [
    {
      Name: "President",
      Designation: " Prof P. Hari Krishna",
      phoneNumber: " 9490457404",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16208/16208.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ce-phari",
      email:" phari@nitw.ac.in ",
    },
    {
      Name: "Vice President (Technical)",
      Designation: " Prof. P. Hari Prasad Reddy",
      phoneNumber: " 8332969254",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16216/16216.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ce-hari",
      email:" hari@nitw.ac.in",
    },
    {
      Name: "Vice President (Cultural)",
      Designation: " Prof. Sathish Ben",
      phoneNumber: " 9848065254",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16439/16439.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/me-satishben",
      email:" satishben@nitw.ac.in ",
    },
    {
      Name: "Vice President (Athletics)",
      Designation: " Prof. P. Abdul Azeem",
      phoneNumber: " 8332969473",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ph-drazeem2002",
      email:" drazeem2002@nitw.ac.in",
    },

    {
      Name: "Vice President (Film and Music)",
      Designation: " Prof. P. Muralidhar",
        phoneNumber: " 8332969473",
        image: "https://wsdc.nitw.ac.in/facultynew/media/16308/16308.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ec-pmurali",
        email: " pmurali@nitw.ac.in",
      },
    // ... add more items ...
    // ... other associate deans
  ];

  const researchAdvisoryCommittee = [
  

    {
      Designation: " Dr. Prasanth P",
      Name: "Senior SASO",
      phoneNumber: " 9881096695",
      email: "ssaso@nitw.ac.in", 
      image: "https://wsdc.nitw.ac.in/facultynew/media/17085/17085.jpg",
      link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17085",
    },


    {
      Designation: " Dr. Devasoth Hari",
      Name: "Students Activity and Sports Officer (SASO)",
      phoneNumber: " 9848251293",
      email: "hd_saso@nitw.ac.in", 
      image: "https://wsdc.nitw.ac.in/facultynew/media/17086/17086.jpg",
      link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17086",
    
    },

   
    // ... more RAC members
  ];

  const officeStaff = [

    {
      // Designation: "Director, IIM Visakhapatnam",
      Name: " Asst. S.G-II ",
      // department: " Professor, Computer Science and Engg",
      Designation:  "Shri. R. Srinivas ",
      phoneNumber: "8332969575",
      email: "rsvas@nitw.ac.in", 
      image: "https://nitw.ac.in/api/static/files/R.SRINIVAS_2023-7-31-11-54-9.jpg",
      link: "#",
    },
  

  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setHoveredIndex(null);
  };

  const handleHover = (index) => {
    // setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    // setHoveredIndex(null);
  };

  const activeData =
    activeTab === 'deans'
      ? deans
      : activeTab === 'associateDeans'
      ? associateDeans
      : activeTab === 'researchAdvisoryCommittee'
      ? researchAdvisoryCommittee
      : officeStaff;
  const facilitiesPerColumn = Math.ceil(activeData.length / 2);

  return (
    <div className="page text-left font-change-applicable static-facilities">
      <PageHeader dept="/deansw" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'deans' ? 'active' : ''}`}
            onClick={() => handleTabClick('deans')}
          >
            Dean
          </button>
          <button
            className={`tab ${activeTab === 'associateDeans' ? 'active' : ''}`}
            onClick={() => handleTabClick('associateDeans')}
          >
          Student Activity Centre
          </button>
          {/* <button
            className={`tab ${activeTab === 'researchAdvisoryCommittee' ? 'active' : ''}`}
            onClick={() => handleTabClick('researchAdvisoryCommittee')}
          >
           Students Activity and Sports Officer
          </button> */}
          <button
            className={`tab ${activeTab === 'officeStaff' ? 'active' : ''}`}
            onClick={() => handleTabClick('officeStaff')}
          >
            Office Staff
          </button>
        </div>
        <div className="columns-wrapper row">
          {activeData.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >

                
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img src={person.image} alt={person.Name} />
                </a>
              </div>

              
              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
                <h2>{person.Name}</h2>
                <div className="details-wrapper">
                  {person.Designation && (
                    <div className="details-row">
                      <span className="details-label">Name: </span>
                      <span className="details-value">{person.Designation}</span>
                    </div>
                  )}
                  {person.phoneNumber && (
                    <div className="details-row">
                      <span className="details-label">Phone: </span>
                      <span className="details-value">{person.phoneNumber}</span>
                    </div>
                  )}
                  {person.email && (
                    <div className="details-row">
                      <span className="details-label">Email: </span>
                      <span className="details-value">{person.email}</span>
                    </div>
                  )}
                  {person.department && (
                    <div className="details-row">
                      <span className="details-label">Department: </span>
                      <span className="details-value">{person.department}</span>
                    </div>
                  )}
                  {person.institute && (
                    <div className="details-row">
                      <span className="details-label">Institute: </span>
                      <span className="details-value">{person.institute}</span>
                    </div>
                  )}
                     {person.person1 && (
                    <div className="details-row"> 
                      <span className="details-label">  </span>
                      <span className="details-value">{person.person2}</span>
                    </div>
                  )}
                   {person.person1 && (
                    <div className="details-row">
                      <span className="details-label">  </span>
                      <span className="details-value">{person.person1}</span>
                    </div>
                  )}
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeanSW;