// Au.js
import React, { useState } from 'react';
import '../css/MilestonesTimeline.css';

const AuData = [
    {
        date: "October 10, 1959",
        description: "Pandit Jawaharlal Nehru laid foundation stone for the College, the first in the chain of NITs (formerly RECs) in the country."
    },
    {
        date: "November 10, 1959",
        description: "Registration of the Society of Regional Engineering College, Warangal."
    },
    {
        date: "September, 2002",
        description: "Institute renamed as National Institute of Technology, Warangal and offered the status of Deemed University."
    },
    {
        date: "March 1, 2004",
        description: "Ist Convocation of NIT Warangal. Prof. S.K. Joshi, Chairman, Board of Governors, IIT Roorkee and Former Director General, CSIR delivers the First Convocation address."
    },
    {
        date: "Sep 10, 2018",
        description: "Diamond Jubilee Celebrations ( Conferences And Other Events )."
    },
    // Add your other milestones here
];

const AuTimeline = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <div className="milestones-container">
            <h1 className="milestones-title">About Us</h1>
            <div className="milestones-list">
                {AuData.map((milestone, index) => (
                    <div
                        className={`milestone-box ${index === hoveredIndex ? 'hovered' : ''}`}
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        {index !== AuData.length - 1 && (
                            <div className="arrow"></div>
                        )}
                        <div className="milestone-content">
                            <div className="milestone-date">{milestone.date}</div>
                            <div className="milestone-description">{milestone.description}</div>
                        </div>
                        {index !== AuData.length - 1 && (
                            <div className="connector"></div>
                        )}
                    </div>
                ))}
            </div>
            <div className="container paragraph-container">
                <p style={{ textAlign: 'justify' }}>
             
                                National Institute of Technology, Warangal, formerly known as Regional Engineering College, was established in 1959. Pandit Jawaharlal Nehru laid the foundation stone for this institute on October 10, 1959, the first in the chain of 31 NITs (formerly known as RECs) in the country.
                    <br></br><br></br>
                    The Institute is well known for its dedicated faculty, staff and the state-of-the art infrastructure conducive to a healthy academic environment.The Institute is constantly striving to achieve higher levels of technical excellence.  Evolving a socially relevant and yet internationally acceptable curriculum, implementing innovative and effective teaching methodologies and focusing on the wholesome development of the students are our concerns.  Thanks to UNESCO and UK assistance in the past, many developmental activities were undertaken. The World Bank Assistance under Technical Education Quality Improvement Programme (TEQIP) had been a timely help in the overall development of the Institute.
                    <br></br><br></br>
                    The Institute currently has thirteen academic departments and a few advanced research centres in various disciplines of engineering, pure sciences and management, with nearly 100 laboratories organized in a unique pattern of functioning, Central Library with state of the art facilities, Auditorium, Student Activity Centre, Mega Computer Centre (currently renamed as Centre for Digital Infrastructure and Services (CDIS)), Indoor Games Complex, big stadium, Seminar Halls with required infrastructure, Dispensary with state of art of facilities, etc. Faculty of repute, brilliant student 
                    <br></br><br></br>
                    community, excellent technical and supporting staff and an effective administration have all contributed to the pre-eminent status of NIT Warangal. The Institute offers Ten undergraduate programmes (B.Tech.) in engineering, Twenty nine post graduate programmes (M.Tech., M.Sc., MCA and MBA) in engineering, sciences and management and research programmes in engineering, sciences, humanities, physical education and management. The institute is well-known for its Research and Development, Industrial consultancy, Continuing education and Training programmes for teachers and industrial personnel.
                    <br></br><br></br>
                    NIT Warangal is a residential institute with nearly 6000+ ( UG & PG ) students apart from nearly 1000+ Ph.D., ( Full Time and Part time) Scholars.  It has 16 residential blocks including one mega & one ultra-mega hostel for boys, two hostels for girls and one hostel for foreign students.  It has five messes for boys, one mess exclusively for girls and one mega mess with present state of art facilities for boys, foreign students and girls.  It has nearly 320 teaching staff and 360 administrative and supporting staff apart from outsource personnel. It is a self contained residential campus located in a beautiful wooded land of about 248 acres. It is located near to Kazipet at a distance of 3 Kms from Kazipet Railway Station and 12 Kms from Warangal Railway Station. It is located at a distance of 140 Kms from Hyderabad. Warangal enjoys a unique position in the cultural and historical map of the state. The exquisite and intricate architecture in Ramappa, Thousand Pillar Temple, Badrakali Temple and the Warangal Fort have been centres of attraction. Lovely gardens, wild life sanctuaries, lakes and rocks in the vicinity are other places of interest for students. The place enjoys a good climate throughout the year except for the patches of hot summer during May-June.
                    <br></br><br></br>
                    It is a residential campus with all the basic facilities for both the students and staff to live on the campus. The following statistical figures might give an idea about the buildings in the campus. The plinth area occupied by the different buildings on the campus are: Administrative Building (3,331 Sqm), Academic Buildings (34,448 sqm), Library Building (3,610 sqm),  Students Hostels (32,642 sqm), Sports Pavilion and Gallery (784 Sqm), Director's Bungalow, Staff Quarters and Guest House (31,598 sqm) The road network in the campus runs to a length of 12 kms. Work is in progress in creating more space for the varied purposes referred above and also for the extension of network of roads.
                    <br></br><br></br>
                    The placement service at the institute is one of the best of its kind in the country. The alumni of the institute hold responsible and enviable positions all over the world and are in constant touch with the institute. Every new entrant into the portals of this institution is poised for partaking a rich heritage and tradition that is unique to NITW. According to NIRF RANK 2023, NITW clinches the 21st rank among the Engineering Institutes in the country.


</p>
            </div>
        </div>
    );
};

export default AuTimeline;