import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name

export const IOC = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('RAC');

  const researchAdvisoryCommittee = [
    {
        // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
        Name: "Prof. Shirish H Sonawane",
        // department: "-",
        person1: "Chairman, Industry Outreach Cell",
        image: "https://nitw.ac.in/api/static/files/Prof._Sonawane_Shirish_Hari_2024-3-22-17-4-32.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ch-shirish",
      },
      {
        // Designation: "Director, ARCI, Hyderabad",
        Name: "Prof. Vamsi Krishna P",
        // department: "-",
        person1: "Convener, Department of Mechanical Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._P_Vamsi_Krishna_2024-3-22-17-3-22.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/me-vamsikrishna",
      },
  
  
      {
          // Designation: "Director, ARCI, Hyderabad",
          Name: "Prof. Joseph Davidson M",
          // department: "-",
          person1: "Head, Centre for Career Planning and Development",
          image: "https://nitw.ac.in/api/static/files/Prof._Joseph_Davidson_M_2024-3-28-16-2-56.jpg",
          link: "https://erp.nitw.ac.in/ext/profile/me-jd",
        },
  
  
        {
          // Designation: "Director, ARCI, Hyderabad",
          Name: "Prof. Venkata Reddy Keesara",
          // department: "-",
          person1: "Department of Civil Engineering",
          image: "https://nitw.ac.in/api/static/files/Prof._K_Venkata_Reddy_2024-3-28-16-5-11.jpg",
          link: "https://erp.nitw.ac.in/ext/profile/ce-kvreddy",
        },
  
  
  


    {
      // Designation: "Department of Electrical Engineering, IIT Bombay",
      Name: "Prof. K. Hari Prasad ",
    //   person2: " Department of Chemistry",
      person1: " Department of Chemistry",
      image: "https://nitw.ac.in/api/static/files/Prof._K_Hari_Prasad_2024-3-22-16-59-52.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cy-harikokatla",
    },


    {
      // Designation: "Department of Electrical Engineering, IIT Bombay",
      Name: "Prof. B. Venugopal Reddy",
    //   person2: " Professor, Civil Engineering",
      person1: " Department of Electrical Engineering",
      image: "https://nitw.ac.in/api/static/files/Prof._B._Venugopal_Reddy_2024-3-22-16-58-53.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ee-venugopalreddy",
    },



    {
      // Designation: "Department of Mechanical Engineering, IIT Guwahati",
      Name: "Prof. R. Arockia Kumar",
    //   person2: " Professor, Mechanical Engineering",
      person1: " Department of Metallurgical and Materials Engineering",
      image: "https://nitw.ac.in/api/static/files/Prof._R._Arockia_Kumar_2024-3-22-16-57-53.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/mm-arockia",
    },



    {
      // Designation: "Department of Computer Science and Engg, IIT Kharagpur",
      Name: "Prof. Sourabh Roy ",
    //   person2: " Professor, Computer Science and Engg",
      person1: " Department of Physics",
      image: "https://nitw.ac.in/api/static/files/Prof._Sourabh_Roy_2024-3-22-16-56-56.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ph-sroy",
    },

    {
      // Designation: "Director, IIM Visakhapatnam",
      Name: "Prof. K. Kaladhar ",
      // department: " Professor, Computer Science and Engg",
      person1: " Department of Mathematics",
      image: "https://nitw.ac.in/api/static/files/Prof._Kaladhar_Kolla_2024-3-22-16-56-0.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ma-kaladhar",
    },



    {
        // Designation: "Director, IIM Visakhapatnam",
        Name: "Prof. P. Srihari Rao ",
        // department: " Professor, Computer Science and Engg",
        person1: "Department of Electronics and Communication Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._P_Sreehari_Rao_2024-3-22-16-55-11.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ec-patri",
      },
  

      {
        // Designation: "Director, IIM Visakhapatnam",
        Name: "Prof. Ravi Chandra  ",
        // department: " Professor, Computer Science and Engg",
        person1: "Department of Computer Science and Engineering",
        image: "https://erp.nitw.ac.in/ext/profile/getUserImage/cs-ravic",
        link: "https://erp.nitw.ac.in/ext/profile/cs-ravic",
      },
  
      {
        // Designation: "Director, IIM Visakhapatnam",
        Name: "Prof. Manohar Kakunuri   ",
        // department: " Professor, Computer Science and Engg",
        person1: " Department of Chemical Engineering",
        image: "https://nitw.ac.in/api/static/files/Prof._Manohar_Kakunuri_2024-3-22-16-54-3.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/ch-manohar",
      },
  

      {
        // Designation: "Director, IIM Visakhapatnam",
        Name: "Prof. Asim Bikas Das   ",
        // department: " Professor, Computer Science and Engg",
        person1: "Department of Biotechnology",
        image: "https://nitw.ac.in/api/static/files/Prof._Asim_Bikas_Das_2024-3-22-16-52-47.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/bt-asimbikas",
      },
  
      {
        // Designation: "Director, IIM Visakhapatnam",
        Name: "Prof. K. Francis Sudhakar    ",
        // department: " Professor, Computer Science and Engg",
        person1: "Department of Management Studies",
        image: "https://nitw.ac.in/api/static/files/Prof._K._Francis_Sudhakar_2024-3-22-16-51-17.jpg",
        link: "https://erp.nitw.ac.in/ext/profile/sm-kfsudhakar",
      },
  



    // ... other RAC members
  ];

  const racStaff = [
    // {
    //   Name: "Dr. Ramanuj Narayan",
    //   person1: "Director, CSIR-IMMT, Bhubaneshwar",
    //   image: "https://nitw.ac.in/api/static/files/Dr._Ramanuj_Narayan_2023-7-17-11-50-26.jpg",
    //   link: "https://example.com/profile/john-doe",
    // },
    // {
    //   Name: "Dr. Tata Narasinga Rao",
    //   person1: "Director, ARCI, Hyderabad",
    //   image: "https://nitw.ac.in/api/static/files/Dr._Tata_Narasinga_Rao_2023-7-17-11-51-1.jpg",
    //   link: "https://example.com/profile/john-doe",
    // },
    // ... other RAC Staff members
  ];

  const staff = [
    {
      // Designation: "Director, CSIR-Institute of Minerals and Materials Technology, Bhubaneshwar",
      Name: "Dr. Ramanuj Narayan",
      // department: "-",
      person1: "Director, CSIR-IMMT, Bhubaneshwar",
      image: "https://nitw.ac.in/api/static/files/Dr._Ramanuj_Narayan_2023-7-17-11-50-26.jpg",
      link: "https://example.com/profile/john-doe",
    },
    {
      // Designation: "Director, ARCI, Hyderabad",
      Name: "Dr. Tata Narasinga Rao",
      // department: "-",
      person1: "Director, ARCI, Hyderabad",
      image: "https://nitw.ac.in/api/static/files/Dr._Tata_Narasinga_Rao_2023-7-17-11-51-1.jpg",
      link: "https://example.com/profile/john-doe",
    },
    // ... other staff members
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setHoveredIndex(null);
  };

  const handleHover = (index) => {
    // setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    // setHoveredIndex(null);
  };

  let activeData = researchAdvisoryCommittee;

  if (activeTab === 'RAC Staff') {
    activeData = racStaff;
  } else if (activeTab === 'Staff') {
    activeData = staff;
  }

  return (
    <div className="page text-center font-change-applicable static-facilities">
      <PageHeader dept="/Ioc" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'RAC' ? 'active' : ''}`}
            onClick={() => handleTabClick('RAC')}
          >
          Industry Outreach Cell Members 
          </button>
          {/* <button
            className={`tab ${activeTab === 'RAC Staff' ? 'active' : ''}`}
            onClick={() => handleTabClick('RAC Staff')}
          >
            RAC Minutes
          </button> */}
          {/* <button
            className={`tab ${activeTab === 'Staff' ? 'active' : ''}`}
            onClick={() => handleTabClick('Staff')}
          >
            Staff
          </button> */}
        </div>
        <div className="columns-wrapper row">
          {activeData.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img src={person.image} alt={person.Name} />
                </a>
              </div>
              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
              <h2>{person.Name}</h2>
                <div className="details-wrapper">
                  {person.Designation && (
                    <div className="details-row">
                      <span className="details-label">Name:</span>
                      <span className="details-value">{person.Designation}</span>
                    </div>
                  )}
                  {person.phoneNumber && (
                    <div className="details-row">
                      <span className="details-label">Phone:</span>
                      <span className="details-value">{person.phoneNumber}</span>
                    </div>
                  )}
                  {person.email && (
                    <div className="details-row">
                      <span className="details-label">Email:</span>
                      <span className="details-value">{person.email}</span>
                    </div>
                  )}
                  {person.department && (
                    <div className="details-row">
                      <span className="details-label">Department:</span>
                      <span className="details-value">{person.department}</span>
                    </div>
                  )}
                  {person.institute && (
                    <div className="details-row">
                      <span className="details-label">Institute:</span>
                      <span className="details-value">{person.institute}</span>
                    </div>
                  )}
                     {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person2}</span>
                    </div>
                  )}
                   {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person1}</span>
                    </div>
                  )}
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IOC;
