// import React from "react";
// import { changeBaseUrl } from "../../utils/changeBaseUrl";

// // const supportedKeys = ['img', 'figure', 'figcaption'];

// const ImageOutput = ({data}) => {
//     if (!data || !data.file) return '';
//     var newUrl = changeBaseUrl(data.file.url);

//     return (
//         <React.Fragment>
//             <div className="offset-top-35 offset-lg-top-60" style={{display:"flex" , flexDirection:"row", justifyContent:"center"}}>
//                 <img className="img-responsive" width="400" height="250"
//                      src={newUrl} alt={data.caption || ''}/>
//             </div>
//             <div className="inset-xl-left-20">
//                 <p className="text-gray offset-top-20 inset-left-lg-30 fst-italic" style={{textAlign:"center"}}>{data.caption || ''}</p>
//             </div>
//         </React.Fragment>
//     );
// };


// export default ImageOutput;

import React from "react";
import { changeBaseUrl } from "../../utils/changeBaseUrl";

const ImageOutput = ({ data }) => {
  if (!data || !data.file) return null;

  var newUrl = changeBaseUrl(data.file.url);

  return (
    <div
      className="offset-top-35 offset-lg-top-60 d-flex justify-content-center"
      style={{
        height: "400px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        className="image-container"
        style={{
          position: "relative",
          height: "100%",
          overflow: "hidden",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)", /* For Safari */
        }}
      >
        <img
          className="img-responsive"
          src={newUrl}
          alt={data.caption || ""}
          style={{
            width: "128%",
            height: "100%",
            objectFit: "cover",
            transition: "transform 0.3s ease",
            transform: "scale(1)",
          }}
          onMouseEnter={(e) => {
            e.target.style.transform = "scale(1.1)";
          }}
          onMouseLeave={(e) => {
            e.target.style.transform = "scale(1)";
          }}
        />
        <div
          className="caption-overlay"
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            background: "rgba(0, 0, 0, 0.6)",
            padding: "8px",
            color: "#fff",
            fontSize: "14px",
          }}
        >
          {data.caption || ""}
        </div>
      </div>
    </div>
  );
};
export default ImageOutput;
