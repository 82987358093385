import { MainServerUrl } from "../BasePath";

export const Allpages = [
  {
    blocks: [
      {
        id: "Ga9gifZhxL",
        type: "header",
        data: { text: "Welcome to Mathematics Department", level: 3 },
      },
      {
        id: "pLYD6DTM9D",
        type: "paragraph",
        data: {
          text: "The Department of Mathematics was established in 1959 and has always shared the vision of the institute in striving for excellence in teaching and research activities.  Over the years, the department has evolved as one that provides excellent teaching and research in Applied Mathematical Sciences.",
        },
      },
      {
        id: "FeD_0zUeCt",
        type: "header",
        data: { text: "Vision and Mission", level: 3 },
      },
      { id: "Pj6JAFeX3B", type: "paragraph", data: { text: "<b>Vision:</b>" } },
      {
        id: "GUIH9oulyn",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To be among the best Mathematics Departments in the country.",
            "To be an international centre for Mathematical Sciences.",
          ],
        },
      },
      {
        id: "ecblvrdmQe",
        type: "paragraph",
        data: { text: "<b>Mission:</b>" },
      },
      {
        id: "EmoUXHZUG9",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To attract motivated and talented students to the Master’s and Doctoral&nbsp;programs.",
          ],
        },
      },
      {
        id: "d61QWKUhVx",
        type: "paragraph",
        data: { text: "<b>Strengths:</b>" },
      },
      {
        id: "Pt4gLehfRn",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Vibrant Academic Environment",
            "Well-experienced, dedicated and strongly motivated Faculty",
            "Application oriented teaching",
            "Development of soft-skills",
            "Interaction with experts in the field through workshops and seminars",
            "Value addition through projects in the final year",
            "Wide range of extra-curricular activities",
          ],
        },
      },
      {
        id: "qIDdMgS0Rl",
        type: "paragraph",
        data: {
          text: "With the above, our students are groomed into being responsible human beings, having sound knowledge in the field and good leadership qualities. They are well-nurtured towards developing effective solutions for industrial and scientific problems. Over and above a rigorous academic schedule, a great emphasis is placed on all-round development of the students.",
        },
      },
      {
        id: "9PwGQp0e9b",
        type: "header",
        data: {
          text: "Profile of the Department of Mathematics, NITW:",
          level: 3,
        },
      },
      {
        id: "His1361H7s",
        type: "paragraph",
        data: {
          text: "Greetings from the Department of Mathematics, NIT Warangal !!!",
        },
      },
      {
        id: "zk5m9kJ2Fd",
        type: "paragraph",
        data: {
          text: "NIT Warangal, the first and the most diversified among the NITs has been among the top 10 institutes of our country. The Department of Mathematics was&nbsp;established in 1959&nbsp;and has always shared the vision of the institute in striving for excellence in teaching and research activities. Over the years, the department has evolved as one that provides excellent teaching and research in Applied Mathematical Sciences. The frontier areas of research of the department include: Fluid Mechanics, Bio-mechanics, Mathematical Modeling, Numerical Analysis, Finite Element Method, CFD, Optimization Techniques, Coding Theory, etc. Our Department is recognized by AICTE as the only QIP center for PhD programs among all NITS. The department started its M.Sc. Applied Mathematics course in the year 1970 with the intake of students on the basis of NITWET, a national-level entrance test for NIT Warangal. In the context of changing needs of the software industry, the Department is also offering a Computer Oriented Mathematics course – M.Sc. (Mathematics and Scientific Computing). This course is offered from the year2001 onwards, with a judicious combination of essential Mathematics, Applied Mathematics and Computer courses.",
        },
      },
      {
        id: "kiFD2XxdOr",
        type: "paragraph",
        data: { text: "<b>Curriculum:</b>" },
      },
      {
        id: "ZTTwCFK3gb",
        type: "paragraph",
        data: {
          text: "The department keeps in pace with the advances in technology while framing the curriculum. The curriculum of M.Sc. Degree program in Applied Mathematics &amp; Mathematics and Scientific Computing is on par with that of the best Institution in the country.Facilities in the department:v Department Library The department has its own library with a reasonably good collection of books on Mathematics Laboratories The laboratories in the department and the computer center are equipped with most modern facilities to provide the best work environment. The students are trained in computer programming languages like C, C++, and FORTRAN. They also gain exposure in handling problems through mathematical software like MATLAB, Mathematica etc.",
        },
      },
      {
        id: "6gY1OQdlJ_",
        type: "paragraph",
        data: { text: "<b>Project Work:</b>" },
      },
      {
        id: "JXgHIVlG_w",
        type: "paragraph",
        data: {
          text: "Apart from the curriculum, a project work on an applied mathematics field is mandatory for completion of the degree in our M.Sc. Mathematics courses. Every student has to carry out an independent project in the final year second semester under the guidance of an expert faculty member in the concerned field.",
        },
      },
      {
        id: "XpaUnjAJCx",
        type: "paragraph",
        data: { text: "<b>Our Alumni’s Positions:</b>" },
      },
      {
        id: "0OnKWnZ034",
        type: "paragraph",
        data: {
          text: "Alumni of our department occupy top positions in various renowned organizations at various levels:",
        },
      },
      {
        id: "eW7Tcped1v",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["<b>Positions</b>", "<b>Organizations</b>"],
            ["Software Experts&nbsp;", "Infosys, Cognizant, TCS, CMC, etc."],
            ["Scientists&nbsp;", "ISRO, NGRI, etc."],
            ["Managers&nbsp;", "Banks"],
            ["Faculty&nbsp;", "IITs, NITs"],
            ["Coaching Experts", "&nbsp;FIITJEE, TIME"],
            ["Consultants/Experts", "&nbsp;Foreign Countries"],
          ],
        },
      },
      {
        id: "_BkzZzQy9W",
        type: "header",
        data: { text: "MILE STONES OF THE DEPARTMENT", level: 3 },
      },
      { id: "ylGSzskjgR", type: "header", data: { text: "1959", level: 5 } },
      {
        id: "WRkHGkLJrL",
        type: "paragraph",
        data: {
          text: "1. The Department of Mathematics has been in existence since the inception of the College<b>.</b>",
        },
      },
      {
        id: "QyDceUQUkg",
        type: "paragraph",
        data: { text: "2. The department launched Ph.D. program." },
      },
      { id: "s_ZTCW56AB", type: "header", data: { text: "1970", level: 5 } },
      {
        id: "4jkKrTM37M",
        type: "paragraph",
        data: {
          text: "Started TWO years M.Sc. (Applied Mathematics) course sponsored by UNESCO with an intake of 6 students. later raised to 13 students and now increased to 24.",
        },
      },
      { id: "p5rhWc-ZPU", type: "header", data: { text: "1978", level: 5 } },
      {
        id: "dRrDniD2JP",
        type: "paragraph",
        data: {
          text: "Organized the prestigious 23rd Congress of Indian Society of Theoretical and Applied Mechanics (ISTAM) during 26-29 December 1978. A national level congress with the participants from all over the country.",
        },
      },
      { id: "8RSMmjXqVu", type: "header", data: { text: "1981", level: 5 } },
      {
        id: "FF3zaIEUK2",
        type: "paragraph",
        data: {
          text: "Prof P. V. Rao, USA was the visiting professor during 1981-82.",
        },
      },
      { id: "3z0wV_vZ8-", type: "header", data: { text: "1984", level: 5 } },
      {
        id: "WpJS4mzUSf",
        type: "paragraph",
        data: {
          text: "Expanded as the Department of Mathematics and Humanities.",
        },
      },
      { id: "tGen7ne9Ll", type: "header", data: { text: "1993", level: 5 } },
      {
        id: "s-GDKY4LBc",
        type: "paragraph",
        data: {
          text: "Organized the prestigious 5th Congress of Andhra Pradesh Society for Mathematical Society (APSMS) during 29-30 October, 1993. APSMS is the brain child of Prof N.Ch.Pattabhi Ramacharyulu the then Professor of Mathematics, Regional Engineering College, Warangal.",
        },
      },
      { id: "OVJ1Q-n8Gz", type: "header", data: { text: "1994", level: 5 } },
      {
        id: "1eEh1RbofL",
        type: "paragraph",
        data: {
          text: "Established a full-fledged Computation Laboratory to meet the requirements of the M.Sc. students, research scholars and the faculty.",
        },
      },
      { id: "_p19Y-WMED", type: "header", data: { text: "1999", level: 5 } },
      {
        id: "IJ_JoRqeYN",
        type: "paragraph",
        data: {
          text: "Organized the prestigious 44th Congress of Indian Society of Theoretical and Applied Mechanics (ISTAM) during 22 – 25 December 1999. A national level congress with the participants from across the country.",
        },
      },
      { id: "S48bPErkFx", type: "header", data: { text: "2000", level: 5 } },
      {
        id: "LWWlcK1w1w",
        type: "paragraph",
        data: {
          text: "Organized MATH QUEST - 2K, A national level Mathematics competition as a part of World mathematics Year - 2000 celebrations&nbsp; during 24 - 25 November&nbsp; 2000.",
        },
      },
      { id: "CDvRf2iL8z", type: "header", data: { text: "2001", level: 5 } },
      {
        id: "O9OWEazFxY",
        type: "paragraph",
        data: { text: "Mathematics Alumni Meet during 17 - 18, August 2001." },
      },
      { id: "6alSVvuNxa", type: "header", data: { text: "2001", level: 5 } },
      {
        id: "hGdbNNPgVL",
        type: "paragraph",
        data: {
          text: "Launched TWO years M.Sc. (Mathematics and Scientific Computing) course with a judicious combination of essential Mathematics, Applied Mathematics and Computer courses.",
        },
      },
      { id: "5u0zLlnPLQ", type: "header", data: { text: "2002", level: 5 } },
      {
        id: "PAH_mz-Jpd",
        type: "paragraph",
        data: {
          text: "Recognized as a center for Quality Improvement Programme (QIP) by the AICTE apart from IITs and IISc (only from NITs) for Research in MATHEMATICS to in service candidates.",
        },
      },
      { id: "ksrrqAL6m8", type: "header", data: { text: "2004", level: 5 } },
      {
        id: "kvWJN2J1YE",
        type: "paragraph",
        data: {
          text: "Organized the prestigious 13th Congress of Andhra Pradesh Society for Mathematical Society (APSMS) during 10-12 December 2004.",
        },
      },
      { id: "UjD1Ry5Dco", type: "header", data: { text: "2009", level: 5 } },
      {
        id: "0jsniUsuc3",
        type: "paragraph",
        data: { text: "Bifurcated as the Department of Mathematics." },
      },
      { id: "qnI0vYPbVd", type: "header", data: { text: "2010", level: 5 } },
      {
        id: "rVRXv-RUo7",
        type: "paragraph",
        data: {
          text: "Prof.J.Brestensky, Faculty of Mathematics, Physics and Informatics at Comenius University in Bratislava, Slovakia was the visiting Professor during December 2010 to February 2011.",
        },
      },
      { id: "3ZVoDveKWG", type: "header", data: { text: "2012", level: 5 } },
      {
        id: "3WjRqFmG0z",
        type: "paragraph",
        data: {
          text: "Organized a national conference on New Vistas in CFD in engineering during 27-29, January 2012.",
        },
      },
      { id: "q7Z1eePK60", type: "header", data: { text: "2015", level: 5 } },
      {
        id: "gBK6qxJKTX",
        type: "paragraph",
        data: {
          text: "Organized international conference on Computational Heat and Mass Transfer with 250 participants across the globe and the publication of proceedings in an Elsevier journal “Procedia Engineering” during 30 November- 2 December, 2015.",
        },
      },
      { id: "JzmMKivZQS", type: "header", data: { text: "2015", level: 5 } },
      {
        id: "qWsoYN0yTW",
        type: "paragraph",
        data: {
          text: "Entered into a Memorandum of understanding with Indian Institute of Geomagnetism, Mumbai.",
        },
      },
      { id: "ZFmirOmoMa", type: "header", data: { text: "2016", level: 5 } },
      {
        id: "Zz-rkAlrv6",
        type: "paragraph",
        data: {
          text: "Organized a National Conference on Women in Higher Education: Perspectives and Challenges in Digital Era sponsored by TEQIP in collaboration with the Department of Humanities and Social Sciences, NITW during 30-31, May 2016.",
        },
      },
      { id: "EUdo3ArBRA", type: "header", data: { text: "2016", level: 5 } },
      {
        id: "6y7EU7nJaj",
        type: "paragraph",
        data: {
          text: "Prof. J. Brestensky, Dept. of Mathematics, Physics and Informatics (FMPHI), Comenius University, Bratislava, Slovakia offered a GIAN course sponsored by MHRD for 10 days on Hydrodynamic Stability and Dynamo Theory during 9 - 20 December 2016.",
        },
      },
      { id: "RPwdtF8TMF", type: "header", data: { text: "2016", level: 5 } },
      {
        id: "--UBBDznj7",
        type: "paragraph",
        data: {
          text: "Organized a National Conference on Applied Nonlinear Dynamics in collaboration with Indian Institute of Geomagnetism, Mumbai during 21 - 22, December, 2016.",
        },
      },
      { id: "Sl1reMMi8P", type: "header", data: { text: "2017", level: 5 } },
      {
        id: "VtLa3m6w_8",
        type: "paragraph",
        data: {
          text: "Prof. Tony W. H. Sheu, National Taiwan University, Taiwan offered GIAN course sponsored by MHRD for 10 days on Computational Methods and Parallel Processing in Science and Technology during 20 - 30, December 2017.",
        },
      },
      { id: "9vGYfG1j7E", type: "header", data: { text: "2018", level: 5 } },
      {
        id: "CXKotHGhAd",
        type: "paragraph",
        data: {
          text: "Organized an International Conference on Numerical Heat Transfer and Fluid Flow with 120 participants across the globe and the publication of proceedings in an Springer popular book series “Lecture Notes in Mechanical Engineering” during 19 - 21, January 2018.",
        },
      },
      { id: "2O6BIyixGC", type: "header", data: { text: "2018", level: 5 } },
      {
        id: "uhC__hGptq",
        type: "paragraph",
        data: {
          text: "Organized a national conference on Mathematical Modeling in Science and Engineering during 27 - 28, March 2018.",
        },
      },
      { id: "U86JPEUIqb", type: "header", data: { text: "2018", level: 5 } },
      {
        id: "Db2a3FZDnp",
        type: "paragraph",
        data: {
          text: "Recognized as a National Resource Centre in Mathematics by MHRD, Govt. of Inida to conduct Online Refresher Courses for all Mathematics Faculty members (irrespective of their seniority and designation) of all Institutions in the Country.",
        },
      },
      { id: "T8rZtxOJ5w", type: "header", data: { text: "2018", level: 5 } },
      {
        id: "d17bE4YePH",
        type: "paragraph",
        data: {
          text: "Prof. Jitesh S. B. Gajjar. Department of Mathematics, University of Manchester, England, U.K. offered a GIAN course on Separation and Instabilities in High Speed Flows sponsored by MHRD for 10 days during 06 – 17 August, 2018.",
        },
      },
      { id: "7S0gZHW2YM", type: "header", data: { text: "2018", level: 5 } },
      {
        id: "Y1SKH1e_TA",
        type: "paragraph",
        data: {
          text: "Recognized as a National Resource Centre in Mathematics by MHRD, Govt. of India to conduct Online Refresher Courses for all Mathematics Faculty members (irrespective of their seniority and designation) of all Institutions in the Country.",
        },
      },
    ],
    language: "En",
    _id: "6162b0dcc843cb0023036eb5",
    time: 1640277713810,
    version: "2.22.2",
    title: "Home",
    url: "/mathematics/home",
    path: "/mathematics",
    createdAt: "2021-10-10T09:22:36.482Z",
    updatedAt: "2021-12-23T16:41:54.347Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "MNxbOKg1sg",
        type: "header",
        data: { text: "Ph.D. Program", level: 3 },
      },
      {
        id: "_JKI5bWcJq",
        type: "paragraph",
        data: {
          text: "The Department offers Ph.D programme in Mathematics on regular basis, Part time and also under Quality Improvement Programme (QIP). The admissions for regular Ph.D. programme are made through advertisement by the institute in every semester.",
        },
      },
      {
        id: "rg3eQ6TBjb",
        type: "paragraph",
        data: {
          text: "The AICTE, based on the progress made by the Department and the recommendations of the expert committee, sanctioned One seat for Ph.D. under QIP Programme.",
        },
      },
      {
        id: "m8fCmgbELA",
        type: "paragraph",
        data: {
          text: "The admissions for the Ph.D. programme under Q.I.P. are made by the AICTE by the advertisement in leading news papers in every year July/August.",
        },
      },
      {
        id: "8avNLQBXI3",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/08/27/phd-rules-and-regulations-2021-22.pdf"><b>2021 - 22&nbsp; Regulations</b></a>',
          ],
        },
      },
      {
        id: "4IfiFMJftw",
        type: "header",
        data: { text: "Post Graduate Courses", level: 3 },
      },
      {
        id: "LO3i-5R0PD",
        type: "header",
        data: {
          text: "M.Sc. (Applied Mathematics) – 2 year Programme:",
          level: 5,
        },
      },
      {
        id: "gFWs5U58wS",
        type: "paragraph",
        data: {
          text: "This industrially oriented M.Sc. (Applied Mathematics) course, initially sponsored by UNESCO, was started in the year 1970 with an initial&nbsp;intake of 6 students and later raised to 10 students in open category and 3 seats for SC/ST reservation.&nbsp;Now the number of seats are increased to 48.",
        },
      },
      {
        id: "hmzmO7q2Zj",
        type: "paragraph",
        data: {
          text: "In addition to the regular class room&nbsp;instruction and laboratory course in each semester, seminar and project work are integral parts of both&nbsp;the courses. They inculcate a spirit of practical application of Mathematical Concepts and also instill&nbsp;enthusiasm for research activity. Special emphasis is laid on promoting team spirit and improving the&nbsp;oral communication skills of the students.",
        },
      },
      {
        id: "gp86nZnMrs",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_applied-mathematics.pdf">M.Sc. (Applied Mathematics)&nbsp;Scheme &amp; Syllabi Effective from 2021-2022</a>',
        },
      },
      {
        id: "FjBBWB_397",
        type: "paragraph",
        data: {
          text: "<b>Admission to this&nbsp;programmes is through IIT Joint&nbsp;Admission Test for MSc (IIT JAM)</b>",
        },
      },
      {
        id: "yjqy2oHIBP",
        type: "header",
        data: {
          text: "Scheme of Instruction For  M. Sc. (Applied Mathematics)",
          level: 5,
        },
      },
      {
        id: "ehciExWWrm",
        type: "paragraph",
        data: { text: "<b>I – Semester</b>" },
      },
      {
        id: "H1RyFUTCRE",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["S. No", "Course Code", "Course Name", "L", "T", "P", "Credit"],
            ["1", "MA 5101", "Real Analysis", "3", "1", "0", "4"],
            ["2", "MA 5102", "Advanced Modern Algebra", "3", "1", "0", "4"],
            [
              "3",
              "MA 5103",
              "Ordinary Differential Equations",
              "3",
              "1",
              "0",
              "4",
            ],
            ["4", "MA 5104", "Linear Algebra", "3", "1", "0", "4"],
            [
              "5",
              "MA 5105",
              "Computer Programming &amp; Data Structures (CPDS)",
              "3",
              "1",
              "0",
              "4",
            ],
            ["6", "MA 5106", "Numerical Analysis", "3", "1", "0", "4"],
            ["7", "MA 5107", "CPDS Lab", "0", "1", "2", "2"],
            [
              "<b>Total</b>",
              "",
              "",
              "<b>18</b>",
              "<b>7</b>",
              "<b>2</b>",
              "<b>26</b>",
            ],
          ],
        },
      },
      {
        id: "iDWol3whF6",
        type: "paragraph",
        data: { text: "<b>II – Semester</b>" },
      },
      {
        id: "B4TtWKZBJN",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["S. No", "Course Code", "Course Name", "L", "T", "P", "Credit"],
            [
              "1",
              "MA 5151",
              "Probability &amp; Statistics",
              "3",
              "1",
              "0",
              "4",
            ],
            [
              "2",
              "MA 5152",
              "Partial Differential Equations",
              "3",
              "1",
              "0",
              "4",
            ],
            [
              "3",
              "MA 5153",
              "Integral &amp; Discrete Transforms",
              "3",
              "1",
              "0",
              "4",
            ],
            ["4", "MA 5154", "Topology", "3", "1", "0", "4"],
            ["5", "MA 5155", "Mechanics", "3", "1", "0", "4"],
            ["6", "", "ELECTIVE – I", "3", "0", "0", "3"],
            ["7", "MA 5156", "Computational  Laboratory", "0", "1", "2", "2"],
            ["8", "MA 5191", "Seminar", "0", "0", "2", "1"],
            [
              "<b>Total</b>",
              "",
              "",
              "<b>18</b>",
              "<b>7</b>",
              "<b>2</b>",
              "<b>26</b>",
            ],
          ],
        },
      },
      {
        id: "UWX2hiHs0s",
        type: "header",
        data: { text: "M.Sc (Mathematics and Scientific Computing)", level: 6 },
      },
      {
        id: "rR2LtyNefw",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_mathematics-scientific-computing.pdf">M.Sc. (</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_mathematics-scientific-computing.pdf">Mathematics &amp; Scientific&nbsp;Computing)&nbsp;Scheme &amp; Syllabi (Effective from 2021-22)</a>',
          ],
        },
      },
      {
        id: "nsVhgi0HOn",
        type: "paragraph",
        data: {
          text: "M.Sc. (Mathematics and Scientific Computing) – 2 year Programme:",
        },
      },
      {
        id: "aV4t79xhTV",
        type: "paragraph",
        data: {
          text: "In the context of changing needs of the software industry, the Department is offering a Computer Oriented Mathematics course –M.Sc. (Mathematics and Scientific Computing). This course is offered from the year 2001 onwards,&nbsp;with a judicious combination of essential Mathematics, Applied Mathematics and Computer courses.",
        },
      },
      {
        id: "sTDyrWSiZs",
        type: "paragraph",
        data: {
          text: "In addition to the regular class room&nbsp;instruction and laboratory course in each semester, seminar and project work are integral parts of both&nbsp;the courses. They inculcate a spirit of practical application of Mathematical Concepts and also instill&nbsp;enthusiasm for research activity. Special emphasis is laid on promoting team spirit and improving the&nbsp;oral communication skills of the students.",
        },
      },
      {
        id: "DRgLBq7TIg",
        type: "paragraph",
        data: {
          text: "Admission to this&nbsp;programmes is through IIT Joint&nbsp;Admission Test for MSc (IIT JAM)",
        },
      },
      {
        id: "9-EQ58lq2j",
        type: "paragraph",
        data: {
          text: "Scheme of Instruction For &nbsp;M. Sc.(Mathematics &amp; Scientific Computing)",
        },
      },
      {
        id: "qY3-EOFGEb",
        type: "header",
        data: { text: "Other PG Courses", level: 6 },
      },
      {
        id: "bKghM7T29F",
        type: "paragraph",
        data: {
          text: "At post-graduate level, the Department offers well-designed diverse courses for all&nbsp;Programmes of M.Tech. M.C.A., M.B.A. and M.Sc. Tech (Engg. Physics) and also offers Global&nbsp;Electives for all PG and Ph.D. Programmes&nbsp;&nbsp;",
        },
      },
      {
        id: "EiVDFkFiFO",
        type: "paragraph",
        data: { text: "Under Graduate Courses" },
      },
      { id: "05jXV5kYwL", type: "header", data: { text: "B.Tech", level: 6 } },
      {
        id: "EplRjdqTwD",
        type: "paragraph",
        data: { text: "UG level: B.Tech. All Branches:&nbsp;" },
      },
      {
        id: "dlQaOKe1Kz",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Mathematics-I&nbsp;",
            "Mathematics-II&nbsp;",
            "Mathematics-III (for II EEE, Chemical, MME, BioTech, I Sem)&nbsp;",
            "Mathematical Methods (for II Civil, I Sem)&nbsp;",
            "Transformation Techniques (for II Mech, I Sem)&nbsp;",
            "Complex Variables and Special Functions (for II ECE, I Sem)&nbsp;",
            "Statistical and Numerical Methods (for II CSE, I Sem)&nbsp;",
            "Mathematics-IV&nbsp;(for II EEE, Chemical, MME, BioTech, I Sem)&nbsp;",
            "Numerical and Statistical Methods (for II Mech, II Sem)&nbsp;",
            "Optimization Techniques (Global Elective)&nbsp;",
            "Numerical Solutions of Differential Equations (Global Elective)&nbsp;",
            "Operations Research (Global Elective)&nbsp;",
            "Fuzzy Logic and Fuzzy Systems (Global Elective)",
          ],
        },
      },
      {
        id: "TGNR0H6N54",
        type: "header",
        data: { text: "B.Tech Syllabi Maths", level: 6 },
      },
      {
        id: "KcCQ504Abe",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/10/22/10_minor-in-applied-and-computational-mathematics.pdf">Minor in APPLIED AND COMPUTATIONAL MATHEMATICS SCHEME OF INSTRUCTION AND SYLLABI (for all B.Tech. students)&nbsp;(Effective from 2021-22)</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "6162bfa7dfa9a90023cb0bbb",
    time: 1643043267311,
    version: "2.22.2",
    title: "Academics",
    url: "/mathematics/academics",
    path: "/mathematics",
    createdAt: "2021-10-10T10:25:43.461Z",
    updatedAt: "2022-01-24T16:54:27.871Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "zASN_GMDNA",
        type: "header",
        data: { text: "Research Areas:", level: 3 },
      },
      {
        id: "MwEji5RKHL",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Fluid Mechanics",
            "Bio-Mechanics",
            "Numerical Analysis",
            "Finite Element Method",
            "Computational Fluid Dynamics",
            "Operations Research",
            "Mathematical Modelling",
            "Algebraic Coding Theory",
            "Functional Analysis and Linear Algebra",
            "Cryptography",
            "Partial Differential equations",
          ],
        },
      },
      {
        id: "nH9-efeeJm",
        type: "header",
        data: { text: "R&amp;D Projects", level: 3 },
      },
      {
        id: "-0EiUaMTzR",
        type: "header",
        data: { text: "On-Going Projects :", level: 5 },
      },
      {
        id: "OleZo_zgrB",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Numerical treatment of convective transport in non-Newtonian fluids,&nbsp;October 2015 to Date&nbsp;Sponsored by Counsel of Scientific and Industrial Research, New Delhi.",
            "Nonlinear convection in rotating Earth's outer Core with Isotropic/ Anisotropic Diffusivities,&nbsp;February 2016 to date, Sponsored by DST(SERB), New Delhi.",
            "Numerical Study of convective transport in a viscous fluid-saturated porous medium,&nbsp;February 2017 to date, Sponsored by DST(SERB), New Delhi.",
          ],
        },
      },
      {
        id: "M5rCccjcTi",
        type: "header",
        data: { text: "&nbsp;Projects Completed:", level: 5 },
      },
      {
        id: "htTqUT7pHz",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Development of an Information Management System for Operationalizing Maternal Neonatal &amp; Child Health (MNCH) in a CHNC Area, May 2011 to March 2013, Sponsored by UNICEF, Hyderabad.",
            "Free and Forced Convection effects on perfectly conduction micropolar fluid, October 2010 To September 2013 Sponsored by Counsel of Scientific and Industrial Research, New Delhi.",
            "CFD Analysis of flow assisted corrosion (FAC) problems in pipes and pipe bends,&nbsp;December&nbsp;2009&nbsp; to July 2013,&nbsp; Sponsored by Dept of Atomic Energy, BRNS, Mumbai.",
          ],
        },
      },
    ],
    language: "En",
    _id: "6162c4f7dfa9a90023cb0bc5",
    time: 1633862936518,
    version: "2.22.2",
    title: "Research",
    url: "/mathematics/research",
    path: "/mathematics",
    createdAt: "2021-10-10T10:48:23.595Z",
    updatedAt: "2021-10-10T10:48:59.081Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "3vwDEpdEXD",
        type: "header",
        data: { text: "Computation Laboratory", level: 3 },
      },
      {
        id: "HJ06pXb7dd",
        type: "paragraph",
        data: {
          text: "The Department has a full-fledged computational laboratory to meet the requirements of the M.Sc. students, research scholars and the faculty.&nbsp;",
        },
      },
      {
        id: "AeiYI7NP22",
        type: "paragraph",
        data: {
          text: "Presently there are 24 system in the Computation lab with advanced configuration.",
        },
      },
      {
        id: "0k80ESe7cc",
        type: "paragraph",
        data: {
          text: "The students are trained in computer programming languages like C, C++, FORTRAN and SQL. They also gain exposure in handling problems through mathematical software like MATLAB, Mathematica etc.",
        },
      },
      {
        id: "lnRBRZoVil",
        type: "header",
        data: { text: "Mathematics Association", level: 3 },
      },
      {
        id: "8EhagmHg99",
        type: "paragraph",
        data: {
          text: "All the students and faculty of the Department are members of the&nbsp;Mathematics association. This association organizes several academic and co-curricular programs like&nbsp;Quiz, Debate, Talent tests etc.",
        },
      },
    ],
    language: "En",
    _id: "6162c699dfa9a90023cb0bcc",
    time: 1633863338417,
    version: "2.22.2",
    title: "Resources",
    url: "/mathematics/resources",
    path: "/mathematics",
    createdAt: "2021-10-10T10:55:21.513Z",
    updatedAt: "2021-10-10T10:55:41.019Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "XADpw3BedR",
        type: "header",
        data: { text: "Seminars/Conferences", level: 3 },
      },
      {
        id: "mIOYhmB3-B",
        type: "header",
        data: { text: "Upcoming Seminars / Conferences / Workshops", level: 5 },
      },
    ],
    language: "En",
    _id: "6162c711dfa9a90023cb0bd0",
    time: 1634616515107,
    version: "2.22.2",
    title: "News",
    url: "/mathematics/news",
    path: "/mathematics",
    createdAt: "2021-10-10T10:57:21.218Z",
    updatedAt: "2021-10-19T04:08:36.037Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "ZRHsnRRPyM",
        type: "header",
        data: { text: "Vision Statement:", level: 3 },
      },
      {
        id: "ctpOEH_GNw",
        type: "paragraph",
        data: {
          text: "To be a global knowledge hub in mechanical engineering education, research, entrepreneurship and industry outreach services.",
        },
      },
      {
        id: "b8dhbnZnVf",
        type: "header",
        data: { text: "Mission Statements:", level: 3 },
      },
      {
        id: "dzpfwn1-NT",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Impart quality education and training to nurture globally competitive mechanical engineers.",
            "Provide vital state-of-the-art research facilities to create, interpret, apply and disseminate knowledge.",
            "Develop linkages with world class educational institutions and R&amp;D organizations for excellence in teaching, research and consultancy services.",
          ],
        },
      },
      {
        id: "OWrBnDUDE0",
        type: "header",
        data: { text: "Central Workshops", level: 3 },
      },
      {
        id: "r36FsqhYW6",
        type: "paragraph",
        data: {
          text: "The central workshop a central facility, was merged with the Department of Mechanical Engineering in the year 1992. The Workshop consisting of Fitting Shop, Welding shop, Carpentry shop, Power Tools shop, Foundry Shop, Machine Shop and House wiring shop. The facilities are used for conduct of regular sessional classes as a part of the curriculum for students at various levels pertaining to different departments. In addition to housing many conventional machines and equipment it also has most modern fabrication and production units in tune with the recent technological developments. Some of these include Electro Chemical Machine, EDM, Tig and Mig welding machines, Tool and cutter Grinding Machine, Mitutoyo Tool Makers Microscope, Kistler 3 component force measurement Dynamometer, Variable Speed Precision Lathe, Tool Monitoring Equipment during machining and CNC Engraving Machine etc. Steps are afoot for further modernization of the central workshop utilizing the funds made available through MHRD / MODROBS / TEQIP etc.",
        },
      },
      {
        id: "MsUPn6VEMf",
        type: "paragraph",
        data: {
          text: "Workshop facilities are extended to facilitate conduct of different projects / research work at UG, PG and Doctoral level, under the guidance of the respective guides.",
        },
      },
      {
        id: "EnvFhfGj16",
        type: "header",
        data: { text: "Design Engineering", level: 3 },
      },
      {
        id: "kESUTukH2X",
        type: "paragraph",
        data: {
          text: "Design Engineering Section is one of the active divisions of Mechanical Engineering Department, with a focussed vision to promote Academic, Research, and Industrial Consulting activities. It has a total of ten faculty members. Their research areas include: Engineering Design and Rapid Prototyping; Bio-CAD and Bio Additive Fabrication, Geometric modelling, CAD, Isogeometric Analysis, Optimization and Robotics; Engineering Analysis and FEA; Vibration Analysis and Control; Condition Monitoring and Rotor Dynamics; Structural Health Monitoring; Tribology, Composite Materials; Piezoelectric Energy Harvesting, Micro Air Vehicles etc.",
        },
      },
      {
        id: "_7_Eb0iOSC",
        type: "paragraph",
        data: { text: "<b>List of Faculty Members:</b>" },
      },
      {
        id: "IYI3_ScKhd",
        type: "paragraph",
        data: { text: "Prof. R V Chalam" },
      },
      {
        id: "kZnK0QAmGz",
        type: "paragraph",
        data: {
          text: "Prof. P Bangaru Babu&nbsp;(Head, Design Engineering Section)",
        },
      },
      {
        id: "1Z5QTPIwfa",
        type: "paragraph",
        data: { text: "Dr. R Narasimha Rao" },
      },
      {
        id: "xLAlIcVuFI",
        type: "paragraph",
        data: { text: "Dr. V Suresh Babu" },
      },
      {
        id: "eWhUBSp2Gk",
        type: "paragraph",
        data: { text: "Dr. Srikanth Korla" },
      },
      {
        id: "esiNMC6Aog",
        type: "paragraph",
        data: { text: "Dr. Harikumar Voruganti" },
      },
      {
        id: "DB_cQ71jTR",
        type: "paragraph",
        data: { text: "Dr. B Satish Ben" },
      },
      {
        id: "fb4nZ31FIz",
        type: "paragraph",
        data: { text: "Dr. Syed Ismail" },
      },
      {
        id: "uWprG1Pg1L",
        type: "paragraph",
        data: { text: "Mr. G. Raghavendra" },
      },
      {
        id: "0oV97FCVlz",
        type: "paragraph",
        data: { text: "<b>List of Laboratories:</b>" },
      },
      {
        id: "xDptmwbrTB",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Machine Dynamics Laboratory (In-charge: Prof. RV Chalam)",
            "&nbsp;Product Design and Development (In-Charge: Dr. Srikanth Korla)<br>",
            "Tribology Laboratory (In-Charge: Dr. Syed Ismail)<br>",
            "&nbsp;Computational Research Laboratory (In-Charge: Dr. Hari Kumar Voruganti)",
          ],
        },
      },
      {
        id: "fs3b5vrBiP",
        type: "paragraph",
        data: { text: "<b>Academic Programme:</b>" },
      },
      {
        id: "e7zdpnAXDb",
        type: "paragraph",
        data: {
          text: "M. Tech. in Machine Design (Coordinator: Dr. V. Suresh Babu)",
        },
      },
      {
        id: "iHE6mHAOwH",
        type: "header",
        data: { text: "History And Milestones", level: 3 },
      },
      {
        id: "nnOEFNRchN",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "Sept.12,1959",
              "Starting of B.E (Mech.) course from the inception of the College",
            ],
            ["August,1964", "Starting of M.E. (Heat Power Engineering)"],
            [
              "Sept.,1966",
              "Starting of UNESCO sponsored M.Tech Programme in Design  &amp; Production Engineering (Machine Tools) with industrial orientation",
            ],
            [
              "Sept., 1970",
              "Conversion of M.E. (Heat Power Engineering) as M.Tech. (Design &amp; Production of I.C. Engines &amp; Gas Turbines- with industrial orientation",
            ],
            [
              "Dec., 1978",
              "Commencement of Part-time B. Tech. Programme for Diploma holders",
            ],
            ["June 6,1996", "Accreditation of B. Tech. &amp;M.Tech."],
            [
              "July 18,2000",
              "Commencement of M.Tech. courses:&nbsp;1. CIM  2.AMP (PDD) (Self Finance)",
            ],
            [
              "July 20,2001",
              "Approval as QIP Centre for Ph.D. and M.Tech. Programmes",
            ],
            [
              "August,2003",
              "Credit based system introduced with core courses, electives etc.",
            ],
            ["August, 2007", "M.Tech in Materials Technology Commenced"],
            [
              "March, 2008",
              "Accreditation of B. Tech. &amp; M. Tech Programmes",
            ],
            ["July, 2008", "M.Tech in Automobile Engineering Commenced"],
            [
              "July, 2013",
              "M.Tech in MSED (Materials and System Engineering Design) Commenced in collaboration with NFTDC Hyderabad",
            ],
            [
              "July, 2014",
              "M.Tech in Additive Manufacturing Commenced in collaboration with CMTI Bangalore",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "6162ca96dfa9a90023cb0bd2",
    time: 1633865790669,
    version: "2.22.2",
    title: "Home",
    url: "/me/home",
    path: "/me",
    createdAt: "2021-10-10T11:12:22.842Z",
    updatedAt: "2021-10-10T11:36:33.502Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "W92CQd3t12",
        type: "header",
        data: { text: "Research", level: 3 },
      },
      {
        id: "vsrzIHXyEJ",
        type: "paragraph",
        data: { text: "Research Projects sanctioned to Mech Engg." },
      },
      {
        id: "osC64a3Kw-",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "&nbsp; &nbsp; Modernization and Removal of Obsolescence in Central Workshop (Rs. 15.00 Lakhs)",
            "&nbsp; Characterization on Solar Transparent Insulation Materials based advanced Glazing’s for Energy Efficiency in Agriculture Building and Industrial Sectors (Rs. 12.00 Lakhs).",
            "&nbsp;&nbsp; Development of Responsive Intelligent Manufacturing Systems (Rs. 20.00 Lakhs)",
          ],
        },
      },
    ],
    language: "En",
    _id: "6162d10edfa9a90023cb0bdb",
    time: 1634198641704,
    version: "2.22.2",
    title: "Research",
    url: "/me/research",
    path: "/me",
    createdAt: "2021-10-10T11:39:58.820Z",
    updatedAt: "2021-10-14T08:04:03.612Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "3Kk7aMzJCf", type: "header", data: { text: "Ph.D", level: 3 } },
      {
        id: "N2XcAXj8f5",
        type: "paragraph",
        data: { text: "PhD program of Department of Mechanical Engineering" },
      },
      {
        id: "epQEOPfaPn",
        type: "paragraph",
        data: {
          text: "The department offers full time and part time Ph.D programmes in Mechanical Engineering. The admission to Ph.D programme takes place in both odd and even semesters of an academic year. The notification for the Ph.D admissions is advertised in the institute website as well as in leading national news papers.&nbsp; The minimum qualification and other details for the aspiring students &nbsp;can be obtained from the advertisement . &nbsp;The candidates are selected through a written exam and/or interview. All the regular Ph.D students are paid stipend by the Institute.&nbsp;The department has been recognized for Ph.D programme under QIP.",
        },
      },
      {
        id: "1whzLZaDwh",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/Scheme&amp;Syllabus-2012/Ph.D1.pdf">Rules and Regulations</a>&nbsp;(Introduction, Admissions, Program Structure, Evaluation, Requirements, Fellowships, Forms etc).',
            'List of PhD Scholars (<a href="https://drive.google.com/file/d/0B5VjI8YmohsMVVRHM0NERWFSM3c/view?resourcekey=0-I1zl5KydrfEsBxjkPsKqXw">In Progress and Completed</a>)',
            "<b>Coordinator of PhD Program:</b>Dr P S C Bose (subhashnitw@gmail.com, Ph: &nbsp;+91&nbsp;8332969405)",
            "Dr P S C Bose (subhashnitw@gmail.com, Ph: &nbsp;+91&nbsp;8332969405)",
          ],
        },
      },
      { id: "GIXdrz4a05", type: "header", data: { text: "B.Tech", level: 3 } },
      {
        id: "PjWODfQiO1",
        type: "paragraph",
        data: {
          text: "B. Tech. in Mechanical Engineering is the flagship programme of the Department. The graduate mechanical engineers apply scientific principles to the analysis, design and synthesis of various products and processes. The range of products include: automobiles, airplanes, electronics, surgical instruments, toys, building products, sports and recreational equipment etc. In all the cases, mechanical engineers design the products with the required functionality to high quality standards at the best possible price and in ways that are environmentally-friendly.",
        },
      },
      {
        id: "Q4gpgY3HG5",
        type: "paragraph",
        data: {
          text: "B. Tech. in Mechanical Engineering is existing from the inception of the Institute in the year 1959. This programme is of 4 years duration (8 semesters). The course work in the first two semesters is common to all branches of engineering. The core Mechanical Engineering courses are introduced from the 3rd&nbsp;semester onwards. Besides, elective courses are offered from 5thsemester onwards. The electives permit one to choose his/her area of interest within the broad areas. The laboratories are equipped with state of the art equipment to impart necessary hands-on practice. The students also carry out project work during the last two semesters based on the knowledge acquired.",
        },
      },
      {
        id: "G5c40XXAir",
        type: "paragraph",
        data: { text: "<b>Scheme &amp; Syllabus:</b>" },
      },
      {
        id: "J_vNhkGBX5",
        type: "paragraph",
        data: {
          text: "https://www.nitw.ac.in/media/uploads/2018/10/12/btech_mech_syllabus_2017.pdf",
        },
      },
      {
        id: "T8eVg0SROl",
        type: "paragraph",
        data: { text: "<b>Career Opportunities:</b>" },
      },
      {
        id: "G_aUNb5VBn",
        type: "paragraph",
        data: {
          text: "Mechanical engineers play their role in various sectors such as Product and Process industries, Automobiles, Heavy engineering, Oil and Gas, Transportation, etc. They pursue many job profiles covering shop floor, quality, design and analysis, R &amp; D, Sales and Marketing.",
        },
      },
      {
        id: "GMKPXLUmVx",
        type: "paragraph",
        data: {
          text: "<b>Admission Requirements:</b> Based on the rank in JEE",
        },
      },
      {
        id: "VL0BAiLpXL",
        type: "paragraph",
        data: { text: "<b>Course Duration:</b>&nbsp;4 Years (8 Semesters)" },
      },
      { id: "AWkzQsXowK", type: "header", data: { text: "M.Tech", level: 3 } },
      {
        id: "P2jKrQeZMy",
        type: "header",
        data: { text: "Automobile Engineering", level: 5 },
      },
      {
        id: "UbOnYjiP9j",
        type: "paragraph",
        data: {
          text: "The programme was started in the academic year 2008-2009. The programme covers the basic subjects related to Automobile Engineering such as Prime Movers, Automobile Body Structures, Vehicle Dynamics, Automotive Materials, Sensors and Actuators and Manufacturing Processes, Advanced CAD and so on. The first two semesters cover course work facilitating the students to learn both fundamentals and recent advances in various core and elective subjects supported by laboratory courses, which include Automotive Engines Laboratory, CAD Laboratory and Simulation Laboratory. The third and fourth semesters are earmarked for dissertation Work. Students are generally encouraged to do their projects in the industry, wherever there are chances of exposure to various avenues in Automobile Engineering. Those, who work in the Institute, take up both theoretical (analytical or numerical) and experimental dissertation works.",
        },
      },
      {
        id: "8-xgv0rwCc",
        type: "paragraph",
        data: { text: "<b>Scheme &amp; Syllabus</b>" },
      },
      {
        id: "zcGrBoaWlj",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2019/09/10/ae.pdf">https://nitw.ac.in/media/uploads/2019/09/10/ae.pdf</a>',
        },
      },
      {
        id: "5dBAwMuYph",
        type: "paragraph",
        data: { text: "<b>Career Opportunities:-</b>" },
      },
      {
        id: "ceLqL0aV5o",
        type: "paragraph",
        data: {
          text: "The automobile sector in India is growing at a faster rate for the last few years. It is expected that this growth continues for a few more years. This requires qualified technical manpower. It is hoped that the Automobile Engineering students would find good placement in this scenario. Some of the students, out of their passion for teaching cum research may even go for their Ph. D. in a specialized area.",
        },
      },
      {
        id: "mrByAUV62T",
        type: "paragraph",
        data: { text: "<b>Admission Prerequisites:-</b>" },
      },
      {
        id: "0j7XNQrTFF",
        type: "paragraph",
        data: {
          text: "B. E. or B. Tech. in Mechanical or Automobile or Aeronautical or Aerospace Engineering, with a First Class, and with a valid GATE score.",
        },
      },
      {
        id: "4nHztEjBaZ",
        type: "paragraph",
        data: { text: "<b>Course Duration:-</b>" },
      },
      {
        id: "9Oi_dnN4He",
        type: "paragraph",
        data: { text: "Two academic years or four semesters." },
      },
      {
        id: "LjzTzQEeo-",
        type: "paragraph",
        data: {
          text: "<b>For further information, contact the coordinator:</b>",
        },
      },
      {
        id: "y0k1wZdnAf",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dr. V P Chandramohan, Associate&nbsp;Professor&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email: vpcm80@nitw.ac.in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone:&nbsp; 8332969329",
        },
      },
      {
        id: "Xsnrm4EG3r",
        type: "paragraph",
        data: {
          text: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Webpage:&nbsp;<a href="https://nitw.ac.in/faculty/id/16436/">https://nitw.ac.in/faculty/id/16436</a>',
        },
      },
      {
        id: "BGE-VXF9RH",
        type: "header",
        data: { text: "Computer Integrated Manufacturing", level: 5 },
      },
      {
        id: "drIv-ThFey",
        type: "paragraph",
        data: {
          text: "Computer-integrated manufacturing&nbsp;is the&nbsp;manufacturing&nbsp;approach of using computers&nbsp;to control the entire production&nbsp;process. This integration allows individual processes to exchange information with each other and initiate actions. Through the integration of computers, manufacturing can be faster and less error-prone, although the main advantage is the ability to create automated manufacturing processes.&nbsp;In a CIM system functional areas such as design, analysis, simulation, planning, and purchasing, cost accounting, and scheduling, inventory control, and distribution, product life cycle management, supply chain management are linked through the computer with factory floor functions such as materials handling and management, providing direct control and monitoring of all the operations. To achieve automated manufacturing CIM uses technologies like FMS, ASRS, AGV, Robotics, Mechatronics, automated conveyor systems and computer aided techniques like CAD, CAE, CAM, CAPP, CAQ, PPC, ERP, a business system integrated by a common database.",
        },
      },
      {
        id: "WEQYvxSpSf",
        type: "paragraph",
        data: {
          text: "After through discussions with Industries and experts, the need for a graduate course on C.I.M is recognized and thus an M.Tech program in C.I.M is introduced in the MED in the year 2000 with due permission of AICTE.",
        },
      },
      {
        id: "QmUv3yOjkD",
        type: "paragraph",
        data: { text: "<b>Scheme &amp; Syllabus</b>" },
      },
      {
        id: "WPkV4eMvR7",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2019/09/10/cim.pdf">https://nitw.ac.in/media/uploads/2019/09/10/cim.pdf</a>',
        },
      },
      {
        id: "4zqposVd2t",
        type: "paragraph",
        data: { text: "<b>Objectives &amp; Activities</b>&nbsp;" },
      },
      {
        id: "VPIgkILB5A",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To train the PG students to work in the field of computer integrated manufacturing where in these students will get detailed insight into automation of manufacturing and manufacturing informatics.",
            "The students will study latest state of art subjects in the area of C.I.M and the courses are scrutinized with latest updated courses once in 2-4 years with BOS comprising of experts of Industry and Academia like IITs.",
            "To provide hands on experience to the students on the latest software and hardware and make them to fit ready to the Industries.",
          ],
        },
      },
      {
        id: "brbtQoVtAI",
        type: "paragraph",
        data: { text: "<b>Career Scope</b>" },
      },
      {
        id: "cjY_UUCqa2",
        type: "paragraph",
        data: {
          text: "Present day trend is towards use of automated machineries and optimization, which increase the production capacity to very high quantity. For these machineries either for manufacturing or for maintenance, more number of qualified professionals in the area of computer-integrated manufacturing is required. In addition, the present conventional industries are getting retrofitted into automated industries even for such activities experienced professionals with computer integrated manufacturing knowledge is required. By considering above information, knowledge of CIM will benefit students to get into high profile jobs of highly automated industries like Aerospace Engineers, Agriculture, Automobile, Biomedical, and Electrical/Electronics Engineers, Defense, Soft Computing, Materials Management and Supply Chains etc.,",
        },
      },
      {
        id: "4g7MsJsLzm",
        type: "paragraph",
        data: { text: "<b>Eligibility Criteria</b>" },
      },
      {
        id: "ekh9kKSK1J",
        type: "paragraph",
        data: {
          text: "Bachelor’s Degree in Mechanical Engineering/ Industrial Production Engineering/ Manufacturing Engineering/ Industrial Engineering &amp; Management/ Industrial Engineering &amp; Manufacturing or equivalent degree; AMIE in appropriate branch. GATE: ME, PI",
        },
      },
      {
        id: "Re3t0qhQmn",
        type: "paragraph",
        data: { text: "<b>Duration</b>" },
      },
      {
        id: "C9GiW61P6y",
        type: "paragraph",
        data: {
          text: "The duration of the course is 2 years comprising of 4 semesters with 2 semesters of class work and 2 semesters of in house R &amp; D project work.",
        },
      },
      {
        id: "stNuxGb4ef",
        type: "paragraph",
        data: {
          text: "<b>For further information, contact the coordinator:</b>",
        },
      },
      {
        id: "tdX3ov_VhA",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dr. M Vijaya Kumar, Assistant Professor&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;manupati.vijay@nitw.ac.in&nbsp;( E Mail)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;9775627564 (Phone)",
        },
      },
      {
        id: "oJOly-oDWn",
        type: "paragraph",
        data: {
          text: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://nitw.ac.in/faculty/id/16921/">https://nitw.ac.in/faculty/id/16921/</a><br>',
        },
      },
      {
        id: "BM1IW60stO",
        type: "header",
        data: { text: "M.Tech Additive Manufacturing", level: 5 },
      },
      {
        id: "sKy1gP7ucb",
        type: "paragraph",
        data: {
          text: "Additive Manufacturing&nbsp;is an emerging technology revolutionizing the manufacturing industry today. This technology is employed to build products in almost every sector. One of the striking features of this technology is that complexity of part is no more a concern during manufacturing. This technology is expected to grow rapidly in future.",
        },
      },
      {
        id: "3QTh3s89FO",
        type: "paragraph",
        data: {
          text: "The Department started M. Tech. in “Additive Manufacturing” for the first time in India in the year 2014 in collaboration with Central Manufacturing Technology Institute (CMTI), Bangalore.&nbsp;CMTI is an R &amp; D organization and plays the role of being a catalyst and a key player in manufacturing technology growth in the country.",
        },
      },
      {
        id: "lvM8kIDOqM",
        type: "paragraph",
        data: { text: "<b>Programme outcomes:</b>" },
      },
      {
        id: "T2eoxg7Zzu",
        type: "paragraph",
        data: {
          text: "At the end of this programme, students will be able to",
        },
      },
      {
        id: "foSZFTwJHH",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Apply engineering knowledge, techniques, skills and modern tools to analyze problems in AM.",
          ],
        },
      },
      {
        id: "vIog3IzDDf",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Identify different industrial sectors, relevant AM processes and measurement techniques to reduce cost and time from design to manufacture.",
          ],
        },
      },
      {
        id: "5b3EETYPib",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Develop advanced sensing and control algorithms for AM technology",
          ],
        },
      },
      {
        id: "X87PXv5YX7",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Exploit AM technology to reduce tooling cost at low volume production environments",
          ],
        },
      },
      {
        id: "Pu-51Jpbt1",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Apply 3D printing technology for organ printing and other bio-medical applications",
          ],
        },
      },
      {
        id: "wyFavIv6y0",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Design and conduct experiments to understand process behavior for providing optimal solutions.",
          ],
        },
      },
      {
        id: "N0Sp6GrDaK",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Develop and validate models to solve complex manufacturing problems using modern engineering and IT tools.",
          ],
        },
      },
      {
        id: "v52srUG4_l",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Communicate effectively while leading and executing projects.",
          ],
        },
      },
      {
        id: "oYqLW7FZqH",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Apply professional, ethical, legal, security and social issues in the design of AM processes.",
          ],
        },
      },
      {
        id: "49KiJn_R5l",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Engage in life-long learning as a means of enhancing knowledge and skills for professional advancement.",
          ],
        },
      },
      {
        id: "Pn_EOHDfci",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Conceptualize and analyze new problems leading to research and development.",
          ],
        },
      },
      {
        id: "l1i-TH6WL7",
        type: "paragraph",
        data: {
          text: "In the first two semesters the students study relevant core courses and a good number of elective courses. The electives permit one to choose his/her area of interest within the broad spectrum of courses. Experts from CMTI handle some of the courses. The laboratories are equipped with state of the art equipment to impart necessary hands-on practice. Laboratory classes in the first-semester will be conducted at NITW and in the second semester at CMTI. Last two semesters are dedicated for the dissertation work relevant to the course. Dissertation works will be carried out at CMTI. Nominal charges will be collected from the students for their stay at CMTI.",
        },
      },
      {
        id: "xIXmh2qtkA",
        type: "paragraph",
        data: { text: "<b>Scheme &amp; Syllabus</b>" },
      },
      {
        id: "muLwvAdwh2",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2019/09/10/am.pdf">https://nitw.ac.in/media/uploads/2019/09/10/am.pdf</a>',
        },
      },
      {
        id: "M613M7CMUp",
        type: "paragraph",
        data: { text: "<b>Career Opportunities:</b>" },
      },
      {
        id: "fYAVO6mJ8t",
        type: "paragraph",
        data: {
          text: "Engineers trained in this programme may pursue many job profiles. Many MNCs are in need of qualified engineers in AM technology to design and fabricate parts/items for various applications including engineering, agricultural, jewelry, textile, food, medical etc. Since it is a cutting edge technology, there is lot of scope for choosing research as a career in this field.",
        },
      },
      {
        id: "Gp4WBSB1l5",
        type: "paragraph",
        data: { text: "<b>Admission Requirements:</b>" },
      },
      {
        id: "7WnkETYXvn",
        type: "paragraph",
        data: {
          text: "B.E. /B. Tech in Mechanical/Production/Industrial and Production Engineering with a valid GATE score in Mechanical/Production and Industrial Engg.",
        },
      },
      {
        id: "FeJRtrd7uE",
        type: "paragraph",
        data: { text: "Course Duration:&nbsp;2 Years (4 Semesters)" },
      },
      {
        id: "2hewY5oSPR",
        type: "paragraph",
        data: {
          text: "<b>For further information, contact the coordinator:</b>",
        },
      },
      {
        id: "85Kd8BkuX-",
        type: "paragraph",
        data: {
          text: "Dr Manjaiah M, Assistant Professor,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
        },
      },
      {
        id: "jHJCsGtStJ",
        type: "paragraph",
        data: { text: "E Mail: Manjaiah.m@nitw.ac.in" },
      },
      {
        id: "LCmHf3c57B",
        type: "paragraph",
        data: { text: "Phone: 9740847669" },
      },
      {
        id: "GwL5SStFNa",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/faculty/id/17017/">https://nitw.ac.in/faculty/id/17017/</a>',
        },
      },
      {
        id: "nVl7RiliK4",
        type: "header",
        data: { text: "Machine Design", level: 5 },
      },
      {
        id: "TKBFDaV9Hg",
        type: "paragraph",
        data: {
          text: "The global industries have realized that the ability to consistently define and deliver products to the market place more rapidly and efficiently than their competitors can become a source of sustainable competitive advantage. These companies have moved their view to product design and development capability as an end to end business process that can be greatly enhanced through reengineering and can be continuously improved through total quality management.",
        },
      },
      {
        id: "s_2e71xX7Q",
        type: "paragraph",
        data: {
          text: "In this environment in the year 2006 the Department of Mechanical Engineering NIT Warangal started an M.Tech Programme titled Machine Design to prepare the human resources.",
        },
      },
      {
        id: "kKXGbdNe5b",
        type: "paragraph",
        data: {
          text: "The first two semesters cover course work facilitating the students to learn both fundamentals and recent advances in various core and elective subjects supported by laboratory courses, which include Numerical Simulation Laboratory, CAD Laboratory, Computer Aided Analysis Laboratory and Rapid Prototyping Laboratory. The third and fourth semesters are earmarked for Dissertation Work. Students are generally encouraged to do their projects in industries, wherever there are chances of exposure to various avenues. Those, who work in the Institute, take up both theoretical (analytical or numerical) and experimental dissertation works. The students are able to publish their work in journals or conferences of repute.",
        },
      },
      {
        id: "7E2qHZ4wgZ",
        type: "paragraph",
        data: {
          text: "The primary objective of the programme is to train the students in Machine Design field by imparting broad based training on the state of the art engineering design methods analysis tools and manufacturing processes. With this training we expect the students to become powerful practitioners of product designers. The knowledge gained by the students in this course is an effective and practical, immediately applicable to today’s design challenges in the industry.",
        },
      },
      {
        id: "7VDK8aF04A",
        type: "paragraph",
        data: { text: "<b>Scheme &amp; Syllabus</b>" },
      },
      {
        id: "G6cCIwPjvW",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2019/09/10/md.pdf">https://nitw.ac.in/media/uploads/2019/09/10/md.pdf</a>',
        },
      },
      {
        id: "C5tWNF1vbM",
        type: "paragraph",
        data: { text: "<b>Career Opportunities:</b>" },
      },
      {
        id: "u6yC69gXDn",
        type: "paragraph",
        data: {
          text: "Students are sought after by many Research organisations (like DRDO, DRDL, RCI, HAL, DMRL etc.), companies and industries (like GE, John Deere,&nbsp; Mahindra and Mahindra, 3D PLM, Hansen transmissions, Web Peripherals,&nbsp; L&amp;T,&nbsp; Honeywell,&nbsp; Cargotec ,&nbsp; LG,&nbsp; Michelin Tyres,&nbsp; Intergraph Solutions,&nbsp; Geometric solutions, HP, BE Aerospace,&nbsp; TCS, Ashok leylands, GTRE, ARAI, Wadia, Praga tools etc.).",
        },
      },
      {
        id: "dubpgclUoI",
        type: "paragraph",
        data: { text: "<b>Admission Requirements:</b>" },
      },
      {
        id: "P8fz9b901V",
        type: "paragraph",
        data: {
          text: "B.E. /B. Tech in Mechanical/Production/Automobile/Industrial Engineering with a valid GATE score.",
        },
      },
      {
        id: "pEKtpj7o3E",
        type: "paragraph",
        data: { text: "Course Duration:&nbsp;2 Years (4 Semesters)" },
      },
      {
        id: "zmDl2QU-TG",
        type: "paragraph",
        data: {
          text: "<b>For further information, contact the coordinator:</b>",
        },
      },
      {
        id: "h9rQXrvNrS",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dr.&nbsp;Satish Ben, Associate&nbsp;Professor&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email: satishben@nitw.ac.in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone: 9848065254",
        },
      },
      {
        id: "Kmg4843Kwb",
        type: "header",
        data: { text: "Manufacturing Engineering", level: 5 },
      },
      {
        id: "z5Dz3CntPs",
        type: "paragraph",
        data: {
          text: "Manufacturing engineers apply scientific principles to the production of various products. The range of products include: automobiles, airplanes, tractors, electronics, surgical instruments, toys, building products, sports and recreational equipment etc. In all the cases, manufacturing engineers design the products with the required functionality, to high quality standards at the best possible price and in ways that are environmentally-friendly.",
        },
      },
      {
        id: "6MuWa7ZWFI",
        type: "paragraph",
        data: {
          text: "The Department started M.Tech in “Design and Production of Machine Tools” in the year 1966 and it was renamed as Manufacturing Engineering in the year 2000. In the first two semesters the students study relevant core courses and a good number of elective courses. The electives permit one to choose his/her area of interest within the broad spectrum of courses .The laboratories are equipped with state of the art equipment to impart necessary hands-on practice. Last two semesters are dedicated for the dissertation work relevant to the course.",
        },
      },
      {
        id: "MAQqTSGmDB",
        type: "paragraph",
        data: { text: "<b>Scheme &amp; Syllabus</b>" },
      },
      {
        id: "QA8-jGXl5c",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2019/09/10/me.pdf">https://nitw.ac.in/media/uploads/2019/09/10/me.pdf</a>',
        },
      },
      {
        id: "s0HxZmh1Vt",
        type: "paragraph",
        data: { text: "<b>Career Opportunities:</b>" },
      },
      {
        id: "HAH8SrVbEv",
        type: "paragraph",
        data: {
          text: "Manufacturing engineers may pursue many job profiles. As a planning specialist one will estimate production schedules, as a material handling engineer, one will design new and advanced systems for directing the flow of material through the factory, as a production engineer one will design and develop machine tools, as a tool engineer one will design the tools required for producing parts, as a mechatronics engineer one will work in interdisciplinary teams and develop efficient mechatronic products and processes.",
        },
      },
      {
        id: "A8QyoTj3qh",
        type: "paragraph",
        data: { text: "<b>Admission Requirements:</b>" },
      },
      {
        id: "WqraHAHxs9",
        type: "paragraph",
        data: {
          text: "B.E. /B. Tech in Mechanical/Production/Industrial and Production Engineering with a valid GATE score in Mechanical/Production and Industrial Engg.",
        },
      },
      {
        id: "CqqNfymBAD",
        type: "paragraph",
        data: { text: "Course Duration:&nbsp;2 Years (4 Semesters)" },
      },
      {
        id: "u3zAPUTxoU",
        type: "paragraph",
        data: {
          text: "<b>For further information, contact the coordinator:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
        },
      },
      {
        id: "9hJFLoe0HH",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dr. Venkatesh Gudipadu, Assistant Professor&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email:&nbsp;&nbsp;venkatesh@nitw.ac.in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone: +91-7093901757",
        },
      },
      {
        id: "C6MPDv3HtH",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Webpage:&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16936/">https://www.nitw.ac.in/faculty/id/16278/</a>',
        },
      },
      {
        id: "f53JiHvOp_",
        type: "header",
        data: { text: "Materials And Systems Engineering Design", level: 5 },
      },
      {
        id: "OIByYicwDE",
        type: "paragraph",
        data: {
          text: "The Department of Mechanical Engineering, NIT Warangal in the year 2013 has started&nbsp;M.Tech. programme in&nbsp;Materials and System Engineering Design(MSED)&nbsp;in collaboration with Nonferrous Materials Technology Development Centre (NFTDC), a research laboratory based at Hyderabad. NFTDC is an Advanced Technology Centre, actively involved in high technology research in the areas such as (i) aerospace (ii) energy (conventional and nonconventional), (iii) automotive, (iv) biomedical, (v) process engineering and (vi) environment sectors.",
        },
      },
      {
        id: "fVkPEvajm6",
        type: "paragraph",
        data: {
          text: "The basic core competence of the M.Tech. programme is&nbsp;(a) Design, (b) Advanced Materials &amp; Manufacturing and (c) Controls and Instrumentation&nbsp;which have been integrated in all its research projects to render technology solutions in an end to end paradigm.",
        },
      },
      {
        id: "DYeLYn-URI",
        type: "paragraph",
        data: {
          text: "The primary objective of the programme is to train the student in selection of material and its processing, and control systems used in system engineering design such as energy systems: fuel cells / battery, automatic system: ECU of hybrid vehicle, Biomedical test rig of knee simulator and so on.",
        },
      },
      {
        id: "a8rNE7duBo",
        type: "paragraph",
        data: {
          text: "The present programme being of joint nature has to be of full time nature only with mandatory course work at NITW for first two semesters followed by third and fourth semester project work at NFTDC. The Lab sessions will be conducted at NFTDC.",
        },
      },
      {
        id: "Tx6EfsGDNz",
        type: "paragraph",
        data: { text: "<b>Scheme &amp; Syllabus</b>" },
      },
      {
        id: "viEyIqWZrl",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2019/09/10/msed_D7alCq1.pdf">https://nitw.ac.in/media/uploads/2019/09/10/msed_D7alCq1.pdf</a>',
        },
      },
      {
        id: "Xn_SDT2OtT",
        type: "paragraph",
        data: { text: "<b>Career opportunities:</b>" },
      },
      {
        id: "ZgDLADs5kP",
        type: "paragraph",
        data: {
          text: "From amongst the graduating students, a few will be given an option of serving in NFTDC for one year as Senior Project Associate on completion of the programme. This will give the necessary work experience to the graduating students.&nbsp; They also &nbsp;&nbsp; become eligible to apply to be considered for Project Engineer and other positions in NFTDC as per the norms prevailing in the centre from time to time.",
        },
      },
      {
        id: "ZyJv1czrhq",
        type: "paragraph",
        data: { text: "<b>Academic Requirements for admission:</b>" },
      },
      {
        id: "db2vT37gY_",
        type: "paragraph",
        data: {
          text: "BE/BTech in Mechanical / Metallurgy / Materials Science / Production Engineering / Industrial &amp; Production Engineering /&nbsp;Mechatronics, Aerospace / Automobile Engineering with valid GATE scores in Mechanical Engineering (ME) / Materials Technology (MT) / Automobile Engineering (AE) / Production and Industrial Engineering (PIE).",
        },
      },
      {
        id: "zhJKrOwnzx",
        type: "paragraph",
        data: { text: "<b>Course Duration:</b>" },
      },
      {
        id: "fOoga9Ja8I",
        type: "paragraph",
        data: { text: "Two academic years or four semesters." },
      },
      {
        id: "GXfWRyy0cc",
        type: "paragraph",
        data: { text: "For further information, contact the coordinator:" },
      },
      {
        id: "WyJIETBzGA",
        type: "paragraph",
        data: { text: "<b>Dr. Marrapu Bhargava</b>" },
      },
      {
        id: "mErIe82av5",
        type: "paragraph",
        data: {
          text: "Assistant Professor&nbsp;- Mechanical Engineering Department",
        },
      },
      {
        id: "v5yws_VaO8",
        type: "paragraph",
        data: { text: "E Mail: bhargava@nitw.ac.in" },
      },
      {
        id: "z5R0dm6Fh-",
        type: "paragraph",
        data: { text: "Phone&nbsp; 9004669353" },
      },
      {
        id: "Tj4NvUtXaS",
        type: "header",
        data: { text: "Thermal Engineering", level: 5 },
      },
      {
        id: "7ppaFSqiRg",
        type: "paragraph",
        data: {
          text: "This programme was being offered with the title “Design and Production of IC Engines and Gas Turbines” till the year 2000. The programme emphasizes application of fundamental principles of Thermal Engineering for various applications, which include Steam Power Plants, Gas Turbine Power Plants, Petroleum Refineries, Solar Appliances, Cooling of Electronic Equipment and Devices, Automobiles, I. C. Engines, Refrigeration Plants, Air-Conditioning Systems, Cold Storage Units, Propulsion Systems and so on. The first two semesters cover course work facilitating the students to learn both fundamentals and recent advances in various core and elective subjects supported by laboratory courses, which include Computational Fluid Dynamics Laboratory, Thermal Engineering Laboratory and Energy Systems Laboratory. The third and fourth semesters are earmarked for Dissertation Work. Students are generally encouraged to do their projects in industries, wherever there are chances of exposure to various avenues in Thermal Engineering. Those, who work in the Institute, take up both theoretical (analytical or numerical) and experimental dissertation works. The students are able to publish their work in journals or conferences of repute.",
        },
      },
      {
        id: "BSSHxjGjcg",
        type: "paragraph",
        data: { text: "<b>Scheme &amp; Syllabus</b>" },
      },
      {
        id: "WdnfeTyxSY",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2019/09/10/te.pdf">https://nitw.ac.in/media/uploads/2019/09/10/te.pdf</a>',
        },
      },
      {
        id: "CpWLeoWTnX",
        type: "paragraph",
        data: { text: "<b>Career Opportunities:-</b>" },
      },
      {
        id: "f4hmeQt-xw",
        type: "paragraph",
        data: {
          text: "Thermal Engineering students are sought after by many research establishments (like DRDO, NAL, BARC, ISRO, etc.), companies and industries (like GE, BHEL, NTPC, KOEL, Ashok Leyland, Mahindra, Tats Motors, L&amp;T, Reliance, etc.). Some of the students, out of their passion for teaching cum research may even go for their Ph. D. in a specialized area.",
        },
      },
      {
        id: "EwixgF4Ioh",
        type: "paragraph",
        data: { text: "<b>Admission Prerequisites:-</b>" },
      },
      {
        id: "O-LP9vHvIn",
        type: "paragraph",
        data: {
          text: "B. E. or B. Tech. in Mechanical or Automobile or Aeronautical or Aerospace Engineering, with a First Class, and with a valid GATE score.",
        },
      },
      {
        id: "rtyYVaoflJ",
        type: "paragraph",
        data: { text: "<b>Course Duration:-</b>" },
      },
      {
        id: "g4_RBXRBFU",
        type: "paragraph",
        data: { text: "Two academic years or four semesters." },
      },
      {
        id: "pdn5dM_aBH",
        type: "paragraph",
        data: {
          text: "<b>For further information, contact the coordinator:</b>",
        },
      },
      {
        id: "tv6YZvvurM",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dr. Devanuri Jaya Krishna, Associate&nbsp; Professor",
        },
      },
      {
        id: "TI0KEIK3MY",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email: djayakrishna@nitw.ac.in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone: 8332969369",
        },
      },
      {
        id: "3lN-j-VAXk",
        type: "paragraph",
        data: {
          text: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Webpage:&nbsp;<a href="https://nitw.ac.in/faculty/id/16435/">https://nitw.ac.in/faculty/id/16435</a>',
        },
      },
    ],
    language: "En",
    _id: "6167ee8b1a9f1f002340380e",
    time: 1634201281745,
    version: "2.22.2",
    title: "Academics",
    url: "/me/academics",
    path: "/me",
    createdAt: "2021-10-14T08:47:07.964Z",
    updatedAt: "2021-10-14T08:48:03.963Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9YWCMJr_d5",
        type: "paragraph",
        data: {
          text: "The&nbsp;Department of Metallurgical and Materials Engineering&nbsp;(formerly known as Department of Metallurgical Engineering) was established in the year 1965 with an intake of 30 students. A post-graduate programme in&nbsp;Industrial Metallurgy&nbsp;was introduced in the year 1983 with an intake of 10 students. The Department is also offering a&nbsp;Ph. D. programme&nbsp;since 1983. Department has also introduced another M. Tech. programme in&nbsp;Materials Technology&nbsp;from the academic year 2011-2012.",
        },
      },
      {
        id: "7w7tR0vyNC",
        type: "paragraph",
        data: {
          text: "The main areas of study in Metallurgical and Materials Engineering include Physical Metallurgy, Extractive Metallurgy (Iron, Steel and Non-ferrous Metal extraction), Mineral Processing, Heat Treatment of Steels, Manufacturing Technologies (Metal Casting, Metal Forming, Powder Metallurgy and Welding), Mechanical Metallurgy (includes Material Testing – Destructive and Non-Destructive), Corrosion Engineering, and Materials Characterization Techniques etc. In view of the increasing demand in materials development, the department has introduced new subjects in its curriculum with materials background in the year 2004. Accordingly the scheme of instruction has been enriched with subjects on advanced materials like Composite Materials, Ceramics, Polymers, Nanomaterials, Computer Applications in Metallurgical and Materials Engineering etc. in addition to the above main areas. The laboratories of the Department are well furnished with the state-of-the-art equipment.",
        },
      },
    ],
    language: "En",
    _id: "61685f71bbba560023006d52",
    time: 1634230149164,
    version: "2.22.2",
    title: "Home",
    url: "/mme/home",
    path: "/mme",
    createdAt: "2021-10-14T16:48:49.830Z",
    updatedAt: "2021-10-14T16:49:11.604Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "2TOm9b4b6w",
        type: "header",
        data: { text: "B. Tech.", level: 3 },
      },
      {
        id: "ZTeUnuPvOq",
        type: "paragraph",
        data: {
          text: "B. Tech. in Metallurgical &amp; Materials Engineering programme is of 4 years duration (8 semesters). The course work in the first two semesters is common to all branches of engineering. The core courses are introduced from the 3rd semester onwards. Besides, elective courses are offered from 5th semester onwards. The electives permit one to choose his/her area of interest within the broad areas. The students also carry out project work during the last two semesters based on the knowledge acquired.",
        },
      },
      {
        id: "6dxdiJyKQ6",
        type: "header",
        data: { text: "Program Educational Objectives", level: 5 },
      },
      {
        id: "yOR2h1LJ6-",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Apply knowledge of basic sciences and metallurgical engineering to extract &amp; refine metals and to manufacture products.",
            "Design, characterize and evaluate material systems for metallurgical and materials applications.",
            "Work effectively as individuals and as team members in multidisciplinary projects.",
            "Pursue life-long learning to enhance knowledge base and skills for professional growth.",
          ],
        },
      },
      {
        id: "JN6_YPZD82",
        type: "header",
        data: { text: "Program Outcomes", level: 5 },
      },
      {
        id: "-t2EmrtiOd",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Design processes for concentrating ores and minerals.",
            "Select processes for extraction of ferrous and non-ferrous metals.",
            "Assess performance of metallurgical processes.",
            "Identify processes to produce products as per specifications.",
            "Produce defect free products using metal forming and metal joining processes.",
            "Design thermo-mechanical treatment processes to modify the properties of metals and alloys for specific engineering applications.",
            "Analyze processes for protecting materials from mechanical and environmental degradation.",
            "Design material systems, components, processes for specific applications considering environmental sustainability.",
            "Apply modern science, engineering and project management principles to address the specific needs of metallurgical industries.",
            "Function in multi-disciplinary teams using tools and environment to achieve project objectives.",
            "Practice professional ethics for improved moral and human values.",
            "Engage in lifelong learning for professional advancement.",
          ],
        },
      },
      {
        id: "VBVPQGfIcW",
        type: "header",
        data: { text: "Scheme &amp; Syllabus", level: 5 },
      },
      {
        id: "2Q4D-PIub_",
        type: "paragraph",
        data: { text: "Admission Requirements: Based on the rank in JEE" },
      },
      {
        id: "AEyVzE4M1g",
        type: "paragraph",
        data: { text: "<b>Course Duration:</b> 4 Years (8 Semesters)" },
      },
      {
        id: "Nxwe9o4bh7",
        type: "header",
        data: { text: "M. Tech.", level: 3 },
      },
      {
        id: "NjrRiBtP-c",
        type: "header",
        data: { text: "(Industrial Metallurgy)", level: 3 },
      },
      {
        id: "d5eE5-coqS",
        type: "paragraph",
        data: {
          text: "A post-graduate programme in Industrial Metallurgy was introduced in the year 1983 with an intake of 10 students. This programme offers in depth knowledge about Physical Metallurgy, Extractive Metallurgy (Design &amp; production of Iron &amp; Steel), Manufacturing Technologies (Metal Casting, Metal Forming, Powder Metallurgy and Welding), Mechanical Metallurgy (includes Material Testing – Destructive and Non-Destructive), Corrosion Engineering, and Materials Characterization Techniques etc. In view of the increasing demand in materials development, the department has introduced new subjects in its curriculum with materials background in the year 2004. The students are exposed to core and advanced areas in the first two semesters. Dissertation work is being carried out in the third and fourth semesters.",
        },
      },
      {
        id: "7yfDxkNxnW",
        type: "header",
        data: { text: "Program Educational Objectives", level: 5 },
      },
      {
        id: "sdW02B-usk",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Design and develop Metallurgical and Materials Engineering systems to enhance the quality of life.",
            "Characterize materials and carry out research on advanced materials.",
            "Identify issues related to addressing safety hazards, material degradation and environmental pollution in metallurgical industries.",
            "Pursue life-long learning by enhancing knowledge and skills for professional advancement.",
          ],
        },
      },
      {
        id: "mREfVu0wzP",
        type: "header",
        data: { text: "Program Outcomes", level: 5 },
      },
      {
        id: "Pne7m4-jO0",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Apply thermodynamics and kinetics to improve metallurgical processes.",
            "Identify techniques for effective and eco-friendly production of iron and steel.",
            "Develop casting and forming process for effective design of industrial components.",
            "Analyze metal joining processes for specified requirements.",
            "Design metallurgical components as per specifications through powder technology.",
            "Process materials for improvement of properties.",
            "Characterize and evaluate materials for specific applications.",
            "Evaluate products using non-destructive testing methods and update processes.",
            "Identify mechanisms to protect engineering materials from degradation.",
            "Design and evaluate new materials to suit the requirements of modern systems.",
            "Apply project management techniques effectively to address issues related to metallurgical industries.",
            "Practice professional ethics and engage in lifelong learning for improved professional advancement, moral and human values.",
          ],
        },
      },
      {
        id: "-BOKyFlf7_",
        type: "header",
        data: { text: "Scheme &amp; Syllabus", level: 5 },
      },
      {
        id: "HoP5j2g-Fw",
        type: "paragraph",
        data: {
          text: "Admission Requirements:&nbsp;B. E. or B. Tech. in Mechanical or Metallurgical or Materials Engineering, with a First Class, and with a valid GATE score.",
        },
      },
      {
        id: "wg-Rvthwu0",
        type: "paragraph",
        data: { text: "<b>Course Duration:</b>&nbsp;2 Years (4 Semesters)" },
      },
      {
        id: "CDnKRHARvr",
        type: "header",
        data: { text: "Timetable", level: 5 },
      },
      {
        id: "5GxHb6a57Z",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/08/28/tt-mme-aug-2021-mtech-v03.pdf" target="_blank">Timetable for Current Semester IM and MT (2021-22 Sem I)</a>',
        },
      },
      {
        id: "WOBGdeMfdp",
        type: "header",
        data: { text: "M. Tech.", level: 3 },
      },
      {
        id: "nlfMLHplHZ",
        type: "header",
        data: { text: "(Materials Technology)", level: 3 },
      },
      {
        id: "eVV4V943Nh",
        type: "paragraph",
        data: {
          text: "A post-graduate programme in Materials Technology was introduced in the year 2011 with an intake of 20 students. This programme offers in depth knowledge about Physical Metallurgy, Extractive Metallurgy (Design &amp; production of Iron &amp; Steel), Manufacturing Technologies (Metal Casting, Metal Forming, Powder Metallurgy and Welding), Mechanical Metallurgy (includes Material Testing – Destructive and Non-Destructive), Corrosion Engineering, and Materials Characterization Techniques etc. The students are exposed to core and advanced areas in the first two semesters. Dissertation work is being carried out in the third and fourth semesters.",
        },
      },
      {
        id: "g8Cr1Q-TxM",
        type: "header",
        data: { text: "Program Educational Objectives", level: 5 },
      },
      {
        id: "tFi4qwdxVw",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Evaluate the performance of material systems using the relationship between structure, properties and processing.",
            "Characterize materials and carry out research on advanced materials.",
            "Design and develop effective and eco-friendly materials for generic and strategic applications.",
            "Pursue life-long learning by enhancing knowledge and skills for professional advancement.",
          ],
        },
      },
      {
        id: "bhLb70denf",
        type: "header",
        data: { text: "Program Outcomes", level: 5 },
      },
      {
        id: "c0QU0qTY6E",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Apply phase transformation phenomena to improve the performance of materials.",
            "Apply principles of deformation to modify structure and properties of materials.",
            "Characterize and evaluate materials for specific applications.",
            "Design metallurgical processes to produce products as per specifications.",
            "Evaluate products using non-destructive testing methods and modify processes.",
            "Identify mechanisms for protecting engineering materials from degradation.",
            "Synthesize ceramic, polymer, composite and non-ferrous materials.",
            "Design advanced materials for aerospace, biological, nuclear and high temperature applications.",
            "Apply project management techniques effectively to address issues related to metallurgical industries.",
            "Practice professional ethics and engage in lifelong learning for improved professional advancement, moral and human values.",
          ],
        },
      },
      {
        id: "4KkSBpewTq",
        type: "header",
        data: { text: "Scheme &amp; Syllabus", level: 5 },
      },
      {
        id: "-nsqPsmron",
        type: "paragraph",
        data: {
          text: "Admission Requirements: B. E. or B. Tech. in Mechanical or Metallurgical or Materials Engineering, with a First Class, and with a valid GATE score.",
        },
      },
      {
        id: "DtEdLWOnQ4",
        type: "paragraph",
        data: { text: "Course Duration: 2 Years (4 Semesters)" },
      },
      {
        id: "xi6gU0gSEg",
        type: "header",
        data: { text: "Timetable", level: 5 },
      },
      {
        id: "po9HhAqSJa",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/08/28/tt-mme-aug-2021-mtech-v03.pdf" target="_blank">Timetable for Current Semester MT and IM (2021-22 Sem I)</a>',
        },
      },
    ],
    language: "En",
    _id: "61686125bbba560023006d56",
    time: 1634230584998,
    version: "2.22.2",
    title: "Academics",
    url: "/mme/academics",
    path: "/mme",
    createdAt: "2021-10-14T16:56:05.432Z",
    updatedAt: "2021-10-14T16:56:27.334Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "nny0vwG1Jl",
        type: "header",
        data: { text: "Ongoing Projects", level: 3 },
      },
      {
        id: "S-HzJecwLb",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "1.",
              "Dr. Sreedevi Varam, Prof. Vadali V S S Srikanth, DST-SERB Core Research Grant, Hydrogen Production through Water Splitting by Novel Nanocrystalline Al-based Alloys (with Added Few Layered Graphene), 34.20 Lakhs.",
              "2020",
            ],
            [
              "2.",
              "Dr. Uma Maheswara Rao Seelam, DST-SERB, Towards 3 tesla coercivity Nd-Fe-B magnets without dysprosium addition into the bulk, 29.8 Lakhs.",
              "2020",
            ],
            [
              "3.",
              "Dr. Mahesh Kumar Talari, Prof. G. V. S. Nageswara Rao, Aeronautics R&amp;D Board, DRDO, Development of novel B and C modified fillers for the gas tungsten arc welding of Ti alloys, 48.00 Lakhs.",
              "2021",
            ],
            [
              "4.",
              "Dr. Nagumothu Kishore Babu, Prof. G. V. S. Nageswara Rao, Aeronautics R&amp;D Board, DRDO, Understanding the Role of Hydrogen in Titanium Alloy Weldments, 48.24 Lakhs.",
              "2021",
            ],
          ],
        },
      },
      {
        id: "CTCHKHLpmT",
        type: "header",
        data: {
          text: "Industries and R&amp;D organizations in collaboration",
          level: 3,
        },
      },
      {
        id: "EZ5wpBoOQu",
        type: "paragraph",
        data: {
          text: "The speciality of the courses at UG and PG level is that they are industry and R&amp;D oriented.",
        },
      },
      {
        id: "ihmQEoI9rt",
        type: "paragraph",
        data: {
          text: "The Department has close rapport with a number of Metallurgical Industries like Vizag Steel Plant, Visakhapatnam(A.P.); Mishra Dhatu Nigam Limited (MIDHANI), Hyderabad; Nuclear Fuel Complex, Hyderabad; Nava Bharat Ferro Alloys, Paloncha, (Khammam Dist.); Sai Surface Coating Technologies, Hyderabad; Fluidtherm Technology Private Limited, Chennai (T.N.) etc. and",
        },
      },
      {
        id: "UjTJQhohua",
        type: "paragraph",
        data: {
          text: "R&amp;D Organisations like Defence Metallurgical Research Laboratory (DMRL), Hyderabad; Defence Research and Development Organization (DRDO), Hyderabad; International Advanced Research Centre for Powder Metallurgy and New Materials (ARCI), Hyderabad; Bharat Heavy Electricals Limited (BHEL-R&amp;D), Hyderabad; Bhabha Atomic Research Centre (BARC), Mumbai; and Indira Gandhi Centre for Atomic Research (IGCAR), Kalpakkam (T.N), Indian Space Research Organization (ISRO), Bangalore etc.",
        },
      },
      {
        id: "bncmZK2UuT",
        type: "header",
        data: { text: "Modernisation of laboratories", level: 3 },
      },
      {
        id: "wiivIllHax",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Modernization of Mechanical Metallurgy Lab. (MHRD)",
            "Modernization of Material Testing Lab. (AICTE)",
            "Centre for Material Science and Engineering (MHRD)",
            "Development of Structural Metallurgy Lab. (MHRD)",
            "Upgradation of Metal Casting and Metal Joining Lab (INS – in collaboration with IIT Madras)",
          ],
        },
      },
      {
        id: "lBXQs2twpx",
        type: "header",
        data: { text: "Completed Projects", level: 3 },
      },
      {
        id: "AYkGEOrvAr",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "1.",
              "Dr. M. Hanumantha Rao, Dr. G. Sambasiva Rao, G.V.S. Nageswara Rao, AICTE (MODROB), Modernization of Material Testing Laboratory, 10.0 Lakhs",
              "1995",
            ],
            [
              "2.",
              "Dr. G. Sambasiva Rao, Dr. M. Hanumantha Rao, G.V.S. Nageswara Rao, AICTE (R&amp;D), Nitrocarburising of crankshaft steels using fluidized bed technology for improved tribological, fatigue and corrosion resistance properties, 4.0 Lakhs",
              "1995",
            ],
            [
              "3.",
              "Dr. G.V.S.Nageswara Rao, Centre of Excellence NITW, Chemical Thermal Treatment of Structural Steels Using Fluidized Bed Furnace,0.6 Lakhs",
              "1996",
            ],
            [
              "4.",
              "Dr. M. K. Mohan, AICTE, Indegenous production of Molybdenum and Molybdenum Carbide, 10.0 Lakhs.",
              "1998",
            ],
            [
              "5.",
              "Dr. M. K. Mohan, MHRD, Surface modification of Ti alloys used for Biomedical Implants, 10 Lakhs.",
              "1998",
            ],
            [
              "6.",
              "Dr. M. Hanumantha Rao, Dr. G. Sambasiva Rao, G.V.S. Nageswara Rao, MHRD (MODROB), Modernization of Mechanical Testing Laboratory, 7.0 Lakhs",
              "1999",
            ],
            [
              "7.",
              "Dr. G.V.S.Nageswara Rao, MHRD (R&amp;D), Surface Modification of Ti alloys used for Biomedical Implants, 10.0 Lakhs",
              "2005",
            ],
            [
              "8.",
              "Dr. G.V.S.Nageswara Rao,TEQIP-NITW, Life enhancement of Tooling used in machines used for Briquetting of Agricultural wastes by Electro Spark Coating (ESC) Technique,  0.3 Lakhs",
              "2007",
            ],
            [
              "9.",
              "M. K. Mohan, Naval Research Board (NRB), Studies on Corrosion Behaviour of a Nickel-based Superalloy for Application as Fasteners in Marine Environment, 18.80 Lakhs.",
              "2007",
            ],
            [
              "10.",
              "Dr. G.V.S.Nageswara Rao, AR&amp;DB, Improvement of Tribological Properties of Titanium Alloys, 17.1392 Lakhs.",
              "2008",
            ],
            [
              "11.",
              "Dr. M. K. Mohan, NRB, Studies on Corrosion Behaviour of Nickel- Based superalloy for application as fasteners in Marine Environment, 19.0 Lakhs",
              "2010",
            ],
            [
              "12.",
              "Dr. M. K. Mohan, NRB, Inhibitor Formulation to Combat Corrosion and Fouling of Ship Seawater Cooling systems, 10 Lakhs.",
              "2010",
            ],
            [
              "13.",
              "A. K. Khanra, DRDO, Synthesis and Characterization of ZrB2 Powder and ZrB2 based Composite Powder, 15.86 Lakhs.",
              "2010",
            ],
            [
              "14.",
              "Dr. M.K.Mohan, ISRO, Evaluation of Ti-15-3 Beta Titanium alloy for high strength fasteners for space applications, 16.79 Lakhs.",
              "2011",
            ],
            [
              "15.",
              "Dr. C.Vanitha, MHRD-RSM, Texture and Micro Structure Developments in  Cold, Warm and Hot Rolled pure magnesium,   AZ91,AZ61 and AZ31 , 1.25 Lakh",
              "2011",
            ],
            [
              "16.",
              "Asit Kumar Khanra, CSIR, Kinetic Study on Self-Propagating High Temperature Synthesis (SHS) of TiO2-H3BO3-Mg Mixture for the production of Nanocrystalline and Ultrafine TiB2 powder, 21.5 Lakhs.",
              "2012",
            ],
            [
              "17.",
              "Dr. G. V. S. Nageswara Rao (PI), Dr. C. Vanita (Co-PI), DRDO-ERIPR, Influence of Gas Tungsten Arc Welding on Microstructure and Tensile Properties of Cu Modified Ti-6Al-4V Alloy, 20.77 Lakhs.",
              "2013",
            ],
            [
              "18.",
              "Dr. G. V. S. Nageswara Rao, DAE-BRNS, Development and Evaluation of Wear Properties of Boron Carbide and Chromium Diboride for Control Rod Applications in Nuclear Reactors, 46.655 Lakhs.",
              "2013",
            ],
            [
              "19.",
              "Dr. G. V. S. Nageswara Rao and team, Department of Science and Technology (DST), strengthening of PG programme under “Fund for Improvement of Science &amp; Technology Infrastructure (FIST)”, 147.0 Lakhs",
              "2015",
            ],
            [
              "20.",
              "G. Brahma Raju, Development of ZrB2-SiC-Si3N4 ceramic composites for high temperature applications, 27.60 Lakhs.",
              "2015",
            ],
            [
              "21.",
              "Ajoy Kumar Pandey, DST-SERB, Development of Alumina-Zirconia-Titania Based Ceramic Composite for Biomedical&nbsp;Applications, 37.9 Lakhs.",
              "2016",
            ],
            [
              "22.",
              "R. Arokia Kumar, Ajoy Kumar Panday and N. Narasaiah, NTPC, In Situ hydrogen damage evaluation of boiler water wall tubes using NDT, 10.00 Lakhs.",
              "2016",
            ],
            [
              "23.",
              "B. Srinivasa Rao, DST-SERB, Development of high strength and creep resistant Mg-Li alloys for automotive and aerospace applications, 20.79 Lakhs.",
              "2016",
            ],
            [
              "24.",
              "Dr. M. K. Mohan, NRB, Electrochemical and surface analytical studies of corrosion behaviour of selected superally in Marine Gas Turbine Engine Conditions, 19.97 Lakhs.",
              "2017",
            ],
            [
              "25.",
              "Dr. M. K. Mohan, Mitigating the Environmentally Induced Degradation and Improving the Ductility of Flow Formed Maraging Steel Tubes, 10.0 Lakhs.",
              "2017",
            ],
            [
              "26.",
              "Dr. G. V. S. Nageswara Rao, UGC-DAE-CSR, Influence of Thermo-Mechanical Processing on Creep deformation and rupture behaviour of INRAFM steel, 12.5 Lakhs.",
              "2017",
            ],
            [
              "27.",
              "R. Arockia Kumar, DST-SERB-ECR, Development of high vibration damping composite layers through friction stir processing, 47.76 Lakhs.",
              "2017",
            ],
            [
              "28.",
              "R. Arokia Kumar, UGC-DAE-CSR, Development of ductile SMA through Powder Metallurgy, 0.09 Lakhs.",
              "2017",
            ],
            [
              "29.",
              "Prof. T. Srinivasa Rao &amp; Prof. G. V. S. Nageswara Rao, DAE-BRNS, Enhancement of Type-IV cracking resistance of modified 9Cr-1Mo ferritic steel by thermo mechanical treatment, 40.025 Lakhs.",
              "2018",
            ],
            [
              "30.",
              "Dr. G. V. S. Nageswara Rao, Mission Directorate, AUSC Consortium, Generation of Creep Data of wrought Alloys of 617M and 304HCu, 620.90 Lakhs.",
              "2018",
            ],
            [
              "31.",
              "Dr. G. V. S. Nageswara Rao, Mission Directorate, AUSC Consortium, Evaluation of Creep-Fatigue interaction properties of Alloy 617M forging, 348.89 Lakhs",
              "2018",
            ],
            [
              "32.",
              "Dr. G. V. S. Nageswara Rao, Mission Directorate, AUSC Consortium, Evaluation of Creep-Fatigue interaction properties of Alloy 625 Casting, 348.89 Lakhs",
              "2018",
            ],
            [
              "33.",
              "N. Narasaiah, R. Arockia Kumar, Dr.G.V.S. Nageswara Rao, Ajoy K. Pandey, B. Srinivasa Rao, Mission Directorate, AUSC Consortium, Evaluation of creep-fatigue crack growth (CFCG) for Alloy 625 Cast material, 490.56 Lakhs.",
              "2018",
            ],
            [
              "34.",
              "Ajoy K. Pandey, B. Srinivasa Rao, Asit K. Khanra, G. Brahma Raju, N. Narasaiah, Mission Directorate, AUSC Consortium, Evaluation of Creep – Fatigue Crack Growth (CFCG) for Alloy 617 forging material, 481.00 Lakhs.",
              "2018",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616862efbbba560023006d5a",
    time: 1634231918363,
    version: "2.22.2",
    title: "R&D",
    url: "/mme/research",
    path: "/mme",
    createdAt: "2021-10-14T17:03:43.260Z",
    updatedAt: "2021-10-14T17:18:41.202Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: {
          text: "WELCOME TO ELECTRICAL ENGINEERING DEPARTMENT",
          level: 3,
        },
      },
      {
        id: "Ns3eelU_fl",
        type: "paragraph",
        data: {
          text: "The Department of Electrical Engineering is one of the oldest departments of the National Institute of Technology, Warangal (NITW). Established as one of the major departments of the Institute, since its inception in 1959, the Department of Electrical Engineering has been actively engaged in teaching and research in diverse fields of Electrical Engineering. With excellent faculty, the Department of Electrical Engineering offers Under Graduate (B.Tech) and Post Graduate (M.Tech) in Power Electronics &amp; Drives and Power Systems and research (Ph.D) programmes.",
        },
      },
      {
        id: "dj_rjwvXQ3",
        type: "paragraph",
        data: {
          text: "The Department is strong with all faculty members holding PhD degrees and expertise in various fields. The broad areas of expertise include State Estimation and Real Time Control of Power Systems, Applications of ANN and Fuzzy Logic in Power Systems, Power System Deregulation, Power System Transients, Power Quality, EMTP applications in Power Systems, Relay Coordination, Application of Power Electronics to Power Quality Improvement and Industrial Drives, Hybrid Energy Storage Systems, DSP controlled Drives, Simulation of Power Electronic Converters and Drives Systems and Control of Special Machines",
        },
      },
      {
        id: "hIRqZE7xQt",
        type: "paragraph",
        data: {
          text: "The Department has strong Industry interaction and has been involved in constantly handling various consultancy projects for various Industries and Government of Telangana and Andhra Pradesh.&nbsp; Department has an MOU with Central Power Research Institute (CPRI), Defence Research and Development Organization (DRDO) to carry out the collaborative projects.",
        },
      },
      { id: "xQ8hu7oYnA", type: "header", data: { text: "Vision", level: 5 } },
      {
        id: "oy30l3d1CZ",
        type: "paragraph",
        data: {
          text: "To excel in education, research and technological services in electrical engineering in tune with societal aspirations.",
        },
      },
      { id: "0mErS4SCuP", type: "header", data: { text: "Mission", level: 5 } },
      {
        id: "3mDGtKFVxR",
        type: "paragraph",
        data: {
          text: "1. Impart quality education to produce globally competent electrical engineers capable of extending technological services.",
        },
      },
      {
        id: "3Vs0c4rFwY",
        type: "paragraph",
        data: {
          text: "2. Engage in research &amp; development in cutting edge and sustainable technologies.",
        },
      },
      {
        id: "tmzTi7gOjw",
        type: "paragraph",
        data: {
          text: "3.&nbsp; Nurture scientific temperament, professional ethics and industrial collaboration.",
        },
      },
      {
        id: "lOuAVbqWLS",
        type: "header",
        data: { text: "Achievements", level: 3 },
      },
      {
        id: "PEVB8xyQlg",
        type: "paragraph",
        data: {
          text: "&nbsp; The success and the achievement of any academic institution and its departments can be gauged by the success and reputation enjoyed by its alumni. In this context, NIT Warangal in general and the department of electrical engineering in particular can boast of the achievements of several of its alumni.<br>A few glimpses&nbsp;&nbsp;",
        },
      },
      {
        id: "WRYkyKd-LY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Dr V Anjaneya Sastry, batch of 1964 was one of the co- founders of Infosys-presently heads software companies based in Bangalore-donated rupees 1 Crore to the Alma-mater for the establishment of the innovation and incubation centre.",
            "Sri P Sudhakar – batch of 1977 CMD, ECIL – Awarded the Electronics Man of the Year 2014",
            "Sri S M Shetty, batch of 1965 heads the SM group of companies –engaged in development of import substitution of chemicals used for dyes and paints industry-engaged in packaged snacks-heads a thousand crore enterprise.",
            "Sri Rao Remella, batch of 1971-the 1st Indian employee to have joined Microsoft corporation-involved in the development of the windows OS.",
            "Prof. Mohan Tanniru, batch of 1969-Dean, School of Business Administration , Auckland University Rochester- USA",
            "Sri Satya Bolli, batch of 1978 is a successful entrepreneur, winner of the best software exporter award from AP- CEO of SEMANTICSPACE technologies, a hundred million dollar company.",
            "Sri L Venkateshvarulu IAS, topper of the batch presently working in the UP Administrative cadre",
            "Sri R Mutyala Raju -All India IAS topper-2007.",
            "Sri K Linga Reddy, 1967 batch, chairman PETE winner of the MSME award for the year 2009.",
            "Sri R Sesha Talpa Sai, batch of 1977- CMD TEHRI hydro development corporation",
            "Sri R P Singh, batch of 1979- CMD of Sutlej Jamuna Vidyut Nigam ltd (SJVNL)",
          ],
        },
      },
      {
        id: "hxaTQtIoec",
        type: "header",
        data: { text: "History And Milestone", level: 3 },
      },
      {
        id: "cwMD4Ba-mz",
        type: "paragraph",
        data: {
          text: "The&nbsp;Department of Electrical Engineering was&nbsp;established in the year 1959.&nbsp;Since the inception of the Institute, the department developed state of the art Infrastructure, thanks to the liberal assistance received in the formative years under UNESCO aid through UNDP and later from MHRD, GOI through several schemes like institutional network scheme( INS) , MODROBS, R&amp; D, thrust area programme and TEQIP phase-I, II&nbsp;and III.",
        },
      },
      {
        id: "FyVODYTp4g",
        type: "paragraph",
        data: {
          text: "The&nbsp;Department had the fortune of well trained and dedicated faculty due to whose sustained efforts the Department enjoys a position of eminence in the field of technical education.",
        },
      },
      {
        id: "bJ46r8K1-P",
        type: "paragraph",
        data: {
          text: "The post graduate programmes of the Department were started in the year 1964 coinciding with the 1st outgoing batch of the under graduate course.",
        },
      },
      {
        id: "F_B5oI7s_d",
        type: "paragraph",
        data: {
          text: "The establishment of the PG courses has contributed significantly to the research and consultancy activities of the dept. Going by the mission and vision of the institute, the dept. always endeavoured to contribute significantly to the growth of technical education in this area in tune with this objective the dept. organised a number of faculty development programmes, conferences, fresher courses tailor made for industries and utilities like BHEL, APSEB, TATA power companies, VSP etc.",
        },
      },
      {
        id: "whGJ73ZH1A",
        type: "paragraph",
        data: {
          text: "The&nbsp;Department also organised several continuing education programmes in emerging areas to faculty of educational institutions in and around the region as well as other states. The&nbsp;Department is recognised as a QIP centre for M. Tech programmes in Electrical Engineering.",
        },
      },
      {
        id: "9rKR20u0Mk",
        type: "paragraph",
        data: {
          text: "The&nbsp;Department was active in research and the faculty completed several research projects sponsored by MHRD CSIR, DST, DRDO and other private and govt. organizations. With the provision of institute fellowships for Ph.D. scholars. The research activity in the&nbsp;Department received a boost and currently there are about 55&nbsp;Ph.D. scholars working for their doctorate degrees in various areas.",
        },
      },
      {
        id: "IB7qd1qj8Q",
        type: "paragraph",
        data: {
          text: "In its <b>60 odd years</b> of existence the department had quite a few academic accomplishments. To site a few of them are:",
        },
      },
      {
        id: "CNPLIRBPzj",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Establishment of power system study cell by the&nbsp;erst while APSEB to train their engineers&nbsp;in the power system packages.",
            "Completion of a sponsored research project to study the impact of the electrical load of&nbsp;VSP on APSEB grid which was awarded to the&nbsp;Department in the face of competition from several&nbsp;educational institutes including IITs.",
            "Being awarded for establishment of a centre of excellence in power and energy systems.",
            "Accreditation by NBA for full 5 years for all the programmes offered by the Department in 2008 and 2013",
            "Completion of 26 consultancy projects for design wetting of electro- mechanical designs for&nbsp;lift irrigation projects by&nbsp;Government of AP.",
          ],
        },
      },
      { id: "3ctM1tP3nw", type: "header", data: { text: "News", level: 3 } },
      {
        id: "oJ3qemOAw1",
        type: "paragraph",
        data: { text: "<b>Electronics Man of the Year 2014:</b>" },
      },
      {
        id: "Ah6D4OYZRl",
        type: "paragraph",
        data: {
          text: "Sri P Sudhakar (1977 Batch) Chairman and Managing Director, Electronics and Corporation of India Limited, A Government of India Enterprise under Department of Atomic Energy, is selected as ‘Electronics Man of the Year 2014′ by Electronics Industries Association (ELCINA) the oldest and largest association representing the Electronics Manufacturing Industry in India. ELCINA’s Electronics Man of the Year award was to recognize the extraordinary contributions by individuals towards growth and strengthening of Electronics Industry in India. The selection committee for this award comprise of senior industry captians from electronics Industry.",
        },
      },
      {
        id: "Se31KUQrt9",
        type: "paragraph",
        data: {
          text: "At a glittering Annual Awards presentation ceremony held on September 26, 2014 in New Delhi, the award is presented to Sri P Sudhakar by Sri Anil Swarup, Additional Secretary, Cabinet Secretariat, Government of India. The honour of Electronics Man of the Year has been bestowed to Sri P Sudhakar for his unparalled contributions to the growth of manufacturing and expansion of applications of electronics and information technology as well as research and development and assimilation of new technologies. The eminent jury members recognized Sri P Sudhakar’s outstanding achievements in leading ECIL to great heights, achieving the highest ever turnover, ramping up production to support the Indian Electronics machinery as well as several other initiatives in expanding the activities of ECIL in new arena.",
        },
      },
      {
        id: "bTOJ5xopqc",
        type: "paragraph",
        data: {
          text: "Some of the previous receipients of this award include exceptional achievers such as Sri Ratan Tata, Prof UR Rao, Sri Mukesh Ambani, Sri NR Narayana Murthy, Sri Azim Premzi, Sunil Bharti Mittal and a number of other luminaries.",
        },
      },
      {
        id: "a_dboKXeXE",
        type: "paragraph",
        data: {
          text: "Sri P Sudhakar has been serving ECIL since 1979 and has been steadfast in his committment to Electronics and development of systems for Nuclear Aerospace, Defence, Security as well as Information Technology and e-governance programs. Sri P Sudhakar graduated in Electrical Engineering from the Department of Electrical Engineering&nbsp;at&nbsp;the erstwhile Regional Engineering College, Warangal in 1977.",
        },
      },
    ],
    language: "En",
    _id: "6168ff7a87384c0023594173",
    time: 1634271096549,
    version: "2.22.2",
    title: "Home",
    url: "/eee/home",
    path: "/eee",
    createdAt: "2021-10-15T04:11:38.915Z",
    updatedAt: "2021-10-15T04:11:38.915Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "aRi8lp5G82",
        type: "header",
        data: { text: "Courses Offered", level: 3 },
      },
      {
        id: "UvZEHZ0RRV",
        type: "paragraph",
        data: {
          text: "The department offers B.Tech in Electrical and Electronics Engineering with an intake of 175 students and M.Tech programme in three streams namely Power Systems Engineering, Power Electronics &amp; Drives and Smart Electric Grid with an annual intake of 32 Students, 33 Students and 30 Students respectively",
        },
      },
      {
        id: "gqu_TYju5w",
        type: "paragraph",
        data: {
          text: "In addition to these programmes the department also offers Ph.D programme in all the areas of Electrical Engineering with 50 scholars pursuing their doctoral studies currently and 68 Doctorate degrees are awarded as on June 2020.",
        },
      },
      {
        id: "sn2Ia581BS",
        type: "paragraph",
        data: {
          text: "The department has also introduced courses on Artificial Intelligence Techniques in Power System and Power System Deregulation at M.Tech level for the first time in the state of Andhra Pradesh/Telangana.",
        },
      },
      { id: "0r9vTqjSnA", type: "header", data: { text: "B. Tech", level: 3 } },
      {
        id: "U_bb8WJkjx",
        type: "paragraph",
        data: { text: "&lt;PDF to be attached&gt;" },
      },
      { id: "UxDsKo_QnS", type: "header", data: { text: "M. Tech", level: 3 } },
      {
        id: "j7UI_uHInB",
        type: "header",
        data: { text: "P. Ed. Syllabus", level: 5 },
      },
      { id: "KHcYHRef1A", type: "paragraph", data: { text: "&lt;pdf&gt;" } },
      {
        id: "QKc6Y54uN9",
        type: "header",
        data: { text: "PSE Syllabus", level: 5 },
      },
      { id: "AdE3dVWd2K", type: "paragraph", data: { text: "&lt;pdf&gt;" } },
      {
        id: "3lWhVFEju8",
        type: "header",
        data: { text: "Smart Electric Grid", level: 5 },
      },
      { id: "IwYDRFcxDV", type: "paragraph", data: { text: "&lt;pdf&gt;" } },
      { id: "yB04rT09Al", type: "header", data: { text: "Ph.D.", level: 3 } },
      {
        id: "be_oC1tDsK",
        type: "paragraph",
        data: { text: "Total number&nbsp; of Ph.D. degrees awarded : 74" },
      },
      {
        id: "byb4kHfkCW",
        type: "paragraph",
        data: { text: "Total number of Ph.D. scholars ongoing&nbsp; : 61" },
      },
    ],
    language: "En",
    _id: "616900c187384c0023594175",
    time: 1634271423357,
    version: "2.22.2",
    title: "Academics",
    url: "/eee/academics",
    path: "/eee",
    createdAt: "2021-10-15T04:17:05.722Z",
    updatedAt: "2021-10-15T04:17:05.722Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "List of Ph.D. Degree Awarded", level: 3 },
      },
      {
        id: "_O6cd05M6n",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "<b>S. No</b>",
              "<b>Name of the Scholar</b>",
              "<b>Title of The Thesis</b>",
              "<b>Year</b>",
            ],
            ["1", "B Subramanyam", "", "1976"],
            ["2", "B Ram Mohan Rao", "", "1978"],
            ["3", "P.V. Ramana Rao", "", "1982"],
            ["4", "G Murali Krishna", "", "1982"],
            ["5", "Anand Ramachandra Kelkar", "", "1984"],
            ["6", "K Madhava Rao", "", "1985"],
            ["7", "M Sydulu", "", "1992"],
            ["8", "P.E.C. Vidyasagar", "", "1992"],
            ["9", "Mz. Jawed Khan", "", "1995"],
            ["10", "N. Subrahmanyam", "", "1998"],
            ["11", "T. Sairam", "", "2001"],
            ["12", "M.K.S. Shastry", "", "2002"],
            [
              "13",
              "S Srinivasa Rao",
              "A new scalar control strategy for extracting maximum power from a wind or wave energy turbine using a squirrel cage induction generator",
              "2007",
            ],
            [
              "14",
              "N V Srikanth",
              "Design and Analysis of Fuzzy-FACTS based Power System Stabilizers for Enhancement of Small Signal Stability",
              "2007",
            ],
            [
              "15",
              "B. Basvaraja",
              "Studies on Transient Effects of PWM inverters on Rotating Machines",
              "2007",
            ],
            [
              "16",
              "V. Vijaya Kumar Reddy",
              "New Algorithms for Distribution System Studies",
              "2008",
            ],
            [
              "17",
              "M Sailaja Kumari",
              "Multi-Objective Optimal Power Flow Solution using Evolutionary Computation Techniques",
              "2008",
            ],
            [
              "18",
              "S Srinivas",
              "Investigations on Space Vector Modulation Schemes and Placement of Zero-Vector in a Three-level Dual-inverter fed Open-end Winding Induction Motor Drive",
              "2008",
            ],
            ["19", "Shaik Abdul Gafoor", "", "2008"],
            [
              "20",
              "K. Chandram",
              "New Approaches for Economic Dispatch and Unit Commitment Problems in Power Systems",
              "2009",
            ],
            [
              "21",
              "C Venkatesh",
              "Analysis, Classification and Mitigation Techniques of Voltage Sag/Swell and Harmonics",
              "2012",
            ],
            [
              "22",
              "T. Adhinarayanan",
              "New Techniques for Economic Operation of Thermal Stations in Vertically Integrated and Deregulated Environment",
              "2012",
            ],
            [
              "23",
              "K Prakash",
              "&nbsp;Novel Algorithms and Meta-heuristic Techniques for Distribution System Studies",
              "2012",
            ],
            [
              "24",
              "E. Muneeder",
              "Optimal Static and Dynamic Congestion Management in a Power Market using Mutation based Hybrid Fuzzy Adaptive Swarm Intelligence Techniques",
              "2012",
            ],
            [
              "25",
              "D. Harikrishna",
              "Enhancement of Dynamic and Transient Stability of Power systems using Fuzzy and ANN controlled FACTS",
              "2012",
            ],
            [
              "26",
              "B. Venu Gopal Reddy",
              "Investigations on Circuit Topologies and PWM Schemes for a Four-Level Dual Inverter Fed Open-End Winding Induction Motor Drives",
              "2012",
            ],
            [
              "27",
              "M. Mithun Bhaskar",
              "New Nature and Bio-Inspired Computational (NaBIC) Techniques for Power System Optimization Problems",
              "2013",
            ],
            [
              "28",
              "R.S. Dhekekar",
              "Reactive Power control of Transmission Lines with Harmonic minimization using novel controls and topologies of shunt FACTS Devices",
              "2013",
            ],
            [
              "29",
              "J. Vijaya Kumar",
              "Optimal Bidding Strategies in a Double Auction Electricity Market using Hybrid and varying Population based Meta-heurisitic Techniques",
              "2013",
            ],
            [
              "30",
              "S.V.N.L. Lalitha",
              "Effective transmission pricing schemes in deregulated environment using direct and bio-inspired techniques",
              "2014",
            ],
            [
              "31",
              "Ch. Venkaiah",
              "Real-Time Available Transfer Capability Computation and Congestion Management using Intelligent Techniques in Deregulated Power Markets",
              "2014",
            ],
            [
              "32",
              "S. Porpandi Selvi",
              "Development of Dual Frequency Inverter Configurations for Induction Hardening Application",
              "2014",
            ],
            [
              "33",
              "M. Murali",
              "&nbsp;Transmission Pricing in pool and transaction-based power markets using Game theory and Heuristic techniques",
              "2015",
            ],
            [
              "34",
              "T. Vinay Kumar",
              "Reduction of Torque and Flux Ripple in Direct Torque Controlled Three Phase Induction Motor Drive",
              "2015",
            ],
            [
              "35",
              "Y Chandrasekhar",
              "Multi-Objective Optimal Placement and Sizing of Distributed Generators using Meta-Heuristic Techniques",
              "2015",
            ],
            [
              "36",
              "P Sharath Kumar",
              "High-Frequency Resonant Inverters for Multiple-Load Induction Cooking Application",
              "2015",
            ],
            [
              "37",
              "M. Ajay Kumar",
              "Intelligent Controlled HVDC Light and MTDC Transmission Systems for Offshore Wind Farm to the Onshore AC Grid Integration .",
              "2015",
            ],
            [
              "38",
              "B. Vijay Kumar",
              "Enhancement of power system stability with optimal   locations and sizing of   FACTS in AC-DC Systems.",
              "2015",
            ],
            [
              "39",
              "K. Ravi Kumar",
              "PSO and Multi Agent Based Algorithms for Optimal Power Flow Studies With FACTS Devices&nbsp;",
              "2016",
            ],
            [
              "40",
              "D. SudhaRani",
              "Multi-Objective Approaches for Optimal Distribution Network Reconfiguration, Capacitor and DG Placement",
              "2016",
            ],
            [
              "41",
              "B. Nagu",
              "Enhancement of AC-DC System Stability Using Artificial Intelligence Based HVDC Controllers&nbsp;",
              "2016",
            ],
            [
              "42",
              "D. Rakesh Chandra",
              "Wind Integration Studies in Power Systems: Forecasting, Stability and Load Management Issues",
              "2016",
            ],
            ["43", "P Srinivasan", "", "2016"],
            [
              "44",
              "B. Durga Harikiran",
              "Operating Reserve and Frequency Regulation Ancillary Services Management in Restructured Electricity Markets",
              "2017",
            ],
            [
              "45",
              "M Vishnu Prasad",
              "Simplified Weighting Factor Selection for Predictive Torque Control of Induction Motor Drives",
              "2017",
            ],
            [
              "46",
              "U Ramanjaneya Reddy",
              "Improved Driver Circuits For LED Lighting Applications&nbsp;",
              "2017",
            ],
            [
              "47",
              "K. Vijaya Babu",
              "Performance Investigation on Switched Reluctance Machine Control in off-grid Applications",
              "2017",
            ],
            [
              "48",
              "B. Ravi Kumar Verma",
              "Digital Protection Algorithms for EHV Teed and Multiterminal Transmission Circuits",
              "2017",
            ],
            [
              "49",
              "N Rama Devi",
              "Fault Diagnosis Schemes for three phase induction motor",
              "2017",
            ],
            [
              "50",
              "D Vijaya Bhaskar",
              " Inverter Configuarations for Induction Cooking Applications",
              "2017",
            ],
            ["51", "Ch. Ramulu", "", "2017"],
            [
              "52",
              "T. Kiran",
              "Static and dynamic Power system Security Analysis using Hybrid Evolutionary Algorithms",
              "2018",
            ],
            [
              "53",
              "S. Kayalvizhi",
              "Optimal Planning of Distributed Generations in Distribution Network and Load Frequency Control of Micro Grid using Meta-Heuristic Algorithms",
              "2018",
            ],
            [
              "54",
              "Sachidananda Prasad",
              "Optimal Planning of Distributed Generations in Distribution Network and Load Frequency Control of Micro Grid using Meta-Heuristic Algorithms",
              "2018",
            ],
            [
              "55",
              "V. Venkataramana",
              "Locational Marginal Price Computation for Optimal Operation of Active Distribution System using Game Theory and Meta Heuristic Techniques&nbsp;",
              "2018",
            ],
            ["56", "S. Venu", "", "2018"],
            [
              "57",
              "K Ramsha",
              "Investigations on the Applicability of Zero-Sequence Current Elimination PWM Schemes for PV Powered Multiphase OEWIM-Pump Drive",
              "2019",
            ],
            ["58", "Haripriya Vemuganti", "", "2019"],
            [
              "59",
              "Ch. Kasi Ramakrishna Reddy",
              "Development of Converter configurations for LED lighting application",
              "2019",
            ],
            ["60", "K.V. Praveen Kumar", "", "2019"],
            [
              "61",
              "N Venkata Phanindrababu",
              "Optimal Selection and placement of  PMUs and their application for Power System Protection",
              "2019",
            ],
            [
              "62",
              "Ratna Rahul T",
              "Investigation on Control Strategies for Grid and Off-Grid Connected Inverters in Distributed Generation System",
              "2019",
            ],
            [
              "63",
              "Jammy Ramesh Rahul",
              "Investigations on Impedance Source-Based Multilevel Inverters for Photovoltaic Applications",
              "2019",
            ],
            [
              "64",
              "Saptarshi Roy",
              "Novel Techniques for Identification and Locating Faults in Power System compensated with FACTS devices",
              "2019",
            ],
            [
              "65",
              "Suresh Lakhimsetty",
              "Improvised Space-Vector Pulse Width Modulation Schemes for Four-Level Open-End Winding Induction Motor Drives",
              "2019",
            ],
            [
              "66",
              "Hareesh Myneni",
              "Power Quality Enhancement by DSTATCOM with Improved Performance",
              "2019",
            ],
            [
              "67",
              "B Anil Kumar",
              "Multi-Objective Model Predictive Control of Grid-Tied Solar Photovoltaic System",
              "2019",
            ],
            ["68", "O.V.S.R. Vara Prasad", "", "2019"],
            [
              "69",
              "T. Abhilash",
              "Performance Evaluation of Hybrid Multilevel Inverter Topologies for Medium Voltage Applications",
              "2020",
            ],
            [
              "70",
              "M. Santosh",
              "Investigations on Wind Speed Forecasting using Artificial Intelligence Techniques",
              "2020",
            ],
            ["71", "B. Gurappa", "", "2020"],
            [
              "72",
              "A Pranay Kumar",
              "Investigations on Topologies and Control Algorithms of DSTATCOM to Compensate Current Related Power Quality Issues",
              "2020",
            ],
            [
              "73",
              "K.M. Ravi Eshwar",
              "Modified Direct Torque and Predictive Torque Control Strategies for Induction Motor Drive.",
              "2020",
            ],
            ["74", "B. Kiran Babu", "", "2020"],
            [
              "75",
              "A. Anil Kumar&nbsp;",
              "Investigation on load frequency control of microgrid and interconnected power systems using AI techniques",
              "2021",
            ],
            [
              "76",
              "Kuncham Sateesh Kumar",
              "Investigation on single-phase voltage source based inverter topologies for grid-connected photovoltaic applications",
              "2021",
            ],
            [
              "77",
              "Y Bhaskar S S Gupta",
              "Investigation on Fault-Tolerant Converter Topologies for Three Phase Induction Motor Drive System",
              "&nbsp; (Thesis Submitted)&nbsp;",
            ],
          ],
        },
      },
      {
        id: "XIV5SPkBcp",
        type: "header",
        data: { text: "List of Ph.D. Scholars:- Ongoing", level: 3 },
      },
      {
        id: "qri26BiHW6",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "<b>S. No.</b>",
              "<b>Roll Number</b>",
              "<b>Name of the Scholar</b>",
              "<b>Mode</b>",
              "<b>Supervisor(s)</b>",
              "<b>Research Area</b>",
            ],
            [
              "1",
              "701410",
              "M.Kalyan Chakravarthi",
              "FT",
              "Dr.A.V. Giridhar,Prof. DVSS Siva Sarma",
              "High Voltage Engineering&nbsp;",
            ],
            [
              "2",
              "716012",
              "D. Mounika",
              "PT",
              "N. Vishwanathan, S.&nbsp;Porpandiselvi",
              "",
            ],
            [
              "3",
              "716112",
              "Hemasundara Rao Kolla",
              "FT",
              "Bhagwan K Murthy",
              "",
            ],
            [
              "4",
              "716120",
              "Srikant Srivastava",
              "PT",
              "Prof DM Vinod Kumar",
              "",
            ],
            ["5", "716121", "Krishna Kumar Rai", "PT", "Dr A V Giridhar", ""],
            [
              "6",
              "701607",
              "Narender Reddy Kedika",
              "FT",
              "Dr. P. Srinivasan",
              "Investigation on Fault-Tolerant Multilevel Inverter Topologies for Open-End Winding Induction Motor Drives",
            ],
            [
              "7",
              "701608",
              "Niveditha Sivadanam",
              "FT",
              "Dr. B. Nagu,&nbsp;Prof. M. Sydulu",
              "Electric Vehicles, Microgrids&nbsp; &nbsp; &nbsp; &nbsp;",
            ],
            [
              "8",
              "701609",
              "Chejarla Madhu Kishore",
              "FT",
              "Prof. M. Sailaja Kumari",
              "PMU assisted power system state estimation",
            ],
            [
              "9",
              "701610",
              "Bhukya Laxman",
              "FT",
              "Dr. N.V. Srikanth",
              "Investigation and Design of Intelligent Controllers for Performance Improvement of Renewable Energy Sources in Microgrid&nbsp; &nbsp; &nbsp;&nbsp;",
            ],
            [
              "10",
              "701611",
              "Harmindar Singh Johar",
              "PT",
              "Dr. S. Srinivasa Rao",
              "",
            ],
            [
              "11",
              "717010",
              "Patil Mounica",
              "FT",
              "Dr. S. Srinivasa Rao",
              "Bipolar Bidirectional DC DC Converter",
            ],
            [
              "12",
              "717011",
              "M Rambabu",
              "FT",
              "Dr. Bhookya Nagu",
              "Enhancement of solar photovoltaic system performance under partial shaded conditions using evolutionary optimization techniques",
            ],
            [
              "13",
              "717012",
              "Sumon Dhara",
              "FT",
              "Dr. V. T. SOMASEKHAR",
              "Investigations on Voltage Boosting Schemes in Transformerless Multilevel Inverter Configuration for PV Systems",
            ],
            [
              "14",
              "717013",
              "Sambhani Madhu Babu",
              "FT",
              "Dr B L NARASIMHARAJU",
              "Multilevel Inverters",
            ],
            [
              "15",
              "717014",
              "Punna Srinivas",
              "FT",
              "Dr M Udaya Bhasker",
              "Hybrid Energy Storage System using Battery Supercapacitor",
            ],
            [
              "16",
              "717015",
              "K Srinivas",
              "FT",
              "Dr. S. Porpandiselvi",
              "Resonant converter for induction heating applications&nbsp;&nbsp;",
            ],
            [
              "17",
              "717016",
              "Dharmesh Yelamanchi",
              "PT",
              "Dr. A V Giridhar &amp; Dr. N Vasudev",
              "External Insulation of High Voltage Equipment",
            ],
            [
              "18",
              "717113",
              "Dileep Krishna  Mathi",
              "FT",
              "Dr. Ramulu Chinthamalla",
              "Investigations on Maximum Power Point Tracking Techniques for Solar Photovoltaic Systems under Partially Shaded Conditions&nbsp; &nbsp;&nbsp;",
            ],
            [
              "19",
              "717114",
              "Patnana Hema Kumar",
              "FT",
              "Dr.V.T.Somasekhar",
              "Fault-Tolerant Operation of BLDC Motor Drives for Low Power Electric Vehicles&nbsp;",
            ],
            [
              "20",
              "717115",
              "Bhanu Prasad Chintala",
              "FT",
              "Prof. D. M. Vinod Kumar",
              "Optimal Meter Placement in Distribution System State Estimation",
            ],
            [
              "21",
              "717116",
              "Chinna Karasala",
              "FT",
              "Dr. G. Siva Kumar",
              "Grid connected inverter control",
            ],
            [
              "22",
              "717117",
              "Vaka S Sandeep Kumar Reddy",
              "FT",
              "Prof.M.Sailaja Kumari",
              "Microgrid Storage Managment and Optimizations",
            ],
            [
              "23",
              "717118",
              "Kusuma Eshwar",
              "FT",
              "Dr. T. Vinay Kumar",
              "Electric Drives&nbsp;&nbsp;",
            ],
            [
              "24",
              "717119",
              "Srikanth Reddy Yanala",
              "PT",
              "Prof.M. Sydulu",
              "",
            ],
            [
              "25",
              "718014",
              "Ajit Kumar Mohanty",
              "FT",
              "Dr P Suresh Babu",
              "Optimal allocation of Electric Vehicles charging stations&nbsp;&nbsp;",
            ],
            ["26", "718016", "Vutla Vijay", "FT", "Dr Ch. Venkaiah", ""],
            [
              "27",
              "718018",
              "Arunkumar C R",
              "FT",
              "Dr. M Udaya Bhasker",
              "Energy storage system, Fast responsive devices, Pulsed load systems, DC microgrid systems",
            ],
            ["28", "718019", "P.Manoj", "FT", "Dr.V.T.Somasekhar", ""],
            [
              "29",
              "718020",
              "G.Ramesh",
              "FT",
              "Dr.Bhookya Nagu",
              "Solar PV&amp;FUEL CELL Energy Based nano grid system",
            ],
            [
              "30",
              "718117",
              "S Sreekantha Reddy",
              "FT",
              "Dr Chandrasekhar&nbsp;Yammani",
              "Partial shading of solar system",
            ],
            [
              "31",
              "718118",
              "C Venkata Subba Reddy",
              "FT",
              "Dr. Swati Devabhaktuni",
              "Power electronic Control of Five phase induction motor Drives",
            ],
            [
              "32",
              "718119",
              "Valluru Chandra Sekhar",
              "FT",
              "Prof.N.Vishwanathan",
              "",
            ],
            [
              "33",
              "718120",
              "Sagar Petkar",
              "FT",
              "Dr. T. Vinay Kumar",
              "Predictive control of permanenet magnet motors",
            ],
            [
              "34",
              "718122",
              "Avvari Ravi Kumar",
              "FT",
              "Prof.D.M.Vinod Kumar",
              "Multi-objective optimal power flow using hybrid evolutionary algorithms under normal and uncertainty conditions",
            ],
            [
              "35",
              "718123",
              "A Bhanuchandar",
              "FT",
              "Prof B K Murthy",
              "Reduced Switch Count MLI Topologies",
            ],
            [
              "36",
              "718124",
              "Gotte Vikram Raju",
              "FT",
              "Dr. N. V. SRIKANTH",
              "Protection of Power Transmission System",
            ],
            [
              "37",
              "718125",
              "Ramesh Babu Pallapati",
              "FT",
              "Dr. Ramulu Chinthamalla",
              "Electrolytic Capacitor-less LED Driver with Reduced Power Processing&nbsp; &nbsp;",
            ],
            ["38", "718126", "V. Lokesh", "FT", "Dr. Altaf Q. H. Badar", ""],
            [
              "39",
              "718127",
              "Thunuguntla Vinod Kumar",
              "FT",
              "Dr I SATISH KUMAR",
              "OPTIMAL PLACEMENT OF DGs DISTRIBITION SYSTEM&nbsp; &nbsp;",
            ],
            [
              "40",
              "719033",
              "Chinmay Kumar Das",
              "FT",
              "Dr. A. Kirubakaran",
              "quasi z source based Multilevel inverter&nbsp; &nbsp;",
            ],
            ["41", "719035", "Ramesh Daravath", "FT", "Dr.S.Srinivasa Rao", ""],
            [
              "42",
              "719036",
              "Dodda Satish Reddy",
              "FT",
              "Dr. S. Srinivasa Rao",
              "",
            ],
            [
              "43",
              "719037",
              "Sunil Ankeshwarapu",
              "FT",
              "Dr Ch Venkaiah",
              "Power Distribution Systems",
            ],
            ["44", "719038", "M F Baba", "FT", "Dr.A.V.Giridhar", ""],
            [
              "45",
              "719039",
              "Parvathy M L",
              "FT",
              "Dr. T VINAY KUMAR",
              "ELECTRIC DRIVES&nbsp;",
            ],
            [
              "46",
              "719040",
              "Kanithi Ashok Kumar",
              "FT",
              "Dr.B.L. Narasimharaju",
              "",
            ],
            ["47", "719041", "Devasish Dey", "FT", "Dr. P. Srinivasan", ""],
            [
              "48",
              "719124",
              "G Sunil Kumar",
              "PT",
              "Prof.DVSS SIVA SARMA",
              "Harmonic analysis and Modelling of  Domestic and Commercial Nonlinear loads with Experimental Verification&nbsp; &nbsp;&nbsp;",
            ],
            [
              "49",
              "719125",
              "T Praveen Kumar",
              "PT",
              "Prof. N. Subrahmanyam &amp; Prof. M. Sydulu",
              "Power Management Strategies of a Grid Connected Hybrid Distributed Generation Systems&nbsp; &nbsp; &nbsp;&nbsp;",
            ],
            [
              "50",
              "719126",
              "Negasa Muleta",
              "FT",
              "Dr. Altaf Q. H. Badar",
              "Hybrid renewable energy resource optimization",
            ],
            [
              "51",
              "719127",
              "Berhanu Deggefa Lemma",
              "FT",
              "Dr. P. Srinivasan",
              "INVESTIGATION ON CONTROL TECHNIQUES OF PMSM DRIVED&nbsp; &nbsp;",
            ],
            [
              "52",
              "701918",
              "Ambati Dinesh Kumar",
              "FT",
              "Dr. D. Sreenivasarao",
              "",
            ],
            [
              "53",
              "701920",
              "Bhavin Salvi",
              "FT",
              "Dr. S Porpandiselvi and Prof. N Vishwanathan",
              "",
            ],
            [
              "54",
              "720032",
              "GSR Sanjeevini",
              "FT",
              "Dr. Debasmita Panda",
              "Electricity markets",
            ],
            [
              "55",
              "720033",
              "Kalvapalli Srikanth Reddy",
              "FT",
              "Dr. Jeyasenthil R",
              "QFT based Robust PID Controller Design For Power electronic converters&nbsp; &nbsp; &nbsp;&nbsp;",
            ],
            [
              "56",
              "720034",
              "Chadaram Chandra Sekhar",
              "FT",
              "Dr.P. Suresh Babu",
              "Optimal PMU Placement in power network for full system observability.",
            ],
            [
              "57",
              "720035",
              "T Phani Swecha",
              "FT",
              "Dr. Deepak Reddy Pullaguram",
              "Microgrids",
            ],
            [
              "58",
              "720036",
              "Gajangi Arun Kumar",
              "PT",
              "Dr.G.Siva Kumar",
              "Power Quality",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616904f187384c0023594177",
    time: 1634274546946,
    version: "2.22.2",
    title: "Ph. D. Awardees",
    url: "/eee/phd_awardees",
    path: "/eee",
    createdAt: "2021-10-15T04:34:57.414Z",
    updatedAt: "2021-10-15T05:09:09.967Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "No0FjIsZ9T",
        type: "header",
        data: { text: "Areas Of Interest", level: 3 },
      },
      {
        id: "qjR7eYp6Pa",
        type: "paragraph",
        data: {
          text: "The department has a very strong liaison with several manufacturing industries and utilities and hasexecuted several R &amp; D and consultancy projects. The department has signed an MOU with CPRI,Bangalore and RCI-Hyderabad for research in emerging areas. It has liaison with BHEL (R&amp;D), ABB, ERDA, CromptonGreaves, CEA, TCE, LANCO, LUCAS TVS, CMC, GE, PRDC, APGENCO, APTRANSCO etc. Thefaculty have research publications in International and National journals/conferences to their credit indiverse areas.<br>The major areas of research in the department are as follows:",
        },
      },
      {
        id: "z3wjO3cm1m",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Neural networks, fuzzy logic &amp; Evolutionary algorithm applications to power system",
            "Power system stability",
            "Power system Restructuring &amp; Deregulation",
            "Power system protection",
            "Distribution system automation",
            "Application of wavelet techniques",
            "Power system Transients",
            "DSP applications to drives",
            "Switch Mode Power Conversion",
            "Renewable energy",
            "Power quality",
            "Hybrid Energy Storage Systems",
            "Electric Vehicles",
            "Smart Grid",
          ],
        },
      },
      {
        id: "_JaE5Zdkgl",
        type: "header",
        data: { text: "Consultancy Works", level: 3 },
      },
      {
        id: "Tn_L1fDNX4",
        type: "paragraph",
        data: {
          text: "The Department is engaged in and has successfully completed consultancy",
        },
      },
      {
        id: "LDoJMmkX3y",
        type: "paragraph",
        data: {
          text: "projects of design vetting of electro-mechanical designs of Lift Irrigation",
        },
      },
      {
        id: "g7qByIFwBl",
        type: "paragraph",
        data: {
          text: "projects undertaken by I&amp;CAD, Govt. of AP to the tune of about INR 4 crore.",
        },
      },
      {
        id: "gsLA7QaR56",
        type: "header",
        data: { text: "R&amp;D Projects", level: 3 },
      },
      {
        id: "0it1zYxJyi",
        type: "header",
        data: { text: "COMPLETED:", level: 5 },
      },
      {
        id: "p13ISE7CmZ",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Reliability Enhancement of AC adjustable Speed Drives, MHRD, New Delhi.",
            "Integrated Operation of Modern Power Systems with Controllable Elements, MHRD, New-Delhi.",
            "Implementation of Efficient Switching Algorithms with Digital Signal Processor for Multi-Level Inverter",
            "Configuration with open end winding Induction Motor, MHRD, New Delhi.",
            "Development of Efficient models for frequency related Availability Tariffs in Deregulated environment, MHRD, New Delhi.",
            "Development of low Voltage High Current Power Supply ,MHRD, New Delhi",
          ],
        },
      },
      {
        id: "xP_GYqDQFH",
        type: "header",
        data: { text: "ONGOING :", level: 5 },
      },
      {
        id: "cLJG-cY4P7",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Intelligent Transmission Management in Power System Deregulated",
            "Environment Sponsored By Institution Of Engineers",
          ],
        },
      },
      {
        id: "v04RIM7wxy",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Design and Development of Cascaded Quasi-Z-Source Multilevel Inverter for Grid Connected Photovoltaic System, MHRD, India.",
            "Design and Development of PV and Fuel Cell Energy based Nano-Grid System, SERB-DST, India.",
            "Optimized and Efficient Stand Alone PV System for Rural Applications, SERB-DST, India.",
            "Investigations on Multi-level Converter Interfaced Bi-Polar DC Micro-grids with Adaptive Centralized Energy Management System, SERB-DST, India.",
            "Reduction of Torque Ripple in Direct Torque Controlled Three-Phase Permanent Magnet Synchronous Motor Drive for a Hybrid Electric Vehicle, MHRD, India.",
            "Reliability enhancement of AC adjustable speed drives, MHRD, India.",
            "Integrated operation of modern power system with controllable elements, MHRD, India.",
            "Implementation of efficient switching algorithm with digital signal processing for multi-level inverter Configuration with open end winding induction motor, MHRD, India.",
            "Reliability Enhancement in PV Rich Microgrids with Plug-in-Hybrid Electric Vehicles and Data Centres (REPRMs), under ERIGrid, European Union H2020 Programme.",
            "Development of efficient models for frequency related availability tariffs in deregulation environment, MHRD, India.",
            "Design and Development of High-Efficient PFC based AC-DC LED Driver for AC-Grid Fed LED Lighting System, MHRD, India.",
            "Design and Development of High Efficient Switched Reluctance Motor based Solar Photovoltaic (SPV) Water Pumping System (WPS), MHRD, India.",
            "Modeling and simulation of energy harvesting using piezo-materials and super capacitors, DRDO.",
            "Investignations on control flexibilities of grid integrated solar photovoltaic energy conversion systems.&nbsp;",
          ],
        },
      },
    ],
    language: "En",
    _id: "61690d6a87384c002359417d",
    time: 1634274663836,
    version: "2.22.2",
    title: "Research",
    url: "/eee/research",
    path: "/eee",
    createdAt: "2021-10-15T05:11:06.258Z",
    updatedAt: "2021-10-15T05:11:06.258Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "FxLCo8Qr9j",
        type: "header",
        data: { text: "Workshop Conferences", level: 3 },
      },
      {
        id: "7qQXlC2QZu",
        type: "paragraph",
        data: {
          text: '1. A One Week ONLINE Continuing Education Programme on "Applications of Power Electronics to Renewable Energy Sources and Power Quality Improvement Devices", (04th- 09th January 2021), Organized by Department of Electrical Engineering Organized by <b>Dr. G Siva kumar</b> and <b>Dr. D Sreenivasarao</b>',
        },
      },
      {
        id: "rpOUO3kTZP",
        type: "paragraph",
        data: {
          text: '2. A 10-day Faculty Development Programme on "Power Electronics"&nbsp; during <b>11.12.17 to 20.12.17</b> organized by <b>Dr. Udaya Bhasker Manthati.</b>',
        },
      },
      {
        id: "SeVFpz8i06",
        type: "paragraph",
        data: {
          text: "3. GIAN (Global Initiative for Academic Networking) - Distribution Generation and Renewable Integration: Forecasting tools and EMS towards smart grids/ smart cities, during <b>23.05.16 to 03.06.16</b> organized by <b>Prof. M. Sydulu and Dr. M. Sailaja Kumari</b>",
        },
      },
      {
        id: "irblqxoK-N",
        type: "paragraph",
        data: {
          text: "4. Workshop on PMLK &amp; C2000 during <b>22.03.17 to 24.03.17</b> organized by <b>Prof. V. T. Somasekhar.</b>",
        },
      },
      {
        id: "T0GoqsoORN",
        type: "paragraph",
        data: {
          text: "5. A 6-day Faculty Development Program on “Applications of Digital Signal Processing Techniques for Power Electronic Systems “during <b>13.05.19 to 18.05.19</b> organized by <b>Dr Udaya Bhasker Manthati and Dr Suresh Babu Perli.</b>",
        },
      },
      {
        id: "V3w8eKycAA",
        type: "paragraph",
        data: {
          text: "6. A 6-day Faculty Development Program on “Recent Trends in Power Electronics Applications in Smart Grid &nbsp;Electric vehicles and Renewable Energy&nbsp;“during <b>17.06.19 to 22.05.19</b> organized by<b> Dr G Siva Kumar.</b>",
        },
      },
    ],
    language: "En",
    _id: "61690de387384c0023594180",
    time: 1634274784928,
    version: "2.22.2",
    title: "Workshop and Conferences",
    url: "/eee/conferences",
    path: "/eee",
    createdAt: "2021-10-15T05:13:07.342Z",
    updatedAt: "2021-10-15T05:13:07.342Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: {
          text: "Department of Electronics and Communication Engineering",
          level: 3,
        },
      },
      {
        id: "YeYma3IKWj",
        type: "paragraph",
        data: {
          text: "National Institute of Technology Warangal was established in 1959, and the department of Electronics&nbsp;and Communication Engineering has been one of the key departments of the institute since its&nbsp;inception in the year 1971. The department has expanded steadily over the last few decades and is now&nbsp;recognized as one of the leading academic and research centres in India. The department has strong&nbsp;liaisons with academics and industry across the globe. The department also provides support to R&amp;D&nbsp;organizations. The faculty is actively engaged in providing solutions to industry problems as consultants.&nbsp;The academic programs of the department are rated among the top in the country. The graduating&nbsp;students are well placed in leading academic institutes, research organizations and industries.",
        },
      },
      {
        id: "mEZ6E3yTcP",
        type: "paragraph",
        data: {
          text: "The Department of Electronics and Communication Engineering is one of the country’s larger ECE departments among all NITs in India and one of the largest departments of the National Institute of Technology, Warangal (NITW). The ECE Department at NITW has been an international reputation of excellence in teaching, research and service. With excellent laboratory facilities and dedicated faculty, the department of ECE offers broad range of programs that include undergraduate(B.Tech) and post graduate (M.Tech) in Electronics Instrumentation, VLSI System design, Communication Systems and research (Ph.D) programs. Some of the recent sponsored project undertaken by the department includes <b>Radar Emitter Identification</b> using <b>Neural Networks </b>sponsored by <b>DLRL, Hyderabad</b> and <b>Special Manpower Development in VLSI</b> sponsored by<b> MIT-Govt. of India</b>.",
        },
      },
      {
        id: "RHANbl1MgI",
        type: "header",
        data: { text: "Academic Programs", level: 4 },
      },
      {
        id: "51EkJKZkrK",
        type: "paragraph",
        data: {
          text: "The department offers a comprehensive Bachelor in Technology program in Electronics and Communication Engineering and an intensive Master in Technology program in three specializations- Electronic Instrumentation ,VLSI and Advanced Communication.",
        },
      },
      {
        id: "RBSsqaicf3",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "<b>Name of the Programme</b>",
              "<b>Specialization</b>",
              "<b>Year of Starting</b>",
            ],
            ["B.Tech", "Electronics and Communication Engineering", "1971"],
            ["M.Tech", "Electronic Instrumentation&nbsp;&nbsp;", "1966"],
            ["M.Tech", "VLSI System Design", "2000"],
            ["M.Tech", "Advanced Communication Systems", "2006"],
          ],
        },
      },
    ],
    language: "En",
    _id: "6169100987384c0023594182",
    time: 1638255319528,
    version: "2.22.2",
    title: "Home",
    url: "/ece/home",
    path: "/ece",
    createdAt: "2021-10-15T05:22:17.641Z",
    updatedAt: "2021-11-30T06:55:19.996Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "B. Tech", level: 3 } },
      {
        id: "hVC5idEd1R",
        type: "header",
        data: { text: "Scheme and Syllabi :", level: 6 },
      },
      {
        id: "hNusaE2VhT",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "Electronics and Communication Engineering (Effective from 2021-22)",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/10/23/04_btech-ece.pdf">View/Download</a>',
            ],
            [
              "Electronics and Communication Engineering (Effective from 2017 - 18)",
              '<a href="https://www.nitw.ac.in/media/uploads/2018/09/25/btech_ece_syllabus_2017.pdf" target="_blank">View/Download</a>',
            ],
          ],
        },
      },
      { id: "hblMFLELeN", type: "header", data: { text: "M. Tech", level: 3 } },
      {
        id: "pd2ZvKyIHz",
        type: "header",
        data: { text: "Scheme and Syllabi :", level: 6 },
      },
      {
        id: "dwJQ33RUp5",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "Electronics &amp;  Communication Engineering (Effective from 2021-22)",
              "1. Electronic Instrumentation &amp; Embedded Systems.2. VLSI System Design3. Advanced Communication Systems",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_electronic-instrumentation-and-embedded-systems.pdf">View/Download(EI &amp; ES)</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_vlsi-system-design.pdf">View/Download(VLSI)</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/3_advanced-communication-systems.pdf">View/Download(ACS)</a>',
            ],
            [
              "Electronics &amp; Communication Engineering (Effective from 2019-20)&nbsp; &nbsp;",
              "1. Electronic Instrumentation &amp; Embedded Systems2. VLSI System Design3. Advanced Communication Systems",
              '<a href="https://www.nitw.ac.in/media/uploads/2019/10/16/mteceiembedded-systems_new_syll_senate-1.pdf">View/Download(EI &amp; ES)</a><a href="https://www.nitw.ac.in/media/uploads/2019/10/16/syllabus_mtech_vlsi_updated-1.pdf">View/Download(VLSI)</a><a href="https://www.nitw.ac.in/media/uploads/2019/10/16/m-tech-acs-syllabus-2019-2020.pdf">View/Download(ACS)</a>',
            ],
          ],
        },
      },
      { id: "iOs3yx_o-4", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "616910a187384c0023594185",
    time: 1643042843007,
    version: "2.22.2",
    title: "Academics",
    url: "/ece/academics",
    path: "/ece",
    createdAt: "2021-10-15T05:24:49.909Z",
    updatedAt: "2022-01-24T16:47:23.572Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "R&amp;D Projects", level: 3 },
      },
      {
        id: "s9z4NnOP-X",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "<b>S. No</b>",
              "<b>Title of the project</b>",
              "<b>Name and Designation of the PI &amp; Co – PI</b>",
              "<b>Funding Agency</b>",
              "<b>Duration of the Project</b>",
            ],
            [
              "1",
              "Design of Optimum phased array and Identification of Faulty Elements",
              "Prof. N.V.S.N. Sarma/ Dr.D.Vakula",
              "DRDO",
              "2 Years",
            ],
            [
              "2",
              "DSP and Multimedia Instructions for 32-bit MIPS Processor",
              "Dr. B.Lakshmi",
              "RCI, DRDO",
              "2 Years",
            ],
            [
              "3",
              "Study and development of real time adaptive filter algorithms for sensor measurements",
              "Dr.T.Kishore Kumar",
              "RCI, DRDO",
              "2 Years",
            ],
            [
              "4",
              "Intel Atom based Embedded Systems Laboraory Development",
              "Dr. B.Lakshmi",
              "ANURAG,DRDO",
              "2 Years",
            ],
            [
              "5",
              "O-QPSK Modulator &amp; Demodulator design and its performance analysis for IEEE 802.15.4 Personal Area Networks",
              "Dr.V.V.Mani",
              "DRDO ANURAG",
              "2 Years",
            ],
            [
              "6",
              "Design of 14 bit 4 channel ADC with high voltage analog input",
              "Dr.Sri Hari Rao",
              "DRDO",
              "1 Years",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616911b287384c0023594187",
    time: 1636994888358,
    version: "2.22.2",
    title: "Research",
    url: "/ece/research",
    path: "/ece",
    createdAt: "2021-10-15T05:29:22.532Z",
    updatedAt: "2021-11-15T16:48:10.053Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "UEzdjzt7pM",
        type: "header",
        data: { text: "ECE Labs", level: 3 },
      },
      {
        id: "E_jMTMiuRI",
        type: "header",
        data: { text: "ELECTRONIC INSTRUMENTATION LAB", level: 5 },
      },
      {
        id: "jfOqNlTQTJ",
        type: "paragraph",
        data: { text: "• Transducer and Signal Cond. Lab" },
      },
      {
        id: "lyMKq8zFQ8",
        type: "paragraph",
        data: { text: "• PC Based Instrumentation Lab" },
      },
      {
        id: "WUfzLyu3--",
        type: "paragraph",
        data: { text: "• Electronic Instrumentation Lab" },
      },
      {
        id: "EECcEOjyjS",
        type: "header",
        data: { text: "COMMUNICATION LABS", level: 5 },
      },
      {
        id: "B9anmoOK7j",
        type: "paragraph",
        data: { text: "• Communication System Lab" },
      },
      {
        id: "uZNnRJYsAa",
        type: "paragraph",
        data: { text: "• Microwave &amp; Optical Communication Lab" },
      },
      {
        id: "9wnEeIlTC9",
        type: "paragraph",
        data: { text: "• Vector Network Analyzer, Spectrum Analyzer, MIC kit" },
      },
      {
        id: "3u21WPOJwu",
        type: "paragraph",
        data: { text: "• Microwave Test benches and MIC Design Software" },
      },
      {
        id: "oewi8_p5yF",
        type: "header",
        data: { text: "ANALOG &amp; DIGITAL CIRCUIT LABS", level: 5 },
      },
      {
        id: "Dx8DkOtx0C",
        type: "paragraph",
        data: { text: "• Electronic Devices &amp; Circuits Lab" },
      },
      {
        id: "BXMI2BzH6U",
        type: "paragraph",
        data: { text: "• Integrated Circuits Lab" },
      },
      {
        id: "qiJZf0DrlU",
        type: "paragraph",
        data: { text: "• Microprocessor &amp; Microcontroller lab" },
      },
      {
        id: "HCm8VP2b1M",
        type: "paragraph",
        data: {
          text: "• Micro processing and Interfacing lab with 8086 &amp;8085,",
        },
      },
      {
        id: "Dyqcxnets3",
        type: "paragraph",
        data: {
          text: "• Microcontroller 8051 &amp;32 bit channel Logic Analyzer",
        },
      },
      {
        id: "MHb3GHeVsH",
        type: "header",
        data: { text: "VLSI DESIGN LABS", level: 5 },
      },
      {
        id: "hoWUbP4FP1",
        type: "paragraph",
        data: { text: "• Electronic Computer Design &amp; Automation Lab" },
      },
      {
        id: "sc2kD7Gt2W",
        type: "paragraph",
        data: { text: "• Analog IC Design lab" },
      },
      { id: "HYk-1gxYRN", type: "paragraph", data: { text: "• PDA Lab" } },
      {
        id: "NlBwoOYbuP",
        type: "paragraph",
        data: { text: "• ALTERA FOUNDATION Series" },
      },
      {
        id: "DLfH_TaINR",
        type: "paragraph",
        data: { text: "• Synopsis Leonardo Synthesizer" },
      },
      {
        id: "u_qQWh590A",
        type: "paragraph",
        data: { text: "• SPICE Variants" },
      },
      {
        id: "T-lOrnwMQ5",
        type: "paragraph",
        data: {
          text: "• CADENCE &amp; MENTOR graphics mixed signal kits Synopsis &amp; magma Digital Kits",
        },
      },
      {
        id: "AO2jnclgHh",
        type: "paragraph",
        data: { text: "• XILINX FPGAs &amp;CPLDs-DSP" },
      },
      {
        id: "VsxOBWF3o4",
        type: "header",
        data: { text: "Circuits Lab", level: 3 },
      },
      {
        id: "OWWUhAo2q6",
        type: "paragraph",
        data: { text: "<b>Electronic Devices and Circuits laboratory</b>" },
      },
      {
        id: "YENtdaKWUe",
        type: "paragraph",
        data: {
          text: "This is the heart of the analog electronics curriculum. This laboratory is equipped with different types of Diodes, Transistors, FETs and basic instruments like CRO, Power Supply and Coaxial Cables. It covers the following topics of the curriculum",
        },
      },
      {
        id: "4GMUXKyGRw",
        type: "paragraph",
        data: { text: "1. Study of Instruments" },
      },
      {
        id: "8YYV5oYjbI",
        type: "paragraph",
        data: { text: "2. Study of Electronic Components" },
      },
      {
        id: "t-k39Uam2Z",
        type: "paragraph",
        data: { text: "3. Soldering practice" },
      },
      {
        id: "6YdEZX39yQ",
        type: "paragraph",
        data: { text: "4. Diode characteristics" },
      },
      {
        id: "rKlo6JuJZw",
        type: "paragraph",
        data: { text: "5. Transistor input and output characteristics" },
      },
      {
        id: "0hUaiGAGe2",
        type: "paragraph",
        data: { text: "6. Transistor biasing circuits" },
      },
      {
        id: "MCV4cI32HQ",
        type: "paragraph",
        data: { text: "7. Characteristics of JFET" },
      },
      {
        id: "T92bfSo0j2",
        type: "paragraph",
        data: { text: "8. Biasing circuits for FETs" },
      },
      {
        id: "Ot0c0CcZOO",
        type: "paragraph",
        data: { text: "9. Half wave, Full wave and Bridge rectifiers" },
      },
      {
        id: "Q_3qZue8Yr",
        type: "paragraph",
        data: { text: "10. Rectifier filters" },
      },
      {
        id: "Utjzl1vT8n",
        type: "paragraph",
        data: { text: "11. Zener Diode regulator/ reference" },
      },
      {
        id: "rpCWmEov1v",
        type: "paragraph",
        data: { text: "12. Measurement of h-parameters" },
      },
      {
        id: "8qP6X10IBd",
        type: "paragraph",
        data: { text: "13. Single stage BJT amplifier" },
      },
      {
        id: "mrCDF2QFPw",
        type: "paragraph",
        data: { text: "14. Two stage BJT amplifier" },
      },
      {
        id: "IftYBHw_m2",
        type: "paragraph",
        data: { text: "15. FET amplifier" },
      },
      {
        id: "36baHN3fSd",
        type: "paragraph",
        data: { text: "16. Differential amplifier" },
      },
      {
        id: "cRWwTmoMAm",
        type: "paragraph",
        data: { text: "17. Voltage series feedback amplifier" },
      },
      {
        id: "HKxkOS0qaz",
        type: "paragraph",
        data: { text: "18. Voltage shunt feedback amplifier" },
      },
      {
        id: "pBa5l-wegA",
        type: "paragraph",
        data: { text: "19. Current series feedback amplifier" },
      },
      {
        id: "bOxJWDkk2A",
        type: "paragraph",
        data: { text: "20. Current shunt feedback amplifier" },
      },
      {
        id: "OUvvoFamRI",
        type: "paragraph",
        data: { text: "21. RC phase shift oscillator" },
      },
      {
        id: "PmPWoI8frY",
        type: "paragraph",
        data: { text: "22. Wein bridge oscillator" },
      },
      {
        id: "29rYJVfOLR",
        type: "paragraph",
        data: { text: "23. LC/ crystal oscillator" },
      },
      {
        id: "0ziOrdlnon",
        type: "paragraph",
        data: { text: "<b>Electronic Design Automation Laboratory</b>" },
      },
      {
        id: "Z1DTim3nd5",
        type: "paragraph",
        data: {
          text: "This is a fully computerized laboratory for the simulation of basic analog electronics circuits on PSPICEplatform. It covers the following topics of the curriculum",
        },
      },
      {
        id: "EV9Rimg4wR",
        type: "paragraph",
        data: { text: "1. Introduction to PSPICE" },
      },
      {
        id: "LzDHTmkcRG",
        type: "paragraph",
        data: { text: "2. MS Windows and Operating system acquaintance" },
      },
      {
        id: "XbUZRD3uD9",
        type: "paragraph",
        data: {
          text: "3. DC Circuit Analysis Thevenins theorem and applications Nortons theorem and applications Mesh analysis and DC sweep Nodal analysis",
        },
      },
      {
        id: "v2TZtb3UOz",
        type: "paragraph",
        data: {
          text: "4. AC Circuit Analysis Series AC Circuit with R and L Series AC Circuit with R and C RLC Series resonance circuit RLC Parallel resonance circuit Frequency Sweep for series and parallel circuits",
        },
      },
      {
        id: "mQBi5b7tBJ",
        type: "paragraph",
        data: {
          text: "5. Transistor Circuits Biasing of BJT Analysis of small signal h-parameter models CE, CC, CB configurations Miller’s theorem Frequency response of CE, CC, CB amplifiers FET biasing and frequency response High frequency analysis of CE, CC, CB amplifiers",
        },
      },
      {
        id: "eVD8vklH06",
        type: "paragraph",
        data: {
          text: "6. Multistage Amplifiers Analysis and frequency response of: – Two stage CE amplifier Two stage CE amplifier with voltage series feedback Two stage CE amplifier with current shunt feedback",
        },
      },
      {
        id: "KMfjanFb4J",
        type: "paragraph",
        data: {
          text: "Pulse Circuits Laboratory This laboratory is for the experimentation of various pulse shaping circuits. It covers the following topics of the curriculum",
        },
      },
      {
        id: "k6pn2OxEBu",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Characteristics of Semiconductor devices: Diode, BJT, FET.",
            "Transistor biasing",
            "Single Sage Amplifier",
            "Negative Feedback Amplifier",
            "R.C. Phase shift Oscillator",
            "Integrator and Differentiator",
            "Clippers and Clampers",
            "Rectifiers and Filters",
            "Power Amplifier",
            "Multivibrators",
            "Schmitt Trigger",
            "Sweep Circuits",
          ],
        },
      },
      { id: "IwGdL9WOy9", type: "header", data: { text: "EI Lab", level: 3 } },
      {
        id: "53wUU-6Akc",
        type: "header",
        data: { text: "ELECTRONIC INSTRUMENTATION LAB", level: 5 },
      },
      {
        id: "ZcLU54Tj3Y",
        type: "paragraph",
        data: { text: "• Transducer and Signal Cond. Lab" },
      },
      {
        id: "p90_RDjIUO",
        type: "paragraph",
        data: { text: "• PC Based Instrumentation Lab" },
      },
      {
        id: "zQjAWWsW3O",
        type: "paragraph",
        data: { text: "• Electronic Instrumentation Lab" },
      },
      {
        id: "qNi6tNAhhR",
        type: "header",
        data: { text: "Other Labs", level: 3 },
      },
      {
        id: "w3q9Q38vDl",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "BE Labs",
            "CS Labs",
            "EDA Labs",
            "IC Labs",
            "MP &amp; MC Labs",
            "MW &amp; OC Labs",
          ],
        },
      },
    ],
    language: "En",
    _id: "616912c787384c0023594189",
    time: 1634276037272,
    version: "2.22.2",
    title: "Labs",
    url: "/ece/labs",
    path: "/ece",
    createdAt: "2021-10-15T05:33:59.654Z",
    updatedAt: "2021-10-15T05:33:59.654Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "Home", level: 3 } },
      {
        id: "ICji0FyX9k",
        type: "header",
        data: { text: "Our Vision And Mission", level: 3 },
      },
      {
        id: "aYXIW8XDX4",
        type: "paragraph",
        data: {
          text: "<b>VISION&nbsp;-</b>&nbsp;To promote excellence in education by offering a variety of courses at the undergraduate and post graduate levels to equip the students of National Institute of Technology Warangal with the requisite linguistic and people skills required to excel in any field.&nbsp;",
        },
      },
      {
        id: "1JX8OBTwqc",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Humanities and Social Sciences Department offers courses across the board ranging from basic to advanced courses in English, catering for diverse needs of students.&nbsp;",
          ],
        },
      },
      {
        id: "vw--qX4RYm",
        type: "paragraph",
        data: {
          text: "<b>MISSION&nbsp;-</b>&nbsp;To impart quality education and life skills that enable students to become successful on the personal, academic, and professional front; to foster critical thinking through a diverse range of courses aimed at making students industry ready; to encourage and facilitate&nbsp; research in advanced areas of Humanities.",
        },
      },
    ],
    language: "En",
    _id: "6169137f87384c002359418b",
    time: 1643023982788,
    version: "2.22.2",
    title: "Home",
    url: "/hss/home",
    path: "/hss",
    createdAt: "2021-10-15T05:37:03.147Z",
    updatedAt: "2022-01-24T11:33:03.620Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "RESEARCH PROGRAMMES:", level: 3 },
      },
      {
        id: "HDN5BLThnE",
        type: "paragraph",
        data: {
          text: "The Department now offers Ph.D. programme in English . It has so far awarded twelve Ph.D degrees to candidates in the disciplines of English and Economics. The areas of specialization include English Language Teaching, English and Commonwealth literatures and English for Specific Purposes and Business English.",
        },
      },
      {
        id: "bnN9_uIite",
        type: "paragraph",
        data: { text: "<b>Ph.D’s produced by Faculty:</b>" },
      },
      {
        id: "4CcvnAj_uW",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "<b>S. No.</b>",
              "<b>Name</b>",
              "<b>Ph.D’s guiding</b>",
              "<b>Ph.D’s guided in last 5 years</b>",
              "<b>Ph.D’s guided in last 5 years</b>",
            ],
            ["1", "D.S.Kesava Rao", "01", "01", "03"],
            ["2", "S.Thiruvenkataswamy", "01", "0", "01"],
            ["3", "V.V..Reddy", "-", "-", "05"],
            ["4", "G.V.L.N.Sarma", "-", "-", "03"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616913f387384c002359418d",
    time: 1634276337048,
    version: "2.22.2",
    title: "Research",
    url: "/hss/research",
    path: "/hss",
    createdAt: "2021-10-15T05:38:59.516Z",
    updatedAt: "2021-10-15T05:38:59.516Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "HqqphCBUHv",
        type: "header",
        data: { text: "LANGUAGE LABORATORY:", level: 3 },
      },
      {
        id: "ACZFmC78M-",
        type: "paragraph",
        data: {
          text: "The Department runs a language laboratory located in the Computer Centre. The main objective is to strengthen the language and communicative skills of students. It now has a LAN of 40 computers with Ideal Systems platform and several softwares to teach proper English pronunciation and stress and intonation. Intensive training is offered in short oral presentations and Group Discussions.",
        },
      },
    ],
    language: "En",
    _id: "6169146187384c002359418f",
    time: 1634276447075,
    version: "2.22.2",
    title: "Lab",
    url: "/hss/labs",
    path: "/hss",
    createdAt: "2021-10-15T05:40:49.426Z",
    updatedAt: "2021-10-15T05:40:49.426Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "IDQ9b2Q4PH", type: "header", data: { text: "Events", level: 3 } },
      {
        id: "1hwtkh_Gpm",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "S. No",
              "Name of the conference/workshop",
              "Dates",
              "Coordinators",
            ],
            [
              "1.",
              "International Conference on Advances in Minerals, Metals, Materials, Manufacturing and Modelling ICAM5,",
              "25th - 27th September, 2019",
              "G. V. S. Nageswara Rao, C. Vanitha, N. Kishore Babu, Brahma Raju Golla, Arockia Kumar R, Sreedevi V",
            ],
            [
              "2.",
              "Two day National Conference on Frontiers in Corrosion Control of Materials (FCCM -2018)",
              "28th - 29th June, 2018",
              "G.V.S. Nageswara Rao",
            ],
            [
              "3.",
              "GIAN course on Synthesis, characterization, processing and applications of nanomaterials    (Guest Faculty: Prof. C. Suryanarayana, University of Central Florida, Florida)",
              "7th - 11th March, 2016",
              "G.V.S. Nageswara Rao",
            ],
            ["4.", "Alumni Meet", "12th-13th Jan. 2016", "Department"],
            [
              "5.",
              "National Conference on Advances in Materials Processing and Characterization (NCAMPC – 2016)",
              "4th-6th Jan. 2016",
              "&nbsp; Dr. M.K. Mohan , Dr. N. Narasaiah , Dr. B. Srinivasa Rao , Dr. G.B. Raju&nbsp;&nbsp;",
            ],
            [
              "6.",
              "Advances in Computer Simulation of Solidification Processes",
              "18th-20th Dec. 2014",
              "&nbsp; Dr. T. Srinivasa Rao , Dr. G. S. Reddy , Dr. R. Arockia Kumar&nbsp;&nbsp;",
            ],
            [
              "7.",
              "Navigating New Frontiers in Materials",
              "2nd to 6th July 2013",
              "&nbsp; Dr. T. Srinivasa Rao , Dr. G. S. Reddy , Mr.V. R. Chary&nbsp;&nbsp;",
            ],
            [
              "8.",
              "Innovations in Materials and processes and Transfer of technology to Industries",
              "17th to 19th Oct. 2013",
              "&nbsp; Dr. B. V. Appa Rao , Dr. M. K. Mohan&nbsp;&nbsp;",
            ],
            [
              "9.",
              "Advances in Electron Microscopy",
              "9th to 13th Dec. 2013",
              "&nbsp; Dr. C. Vanitha , Dr. N. Narasaih&nbsp;&nbsp;",
            ],
            [
              "10.",
              "Recent Advances in Development, Processing, Characterization and Applications o Steels and Non – Ferrous Metals",
              "27th Dec. 2013",
              "&nbsp; Dr. M. K .Mohan , B. Saha (IIM- Hyd) , Mr.V. R. Chary&nbsp;&nbsp;",
            ],
            [
              "11.",
              "Trends and Advances in Heat Treatment of steels",
              "30th July to 3rd Aug. 2013",
              "&nbsp; Dr. T Srinivas Rao , Dr. G V S NageswaraRao&nbsp;&nbsp;",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616a6953498ac7002338e3da",
    time: 1634364140546,
    version: "2.22.2",
    title: "Events",
    url: "/mme/events",
    path: "/mme",
    createdAt: "2021-10-16T05:55:31.452Z",
    updatedAt: "2021-10-16T06:02:23.558Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "UWdYcbaEbb",
        type: "header",
        data: { text: "Welcome to Department of Physical Education", level: 3 },
      },
      { id: "aXneJbyWaO", type: "header", data: { text: "Mission", level: 5 } },
      {
        id: "RZFXw1vIdE",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To provide ample opportunities in diversified areas such as physical activity, exercise,&nbsp; sports and competitions.",
            "Commitment to encourage, develop and enable opportunities for participation in Sports activities for the campus community.",
          ],
        },
      },
      { id: "J4VQZXS1TS", type: "header", data: { text: "Vision", level: 5 } },
      {
        id: "XbofC2UFQN",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To promote Fitness, health and wellness of the Campus Community.",
            "To improve the quality of life as a whole.",
            "To increase participation amongst young boys and girls.",
            "To develop Sportsmanship and fair competition.",
          ],
        },
      },
      { id: "x2MNaliR5O", type: "header", data: { text: "Goals", level: 5 } },
      {
        id: "vtAtlZ6guN",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Strengthening of Existing facilities",
            "Promoting FITNESS to students and staff",
            "Attracting mass participation",
            "Creating new facilities",
            "Proper maintenance of all play grounds",
            "Inculcating the spirit of sportsman ship",
          ],
        },
      },
      {
        id: "OOJ0RvE-s-",
        type: "header",
        data: { text: "Tangible Outcomes", level: 5 },
      },
      {
        id: "z0P3Vmsuum",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "100% participation of both UG &amp; PG Students",
            "State of the art facilities for all sports events",
            "Conduct of Coaching camps in various disciplines",
            "Inculcating the spirit of sportsman ship and discipline",
          ],
        },
      },
      {
        id: "Tuijc3C898",
        type: "header",
        data: { text: "Information about Physical Education", level: 3 },
      },
      {
        id: "uw7muAVSrO",
        type: "paragraph",
        data: {
          text: "The phy.edu. Sports dept. of NIT Warangal has a dedicated team of people working hard to increase the overall enthusiasm for sports in the institute and the smooth conduction of the tournaments that occur year round. This system covers every individual in the campus, and ensures that good talent is always spotted.",
        },
      },
      {
        id: "BsNchZ-u-5",
        type: "paragraph",
        data: {
          text: "The Physical Education department will organize sports activities in NITW in recognized events, to encourage sportsmanship generally, and to promote friendly relations among the students of the NITs and to foster a close association among them.",
        },
      },
      {
        id: "Km6eVvfVm1",
        type: "paragraph",
        data: {
          text: "It is hoped that these activities would result in greater harmony, health-consciousness, brotherhood, goodwill, work sincerity; sporting spirit, open mindedness, etc. among NIT students and staff.",
        },
      },
      {
        id: "JbU2zKIm33",
        type: "paragraph",
        data: {
          text: "The Sports activities will help the Institute to identify talented and dedicated sports persons from the students.",
        },
      },
      {
        id: "DJfKqwId4F",
        type: "paragraph",
        data: {
          text: "Apart from playing regularly at the courts, students at NITW are given opportunity to show their flair and class in various tournaments. Talented players are given opportunity to qualify for entering the NITW ´s Inter NITW teams.",
        },
      },
      {
        id: "UQTLm5jlwi",
        type: "paragraph",
        data: {
          text: "For the freshmen at NITW, a Freshmen Kabaddi, Ball Badminton, Tennis and Volleyball open is held to give them opportunity to show their worth, and year after year, fresh young talented players are identified who are groomed to eventually represent NITW at the Annual Inter NIT Sports Meet.",
        },
      },
      {
        id: "uHuSl68YdE",
        type: "paragraph",
        data: {
          text: "The Inter-NIT Sports Meet, the biggest stage for sportsmen from NIT, is held annually during first and second semesters. it is a three-day extravaganza where students get to keep alive the spirit of Citius, Altius, Fortius. The NIT performing best in the Sports Meet&nbsp; wins the General Championship. With the birth of several new NITs, the talent pool has increased dramatically, resulting in a very closely fought General Championship.",
        },
      },
      {
        id: "p4cRhomX63",
        type: "paragraph",
        data: {
          text: "We will strive to raise the standard of sports in the NITs and to work for the development of character and values through sports amongst the staff of the NITs.",
        },
      },
      {
        id: "fagjE5BkwF",
        type: "header",
        data: {
          text: "DEPARTMENT OF PHYSICAL EDUCATION AND SPORTS&nbsp;PURPOSE ARE:",
          level: 5,
        },
      },
      {
        id: "k1KVZic9W0",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Promoting interaction among the students of the Institute.",
            "Fostering fraternal friendship and mutual understanding devoid of any political character whatsoever, and promoting goodwill purely through healthy and friendly participation in sports and games &nbsp;and",
            "Projecting a laudable image of NIW and enhance its prestige by dedication and determination in pursuit of success and victory in competitive activities of sports and games",
          ],
        },
      },
      {
        id: "vKuhMXt2g7",
        type: "header",
        data: { text: "FACILITIES AND PROGRAMMES OF THE DEPARTMENT", level: 5 },
      },
      {
        id: "liZDU1-2-Q",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "The department has Indoor (fitness center, wooden flooring for shuttle badminton) and outdoor sports facilities in almost all disciplines, (400mtrs track, flood lit courts, stadium pavilion) except swimming pool.",
            "The department will organize intramural competitions branch wise in all the disciplines to give an opportunity to involve more number of student community.",
            "The department will provide an opportunity to talented sportsmen and women to participate in the extramural competitions like, Inter NIT sports meet and Inter University sports competitions regularly.",
            "The department will organize sports tournaments at our campus regularly.",
            "Fitness and Conditioning classes were conducted to all first year students regularly during&nbsp; academic year.",
          ],
        },
      },
      {
        id: "xw63Gh3i03",
        type: "header",
        data: {
          text: "The sports comprises of the following events:-",
          level: 3,
        },
      },
      {
        id: "b9A_iZ0VFT",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Athletics",
            "Cricket",
            "kabaddi",
            "Basketball",
            "Volleyball",
            "Badminton",
            "Weight Lifting",
            "Best Physique",
            "Tennis",
            "Football",
            "Ball badminton",
            "Chess",
            "Table - Tennis",
            "Kho-Kho",
            "Tenni – Koit",
            "Throwball",
          ],
        },
      },
      {
        id: "qNXYHv9btP",
        type: "paragraph",
        data: {
          text: "Timings: 5:00 am to 8.30 a.m. and &nbsp;400 p.m. to 9.00 p.m. open to the students apart from office time.",
        },
      },
    ],
    language: "En",
    _id: "616a6c66498ac7002338e3e0",
    time: 1640277966063,
    version: "2.22.2",
    title: "Home",
    url: "/pe/home",
    path: "/pe",
    createdAt: "2021-10-16T06:08:38.548Z",
    updatedAt: "2021-12-23T16:46:06.501Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "s6tmaz_rFA",
        type: "header",
        data: { text: "Existing Sports Facilities", level: 3 },
      },
      {
        id: "ritlJl47G-",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Fitness Hall&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; 2",
            "Flood lit Synthetic Tennis Court&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;2",
            "Indoor Badminton Courts&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; 3",
            "Flood lit Volleyball Courts&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; 2",
            "Flood lit Tennis Clay Courts&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2",
            "Flood lit Ball Badminton Courts&nbsp; &nbsp; &nbsp; &nbsp; 1",
            "Flood lit Basketball Court&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2",
            "Table Tennis Halls&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2",
            "Flood lit Multi Purpose Stadium&nbsp; &nbsp; &nbsp; &nbsp; 1&nbsp;",
            "Flood lit 400 meters. Track Gravel&nbsp; &nbsp; &nbsp;1",
          ],
        },
      },
      { id: "MDJ4oad7g_", type: "header", data: { text: "INDOOR", level: 3 } },
      {
        id: "7AcjOs6G0Y",
        type: "list",
        data: { style: "unordered", items: ["Carrom"] },
      },
      {
        id: "rNahL-7k1y",
        type: "list",
        data: { style: "unordered", items: ["Chess"] },
      },
      {
        id: "zDDPsf0ZD_",
        type: "list",
        data: { style: "unordered", items: ["Badminton"] },
      },
      {
        id: "fa_Xyen60O",
        type: "list",
        data: { style: "unordered", items: ["Table Tennis"] },
      },
      {
        id: "H0vNE-kOLf",
        type: "list",
        data: { style: "unordered", items: ["Weight Lifting"] },
      },
      {
        id: "n8OpqXFqhn",
        type: "list",
        data: { style: "unordered", items: ["Best Physique"] },
      },
      { id: "DYNTBexyJO", type: "header", data: { text: "OUTDOOR", level: 3 } },
      {
        id: "hJRIMcCZzp",
        type: "list",
        data: { style: "unordered", items: ["Athletics"] },
      },
      {
        id: "_SG0XJdVUa",
        type: "list",
        data: { style: "unordered", items: ["Ball Badminton&nbsp;"] },
      },
      {
        id: "nOrudSKBLE",
        type: "list",
        data: { style: "unordered", items: ["Cricket"] },
      },
      {
        id: "QzIE9kUSZB",
        type: "list",
        data: { style: "unordered", items: ["Football"] },
      },
      {
        id: "DLvOuycWng",
        type: "list",
        data: { style: "unordered", items: ["Basket Ball&nbsp;"] },
      },
      {
        id: "vOPa7AeMHI",
        type: "list",
        data: { style: "unordered", items: ["Tennikoit"] },
      },
      {
        id: "C3UNJxf5dj",
        type: "list",
        data: { style: "unordered", items: ["Tennis"] },
      },
      {
        id: "HyYTPtnYp_",
        type: "list",
        data: { style: "unordered", items: ["Volleyball &amp; Throwball"] },
      },
      {
        id: "uXv-hl3bQM",
        type: "list",
        data: { style: "unordered", items: ["Kabaddi &amp; Kho-Kho"] },
      },
    ],
    language: "En",
    _id: "616a71af498ac7002338e3e4",
    time: 1634365896484,
    version: "2.22.2",
    title: "Sports",
    url: "/pe/sports",
    path: "/pe",
    createdAt: "2021-10-16T06:31:11.842Z",
    updatedAt: "2021-10-16T06:31:37.603Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "iYQDJdK5O2",
        type: "header",
        data: { text: "Extramural Competitions", level: 3 },
      },
      {
        id: "lJ43Hx9C2I",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2018/12/26/18-report.pdf">Extramural Competitions 2018 report</a>',
            "All India Inter NIT Football and Chess Tournament held at Rourkela",
            "All India Inter NIT Table Tennis and Volleyball Tournament held at Kurkshetra",
            "All India Inter NIT Tennis Badminton and Basketball Tournament was organised at our campus Tennis Boys were Runner up",
            "All India Inter NIT Football and Handball Tournament&nbsp; Was held at Agartala.",
            "All India Inter NIT Swimming Kabaddi Hockey and YogaTournament held at Suratkal secured 3 medal in women section.",
            "All India Inter NIT Athletics and Weight Lifting and Best Physique was held at Jaipur - Weight Lifting and Best Physique - Champions.",
          ],
        },
      },
      {
        id: "zVVZfmsYOb",
        type: "header",
        data: { text: "Intramural Activities", level: 3 },
      },
      {
        id: "Xiheb7SxTK",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2018/12/26/intramural-18-19.pdf">Intramural 2018 - 2019</a>',
          ],
        },
      },
      {
        id: "hoWE0UvKXl",
        type: "header",
        data: { text: "Tournaments Organised every year", level: 3 },
      },
      {
        id: "LnlErYsA7i",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "A volleyball Tournament in Memory of Late Sri. Sri Hari, Hostel employee was organized to the campus community",
            "Late Pattabhi (ex student) Memorial Volleyball Tournament was organized to the campus community.",
            "Late Ch. Sriharirao (ex physical director) memorial tennis tournament was organized to the campus community.",
            "A Tennis Tournament was organized in memory of Late&nbsp;&nbsp;&nbsp;&nbsp; Sri. Palaih (ex Principal) for the Campus Community.",
            "Subbulu Memorial Ball Badminton Tournament was organized in memory of Late Sri. Subbulu&nbsp; Ex Asst.Physical Director for the Campus Community.",
            "IPL Cricket Tournament to the student community",
          ],
        },
      },
    ],
    language: "En",
    _id: "616a736d498ac7002338e3e8",
    time: 1634366341160,
    version: "2.22.2",
    title: "Tournaments",
    url: "/pe/tournaments",
    path: "/pe",
    createdAt: "2021-10-16T06:38:37.879Z",
    updatedAt: "2021-10-16T06:39:02.243Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "HkylsD7fLa",
        type: "header",
        data: { text: "Conferences And Other Events", level: 2 },
      },
      {
        id: "VJV47PSbko",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '25 Feb 2019&nbsp;:<a href="https://www.nitw.ac.in/main/Tournament/2018-19/" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All India Inter NIT Tournament 2019, Department of Physical Education</a>&nbsp;&nbsp;&nbsp;',
          ],
        },
      },
      {
        id: "JOS4KobKS5",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2018/12/21/sep-19-brochure_updated_3.pdf">National Conference of Sport and Exercise Psychology:Practices, Pursuits and Promises (March 29 - 31, 2019), organised by Department of Physical Education.</a><a href="https://www.nitw.ac.in/media/uploads/sep_19_brochure_FyWBL0q.pdf">&nbsp;&nbsp;&nbsp;</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "616a76d9498ac7002338e3ec",
    time: 1643004796346,
    version: "2.22.2",
    title: "Conferences And Events",
    url: "/pe/conferences",
    path: "/pe",
    createdAt: "2021-10-16T06:53:13.097Z",
    updatedAt: "2022-01-24T06:13:16.836Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "2JyxsMyZMu",
        type: "header",
        data: { text: "Activities", level: 3 },
      },
      {
        id: "092Kv2qakj",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2018/12/26/dpe-18-19-activities.pdf">Department Activities 2018 - 2019&nbsp;</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "616a777f498ac7002338e3f0",
    time: 1634367358129,
    version: "2.22.2",
    title: "Activities",
    url: "/pe/activities",
    path: "/pe",
    createdAt: "2021-10-16T06:55:59.331Z",
    updatedAt: "2021-10-16T06:55:59.331Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "j2nwrnwqxU", type: "header", data: { text: "Gallery", level: 3 } },
      {
        id: "K6kDYNQjgY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/department/pe/InternationalDay/Yoga21-06-2019/">International Day of Yoga 21-06-2019</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "616a77f4498ac7002338e3f2",
    time: 1634367492659,
    version: "2.22.2",
    title: "Gallery",
    url: "/pe/gallery",
    path: "/pe",
    createdAt: "2021-10-16T06:57:56.015Z",
    updatedAt: "2021-10-16T06:58:13.706Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "VSZr-N63bK",
        type: "header",
        data: { text: "Welcome to Department of Physics&nbsp;", level: 3 },
      },
      {
        id: "_4aKPFVRb3",
        type: "paragraph",
        data: {
          text: "The Department offers courses including Engineering Physics for B.Tech I year students, a masters programme M.Sc.(Tech.) with Photonics, Electronics, and Instrumentation as specializations. Apart from these courses, the  Department also offers open electives for various B.Tech programmes. The department is well known for its research in Photonics, Electronic Instrumentation, Materials science and Thin film technology.",
        },
      },
      { id: "R2HYTQIHtw", type: "header", data: { text: "History", level: 3 } },
      {
        id: "uLIcZv8rzX",
        type: "paragraph",
        data: {
          text: "Department of Physics started along with the institute in the year 1959. The department has grown from strength to strength along with the institute.",
        },
      },
      {
        id: "f9Dx69nQjY",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The department started a&nbsp;three year&nbsp;M.Sc. (Tech) programme in 1970 in Engineering Physics with five specializations namely Applied and Modern optics, Transistor circuit analysis and Design, Microwaves, Material sciences and Ionosphere science, with a clause, that at a given time only two specializations to be offered.",
        },
      },
      {
        id: "r8f0nR-tKU",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Presently department offers a&nbsp;three year&nbsp;M.Sc. (Tech) programme in Engineering Physics with specializations Electronics, Photonics, and Instrumentation. It is proud to say that the M.Sc. (Tech) Engineering Physics-Industry Oriented Course is well received by the industry and R&amp;D establishments.",
        },
      },
      {
        id: "bhNzclU7l4",
        type: "paragraph",
        data: { text: "Notable Incidents in the Department:" },
      },
      {
        id: "4jq5gg-NIR",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Developed Optical fiber sensors for sensing temperature, displacements, and fiber optic lathe tool dynamometer, etc. under UG/PG project&nbsp;works.",
            "A pulsed Nitrogen laser pump source was designed and constructed to develop the dye laser systems for wavelength tuning and study the laser action in indigenously synthesized dyes.",
            "Developed a Holographic gun sight for military rifles and transferred the know-how to BEL, Machilipatnam for production.",
            "Developed various new labs like Laser Applications, Fiber Optic Communications and Sensing, Microprocessors, Microcontrollers, &nbsp;Optical Data Processing and Holography, Optical System Design, Thin Film Coating, Solar Cell Characterization, Semiconductor Characterization,&nbsp; Digital System Design and Electronic Instrumentation, Optical Metrology, etc.",
          ],
        },
      },
      { id: "Eir0xW2hbf", type: "header", data: { text: "Vision", level: 3 } },
      {
        id: "RDvTGuUJnB",
        type: "paragraph",
        data: {
          text: "Be on the forefront of applied research and dissemination of knowledge combining Science and Engineering perspectives.",
        },
      },
      { id: "0JYIkkOGJ4", type: "header", data: { text: "Mission", level: 3 } },
      {
        id: "oiMfDFYQAl",
        type: "paragraph",
        data: {
          text: "Develop the scientific insight and technical competence of the students for keeping abreast with the advancements in Science and Technology to meet the demands of Industry and Research.&nbsp;",
        },
      },
      {
        id: "4adjp49_8h",
        type: "header",
        data: { text: "Contact Us", level: 3 },
      },
      {
        id: "A5r4ZTeTEC",
        type: "paragraph",
        data: { text: "For further details, contact" },
      },
      { id: "-p_qbScmNT", type: "paragraph", data: { text: "Head,&nbsp;" } },
      {
        id: "_84p26-Dmz",
        type: "paragraph",
        data: { text: "Department of Physics,&nbsp;" },
      },
      {
        id: "PJJxEpHedH",
        type: "paragraph",
        data: { text: "National Institute of Technology,&nbsp;" },
      },
      {
        id: "tQ6f-S3Xce",
        type: "paragraph",
        data: { text: "Warangal - 506 004,&nbsp;Telangana. (INDIA)" },
      },
      {
        id: "T9ywbSb7ZM",
        type: "paragraph",
        data: { text: "Ph: 0870-2462550" },
      },
      {
        id: "o2Pj5NB1Qn",
        type: "paragraph",
        data: { text: "Mail: physics_hod@nitw.ac.in" },
      },
      { id: "KohEkzM5no", type: "header", data: { text: "Gallery", level: 3 } },
    ],
    language: "En",
    _id: "616a8122498ac7002338e3f8",
    time: 1634369845153,
    version: "2.22.2",
    title: "Home",
    url: "/physics/home",
    path: "/physics",
    createdAt: "2021-10-16T07:37:06.796Z",
    updatedAt: "2021-10-16T07:37:27.026Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "glGICVUbq2",
        type: "header",
        data: { text: "News &amp; Events", level: 3 },
      },
      {
        id: "LPgE46eOGS",
        type: "paragraph",
        data: { text: "News and Events&nbsp;" },
      },
      {
        id: "6xNnmVnMcK",
        type: "header",
        data: { text: "Workshops", level: 3 },
      },
      {
        id: "C49TdAqK7u",
        type: "list",
        data: {
          style: "unordered",
          items: [
            'National Conference on Luminescence and its Applications (NCLA-2020) (10th - 12th February, 2020) On the eve of Diamond Jubilee Celebrations of NIT (REC), Warangal&nbsp;(&nbsp;<a href="https://ncla2020nitw.wixsite.com/lmdgroup">https://ncla2020nitw.wixsite.com/lmdgroup</a>)',
            '<a href="https://www.nitw.ac.in/media/uploads/ARRIM-2018-_Brochure_Nov_2018___17918.pdf" target="_blank">A One Week National Workshop on "Applications of Radiation and Radioisotopes in Industry and Materials Science (ARRIM – 2018)" (26th- 30th November, 2018),Organized by Department of Physics Sponsored under TEQIP III.</a>',
            '<a href="https://www.nitw.ac.in/main/update/Workshops/Conferences/#">International Conference on "Advanced Functional Materials and Devices (ICAFMD – 2019) On the eve of Diamond Jubilee Celebrations of NIT (REC), Warangal" (26th- 28th February, 2019), Organized by Department of Physics Sponsored under TEQIP III.(</a><a href="https://goo.gl/FsTnhV">https://goo.gl/FsTnhV)</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "616a8268498ac7002338e400",
    time: 1634370151710,
    version: "2.22.2",
    title: "News and Events",
    url: "/physics/news-events",
    path: "/physics",
    createdAt: "2021-10-16T07:42:32.890Z",
    updatedAt: "2021-10-16T07:42:32.890Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "6_eC5OPBB0",
        type: "header",
        data: { text: "Courses Offered", level: 3 },
      },
      { id: "R60loIjk3d", type: "header", data: { text: "PhD", level: 5 } },
      {
        id: "-EYroJW_bo",
        type: "paragraph",
        data: {
          text: "The Department offers PhD program in the advanced fields of Physics such as Electronic Instruementation, Optics, Photonics, Thin film Technology, Materials Science, Optical and Bio glasses, Glass cermaics, Solar Cells, Sensors, Organic LEDs, Soft condensed matter (Liquid crystals).&nbsp;",
        },
      },
      {
        id: "xZeboSkqBQ",
        type: "paragraph",
        data: {
          text: "The candidates are admitted to the program through notification released by NIT Warangal.&nbsp;Eligibility criteria, mode of admissions and other detials are subjected to the notification and may change time to time.&nbsp;",
        },
      },
      {
        id: "oxoThkMWDh",
        type: "paragraph",
        data: {
          text: "For more information, visit the main website regularly.&nbsp;&nbsp;",
        },
      },
      {
        id: "v1j7LF5Ycf",
        type: "header",
        data: { text: "M.Sc.(Tech.)", level: 3 },
      },
      {
        id: "NhmyM-UGdd",
        type: "paragraph",
        data: {
          text: "Since its inception in 1959, the&nbsp;Department of Physics has kept pace with&nbsp;developments in advanced areas in&nbsp;the discipline,&nbsp;the introduction of&nbsp;a&nbsp;three year M.Sc.&nbsp;(Tech.)&nbsp; in&nbsp; Photonics&nbsp;and Electronics Technologies in Engineering Physics course with specializations reflecting that mindset. Such courses aim to develop professionals who are versatile and conversant with the needs of the industry.",
        },
      },
      {
        id: "IAmt2LoNE7",
        type: "paragraph",
        data: {
          text: "Admissions to the&nbsp;course are made through an All India Entrance Test - Joint Admission Test&nbsp;for&nbsp; M.Sc.(JAM).&nbsp;",
        },
      },
      {
        id: "AD8b8X6Jbz",
        type: "paragraph",
        data: {
          text: "Apart&nbsp;from&nbsp;its&nbsp;regular coursework,&nbsp; the course&nbsp;includes&nbsp; Industrial&nbsp; Training of&nbsp;eight&nbsp;weeks and six&nbsp;months of Project Work/Dissertation in various reputed&nbsp; R&amp;D Organizations and industries.",
        },
      },
      {
        id: "66sIH3MAM2",
        type: "paragraph",
        data: {
          text: "The Department strives to provide a balanced blend of both&nbsp;&nbsp; Science and Engineering curriculum and hands-on training for students. The course curriculum is upgraded regularly in keeping with the ever-changing needs and demands of the industry.",
        },
      },
      {
        id: "g2PKbUouJW",
        type: "paragraph",
        data: {
          text: 'For detailed program structure and courses offered,&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/03/16/new-msc_tech_3yrs_strcutre.pdf">click here&nbsp;</a>',
        },
      },
      {
        id: "V7POL9GIz9",
        type: "header",
        data: { text: "Rules And Regulations", level: 3 },
      },
      {
        id: "1Ce28GO-ZG",
        type: "paragraph",
        data: {
          text: "Current regulations are applicable only to the UG/PG/PhD&nbsp;admitted from 2021-22 year onwards whereas Old regulations are applicable to the previously joined&nbsp;.&nbsp;",
        },
      },
      { id: "BIUG10MHjV", type: "header", data: { text: "PhD", level: 5 } },
      {
        id: "jX-ufO92Dx",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/09/04/phd-rules-and-regulations-2021-22.pdf">Current PhD Regulations</a>',
        },
      },
      {
        id: "KR1H7N6QeI",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/09/04/phd-rules-2017-18modified-2019_hKdbeEf.pdf">Old PhD Regulations</a>',
        },
      },
      {
        id: "ElU8B1-Dwk",
        type: "header",
        data: { text: "M.Sc. (Tech.)", level: 5 },
      },
      {
        id: "B24TMFxnJ8",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/08/30/pg-msc-int-rules-and-regulations-2021-22_wxdtckd.pdf">Current&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/30/pg-msc-int-rules-and-regulations-2021-22_wxdtckd.pdf">M.Sc Tech</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/30/pg-msc-int-rules-and-regulations-2021-22_wxdtckd.pdf">&nbsp;Regulations&nbsp;</a>',
        },
      },
      { id: "aC0jgmPuS4", type: "header", data: { text: "Btech", level: 5 } },
      {
        id: "TuOPKK6HmH",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/08/30/btech_rules-and-regulations-2021-22.pdf">Current&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/30/btech_rules-and-regulations-2021-22.pdf">Btech</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/30/btech_rules-and-regulations-2021-22.pdf">&nbsp;Regulations&nbsp;</a>',
        },
      },
      {
        id: "F_M4mbf_Vj",
        type: "header",
        data: { text: "Schemes and Syllabus", level: 3 },
      },
      {
        id: "qHsxpGxh8Q",
        type: "header",
        data: { text: "M.Sc.(Tech.) Engineering Physics", level: 5 },
      },
      {
        id: "l-VqL4HMxW",
        type: "header",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/09/04/new-curriculum_msctech-engineering-physics.pdf">New Curriculum</a>',
          level: 5,
        },
      },
      {
        id: "my3FDSmLTH",
        type: "header",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/09/04/old-curriculum-msc-engineering-physics.pdf">Old Curriculum</a>',
          level: 5,
        },
      },
      {
        id: "kC1IUioKrl",
        type: "header",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/09/04/old-msc_tech_structure.pdf">Old M.Sc Tech Regulations&nbsp;</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "616a8374498ac7002338e402",
    time: 1634563835530,
    version: "2.22.2",
    title: "Academics",
    url: "/physics/academics",
    path: "/physics",
    createdAt: "2021-10-16T07:47:00.860Z",
    updatedAt: "2021-10-18T13:30:36.965Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9sVEXca2kp",
        type: "header",
        data: { text: "Department Library", level: 3 },
      },
      {
        id: "sj5dYFwqM6",
        type: "header",
        data: {
          text: "In the Department&nbsp;Library, the following are available for Students and Research Scholars",
          level: 5,
        },
      },
      {
        id: "5_Or4iPL0T",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Textbooks of various subjects",
            "Reference Books",
            "Physics Today",
            "Optics &amp; Photonics News",
            "Science Magazines",
            "Review Articles",
            "Student Dissertations and Seminar Papers",
            "Ph.D. Thesis of Research Students",
            "Online Journals are available through the “INDEST” Scheme of MHRD, Govt. of India",
          ],
        },
      },
      {
        id: "-UtDBcctMK",
        type: "header",
        data: { text: "Instruments", level: 3 },
      },
      {
        id: "qtsnp0J-1Q",
        type: "paragraph",
        data: {
          text: "&nbsp;Instruments available&nbsp;in the Department&nbsp;",
        },
      },
      {
        id: "Lz9fePUWGy",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Lasers (He-Ne &amp; Semi-Conductor)",
            "Optical Data Processing Unit",
            "OTDR",
            "Optical Spectrum Analyzer",
            "Fizeau interferometer",
            "Fiber Bragg Gratings",
            "SPLICING Unit for SM fibers",
            "Digital Storage Oscilloscope (400 MHz &amp; 100 MHz)",
            "Image Processing Workstation",
            "Holographic Equipment",
            "Fiber Optic Kits for student training",
            "High-Resolution Microscopes",
            "Interferometers&nbsp;",
            "VHF &amp; Microwave Field Strength Meters",
            "PC Based Instrumentation Trainer",
            "Optical Power Meters",
            "Multi-channel Analyser",
            "Microprocessor Kits (8086, Z80, 8085) &amp; VDUS",
            "Keithley Electrometer",
            "Microwave Equipment (X &amp; P Bands)",
            "Pulse Oximeter",
            "Communication Kits",
            "Neutron Source",
            "Microcontroller Kits",
            "Programmable&nbsp;High-Temperature Muffle Furnaces (Up to 16000&nbsp;C )",
            "Water Cooled Tubular Furnace&nbsp; with PID Controller (1200&nbsp;o&nbsp;C, with Gas Purging)",
            "Vacuum Ovens Up to 2500&nbsp;C",
            "Optical Sensors for pressure, displacement, refractive index, force, flow, rotation,&nbsp;",
            "Laser Modulation Kits",
            "Trinocular Microscope",
            "Micro Balance(CX-265N, Citizon)",
            "Simulation Software like CODE – V, Light Tools, ZEMAX, and GST",
            "Vibration Free Optical Table",
            "Thermal Evaporation Unit (For Metallic And Low Melting Oxide Thin Films)&nbsp;",
            "Spray Pyrolysis Unit (For Oxide Thin Films)",
            "Spin coater &nbsp;(SPIN NXG – P2 )",
            "Plasma Cleaner (Harrick Plasma )",
            "Ultrasonic Cleaner &nbsp;",
            "Type II De-Ionized Water Unit&nbsp; ( Aquelix, Millipore)",
            "Keithley Source Meter – 2450",
            "Spectro Radio Meter &nbsp;for EL measurements (Ocean Optics)",
            "UV blocking Vacuum Desiccator",
            "Dual channel syringe pump",
            "Vortex Mixer",
            "Magnetic Stirrers with Hot Plate",
            "Dry Cabinet (Benro – LD 080)",
            "Substrate Cutter ( Kaivo)",
            "&nbsp;Bending Twisting Apparatus (Home Made for Testing flexible thin films)",
            "UV/Vis - DRS spectrometer",
            "Micro forge",
            "Micro puller",
            "Infuse/Withdrawl Syrringe pumps",
          ],
        },
      },
      {
        id: "yesgLVi-BG",
        type: "header",
        data: { text: "Laboratories", level: 3 },
      },
      {
        id: "6ugmIcDyD4",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Department of Physics has well-equipped laboratories for different specializations. The salient features of few labs are.",
        },
      },
      {
        id: "ZrrlEYEYIh",
        type: "paragraph",
        data: {
          text: "Computers Lab:&nbsp;&nbsp;The lab is equipped with a good number of computers for the benefit of the students. The students are well trained in different operating systems like Windows, Linux and also trained in programming languages and packages like C, C++ &nbsp;and Data structures. Packages like CODE - V, LightTools are also offered.&nbsp;",
        },
      },
      {
        id: "6vANBtfdXO",
        type: "paragraph",
        data: {
          text: "Electronics Lab:&nbsp;&nbsp;The lab is well known for its ready to use the equipment. In this lab, many circuits like amplifiers, communication units, modulators, filters, can be easily constructed and results can be analyzed, using the available equipment. &nbsp;Electronic instrumentation, Medical instrumentation are the current research areas of the lab. The lab is equipped with CRO ’s,&nbsp;advanced IC’s, signal processing units, analog and digital logic training kits, variable and fixed voltage sources etc.",
        },
      },
      {
        id: "o-H_CScqwh",
        type: "paragraph",
        data: {
          text: "Materials Science lab:&nbsp;This lab is well known for its research in nanomaterials, optical materials, Bio-glasses and magnetic materials like ferrites. The major research equipment in this lab is 1600&nbsp;o&nbsp;C programmable furnace, 1200&nbsp;o&nbsp;C programmable furnace, 8000C furnace, &nbsp;Keithley 2001 multimeter, balance up to 10micro grams accuracy measurements, resistivity measurement units, trinocular microscope to study surface structures, etc. Along with this, the set-ups for PG experiments are also available in the lab.",
        },
      },
      {
        id: "-VVdLJTyLj",
        type: "paragraph",
        data: {
          text: "Microprocessors and Microcontrollers Lab:&nbsp; This lab consists&nbsp;of advanced microprocessors and microcontrollers to learn all the basic as well as advanced programming and interfacing techniques.&nbsp; The students are instructed by well-experienced faculty and are trained to learn the programming and interfacing techniques in different ICs of microprocessors and microcontrollers.",
        },
      },
      {
        id: "zdc8_x40rg",
        type: "paragraph",
        data: {
          text: "Neutron Irradiation Lab:&nbsp; The lab is designed for research activities for the study of materials, especially polymers by irradiating them with neutrons. The lab is equipped with the neutron source, counters, particle measurement units, etc. Effects of radiation on materials such as polymers, composites are the current research areas of the lab. &nbsp;",
        },
      },
      {
        id: "nMb82boyrn",
        type: "paragraph",
        data: {
          text: "Optics lab:&nbsp;This lab is well established with equipment like universal interferometers, gas lasers, semiconductor lasers, polarizer’s, optical detectors, mounts to set necessary arrangements.",
        },
      },
      {
        id: "TLBvgZdUdf",
        type: "paragraph",
        data: {
          text: "OSD lab:&nbsp;The lab is equipped with the good number of computers and the essential software like&nbsp;CODE - V&nbsp;for designing Optical systems.",
        },
      },
      {
        id: "n-__re6cU3",
        type: "paragraph",
        data: {
          text: "Photonics and Lasers lab:&nbsp;Department of Physics is one of the best centers of excellence in the country offering M.Sc. (Tech.) Engineering Physics in photonics. The lab is established to study different optical effects such as the acoustic-optic, electro-optic and magneto-optic effects. The simulation laboratory to study the propagation of light in fibers and sensors is available. The main equipment available in the lab is OSA, OTDR, different types of lasers. The current research is going to Optical sensors, FBG etc.",
        },
      },
      {
        id: "6GJGbbj4EI",
        type: "paragraph",
        data: {
          text: "Under Graduate lab:&nbsp;The department has a well-equipped undergraduate physics laboratory which gives basic knowledge and understanding the different concepts in Physics to the students.",
        },
      },
      {
        id: "xoZCecPrV_",
        type: "paragraph",
        data: {
          text: "All the research labs are working around the clock in the advanced fields of physics with several research scholars and PG students.",
        },
      },
      {
        id: "l1iAmb8TGn",
        type: "header",
        data: { text: "Software", level: 1 },
      },
      {
        id: "VoTa5hVIlQ",
        type: "paragraph",
        data: {
          text: "In Department of Physics, various software is available for Optical System Designing, Electronic Simulations, and other research activities. The students pursuing masters programme are trained with these facilities which are very helpful for them in the industries.&nbsp;",
        },
      },
      {
        id: "2xE8psmUZH",
        type: "paragraph",
        data: {
          text: 'Recently, Department along with SPIE student chapter has conducted a three-day workshop entitled " Advanced Simulation Techniques of Optics and Photonics" for the students and academicians who are working in the field of Optics and Photonics.&nbsp;',
        },
      },
      {
        id: "sVxYP2Z-tm",
        type: "paragraph",
        data: {
          text: "Department of Physics, NITW acknowledges, “Optical Solution Group, Synopsys Inc., USA&nbsp;&nbsp;” for providing CODE – V and Light Tools software for academic purposes.&nbsp;&nbsp;&nbsp;",
        },
      },
    ],
    language: "En",
    _id: "616a8484498ac7002338e406",
    time: 1634370712838,
    version: "2.22.2",
    title: "Facilities",
    url: "/physics/facilities",
    path: "/physics",
    createdAt: "2021-10-16T07:51:32.795Z",
    updatedAt: "2021-10-16T07:51:54.179Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "B7fIyUKfTN",
        type: "header",
        data: { text: "Areas of Research", level: 3 },
      },
      {
        id: "me3V9jLNML",
        type: "paragraph",
        data: {
          text: "Department of Physics is well known for its research and development activities. Every year, the faculty of the department are awarded with different projects from various funding agencies like DST, BRNS, DAE etc.",
        },
      },
      {
        id: "sSvB8HOM2i",
        type: "paragraph",
        data: {
          text: "Currently&nbsp;in the Department of Physics, the research is going in the following areas.&nbsp;",
        },
      },
      {
        id: "eHV7Uh2Sb-",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Fiber Bragg Grating Sensors",
            "High temperature measurement",
            "Holographic NDT",
            "Medical Instrumentation",
            "Microcontrollers and communication",
            "Nano Ferrites &amp; Nano Composites",
            "Nano Phosphors &amp; Bio Glass Ceramics",
            "Nuclear Instrumentation",
            "Optical system design",
            "Organic Light Emitting Diodes",
            "Organic, polymer &amp; Dye-sensitized solar cells",
            "Photonics",
            "Pressure sensing",
            "Radioactive degradations",
            "Liquid Crystals",
            "Microfluidics",
            "Transparent conducting thin films",
            "Vibration measurement",
            "Nanomaterials for energy harvesting",
          ],
        },
      },
      {
        id: "QV_wS7f1cj",
        type: "header",
        data: { text: "Consultancy Services", level: 3 },
      },
      {
        id: "ncmGEw6MCL",
        type: "paragraph",
        data: { text: '"UNDER CONSTRUCTION"' },
      },
      {
        id: "Jd6adqssjl",
        type: "header",
        data: { text: "R &amp; D Projects", level: 3 },
      },
      {
        id: "514z0Ohuiq",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Effects of electron beam irradiation on polylactic acid blends and composites",
            "Development of novel bioactive glass and glass ceramics-invitro and invivo",
            "Development of Distributed Photonic Sensor for Simultaneous and Real-time Measurements of Strain and Temperature in Fusion Reactor Relevant Applications",
            "Development of Highly Efficient Perovskite Solar Cells using Chemical Vapor &nbsp;Deposited Graphene and Graphene Nanocomposites as Electron Transport layers",
            "Interface engineering with CVD grown MoS2 ultra-thin layers for the Improvement of Perovskite Solar Cell Performance",
            "Nanostructured ‘La’ doped BaSnO3 Electrodes for high efficiency Dye-Sensitized solar cells",
            "Microfluidic Methods for the Synthesis of Monodisperse Nematic and Chiral nematic Emulsions for the Application in Display and Fiber Optic Technology",
            "Piezo and Pyroelectric Flexible Nano generators for Energy Harvesting and Self-Powered Sensor Applications",
          ],
        },
      },
      {
        id: "Qt9-H-uQ2X",
        type: "header",
        data: { text: "Research Highlights", level: 3 },
      },
      {
        id: "yU5KPfI-Ee",
        type: "paragraph",
        data: { text: '"UNDER CONSTRUCTION "' },
      },
      {
        id: "TF2aEMlvke",
        type: "header",
        data: { text: "Research&nbsp;Scholars", level: 5 },
      },
      {
        id: "tXEYigkkB3",
        type: "header",
        data: {
          text: 'Full-time Scholars&nbsp;- <a href=`${MainServerUrl}/static/files/Full-time_Scholars_2022-0-24-11-39-27.pdf`>Click here</a>',
          level: 6,
        },
      },
      {
        id: "TbP-bQgx_i",
        type: "paragraph",
        data: {
          text: 'Part-time Scholars - <a href=`${MainServerUrl}/static/files/Part-time_Scholars_-_Click_here_2022-0-24-11-41-25.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "616a8d78498ac7002338e40b",
    time: 1643004703948,
    version: "2.22.2",
    title: "Research",
    url: "/physics/research",
    path: "/physics",
    createdAt: "2021-10-16T08:29:44.697Z",
    updatedAt: "2022-01-24T06:11:44.449Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "CoQLSZZHCF",
        type: "header",
        data: {
          text: "Welcome to Department of School of Management",
          level: 3,
        },
      },
      {
        id: "VzZbphCjQ-",
        type: "paragraph",
        data: {
          text: "In the contemporary world of rapidly changing global business, which are technology intensive and consumer oriented, the acumen needed for managers is being revolutionized. Recognizing this the institute has designed a comprehensive MBA programme exclusively for engineers. Thus came to light the “School Of Management (SOM)” at NIT Warangal.",
        },
      },
      {
        id: "r0Q1on5kAY",
        type: "paragraph",
        data: {
          text: "The MBA program offered by the School of Management is exclusively for engineering graduates. It is a two year course that runs into 8 quarters. The program has extensive electives in the specialised areas of Marketing Management, Human Resource Management, Financial Management, Operations Management, Information Technology and allied areas of management. Students are exposed to industrial training (8weeks) after completion of 1st year and the project work (12weeks) during their final quarter.",
        },
      },
      {
        id: "Ofw32Qj8im",
        type: "paragraph",
        data: {
          text: "The school of Management provides a well-balanced combination of academic and practical business oriented content. The updated curriculum with its wide range of video materials, databases like prowess, case studies, research projects and other tools aim at enhancing the understanding of the business reality and also imbibing problem-solving skills. The school has been accredited with Tata Consultancy Services and is having good placements. The School has fully air conditioned classrooms with Wi-Fi facility and on-desk microphone with other modern digital gadgets.",
        },
      },
      {
        id: "cIG1-FEfVk",
        type: "header",
        data: { text: "Introduction", level: 3 },
      },
      {
        id: "XTUvxJvVBJ",
        type: "paragraph",
        data: {
          text: "National Institute of Technology Warangal (NITW), in 2000, established Center for Management Studies. Later, renamed as School of Management (SOM). SOM offers Masters and doctoral programs and promotes faculty and doctoral research, training, consultancy, and outreach activities in various areas.&nbsp;The Master's program is a two-year full-time residential program. The admission to Masters program, Masters in Business Administration - MBA, is on the basis of CAT/MAT scores, done once every year at the beginning of the academic year. In 2009, School of Management started Doctor of Philosophy, a doctoral program. The doctoral program is designed to provide both depth and breadth to doctoral participants. The doctoral program is offered in various areas of management.",
        },
      },
      {
        id: "3oTQt8LFCZ",
        type: "paragraph",
        data: {
          text: "School of Management is placed in the precincts of NITW and enjoys the state-of-the-art infrastructure of NITW, these include a strong IT-backbone, sports complex, hostels, and so forth.&nbsp;",
        },
      },
      {
        id: "UGq3Bgmlj3",
        type: "paragraph",
        data: {
          text: "A highly enriching environment, both professionally and personally, awaits you at School of Management.",
        },
      },
      { id: "nE-NFB3-9x", type: "header", data: { text: "Vision", level: 3 } },
      {
        id: "lcYI33AWCr",
        type: "list",
        data: {
          style: "unordered",
          items: ["To carve engineers into corporate leaders."],
        },
      },
      { id: "98vZYCBcNE", type: "header", data: { text: "Mission", level: 3 } },
      {
        id: "MMhVxQQ79T",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To impart management education synchronized with the needs of the industry.",
          ],
        },
      },
      {
        id: "GqVWPgZLWw",
        type: "list",
        data: {
          style: "unordered",
          items: ["To strengthen leadership qualities."],
        },
      },
      {
        id: "f9btW4xklA",
        type: "list",
        data: {
          style: "unordered",
          items: ["To enhance the analytical and problem-solving abilities."],
        },
      },
      {
        id: "a1P7uLfkui",
        type: "header",
        data: { text: "Message from the HOD's desk", level: 3 },
      },
      {
        id: "Kg8p_P4AF2",
        type: "paragraph",
        data: {
          text: 'The School of Management, NITW offers M.B.A &amp; Ph.D programmes and the vivid teaching and research pedagogy across the discipline facilitates synchronized delivery of knowledge and skills to the students. The School of Management has been striving consistently towards excellence in management education and carved it’s own niche through nurturing "Technomanagers ready for tomorrow".&nbsp; SoM has a team of 11 full time Core Faculty having rich experience in academics/ industry who are engaged in teaching, research and outreach activities.&nbsp; The Department has funded research projects and consultancy assignments.',
        },
      },
      {
        id: "lB7IgSK6_9",
        type: "paragraph",
        data: {
          text: "MBA is a two-year programme spanning across eight quarters with the curriculum that provides functional and integrative perspectives to the students. The programme has a blend of variety of courses - core courses, capstone courses, extensive electives in the specialized areas of Marketing Management, Human Resource Management, Financial Management, Operations &amp; IT , industrial training (8 weeks) and the project work (12 weeks).&nbsp; In the evolving context of the dynamic business world, a manager needs to be agile and respond to the diverse challenges being faced by organizations. Our pedagogy aims to build this through experiential learning. The teaching pedagogy is a well-balanced mix of interactive lectures, case studies, role plays, simulations, group exercises, seminars, assignments and live projects that enables our students to realize their potential to become excellent managers.",
        },
      },
      {
        id: "a6dCWqVnsc",
        type: "paragraph",
        data: {
          text: "The School has a continuous process of interaction with industry professionals through expert lectures/seminars and other students’ festivals / events / activities (clubs), etc. Our alumni are placed in prestigious organizations as managers and in leadership positions representing different verticals and sectors. &nbsp;&nbsp;I welcome you all to the SoM to explore and experience the journey of quality management education and lifelong learning.",
        },
      },
      {
        id: "kA4fxEfQwZ",
        type: "header",
        data: { text: "Administrative Staff", level: 3 },
      },
      {
        id: "P7wuBiQXR0",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Name", "Designation", "Contact details"],
            ["Bhaskar", "Clerk", "+91-870-2462850&nbsp;+91-870-2462851"],
            ["Sambiah", "Attender", "+91-870-2462850"],
            ["Naresh", "Office boy", "+91-870-2462850"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616a93ad498ac7002338e416",
    time: 1640278051965,
    version: "2.22.2",
    title: "Home",
    url: "/sm/home",
    path: "/sm",
    createdAt: "2021-10-16T08:56:13.075Z",
    updatedAt: "2021-12-23T16:47:32.463Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "b2O5J8GphJ",
        type: "header",
        data: { text: "Academic Programs", level: 3 },
      },
      {
        id: "NfIUSYlJOR",
        type: "paragraph",
        data: {
          text: "The School of Management of National Institute of Technology Warangal offers Masters in Business Administration and Doctor of Philosophy in management studies. It provides&nbsp;learning and research in its academic programmes by providing exposure to a format which integrates data, information, tools and techniques, perspectives and concepts, from various basic disciplines aimed at advancing fundamental understanding to solve&nbsp;real-life problems.",
        },
      },
      {
        id: "_4fBksuQPv",
        type: "paragraph",
        data: {
          text: "School of Management's intake of students for Masters in Business Administration (MBA) is 62. For Doctor of Philosophy (PhD), there are currently 11 registered (both full-time and part-time) participants. The alumni for MBA and PhD are 584 and 4 respectively. These values exclude DASA and ICCR students.",
        },
      },
      {
        id: "TloDccxip1",
        type: "header",
        data: { text: "Admissions - MBA and PhD", level: 3 },
      },
      {
        id: "leNT1ak7Sk",
        type: "paragraph",
        data: {
          text: "MBA Admission for 2020 is completed and the selected candidates list is uploaded in the institute web-site.",
        },
      },
      {
        id: "ttwnYDG0TC",
        type: "paragraph",
        data: {
          text: "Ph.D admissions for the the odd semester of AY2020=21 has commenced. For details, please visit the announcements section in the main page.",
        },
      },
      {
        id: "Vd4UFNGK9q",
        type: "header",
        data: { text: "MBA Admissions", level: 5 },
      },
      {
        id: "eaw9SZFHkb",
        type: "paragraph",
        data: {
          text: "Admissions for MBA at NITW will be advertised every year in the leading newspapers in the months of December-January. The advertisement will providie&nbsp;a link to the notification uploaded in the institute web-site. The notification will contain the details inclduing&nbsp;eligibility criteria, admission procedure, fees and timelines and so forth. The adnmissions will follow&nbsp;reservations for OBC, SC, ST, and EWS as per the rules of&nbsp;Government of India.",
        },
      },
      {
        id: "0CdRKDFVwz",
        type: "paragraph",
        data: {
          text: "For the sake of convenience, certain details are mentioned below -",
        },
      },
      {
        id: "TrK_esv5B4",
        type: "paragraph",
        data: { text: "Eligibity Criteria -&nbsp;" },
      },
      {
        id: "F080gxs7U6",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "A first class Bachelor's degree in Engineering/Tcehnology from an reputed institute/university.",
            "A valid CAT/MAT score",
            "At least 60% marks in aggregate (I to IV years) or 6.5 CGPA&nbsp; on 10-point scale.",
            "SC/ST/PWD candidates with 55% aggregate marks (I to IV years) or 6.0 CGPA on 10-point scale.",
          ],
        },
      },
      {
        id: "4b8UkFIqL-",
        type: "paragraph",
        data: { text: "Admissions Procedure -" },
      },
      {
        id: "Z3-djfR63Z",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Admissions are done on All&nbsp;India basis.",
            "Applicants should be citizen of India.",
            "Selection will be based on valid CAT/MAT score, group discussion and personal interview.",
            "The short-listed candidates will be notified by uploading the selected list in the institute web-site. No personal communications will be sent.",
          ],
        },
      },
      {
        id: "9YyJtjVZUa",
        type: "header",
        data: { text: "Ph. D Admissions", level: 5 },
      },
      {
        id: "mAca3EU3AO",
        type: "paragraph",
        data: {
          text: "Admissions for Ph.D at NITW are advertised twice a year in June/July and November/December&nbsp;months in the research areas mentioned in the department web-site. The advertisement will providie&nbsp;a link to the notification uploaded in the institute website. The notification will contain all the details&nbsp;inclduing&nbsp;eligibility criteria, admission procedure, fees,&nbsp;timelines, and so forth. Candidates can register for PhD in either full time or part time;&nbsp;for details,&nbsp;please refer the&nbsp;notification. The adnmissions will follow&nbsp;reservations for OBC, SC, ST, and EWS as per the rules of&nbsp;Government of India.",
        },
      },
      {
        id: "5TQglNdaQu",
        type: "paragraph",
        data: {
          text: "For the sake of convenience, certain details are mentioned below -",
        },
      },
      {
        id: "-wNyEHJimy",
        type: "paragraph",
        data: { text: "Eligibility Criteria -" },
      },
      {
        id: "K2pD2I1kJT",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Any graduate having full time MBA degree.",
            "Qualified in UGC NET examination",
            "At least&nbsp;First class with 60% or 6.5 CGPA at both undergraduate and postgraduate degrees for Open/OBC categories.",
            "SC/ST/PWD candidates with 55% aggregate marks or 6.0 CGPA on 10-point scale&nbsp;",
          ],
        },
      },
      {
        id: "tPhyf9CYc-",
        type: "paragraph",
        data: { text: "Admission Procedure -&nbsp;" },
      },
      {
        id: "sw1WasKZx9",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "The department reserves the right to conduct a written test. The date of written test will be mentioned in the notification. The notification will uploaded in&nbsp;the institute website.",
            "The written test will be followed by an interview. The short-listed candidates wuill be notified by uploading the selected list in the institute web-site. No personal communications will be sent.",
          ],
        },
      },
      { id: "_LA9Q3J_PX", type: "header", data: { text: "MBA", level: 3 } },
      {
        id: "GkyTBJxtyd",
        type: "header",
        data: { text: "Masters in Business Administration (MBA)", level: 5 },
      },
      {
        id: "bf9H2vUeTs",
        type: "paragraph",
        data: {
          text: "The Masters in Business Administration (MBA) program offered by the School of Management is exclusively for engineering graduates.",
        },
      },
      {
        id: "3PMAHNIVGj",
        type: "header",
        data: { text: "Pedagogy", level: 5 },
      },
      {
        id: "r_iqhj-Ja2",
        type: "paragraph",
        data: {
          text: "MBA program of NITW consists of a combination of various teaching methods such as cases, research papers, projects, computer-aided instructions, class participation, lectures, seminars by participants and guest lectures from industry and government. The case and research papers are the predominant pedagogical tools. Thereby, the participant's analytical skills get improved and assist them to analyze problems by considering multi-functional perspectives. The instructors mainly guide the group and encourage participants to develop and defend their ideas including decision-making.",
        },
      },
      {
        id: "2bauOKxVSR",
        type: "header",
        data: { text: "Structure", level: 5 },
      },
      {
        id: "80d81g8rAZ",
        type: "paragraph",
        data: {
          text: "It lasts for 8 terms spread over two years program with a summer project in between the two years. The summer project duration is 8 weeks and participants are required to put their ideas and skills to work in established and reputed companies from various sectors. The selection of the company is left to the participants choice.",
        },
      },
      {
        id: "lKPj8T9Nd4",
        type: "paragraph",
        data: {
          text: "The first year consists of core subjects that provide the fundamental knowledge, analytical skills &amp; techniques, contextual understanding, environmental awareness and overall perspective which serve as the bedrock for the programme. The areas covered are economics, human resource management, finance, information technology, marketing, operations, quantitative research, and strategy.",
        },
      },
      {
        id: "qJYYgCo4NG",
        type: "paragraph",
        data: {
          text: "In the second year, participants along with certain advanced subjects select major and minor electives. The electives assist participants to get a deeper understanding in the functional area of their choice. In the terms five, six, and seven, participants will need to take two subjects from their major elective and one subject from their minor elective. The choices for both major and minor electives are from the following areas:",
        },
      },
      {
        id: "Zd472uOaQj",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Financial Management",
            "Information Technology.",
            "Marketing Management",
            "Operations Management",
            "Organizational Behavior &amp; Human Resource Management",
          ],
        },
      },
      {
        id: "terdRqyPul",
        type: "paragraph",
        data: {
          text: "Apart from the above, the participants need to complete a project work of 12 weeks duration. The final term is exclusively assigned for project work.&nbsp;",
        },
      },
      {
        id: "CqrCkFZaP6",
        type: "header",
        data: { text: "Blending Theory and Practice", level: 5 },
      },
      {
        id: "a2P4MQjUB6",
        type: "paragraph",
        data: {
          text: "MBA program of NITW prides itself in the hiring of professors of practice., which allows professionals with 15-20 years of industry experience to become a full-time faculty. During the coursework, they provide examples from the 'trenches' and provide a practicum-based course of study.",
        },
      },
      {
        id: "zL5cUikR0-",
        type: "header",
        data: { text: '<a href="http://google.com">Syllabus</a>', level: 5 },
      },
      {
        id: "XLQbaNKpKC",
        type: "header",
        data: { text: "Ph. D Program", level: 3 },
      },
      {
        id: "Xw-jslK0It",
        type: "header",
        data: {
          text: "Doctor of Philosophy (Ph. D) at School of Management NITW",
          level: 5,
        },
      },
      {
        id: "8f-hkmW_jV",
        type: "paragraph",
        data: {
          text: "The PhD program at the School of Management NITW offers specialization in the areas mentioned below.&nbsp; It enables the participants to master relevant methodological tools of management research and advance the current body of management knowledge. The rigour of the doctoral program provides ample training for an academic career or in research positions outside academia.&nbsp;",
        },
      },
      {
        id: "kQhuWMzdm2",
        type: "paragraph",
        data: { text: "The areas available for pursuing doctoral program are" },
      },
      {
        id: "j40X5ioypX",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Economics",
            "Financial Management",
            "Information Technology.",
            "Marketing Management",
            "Operations Management",
            "Organizational Behaviour &amp; Human Resource Management",
            "Strategic Management",
          ],
        },
      },
      {
        id: "Lw4V35sP4j",
        type: "paragraph",
        data: {
          text: "The research at the School of Management NITW expects the participants to think critically and reflect originally with high ethical standards. In doing so, the participants will not only meet the rigour of NITW but also the societal need for excellent researchers and academicians.",
        },
      },
      {
        id: "MWUOLmNPm5",
        type: "paragraph",
        data: {
          text: "The full-time participants are provided with scholarship and accommodation at the institute. For part-time participants, the curriculum does provide the required flexibility to balance their work and research. &nbsp;",
        },
      },
      {
        id: "P-8b4bA9de",
        type: "header",
        data: { text: "Rules and Regulations", level: 3 },
      },
      {
        id: "kIV7N1ZGhs",
        type: "paragraph",
        data: {
          text: "The rules and regaultions mentioned for the Masters program in the institute web-site are applicable for MBA program. For the sake of convenience, the link is given below -",
        },
      },
      {
        id: "xRr1Wq5xRP",
        type: "paragraph",
        data: {
          text: 'Please&nbsp;<a href="https://www.nitw.ac.in/media/uploads/PG_rules_2017-18_Sciences_MCA_and_MBA-1_1.pdf">CLICK HERE</a>&nbsp;',
        },
      },
    ],
    language: "En",
    _id: "616ac329dcba0b907969ebb4",
    time: 1634386743345,
    version: "2.22.2",
    title: "Academics",
    url: "/sm/academics",
    path: "/sm",
    createdAt: "2021-10-16T12:18:49.620Z",
    updatedAt: "2021-10-16T12:19:03.348Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "AXBEHEjrMt",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<b>Areas of Research</b>&nbsp;- <a href=`${MainServerUrl}/static/files/Areas_of_Research_2022-0-24-11-24-26.pdf`>Click here</a>',
          ],
        },
      },
      {
        id: "0rrQRO0Zjy",
        type: "header",
        data: { text: "Awards &amp; Achievements", level: 6 },
      },
      { id: "YY4kWOSBPK", type: "paragraph", data: { text: "&nbsp;AWARDS" } },
      {
        id: "3I96rc_Z1w",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Outstanding Engineering College, 2014,&nbsp;by National Education Leadership&nbsp;in association with STARS GROUP.",
            "National B-School Award&nbsp;- For B-School with Industry Related Curriculum in Information Technology by&nbsp;DNA &amp; STARS GROUP.",
            "Innovative B-School Award&nbsp;- For B-School with Industry Related Curriculum in Information Technology by&nbsp;AMAR UJALA B-School Excellence Awards endorsed by DSA.",
            "ABP News National B-School Award, 2012,&nbsp;- For B-School with Industry Related Curriculum in Information Technology.",
            "B-School Excellence Award Powered by Bloomberg/UTV, 2012,&nbsp;- For B-School with Industry Related Curriculum in Information Technology.",
            "Star News National B-School Award In Association with Canon&nbsp;(South of India) - For Outstanding Engineering Institute with Business Management course.",
            "ABP (Ananda Bazaar Patrika)&nbsp;News honoured&nbsp;National Institute of Technology Warangal and the School of Management for two consecutive years.",
            "Tata Consultancy Services, 2011,&nbsp;did accredit the School of Management",
          ],
        },
      },
      {
        id: "dvJCiUN0H0",
        type: "header",
        data: { text: "Consultancy - Will be updated", level: 6 },
      },
      {
        id: "NyqpBmYr9I",
        type: "header",
        data: { text: "Ph.D. Thesis&nbsp;- Will be updated", level: 6 },
      },
      {
        id: "e2aRdaWG-c",
        type: "header",
        data: { text: "Faculty Publications&nbsp;", level: 5 },
      },
      {
        id: "qBJ_TEEnQP",
        type: "paragraph",
        data: {
          text: '2018-19&nbsp;- <a href=`${MainServerUrl}/static/files/2018-19_2022-0-24-11-26-50.pdf`>Click here</a>',
        },
      },
      {
        id: "ggb7gIQOQO",
        type: "paragraph",
        data: {
          text: '2017-18 - <a href=`${MainServerUrl}/static/files/2017-18_2022-0-24-11-27-29.pdf`>Click here</a>',
        },
      },
      {
        id: "kl96yUwWFV",
        type: "paragraph",
        data: {
          text: '2019-2020 - <a href=`${MainServerUrl}/static/files/2019-2020_2022-0-24-11-28-36.pdf`>Click here</a>',
        },
      },
      {
        id: "ovrN1bANWd",
        type: "header",
        data: { text: "Major R&amp;D projects (2010-2018)", level: 5 },
      },
      {
        id: "cffl95_GvS",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Research activities in the institute: So far SOM has completed projects worth Rs.1.602 million by NSMTMIS, DST and Government of India.",
            "Social impact study of CSR CD activities of NTPC SRHQ Hyderabad and Ramagundam",
            "Two-day training program 'DISHA' that provides management insights for IOCL dealers.",
          ],
        },
      },
    ],
    language: "En",
    _id: "616b0ba8e871ca903d807362",
    time: 1643003939076,
    version: "2.22.2",
    title: "Research",
    url: "/sm/research",
    path: "/sm",
    createdAt: "2021-10-16T17:28:08.282Z",
    updatedAt: "2022-01-24T05:58:59.582Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "gZop82FTxN",
        type: "header",
        data: { text: "Adhoc Faculty", level: 3 },
      },
      {
        id: "Uf6EjHvagG",
        type: "paragraph",
        data: { text: "Jun2021&nbsp;thru Dec2021" },
      },
      {
        id: "H8YiH2pJs5",
        type: "paragraph",
        data: { text: "Adhoc faculty on-board are" },
      },
      {
        id: "fm9BOEB84O",
        type: "paragraph",
        data: {
          text: "Habeeb Syed; Ph: +91 8978625821; Email: habeebsyed.nitw@gmail.com",
        },
      },
      {
        id: "ty64ZeCO7M",
        type: "paragraph",
        data: {
          text: 'Jyoti Chepuru; Ph: +919010946549; E-mail:&nbsp;<a href="mailto:jchepur_adh@nitw.ac.in">jchepur_adh@nitw.ac.in</a>',
        },
      },
      {
        id: "2TpULVlBTO",
        type: "paragraph",
        data: {
          text: "Saleh Ahmed Mozumder;&nbsp;Ph: &lt;&lt;TBD&gt;&gt;; E-mail: &lt;&lt;TBD&gt;&gt;",
        },
      },
      {
        id: "hc8MvhJazR",
        type: "paragraph",
        data: { text: "<b>The above adhoc faculty handle subjects:</b>" },
      },
      {
        id: "fka1TvzWTl",
        type: "paragraph",
        data: { text: "Economics and Finance;" },
      },
      {
        id: "8DEwjgMHLq",
        type: "paragraph",
        data: { text: "Industrial Management" },
      },
      {
        id: "kiihlOo2UE",
        type: "header",
        data: { text: "Other resources", level: 3 },
      },
      {
        id: "Cf5b9Naf-L",
        type: "list",
        data: { style: "unordered", items: ["Library"] },
      },
      {
        id: "4_2mrSKP1v",
        type: "paragraph",
        data: {
          text: "The central library is a major repository for books. The central library has a wide number of volumes, journals, magazines, books, databases etc. Apart from the Central Library, the department does maintain an in-house library covering books is select areas of management.&nbsp;",
        },
      },
      {
        id: "mvlTiMI4-G",
        type: "list",
        data: { style: "unordered", items: ["Analytics Lab"] },
      },
      {
        id: "OXa3zh-S1t",
        type: "paragraph",
        data: {
          text: "The analytics lab has 30 machines. The machines have access to Capitaline database,IBM SPSS, India business insight,&nbsp;IBI, GRETL, R and Python.&nbsp;The analytics lab has an inverter and provides uninterrupted power-supply.",
        },
      },
      {
        id: "0PK0y7LknK",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "&nbsp;Wi-Fi enabled&nbsp;Interactive Classrooms",
            "&nbsp;Group Discussion hall",
            "Seminar Hall to facilitate corporate interactions",
          ],
        },
      },
    ],
    language: "En",
    _id: "616b0eec4f1bf39065c702f1",
    time: 1634406182232,
    version: "2.22.2",
    title: "Resources",
    url: "/sm/resources",
    path: "/sm",
    createdAt: "2021-10-16T17:42:04.975Z",
    updatedAt: "2021-10-16T17:43:02.121Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "kbtfRwtt6p",
        type: "header",
        data: { text: "About Us", level: 4 },
      },
      {
        id: "OIrz1vuuRR",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="" class="">National Institute of Technology, Warangal (Deemed University)&nbsp;, formerly known as Regional Engineering College, was&nbsp;established&nbsp;in 1959.</editorjs-style>',
        },
      },
      {
        id: "6QGYb9qvC6",
        type: "paragraph",
        data: {
          text: "Pandit Jawaharlal Nehru&nbsp;laid the foundation stone for this institute on October 10, 1959, the first in the chain of 30 NITs (formerly known as RECs) in the country.",
        },
      },
      {
        id: "i2edLSVme0",
        type: "paragraph",
        data: {
          text: "The Institute is well known for its dedicated faculty, staff and the state-of-the art infrastructure conducive to a healthy academic environment.The Institute is constantly striving to achieve higher levels of technical excellence.&nbsp; Evolving a socially relevant and yet internationally acceptable curriculum, implementing innovative and effective teaching methodologies and focusing on the wholesome development of the students are our concerns.&nbsp; Thanks to UNESCO and UK assistance in the past, many developmental activities were undertaken.The World Bank Assistance under Technical Education Quality Improvement Programme (TEQIP during 2004-09) had been a timely help in the overall development of the Institute.",
        },
      },
      {
        id: "AhoIGRQxjh",
        type: "paragraph",
        data: {
          text: "The Institute currently has thirteen academic departments and a few advanced research centres in various disciplines of engineering, pure sciences and management, with nearly 100 laboratories organized in a unique pattern of functioning, Central Library with state of the art facilities, Auditorium, Student Activity Centre, Mega Computer Centre, Indoor Games Complex, big stadium, Seminar Halls with required infrastructure, Dispensary with state of art of facilities, etc. Faculty of repute, brilliant student&nbsp;",
        },
      },
      {
        id: "rUnI7MWbjX",
        type: "paragraph",
        data: {
          text: "community, excellent technical&nbsp;and supporting staff and an effective administration have all contributed to the pre-eminent status of N.I.T., Warangal.The Institute offers eight undergraduate programmes (B.Tech.,) in engineering, Twenty nine post graduate programmes (M.Tech., M.Sc., MCA and MBA) in engineering, sciences and management and research programmes in engineering, sciences, humanities, physical education and&nbsp;management.The institute is well-known for its Research and Development, Industrial consultancy, Continuing education and Training programmes for teachers and industrial personnel.",
        },
      },
      {
        id: "DIPyuRqDYt",
        type: "paragraph",
        data: {
          text: "\n\nN.I.T., Warangal is a residential institute with nearly 3355 (UG : 2251 &amp; PG : 1104) students apart from nearly 269 Ph.D., (150 Full Time and 119 Part time) Scholars.&nbsp; It has 16 residential blocks including one mega &amp; one ultra-mega hostel for boys, two hostels for girls and one hostel for foreign students.&nbsp; It has five messes for boys, one mess exclusively for girls and one mega mess with present state of art facilities for boys, foreign students and girls.&nbsp; It has nearly 200 teaching staff and 360 administrative and supporting staff apart from outsource personnel.It is a self contained residential campus located in a beautiful wooded land of about 248 acres.It is located on either side of Hyderabad - Warangal highway at a distance of 3 Kms from Kazipet Railway Station and 12Kms from Warangal Railway Station. It is located at a distance of 140 Kms from Hyderabad. Warangal enjoys a unique position in the cultural and historical map of the state. The exquisite and intricate architecture in Ramappa, Thousand Pillar Temple, Badrakali Temple and the Warangal Fort have been centres of attraction. Lovely gardens, wild life sanctuaries, lakes and rocks in the vicinity are other places of interest for students. The place enjoys a good climate throughout the year except for the patches of hot summer during May-June.\n\n",
        },
      },
      {
        id: "q9LrsQ6dMn",
        type: "paragraph",
        data: {
          text: "It is a residential campus with all the basic facilities for both the students and staff to live on the campus. The following statistical figures might give an idea about the buildings in the campus. The plinth area occupied by the different buildings on the campus are: Administrative Building (3,331 Sqm), Academic Buildings (34,448 sqm), Library Building (3,610 sqm), Students Hostels (32,642 sqm), Sports Pavilion and Gallery (784 Sqm), Director's Bungalow, Staff Quarters and Guest House (31,598 sqm.) The road network in the campus runs to a length of 12 kms. Work is in progress in creating more space for the varied purposes referred above and also for the extension of network of roads.",
        },
      },
      {
        id: "gcy_m4dTNW",
        type: "paragraph",
        data: {
          text: 'The placement service at the institute is one of the best of its kind in the country. The alumni of the institute hold responsible and enviable positions all over the world and are in constant touch with the institute. Every new entrant into the portals of this institution is poised for partaking a rich heritage and tradition that is unique to NITW. According to&nbsp;<a href="http://www.outlookindia.com/article/top-engineering-colleges/291203" target="_blank">2015 Outlook&nbsp;Survey, NITW clinches the&nbsp;11th rank</a>&nbsp;among the technical institutes in the country.',
        },
      },
    ],
    language: "En",
    _id: "616bfced15865900231f76a0",
    time: 1641448591242,
    version: "2.22.2",
    title: "About Us",
    path: "/home",
    url: "/home/about",
    createdAt: "2021-10-17T10:37:33.902Z",
    updatedAt: "2022-01-06T05:54:40.856Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "J1-5SuZnOA",
        type: "header",
        data: { text: "Important Events", level: 4 },
      },
      {
        id: "7VDNZ8dMF5",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Date", "Event"],
            [
              "October 10, 1959",
              "Pandit Jawaharlal Nehru laid foundation stone for the College, the first in the chain of NITs (formerly RECs) in the country.",
            ],
            [
              "November 10, 1959",
              "Registration of the Society of Regional Engineering College, Warangal",
            ],
            ["August, 1965", "Commencement of UNESCO assistance project."],
            [
              "October, 1972",
              "Change of affiliation of the College from Osmania University to Jawaharlal Nehru Technological University. Academic autonomy conferred on the College.",
            ],
            [
              "August 19, 1976",
              "Change of affiliation of the College from JNTU to Kakatiya University.",
            ],
            [
              "August, 1981",
              "Admission for B.Tech. through Common Entrance Examination for students from Andhra Pradesh.",
            ],
            [
              "June, 1987",
              "Establishment of Entrepreneurship Development Cell.",
            ],
            [
              "January, 1990",
              "The College is selected for assistance under UK-INDIA Project.",
            ],
            [
              "June, 1991",
              "Memorandum of understanding signed between the College and Confederation Indian Industry.",
            ],
            ["April, 1994", "Commencement of UK - INDIA RECs Project."],
            [
              "January, 1995",
              "Campus LAN installed. E-Mail facility provided by dial up connectivity through Central University, Hyderabad.",
            ],
            [
              "October, 1996",
              "Commissioning of a dedicated Internet connectivity through VSAT dish. Browsing and E-mail facility provided.",
            ],
            [
              "November, 1996",
              "The AICTE recommends to the M.H.R.D and U.G.C the award of the Deemed to be University Status to REC Warangal.",
            ],
            [
              "October 23-24, 1999",
              "“40th Foundation Day Celebrations”. His Excellency Dr.C.Rangarajan, Governor of A.P. and General K.V. Krishna Rao (Retd. Chief of Army Staff) graced the occasion by their presence.",
            ],
            [
              "May 16, 2001",
              "MoU was signed between STPI, Hyderabad and REC, Warangal regarding setting-up of Software Technology Park at the Institute",
            ],
            [
              "July 20, 2001",
              "&nbsp; AICTE grants the status of QIP (Quality Improvement Programme) Centre to the following departments:(i)Ph.D. Programme – Departments of Mechanical Engineering, Chemical Engineering and Mathematics(ii)M.Tech. Programme – Departments of Electrical Engineering and the Department of Electronics and Communication Engineering.&nbsp;&nbsp;",
            ],
            [
              "September, 2001",
              "Inauguration of MSIT course in A.P. by the Hon’ble Chief Minister of Andhra Pradesh at Hyderabad with a Learning Centre at Rec, Warangal",
            ],
            [
              "July, 2002",
              "Board of Governors resolved to approve the new Memorandum Of Association (MOA) for conversion of Regional Engineering College, Warangal as National Institute of Technology (NIT), Warangal.",
            ],
            [
              "September, 2002",
              "Institute renamed as National Institute of Technology, Warangal and offered the status of Deemed University.",
            ],
            [
              "July, 2003",
              "Commencement of admission of students for B.Tech through AIEEE stream",
            ],
            [
              "August 4, 2003",
              "Prof. Deba Kumar Tripathy takes charge as the first Director of NIT Warangal.",
            ],
            [
              "August, 2003",
              "Change over to IIT Pattern in Curriculum: Revision of syllabi and rules of regulations for the UG and PG courses and introduction of semester system for B.Tech and MBA at the first year level. Change over of evaluation pattern to Credits and GPA system.",
            ],
            [
              "June, 2004",
              "The Institute receives an assistance of Rs. 11.41 crores from the world bank under TEQIP (Technical Education Quality Improvement Programme).",
            ],
            [
              "March 1, 2004",
              "Ist Convocation of NIT Warangal. Prof. S.K. Joshi, Chairman, Board of Governors, IIT Roorkee and Former Director General, CSIR delivers the First Convocation address.",
            ],
            [
              "January 22, 2005",
              "2nd Convocation of NIT Warangal. Dr. Anil Kakodkar, Chairman, Atomic Energy Commission and Secretary, Department of Atomic Energy, Government of India delivers the Second Convocation address.",
            ],
            [
              "June 2005",
              "MoU was signed between NIT Warangal and M/S Philips Software, Bangalore. Under this MoU Philips software shall sponsor a maximum of 5 candidates to pursue M.Tech (VLSI) in the institute. Further research collaboration is planned between the two partners. Phillips Software shall also sponsor a state of the art laboratory in VLSI at the institute.",
            ],
            [
              "November 21, 2005",
              "Dr. Ing. Y.V.Rao takes charge as Director of NIT, Warangal.",
            ],
            [
              "March 25, 2006",
              "3rd Convocation of NIT, Warangal Prof. S.K. Khanna, Former Chairman, AICTE &amp; Former Vice-Chairman, UGC delivers the Third Convocation Address.",
            ],
            [
              "August 8, 2006",
              "Prof. Y.V. Rao, Director and Prof. T.K.V.Iyengar, NIT, Warangal were awarded Bharath Jyothi Award",
            ],
            [
              "October 18, 2006",
              "4th Convocation of NIT, Warangal. Prof. Damodar Acharya, Chairman, AICTE, New Delhi delivered the Convocation Address",
            ],
            [
              "November 30, 2006",
              "NIT, Warangal entered into MoU with Effexoft Solutions Pvt. Ltd., USA (Regd. Office at Hyderabad) with regard to IT consulting software and solutions development",
            ],
            [
              "December 7, 2006",
              "NIT, Warangal entered into MoU with National Academy of Construction (NAC), Hyderabad with regard to implementation of academic continuing education and training programmes in construction engineering of mutual interest and benefit.",
            ],
            [
              "January 3, 2007",
              "Inauguration of Centre for Rural Development building by Prof. Y.V.Rao, Director, NITW",
            ],
            [
              "January 15, 2007",
              "International Kalam Guest House was inaugurated by Prof. K.Koteswara Rao, former Principal, RECW",
            ],
            [
              "January 18, 2007",
              "Foundation stone laid for Centre for Automation &amp; Instrumentation by Prof. R.A. Yadav, Vice-Chairman, AICTE, New Delhi",
            ],
            [
              "March 22, 2007",
              "Prof. Y.V.Rao, Director, NITW was appointed as Chairman, AICTE South Central Committee with its Office at Hyderabad.",
            ],
            [
              "August 24, 2007",
              "Inauguration to initiate concrete activity for the construction of Mega Hostel.",
            ],
            ["September 5, 2007", "MoU with M/s TCS Company"],
            [
              "September 15, 2007",
              "Institution of Engineers Andhra Pradesh State Centre Presented Engineer of the year award to Prof. D.V.L.N. Somayajulu",
            ],
            [
              "October 27, 2007",
              "Team of experts from France, ESIGELEC sign MoU&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; MoU with Lawrence Tech University&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; MoU with Mont Belford University",
            ],
            [
              "January 25, 2008",
              "Dr. Abdul Kalam, Former President visits Campus during TECHNOZION&nbsp; &nbsp; &nbsp; &nbsp;Honourable Minister Purandeswari, MHRD inaugurates New Computer Centre and lays foundation stone for ultra Mega Hostel",
            ],
            [
              "February 21, 2008",
              "APCOST presented Scientist of the year award to Prof. C.S.P. Rao",
            ],
            ["June 11, 2008", "SRIC MoU with IFFCO"],
            [
              "July 18, 2008",
              "11th Building &amp; Works Committee Meeting and 14th BOG Meeting at Hyderabad.",
            ],
            [
              "August 18, 2008",
              "Halls of Residence 1600 student’s capacity (Ultra-Mega Hostel) Bhoomipuja.",
            ],
            ["September 8, 2008&nbsp;&nbsp;", "MoU with ECIL, Hyderabad"],
            [
              "September 15, 2008",
              "Institution of Engineers Andhra Pradesh State Centre Presented Engineer of the year award to Prof. C.S.P. Rao",
            ],
            [
              "September 18, 2008",
              "MoU with Cincinnati University (Prof. Vemuri Ranga from Cincinnati University)",
            ],
            [
              "October 2, 2008",
              "Inauguration of Renovated Administrative Block with the State of the Art facilities and MIS.",
            ],
            [
              "October 10, 2008",
              "NIT(REC) Golden Jubilee year Celebrations – Kickoff Ceremony at Auditorium – Chief Guest Sri Ramalinga Raju, Chairman, Satyam Computers.",
            ],
            [
              "October 17 – 19, 2008",
              "TECHNOZION-08 – Chief Guest Prof. D.N. Reddy, Vice-Chancellor, JNTU, Hyderabad.",
            ],
            [
              "December 12-14, 2008",
              "23rd Indian Engineering Congress held in the Institute. (AP State Major Irrigation Minister Sri Ponnala Lakshmaiah inaugurated the Ceremony).",
            ],
            [
              "January 3-4, 2009",
              "BIO-FIESTA a two day seminar held by BIO-Tech. Dept.",
            ],
            [
              "January 9, 2009",
              "Visit of Dr. Walker, President, Lawrence Tech. University, USA., Visited the Institute and interacted with all the Deans and the Heads of Departments.",
            ],
            [
              "January 19-23, 2009",
              "10th Joint Review Mission (JRM) of TEQIP beneficiary Institutes across the country held at the Institute.",
            ],
            [
              "January 21, 2009",
              "Bhoomi Puja:- 1) Girls Hostel (350 capacity) – by Shri Ashok Thakur, Addl. Secretary, MHRD, New Delhi&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2) Campus Landscaping.",
            ],
            [
              "February 4, 2009",
              "The Director of ESIGELIC, School of Engineering, France – Prof. Cyril Martaure visited the Institute consequent to signing of MoU and interacted with all the Deans and the Heads of Departmennts",
            ],
            [
              "March 3, 2009",
              "The MHRD, New Delhi constituted CCB-2009 with Director, NIT, Warangal as its Chairman and NIT, Warangal as its Headquarters.",
            ],
            [
              "March 6, 2009",
              "Automated entry access system to Administrative Building.",
            ],
            [
              "May 13, 2009",
              "&nbsp; Director’s visit to Finland and Germany. The MoUs are signed with the following foreign Universities:1) University of Tampere, Finland.2) Tampere University of Technology, Finland&nbsp;&nbsp;",
            ],
            [
              "June 11, 2009",
              "Baba Atomic Research Centre (BARC) Board of Research in Nuclear Science (BRNS) Technical Project Discussion meeting held in the Institute.",
            ],
            [
              "June 13, 2009",
              "NIT, Warangal has been selected as a “Leading Technology Adopter of the year 2009” (It is an award for excellence in Technology Adopter in the Education Sector)",
            ],
            [
              "June 22, 2009",
              "Inauguration of INSPIRE programme by Dr. N.R. Shetty, Former Vice-Chancellor, Bangalore University.",
            ],
            [
              "July 11, 2009",
              "7th Convocation of the Institute held at 4.00 PM in the Auditorium, Padmashree Dr.B.N. Suresh, Director, Indian Institute of Space Science and Technology, Thiruvanantapuram was the Chief Guest of the function.",
            ],
            ["July 18, 2009", "Orientation programme for first year students."],
            [
              "August 12, 2009",
              "Sri Shahnawaz Quasim, Superintendent of Police, Warangal has visited the Institute to address the students about Anti Ragging.",
            ],
            [
              "August 13, 2009",
              "A seminar on “Advance in Manufacturing” conducted by Dept. of Mechanical Engineering at the Institute.",
            ],
            [
              "August 18, 2009",
              "Dr. R.L. Umashankar, Managing Director of Lakshman Chemicals Davangere Pvt Ltd. as the Chief Guest of Inaugural function of activities of the Chemical Engineering Association (ChEA) and Indian Institute of Chemical Engineering and NIT, Warangal students chapters for the year 2009-10 was held at the Institute.",
            ],
            [
              "August 24-30, 2009",
              "A faculty Development programme on “Project Management” Organized by the Institute.",
            ],
            [
              "September 19, 2009",
              "One day workshop on ‘Developing Suitable Pedagogical methods for various classes Intellectual calibers and Research in e-learning’ under National Mission Project by MHRD, GoI, New Delhi.",
            ],
            [
              "October 8, 2009",
              "4th International Alumni meet Golden Jubilee Edition.",
            ],
            [
              "October 10, 2009",
              "Foundation day of the Institute ‘Golden Jubilee Celebrations’ Padmabhushan Dr. R.A. Mashelkar, Chairman, National Innovation Foundation &amp; President, Global Research Alliance, NCL, Pune was the Chief Guest.",
            ],
            [
              "October 23, 2009",
              "Inaugural of the National Seminar on ‘Prospects and Challenges of Power Sector in Indian Context ‘CPCPS-09’ by Dept. of EEE.",
            ],
            [
              "December 16, 2009",
              "MoU signed with Malardalen University, Sweden.",
            ],
            [
              "December 28, 2009",
              "Inauguration of Dr. V.A. Sastry Innovation and Development Centre at the Institute.",
            ],
            [
              "April 11, 2010",
              "Dr. S.S. Gokhale, Director, VNIT, Nagpur has taken over charge as Director (Addl. Charge) of the Institute.",
            ],
            [
              "May 27, 2011",
              "Prof. T. Srinivasa Rao, assumed charge as the Director of the Institute. It is a happy and momentous coincidence that he is an alumnus of this Institute.",
            ],
            [
              "January 21, 2012",
              "8th Convocation of the Institute held at 3.00 PM in the Auditorium, Padmashree Prof. K.L. Chopra, Former Director, Indian Institute of Technology, Kharagpur was the Chief Guest of the function.",
            ],
            [
              "March 8, 2012",
              "Inaugural function of Spring Spree-2012 held at the Institute, Sri Ali and Sri Shiva Reddy, film actors were Chief Guests.",
            ],
            [
              "March 21, 2012",
              "18th Finance Committee and 23rd Board of Governors meeting held.",
            ],
            [
              "September 15, 2012",
              "10th Convocation was held on 15th Sept 2012, in which Dr. K. Radhakrishnan, Chairman ISRO &amp; Secretary, Dept of Space, GoI, was the Chief Guest",
            ],
            [
              "October , 2012",
              "The NIT Warangal has entered into an MoU with the Erasmus Mundus (Indo – Europe Academic Exchange Programme) Scheme, called, “HERITAGE” Programme. Eight Indian Higher Education Institutes (IHEIs) and 12 European Higher Education Institutes (EHEIs) are the major partners under this Programme",
            ],
            [
              "October 26, 2012",
              "Dr. Krishna M. Ella takes charge as the Chairman, Board of Governors, NITW",
            ],
            [
              "February 28 -&nbsp;March 1, 2013",
              "Prof. T. Srinivasa Rao, Director participated at the Progress Meeting of Heritage scheme at Ecole Centralede Nantes, France for effective collaboration and research activities by exchange programmes.",
            ],
            [
              "October 15, 2013",
              "11th Convocation was held on 15th Oct. 2013, in which Dr. Krishna M. Ella, Chairman BOG, NIT Warangal, was the Chief Guest",
            ],
            [
              "December 27-29, 2013",
              "TECHNOZION 2013 - Prof. C. Suryanarayana, Dept. of Mechanical, Materials and Aerospace Engineering, University of Central Florida, Orlanda, USA was the Chief Guest.",
            ],
            [
              "February 21-23, 2014",
              "Inaugural function of Spring Spree-2014 held at the Institute, Ms. Shobitha Dhulipala (Miss India) and Sri Anup Rubens (Music Director) were Chief Guests.",
            ],
            [
              "October 23 , 2017",
              "Prof. N.V. Ramana Rao has taken over the charge of director NIT warangal.",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616bfefe15865900231f76ac",
    time: 1641450191587,
    version: "2.22.2",
    title: "History",
    path: "/home",
    url: "/home/history",
    createdAt: "2021-10-17T10:46:22.228Z",
    updatedAt: "2022-01-06T06:21:21.240Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "9AkghmV4K7", type: "header", data: { text: "Mission", level: 4 } },
      {
        id: "eWGilSHOwp",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Imparting total quality education to develop innovative, entrepreneurial and ethical future professionals fit for globally competitive environment.",
            "Allowing stake holders to share our reservoir of experience in education and knowledge for mutual enrichment in the field of technical education.",
            "Fostering product oriented research for establishing a self-sustaining and wealth creating centre to serve the societal needs.",
          ],
        },
      },
    ],
    language: "En",
    _id: "616bff1515865900231f76ae",
    time: 1641450242504,
    version: "2.22.2",
    title: "Mission",
    path: "/home",
    url: "/home/mission",
    createdAt: "2021-10-17T10:46:45.585Z",
    updatedAt: "2022-01-06T06:22:12.110Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Jg1FUJKlcx",
        type: "header",
        data: { text: "Academic Administration", level: 4 },
      },
      {
        id: "POuAN36QrB",
        type: "header",
        data: { text: "Director", level: 5 },
      },
      {
        id: "ETbG41BMLr",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Director",
              '<a href="https://www.nitw.ac.in/main/administration/director/" target="_blank">Prof. N.V. Ramana Rao&nbsp; &nbsp;&nbsp;</a>',
              "--",
            ],
          ],
        },
      },
      { id: "Th8nGcQGtx", type: "header", data: { text: "Deans", level: 5 } },
      {
        id: "QiPw3h4nfJ",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            [
              "1.",
              "Dean(IR &amp; AA)",
              '<a href="https://nitw.ac.in/faculty/id/16228/" target="_blank">Dr. N. Subrahmanyam</a>',
              "94910 65002",
            ],
            [
              "2.",
              "Dean (Research &amp; Consultancy)",
              '<a href="https://www.nitw.ac.in/faculty/id/16378/" target="_blank">Dr. V. Rajeswar Rao</a>',
              "94901 65364",
            ],
            [
              "3.",
              "Dean(Faculty Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16260/" target="_blank">Dr. G. Amba Prasad Rao</a>',
              "94901 64973",
            ],
            [
              "4.",
              "Dean(Academic)",
              '<a href="https://nitw.ac.in/faculty/id/16389/" target="_blank">Dr. K.N.S. Kasi Viswanadham</a>',
              "94901 65361",
            ],
            [
              "5.",
              "Dean(Student Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16264/" target="_blank">Dr. P. Ravi Kumar</a>',
              "94901 65362",
            ],
            [
              "6.",
              "Dean(Planning &amp; Development)",
              '<a href="https://www.nitw.ac.in/faculty/id/16257/" target="_blank">Dr. P. Bangaru Babu</a>',
              "94901 65363",
            ],
          ],
        },
      },
      {
        id: "TlOWTjbiVJ",
        type: "header",
        data: { text: "Advisors", level: 5 },
      },
      {
        id: "yyx5WD5Lce",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            ["1.", "Advisor to the Director (Special Programmes)", "", "--"],
            [
              "2.",
              "Advisor to the Director (Corporate Relations &amp; Resource Mobilization)",
              '<a href="https://www.nitw.ac.in/faculty/id/16200/">Dr. C.S.R.K. Prasad</a>',
              "--",
            ],
            [
              "3.",
              "Advisor to the Director&nbsp;(Quality Assurance and Academic Audit)",
              '<a href="https://www.nitw.ac.in/faculty/id/16248/">Dr. S. Srinivasa Rao</a>',
              "--",
            ],
          ],
        },
      },
      { id: "0EU9lKeFo-", type: "header", data: { text: "Heads", level: 5 } },
      {
        id: "HYiDMSykGH",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            [
              "1.",
              "Head (Civil Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16209/" target="_blank">Dr. P. Rathish Kumar</a>',
              "94901 65341",
            ],
            [
              "2.",
              "Head (Electrical Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16234/" target="_blank">Dr. M. Sailaja Kumari</a>',
              "94901 65342",
            ],
            [
              "3.",
              "Head (Mechanical Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16278/" target="_blank">Dr. Adepu Kumar&nbsp;</a>',
              "94901 65343",
            ],
            [
              "4.",
              "Head (Electronic &amp; Comm. Engg)",
              '<a href="https://www.nitw.ac.in/faculty/id/16301/">Dr. P. Sreehari Rao</a>',
              "94901 65344",
            ],
            [
              "5.",
              "Head (Metallurgy &amp; Materials Engg.)",
              '<a href="https://www.nitw.ac.in/faculty/id/16922/" target="_blank">Dr. T. Mahesh Kumar</a>',
              "94901 65345",
            ],
            [
              "6.",
              "Head (Chemical Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16325/">Dr. S. Srinath</a>',
              "94901 65346",
            ],
            [
              "7.",
              "Head (Computer Science Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16934/https://www.nitw.ac.in/faculty/id/16342/" target="_blank">Dr. S. Ravi CHandra</a>',
              "94901 65347",
            ],
            [
              "8.",
              "Head (Biotechnology)",
              '<a href="https://www.nitw.ac.in/faculty/id/16351/">Dr.Korrapati Narasimhulu</a>',
              "94901 65348",
            ],
            [
              "9.",
              "Head (School of Management)",
              '<a href="https://nitw.ac.in/faculty/id/16407/">Dr. M. Ravindar Reddy</a>',
              "9490165349",
            ],
            [
              "10.",
              "Head (Mathematics)",
              '<a href="https://nitw.ac.in/faculty/id/16396/">Dr. Hari Ponnamma Rani</a>',
              "94901 65350",
            ],
            [
              "11.",
              "Head (Physics)",
              '<a href="https://www.nitw.ac.in/faculty/id/16364/" target="_blank">Dr. D. Dinakar</a>',
              "94901 65351",
            ],
            [
              "12.",
              "Head (Chemistry)",
              '<a href="https://www.nitw.ac.in/faculty/id/16382/" target="_blank">Dr. Vishnu Shanker</a>',
              "94901 65352",
            ],
            [
              "13.",
              "Head (Humanities &amp; Social Sciences)",
              '<a href="https://www.nitw.ac.in/faculty/id/16617/" target="_blank">Dr. K. Madhavi</a>',
              "94901 64798",
            ],
            [
              "14.",
              "Head (Computer Centre)",
              '<a href="https://www.nitw.ac.in/faculty/id/16307/" target="_blank">Dr. V.V. Mani</a>',
              "94901 64790",
            ],
            [
              "15.",
              "Head (Physical Education)",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Dr. P. Ravikumar</a>',
              "94910 85287",
            ],
          ],
        },
      },
      {
        id: "P4tG5GC0Yc",
        type: "header",
        data: { text: "Associate Deans", level: 5 },
      },
      {
        id: "041J_A43yF",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            [
              "1.",
              "Associate Dean (Faculty Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16277/" target="_blank">Dr. G. Naga Srinivasulu</a>',
              "9491296456",
            ],
            [
              "2.",
              "Associate Dean (Student Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16213/" target="_blank">Dr. M. Heera Lal</a>&nbsp; &nbsp;<a href="https://nitw.ac.in/faculty/id/16345/" target="_blank">Dr. R. Padmavathy</a>',
              "8332969517&nbsp; 9440173819",
            ],
            [
              "3.",
              "Associate Dean&nbsp; (Academic - Pd.D.) ",
              '<a href="https://www.nitw.ac.in/faculty/id/16283/">Dr. V. Rajesh Khana Raju</a>',
              "",
            ],
            [
              "4.",
              "Associate Dean&nbsp; (Academic - PG &amp; R)&nbsp;",
              "Dr. P. V. Suresh",
              "--",
            ],
            [
              "5.",
              "Associate Dean&nbsp; (Academic - UG)",
              "Dr. V. Hari Kumar",
              "--",
            ],
            [
              "6.",
              "Associate Dean&nbsp; (Academic Audit) &nbsp;",
              '<a href="https://www.nitw.ac.in/faculty/id/16280/">Dr. K. Srikanth</a>',
              "8332969720",
            ],
            [
              "7.",
              "Associate Dean (Planning &amp;&nbsp;Development) ",
              '<a href="https://nitw.ac.in/faculty/id/16614/">Dr. P. Venkateswara Rao</a>',
              "9491210789",
            ],
            [
              "8.",
              "Associate Dean&nbsp; (International Relations)  ",
              '<a href="https://nitw.ac.in/faculty/id/16435/">Dr.&nbsp;&nbsp;Jayakrishna</a>',
              "--",
            ],
            [
              "9.",
              "Associate Dean&nbsp; (Alumni Affairs) ",
              '<a href="https://www.nitw.ac.in/faculty/id/16367/">Dr.&nbsp;&nbsp;Abdul Azeem P</a>',
              "8332969473",
            ],
            [
              "10.",
              "Associate Dean&nbsp; (Research and Consultancy) &nbsp;",
              '<a href="https://nitw.ac.in/faculty/id/16217/">Dr. S. Venkateswara Rao</a>',
              "9491085293",
            ],
            [
              "11.",
              "Associate Dean (Library)",
              '<a href="https://www.nitw.ac.in/faculty/id/16237/" target="_blank">Dr. S. Porpandiselvi</a>',
              "9491085289",
            ],
            [
              "12.",
              "Associate Dean&nbsp; (Transportation)",
              '<a href="https://nitw.ac.in/faculty/id/16522/" target="_blank">Dr. P. Vamsi Krishna</a>',
              "8332969491",
            ],
            [
              "13.",
              "Associate Dean (Media Relations) &nbsp;",
              '<a href="https://www.nitw.ac.in/faculty/id/16419/">Dr. K. Kiran Kumar</a>',
              "9491085293",
            ],
            [
              "14.",
              "Associate Dean (Special Programmes) &nbsp;",
              '<a href="https://nitw.ac.in/faculty/id/16435/">Dr. D. Jaya Krishna</a>',
              "",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616bffc415865900231f76b5",
    time: 1643261655556,
    version: "2.22.2",
    title: "Academic",
    path: "/administration",
    url: "/administration/academic",
    createdAt: "2021-10-17T10:49:40.353Z",
    updatedAt: "2022-01-27T05:34:14.090Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Qrwv1l9uEC",
        type: "header",
        data: { text: "Admission Officer", level: 5 },
      },
      {
        id: "D7lJ72Xbm4",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Chairman (Admissions)",
              '<a href="https://www.nitw.ac.in/faculty/id/16324/">Prof. Anand Kishore Kola</a>',
              "8332969703",
            ],
            [
              "2",
              "Vice Chairman (Admissions)",
              '<a href="https://www.nitw.ac.in/faculty/id/16215/">Dr. Venkaiah Chowdary</a>',
              "--",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616bffed15865900231f76b7",
    time: 1641450699922,
    version: "2.22.2",
    title: "Admission Officer",
    path: "/administration",
    url: "/administration/admissionOfficer",
    createdAt: "2021-10-17T10:50:21.316Z",
    updatedAt: "2022-01-06T06:29:49.523Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "YD8MYxHz_X",
        type: "header",
        data: { text: "Central Administration", level: 4 },
      },
      {
        id: "NbJDr6EnTz",
        type: "header",
        data: { text: "Director", level: 5 },
      },
      {
        id: "DxipTqTwCr",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Director",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Prof. N.V. Ramana Rao</a>',
              "--",
            ],
          ],
        },
      },
      { id: "DgnfjFIA0H", type: "header", data: { text: "Deans", level: 5 } },
      {
        id: "e-Jn7762Qv",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            [
              "1",
              "Dean(IR &amp; AA)",
              '<a href="https://nitw.ac.in/faculty/id/16228/" target="_blank">Dr. N. Subrahmanyam</a>',
              "94910 65002",
            ],
            [
              "2",
              "Dean (Research &amp; Consultancy)",
              '<a href="https://www.nitw.ac.in/faculty/id/16378/" target="_blank">Dr. V. Rajeswar Rao</a>',
              "94901 65364",
            ],
            [
              "3",
              "Dean(Faculty Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16260/" target="_blank">Dr. G. Amba Prasad Rao</a>',
              "94901 64973",
            ],
            [
              "4",
              "Dean(Academic)",
              '<a href="https://nitw.ac.in/faculty/id/16389/" target="_blank">Dr. K.N.S. Kasi Viswanadham</a>',
              "94901 65361",
            ],
            [
              "5",
              "Dean(Student Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16264/" target="_blank">Dr. P. Ravi Kumar</a>',
              "94901 65362",
            ],
            [
              "6",
              "Dean(Planning &amp; Development)",
              '<a href="https://www.nitw.ac.in/faculty/id/16257/" target="_blank">Dr. P. Bangaru Babu</a>',
              "94901 65363",
            ],
          ],
        },
      },
      {
        id: "WwhhnqXSDU",
        type: "header",
        data: { text: "Registrar", level: 5 },
      },
      {
        id: "HuaWeFcpzl",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Registrar",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Sri S. Goverdhan Rao</a>',
              "",
            ],
          ],
        },
      },
      {
        id: "rL3lo13d_C",
        type: "header",
        data: { text: "Director's Office", level: 5 },
      },
      {
        id: "KaG1UUIcq8",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "PA to Director",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">S Surya Prakash</a>',
              "&nbsp;2462001",
            ],
          ],
        },
      },
      {
        id: "DYHuHKnT7o",
        type: "header",
        data: { text: "Chief Vigilance Officer", level: 5 },
      },
      {
        id: "dLj3kDkn2l",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Chief Vigilance Officer",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Sri. S. Goverdhan Rao</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Registrar',
              "Ph: 0870 2468510&nbsp; Fax: 0870 2459547 / 2459119&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Email : cvo_nitw@nitw.ac.in",
            ],
          ],
        },
      },
      {
        id: "bXnB0Rb8np",
        type: "header",
        data: { text: "Engineering &amp; &nbsp;Maintenance Unit", level: 5 },
      },
      {
        id: "Ek2v7J5wuW",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Executive Engineer",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. G. Ramesh</a>',
              "83329 69671",
            ],
            [
              "2",
              "Assistant Engineer",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. M. Sardar Singh</a>',
              "94910 65007",
            ],
            [
              "3",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. K. Ravinder</a>',
              "94910 85292",
            ],
            [
              "4",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. S. Venu Gopal</a>',
              "99893 53535",
            ],
            [
              "5",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. K. Mahendra Kumar</a>',
              "83329 69714",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c003d15865900231f76ba",
    time: 1643261701552,
    version: "2.22.2",
    title: "Central Administration",
    path: "/administration",
    url: "/administration/centralAdministration",
    createdAt: "2021-10-17T10:51:41.324Z",
    updatedAt: "2022-01-27T05:35:00.051Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "wJv-nq6cYB",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2017/10/23/Director.jpg",
          },
          caption: "Prof. N.V. Ramana Rao",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "y5i4zO5PUY",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/main/administration/director/">Prof. N.V. Ramana Rao</a>',
        },
      },
      { id: "yNjQBuIiUK", type: "paragraph", data: { text: "Chairman," } },
      {
        id: "XIToH7fgtP",
        type: "paragraph",
        data: { text: "Board of Governors," },
      },
      {
        id: "leHLOzJM36",
        type: "paragraph",
        data: { text: "National Institute of Technology, Warangal." },
      },
    ],
    language: "En",
    _id: "616c00d315865900231f76be",
    time: 1640599915232,
    version: "2.22.2",
    title: "Chairman",
    path: "/administration",
    url: "/administration/chairman",
    createdAt: "2021-10-17T10:54:11.214Z",
    updatedAt: "2021-12-27T10:11:55.387Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Qrwv1l9uEC",
        type: "header",
        data: { text: "Chief Warden", level: 5 },
      },
      {
        id: "D7lJ72Xbm4",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Chief Warden",
              '<a href="https://www.nitw.ac.in/faculty/id/16211/">Dr. T. D. Gunneswara Rao</a>',
              "94901 65356",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c00f015865900231f76c0",
    time: 1641450775284,
    version: "2.22.2",
    title: "Chief Warden",
    path: "/administration",
    url: "/administration/chiefWarden",
    createdAt: "2021-10-17T10:54:40.446Z",
    updatedAt: "2022-01-06T06:31:04.887Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Vj4-r36ig-",
        type: "header",
        data: { text: "Director's Profile", level: 5 },
      },
      {
        id: "FE37yIqI7x",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;Prof. N.V. Ramana Rao, Director, NIT&nbsp;Warangal",
        },
      },
      {
        id: "Ndn-MkbwXb",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2017/10/23/Director.jpg",
          },
          caption: "Prof. N.V. Ramana Rao",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "c-yE4bHtJi",
        type: "paragraph",
        data: {
          text: "Prof. N.V. Ramana Rao earlier worked as&nbsp;Rector &amp;&nbsp;&nbsp;Professor of Civil Engineering&nbsp;&nbsp;&nbsp; at&nbsp;&nbsp; JNTU Hyderabad before joining as the Director, NIT, Warangal.&nbsp; He did his&nbsp;BE&nbsp;in Civil Engineering from (Osmania),&nbsp;M.Tech&nbsp;from (IIT&nbsp; Delhi),&nbsp;&nbsp;&nbsp;Ph.D.&nbsp;&nbsp;and&nbsp;Post Doctorate&nbsp;from U.K. in&nbsp;&nbsp; Civil&nbsp;&nbsp;&nbsp; and Structural&nbsp; Engineering from&nbsp;University of Wales, Swansea , UK.&nbsp; He has held several &nbsp;Administrative posts&nbsp;as Registrar, JNT University Hyderabad, Kukatpally, Hyderabad from 30.04.2012 to 21.07.2015, as&nbsp;Principal, JNTUH College of Engineering Hyderabad, Kukatpally,&nbsp;Hyderabad from 30.04.2008 to&nbsp; 24.11.2011, as&nbsp;Director, Bureau of Industrial Consultancy Services, JNT University, Hyderabad from 05.09.2003 to 05.05.2006, as&nbsp;Co-ordinator, Entrepreneurship Development Cell, JNT University, Hyderabad from 01.08.2002 to 31.01.2004. &nbsp;",
        },
      },
      {
        id: "BMaLp2w5o2",
        type: "paragraph",
        data: {
          text: "He has&nbsp;Edited&nbsp;three books and published&nbsp;157&nbsp;papers in International journals/International Conferences/ National Journals/ National Conferences.&nbsp; He has&nbsp;Organized&nbsp;12 Conferences/workshops,&nbsp;&nbsp; Delivered 15 invited lectures. He has Guided more than&nbsp;60 M.Tech’s, 12 Ph.D’s&nbsp;and guiding at present several Ph.Ds.",
        },
      },
      {
        id: "CPhjQ-Hxd-",
        type: "paragraph",
        data: {
          text: "He has won&nbsp;Several Awards/ Prizes/Certificates. To name&nbsp; a few ,&nbsp;Outstanding Concrete Engineer Award for the year 2012 by A.P.&nbsp;Chapter of the Indian Concrete Institute.,&nbsp;Sir Arthur Cotton Memorial Prize&nbsp;by the Institute of Engineers (India) for the best paper in the IEI Journal, December, 2012),&nbsp;Sir Arthur Cotton Memorial Prize&nbsp;by the Institute of Engineers (India) in 2015,&nbsp;State Best Teacher Award for the year 2011&nbsp;by the Government of Andhra Pradesh.,&nbsp;Best Designer&nbsp;Award for year the 2004&nbsp;by Indian Concrete Institute,&nbsp; A.P., Hyderabad. He has been Awarded the&nbsp;1998 Commonwealth Fellowship&nbsp;to pursue Post Doctoral research at the University of Wales, Swansea. U.K.,&nbsp;Young Engineer Award for the year 1995&nbsp;by Government of Andhra Pradesh and Institution of Engineers, India, A.P. State Center. He &nbsp;has been awarded the 1989&nbsp;Commonwealth Scholarship&nbsp;to pursue PhD in Structural engineering at the&nbsp;University of Wales, Swansea, UK.",
        },
      },
      {
        id: "WKUOpHhkg1",
        type: "paragraph",
        data: {
          text: "He has been Nominated as an&nbsp;Expert Committee member constituted by the UGC for grant of Autonomous status&nbsp;to various Technical Institutions He has been a Subject Committee&nbsp;Member of Telangana State Public Service Commission.&nbsp;He is a member of&nbsp;Member of Telangana State Pollution Control Board, Expert Committee Member of&nbsp;Telangana State Power Generation Corporation. He has been the&nbsp;Convener of&nbsp; EAMCETs,&nbsp; for more than 6 years of both combined AP&nbsp; and separate Telangana States.",
        },
      },
      {
        id: "KZP0jQ8xhG",
        type: "paragraph",
        data: {
          text: "He has been a Member&nbsp;of Academic Senate, JNT University, Hyderabad,&nbsp;Past Chairman of the Indian Concrete Institute– AP Hyderabad Center,&nbsp;Past Secretary of the Indian Concrete Institute– AP Hyderabad Center,&nbsp;Executive member of the Indian Institute of Bridge Engineers. He has held Membership of&nbsp;Several Important State level Government Committees.&nbsp;He has been a&nbsp;Member of Several AICTE Inspection Committees, Board of Studies&nbsp;Chairman&nbsp;in Civil Engineering, JNTUH,&nbsp; External&nbsp;Expert Member&nbsp; of ISRO Departmental Promotion Committee (DPC. He has been a member of Several High Power Committees constituted by Govt of AP &amp; Telangana. He has been a member of the committee to frame affiliation guidelines for affiliated Engineering Colleges by Govt of Telangana.&nbsp; On the invitation from the&nbsp;President of Mauritius&nbsp;he has been a part of High level discussion on the need to engage the worlds of Art, Nature and Science in Mauritius.",
        },
      },
      {
        id: "d0hcY10n49",
        type: "paragraph",
        data: {
          text: "Prof. N.V. Ramana Rao&nbsp;took over as Director, NIT Warangal on 23.10.2017.",
        },
      },
      {
        id: "wa7UZg-QKS",
        type: "paragraph",
        data: { text: "Prof. N.V. Ramana Rao" },
      },
      { id: "UdhBABWHdy", type: "paragraph", data: { text: "Director " } },
      {
        id: "jABDvpSKFE",
        type: "paragraph",
        data: { text: "National Institute of Technology Warangal" },
      },
      { id: "YHYA26lZLM", type: "paragraph", data: { text: "Warangal," } },
      {
        id: "Ng2yyJJaj_",
        type: "paragraph",
        data: { text: "Telangana,India" },
      },
      {
        id: "JlQ6JuyCRp",
        type: "paragraph",
        data: {
          text: '<a href="mailto:director@nitw.ac.in">director@nitw.ac.in</a>',
        },
      },
      {
        id: "5I0sIhpoMC",
        type: "header",
        data: { text: "Office Details:", level: 3 },
      },
      { id: "MTbQNPBCK6", type: "paragraph", data: { text: "PA to Director" } },
      { id: "KAgWu_5lHR", type: "paragraph", data: { text: "Phone: 2462001" } },
      {
        id: "ZKqWvcQj2W",
        type: "paragraph",
        data: {
          text: '<a href="mailto:pa2dir@nitw.ac.in">pa2dir@nitw.ac.in</a>',
        },
      },
    ],
    language: "En",
    _id: "616c010d15865900231f76c2",
    time: 1641450815811,
    version: "2.22.2",
    title: "Director",
    path: "/administration",
    url: "/administration/director",
    createdAt: "2021-10-17T10:55:09.849Z",
    updatedAt: "2022-01-06T06:31:45.418Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Ob9-iKjmAD",
        type: "header",
        data: { text: "EMERGENCY CONTACT NUMBERS", level: 6 },
      },
      {
        id: "PEnf-J7E1w",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["NAME", "DESIGNATION", "CONTACT NUMBER"],
            [
              '<a href="https://nitw.ac.in/main/" target="_blank">Sri. S. Goverdhan Rao&nbsp;</a>',
              "Registrar",
              "9490165360",
            ],
            [
              '<a href="https://www.nitw.ac.in/faculty/id/16264/" target="_blank">Dr. P. Ravi Kumar</a>',
              "Dean-Student Welfare",
              "9490165362",
            ],
            [
              '<a href="https://www.nitw.ac.in/faculty/id/16211/">Dr. T. D. Gunneswara Rao</a>',
              "Chief Warden&nbsp;&nbsp;",
              "9490165356",
            ],
            [
              '<a href="https://nitw.ac.in/faculty/id/16398/">Dr. D. Bhargavi</a>',
              "Dy. Chief Warden, LH",
              "9491065948",
            ],
            [
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Dr.Chintala Karthik</a>',
              "In-charge, Medical Officer",
              "9490165359",
            ],
            ["Medical Emergency", "Institute Ambulance", "9491065006"],
            [
              '<a href="https://nitw.ac.in/main/" target="_blank">Sri. S. Goverdhan Rao&nbsp;</a>',
              "In-charge, Part-time&nbsp; &nbsp; &nbsp; &nbsp;Chief Vigilance Officer (CVO)",
              "9490165360",
            ],
            [
              '<a href="https://www.nitw.ac.in/faculty/id/16292/">Dr. B. Lakshmi</a>',
              "Woman Protection Cell",
              "8332969705",
            ],
            ["Main Gate", "24x7 Security", "8332969706"],
            ["Academic area", "Lift", "08702462099"],
            ["---", "Faculty Incharge, Security", "9490164972"],
            ["Police Station", "Kazipet&nbsp;&nbsp;", "9491089137"],
            ["Police: 100", "Fire: 101", "Medical: 108"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c013a15865900231f76c4",
    time: 1642051785399,
    version: "2.22.2",
    title: "Emergency Contact Numbers",
    path: "/administration",
    url: "/administration/emergency",
    createdAt: "2021-10-17T10:55:54.273Z",
    updatedAt: "2022-01-13T05:29:46.287Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "0bqM8YW25g",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2018/11/02/e-and-mu_Iiyot8d.jpg",
          },
          caption: "Engineering And Maintenance Unit",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "kKBUwDq_bU",
        type: "header",
        data: {
          text: "ENGINEERING AND MAINTENANCE UNIT&nbsp;::&nbsp; NIT WARANGAL&nbsp;",
          level: 6,
        },
      },
      {
        id: "02vjuynWnh",
        type: "paragraph",
        data: {
          text: "The Engineering and Maintenance Unit (E &amp; MU) is one of the important service units which play a vital role providing the requisite infrastructure facilities and all other amenities in the campus.&nbsp;",
        },
      },
      {
        id: "yNExdCXGbL",
        type: "paragraph",
        data: {
          text: "The office of&nbsp;E &amp; MU&nbsp;is responsible for construction and maintenance of buildings (Academic, Residential, Hostel, Guest House, Student Activity Centers etc;), Water Supply System, Roads, Gardens, Electrical Supply, Sports Grounds and Waste Disposal Management. The E &amp; MU Office prepares the proposals, estimates, presentation to the Buildings and Works Committee, tendering and executes the work based on departments/centers/hostels/residential requirements of the institute. The unit is also responsible for allotment of campus residences.",
        },
      },
      {
        id: "3jmOrMKxt3",
        type: "paragraph",
        data: {
          text: "The Faculty In-charge is the Head of Engineering Unit who reports to Director / Dean (P&amp;D). Under this unit, One Executive Engineer, One Assistant Engineer and Three Supervisors are working and supporting the Faculty In-charge. One Project Engineer on deputation from state government will look after the day to day activities of Engineering and Maintenance Unit.",
        },
      },
      {
        id: "4WyeTq0KZm",
        type: "header",
        data: { text: "Faculty Incharge:", level: 5 },
      },
      {
        id: "FA2AohhZ2f",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16259/">Dr. A. Neelakanteswara Rao</a>',
        },
      },
      {
        id: "CHF7HacFch",
        type: "paragraph",
        data: { text: "Associate Professor Civil Engineering Department" },
      },
      { id: "bXnB0Rb8np", type: "header", data: { text: "Staff", level: 6 } },
      {
        id: "Ek2v7J5wuW",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Executive Engineer",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. G. Ramesh</a>',
              "83329 69671",
            ],
            [
              "2",
              "Assistant Engineer",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. M. Sardar Singh</a>',
              "94910 65007",
            ],
            [
              "3",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. K. Ravinder</a>',
              "94910 85292",
            ],
            [
              "4",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. S. Venu Gopal</a>',
              "99893 53535",
            ],
            [
              "5",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. K. Mahendra Kumar</a>',
              "83329 69714",
            ],
          ],
        },
      },
      {
        id: "SY_T4fx5P6",
        type: "paragraph",
        data: {
          text: "<editorjs-style><b>Contact Details</b></editorjs-style>",
        },
      },
      {
        id: "rGjXEO3XpR",
        type: "paragraph",
        data: { text: "Phone:&nbsp; 0870 2462080" },
      },
      {
        id: "nPsh-u3ZXs",
        type: "paragraph",
        data: {
          text: '<a href="mailto:eandmu@gmail.com">E- Mail:&nbsp; &nbsp;eandmu@gmail.com</a>',
        },
      },
    ],
    language: "En",
    _id: "616c018115865900231f76c6",
    time: 1641382105139,
    version: "2.22.2",
    title: "Engineering and Maintenance Unit",
    path: "/administration",
    url: "/administration/enggAndMaintenanceUnit",
    createdAt: "2021-10-17T10:57:05.391Z",
    updatedAt: "2022-01-05T11:26:41.321Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "GzqZS9cY2r",
        type: "header",
        data: { text: "Memorandum of Understanding", level: 5 },
      },
      {
        id: "3gAjSvWgnS",
        type: "header",
        data: {
          text: "NIT Warangal has signed MOU's with the following companies.",
          level: 6,
        },
      },
      {
        id: "f85UQRaFMO",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Central Power Research Instiute, Bangalore",
            "Indian Institute of Chemical Technology, Hyderabad",
            "Sky e IT Solutions Private Limited, Hyderabad",
            "Tata Consultancy Services Limited, Hyderabad",
            "Warangal Municipal Corporation, Warangal",
            "Infosys Limited, Bangalore",
            "The LakshyaFoundaiton, Warangal",
            "Electronics Corporation of India Limited, Hyderabad",
            "Software Technology Park of India, Hyderabad",
            "Center for Development of Advanced Computing (C-DAC), Bangalore",
            "CNR, Chennai",
            "Effexort Solutions Pvt. Ltd., Hyderabad",
            "University of Malardalan, Sweden",
            "FutureNowInnosoft Pvt. Ltd., Hyderabad",
            "Helsinki University of Technology, University of Oulu, Tampere University of Technology and The University of Tampere, Finland",
            "IBM India Limited, Bangalore",
            "International Advanced Research Centre for Power Metallurgy and New Materials (ARCI), Hyderabad",
            "International Foundation for Entrepreneurship, Science and Technology (IFEST), USA",
            "Jayaprakash Narayan College of Engineering, Dharampur, Mahabubnagar",
            "National Academy of Construction (NAC), Hyderabad",
            "Nonferrous Materials Technology Development Centre, Hyderabad",
            "Research Centre Imarat (RCI), Hyderabad",
            "Tampere University of Technology, Finland",
            "Texas A&amp;M Agrilife Research University, USA",
            "Appolo Computing Laboratory Pvt. Ltd., Hyderabad",
            "Central Manufacturing Technology Institute (CMTI)",
            "Defence Metallurgical Research Laboratory (DMRL)",
            "National Institute of Technology Tiruchirapalli (NITT)",
            "Visvesvaraya National Institute of Technology, Nagpur (VNIT)",
            "National Institute of Technology, Agartala (NITA)",
            "Telangana Academy of skill and knowledge (TASK)_Institute for Electronic Governance, Telangana State",
            "Electronic Corporation of India Limited, Hyderabad",
            "Indian Institute of Technology, Madras, Chennai, India (IITM)",
            "Water and Land Management Training and Research Institute (WALMTARI)",
            "Elico Ltd., Hyderabad",
            "PEPSICO",
            "ELICO Ltd., Hyderabad (different from that of S.No.35)",
            "MNIT, Hyderabad",
            "University Corporation for Atmospheric Research",
            "Ecole Centrale de Nantes, Nantes, France",
            "Lave Consulting Services, Bangalore",
            "Sapience Consulting a Texas Instruments University Programe (Bangalore)",
            "Institute for Development and Research in Banking Technology (IDRBT)",
            "Telangana State Road Transport Corporation, Hyderabad",
            "Incube Ventures Pvt. Ltd., Ahmedabad",
            "Geo Vista Technologies Pvt. Ltd., Hyderabad",
            "Sky e IT Solutions(P) Ltd., Hyderabad (License agreement)",
            "Sri MangaduKamatchiammam Business Corporation (INFOMAPS), Chennai",
            "Info Trans Engineers Private Limited (ITE), Hyderabad",
            "BHEL for Research &amp; Development, New Delhi",
            "Electro Optical Instruments Research Academy DRDO, Ministry of Defence (MoD) (ELoira), Hyderabad",
            "Nucleonix Systems Pvt. Ltd., Hyderabad",
            "South Ural State University Chelyafinsk, Russia",
            "Affine Tech Systems Pvt. Ltd., Hyderabad",
            "iBuild innovations India Ltd.,Hyderabad",
            "Survey of India, New Delhi",
            "Institute for Solid Waste Research and Ecological Balance(INSWAREB), Visakhapatnam",
            "CUSMAT Technologies, Hyderabad",
            "FLOWHREX Technologies, Pune",
            "AMD India Pvt Ltd., Hyderabad",
            "Neuland Laboratories Limited, Hyderabad",
            "Research on Advanced Biomedical Solutions Pvt Ltd., Hyderabad",
            "Blusapphire Cyber Systems Pvt Ltd., Hyderabad",
            "Engineering Staff College of India (ESCI), Hyderabad",
            "Exseed Space Innovations Pvt Ltd., Mumbai",
            "Eternal Green Innovations Pvt Ltd, Hyderabad",
            "Engineering Council of India, New Delhi",
            "Hexagon Capability Center India Pvt Ltd., Hyderabad",
            "Telangana State Council of Science &amp; Technology (TSCOST), Govt Telangana, Hyderabad",
            "National Council for Cement and Building Materials, Hyderabad",
            "Indian Institute of Packaging, Mumbai",
          ],
        },
      },
      {
        id: "OQwhgg7D9O",
        type: "header",
        data: { text: "Professor In-charge, MoUs", level: 6 },
      },
      {
        id: "h589n6cdKn",
        type: "paragraph",
        data: {
          text: "1. &nbsp;MoUs for International Partnerships :&nbsp;&nbsp;",
        },
      },
      {
        id: "I7qzFeip4K",
        type: "paragraph",
        data: {
          text: '2. &nbsp;MoUs for National Partnerships :&nbsp; &nbsp;<a href="https://www.nitw.ac.in/faculty/id/16297/">Dr. P.Sreehari Rao</a>',
        },
      },
    ],
    language: "En",
    _id: "616c01b115865900231f76c8",
    time: 1641381977317,
    version: "2.22.2",
    title: "Memorandum Of Understanding",
    path: "/administration",
    url: "/administration/mou",
    createdAt: "2021-10-17T10:57:53.840Z",
    updatedAt: "2022-01-05T11:24:33.508Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "45ZLV9RoQt",
        type: "header",
        data: { text: "ABOUT THE NITs COUNCIL", level: 6 },
      },
      {
        id: "qq_UlSYyjP",
        type: "paragraph",
        data: {
          text: "The&nbsp;NIT Council&nbsp;is the supreme governing body of India's National Institutes of Technology (NIT) system. As like the IIT Council and modeled very much similarly to its IIT counterpart the NIT Council consists of chairmen, directors of all NITs along with the government nominees from various sectors with the Minister of HRD, Govt. of India as the Chairman of the Council. The NIT Council is the highest decision making body in the NIT fraternity and is answerable only to the Government of India. The NIT Council is expected to meet regularly and take steps conducive for maximum growth of the NITs as whole in the near future. (Refer Section 30 to 34 of the NITs Act, 2007).",
        },
      },
      {
        id: "ZsJRGPM2Nt",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "The NIT Council is created under the NIT Act 2007 and its present composition stands as follows:",
            "The Minister in charge of the Ministry or Department of the Central Government having administrative control of the technical education, ex officio-Chairman",
            "The Secretary to the Government of India in charge of the Ministry or Department of the Central Government having administrative control of the technical &nbsp;education, ex officio-&nbsp;Vice-Chairman.",
            "The Chairperson of every Board, ex officio",
            "The Director of every Institute, ex officio",
            "The Chairman, University Grants Commission, ex officio",
            "The Director General, Council of Scientific and Industrial Research, ex officio",
            "Four Secretaries to the Government of India, to represent the Ministries or Departments of the Central Government dealing with biotechnology, atomic &nbsp;energy, information technology and space, ex officio",
            "The Chairman, All India Council for Technical Education, ex officio",
            "Not less tha n three, but not more than five persons to be nominated by the Visitor, at least one of whom shall be a woman, having special knowledge or &nbsp;experience in respect of education, industry, science or technology",
            "Three members of Parliament, of whom two shall be chosen by the House of the People and one by the Council of States (Provided that the office of &nbsp;member of the Council shall not disqualify its holder for being chosen as or for being, a member of either House of Parliament)",
            "Two Secretaries to the State Government, from amongst the Ministries or Departments of that Government dealing with technical education where the &nbsp;Institutes are located, ex officio",
            "Financial Advisor, dealing with the Human Resource Development Ministry or Department of the Central Government, ex officio",
            "One officer not below the rank of Joint Secretary to the Government of India in the Ministry or Department of Central Government having administrative &nbsp;control of the Technical Education, ex officio-Member-Secretary.",
          ],
        },
      },
      {
        id: "xwkYDsL2Js",
        type: "header",
        data: { text: "TERMS OF THE NIT COUNCIL", level: 6 },
      },
      {
        id: "_I7Xn57-ME",
        type: "paragraph",
        data: {
          text: "The duration of office of a member is three years from the date of notification provided that the term of office of an ex officio member shall continue so long as he holds office by virtue of which he is such a member and shall expire as soon as he ceases to be Member of the House, which elected him. The term of a member nominated or elected to fill a causal vacancy shall continue for the remainder of the term of the member in whose place he has been appointed and an outgoing member shall, unless the Central Government otherwise directs, continue in office until another person is appointed as a member in his place.",
        },
      },
      {
        id: "JEEPS5buvE",
        type: "header",
        data: { text: "FUNCTIONS OF NITs COUNCIL", level: 6 },
      },
      {
        id: "NUM0WYvH88",
        type: "paragraph",
        data: {
          text: "It shall be the general duty of the Council to co-ordinate the activities of all the NITs without prejudice. The Council shall perform the following functions, namely:-",
        },
      },
      {
        id: "GiWplz625L",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To advise on matters relating to the duration of the courses, the degrees and other academic distinctions to be conferred by the individual NITs, admission &nbsp;standards and other academic matters",
            "To lay down policy regarding cadres, methods of recruitment and conditions of service of employees, institution of scholarships and freeships, levying of fees &nbsp;and other matters of common interest",
            "To examine the development plans of each NIT and to approve such of them as are considered necessary and also to indicate broadly the financial implications &nbsp;of such approved plans",
            "To advise the Visitor, if so required, in respect of any function to be performed by him under the NIT Act",
            "To perform such other functions as are assigned to it by or under the NIT Act.",
          ],
        },
      },
    ],
    language: "En",
    _id: "616c01d815865900231f76ca",
    time: 1641381882110,
    version: "2.22.2",
    title: "NIT Warangal Council",
    path: "/administration",
    url: "/administration/nitwCouncil",
    createdAt: "2021-10-17T10:58:32.468Z",
    updatedAt: "2022-01-05T11:22:58.293Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Qrwv1l9uEC",
        type: "header",
        data: { text: "Registrar", level: 5 },
      },
      {
        id: "D7lJ72Xbm4",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            ["1", "Registrar", "Sri S. Goverdhan Rao", "--"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c020215865900231f76cc",
    time: 1641450864988,
    version: "2.22.2",
    title: "Registrar",
    path: "/administration",
    url: "/administration/registrar",
    createdAt: "2021-10-17T10:59:14.961Z",
    updatedAt: "2022-01-06T06:32:34.593Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "urWNdRTCrB",
        type: "header",
        data: { text: "Right to Information", level: 3 },
      },
      {
        id: "2OcERamF7e",
        type: "header",
        data: {
          text: '<a href="http://daa.nitw.ac.in:8081/path/?dept=/rti2021">Suo Moto Disclosure&nbsp;</a>&nbsp;- <a href="http://daa.nitw.ac.in:8081/path/?dept=/rti2021">Click here</a>',
          level: 5,
        },
      },
      {
        id: "1dgM4UZFyK",
        type: "paragraph",
        data: {
          text: '&nbsp;<a href="http://172.20.0.4/nitw/index.php/administration/right-to-information/85">Home</a>',
        },
      },
      {
        id: "TVU_1tqN8s",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/organization_chart_2022-0-19-12-16-12.pdf`>Organization Chart</a>',
        },
      },
      {
        id: "JWi-T5VSZp",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Application_under_RTI_2022-0-19-12-17-28.pdf`>Application under RTI</a>',
        },
      },
      {
        id: "QS9WjyKzR2",
        type: "paragraph",
        data: {
          text: "<editorjs-style>This area of National Institute of Technology, Warangal website is for disseminating information unde</editorjs-style>r the Right to Information Act 2005.",
        },
      },
      {
        id: "jG11WZ-Ww_",
        type: "paragraph",
        data: {
          text: '<a href="http://daa.nitw.ac.in:8081/page/?url=/home/boardOfGovernors">Board of Governors</a>',
        },
      },
      {
        id: "aPmN8bXyPo",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Public_Information_Officers_&amp;_Appellate_Authority_2022-0-19-12-18-24.pdf`>Public Information Officers &amp; Appellate Authority</a>',
        },
      },
      {
        id: "3EhJLj07qO",
        type: "paragraph",
        data: {
          text: '<a href="http://daa.nitw.ac.in:8081/page/?url=/home/committee">Finance Committee</a><br><a href="http://daa.nitw.ac.in:8081/page/?url=/home/buildingAndWorksCommittee">Building &amp; Works Committee</a>',
        },
      },
      {
        id: "DQGZtF8Xyn",
        type: "paragraph",
        data: {
          text: '<a href="http://daa.nitw.ac.in:8081/page/?url=/administration/senate/">Senators</a>',
        },
      },
      {
        id: "N1lxZpAZGl",
        type: "header",
        data: { text: "PROCEDURE FOR FILING OF APPLICATION", level: 6 },
      },
      {
        id: "u9OzQgm9Ii",
        type: "paragraph",
        data: {
          text: "A person, who desires to obtain any information under RTI Act 2005 can be submit an application to the Public Information Officer (PIO),&nbsp;National Institute of Technology, Warangal – 506 004. (Andhra Pradesh)",
        },
      },
      {
        id: "lZD5iUIcqD",
        type: "header",
        data: { text: "FORMAT OF APPLICATION", level: 6 },
      },
      {
        id: "HCDu4HyJtC",
        type: "paragraph",
        data: {
          text: "The applicant should download the application form from the website and clearly mention all particulars his/her NAME &amp; ADDRESS for&nbsp;correspondence, Telephone/Mobile No. and specific information which he/she wants, if practicable, with No. &amp; Date etc.",
        },
      },
      { id: "08yDkCh6xK", type: "header", data: { text: "FEE", level: 6 } },
      {
        id: "FSAUN4znpF",
        type: "paragraph",
        data: {
          text: "For providing the information under sub-section (1) of section 6 shall be accompanied by an application fee of Rs. 10/- by way of&nbsp;cash&nbsp;against proper receipt or by DD/BC/PO in&nbsp;favour&nbsp;of the&nbsp;Director, N.I.T., Warangal.",
        },
      },
      {
        id: "VgHWhrj1Ei",
        type: "paragraph",
        data: {
          text: "For providing the information under sub-section (1) of Section 7, the fee shall be chargeable rates :",
        },
      },
      {
        id: "qpVt1b2r4e",
        type: "paragraph",
        data: {
          text: "i) Rupees two for each page (in A4 or A3 size paper) created or copied;",
        },
      },
      {
        id: "yQci72mNJ1",
        type: "paragraph",
        data: {
          text: "ii) Actual charge or cost price of a copy in larger size paper;",
        },
      },
      {
        id: "NFlTEavmI_",
        type: "paragraph",
        data: { text: "iii) Actual cost or price for samples or models; and" },
      },
      {
        id: "8HffVipoSx",
        type: "paragraph",
        data: {
          text: "iv) For inspection of records, no fee for the first hour, and a fee of rupees five for each sub-section.",
        },
      },
      { id: "2aVMc0ZGzO", type: "header", data: { text: "GENERAL", level: 6 } },
      {
        id: "nX6rZSpXI6",
        type: "paragraph",
        data: {
          text: 'The Institute had already launched a registered website viz. http://<a href="http://172.20.0.4/">www.nitw.ac.in</a>&nbsp;wherein detailed information regarding all matters&nbsp;relating to Administration, Alumni, Departments, Opportunities, Placements, Tenders, TEQIP, Staff, Directory, Syllabus &amp; Students&nbsp;Mess Bill. The website may be seen for further details.',
        },
      },
      {
        id: "MYTNpO-L9N",
        type: "paragraph",
        data: {
          text: '1<a href="https://nitw.ac.in/media/pdfs2/pdfs/Powers%20and%20Duties%20of%20Officers.pdf">.&nbsp;Powers &amp; Duties of Officers and Employees</a>',
        },
      },
      {
        id: "B5WSIiyw-H",
        type: "paragraph",
        data: {
          text: "2. Decision making process (including channels of supervision and accountability)",
        },
      },
      {
        id: "wErQ1381vq",
        type: "paragraph",
        data: { text: "3. Norms for Discharge of Functions" },
      },
      {
        id: "OEt2miKBnA",
        type: "paragraph",
        data: {
          text: "4. Rules, Regulations, Instructions, Manuals and records used by employees for discharging functions",
        },
      },
      {
        id: "bSy55IVJig",
        type: "paragraph",
        data: {
          text: "5. Categories of documents held or under control, list of Committees.",
        },
      },
      {
        id: "Y0nTDD_aNu",
        type: "paragraph",
        data: { text: "6. Formulation of policy or implementation of thereof" },
      },
      {
        id: "oLY8BrU6oD",
        type: "paragraph",
        data: {
          text: '7.&nbsp;<a href="https://nitw.ac.in/media/pdfs2/pdfs/Budget%20Allocation.pdf">Budget allocations (All Plans, Proposed expenditure and reports on disbursements made)</a>',
        },
      },
      {
        id: "41U9EJ3kk7",
        type: "paragraph",
        data: {
          text: "8. Execution of Subsidy&nbsp;programmes&nbsp;(including amounts allocated, details and beneficiaries)",
        },
      },
      {
        id: "jS7Zl6Bwfh",
        type: "paragraph",
        data: {
          text: "9. Recipients of concessions, permits of authorizations granted",
        },
      },
      {
        id: "Ey-a-fvDtR",
        type: "paragraph",
        data: {
          text: 'i)&nbsp;<a href="https://nitw.ac.in/media/pdfs2/pdfs/BC-Scholarships.pdf">BC Scholarships</a>&nbsp;ii)&nbsp;<a href="https://nitw.ac.in/media/pdfs2/pdfs/RTI/SC-Scholarships.pdf">SC Scholarships</a>&nbsp;iii)&nbsp;<a href="https://nitw.ac.in/media/uploads/2017/10/22/st-scholarships.pdf">ST Scholarships</a>&nbsp;iv)&nbsp;<a href="https://nitw.ac.in/media/pdfs2/pdfs/Scholarships-Other%20State.pdf">Other State&nbsp;Scholarships</a>',
        },
      },
      {
        id: "EzqveFLDSV",
        type: "paragraph",
        data: {
          text: "10. Monthly remuneration of Faculty, Officers and Employees and system of compensation:",
        },
      },
      {
        id: "o9dSVRC_f8",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2021/07/09/higher-to-lower-ranked-employees-working-at-nit-warangal-for-the-purpose-of-proactive-disclosure-under-rti-act-2005.docx">Pay scales of various cadres of the employees</a>',
        },
      },
      {
        id: "VzmdJTxPN5",
        type: "paragraph",
        data: { text: "11. Directory of Faculty, Officers and Employees" },
      },
      {
        id: "pLypdMHUEJ",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/">Telecom Directory (Intercom)</a>',
        },
      },
      {
        id: "JmBPEbP0Ao",
        type: "paragraph",
        data: {
          text: '12.&nbsp;<a href="https://nitw.ac.in/media/pdfs2/pdfs/RTI/Statutory_Policies.pdf">Statutory Policies</a>',
        },
      },
      {
        id: "bypEXE4L6v",
        type: "paragraph",
        data: {
          text: '13.&nbsp;<a href="https://www.nitw.ac.in/main/administration/emergency/">Emergency Contact Numbers</a>',
        },
      },
      {
        id: "39jVU2bYHM",
        type: "header",
        data: { text: "Minutes of Governing Body:", level: 6 },
      },
      {
        id: "u_Ff23ZkbT",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/15thBOG.pdf">15th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/16thBOG.pdf">16th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/17thBOG.pdf">17th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/18thBOG.pdf">18th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/19thBOG.pdf">19th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/20bog-v1.pdf">20th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/21bog-v1.pdf">21st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/22bog-v1.pdf">22nd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/23bog-v1.pdf">23rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/24bog-v1.pdf">24th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/25bog-v1.pdf">25th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/26bog-v1.pdf">26th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2017/10/22/27th-bog-minutes.pdf">27th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/10/22/28th-bog-minutes.pdf">28th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/10/22/29th-bog-minutes.pdf">29th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/BOG/30th%20BoG%20Minutes.pdf">30th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/31-bog.pdf">31st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/32-bog.pdf">32nd&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/33-bog.pdf">33rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/34-bog.pdf">34th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/35-bog.pdf">35th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/36th-bog.pdf">36th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/37th-bog.pdf">37th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/38th-bog.pdf">38th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/39th-bog.pdf">39th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/40th-41st-bog.pdf">40th&nbsp;&amp; 41st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/42nd-bog.pdf">42nd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/12/10/43-bog-minutes.pdf">43rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/12/10/44-bog-minutes.pdf">44th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/12/10/45-bog-minutes.pdf">45th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2019/07/19/minutes-of-46th-bog.pdf">46th&nbsp;&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/07/19/47-bog-minutes.pdf">47th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/02/26/minutes-of-48th-bog-meeting.pdf">48th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/02/26/mnts-of-49-bog-meeting.pdf">49th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/02/26/minutes-of-50th-bog-meeting.pdf">50th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/02/26/minutes-of-50a-bog-meeting.pdf">50A&nbsp; meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2020/12/18/minutes-of-51st-bog-meeting-held-on-10012020.pdf">51st Meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/07/29/minutes-of-52nd-bog-meeting-held-on-20082020-website.pdf">52nd&nbsp;meeting</a>',
              "",
              "",
              "",
              "",
            ],
          ],
        },
      },
      { id: "UFrOf8rnbe", type: "paragraph", data: { text: "<br>" } },
      {
        id: "Jddp9a-z8h",
        type: "header",
        data: { text: "Minutes of Finance Committee:", level: 6 },
      },
      { id: "zaen36anl5", type: "paragraph", data: { text: "<br>" } },
      {
        id: "HbljkpmEGR",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/10thFC.pdf">10th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/11thFC.pdf">11th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/12thFC.pdf">12th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/13thFC.pdf">13th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/14thFC.pdf">14th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/15fc-v1.pdf">15th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/16fc-v1.pdf">16th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/17fc-v1.pdf">17th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/18fc-v1.pdf">18th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/19fc-v1.pdf">19th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/20fc-v1.pdf">20th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/21fc-v1.pdf">21st&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/22nd%20FC%20Minutes.pdf">22nd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/23rd%20FC%20Minutes.pdf">23rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/24th%20FC%20Minutes.pdf">24th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/fc/25th%20FC%20Minutes.pdf">25th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/26-fc.pdf">26th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/27-fc.pdf">27th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/28-fc.pdf">28th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/29-fc.pdf">29th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/30th-fc.pdf">30th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/31st-fc.pdf">31st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/32nd-fc.pdf">32nd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/12/10/33-fc-minutes.pdf">33rd&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2018/12/10/34-fc-minutes.pdf">34th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/12/10/35-fc-minutes.pdf">35th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/07/19/minutes-of-36th-fc.pdf">36th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/07/19/37-fc-minutes.pdf">37th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/02/26/minutes-of-38th-fc-meeting.pdf">38th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/02/26/mnts-of-39-fc-meeting.pdf">39th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2020/02/26/minutes-of-39a-fc-meeting.pdf">39A&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/12/18/minutes-of-40th-fc-meeting-held-on-10012020.pdf">40th Meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/07/29/minutes-of-41st-fc-meeting-held-on-20082020.pdf">41st&nbsp;meeting</a>',
              "",
              "",
              "",
            ],
          ],
        },
      },
      {
        id: "F8pmdRgOW2",
        type: "header",
        data: { text: "Minutes of Buildings &amp; Works Committee:", level: 6 },
      },
      { id: "CLamnHiiaI", type: "paragraph", data: { text: "<br>" } },
      {
        id: "DrOdigGdBR",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/12thBWC.pdf">12th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/13thBWC.pdf">13th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/14thBWC.pdf">14th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/15thBWC.pdf">15th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/16bwc-v1.pdf">16th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/17bwc-v1.pdf">17th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/18bwc-v1.pdf">18th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/19bwc-v1.pdf">19th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/20bwc-v1.pdf">20th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/21bwc-v1.pdf">21st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/22nd%20BWC%20Minutes.pdf">22nd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/23rd%20BWC%20Minutes.pdf">23rd&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/bwc/24th%20BWC%20Minutes.pdf">24th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/25-bwc.pdf">25th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/26-bwc.pdf">26th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/27-bwc.pdf">27th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/28th-bwc.pdf">28</a><a href="https://www.nitw.ac.in/media/uploads/2018/05/28/28th-bwc.pdf">th</a><a href="https://www.nitw.ac.in/media/uploads/2018/05/28/28th-bwc.pdf">&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/29th-bwc.pdf">29th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2018/05/28/30th-bwc.pdf">30th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/12/10/31-bwc-minutes.pdf">31st&nbsp;meeting</a>',
              "",
              "",
              "",
              "",
            ],
          ],
        },
      },
      {
        id: "UZTEkMOKwu",
        type: "header",
        data: { text: "Minutes of Senate:&nbsp;N.I.T. Warangal", level: 6 },
      },
      { id: "ckjkopdagT", type: "paragraph", data: { text: "<br>" } },
      {
        id: "LI6sZoSbYn",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/43rd%20Senate.pdf">43rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/44th%20Senate.pdf">44th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/45th%20Senate.pdf">45th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/46th%20Senate.pdf">46th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/47th%20Senate.pdf">47th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/48senate.pdf">48th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/49senate.pdf">49th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/50senate.pdf">50th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/51senate.pdf">51st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/52senate.pdf">52nd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/53senate.pdf">53rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/54senate.pdf">54th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/55senate.pdf">55th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/56senate.pdf">56th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/57senate.pdf">57th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/58senate.pdf">58th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/59senate.pdf">59th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/60senate.pdf">60th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/61senate.pdf">61st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/62senate.pdf">62nd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/63senate.pdf">63rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/RTI/senate/64th%20Senate%20meeting%20minutes.pdf" target="_blank">64th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/65th-senate-minutes.pdf">65th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/66th-senate-minutes.pdf">66th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/67th-senate-minutes.pdf">67th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/68th-senate-minutes.pdf">68th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/10/22/69th-senate-minutes.pdf">69th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/70th-senate-minutes.pdf">70th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/71st-senate-minutes.pdf">71st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/72nd-senate-minutes.pdf">72nd&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2017/10/22/73rd-senate-minutes.pdf">73rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/74th-senate-minutes.pdf">74th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/75th-senate-minutes.pdf">75th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/76th-emergency-senate-minutes.pdf">76th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/77th-senate-minutes.pdf">77th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/78th-senate-minutes.pdf">78th meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2016/09/29/79th-senate-minutes.pdf">79th&nbsp;meeting&nbsp; &nbsp;</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/06/26/nitw-minutes-of-the-80th-meeting-of-the-senate.pdf">80th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/06/27/nitw-minutes-of-the-81st-meeting-of-the-senate.pdf">81st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/12/04/82nd-senate-minutes.pdf">82nd meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/12/12/83-senate.pdf">83rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/12/12/84-senate.pdf">84</a><a href="https://www.nitw.ac.in/media/uploads/2017/12/12/84-senate.pdf">th</a><a href="https://www.nitw.ac.in/media/uploads/2017/12/12/84-senate.pdf">&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2017/12/12/85-senate.pdf">85th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/12/13/86.pdf">86th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/12/13/87.pdf">87th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2017/12/13/88.pdf">88th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/05/89th-senate-minutes-meetings.pdf">89th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/05/90th-senate-minutes.pdf">90th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/06/91st-senate-meeting_FPoP3FR.pdf">91st&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/11/08/minutes-of-the-92-meeting-of-the-senate-held.pdf">92nd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/08/30/rotated-nitw-93rd-senate-minutes.pdf">93rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/08/30/rotated-minutes-of-94th-meeting.pdf">94</a><a href="https://www.nitw.ac.in/media/uploads/2018/08/30/rotated-minutes-of-94th-meeting.pdf">th</a><a href="https://www.nitw.ac.in/media/uploads/2018/08/30/rotated-minutes-of-94th-meeting.pdf">&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/01/14/95-th-senate-meeting-minutes.pdf">95</a><a href="https://www.nitw.ac.in/media/uploads/2019/01/14/95-th-senate-meeting-minutes.pdf">th</a><a href="https://www.nitw.ac.in/media/uploads/2019/01/14/95-th-senate-meeting-minutes.pdf">&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/01/14/95-th-senate-meeting-minutes.pdf">96</a><a href="https://www.nitw.ac.in/media/uploads/2019/02/13/96th-minutes-meeting.pdf">th</a><a href="https://www.nitw.ac.in/media/uploads/2019/02/13/96th-minutes-meeting.pdf">&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2019/01/14/95-th-senate-meeting-minutes.pdf">97</a><a href="https://www.nitw.ac.in/media/uploads/2019/02/13/96th-minutes-meeting.pdf">th</a><a href="https://www.nitw.ac.in/media/uploads/2019/04/30/minutes-of-97th-meeting-of-the-senate-april-30_VmAFrIj.pdf">&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/05/28/minutes-of-98th-senate-meeting-rotated.pdf">98th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/08/02/99th-minutes_rotated.pdf">99th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/08/02/100th-minutes_rotated.pdf">100th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/10/12/101-minutes-rotated.pdf">101th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2019/12/11/minutes-of-the-102nd-meeting-rotated.pdf">102nd&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2020/01/02/103-minutes-rotated.pdf">103rd&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/11/12/minutes-of-the-104th-senate-meeting.pdf">104th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/11/12/minutes-of-the-105th-senate-meeting.pdf">105th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/11/12/minutes-of-the-106th-senate-meeting.pdf">106th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/11/12/minutes-of-the-107th-senate-meeting.pdf">107th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/11/12/minutes-of-the-108th-senate-meeting.pdf">108th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2020/11/12/minutes-of-the-109th-senate-meeting.pdf">109th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2020/11/12/minutes-of-the-110th-senate-meeting.pdf">110th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/02/10/minutes-of-the-111th-senate-meeting.pdf">111th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/02/10/minutes-of-112th-senate-meeting.pdf">112th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/07/20/minutes-of-the-113th-senate-meeting.pdf">113th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/07/20/minutes-of-the-114th-senate-meeting.pdf">114th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2021/07/20/minutes-of-the-115th-senate-meeting.pdf">115th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/17/minutes-of-the-116th-senate-meeting.pdf">116th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/11/19/minutes-of-the-117th-senate-meeting.pdf">117th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/11/19/minutes-of-the-118th-senate-meeting.pdf">118th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/19/minutes-of-119th-senate-meeting.pdf">119th&nbsp;meeting</a>',
              "",
            ],
          ],
        },
      },
      { id: "Lz7weMmW70", type: "paragraph", data: { text: "<br>" } },
      {
        id: "sIol9Cu-gh",
        type: "header",
        data: { text: "Minutes of Senate: NITAP", level: 6 },
      },
      { id: "ZMBpAZkbpU", type: "paragraph", data: { text: "<br>" } },
      {
        id: "cGVVfVy9iz",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-1st-senate-minutes.pdf">1</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-1st-senate-minutes.pdf">st</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-1st-senate-minutes.pdf">&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-2nd-senate-minutes.pdf">2</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-2nd-senate-minutes.pdf">nd</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-2nd-senate-minutes.pdf">&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-3rd-senate-minutes.pdf">3</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-3rd-senate-minutes.pdf">rd</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-3rd-senate-minutes.pdf">&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-minutes-of-the-4th-meeting-of-the-senate_ty8dcgn.pdf">4</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-minutes-of-the-4th-meeting-of-the-senate_ty8dcgn.pdf">th</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/nit-ap-minutes-of-the-4th-meeting-of-the-senate_ty8dcgn.pdf">&nbsp;&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/06/5th-meeting.pdf">5</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/5th-meeting.pdf">th</a><a href="https://www.nitw.ac.in/media/uploads/2018/02/06/5th-meeting.pdf">&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/06/6th-senate-minutes.pdf">6th&nbsp;meeting</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/media/uploads/2018/02/06/7th-senate-meeing.pdf">7th&nbsp;meeting</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2018/11/17/minutes-of-the-8th-meeting-of-the-nit-ap.pdf">8th&nbsp;meeting</a>',
              "",
              "",
              "",
              "",
            ],
          ],
        },
      },
      {
        id: "S1v0XG0VCR",
        type: "header",
        data: {
          text: "Facilities available to citizens for obtaining information (Library / Reading Room):",
          level: 6,
        },
      },
      {
        id: "n6TIU3vsXK",
        type: "paragraph",
        data: {
          text: "Library is only for registered users (students, faculty &amp; staff) and not general public use.",
        },
      },
      {
        id: "s5haTST0pE",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/Library_Info.pdf">Library Information</a>',
          ],
        },
      },
      {
        id: "6jceXPEmxb",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/academics/rules">Rules &amp; Regulations for all courses:</a>',
          ],
        },
      },
      {
        id: "Dyw-JGCsFM",
        type: "list",
        data: { style: "unordered", items: ["Prospectus &amp; Courses"] },
      },
      {
        id: "2TvU2Z_ONX",
        type: "list",
        data: { style: "unordered", items: ["Convocation"] },
      },
      {
        id: "xH5QLhUPc7",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/NITW%20MoA.pdf">Memorandum of Association</a>',
          ],
        },
      },
      {
        id: "XfKARhGOlT",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/NIT%20Act-2007-new1.pdf">NIT-Act, (No.29 of 2007)</a>',
          ],
        },
      },
      {
        id: "bYPKzIeVRm",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2017/10/22/statutes-new1.pdf">First Statutes of NITs</a>',
          ],
        },
      },
      { id: "D8bnUvRWPZ", type: "paragraph", data: { text: "<br>" } },
      {
        id: "7n7jccMbRR",
        type: "paragraph",
        data: {
          text: "<b>Sri. S. Goverdhan Rao</b>&nbsp;,&nbsp;Registrar,&nbsp;N.I.T. Warangal&nbsp; is appointed as In-charge Part-time Chief Vigilance Officer (CVO) of the Institute.&nbsp;All matters related to the CVO may be addressed to the above Officer&nbsp;&nbsp;<br>",
        },
      },
      { id: "cOvDTkd7Jx", type: "paragraph", data: { text: "<br>" } },
      { id: "3ybtfG9_WC", type: "paragraph", data: { text: "<br>" } },
      { id: "rQHsoDjW8e", type: "paragraph", data: { text: "<br>" } },
      { id: "6iOe7VR-vD", type: "paragraph", data: { text: "<br>" } },
      { id: "bF7AXnqhFa", type: "paragraph", data: { text: "<br>" } },
      { id: "aYZnHsNGG_", type: "paragraph", data: { text: "<br>" } },
      { id: "UQ08V_u_qK", type: "paragraph", data: { text: "<br>" } },
      { id: "Bnrj_QcE3V", type: "paragraph", data: { text: "<br>" } },
      { id: "nThboo2a0h", type: "paragraph", data: { text: "<br>" } },
      { id: "mLTgxaXNiT", type: "paragraph", data: { text: "<br>" } },
      { id: "gmVP9C_N-X", type: "paragraph", data: { text: "<br>" } },
      { id: "2AFt7MtcL3", type: "paragraph", data: { text: "<br>" } },
      { id: "FJCSM45llP", type: "paragraph", data: { text: "<br>" } },
      { id: "2Yw6iKVTg7", type: "paragraph", data: { text: "<br>" } },
      { id: "dqOV51rqta", type: "paragraph", data: { text: "<br>" } },
      { id: "AXYHFR8PAO", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "616c022815865900231f76ce",
    time: 1642576784287,
    version: "2.22.2",
    title: "Right To Information",
    path: "/administration",
    url: "/administration/rightToInformation",
    createdAt: "2021-10-17T10:59:52.372Z",
    updatedAt: "2022-01-19T07:19:43.351Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "jAUCuT0LLU", type: "header", data: { text: "Senate", level: 6 } },
      {
        id: "NolmOztr5r",
        type: "paragraph",
        data: {
          text: "As per Section 14 of the NITs Act, 1007, the Senate of every Institute shall consist of the following persons, namely:",
        },
      },
      {
        id: "CdrpbjMYV8",
        type: "paragraph",
        data: {
          text: "(a) the Director, ex-officio, who shall be the Chairman of the Senate;",
        },
      },
      {
        id: "soigAneGo5",
        type: "paragraph",
        data: { text: "(b) the Deputy Director, ex-officio;" },
      },
      {
        id: "5xcA0-2wTl",
        type: "paragraph",
        data: {
          text: "(c) the Professors appointed or recognized as such by the Institute for the purpose of imparting instructions in the Institute;",
        },
      },
      {
        id: "ECbfIPB4mP",
        type: "paragraph",
        data: {
          text: "(d) three persons, one of whom shall be woman, not being employees of the Institute, to be nominated by the Chairperson in consultation with the Director, from amongst educationalists of repute, one each from the field of science, engineering and humanities; and",
        },
      },
      {
        id: "lVgu97P59u",
        type: "paragraph",
        data: {
          text: "(e) such other members of the staff as may be laid down in the Statutes.",
        },
      },
      {
        id: "P7tz4fcE-H",
        type: "header",
        data: {
          text: "DETAILS OF THE CHAIRMAN AND THE EXTERNAL MEMBERS OF SENATE OF NATIONAL INSTITUTE OF TECHNOLOGY, WARANGAL",
          level: 6,
        },
      },
      {
        id: "bQl-2XDkgy",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "S.NO",
              "SECTION",
              "NAME, DES. &amp; ADDRESS",
              "POSITION HELD",
              "E-MAIL ID, PHONE &amp; FAX NOS",
            ],
            [
              "1",
              "14 (a)",
              '<a href="https://www.nitw.ac.in/main/administration/director/"></a><editorjs-style><b><a href="https://www.nitw.ac.in/main/administration/director/">Prof. N.V. Ramana Rao</a>&nbsp;</b></editorjs-style> Director,&nbsp; National Institute of&nbsp;Technology,&nbsp;Warangal - 506 004',
              "Chairman",
              '<editorjs-style style="">director@nitw.ac.in</editorjs-style>',
            ],
            [
              "2",
              "14 (d)",
              '<a href="https://www.nitw.ac.in/main/Academic%20Administration/nitw/" target="_blank"></a><editorjs-style><editorjs-style><b><a href="https://www.nitw.ac.in/main/Academic%20Administration/nitw/" target="_blank">Prof. Sujata Patel</a>&nbsp;</b></editorjs-style></editorjs-style>\n\nPresident, Indian Sociological Society (2016-17) Professor, Department of Sociology, University of Hyderabad,&nbsp; Hyderabad\n\n',
              "External Member",
              '<editorjs-style style="">Patel.sujata09@gmail.com</editorjs-style>',
            ],
            [
              "3",
              "14 (d)",
              '<b><a href="https://www.nitw.ac.in/main/Academic%20Administration/nitw/">Prof. O.R. Jaiswal</a>&nbsp;</b> &nbsp; &nbsp; &nbsp;Dept of Applied Mechanics, VNIT, Nagpur\n\n',
              "External Member",
              "<editorjs-style>orjaiswal@apm.vnit.ac.in</editorjs-style>",
            ],
            [
              "4",
              "14(d)",
              '<b><a href="https://www.nitw.ac.in/main/Academic%20Administration/nitw/">Prof. P.S. Roy</a>&nbsp;</b>&nbsp;\n\nNASI Senior Scientist Platinum Jubilee, Fellow Center for Earth &amp; Space Sciences University of Hyderabad,&nbsp; Hyderabad\n\n',
              "External Member",
              "Psroy13@gmail.com",
            ],
          ],
        },
      },
      {
        id: "tlAV3souZs",
        type: "paragraph",
        data: {
          text: "Section 14 of the National Institutes of Technology Act, 2007 (Act 29 of 2007).",
        },
      },
      {
        id: "R-DJqCHvyc",
        type: "header",
        data: { text: "SENATE - FUNCTIONS &amp; POWERS&nbsp;:", level: 6 },
      },
      {
        id: "uOQfjwVJOA",
        type: "paragraph",
        data: {
          text: "(A)&nbsp;FUNCTIONS: The Senate of an Institute shall have the control and general regulation, and be responsible for the maintenance of standards of instruction, education and examination in the Institute and shall exercise such other powers and perform such other duties as may be conferred or imposed upon it by the Statutes.",
        },
      },
      {
        id: "FVXFaC2FWV",
        type: "paragraph",
        data: {
          text: "(B)&nbsp;POWERS: The Senate shall have the powers to:-",
        },
      },
      {
        id: "xteP3SKlFH",
        type: "paragraph",
        data: {
          text: "(i) frame and revise curricula and syllabi for the course of studies for the various Departments and Centres;",
        },
      },
      {
        id: "Y-BRMgXV0W",
        type: "paragraph",
        data: {
          text: "(ii) make arrangements for the conduct of examinations, appointment of examiners, moderators, tabulators and other matters relating to the examinations;",
        },
      },
      {
        id: "LvAGn7-LDs",
        type: "paragraph",
        data: {
          text: "(iii) declare the results of the examinations or to appoint Committees or Officers to do so and to make recommendations to the Board regarding conferment or grant of degrees, diplomas and other academic distinctions or titles;",
        },
      },
      {
        id: "HXwZa4k1dL",
        type: "paragraph",
        data: {
          text: "(iv) appoint Advisory Committees or Expert Committees or both for the Departments or Centres of the Institute to make recommendations on academic matters connected with the working of the Departments or Centres;",
        },
      },
      {
        id: "i5Kyo5iQtp",
        type: "paragraph",
        data: {
          text: "(v) appoint Committees from amongst the members of the Senate, other teachers of the Institute and experts from outside to advise on such specific and important academic matters as may be referred to any such Committee by the Senate.",
        },
      },
      {
        id: "g83nOn156h",
        type: "paragraph",
        data: {
          text: "(vi) consider the recommendations of the Advisory Committees attached to various Departments or Centres and that of Experts and other Committees and take such action (including the making of recommendations to the Board) as warranted by each case)",
        },
      },
      {
        id: "3GzpdPqtxH",
        type: "paragraph",
        data: {
          text: "(vii) make periodical review of the activities of the Departments or Centres and take appropriate action (including the making of recommendations to the Board);",
        },
      },
      {
        id: "Tb3Y7RMrlV",
        type: "paragraph",
        data: {
          text: "(viii) supervise the working of the Library of the Institute.",
        },
      },
      {
        id: "C0I8_xiztB",
        type: "paragraph",
        data: {
          text: "(ix) promote research and academic development or activity within the Institute and seek reports on such research or academic development or activity from the persons engaged therein;",
        },
      },
      {
        id: "ANOnpmtyt6",
        type: "paragraph",
        data: {
          text: "(x) provide for the inspection of the class rooms, Laboratories, Library and the Residential Hostels;",
        },
      },
      {
        id: "60RSp9_VHo",
        type: "paragraph",
        data: {
          text: "(xi) plan co-curricular activities of the students of the Institute.",
        },
      },
      {
        id: "mPNIa6stx6",
        type: "paragraph",
        data: {
          text: "(xii) award stipends, scholarships, medals and prizes and make other awards in accordance with such conditions as may be attached to the awards;",
        },
      },
      {
        id: "83LpL6S_sI",
        type: "paragraph",
        data: {
          text: "(xiii) make recommendations to the Board with regard to the creation or restructuring of Departments or Programmes or Centres and the abolition of existing Departments or centres thereof;",
        },
      },
      {
        id: "ceuyLDGDVn",
        type: "paragraph",
        data: {
          text: "(xiv) make recommendations to the Board of disseminate knowledge through distance learning mode to various parts of the State or country or abroad; and",
        },
      },
      {
        id: "XEnS5P2Qa_",
        type: "paragraph",
        data: {
          text: "(xv) invite upto two student representatives during discussion of general nature nor involving policy or disciplinary matters in the Senate meetings.",
        },
      },
      {
        id: "XEcCGfk0lu",
        type: "header",
        data: {
          text: "CHAIRMAN OF THE SENATE TO EXERCISE POWERS IN EMERGENCY&nbsp;:",
          level: 6,
        },
      },
      {
        id: "0nEFgC0C2c",
        type: "paragraph",
        data: {
          text: "If, in the opinion of the Chairman of the Senate, any emergency has arise which requires immediate action, he may take such action as he deems necessary and shall report the same for approval to the Senate in its next meeting.",
        },
      },
    ],
    language: "En",
    _id: "616c026515865900231f76d0",
    time: 1642405545944,
    version: "2.22.2",
    title: "Senate",
    path: "/administration",
    url: "/administration/senate",
    createdAt: "2021-10-17T11:00:53.175Z",
    updatedAt: "2022-01-17T07:45:46.970Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Qrwv1l9uEC",
        type: "header",
        data: { text: "Website Administration", level: 4 },
      },
      {
        id: "kcxSWahATY",
        type: "header",
        data: { text: "Website In-Charge", level: 6 },
      },
      {
        id: "rB-h0ct1Vs",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16878/" target="_blank">Dr. M. Srinivas</a>',
        },
      },
      {
        id: "xmwW2nkptL",
        type: "paragraph",
        data: { text: "Assistant Professor" },
      },
      {
        id: "ObsFtKG6av",
        type: "paragraph",
        data: { text: "Department of Computer Science And Engineering" },
      },
      {
        id: "fBEvSO_f_9",
        type: "paragraph",
        data: {
          text: "Phone:&nbsp;+91-8897064421E-mail:&nbsp;&nbsp;institutewebsite@nitw.ac.in",
        },
      },
      { id: "nSjopqMcAD", type: "header", data: { text: "WSDC", level: 6 } },
      {
        id: "RAMmBFGwiJ",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.4/nitw/index.php/component/content/article/580" target="_blank">NITW Web and Software Development Cell</a>',
        },
      },
    ],
    language: "En",
    _id: "616c028315865900231f76d2",
    time: 1641447853482,
    version: "2.22.2",
    title: "Website Administration",
    path: "/administration",
    url: "/administration/websiteAdministration",
    createdAt: "2021-10-17T11:01:23.070Z",
    updatedAt: "2022-01-06T05:42:23.102Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Areas of Research", level: 5 },
      },
      {
        id: "HDN5BLThnE",
        type: "paragraph",
        data: {
          text: 'Department Wise Research Interests&nbsp;- <a href="https://drive.google.com/drive/folders/1Q4fDYkAZ7D9aarrzkM83aWUoorI4WWWC">Click here</a>',
        },
      },
      {
        id: "ltTkhD4V1Q",
        type: "paragraph",
        data: {
          text: 'Faculty Wise Research Interests&nbsp;- <a href="https://drive.google.com/file/d/14czwqY96iWXA1qeXPXJUbKzSl6FwyFFS/view">Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "616c2d35a684840023b6ef83",
    time: 1641453170053,
    version: "2.22.2",
    title: "Areas of Research",
    url: "/rnd/areasOfResearch",
    path: "/rnd",
    createdAt: "2021-10-17T14:03:33.488Z",
    updatedAt: "2022-01-06T07:10:59.666Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "ExzM8m1_gK",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Department_In_Research_2021-11-27-15-1-28.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "616c3002a684840023b6ef88",
    time: 1641453039988,
    version: "2.22.2",
    title: "Department In Research",
    url: "/rnd/departmentInREsearch",
    path: "/rnd",
    createdAt: "2021-10-17T14:15:30.498Z",
    updatedAt: "2022-01-06T07:08:49.599Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Research (Ph.D.) Scholars", level: 5 },
      },
      {
        id: "wgqHaXAXX4",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Year", "Registered", "Awarded"],
            ["2013-14", "292", "41"],
            ["2014-15", "378", "46"],
            ["2015-16", "444", "52"],
            ["2016-17", "", ""],
            ["2017-18", "", ""],
            ["2018-19", "", ""],
            ["2019-20", "", ""],
            ["2020-21", "*", "*"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c3188a684840023b6ef8e",
    time: 1641453210199,
    version: "2.22.2",
    title: "Research (Ph.D.) Scholars",
    url: "/rnd/phd",
    path: "/rnd",
    createdAt: "2021-10-17T14:22:00.738Z",
    updatedAt: "2022-01-06T07:11:39.804Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: {
          text: "Mini Centres of Excellences in various Departments at NIT Warangal",
          level: 5,
        },
      },
      {
        id: "YkpbopFMcF",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "Sr. No.",
              "Name of the&nbsp;mini Centre of Excellence",
              "Department",
            ],
            [
              "1",
              "Centre for Advanced&nbsp;Geospatial Solutions",
              "Civil Engineering",
            ],
            [
              "2",
              "Centre for Sustainable Environment Management",
              "Civil Engineering",
            ],
            [
              "3",
              "Centre for Manufacturing&nbsp;Advanced Materials",
              "Mechanical Engineering",
            ],
            [
              "4",
              "Centre for Development of Electronics Systems",
              "Electronics and Communication Engineering",
            ],
            [
              "5",
              "Centre for&nbsp;Sonoprocess Engg. for nanomaterials",
              "Chemical Engineering",
            ],
            [
              "6",
              "Centre for Big Data",
              "Computer Science and&nbsp;Engineering",
            ],
          ],
        },
      },
      {
        id: "jXiMKZslNr",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Research_Centers_2021-11-27-15-13-21.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "616c33daa684840023b6ef93",
    time: 1641453257402,
    version: "2.22.2",
    title: "Research Centres",
    url: "/rnd/researchCentres",
    path: "/rnd",
    createdAt: "2021-10-17T14:31:54.093Z",
    updatedAt: "2022-01-06T07:12:27.010Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Research Presentations", level: 5 },
      },
      {
        id: "K1SWNf_iP4",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Year", "International", "National"],
            ["2013-14", "146", "61"],
            ["2014-15", "204", "91"],
            ["2015-16", "244", "93"],
            ["2016-17", "", ""],
            ["2017-18", "", ""],
            ["2018-19", "", ""],
            ["2019-20", "", ""],
            ["2020-21", "*", "*"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c3934a684840023b6ef9a",
    time: 1641453287547,
    version: "2.22.2",
    title: "Research Presentations",
    url: "/rnd/researchPresentations",
    path: "/rnd",
    createdAt: "2021-10-17T14:54:44.736Z",
    updatedAt: "2022-01-06T07:12:57.154Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Research Publications", level: 5 },
      },
      {
        id: "CCArwpprqN",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Year", "International", "National"],
            ["2013-14", "276", "45"],
            ["2014-15", "315", "30"],
            ["2015-16", "293", "58"],
            ["2016-17", "", ""],
            ["2017-18", "", ""],
            ["2018-19", "", ""],
            ["2019-20", "", ""],
            ["2020-21", "*", "*"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c3a77a684840023b6ef9f",
    time: 1641453312856,
    version: "2.22.2",
    title: "Research Publications",
    url: "/rnd/researchPublications",
    path: "/rnd",
    createdAt: "2021-10-17T15:00:07.562Z",
    updatedAt: "2022-01-06T07:13:22.465Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Research Publications", level: 2 },
      },
      {
        id: "CCArwpprqN",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Year", "International", "National"],
            ["2013-14", "276", "45"],
            ["2014-15", "315", "30"],
            ["2015-16", "293", "58"],
            ["2016-17", "", ""],
            ["2017-18", "", ""],
            ["2018-19", "", ""],
            ["2019-20", "", ""],
            ["2020-21", "*", "*"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c3a7ba684840023b6efa1",
    time: 1634482808541,
    version: "2.22.2",
    title: "Research Publications",
    url: "/rnd/researchPublications",
    path: "/rnd",
    createdAt: "2021-10-17T15:00:11.036Z",
    updatedAt: "2021-10-17T15:00:11.036Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Total Research Funds", level: 5 },
      },
      {
        id: "EzDaMIeJZn",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Department", "Total", "Average"],
            ["Civil Engg", "652.09", "69.47"],
            ["Metallurgical Engg.", "637.13", "55.89"],
            ["Chemical Engg.", "467.74", "74.39"],
            ["Mechanical Engg.", "374.16", "55.96"],
            ["Bio Tech Engg.", "253.42", "70.81"],
            ["Computer Science Engg.", "175.14", "22"],
          ],
        },
      },
    ],
    language: "En",
    _id: "616c3e53a684840023b6efa7",
    time: 1641453333260,
    version: "2.22.2",
    title: "Total Research Funds",
    url: "/rnd/totalResearchFunds",
    path: "/rnd",
    createdAt: "2021-10-17T15:16:35.069Z",
    updatedAt: "2022-01-06T07:13:42.868Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "3oM5CIsN6B",
        type: "header",
        data: { text: "Central Workshop", level: 3 },
      },
      {
        id: "NdUhpR7JHt",
        type: "paragraph",
        data: {
          text: "The&nbsp;central workshop,&nbsp;a central facility, was merged with the Department of Mechanical Engineering in the year 1992. The Workshop consisting of Fitting Shop, Welding shop, Carpentry shop, Power Tools shop, Foundry Shop, Machine Shop and House wiring shop.",
        },
      },
      {
        id: "jimMmBn3S1",
        type: "paragraph",
        data: {
          text: "The facilities are used for the conduction of regular sessional classes as a part of the curriculum for students at various levels pertaining to different departments. In addition to housing many conventional machines and equipment it also has most modern fabrication and production units in tune with the recent technological developments. Some of these include&nbsp;Electro Chemical Machine, EDM, Tig and Mig welding machines, Tool and cutter Grinding Machine, Mitutoyo Tool Makers Microscope, Kistler 3 component force measurement Dynamometer, Variable Speed Precision Lathe, Tool Monitoring Equipment during machining and CNC Engraving Machine etc.",
        },
      },
      {
        id: "6ooTui90N2",
        type: "paragraph",
        data: {
          text: "Steps are afoot for further modernization of the central workshop utilizing the funds made available through MHRD / MODROBS / TEQIP etc.",
        },
      },
      {
        id: "ZI4CKZ0V_x",
        type: "paragraph",
        data: {
          text: "Workshop facilities are extended to facilitate execution&nbsp;of different projects / research work at UG, PG and Doctoral level, under the guidance of the respective guides.",
        },
      },
      {
        id: "We8iv47PDu",
        type: "paragraph",
        data: {
          text: "Some of the facilities available in the Central Workshops.",
        },
      },
      { id: "9px3GhSqOn", type: "header", data: { text: "Images", level: 3 } },
      {
        id: "YGhiF2N4qy",
        type: "header",
        data: { text: "Design Engg. Labs", level: 3 },
      },
      {
        id: "8HIDGW6Raj",
        type: "header",
        data: {
          text: "Computational Research Laboratory and CAD Lab",
          level: 5,
        },
      },
      {
        id: "gsQiFxiwFF",
        type: "paragraph",
        data: { text: "Location:&nbsp;Room No: B-301" },
      },
      {
        id: "YWBbbcXEzu",
        type: "paragraph",
        data: {
          text: 'Faculty In-charges:&nbsp;<a href="http://www.nitw.ac.in/nitw/div/view.php?facultyid=16420" target="_blank">Dr. Hari Kumar V</a>oruganti&nbsp;and&nbsp;Dr. P. Suresh',
        },
      },
      {
        id: "fY-KxarQs_",
        type: "paragraph",
        data: {
          text: 'Contact:&nbsp;&nbsp;Email:&nbsp;<a href="mailto:harikumar@nitw.ac.in">harikumar@nitw.ac.in</a>',
        },
      },
      {
        id: "_fMVRJbmXm",
        type: "paragraph",
        data: {
          text: "Laboratory support:&nbsp; Teaching assistantship by PhD scholars and PG students.",
        },
      },
      {
        id: "_ww5-_BFL4",
        type: "paragraph",
        data: {
          text: "Objective:&nbsp;To facilitate the smooth running of lab course of UG and PG that require computational resource and also project works of the students and faculty.",
        },
      },
      {
        id: "ZK2oq0isKk",
        type: "paragraph",
        data: {
          text: "Lab Coverage:&nbsp;Modeling, Simulation and Analysis: Solid Modeling, Programming, DIN/ISO programming, CNC training.",
        },
      },
      {
        id: "dM5Jkyv9ZB",
        type: "paragraph",
        data: {
          text: "Services Offered to: The lab is used for the needs of PG students and research.&nbsp;",
        },
      },
      {
        id: "1pnmf1AC60",
        type: "paragraph",
        data: { text: "During 2018-19 (I Sem):" },
      },
      {
        id: "p_OdWKbAsg",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "1st&nbsp; Year M. Tech. (Automobile Engineering)– 1st&nbsp; Semester :&nbsp;CAD Laboratory (ME5406)",
            "1st&nbsp; Year M. Tech. (Automobile Engineering)– 1st&nbsp; Semester :&nbsp;Computational Laboratory (ME5503)",
            "1st&nbsp; Year M. Tech. (Machine Design)– 1st&nbsp; Semester :&nbsp;Numerical Simulation Laboratory (ME5405)",
            "1st&nbsp; Year M. Tech. (Machine Design)– 1st&nbsp; Semester :&nbsp;CAD Laboratory (ME5406)",
            "1st&nbsp; Year M. Tech. (Additive Manufacturing)– 1st&nbsp; Semester :&nbsp;CAD/CAM Laboratory (ME5703)",
            "1st&nbsp; Year M. Tech. (Additive Manufacturing)– 1st&nbsp; Semester :&nbsp;Rapid Prototyping Laboratory (ME5704)",
            "1st&nbsp; Year M. Tech. (Manufacturing Engineering)– 1st&nbsp; Semester :&nbsp;CAD Laboratory (ME5406)",
            "1st&nbsp; Year M. Tech. (Computer Integrated Manufacturing)– 1st&nbsp; Semester :&nbsp;CAD Laboratory (ME5406)",
            "M.Tech. Dissertation Works",
            "Ph.D. Works",
          ],
        },
      },
      {
        id: "1tnqch9B9i",
        type: "paragraph",
        data: { text: "<b>Details of computer hardware facilities</b>" },
      },
      {
        id: "an0m2xsHyC",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "High performance Computing Server – 1 No",
              "Operating system- : Windows server 2013 with media , Model- ML 350 G8P ,&nbsp;Processor:  2No. Intel Xeon E5-2650 @ 2.0 Ghz, 8-core, 20MB ,&nbsp;&nbsp;RAM- 128 GB DDR3 ,&nbsp;Hard disk- 4 TB HDD ,&nbsp;No of system-AMD FirePro W2100 (FireGL V) ,&nbsp;Graphic card: 2GB",
            ],
            [
              "Workstation (HP Make) – 1No",
              "Operating system- Windows 7 Professional ,&nbsp;Model- HP Z 440 ,&nbsp;Processor: Intel Xeon E5-1620 v3 @3.5 GHz ,&nbsp;RAM- 32 GB ,&nbsp;Harddisk-2 TB ,&nbsp;System type- 64 bit operating system ,&nbsp;Graphic card: AMD FirePro W2100",
            ],
            [
              "Workstation (HP Make) – 1 No",
              "Operating system- Windows 7 ,&nbsp;Model- HP Z 220 ,&nbsp;Processor: Intel Xeon E3-1225 ,&nbsp;RAM- 16 GB DDR3 ,&nbsp;Harddisk-2 TB ,&nbsp;System type- 64 bit operating system ,&nbsp;Graphic card: 1GB Nvidia Quadro 600",
            ],
            [
              "Workstation (Dell Make) – 1No",
              "Operating system- Windows 8.1 ,&nbsp;Model- DELL Poweedge T320 ,&nbsp;Processor: Intel Xeon ,&nbsp;RAM- 16 GB DDR3 ,&nbsp;Harddisk-1 TB, 7200 rpm ,&nbsp;System type- 64 bit operating system ,&nbsp;Graphic card: 1GB Nvidia",
            ],
            [
              "Desktop Computers (Dell Make) – 30 Nos.",
              "Operating system- Windows 8.1 ,&nbsp;Dell- OPTIPLEX 7010 ,&nbsp;Processor –Intel core i7 ,&nbsp;RAM- 4 GB ,&nbsp;Harddisk-500 GB ,&nbsp;System type- 32 bit operating system",
            ],
            [
              "Desktop Computers (HP Make) – 16 Nos",
              "Operating system- Windows 7 Professional ,&nbsp;Model- HP Compaq Elite 8300 MT ,&nbsp;Processor –Intel core i5 ,&nbsp;RAM- 2 GB ,&nbsp;Harddisk-500 GB ,&nbsp;System type- 32 bit operating system",
            ],
            [
              "Projector",
              "EPSON EB-SO3 ,&nbsp;Projection System: 3LCD Technology ,&nbsp;LCD Panel: 0.55 inch ,&nbsp;Resolution: SVGA, 800 x 600, 4:3",
            ],
            [
              "Amplifier",
              "Portable Wireless Amplifier- Focus ,&nbsp;Model- WPA- 775UH",
            ],
          ],
        },
      },
      {
        id: "luoyhFQm7e",
        type: "paragraph",
        data: { text: "<b>&nbsp;Softwares Available</b>" },
      },
      {
        id: "3NTOH4qmX9",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "Mathcad",
              "Version- MathCAD Education University Edition ,&nbsp;No of Licenses- 25",
            ],
            [
              "Design Expert",
              "Version - Design Expert 9 ,&nbsp;No of Licenses- 20",
            ],
            ["Creo", "Version - Creo Parametric 2.0 ,&nbsp;No of Licenses- 50"],
            ["LS- Dyna", "Version- LS-DYNA 4.2 ,&nbsp;No of Licenses- 32"],
            ["ANSYS", "Version- ANSYS 15.0 ,&nbsp;No of Licenses- 50"],
            ["MATLAB", "Version – MATLAB 2015a ,&nbsp;No of Licenses- 5"],
            ["Operating Systems", "Ubuntu Linux ,&nbsp;Windows 7 and Windows"],
            ["FlexSim", "Version 7.5&nbsp;"],
            ["EdgeCAM", "CNC simulation"],
            ["VEL CNC", "CNC simulation"],
          ],
        },
      },
      {
        id: "4Pzvj38anE",
        type: "header",
        data: { text: "Kinematics Lab", level: 5 },
      },
      {
        id: "RINH1l8MJd",
        type: "paragraph",
        data: {
          text: 'Kinematics Lab Information&nbsp;[<a href="https://drive.google.com/open?id=0B5VjI8YmohsMZWlMMU1hcHBoNVk&amp;authuser=0">pdf</a>]',
        },
      },
      {
        id: "KE4CW_ANxQ",
        type: "header",
        data: { text: "Machine Dynamics Lab", level: 5 },
      },
      {
        id: "Um-FlQ45ar",
        type: "paragraph",
        data: {
          text: 'Lab Information [<a href="https://drive.google.com/open?id=0B5VjI8YmohsMZWlMMU1hcHBoNVk&amp;authuser=0">pdf</a>]',
        },
      },
      {
        id: "d_9Q1lMfrE",
        type: "paragraph",
        data: {
          text: "This laboratory is mainly to give a practical exposure to the students who are indeed first step of planning theory of vibrations. Most of the experimental setup are being fabricated by the students only. The student is made to physically experience basic concepts such as normal mode vibrations, natural frequency, mode shapes, beat phenomenon, different types of damping.",
        },
      },
      {
        id: "U1kUr1oR2Q",
        type: "paragraph",
        data: {
          text: "There are equipment such as Electrodynamic exciter, Portable vibration analyzer which enables the student to do some advanced experiments.",
        },
      },
      {
        id: "Fmi_JyTddV",
        type: "paragraph",
        data: {
          text: "Apart from the experiments on vibrations the lab is also equipped with rotor balancing machine, twin cylinder locomotive balancing machine, gyroscope demonstration, portable balancing machine. It is planned to procure sophisticated experimental setups in the coming years.",
        },
      },
      {
        id: "vc1wJhbnv6",
        type: "header",
        data: { text: "Production Engg. Labs", level: 3 },
      },
    ],
    language: "En",
    _id: "616e4da71f00cb905bc71c73",
    time: 1634622350328,
    version: "2.22.2",
    title: "Labs",
    url: "/me/labs",
    path: "/me",
    createdAt: "2021-10-19T04:46:31.480Z",
    updatedAt: "2021-10-19T05:45:51.880Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "ElX0o33YyS",
        type: "header",
        data: { text: "What is Biotechnology Engineering?", level: 3 },
      },
      {
        id: "JHshp8CvqT",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Biotechnology engineering&nbsp;is a branch of engineering where technology is combined with biology for research &amp; development. There are various applications of biotechnology in fields such as animal husbandry, growth of vaccines and medicines, agriculture, pollution control, energy production and conservation, healing of prolonged disease and ecological conservation. It also helps in development of insecticides, fertilizers and quality of seeds. A candidate who specializes in this field is known as a biotechnologist or Bioprocess Engineer. There are lots of career opportunities in this field for young aspirants both in India as well as in aboard.",
            "Best 10 Biotechnology Companies In India In 2021:",
            'See the link:&nbsp;<a href="https://www.inventiva.co.in/trends/ishikasharma/biotechnology-companies/">https://www.inventiva.co.in/trends/ishikasharma/biotechnology-companies/</a>',
          ],
        },
      },
      {
        id: "WsJy5aAPB2",
        type: "header",
        data: { text: "OUR VISION AND MISSION", level: 3 },
      },
      {
        id: "R6Xda4JagO",
        type: "header",
        data: { text: "VISION OF THE DEPARTMENT:", level: 5 },
      },
      {
        id: "H2aTm40t3m",
        type: "paragraph",
        data: {
          text: "Through its faculty, Department of Bio-Technology is a driving force.",
        },
      },
      {
        id: "UpTf7BHbhz",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To apply engineering knowledge and create novel technologies that improve the human condition by optimizing opportunities for further training and a successful transition to a career in the biotechnology and life sciences.",
            "To initiate a level of attitude in research that will provide solutions to environmental, industrial, agricultural and health based problems.",
            "To graduate students who can compete, with advantage, within the relevant academic and commercial markets in the world.&nbsp;",
          ],
        },
      },
      {
        id: "-soBR89BBF",
        type: "header",
        data: { text: "MISSION OF THE DEPARTMENT:&nbsp;", level: 5 },
      },
      {
        id: "OzMCl0XZzE",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To offer a comprehensive exploration of basic science, applied science, and lab science with an industry focus and to act collectively like a enzyme in enhancing, improving and supporting interdisciplinary research and training.",
            "To provide a quality education in pursuit of knowledge, that establishes a strong foundation for understanding developments in the rapidly advancing field of biotechnology with the development of new biochemical technologies and therapies through research and education.",
            "To provide the nation with highly trained professional who are able to implement the scientific principles to the continuous improvement of the safe, quality and value biotechnological services and products.",
          ],
        },
      },
    ],
    language: "En",
    _id: "6171217c9be7b97996d45957",
    time: 1634804107720,
    version: "2.22.2",
    title: "Home",
    url: "/bt/home",
    path: "/bt",
    createdAt: "2021-10-21T08:14:52.487Z",
    updatedAt: "2021-10-21T08:15:10.367Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "tx2u_s-7RS", type: "header", data: { text: "Btech", level: 3 } },
      {
        id: "T56oNNAo77",
        type: "header",
        data: { text: "Introduction:", level: 5 },
      },
      {
        id: "kgbhyAj0N8",
        type: "paragraph",
        data: {
          text: "The study of Biotechnology is a blend of subjects related Biology and subjects like Mathematics, Physics, Chemistry and Engineering. A simple definition of Biotechnology is that it uses the properties of living organisms to produce desired products. The Department was started in the year 2006 with an under-graduate programme in B.Tech with an intake of 60 students. Presently the intake is 92 students. Curriculum of B.Tech. degree programme in Biotechnology is at par with that of the best Institutions in the country. Industrial visits are included. Departmental core subjects, departmental electives, basic science core subjects and electives, basic engineering core subjects and electives humanities and social sciences core subjects and electives are included in the curriculum. It is a flexible curriculum and choice based credit system. Every student has to carry out an independent project in the IV year.",
        },
      },
      {
        id: "UwVpEC3qti",
        type: "header",
        data: { text: "Curriculum", level: 5 },
      },
      {
        id: "Hf94Qbyj5P",
        type: "paragraph",
        data: {
          text: "Curriculum of B.Tech. degree programme in Biotechnology is at par with that of the best Institutions in the country.Industrial visits are included. Departmental core subjects, departmental electives, basic science core subjects and electives, basic engineering core subjects and electives humanities and social sciences core subjects and electives are included in the curriculum. It is a flexible curriculum and choice based credit system. Every student has to carryout an independent project in the IV year.",
        },
      },
      {
        id: "3AIC29rVj0",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2022/01/02/biotech-btech-tt.pdf">EVEN SEMESTER ACADEMIC YEAR (2022 -2023)</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61713df82b005d79da21afad",
    time: 1642831088259,
    version: "2.22.2",
    title: "Academics",
    url: "/bt/academics",
    path: "/bt",
    createdAt: "2021-10-21T10:16:24.895Z",
    updatedAt: "2022-01-22T05:58:03.894Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Euphrates", level: 2 },
      },
      {
        id: "6qr97eOpgX",
        type: "paragraph",
        data: { text: "Dear colleagues and students," },
      },
      {
        id: "jF0pazl9uX",
        type: "paragraph",
        data: {
          text: "Our\n Institution is a member of the European Union for Promotion of Health \nthrough Research, Applied Technology, Education and Science (EUPHRATES) \nin India Project which offers <b>140</b> scholarships for nobilities to Europe!\n This is a unique opportunity for you to carry out your studies \n(Undergraduates, Master, PhD and Post Doc between 1 semester and a full \nprogramme), reasearch or teaching abroad. The scholarship covers \nsubsistence (1000 EUR to 2500 EUR/month), travel costs and insurance.",
        },
      },
      {
        id: "eclV4uifme",
        type: "paragraph",
        data: {
          text: "Our NIT Warangal has entered into an MoU with <b>12 Europian Higher Education Institutes.&nbsp;</b>viz.,",
        },
      },
      {
        id: "5wPiDk0ZNu",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="http://www.univ-perp.fr/en/index.html" target="_blank">University of Perpignan, France</a>',
            '<a href="http://www.international.fu-berlin.de/" target="_blank">Freie Universitat Berlin, Germany</a>',
            '<a href="http://www.teipat.gr/" target="_blank">Technological Educational Institute of Patras, Greece</a>',
            '<a href="http://www.ucd.ie/" target="_blank">University of College Dublin, National University of Ireland, Ireland</a>',
            '<a href="http://www.hu-berlin.de/?set_language=en&amp;cl=en" target="_blank">Vilnius University, Lithuania</a>',
            '<a href="http://www.sggw.pl/?lang=en" target="_blank">Warsaw University of Life Science, Poland</a>',
            '<a href="http://sigarra.up.pt/up/pt/web_page.inicial" target="_blank">Universidade do porto, Portugal</a>',
            '<a href="http://www.um.si/Strani/default.aspx" target="_blank">University of Maribor, Faculty of Mechanical Engineering, Slovenia</a>',
            '<a href="http://revistas.usc.es/gcompostela/en/index.html" target="_blank">CGU, Spain</a>',
            '<a href="http://www.casadelaindia.org/indianet/cm" target="_blank">Fundacion Casa de la India, Spain</a>',
            '<a href="http://www.universia.es/index.htm" target="_blank">Portal University, S.A., Spain</a>',
          ],
        },
      },
      {
        id: "-IMiPcfdN6",
        type: "paragraph",
        data: { text: "and 12 other Indian Institutes, viz.," },
      },
      {
        id: "ZRxi_vDI-y",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="http://www.iitb.ac.in/" target="_blank">Indian Institute of Technology Bombay, Mumbai</a>',
            '<a href="http://www.cuo.ac.in/" target="_blank">Central University of Orissa, Koraput</a>',
            '<a href="http://www.doonuniversity.ac.in/" target="_blank">Doon University, Dehradun</a>',
            '<a href="http://www.bamu.ac.in/" target="_blank">Dr Babasaheb Ambedkar Marathwada University, Aurangabed</a>',
            '<a href="http://www.iitg.ernet.in/" target="_blank">Indian Institute of Technology Guwahati</a>',
            '<a href="http://www.kiit.ac.in/" target="_blank">Kalinga Institute of Industrial Technology Unversity, Bhubaneswar</a>',
            '<a href="http://www.pravara.com/" target="_blank">Pravara Institute of Medical Sciences Deemed Unversity, Loni</a>',
            '<a href="http://www.unipune.ac.in/" target="_blank">University of Pune</a>',
            '<a href="http://www.srtmun.ac.in/" target="_blank">Swami Ramanand Teerth Marathwadan, Nanded</a>',
            '<a href="http://www.international-msubaroda.org/" target="_blank">Maharaja Sayajirao University of Baroda</a>',
            '<a href="http://www.jaduniv.edu.in/campus_map.php" target="_blank">Jadavpur University, Kolkata</a>',
            '<a href="http://www.pravarares.org.in/" target="_blank">Pravara Rural Education Society, Loni</a>',
          ],
        },
      },
      {
        id: "f2s8Oz0Izt",
        type: "paragraph",
        data: {
          text: "and became an integral Partner Institute of the EUPHATES Programme.",
        },
      },
      {
        id: "pyQy3lav7F",
        type: "paragraph",
        data: {
          text: "Our\n beloved Director, Prof. T. Srinivasa Rao, has signed the MoU. It is a \nwonderful and great opportunity for the faculty and students of our \nInstitute under Target Group 1 and Target Group 3 (TG1 and TG3) and also\n for socially deprived sections (SC, ST and PDA) of any institute in \nIndia. Under Target Group 2 (TG2) for carving new and additional \nfeathers in their academic cap.",
        },
      },
      {
        id: "3PB5PQCtuJ",
        type: "paragraph",
        data: {
          text: 'Apply\n in large numbers. For knowing the courses, academic and research \navenues, help zones, guidance and modus operendi and application \nprotocols, details of financial support, etc can be had by visiting the \nMoU document that we have entered into. It can be opened&nbsp;here:&nbsp;<a href="http://172.20.0.4/nitw/pdfs/MoU-Signed-Euphrates.pdf">here:</a><a href="http://172.20.0.4/nitw/pdfs/MoU-Signed-Euphrates.pdf">EUPHRATES.pdf</a>',
        },
      },
      {
        id: "9kW17iYigX",
        type: "paragraph",
        data: {
          text: 'For more information, see the website:&nbsp;<a href="http://www.usc.es/euphrates/">www.usc.es/euphrates/</a>&nbsp;',
        },
      },
      {
        id: "h-z7swDP-W",
        type: "paragraph",
        data: {
          text: "In order to apply, go on the online application which would be soon flashed on University Santiago de Compostela website.",
        },
      },
      {
        id: "p6II8eNJsT",
        type: "paragraph",
        data: {
          text: "EUPHRATES\n is an ERASMUS MUNDUS Partnership (Action 2, Strand 1) Project, funded \nby the European Commission and coordinated by University Santiago de \nCompostela as the European Coordinating Institute and Babasaheb Ambedkar\n Marathwada University, Aurangabed, Maharashtra, as the Indian \nCounterpart.",
        },
      },
      {
        id: "Khw4UjrQtj",
        type: "paragraph",
        data: { text: "Thank you for your interest and for your cooperation." },
      },
      {
        id: "jxS14RLPRv",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.4/nitw/Euphrates/2015/ApplicationGuide.pdf">ApplicationGuide</a>',
        },
      },
      {
        id: "u2vv0Ev9mx",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.4/nitw/Euphrates/2015/Call-Cohort3-Circular.pdf">Call-Cohort3-Circular</a>',
        },
      },
      {
        id: "d_doBH15Jj",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.4/nitw/Euphrates/2015/CoverPageNew.pdf">CoverPageNew</a>',
        },
      },
      {
        id: "0OFbfweYrp",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.4/nitw/Euphrates/2015/HandOut-Cohort3.pdf">HandOut-Cohort3</a>',
        },
      },
      {
        id: "piJTaoSU5W",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.4/nitw/Euphrates/2015/Poster-Cohort3.pdf">Poster-Cohort3</a>',
        },
      },
      {
        id: "R9jfQ0KU-g",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.4/nitw/Euphrates/2015/SomePhotos.pdf">SomePhotos</a>',
        },
      },
      {
        id: "MmbjEgZoTt",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.4/nitw/Euphrates/Epharates-Clarifications%20for%20FAQ%2001-02-14.pdf" target="_blank">Clarifications for Frequently Asked Questions</a>',
        },
      },
      {
        id: "Avwp1iKg1s",
        type: "paragraph",
        data: { text: "For further details and guidance contact:" },
      },
      {
        id: "lqUEUEayxD",
        type: "paragraph",
        data: { text: "<b>Prof. A. Ramachandraiah</b>\n" },
      },
      {
        id: "tmI97ByzkM",
        type: "paragraph",
        data: { text: "Coordinator, EUPHRATES\n" },
      },
      {
        id: "tLFWnUGpo4",
        type: "paragraph",
        data: {
          text: 'E-mail :&nbsp;&nbsp;<a href="mailto:archem@nitw.ac.in">archem@nitw.ac.in</a>',
        },
      },
      {
        id: "aFRn-cVACz",
        type: "paragraph",
        data: {
          text: 'Mobile:&nbsp;<a href="callto:8332969504">8332969504</a>',
        },
      },
      { id: "mX6hf0Is2r", type: "paragraph", data: { text: "or" } },
      {
        id: "pD2r3kLtlZ",
        type: "paragraph",
        data: { text: "<b>Dr. K.V. Jayakumar</b>" },
      },
      {
        id: "izTfVK843U",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16194/"></a>&nbsp;Professor –in- charge,',
        },
      },
      {
        id: "gA-RHiyfjp",
        type: "paragraph",
        data: { text: "International Relations and Alumni Affairs\n" },
      },
      {
        id: "4ZRcTie1t6",
        type: "paragraph",
        data: {
          text: 'E-mail:&nbsp;<a href="mailto:kvj@nitw.ac.in">kvj@nitw.ac.in</a>\n',
        },
      },
      {
        id: "q-Mbq80osT",
        type: "paragraph",
        data: { text: "Mobile: 9491065002" },
      },
    ],
    language: "En",
    _id: "61717fc80828ed79896d18fb",
    time: 1634828228616,
    version: "2.22.2",
    title: "Euphrates",
    url: "/academics/euphrates",
    path: "/academics",
    createdAt: "2021-10-21T14:57:12.331Z",
    updatedAt: "2021-10-21T14:57:12.331Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "HERITAGE", level: 2 },
      },
      {
        id: "ECEVRpvLYG",
        type: "paragraph",
        data: {
          text: "The\n Director and the HERITAGE Committee of NIT Warangal are very happy to \nstate that out of about 140 HERITAGE mobilities from&nbsp; 8 Indian Higher \nEducation Institute (HEIs) as many as 45 (almost one third of the total)\n mobilities have been garnered by the students and staff of NIT Warangal\n alone.",
        },
      },
      {
        id: "jXG69CMFpE",
        type: "paragraph",
        data: {
          text: 'The\n Director congratulates all of them because the selection was purely on \ntheir merit. The country-wide mobilities and their details can be viewed\n in the associated links&nbsp;<a href="http://www.nitw.ac.in/nitw/pdfs/NIT%20Warangal-Heritage%20Cohorte%201-%20Selection.xlsx" target="_blank">Phase-I</a>&nbsp;<a href="http://www.nitw.ac.in/nitw/pdfs/NIT%20Warangal-Heritage%20Cohorte%202-%20Selection.xlsx" target="_blank">Phase-II</a>',
        },
      },
      {
        id: "phgG_zHB69",
        type: "paragraph",
        data: {
          text: "The\n Director is very hopeful that similar performance would be exhibited by\n the students and staff of our Institute in winning the maximum \nmobilities under the TG1 of another Erasmus Mundus Indo – Europe \nAcademic Exchange Programme, EUPHRATES.",
        },
      },
      {
        id: "7pPMTD1Xdo",
        type: "paragraph",
        data: {
          text: "Those\n who have not availed themselves of the opportunity of HERITAGE may \napply for EUPHRATES mobilities by visiting that link sited elsewhere as \n&lt;EUPHRATES&gt;.",
        },
      },
      {
        id: "fyltKNOLHs",
        type: "paragraph",
        data: { text: "The last date is December 9th&nbsp;2013." },
      },
    ],
    language: "En",
    _id: "617180a936385b79b439661f",
    time: 1634828456437,
    version: "2.22.2",
    title: "Heritage",
    url: "/academics/heritage",
    path: "/academics",
    createdAt: "2021-10-21T15:00:57.450Z",
    updatedAt: "2021-10-21T15:00:57.450Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "NMEICT Projects", level: 2 },
      },
      {
        id: "he8co2L9mn",
        type: "header",
        data: {
          text: "a) Design Suitable pedagogical Methods for various classes of intellectual calibers and research in E-learning- Pilot project:",
          level: 3,
        },
      },
      {
        id: "GfYgTGb3aV",
        type: "paragraph",
        data: { text: "Prof DVLN Somayajulu, Professor of CSE, NIT, Warangal" },
      },
      {
        id: "A0ry-cyOwc",
        type: "paragraph",
        data: {
          text: 'National Institute of Technology, \nWarangal, in association with IIT, Kharagpur, has completed NMEICT MHRD \nsponsored pilot project during 2010-13. Total courses taken up by NIT, \nWarangal is 18 with cost of Rs 67.75 lakhs. Around 50 faculty members \nare associated with this work.&nbsp;<a href="http://www.ide.iitkgp.ernet.in/Pedagogy1/pedagogy_main.jsp" target="_blank">click here</a>&nbsp;for details about this project.',
        },
      },
      {
        id: "SLr7upaEjW",
        type: "header",
        data: {
          text: "b) E-content generation and delivery management for student-centric learning-Pilot project:",
          level: 3,
        },
      },
      {
        id: "yCl2Iea4q2",
        type: "header",
        data: { text: "Project Coordinators:", level: 3 },
      },
      {
        id: "QX4ar0vcbS",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/faculty/id/16333/">Prof DVLN Somayajulu,</a> Professor of CSE, NIT, Warangal',
            '<a href="https://www.nitw.ac.in/faculty/id/16334/">Prof B B Amberker,</a> Professor of CSE, NIT, Warangal',
            "&nbsp;",
          ],
        },
      },
      {
        id: "nqRWDHhnft",
        type: "paragraph",
        data: {
          text: 'E-Content Generation and Delivery Management for Student Centric Learning&nbsp;is\n a funded pilot project from Ministry of Human Resources Development \n(MHRD), GOI. The pilot implementation phase of the project is completed \nwith project cost of Rs 21 lakhs by developing e-content for three \nengineering subjects i) Radar Engineering ii) Computer Integrated \nmanufacturing and iii) Data Warehousing in "Four Quadrant" format. \nPlease&nbsp;<a href="http://www.navayugainfotech.com/nitw/" target="_blank">click here</a>&nbsp;for the approach and methodology adopted in implementing this pilot project.',
        },
      },
      {
        id: "sFfVWIXe6m",
        type: "header",
        data: {
          text: "c) Design suitable pedagogical Methods for various classes of intellectual calibers and research in E-learning: Pilot project:",
          level: 3,
        },
      },
      {
        id: "sr9XnUQ_ba",
        type: "header",
        data: { text: "Project Coordinators:", level: 3 },
      },
      {
        id: "crNS8PYrf7",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/faculty/id/16333/">Prof DVLN Somayajulu</a>, Professor of CSE, NIT, Warangal',
          ],
        },
      },
      {
        id: "SbK5gFfG1e",
        type: "paragraph",
        data: {
          text: "National Institute of Technology, \nWarangal, in association with IIT, Kharagpur, has taken up NMEICT MHRD \nsponsored main phase project in 2013. Total courses taken up by NIT, \nWarangal is 29 with cost of Rs 159.66 lakhs. Around 75 faculty members \nare associated with this work.",
        },
      },
      {
        id: "0UhERpl-dj",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/pdfs2/pdfs/NMEICT-developersNITW.pdf">Click here for details about the course titles and developers of NIT, Warangal.</a>',
        },
      },
      {
        id: "kFHxjilxaR",
        type: "paragraph",
        data: {
          text: '<a href="http://www.ide.iitkgp.ernet.in/Pedagogy1/pedagogy_main.jsp" target="_blank">To know more about project details</a>',
        },
      },
    ],
    language: "En",
    _id: "61718153bd3e7d9da04a94d7",
    time: 1637163335157,
    version: "2.22.2",
    title: "NMEICT",
    url: "/academics/nmeict",
    path: "/academics",
    createdAt: "2021-10-21T15:03:47.418Z",
    updatedAt: "2021-11-17T15:35:35.263Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "ZEo2Si_YJI",
        type: "header",
        data: { text: "Technical Association of Biotechnology", level: 3 },
      },
      {
        id: "lZrsf50E2h",
        type: "paragraph",
        data: {
          text: "TAB : Technical Association of Biotechnology. Faculty Advisor: Dr.Perugu Shyam",
        },
      },
      {
        id: "n3U5B1DTG4",
        type: "paragraph",
        data: {
          text: "Technical Association of Biotechnology (abbreviated as TAB) is the students’ association of the Department of Biotechnology, &nbsp;NITWarangal.",
        },
      },
      {
        id: "QA2rqMGzYF",
        type: "paragraph",
        data: {
          text: "TAB was established to enhance the student experience at the department along with creating a platform for constructive interactionand information flow amongst the students and with the basic motto of representing the Biotech student community in general.It hasalso strived to maximize the interaction amongst students and the faculty of the department.",
        },
      },
      {
        id: "2_b0gXFokm",
        type: "paragraph",
        data: {
          text: "Representatives from each year elected by faculty members constitute the working body of the association. The association follows aformal hierarchy, with the posts being those of General Secretary, Additional Secretaries, Joint Secretaries and Executive Members.",
        },
      },
      {
        id: "viM185I2Er",
        type: "paragraph",
        data: {
          text: "The primary purpose of TAB is to conduct activities for the academic as well as extracurricular growth and development of students ofthe department along with endeavoring to ameliorate the problems faced by them. Various events are conducted each year by the association to this end.",
        },
      },
      {
        id: "sZKv7VvBZK",
        type: "header",
        data: { text: "Events Of TAB", level: 3 },
      },
      {
        id: "oA-BVsrxMt",
        type: "paragraph",
        data: { text: "<b>Events List:</b>" },
      },
      {
        id: "jKbOvL0Kwr",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Inaugural of TAB&nbsp;",
            "Fresher’s Talent Search:&nbsp;The first year students were welcomed to the institute in general and the department in particular by their seniors. “Mr and Miss Freshers” for the year will be crowned during this event.&nbsp;",
            "Alumni Meet:&nbsp;Were all students get an opportunity to meet the recent passed outs during the convocation.&nbsp;",
            "Internship Konnect:&nbsp;All the students, who have been to internships will give the insights and share their experiences .&nbsp;",
            "Know Your Branch –Event:&nbsp;Which is exclusively for first years to know about their branch, the opportunities they will get in coming years and different fields in which they can harness their talent. It is more of a mentorship programme to guide them and put them through a proper channel in future.Exclusive doubts session will also be kept for them.&nbsp;",
            "We planned to have a&nbsp;Guest Lecture&nbsp;from CCMB by Dr. Sashi Singh in the month of December.&nbsp;",
            "In Technozion’13&nbsp;(Events &amp; workshops): There were be 4 events and 1 workshop on behalf of dept of biotechnology.&nbsp;",
            "Grey Matter: A bi-weekly quiz event with content exclusively pertaining to the courses taught in the department will be held.&nbsp;",
            "We also conducted&nbsp;Biotech Sports League&nbsp;to instil the quality of teamspirit and brotherhood among biotech students. All different kinds of sport will be organised. Students from all years are encouraged to participate.&nbsp;",
            "T-Shirt Design Contest: Branch T-Shirt Designing Contest.&nbsp;",
            "Weekly Seminars on GRE,GMAT/CAT and GATE&nbsp;will be held to guide the students of the department on their course of action for pursuing higher studies.&nbsp;",
            "Preparation of Department newsletter&nbsp;",
            "Mock Placements Week for 2nd&nbsp;and 3rd&nbsp;years:&nbsp;which is an exclusive event for third years which enables them to prepare for future placements, the event comprised of technical and aptitude questions, group discussion and interviews",
          ],
        },
      },
      {
        id: "9xu_nMLn8z",
        type: "header",
        data: {
          text: "TECHNICAL ASSOCIATION OF BIOTECHNOLOGY (TAB)",
          level: 6,
        },
      },
      {
        id: "L0QgdxdCm8",
        type: "paragraph",
        data: { text: "DEPARTMENT OF BIOTECHNOLOGY" },
      },
      {
        id: "ztjfgG3Au9",
        type: "paragraph",
        data: { text: "<b>Faculty in-Charge</b>&nbsp;:&nbsp;Dr Perugu Shyam" },
      },
      {
        id: "hcODps66EZ",
        type: "paragraph",
        data: {
          text: "<b>Treasurer&nbsp;</b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :&nbsp;Dr Urmila Saxena",
        },
      },
      {
        id: "NGj-SZ8TUZ",
        type: "paragraph",
        data: {
          text: "Executive Body of the&nbsp;TECHNICAL ASSOCIATION OF BIOTECHNOLOGY (TAB)&nbsp;for&nbsp;AY 2021-22:&nbsp;Following is the list of Executive Members selected for the TAB for AY 2021-22. All the selected candidates are required to contact&nbsp;Dr. Perugu Shyam, Faculty Advisor, TAB&nbsp;(shyamperugu@nitw.ac.in) for planning and execution of future activities.",
        },
      },
      {
        id: "NcBnxE0HHT",
        type: "paragraph",
        data: {
          text: 'Executive Body of the TECHNICAL ASSOCIATION OF BIOTECHNOLOGY (TAB) for AY 2021-22: Following is the list of Executive Members selected for the TAB for AY 2021-22. All the selected candidates are required to contact Dr. Perugu Shyam, Faculty Advisor, TAB (shyamperugu@nitw.ac.in) for planning and execution of future activities. \n\n-&nbsp;<a href=`${MainServerUrl}/static/files/Executive_Members_selected_2022-0-22-11-32-5.pdf`><b>Click here</b></a>',
        },
      },
      {
        id: "l7UuOfj76F",
        type: "paragraph",
        data: {
          text: 'Previous General Secretaries&nbsp;\n\n&nbsp;-&nbsp;<a href=`${MainServerUrl}/static/files/Previous_General_Secretaries_2022-0-22-11-33-32.pdf`><b>Click here</b></a>',
        },
      },
    ],
    language: "En",
    _id: "617199367bf31d9dbf3e11de",
    time: 1642831436067,
    version: "2.22.2",
    title: "TAB",
    url: "/bt/tab",
    path: "/bt",
    createdAt: "2021-10-21T16:45:42.473Z",
    updatedAt: "2022-01-22T06:03:51.643Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "xbOtxYVAqC",
        type: "header",
        data: { text: "Areas Of Interest", level: 3 },
      },
      {
        id: "dW-ZjHJbJo",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Environmental Biotechnology",
            "Down Stream Processing",
            "Bioinformatics",
            "Functional Genomics,",
            "Microarray Data Analysis,",
            "Structural Bioinformatics,",
            "Molecular Modeling,",
            "In-silico&nbsp;Biomarker identification,",
            "In-Silico&nbsp;Biology",
            "Bio-fuels,",
            "Sustainable Bio-fuels development",
            "Biological Wastewater Treatment",
            "Systems Biology",
            "Modeling &amp; simulation of Bio-processes,",
            "Bio-Chemistry,",
            "Gene therapy and Insights of Molecular pathogenesis,",
            "Molecular and Biochemical Parasitology",
            "Signalling Pathways in Cancer,",
            "Network Biology,",
            "Designing of Recombinant Therapeutic Molecules for Targeted Therapy",
            "Nano biotechnology,",
            "Medical Biosensors",
            "Gene regulation in Mycobacteria,",
            "Characterization of transcription factors",
            "Stem Cell Engineering",
            "Metabolic Engineering",
          ],
        },
      },
      { id: "uEdZ0ZpJTy", type: "header", data: { text: "Labs", level: 3 } },
      {
        id: "Hr42XBMXEK",
        type: "paragraph",
        data: {
          text: "The Department is equipped with modern Laboratories with state of art facilities in",
        },
      },
      {
        id: "xZ0rfQGcPa",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Micro Biology Lab",
            "Bio Chemistry Lab",
            "Instrumental Analysis Lab",
            "Down Stream Processing Lab",
            "Bio Process Control &amp;Instrumentation",
            "Molecular Biology &amp;Genetics Lab",
          ],
        },
      },
      {
        id: "LCMrgy6fl4",
        type: "paragraph",
        data: {
          text: "The Department is in the process of equipping with laboratories like Bioprocess Engineering, Bio reaction Engineering and Bioinformatics.",
        },
      },
      {
        id: "h3ikZhGkw0",
        type: "header",
        data: { text: "Equipment", level: 3 },
      },
      {
        id: "NYOXA9VO9W",
        type: "header",
        data: { text: "MAJOR EQUIPMENT:", level: 5 },
      },
      {
        id: "bj7a2mk0Ho",
        type: "paragraph",
        data: {
          text: "Gradient Thermal Cycler (PCR)Microplate Reader (Elisa Reader)Research grade Optical MicroscopesUV-Visible Spectrophotometer for Biotechnology applicationsVisible SpectrophotometersElectrophoresis SystemsBioreactor (3L)Ultra Deep FreezerCooling CentrifugeShaking IncubatorsAutomatic AutoclavesUltra-pure Water Purification SystemDe-ionized Water SystemBio-Safety cabinetLaminar Flow Chambers5KVA UPS2KVA UPS",
        },
      },
      {
        id: "9iJVqFZk3E",
        type: "header",
        data: { text: "Modern Laboratories:", level: 5 },
      },
      {
        id: "rHvx_pTkga",
        type: "paragraph",
        data: {
          text: "The Department is equipped with modern Laboratories with state of art facilities, in Microbiology Lab., Biochemistry Lab., Molecular Biology &amp; Genetics Lab. and Genetics Engineering Lab. The Department is in the process of equipping with laboratories like Bioprocess Engineering, Bioreaction Engineering, Bioinformatics, Down Stream Processing and Bioprocess Instrumentation Control.",
        },
      },
      {
        id: "SvtpEgM1IB",
        type: "paragraph",
        data: {
          text: "Software:Department has SuperPro Designer software for Modelling and Simulation of Bioprocesses laboratory.",
        },
      },
      {
        id: "M-jtoBv2Ll",
        type: "header",
        data: { text: "Departmental Seminar Hall:", level: 5 },
      },
      {
        id: "LcUpq2eoNW",
        type: "paragraph",
        data: {
          text: "The Department has its own seminar hall with L.C.D. projector and Internet connectivity. Several classroom lectures, Seminars from external experts and student seminars are organized on a regular basis in this seminar hallLibrary Books/Journals:The Central Library is equipped with all the best textbooks and reference books (Latest editions) in sufficient numbers to meet the requirement of all the students. The Digital Library is very much useful to all the students. Several international and national journals in the field of Biotechnology and related areas are subscribed through MHRD-INDEST programme. These journals can be accessed by the students through the LAN facility.",
        },
      },
      {
        id: "2S-O46fXYe",
        type: "header",
        data: { text: "R&amp;D Projects", level: 6 },
      },
      {
        id: "NCy2RXHL8T",
        type: "paragraph",
        data: {
          text: '1. Ongoing Research Projects&nbsp;- <a href=`${MainServerUrl}/static/files/Ongoing_Research_Projects_2022-0-22-12-26-51.pdf`>Click here</a>',
        },
      },
      {
        id: "A1fR-bD9V0",
        type: "paragraph",
        data: {
          text: '2. Completed Research Projects&nbsp;- <a href=`${MainServerUrl}/static/files/Completed_Research_Projects_2022-0-22-12-28-22.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61719bfcbc0602798811add1",
    time: 1642834721931,
    version: "2.22.2",
    title: "Research",
    url: "/bt/research",
    path: "/bt",
    createdAt: "2021-10-21T16:57:32.631Z",
    updatedAt: "2022-01-22T06:58:37.421Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "oMHwxKH_0i",
        type: "header",
        data: { text: "WELCOME TO CHEMICAL ENGINEERING DEPARTMENT", level: 3 },
      },
      {
        id: "MebiiCFW97",
        type: "paragraph",
        data: {
          text: "The department of Chemical Engineering offers B.Tech., M.Tech. and Ph.D programmes. Students are admitted to these programmes based on National level competitive examinations. Foreign national students are also given admission to these programmes. The department has well qualified faculty dedicated to teaching and doing research in fundamental and advanced areas. The department houses various laboratories catering to the needs of the curriculum. The faculty are actively engaged in industrial consultancy and sponsored research projects. The department has inked MoUs with some of the leading industries. The graduating students are absorbed into reputed firms through campus placements. The department aims at inculcating lifelong learning skills in the students. The alumni of the department are shouldering high positions in multifarious organizations.",
        },
      },
      {
        id: "tLg8RRt4_t",
        type: "paragraph",
        data: {
          text: "The department was started in the year 1964 and since then it has come a long way in educating budding chemical engineers and scholars. The department celebrated it’s&nbsp;Golden Jubilee&nbsp;in 2014-15 and on this occasion several events were conducted such as an International Conference on New Frontiers in Chemical Energy and Environmental Engineering (INCEEE) during March 20-21, 2015,&nbsp;a National Student Conference “Chem-Flair” on March 13-14, 2015 and an Alumni Meet on January 30-31, 2015.",
        },
      },
      {
        id: "1oKHYE25x3",
        type: "paragraph",
        data: {
          text: "The B.Tech. programme in Chemical Engineering has been accredited for&nbsp;5 years&nbsp;by National Board of Accreditation (NBA) with effect from June 2015.",
        },
      },
      {
        id: "__Tw6xP-Fb",
        type: "paragraph",
        data: {
          text: "The M.Tech programme in Chemical Engineering has also been accredited for 5 years by NBA with effect from July, 2016.",
        },
      },
      {
        id: "kf8SSjAetV",
        type: "header",
        data: { text: "VISION AND MISSION", level: 3 },
      },
      { id: "BwGeTthkHy", type: "header", data: { text: "VISION:", level: 5 } },
      {
        id: "LlyWWFatbH",
        type: "paragraph",
        data: {
          text: "To attain global recognition in research and training students for meeting the challenging needs of chemical &amp; allied industries and society.",
        },
      },
      {
        id: "PUiqO1SXmx",
        type: "header",
        data: { text: "MISSION:", level: 5 },
      },
      {
        id: "dW9sa3Qdeb",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Providing high quality undergraduate and graduate education in tune with changing needs of industry.",
            "Generating knowledge and developing technology through quality research in frontier areas of chemical and interdisciplinary fields.",
            "Fostering industry-academia relationship for mutual benefit and growth.",
          ],
        },
      },
      {
        id: "pA09ifJlhT",
        type: "header",
        data: { text: "What Is Chemical Engineering ?", level: 3 },
      },
      {
        id: "quXjZwHCPv",
        type: "paragraph",
        data: {
          text: "“Chemical engineering is the study and practice of transforming substances at large scales for the tangible improvement of the human condition. Such transformations are executed to produce other useful substances or energy, and lie at the heart of vast segments of the chemical, petroleum and pharmaceutical industries.”",
        },
      },
      {
        id: "Yq9oon7_x3",
        type: "paragraph",
        data: {
          text: "“Chemical engineers take chemistry out of the laboratory and into the factory and the world around us”.",
        },
      },
      {
        id: "b41MKntpYd",
        type: "paragraph",
        data: {
          text: "The study involves understanding, interpreting and conceiving of single or sequential unit operations/processes such as pre-treatment, mixing, reactions, separations and purifications. The scientific aspects of momentum, heat &amp; mass transfer and chemical reactions lie at the core of the chemical engineering.",
        },
      },
      {
        id: "M2dFz6MIt5",
        type: "paragraph",
        data: {
          text: "Chemical Engineering also addresses the challenges in energy, water and environmental pollution.&nbsp; This branch of engineering also plays a role in developing fascinating gadgets such as point-of-care diagnostic microfluidic devices, thin film batteries, micro chips, etc.",
        },
      },
      {
        id: "wwWGstBP8r",
        type: "header",
        data: { text: "History Of The Department", level: 3 },
      },
      {
        id: "_Tu84NNQqf",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Department was established in 1964 with an initial intake of 30 students in B.Tech. programme. Later on the intake was increased to 40, subsequently to 60 and presently it is 92.",
            "M. Tech. Course in Chemical Plant Engineering was started in 1968. The course was redesigned as M. Tech in Chemical Engineering with specialization in Computer Aided Process &amp; Equipment Design (CAPED) in 1987.",
            "The department offers Ph.D programme funded by MHRD.",
            "B.Tech. and M.Tech. programs were accredited by NBA for 3 years in 2008.",
            "The department has been recognized for Ph.D programme under QIP.",
            "Department completed 50 years of its existence and celebrated its Golden Jubilee year in 2014-15.",
            "B.Tech. program in Chemical Engineering has been accredited for 5 years by NBA with effect from June 2015.",
            "M.Tech. in Chemical Engineering with Specialization in CAPED has been re-designated as M.Tech. in Chemical Engineering in 2015.",
            "M.Tech. Course in Process Control is being started in 2016.",
          ],
        },
      },
    ],
    language: "En",
    _id: "6171a2a036385b79b4396623",
    time: 1634907289093,
    version: "2.22.2",
    title: "Home",
    url: "/che/home",
    path: "/che",
    createdAt: "2021-10-21T17:25:52.144Z",
    updatedAt: "2021-10-22T12:54:50.017Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "ZVIXjjTq3i",
        type: "header",
        data: { text: "Undergraduate Program", level: 3 },
      },
      { id: "_e2sAp20fo", type: "header", data: { text: "B.Tech", level: 5 } },
      {
        id: "BZ0454ugki",
        type: "paragraph",
        data: {
          text: "The Department offers 4 Year&nbsp;B.Tech. programme in Chemical Engineering. The current intake of the course is 92. The intake to this programme is based on the All India Rank (AIR) obtained by the aspiring students, which is a combination of their 10+2 or other equivalent qualifying exam and score obtained in IIT Main exam, conducted by Joint Admission Board (JAB)- constituting NITs, other CFTIs, CBSE and others. Foreign nationals are also admitted to this programme through schemes such as DASA.",
        },
      },
      {
        id: "lMUHxdHXKt",
        type: "paragraph",
        data: {
          text: "Semester system is followed for the B.Tech.&nbsp;programme, where there are two semesters per an academic year. First two semesters of the programme are dedicated for common courses for various engineering branches. Core subjects of the branch are introduced from third semester onwards. In addition to the core subjects, a wide range of electives are also offered in this programme, which provides an opportunity to explore the branch in a desired perspective. The course contents are updated every four years by Board of Studies, consisting of senior faculty, experts from other institutes, industry and Alumni. During the summer vacation the students usually take up internships in industries or academic institutes. In the third year of the study, an industrial tour is arranged for gaining an exposure to the industrial operations. A project on Chemical Plant design is taken up by the students during the final year of the course. The campus placements for the students begin during the final year of the study.",
        },
      },
      {
        id: "3vcsasANrr",
        type: "list",
        data: {
          style: "unordered",
          items: [
            'Scheme of Instructions and Syllabus&nbsp;–&nbsp;<a href="https://nitw.ac.in/media/uploads/2021/01/18/btech_chemical_final-2018-aug-2020-new.pdf">https://nitw.ac.in/media/uploads/2021/01/18/btech_chemical_final-2018-aug-2020-new.pdf</a>',
          ],
        },
      },
      {
        id: "ytMbOmxG7b",
        type: "header",
        data: { text: "Postgraduate Program", level: 3 },
      },
      {
        id: "lNkWukLTJj",
        type: "paragraph",
        data: {
          text: "The Department of Chemical Engineering offers two Postgraduate&nbsp;Programs –&nbsp;M. Tech. in&nbsp;Chemical Engineering&nbsp;and&nbsp;M.Tech. in Systems and Control Engineering. From 2021-2022 academic year, the department has started a PG Diploma course in Systems and Control Engineering .",
        },
      },
      {
        id: "fXHw_m-6Yf",
        type: "header",
        data: { text: "M.Tech in Chemical Engineering", level: 5 },
      },
      {
        id: "AJb9yWLU_T",
        type: "paragraph",
        data: {
          text: "The program spans for a period of four semesters. The intake to this course is based on the score obtained in the&nbsp;GATE exam conducted&nbsp;jointly by the Indian Institute of science (IISc)&nbsp;and seven&nbsp;Indian Institutes of Technology&nbsp;(IIT)&nbsp;on behalf of the National Coordination Board – GATE, Ministry of Human Resource Development (MHRD). Foreign nationals are also admitted to this programme through schemes like ICCR.&nbsp;Education and research are closely integrated within this Master’s program, which helps to stay aware of the latest developments in the field of chemical engineering.",
        },
      },
      {
        id: "q6baPwRosa",
        type: "paragraph",
        data: {
          text: "The students are exposed to core and advanced areas of Chemical Engineering in &nbsp;the first two semesters consisting of a well structured palette of core subjects like Advanced Reaction Engineering, Steady State Process Simulation, Chemical Process Modeling and &nbsp;Chemical Process Synthesis . A wide range of electives like Process Intensification, Membrane Separation Processes, Multiphase Flow, etc., are also offered in this course, which provides an opportunity to explore the branch in a desired perspective. As part of the curriculum, students get hands on experience of software packages like Aspen Engineering Suite, Ansys Fluent &amp; CFX, Matlab, Comsol and gProms. Students devote their entire second year for the Master’s dissertation Project.",
        },
      },
      {
        id: "i8opMB18zN",
        type: "paragraph",
        data: {
          text: "The program has an annual intake of 25 seats based on GATE score and additional 5 seats under self-sponsored categeory (without GATE qualification)&nbsp;",
        },
      },
      {
        id: "dOLs6-qfOP",
        type: "list",
        data: {
          style: "unordered",
          items: [
            'Scheme of Instructions and Syllabus -&nbsp;<a href="https://www.nitw.ac.in/media/uploads/M.Tech._Chemical_Engg._Curriculum_2021.pdf">https://www.nitw.ac.in/media/uploads/M.Tech._Chemical_Engg._Curriculum_2021.pdf</a>',
          ],
        },
      },
      {
        id: "deG9HXE-fx",
        type: "header",
        data: {
          text: "M.Tech in Systems and Control Engineering&nbsp;(upto 2020-2021,&nbsp;the specilization name is M.Tech in Process Control)",
          level: 5,
        },
      },
      {
        id: "xxA2I1yYad",
        type: "paragraph",
        data: {
          text: "The program&nbsp;spans for a period of four semesters. The intake to this course is based on the score obtained in the&nbsp;GATE exam.&nbsp;This course is an interdisciplinary program admitting students with different backgrounds such as Instrumentation &amp; Control Engineering, Electrical Engineering, Electronics and Instrumentation, Chemical Engineering, etc…Education and research are closely integrated within this Master’s program, which helps to stay aware of the latest developments in the field of Control and Automation.",
        },
      },
      {
        id: "1-AxsUfbEl",
        type: "paragraph",
        data: {
          text: "The students&nbsp;are exposed to core and advanced areas of Systems and Control in the first two semesters consisting of a well-structured palette of core subjects. A wide range of electives are also offered in this course, which provides an opportunity to explore the branch in a desired perspective. As a part of the curriculum, students get hands on experience on software packages MATLAB/SIMULINK, Python. Students devote their entire second year for the Master’s dissertation Project. The department is equipped with good Control Laboratory with different experimental set ups for understanding Systems Modeling, Control and Automation concepts.",
        },
      },
      {
        id: "rgJb9gG5XK",
        type: "paragraph",
        data: {
          text: "The program has an annual intake of 25 seats based on GATE score and additional 5 seats under self-sponsored categeory (without GATE qualification)&nbsp;&nbsp;",
        },
      },
      {
        id: "Dl0Zog9jX7",
        type: "list",
        data: {
          style: "unordered",
          items: [
            'Scheme of Instructions and Syllabus -&nbsp;<a href="https://www.nitw.ac.in/media/uploads/MTech_Sys_Con_Engg_Curriculum_2021.pdf">https://www.nitw.ac.in/media/uploads/MTech_Sys_Con_Engg_Curriculum_2021.pdf</a>&nbsp;',
          ],
        },
      },
      {
        id: "I-O0HP3CgW",
        type: "header",
        data: {
          text: "PG. Diploma in Systems and Control Engineering",
          level: 5,
        },
      },
      {
        id: "1tRDNfqw7n",
        type: "paragraph",
        data: {
          text: "The program&nbsp;spans for a period of one year and is with effect from 2021-2022 academic year. The is a self sponsored program.&nbsp;This is an interdisciplinary program admitting students with different backgrounds such as Instrumentation &amp; Control Engineering, Electrical Engineering, Electronics and Instrumentation, Chemical Engineering, etc…Education and research are closely integrated within this PG diploma&nbsp;program, which helps to stay aware of the latest developments in the field of Control and Automation. This course is also open to people working in industry.&nbsp;",
        },
      },
      {
        id: "R-Mx9HQ93X",
        type: "paragraph",
        data: {
          text: "The program is only offered under self-sponsored categeory with&nbsp;an annual intake of 10&nbsp;seats.&nbsp;&nbsp;",
        },
      },
    ],
    language: "En",
    _id: "6171a4f075d8e29daf62afa1",
    time: 1642327256537,
    version: "2.22.2",
    title: "Programs",
    url: "/che/programs",
    path: "/che",
    createdAt: "2021-10-21T17:35:44.471Z",
    updatedAt: "2022-01-16T10:00:57.668Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "gmW11gtxNM",
        type: "header",
        data: { text: "Research Areas", level: 3 },
      },
      {
        id: "jPdTwTobGn",
        type: "header",
        data: {
          text: '<a href="https://sites.google.com/view/seshagirirao">Process Control &amp; Environmental Systems Research Lab</a>',
          level: 5,
        },
      },
      {
        id: "h7ZtbNiXMD",
        type: "header",
        data: { text: "Other Research Areas:", level: 5 },
      },
      {
        id: "5TxNoLWISF",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Modeling, Simulation, Design &amp; Optimization of Chemical Processes",
            "Fluidized Bed Combustion",
            "Waste Water Treatment",
            "Fluidized Bed Drying",
            "Bubbling Fluidized Bed",
            "Circulating Fluidized Bed",
            "Heat Exchanger Network Synthesis",
            "Fuzzy Logic Controllers",
            "Computational Fluid Dynamics",
            "Biochemical Engineering",
            "ASPENPLUS Simulation of Process Plants",
            "Reactive Distillation",
            "Heterogeneous catalysis",
            "Fermentation Processes",
            "Fluidized Bed Bioreactors",
            "Multi-component Distillation",
            "Distillation Sequences",
            "Synthesis of nanomaterials",
            "Microreactors",
            "Microfluidics",
            "Biomass gasification",
            "Plate Heat exchangers",
            "PEM Fuel Cells",
            "Chemical looping combustion",
            "Redox Flow Batteries",
            "Energy Intensification",
            "Non-linear control",
            "Membrane Synthesis and Characterization",
            "Membrane Separations",
            "Energy efficient design of engineering systems",
            "Chemical Process Scheduling",
          ],
        },
      },
      {
        id: "v0j3ClzVlp",
        type: "header",
        data: { text: "MoUs/Collaborations", level: 3 },
      },
      {
        id: "Dw3e8i8EQj",
        type: "header",
        data: {
          text: "The department has inked MoUs/Collaborations with the following organizations.",
          level: 5,
        },
      },
      {
        id: "cDw5agD-2b",
        type: "list",
        data: {
          style: "unordered",
          items: ["Corning Technologies India Private Limited"],
        },
      },
      {
        id: "s0L96WQfU9",
        type: "header",
        data: { text: "Consultancy Projects", level: 3 },
      },
      {
        id: "bt1bGWO-Tv",
        type: "header",
        data: { text: "Ongoing Projects:", level: 5 },
      },
      {
        id: "18BKKRAmxY",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "S. No",
              "Title of the Project",
              "Investigator(s)",
              "Sponsoring agency",
              "Amount in Rs.",
              "Duration",
            ],
            [
              "1.",
              "Synthesis of nano Iron Oxide particles",
              "Dr. Shirish Sonawane",
              "M/s Felix Industries, Ahmedabad",
              "3 Lakhs",
              "1 Year",
            ],
            [
              "2.",
              "Development of a Practitioners information package report on the “Developments in Chemical Looping Technology Worldwide” for power plants sector.",
              "Dr. P. V. Suresh",
              "TREC-STEP,&nbsp;Tiruchirappalli",
              "1.5 lakhs",
              "4 months",
            ],
            [
              "3.",
              "Devlopment of Antiscalent for the polymer memberane",
              "Dr. Shirish Sonawane",
              "Krishna Water Treatment and Chemicals Surat",
              "2 Lakhs",
              "2014-2017",
            ],
            [
              "4.",
              "Development of inorganic nanopigments",
              "Dr. Shirish Sonawane",
              "Jason specialty Chemicals Nagpur",
              "1 Lakhs",
              "2015-2016",
            ],
            [
              "5.",
              "Feasibility check for hybrid waste water treatment system",
              "Dr. Shirish Sonawane",
              "Megafine specialty Chemical Pvt Ltd., Ahmedabad",
              "0.3 Lakh",
              "2015-2016",
            ],
            [
              "6.",
              "Mathematical Modeling of Belt Filter",
              "Dr. P. V. Suresh ,&nbsp;Dr. A. Sarath Babu",
              "Grace Davison India Pvt. Ltd., Chennai",
              "1.68 Lakhs",
              "2015-2016",
            ],
            [
              "7.",
              "Steady State Simulation of CO2 Absorption Column using MEA Solvent",
              "Dr. V. Ramsagar Dr. A. Sarath Babu",
              "S S Gas Lab Asia Pvt. Ltd., Delhi",
              "1.52 Lakhs",
              "2015-2016",
            ],
          ],
        },
      },
      {
        id: "0wjj2sCH3b",
        type: "header",
        data: { text: "Some of the Completed Projects:", level: 5 },
      },
      {
        id: "605jHhEQ8C",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "S. No",
              "Title of the Project",
              "Investigators",
              "Sponsoring agency",
              "Amount in Rs.",
              "Duration",
            ],
            [
              "1.",
              "Steady state simulation of a 100MW IGCC Power plant using Aspen plus",
              "A. Sarat Babu ,&nbsp;Dr. G. Venkat Reddy ,&nbsp;S. Srinath ,&nbsp;Dr. R.C.Sastry",
              "BHEL R&amp;D&nbsp;Hyderabad",
              "2.5 Lakhs",
              "2006-2007",
            ],
            [
              "2.",
              "Pilot Scale Production of Nano additives Argonate Phase-1",
              "Dr. Shirish Sonawane ,&nbsp;Y.Pydi Setty ,&nbsp;S.Srinath",
              "M/s Felix Industries, Ahmedabad",
              "1.68 Lakhs",
              "2012-2013",
            ],
            [
              "3.",
              "Computer simulation of Sulphuric acid plant",
              "A.Sarat Babu ,&nbsp;S.Srinath",
              "Coromandal International limited, Secunderabad",
              "1.12 Lakhs",
              "2012-2013",
            ],
            [
              "4.",
              "Nano Iron Oxide Pilot scale reactor",
              "Dr. Shirish Sonawane",
              "Felix Industries",
              "3 lakhs",
              "2012-2013",
            ],
            [
              "5.",
              "Testing of Oil Sample",
              "Dr. A. Venu vinod",
              "M/S Arc India Petroleum PVT Ltd., Secunderabad",
              "11,236",
              "2013-2014",
            ],
            [
              "6.",
              "Development of a Practitioners information package on “Developments in Chemical Looping Technology worldwide”",
              "Dr. P.V. Suresh",
              "TREC-STEP, Tiruchirappalli",
              "1.5 lakhs",
              "2013-2014",
            ],
          ],
        },
      },
      {
        id: "P8g8MDFim2",
        type: "header",
        data: { text: "Research Projects", level: 3 },
      },
      {
        id: "qFh2iJdgVl",
        type: "header",
        data: { text: "Ongoing Projects:", level: 5 },
      },
      {
        id: "GcSOMRhbU7",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "S. No",
              "Title of the Project",
              "Investigator(s)",
              "Sponsoring agency",
              "Amount in Rs.",
              "Duration",
            ],
            [
              "1.",
              "Synthesis of nanohydrogel and Devlopment of hybrid waste water treatment technique using cavitation technique and hydrogel",
              "Dr. Shirish Sonawane (PI)",
              "Ministry of Environment and Forest (MOEF),GOI",
              "58 Lakhs",
              "&nbsp; 2013-15",
            ],
            [
              "2.",
              "Simulation of pitting corrosion and galvanic corrosion in stressed and unstressed parts subjected to chloride environment",
              "&nbsp; Dr. Sunil Kumar Thamida (PI) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Dr. N.Narasaiah(Co-PI)Prof. B.V.Appa Rao (Co-PI)&nbsp;&nbsp;",
              "Naval Research Board, DRDO, India",
              "23.4 Lakhs",
              "3 Years&nbsp;2014-16",
            ],
            [
              "3.",
              "Prototype development of fuel cell",
              "Dr. Shirish Sonawane (PI) ,&nbsp;Dr. B B Kale (Co-PI), CMET Pune",
              "Department of information Technology Govt of India",
              "104 Lakhs",
              "2014-17",
            ],
            [
              "4.",
              "Development of nano hybrid self healing coating using ultrasound assisted encapsulation approach for anticorrosion performance",
              "Dr. Shirish Sonawane (PI)",
              "EP &amp;IPR DRDO Govt of India",
              "34 Lakhs",
              "2014-17",
            ],
            [
              "5.",
              "Theoretical and Experimental Studies on Chemical Looping Combustion of Indian Coal",
              "Dr. P.V Suresh (PI)",
              "SERB, Govt. of India.",
              "28.07 Lakhs",
              "2015-18",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "6171b8b1bc0602798811add4",
    time: 1634843549742,
    version: "2.22.2",
    title: "Research",
    url: "/che/research",
    path: "/che",
    createdAt: "2021-10-21T19:00:01.294Z",
    updatedAt: "2021-10-21T19:12:30.299Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "KDl3ZLZizG",
        type: "header",
        data: { text: "Computational Resources", level: 3 },
      },
      {
        id: "NsG3mr2DdG",
        type: "header",
        data: { text: "Software:", level: 5 },
      },
      {
        id: "SijmGhUAT3",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Ansys Research CFD – Fluent, CFX, ICEM CFD and&nbsp;Work bench&nbsp;–&nbsp;with HPC capability",
            "Aspen Plus",
            "ChemCad",
            "Comsol Multiphysics",
            "gProms",
            "Matlab &amp; Simulink",
            "Design-Expert",
            "GPS-X",
            "GAMS",
            "LabVIEW",
            "Prosimulator",
          ],
        },
      },
      {
        id: "7iWr_5Pqlt",
        type: "header",
        data: { text: "Hardware:", level: 5 },
      },
      {
        id: "WhpgSDqkKu",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Systems: 30",
            "Workstations: 4",
            "Servers: 2",
            "Wi-Fi connection",
            "10 kVA&nbsp;UPS",
          ],
        },
      },
      {
        id: "ulfhyNGhda",
        type: "header",
        data: { text: "Research Facilities", level: 3 },
      },
      {
        id: "0_g2fSapRn",
        type: "header",
        data: { text: "Process Equipment:", level: 5 },
      },
      {
        id: "c8kp_V1o3y",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Advanced Flow Reactor (from Corning Technologies India Pvt. Ltd.)",
            "Atomizer",
            "Autoclave",
            "Biomass Pelletizer",
            "Bioreactor",
            "Circulating Fluidized bed Dryer",
            "Circulating hot oil bath",
            "Double pipe helical coil heat exchanger",
            "Fluidized bed Bio Reactor",
            "Fluidized bed Combustor",
            "Fluidized bed Dryer",
            "Grinding Machine",
            "Hot air Oven ( 0- 300 °C)",
            "Incubator shaker",
            "Micro Reactor",
            "Plate type heat exchanger",
            "Reactive Distillation Column",
            "Shell and helical coil Heat exchanger",
            "Sieve Shaker",
            "Spout Fluidized bed",
            "Ultra Pure Water Purifier",
            "Ultra Sonicators",
            "Vibratory Feeder (DR 100)",
          ],
        },
      },
      {
        id: "lolhWmwrvV",
        type: "header",
        data: { text: "Analytical Instruments:", level: 5 },
      },
      {
        id: "S5KjqvPbLd",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Digital Electronic Balances",
            "Flue Gas analyzer",
            "Gas Chromatographic System (GC)",
            "High performance Liquid Chromatography (HPLC)",
            "Ion analyzer",
            "Optical Microscope with image /video acquisition",
            "Particle size analyzer",
            "PH Meter",
            "Rheometer",
            "Thermal Property Analyzer",
            "UV-Visible Spectrophotometer",
          ],
        },
      },
    ],
    language: "En",
    _id: "6171bc56c1ada47997934770",
    time: 1634843748617,
    version: "2.22.2",
    title: "Resources",
    url: "/che/resources",
    path: "/che",
    createdAt: "2021-10-21T19:15:34.854Z",
    updatedAt: "2021-10-21T19:15:49.208Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "SJKnHUGpsD",
        type: "header",
        data: { text: "Association Bodies", level: 3 },
      },
      {
        id: "e_yyFAM7l0",
        type: "header",
        data: { text: "Chemical Engineering Association (ChEA)", level: 5 },
      },
      {
        id: "4L95JlE7nH",
        type: "paragraph",
        data: {
          text: "<b>Chairman&nbsp;</b> &nbsp; :&nbsp; &nbsp; Dr. A. Sarath Babu – HOD",
        },
      },
      {
        id: "p2gvTj1PYR",
        type: "paragraph",
        data: {
          text: "<b>Staff Adviso</b>r&nbsp; &nbsp; :&nbsp; &nbsp; Dr. T. Sunil Kumar",
        },
      },
      {
        id: "T8nRXiIDXE",
        type: "paragraph",
        data: {
          text: "<b>Treasurer</b>&nbsp; &nbsp; &nbsp;:&nbsp; &nbsp; Dr. P. V. Suresh",
        },
      },
      {
        id: "QrUNoRsfqy",
        type: "paragraph",
        data: { text: "<b>Executive Body:</b><br>" },
      },
      {
        id: "SPCq40H_5c",
        type: "paragraph",
        data: { text: "<b>Secretary</b>" },
      },
      {
        id: "guZl9zEbGW",
        type: "paragraph",
        data: { text: "&nbsp; Mr. Aditya Sarjak Kantha, IV&nbsp;Year B.Tech" },
      },
      {
        id: "FYf6XENqoG",
        type: "paragraph",
        data: { text: "<b>Additional Secretaries&nbsp;</b>&nbsp; " },
      },
      {
        id: "fWbEMN9t2q",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;Mr. Aritra Bhattacharya, IV Year B.Tech&nbsp;",
        },
      },
      {
        id: "JoHa8hHzZi",
        type: "paragraph",
        data: { text: "Mr. Arindam Malakar, IV Year B.Tech " },
      },
      {
        id: "QCP5f0JRKt",
        type: "paragraph",
        data: { text: "Ms. Ranul Jain, III&nbsp;Year B.Tech" },
      },
      {
        id: "r739QbQLaX",
        type: "paragraph",
        data: { text: "<b>Joint Secretaries </b>" },
      },
      {
        id: "_ryJTnJXaa",
        type: "paragraph",
        data: { text: "Mr. Milind Sheth, III Year B.Tech " },
      },
      {
        id: "imuaOTyp7j",
        type: "paragraph",
        data: { text: "Ms. Arpita Biswas, III Year B.Tech" },
      },
      {
        id: "3Adg5m4Dly",
        type: "paragraph",
        data: { text: "&nbsp;Ms. Jahnawi Priya, III Year B.Tech" },
      },
      {
        id: "80hxpDHorG",
        type: "paragraph",
        data: { text: "<b>Executive Members&nbsp;</b>" },
      },
      {
        id: "TFfEc73e1o",
        type: "paragraph",
        data: { text: "Ms. D. Renuka Sai, III Year B.Tech" },
      },
      {
        id: "giuk4_PnnL",
        type: "paragraph",
        data: { text: "Ms. T. Ragamaye, III Year B.Tech&nbsp;" },
      },
      {
        id: "rmVXqh_YH-",
        type: "paragraph",
        data: { text: "Ms. J. Meenakshi, III Year B.Tech" },
      },
      {
        id: "4wuuqz-LRV",
        type: "paragraph",
        data: { text: "Mr. K. Pramod Kumar, III Year B.Tech" },
      },
      {
        id: "XvWKQU2IGn",
        type: "paragraph",
        data: { text: "Mr. Sachin Raj Soni,&nbsp;III Year B.Tech" },
      },
      {
        id: "9Eoh3kcEAi",
        type: "paragraph",
        data: { text: "Ms. Md. Sana, II Year B.Tech" },
      },
      {
        id: "MZmf6WInoT",
        type: "paragraph",
        data: { text: "Ms. Prathyusha Challa, II Year B.Tech" },
      },
      {
        id: "shD-SXKRrQ",
        type: "paragraph",
        data: { text: "Ms. J. Lakshmi Kanthi, II Year B.Tech" },
      },
      {
        id: "mxo8wUNrda",
        type: "paragraph",
        data: { text: "Mr. M. Siva Sai Reddy, II Year B.tech" },
      },
      {
        id: "bT7gZlOTzO",
        type: "paragraph",
        data: { text: "Mr. Mordia Prafull, II Year B.Tech&nbsp;" },
      },
      {
        id: "FOeL0gJW-J",
        type: "paragraph",
        data: { text: "Mr. Joseph Kurian, II Year B.Tech&nbsp;" },
      },
      {
        id: "PLZwnWcdCe",
        type: "paragraph",
        data: { text: "Ms. Shubhangi Verma, II Year B.Tech" },
      },
      {
        id: "deEkSDeTON",
        type: "paragraph",
        data: { text: "Mr. Himanshu Kashyap, II Year M.Tech" },
      },
      {
        id: "Y1eIpM-epx",
        type: "paragraph",
        data: { text: "Mr. Abdulla Sheikh, Ph.D Scholar" },
      },
      {
        id: "21hdSmf_VO",
        type: "header",
        data: { text: "IIChE Student Chapter", level: 5 },
      },
      {
        id: "dPWNsSB_Ug",
        type: "paragraph",
        data: {
          text: "<b>Chairman&nbsp; &nbsp;</b> :&nbsp; &nbsp; Dr. A. Sarath Babu – HOD",
        },
      },
      {
        id: "Ltg2quMDCq",
        type: "paragraph",
        data: {
          text: "<b>Staff Advisor</b>&nbsp; &nbsp; :&nbsp; &nbsp; Dr. T. Sunil Kumar",
        },
      },
      {
        id: "f8FQ_-Lhnj",
        type: "paragraph",
        data: { text: "<b>Executive Body:</b>" },
      },
      {
        id: "fHtAH_DFHr",
        type: "paragraph",
        data: { text: "<b>Secretary&nbsp;</b>" },
      },
      {
        id: "pBLWtZuuKX",
        type: "paragraph",
        data: { text: "Mr. Soumik Mal, IV&nbsp;Year B.Tech" },
      },
      {
        id: "BHEv-2kcyI",
        type: "paragraph",
        data: { text: "<b>Joint Secretary</b>" },
      },
      {
        id: "kp2Bs6oMHy",
        type: "paragraph",
        data: { text: "Mr. Deepesh Sawhney, IV&nbsp;Year B.Tech" },
      },
      {
        id: "EA5w_zlXE9",
        type: "paragraph",
        data: { text: "<b>Treasurer</b>&nbsp;" },
      },
      {
        id: "p4bcGW6sSZ",
        type: "paragraph",
        data: { text: "&nbsp;Ms. B. Shruthi, III Year B.Tech" },
      },
      {
        id: "AQqq-7JwId",
        type: "paragraph",
        data: { text: "<b>Executive Members</b>" },
      },
      {
        id: "v_rr7S1DvP",
        type: "paragraph",
        data: { text: "Mr. K.M. Vishnu, IV&nbsp;Year B.Tech" },
      },
      {
        id: "upCYAcJc15",
        type: "paragraph",
        data: { text: "Ms. Apoorva Choudhary, III Year B.Tech" },
      },
      {
        id: "X0Hs6fxyCh",
        type: "paragraph",
        data: { text: "Mr. Arihant Chindalia, II Year B.Tech" },
      },
      { id: "D7645T1t7Q", type: "header", data: { text: "Alumni", level: 6 } },
      {
        id: "vhNyZvVg7s",
        type: "header",
        data: { text: "Contacts", level: 6 },
      },
      { id: "zbYn2xUXK8", type: "header", data: { text: "B.Tech.", level: 6 } },
      {
        id: "zd-a4Cwk8E",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2016/10/21/2012-16_chemical_list-1.xlsx">2012 - 2016 Batch</a>',
        },
      },
      {
        id: "FDuAHqaYgD",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2016/10/21/2011-15_chemical_list-1.xlsx">2011 - 2015 Batch</a>',
        },
      },
      {
        id: "tbHyWU8AF0",
        type: "paragraph",
        data: { text: "2010 - 2014 Batch" },
      },
      {
        id: "5W-1m1_i0K",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2016/10/21/2009-13_chemical_list-1.xlsx">2009 - 2013 Batch</a>',
        },
      },
      { id: "kgV75x5hhw", type: "header", data: { text: "M.Tech.", level: 6 } },
      {
        id: "NSgWUPff0b",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2016/10/21/mtech_2014-16_chemical_list-1.xlsx">2014&nbsp;- 2016 Batch</a>',
        },
      },
      {
        id: "gZaSCj2Md9",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2016/10/21/mtech_2013-15_chemical_list-1.xlsx">2013&nbsp;- 2015 Batch</a>',
        },
      },
      {
        id: "NXK7PckyGC",
        type: "paragraph",
        data: { text: "2012 - 2014 Batch" },
      },
      { id: "Lgi8BGbLm7", type: "header", data: { text: "Ph.D", level: 6 } },
      { id: "VTnZGGLYcN", type: "paragraph", data: { text: "Click here" } },
      { id: "8HEke6aYaA", type: "header", data: { text: "Support", level: 6 } },
      {
        id: "1M9YwsmmY2",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Anand Kumar Vyas Memorial Award –&nbsp;This award has been constituted by four classmates of late Mr. Anand Kumar Vyas who passed away during his final year of B. Tech at our institute during 1994-1995. Chemical Engineering Association confers Anand Kumar Vyas Memorial Award to the topper of final year B.Tech (Chemical) student during its valedictory function every year.",
            "The Department and the Chemical Engineering Association (ChEA) sincerely thank&nbsp;Dr. R. L. Uma Shankar, Alumnus and Topper of the first batch (1969) of B.Tech.&nbsp;Chemical Engineering, for donating an amount of Rs. 1,50,000/- (rupees one lakh only). As per his request, the amount has been kept in a deposit and the interest accrued is being used towards organizing guest lectures every year.",
          ],
        },
      },
      { id: "NyARgvWDKh", type: "header", data: { text: "News", level: 6 } },
      {
        id: "PG-4z1LEEs",
        type: "paragraph",
        data: {
          text: "Various Events are conducted round the year to have an Healthy Interaction among Alumni and Students.",
        },
      },
      {
        id: "1C6Kae6RxX",
        type: "paragraph",
        data: { text: "Here is a list of some of the events&nbsp; :-" },
      },
      {
        id: "X4M88eP_ZV",
        type: "list",
        data: {
          style: "unordered",
          items: ["<b>GOLDEN JUBLIE CELEBRATION:-</b>"],
        },
      },
      {
        id: "-QPIBhNkW5",
        type: "paragraph",
        data: {
          text: "Inaugral function of the activities of Chemical Engineering Association (ChEA) &amp; Indian Institute of Chemical Engineers (IIChE) was held. It was followed by the release of the Logo for Golden Jubliee of Chemical Engineering Department.&nbsp;K.Babu Rao(Alumnus of First Batch) was Guest of Honour.",
        },
      },
    ],
    language: "En",
    _id: "6172b45d97495479bedc4143",
    time: 1642836112579,
    version: "2.22.2",
    title: "Students",
    url: "/che/students",
    path: "/che",
    createdAt: "2021-10-22T12:53:49.798Z",
    updatedAt: "2022-01-22T07:21:48.168Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "WE2wDzHhn_",
        type: "header",
        data: { text: "Student Life", level: 3 },
      },
      {
        id: "ZFmIca7ycV",
        type: "paragraph",
        data: {
          text: "Campus life at&nbsp;NIT Warangal&nbsp;is vibrant and engaging.NITW&nbsp;is a fully residential campus with all students being provided on-campus accommodation spread across twenty hostel blocks (halls of residence) for men and two for women. Students have the facilities well furnished kitchen and dining facilities, canteens, a reading room, LAN connectivity in the rooms along with sprawling lawns.Students thus have a very cosy oncampus stay where life-long friendships are formed.",
        },
      },
      {
        id: "6SgZCYGQiO",
        type: "paragraph",
        data: {
          text: "Campus life teems with the exuberance and vitality of youth.Proximity to faculty helps create collaborative situations where students can learn from their teachers outside the classroom setting. Students also express their talents through membership and participation in activities of various clubs such as aerospace, music,lan-radio, photography amongst many others.&nbsp;Apart from helping students explore their interests, the clubs also helps foster a sense of responsibility.",
        },
      },
      {
        id: "ye9vbApeoy",
        type: "paragraph",
        data: {
          text: "There exists a very liberal campus environment for students to discover and explore their inner calling. The guiding philosophy is to treat them as adults and give them the flexibility to make their own choices from the very beginning, which naturally matures them into responsible individuals balancing professional and personal pursuits.",
        },
      },
    ],
    language: "En",
    _id: "6172b50675d8e29daf62afa4",
    time: 1634907398051,
    version: "2.22.2",
    title: "Student Life",
    url: "/che/student-life",
    path: "/che",
    createdAt: "2021-10-22T12:56:38.890Z",
    updatedAt: "2021-10-22T12:56:38.890Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "xsmRp1__0P",
        type: "header",
        data: { text: "WELCOME TO CIVIL DEPARTMENT", level: 3 },
      },
      {
        id: "8MfeYudGVM",
        type: "header",
        data: { text: "DEPARTMENT OF CIVIL ENGINEERING", level: 5 },
      },
      {
        id: "5fn7kIHfoD",
        type: "paragraph",
        data: {
          text: "Civil engineering is a broad field of engineering that deals with the planning, construction, and maintenance of fixed structures, or public works, as they are related to earth, water, or civilization and their processes. Most civil engineering today deals with power plants, bridges, roads, railways, structures, water supply, irrigation, the natural environment, sewer, flood control, transportation and traffic. In essence, civil engineering may be regarded as the profession that makes the world a more agreeable place in which to live.",
        },
      },
      {
        id: "DuTFidnanl",
        type: "header",
        data: { text: "Divisions", level: 3 },
      },
      {
        id: "rQmnMI5ZpL",
        type: "paragraph",
        data: {
          text: "The department of Civil Engineering contains 4 divisions",
        },
      },
      {
        id: "K3v1055Cof",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Structures division",
            "Geotechnical division",
            "Transportation division",
            "Water Resources and Environmental division",
          ],
        },
      },
      {
        id: "qQ_eIAsfcn",
        type: "header",
        data: { text: "Vision and Mission", level: 3 },
      },
      { id: "nJ_N4zyaQb", type: "header", data: { text: "Vision", level: 5 } },
      {
        id: "jJdE-LftlB",
        type: "paragraph",
        data: {
          text: "To be a knowledge nerve centre in civil engineering education, research, entrepreneurship and industry outreach services for creating sustainable infrastructure and enhancing quality of life.",
        },
      },
      { id: "a-7fP2WSGp", type: "header", data: { text: "Mission", level: 5 } },
      {
        id: "piUfFqLUnP",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Generate a specialized cadre of civil engineers by imparting quality education and training",
            "Attain international standards in teaching, research and consultancy with global linkages",
          ],
        },
      },
      {
        id: "LS2T2wacH0",
        type: "header",
        data: { text: "History and Milestones", level: 3 },
      },
      {
        id: "s94s4YS_3m",
        type: "paragraph",
        data: {
          text: "The Department of Civil Engineering has been one of the pioneering academic departments of the National Institute of Technology, Warangal since the inception of the institute in 1959. The Department has well qualified, experienced and motivated faculty members. The Department offers the following programmes.",
        },
      },
      {
        id: "NcL01hQ48i",
        type: "paragraph",
        data: { text: "<b>Programmes</b>" },
      },
      {
        id: "TONcv5BmHD",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "B.Tech",
            "M.Tech. (Construction Technology and Management)",
            "M.Tech. (Engineering Structures)",
            "M.Tech. (Environmental Engineering)",
            "M.Tech. (Geotechnical Engineering)",
            "M.Tech. (RS &amp; GIS)",
            "M.Tech. (Transportation Engineering)",
            "M.Tech. (Water Resources Engineering)",
          ],
        },
      },
      {
        id: "-LRIPDP41H",
        type: "paragraph",
        data: {
          text: "50 percent of the undergraduate students are from the State of Andhra Pradesh and the remaining 50% are from different States. These students are selected based on AIEEE. The M.Tech students are selected based on their performance in GATE.",
        },
      },
      {
        id: "CDEFHGFXjx",
        type: "paragraph",
        data: {
          text: "For administrative reasons, the Department has been divided into four divisions, depending on the specialisation, viz., Engineering Structures Division, Geotechnical Engineering Division, Transportation Division and Water &amp; Environment Division.",
        },
      },
      {
        id: "nTbbsaYFX6",
        type: "paragraph",
        data: {
          text: "<b>QIP Centre:</b>&nbsp;The Department is one of the centers recognised by the Ministry of Human Resources Development, Government of India, for the engineering college teachers to pursue their M.Tech. and Ph.D. programmes under the Quality Improvement Programme, since 1978.",
        },
      },
      {
        id: "eDTB27K_rL",
        type: "paragraph",
        data: {
          text: "<b>Centres of Excellence:</b>&nbsp;Realising the excellent work done by the Department, the following two centres of excellence have been set up in the Department.",
        },
      },
      {
        id: "T3zoKbCRLy",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Centre for Transportation Engineering",
            "Centre for Water Management",
            "Centre for Rural Development",
            "centre for Advanced Geospacial Solutions",
            "Centre for Sustainable Environmental Management",
          ],
        },
      },
      {
        id: "obevcn-E_Q",
        type: "paragraph",
        data: {
          text: "<b>Research and Consultancy:</b>&nbsp;The Department has a large number of research projects sponsored by MHRD, AICTE, UGC, CSIR, DST, MOUA, MOST, CWC, CRRI, CBIP, R&amp;D wings of AP State Government undertakings. Many public sector undertakings like NTPC, RITES, Singareni Collieries etc. and private companies like Gammon India Ltd.,., L&amp;T etc. consult the Department for technical advice. The Department takes pride in contributing the major share of funds raised through the ICC of the institute. The Department has also excellent rapport with various State government Departments like I&amp;CAD, Public Health, Pollution Control Board, R&amp;B, Groundwater, PR and Central Government bodies like MORTH, BIS, Ministry of Urban Affairs, Ministry of Water Resources, Ministry of Rural Development and several municipal bodies and UDAs.",
        },
      },
      {
        id: "RBZE2qrIAB",
        type: "paragraph",
        data: {
          text: "<b>Continuing Education, Workshops and Seminars:</b>&nbsp;The Department has taken leading role in organising a number of continuing education programmes for the field engineers and teachers of engineering colleges. A number of Seminars and conferences also has been successfully organised by the Department to provide a platform for academicians and engineers to share and update their knowledge.",
        },
      },
      {
        id: "V9gq9Retea",
        type: "paragraph",
        data: { text: "<b>Milestones</b>" },
      },
      {
        id: "zoHm_1W02f",
        type: "paragraph",
        data: {
          text: "1959&nbsp;B.Tech. (Civil Engineering) was started with an intake of 50 students1964&nbsp;M.Tech. (Soil Mechanics &amp; Foundation Engineering) was started1964&nbsp;M.Tech. (Hydraulic Engineering) was started1967&nbsp;M.Tech. (Structural Engineering) was started1968&nbsp;M.Tech. (Transportation Engineering) was started1978&nbsp;B.Tech. (Civil Engineering) (For Diploma Holders (FDH)) was started with an intake of 40 students1978&nbsp;Recognised as Quality Improvement Programme (QIP) Centre for M.Tech. and&nbsp; Ph.D. .1996&nbsp;Graded as A+&nbsp; for both U.G and P.G Courses by NBA2000&nbsp;M.Tech. Remote Sensing and Geographic Information System2003&nbsp;B.Tech. (Civil Engineering) (FDH) was closed2005&nbsp;M.Tech (Environmental Engineering) was started2008&nbsp;M.Tech (Construction Technology &amp; Management) was started",
        },
      },
    ],
    language: "En",
    _id: "6172baecbd3e7d9da04a94de",
    time: 1634908907511,
    version: "2.22.2",
    title: "Home",
    url: "/ce/home",
    path: "/ce",
    createdAt: "2021-10-22T13:21:48.461Z",
    updatedAt: "2021-10-22T13:21:48.461Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "B5M36ZnfM9",
        type: "header",
        data: { text: "Overview About Courses", level: 3 },
      },
      {
        id: "9K1Nn6rfOT",
        type: "paragraph",
        data: {
          text: "The Department of Civil Engineering has been one of the pioneering academic departments of the National Institute of Technology, Warangal since the inception of the institute in 1959. The Department offers the following programmes.",
        },
      },
      {
        id: "U7KRRGva25",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "S. No",
              "Programme",
              "Duration",
              "Intake",
              "Admission Criteria",
              "Year of Start",
            ],
            [
              "1",
              "B.Tech. (Civil Engineering)",
              "4 years",
              "92",
              "AIEEE",
              "1959",
            ],
            [
              "2",
              "M.Tech. (Construction Technology &amp; Management)",
              "2 years",
              "20",
              "GATE",
              "2008",
            ],
            [
              "3",
              "M.Tech. (Engineering Structures)&nbsp;&nbsp;",
              "2 years",
              "38",
              "GATE",
              "1967",
            ],
            [
              "4",
              "M.Tech. (Environmental Engineering)",
              "2 years",
              "20",
              "GATE",
              "2004",
            ],
            [
              "5",
              "M.Tech. (Geotechnical Engineering)",
              "2 years",
              "20",
              "GATE",
              "1964",
            ],
            ["6", "M.Tech. (RS&amp;GIS)", "2 years", "20", "GATE", "2000"],
            [
              "7",
              "M.Tech. (Transportation Engineering)",
              "2 years",
              "38",
              "GATE",
              "1968",
            ],
            [
              "8",
              "M.Tech. (Water Resources Engineering)",
              "2 years",
              "20",
              "GATE",
              "1964",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "6172bcbb48df11c83028d55a",
    time: 1634909370109,
    version: "2.22.2",
    title: "Academics",
    url: "/ce/academics",
    path: "/ce",
    createdAt: "2021-10-22T13:29:31.006Z",
    updatedAt: "2021-10-22T13:29:31.006Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "VIs5932097",
        type: "header",
        data: { text: "Ongoing Projects", level: 3 },
      },
      {
        id: "ZEDVQ4Sq0F",
        type: "paragraph",
        data: {
          text: "The following are the ongoing R&amp;D Projects in the Department of Civil Engineering.",
        },
      },
      {
        id: "kuv5T4SGRY",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Centre for Urban Transportation Studies (CUTS) – AN Centre of Excellence in Urban Transport, sponsored by MOUD, GOI, New Delhi (2009-2013) Rs. 5.30 crores. .",
            "Sealing of Gravel Roads – Performance Evaluation, sponsored by NRRDA, MoRD, GOI, New Delhi (2009-2013) Rs. 19.50 lakhs",
            "Pilot research projects on a) Optimization of bus route networks in Hyderabad city b) Corridor improvement through Micro simulation; sponsored by PTV Vision, Germany (2012) Euro 15,000",
            "Application of coir geotextiles in rural roads: Performance evaluation, sponsored by Coir Board, Cochi and NRRDA, MoRD, GOI, New Delhi (2011-2015) Rs 1.10 crores",
            "Lake dynamics and their interaction with aquifers at Warangal city by using isotope studies (2011-2013: Rs. 19.81 Lakhs) (Coordinator:&nbsp; Dr. E. Venkata Rathnam), sponsored by CWRDM (Centre for Water Resources Development and Management), Kozhikode, Kerala.",
            "Fly ash to control acid/alkali induced volume changes in soils, sponsored by DST-SERB (2013-2016) Rs. 25.00 lakhs",
            "Effect of aging on the mechnaical response of asphalt binders, sponsored by DST-SERB (2013-2016) Rs. 20.90 lakhs",
          ],
        },
      },
      {
        id: "pISNvM3AX0",
        type: "header",
        data: { text: "Completed Projects", level: 3 },
      },
      {
        id: "SJm4JFkEll",
        type: "paragraph",
        data: {
          text: "The Department of Civil Engineering has successfully completed the following R&amp;D projects, being sponsored by various funding agencies.",
        },
      },
      {
        id: "WFkPXnMQFl",
        type: "header",
        data: { text: "RESEARCH AREA: ENGINEERING STRUCTURES", level: 5 },
      },
      {
        id: "M1VOweyhTh",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "High Quality Low Cost Ferro Cement Housing – MHRD Sponsored R&amp;D Project",
            "Performance Evaluation and Analytical Modelling of Ferrocement Skin Element under Torsion (2004 to 2007), sponsored by DST (12.26 lakhs)",
            "Structural Properties of high volume fly ash concrete, Sponsored by AICTE under TAPTEC Scheme, 2001 – 2004 (Rs. 7.50 lakhs)",
            "Effect of Lateral Rein-forcemeat on strength and deformation of RC columns with different slenderness Ratio, UGC Grants, 1978-80 (0.42 Lakhs).",
            "Prestressed concrete box girders in tortion, bending, bending and tortion and bending and shear, UGC Grants, 1978-80 (0.50 Lakhs).",
            "Optimisation in RC Structures, Ministry of Education and Culture, 1979-81 (0.25 lakhs)",
            "Investigation of Strength and Deformation of Lime/Pozzolona/Cement Mortars, CSIR Grants, 1981-82 (0.50 Lakhs).",
            "Design and construction of Ferrocement stressed skin surface, CSIR Grants, (1.25 Lakhs).",
            "Study of the complete behavior of RCC slabs using welded wire fabric as reinforcement, UGC Grants, 1986-88 (0.57 Lakhs).",
            "Analytical and Experimental study of prediction of crack widths in RC Roof elements, UGC Grants, 1987-89 (0.05 Lakhs).",
            "A Multipurpose ferrocement element for rural applications, APCOST Grants, 1993-95 (0.50 Lakhs).",
            "Development of Prototype testing facility for low cost Ferrocement Structural elements, AICTE Grants, 1993-95, (5.00 Lakhs).",
            "Design methodology for high strength silica fume concrete mixes, INCCMS Grants, 1994-96 (3.25 Lakhs).",
            "Strengthening of RC beams with Ferrocement jacketing, INCCMS Grants, 1996-98 (3.50 Lakhs).",
            "A Study on the effects of High volume Fly ash on the Structural properties of Concrete, MHRD Grants, 2000-02 (7.00 Lakhs).",
            "Performance evaluation and analytical modeling of ferrocement skin elements under torsion, DST Grants, 2003-05 (10.87 Lakhs).",
            "Structural Dynamics Laboratory Development Grant, NPEEE Grants, 2004-06 (15.00 Lakhs).",
            "High Quality Low Cost Ferrocement Housing, sponsored by M.H.R.D., New Delhi.",
          ],
        },
      },
      {
        id: "ZtlrESe62D",
        type: "header",
        data: { text: "RESEARCH AREA: GEOTECHNICAL ENGINEERING", level: 5 },
      },
      {
        id: "9HfE2DkZbr",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "High Quality Low Cost Ferrocement Housing, sponsored by M.H.R.D., New Delhi.",
          ],
        },
      },
      {
        id: "Osh8UrTwIW",
        type: "header",
        data: { text: "RESEARCH AREA: RS &amp; GIS", level: 5 },
      },
      {
        id: "QIVIjsz3wZ",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Ground water Recharge using RS &amp; GIS Approach, sponsored by M.H.R.D., New Delhi",
          ],
        },
      },
      {
        id: "G_CNqrsS9K",
        type: "header",
        data: { text: "RESEARCH AREA: TRANSPORTATION ENGINEERING", level: 5 },
      },
      {
        id: "EbRj7vAI-m",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Accident Analysis in Hyderabad City, sponsored by Ministry of Home Affairs; sponsored by GoAP, Hyderabad",
            "Analysis, Modelling and Management of Air Pollution due to Motor Traffic Under Multimodal Environment”, R&amp;D Project Sponsored by MHRD, New Delhi. 2000-2003. (Rs. 5.00 lakhs).",
            "Centre for Transportation Engineering; sponsored by MHRD, GOI, New Delhi",
            "Development of Landuse Transportation Demand Model for the Spatial Allocation of Activities and Landuse in Metropolitan Areas; sponsored by Ministry of Education, GOI, New Delhi",
            "Development of Optimal Road Network Patterns in Andhra Pradesh; sponsored by Ministry of Education, GOI, New Delhi",
            "Development of Standards for Highway Capacity under Mixed Traffic Conditions; sponsored by UGC, New Delhi",
            "Effect of Traffic Workshop on the Behaviour of Traffic in Hyderabad City; sponsored by CSIR, GOI, New Delhi",
            "Modelling Urban Growth with Decision Support System; sponsored by MHRD, GOI, New Delhi",
            "Modernisation and Upgradation of Centre for Transportation Engineering, Level 1 FIST Project sponsored by DST, New Delhi. 2003-2008. (Rs. 40.00 lakhs)",
            "Permanent Traffic Counting Station at Regional Engineering College, Warangal, sponsored by R&amp;B Department; sponsored by GoAP, Hyderabad",
            "Public Transportation in Urban Areas: Bus Routes Optimization and Scheduling; sponsored by CSIR, GOI, New Delhi",
            "R-11: Transportation Modelling on Regional Basis for Trunk Route System; sponsored by MOSRT&amp;H, GOI, New Delhi",
            "R-13: Study of Road Intersections under Mixed Traffic Conditions; sponsored by MOSRT&amp;H, GOI, New Delhi",
            "R-2 Phase-II: Weigh-in-Motion (WIM) Axle Load Spectral Analysis; sponsored by MOSRT&amp;H, GOI, New Delhi",
            "R-3 Phase-III Establishment of Permanent Traffic Counting Stations on&nbsp;&nbsp; National Highways in Andhra Pradesh; sponsored by MOSRT&amp;H, GOI, New Delhi",
            "R-43: Trip Length Frequency Analysis for Commodity Carriers in AP, sponsored by MOSRT&amp;H, GOI, New Delhi",
            "Road user cost study on National Highways in the state of AP: Data collection; sponsored by CRRI, New Delhi",
            "Rural road pavement performance study, sponsored by NRRDA, MoRD, GOI, New Delhi",
            "Safety in State Road Transport Undertakings; AICTE, GOI, New Delhi",
          ],
        },
      },
      {
        id: "mSa14ncHVw",
        type: "header",
        data: {
          text: "RESEARCH AREA:WATER RESOURCES &amp; ENVIRONMENTAL ENGINEERING",
          level: 5,
        },
      },
      {
        id: "FrSutpzSvf",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Neuro-Fuzzy Adaptive System for River Basin Management – Sanctioned by the REC,Warangal.",
            "Rainwater Management in Hard Rock Terrain Using Remote Sensing and GIS (2004 to 2007), sponsored by MHRD (Rs. 15.00 lakhs)",
            "Urban Storm Water Modelling and Transport Requirements for Hyderabad City; AICTE, GOI, New Delhi",
            "Watershed Development Project (1996 to 2005), sponsored by Ministry of Rural Development through District Rural Development Agency (Rs. 400.00 lakhs)",
            "Solid Waste Management Studies; sponsored by MHRD, GOI, New Delhi",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173c49ce2d843c868aa6986",
    time: 1634976923672,
    version: "2.22.2",
    title: "R & D",
    url: "/ce/research",
    path: "/ce",
    createdAt: "2021-10-23T08:15:24.458Z",
    updatedAt: "2021-10-23T08:15:24.458Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "S1a9_m9D1j",
        type: "header",
        data: { text: "Ongoing Programmes", level: 3 },
      },
      {
        id: "KwfPIE2ZIM",
        type: "paragraph",
        data: { text: "<b>Ongoing Industrial extension services:</b>" },
      },
      {
        id: "Krkt63fiUf",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Development of road safety manual for R &amp; B engineers, sponsored by R&amp;B Department, GoAP, Hyderabad",
            "Rural Road GIS for Warangal District, sponsored by PR Engineering Department, GoAP, Hyderabad",
            "Development of tools kits for a) Traffic measurements and Analysis, and b) Highway capacity and level of service and traffic system design for urban roads; sponsored by IUT, MoUD, GOI, New Delhi (GEF and World Bank funded project – SUTP)",
            "Upgradation&nbsp; Design of Effluent Treatment Plant for Tannery Wastewater (Client: Classic Tanneries, Warangal, Cost:1.2 Lakhs).",
            "Vetting and&nbsp;Hydraulic design&nbsp;of Barrages across River Mahanadii. There are totally 5 barrages of worth of Rs. 250 Crores. (50 lakhs).",
          ],
        },
      },
      {
        id: "cnKYTJYg6-",
        type: "header",
        data: { text: "Completed Programmes", level: 3 },
      },
      {
        id: "N3w5BLyq8z",
        type: "header",
        data: { text: "Broad Area: Structural Engineering", level: 5 },
      },
      {
        id: "3evJhRHmne",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Assessment of Fire damaged Storage godown, Korutla Godowns (1995).",
            "Assessment of Strength of Canal Lining Non Destructive Tests for Sri Ram Sagar Project.",
            "Assessment of Strength of Cement Concrete Pavement.",
            "Design of Administrative, Hostel Buildings, Kakatiya University, (1998).",
            "Design of anchor bolts for colliery mines, Singareni Collieries (1993).",
            "Design of Bridge at Chennur for Singareni Collieries.",
            "Design of Chock Shields (Underground Mine &amp; Support System), APHMEL, Vijayawada(2003).",
            "Design of grid floor for Vemulawada temple choultry, Endowments Department&nbsp; Vemulawada (1994).",
            "Design of RCC Flow Chamber for Ash Pond Fertiliser Corporation of India, Ramagundem.",
            "Design of RCC Flow Chamber for Ash Pond, FCI, Ramagundam, (2002).",
            "Design of Support System for Conveyor at Fertiliser Corporation of India, Ramagundem (2002)",
            "Detailed design of Municipal complex Adilabad, Municipality Adilabad (1991).",
            "Detailed design of scaffolding bridge for the coal handling plant, Singareni Collieries (1997)",
            "Determination of Strength of Steel Chokes and Props for Singareni Collieries",
            "Life assessment of Odalarevu bridge, ONGC (2004)",
            "Load Testing of Madepalli Bride, SCCL, GDK (2004).",
            "Material testing, Structural designs, Non destructive testing of Water tanks, market yard floors, canal linings, Concrete pavements etc.",
            "ND Testing of Bridge Pier at Chlpet National Highway, NHAI &amp; R&amp;B (2003).",
            "NDT of Bridge Abutments at Tuni/The Evaluation of Blast/Fire Damaged buildings, R&amp;B/ Departments (2002).",
            "NDT of Bridge pier cap/Concrete Mix designs/Structural Evaluation, NHAI/Indian Railways (1999).",
            "Planning and Design of Bridge Across Panchanadi Drain, ONGC, Rajahmudry.",
            "Proof Design of Blast fence/Mix designs, IAL/Government Department (2001)",
            "Site Investigation for Bridge Location at Chittakodur and Uggampalli, sponsored by Roads &amp; Buildings Department, Govt. of A.P.",
            "Structural Design of Multi Storied Structures.",
            "Structural Design/Concrete Mix designs, Central/State Department (2005).",
            "Structural designs of council hall, Municipality of Kothagudem (1992).",
            "Structural Designs, KU, Warangal/Charity Institutions, (2000).",
            "Testing of Bridges for Roads and Buildings &amp; Singareni Collieries",
            "Testing of Mine supporting systems for Singareni Collieries",
            "Testing of props and chocks for mine supports systems, Singareni Collieries (1996)",
          ],
        },
      },
      {
        id: "SNgvl_DZcI",
        type: "header",
        data: { text: "Broad Area: Geotechnical Engineering", level: 5 },
      },
      {
        id: "KDeIvf4OJf",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Geotechnical Investigation and Design of Flood Banks Along the River Godavari at Srirampur and Manuguru Coal Mines, Singareni Collieries Co. Ltd.",
            "Geotechnical Investigation and Testing for Construction of FLOOD BANKS at Eturunagaram, Ramagundam and Bhadrachalam (for A.P. Irrigation Dept. and Singareni Colleries Co. Ltd.).",
            "Geotechnical Investigation for SEWAGE TREATMENT PLANT at Hyderabad.",
            "Geotechnical Investigation for SUMMER STORAGE TANKS at Hasanparthy, Katrial and Khammam.",
            "Geotechnical Investigation for the failure of earth dam of GUNDLAVAGU Project.",
            "Geotechnical investigation works pertaining to major and minor projects for public sector and private sectors like ONGC, NTPC, SCCL, KTPS, I&amp;CAD, R&amp;B, Panchayat Raj, ITDA etc.",
            "Geotechnical Investigations and Technical Suggestions for the Formation of Irrigation Tanks under Godavari and Kaleswaram Lift Irrigation Schemes in Andhrapradesh.",
            "Geotechnical Investigations for embankment at Hyderabad airport.",
            "Geotechnical Investigations for Flood Banks at Ramagundam, Bhadrachalam and Eturnagaram",
            "Geotechnical Investigations for Sewage Treatment Plant at Hyderabad",
            "Geotechnical Investigations for Summer Storage Tanks in Telangana Region",
            "Integrated Development of K.T.P.S. ASH POND.",
            "Restoration of Kalyana Mandapam at Thousand Pillar Temple, Hanamkonda.",
            "Technical report for the correction of TILTED MINE shaft at Ramagundam, SCC Ltd.",
            "Technical Reports for the Construction of Roads under Pradhan Manthri Gram Sadak Yojana.",
            "Test Grouting for RALI VAGU IRRIGATION PROJECT, Adilabad District.",
          ],
        },
      },
      {
        id: "IvmNR84yXe",
        type: "header",
        data: { text: "Broad Area: Transportation Engineering", level: 5 },
      },
      {
        id: "QCxCo2biKi",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Alternative Proposal to the Road Diversion Scheme at Tirupathi, sponsored by TTD, Tirupathi",
            "Axle Spectrum Studies on State Highway in Tamil Nadu state, sponsored by M/S Scot Wilson (I) Pvt., Ltd.",
            "Bituminous Concrete Mix Design, sponsored by AFCONS, Hyderabad",
            "Comprehensive Traffic and Transportation Studies for Warangal, sponsored by KUDA.",
            "Database Studies – Hyderabad Area Transportation Study – Module I, sponsored by HUDA, Hyderabad.",
            "Design of Grade Separation Complex at Mozam Jahi Market, sponsored by Roads and Buildings Department, Govt. of A.P.",
            "Design of Signals in Hyderabad, sponsored by Traffic Police, Hyderabad",
            "Design of Street Lighting System on R.O.B. Kazipet, sponsored by Warangal&nbsp;&nbsp; Municipality, Warangal",
            "Development of Diversion Curves on Rau-Pithampur Road, Indore (M.P.), sponsored by BMRDA, Mumbai.",
            "Economic Feasibility of Light Rail Transit, sponsored by Industrial Liaison&nbsp; &amp; Financial Services, (IL&amp;FS), Bombay.",
            "Estimation of Demand for Suburban Train in Hyderabad, sponsored by HUDA, Hyderabad.",
            "Estimation of Traffic on the Proposed Vijayawada Hyderabad Expressway, sponsored by HUDA, RAO &amp; RAO Consultants, Hyderabad.",
            "Evaluation of the Failure of the Road between Kothagudem and Burgampad, sponsored by Roads &amp; Buildings Department, Govt. of A.P.",
            "Hyderabad Area Transportation Study (HATS), sponsored by HUDA, APSRTC and Municipal Corporation of Hyderabad.",
            "Location of Inland Port, Hyderabad, sponsored by Hyderabad Urban Development Authority, Hyderabad.",
            "Location of Truck Terminals in Warangal City, sponsored by Kakatiya Urban Development Authority, Warangal.",
            "LRT system of Hyderabad, sponsored by IL&amp;FS, Bangalore",
            "Necklace Road Project Evaluation of Alternate Pavement Design, sponsored by Hyderabad Urban Development Authority (HUDA), Hyderabad.",
            "Opinion Surveys of Flyover Usage in Hyderabad, sponsored by HUDA, Hyderabad",
            "Parking Studies at Tirumala, sponsored by Tirumala Tirupati Devasthanams, Tirupati",
            "Pavement Design and Quality Control Study for Roads in Mines. Sponsored by M/S Singareni Colleries Company Limited, Kothagudem.",
            "Pavement Evaluation and Load Spectrum Studies for Eluru to Rajahmundry NH, sponsored by Aarvee Associates, Hyderabad.",
            "Pavement Evaluation and Load Spectrum Studies for NH5- Visakhaptnam to Ichapuram section, sponsored by Sheladia/MRJ/ JV, Visakhaptnam",
            "Pavement Evaluation Studies between Gudihatnoor and Palakollu, sponsored by STUP Consultants, New Delhi.",
            "Pavement Evaluation Studies for Warangal – Karimnagar Road, sponsored by IRCON International, New Delhi",
            "Pavement Evaluation Studies of Hyderabad-Medak Road, sponsored by R&amp;B, Department, Govt. of Andhra Pradesh.",
            "Pavement Evaluation Study for Manpur – Bhanupratappur – Kanker – Mainpur – Debjhog road in the state of Chattisgarh, sponsored by M/S Capital Fortunes Ltd., Hyderabad.",
            "Rail Passenger Demand Estimation for Hyderabad City, sponsored by Rail India Techno Economic Services (RITES), New Delhi.",
            "Rail Passenger Demand Estimation for India through Link Volume Forecasting, sponsored by Rail India Techno Economic Services (RITES), New Delhi.",
            "Road Commuter Behaviour Study, sponsored by APSRTC, Hyderabad.",
            "Site Selection of International Airport at Hyderabad, sponsored by HUDA, Hyderabad",
            "Study of Access Problem to Andhra Sugars Limited, Tanuku, sponsored by Andhra Sugars, Tanuku",
            "Technical Advisory services to M/S Capital Fortunes Ltd., Hyderabad for Evaluation of DPRs prepared for many roads in the State of Madhya Pradesh",
            "Techno-Economic Feasibility Study of Proposed Flyovers in Vijayawada city, sponsored by VGTM – UDA, Vijayawada",
            "Techno-economical feasibility of Pedestrian Subways in Mumbai, sponsored by STUP Consultants, Mumbai.",
            "Techno-Economical Feasibility of State Highway in the state of Chattisgarh, Sponsored by M/S Capital Fortunes Ltd., Hyderabad",
            "Traffic and Transportation Studies in Tirupathi Town, sponsored by Tirumala Tirupati Devasthanams, Tirupati.",
            "Traffic Management proposals to Warangal, sponsored by Warangal Municipality, Warangal.",
            "Urban Settlement Planning for Hyderabad City, sponsored by Andhra Pradesh Council for Science &amp; Technology (APCOST), Hyderabad.",
          ],
        },
      },
      {
        id: "g7EsT7ENkm",
        type: "header",
        data: {
          text: "Broad Area: Water Resources &amp; Environmental Engineering",
          level: 5,
        },
      },
      {
        id: "3GfXCNBDxD",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Calibration of Water Current Meters (Client: Executive Engineer, I&amp;CAD, Karimnagar), (2010).",
            "Chemical analysis of Leaching Material of Lower Mannair Dam, Karimnagar District. (2009)",
            "Design of Over Head Service Reservoirs.",
            "Performance Evaluation of Effluent Treatment plant of M/s. BILT AP Rayons Unit, Kamalapuram, Warangal District (2007).",
            "Surge analysis and Design of Lift Irrigation Scheme of Dummagudem Project in Khammam District. (2006)",
            "Watershed development works in Warangal District, funded by DRDA, Warangal, Rs. 4 Crores (2002)",
            "Evaluation of Projects for CAPART",
          ],
        },
      },
      {
        id: "64kDuiCiBG",
        type: "header",
        data: { text: "Seminars/Conferences", level: 3 },
      },
      {
        id: "faEUaOqmLS",
        type: "paragraph",
        data: {
          text: "The following seminars and conferences are being organized by the department.",
        },
      },
      {
        id: "xyAurU0_DC",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "&nbsp;A study of change in dynamic characteristics of structural materials using Modal analysis, National Conference on ‘Materials and Structures’, 14-15th Dec 2007",
            "&nbsp;National Conference on Materials and Structures (MAST) during 2004 and MAST 2007 during December 14-15th, 2007",
            "&nbsp;Mechanical Properties of fiber reinforced self-compacting concrete using recycled aggregate from building demolished waste, National Conference on Materials and Structures(MAST 2007), Dec 14-15, 2007.",
            "&nbsp;Studies on compressive and tensile strengths of high volume flyash concrete using grout materials, National Conference on Materials and Structures(MAST 2007), Dec 14-15, 2007",
            "&nbsp;Investigations on impact and flexural behaviour of high volume Flyash concrete using grout materials, National Conference on Materials and Structures(MAST 2007), during Dec 14-15, 2007.",
            "&nbsp;Strength of Fiber Reinforced Self Compacting Concrete using Recycled Aggregate from Building Demolished Waste”, 2nd national conference on Materials and Structures (MAST), December 14-15th, 2007.",
            "&nbsp;A study on the effect of size of aggregate and fines on the properties of Ordinary grade Self Compacting Concrete”, 2nd National Conference on “Materials and Structures (MAST)”, 14–15 December 2007.",
            "&nbsp;A Study on The Influence of Rice&nbsp; Husk&nbsp; Ash on Fresh And Hardened Properties of&nbsp; Self Compacting Concrete, National Conference on ‘Materials and Structures’, 14-15th Dec 2007.",
            "&nbsp;Retrofitting- a state of the art, National Conference on Materials and Structures, Dec. 14-15, 2007",
            "&nbsp;Second National Conference on Materials and Structures (MAST) 14 – 15 December, 2007.",
            "&nbsp;Effect of Curing Conditions on the Mechanical Properties of Fly Ash Concretes, National Conference on Materials and Structures, 23rd – 24th January, 2004",
            "&nbsp;Reliability of axially loaded short columns, National Conference on Materials and Structures, N.I.T, Warangal, 23rd – 24th January, 2004",
            "&nbsp;National Conference on Materials and Structures (MAST) 23-24th January, 2004",
            "&nbsp;Indian Geotechnical Conference – 2004.",
            "&nbsp;Structural distress and Rehabilitation- Brief review, National Conference on ‘Materials and Structures’, 23-24th Jan 2004.",
            "&nbsp;Engineered Steel fibre reinforced concrete, National Conference on ‘Materials and Structures’ , 23-24th Jan 2004",
            "&nbsp;Ferrocement for confinement of RC-A Review, National Conference on ‘Materials and Structures’, 23-24th Jan 2004.",
            "&nbsp;A study on the effect of silica fume and super plasticizer in rich mortar mixes”, National Conference on Materials and Structures, 23-24 January 2004",
            "&nbsp;Effect of Shape and Texture of Coarse Aggregate on Compressive Strength of Concrete, National Conference on Materials and Structures, January 2004",
            "&nbsp;Some tests on reinforced concrete elements with ferrocement skin under torsional action, National Conference, MAST( Material and Structures), Jan 2004.",
            "&nbsp;Seminar on “Ground Improvement Methods” – 1998.",
            "&nbsp;Geo-textiles and Reinforced Earth” organised by Indian Geotechnical Society, Warangal during 30th and 31st August 1997.",
            "&nbsp;Mode Choice Model for Inter City Travel – A Case Study. Paper presented in Silver Jubilee Conference TETRA 97 during 23rd to 25th August 1997National Seminar on Geotextiles, 1997",
            "&nbsp;Seminar on “Geotextiles” – 1997.",
            "&nbsp;National Seminar on Expansive Soils, 1996",
            "&nbsp;Indian Geotechnical Conference, 1994",
          ],
        },
      },
      { id: "RGGtLdTiyt", type: "header", data: { text: "STTP", level: 3 } },
      {
        id: "-LBjYiYKE6",
        type: "paragraph",
        data: {
          text: "The following programs are being organized by the department.",
        },
      },
      {
        id: "VovecXvX73",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Project Management during 24-08-2009 to 30-08-2009",
            "Centering and Bar bending training for rural Boys during 08-09-2008 to 19-10-2008",
            "Design Of Experimental Setups For Engineering Practice (Desfep-08) during August 25-September 6, 2008",
            "Water Quality Modelling and Management’, June 16-21, 2008",
            "Flexible Pavement Engineering,” sponsored by National Institute of Technology, Rourkela from February 01 to 04, 2008.",
            "Construction Industry :&nbsp; Challenges and Opportunities during 30-07-2008-5-08-2008",
            "15 Six day Training Programmes on “Design, Construction and Maintenance of Rural Roads under PMGSY” to the PR Engineers of Andhra Pradesh between August 2004 and July 2005 (Participants – 546).",
            "Structural Steel design for university faculty, INSDAG, Kolkata,29th&nbsp;Nov to 4th&nbsp;Dec 2004 (One week)",
            "Two Weeks AICTE/ISTE Sponsored STTP on “Issues in Environmental Pollution due to Road Traffic Under Mixed Traffic Conditions” to Engineering College Teachers during 15-27 December 2003.",
            "Three day Training Programme on “Design, Construction and Maintenance of Rural Roads under PMGSY” to the PR Engineers of Andhra Pradesh during 25-27 October 2003.",
            "Neuro-Fuzzy Applications in Civil Engineering’, May, 2001",
            "2 week short term course on Ground Improvement Methods in Problematic Soils during January 2001",
            "ISTE sponsored winter school on Irrigation Management during 2001.",
            "“V International Course on Urban Drainage in Developing Countries”, cosponsored by the German National Committee on International Hydrological Programme of UNESCO and the Operational Hydrology Programme of WMO. This was followed up with a follow up course. The course was conducted by an international team of resource persons drawn from USA, Canada, Germany, The Netherlands and India.",
            "Ground Improvement Methods in Problematic Soils” in January 2001",
            "Three day&nbsp;Induction Training Programme for Teachers of Engineering Colleges, during 13-15 September 1997.",
            "Concrete technology for Department Engineers under P.M Jawahar Rojgar Yojana",
            "Water Resources Systems Planning, Analysis and Management’, May, 1992.",
          ],
        },
      },
      {
        id: "JBmPKYSXWu",
        type: "header",
        data: { text: "Workshops", level: 3 },
      },
      {
        id: "HiKfQrOAqP",
        type: "paragraph",
        data: {
          text: "The following workshops are being organized by the department.",
        },
      },
      {
        id: "0kEAO07V9V",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Two day workshop on “Emerging Trends in Water Supply Sector”, from 13th&nbsp;to 14th&nbsp;April 2012. Sponsored by TEQIP Phase II",
            "A Three Day Workshop on “Advances in Flexible Pavement Design,” from March 15 to 17, 2012. Sponsored by TEQIP Phase II (64 participants)",
            "One day Workshop on “Geotechnical Engineering in Practice” on 4th&nbsp;March 2012. Sponsored by TEQIP Phase II.",
            "Design of experimental setups for engineering practice” on 02.09.2008",
            "One Day Workshop on “Sealing of Gravel Roads” to PR Engineers of GoAP on 9th&nbsp;February 2008. Sponsored by Shell Bitumen India Private Limited, New Delhi (Participants – 98)",
            "Two Day Workshop on “Construction Industry: Opportunities and Challenges” to the faculty of Engineering Colleges and practicing Engineers during 31st&nbsp;January to 1st&nbsp;February 2008. Sponsored by TEQIP. (Participants – 31)",
            "One Day Workshop on “Best Design Practices in Construction of Rural Roads” to the Panchayat Raj Engineers of GoAP on 11th&nbsp;January 2008. (Participants – 120)",
            "Three day Workshop (Train the Trainer Program) on “Autodesk Software Solutions” to the Faculty of Southern India Engineering Colleges during 2nd&nbsp;May to 4th&nbsp;May 2007, sponsored by AutoDesk India Pvt. Ltd., Bangalore. (Participants – 30)",
            "Basic Properties of Bitumen,” Workshop on Recent Advances in Bitumen Technology for Engineers of SCCL at Ramagundam, Andhra Pradesh on February 07, organized by Singareni Collieries Company Limited (SCCL) in association with Shell Bitumen",
            "One Day Workshop on “Role of Community in Construction and Maintenance of Rural Roads” to the People’s Representatives of AP on 30thDecember 2006. Sponsored by TEQIP (Participants – 78)",
            "National Workshop on Waste Management in Urban Areas, September 24-25, 2004.",
            "44th&nbsp;Congress of Indian Society of Theoretical and Applied Mechanics” during 22nd&nbsp;to 25th&nbsp;December 1999",
            "Workshop on “Durability of Concrete – Role of Chemical Admixtures” FOSROC Chemicals (India) Ltd., Hyderabad on 14thFebruary 1997.",
            "Corrosion of steel reinforcement’, Workshop on durability of concrete – role of chemical admixtures, Feb. 1997",
            "One day Workshop on ‘Urbanization – A Case Study of Warangal City’, December, 1996",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173c63fe80eacc88579dcb0",
    time: 1634977378905,
    version: "2.22.2",
    title: "Consultancy",
    url: "/ce/consultancy",
    path: "/ce",
    createdAt: "2021-10-23T08:22:23.121Z",
    updatedAt: "2021-10-23T08:22:59.792Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "02w8KwTWza", type: "header", data: { text: "Staff", level: 3 } },
      {
        id: "PZtSGd5uVu",
        type: "header",
        data: {
          text: "The Department of Civil Engineering is having the following supporting staff.",
          level: 5,
        },
      },
      {
        id: "X_HXQuwnB7",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["NAME", "DESIGNATION", "OFFICE"],
            ["Sri P. Ashok Kumar", "LD Steno", "2101"],
            ["Sri T. SiddiRamulu", "Mech A Grade", "2161"],
            ["Sri M. Narasimha", "Mech A Grade", "2162"],
            ["Sri K. Ramesh (Jr)", "Mech A Grade", "2166"],
            ["Sri A. Laxman", "Mech A Grade", "2165"],
            ["Sri K. Purushotham", "Mech B Grade", "2163"],
            ["Sri N. Sudhakar", "Mech B Grade", "2165"],
            ["Sri S. Venugopal", "Mech B Grade", "2167"],
            ["Sri A. Chandranarayana", "Mech C Grade", "2162"],
            ["Sri T. Balaswamy", "Mech C Grade", "2162"],
            ["Sri V. Shiva Prasad", "Store Assistant", "2161"],
            ["Sri Mohd Abdul Gafar", "Store Assistant", "2163"],
            ["Sri A. Srinivas (Sr)", "Technical Helper", "2165"],
            ["Sri P. Palaiah", "Technical Helper", "2162"],
            ["Smt. J. Rajamma", "Technical Helper", "2161"],
          ],
        },
      },
    ],
    language: "En",
    _id: "6173c7ebbd3e7d9da04a94e0",
    time: 1634977770567,
    version: "2.22.2",
    title: "People",
    url: "/ce/people",
    path: "/ce",
    createdAt: "2021-10-23T08:29:31.611Z",
    updatedAt: "2021-10-23T08:29:31.611Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "1ETFp-QHnC",
        type: "header",
        data: {
          text: "HEARTY WELCOME TO THE DEPARTMENT OF CHEMISTRY NIT WARANGAL",
          level: 3,
        },
      },
      {
        id: "9CedxWTdtF",
        type: "paragraph",
        data: { text: "Greeting of the Day!" },
      },
      {
        id: "X_VFlmRmF_",
        type: "paragraph",
        data: {
          text: "I hope everyone is in good health and I pray to God to keep&nbsp;everyone safe.",
        },
      },
      {
        id: "Inpey9XPRZ",
        type: "paragraph",
        data: {
          text: "On behalf of the Department, it is my great pleasure to welcome you all to the Department of Chemistry, NIT Warangal, an Institution of National Importance. I’m delighted to see as you become a fellow member of our academic family. Of course, due to pandemic COVID-19 we are unable to welcome you physically, but in the prevailing situation we will use our technology to meet and greet each other and exploit the technology to meet our academic requirements. I hope you will discover over the next two years, what I have discovered in my 12 years at NIT Warangal: that you have entered a lively, challenging, diverse, and warm community.",
        },
      },
      {
        id: "V5Ru3lBCr5",
        type: "paragraph",
        data: {
          text: "To your parents, here I want to tell them your wards are there in safe hands and we will provide our best so they will make a difference in the world.",
        },
      },
      {
        id: "J5hCDvxcif",
        type: "paragraph",
        data: {
          text: "To you the students of 2020, welcome! I’m here to say a few introductory words about the Institute, as one of the nation’s leading academic Institutions and about our Department that is known for its academic and research output.",
        },
      },
      {
        id: "WDAdyEBdVm",
        type: "header",
        data: { text: "Institute at glance:", level: 5 },
      },
      {
        id: "-uqOMkeq06",
        type: "paragraph",
        data: {
          text: "National Institute of Technology, Warangal, formerly known as Regional Engineering College, was established in 1959. Pandit Jawaharlal Nehru laid the foundation stone for this institute on October 10, 1959, the first in the chain of 30 NITs (formerly known as RECs) in the country. The Institute is well known for its dedicated faculty, staff and the state-of-the art infrastructure conducive to a healthy academic environment. The Institute is constantly striving to achieve higher levels of technical excellence.Evolving a socially relevant and yet internationally acceptable curriculum, implementing innovative and effective teaching methodologies and focusing on the wholesome development of the students are our concerns.&nbsp; The Institute currently has thirteen academic departments and a few advanced research centres in various disciplines of engineering, pure sciences and management, with nearly 100 laboratories organized in a unique pattern of functioning, Central Library with state-of-the-art facilities, Auditorium, Student Activity Centre, Mega Computer Centre, Indoor Games Complex, big stadium, Seminar Halls with required infrastructure, Dispensary with state of art of facilities, etc. Faculty of repute, brilliant student community, excellent technical and supporting staff and an effective administration have all contributed to the pre-eminent status of N.I.T., Warangal. The Institute offers eight undergraduate programmes (B.Tech.,) in engineering, twenty-nine post graduate programmes (M.Tech., M.Sc., MCA and MBA) in engineering, sciences and management and research programmes in engineering, sciences, humanities, physical education and management.The institute is well-known for its Research and Development, Industrial consultancy, Continuing education and Training programmes for teachers and industrial personnel.",
        },
      },
      {
        id: "vShA8vYum8",
        type: "paragraph",
        data: {
          text: "NIT Warangal is a self-contained residential campus located in a beautiful wooded land of about 248 acres with nearly 3355 (UG : 2251 &amp; PG : 1104) students apart from nearly 269 Ph.D., (150 Full Time and 119 Part time) Scholars.&nbsp; It has 16 residential blocks including one mega &amp; one ultra-mega hostel for boys, two hostels for girls and one hostel for foreign students.&nbsp; It has five messes for boys, one mess exclusively for girls and one mega mess with present state of art facilities for boys, foreign students and girls.&nbsp; It has nearly 320 teaching staff and 360 administrative and supporting staff apart from outsource personnel.It is a residential campus with all the basic facilities for both the students and staff to live on the campus. The placement service at the institute is one of the best of its kind in the country. Recent placement record at the institute is on par with the best IITs in the country. The institute ranked 19thamong all the technical institutes in the country by National Institutional Ranking Framework (NIRF) 2020.",
        },
      },
      {
        id: "OW2thLKnzR",
        type: "header",
        data: { text: "Department at glance:", level: 5 },
      },
      {
        id: "9Edd32fJuA",
        type: "paragraph",
        data: {
          text: "The department of chemistry was established in the Year, 1959, as an integral part of the Regional Engineering College Warangal (RECW). Since its inception, the department is greeted as one of the most academically active departments in the Institute. The department housed 18 highly reputed faculty members, 60 research scholars and 101 M.Sc. students. Department offers chemistry course to all engineering branches and two years M.Sc. Chemistry with specialization in Organic and Analytical Chemistry. The department has been conducting cutting-edge research in contemporary topics of Organic, Inorganic, Physical and Analytical Chemistry. In the department, various state-of-the-art facilities such as 400 MHz NMR, HRMS, gas chromatography, HPLC, FTIR, UV-vis-NIR, fluorescence, electrochemical workstation, computational chemistry software, etc. are available. So far, department received several extramural research projects worth Rs ~11 crores. Recently department received FIST grants of worth 1.67 crores.As a part of continuing education and outreach activities, department organized several national and international Conferences, seminars and workshops. In addition, department is actively involved in science popularization among the school children via Inspire programme and society. So far, the department produced 130 Ph.D. students and published over 950 research articles in various well-reputed international and national journals. Most of the students are well placed in various companies and institutions with decent package.",
        },
      },
      {
        id: "CFeCuwZ6kN",
        type: "header",
        data: { text: "Introduction", level: 3 },
      },
      {
        id: "oUL2DHhHvy",
        type: "header",
        data: {
          text: "A Brief Profile of the&nbsp;Department of Chemistry&nbsp;",
          level: 5,
        },
      },
      {
        id: "Q3wV9yAtN6",
        type: "paragraph",
        data: {
          text: "The Department of Chemistry, NIT Warangal is growing from strength to strength while keeping pace with overall development of NIT Warangal. The Department has a team of faculty, graduated from IIT’s, Central Universities and other premier Institutions of higher learning. The Department has good infrastructure and is equipped with the latest sophisticated analytical instruments. The Department has been offering two M.Sc. programmes with the specializations in: i) Organic Chemistry and ii) Analytical Chemistry.The faculty members are actively engaged in research and consultancy. The Department has produced 90&nbsp;PhDs and has to its credit about 500 research publications in reputed International and National Journals. Currently 35 research scholars are working towards PhD degree.",
        },
      },
      {
        id: "f2NtINtLO_",
        type: "header",
        data: { text: "Srengths&nbsp;and Achievements", level: 5 },
      },
      {
        id: "Ef-sTZmbYY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Highly Qualified and Experienced Faculty members",
            "Active participation of Faculty in Administrative, Curricular and Extra-Curricular activities",
            "Faculty members are authors of Books; Reviewers of Research Journals; Selection Committee Members; Chairpersons at National and International Symposia; Winners of Awards, Fellowships, Visiting Faculty, Ph.D. Theses Evaluators",
            "Faculty members are invitees as Experts to specific Centers of Academic Excellence",
            "Faculty are invited for popularization of Science and Rural Development activities",
            "Research Projects in Cutting-Edge Areas",
            "Sponsored Research project by funding agencies like DST, CSIR, UGC, ICMR, MHRD, AICTE, DRDO, NRB etc.",
            "Linkages with premier R&amp;D and Industrial Establishments such as IICT, CECRI, ARCI, GVK Bio, DRL, NATCO, SUVEN Pharma, HETERODRUGS, etc.",
            "Novel Methods and use of AV Aids for Effective Teaching",
            "Optimal Use of Available Infrastructure and Facilities",
            "Excellent Student Feedback",
            "Laboratory Manuals in Textbook format and Updated Frequently",
            "UG and PG Curricula updated frequently with latest and advanced topics",
          ],
        },
      },
      {
        id: "yUrl8tyVx0",
        type: "header",
        data: { text: "Vision And Mission", level: 3 },
      },
      { id: "sENucXPVfn", type: "header", data: { text: "Vision:", level: 5 } },
      {
        id: "wnDsrECqhO",
        type: "paragraph",
        data: {
          text: "To ensure that Chemistry &nbsp;is the international expert in providing excellence in education, initiating research and inspiring environment that ignites the young minds.",
        },
      },
      {
        id: "s7UhatXKcb",
        type: "header",
        data: { text: "Mission:", level: 5 },
      },
      {
        id: "U26fJoqcHp",
        type: "paragraph",
        data: {
          text: "To maintain a Department that gives equal importance in terms of our relevance of teaching and research by creating an environment that promotes…",
        },
      },
      {
        id: "IlRBmndg0g",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Independent thought, exchange of ideas and high ethical standards,",
            "Development of innovative instructional techniques,",
            "Use of modern educational technology in lecture and laboratory courses, and",
            "Increased opportunities and greater participation by under-represented minorities.",
          ],
        },
      },
      {
        id: "IMzOnrqTxW",
        type: "header",
        data: { text: "Programmes and Courses Offered", level: 3 },
      },
      {
        id: "vA21ggkbqe",
        type: "header",
        data: { text: "UG Courses:", level: 5 },
      },
      {
        id: "GnFgplkE14",
        type: "paragraph",
        data: {
          text: "Chemistry Theory and Lab Courses (I B. Tech. All Branches), Physical &amp; Organic Chemistry (II B. Tech. Chemical Engg.), Instrumental Analysis Laboratory Course (II B. Tech. MME Students), Instrumental Methods of Chemical Analysis – Global Elective (III B.Tech. All Engg. Departments), Chemistry of Nanomaterials – Global Elective (IV B.Tech. All Engg. Departments), Industrial Organic Chemistry (Chemical Engg), Polymer Technology (MME)",
        },
      },
      {
        id: "NnLAB9kwmJ",
        type: "header",
        data: { text: "PG programme:", level: 5 },
      },
      {
        id: "LZKSzp5HfK",
        type: "paragraph",
        data: {
          text: "M.Sc. in organic and analytical chemistry with intake of 30 in each stream. The admission is based on National competitive exam, JAM",
        },
      },
      {
        id: "OsJ2NPi_lO",
        type: "header",
        data: { text: "PhD programme:", level: 5 },
      },
      {
        id: "a7fJv5RCCi",
        type: "paragraph",
        data: {
          text: "The Department offers both full time and external Ph.D. admissions. Financial assistance from either institute or project.",
        },
      },
      {
        id: "S8PuX2IQ5S",
        type: "header",
        data: { text: "Contact Us:", level: 3 },
      },
      {
        id: "oaS9kMi5oI",
        type: "paragraph",
        data: { text: "Department of Chemistry" },
      },
      {
        id: "-0bEXwg9Zb",
        type: "paragraph",
        data: { text: "National Institute of Technology Warangal" },
      },
      {
        id: "yPtGQ0vUEx",
        type: "paragraph",
        data: { text: "Warangal-506004, &nbsp;Telangana" },
      },
      {
        id: "5TxNPNqMa3",
        type: "paragraph",
        data: {
          text: 'E-mail:&nbsp;<a href="mailto:Chemistry_hod@nitw.ac.in">Chemistry_hod@nitw.ac.in</a>',
        },
      },
      {
        id: "8tcXVU9qw9",
        type: "paragraph",
        data: { text: "Ph (O):&nbsp;0870-2462650" },
      },
      { id: "sgZq08NBTg", type: "header", data: { text: "History", level: 1 } },
      {
        id: "X4X4M57BHs",
        type: "paragraph",
        data: { text: "<b>MILE STONES OF THE DEPARTMENT OF CHEMISTRY</b>" },
      },
      {
        id: "VHR36puFQn",
        type: "list",
        data: {
          style: "unordered",
          items: ["Established in the Year -&nbsp;1959"],
        },
      },
      {
        id: "_kBLRzUlbi",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Offering Chemistry Courses to All Branches of Engineering from the year -&nbsp;1959",
          ],
        },
      },
      {
        id: "R66LdxUMhc",
        type: "list",
        data: {
          style: "unordered",
          items: ["Research Activity started in the year -&nbsp;1962"],
        },
      },
      {
        id: "BDy2ijACbd",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "M.Sc Chemistry in Dyes,&nbsp;Drugs,&nbsp;Pharmaceuticals &amp; Pesticides started in&nbsp;(in 2014-15 academic year the programme changed to M.Sc. Organic Chemistry) -&nbsp;1977",
          ],
        },
      },
      {
        id: "RLhWgyss8z",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "M.Sc. Chemistry in Modern Methods of Chemical Analysis started in&nbsp;(in 2014-15 academic year the programme&nbsp;changed to M.Sc. Analytical Chemistry) -&nbsp;1999",
          ],
        },
      },
      {
        id: "8VBZjSoYYK",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "No. of&nbsp; Ph. D’s Produced by the Department so far -&nbsp; 90",
          ],
        },
      },
      {
        id: "B4smrZ1O_a",
        type: "list",
        data: {
          style: "unordered",
          items: ["No. of Research Publications -&nbsp;500"],
        },
      },
      {
        id: "0C7zVnAKum",
        type: "list",
        data: {
          style: "unordered",
          items: ["No. of Major Research Projects Completed - 20"],
        },
      },
      {
        id: "0D0rqftNUa",
        type: "list",
        data: {
          style: "unordered",
          items: ["No. of Ongoing Major Research Projects -&nbsp;4"],
        },
      },
      {
        id: "UWvXGvCV-l",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "No. of&nbsp; Minor Research Projects&nbsp; Completed -&nbsp;25",
          ],
        },
      },
      {
        id: "SXRSKupTGf",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "No. of Seminars / Conferences / Workshops Conducted -&nbsp;20",
          ],
        },
      },
      {
        id: "Qh3u2jmm5S",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Value of the&nbsp;Current&nbsp;R&amp;D Projects Funded -&nbsp;Rs. 67.15 lakhs",
          ],
        },
      },
      {
        id: "4jSoLl-q7o",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Sanctioned FIST project level I by DST (Rs. 35 lakhs) -&nbsp;2010",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173cba848df11c83028d55c",
    time: 1634979946593,
    version: "2.22.2",
    title: "Home",
    url: "/cy/home",
    path: "/cy",
    createdAt: "2021-10-23T08:45:28.842Z",
    updatedAt: "2021-10-23T09:05:47.600Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "jzsnKBuOI-",
        type: "header",
        data: { text: "Courses Offered", level: 3 },
      },
      {
        id: "jfwtxFhaga",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Courses Offered", "Intake"],
            ["&nbsp; M. Sc. (Organic Chemistry)&nbsp;&nbsp;", "24"],
            ["&nbsp; M. Sc. (Analytical Chemistry)&nbsp;&nbsp;", "24"],
            ["Ph.D.", ""],
          ],
        },
      },
      {
        id: "J8xvHBFmfo",
        type: "header",
        data: { text: "Rules And Regulations", level: 3 },
      },
      {
        id: "_9RZT_j2gC",
        type: "paragraph",
        data: {
          text: "Click the below links to get the rules and regulations",
        },
      },
      {
        id: "oAa7YlNHks",
        type: "paragraph",
        data: {
          text: '2. Ph.D&nbsp;&nbsp;— &nbsp;&nbsp;<a href="http://www.nitw.ac.in/nitw/Scheme&amp;Syllabus-2012/Ph.D1.pdf">http://www.nitw.ac.in/nitw/Scheme&amp;Syllabus-2012/Ph.D1.pdf</a>',
        },
      },
      {
        id: "Cv1XxUAXsF",
        type: "header",
        data: { text: "Association", level: 3 },
      },
      {
        id: "t7XujliVh_",
        type: "header",
        data: {
          text: "Faculty Incharge&nbsp;:&nbsp;Dr. K. Hari Prasad&nbsp;(Assistant&nbsp;Professor)",
          level: 5,
        },
      },
      {
        id: "UpI3g1gRSZ",
        type: "paragraph",
        data: { text: "<b>Executive Body:</b>" },
      },
      {
        id: "38PoVTL21q",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["S. No", "Name", "Designation"],
            ["1", "Vivek Kumar", "General Secretory"],
            ["2", "Mounika Devala", "Additional Secretory"],
            ["3", "Jagrit Grover", "Joint Secretory"],
            ["4", "Sarita", "Executive Member"],
            ["5", "Chandra Prakash Choudhary", "Executive Member"],
            ["6", "Shweta Sharma", "Executive Member"],
            ["7", "Ruttala Nagendra", "Executive Member"],
            ["8", "Seema Sharma", "Executive Member"],
            ["9", "Gaurav Kumar", "Executive Member"],
            ["10", "Neha Dagar", "Executive Member"],
            ["11", "Vinayak Sidana", "Executive Member"],
            ["12", "Suman Chirra (Research scholar)", "Executive Member"],
          ],
        },
      },
      {
        id: "wP_uHSWJe3",
        type: "header",
        data: {
          text: "Chemistry&nbsp;Association&nbsp;Activities&nbsp;:",
          level: 5,
        },
      },
      {
        id: "kVwt5ZTVTc",
        type: "paragraph",
        data: {
          text: "Chemistry association was started long back and serving the department in many ways to develop in all aspects. Association is actively involved in conducting scientific events on various occasions. In the previous year, we have conducted several programmes such as International Ozone day celebrations, invited lectures from well-known scientists from various institutions.&nbsp;Also we are organizing the industrial visits for M.Sc., and Ph.D., students with the help of Training and Placement section. Last year we&nbsp;organized scientific talk by Prof. Kaliyappan from IIT Bombay, Prof Anil kumarSaikia from IIT Guwahati, Prof. Bhaskaran from IIT Madras and Dr. Santhoshkumar from Albany molecular center, Singapore.&nbsp;As a part of industrial tour, we have visited&nbsp;Suven Life Sciences&nbsp;and&nbsp;Natcopharma&nbsp;industries. Very recently, we organized International Ozone Day celebrations at institute level to bring the awareness of the importance of ozone layer. Also, our M.Sc students went to the government high schools to bring the awareness among&nbsp;the school students.Similarly, the association will&nbsp;continue to organise&nbsp;the more events and lectures in the coming days.&nbsp;",
        },
      },
      {
        id: "EUvvg9RnBW",
        type: "paragraph",
        data: { text: "Additionally, we are planning for&nbsp;:&nbsp;" },
      },
      {
        id: "g4VNMk8oTS",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Industrial Tours&nbsp;",
            "Expert Lectures",
            "Workshops",
            "Quiz Programmes",
            "Chem. Quests",
            "Science Popularization",
            "Communication skills",
            "Personality development Programmes",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173cdc0e80eacc88579dcb2",
    time: 1634979262843,
    version: "2.22.2",
    title: "Academics",
    url: "/cy/academics",
    path: "/cy",
    createdAt: "2021-10-23T08:54:24.267Z",
    updatedAt: "2021-10-23T08:54:24.267Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "a7_qA9e9Gc",
        type: "header",
        data: { text: "Instrumentation", level: 3 },
      },
      {
        id: "MC3Y3QxAEr",
        type: "header",
        data: { text: "SOPHISTICATED INSTRUMENTS:", level: 5 },
      },
      {
        id: "eywJ0Hned_",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Carlo Erba (Fisons) EA 1108 CHNS-O Elemental Analyzer",
            "Perkin Elmer 100S FTIR spectrophotometer",
            "Shimadzu 8201 PC Fourier Transform Infrared Spectrophotometer",
            "Shimadzu UV-1601 Ratio Recording UV-Vis-NiR Spectrophotometer",
            "UV-Vis-NiR Spectrophotometer-Analytical Zena",
            "EG&amp;G PARC 264 A3 Polarographic Analyzer/Stripping Voltammeter",
            "High temperature programmable furnace",
            "High speed cooling centrifuge",
            "Metrohm Electrochemical Analyzer",
            "BAS CV 27 Voltammograph",
            "CHI&nbsp;Electrochemical work station",
            "Sartorius MC 5 Microbalance",
            "ATI Orion 902 Expandable Ion Meter",
            "Aimil Nucon 5765 Gas Chromatograph",
            "Aldrich Flash Chromatograph",
            "Mettler M3 and M5 Microbalances",
            "Spectronic 20 Genesis Spectrophotometers",
            "Buchi Rotovapor",
            "Shaking Incubator",
            "Digital polarimeter",
            "Digital Refractometer",
            "Digital Melting Point Apparatus",
          ],
        },
      },
      {
        id: "t0LzCAGe2U",
        type: "header",
        data: { text: "Laboratories", level: 3 },
      },
      {
        id: "19m_SSDFvp",
        type: "header",
        data: { text: "LABORATORIES&nbsp;:", level: 5 },
      },
      {
        id: "1puGpi0xpw",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Sophisticated Analytical Instruments Laboratories (I &amp; II)",
            "Analytical Chemistry Laboratory",
            "Organic Chemistry Laboratory",
            "Inorganic Chemistry Laboratory",
            "Physical Chemistry Laboratory",
            "Computational Chemistry Laboratory",
            "Environmental Chemistry Laboratory",
            "Engineering Chemistry Laboratory",
            "Research Laboratories (I II &amp; III)",
          ],
        },
      },
      {
        id: "SSEibTl1ga",
        type: "header",
        data: { text: "SOPHISTICATED INSTRUMENTS:", level: 5 },
      },
      {
        id: "14kEHNWkQY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "400 MHz NMR Spectrometer with solid probe",
            "LC-HRMS analyzer",
            "FTIR spectrometer&nbsp;&nbsp;",
            "ESR Spectrometer",
            "UV-VIS Spectrometer (DRS facility)",
            "CHNS-O Elemental Analyzer",
            "High temperature programmable furnace",
            "HPLC analyzer",
            "High speed cooling centrifuge",
            "Polarographic Analyzer/Stripping Voltammeter",
            "Electrochemical work station",
            "Gas Chromatography",
            "Flash Chromatography",
            "TGA-DTA Analyzer",
            "Digital polarimeter",
          ],
        },
      },
      { id: "F5cOi_VdSb", type: "header", data: { text: "Library", level: 3 } },
      {
        id: "ihn9mVZ_Ng",
        type: "paragraph",
        data: { text: "<b>Departmental Library</b>" },
      },
      {
        id: "0VVeg7GmNA",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Textbooks",
            "Popular Science Magazines",
            "Review Articles",
            "Reprints",
            "Student Dissertations and Seminar Papers",
            "Ph.D. Theses",
          ],
        },
      },
      {
        id: "0-DIWEPRnj",
        type: "paragraph",
        data: { text: "<b>Journals Available Online</b>" },
      },
      {
        id: "RHHbNPQXX5",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "All journals are available through the “INDEST” Scheme of MHRD, Govt. of India",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173cf370828ed79896d1903",
    time: 1634979638454,
    version: "2.22.2",
    title: "Facilities",
    url: "/cy/facilities",
    path: "/cy",
    createdAt: "2021-10-23T09:00:39.213Z",
    updatedAt: "2021-10-23T09:00:39.213Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "JIZEmSTuuC",
        type: "header",
        data: { text: "Broad Areas of Research:", level: 3 },
      },
      {
        id: "uviDfHcaDJ",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Synthetic/Medicinal and Bioorganic chemistry",
            "Organometallic, Bio-inorganic, Analytical chemistry",
            "Electrochemistry, Theoretical chemistry and Nanomaterials",
          ],
        },
      },
      {
        id: "VpAcG6gd4v",
        type: "header",
        data: { text: "Thrust Areas of Research:", level: 3 },
      },
      {
        id: "ui1GfXtOUu",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Electrocatalysis, Biomimic materials, Biosensors, Fuel cells",
            "Photocatalysis, Nanotechnology and Solar energy harvesting",
            "Environmental chemistry, Drug evaluation process development",
            "Bioactive compounds and Drug design &amp; delivery",
            "Carbohydrate chemistry, Organic synthesis, X-ray crystallography studies",
            "Artificial Photosynthesis and Fluorescent Chemosensors",
            "Functional materials, Self-Assembled Materials, Supramolecular Chemistry",
            "Medicinal Inorganic Chemistry and Bio-analytical Chemistry",
            "Theoretical and Computational Chemistry (DFT and Applications)",
          ],
        },
      },
      { id: "IStayJNNz7", type: "header", data: { text: "Groups", level: 3 } },
      {
        id: "uzFX-KnVJ_",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Organic Chemistry:&nbsp;Synthesis, Hetero cyclic drugs, Lipids and&nbsp;Bio-organic&nbsp;compounds.",
            "Physical Chemitry:&nbsp;Kinetics, Catalysis, Advanced oxidation process and photocatalysis.",
            "Inorganic Chemistry:&nbsp;Coordination compounds, Magnetic materials.&nbsp;",
            "Analytic Chemistry:&nbsp;New chromatographic methods for pharmaco&nbsp;kinetic studies.",
            "Material Chemistry :&nbsp;Advanced functional&nbsp;materials, Nanoscience, nanomaterials for energy and environment,&nbsp;Bio Sensors, Electrochemical sensors,&nbsp;Corrosion inhibitors,&nbsp;Self assembled&nbsp;nanofilms, Lithium ion batteries.",
            "Environmental Chemistry:&nbsp;Pollution control, water purification, Green Chemistry,&nbsp;Advanced oxidation process and photocatalysis.",
          ],
        },
      },
      {
        id: "b5QTDz-Pnf",
        type: "header",
        data: { text: "Highlights", level: 3 },
      },
      {
        id: "aj04iYi6yC",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Santosh Kumar, Arabinda Baruah, Surendar T, Vishnu Shanker and B. Sreedhar,&nbsp;Cost-effective and eco-friendly synthesis of a novel and stable N-doped ZnO/g-C3N4&nbsp;core-shell nanoplates with excellent visible-light responsive photocatalysis, Nanoscale, 6, 2014,&nbsp;4830.",
            "Surendar T, Santosh Kumar, Syam Kandula and Vishnu Shanker,&nbsp;Fe-doped mediated graphitic carbon nitride nanosheets: Photocatalytic application towards the degradation of dye under the sunlight irradiation,&nbsp;J. Mater. Chem. A, 2, 2014,&nbsp;6772.",
            "S. Kumar, T. Surendar, A. Baruah and V. Shanker,&nbsp;Synthesis of novel and stable g-C3N4/Ag3PO4 inorganic-organic hybrid nanocomposite photocatalyst and enhancement of photocatalytic activity under visible light, J. Mater. Chem. A,&nbsp;1, 2013,&nbsp;5333.",
            "S. Kumar, T. Surendar, A. Baruah and V. Shanker, Synthesis of magnetically separable and recyclable g-C3N4-Fe3O4hybrid nanocomposite with enhanced photocatalytic performance under visible-light irradiation J. Phys. Chem. C, 117, 2013, 26135",
            "Surendar T, S. Kumar and V. Shanker, Influence of La-doping on phase transformation and photocatalytic properties of ZnTiO3&nbsp;nanoparticles synthesized via modified sol-gel method. Phys.Chem. Chem. Phys., 2014, 16, 728",
            "Santosh Kumar,&nbsp;&nbsp;&nbsp;Surendar T,&nbsp;&nbsp;&nbsp;Bharat Kumar,&nbsp;&nbsp;Arabinda Baruah&nbsp;and&nbsp;&nbsp;&nbsp;Vishnu Shanker,&nbsp;Synthesis of highly efficient and recyclable visible-light responsive mesoporous g-C3N4 photocatalyst via facile template-free sonochemical route, RSC Adv., 4, 2014,&nbsp;8132.",
            "Santosh Kumar, Surendar T and Vishnu Shanker,&nbsp;Template-free and eco-friendly synthesis of hierarchical Ag3PO4 microcrystals with sharp corners and edges for enhanced photocatalytic activity under visible light, Materials Letters, 123, 2014,&nbsp;172.",
            "S. Kumar, T. Surendar, D. Das, B. Kumar, V. Shanker, Hierarchical ZnO architectures synthesized&nbsp;via&nbsp;reverse micellar method for improved photocatalytic activity.&nbsp;Mat. Lett. 101, 2013, 33-36.",
            "S. Kumar, B. Kumar, T. Surendar, V. Shanker, g-C3N4/NaTaO3&nbsp;organic-inorganic hybrid nanocomposite: High-performance and recyclable visible light driven photocatalyst, Mater. Res. Bull., 49, 2014, 310–318",
          ],
        },
      },
      {
        id: "eOxgQNyaDu",
        type: "header",
        data: { text: "Projects", level: 3 },
      },
      {
        id: "yHy0JrzT-w",
        type: "header",
        data: { text: "Ongoing Projects:", level: 5 },
      },
      {
        id: "fSwNA1Dnh9",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Inhibitor Formulation to Combat Corrosion and Fouling of Ship sea water cooling system. (NRB,&nbsp; 9.88 lakhs)",
            "Synthesis and anti angiogenisis activity of new sulphur heterocyclic compounds derived from coumarins. (CSIR, 11 lakhs)",
            "Rational Design and Synthesis of Dimerizable and Targetable Cationic Peptide Amphiphiles for Non-vira l Gene Delivery. (CSIR, 10 lakhs).",
            "Development of benzaoxazole, benzthiazole and benzimidazoles based novel cationic amphiphiles for non viral gene delivery targeting to tumors. (DST, 17 lakhs).",
            "Synthesis, Characterization and doping effect-studies of complex metal oxide nanoparticles. (DST, 19.45 lakhs)",
          ],
        },
      },
      {
        id: "IXww8UiqUu",
        type: "header",
        data: { text: "Completed Projects in last five years:", level: 5 },
      },
      {
        id: "jyJc1RGIdz",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Development of Methods of removal of Arsenic from Industrial Effluents by bio-sorption and species alteration.&nbsp; sponsored by MHRD (Rs. 15.00 Lakhs) 2005-08",
            "Design and Synthesis of Heteroaryl Porphyrins as Possible AntiCancer Agents. sponsored by UGC (Rs. 2.52 Lakhs ) 2006-09",
            "Design and Synthesis of New Phenolic Crown Ethers and Mcso-substituted Porphyrins as Antioxidants and Multifunctional Additives. sponsored by DST under Women Scientist Scheme A, GOI (Rs. 17.00 Lakhs ) 2006-09",
          ],
        },
      },
      {
        id: "9kQmI7QyzS",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Studies on Synthesis &amp; Biological Activities of Fused Heteroaryl Chromenes with New Reagents. sponsored by (CSIR, 9.00 lakhs) 2006-09",
          ],
        },
      },
      {
        id: "ezGcJbSNcA",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "New Heterocyclic Quinones as Potential Antineoplastic Agents. sponsored by (UGC, 7.6 lakhs) 2006-09",
          ],
        },
      },
      {
        id: "4h5aDBjSXZ",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "New Heteroaryl styrylsulfones as Anticancer Agents. sponsored by (CSIR, 9.7 lakhs) 2006-09",
          ],
        },
      },
      {
        id: "ddvTMae8V_",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Rational Design and Synthesis of Dimerizable and Targetable Cationic Peptide Amphiphiles for non-viral Gene Delivery. sponsored by (CSIR, 10.00 lakhs) 2006-09",
          ],
        },
      },
      {
        id: "fk7VzetT7W",
        type: "header",
        data: { text: "Research Achievements:", level: 3 },
      },
      {
        id: "-cZSPAqRcQ",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Items", "From Inception", "In the past five years"],
            [
              "No.of Research Papers Published in International Journals",
              "550",
              "102",
            ],
            [
              "No.of  Research Papers Published in National Journals",
              "250",
              "99",
            ],
            [
              "No. of Research Papers Presented in International Conferences",
              "100",
              "40",
            ],
            [
              "No. of Research Papers Presented in National Conferences",
              "250",
              "60",
            ],
            ["No. of Ph.D.s Awarded", "118", "22"],
          ],
        },
      },
      {
        id: "CWDPxnKgvq",
        type: "header",
        data: { text: "Sponsored Research Projects:", level: 3 },
      },
      {
        id: "YPRWovUvwp",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Item", "For the past five years", "Ongoing"],
            ["No. of Sponsored Projects", "12", "11"],
            ["Total value of the projects", "2.5 Crores", "3.2 Crores"],
            [
              "Names of the funding agencies",
              "DST, CSIR, UGC, MHRD, DRDO",
              "DST, CSIR, UGC, MHRD, DRDO",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "6173d29a4c429ac83f87b4ea",
    time: 1634980505689,
    version: "2.22.2",
    title: "Research",
    url: "/cy/research",
    path: "/cy",
    createdAt: "2021-10-23T09:15:06.565Z",
    updatedAt: "2021-10-23T09:15:06.565Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "p6izaoCMDk", type: "header", data: { text: "Awards", level: 3 } },
      {
        id: "BTPyVbpHcn",
        type: "header",
        data: { text: "Honors and Awards:", level: 5 },
      },
      {
        id: "3qBfpY-q1m",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Prof. B V Appa Rao received NACE International – India section “Corrosion Awareness Award” for the year 2003 (Sponsored by ONGC), for the meritorious research contribution in the field of Corrosion Science",
            "Dr. B. Rajitha received most cited paper 2005-2008.Elsevier’s Journal for one of her publications. “A novel and efficient catalyst for the synthesis of aryl-14-H-dibenzo[a,f] xanthenes in conventional heating and microwave irradiation”Tetrahedron Letters,46, 2005, 8691. B. Rajitha*, Y. Thirupathi Reddy, P. Narasimha Reddy.",
            "The review paper entitled “Inhibition by Phosphonic acids – an overview” by G. Gunasekaran, R. Natarajan, V. S. Muralidharan, N. Palaniswamy and B. V. Appa Rao, Anticorrosion methods and Materials (U.K.), Vol.44, No.4, 1997, pp.248-259 has been awarded CSIR Foundation Day Award for the best review article from CSIR Laboratories for the year 1997",
            "Dr. K.V. Rao Scientific society annual research awards under&nbsp;Young Scientist&nbsp;category in chemical science in 2009. Cash award Rs 10,000/- and Silver Shield.",
          ],
        },
      },
      {
        id: "RJDmNHF5jC",
        type: "paragraph",
        data: {
          text: "Authors:&nbsp;Nalajam Guravaiah&nbsp;&amp; V. Rajeswara Rao",
        },
      },
      {
        id: "3UfjXSUWaB",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Title: Synthesis, Single crystal X-ray studies and antimicrobial activities of novel Indole barbiturates.",
          ],
        },
      },
      {
        id: "_tgYTe_dyJ",
        type: "paragraph",
        data: {
          text: "Authors:&nbsp;Vijaya Laxmi S, Janardhan B,a&nbsp;Rajitha B, Raghavaiah. P Prathima Srinivas.",
        },
      },
      {
        id: "t7Hz_PUGUh",
        type: "paragraph",
        data: {
          text: "Dr. K.V. Rao Scientific Society award for&nbsp;Young Scientist&nbsp;on March 26th&nbsp;2011.",
        },
      },
      {
        id: "CetShoNwrW",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Title:&nbsp;“Synergistic effect of Ascorbate in Combination with 2-phoshonobutane-1,2,4-tricarboxylic acid and Zn2+&nbsp;for corrosion control of carbon steel”,",
          ],
        },
      },
      {
        id: "iyJWXLAo4P",
        type: "paragraph",
        data: { text: "Authors:&nbsp;B.V. Appa Rao and&nbsp;S. Srinivasa Rao" },
      },
      {
        id: "SLYNTT4sT7",
        type: "paragraph",
        data: {
          text: "Proc. 14th&nbsp;National Congress on Corrosion Control (NCCC-14) organized by National Corrosion Council of India (NCCI), CECRI, Karaikudi at Hotel Green Park, Hyderabad ,18th&nbsp;– 20th&nbsp;September 2008.",
        },
      },
      {
        id: "fk9D-QgPaf",
        type: "paragraph",
        data: {
          text: "Awarded&nbsp;Second Prize&nbsp;for the&nbsp;Oral Presentation",
        },
      },
      {
        id: "IfPjMUUrUH",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Title:&nbsp;“ A new eco-friendly ternary inhibitor formulation based on phosphate for corrosion inhibition of carbon steel”",
          ],
        },
      },
      {
        id: "CGRKm3drHp",
        type: "paragraph",
        data: {
          text: "Authors:&nbsp;B.V. Appa Rao and&nbsp;S. Srinivasa Rao,",
        },
      },
      {
        id: "bC1U6LkOXR",
        type: "paragraph",
        data: {
          text: "Proc. National Conference on Corrosion Assessment and its Control (NCAC-09) organized by National Corrosion Council of India (NCCI), CECRI, Karaikudi at Thiagarajar college of Engineering, Madurai, 21-22 December 2009.",
        },
      },
      {
        id: "gsw6ePfRQw",
        type: "paragraph",
        data: {
          text: "Awarded&nbsp;First Prize&nbsp;for the&nbsp;Oral Presentation",
        },
      },
      {
        id: "5BPlzwmlaS",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Title: “Electrochemical Studies of corrosion inhibition of Cu-Ni (90/10) alloy in sea water and sulfide polluted sea water using amino phenyl tetrazole“.",
        },
      },
      {
        id: "NnRigW9GXN",
        type: "paragraph",
        data: { text: "Authors: B V Appa Rao and&nbsp;K Chaitanya kumar." },
      },
      {
        id: "c2dbC0pQHg",
        type: "paragraph",
        data: {
          text: "National Convention of ElectroChemists 2011, organised by CECRI,&nbsp;Karaikudi Dec. 15th&nbsp;– 16th, 2011, Coimbatore.",
        },
      },
      {
        id: "mLFUfk5qx8",
        type: "paragraph",
        data: {
          text: "Awarded for the&nbsp;Oral Presentation, the Annapurnammal award for best paper in corrosion science",
        },
      },
      {
        id: "PtZolqSWqF",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Title: “Synthesis, characterization and catalytic properties of a novel Ti – mesoporous nano hollow silica from tetramethyl ammoniumbromide surfactant“.",
        },
      },
      {
        id: "NQLVziYG4M",
        type: "paragraph",
        data: {
          text: "Authors: N. Venkatathri,&nbsp;M. Nookaraju, A. Rajini and I.A.K. Reddy.",
        },
      },
      {
        id: "YtSGfE8K7N",
        type: "paragraph",
        data: {
          text: "International Conference on Recent Trends in Nano &amp; Bio-Sciences&nbsp;(ICORTNBS-2010),&nbsp;Feb 24th&nbsp;– 26th, 2010. Organised by the Department of Physics, PG College of Science, Saifabad, Osmania University, Hyderabad.",
        },
      },
      {
        id: "tkblIiSNOP",
        type: "paragraph",
        data: { text: "Awarded the&nbsp;“Best Poster Award – 2nd&nbsp;Prize" },
      },
      {
        id: "eGZnF5VWMF",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Title: ‘Electron transfer characteristics of redox activated enzymes and application for superoxide dismutase sensing’.",
          ],
        },
      },
      {
        id: "br6BHc79iL",
        type: "paragraph",
        data: {
          text: "Authors:&nbsp;K. Vengatajalabathy Gobi &amp;&nbsp;K. Koteshwara Reddy.",
        },
      },
      {
        id: "pYjbDI-pAv",
        type: "paragraph",
        data: {
          text: "National Conference on Recent Advances in Electroanalytical Techniques, February 25-26, 2010 organized by Gandhigram Rural Institute, Gandhigram, Dindigul, Tamil Nadu.",
        },
      },
      {
        id: "GQqwyJAQOz",
        type: "paragraph",
        data: {
          text: "Awarded the&nbsp;“Best Poster Presentation – 3rd&nbsp;Prize”",
        },
      },
      {
        id: "bwo3YGBNjF",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Title:&nbsp; Activated Direct Electron Transfer of Cytochrome c at Au Bionanoconjugates for Electrocatalytic Detection of Superoxide Dismutase Enzyme.",
          ],
        },
      },
      {
        id: "0UKkSYK9kd",
        type: "paragraph",
        data: {
          text: "Authors: K.Vengatajalabathy Gobi &amp; K. Koteshwara Reddy",
        },
      },
      {
        id: "JdkzCOSdmF",
        type: "paragraph",
        data: {
          text: "4th&nbsp;ISEAC International Discussion Meet on Electrochemistry &amp; its Applications&nbsp;(DM-ISEAC-2011),&nbsp;Feb 7-10, Mascot Hotel, Thiruvananthapuram, Kerala organised by Indian Society for Electro Analytical Chemistry (ISEAC), , Mumbai, India.",
        },
      },
      {
        id: "fWNxRpSPf0",
        type: "paragraph",
        data: { text: "Awarded the&nbsp;“Best Poster Award – 1st&nbsp;Prize”" },
      },
      {
        id: "zVU3HMJyKw",
        type: "header",
        data: { text: "Students Awards", level: 3 },
      },
      {
        id: "jdz4qiU6zK",
        type: "header",
        data: { text: "INTERNSHIPS", level: 5 },
      },
      {
        id: "tm3Bwmzyft",
        type: "paragraph",
        data: {
          text: "Following M.Sc students have taken up summer projects (during May-July 2016) at different research institutes and R &amp; D laboratories.:-1. Vivek Kumar (156969) at &nbsp;IIT Kharagpur (Indian Academy of Sciences Fellowship)",
        },
      },
      {
        id: "486mwiGRTE",
        type: "paragraph",
        data: {
          text: "2. Vinod Mouli (156909) at IISER Bhopal &nbsp;(Indian Academy of Sciences Fellowship)",
        },
      },
      {
        id: "k24mNSrWQ_",
        type: "paragraph",
        data: {
          text: "3. G.Vijay Kumar (156905) at Hyderabad Central University&nbsp;",
        },
      },
      {
        id: "7Ib9vkh68t",
        type: "paragraph",
        data: {
          text: "4. SuchitraSahu (156967) at Hyderabad Central University",
        },
      },
      {
        id: "H8FaSh-aaQ",
        type: "paragraph",
        data: {
          text: "5. V. Ashwini (156920) at Hyderabad Central University",
        },
      },
      {
        id: "B9JF-xYqZB",
        type: "paragraph",
        data: { text: "6. Sarita(156965) at NIT Rourkela" },
      },
      {
        id: "tNUV5DdxeQ",
        type: "paragraph",
        data: { text: "7. K. Ramyashri&nbsp;(156957) at IGCAR Kalpakkam" },
      },
      {
        id: "2hFuccHbvh",
        type: "paragraph",
        data: { text: "8. B Chandan Kumar (156952) at NIT Warangal" },
      },
      {
        id: "U8lC3jZ-yQ",
        type: "header",
        data: { text: "SCHOLARSHIPS&nbsp;", level: 5 },
      },
      {
        id: "34F83HXgXZ",
        type: "paragraph",
        data: {
          text: "1. Vivek Kumar (156969) Scholarship for Higher Education-INSPIRE Fellowship",
        },
      },
      {
        id: "lCZcG1wC6C",
        type: "paragraph",
        data: {
          text: "2. KirthiJoshi &nbsp;(156906) Scholarship for Higher Education-&nbsp;INSPIRE Fellowship",
        },
      },
      {
        id: "jgSkxay0Fl",
        type: "paragraph",
        data: {
          text: "3.&nbsp;Abhinav Purohit (156901) Central Sector Scheme Scholarship-CBSE",
        },
      },
      {
        id: "KH3V2rJsZc",
        type: "paragraph",
        data: { text: "4. Sarita (156965) UGC PG Merit Scholarship" },
      },
      {
        id: "shHQGBrlpb",
        type: "paragraph",
        data: {
          text: "5. G.L.N.Bhavana &nbsp;(156922) Indira Gandhi Scholarship",
        },
      },
      {
        id: "PEDY40tVBe",
        type: "paragraph",
        data: {
          text: "6. Chinkit Tyagi &nbsp;(Scholarship for Higher Education-INSPIRE Fellowship)",
        },
      },
      {
        id: "zRdLZsk_PG",
        type: "paragraph",
        data: {
          text: "7. Hari Mohan (Scholarship for Higher Education-INSPIRE Fellowship)",
        },
      },
      {
        id: "0BmR0bpRBo",
        type: "paragraph",
        data: {
          text: "8. Haritha Ramireddy (Scholarship for Higher Education-INSPIRE Fellowship)",
        },
      },
      {
        id: "Wt-OhLWn05",
        type: "paragraph",
        data: {
          text: "9. Sonali (Scholarship for Higher Education-INSPIRE Fellowship)",
        },
      },
    ],
    language: "En",
    _id: "6173d35d601b47c867b5218c",
    time: 1634980701118,
    version: "2.22.2",
    title: "Awards",
    url: "/cy/awards",
    path: "/cy",
    createdAt: "2021-10-23T09:18:21.992Z",
    updatedAt: "2021-10-23T09:18:21.992Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "JA3qyOYAHp",
        type: "header",
        data: { text: "Office Staff", level: 3 },
      },
      {
        id: "oa5TAkTMRf",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["S. No", "Name", "Designation", "Email ID"],
            [
              "1",
              "Mr. N.Kishore Kumar",
              "Jr Assistant&nbsp;",
              "nkkishore13@gmail.com",
            ],
            ["2", "Mr. M.Kumara Swamy", "Jr Assistant&nbsp;", ""],
            ["3", "Mr. P.Heeru Lal", "Jr Assistant&nbsp;", ""],
          ],
        },
      },
    ],
    language: "En",
    _id: "6173d3dfe80eacc88579dcb4",
    time: 1634980831193,
    version: "2.22.2",
    title: "People",
    url: "/cy/people",
    path: "/cy",
    createdAt: "2021-10-23T09:20:31.917Z",
    updatedAt: "2021-10-23T09:20:31.917Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "BTneCjj64c",
        type: "header",
        data: { text: "Fluid Mechanics &amp; WRE Laboratory", level: 3 },
      },
      {
        id: "R8q6Pm2kMR",
        type: "header",
        data: {
          text: "Concrete Technology &amp; Structures Laboratory",
          level: 3,
        },
      },
      {
        id: "k9nKF1e_Gt",
        type: "header",
        data: { text: "Engineering Geology Laboratory", level: 3 },
      },
      {
        id: "4FsKWwat_O",
        type: "header",
        data: { text: "Environmental Engineering Laboratory", level: 3 },
      },
      {
        id: "Ck1Pnu19-c",
        type: "header",
        data: { text: "Material Testing Laboratory", level: 3 },
      },
      {
        id: "8cEM4LpmwJ",
        type: "header",
        data: { text: "RS &amp; GIS Laboratory", level: 3 },
      },
      {
        id: "A2lCH59f_W",
        type: "header",
        data: { text: "Soil Mechanics Laboratory", level: 3 },
      },
      {
        id: "2gsw9vcVa0",
        type: "header",
        data: { text: "Surveying Laboratory", level: 3 },
      },
      {
        id: "ZU-6TFDCo8",
        type: "header",
        data: { text: "Transportation Engineering Laboratory", level: 3 },
      },
    ],
    language: "En",
    _id: "6173e002bd3e7d9da04a94e2",
    time: 1634983960263,
    version: "2.22.2",
    title: "Labs",
    url: "/ce/labs",
    path: "/ce",
    createdAt: "2021-10-23T10:12:18.627Z",
    updatedAt: "2021-10-23T10:12:40.527Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "rjSKmqUjEW", type: "header", data: { text: "BANK:", level: 6 } },
      {
        id: "m4b7zo6yAy",
        type: "paragraph",
        data: {
          text: "The institute has a bank situated beside the campus main gate, State Bank of India. The bank is regular&nbsp; in the campus and are housed in separate buildings. Application forms for important national level exams such as JEE, GATE and CAT are sold in this bank.",
        },
      },
      {
        id: "iHy_IvMAyW",
        type: "paragraph",
        data: {
          text: "State Bank of India:&nbsp;Institute employees have their salaries credited to the State Bank of India. Online Fees transactions are done online through the State Bank of India. Director and Chief wardens have their permanent accounts in State Bank of India.",
        },
      },
      {
        id: "DmQf3-BAy6",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2016/04/14/sbh.JPG",
          },
          caption: "State Bank of India",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      { id: "G5f1s4bvoy", type: "header", data: { text: "ATM:", level: 6 } },
      {
        id: "KNWnB1hSqy",
        type: "paragraph",
        data: {
          text: "State Bank of India has&nbsp;two ATMs&nbsp;- one located in the bank premises and the other near the Sports Stadium.<br><br>",
        },
      },
      { id: "gQtBFU3JMi", type: "header", data: { text: "ATM-I", level: 6 } },
      {
        id: "EoK9UtbwGk",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2016/04/14/atm-at-bank.JPG",
          },
          caption: "(In the Bank Premises)",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      { id: "4uZzbmBelX", type: "header", data: { text: "ATM-II", level: 6 } },
      {
        id: "eIq3tzcSqa",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2016/04/14/atm-inside-campus.JPG",
          },
          caption: "(Inside the campus at the stadium)",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
    ],
    language: "En",
    _id: "6173f1d9f7fd7700239e6888",
    time: 1640590589447,
    version: "2.22.2",
    title: "Bank & ATM",
    path: "/facilities",
    url: "/facilities/bank-atm",
    createdAt: "2021-10-23T11:28:25.258Z",
    updatedAt: "2021-12-27T07:36:29.597Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "7J_G1Qj2WJ",
        type: "header",
        data: { text: "NIT Canteen", level: 5 },
      },
      {
        id: "28_Eiz3Zoq",
        type: "paragraph",
        data: { text: "<b>1. New NIT canteen</b>" },
      },
      {
        id: "mdzaG2IglP",
        type: "paragraph",
        data: { text: "<b>2. Staff Canteen</b>" },
      },
      {
        id: "gLFxnWSCHT",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2016/08/14/nitw_canteen_2BhFwQo.JPG",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
    ],
    language: "En",
    _id: "6173f222f7fd7700239e688b",
    time: 1641454474859,
    version: "2.22.2",
    title: "Canteen",
    path: "/facilities",
    url: "/facilities/canteen",
    createdAt: "2021-10-23T11:29:38.564Z",
    updatedAt: "2022-01-06T07:32:44.470Z",
    __v: 0,
  },
  {
    blocks: [],
    language: "En",
    _id: "6173f24af7fd7700239e688d",
    time: 1636727637915,
    version: "2.22.2",
    title: "Library",
    path: "/facilities",
    url: "/facilities/library",
    createdAt: "2021-10-23T11:30:18.488Z",
    updatedAt: "2021-11-12T14:33:59.484Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "cCJhACXGuI",
        type: "header",
        data: { text: "Post Office", level: 4 },
      },
      {
        id: "mU-q9LR0Wd",
        type: "paragraph",
        data: {
          text: "The Institute has a Post Office . The NITW campus comes under a separate postal zone and it is a postal delivery office.The post office is located Beside the Boys Hostels(12th Block Viswesvraya Hall)",
        },
      },
      {
        id: "ARgvpgUkEr",
        type: "paragraph",
        data: {
          text: "Works on all weekdays from 10.00 A.M.&nbsp;to 5.00 P.M. and from&nbsp; 9.00 A.M. to 2.00 P.M. on Saturdays.",
        },
      },
      {
        id: "1OFDNTmVo9",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2016/04/14/post-office.JPG",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
    ],
    language: "En",
    _id: "6173f262f7fd7700239e688f",
    time: 1641454500881,
    version: "2.22.2",
    title: "Post Office",
    path: "/facilities",
    url: "/facilities/postoffice",
    createdAt: "2021-10-23T11:30:42.794Z",
    updatedAt: "2022-01-06T07:33:10.502Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "A-dDytP779",
        type: "header",
        data: { text: "Shopping Complex", level: 4 },
      },
      { id: "WMMMlT8_fg", type: "paragraph", data: { text: "" } },
      { id: "ZXt17dE1H2", type: "paragraph", data: { text: "" } },
      {
        id: "D7TXLtz2lA",
        type: "list",
        data: {
          style: "unordered",
          items: ["Laundry Shop", "Hair Cutting Saloon", "Uma Xerox Shop"],
        },
      },
      {
        id: "X6kLwKEPRt",
        type: "paragraph",
        data: {
          text: "Each of these remain open regularly from 9 to around 8pm.",
        },
      },
      { id: "dfLjadBhyY", type: "paragraph", data: { text: "" } },
    ],
    language: "En",
    _id: "6173f283f7fd7700239e6891",
    time: 1636621927720,
    version: "2.22.2",
    title: "Shopping Complex",
    path: "/facilities",
    url: "/facilities/shopping-complex",
    createdAt: "2021-10-23T11:31:15.646Z",
    updatedAt: "2021-11-11T09:12:08.937Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "gs2Obgfo2t",
        type: "header",
        data: { text: "Motor Transport Section", level: 4 },
      },
      {
        id: "ESTOqQw9KX",
        type: "paragraph",
        data: { text: "Contact Details:<br>" },
      },
      {
        id: "vJdnCBaXKh",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16522/"></a><b>MT Section Office</b>:&nbsp;870-2462990, 2462991&nbsp;',
        },
      },
      {
        id: "Df8Toh6F9k",
        type: "paragraph",
        data: {
          text: "<b>Foreman MT Section</b>:&nbsp;&nbsp;Sri R. Veera Swamy",
        },
      },
      {
        id: "cfrtkmgf5m",
        type: "paragraph",
        data: {
          text: "<b>Ph. No</b>. :&nbsp;&nbsp;870-2462336, 2468336&nbsp;\n\n",
        },
      },
      {
        id: "dC3a1q-CL9",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2016/04/14/nitw_college_bus.JPG",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
    ],
    language: "En",
    _id: "6173f2b0f7fd7700239e6894",
    time: 1640585965386,
    version: "2.22.2",
    title: "Transport",
    path: "/facilities",
    url: "/facilities/transport",
    createdAt: "2021-10-23T11:32:00.934Z",
    updatedAt: "2021-12-27T06:19:25.541Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "jCjOzBLV2P",
        type: "header",
        data: { text: "Various Activities in 2020 - 21", level: 1 },
      },
      {
        id: "RBYiF5iQia",
        type: "image",
        data: {
          url: "https://nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-123217-pm-1.jpeg",
          caption: "",
          withBorder: false,
          withBackground: false,
          stretched: false,
        },
      },
      {
        id: "SGtDMGF1xo",
        type: "image",
        data: {
          url: "https://nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-123217-pm.jpeg",
          caption: "",
          withBorder: false,
          withBackground: false,
          stretched: false,
        },
      },
      {
        id: "A3WA9cI1tn",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp;Prof. N.V.Ramana Rao, Director NITW has inaugurated the health centre with more medical facilities (Hematology analyzer, Biochemistry analyzer and physiotherapy equipments&nbsp; on 28th&nbsp;April 2021.",
        },
      },
      {
        id: "1dMxetrDch",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; Sri. S.Govardhan Rao&nbsp;Registrar NITW, Institute Health Centre Doctors and staff attended the program.",
        },
      },
      {
        id: "jLM1tnRVqo",
        type: "header",
        data: { text: "&nbsp;Laboratory:", level: 3 },
      },
      {
        id: "NoNbdOuod7",
        type: "paragraph",
        data: {
          text: "The Laboratory is equiped wih latest equipments.&nbsp;",
        },
      },
      {
        id: "YAgYBsBDOM",
        type: "header",
        data: { text: "Physiotherapy:", level: 3 },
      },
      {
        id: "CT3YQDf8T2",
        type: "paragraph",
        data: {
          text: "The Physiotherapy unit&nbsp;&nbsp;is equiped wih all the latest equipments.",
        },
      },
      {
        id: "EQ_QYDggIZ",
        type: "header",
        data: { text: "Covid -19 vaccination", level: 3 },
      },
      {
        id: "6-PYvvG8yW",
        type: "paragraph",
        data: {
          text: "&nbsp;Covid -19 vaccination drive is arranged at NIT warangal. Faculty, staff and family dependents&nbsp; have utilized the opportunity and got vaccinated.",
        },
      },
      {
        id: "Bv_6CqTYaE",
        type: "image",
        data: {
          url: "https://nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-123051-pm.jpeg",
          caption: "",
          withBorder: false,
          withBackground: false,
          stretched: false,
        },
      },
      {
        id: "OdKc5aFWvM",
        type: "paragraph",
        data: { text: "Tackling Covid-19 during the PANDEMIC&nbsp;" },
      },
      {
        id: "n9IpEtd5Ao",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Tele Consultation:&nbsp;Tele Consultation was done round the clock (24*7). Around 100 Covid-19 positive cases were treated by our Institute Health Centre doctors during the Covid-19 2nd&nbsp;wave .&nbsp;",
            "Testing:&nbsp;Covid testing has been arranged whenever required and also reached to the nearest Primary Health Center (Boda Gutta) for immediate testing/vaccination, wherever possible.&nbsp;",
            "Arranged quarantine at the Punnami Guest House employees who require isolation.&nbsp;",
            "For emergency Covid patient’s - beds have been arranged in hospitals whenever required.&nbsp;",
            "Monitoring of post Covid patients by physiotherapist is arranged to discuss the techniques that increase the lung capacity.&nbsp;",
            "In the process of Covid and during post Covid Rehabilitation, patients are being counselled regarding their condition, techniques to increase their lung capacity and mental wellbeing which improve their immunity and ability to fight the diseases.&nbsp;&nbsp;",
            "Health Centre has taken initiative for the post COVID recovering patients in the form of arranging OXYGEN CONCENTRATOR which can deliver 7 Litres of OXYGEN.&nbsp;",
            "Diet:&nbsp;Dietary advice was given to entire family members.&nbsp;",
            "Patients were&nbsp;encouraged to be STRONG ENOUGH. They were also counselled to take all the medicines in time and NOT PANIC by reading the WhatsApp forwarded messages and google search.&nbsp;",
            "Preventive measures were &nbsp;being given in R.O frequently.&nbsp;",
            "Regular medicines were given for one month in order to decrease the visit / exposure to the health Centre and even issuing emergency drugs for cold, cough, fever.&nbsp;",
            "The IHC staff are exposed to heavy load of positive cases and we continue to give the services at the best possible way.",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173f35bf7fd7700239e6897",
    time: 1631172838281,
    version: "2.22.2",
    title: "HealthCare - Activities",
    path: "/facilities",
    url: "/facilities/healthcare/activities",
    createdAt: "2021-10-23T11:34:51.583Z",
    updatedAt: "2021-10-23T11:34:51.583Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "rEZaD6vu64",
        type: "header",
        data: { text: "Ambulance service", level: 1 },
      },
      {
        id: "Qwy4VsBc6p",
        type: "header",
        data: { text: "Ambulance:", level: 3 },
      },
      {
        id: "8PeyIPS7WJ",
        type: "paragraph",
        data: { text: "Ambulance service is provided 24X7. " },
      },
      {
        id: "DndNTHV6-t",
        type: "paragraph",
        data: {
          text: "Ambulance is equipped with O2 cylinder, nebulizer and first aid box with routine medicines, essential life saving drugs.",
        },
      },
      {
        id: "rgQEeCXtam",
        type: "header",
        data: { text: "Contact No", level: 3 },
      },
      {
        id: "rPaJG7rp-7",
        type: "paragraph",
        data: {
          text: "Ambulance can be&nbsp;contacted from the Phone numbers given below.",
        },
      },
      {
        id: "UzpeCeoMzj",
        type: "paragraph",
        data: { text: "<b>Phone:&nbsp;</b>0870-2462099" },
      },
      {
        id: "JiEKgouGTG",
        type: "paragraph",
        data: { text: "<b>Mobile:</b>&nbsp;9491065006" },
      },
      {
        id: "KCYpehJGUI",
        type: "paragraph",
        data: {
          text: "Emergency phone number is displayed in all buildings and hostels.",
        },
      },
      {
        id: "gALgCIJuoF",
        type: "image",
        data: {
          url: "https://nitw.ac.in/media/uploads/2020/07/14/whatsapp-image-2020-07-14-at-14448-pm.jpeg",
          caption: "Ambulance",
          withBorder: false,
          withBackground: false,
          stretched: false,
        },
      },
    ],
    language: "En",
    _id: "6173f387f7fd7700239e6899",
    time: 1631170429303,
    version: "2.22.2",
    title: "Ambulance",
    path: "/facilities",
    url: "/facilities/healthcare/ambulance",
    createdAt: "2021-10-23T11:35:35.316Z",
    updatedAt: "2021-10-23T11:35:35.316Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "WTCEIojb9m",
        type: "header",
        data: { text: "INSTITUTE HEALTH CENTRE (IHC)", level: 1 },
      },
      {
        id: "9r4eTzlbox",
        type: "paragraph",
        data: {
          text: "The Institute Heath Centre is located within the campus",
        },
      },
      {
        id: "5d1uETOHwb",
        type: "header",
        data: { text: "TIMINGS:&nbsp;", level: 3 },
      },
      {
        id: "FSCiLCr9gZ",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "<b>On Working Days:</b>&nbsp;&nbsp;9:30am to 1:00pm and 3:00pm to 6:00pm.&nbsp;",
            "<b>On Saturday and Sunday:</b>&nbsp; &nbsp;9:30am to 11:30am.",
          ],
        },
      },
      {
        id: "ioAq9xs-36",
        type: "paragraph",
        data: {
          text: "NITW Health Centre is a full fledged unit with Three Full time regular medical officers, one contract part time doctor&nbsp;who are assisted by paramedical staff (Staff Nurse, ANM, OPD Assistant, Dresser, Lab Technician).",
        },
      },
      {
        id: "Ud7WPyqUOm",
        type: "paragraph",
        data: {
          text: "The Health Centre has attached pharmacy with the turn over of medicines of more than 50 lakhs per annum.",
        },
      },
      {
        id: "DJVsLhkFbb",
        type: "paragraph",
        data: {
          text: "The students, employees and their dependents almost 11000 members are getting benefited with the daily OPD of around 150 and above.",
        },
      },
      {
        id: "eDlxSwIBPD",
        type: "header",
        data: { text: "FACILITIES:", level: 3 },
      },
      {
        id: "RF8i-pq-Vj",
        type: "paragraph",
        data: {
          text: "The Health Centre is having well established pathological lab facilities where blood, urine tests are done. The other facilities are ECG, pulse oximeter, nebulizer, oxygen support, o2 masks.",
        },
      },
      {
        id: "VHQS59QjDj",
        type: "paragraph",
        data: {
          text: "The laboratory is equipped with hematology analyzer, Biochemistry analyzer, urine analyzer, hot air oven ,R-8C laboratory centrifuge ,Blood mixer,binocular microscope. All the routine blood tests(Hemogram, LFT, RFT, lipid profile, thyroid profile) are done.&nbsp;",
        },
      },
      {
        id: "jCZyjD-vbc",
        type: "image",
        data: {
          caption: "BIOCHEMISTRY ANALYZER",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "X48-SZU8FS",
        type: "image",
        data: {
          caption: "HEMATOLOGY ANALYZER",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "6KYRW4Hr4O",
        type: "image",
        data: {
          caption: "URINE ANALYZER",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "_K_edLpvuD",
        type: "image",
        data: {
          caption: "R-8C LABORATORY CENTRIFUGE",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "UG5W7-WRPk",
        type: "image",
        data: {
          caption: "BLOOD MIXER",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "irAJ1TjZ_j",
        type: "paragraph",
        data: {
          text: "We have a Five bed facility for observation and emergency first aid of patients.",
        },
      },
      {
        id: "Xq1wuddvfC",
        type: "header",
        data: { text: "DENTAL CLINIC:", level: 3 },
      },
      {
        id: "A54ffyaJqH",
        type: "paragraph",
        data: {
          text: "The Health Centre has a Dental chair where minor procedures are done such as scaling, filling and extraction of tooth.",
        },
      },
      {
        id: "5PloJtYpw0",
        type: "header",
        data: { text: "Specialist Services:", level: 4 },
      },
      {
        id: "Swhy7jxxbU",
        type: "paragraph",
        data: {
          text: "The Health Centre has Four visiting Doctors in the department of",
        },
      },
      {
        id: "HPxAqHN8Ka",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Psychiatry&nbsp;on&nbsp;Friday&nbsp;from&nbsp;<b>4:30pm to 6:00pm.&nbsp;</b>",
            "Dentist&nbsp;on&nbsp;Wednesday&nbsp;from&nbsp;<b>3:30pm to 5:30pm.&nbsp;</b>",
            "General physician&nbsp;on&nbsp;Tuesday&nbsp;from&nbsp;<b>4:00pm to 6:00pm.&nbsp;</b>",
            "Homeopathy&nbsp;on&nbsp;Monday and Thursday&nbsp;from&nbsp;<b>11:00am to 12:00pm.</b>",
          ],
        },
      },
      {
        id: "4MTneDAyI3",
        type: "header",
        data: { text: "PHYSIOTHERAPY:", level: 4 },
      },
      {
        id: "puqX6Ic9JM",
        type: "paragraph",
        data: {
          text: "The physiotherapy unit is equipped with all the latest modalities. tens muscle stimulator machine, interferential therapy, Ultra sound therapy machine, hydro collator packs.",
        },
      },
      {
        id: "tSygjXaPUK",
        type: "image",
        data: {
          caption: "TENS MUSCLE STIMULATOR MACHINE",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "inKHSpdIN-",
        type: "image",
        data: {
          caption: "INTERFERENTIAL THERAPY",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "TyIcoc5GNW",
        type: "image",
        data: {
          caption: "ULTRA SOUND THERAPY MACHINE",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "S_eXG81pnz",
        type: "image",
        data: {
          caption: "HYDRO COLLATOR PACKS",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "C3I6tyCfFl",
        type: "paragraph",
        data: {
          text: "We have Two physiotherapists from&nbsp;<b>11am to 6pm</b>. The physiotherapy unit has all the electrotherapy modalities and exercise therapy for rehabilitation and chronic and acute pain management.",
        },
      },
      {
        id: "uaT4v_Pct_",
        type: "image",
        data: {
          file: {
            url: `${MainServerUrl}/static/files/testimages_2021-11-24-17-10-42.jpeg`,
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: true,
        },
      },
      {
        id: "KL_4-zfQoF",
        type: "header",
        data: { text: "WASTE DISPOSAL:", level: 4 },
      },
      {
        id: "7WYgMgW5RX",
        type: "paragraph",
        data: {
          text: "We are also following the rules of Bio medical waste as per Ministry of Environment and Forests.",
        },
      },
      {
        id: "4LXdXe9BHq",
        type: "header",
        data: { text: "MEDICAL SCREENING:", level: 4 },
      },
      {
        id: "Pe5EfzVtiF",
        type: "paragraph",
        data: {
          text: "During admission process, medical screening is being handled by Health Centre for all the students.",
        },
      },
      {
        id: "kktspqJ_MT",
        type: "paragraph",
        data: {
          text: "Various clinical activities and camps undertaken in 2019 are as Follows:",
        },
      },
      {
        id: "-HewfovXsv",
        type: "paragraph",
        data: {
          text: "1. Medical service is provided at the hostel itself for first 3 months to make the stay of students comfortable and safe including Saturday, Sunday, festivals.",
        },
      },
      {
        id: "0H4BMNU_mT",
        type: "paragraph",
        data: {
          text: "2. Induction lecture and psychology counseling for the students with interactive session.",
        },
      },
      {
        id: "nH59qspllU",
        type: "paragraph",
        data: {
          text: "3.&nbsp;<b>Muddunur camp</b>: Around 700 village patients are benefited with the camp in a village near Warangal, which is the village adopted by NIT Warangal with free distribution of medicines.",
        },
      },
      {
        id: "oxVF1iKh1D",
        type: "paragraph",
        data: {
          text: "4. Cardiology camp around 110 patients have undergone cardiology consultation and 2Decho is done for the patients who needed.",
        },
      },
      {
        id: "9SBAmJDb-G",
        type: "header",
        data: {
          text: "The list of empanelled hospitals from 01.07.2021 to 30.06.2022:",
          level: 3,
        },
      },
      {
        id: "YSi400BV1j",
        type: "header",
        data: { text: "1. Super Speciality Hospitals at warangal", level: 4 },
      },
      {
        id: "kJrO748SNt",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. No</b>", "<b>Name of Hospital</b>"],
            ["1", "Rohini Super Specialty Hospital"],
            ["2", "Samraksha Super Specialty Hospital"],
          ],
        },
      },
      {
        id: "8JpnApE7o5",
        type: "header",
        data: { text: "2.&nbsp;General Hospitals at Warangal", level: 4 },
      },
      {
        id: "ojlqZhGfDX",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. No</b>", "<b>Name of Hospital</b>"],
            ["1", "Jaya Hospital"],
            ["2", "Guardian Multi-Speciality Hospital"],
            ["3", "Max Care Hospitals"],
            ["4", "Pramoda Hospital"],
            ["5", "Sharat Laser Eye Hospital"],
            ["6", "Bharathi Nursing Home"],
            ["7", "Sri Laxmi Narasimha Hospital"],
          ],
        },
      },
      {
        id: "8QPIZXdhRj",
        type: "header",
        data: {
          text: "3.&nbsp;Super Speciality Hospitals at Hyderabad",
          level: 4,
        },
      },
      {
        id: "zOcJuOuE6-",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. No</b>", "<b>Name of Hospital</b>"],
            [
              "1",
              "Basavatarakam Indo American Cancer Hospital &amp; Research Institute",
            ],
            ["2", "Krishna Institute of Medical Sciences Ltd."],
            ["3", "Sunshine Hospitals"],
            ["4", "CARE Super Speciality Hospitals"],
          ],
        },
      },
      {
        id: "1rBrQHs_z0",
        type: "header",
        data: {
          text: "4. Empanelled Hospitals list for Students at Warangal",
          level: 4,
        },
      },
      {
        id: "kAfcQpTegy",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. No</b>", "<b>Name of Hospital</b>"],
            ["1", "Rohini Super Speciality Hospital"],
            ["2", "Jaya Hospital"],
            ["3", "Max Care Hospitals"],
            ["4", "Samraksha Super Speciality Hospital"],
          ],
        },
      },
      {
        id: "s_SvgCnJ3Y",
        type: "header",
        data: { text: "MEDICAL OFFICERS:", level: 3 },
      },
      {
        id: "fkBxgzF7rX",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S. NO", "NAME", "QUALIFICATION", "DESIGNATION", "CONTACT NO:"],
            [
              "1",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Dr.Chintala Karthik</a>',
              "M.B.B.S",
              "Medical OfficerIn-Charge",
              "833296946894901653590870-246 2090",
            ],
            [
              "2",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Dr.Pradeep Dodda</a>',
              "M.B.B.S",
              "Medical Officer",
              "83329693540870-246 2096",
            ],
            [
              "3",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Dr.Anchoori&nbsp;</a><a href="https://www.nitw.ac.in/main/home/" target="_blank">Karthik</a>',
              "M.B.B.S",
              "Medical Officer",
              "8332969392",
            ],
          ],
        },
      },
      {
        id: "qWw3XZig4s",
        type: "header",
        data: { text: "CONTRACT DOCTORS:", level: 3 },
      },
      {
        id: "zo_mlI2Yqc",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S. NO", "NAME", "QUALIFICATION", "DESIGNATION", "CONTACT NO:"],
            [
              "1",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Dr.K.Anita Kumari</a>',
              "M.B.B.S",
              "Medical Officer",
              "8332969702",
            ],
          ],
        },
      },
      {
        id: "ZoqywPwUcl",
        type: "header",
        data: { text: "PERMANENT STAFF:", level: 3 },
      },
      {
        id: "G0X4qHvwsH",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S. NO", "NAME", "QUALIFICATION", "DESIGNATION", "CONTACT NO:"],
            [
              "1",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">K.Rameeja Bee</a>',
              "G.N.M",
              "Technical assistant(Staff Nurse)",
              "8332969240",
            ],
            [
              "2",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Y.Bikshapathy</a>',
              "...",
              "Junior Assistant (UG)",
              "8332969306",
            ],
          ],
        },
      },
      {
        id: "zlMEt8cCnG",
        type: "header",
        data: { text: "MEDICAL OFFICERS:", level: 3 },
      },
      {
        id: "A8BU1HuzM4",
        type: "image",
        data: {
          caption: "DR.CHINTALA KARTHIK (MEDICAL OFFICER IN - CHARGE)",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "a7j7lmbVxC",
        type: "image",
        data: {
          caption: "DR.PRADEEP DODDA (MEDICAL OFFICER)",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "4STafatpcU",
        type: "image",
        data: {
          caption: "DR.ANCHOORI KARTHIK (MEDICAL OFFICER)",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "0yIAhEvqCp",
        type: "header",
        data: { text: "CORONA VIRUS", level: 3 },
      },
      {
        id: "I2HIC8XjA0",
        type: "paragraph",
        data: {
          text: "Corona viruses are a large family of viruses which may cause illness in animals or humans. In humans, several corona viruses are known to cause respiratory infections ranging from the common cold to more severe diseases such as Middle East Respiratory Syndrome (MERS) and Severe Acute Respiratory Syndrome (SARS). The most recently discovered corona virus causes corona virus disease COVID-19. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
        },
      },
      {
        id: "IR3EKCjjhM",
        type: "paragraph",
        data: {
          text: "The symptoms are fever, cough, sorethroat, breathlessness, sneezing, myalgia, GI symptoms, weakness.",
        },
      },
      {
        id: "sZ_XoFJ9yX",
        type: "paragraph",
        data: { text: "Basic preventive measures to be followed:" },
      },
      {
        id: "gftBxs0wxI",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Physical distancing of at least one meter (6 feet) between individuals to be followed at all times.&nbsp;",
            "Employees and Students entering the campus shall be thermally scanned for their body temperature. If the body temperature is normal, then nothing needs to be done. If the body temperature is higher than normal, then the Institute Health Centre must be informed, and the person scanned should be asked to visit the Health Centre, and their vehicle will also be disinfected.&nbsp;",
            "Use of face covers/masks to be mandatory.&nbsp;",
            "Don’t touch your face, mouth and nose as this might increases the risk of infection.&nbsp;",
            "Practice frequent hand washing (for at least 40-60 seconds) even when hands are not visibly dirty and use of alcohol based hand sanitizers (for at least 20 seconds).&nbsp;",
            "Respiratory etiquettes to be strictly followed. This involves strict practice of covering one’s mouth and nose while coughing/sneezing with a tissue/handkerchief/flexed elbow and disposing off used tissues properly.&nbsp;",
            "Self-monitoring of health by all and reporting any illness at the earliest&nbsp;",
            "Spitting anywhere in the campus is strictly banned.&nbsp;",
            "All employees must carry their ID card to be shown when stopped and asked by the Security.&nbsp;",
            "Anyone coming to the campus from abroad or outside Warangal has to undergo a mandatory home quarantine for as per GOI norms after arrival on the campus.&nbsp;",
            "Putting sanitizing hand rub dispensers in prominent places around the workplace. Make sure these dispensers are regularly refilled.&nbsp;",
            "Making sure that all toilets are disinfected at least twice a day, and all common areas are disinfected periodically.&nbsp;",
            "Older people, and those with underlying medical problems like high blood pressure, heart problems or diabetes, are more likely to develop serious illness. People with fever, cough and difficulty breathing should seek medical attention. People can catch COVID-19 from others who have the virus.&nbsp;",
            "Eat healthy foods to boost immunity.",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173f3c1f7fd7700239e689b",
    time: 1640346437255,
    version: "2.22.2",
    title: "Institute Healthcare Centre",
    path: "/facilities",
    url: "/facilities/healthcare/institute-healthcare-centre",
    createdAt: "2021-10-23T11:36:33.584Z",
    updatedAt: "2021-12-24T11:47:30.792Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "JWHyBCUrsJ",
        type: "header",
        data: { text: "Hostel Blocks &amp; Capacity", level: 1 },
      },
      {
        id: "YbF7Bmk4dX",
        type: "header",
        data: { text: "Boy's Hostels:", level: 2 },
      },
      {
        id: "fePitswL-M",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "<b>BLOCK NO</b>",
              "<b>BLOCK NAME</b>",
              "<b>NO.OF ROOMS</b>",
              "<b>SINGLE/DOUBLE</b>",
              "<b>TOTAL CAPACITY</b>",
            ],
            ["I", "Azad Hall(S)", "100", "Single-100", "98"],
            ["II", "Bose Hall(S)", "100", "Single-100", "98"],
            ["III", "Ambedkar Hall(T)", "48", "Double-48", "94"],
            ["IV", "Babha Hall(T)", "48", "Double-48", "94"],
            ["V", "Gandhi Hall(T)", "48", "Double-48", "94"],
            ["VI", "Gokhale Hall(T)", "48", "Double-48", "94"],
            ["VII", "Radhakrishnan Hall(T)", "48", "Double-48", "94"],
            ["VIII", "Raman Hall(T)", "48", "Double-48", "94"],
            ["IX", "Nehru Hall(S)", "100", "Single-100", "98"],
            ["X", "Patel Hall(S)", "100", "Single-100", "98"],
            ["XI", "Tagore Hall(D)", "48", "Double-48", "94"],
            ["XI", "Viswesvraya Hall(D)", "48", "Double-48", "94"],
            ["XII", "Rajendra Prasad Hall(S)", "202", "Single-202", "198"],
            ["XIV", "Vikram Sarabhai Hall(S)", "200", "Single-200", "198"],
            ["1K", "1K Hall of Residence", "1050", "Single-1050", "1050"],
            [
              "1.8K",
              "Ultra Mega Hostel",
              "1194",
              "Single-640Double-554",
              "640+1108 =1748",
            ],
            ["ISH", "International Students Hall", "200", "Single-200", "200"],
          ],
        },
      },
      {
        id: "cbeXVUVIiK",
        type: "header",
        data: { text: "Girls Hostel:", level: 2 },
      },
      {
        id: "26MVyIXZaY",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "<b>BLOCK NO</b>",
              "<b>BLOCK NAME</b>",
              "<b>NO.OF ROOMS</b>",
              "<b>SINGLE/DOUBLE</b>",
              "<b>TOTAL CAPACITY</b>",
            ],
            ["LH-PH", "Priyadarshini Hall", "75", "Triple-75", "225"],
            ["LH-SH", "Sarojini Hall", "65", "Double-65", "130"],
            ["NEW LH-A", "NEW LADIES HOSTEL-A", "120", "Single-120", "120"],
            ["NEW LH-B", "NEW LADIES HOSTEL-B", "110", "Single-110", "110"],
            ["NEW LH-C", "NEW LADIES HOSTEL-C", "90", "Single-90", "90"],
          ],
        },
      },
    ],
    language: "En",
    _id: "6173f41ef7fd7700239e689f",
    time: 1631173808809,
    version: "2.22.2",
    title: "Hostel Blocks Capacity",
    path: "/facilities",
    url: "/facilities/hostel/block-capacity",
    createdAt: "2021-10-23T11:38:06.572Z",
    updatedAt: "2021-10-23T11:38:06.572Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "h8O71O4UO8",
        type: "header",
        data: {
          text: "FORUM FOR INTERNATIONAL STUDENT AFFAIRS (FISA)",
          level: 2,
        },
      },
      {
        id: "VhgG-QPwY5",
        type: "paragraph",
        data: {
          text: "The National Institute of Technology is home to more than 4000 of India’s brilliant minds and future leaders. Setting trends in the technological and academic worlds, the premier institute also houses foreign students from more than 30 countries all over the world. This is where the Forum for International Student Affairs (FISA) steps in. FISA, an infant club at the institute serves as a platform to showcase the talent of the foreign students and show that they are at par with everyone else.",
        },
      },
      {
        id: "OkKU3xK3_q",
        type: "paragraph",
        data: {
          text: "That’s not where FISA stops. Apart from being a talent platform, FISA also caters the academic needs of foreign students. It is no secret that the Indian educational system requires that a student clear tough competition and emerge a winner if he has to be considered worthy and the college being one of the best in the country is home only to the cream layer of such winners. FISA recognises the need of foreign students for extra academic help and extends support and guidance in such matters. Taking this into consideration, FISA is organising extra classes for subjects that are considered tough and these classes will be conducted taking into consideration the needs and limits of foreign students who are new to the Indian way of teaching. FISA comprises of members who themselves are foreign students and understands that it is difficult to adjust to a new environment thousands of miles away from home. New place, new college, new faces and a new learning system is bound to be overwhelming to anyone and hence we strive to do everything possible to make foreign students comfortable in their new surroundings.&nbsp; Students are most welcome to contact any of the club members for guidance for anything that may be bothering them and the members will try their level best to help the students out.",
        },
      },
      {
        id: "7YekM9f8rH",
        type: "paragraph",
        data: {
          text: "As it serves to be a platform to foreign students, FISA also gives the local students a sneak view of international culture to show them the delights of places beyond the Indian shores, and what can be a better way to put up a cultural display other than food and movies. Organising an International Food Festival is FISA’s way of giving NITW a taste of life abroad. An international food festival is also on FISA’s agenda where students can enjoy something different from the traditional bollywood movies.",
        },
      },
      {
        id: "Yj_tRvtc-z",
        type: "paragraph",
        data: {
          text: "FISA also has other exciting events for active participation of both foreign and local students. &nbsp;A sports meet to display the sporting talents and a cultural program will soon be held. FISA also plans to pair up with the L&amp;D club and organise a debate/essay competition, the main focus being on international affairs. Organising an Inter-NIT foreign student’s meet is another event that has the club members excited. It is the first time that such an event will be hosted in the institute.",
        },
      },
      {
        id: "djA4ZSmoEm",
        type: "paragraph",
        data: {
          text: "With blessing from the Dean and a kick start by Dr. Kesava Rao, the club was started in the academic year 2010-11. Owing to lack of funds and confusion, the club hosted no more than an inaugural. However, starting this year(2011-12), the club under the leadership of Anish Singh, the general secretary,&nbsp; and innovative ideas from the club members, the club has found its direction and strives to be the most happening club in NITW.",
        },
      },
      {
        id: "rjP8cFbzsV",
        type: "paragraph",
        data: {
          text: "The main goal of FISA is to bring the foreign students of NITW under one canopy to ensure that they excel in academic and co-curricular activities and bring pride to our prestigious institute.",
        },
      },
      {
        id: "uS0aexhuqj",
        type: "header",
        data: { text: "Team of fisa", level: 2 },
      },
      {
        id: "gjsz8zyVTL",
        type: "paragraph",
        data: {
          text: "Faculty advisor&nbsp;:&nbsp;<b>Prof. D.S. kesava Rao</b>",
        },
      },
      {
        id: "C49Kp6-UbX",
        type: "paragraph",
        data: { text: "General Secretary:&nbsp;<b>Anish Singh</b>" },
      },
      {
        id: "VcuHAIDCmJ",
        type: "paragraph",
        data: { text: "Additonal General Secretary:&nbsp;<b>Vivek Vepa</b>" },
      },
      {
        id: "C2-ngy05Ow",
        type: "header",
        data: { text: "Joint Secretaries:", level: 4 },
      },
      {
        id: "ie_9njzR0D",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Sadhan Adlakha",
            "Prajin Jonche",
            "Mohammed Azad",
            "Ayesha Maryum",
          ],
        },
      },
      {
        id: "h51CIFtdYc",
        type: "header",
        data: { text: "Executive members:", level: 4 },
      },
      {
        id: "RJMb_tN4II",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Vivek Singh",
            "Pramond Nepal",
            "Aakash Taduri",
            "Kousalya Dwarapudi",
          ],
        },
      },
      {
        id: "KawT5KcEp-",
        type: "header",
        data: { text: "List of events&nbsp;:", level: 4 },
      },
      {
        id: "ww1LX-4XHn",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Inaugural/ freshers event (nov)&nbsp;",
            "Sports meet&nbsp;(nov)&nbsp;",
            "Classes for foreign students (starting of the 2nd&nbsp;sem January)&nbsp;",
            "Inter Nit Foreign students meet(feb)&nbsp;",
            "Cultural&nbsp;Programme(jan)&nbsp;",
            "Entrepreneurship event&nbsp;(feb)&nbsp;",
            "Food festival(march)&nbsp;",
            "Quiz, debate and essay competitions( in between events whenever we get some gaps)",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173f488f7fd7700239e68a3",
    time: 1631044199498,
    version: "2.22.2",
    title: "FORUM FOR INTERNATIONAL STUDENT AFFAIRS (FISA)",
    path: "/student-welfare",
    url: "/student-welfare/fisa",
    createdAt: "2021-10-23T11:39:52.737Z",
    updatedAt: "2021-10-23T11:39:52.737Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "h8O71O4UO8",
        type: "header",
        data: {
          text: "Institute of Electrical and Electronics Engineers (IEEE)",
          level: 2,
        },
      },
      {
        id: "3oBdClrDgt",
        type: "header",
        data: { text: "Primary purpose", level: 3 },
      },
      {
        id: "hHt9hOQjGO",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "The primary purpose of our branch is to promote&nbsp;research among the students and provide a platform&nbsp;to showcase and improve their interests and talents.&nbsp;",
          ],
        },
      },
      {
        id: "6jOISJQHr4",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "We provide them the exposure needed to the wide&nbsp;range of topics and research areas available so that&nbsp;they do not leave any stone unturned. It might form&nbsp;their future career path.",
          ],
        },
      },
      {
        id: "O9f5O4zULB",
        type: "header",
        data: { text: "Objective of the student branch", level: 3 },
      },
      {
        id: "YmP0KMwqpL",
        type: "list",
        data: {
          style: "unordered",
          items: ["To improve the research level of student branch&nbsp;"],
        },
      },
      {
        id: "8TutEEfD5a",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Help the student member to grow and excel in their&nbsp;respective field of interest.&nbsp;",
          ],
        },
      },
      {
        id: "Oslp7530Zs",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Mentoring various student members and student&nbsp;branches in Warangal by providing necessary&nbsp;support and guidance to advance technically.",
          ],
        },
      },
      {
        id: "ry_XrHdoe1",
        type: "header",
        data: { text: "Enhancing Research at Student Branch", level: 3 },
      },
      {
        id: "46rzON1eNw",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Research governed by Special Interest Groups (SIG’s) or&nbsp;Clusters: Conduct professional events regularly (lectures,&nbsp;tutorials, demos) to keep the student members updated&nbsp;on the latest advancements in different fields.&nbsp;",
          ],
        },
      },
      {
        id: "QdQzrGZ3gI",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Robotics Cluster&nbsp;",
            "Image Processing Cluster&nbsp;",
            "Bioinformatics Cluster&nbsp;",
            "Networking&nbsp;",
            "Power Quality&nbsp;",
            "Nano-Technology&nbsp;",
          ],
        },
      },
      {
        id: "aFbpC6Hn0Q",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "We at IEEE-student branch are a group of people&nbsp;definitely looking beyond academics. The special&nbsp;interest group discussions, cluster discussions and&nbsp;the student lectures initiated at the student branch&nbsp;has brought like minded people in the student&nbsp;community to work together. It is one of the most&nbsp;mature &amp; professional student organization of the&nbsp;many clubs at NIT Warangal.",
          ],
        },
      },
      {
        id: "huYIPiDJ7F",
        type: "header",
        data: { text: "So how do you join IEEE?", level: 3 },
      },
      {
        id: "JcgzVJr5XO",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "For IEEE international, the membership fees is $27 ;roughly 1300INR&nbsp;",
          ],
        },
      },
      {
        id: "RAvoh3eumW",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Each IEEE member is a part of the Student Branch&nbsp;? Membership is valid for a Calendar Year (not&nbsp;academic year)&nbsp;",
          ],
        },
      },
      {
        id: "2eosKM20lL",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "We start taking applications for memberships&nbsp;from October (posters will be put up and will be&nbsp;announced on website)",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173f4b5f7fd7700239e68a5",
    time: 1631044403806,
    version: "2.22.2",
    title: "Institute of Electrical and Electronics Engineers (IEEE)",
    path: "/student-welfare",
    url: "/student-welfare/ieee",
    createdAt: "2021-10-23T11:40:37.535Z",
    updatedAt: "2021-10-23T11:40:37.535Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "PlQWJ9dA-g",
        type: "header",
        data: { text: "NCC &amp; NSS", level: 2 },
      },
      {
        id: "cj3pF0lfU-",
        type: "header",
        data: { text: "National Cadet Corps (NCC)", level: 4 },
      },
      {
        id: "z5OEo5bzBu",
        type: "paragraph",
        data: {
          text: "NCC aims at inculcating a sense of patriotism and national pride in students. In addition it helps in physical fitness and also promotes all round personality in the students. Adequate facilities exist in the Institute for regular parades firing, training “obstacle course” and organizing camps. It offers 1 credit course in I and II semesters of the B.Tech.",
        },
      },
      {
        id: "62U31GpqNm",
        type: "paragraph",
        data: {
          text: 'N.C.C. Officer :&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16238/">&nbsp;Dr. M Udaya Bhasker</a>',
        },
      },
      {
        id: "zFOY4zT8SW",
        type: "header",
        data: { text: "National Social Service (NSS)", level: 4 },
      },
      {
        id: "s_shC1Cl1h",
        type: "paragraph",
        data: {
          text: "It aims at creating social awareness and also imparts human values in students. Its activities include protection of the environment and social services. It offers 1 credit course in I and II semesters of the B.Tech.",
        },
      },
      {
        id: "JzK97nZUu4",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Name", "Designation", "Contact number"],
            [
              '<a href="https://www.nitw.ac.in/faculty/id/16659/" target="_blank">Dr. B. Srinivas</a>',
              "Program officer cum Co-ordinator",
              '<a href="tel:8332969708" target="_blank">8332969708</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/faculty/id/16423/" target="_blank">Dr. T. Vinay Kumar</a>',
              "Program officer",
              '<a href="tel:8332969478" target="_blank">8332969296</a>',
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "6173f4d2f7fd7700239e68a7",
    time: 1641379111211,
    version: "2.22.2",
    title: "NCC & NSS",
    path: "/student-welfare",
    url: "/student-welfare/ncc-nss",
    createdAt: "2021-10-23T11:41:06.130Z",
    updatedAt: "2022-01-05T10:36:47.404Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "bMbBzpdLRM",
        type: "header",
        data: { text: "SC/ST CELL", level: 1 },
      },
      {
        id: "QnDkWSZTFK",
        type: "header",
        data: { text: "SC/ST CELL@ NIT WARANGAL", level: 2 },
      },
      {
        id: "cT2fyiBuZZ",
        type: "paragraph",
        data: {
          text: "The SC/ST Cell, a statutory body has been constituted by the Ministry of Human Resource Management (MHRD), Government of India (GOI) in the erstwhile Regional Engineering College (REC) Warangal to safeguard the interests of the SC/ST students and employees of the Institute as per the instructions of MHRD, GOI from time to time.",
        },
      },
      {
        id: "Ndx1PTNO5b",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/main/student-welfare/womens-cell/sc-st-cell/#Cell%20Functions">Cell Functions</a>&nbsp;',
            '<a href="https://nitw.ac.in/main/student-welfare/womens-cell/sc-st-cell/#Programs%20Organized">Programs Organized</a>&nbsp;',
            '<a href="https://nitw.ac.in/main/student-welfare/womens-cell/sc-st-cell/#gallery">Gallery</a>&nbsp;',
            '<a href="https://nitw.ac.in/main/student-welfare/womens-cell/sc-st-cell/#SC/ST%20Cell%20Coordinator">SC/ST Cell Coordinator</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/Special%20Cells/GATE%202014%20RANKS%20(1)%20(1).pdf" target="_blank">GATE Ranks Secured by NITW Students Through GATE Coaching funded by SC/ST subplan</a>&nbsp;',
            '<a href="https://nitw.ac.in/main/component/content/article/feewaiver/">Circular of Tuition fee waiver for SC / ST Students</a>&nbsp;',
          ],
        },
      },
      {
        id: "Zdsa26iC3P",
        type: "header",
        data: { text: "The Cell Functions:", level: 3 },
      },
      {
        id: "8KhAQGqdyl",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "As a Grievances Redressal Cell for the grievances of SC/ST students and employees and renders them necessary help in solving their academic as well as administrative problems.&nbsp;",
            "It functions to promote higher education among the SC/ST or weaker communities that are suffering from economic, social and educational deprivations.&nbsp;",
            "It continuously monitors and evaluates the reservation policies and other programs intended for SC/STs by the GOI for their effective implementation at NIT Warangal.&nbsp;",
            "It suggests the follow-up measures to the administration of the Institute for achieving the objectives and targets laid down by MHRD, GOI for the empowerment of SC/STs.",
          ],
        },
      },
      {
        id: "Qb93jx0TO2",
        type: "header",
        data: {
          text: "PROGRAMS ORGANIZED BY SC/ST CELL THROUGH SC/ST SUBPLAN:",
          level: 3,
        },
      },
      {
        id: "EpzYGLKc80",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Organized Guest Lectures on&nbsp;Ambedkar and his vision for Dalit education&nbsp;through SC/ST Sub plan, MHRD, GOI for the SC/ST students and employees of NITW on 06-12-2014 on the occasion of Mahaparinirvan Diwas of Dr. BR Ambedkar.&nbsp;",
            "GATE-2015 coaching classes&nbsp;for the final SC/ST B.Tech &amp; PG Students of NIT Warangal during 08-12-2014 to 31-01-2015 through SC/ST Sub plan, MHRD, GOI.&nbsp;",
            "Campus Placement Training Program&nbsp;for the SC/ST students of final year B.Tech, M.Tech and other PG Students of NIT Warangal during Nov-Dec,2014 and Jan-March, 2015through SC/ST Sub plan, MHRD, GOI&nbsp;",
            "Organized Guest lectures on&nbsp;Dr. B. R. Ambedkar and His Contribution to the Nation&nbsp;through SC/ST Sub plan, MHRD, GOI for the SC/ST students and employees of NITW on 14-04-2015 on the occasion of 125th birth anniversary celebrations of Dr. BR. Ambedkar.&nbsp;",
            "GATE-2015 Coaching Classes&nbsp;by SC/ST Cell, NITW from 8th December,2014 through SC/ST Subplan, NIT Warangal&nbsp;",
            "Communication Skill Development&nbsp;Classes by SC/ST Cell, NITW for the first year students of UG, PG &amp; Ph.D from 24th November, 2014 through SC/ST Subplan, NIT Warangal.&nbsp;",
            "Guest Lectures on&nbsp;‘Ambedkar and his Vision for Dalit Education’&nbsp;by SC/ST Cell, NITW on 06-12-2014 through SC/ST Subplan, NIT Warangal&nbsp;",
            "Organized an inspiration lecture on&nbsp;EXCELLENCE AND BETTER PERFORMANCE&nbsp;by Mr. Naresh Dubbudu, IIM Bangalore and Stanford University, USA, a 2004 alumnus and Gold medalist of NIT Warangal on 7th November, 2014 for all the SC/ST and other Students of NIT Warangal-&nbsp;Most of the Students got realized from the Lecture to rededicate for the cause of joining in NIT Warangal.&nbsp;",
            "Proposed&nbsp;Dr. B.R. AMBEDKAR LEARNING CENTRE&nbsp;with all the sophisticated amenities for organizing various SC/ST related academic and extra academic programs through SC/ST Sub plan, MHRD, GOI –&nbsp;The proposal was approved by the BOG and the preliminary estimates are to be placed before BWC for approval.&nbsp;",
            "Conducted&nbsp;CAMPUS PLACEMENT TRAINING PROGRAM (CPTP)&nbsp;for the final year B.Tech, M.Tech and other PG Students of NIT Warangal during 02-15 December, 2013 through SC/ST Sub plan, MHRD, GOI –&nbsp;The percentage of SC/ST students placed into public and private sector units got improved.&nbsp;",
            "Conducted&nbsp;GATE-2014&nbsp;coaching classes for the final B.Tech SC/ST and other Students of NIT Warangal during 02-12-2013 to 31-01-2014 through SC/ST Sub plan, MHRD, GOI-&nbsp;Quite a good number of the students secured better ranks.&nbsp;",
            "Conducted a two day workshop on&nbsp;RESEARCH METHODOLOGY AND SCHOLARLY WRITING SKILLS&nbsp;(RMSWS) for the SC/ST and other research scholars of NIT Warangal through SC/ST Sub plan, MHRD, GOI –&nbsp;There was an overwhelming response from the participants and everybody has derived maximum benefit out of the workshop.&nbsp;",
            "Conducted&nbsp;SOFT SKILL DEVELOPMENT TRAINING PROGRAM&nbsp;for the non-teaching staff of the Institute 10-12-2013 to 21-02-2014 through SC/ST Sub plan, MHRD, GOI –&nbsp;The program could impart reading and writing skills to all the non-teaching staff of the Institute.&nbsp;",
            "conducted Guest Lectures on&nbsp;Dr. B.R. AMBEDKAR’S THOUGHTS AND THEIR RELEVANCE IN THE PRESENT DAY CONTEXT&nbsp;on 14-04-2014 for all the SC/ST Students and staff of NIT Warangal through SC/ST Sub plan, MHRD, GOI-&nbsp;The program could provide minute details of Ambedkar’s thoughts embedded in Indian constitution for the upliftment of weaker sections to all the audience of the program.&nbsp;",
            "Many more programs are in pipeline………….",
          ],
        },
      },
      {
        id: "9wqdHQYp_l",
        type: "header",
        data: { text: "ESSAY WRITING AND ELOCUTION COMPETITIONS:", level: 3 },
      },
      {
        id: "J63PYEvymo",
        type: "paragraph",
        data: {
          text: "On the occasion of 125th birth anniversary celebrations-2015 of Dr. B. R. Ambedkar, the SC/ST Cell, NITW has organized Essay writing and Elocution competitions for the SC/ST Students and Non-teaching staff of the Institute.",
        },
      },
      {
        id: "wQUjloOdtg",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "<b>For Students of B.Tech, all PG &amp; Ph.D:</b>",
              "<b>For Non-teaching Staff:</b>",
            ],
            [
              "<b>Essay Writing Competition:</b>TOPIC: AMBEDKAR’S VISION ON THE EMPOWERMENT OF DALIT EDUCATION",
              "<b>Essay Writing Competition:</b>&nbsp;TOPIC:  RELEVANCE OF AMBEDKAR IN CONTEMPORARY INDIA ",
            ],
            [
              "<b>Elocution Competition:&nbsp;</b>TOPIC:  Is Education only for Employment or Social Democracy?",
              "<b>Elocution Competition :&nbsp;</b>TOPIC:  VISION OF AMBEDKAR FOR DALIT",
            ],
          ],
        },
      },
      {
        id: "WIfNtBCzxV",
        type: "header",
        data: { text: "Professor In-charge,  SC/ST Cell", level: 3 },
      },
      {
        id: "byJvw-6gq-",
        type: "paragraph",
        data: {
          text: "&nbsp; 1. &nbsp;Coordinator, SC/ST Cell &amp; Liaison Officer for SC/ST :&nbsp;&nbsp;Dr. J. Ravi Kumar(9490164799)&nbsp;&nbsp;",
        },
      },
    ],
    language: "En",
    _id: "6173f4fcf7fd7700239e68a9",
    time: 1631045002029,
    version: "2.22.2",
    title: "SC-ST Cell",
    path: "/student-welfare",
    url: "/student-welfare/sc-st-cell",
    createdAt: "2021-10-23T11:41:48.456Z",
    updatedAt: "2021-10-23T11:41:48.456Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "BBCozmS1tM",
        type: "header",
        data: { text: "Special Cell", level: 1 },
      },
      {
        id: "PR1bFLgTqY",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "S.No",
              "Cell Name",
              "Coordinators/Chairperson",
              "Members",
              "Contact Number",
            ],
            [
              "1",
              "Women's Protection Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16292/">Dr. B. Lakshmi</a>ProfessorDept. of Electronics &amp; communication Engineering&nbsp;&nbsp;',
              '&nbsp; 1)&nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16391/">Dr. J.V. Ramana Murthy</a>&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; Dean SW2)&nbsp;&nbsp;<a href="https://www.nitw.ac.in/main/home/" target="_blank">Sri S. Goverdhan Rao</a>,&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Registrar3)&nbsp; &nbsp;<b>Sri S. Shoury&nbsp;Reddy,&nbsp;</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Executive&nbsp; &nbsp;Director,&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Balavikas4)&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16409/">Dr. K. Padma</a>,&nbsp;&nbsp; &nbsp; &nbsp; Dept. of SOM5)&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16335/">Dr. K. Kadambari</a>,&nbsp; &nbsp; &nbsp; &nbsp;Dept. of CSE&nbsp;&nbsp;',
              "8332969705",
            ],
            [
              "2",
              "OBC Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16300/">Dr. (Smt.) V. Rama</a>Associate ProfessorDept. of Electronics &amp; communication Engineering',
              "",
              "9440762744",
            ],
            [
              "3",
              "SC/ST Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16305/">Dr. J. Ravi Kumar</a>Associate ProfessorDepartment of Mathematics',
              "",
              "09490164793",
            ],
            [
              "4",
              "Minority Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16876/">Dr. Arif Ali Baig Moghal</a>Associate ProfessorDept. Civil Engineering',
              "",
              "8702462133",
            ],
            [
              "5",
              "Differently Abled People Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16300/" target="_blank">Ms. V. Rama</a>Assistant ProfessorDept. Electronics &amp; communication Engineering',
              "",
              '9440762744<a href="mailto:dapcell@nitw.ac.in">dapcell@nitw.ac.in</a>',
            ],
          ],
        },
      },
      { id: "NmdUBDJSVm", type: "paragraph", data: { text: "<br>" } },
      { id: "Vw8Cg62w8b", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "6173f51df7fd7700239e68ab",
    time: 1640597313195,
    version: "2.22.2",
    title: "Special Cell",
    path: "/student-welfare",
    url: "/student-welfare/special-cell",
    createdAt: "2021-10-23T11:42:21.999Z",
    updatedAt: "2021-12-27T09:28:33.332Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "SQHqd1-0-C", type: "header", data: { text: "Sports", level: 1 } },
      {
        id: "3Tnl3RTpQr",
        type: "paragraph",
        data: {
          text: 'The&nbsp;<a href="https://www.nitw.ac.in/department/pe/">Department of Physical Education</a>&nbsp;provides training facilities for all the students and staff of this Institute. It has excellent infrastructure facilities for both outdoor and indoor games and sports. The outdoor games includes Volley Ball, Foot Ball, Cricket, Tennis, Ball Badminton, Kabaddi, Kho-Kho, Hand Ball, Hockey, Tennikoit, Basket Ball (concrete) and Tennis. Flood Light facility is provided to all the outdoor games. The sports ground accommodates a 400m standard track, an excellent pavilion and facilities for all athletic events.',
        },
      },
      {
        id: "r4m0Gr5nWF",
        type: "paragraph",
        data: {
          text: "<b>The Indoor Games Complex (IGC) provides the following facilities: &nbsp;&nbsp; &nbsp;&nbsp;</b>",
        },
      },
      {
        id: "ekf-m8rL4v",
        type: "paragraph",
        data: {
          text: "Chess, Caroms, Gymnastics, Shuttle badminton with wooden flooring, Table Tennis, Weight Lifting and 16 &amp; 12 stations multi-Gyms.",
        },
      },
      {
        id: "knivdBtZO8",
        type: "paragraph",
        data: { text: "<b>Activities of the department include :&nbsp;</b>" },
      },
      {
        id: "KIIQFlRg8k",
        type: "paragraph",
        data: {
          text: "Organizing intramural and extramural competitions and preparing the students for the same. &nbsp;&nbsp; &nbsp;",
        },
      },
      {
        id: "wit_8-YjmM",
        type: "paragraph",
        data: {
          text: "Encouraging student participation in inter-collegiate, Inter University, Inter-NIT and other open tournaments in the country.",
        },
      },
      {
        id: "SlXBi2_pbk",
        type: "paragraph",
        data: {
          text: "Organizing Inter-Collegiate tournaments, Inter University, Inter-National Institute of Technology tournaments coaching camps and open tournaments in various sports and games.",
        },
      },
      {
        id: "OKcU9VKwyG",
        type: "paragraph",
        data: {
          text: "Organizing tournaments for Teaching and Non-Teaching staff. &nbsp;&nbsp; &nbsp;",
        },
      },
      {
        id: "GaIdCssYkF",
        type: "paragraph",
        data: { text: "<b>Physical Directors :</b>" },
      },
      {
        id: "ruD-Ofz1yn",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "<b>R. Dayanithi, Ph.D.</b>",
            "<b>P.&nbsp;Madhusudan Reddy, Ph.D.</b>",
            "<b>P. Ravi Kumar, Ph.D.&nbsp;</b>",
          ],
        },
      },
    ],
    language: "En",
    _id: "6173f53bf7fd7700239e68ad",
    time: 1641378624562,
    version: "2.22.2",
    title: "Sports",
    path: "/student-welfare",
    url: "/student-welfare/sports",
    createdAt: "2021-10-23T11:42:51.859Z",
    updatedAt: "2022-01-05T10:28:40.755Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "EOIMoqtODM",
        type: "header",
        data: { text: "SPRING SPREE:", level: 1 },
      },
      {
        id: "1RpN8w6e00",
        type: "paragraph",
        data: {
          text: '<a href="http://www.springspree.in/">SpringSpree</a>&nbsp;was started in the year 1978 by the students of&nbsp;<a href="http://en.wikipedia.org/wiki/National_Institute_of_Technology,_Warangal">National Institute of Technology, Warangal</a>. &nbsp;It was an intercollegiate annual sports and cultural event held between January and March every year. Students came up with the idea of holding a small festival, which would lay down a platform for the students to showcase their skills in the areas of sports, athletics and cultural activities. Additionally, the festival was to unify a diverse student body by celebrating the culture of the regions to which they belonged along with the culture of India as a whole. It was the first college festival in southern India and was conducted every year till the end of the 90s. SpringSpree was not conducted for a few years in the early 2000s. Its revival was gradual with small fests from clubs finally giving rise to a mini cultural festival called Elysium in 2006. In 2007, SpringSpree was brought back and has since been an annual event.',
        },
      },
      {
        id: "posxotHmRH",
        type: "paragraph",
        data: {
          text: "SpringSpree, a three day annual cultural festival, showcases 55+ events in the fields of art, literature, drama, dance, music, quizzing, photography and painting. Including spotlight events like Fashion Show, Bboying Competition, Rock Music Competition, Model United Nations and Minute Made. A number of arts and skill oriented workshops are also held to give a jump start to the enthusiastic participants. Apart from these, SpringSpree holds various attractions like Amazing Race, Lounge, The One, The Haunted House and many others. To cater to the young entrepreneurs there are events involving business planning and stock marketing.",
        },
      },
      {
        id: "gxGCBEui7v",
        type: "paragraph",
        data: {
          text: 'SpringSpree also conducts concerts during the three days of Spree, known Pronites. Famous musicians are invited to close each day of the fest with a bang. In the years prior, National Institute of Technology, Warangal, has seen the likes of&nbsp;Pt. Hariprasad Chaurasia, Pt. Jasraj,&nbsp;<a href="http://en.wikipedia.org/wiki/Naresh_Iyer">Naresh Iyer</a>&nbsp;and&nbsp;<a href="http://en.wikipedia.org/wiki/Stephen_Devassy">Stephen Devassy</a>&nbsp;to name a few.&nbsp;Indian rock bands like&nbsp;<a href="http://en.wikipedia.org/wiki/Parikrama_(band)">Parikrama</a>, Thermal And a Quarter, ThemClones, Drum Cafe and&nbsp;others have also performed.',
        },
      },
      {
        id: "WjOYm3bf_f",
        type: "paragraph",
        data: {
          text: "SpringSpree is a fest conducted by the students of National Institute of Technology, Warangal on a non-profit basis. Every year a core committee of students is chosen by the student council of the college to oversee the smooth running of the fest. They are aided by the student council themselves and a faculty advisor chosen by the administration to assist them in their tasks. The core committee looks after the all important aspects of the fest like Event Conduction, Sponsorship, Publicity, Logistics, Hospitality, Design, Decoration, Accounting and Prizes. Every member of the committee is dedicated to either of these departments. The synchronization between all the working committees is taken care by the student coordinator of the festival. To assist the core committee, a sub-core committee and a work force body is chosen forming an apex structure contributing to a students working team. The success of the festival heavily depends on the efforts and idea of the entire students working team",
        },
      },
      {
        id: "wwK0dpDOGH",
        type: "paragraph",
        data: {
          text: 'The Official Website for the festival is&nbsp;“<a href="http://www.springspree.in/" target="_blank">www.springspree.in</a>”',
        },
      },
      {
        id: "Vr-RdnZABA",
        type: "paragraph",
        data: {
          text: 'Faculty Advisor Spring Spree - 2020&nbsp; :&nbsp; &nbsp;<a href="https://www.nitw.ac.in/faculty/id/16393/">Dr. D. Srinivasacharya</a>',
        },
      },
    ],
    language: "En",
    _id: "6173f564f7fd7700239e68af",
    time: 1631045697814,
    version: "2.22.2",
    title: "Springspree",
    path: "/student-welfare",
    url: "/student-welfare/springspree",
    createdAt: "2021-10-23T11:43:32.303Z",
    updatedAt: "2021-10-23T11:43:32.303Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "doB8tQPPPf",
        type: "header",
        data: { text: "Technozion", level: 1 },
      },
      {
        id: "oENvXDePBp",
        type: "paragraph",
        data: {
          text: '<a href="http://www.technozion.org/land/" target="_blank">Technozion</a>&nbsp;a three day annual Technical Festival organized aims to provide a platform for students across the globe to assemble, interact, share and gain knowledge in various fields of science and technology. Through this Symposium we seek to provide a platform for budding engineers and scientists from all over the world to display their skills in a plethora of events designed to exercise their minds, and to further a spirit of technology. From its inception in 2006, Technozion has grown in strength and in numbers, and is now considered to be amongst the second biggest technical festivals in India. One of the hallmarks of Technozion has always been the presence of notable luminaries gracing the Institute portals, delivering inspiring lectures, video conferencing and conducting interactive workshops for the benefit of the student community.',
        },
      },
      {
        id: "AXFG7z6WKs",
        type: "paragraph",
        data: {
          text: "From its inception in 2006, Technozion has grown in strength and in numbers, and is now considered to be amongst the second biggest technical festivals in India. One of the hallmarks of Technozion has always been the presence of notable luminaries gracing the Institute portals, delivering inspiring lectures, video conferencing and conducting interactive workshops for the benefit of the student community.",
        },
      },
      {
        id: "gGx4Ppf37J",
        type: "paragraph",
        data: {
          text: 'For more details visit Technozion official website &nbsp;<a href="http://www.technozion.org/" target="_blank">www.technozion.org</a>',
        },
      },
      {
        id: "PP3o6s8nW0",
        type: "paragraph",
        data: {
          text: 'Contact Technozion, NIT Warangal on&nbsp;<a href="mailto:info@technozion.org">info@technozion.org</a>',
        },
      },
      {
        id: "2vymWmPR63",
        type: "paragraph",
        data: {
          text: '<b>Faculty Advisor, Technozion – 2019</b>:&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16297/">Dr. L. Anjaneyulu</a>',
        },
      },
    ],
    language: "En",
    _id: "6173f58df7fd7700239e68b1",
    time: 1631046022499,
    version: "2.22.2",
    title: "Technozion",
    path: "/student-welfare",
    url: "/student-welfare/technozion",
    createdAt: "2021-10-23T11:44:13.923Z",
    updatedAt: "2021-10-23T11:44:13.923Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: {
          text: "Welcome to Computer Science and Engineering Department",
          level: 3,
        },
      },
      {
        id: "a6c0b47p8Z",
        type: "paragraph",
        data: {
          text: "<editorjs-style>The Department of Computer Science &amp; Engineering was established in the year 1991. The department offers high quality undergraduate, postgraduate and doctoral programs. The B. Tech (Computer Science and Engineering) program was started in the year 1983 with an intake of 20 students. The intake was subsequently increased to 120 in 2008. M. Tech (Computer Science and Engineering) program was started in 1987 with an intake of 18 and subsequently increased to 20 in 2008. M. Tech (Information Security) was introduced in the year 2008 Under ISEAP sanctioned by Ministry of Communication and Information Technology (MCIT), DOE, GOI, New Delhi with intake of 20. Later, it was renamed as Computer Science and Information Security. The Master of Computer Applications (MCA) program was started in 1986 with an intake of 30 and increased to 46 from 2008. B.Tech, M.Tech (CSE) and M.Tech (CSIS) programs were accredited in 2014 by NBA as per Washington Accord.</editorjs-style> ",
        },
      },
      {
        id: "lGg2P6tN2S",
        type: "paragraph",
        data: {
          text: "The department has distinguished and committed faculty members with PhD from reputed institutes. It has close rapport with MICROSOFT, TCS, INFOSYS, ORACLE, TRDDC-Pune, SUN Microsystems, EMC2, ACCENTURE, C-DAC, MOTOROLA,  HONEYWELL, NOVELL, PHILIPS, SAMSUNG, and IBM-Bangalore. The department has MOUs with TCS, IBM, C-DAC and INFOSYS, for training students and faculty on latest cutting edge technologies and also to pursue Research and Development activities.The Department has been selected as  a Remote Center under Information Security Awareness Project (ISEAP) Phase II, MCIT Dept of IT. Along with department of ECE, it is associated in E&amp;ICT Academy project sanctioned by Department of Electronics and Information Technology, Govt of India for Rs.25 crores to train faculty from Engineering and Polytechnic Streams across  Telangana, Andhra Pradesh, Karnataka States and Pudicherry, Andaman &amp; Nicobar islands and Lakshadweep UTs.",
        },
      },
    ],
    language: "En",
    _id: "618ca1266310bc5b10af9d51",
    time: 1642511373129,
    version: "2.22.2",
    title: "Home",
    url: "/cs/home",
    path: "/cs",
    createdAt: "2021-11-11T04:50:46.443Z",
    updatedAt: "2022-01-18T13:09:32.904Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "Vision", level: 4 } },
      {
        id: "tUmk3vHXCw",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Attaining global recognition in Computer Science &amp; Engineering education, research and training to meet the growing needs of the industry and society.",
          ],
        },
      },
      { id: "Tg4wLaJxjf", type: "header", data: { text: "Mission", level: 4 } },
      {
        id: "p575CShI6D",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Imparting quality education through well-designed curriculum in tune with the challenging software needs of the industry.",
          ],
        },
      },
      {
        id: "EJgmEbi7Rc",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Providing state-of-art research facilities to generate knowledge and develop technologies in the thrust areas of Computer Science and Engineering.",
          ],
        },
      },
      {
        id: "k5bjbOAK13",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Developing linkages with world class organizations to strengthen industry-academia relationships for mutual benefit.",
          ],
        },
      },
    ],
    language: "En",
    _id: "618ca43a8c1c795b0fc16519",
    time: 1638253167425,
    version: "2.22.2",
    title: "Vision And Mission",
    url: "/cs/vision",
    path: "/cs",
    createdAt: "2021-11-11T05:03:54.821Z",
    updatedAt: "2021-11-30T06:19:27.786Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Page Heading", level: 2 },
      },
      { id: "HDN5BLThnE", type: "paragraph", data: { text: "Random Text" } },
    ],
    language: "En",
    _id: "618cef4d8c1c795b0fc1651f",
    time: 1636626266780,
    version: "2.22.2",
    title: "Documentation",
    url: "/documentation",
    path: "/documentation",
    createdAt: "2021-11-11T10:24:13.095Z",
    updatedAt: "2021-11-11T10:24:27.670Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "AYGRv6_oSW",
        type: "header",
        data: { text: "B.Tech&nbsp;", level: 3 },
      },
      {
        id: "wJTNpxWRMR",
        type: "header",
        data: { text: "B.Tech Program", level: 4 },
      },
      {
        id: "lujsoyIt3t",
        type: "paragraph",
        data: {
          text: '<a href="http://daa.nitw.ac.in:8081/page/?url=/academicprogram/btech/btechprogram">Click here to view</a>',
        },
      },
    ],
    language: "En",
    _id: "618e0e56a0407b2d686e1e73",
    time: 1636702071251,
    version: "2.22.2",
    title: "Academic program",
    url: "/academicprogram/btech",
    path: "/academicprogram",
    createdAt: "2021-11-12T06:48:54.122Z",
    updatedAt: "2021-11-12T07:27:51.710Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "Btech", level: 3 } },
      {
        id: "PaKoJhgKAW",
        type: "header",
        data: { text: "Btech program", level: 5 },
      },
      {
        id: "U-8iXkR_Bj",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/document/d/1_ty4RU6Wx-9RBcmgtlyh19g-mCw_aOynLDs191HAGrc/edit">Click here view btech program</a>',
        },
      },
    ],
    language: "En",
    _id: "618e1094e23e7a2d5a281d48",
    time: 1636700308014,
    version: "2.22.2",
    title: "Academic Program",
    url: "/academicprogarm/btech",
    path: "/academicprogram",
    createdAt: "2021-11-12T06:58:28.515Z",
    updatedAt: "2021-11-12T06:58:28.515Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "B.Tech Program", level: 2 },
      },
      { id: "HDN5BLThnE", type: "paragraph", data: { text: "Something" } },
    ],
    language: "En",
    _id: "618e171ad0de4b2d69ccbfbe",
    time: 1636701978008,
    version: "2.22.2",
    title: "B.Tech Program",
    url: "/academicprogram/btech/btechprogram",
    path: "----",
    createdAt: "2021-11-12T07:26:18.426Z",
    updatedAt: "2021-11-12T07:26:18.426Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "test3 2021", level: 2 },
      },
    ],
    language: "En",
    _id: "618e371b569b682d5b12dc4b",
    time: 1636710170871,
    version: "2.22.2",
    title: "test3",
    url: "test 3",
    path: "test3/2021",
    createdAt: "2021-11-12T09:42:51.030Z",
    updatedAt: "2021-11-12T09:42:51.030Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "brwsclf8qP", type: "header", data: { text: "Library", level: 3 } },
      {
        id: "riXaKVY_sb",
        type: "paragraph",
        data: {
          text: "The Central Library supports the teaching and research programs of the Institute and provides facilities for general reading and disseminates information according to the requirement of the users. It is housed in a separate building with a plinth area of 4000 Sq. Mts.&nbsp; It has around 1,79,797 books, back volumes, technical pamphlets, standards, CD-ROMs, Video Cassettes, e-books etc.&nbsp; The total cost of the books is Rs. 10&nbsp;Crores and Central Library subscribed 3200 e-journals.&nbsp;It also receives 4645 e-journals&nbsp;through E-Shodh Sindhu consortum.",
        },
      },
      {
        id: "f0Llgq2u6H",
        type: "paragraph",
        data: {
          text: "The services and operations in the Central Library are fully computerized.&nbsp; The database of entire Library acquisitions was created using Koha software.",
        },
      },
      {
        id: "XOSmbK3MDv",
        type: "image",
        data: {
          file: {
            url: `${MainServerUrl}/static/files/library_2021-10-12-12-49-58.jpg`,
          },
          caption: "Library NITW",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "bR_sEayXjH",
        type: "paragraph",
        data: {
          text: "Library at NIT Warangal is your window to the world of latest information in sciences, engineering, technology, School of  Management . Being an integral part of academic and research work on the campus, it provides library and information services to support the teaching, learning, and research activities of the Institute by creating state-of-the-art facilities and offering innovative services. The library has a fast growing collection of books, journals, magazines both in print and digital format. Most of the in-house services of the library are fully computerized and provides access to many core subscribed online and open access resources.",
        },
      },
      {
        id: "FYHu3C8l1V",
        type: "image",
        data: {
          file: {
            url: `${MainServerUrl}/static/files/librarian_2021-10-12-12-52-35.jpg`,
          },
          caption: "Dr. K. Veeranjaneyulu",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "sTPzC7GzYl",
        type: "header",
        data: { text: "Librarian", level: 5 },
      },
      {
        id: "_mIGBXPDT0",
        type: "paragraph",
        data: { text: "Dr. K. Veeranjaneyulu" },
      },
      {
        id: "Sm9INV5kj0",
        type: "paragraph",
        data: { text: "Contact:&nbsp;9491085289<br><br>" },
      },
      {
        id: "0_pA0jsakj",
        type: "header",
        data: { text: "Annual Research Digest of Ph.D Theses", level: 5 },
      },
      {
        id: "a0e18RRdc2",
        type: "paragraph",
        data: { text: "<b>Click here to view</b>" },
      },
      {
        id: "I4gw8Bu5X2",
        type: "header",
        data: {
          text: '1.&nbsp;<a href="https://nitw.ac.in/ard2019/">Annual Research Digest of Ph.D Theses (2019 - 2020)</a>',
          level: 6,
        },
      },
      {
        id: "wJGaplcuYG",
        type: "header",
        data: {
          text: '2.&nbsp;<a href="https://nitw.ac.in/ard/">Annual Research Digest of Ph.D Theses (2018 - 2019)</a>',
          level: 6,
        },
      },
    ],
    language: "En",
    _id: "618e7aafcabe492d85a48fa5",
    time: 1640586375058,
    version: "2.22.2",
    title: "Home",
    url: "/facilities/library/home",
    path: "/facilities/library",
    createdAt: "2021-11-12T14:31:11.281Z",
    updatedAt: "2021-12-27T06:26:15.212Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "2oFF_4TQuI",
        type: "header",
        data: { text: "Barcode Member ID Cards", level: 3 },
      },
      {
        id: "YZm485Ti-d",
        type: "paragraph",
        data: {
          text: "Each bonafide user will be provided smart card with bar-code for the duration of course.&nbsp; The Member ID card is not transferable.&nbsp; Penalty for loss of member ID card is Rs. 500.",
        },
      },
      {
        id: "nzT0HFAPyi",
        type: "header",
        data: { text: "Borrowing Privileges", level: 3 },
      },
      {
        id: "Tj6t-jVtbi",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Category", "Number of Loans", "Period of Loan"],
            ["UG Students", "6", "One Month"],
            ["PG/M.Tech./MCA/MBA &amp; Research Scholars", "6", "One Month"],
            ["Faculty Members", "12", "One Semester"],
            ["Administrative Officers", "2", "One Month"],
            ["Administrative / Technical Staff", "2", "One Month"],
          ],
        },
      },
    ],
    language: "En",
    _id: "618e7cc3f9c3d02d941f597a",
    time: 1636732181495,
    version: "2.22.2",
    title: "Membership",
    url: "/facilities/library/membership",
    path: "/facilities/library",
    createdAt: "2021-11-12T14:40:03.323Z",
    updatedAt: "2021-11-12T15:49:43.465Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "XFhaNnMsJ2",
        type: "header",
        data: {
          text: '<a href="http://nitwopac.in:7071/">Web OPAC</a>',
          level: 4,
        },
      },
    ],
    language: "En",
    _id: "618e8e6cd3f3052db2387795",
    time: 1636732522823,
    version: "2.22.2",
    title: "OPAC",
    url: "/facilities/library/opac",
    path: "/facilities/library",
    createdAt: "2021-11-12T15:55:24.595Z",
    updatedAt: "2021-11-12T15:55:24.595Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "TRSOnO7DRO",
        type: "header",
        data: { text: "Digital Library", level: 3 },
      },
      {
        id: "DqdD5izbyr",
        type: "image",
        data: {
          file: {
            url: `${MainServerUrl}/static/files/Digital_Library_2021-10-12-21-30-3.png`,
          },
          caption: "Digital Library",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "lKuhhM7kOB",
        type: "paragraph",
        data: {
          text: "The Library has campus LAN connectivity with fiber optic cable (10/100 Mbps) through the Computer Center and is connected to web server (Duel Xeon).  50 systems providing network facility are installed for accessing / browsing Online.  During the year 2003 the MHRD, Govt. of India, New Delhi formed a “Consortia-based subscription to Electronic resources for Technical Education” and named as INDEST (Indian Nationals Digital Library in Engineering Science and Technology), its head quarters are at I.I.T., Delhi and receiving about 2000 online Journals. The online Journals that are available through E-Shodh Sindhu consortium and NITW.",
        },
      },
      {
        id: "r0s2I6eekY",
        type: "header",
        data: { text: "IT Facilities", level: 3 },
      },
      {
        id: "zUM8YUQL0H",
        type: "paragraph",
        data: {
          text: "The Central Library is connected to the CAMPUS LAN.&nbsp; The Library server works under windows XP environment.&nbsp; Subsequently, the house keeping operations and services such as Acquisition entries, Serial Control, Circulation of books, using of Barcode system for issue and return of books have been computerized.&nbsp; The faculty members can access the OPAC&nbsp; from their respective departments through the campus LAN.",
        },
      },
      {
        id: "xPDNhlcIn8",
        type: "header",
        data: { text: "Virtual Library", level: 3 },
      },
      {
        id: "F7V-Gr9v8G",
        type: "paragraph",
        data: {
          text: "The Central Library has also established Virtual Library by procuring IT infrastructure.&nbsp; Xeon Server E5-2603v3, 6-core/15MB/85W Processor &nbsp;and 50 Nos. of HP Elite Desktop 800 G1 with TFT Monitors.&nbsp; The networking was also provided connecting to servers.&nbsp; The Digital Library and E-learning Software were also procured and loaded in the servers.&nbsp; The creation of archive data is in progress.&nbsp; The users can access in the Virtual Library through off-line.",
        },
      },
      {
        id: "130XEQi2XI",
        type: "header",
        data: { text: "TECHFOCUZ", level: 5 },
      },
      {
        id: "vtlu3oJs21",
        type: "header",
        data: { text: "Digital Library Software 4.0", level: 5 },
      },
      {
        id: "2lL9ZaUFkK",
        type: "paragraph",
        data: {
          text: "Techfocuz digital library software is a complete software /hardware solution infrastructure that helps the customer to manage digital data - store , manage and retrieve in the most simple and ideal way. It will help the customers to store all their digital data archives available in the form of CDs, DVDs, files in to the digital library software server in a very logical way exactly like how he stores it in a books library. The software will be having two interfaces, Admin interface and Client interface.Admin (Most of the time, a librarian or IT manager) can create categories and store this above data in the form of ISO images inside the server. Further, they can create the users and give this powers to access the categories and data inside it.The client interface is a web client and if the customer is having plans to host the Digital library server, it is possible.Users has been empowered with Content search, Keyword search, photo gallery, Cd emulation softwares, Dublin standard file archive facilities etc, which makes this software a truly outstanding product when compared with others that is in the market.",
        },
      },
      {
        id: "4TZ2izakml",
        type: "header",
        data: { text: "Institutional Repository (IR)", level: 3 },
      },
      {
        id: "Jh1yvdKt2T",
        type: "paragraph",
        data: {
          text: "It is also proposed to establish IR (Institutional Repository) a digital archive of research out-put created by the faculty, Research Scholars and Dissertations of PG/M.Tech. programmes and able to access through on-line to the end-user in the institute.&nbsp; The procurement of relevant IT infrastructure i.e hard disk-caching server, systems and furniture etc. are in the pipeline.",
        },
      },
      { id: "V0Cf3blARr", type: "header", data: { text: "DELNET", level: 3 } },
      {
        id: "aUnK5uW-HR",
        type: "paragraph",
        data: {
          text: "The DELNET (Developing Library Network) New Delhi connectivity is also provided in the Library for searching Bibliographical databases and abstracts of various technical subjects of the other institutions.",
        },
      },
      {
        id: "7DmVs03qUH",
        type: "header",
        data: { text: "Audio-Visual (AV)", level: 3 },
      },
      {
        id: "atU0p75wbr",
        type: "paragraph",
        data: {
          text: "The Central Library houses an audio-visual section with 2 sets of TVs and VCRs.&nbsp; A good collection of Educational Videos are available on varied subjects.",
        },
      },
      { id: "6UjQNLKU3k", type: "header", data: { text: "EDUSAT", level: 3 } },
      {
        id: "K3QuWOE3nV",
        type: "paragraph",
        data: {
          text: "EDUSAT is the first Indian Satellite designed and developed exclusively for serving the educational sector. It is mainly intended to meet the demand for an interactive satellite based distance education system for the country.",
        },
      },
      {
        id: "c1uqY4cToO",
        type: "paragraph",
        data: {
          text: "It is a collaborative project of ISRO, MHRD, AICTE and IGNOU.&nbsp; A satellite terminal has been installed in September 2005 at NIT, Warangal under EDUSAT to receive on-line lectures from ISRO HUB, IGNOU, AICTE covering various subjects of Science and Technology.",
        },
      },
      {
        id: "nw0kf5LHJH",
        type: "header",
        data: { text: "Document Delivery Service", level: 3 },
      },
      {
        id: "h-9MKy3B0q",
        type: "image",
        data: {
          file: {
            url: `${MainServerUrl}/static/files/Document_delivery_2021-10-12-22-32-25.png`,
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "16hnVZH7m0",
        type: "paragraph",
        data: {
          text: "Document Delivery Service through which any library member can send a request to the library for obtaining photocopies of journals articles which are not available in our central library. This service is provided free of charge to all library members of NIT Warangal for academic and research purpose only.",
        },
      },
      {
        id: "mqAujBVxsb",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To avail this service, library users are requested to fill Google Form&nbsp;with full bibliographic details of the documents (not more than two articles at a time)&nbsp;",
        },
      },
      {
        id: "6siX7pjfuI",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSc0RssXCzJHmhWYa5iMJL_0qy_7dhTBFDhQWSh-LlR1EmKJNg/viewform">https://forms.gle/XHRYHffmsJ3wXzko8</a>',
        },
      },
      {
        id: "Px8lmwJU8i",
        type: "header",
        data: { text: "Faculty Information System", level: 3 },
      },
      {
        id: "JHDo2ERzVx",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;The Central Library has implemented a new “Faculty Information System” under the Indian Research Information Network System (IRINS) to showcase the academic and research activities of the institute faculty members and provide an opportunity to create a scholarly research network.",
        },
      },
      {
        id: "PegU_yJloT",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;The NITW- Indian Research Information Network System (IRINS) will support the integration of the existing research management system such as HR system, course management, grant management system, institutional repository, Open and commercial citation databases, scholarly publishers, etc. This will be integrated with the academic identities such as ORCID ID, Scopus ID, Research ID, Microsoft Academic ID, and Google Scholar ID for ingesting the scholarly publication from various sources. This Faculty Information System can become the source for the NIRF, NAC and other international raking systems. The Faculty Information System can be accessed by everyone through the following link:",
        },
      },
      {
        id: "TZiaRwXIlt",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.irins.org/">https://nitw.irins.org/</a>',
        },
      },
      {
        id: "alSOdLUa9v",
        type: "header",
        data: { text: "National Science Digital Library", level: 3 },
      },
      {
        id: "s5d-n6_55A",
        type: "paragraph",
        data: {
          text: "National Science Digital Library (NSDL) aims at providing comprehensive S&amp;T information to students of science, engineering and technology in the country. Begun as a Tenth Five Year Plan Network Project of Council of Scientific and Industrial Research (CSIR), NSDL is the only one of its kind that provides curriculum based content to address the information needs of the undergraduate students of science. The content creation and development for NSDL has gone through rigorous procedures to make available quality content for the students. Authored by eminent teachers and validated by renowned faculty in Indian universities and colleges, NSDL envisages to bring finest content to the students. The discussion forum has been provided for interactions amongst NSDL users.",
        },
      },
      {
        id: "HDjY5pCEzs",
        type: "paragraph",
        data: {
          text: '<a href="https://nsdl.oercommons.org/">https://nsdl.oercommons.org/</a>',
        },
      },
      {
        id: "VzSHyy1kv_",
        type: "paragraph",
        data: {
          text: '<a href="http://nsdl.niscair.res.in/jspui/">http://nsdl.niscair.res.in/jspui/</a>',
        },
      },
    ],
    language: "En",
    _id: "618e91ce905f6e2da3ede89d",
    time: 1637068012293,
    version: "2.22.2",
    title: "Services",
    url: "/facilities/library/services",
    path: "/facilities/library",
    createdAt: "2021-11-12T16:09:50.216Z",
    updatedAt: "2021-11-16T13:06:53.758Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "wrX2M596Gj",
        type: "header",
        data: { text: "E-Resources provided by e-SHODH SINDHU", level: 3 },
      },
      {
        id: "segSYm6BKC",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Sl.No.", "Name of the Database / Publishers", "URL"],
            [
              "1",
              "ACS Digital Library",
              '<a href="http://d.lacm/org">http://d.lacm/org</a>',
            ],
            [
              "2",
              "ASCE Journal Online",
              '<a href="https://ascelibrary.org/">https://ascelibrary.org/</a>',
            ],
            [
              "3",
              "ASME Journals Online",
              '<a href="https://asmedigitalcollection.asme.org/">https://asmedigitalcollection.asme.org/</a>',
            ],
            [
              "4",
              "Economic &amp; Political Weekly",
              '<a href="http://epw.in/">http://epw.in/</a>',
            ],
            [
              "5",
              "Institute for Studies in Industrial Development (ISID) Database",
              '<a href="https://isid.org.in/">https://isid.org.in/</a>',
            ],
            [
              "6",
              "JGate Plus (JCCC)",
              '<a href="https://jgateplus.com/search/">https://jgateplus.com/search/</a>',
            ],
            [
              "7",
              "JSTOR",
              '<a href="https://www.jstor.org/">https://www.jstor.org/</a>',
            ],
            [
              "8",
              "Oxford University Press",
              '<a href="https://academic.oup.com/journals">https://academic.oup.com/journals</a>',
            ],
            [
              "9",
              "Web of Science Lease Access (ESS)",
              '<a href="http://webofknowledge.com/">http://webofknowledge.com</a>',
            ],
          ],
        },
      },
      {
        id: "llGpriLMlt",
        type: "header",
        data: { text: "E-Resources Subscribed NIT Warangal", level: 3 },
      },
      {
        id: "EnI7IMiAM-",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Sl. No", "Resource Name", "URL"],
            [
              "1",
              "ASTM Standards",
              '<a href="https://www.astm.org/">https://www.astm.org/</a>',
            ],
            [
              "2",
              "ACS Journals",
              '<a href="https://pubs.acs.org/">https://pubs.acs.org/</a>',
            ],
            [
              "3",
              "Emerald Journals",
              '<a href="https://www.emeraldgrouppublishing.com/">https://www.emeraldgrouppublishing.com/</a>',
            ],
            [
              "4",
              "Elsevier Science Direct",
              '<a href="http://www.sciencedirect.com/">http://www.sciencedirect.com/</a>',
            ],
            [
              "5",
              "IEEE Xplore",
              '<a href="https://ieeexplore.ieee.org/Xplore/home.jsp">https://ieeexplore.ieee.org/Xplore/home.jsp</a>',
            ],
            [
              "6",
              "Royal Society of Chemistry",
              '<a href="http://pubs.rsc.org/">http://pubs.rsc.org</a>',
            ],
            [
              "7",
              "Sage Journals",
              '<a href="https://journals.sagepub.com/">https://journals.sagepub.com/</a>',
            ],
            [
              "8",
              "SciFinder",
              '<a href="https://www.cas.org/solutions/cas-scifinder-discovery-platform/cas-scifinder">https://www.cas.org/solutions/cas-scifinder-discovery-platform/cas-scifinder</a>',
            ],
            [
              "9",
              "Springer Link (1400+ Jnls) (NITS)",
              '<a href="http://www.springerlink.com/">http://www.springerlink.com/</a>',
            ],
            [
              "10",
              "Taylor &amp; Francis (NITS)",
              '<a href="http://www.tandfonline.com/">http://www.tandfonline.com</a>',
            ],
          ],
        },
      },
      { id: "5imUrdUI9v", type: "header", data: { text: "E-B00KS", level: 3 } },
      {
        id: "oYR34Xz-EN",
        type: "paragraph",
        data: { text: "1.Active Power Line Conditioners 9780128032169&nbsp;" },
      },
      {
        id: "OTO3f9QQUh",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128032169" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128032169</a>',
        },
      },
      {
        id: "eh4sYLysgr",
        type: "paragraph",
        data: {
          text: "2.Advances in Batteries for Medium and Large-Scale Energy Storage 9781782420132",
        },
      },
      {
        id: "f8Hfd6BJ00",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9781782420132" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9781782420132</a>',
        },
      },
      {
        id: "n33NRWT6om",
        type: "paragraph",
        data: {
          text: "3.Advances in battery technologies for electric vehicles 9781782423775&nbsp;",
        },
      },
      {
        id: "m2FgYjv7Z5",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9781782423775" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9781782423775</a>',
        },
      },
      {
        id: "WwMCqsjnzY",
        type: "paragraph",
        data: {
          text: "4.Alternative Energy in Power Electronics 9780124167148&nbsp;",
        },
      },
      {
        id: "9OefT8L4av",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780124167148" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780124167148</a>',
        },
      },
      {
        id: "JfAVEh7BWs",
        type: "paragraph",
        data: {
          text: "5.Clean Electricity Through Advanced Coal Technologies 9781437778151&nbsp;",
        },
      },
      {
        id: "VQ-Yj_T0iu",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9781437778151" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9781437778151</a>',
        },
      },
      {
        id: "N7Gg6TkhIf",
        type: "paragraph",
        data: { text: "6.Competitive Electricity Markets 9780080471723&nbsp;" },
      },
      {
        id: "JDRCHCA0UF",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780080471723" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780080471723</a>',
        },
      },
      {
        id: "rEiC0KsQpJ",
        type: "paragraph",
        data: { text: "7.Control System Design Guide 9780123859204&nbsp;" },
      },
      {
        id: "ihzsbTxcS8",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780123859204" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780123859204</a>',
        },
      },
      {
        id: "JTNdNcsvhf",
        type: "paragraph",
        data: { text: "8.Digital Signal Processing 9780124158931&nbsp;" },
      },
      {
        id: "ngag645uuw",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780124158931" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780124158931</a>',
        },
      },
      {
        id: "kvcj64buKb",
        type: "paragraph",
        data: { text: "9.Electric Motors and Drives 9780080983325&nbsp;" },
      },
      {
        id: "qseIF-syEB",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780080983325" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780080983325</a>',
        },
      },
      {
        id: "FSiUXunwqa",
        type: "paragraph",
        data: {
          text: "10.Electric Renewable Energy Systems 9780128044483&nbsp;",
        },
      },
      {
        id: "cekh9eO6oj",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128044483" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128044483</a>',
        },
      },
      {
        id: "6TpXPvcHYh",
        type: "paragraph",
        data: {
          text: "11.Electrical Drives for Direct Drive Renewable Energy Systems 9781845697839&nbsp;",
        },
      },
      {
        id: "MxDxTSVmWD",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9781845697839" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9781845697839</a>',
        },
      },
      {
        id: "IWUCXe805X",
        type: "paragraph",
        data: { text: "12.Electrical Load Forecasting 9780123815439&nbsp;" },
      },
      {
        id: "jT2Yc92gk9",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780123815439" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780123815439</a>',
        },
      },
      {
        id: "BgKoMktqs_",
        type: "paragraph",
        data: { text: "13.Electrical Motor Products 9780857090775&nbsp;" },
      },
      {
        id: "BbB94o8F94",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780857090775" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780857090775</a>',
        },
      },
      {
        id: "Uc66nat0QZ",
        type: "paragraph",
        data: {
          text: "14.Electricity Transmission&nbsp; Distribution and Storage Systems 9781845697846&nbsp;",
        },
      },
      {
        id: "b06UhQ4EUw",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9781845697846" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9781845697846</a>',
        },
      },
      {
        id: "naR6rmCZiJ",
        type: "paragraph",
        data: { text: "15.Energy Management Principles 9780128025062&nbsp;" },
      },
      {
        id: "eQQlp-zFiL",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128025062" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128025062</a>',
        },
      },
      {
        id: "9YMNdAZwp8",
        type: "paragraph",
        data: { text: "16.Energy Storage 9780128034408&nbsp;" },
      },
      {
        id: "iG_t25xwLy",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128034408" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128034408</a>',
        },
      },
      {
        id: "Uu4NRvS37z",
        type: "paragraph",
        data: { text: "17.Energy Storage for Smart Grids 9780124104914" },
      },
      {
        id: "H-0EcjIn2F",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780124104914" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780124104914</a>',
        },
      },
      {
        id: "uoGq747pAt",
        type: "paragraph",
        data: {
          text: "18.Energy Storage for Sustainable Microgrid 9780128033746&nbsp;",
        },
      },
      {
        id: "Q3A2XBQvn0",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128033746" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128033746</a>',
        },
      },
      {
        id: "Po5Jy9na68",
        type: "paragraph",
        data: {
          text: "19.Feature Extraction &amp; Image Processing for Computer Vision 9780123965493&nbsp;",
        },
      },
      {
        id: "TAo377QvX8",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780123965493" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780123965493</a>',
        },
      },
      {
        id: "5JGmPDFMwO",
        type: "paragraph",
        data: { text: "20.Group Theory for Chemists 9780857092403&nbsp;" },
      },
      {
        id: "yUpVuQ7XBI",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780857092403" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780857092403</a>',
        },
      },
      {
        id: "xV_RRfpm0J",
        type: "paragraph",
        data: { text: "21.Handbook of Energy 9780124170131&nbsp;" },
      },
      {
        id: "YigpkHht2q",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780124170131" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780124170131</a>',
        },
      },
      {
        id: "zwhocFrZXE",
        type: "paragraph",
        data: {
          text: "22.High-temperature Solid Oxide Fuel Cells for the 21st Century 9780124104532&nbsp;",
        },
      },
      {
        id: "gB_J-eH2lu",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780124104532" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780124104532</a>',
        },
      },
      {
        id: "gIGEpcp_Bu",
        type: "paragraph",
        data: {
          text: "23.Industrial Applications of Batteries 9780444521606&nbsp;",
        },
      },
      {
        id: "pC0cRmiaTH",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780444521606" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780444521606</a>',
        },
      },
      {
        id: "-l8Uk-BYEk",
        type: "paragraph",
        data: { text: "24,Inorganic Chemistry 9780123851109&nbsp;" },
      },
      {
        id: "I4caJaTRM7",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780123851109" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780123851109</a>',
        },
      },
      {
        id: "amMVO5QGvr",
        type: "paragraph",
        data: {
          text: "25.Integration of Distributed Energy Resources in Power Systems 9780128032121&nbsp;",
        },
      },
      {
        id: "eboY3A7ju8",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128032121" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128032121</a>',
        },
      },
      {
        id: "iCwPfGHc8c",
        type: "paragraph",
        data: {
          text: "26.Large-Scale Wind Power Grid Integration 9780128498958&nbsp;",
        },
      },
      {
        id: "f6pUNlk4Gk",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128498958" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128498958</a>',
        },
      },
      {
        id: "Z7VNAVrWoT",
        type: "paragraph",
        data: {
          text: "27.Materials for Energy Conversion Devices 9781855739321&nbsp;",
        },
      },
      {
        id: "tM_QH1u3n-",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9781855739321" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9781855739321</a>',
        },
      },
      {
        id: "2Li_wyQc3A",
        type: "paragraph",
        data: {
          text: "28.Medical Image Recognition&nbsp; Segmentation and Parsing 9780128025819&nbsp;",
        },
      },
      {
        id: "VjEqnmZ0pZ",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128025819" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128025819</a>',
        },
      },
      {
        id: "PlkZNTrWJs",
        type: "paragraph",
        data: {
          text: "29.Microgrid Technology and Engineering Application 9780128035986&nbsp;",
        },
      },
      {
        id: "oURhLlVJnP",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128035986" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128035986</a>',
        },
      },
      {
        id: "FQRtNTsMmo",
        type: "paragraph",
        data: {
          text: "30.Modeling and Analysis of Doubly Fed Induction Generator Wind Energy Systems 9780128029695&nbsp;",
        },
      },
      {
        id: "pDkRNWRS2F",
        type: "paragraph",
        data: {
          text: '<a href="http://www.sciencedirect.com/science/book/9780128029695" rel="nofollow" target="_blank">http://www.sciencedirect.com/science/book/9780128029695</a>',
        },
      },
    ],
    language: "En",
    _id: "618eb6bba0407b2d686e1e7d",
    time: 1636743074764,
    version: "2.22.2",
    title: "E-Resources",
    url: "/facilities/library/Eresources",
    path: "/facilities/library",
    createdAt: "2021-11-12T18:47:23.184Z",
    updatedAt: "2021-11-12T18:51:16.188Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "rnuzq_qTF4",
        type: "header",
        data: { text: "Library Policies", level: 3 },
      },
      {
        id: "vN_ln0dLuk",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2021/04/08/1-books-and-journals-acquisition-policy_nitw.pdf">1.&nbsp;&nbsp;&nbsp;&nbsp;Books and Journals Acquisition Policy</a>&nbsp;',
        },
      },
      {
        id: "3z8v-opMht",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2021/04/08/2-institutional-repository-guidelines_nitw.pdf">2.&nbsp;&nbsp;&nbsp;&nbsp;NITW Institutional Repository Policy Guidelines</a>',
        },
      },
    ],
    language: "En",
    _id: "618f3fa0d0de4b2d69ccbfc5",
    time: 1636778054807,
    version: "2.22.2",
    title: "Library Policies",
    url: "/facilities/library/libraryPolicies",
    path: "/facilities/library",
    createdAt: "2021-11-13T04:31:28.759Z",
    updatedAt: "2021-11-13T04:34:16.485Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Board of Governors", level: 2 },
      },
      {
        id: "pErmFPQ5G6",
        type: "table",
        data: { withHeadings: false, content: [["rjkfdghv", "klgeds", ""]] },
      },
    ],
    language: "En",
    _id: "6191e5dc4635d80023315d94",
    time: 1630911530492,
    version: "2.22.2",
    url: "/test/lol",
    path: "/test",
    title: "test about page",
    createdAt: "2021-11-15T04:45:16.466Z",
    updatedAt: "2021-11-15T04:45:16.466Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "vIzaAMneVe", type: "header", data: { text: "B. Tech", level: 3 } },
      {
        id: "n2KjhyeuS9",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/department/cse/b-tech/syllabus/">Syllabus</a>',
        },
      },
      { id: "OBRK2DJTvQ", type: "header", data: { text: "M. Tech", level: 3 } },
      {
        id: "cECMzjkUco",
        type: "paragraph",
        data: {
          text: "M. Tech (Computer Science and Engineering) program was started in 1987 with an intake of 18. M.Tech (Information Security) was introduced in the year 2008 with the intake of 20.",
        },
      },
      {
        id: "N-CpIfiVTB",
        type: "header",
        data: { text: "Computer Science and Engineering (M.Tech)", level: 5 },
      },
      {
        id: "jIJJES_yO2",
        type: "paragraph",
        data: { text: "Master of Technology (M.Tech)-CSE Program:" },
      },
      {
        id: "GX20F6eiMP",
        type: "paragraph",
        data: { text: "Duration of the Program: 2 Years" },
      },
      {
        id: "tIX8xe1nC4",
        type: "paragraph",
        data: { text: "Course Requirement:" },
      },
      {
        id: "X9notHAkTL",
        type: "paragraph",
        data: {
          text: "Program Structure: The M.Tech program in Computer Science and Engineering consists of the following Semesters:",
        },
      },
      { id: "xKyfGsLXI1", type: "paragraph", data: { text: "Semester 1:" } },
      { id: "4fPF7dZ0A0", type: "paragraph", data: { text: "Semester 2:" } },
      {
        id: "tz9YWkiVJ4",
        type: "paragraph",
        data: { text: "Semester 3:Project work -Part-A" },
      },
      {
        id: "4KE9u1R7t2",
        type: "paragraph",
        data: { text: "Semester 4: Project work -Part-B" },
      },
      {
        id: "g80y2KoVMo",
        type: "paragraph",
        data: { text: "Financial Assistance:" },
      },
      {
        id: "AsrwhE_1NL",
        type: "header",
        data: {
          text: "M.Tech Computer Science and Information Security (M.Tech)",
          level: 5,
        },
      },
      {
        id: "Rj6qUDXNM9",
        type: "paragraph",
        data: {
          text: "Master of Technology (M.Tech)-Computer Science and Information Security Program:",
        },
      },
      {
        id: "HTFGsMakYD",
        type: "paragraph",
        data: { text: "Duration of the Program: 2 Years" },
      },
      {
        id: "-eWSr1Ifmk",
        type: "paragraph",
        data: { text: "Course Requirement:" },
      },
      {
        id: "v-8YrN9mnk",
        type: "paragraph",
        data: {
          text: "Program Structure: The M.Tech program in Computer Science and Information",
        },
      },
      {
        id: "AUecinIJcE",
        type: "paragraph",
        data: { text: "Security consists of the following Semesters:" },
      },
      { id: "KAWazmeRjm", type: "paragraph", data: { text: "Semester 1:" } },
      { id: "TiCLtR9XmN", type: "paragraph", data: { text: "Semester 2:" } },
      {
        id: "bq_AjY2enG",
        type: "paragraph",
        data: { text: "Semester 3:Project work -Part-A" },
      },
      {
        id: "EgrxEXh8rh",
        type: "paragraph",
        data: { text: "Semester 4: Project work -Part-B" },
      },
      {
        id: "ljVd1bHc4z",
        type: "paragraph",
        data: { text: "Financial Assistance:" },
      },
      {
        id: "XHgs-6XyDm",
        type: "header",
        data: { text: "M.Tech Syllabus", level: 5 },
      },
      {
        id: "xq9frAF9WO",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/department/cse/mtech-cse-scheme-and-syllabus/">Click here</a>',
        },
      },
      { id: "5AAb9gL6VT", type: "header", data: { text: "MCA", level: 3 } },
      {
        id: "OxQcwcqThg",
        type: "paragraph",
        data: { text: "Master of Computer Applications(MCA) Program:" },
      },
      {
        id: "cWYs7WBDJT",
        type: "paragraph",
        data: { text: "Duration of the Program: 3 years" },
      },
      {
        id: "xXfLL9CVDe",
        type: "paragraph",
        data: { text: "Course Requirement:" },
      },
      {
        id: "l9KNuam3y4",
        type: "paragraph",
        data: {
          text: "Program Structure: The MCA program in consists of the following semesters:",
        },
      },
      { id: "zuMLvxFaJf", type: "paragraph", data: { text: "Semester 1:" } },
      { id: "WAjeojpHXN", type: "paragraph", data: { text: "Semester 2:" } },
      { id: "F0MKIq23jC", type: "paragraph", data: { text: "Semester 3:" } },
      { id: "nhB8OmSxFR", type: "paragraph", data: { text: "Semester 4:" } },
      { id: "XzX6ANqqfh", type: "paragraph", data: { text: "Semester 5:" } },
      {
        id: "OWtFQ_ImhP",
        type: "paragraph",
        data: { text: "Semester 6: Project Work" },
      },
      {
        id: "zsVVajwdSj",
        type: "paragraph",
        data: {
          text: "Financial Assistance: No Financial Assistance for this program.",
        },
      },
      {
        id: "AV1gFSlxuC",
        type: "header",
        data: { text: "Rules and Regulations of Ph.D. Program", level: 3 },
      },
      {
        id: "ti_gdnn4m_",
        type: "paragraph",
        data: { text: "&lt;PDF TO BE UPLOADED&gt;" },
      },
    ],
    language: "En",
    _id: "6193a8e8bb9845518dd0683f",
    time: 1637066982713,
    version: "2.22.2",
    title: "Academics",
    url: "/cs/academics",
    path: "/cs",
    createdAt: "2021-11-16T12:49:44.090Z",
    updatedAt: "2021-11-16T12:49:44.090Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "PINOcCRtpC",
        type: "header",
        data: { text: "Computing Laboratory", level: 3 },
      },
      {
        id: "fh1KYWL_L7",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. NO</b>", "<b>Hardware</b>", "<b>No. of System</b>"],
            [
              "1",
              "<editorjs-style>&nbsp; HP EliteDesk&nbsp; 800 g1 Small Form Factor Systemsi7-4770 CPU @3.40GHz, RAM 4GB, HDD 500GB&nbsp;&nbsp;</editorjs-style>",
              "40",
            ],
            [
              "2",
              "&nbsp; HP Proliant 8300 Small Form Factor systems&nbsp;i7- 3770 CPU @ 3.40GHz, RAM 8GB, HDD 500GB&nbsp;&nbsp;",
              "5",
            ],
          ],
        },
      },
      {
        id: "R0N2KvDHKX",
        type: "header",
        data: { text: "Software", level: 5 },
      },
      {
        id: "us1udRevyl",
        type: "paragraph",
        data: {
          text: "Dual boot with Windows 7 and Ubuntu 14.04<br>NetBeans 7<br>MSDN<br>Dev C++",
        },
      },
      {
        id: "fcWI4KgADu",
        type: "header",
        data: { text: "Data Engineering Laboratory", level: 3 },
      },
      {
        id: "d20viSLbz9",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. NO</b>", "<b>Hardware</b>", "<b>No. of System</b>"],
            [
              "1",
              "\n\nHP EliteDesk&nbsp; 800 g1 SFF Systemsi7-4770 &nbsp;CPU @3.40GHz, 4GB RAM, 500GB HDD\n\n",
              "50",
            ],
          ],
        },
      },
      {
        id: "En5PMWoMie",
        type: "header",
        data: { text: "Software", level: 5 },
      },
      {
        id: "pq2B_IR5MG",
        type: "paragraph",
        data: {
          text: "Cognos BIClementine Version 11.0<br>Oracle 11g (Unlimited user Licenses)<br>MSDN Academic Alliance Software<br>Informatica (25 user licenses )&nbsp;&nbsp;",
        },
      },
      {
        id: "iVwLPUqzAD",
        type: "header",
        data: { text: "Open Source Development Laboratory", level: 3 },
      },
      {
        id: "TLd-WTfpqx",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. NO</b>", "<b>Hardware</b>", "<b>No. of System</b>"],
            [
              "1",
              "\n\n\n\nHP Proliant 8300 Small Form Factor systems&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i7- 3770 CPU @ 3.40GHz, RAM 8GB, HDD 500GB\n\n\n\n",
              "45",
            ],
          ],
        },
      },
      {
        id: "I0FMZikRbn",
        type: "header",
        data: { text: "Software", level: 5 },
      },
      {
        id: "nZEApg4Qht",
        type: "paragraph",
        data: { text: "Dual boot with Windows XP and Ubuntu 12.0" },
      },
      {
        id: "ZL63eJvgjj",
        type: "paragraph",
        data: { text: "NetBeans 7<br>Oracle Client 10&nbsp;&nbsp;" },
      },
      { id: "yzh-IBY-Kp", type: "header", data: { text: "Servers", level: 3 } },
      {
        id: "Rot5M1-iBR",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. No</b>", "<b>Servers</b>", "<b>Hardware</b>"],
            [
              "1",
              "Windows File Server",
              "HP Proliant DL360e Gen8 intel® Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD)",
            ],
            [
              "2",
              "Oracle Server",
              "HP Proliant DL160 Gen8 Intel® Xeon® CPU E5 -2630  @2.30GHz with 16GB RAM, 2*1TB",
            ],
            [
              "3",
              "McAFEE Server",
              "HCL Intel® Xeon® CPU 3.40GHz with 4GB RAM, 3*120GB HDD",
            ],
            [
              "4",
              "Proxy Server&nbsp; &nbsp; &nbsp;&nbsp;",
              "Hp Compaq Elite 8300 SFF (i5 -3470) CPU @3.40GHz, RAM 2GB, HDD 500GB",
            ],
            [
              "5",
              "COGNOS  Server",
              "HP Proliant DL360 Intel® Xeon® CPU 3.20GHz, 3GB RAM, 160GB HDD",
            ],
            [
              "6",
              "Rational Rose Server&nbsp;",
              "IBM e-server  Intel® Xeon™ CPU 2.40 GHz, 1GB RAM, 40GB HDD",
            ],
            [
              "7",
              "Linux Server&nbsp;&nbsp;",
              "HP Proliant DL360e Gen8 intel® Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD)",
            ],
            [
              "8",
              "High Performance Computing Server (8 node Cluster)&nbsp;",
              "HP Proliant DL380P Gen82 x Intel® xeon® CPU E5-2640 (2.5 GHz / 6-core/15MB / 95w) Processor64 GB DDR RAM,  HP SA 410i RAID controller with 1 GB FBWCHP SN1000E 16GB Dual Port, FC HBA/1200WIntel® xeon® CPU E5-2640 (2.5 GHz / 6-core/15MB / 95w) ProcessorIntel 7500 chip set  with node controller",
            ],
            [
              "9",
              "Big Data Server",
              "HP Proliant DL980 Gen78 x Intel® xeon® CPU E7-2830 (2.13 GHz / 8-core/24MB / 105w) ProcessorIntel 7500 chip set  with node controller, 512 GB DDR RAMHP SA 410i RAID controller with 1 GB FBWC6x600GB 10K HDD, DVD + RW  12 Nos. of PCI slots4x dual port Giga Bit Ethernet NIC and 2x 10GBPS Ethernet CardHP SN1000E 16GB Dual Port, FC HBA/1200WRack Mountable Hot pluggable Redundant Power Supplies and Redundant fans/ Server Manageability Software<b>OS:</b> CentOS 6.5 Server with Hadoop2.2",
            ],
          ],
        },
      },
      {
        id: "UuRBX6BLW2",
        type: "header",
        data: { text: "Software Engineering Laboratory", level: 3 },
      },
      {
        id: "cnrxpWgfZ1",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. NO</b>", "<b>Hardware</b>", "<b>No. of System</b>"],
            [
              "1",
              "<editorjs-style>&nbsp;Dell Optiplex 760Intel Core2 duo CPU E8400 @3.00GHz, 300GB HDD, 4GB RAM</editorjs-style>",
              "50",
            ],
            [
              "2",
              "&nbsp; Acer Veriton Intel Core i7 with 1TB HDD, 8 GB RAM",
              "40",
            ],
          ],
        },
      },
      {
        id: "OZpqsdbalH",
        type: "header",
        data: { text: "Softwares", level: 5 },
      },
      {
        id: "rSc7uAzxc6",
        type: "paragraph",
        data: {
          text: "Rational Testing Suite<br>MSDN<br>NetBeans 7<br>Microsoft Assembler<br>Dev C++&nbsp;&nbsp;",
        },
      },
      {
        id: "TY0iGjqpZ5",
        type: "header",
        data: { text: "Wireless and Security Laboratory", level: 3 },
      },
      {
        id: "ODHyVXRvBA",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. NO</b>", "<b>Hardware</b>", "<b>No. of System</b>"],
            [
              "1",
              "\n\nHP Proliant 8300 Micro Tower systemsi5 -3470 CPU @3.40GHz, RAM 2GB, 500GB HDD\n\n",
              "30",
            ],
            [
              "2",
              "&nbsp;&nbsp;\n\nDell Optiplex 760Intel Core2 duo CPU E8400 @3.00GHz, 300GB HDD, 4GB RAM\n\n",
              "10",
            ],
            [
              "3",
              "&nbsp; HCL Dual core2Intel® Core™ 2 Duo CPU E4500@2.20 GHz, 1GB RAM,&nbsp; 160GB HDD&nbsp;&nbsp;",
              "4",
            ],
          ],
        },
      },
      {
        id: "SjyTitjkHr",
        type: "header",
        data: { text: "Software", level: 5 },
      },
      {
        id: "eC_EtI8onn",
        type: "paragraph",
        data: { text: "ONE Simulator<br>" },
      },
      {
        id: "zF0hINInIH",
        type: "paragraph",
        data: {
          text: "NS3<br>Rational Rose Tools<br>MSDN<br>Dev C++&nbsp;&nbsp;",
        },
      },
    ],
    language: "En",
    _id: "6193aadebb9845518dd06841",
    time: 1642435709723,
    version: "2.22.2",
    title: "Labs",
    url: "/cs/labs",
    path: "/cs",
    createdAt: "2021-11-16T12:58:06.491Z",
    updatedAt: "2022-01-17T16:08:30.204Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "QjpzCfLT84",
        type: "header",
        data: {
          text: "Computer Science and Engineering Association (CSEA)",
          level: 3,
        },
      },
      {
        id: "JmUQ7GpYNj",
        type: "paragraph",
        data: {
          text: "CSEA has been functioning over the years in the department of Computer Science &amp; Engineering with the objectives of promoting student activities for organizing and participating in co-curricular activities by the students of the Department.",
        },
      },
      {
        id: "yRYTCxQPVo",
        type: "paragraph",
        data: {
          text: "At the start of new academic session, CSEA recruits its new team every year.<br>Interviews are done for Several Roles like General Secretary, Additional Secretary, Joint Secretary and Executive Members.",
        },
      },
      {
        id: "BKSNYKuXzu",
        type: "paragraph",
        data: {
          text: "The events conducted by CSEA promote Organizing capabilities and communication skills of students in addition to developing the technical knowledge.",
        },
      },
      {
        id: "k2ufD8Dcxj",
        type: "paragraph",
        data: {
          text: 'Link to CSEA Official Website : <a href="http://www.cseanitw.in/team/">www.cseanitw.in/team/</a>',
        },
      },
      {
        id: "zekxw7fHnq",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>S. No</b>", "<b>Name</b>", "<b>Designation</b>"],
            ["1", "Dr. P Radha Krishna, HOD", "HOD"],
            ["2", "Prof. S. G. Sanjeevi", "Staff Advisor"],
            ["3", "Dr.P. Venkata  Subba Reddy", "Treasurer"],
          ],
        },
      },
    ],
    language: "En",
    _id: "6193ac0a4859507c820205ed",
    time: 1637067785596,
    version: "2.22.2",
    title: "CSEA",
    url: "/cs/csea",
    path: "/cs",
    createdAt: "2021-11-16T13:03:06.932Z",
    updatedAt: "2021-11-16T13:03:06.932Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "ZzPZS2ut1n", type: "header", data: { text: "Hello", level: 3 } },
      { id: "LbDp33VQa_", type: "paragraph", data: { text: "Text" } },
      {
        id: "567OepKwhm",
        type: "list",
        data: { style: "ordered", items: ["1", "2", "3"] },
      },
      {
        id: "xX9f4oaZZc",
        type: "image",
        data: {
          file: {
            url: `${MainServerUrl}/static/files/library_2021-10-12-12-49-58.jpg`,
          },
          caption: "Library",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
    ],
    language: "En",
    _id: "6195332e24d3ebe3eb81130d",
    time: 1637168449091,
    version: "2.22.2",
    title: "Demo",
    url: "/demo",
    path: "/normal",
    createdAt: "2021-11-17T16:51:58.473Z",
    updatedAt: "2021-11-17T17:00:50.003Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "About PLACEMENTS", level: 5 },
      },
      {
        id: "e8vxD8kFFM",
        type: "paragraph",
        data: {
          text: "Welcome to The Centre for Career Planning and Development (CCPD), NIT Warangal.&nbsp; &nbsp;",
        },
      },
      {
        id: "8meZXOQCkQ",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;National Institute of Technology, Warangal&nbsp;is one of the premier engineering schools of India, maintaining an excellent academic record. The Institute's Centre for Career Planning and Developement (CCPD)&nbsp; provides a launch pad for students to make it to the best organizations in the fields of software, core industries, management, etc. The biggest names in the corporate world visit the campus on a regular basis, with&nbsp;enviable recruitment offers.",
        },
      },
      {
        id: "KrrCysE5v-",
        type: "paragraph",
        data: {
          text: "Our students have lived up to the expectations of organizations that visit the campus, looking for bright candidates to take up important and challenging responsibilities at their workplace. The CCPD boasts of maintaining an&nbsp;excellent rapport with a wide spectrum of companies&nbsp;spread across various industry sectors. Over the years, the section has maintained good and strong relationship with Industries across the country and as a result, has built up an&nbsp;impressive placement record&nbsp;both in terms of percentage of registered students placed, as well as the number of companies visiting the campus.",
        },
      },
      {
        id: "L0o2p8W90b",
        type: "paragraph",
        data: {
          text: "The CCPD assists the departments in placement of students at major industries. About 150 - 180 companies&nbsp;visit the campus every year. We truly appreciate the faith bestowed on us from several organizations and we look forward to continuing our relationship with them.",
        },
      },
      {
        id: "zBRG22ar3X",
        type: "paragraph",
        data: {
          text: "<b>Head, Centre for Career Planning and Development (CCPD)</b>",
        },
      },
      {
        id: "3Lb07EyVCC",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/faculty/id/16419/" target="_blank">Dr. K. Kiran Kumar</a>',
        },
      },
      {
        id: "cOU8fgOv7d",
        type: "paragraph",
        data: { text: "MOBILE:&nbsp;94901 65357" },
      },
      {
        id: "u6jTUIzYtb",
        type: "paragraph",
        data: { text: "Email Id:&nbsp;&nbsp;ccpd_hod@nitw.ac.in" },
      },
    ],
    language: "En",
    _id: "6195347924d3ebe3eb811311",
    time: 1641454440040,
    version: "2.22.2",
    title: "Home",
    url: "/placement/new",
    path: "/placement",
    createdAt: "2021-11-17T16:57:29.885Z",
    updatedAt: "2022-01-06T07:32:09.649Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "nJ89w8jnH9", type: "header", data: { text: "nov 25", level: 2 } },
    ],
    language: "En",
    _id: "619f65aa86bea1f2aff6594f",
    time: 1637836293828,
    version: "2.22.2",
    title: "nov 25",
    url: "nov25",
    path: "portals/nov25",
    createdAt: "2021-11-25T10:30:02.784Z",
    updatedAt: "2021-11-25T10:30:02.784Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "Page1", level: 2 } },
      { id: "HDN5BLThnE", type: "paragraph", data: { text: "content" } },
    ],
    language: "En",
    _id: "61a5be4eca323d49ccd59529",
    time: 1638252110523,
    version: "2.22.2",
    title: "page1",
    url: "/somepath/home/page1",
    path: "/somepath/home",
    createdAt: "2021-11-30T06:01:50.749Z",
    updatedAt: "2021-11-30T06:01:50.749Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Purchase SEC", level: 2 },
      },
      {
        id: "HpFsJabtUP",
        type: "list",
        data: { style: "unordered", items: ["Purchase SEC"] },
      },
    ],
    language: "En",
    _id: "61a5c91e5de3c249aaffee68",
    time: 1638256471952,
    version: "2.22.2",
    title: "Purchase SEC",
    url: "/Portals/PurchaseSEC",
    path: "/Portals",
    createdAt: "2021-11-30T06:47:58.534Z",
    updatedAt: "2021-11-30T07:13:00.578Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Notifications &amp; Office Orders", level: 4 },
      },
    ],
    language: "En",
    _id: "61a5c949bdd72d49c67024f1",
    time: 1638255349872,
    version: "2.22.2",
    title: "Notifications & Office Orders",
    url: "/Portals/Notifications&Office Orders",
    path: "/Portals",
    createdAt: "2021-11-30T06:48:41.228Z",
    updatedAt: "2021-11-30T06:54:18.505Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: {
          text: '<a href="http://nitw.euniwizarde.com/">E-Tender</a>&nbsp;-<a href="http://nitw.euniwizarde.com/"> Click here</a>',
          level: 2,
        },
      },
    ],
    language: "En",
    _id: "61a5c98ecec91349b92a0cb3",
    time: 1641379244321,
    version: "2.22.2",
    title: "E-Tender",
    url: "/Portals/ETender",
    path: "/Portals",
    createdAt: "2021-11-30T06:49:50.705Z",
    updatedAt: "2022-01-05T10:39:00.516Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "CPPP", level: 6 } },
    ],
    language: "En",
    _id: "61a5cb15cec91349b92a0cb6",
    time: 1641379273279,
    version: "2.22.2",
    title: "CPPP",
    url: "/Portals/CPPP",
    path: "/Portals",
    createdAt: "2021-11-30T06:56:21.810Z",
    updatedAt: "2022-01-05T10:39:29.479Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "-KgZg4WVO-",
        type: "header",
        data: { text: "Vision And Mission", level: 3 },
      },
      { id: "NbUN1ASAq7", type: "header", data: { text: "Vision", level: 5 } },
      {
        id: "dxLABy0AQY",
        type: "paragraph",
        data: {
          text: "Create an Educational environment in which students are prepared to meet the challenges of modern&nbsp;Industrial society by equipping them with:",
        },
      },
      {
        id: "3jwip62mXz",
        type: "list",
        data: { style: "unordered", items: ["Upto date Technical Knowledge"] },
      },
      {
        id: "R29bBa1L0c",
        type: "list",
        data: {
          style: "unordered",
          items: ["Analytical and Practical skills"],
        },
      },
      {
        id: "7m0dHT7dkk",
        type: "list",
        data: {
          style: "unordered",
          items: ["A creative and innovative approach to work"],
        },
      },
      { id: "aSx5363R2o", type: "header", data: { text: "Mission", level: 5 } },
      {
        id: "XWhfHZ3tdq",
        type: "paragraph",
        data: {
          text: "Create Research, Development and Testing environment to meet ever changing and ever demanding needs of the Electronic Industry in particular, along with IT &amp; other inter disciplinary fields in general.",
        },
      },
      { id: "CEvVRKNzqm", type: "header", data: { text: "Goals", level: 5 } },
      {
        id: "D3NVEEkCqY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To be one of the leading electronics and Communication engineering department in the India&nbsp;and to be known for research, creation of knowledge base, education, industry interaction and&nbsp;entrepreneurial activity.",
            "To be a global partner in Training the human resource in the fields of Chip Design, Instrumentation&nbsp;and Networking.",
            "To have an association with Internationally reputed Institutions for academic excellence and man&nbsp;power development.",
            "To bid developmental International projects.",
            "To increase the research activity in the state of the art fields.",
          ],
        },
      },
    ],
    language: "En",
    _id: "61a5cbe798d6aa49bab36759",
    time: 1638255590904,
    version: "2.22.2",
    title: "Vision & Mission",
    url: "/ece/vision",
    path: "/ece",
    createdAt: "2021-11-30T06:59:51.302Z",
    updatedAt: "2021-11-30T06:59:51.302Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "U-8zgGyruS",
        type: "header",
        data: { text: "1. GeM Procurement", level: 6 },
      },
    ],
    language: "En",
    _id: "61a5cc4cca323d49ccd5952d",
    time: 1641379288446,
    version: "2.22.2",
    title: "GeM Procurement",
    url: "/Portals/GeMProcurement",
    path: "/Portals",
    createdAt: "2021-11-30T07:01:32.891Z",
    updatedAt: "2022-01-05T10:39:44.641Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Rate Contract", level: 6 },
      },
    ],
    language: "En",
    _id: "61a5cd7138586049c0bbc966",
    time: 1641379304536,
    version: "2.22.2",
    title: "Rate Contract",
    url: "/Portals/Rate Contract.",
    path: "/Portals",
    createdAt: "2021-11-30T07:06:25.871Z",
    updatedAt: "2022-01-05T10:40:00.733Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "L4ziaKZ8L3",
        type: "header",
        data: { text: "Stock Verification &amp; Condemnation", level: 6 },
      },
    ],
    language: "En",
    _id: "61a600c238586049c0bbc968",
    time: 1641379317947,
    version: "2.22.2",
    title: "Stock Verification & Condemnation",
    url: "/Portals/Stock",
    path: "/Portals",
    createdAt: "2021-11-30T10:45:22.012Z",
    updatedAt: "2022-01-05T10:40:14.129Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Status of the Procurement", level: 6 },
      },
    ],
    language: "En",
    _id: "61a600ebbdd72d49c67024f7",
    time: 1641379331058,
    version: "2.22.2",
    title: "Status of the Procurement",
    url: "/Portals/Status",
    path: "/Portals",
    createdAt: "2021-11-30T10:46:03.365Z",
    updatedAt: "2022-01-05T10:40:27.246Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Supply Orders Status", level: 6 },
      },
    ],
    language: "En",
    _id: "61a6010bcec91349b92a0cba",
    time: 1641379349189,
    version: "2.22.2",
    title: "Supply Orders Status",
    url: "/Portals/Supply",
    path: "/Portals",
    createdAt: "2021-11-30T10:46:35.174Z",
    updatedAt: "2022-01-05T10:40:45.379Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Supply Orders Issued", level: 6 },
      },
    ],
    language: "En",
    _id: "61a60143ca323d49ccd59531",
    time: 1641379363160,
    version: "2.22.2",
    title: "Supply Orders Issued",
    url: "/Portals/OrdersIssued",
    path: "/Portals",
    createdAt: "2021-11-30T10:47:31.111Z",
    updatedAt: "2022-01-05T10:40:59.351Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "Contact", level: 6 } },
    ],
    language: "En",
    _id: "61a6016fec49cd1e4ceee7ff",
    time: 1641379373767,
    version: "2.22.2",
    title: "Contact",
    url: "/Portals/Contact",
    path: "/Portals",
    createdAt: "2021-11-30T10:48:15.753Z",
    updatedAt: "2022-01-05T10:41:09.972Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "---", level: 2 } },
    ],
    language: "En",
    _id: "61af01d95de3c249aaffee74",
    time: 1638859315316,
    version: "2.22.2",
    title: "DST-VP-SCOPE",
    url: "/DSTVPSCOPE/2021",
    path: "/DSTVPSCOPE",
    createdAt: "2021-12-07T06:40:25.756Z",
    updatedAt: "2021-12-07T06:40:25.756Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "ynl0f-h-Uu", type: "paragraph", data: { text: "1. OTT" } },
      { id: "JcbXOKOqm-", type: "paragraph", data: { text: "2. VIDEOS" } },
    ],
    language: "En",
    _id: "61af031aca6261499b09698c",
    time: 1638860025936,
    version: "2.22.2",
    title: "OUTREACH",
    url: "/DSTVPSCOPE/outreach",
    path: "/DSTVPSCOPE",
    createdAt: "2021-12-07T06:45:46.444Z",
    updatedAt: "2021-12-07T06:52:16.443Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "BPE8NcnEmV",
        type: "paragraph",
        data: {
          text: '1.&nbsp;<a href="http://daa.nitw.ac.in:8081/page/?url=/Webinars/Webinar-1">Webinar - 1</a>',
        },
      },
      {
        id: "cf9AJ7lCKP",
        type: "paragraph",
        data: {
          text: "Your comments and suggestions about the\nwebinars:\n\n",
        },
      },
      {
        id: "j7NIqWte0g",
        type: "paragraph",
        data: { text: "&nbsp;Email\nid: scopeonline@nitw.ac.in" },
      },
    ],
    language: "En",
    _id: "61af04ef5de3c249aaffee76",
    time: 1642404609796,
    version: "2.22.2",
    title: "WEBINARS",
    url: "/DSTVPSCOPE/WEBINARS",
    path: "/DSTVPSCOPE",
    createdAt: "2021-12-07T06:53:35.250Z",
    updatedAt: "2022-01-17T07:36:11.240Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "sO7yR8pQty",
        type: "paragraph",
        data: {
          text: '1. <a href="http://daa.nitw.ac.in:8081/path/?dept=/VIJNANAVANI">Issues &nbsp;</a>',
        },
      },
      {
        id: "F9xRy1g9_B",
        type: "paragraph",
        data: {
          text: "2. AUTHORS can send Articles and readers can give\nsuggestions and comments:\n\n<b>&nbsp;vijnanavani@nitw.ac.in</b>",
        },
      },
    ],
    language: "En",
    _id: "61af059acec91349b92a0cc7",
    time: 1640759789766,
    version: "2.22.2",
    title: "VIJNANA VANI",
    url: "/DSTVPSCOPE/VIJNANAVANI",
    path: "/DSTVPSCOPE",
    createdAt: "2021-12-07T06:56:26.548Z",
    updatedAt: "2021-12-29T06:36:30.006Z",
    __v: 0,
  },
  {
    blocks: [{ id: "TjDFXSpchH", type: "paragraph", data: { text: "-" } }],
    language: "En",
    _id: "61af0733cec91349b92a0cca",
    time: 1638860685046,
    version: "2.22.2",
    title: "PUBLICATIONS",
    url: "/DSTVPSCOPE/PUBLICATIONS",
    path: "/DSTVPSCOPE",
    createdAt: "2021-12-07T07:03:15.474Z",
    updatedAt: "2021-12-07T07:03:15.474Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "NZp5JfJyMS",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "<b>Organisational</b>&nbsp;",
            "<b>Contact us :</b> 0870 - 2462687,0870 - 2462688,9573048943,8332969704,9490300457.&nbsp;",
            "<b>Email id:</b>&nbsp;scope@nitw.ac.in, scopeoffice@nitw.ac.in",
          ],
        },
      },
    ],
    language: "En",
    _id: "61af088398d6aa49bab36768",
    time: 1638861021351,
    version: "2.22.2",
    title: "ABOUT US",
    url: "/DSTVPSCOPE/ABOUTUS",
    path: "/DSTVPSCOPE",
    createdAt: "2021-12-07T07:08:51.785Z",
    updatedAt: "2021-12-07T07:08:51.785Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "UqDaG1IEQd",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/05/invitation-inaugaration_induction.pdf">Invitation Inaugaration induction</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/11/26/one-week-induction-program-for-first-year-btech-students-2020-from-2nd-7th-dec-2020-1_tosixjo-converted_9rbJVZg.pdf">One Week Induction Program for First Year B. Tech Students 6th -11th December 2021</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/05/time-table-of-induction-pogram.pdf">Time table for Induction program&nbsp;&nbsp;6th - 11th December, 2021</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/Gurudev/SriSriRaviShankarJi%C2%A0/">Special Session&nbsp;with Gurudev Sri Sri Ravi Shankar Ji on&nbsp;13th December, 2021 at 5:00 PM</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/11/26/creative-arts-information-and-required-materials-induction-prog-2021.pdf">Creative Arts information and Required Materials</a>&nbsp;&nbsp;',
            '&nbsp;<a href="https://docs.google.com/forms/d/e/1FAIpQLSdZlPcwcBApvlsljgHVHhJZK-CxIEMjgJnfMmr7FIJZjmX3zw/viewform">Creative Arts-Option Form (2021) link for students to fill</a>,<editorjs-style>&nbsp;&nbsp;Last date to fill the form is : 5th December 2021, 10:00 PM.&nbsp;</editorjs-style>',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/06/time-table-of-induction-pogram_2021-22.pdf">CISCO WEBEX links Induction program&nbsp;&nbsp;6th - 11th December, 2021.&nbsp;Day 6 links :</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/06/department-hods_jSXqXyb.pdf">Departments Presentation (parallel sessions) on 6th December, 2021&nbsp;links&nbsp;&nbsp;3.45 PM To 5.00 PM</a>&nbsp;<br><br>',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/zoom_0.mp4">CISCO WEBEX installation demo</a><br>',
          ],
        },
      },
      {
        id: "yrGQq2pNWy",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin-left:10vw;\ncolor:#000">6:00 AM - 7:00 AM - Physical Activity</editorjs-style>',
        },
      },
      {
        id: "YsNvB_VG9D",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin-left:10vw;\ncolor:#000">9:30 AM - 11:30 AM - Universal Human Values</editorjs-style>',
        },
      },
      {
        id: "020DbHvG5V",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin-left:10vw;\ncolor:#000">3:15 PM - 4:45 PM - Creative Arts</editorjs-style>',
        },
      },
      {
        id: "xJVMWH5S0g",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Creative arts students groups join the link based on the group&nbsp;",
          ],
        },
      },
      {
        id: "jPY9xuBPHs",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; i.&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/12/07/creative-arts-arts-and-crafts.pdf">Arts-Crafts</a>',
        },
      },
      {
        id: "tSG0hyucSp",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;ii.&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/12/07/creative-arts-western-dance.pdf">Dance</a>',
        },
      },
      {
        id: "LjhDQvNoZj",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; iii.&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/12/07/creative-arts-karate.pdf">Karate</a>',
        },
      },
      {
        id: "6pMy1A1Ncw",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; iv.&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/12/07/creative-arts-photography.pdf">Photography</a>&nbsp;<br><br>',
        },
      },
      {
        id: "81NYerMvJP",
        type: "list",
        data: {
          style: "unordered",
          items: [
            'Presentation Slides Downloads&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <br><br>&nbsp; &nbsp; i.&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/12/09/ug-orientation-academic-rules-and-regulations-2021-22_pojbig4.pdf">Academic Rules and Regulations&nbsp;</a>&nbsp;<br><br>&nbsp; &nbsp;ii.&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/12/09/nitw-induction-ppt-09-12-2021.pptx">English Session Presentation Slides</a>&nbsp;<br><br>&nbsp; iii.&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/12/10/orietation_btech1st-year_lms.pdf">LMS Presentation Slides&nbsp;</a>&nbsp; &nbsp; &nbsp;<br>&nbsp; &nbsp; &nbsp;<br>&nbsp; &nbsp;iv.&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/12/10/books-suggested-by-profpurushottam-reddy.pdf">Books suggested by&nbsp; Prof.Purushottam Reddy&nbsp;</a>&nbsp;<br><br>',
          ],
        },
      },
      {
        id: "7XLH5dXkNU",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/10/invitation-valedictory_induction-3.pdf">Invitation Valedictory Induction program</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/Inductionprogram/2020/">Glimpse of Induction program 2020</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/11/28/glimpses-of-induction-program-2019.pdf">Glimpses of Induction program 2019</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/11/28/glimpse-of-induction-program-2018.pdf">Glimpse of Induction program 2018</a>&nbsp;',
          ],
        },
      },
      {
        id: "Y5w2vJv4sI",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.facebook.com/groups/3959943184035199/">Induction program Facebook Page</a>&nbsp;',
            '<editorjs-style style="background:#faed27">Helpline number :&nbsp;9110739723 ( Satvik&nbsp;)</editorjs-style>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61af2c2cca6261499b096992",
    time: 1639301828399,
    version: "2.22.2",
    title: "One Week Induction program",
    url: "/Inductionprogram/2021",
    path: "/Inductionprogram",
    createdAt: "2021-12-07T09:41:00.260Z",
    updatedAt: "2021-12-12T09:37:09.000Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "pPymZVPxaz",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/_ideations-on-climate-change.png">Ideations on Climate Change</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/cultural-heritage.png">Cultural heritage&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/startup-ideation.png">Startup Ideation</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/brochure.pdf">Brochure</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61b07b7fca323d49ccd59548",
    time: 1640262391087,
    version: "2.22.2",
    title:
      "Brain-Storming Conclave on Atma Nirbhar North East through S&T Interventions",
    url: "/BrainStorming/som",
    path: "/BrainStorming",
    createdAt: "2021-12-08T09:31:43.140Z",
    updatedAt: "2021-12-23T12:26:31.459Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "hHuPYlpdWq",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/_ideations-on-climate-change.png">Ideations on Climate Change</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/cultural-heritage.png">Cultural heritage&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/startup-ideation.png">Startup Ideation</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/brochure.pdf">Brochure</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61b07ff4cec91349b92a0ccf",
    time: 1638957044084,
    version: "2.22.2",
    title: "brainstrom",
    url: "/som/brainstrom",
    path: "/som",
    createdAt: "2021-12-08T09:50:44.203Z",
    updatedAt: "2021-12-08T09:50:44.203Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "a78PGFSoVO",
        type: "header",
        data: {
          text: '"Brain-Storming Conclave on Atma Nirbhar North East through S&amp;T Interventions" (December 21 - 22 , 2021) at Cotton University, Guwahati, Assam',
          level: 5,
        },
      },
      {
        id: "E46rg7kiHO",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/_ideations-on-climate-change.png">Ideations on Climate Change</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/cultural-heritage.png">Cultural heritage&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/startup-ideation.png">Startup Ideation</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/brochure.pdf">Brochure</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61b0832c98d6aa49bab36772",
    time: 1638958677130,
    version: "2.22.2",
    title: "som",
    url: "/portals/som",
    path: "/portals",
    createdAt: "2021-12-08T10:04:28.148Z",
    updatedAt: "2021-12-08T10:17:57.300Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "L2tSb-qrqk",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/_ideations-on-climate-change.png">Ideations on Climate Change</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/cultural-heritage.png">Cultural heritage&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/startup-ideation.png">Startup Ideation</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/08/brochure.pdf">Brochure</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61b085a1ec49cd1e4ceee812",
    time: 1638958497663,
    version: "2.22.2",
    title: "som",
    url: "/portals/som",
    path: "/portals",
    createdAt: "2021-12-08T10:14:57.771Z",
    updatedAt: "2021-12-08T10:14:57.771Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "8Wg4oKfP2G",
        type: "header",
        data: { text: "Fee Structures", level: 2 },
      },
      {
        id: "as7ZHZPSY2",
        type: "header",
        data: { text: "For under&nbsp;graduate students", level: 4 },
      },
      {
        id: "hQQfGlERDo",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/23/btech-ii-sem-fee-structure-2021-22.pdf">B.Tech II-Sem fee structure - 2021-22</a>&nbsp;',
          ],
        },
      },
      {
        id: "DCDzTGEq6I",
        type: "header",
        data: { text: "For Post-graduate and Ph.D. students", level: 4 },
      },
      {
        id: "wtDBGgQQ1a",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/23/pg-phd-ii-sem-fee-structure-2021-22.pdf">PG &amp; Ph.D II-Sem fee structure - 2021-22</a>&nbsp;',
          ],
        },
      },
      {
        id: "hTo5ml1zjL",
        type: "header",
        data: { text: "Important Instructions", level: 4 },
      },
      {
        id: "mh16e9TYTR",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/23/ii-sem-online-fee-instructions.pdf">II-Sem&nbsp;online Fee instructions</a><br>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c54fd61725251ae9cf76ef",
    time: 1641454061801,
    version: "2.22.2",
    title: "UG, PG and PhD fee structure",
    url: "/feestructure/2021",
    path: "/feestructure",
    createdAt: "2021-12-24T04:43:02.169Z",
    updatedAt: "2022-01-06T07:25:51.414Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "OXMZRpLBPI",
        type: "paragraph",
        data: {
          text: '<br>PhD Full time &amp; Part Time course<br><a href="https://www.payumoney.com/webfronts/#/index/PhD-Fulltime-Parttime-December2021Session_FEE-INR39500" target="_blank">https://www.payumoney.com/webfronts/#/index/PhD-Fulltime-Parttime-December2021Session_FEE-INR39500</a><br><br>',
        },
      },
      {
        id: "qqrOO9LYLK",
        type: "paragraph",
        data: {
          text: 'PhD Ful ltime Self-finance course<br><a href="https://www.payumoney.com/webfronts/#/index/PhD-Fulltime-Selffinance-December2021Session-INR92000" target="_blank">https://www.payumoney.com/webfronts/#/index/PhD-Fulltime-Selffinance-December2021Session-INR92000</a>',
        },
      },
    ],
    language: "En",
    _id: "61c5512e1725251ae9cf76fc",
    time: 1640321716631,
    version: "2.22.2",
    title: "Payment Links For PhD Admissions December-2021 Session",
    url: "/payment/2021",
    path: "/payment",
    createdAt: "2021-12-24T04:48:46.419Z",
    updatedAt: "2021-12-24T04:52:15.111Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "fCoLR77bsM",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/08/31/digilocker-partner-logo.png",
          },
          caption: "Click the Logo to Register in DigiLocker",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "2XCBN7lEpj",
        type: "header",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Committee for NAD&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;",
          level: 2,
        },
      },
      {
        id: "BeuoabX3wy",
        type: "paragraph",
        data: {
          text: 'As per Ministry of Education&nbsp;and UGC guidelines&nbsp; all students have to register for NAD (<editorjs-style style="color:#DC143C\n\n">National Ac<editorjs-style>ademic Depo</editorjs-style>sitory</editorjs-style>).',
        },
      },
      {
        id: "4dB7oLrBEE",
        type: "paragraph",
        data: {
          text: "All students of UG and PG courses of second and higher semesters are required to register their names in NAD(with their Roll numbers if it is without Aadhar)",
        },
      },
      {
        id: "N5-pHS9cVk",
        type: "paragraph",
        data: {
          text: "Digi Locker is looking after NAD.&nbsp;&nbsp;The following is the committee for NAD cell at NITW.&nbsp;",
        },
      },
      {
        id: "VAxBo350vN",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;1.&nbsp; Chairman (Dean Academic)&nbsp; &nbsp;:&nbsp; &nbsp;<a href="https://www.nitw.ac.in/faculty/id/16258/">Prof A. Venugopal</a>',
        },
      },
      {
        id: "XgULFl9gt7",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.&nbsp; Member (Professor in charge - Examinations ) :&nbsp; &nbsp;<a href="https://nitw.ac.in/faculty/id/16393/">Prof D. Srinivasacharya</a>',
        },
      },
      {
        id: "8cf3tKwOOo",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.&nbsp;&nbsp;Member (Nodal Officer for NAD &amp; AISHE)&nbsp; &nbsp;:&nbsp; &nbsp;<a href="https://www.nitw.ac.in/faculty/id/16398/">Dr. D. Bhargavi</a>',
        },
      },
      {
        id: "UtsdLB_jVk",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.&nbsp; Head, Centre for Information and Software Systems/MIS&nbsp; :&nbsp; <a href="https://www.nitw.ac.in/faculty/id/16336/">Dr. Rashmi Ranjan Rout</a>',
        },
      },
    ],
    language: "En",
    _id: "61c556561725251ae9cf7719",
    time: 1641293947479,
    version: "2.22.2",
    title: "NAD",
    url: "/NAD/2021",
    path: "/NAD",
    createdAt: "2021-12-24T05:10:46.220Z",
    updatedAt: "2022-01-04T10:57:53.048Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "7i5nnLuBLC",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/07/23/kribhco-announcement.pdf">23 Jul 2018 :&nbsp; &nbsp;Graduate Engineer Trainee (GET) - &nbsp;KRIBHCO</a>',
        },
      },
      {
        id: "aQcxMu6YeS",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/25/change-of-registration-dates-for-the-ay-2018.pdf">25 Jun 2018 :&nbsp; Change of Registration Dates for the AY 2018-19</a>',
        },
      },
    ],
    language: "En",
    _id: "61c55a9e1725251ae9cf7728",
    time: 1640323923797,
    version: "2.22.2",
    title: "ANNOUNCEMENTS",
    url: "/placement/ANNOUNCEMENTS",
    path: "/placement",
    createdAt: "2021-12-24T05:29:02.224Z",
    updatedAt: "2021-12-24T05:29:02.224Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "_faGLvbOjd",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/faculty/id/16419/">Dr. K. Kiran Kumar</a>',
        },
      },
      { id: "6Sv4Tk454P", type: "paragraph", data: { text: "Head" } },
      {
        id: "QuBQv2fOa9",
        type: "paragraph",
        data: { text: "Centre for Career Planning and Development (CCPD)" },
      },
      {
        id: "CEc_Q9M4eI",
        type: "paragraph",
        data: { text: "National Institute of Technology Warangal" },
      },
      {
        id: "rtUBlRkENX",
        type: "paragraph",
        data: { text: "Telangana - 506 004" },
      },
      {
        id: "x_QHrzuyyK",
        type: "paragraph",
        data: { text: "Mobile:&nbsp;+91 9490165357" },
      },
      {
        id: "rfQEPXf9Yl",
        type: "paragraph",
        data: {
          text: 'Email Id:&nbsp;<a href="mailto:taps@nitw.ac.in" target="_blank">taps@nitw.ac.in</a>&nbsp;;&nbsp;<a href="mailto:ccpd_hod@nitw.ac.in" target="_blank">ccpd_hod@nitw.ac.in</a>',
        },
      },
      {
        id: "cW4W1mm-cl",
        type: "paragraph",
        data: {
          text: "Faculty Coordinators, Centre for Career Planning and Development (CCPD) &nbsp;:&nbsp;",
        },
      },
      {
        id: "MnuIl3fCeO",
        type: "paragraph",
        data: {
          text: '1.<a href="https://www.nitw.ac.in/faculty/id/16940/">&nbsp;</a><a href="https://www.nitw.ac.in/faculty/id/16940/">Dr. D. Haranath</a>&nbsp; &nbsp;&nbsp;<br>Associate Professor&nbsp; &nbsp;&nbsp;&nbsp;<br>Department of Physics',
        },
      },
      {
        id: "5W8vcFpyNw",
        type: "paragraph",
        data: {
          text: '2.&nbsp;<a href="https://nitw.ac.in/faculty/id/16920/">Dr. B. Spoorthi</a>&nbsp; &nbsp; &nbsp;<br>Assistant Professor&nbsp; &nbsp;&nbsp;&nbsp; <br>Department of Humanities &amp; Social Science',
        },
      },
      {
        id: "UDFEAcNeEW",
        type: "paragraph",
        data: {
          text: '3.&nbsp;<a href="https://nitw.ac.in/faculty/id/16879/">Dr. E Suresh Babu</a>&nbsp; &nbsp;<br>&nbsp;Assistant Professor&nbsp; &nbsp; &nbsp;<br>Department of Computer Science And Engineering',
        },
      },
      {
        id: "5GxNCHaUzk",
        type: "paragraph",
        data: {
          text: '4.&nbsp;<a href="https://nitw.ac.in/faculty/id/17022/">Dr. Ch. Sampath Kumar</a>&nbsp;&nbsp;&nbsp;<br>&nbsp;Assistant Professor&nbsp; &nbsp; <br>&nbsp;Department of Mechanical Engineering',
        },
      },
      {
        id: "RJS0vwrUTl",
        type: "paragraph",
        data: {
          text: "Centre for Career Planning and Development (CCPD) &nbsp;Office:&nbsp;",
        },
      },
      {
        id: "OJYbimz05c",
        type: "paragraph",
        data: { text: "Phone:&nbsp;0870-2459325" },
      },
      {
        id: "XzZqMza7IZ",
        type: "paragraph",
        data: { text: "Direct:&nbsp;0870-2462930" },
      },
      {
        id: "Jux_WxrHzV",
        type: "paragraph",
        data: { text: "Fax:&nbsp;0870-2459547" },
      },
      {
        id: "ff9i2gDdpc",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/main/academics/placements/">Click&nbsp;</a><a href="https://www.nitw.ac.in/main/academics/placements/" target="_blank">Here</a><a href="https://www.nitw.ac.in/main/academics/placements/">&nbsp;to visit the Centre for Career Planning and Development (CCPD) Website</a>',
        },
      },
    ],
    language: "En",
    _id: "61c55ad91725251ae9cf772a",
    time: 1640324030109,
    version: "2.22.2",
    title: "CONTACT",
    url: "/placement/CONTACT",
    path: "/placement",
    createdAt: "2021-12-24T05:30:01.757Z",
    updatedAt: "2021-12-24T05:30:48.591Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "WphD2H0Wvl",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/EducationalTourForm-23022020.pdf">26 Feb 2020 : &nbsp; &nbsp; &nbsp;Short Educational Tour Proposal Form - 2019-20</a>',
        },
      },
      {
        id: "_QRNQIuaWr",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GuestHouseRequestForm-23022020.pdf">26 Feb 2020 : &nbsp; &nbsp; &nbsp;Proforma for Guest House Booking Booking - 2019-20</a>',
        },
      },
      {
        id: "zf1NMgmjv6",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ProformaForFeedBack-21022020.pdf">26 Feb 2020 : &nbsp; &nbsp; &nbsp;Proforma for Feedback by the Company - 2019-20</a>',
        },
      },
      {
        id: "lz_LF45dFn",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/IndentFormForVehicleBooking-24022020.pdf">26 Feb 2020 : &nbsp; &nbsp; &nbsp;Indent Form for Vehicle Booking - 2019-20</a>',
        },
      },
      {
        id: "-OOpguoPaP",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ProformaForSelectedStudentsList-21022020.pdf">26 Feb 2020 : &nbsp; &nbsp; &nbsp;Proforma for Announcing Selected Students List - 2019-20</a>',
        },
      },
      {
        id: "2mHSjXJRxi",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SeminarHallRequistionForm-23022020.pdf">26 Feb 2020 : &nbsp; &nbsp; &nbsp;Indent Form Seminar Hall Booking - 2019-20</a>',
        },
      },
      {
        id: "oba8ByaOTI",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/OnLineTestRequestForm-24022020.pdf">26 Feb 2020 :&nbsp; &nbsp; &nbsp; OnLine Test Requisition Form for Campus Placements - 2019-20</a>',
        },
      },
      {
        id: "VlruTZ3mbL",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/PhD-Regn-Form-2019-20-03112019%20replaced.pdf">1 Nov 2019 :&nbsp; &nbsp; &nbsp; &nbsp;Ph.D. Scholars Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "4Zo56f4K-o",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MSc(Tech)-Regn-Form-2019-20.pdf">1 Nov 2019 &nbsp;:&nbsp; &nbsp; &nbsp; M.Sc.(Tech.) Students Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "KEQyCkblHe",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MSc%20Regn-Form-2019-20.pdf">1 Nov 2019 &nbsp;:&nbsp; &nbsp; &nbsp; M.Sc. Students Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "5VCVLwPbrQ",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MCA-Regn-Form-2019-20.pdf">1 Nov 2019 &nbsp;: &nbsp; &nbsp; &nbsp;M.C.A. Students Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "IsuMa_JfLG",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MBA-Regn-Form-2019-20.pdf">1 Nov 2019 &nbsp;:&nbsp; &nbsp; &nbsp; M.B.A Students Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "JzhvRdk-bS",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MTech-Regn-Form-2019-20.pdf">1 Nov 2019 &nbsp;:&nbsp; &nbsp; &nbsp; M.Tech. Students Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "u-mXT8UJEq",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MTech-Regn-Form-DASA-2019-20.pdf">1 Nov 2019:&nbsp; &nbsp; &nbsp; &nbsp; M.Tech. Students&nbsp;(DASA/ICCR/Others) Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "CfJWgYAKRD",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/BTech-Regn-Form-2019-20.pdf">1 Nov 2019&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;B.Tech. Students Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "4-0ap47O5u",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/BTech-Regn-Form-DASA-2019-20.pdf">1 Nov 2019 :&nbsp; &nbsp; &nbsp; &nbsp;B.Tech. Students (DASA/ICCR/Others) Registration Form for Campus Placement - 2019-20</a>',
        },
      },
      {
        id: "ZKPIC7wSsk",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/OnLineTestRequestForm20082019.pdf">20 Aug 2019 : &nbsp; &nbsp;Online Test Requisition Form for Campus Placements</a>',
        },
      },
      {
        id: "2BScOOKF8C",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SeminarHallRequistionForm-23072019.pdf">23 Jul 2019 :&nbsp; &nbsp; &nbsp; Indent Form for Seminar Hall Booking - AY 2019-20</a>',
        },
      },
      {
        id: "oLqD_9QOr7",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ProformaForSelectedStudentsList-23072019.pdf">23 Jul 2019 :&nbsp; &nbsp; &nbsp; Proforma for Announcing Selected Students List - AY 2019-20</a>',
        },
      },
      {
        id: "4g9kLcXc96",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/IndentFormForVehicleBooking-23072019.pdf">23 Jul 2019 :&nbsp; &nbsp; &nbsp; Indent Form for Vehicle Booking - AY 2019-20</a>',
        },
      },
      {
        id: "q-bEPAi19y",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/OnLineTestRequestForm-23072019.pdf">23 Jul 2019 :&nbsp; &nbsp; &nbsp; Proforma for On Line Test Venue Booking - AY 2019-20</a>',
        },
      },
      {
        id: "sKlQvyhj0v",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GuestHouseRequestForm-23072019.pdf">23 Jul 2019 :&nbsp; &nbsp; &nbsp; Proforma for Guest House Booking - AY 2019-20</a>',
        },
      },
      {
        id: "PzyDbpjQTT",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ShortEducationalTourForm-23072019.pdf">23 Jul 2019 :&nbsp; &nbsp; &nbsp; Short Educational Tour Proposal Form - AY 2019-20</a>',
        },
      },
      {
        id: "hf7IyYmQlH",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/EducationalTourForm-20022019.pdf">21 Feb 2019 :&nbsp; &nbsp; &nbsp;Educational Tour Proposal Form</a>',
        },
      },
      {
        id: "D9n55xLWFw",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GuestHouseRequestForm-11022019.pdf">12 Feb 2019 :&nbsp; &nbsp; &nbsp;Guest House Request Form</a>',
        },
      },
      {
        id: "aWgTS0KfRz",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/OnLineTestRequestForm44-11022019.pdf">12 Feb 2019 :&nbsp; &nbsp; &nbsp;Online Test Request Form for Campus Placements</a>',
        },
      },
      {
        id: "I_CTpeHRbS",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/IndentFormForVehicleBooking-11022019.pdf">12 Feb 2019 :&nbsp; &nbsp; &nbsp;Indent Form for Vehicle Booking</a>',
        },
      },
      {
        id: "fRbk8OxrQ6",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/07/15/btech-regn-form-dasa-iccr-others_C1Bf4u0.pdf">15 Jul 2018 :&nbsp; &nbsp; &nbsp; B.Tech. Students (DASA/ICCR/Others) Registration Form for Campus Placement - 2018-19</a>',
        },
      },
      {
        id: "iXCYsxwZ5A",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/28/phd-regn-form.pdf">28 Jun 2018 : &nbsp;&nbsp;&nbsp;&nbsp;Ph.D. Scholars Registration Form for Campus Placement - 2018-19</a>',
        },
      },
      {
        id: "XVf-4F86kO",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/28/mtech-regn-form.pdf">28 Jun 2018 :&nbsp; &nbsp;&nbsp;&nbsp;M.Tech. Students Registration Form for Campus Placement - 2018-19</a>',
        },
      },
      {
        id: "qQsvlFMsrv",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/28/btech-regn-form.pdf">28 Jun 2018 : &nbsp;&nbsp;&nbsp;&nbsp;B.Tech. Students Registration Form for Campus Placement - 2018-19</a>',
        },
      },
      {
        id: "B2tFfd-u0V",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/28/mba-regn-form.pdf">28 Jun 2018 :&nbsp;&nbsp;&nbsp;&nbsp; M.B.A Students Registration Form for Campus Placement - 2018-19</a>',
        },
      },
      {
        id: "vwLit46zGi",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/28/mca-regn-form.pdf">28 Jun 2018 :&nbsp;&nbsp;&nbsp;&nbsp; M.C.A Students Registration Form for Campus Placement - 2018-19</a>',
        },
      },
      {
        id: "rimG0GIUpW",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/28/msctech-regn-form.pdf">28 Jun 2018 :&nbsp;&nbsp;&nbsp;&nbsp; M.Sc.(Tech.) Students Registration Form for Campus Placement - 2018-19</a>',
        },
      },
      {
        id: "BnjW2rCd3n",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/28/msc-regn-form.pdf">28 Jun 2018 : &nbsp;&nbsp;&nbsp;&nbsp;M.Sc. Students Registration Form for Campus Placement - 2018-19</a>',
        },
      },
    ],
    language: "En",
    _id: "61c55b631725251ae9cf772d",
    time: 1640324120839,
    version: "2.22.2",
    title: "DOWNLOADS",
    url: "/placement/DOWNLOADS",
    path: "/placement",
    createdAt: "2021-12-24T05:32:19.288Z",
    updatedAt: "2021-12-24T05:32:19.288Z",
    __v: 0,
  },
  {
    blocks: [{ id: "EczS86RWDI", type: "paragraph", data: { text: ".." } }],
    language: "En",
    _id: "61c562941725251ae9cf774e",
    time: 1640325962439,
    version: "2.22.2",
    title: "centres",
    url: "/centres/2021",
    path: "/centres",
    createdAt: "2021-12-24T06:03:00.867Z",
    updatedAt: "2021-12-24T06:03:00.867Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "Su4BDIHDS8", type: "paragraph", data: { text: "." } },
      {
        id: "Q4wk8fGNj7",
        type: "paragraph",
        data: {
          text: '<b>Head:</b>&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16307/" target="_blank">Dr. (Smt.) V. Venkata Mani</a>&nbsp;<br>(Department of Electronics &amp; Communication Engineering )<br><br>',
        },
      },
      {
        id: "uXjlVtwAfQ",
        type: "paragraph",
        data: {
          text: 'The following faculty members will be in-Charge of the activities of the Computer Centre:<br><br>i)&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16887/">Dr. Satish Maheshwaram</a>&nbsp; &nbsp; <br><b>Faculty-in/Charge, Internet &amp; LAN and NKN</b>.<br><br>ii)&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16923/">Dr. V. Narendar</a>&nbsp; &nbsp; &nbsp;<br><b>Faculty-in/Charge, Telecom and&nbsp;CCTV Surveillance</b>.<br><br>iii)&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16879/">Dr. E. Suresh Babu</a>&nbsp; &nbsp; &nbsp; <br><b>Faculty-in/Charge, Computer&nbsp;AMC and Webcasting and Video Conferencing (Hard and Soft VC)</b><br><br>',
        },
      },
      {
        id: "Fo0SS0Y0Ya",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "J6pw9NSn95",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/cc_2021-11-27-12-55-11.pdf`>Non-Teaching Staff&nbsp;</a>',
        },
      },
      {
        id: "-t5hESsJ64",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/cce_lab_2021-11-27-12-58-59.pdf`>Computer Centre Facilities</a>',
        },
      },
      {
        id: "Mf5tT5EV1I",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/sw_avalable_2021-11-27-13-0-1.pdf`>Software Available</a>',
        },
      },
    ],
    language: "En",
    _id: "61c563bd1725251ae9cf7758",
    time: 1640590358205,
    version: "2.22.2",
    title: "Computer Centre",
    url: "/centres/cc",
    path: "/centres",
    createdAt: "2021-12-24T06:07:57.661Z",
    updatedAt: "2021-12-27T07:32:38.357Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "LWhuLLWnk1",
        type: "header",
        data: {
          text: "ENGINEERING AND MAINTENANCE UNIT&nbsp;:&nbsp; NIT WARANGAL&nbsp;",
          level: 5,
        },
      },
      {
        id: "Vw3yHWH3uu",
        type: "paragraph",
        data: {
          text: "The Engineering and Maintenance Unit (E &amp; MU) is one of the important service units which play a vital role providing the requisite infrastructure facilities and all other amenities in the campus.&nbsp;",
        },
      },
      {
        id: "9dUmh8-u2v",
        type: "paragraph",
        data: {
          text: "The office of&nbsp;E &amp; MU&nbsp;is responsible for construction and maintenance of buildings (Academic, Residential, Hostel, Guest House, Student Activity Centers etc;), Water Supply System, Roads, Gardens, Electrical Supply, Sports Grounds and Waste Disposal Management. The E &amp; MU Office prepares the proposals, estimates, presentation to the Buildings and Works Committee, tendering and executes the work based on departments/centers/hostels/residential requirements of the institute.&nbsp;",
        },
      },
      {
        id: "_MTeyMVFuD",
        type: "paragraph",
        data: {
          text: "The Superintending Engineer&nbsp;is the&nbsp;Head&nbsp;of Engineering Unit who assist to Director,&nbsp;Dean (P&amp;D) and Registrar. Under this unit, One Executive Engineer (Civil), One Executive Engineer (Electrical)&nbsp;and Three Supervisors are working and supporting the Head of&nbsp;&nbsp;Engineering Unit .&nbsp;",
        },
      },
      {
        id: "l_Gi_to3PS",
        type: "header",
        data: { text: "Head of Engineering Unit&nbsp;:", level: 5 },
      },
      {
        id: "J9qmfrXi2Y",
        type: "paragraph",
        data: {
          text: '<br>Superintending Engineer<br><a href="mailto:se_emu@nitw.ac.in">E- Mail:&nbsp; &nbsp;se_emu@nitw.ac.in</a><br>Contact:&nbsp; 0870 2462080<br><br>',
        },
      },
      { id: "Fd_eVXu-9B", type: "header", data: { text: "Staff:", level: 4 } },
      {
        id: "bFIyBd855b",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Superintending Engineer",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri T Venugopala Reddy</a>',
              "83329 69671",
            ],
            [
              "2",
              '<editorjs-style style="display: block;\nwhite-space: pre;\n">Executive Engineer</editorjs-style>(Civil)',
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. G. Ramesh</a>',
              "83329 69671",
            ],
            [
              "3",
              '<editorjs-style style="display: block;\nwhite-space: pre;\n">Executive Engineer</editorjs-style>(Electrical)',
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. P. Suresh&nbsp;</a>',
              "94910 65004",
            ],
            [
              "4",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. K. Ravinder</a>',
              "94910 85292",
            ],
            [
              "5",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. S. Venu Gopal</a>',
              "99893 53535",
            ],
            [
              "6",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. K. Mahendra Kumar</a>',
              "83329 69714",
            ],
          ],
        },
      },
      {
        id: "thMlq6Z8b8",
        type: "paragraph",
        data: {
          text: '<a href="mailto:emu_complaints@nitw.ac.in">E- Mail:&nbsp; &nbsp;emu_complaints@nitw.ac.in</a>',
        },
      },
    ],
    language: "En",
    _id: "61c565921725251ae9cf775c",
    time: 1643272822821,
    version: "2.22.2",
    title: "Engineering & Maintenance Unit",
    url: "/emu/2021",
    path: "/emu",
    createdAt: "2021-12-24T06:15:46.297Z",
    updatedAt: "2022-01-27T08:40:21.030Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "2Uzz4jDjAf",
        type: "header",
        data: { text: "Guest House", level: 3 },
      },
      {
        id: "-TshDv4TXa",
        type: "paragraph",
        data: {
          text: "Booking and Contact Details for Guest House accommodation at NIT Warangal&nbsp;",
        },
      },
      {
        id: "EuLksGPUFQ",
        type: "paragraph",
        data: {
          text: '1. For online booking:&nbsp;&nbsp;<a href="https://docs.google.com/forms/d/e/1FAIpQLSdyjFSpJCNZ0DHmEp-KNnLKGH5TH87UeON90OuYyU_taQ5-WA/viewform" target="_blank">https://docs.google.com/forms/d/e/1FAIpQLSdyjFSpJCNZ0DHmEp-KNnLKGH5TH87UeON90OuYyU_taQ5-WA/viewform</a>&nbsp;<br><br>',
        },
      },
      {
        id: "HhEQk03qBD",
        type: "paragraph",
        data: {
          text: '2. For booking related queries, booking confirmation, all correspondence, suggestions, feedback&nbsp;email to: "<a href="mailto:ghnitw@nitw.ac.in" target="_blank">ghnitw@nitw.ac.in</a>"<br><br>',
        },
      },
      {
        id: "B-wlfCLi-c",
        type: "paragraph",
        data: {
          text: "3. For room availability and confirmation call&nbsp; &nbsp;:&nbsp;<br>Shri. T. Dileep Kumar, <br>Assistant SG-II<br>&nbsp;- Ph: 9490164970/ 0870-2468020&nbsp;/ 0870-2468059<br><br>",
        },
      },
      {
        id: "4Xl6dgZmBd",
        type: "paragraph",
        data: {
          text: '4. Officer in Incharge, Guest House:&nbsp;<br>Shri. S. Venkateswaran, <br>Deputy Registrar (Admin.), <br>NIT Warangal, <br>email:&nbsp;<a href="mailto:dr_admin@nitw.ac.in" target="_blank">dr_admin@nitw.ac.in</a>.',
        },
      },
      {
        id: "EwL4fhqOjh",
        type: "paragraph",
        data: {
          text: '5. Registrar, NIT Warangal:&nbsp;<br>Shri. S.Goverdhan Rao, <br>Registrar, NIT Warangal, <br>email:&nbsp;<a href="mailto:registrar@nitw.ac.in" target="_blank">registrar@nitw.ac.in</a>&nbsp;',
        },
      },
      {
        id: "ELfwhpDYZX",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2019/07/20/gh-new-tarrif.pdf">Policy, Procedure and Tariff for Rooms in GH, Seminar Halls etc</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c569261725251ae9cf7770",
    time: 1642499168605,
    version: "2.22.2",
    title: "Guest House",
    url: "/guesthouse/2021",
    path: "/guesthouse",
    createdAt: "2021-12-24T06:31:02.422Z",
    updatedAt: "2022-01-18T09:46:08.537Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "zTbcnEZl5F",
        type: "header",
        data: { text: "INSTITUTE HEALTH CENTRE (IHC)", level: 5 },
      },
      {
        id: "7wnBrcdyYs",
        type: "paragraph",
        data: {
          text: "The Institute Heath Centre is located within the campus",
        },
      },
      { id: "oLcgLDkviq", type: "paragraph", data: { text: "TIMINGS:&nbsp;" } },
      {
        id: "2g1VpIghtx",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "On Working Days&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;&nbsp;9:30am to 1:00pm and 3:00pm to 6:00pm.&nbsp;",
            "On Saturday and Sunday&nbsp;&nbsp;:&nbsp; &nbsp;9:30am to 11:30am.",
          ],
        },
      },
      {
        id: "B1NIEdnWcv",
        type: "paragraph",
        data: {
          text: "NITW Health Centre is a full fledged unit with Three Full time regular medical officers, one contract part time doctor&nbsp;who are assisted by paramedical staff (Staff Nurse, ANM, OPD Assistant, Dresser, Lab Technician).",
        },
      },
      {
        id: "Skp44MqgmJ",
        type: "paragraph",
        data: {
          text: "The Health Centre has attached pharmacy with the turn over of medicines of more than 50 lakhs per annum.",
        },
      },
      {
        id: "73e4en7IxE",
        type: "paragraph",
        data: {
          text: "The students, employees and their dependents almost 11000 members are getting benefited with the daily OPD of around 150 and above.",
        },
      },
      { id: "lWW6yq5MYk", type: "paragraph", data: { text: "FACILITIES:" } },
      {
        id: "rQieD8BF7C",
        type: "paragraph",
        data: {
          text: "The Health Centre is having well established pathological lab facilities where blood, urine tests are done. The other facilities are ECG, pulse oxymeter, nebulizer, oxygen support, o2 masks.",
        },
      },
      {
        id: "uCJfa94Aax",
        type: "paragraph",
        data: {
          text: "The laboratory is equipped with hematology analyzer, Biochemistry analyzer, urine analyzer, hot air oven ,R-8C laboratory centrifuge ,Blood mixer,binocular microscope. All the routine blood tests(Hemogram, LFT, RFT, lipid profile, thyroid profile) are done.&nbsp;",
        },
      },
      {
        id: "gDv2_jFEtJ",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-45944-pm-1.jpeg",
          },
          caption: "&nbsp; &nbsp;BIOCHEMISTRY ANALYZER",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "NZvfdsabuj",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-50101-pm.jpeg",
          },
          caption: "&nbsp; HEMATOLOGY ANALYZER",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "UG4Cr3BEMH",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;",
        },
      },
      {
        id: "o0p5FNEpQN",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-50341-pm.jpeg",
          },
          caption:
            "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;URINE ANALYZER&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "PD93pEpiq1",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-50720-pm.jpeg",
          },
          caption: "&nbsp; R-8C LABORATORY CENTRIFUGE",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "jv_U_dZKak",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;",
        },
      },
      {
        id: "7FRAV0rrKx",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-51148-pm.jpeg",
          },
          caption: "&nbsp; &nbsp; &nbsp;BLOOD MIXER",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "MGuDg3KGG5",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;",
        },
      },
      {
        id: "856vI0rSiy",
        type: "paragraph",
        data: {
          text: "We have a Five bed facility for observation and emergency first aid of patients.",
        },
      },
      { id: "x81vytHRpB", type: "paragraph", data: { text: "DENTAL CLINIC:" } },
      {
        id: "L2IQhXsD2J",
        type: "paragraph",
        data: {
          text: "The Health Centre has a Dental chair where minor procedures are done such as scaling, filling and extraction of tooth.",
        },
      },
      {
        id: "L8EfIFlaR5",
        type: "paragraph",
        data: { text: "Specialist Services:" },
      },
      {
        id: "Q4QkQLKFNY",
        type: "paragraph",
        data: {
          text: "The Health Centre has Four visiting Doctors in the department of",
        },
      },
      {
        id: "StKHEGAFHr",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Psychiatry&nbsp;on&nbsp;Friday&nbsp;from&nbsp;4:30pm to 6:00pm.&nbsp;",
            "Dentist&nbsp;on&nbsp;Wednesday&nbsp;from&nbsp;3:30pm to 5:30pm.&nbsp;",
            "General physician&nbsp;on&nbsp;Tuesday&nbsp;from&nbsp;4:00pm to 6:00pm.&nbsp;",
            "Homeopathy&nbsp;on&nbsp;Monday and Thursday&nbsp;from&nbsp;11:00am to 12:00pm.",
          ],
        },
      },
      { id: "ahVIQ5rSSf", type: "paragraph", data: { text: "PHYSIOTHERAPY:" } },
      {
        id: "JlLVg3VVfU",
        type: "paragraph",
        data: {
          text: "The physiotherapy unit is equipped with all the latest modalities. tens muscle stimulator machine, interferential therapy, Ultra sound therapy machine, hydro collator packs.",
        },
      },
      {
        id: "P7gV6_YsTs",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-43739-pm.jpeg",
          },
          caption: "&nbsp;TENS MUSCLE STIMULATOR MACHINE&nbsp; &nbsp;",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "3G4CzIc1Hy",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-43438-pm.jpeg",
          },
          caption: "&nbsp; &nbsp; INTERFERENTIAL THERAPY",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "W_wB1vu4m3",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;",
        },
      },
      {
        id: "O9H1EWcb30",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-43008-pm.jpeg",
          },
          caption: "&nbsp;ULTRA SOUND THERAPY MACHINE&nbsp; &nbsp;&nbsp;",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "Kxgcmd43UG",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-44229-pm.jpeg",
          },
          caption: "&nbsp; &nbsp;HYDRO COLLATOR PACKS&nbsp;&nbsp;",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "X8d31ltFfk",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;",
        },
      },
      {
        id: "e1ry9E930V",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/11/02/whatsapp-image-2021-11-02-at-103658-am.jpeg",
          },
          caption: "&nbsp;D-Trac and Mobilization Machine&nbsp; &nbsp;",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "zeazNsudsz",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;",
        },
      },
      {
        id: "m6ixsOaMiK",
        type: "paragraph",
        data: {
          text: "We have Two physiotherapists from&nbsp;11am to 6pm. The physiotherapy unit has all the electrotherapy modalities and exercise therapy for rehabilitation and chronic and acute pain management.",
        },
      },
      {
        id: "QVktr17wSG",
        type: "paragraph",
        data: { text: "WASTE DISPOSAL:" },
      },
      {
        id: "ejiQA2XEpw",
        type: "paragraph",
        data: {
          text: "We are also following the rules of Bio medical waste as per Ministry of Environment and Forests.",
        },
      },
      {
        id: "u-OHdeT0js",
        type: "paragraph",
        data: { text: "MEDICAL SCREENING:" },
      },
      {
        id: "iXEP69NzMm",
        type: "paragraph",
        data: {
          text: "During admission process, medical screening is being handled by Health Centre for all the students.",
        },
      },
      {
        id: "Q9_2-QsbY8",
        type: "paragraph",
        data: {
          text: "Various clinical activities and camps undertaken in 2019 are as Follows:",
        },
      },
      {
        id: "Kr76QmEb4H",
        type: "paragraph",
        data: {
          text: "1. Medical service is provided at the hostel itself for first 3 months to make the stay of students comfortable and safe including Saturday, Sunday, festivals.",
        },
      },
      {
        id: "pZxxzVgo2A",
        type: "paragraph",
        data: {
          text: "2. Induction lecture and psychology counseling for the students with interactive session.",
        },
      },
      {
        id: "q0xB5otWjJ",
        type: "paragraph",
        data: {
          text: "3.&nbsp;Muddunur camp: Around 700 village patients are benefited with the camp in a village near Warangal, which is the village adopted by NITWarangal with free distribution of medicines.",
        },
      },
      {
        id: "PkafN7Q5wU",
        type: "paragraph",
        data: {
          text: "4. Cardiology camp around 110 patients have undergone cardiology consultation and 2Decho is done for the patients who needed.",
        },
      },
      {
        id: "2NrziZoFJm",
        type: "paragraph",
        data: {
          text: "The list of empanelled hospitals from 01.07.2021 to 30.06.2022:",
        },
      },
      {
        id: "6XErmFUrGY",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "L8Nz01uDzf",
        type: "paragraph",
        data: {
          text: '1.<a href=`${MainServerUrl}/static/files/Super_Speciality_Hospitals_at_warangal_2021-11-27-12-3-37.pdf`> Super Speciality Hospitals at warangal</a>',
        },
      },
      {
        id: "fgIPCwvSv7",
        type: "paragraph",
        data: {
          text: '2. <a href=`${MainServerUrl}/static/files/General_Hospitals_at_Warangal_2021-11-27-12-4-47.pdf`>General Hospitals at Warangal</a>',
        },
      },
      {
        id: "sVfM7egQg0",
        type: "paragraph",
        data: {
          text: '3. <a href=`${MainServerUrl}/static/files/Super_Speciality_Hospitals_at_Hyderabad_2021-11-27-12-5-54.pdf`>Super Speciality Hospitals at Hyderabad</a>',
        },
      },
      {
        id: "ETtx6lv9kr",
        type: "paragraph",
        data: {
          text: '4. <a href=`${MainServerUrl}/static/files/Empanelled_Hospitals_list_for_Students_at_Warangal_2021-11-27-12-7-23.pdf`>Empanelled Hospitals list for Students at Warangal</a>',
        },
      },
      {
        id: "AOPCowiHuS",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href=`${MainServerUrl}/static/files/MEDICAL_OFFICERS_2021-11-27-12-9-39.pdf`>MEDICAL OFFICERS</a>',
          ],
        },
      },
      { id: "uU_qeYa9nX", type: "paragraph", data: { text: "<br>" } },
      {
        id: "x7epKa4EWi",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href=`${MainServerUrl}/static/files/CONTRACT_DOCTORS_2021-11-27-12-12-19.pdf`>CONTRACT DOCTORS</a>',
          ],
        },
      },
      {
        id: "16Fi6q3EdV",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href=`${MainServerUrl}/static/files/PERMANENT_STAF_2021-11-27-12-13-42.pdf`>PERMANENT STAFF</a><br><br>',
          ],
        },
      },
      {
        id: "oNje46O7jz",
        type: "header",
        data: { text: "MEDICAL OFFICERS:", level: 6 },
      },
      {
        id: "B-LjCny5jd",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2020/07/14/d1.jpg",
          },
          caption: "DR.CHINTALA KARTHIK (MEDICAL OFFICER IN - CHARGE)",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "d4q97Bto6t",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2020/07/14/d2.jpg",
          },
          caption: "DR.PRADEEP DODDA (MEDICAL OFFICER)",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "XwjnRlpFDJ",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2020/07/14/d3.jpg",
          },
          caption: "DR.ANCHOORI KARTHIK (MEDICAL OFFICER)&nbsp;&nbsp;",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "x1bcYAEy2P",
        type: "paragraph",
        data: { text: "<b>CORONA VIRUS</b>" },
      },
      {
        id: "i3-Af--ff_",
        type: "paragraph",
        data: {
          text: "Corona viruses are a large family of viruses which may cause illness in animals or humans. In humans, several corona viruses are known to cause respiratory infections ranging from the common cold to more severe diseases such as Middle East Respiratory Syndrome (MERS) and Severe Acute Respiratory Syndrome (SARS). The most recently discovered corona virus causes corona virus disease COVID-19. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
        },
      },
      {
        id: "aiNmDb8T43",
        type: "paragraph",
        data: {
          text: "The symptoms are fever, cough, sorethroat, breathlessness, sneezing, myalgia, GI symptoms, weakness.<br><br>",
        },
      },
      {
        id: "b4LT6FRIEu",
        type: "paragraph",
        data: { text: "<b>Basic preventive measures to be followed:</b>" },
      },
      {
        id: "-H68vku06M",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Physical distancing of at least one meter (6 feet) between individuals to be followed at all times.&nbsp;",
            "Employees and Students entering the campus shall be thermally scanned for their body temperature. If the body temperature is normal, then nothing needs to be done. If the body temperature is higher than normal, then the Institute Health Centre must be informed, and the person scanned should be asked to visit the Health Centre, and their vehicle will also be disinfected.&nbsp;",
            "Use of face covers/masks to be mandatory.&nbsp;",
            "Don’t touch your face, mouth and nose as this might increases the risk of infection.&nbsp;",
            "Practice frequent hand washing (for at least 40-60 seconds) even when hands are not visibly dirty and use of alcohol based hand sanitizers (for at least 20 seconds).&nbsp;",
            "Respiratory etiquettes to be strictly followed. This involves strict practice of covering one’s mouth and nose while coughing/sneezing with a tissue/handkerchief/flexed elbow and disposing off used tissues properly.&nbsp;",
            "Self-monitoring of health by all and reporting any illness at the earliest&nbsp;",
            "Spitting anywhere in the campus is strictly banned.&nbsp;",
            "All employees must carry their ID card to be shown when stopped and asked by the Security.&nbsp;",
            "Anyone coming to the campus from abroad or outside Warangal has to undergo a mandatory home quarantine for as per GOI norms after arrival on the campus.&nbsp;",
            "Putting sanitizing hand rub dispensers in prominent places around the workplace. Make sure these dispensers are regularly refilled.&nbsp;",
            "Making sure that all toilets are disinfected at least twice a day, and all common areas are disinfected periodically.&nbsp;",
            "Older people, and those with underlying medical problems like high blood pressure, heart problems or diabetes, are more likely to develop serious illness. People with fever, cough and difficulty breathing should seek medical attention. People can catch COVID-19 from others who have the virus.&nbsp;",
            "Eat healthy foods to boost immunity.",
          ],
        },
      },
    ],
    language: "En",
    _id: "61c56b2a1725251ae9cf7778",
    time: 1640587489753,
    version: "2.22.2",
    title: "IHC",
    url: "/ihc/home",
    path: "/ihc",
    createdAt: "2021-12-24T06:39:38.033Z",
    updatedAt: "2021-12-27T06:44:49.851Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Ksq1XQtcy-",
        type: "header",
        data: { text: "Ambulance:", level: 3 },
      },
      {
        id: "grry6DVNkw",
        type: "paragraph",
        data: {
          text: "Ambulance service is provided 24X7. Ambulance is equipped with O2 cylinder, nebulizer and first aid box with routine medicines, essential life saving drugs.",
        },
      },
      { id: "OUsM5V2asG", type: "paragraph", data: { text: "Contact No" } },
      {
        id: "1qk9z22-oT",
        type: "paragraph",
        data: {
          text: "Ambulance can be&nbsp;contacted from the Phone numbers given below.Phone:&nbsp;0870-2462099Mobile:&nbsp;9491065006",
        },
      },
      {
        id: "G2erMUixrB",
        type: "paragraph",
        data: {
          text: "Emergency phone number is displayed in all buildings and hostels.",
        },
      },
      {
        id: "TK_DS3qpHE",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2020/07/14/whatsapp-image-2020-07-14-at-14448-pm.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
    ],
    language: "En",
    _id: "61c56b921725251ae9cf777a",
    time: 1640328264360,
    version: "2.22.2",
    title: "Ambulance service",
    url: "/ihc/Ambulance service",
    path: "/ihc",
    createdAt: "2021-12-24T06:41:22.786Z",
    updatedAt: "2021-12-24T06:41:22.786Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "_c1QHnLq3g",
        type: "header",
        data: { text: "Various Activities in 2020 - 21", level: 5 },
      },
      {
        id: "p0US7_kwf-",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-123217-pm-1.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "wNRhEOYyIG",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-123217-pm.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "iRcx9k2hMc",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp;Prof. N.V.Ramana Rao, Director NITW has inaugurated the health centre with more medical facilities (Hematology analyzer, Biochemistry analyzer and physiotherapy equipments&nbsp; on 28th&nbsp;April 2021.",
        },
      },
      {
        id: "oo88i0eGoP",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp; Sri. S.Govardhan Rao&nbsp;Registrar NITW, Institute Health Centre Doctors and staff attended the program.",
        },
      },
      {
        id: "-p8bzmjm3m",
        type: "paragraph",
        data: { text: "&nbsp;Laboratory:" },
      },
      {
        id: "Nhsarcb3MN",
        type: "paragraph",
        data: {
          text: "The Laboratory is equiped wih latest equipments.&nbsp;",
        },
      },
      { id: "B_kEOrL4Hz", type: "paragraph", data: { text: "Physiotherapy:" } },
      {
        id: "nPOcilWz0i",
        type: "paragraph",
        data: {
          text: "The Physiotherapy unit&nbsp;&nbsp;is equiped with all the latest equipments. Prof.N.V.Ramana Rao, Director NITW has inaugurated the Health Centre Physiotheraphy unit with D-Trac&nbsp;and mobilization machine on 1.11.2021. D- Trac&nbsp;machine is to treat chronic back and neck pain with out any irritation to release the spasms and stiffness",
        },
      },
      {
        id: "xbUjxcyT7c",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/11/02/whatsapp-image-2021-11-02-at-103022-am.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "W51iZjqnLO",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/11/02/whatsapp-image-2021-11-02-at-103021-am.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "HcpfGwKXNt",
        type: "paragraph",
        data: { text: "Covid -19 vaccination" },
      },
      {
        id: "tk_LczrpeC",
        type: "paragraph",
        data: {
          text: "&nbsp;Covid -19 vaccination drive is arranged at NIT warangal. Faculty, staff and family dependents&nbsp; have utilized the opportunity and got vaccinated.",
        },
      },
      {
        id: "Gpk5UHGTjk",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2021/07/15/whatsapp-image-2021-07-13-at-123051-pm.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "rQNE7bDGEa",
        type: "paragraph",
        data: { text: "Tackling Covid-19 during the PANDEMIC&nbsp;" },
      },
      {
        id: "DW0gHALFXj",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Tele Consultation:&nbsp;Tele Consultation was done round the clock (24*7). Around 100 Covid-19 positive cases were treated by our Institute Health Centre doctors during the Covid-19 2nd&nbsp;wave .&nbsp;",
            "Testing:&nbsp;Covid testing has been arranged whenever required and also reached to the nearest Primary Health Center (Boda Gutta) for immediate testing/vaccination, wherever possible.&nbsp;",
            "Arranged quarantine at the Punnami Guest House employees who require isolation.&nbsp;",
            "For emergency Covid patient’s - beds have been arranged in hospitals whenever required.&nbsp;",
            "Monitoring of post Covid patients by physiotherapist is arranged to discuss the techniques that increase the lung capacity.&nbsp;",
            "In the process of Covid and during post Covid Rehabilitation, patients are being counselled regarding their condition, techniques to increase their lung capacity and mental wellbeing which improve their immunity and ability to fight the diseases.&nbsp;&nbsp;",
            "Health Centre has taken initiative for the post COVID recovering patients in the form of arranging OXYGEN CONCENTRATOR which can deliver 7 Litres of OXYGEN.&nbsp;",
            "Diet:&nbsp;Dietary advice was given to entire family members.&nbsp;",
            "Patients were&nbsp;encouraged to be STRONG ENOUGH. They were also counselled to take all the medicines in time and NOT PANIC by reading the WhatsApp forwarded messages and google search.&nbsp;",
            "Preventive measures were &nbsp;being given in R.O frequently.&nbsp;",
            "Regular medicines were given for one month in order to decrease the visit / exposure to the health Centre and even issuing emergency drugs for cold, cough, fever.&nbsp;",
            "The IHC staff are exposed to heavy load of positive cases and we continue to give the services at the best possible way.&nbsp;",
          ],
        },
      },
      {
        id: "-ne-1XVHC_",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Medical Team with a Doctor and a paramedical staff were present at the 60th National Open Athletic Championship&nbsp;2021 for Emergency services.",
          ],
        },
      },
    ],
    language: "En",
    _id: "61c56bd31725251ae9cf777c",
    time: 1640328449672,
    version: "2.22.2",
    title: "Various Activities in 2020 - 21",
    url: "/ihc/VariousActivities",
    path: "/ihc",
    createdAt: "2021-12-24T06:42:27.921Z",
    updatedAt: "2021-12-24T06:44:28.143Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "oJXekUNBlj",
        type: "header",
        data: {
          text: "Computer Science And Engineering Association",
          level: 5,
        },
      },
      {
        id: "YVvJICibOa",
        type: "image",
        data: {
          file: { url: "https://www.nitw.ac.in/media/csea.jpg" },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "67ai3XiaQz",
        type: "image",
        data: {
          file: { url: "https://www.nitw.ac.in/media/ecea.jpg" },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "joGRpliTMf",
        type: "header",
        data: {
          text: "Electronics And Communication Engineering Association",
          level: 5,
        },
      },
      {
        id: "fTcM8n_G2A",
        type: "image",
        data: {
          file: { url: "https://www.nitw.ac.in/media/mea.png" },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "JCiMU3JiNg",
        type: "header",
        data: { text: "Mechanical Engineering Association", level: 5 },
      },
    ],
    language: "En",
    _id: "61c597061725251ae9cf7785",
    time: 1640339387775,
    version: "2.22.2",
    title: "Association",
    url: "/sw/association",
    path: "/sw",
    createdAt: "2021-12-24T09:46:46.209Z",
    updatedAt: "2021-12-24T09:46:46.209Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "KOi-X6qQbb",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2021/08/27/faculty-advisors-for-the-student-clubs.pdf">Faculty Advisors for various Student Clubs for the academic year 2021–22</a>',
        },
      },
      {
        id: "ZovN339yZv",
        type: "header",
        data: { text: "&nbsp;DANCE AND DRAMATICS", level: 5 },
      },
      {
        id: "9w-XZMy1xQ",
        type: "image",
        data: {
          file: { url: "https://www.nitw.ac.in/media/dnd.jpg" },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "K7TdE0FbBN",
        type: "paragraph",
        data: {
          text: "&nbsp;The DND club is an organisation which consists of a dedicated group of members always enthusiastic&nbsp; about putting up a well choreographed show for the ever interest crowd of our college and would make attempt evertything they can to make that happen. It has a diverse mix of students who are passonate about dance from b'boying to contemporary&nbsp;&nbsp; to mass, we excel in all dance forms. when its time to turn up the heat and follow the rythm, the only person u need to be is yourself",
        },
      },
      {
        id: "mfezN6Zztx",
        type: "header",
        data: { text: "&nbsp;LITERARY AND DEBATING CLUB", level: 5 },
      },
      {
        id: "8IBXypRURM",
        type: "image",
        data: {
          file: { url: "https://www.nitw.ac.in/media/lnd.jpg" },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "iYbgDbN20W",
        type: "paragraph",
        data: {
          text: "The Literary and Debating Club is the largest student club in college with a 65-member strong body. It comprises three student groups that organize events in three languages- English, Hindi and Telugu- but work in tandem to benefit students in the realms of writing and speaking by conducting events that are both instructional and enjoyable.",
        },
      },
      {
        id: "QqjkZKnowA",
        type: "header",
        data: { text: "MUSIC CLUB", level: 5 },
      },
      {
        id: "gzY20RGCi8",
        type: "image",
        data: {
          file: { url: "https://www.nitw.ac.in/media/musicclub.jpg" },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "tXea7H92oJ",
        type: "paragraph",
        data: {
          text: "The Music club is a vibrant and elite society of musically gifted students who come together and create fascinating melodies. The purpose of the club is to showcase the musical talent of maximum number of students and it has upheld this tradition over the years.",
        },
      },
      {
        id: "BctxbbHdGt",
        type: "paragraph",
        data: {
          text: "The club encourages young musicians to interact with each other and gives them an appropriate jam pad. It enables us students to utilize our free time productively and do something what we as music enthusiasts are passionate about.",
        },
      },
      {
        id: "rwuguhVCy3",
        type: "paragraph",
        data: {
          text: "The club recognizes exceptional talent and promotes it in the right direction. The students in the executive body arrange practice sessions for intra as well as inter collegiate events. Workshops and semester long classes are held for those who are interested in learning music. Thus, if a student wants to excel in the field of music, the club does its bit to help achieve that goal.",
        },
      },
      {
        id: "NiQpB786W4",
        type: "header",
        data: { text: "PHOTOGRAPHY CLUB", level: 5 },
      },
      {
        id: "ZVwewqN7cX",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/SAVE_20181101_105403_cdCqhLY.png",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "wM7ai4w_4q",
        type: "paragraph",
        data: {
          text: '"Photography is the story people fail to put into words."',
        },
      },
      {
        id: "yrUESUbZ0R",
        type: "paragraph",
        data: {
          text: "Photography can defeat time and is the universal language that speaks to the heart. At Photography Club, we believe in the power of photos to spark positive change in a world where the pursuit of self-interest is prioritized by many, its role and power to disseminate truth when all other avenues fail is unparalleled. The Photography club sorts all the happenings on campus into images and publishes them for the NITW community. PGC is the official body that covers all the aspects of NIT Warangal from the beautiful campus to the fests and conferences in the form of stunning photos and videos. Our dedicated and passionate student body always strives to provide different perspectives to the events held on campus and call people's attention to the beauty that surrounds them. In the end, PGC is all about capturing memories and conveying stories.",
        },
      },
      {
        id: "3kpKQ5D_mw",
        type: "paragraph",
        data: {
          text: "“In photography there is a reality so subtle that it becomes more real than reality.”",
        },
      },
      { id: "szNa2DthVZ", type: "paragraph", data: { text: "Contact:" } },
      {
        id: "BA7Dk0KF3t",
        type: "paragraph",
        data: { text: "Facebook- facebook.com/photographyclubnitw" },
      },
      {
        id: "TmXkzz5QRf",
        type: "paragraph",
        data: { text: "Email- photographyclub@student.nitw.ac.in" },
      },
      {
        id: "yZR6eCPD1a",
        type: "paragraph",
        data: { text: "Instagram- @photography_club_nitw" },
      },
      {
        id: "5dmn_xNfyG",
        type: "header",
        data: { text: "QUIZ CLUB", level: 5 },
      },
      {
        id: "W3mULzc-zS",
        type: "image",
        data: {
          file: { url: "https://www.nitw.ac.in/media/qc.jpg" },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "nwrIDmjqZX",
        type: "paragraph",
        data: {
          text: "The Quiz Club takes&nbsp;pride&nbsp;in being one of the most proactive and popular clubs among the student clubs of NIT Warangal.",
        },
      },
      {
        id: "EOdPBDbHBe",
        type: "paragraph",
        data: {
          text: "Comprising twenty members, the club is dedicated to preparing and hosting quality quizzes for the student population on the campus.",
        },
      },
      {
        id: "7bNkcukP81",
        type: "paragraph",
        data: {
          text: "Equipped with events ranging from quizzes such as&nbsp;The&nbsp;Joga Bonitos&nbsp;(&nbsp;an year-long quiz tournament ) to the&nbsp;Annual General Quiz, the club offers a competing platform for the knowledge-hungry students. The gamut of activities include the&nbsp;Quiz Week&nbsp;( an intra-mural event ) to quizzes covering specific themes, such as business, sports, literature, fine arts, entertainment, history and politics. The Quiz Club also hosts the&nbsp;Business Quiz&nbsp;held as part of the technical festival&nbsp;Technozion&nbsp;every year and quizzes held as part of the cultural festival Spring Spree. With a motivated team of members and an encouraging response from the students, the Quiz Club promises to cultivate a passion for quizzing among the participants.",
        },
      },
      {
        id: "Ims9Xov_Ut",
        type: "header",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2019/03/06/value-education-club-for-website_05_03_2019.pdf">Value Education Club</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "61c597c51725251ae9cf7787",
    time: 1640339578959,
    version: "2.22.2",
    title: "club",
    url: "/sw/club",
    path: "/sw",
    createdAt: "2021-12-24T09:49:57.390Z",
    updatedAt: "2021-12-24T09:49:57.390Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "yaV-NL3Dp4",
        type: "paragraph",
        data: {
          text: '1.MECHANICAL BROOM&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/04/02/abstract-epics17.docx">Download Abstract</a><br><br>2.PEST SPRAY AND DETECTION&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/04/02/abstract.docx">Download Abstract</a><br><br>3.HEALTHY HEART&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/04/02/carpediem-epics-abstract.docx">Download Abstract</a><br><br>4.&nbsp;LOW COST WATER FILTER&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/04/02/epics.pdf">Download Abstract</a><br><br>5.MECHANISED WEED REMOVER&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/04/02/epicsteamdetails.docx">Download Abstract</a><br><br>6.PORTABLE INTERACTIVE MEDICAL TREATMENT ASSISTIVE KIT&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/04/02/portable-interactive-medical-treatment-assistive-kit.docx">Download Abstract</a>',
        },
      },
    ],
    language: "En",
    _id: "61c598621725251ae9cf7789",
    time: 1640339736295,
    version: "2.22.2",
    title: "EPIC Projects",
    url: "/sw/EPICProjects",
    path: "/sw",
    createdAt: "2021-12-24T09:52:34.736Z",
    updatedAt: "2021-12-24T09:52:34.736Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "afnSUp24ux",
        type: "header",
        data: {
          text: "FORUM FOR INTERNATIONAL STUDENT AFFAIRS (FISA)",
          level: 5,
        },
      },
      {
        id: "oAA3YZy_nX",
        type: "paragraph",
        data: {
          text: "The National Institute of Technology is home to more than 4000 of India’s brilliant minds and future leaders. Setting trends in the technological and academic worlds, the premier institute also houses foreign students from more than 30 countries all over the world. This is where the Forum for International Student Affairs (FISA) steps in. FISA, an infant club at the institute serves as a platform to showcase the talent of the foreign students and show that they are at par with everyone else.",
        },
      },
      {
        id: "Q7C-tUsCXl",
        type: "paragraph",
        data: {
          text: "That’s not where FISA stops. Apart from being a talent platform, FISA also caters the academic needs of foreign students. It is no secret that the Indian educational system requires that a student clear tough competition and emerge a winner if he has to be considered worthy and the college being one of the best in the country is home only to the cream layer of such winners. FISA recognises the need of foreign students for extra academic help and extends support and guidance in such matters. Taking this into consideration, FISA is organising extra classes for subjects that are considered tough and these classes will be conducted taking into consideration the needs and limits of foreign students who are new to the Indian way of teaching. FISA comprises of members who themselves are foreign students and understands that it is difficult to adjust to a new environment thousands of miles away from home. New place, new college, new faces and a new learning system is bound to be overwhelming to anyone and hence we strive to do everything possible to make foreign students comfortable in their new surroundings.&nbsp; Students are most welcome to contact any of the club members for guidance for anything that may be bothering them and the members will try their level best to help the students out.",
        },
      },
      {
        id: "KO70-P8KNo",
        type: "paragraph",
        data: {
          text: "As it serves to be a platform to foreign students, FISA also gives the local students a sneak view of international culture to show them the delights of places beyond the Indian shores, and what can be a better way to put up a cultural display other than food and movies. Organising an International Food Festival is FISA’s way of giving NITW a taste of life abroad. An international food festival is also on FISA’s agenda where students can enjoy something different from the traditional bollywood movies.",
        },
      },
      {
        id: "HhG6GOXKXr",
        type: "paragraph",
        data: {
          text: "FISA also has other exciting events for active participation of both foreign and local students. &nbsp;A sports meet to display the sporting talents and a cultural program will soon be held. FISA also plans to pair up with the L&amp;D club and organise a debate/essay competition, the main focus being on international affairs. Organising an Inter-NIT foreign student’s meet is another event that has the club members excited. It is the first time that such an event will be hosted in the institute.",
        },
      },
      {
        id: "lcqIONXbKr",
        type: "paragraph",
        data: {
          text: "With blessing from the Dean and a kick start by Dr. Kesava Rao, the club was started in the academic year 2010-11. Owing to lack of funds and confusion, the club hosted no more than an inaugural. However, starting this year(2011-12), the club under the leadership of Anish Singh, the general secretary,&nbsp; and innovative ideas from the club members, the club has found its direction and strives to be the most happening club in NITW.",
        },
      },
      {
        id: "2uLWlWYRyL",
        type: "paragraph",
        data: {
          text: "The main goal of FISA is to bring the foreign students of NITW under one canopy to ensure that they excel in academic and co-curricular activities and bring pride to our prestigious institute.",
        },
      },
      { id: "R5X3slCVGV", type: "paragraph", data: { text: "Team of fisa" } },
      {
        id: "W3-YKL8ZwG",
        type: "paragraph",
        data: { text: "Faculty advisor&nbsp;:&nbsp;Prof. D.S. kesava Rao" },
      },
      {
        id: "zqMEByPm7t",
        type: "paragraph",
        data: { text: "General Secretary:&nbsp;Anish Singh" },
      },
      {
        id: "PeXeTl55-P",
        type: "paragraph",
        data: { text: "Additonal General Secretary:&nbsp;Vivek Vepa" },
      },
      {
        id: "no4EJU4BtF",
        type: "header",
        data: { text: "Joint Secretaries:", level: 4 },
      },
      {
        id: "7atWhKKJyb",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Sadhan Adlakha",
            "Prajin Jonche",
            "Mohammed Azad",
            "Ayesha Maryum",
          ],
        },
      },
      {
        id: "-RoIinhnS3",
        type: "header",
        data: { text: "Executive members:", level: 4 },
      },
      {
        id: "TkH-G1-ZOp",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Vivek Singh",
            "Pramond Nepal",
            "Aakash Taduri",
            "Kousalya Dwarapudi",
          ],
        },
      },
      {
        id: "_CG5Coh1jT",
        type: "header",
        data: { text: "List of events&nbsp;:", level: 4 },
      },
      {
        id: "AI_JGI4_eu",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Inaugural/ freshers event (nov)&nbsp;",
            "Sports meet&nbsp;(nov)&nbsp;",
            "Classes for foreign students (starting of the 2nd&nbsp;sem January)&nbsp;",
            "Inter Nit Foreign students meet(feb)&nbsp;",
            "Cultural&nbsp;Programme(jan)&nbsp;",
            "Entrepreneurship event&nbsp;(feb)&nbsp;",
            "Food festival(march)&nbsp;",
            "Quiz, debate and essay competitions( in between events whenever we get some gaps)",
          ],
        },
      },
    ],
    language: "En",
    _id: "61c598c11725251ae9cf778b",
    time: 1640339831474,
    version: "2.22.2",
    title: "FISA",
    url: "/sw/FISA",
    path: "/sw",
    createdAt: "2021-12-24T09:54:09.904Z",
    updatedAt: "2021-12-24T09:54:09.904Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "gKA8T6vIXu", type: "header", data: { text: "IEEE", level: 4 } },
      {
        id: "zIhaTYZW6p",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "dcaPLAOE1B",
        type: "paragraph",
        data: {
          text: '<a href="https://www.ieeesbnitw.org/">https://www.ieeesbnitw.org/</a>',
        },
      },
    ],
    language: "En",
    _id: "61c5992e1725251ae9cf778d",
    time: 1640339940042,
    version: "2.22.2",
    title: "IEEE",
    url: "/sw/ieee",
    path: "/sw",
    createdAt: "2021-12-24T09:55:58.466Z",
    updatedAt: "2021-12-24T09:55:58.466Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9Q0tQ-ymOL",
        type: "paragraph",
        data: { text: "National Cadet Corps (NCC)" },
      },
      {
        id: "a-J70FIb6b",
        type: "paragraph",
        data: {
          text: "NCC aims at inculcating a sense of patriotism and national pride in students. In addition it helps in physical fitness and also promotes all round personality in the students. Adequate facilities exist in the Institute for regular parades firing, training “obstacle course” and organizing camps. It offers 1 credit course in I and II semesters of the B.Tech.",
        },
      },
      {
        id: "jud7zz36yF",
        type: "paragraph",
        data: {
          text: 'N.C.C. Officer :&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16238/">&nbsp;Dr. M Udaya Bhasker</a>',
        },
      },
      {
        id: "1YkH42OuM1",
        type: "paragraph",
        data: { text: "National Social Service (NSS)" },
      },
      {
        id: "Mb8r73xSgZ",
        type: "paragraph",
        data: {
          text: "It aims at creating social awareness and also imparts human values in students. Its activities include protection of the environment and social services. It offers 1 credit course in I and II semesters of the B.Tech.",
        },
      },
      {
        id: "jMrlE7DPs9",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Name", "Designation", "Contact number"],
            [
              '<a href="https://www.nitw.ac.in/faculty/id/16421/" target="_blank">Dr. G Siva Kumar</a>',
              "Program officer cum Co-ordinator",
              '<a href="tel:8332969708" target="_blank">8332969295</a>',
            ],
            [
              '<a href="https://www.nitw.ac.in/faculty/id/17030/" target="_blank">Dr. K Uday Kumar</a>',
              "Program officer",
              '<a href="tel:8332969478" target="_blank">9940107507</a>',
            ],
          ],
        },
      },
      {
        id: "4OwzXq5iv3",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Click_here_to_view_2021-11-24-15-28-20.pdf`>NSS - NIT WARANGAL - COMPELETE REPORT</a>',
          level: 4,
        },
      },
      {
        id: "0nlKfsslm8",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Click_here_to_view_2021-11-24-15-28-20.pdf`>Click here to view</a>',
        },
      },
    ],
    language: "En",
    _id: "61c599c81725251ae9cf7791",
    time: 1641378851439,
    version: "2.22.2",
    title: "NCC & NSS",
    url: "/sw/nccnss",
    path: "/sw",
    createdAt: "2021-12-24T09:58:32.516Z",
    updatedAt: "2022-01-05T10:32:27.643Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "PgjLC0Pjz3",
        type: "header",
        data: { text: "SC/ST CELL@ NIT WARANGAL", level: 4 },
      },
      {
        id: "1l0V9QdPbR",
        type: "paragraph",
        data: {
          text: "The SC/ST Cell, a statutory body has been constituted by the Ministry of Human Resource Management (MHRD), Government of India (GOI) in the erstwhile Regional Engineering College (REC) Warangal to safeguard the interests of the SC/ST students and employees of the Institute as per the instructions of MHRD, GOI from time to time.",
        },
      },
      {
        id: "1I17G9fwIP",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/student-welfare/womens-cell/sc-st-cell/#Cell%20Functions">Cell Functions</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/student-welfare/womens-cell/sc-st-cell/#Programs%20Organized">Programs Organized</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/student-welfare/womens-cell/sc-st-cell/#gallery">Gallery</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/student-welfare/womens-cell/sc-st-cell/#SC/ST%20Cell%20Coordinator">SC/ST Cell Coordinator</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/Special%20Cells/GATE%202014%20RANKS%20(1)%20(1).pdf" target="_blank">GATE Ranks Secured by NITW Students Through GATE Coaching funded by SC/ST subplan</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/component/content/article/feewaiver/">Circular of Tuition fee waiver for SC / ST Students</a>&nbsp;',
          ],
        },
      },
      {
        id: "cwnv0-ZrGi",
        type: "header",
        data: { text: "The Cell Functions:", level: 4 },
      },
      {
        id: "s5OcL23pVx",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "As a Grievances Redressal Cell for the grievances of SC/ST students and employees and renders them necessary help in solving their academic as well as administrative problems.&nbsp;",
            "It functions to promote higher education among the SC/ST or weaker communities that are suffering from economic, social and educational deprivations.&nbsp;",
            "It continuously monitors and evaluates the reservation policies and other programs intended for SC/STs by the GOI for their effective implementation at NIT Warangal.&nbsp;",
            "It suggests the follow-up measures to the administration of the Institute for achieving the objectives and targets laid down by MHRD, GOI for the empowerment of SC/STs.",
          ],
        },
      },
      {
        id: "49KbqhkTpp",
        type: "header",
        data: {
          text: "PROGRAMS ORGANIZED BY SC/ST CELL THROUGH SC/ST SUBPLAN:",
          level: 4,
        },
      },
      {
        id: "dicpuvx2j1",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Organized Guest Lectures on&nbsp;Ambedkar and his vision for Dalit education&nbsp;through SC/ST Sub plan, MHRD, GOI for the SC/ST students and employees of NITW on 06-12-2014 on the occasion of Mahaparinirvan Diwas of Dr. BR Ambedkar.&nbsp;",
            "GATE-2015 coaching classes&nbsp;for the final SC/ST B.Tech &amp; PG Students of NIT Warangal during 08-12-2014 to 31-01-2015 through SC/ST Sub plan, MHRD, GOI.&nbsp;",
            "Campus Placement Training Program&nbsp;for the SC/ST students of final year B.Tech, M.Tech and other PG Students of NIT Warangal during Nov-Dec,2014 and Jan-March, 2015through SC/ST Sub plan, MHRD, GOI&nbsp;",
            "Organized Guest lectures on&nbsp;Dr. B. R. Ambedkar and His Contribution to the Nation&nbsp;through SC/ST Sub plan, MHRD, GOI for the SC/ST students and employees of NITW on 14-04-2015 on the occasion of 125th birth anniversary celebrations of Dr. BR. Ambedkar.&nbsp;",
            "GATE-2015 Coaching Classes&nbsp;by SC/ST Cell, NITW from 8th December,2014 through SC/ST Subplan, NIT Warangal&nbsp;",
            "Communication Skill Development&nbsp;Classes by SC/ST Cell, NITW for the first year students of UG, PG &amp; Ph.D from 24th November, 2014 through SC/ST Subplan, NIT Warangal.&nbsp;",
            "Guest Lectures on&nbsp;‘Ambedkar and his Vision for Dalit Education’&nbsp;by SC/ST Cell, NITW on 06-12-2014 through SC/ST Subplan, NIT Warangal&nbsp;",
            "Organized an inspiration lecture on&nbsp;EXCELLENCE AND BETTER PERFORMANCE&nbsp;by Mr. Naresh Dubbudu, IIM Bangalore and Stanford University, USA, a 2004 alumnus and Gold medalist of NIT Warangal on 7th November, 2014 for all the SC/ST and other Students of NIT Warangal-&nbsp;Most of the Students got realized from the Lecture to rededicate for the cause of joining in NIT Warangal.&nbsp;",
            "Proposed&nbsp;Dr. B.R. AMBEDKAR LEARNING CENTRE&nbsp;with all the sophisticated amenities for organizing various SC/ST related academic and extra academic programs through SC/ST Sub plan, MHRD, GOI –&nbsp;The proposal was approved by the BOG and the preliminary estimates are to be placed before BWC for approval.&nbsp;",
            "Conducted&nbsp;CAMPUS PLACEMENT TRAINING PROGRAM (CPTP)&nbsp;for the final year B.Tech, M.Tech and other PG Students of NIT Warangal during 02-15 December, 2013 through SC/ST Sub plan, MHRD, GOI –&nbsp;The percentage of SC/ST students placed into public and private sector units got improved.&nbsp;",
            "Conducted&nbsp;GATE-2014&nbsp;coaching classes for the final B.Tech SC/ST and other Students of NIT Warangal during 02-12-2013 to 31-01-2014 through SC/ST Sub plan, MHRD, GOI-&nbsp;Quite a good number of the students secured better ranks.&nbsp;",
            "Conducted a two day workshop on&nbsp;RESEARCH METHODOLOGY AND SCHOLARLY WRITING SKILLS&nbsp;(RMSWS) for the SC/ST and other research scholars of NIT Warangal through SC/ST Sub plan, MHRD, GOI –&nbsp;There was an overwhelming response from the participants and everybody has derived maximum benefit out of the workshop.&nbsp;",
            "Conducted&nbsp;SOFT SKILL DEVELOPMENT TRAINING PROGRAM&nbsp;for the non-teaching staff of the Institute 10-12-2013 to 21-02-2014 through SC/ST Sub plan, MHRD, GOI –&nbsp;The program could impart reading and writing skills to all the non-teaching staff of the Institute.&nbsp;",
            "conducted Guest Lectures on&nbsp;Dr. B.R. AMBEDKAR’S THOUGHTS AND THEIR RELEVANCE IN THE PRESENT DAY CONTEXT&nbsp;on 14-04-2014 for all the SC/ST Students and staff of NIT Warangal through SC/ST Sub plan, MHRD, GOI-&nbsp;The program could provide minute details of Ambedkar’s thoughts embedded in Indian constitution for the upliftment of weaker sections to all the audience of the program.&nbsp;",
            "Many more programs are in pipeline………….",
          ],
        },
      },
      {
        id: "ZptU5suYjd",
        type: "header",
        data: { text: "ESSAY WRITING AND ELOCUTION COMPETITIONS:", level: 4 },
      },
      {
        id: "0_HLjPr0rb",
        type: "paragraph",
        data: {
          text: "On the occasion of 125th&nbsp;birth anniversary celebrations-2015 of Dr. B. R. Ambedkar, the SC/ST Cell, NITW has organized Essay writing and Elocution competitions for the SC/ST Students and Non-teaching staff of the Institute.&nbsp;",
        },
      },
      {
        id: "KSSqn1BHnm",
        type: "header",
        data: {
          text: "For Students of B.Tech, all PG &amp; Ph.D:&nbsp;",
          level: 4,
        },
      },
      {
        id: "KlBQ-AhxwT",
        type: "list",
        data: {
          style: "unordered",
          items: ["Essay Writing Competition:&nbsp;"],
        },
      },
      {
        id: "gzcIqB-mac",
        type: "paragraph",
        data: {
          text: "TOPIC:&nbsp; AMBEDKAR’S VISION ON THE EMPOWERMENT OF DALIT EDUCATION&nbsp;",
        },
      },
      {
        id: "ePpXIxzpJz",
        type: "list",
        data: { style: "unordered", items: ["Elocution Competition:&nbsp;"] },
      },
      {
        id: "7JXMLmOxMz",
        type: "paragraph",
        data: {
          text: "TOPIC:&nbsp;&nbsp;Is Education only for Employment or Social Democracy?",
        },
      },
      {
        id: "Jy91pa7tCI",
        type: "header",
        data: { text: "For Non-teaching Staff:&nbsp;", level: 4 },
      },
      {
        id: "B33WN-pOSn",
        type: "list",
        data: {
          style: "unordered",
          items: ["Essay Writing Competition:&nbsp;"],
        },
      },
      {
        id: "F2i_9l0g3T",
        type: "paragraph",
        data: {
          text: "TOPIC:&nbsp; RELEVANCE OF AMBEDKAR IN CONTEMPORARY INDIA&nbsp;",
        },
      },
      {
        id: "tl1OO4RND5",
        type: "list",
        data: { style: "unordered", items: ["Elocution Competition :&nbsp;"] },
      },
      {
        id: "0RPSS1885b",
        type: "paragraph",
        data: { text: "TOPIC:&nbsp; VISION OF AMBEDKAR FOR DALIT" },
      },
      {
        id: "-JRsO4VaXq",
        type: "paragraph",
        data: { text: "Professor In-charge, &nbsp;SC/ST&nbsp;Cell&nbsp;" },
      },
      {
        id: "pXqvRpCi9U",
        type: "paragraph",
        data: {
          text: '1. &nbsp;Coordinator, SC/ST Cell &amp; Liaison Officer for SC/ST :&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16305/">Dr. J. Ravi Kumar</a>(9490164799)',
        },
      },
    ],
    language: "En",
    _id: "61c59a061725251ae9cf7793",
    time: 1640340155717,
    version: "2.22.2",
    title: "SC/ST CELL",
    url: "/sw/scstcell",
    path: "/sw",
    createdAt: "2021-12-24T09:59:34.158Z",
    updatedAt: "2021-12-24T09:59:34.158Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "S1WGQXeGuY",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "S.No",
              "Cell Name",
              '<editorjs-style style="display: block;\nwhite-space: pre;">Coordinators\n/Chairperson</editorjs-style>',
              "Members",
              '<editorjs-style style="display: block;\nwhite-space: pre;">Contact Number</editorjs-style>',
            ],
            [
              "1",
              "Women's Protection Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16292/"><editorjs-style style="display: block;\nwhite-space: pre;\n\n">Dr. B. Lakshmi</editorjs-style></a>&nbsp;<editorjs-style style="display: block;\nwhite-space: pre;">Professor&nbsp;</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;\n">Dept. of Electronics &amp; communication Engineering</editorjs-style>',
              '&nbsp; 1)&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16264/" target="_blank">Dr. P. Ravi Kumar</a>&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Dean SW&nbsp;&nbsp;&nbsp; 2)&nbsp;&nbsp;<a href="https://www.nitw.ac.in/main/home/" target="_blank">Sri S. Goverdhan Rao</a>,&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Registrar3)   Sri S. Shoury Reddy,                    Executive   Director,                           Balavikas&nbsp; 4)&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16409/">Dr. K. Padma</a>,&nbsp;&nbsp; &nbsp; &nbsp; Dept. of SOM&nbsp;5)&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16335/">Dr. K. Kadambari</a>,&nbsp; &nbsp; &nbsp; &nbsp;Dept. of CSE&nbsp;&nbsp;&nbsp; &nbsp;',
              "&nbsp;8332969705",
            ],
            [
              "2",
              "OBC Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16300/"><editorjs-style style="display: block;\nwhite-space: pre;\n\n">Dr. (Smt.) V. Rama</editorjs-style></a>Associate ProfessorDept. of Electronics &amp; communication Engineering',
              "",
              "9440762744",
            ],
            [
              "3&nbsp;",
              "SC/ST Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16305/"><editorjs-style style="display: block;\nwhite-space: pre;\n">Dr. J. Ravi Kumar</editorjs-style></a>Associate ProfessorDepartment of Mathematics',
              "",
              "09490164793",
            ],
            [
              "4",
              "Minority Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16876/"><editorjs-style style="display: block;\nwhite-space: pre;\n">Dr. Arif Ali Baig Moghal</editorjs-style></a>Associate ProfessorDept. Civil Engineering',
              "",
              "8702462133",
            ],
            [
              "5",
              "Differently Abled People Cell",
              '<a href="https://www.nitw.ac.in/faculty/id/16300/" target="_blank"><editorjs-style style="display: block;\nwhite-space: pre;\n\n">Ms. V. Rama</editorjs-style></a>Assistant ProfessorDept. Electronics &amp; communication Engineering',
              "",
              '<editorjs-style style="display: block;\nwhite-space: pre;\n">9440762744</editorjs-style><a href="mailto:dapcell@nitw.ac.in">dapcell@nitw.ac.in</a>',
            ],
          ],
        },
      },
      { id: "PL1qZXuCb7", type: "paragraph", data: { text: "<br>" } },
      { id: "ESloP5hpej", type: "paragraph", data: { text: "<br>" } },
      { id: "P2J0cLQNzy", type: "paragraph", data: { text: "<br>" } },
      { id: "PDFHUmcfGr", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61c59b5d1725251ae9cf7795",
    time: 1641454733683,
    version: "2.22.2",
    title: "Special Cell",
    url: "/sw/specialcell",
    path: "/sw",
    createdAt: "2021-12-24T10:05:17.162Z",
    updatedAt: "2022-01-06T07:37:03.361Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "mFzubEOnOi",
        type: "paragraph",
        data: {
          text: 'The&nbsp;<a href="https://www.nitw.ac.in/department/pe/">Department of Physical Education</a>&nbsp;provides training facilities for all the students and staff of this Institute. It has excellent infrastructure facilities for both outdoor and indoor games and sports. The outdoor games includes Volley Ball, Foot Ball, Cricket, Tennis, Ball Badminton, Kabaddi, Kho-Kho, Hand Ball, Hockey, Tennikoit, Basket Ball (concrete) and Tennis. Flood Light facility is provided to all the outdoor games. The sports ground accommodates a 400m standard track, an excellent pavilion and facilities for all athletic events.The Indoor Games Complex (IGC) provides the following facilities: &nbsp;&nbsp; &nbsp;&nbsp;',
        },
      },
      {
        id: "CUkJEKv85P",
        type: "paragraph",
        data: {
          text: "Chess, Caroms, Gymnastics, Shuttle badminton with wooden flooring, Table Tennis, Weight Lifting and 16 &amp; 12 stations multi-Gyms.",
        },
      },
      {
        id: "DJoamS4ade",
        type: "paragraph",
        data: { text: "Activities of the department include :&nbsp;" },
      },
      {
        id: "nYRV0QUNbu",
        type: "paragraph",
        data: {
          text: "Organizing intramural and extramural competitions and preparing the students for the same. &nbsp;&nbsp; &nbsp;",
        },
      },
      {
        id: "c8q6pCikAd",
        type: "paragraph",
        data: {
          text: "Encouraging student participation in inter-collegiate, Inter University, Inter-NIT and other open tournaments in the country.",
        },
      },
      {
        id: "wc9fL8_Shf",
        type: "paragraph",
        data: {
          text: "Organizing Inter-Collegiate tournaments, Inter University, Inter-National Institute of Technology tournaments coaching camps and open tournaments in various sports and games.",
        },
      },
      {
        id: "5j0yCZ66Ma",
        type: "paragraph",
        data: {
          text: "Organizing tournaments for Teaching and Non-Teaching staff. &nbsp;&nbsp; &nbsp;",
        },
      },
      {
        id: "anXkU3KLQL",
        type: "paragraph",
        data: { text: "<b>Physical Directors :</b>" },
      },
      {
        id: "Jz2wRv1Z_K",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "<b>R. Dayanithi, Ph.D.</b>",
            "<b>P.&nbsp;Madhusudan Reddy, Ph.D.</b>",
            "<b>P. Ravi Kumar, Ph.D.&nbsp;</b>",
          ],
        },
      },
    ],
    language: "En",
    _id: "61c59b811725251ae9cf7797",
    time: 1641378662072,
    version: "2.22.2",
    title: "Sports",
    url: "/sw/Sports",
    path: "/sw",
    createdAt: "2021-12-24T10:05:53.497Z",
    updatedAt: "2022-01-05T10:29:18.269Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "iyVR4UI0Gt",
        type: "paragraph",
        data: {
          text: '<a href="http://www.springspree.in/">SpringSpree</a>&nbsp;was started in the year 1978 by the students of&nbsp;<a href="http://en.wikipedia.org/wiki/National_Institute_of_Technology,_Warangal">National Institute of Technology, Warangal</a>. &nbsp;It was an intercollegiate annual sports and cultural event held between January and March every year. Students came up with the idea of holding a small festival, which would lay down a platform for the students to showcase their skills in the areas of sports, athletics and cultural activities. Additionally, the festival was to unify a diverse student body by celebrating the culture of the regions to which they belonged along with the culture of India as a whole. It was the first college festival in southern India and was conducted every year till the end of the 90s. SpringSpree was not conducted for a few years in the early 2000s. Its revival was gradual with small fests from clubs finally giving rise to a mini cultural festival called Elysium in 2006. In 2007, SpringSpree was brought back and has since been an annual event.',
        },
      },
      {
        id: "Ui4bw_KXpZ",
        type: "paragraph",
        data: {
          text: "SpringSpree, a three day annual cultural festival, showcases 55+ events in the fields of art, literature, drama, dance, music, quizzing, photography and painting. Including spotlight events like Fashion Show, Bboying Competition, Rock Music Competition, Model United Nations and Minute Made. A number of arts and skill oriented workshops are also held to give a jump start to the enthusiastic participants. Apart from these, SpringSpree holds various attractions like Amazing Race, Lounge, The One, The Haunted House and many others. To cater to the young entrepreneurs there are events involving business planning and stock marketing.",
        },
      },
      {
        id: "bw7bGzwVLE",
        type: "paragraph",
        data: {
          text: 'SpringSpree also conducts concerts during the three days of Spree, known Pronites. Famous musicians are invited to close each day of the fest with a bang. In the years prior, National Institute of Technology, Warangal, has seen the likes of&nbsp;Pt. Hariprasad Chaurasia, Pt. Jasraj,&nbsp;<a href="http://en.wikipedia.org/wiki/Naresh_Iyer">Naresh Iyer</a>&nbsp;and&nbsp;<a href="http://en.wikipedia.org/wiki/Stephen_Devassy">Stephen Devassy</a>&nbsp;to name a few.&nbsp;Indian rock bands like&nbsp;<a href="http://en.wikipedia.org/wiki/Parikrama_(band)">Parikrama</a>, Thermal And a Quarter, ThemClones, Drum Cafe and&nbsp;others have also performed.',
        },
      },
      {
        id: "WgME-WMw6q",
        type: "paragraph",
        data: {
          text: "SpringSpree is a fest conducted by the students of National Institute of Technology, Warangal on a non-profit basis. Every year a core committee of students is chosen by the student council of the college to oversee the smooth running of the fest. They are aided by the student council themselves and a faculty advisor chosen by the administration to assist them in their tasks. The core committee looks after the all important aspects of the fest like Event Conduction, Sponsorship, Publicity, Logistics, Hospitality, Design, Decoration, Accounting and Prizes. Every member of the committee is dedicated to either of these departments. The synchronization between all the working committees is taken care by the student coordinator of the festival. To assist the core committee, a sub-core committee and a work force body is chosen forming an apex structure contributing to a students working team. The success of the festival heavily depends on the efforts and idea of the entire students working team.",
        },
      },
      {
        id: "iVjOpWGtjq",
        type: "paragraph",
        data: {
          text: 'The Official Website for the festival is&nbsp;“<a href="http://www.springspree.in/" target="_blank">www.springspree.in</a>”',
        },
      },
      {
        id: "aLFdVbvNaT",
        type: "paragraph",
        data: {
          text: 'Faculty Advisor Spring Spree - 2021&nbsp; :&nbsp; &nbsp;<a href="https://www.nitw.ac.in/faculty/id/16393/">-</a>',
        },
      },
    ],
    language: "En",
    _id: "61c59baa1725251ae9cf7799",
    time: 1640340574615,
    version: "2.22.2",
    title: "SPRING SPREE",
    url: "/sw/SPRINGSPREE",
    path: "/sw",
    createdAt: "2021-12-24T10:06:34.349Z",
    updatedAt: "2021-12-24T10:06:34.349Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "AdkB6dJ_yS",
        type: "paragraph",
        data: {
          text: '<a href="http://www.technozion.org/land/" target="_blank">Technozion</a>&nbsp;a three day annual Technical Festival organized aims to provide a platform for students across the globe to assemble, interact, share and gain knowledge in various fields of science and technology. Through this Symposium we seek to provide a platform for budding engineers and scientists from all over the world to display their skills in a plethora of events designed to exercise their minds, and to further a spirit of technology. From its inception in 2006, Technozion has grown in strength and in numbers, and is now considered to be amongst the second biggest technical festivals in India. One of the hallmarks of Technozion has always been the presence of notable luminaries gracing the Institute portals, delivering inspiring lectures, video conferencing and conducting interactive workshops for the benefit of the student community.',
        },
      },
      {
        id: "1QaI9cRONW",
        type: "paragraph",
        data: {
          text: "From its inception in 2006, Technozion has grown in strength and in numbers, and is now considered to be amongst the second biggest technical festivals in India. One of the hallmarks of Technozion has always been the presence of notable luminaries gracing the Institute portals, delivering inspiring lectures, video conferencing and conducting interactive workshops for the benefit of the student community.",
        },
      },
      {
        id: "4oG-iZKFwb",
        type: "paragraph",
        data: {
          text: 'For more details visit Technozion official website &nbsp;<a href="http://www.technozion.org/" target="_blank">www.technozion.org</a>',
        },
      },
      {
        id: "dL7xTQKE7E",
        type: "paragraph",
        data: {
          text: 'Contact Technozion, NIT Warangal on&nbsp;<a href="mailto:info@technozion.org">info@technozion.org</a>',
        },
      },
      {
        id: "FQBL0QBhCc",
        type: "paragraph",
        data: {
          text: 'Faculty Advisor, Technozion – 2021:&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16297/">-</a>',
        },
      },
    ],
    language: "En",
    _id: "61c59bd91725251ae9cf779b",
    time: 1640340623367,
    version: "2.22.2",
    title: "Technozion",
    url: "/sw/Technozion",
    path: "/sw",
    createdAt: "2021-12-24T10:07:21.809Z",
    updatedAt: "2021-12-24T10:07:21.809Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "tMd0RjKxu6",
        type: "paragraph",
        data: {
          text: "The Web and Software Development Cell of National Institute of Technology Warangal is the brain and brawn behind all things online in the college. This is a body which acts as a central hub consisting of highly creative and dedicated workforce trying day and night to bringing the college into the workings of the digital world. This is done by modernizing an array of archaic practices into efficient and streamlined hubs based on the internet.",
        },
      },
      {
        id: "nsuo_nesli",
        type: "paragraph",
        data: {
          text: "The Current version of the institute Website has been created and developed by the following institute website team in WSDC:",
        },
      },
    ],
    language: "En",
    _id: "61c59c0d1725251ae9cf779d",
    time: 1640340675189,
    version: "2.22.2",
    title: "Institute Website Team",
    url: "/sw/institutewebsiteteam",
    path: "/sw",
    createdAt: "2021-12-24T10:08:13.645Z",
    updatedAt: "2021-12-24T10:08:13.645Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "NYl3o4OuUp",
        type: "header",
        data: { text: "OBJECTIVES OF STUDENT COUNCIL", level: 6 },
      },
      {
        id: "kOLDtGxNrW",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "To foster and coordinate all students activities in the Institute.",
            "To identify student issues, and promote discussion on them.",
            "To develop well informed participative student community life, and create societal awareness.&nbsp;",
          ],
        },
      },
      {
        id: "Fu8RK48lGm",
        type: "header",
        data: { text: "COMPOSITION", level: 6 },
      },
      {
        id: "J3ocUplybY",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Director will be Chief Patron and Dean, Student Welfare will be the patron of the Student Council. The Student Council will have an Executive Committee and Class/Section Representatives.&nbsp; The Executive Committee shall consist of the following Secretaries and Deputy Secretaries, who are elected by the electoral colleges mentioned against each position.",
        },
      },
      {
        id: "tBS4riqWOp",
        type: "header",
        data: { text: "EXECUTIVE COMMITTEE", level: 6 },
      },
      {
        id: "PWoln8RtgJ",
        type: "list",
        data: { style: "ordered", items: ["Secretaries<br><br>"] },
      },
      {
        id: "6y-scTgXkx",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Secretary, Academic",
            "Secretary, Hostels",
            "Secretary, Sports",
            "Secretary, LAN, Wi-Fi , Library &amp; other unassigned miscellaneous matters",
            "Secretary, PG (Engineering)",
            "Secretary, PG (Sciences, Management &amp; MCA)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
            "Secretary, Girls – 2 (1 UG &amp;1 PG)<br><br><br>",
          ],
        },
      },
      {
        id: "HCQdiEQ-q5",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Deputy Secretaries",
            "Dy. Secretary, Academic",
            "Dy. Secretary, Hostels",
            "Dy. Secretary, Sports",
            "Dy. Secretary, LAN, Wi-Fi , Library &amp; other unassigned miscellaneous matters",
            "Dy. Secretary, Girls (UG)",
          ],
        },
      },
      {
        id: "JhpuLrxrX7",
        type: "header",
        data: {
          text: "Eligibility Criteria &amp;Election of Secretaries and Deputy Secretaries",
          level: 5,
        },
      },
      {
        id: "nI1Jj8DGbM",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Position", "Eligibility Criteria", "Electoral College"],
            [
              "Secretaries (Academic, Hostels, Sports, LAN+Wi-fi+ Library+ others)",
              "i) B.Tech final year students including CRs of final year B.Tech",
              "CRs of II, III and IV B.Tech classes",
            ],
            ["Secretaries (Hostels)", "", "All CRs &amp; PG Representatives"],
            [
              "Secretary PG (Engineering)",
              "M.TechFinal year students",
              "PG representatives   from all engineering departments",
            ],
            [
              "Secretary PG (Sciences, MBA &amp; MCA)",
              "All the students of M.Sc, M.Sc (Tech), MBA &amp; MCA Final yeari) Minimum CGPA 7.0ii) No active backlogsiii) Should not haveinvolved/indulged in any acts of indiscipline",
              "PG representatives   from all science departmentsand MBA &amp; MCA Departments",
            ],
            [
              "Deputy Secretary(Academic, Hostel, Sports, LAN+Wi-fi+ Library)",
              "III B.Tech students including of CRs of III B.Tech",
              "CRs of II, III and IV B.Tech classes",
            ],
            [
              "Secretary Girls (UG)",
              "Girl Student of B.Tech final year",
              "Selection by Interview",
            ],
            [
              "Secretary Girls (PG)",
              "All the girls students of M.Tech, M.Sc, M.Sc (Tech), MBA &amp; MCA Final year",
              "Selection by Interview",
            ],
          ],
        },
      },
      {
        id: "3Goky_nNg0",
        type: "header",
        data: { text: "CLASS REPRESENTATIVES (CR)", level: 5 },
      },
      {
        id: "C5Yt7A04Cl",
        type: "paragraph",
        data: {
          text: "Class representatives for each class shall be elected by all the students of that class, if there is only one section.&nbsp; If there is more than one section in a given year of a particular branch, each section shall elect its class representative.",
        },
      },
      {
        id: "Ji8AvyfQ12",
        type: "paragraph",
        data: {
          text: "Candidate should have a minimum CGPA of 6.5 and in no event have any active academic backlogs.",
        },
      },
      {
        id: "miU7GGMopD",
        type: "paragraph",
        data: { text: "1.One CR from each section of First Year B.Tech." },
      },
      {
        id: "4gqMT9NF9F",
        type: "paragraph",
        data: {
          text: "2.One CR from each section of Second Year B.Tech.&nbsp;",
        },
      },
      {
        id: "6ApLqXC1QI",
        type: "paragraph",
        data: {
          text: "3.One CR from each section of Third Year B.Tech.&nbsp;",
        },
      },
      {
        id: "PyC33XdRh6",
        type: "paragraph",
        data: {
          text: "4.One CR from each section of Final Year B.Tech.&nbsp;",
        },
      },
      {
        id: "6VpqjxxU0S",
        type: "paragraph",
        data: {
          text: "The number of Class Representatives from different branches for M.Tech. students shall be as follows:Civil Engineering",
        },
      },
      {
        id: "XNR0J2ofIJ",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "Civil Engineering",
              "2(1 from ES,CTM,WRE,EE&amp; 1 from GTE,TE, ,RS&amp;GIS)",
            ],
            ["Electrical Engineering", "1"],
          ],
        },
      },
      {
        id: "T2it_yR90R",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "Mechanical Engineering",
              "2(1 from TE,ME, MD,CIM, &amp;&nbsp;1 from AE,MSED,AM)",
            ],
            ["Electronics &amp; Communication Engineering", "1"],
            ["Metallurgical &amp; Materials Science", "1"],
            ["Chemical Engineering", "1"],
            ["Computer Science &amp; Engineering", "1"],
          ],
        },
      },
      {
        id: "zHyK6XeUOp",
        type: "paragraph",
        data: {
          text: "6. One PG representative&nbsp;&nbsp; from each Science Department.",
        },
      },
      {
        id: "YXX-RIZuzL",
        type: "paragraph",
        data: { text: "7. One PG representative&nbsp;&nbsp; M.C.A. course." },
      },
      {
        id: "Qu3hHuFJDZ",
        type: "paragraph",
        data: {
          text: "8. One PG representative&nbsp;&nbsp; &nbsp;from M.B.A. course.",
        },
      },
      {
        id: "RBb_Z_F7Wg",
        type: "paragraph",
        data: {
          text: "9. One Student is to be nominated by the Director to represent the foreign students.&nbsp;&nbsp;",
        },
      },
      {
        id: "fELaP96gjs",
        type: "paragraph",
        data: {
          text: "Note: Research Scholars are not part of the Student Council.",
        },
      },
      {
        id: "K0lvUP5gLX",
        type: "header",
        data: { text: "DUTIES AND RESPONSIBILITIES OF THE COUNCIL", level: 5 },
      },
      {
        id: "LH_q9YjtVJ",
        type: "paragraph",
        data: { text: "The members of the council shall be assisting in:" },
      },
      {
        id: "Re4D22Vv2R",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Smooth conduct of the Institute\n     Annual Day every year.",
            "Smooth conduct of Foundation\n     Day every year.",
            "Smooth conduct of Fresher’s day\n     every year.",
            "Prevention of ragging on the\n     campus through counseling senior students, helping the administration\n     whenever necessary.",
            "Bring to the notice of the\n     administration the particular requirements for improving the student\n     amenities on the campus.",
            "Helping the administration in\n     smooth conduct of student activities on the campus.",
            "Guiding the junior and needy\n     students to improve their technical, organizational and managerial skills\n     by organizing seminars / symposia / workshops etc.",
            "Encouraging innovative and\n     creative skills of the undergraduate and post-graduate students.",
            "Organizing the Sramadan\n     Programs on the campus to improve the cleanliness and greenery.",
            "Any activity to improve the\n     knowledge and skills of the campus students.",
            "Maintenance of peace and\n     harmony among campus community in general and student community in\n     particular.",
            "Arrange expert lectures from\n     eminent persons for career and personality development.",
          ],
        },
      },
      {
        id: "srCtSM4mcV",
        type: "header",
        data: { text: "CODE OF CONDUCT OF CANDIDATES", level: 6 },
      },
      {
        id: "QEyHy36-dY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "No candidate shall create mutual hatred or cause tension between different castes and communities, religious or linguistic or between any group(s) of students.",
          ],
        },
      },
      {
        id: "9d5qEM2WBt",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Candidates shall refrain from criticism of all aspects of private life not connected with the public activities of other candidates.",
          ],
        },
      },
      {
        id: "BtJg7Fhoew",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "No candidate shall be permitted to make use of&nbsp;&nbsp; print material for the purposes of canvassing.",
          ],
        },
      },
      {
        id: "AP0Zsp-zLA",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "No candidate shall be permitted to conduct/organize procession or public meeting.",
          ],
        },
      },
      {
        id: "QC2l95jK_y",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "No candidate shall, nor shall his/her supporters, deface or cause any destruction to any property of the Institute.",
          ],
        },
      },
      {
        id: "p5Xx2QAncs",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "All candidates shall be prohibited from indulging or abetting activities which are considered to be corrupt practices and offences.&nbsp;",
          ],
        },
      },
      {
        id: "n-JFPnn5yD",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "In general, all candidates must obey the code of conduct of candidates mentioned under 6.7 of recommendations of Mr. J.M.Lyngdoh committee report “Recommendations and suggestions relating to Students’ Union Elections”.",
          ],
        },
      },
      {
        id: "PvzuL7IB-_",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "All activities of students and candidates will come under the scrutiny of recommendations of Mr. J.M. Lyngdohcommittee&nbsp; report&nbsp; “Recommendations&nbsp; and suggestions relating to Students’ Union Elections”.",
          ],
        },
      },
    ],
    language: "En",
    _id: "61c59ef41725251ae9cf77a1",
    time: 1641380600244,
    version: "2.22.2",
    title: "Student Council",
    url: "/sc/StudentCouncil",
    path: "/sc",
    createdAt: "2021-12-24T10:20:36.040Z",
    updatedAt: "2022-01-05T11:01:36.447Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "TnFD3uQ_eQ",
        type: "paragraph",
        data: {
          text: "Students Council is working hard for the welfare of student community. Taking into the consideration of present requirements of students, we have initiated the following activities:-",
        },
      },
      {
        id: "_pFXmhGD2X",
        type: "header",
        data: {
          text: "Targets To Be Completed During This Year Term",
          level: 6,
        },
      },
      {
        id: "I7K4CIuR55",
        type: "paragraph",
        data: {
          text: "1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Online Registrations",
        },
      },
      {
        id: "cnSK6vJJ81",
        type: "paragraph",
        data: {
          text: "2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Student Email Ids",
        },
      },
      {
        id: "eBPpW6eQiv",
        type: "paragraph",
        data: {
          text: "3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Website Development,&nbsp;Maintenance&nbsp;And Linking of their &nbsp;Websites",
        },
      },
      {
        id: "YxYOMwHA-i",
        type: "paragraph",
        data: {
          text: "4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Alumni Association And Collection Of Database",
        },
      },
      {
        id: "8R4tePg4EX",
        type: "paragraph",
        data: {
          text: "5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SPORTS FACILITIES",
        },
      },
      {
        id: "of9C1AU51V",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; improving new grounds near the hostels",
        },
      },
      {
        id: "TGNNl4DGR3",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; renovation of the present stadiums",
        },
      },
      {
        id: "rVP1RaFKcX",
        type: "paragraph",
        data: { text: "6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SAC RENOVATION" },
      },
      {
        id: "hwPXGYLtSy",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; sanitary facilities",
        },
      },
      {
        id: "jv11802aqs",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; security tightening",
        },
      },
      {
        id: "_f24FX-yuz",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; facelift",
        },
      },
      {
        id: "I6rVVeQRzg",
        type: "paragraph",
        data: {
          text: "7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PUBLICATIONS",
        },
      },
      {
        id: "UWe5pPB3fb",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; newsletters",
        },
      },
      {
        id: "C9nKooCt0E",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; year book",
        },
      },
      {
        id: "4WqQu0eoBE",
        type: "paragraph",
        data: {
          text: "8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FORUMS(CAREER OPTIONS)",
        },
      },
      {
        id: "QaFGAuZ5Kg",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; gate-forum",
        },
      },
      {
        id: "gs4VZAQ5HA",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; cat-forum",
        },
      },
      {
        id: "i-t-FrW17B",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; civils-forum etc…",
        },
      },
      {
        id: "kLHRo_2LB4",
        type: "paragraph",
        data: { text: "9)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MESSES" },
      },
      {
        id: "0g910mXh5Q",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; improving present conditions",
        },
      },
      {
        id: "Imn2LAtiuk",
        type: "paragraph",
        data: {
          text: "·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; transperancy",
        },
      },
      {
        id: "vilnLxAdxp",
        type: "paragraph",
        data: { text: "10)&nbsp;&nbsp; Dispensary" },
      },
      {
        id: "lPPbSSLECa",
        type: "paragraph",
        data: { text: "11)&nbsp;&nbsp; Activating Clubs And Associations" },
      },
    ],
    language: "En",
    _id: "61c59f1b1725251ae9cf77a3",
    time: 1641380678122,
    version: "2.22.2",
    title: "Targets",
    url: "/sc/targets",
    path: "/sc",
    createdAt: "2021-12-24T10:21:15.556Z",
    updatedAt: "2022-01-05T11:02:54.305Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "qBTowwABVU",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["COMPOSITION", "INCHARGE", "NAME"],
            [
              "CHAIRMAN",
              "Director",
              '<a href="https://www.nitw.ac.in/main/administration/director/">Prof.&nbsp; N. V.&nbsp; Ramana Rao</a>',
            ],
            [
              "STAFF ADVISOR",
              "Dean, Students’ Affairs.",
              '<a href="https://www.nitw.ac.in/faculty/id/16264/" target="_blank">Dr. P. Ravi Kumar</a>',
            ],
          ],
        },
      },
      { id: "BMTTb14GJU", type: "paragraph", data: { text: "<br>" } },
      {
        id: "QSWdIL_QSh",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/elected-ug-students_2020-2021_2021-11-24-15-55-33.pdf`>Click here</a>',
          level: 6,
        },
      },
      {
        id: "nCFVphom7c",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/elected-Pg-students_2020-2021_2021-11-24-15-54-9.pdf`>Click here</a>',
          level: 6,
        },
      },
    ],
    language: "En",
    _id: "61c5a0291725251ae9cf77a8",
    time: 1641380733500,
    version: "2.22.2",
    title: "EXECUTIVE BODY OF STUDENTS' COUNCIL",
    url: "/sc/executivebody",
    path: "/sc",
    createdAt: "2021-12-24T10:25:45.032Z",
    updatedAt: "2022-01-05T11:03:49.681Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "PNSSfFV0g6",
        type: "paragraph",
        data: {
          text: "The STUDENTS’ COUNCIL has nominated the following final year class representatives as the secretaries to works and these secretaries along with the President, students’ council shall act as the working body of the council.",
        },
      },
      {
        id: "XGM6032HTm",
        type: "paragraph",
        data: {
          text: "These secretaries are formed to ensure that all the responsibilities are shared among the council members and to help in the effective functioning of the STUDENTS’ COUNCIL.",
        },
      },
      {
        id: "yriyh6bQWM",
        type: "paragraph",
        data: {
          text: "All the students are requested to contact the council only through the concerned secretaries. The students can feel free to contact the council for any further assistance through the secretary concerned and also report to the council for any negligence of any secretary.",
        },
      },
      {
        id: "haHGzjB55j",
        type: "paragraph",
        data: {
          text: "All the concerned administrative officers are requested to please cooperate with the secretaries.",
        },
      },
      {
        id: "108AGrPn2k",
        type: "paragraph",
        data: {
          text: "These secretaries will be in office until any further orders from STUDENTS’ COUNCIL.",
        },
      },
      {
        id: "dPqZ8QzsdR",
        type: "paragraph",
        data: { text: "Students’ Council" },
      },
      { id: "pUwu7tRcyR", type: "paragraph", data: { text: "9490164983" } },
      {
        id: "0twiqK1Grr",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/elected-ug-students_2020-2021_2021-11-24-15-55-33.pdf`>Click here</a>',
          level: 6,
        },
      },
      {
        id: "8W6caWsHV4",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/elected-Pg-students_2020-2021_2021-11-24-15-54-9.pdf`>Click here</a>',
          level: 6,
        },
      },
    ],
    language: "En",
    _id: "61c5a04b1725251ae9cf77aa",
    time: 1641380778946,
    version: "2.22.2",
    title: "SECRETARIES AND WORKING BODY",
    url: "/sc/swb",
    path: "/sc",
    createdAt: "2021-12-24T10:26:19.423Z",
    updatedAt: "2022-01-05T11:04:35.124Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "ncsiA6bPiM", type: "paragraph", data: { text: "." } },
      {
        id: "cNM8Xcq-mm",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/elected-ug-students_2020-2021_2021-11-24-15-55-33.pdf`>Elected-UG-students  2020-2021</a>&nbsp;-&nbsp;Click here',
          level: 6,
        },
      },
      {
        id: "eFk_eqSEmr",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/elected-Pg-students_2020-2021_2021-11-24-15-54-9.pdf`>Elected-PG-students  2020-2021</a>&nbsp;-&nbsp;Click here',
          level: 6,
        },
      },
    ],
    language: "En",
    _id: "61c5a0701725251ae9cf77ac",
    time: 1641380339474,
    version: "2.22.2",
    title: "Council Members",
    url: "/sc/councilmembers",
    path: "/sc",
    createdAt: "2021-12-24T10:26:56.007Z",
    updatedAt: "2022-01-05T10:57:15.660Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "mO01Xx9nys",
        type: "header",
        data: { text: "Achievements of Students' Council:&nbsp;", level: 6 },
      },
      {
        id: "ANgnG1nYdi",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/student-welfare/student-council/achievements-2010-11">Academic year 2010-2011</a>&nbsp;',
            "Academic year 2011-2012",
          ],
        },
      },
    ],
    language: "En",
    _id: "61c5a0901725251ae9cf77ae",
    time: 1641380647137,
    version: "2.22.2",
    title: "Achievements",
    url: "/sc/achievements",
    path: "/sc",
    createdAt: "2021-12-24T10:27:28.164Z",
    updatedAt: "2022-01-05T11:02:23.312Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "FHl_neIxHr",
        type: "paragraph",
        data: { text: "<b>Mobile&nbsp;:</b> 9490164983" },
      },
      {
        id: "7bEotWU33m",
        type: "paragraph",
        data: { text: "<b>Email Id&nbsp;:</b> scnitw@student.nitw.ac.in" },
      },
    ],
    language: "En",
    _id: "61c5a0b71725251ae9cf77b0",
    time: 1640341869245,
    version: "2.22.2",
    title: "Contacts",
    url: "/sc/contacts",
    path: "/sc",
    createdAt: "2021-12-24T10:28:07.710Z",
    updatedAt: "2021-12-24T10:28:07.710Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "vYQHtE_MX_", type: "paragraph", data: { text: "DOs:&nbsp;" } },
      {
        id: "CLrm17ZBF9",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "All the students are to send the request/application/form by email to the concerned seat&nbsp;through proper channel.&nbsp;",
            "All requests will be handled by the concerned seat.&nbsp;",
            "Minimum 5 working days are required to address the request.&nbsp;",
            "Applicants should provide complete details required for the request including contact details.&nbsp;",
            'Please write emails from official email address (<a href="mailto:xxxx@student.nitw.ac.in">xxxx@student.nitw.ac.in</a>) only. The requests from personal email address will not be entertained due to authenticity issue.&nbsp;',
            "Always plan ahead of time and give sufficient time to process applications. Urgent requests are difficult to accommodate.&nbsp;",
            "Other than the bonafide certificate, TC and MC, Study cum Conduct certificates, Scholarships for UG students, a recommendation from the head of the department is required. In all such cases, student can write an email to their Head and request to send the recommendation to academic section. This is responsibility of the student.&nbsp;",
            "Contact Student Fee Seat of Accounts Section for any issues related to fee payment, refund, fee receipt, fee estimate, loans and any other financial/money matter. Contact details are given at the end of the page&nbsp;",
          ],
        },
      },
      { id: "tyU9mqPE5H", type: "paragraph", data: { text: "DON’T’s&nbsp;" } },
      {
        id: "T1xzU09teZ",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "There is no need to make a CC of the email to higher authorities like Associate Dean, Dean, Registrar, Director, which is also not appropriate.&nbsp;",
            "Do not send reminders before 5 working days from the date of submission of the request.&nbsp;",
            "Do not call any officials through phone unless there is an emergency.",
          ],
        },
      },
      {
        id: "8Xc8vse2Z_",
        type: "paragraph",
        data: { text: "Contact Information:&nbsp;" },
      },
      {
        id: "Fqcnk2wZVx",
        type: "list",
        data: {
          style: "ordered",
          items: [
            'Examinations&nbsp;All exam related issues:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;<a href="https://www.nitw.ac.in/main/ContactDetails/NITW/">Contact Details</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2.&nbsp;<a href="https://www.nitw.ac.in/main/AcademicVerification/NITW/">Academic Verification for Genuineness</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/11/16/medium-of-instruction.pdf">Medium of Instruction</a>4.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/07/14/cgpa-conversion.pdf">CGPA to percentage conversion certificate</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.<a href="https://www.nitw.ac.in/main/Originalcertificates/NITW/">&nbsp;Original certificates/Grade sheets</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.&nbsp;<a href="https://www.nitw.ac.in/main/DuplicateCertificates/NITW/">Duplicate Certificates</a>7.&nbsp;<a href="https://www.nitw.ac.in/main/ExaminationTimetables/NITW/">Exam Schedules &amp; Time Tables</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.&nbsp;<a href="https://www.nitw.ac.in/main/2018/MEDALS/">Medals and Awards</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.&nbsp;<a href="https://www.nitw.ac.in/main/NoticesandCirculars/nitw/">Notices and Circulars</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;10.&nbsp;<a href="https://www.nitw.ac.in/main/Transcripts/NITW/">Transcripts</a>&nbsp;',
            'All exam related issues:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;<a href="https://www.nitw.ac.in/main/ContactDetails/NITW/">Contact Details</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2.&nbsp;<a href="https://www.nitw.ac.in/main/AcademicVerification/NITW/">Academic Verification for Genuineness</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/11/16/medium-of-instruction.pdf">Medium of Instruction</a>4.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/07/14/cgpa-conversion.pdf">CGPA to percentage conversion certificate</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.<a href="https://www.nitw.ac.in/main/Originalcertificates/NITW/">&nbsp;Original certificates/Grade sheets</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.&nbsp;<a href="https://www.nitw.ac.in/main/DuplicateCertificates/NITW/">Duplicate Certificates</a>7.&nbsp;<a href="https://www.nitw.ac.in/main/ExaminationTimetables/NITW/">Exam Schedules &amp; Time Tables</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.&nbsp;<a href="https://www.nitw.ac.in/main/2018/MEDALS/">Medals and Awards</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.&nbsp;<a href="https://www.nitw.ac.in/main/NoticesandCirculars/nitw/">Notices and Circulars</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;10.&nbsp;<a href="https://www.nitw.ac.in/main/Transcripts/NITW/">Transcripts</a>&nbsp;',
            'UG&nbsp;All UG matters except related to examination section:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;acad_ugseat@nitw.ac.in&nbsp;(Certificates like Bonafide, TC cum MC, Study and Conduct (Character),NOC for an internship, income certificate related, leave etc)&nbsp;&nbsp;NSP &amp; Institute merit Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_nspscholarships@nitw.ac.in">acad_nspscholarships@nitw.ac.in</a>Academic Other State Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_statescholarships@nitw.ac.in">acad_statescholarships@nitw.ac.in</a>Academic Foreign Seat:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>&nbsp;',
            "All UG matters except related to examination section:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;acad_ugseat@nitw.ac.in&nbsp;(Certificates like Bonafide, TC cum MC, Study and Conduct (Character),NOC for an internship, income certificate related, leave etc)&nbsp;&nbsp;",
            'NSP &amp; Institute merit Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_nspscholarships@nitw.ac.in">acad_nspscholarships@nitw.ac.in</a>&nbsp;',
            'Academic Other State Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_statescholarships@nitw.ac.in">acad_statescholarships@nitw.ac.in</a>&nbsp;',
            'Academic Foreign Seat:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>&nbsp;',
            'PG&nbsp;All PG matters except related to examination section:&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_pgseat@nitw.ac.in" target="_blank">acad_pgseat@nitw.ac.in</a>&nbsp;(Certificates like Bonafide, Transfer and Migration certificates,Study cum conduct certificates, Temporary withdrawal, full timeto part time conversion, cancellation etc.)&nbsp;Academic Foreign Seat:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>NSP &amp; Institute merit Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_nspscholarships@nitw.ac.in">acad_nspscholarships@nitw.ac.in</a>Academic Other State Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_statescholarships@nitw.ac.in">acad_statescholarships@nitw.ac.in</a>&nbsp;',
            'All PG matters except related to examination section:&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_pgseat@nitw.ac.in" target="_blank">acad_pgseat@nitw.ac.in</a>&nbsp;(Certificates like Bonafide, Transfer and Migration certificates,Study cum conduct certificates, Temporary withdrawal, full timeto part time conversion, cancellation etc.)&nbsp;',
            'Academic Foreign Seat:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>&nbsp;',
            'NSP &amp; Institute merit Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_nspscholarships@nitw.ac.in">acad_nspscholarships@nitw.ac.in</a>&nbsp;',
            'Academic Other State Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_statescholarships@nitw.ac.in">acad_statescholarships@nitw.ac.in</a>&nbsp;',
            'PhD&nbsp;All PhD matter:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; acad_phdseat@nitw.ac.in&nbsp;Academic Foreign Seat:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>Contact phone numbers:UG Issues&nbsp;:&nbsp;+91-870-2462056PG Issues&nbsp;:&nbsp;+91-870-2462057PhD Issues&nbsp;:&nbsp;&nbsp;+91-870-2462062NSP&amp; Institute merit Scholarships issues:&nbsp;+91-870-2462058Other State Scholarships:&nbsp;+91-870-2462985Foreign Student Issues:&nbsp;+91-870-2462061Superintendent of Academic Section:&nbsp;+91-870-2462063Dy. Registrar (Academic):&nbsp;+91-870-2462055Student Fee Seat (Accounts Section)Email:&nbsp;studentfee@nitw.ac.in&nbsp;&nbsp;Phone:&nbsp;0870 246 2065 &nbsp;',
            "All PhD matter:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; acad_phdseat@nitw.ac.in&nbsp;",
            'Academic Foreign Seat:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>Contact phone numbers:UG Issues&nbsp;:&nbsp;+91-870-2462056PG Issues&nbsp;:&nbsp;+91-870-2462057PhD Issues&nbsp;:&nbsp;&nbsp;+91-870-2462062NSP&amp; Institute merit Scholarships issues:&nbsp;+91-870-2462058Other State Scholarships:&nbsp;+91-870-2462985Foreign Student Issues:&nbsp;+91-870-2462061Superintendent of Academic Section:&nbsp;+91-870-2462063Dy. Registrar (Academic):&nbsp;+91-870-2462055Student Fee Seat (Accounts Section)Email:&nbsp;studentfee@nitw.ac.in&nbsp;&nbsp;Phone:&nbsp;0870 246 2065 &nbsp;',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c5a1af1725251ae9cf77ba",
    time: 1640342117388,
    version: "2.22.2",
    title: "Academic Services",
    url: "/academics/services",
    path: "/academics",
    createdAt: "2021-12-24T10:32:15.830Z",
    updatedAt: "2021-12-24T10:32:15.830Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "6nFOmelvTN",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/website_notice-JoSAA-2021.pdf" target="_blank">14 Oct 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Online Awareness Program for JoSAA 2021 Aspirants by NIT Warangal on 17-10-2021 (Sunday) at 10 AM</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Notice_for_calling_back_PhD_and_PG_students-Oct2021_1.pdf">14 Oct 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Notice to PhD (Full-time) scholars except first year and PG final year students for calling back to the campus by 1st November&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2021</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PG__B.Tech_Dues_List.pdf" target="_blank">28 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; PG &amp; B.Tech Dues List</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Extension_30.07.2021.pdf" target="_blank">26 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; Submission of applications online for claiming the tuition fee remission based on the family Income</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Circular_for_CautionDeposit_and_JoSAA_CSAB_regund.pdf" target="_blank">23 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; Refund of Caution Deposit of outgoing batch and B.Tech., JoSAA/ CSAB refund</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Letter_to_college_Dean_or_Principal_.pdf" target="_blank">23 Jul 2021 :&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;Tribal Welfare Department - Awareness to ST students about financial assistance of Rs.20.00 lakhs under “Dr.B.R.Ambedkar</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/Letter_to_college_Dean_or_Principal_.pdf" target="_blank">Overseas Vidya&nbsp;Nidhi Scheme”</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Institute_Merit_Scholarship-2020-21.pdf" target="_blank">19 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Institute Merit Scholarship 2020-21</a>&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Second_Online_verification_Notice_-_I_Year_M.Tech._Students.pdf" target="_blank">24 Mar 2021 :&nbsp; &nbsp; &nbsp;Second announcement for online verification of documents for I year M.Tech. students, who could not attend the verification during</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="https://nitw.ac.in/media/uploads/Second_Online_verification_Notice_-_I_Year_M.Tech._Students.pdf" target="_blank">Jan-Feb, 2021</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_to_MScMBAMCA.pdf" target="_blank">10 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First year M.Sc. / M.Sc.(Tech.) / MCA / MBA students, who are provisionally admitted in 2020</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_to_I_B.Tech.pdf" target="_blank">10 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First year B.Tech. students, who are provisionally admitted in 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Notice-M.Tech.-I_year-not_reported_to_online_verification-1.pdf">25 Feb 2021 :&nbsp; &nbsp; &nbsp;Notice with second semester tuition fee payment link to First Year M.Tech. students who did not report to online verification of&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;documents - Last date 2nd March 2021.&nbsp; &nbsp;</a>&nbsp;',
            '<a href="https://forms.gle/VbcAQ6HQmq3TTRJa8" target="_blank">10 Feb 2021 :&nbsp;&nbsp; &nbsp; The last date to fill the form for Physical Reporting of newly admitted PwD students of UG/PG/PhD programs is 13-02-2021.</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="https://forms.gle/VbcAQ6HQmq3TTRJa8" target="_blank">Online form link: https://forms.gle/VbcAQ6HQmq3TTRJa8</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Online_verification_Notice_-_I_Year_Ph.D_Students_v2_fT2zlJ6.pdf">05 Feb 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First Year Ph.D scholars (provisionally admitted in Sep 2020 and Jan 2021) regarding online verification.&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PwD_Reporting.pdf" target="_blank">02 Feb 2021&nbsp;:&nbsp; &nbsp; &nbsp;Note to the newly admitted PwD category students of UG, PG and PHD programs- Physical Verification</a>&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Online_verification_Notice_-_I_Year_M.Tech._Students.pdf">13 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to M.Tech. I year students (provisionally admitted in Sep 2020) regarding online verification.</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/January2021_fee_payment_instructions_to_students_2.pdf">11 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;UG, PG, PH.D fee payment for January 2021&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/IC_Reject_NOTICE.pdf" target="_blank">07 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Attention II, III and IV B.Tech students: Tuition fee dues payment. Deadline 10-01-2021</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/home/#" target="_blank">07 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Attention III B.Tech students: Open elective course choice can be exercised using the WSDC portal. Deadline: 10-01-2021</a>&nbsp;&nbsp;&nbsp;',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c5a1d91725251ae9cf77bc",
    time: 1640342158879,
    version: "2.22.2",
    title: "Announcements",
    url: "/academics/announcements",
    path: "/academics",
    createdAt: "2021-12-24T10:32:57.316Z",
    updatedAt: "2021-12-24T10:32:57.316Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "J-XVDZSK3N",
        type: "header",
        data: { text: "Even Semester", level: 6 },
      },
      {
        id: "rYBNwxhZ3G",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/11/11/i-btech-int-msc-ii-sem.pdf">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;I B.Tech. &amp; Int. MSc - II Sem</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/11/11/i-mca.pdf">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;I -&nbsp;MCA</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/11/11/i-msc-i-mtech.pdf">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;I- M.Sc. &amp; I-M.Tech.</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/11/11/ii_iii_iv-ug-ii_iii-pg-except-mba-phd.pdf">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;II_III_IV-UG &amp; II_III-PG (Except MBA) &amp; PhD</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/ACADEMICCALENDER/MBA/">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;MBA Academic Calendars</a>',
          ],
        },
      },
      {
        id: "isAdy1NeZz",
        type: "header",
        data: { text: "Odd Semester", level: 6 },
      },
      {
        id: "gxDjO-9i5v",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;<a href="https://nitw.ac.in/media/uploads/2021/11/11/i-btech-int-msc-i-sem.pdf">I B.Tech. &amp; Int. MSc - I Sem</a>&nbsp;',
            '30 Sep 2021 :&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/09/30/academic-calendar-2021-22-i-yr-mca-odd-semester.pdf">Academic calendar (MCA I year - I Sem)</a>&nbsp;',
            '25&nbsp;Aug&nbsp;2021&nbsp;:&nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/main/MBAAcademic/Calendars/">&nbsp;MBA Academic Calendars &nbsp;</a>&nbsp;',
            '17&nbsp;Aug&nbsp;2021&nbsp;:&nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/17/academic-calendar-2021-22-i-yr-pg-odd-semester.pdf">&nbsp;Academic Calendar (I Yr. PG except MCA)&nbsp;</a>&nbsp;',
            '30 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/07/30/academic-calendar-2021-22.pdf">Academic Calendar (UG II / III / IV; PG II / III and PhD)&nbsp;</a>',
          ],
        },
      },
      {
        id: "fySjHZUo_M",
        type: "header",
        data: { text: "Summer Quarter", level: 6 },
      },
      {
        id: "XwDRPqpMbG",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '22 March 2021 : &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/03/22/acad_calendar-summer-quarter.pdf">Academic Calendar - Summer Quarter</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c5a2441725251ae9cf77be",
    time: 1640342266195,
    version: "2.22.2",
    title: "Academic Calendar",
    url: "/academics/calendar",
    path: "/academics",
    createdAt: "2021-12-24T10:34:44.631Z",
    updatedAt: "2021-12-24T10:34:44.631Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "hp1iI4SUy4",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/03/28/latest_academic-report_23022021_for-publicaiton.pdf">Academic Report 2019 - 20</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c5a3ef1725251ae9cf77c2",
    time: 1640342692810,
    version: "2.22.2",
    title: "Academic Report",
    url: "/academics/ar",
    path: "/academics",
    createdAt: "2021-12-24T10:41:51.256Z",
    updatedAt: "2021-12-24T10:41:51.256Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Q50pTCfF-g",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              'Dean (Academic)Phone: +91-870-2462014,&nbsp;2017 (office)&nbsp; Email:<a href="mailto:dean_acad@nitw.ac.in">dean_acad@nitw.ac.in</a>',
              "Dr. A. Venu GopalProfessorMechanical Engineering",
            ],
          ],
        },
      },
      {
        id: "D0Xzo6Imh9",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Associate Deans", "Name", "Roles and Responsibilities"],
            [
              'Associate Dean (Academic-UG)Phone: +91-870-2462059&nbsp; Email:&nbsp;<a href="mailto:ad_acad.ug@nitw.ac.in">ad_acad.ug@nitw.ac.in</a>',
              "Dr. V. Hari KumarAssociate ProfessorMechanical Engineering",
              "Course registrationTime TablesCurriculum Development, Board of StudiesNBA and NIRF data of UGBranch change",
            ],
            [
              '&nbsp; Associate Dean(Academic - PG&amp;R)Phone: +91-870-2462985&nbsp; Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">ad_acad.pg@nitw.ac.in</a>&nbsp; Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">ad_acad.phd@nitw.ac.in</a>&nbsp;&nbsp;',
              "Dr. P. V. SureshAssociate ProfessorChemical Engineering",
              "Course RegistrationCurriculum Development, Board of StudiesPreparation of Plagiarism reportNBA and NIRF data of PG &amp; Ph.D",
            ],
            [
              'Professor-In-Charge (Examinations)Phone: +91-870-2462021, 22, 25&nbsp; Email:&nbsp;<a href="mailto:ad_exam@nitw.ac.in">ad_exam@nitw.ac.in</a>',
              "Dr. D. SrinivasacharyaProfessorMathematics",
              "Examination schedules, Results and GradesCo-ordination for the conduct of Institute day / ConvocationData for Convocation / Institute day / Annual reportIssue of semester grade sheets and consolidated grade sheetIssue of Original degree certificatesTranscriptsAcademic Genuineness",
            ],
            [
              "Chairman, AdmissionsPhone: +91-870-2462060Email: nitwadm@gmail.com&nbsp;",
              "Prof. Anand Kishore KolaProfessorDepartment of Chemical Engineering",
              "Admissions of all the UG, PG and Ph.D programmes",
            ],
            [
              "Vice Chairman, Admissions&nbsp; Phone: ....Email:&nbsp;",
              "Dr. Venkaiah ChowdaryAssociate ProfessorDepartment of Civil Engineering",
              "Admissions of all the UG, PG and Ph.D programmes",
            ],
          ],
        },
      },
      {
        id: "NQgjigikyA",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Academic Staff", "Name", "Roles &amp; Responsibilities"],
            [
              'Dy. Registrar (Academic)Phone: +91-870-2462055&nbsp; Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">Email: dr_acad@nitw.ac.in</a>',
              "Sri. Soumen Karmakar",
              "Coordination with the staff of Academic section and Examination section.Leave permissions to the staff in academic section.General day to day activities in the Academic section.Coordinating and looking after the following activities:Scholarships for B.Tech.ICCRSPDCStudy In India (SII)Procurement of infrastructure or any other itemsAnnual Report DataData requested by MHRD during parliament sessionsBonafide Certificate and NOC for existing studentsStudy &amp; Conduct, TC &amp; Migration CertificatesCharacter and Bonafide certificatesUtilization certificateIncome certificateNo duesRTI and MHRD queriesSenate mattersM.Tech. StipendsPh.D fellowships (UGC, Visweswaraya, QIP, Institute)",
            ],
            [
              'SuperintendentPhone: +91-870-2462063&nbsp; Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">acad_supdt@nitw.ac.in</a>',
              "Sri. S. Suryaprakash",
              "Coordinating with all the seats in Academic sectionForeign seat responsibilities",
            ],
            [
              'UG seatPhone: +91-870-2462056&nbsp; Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">acad_ugseat@nitw.ac.in</a>',
              "Sri. B. Mangilal",
              "Temporary Break of Study of UGUG Admission CancellationIncome certificateBranch changeNo Objection Certificate, Study &amp; Conduct certificate,  Transfer and Migration Certificate, Custodian Certifcate, No dues certificateMaintenance of Academic Records, Archiving of Academic RecordsNBA and NIRF data for UGInformation for RTI / MHRD queries",
            ],
            [
              'PG SeatPhone: +91-870-2462057&nbsp; Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">acad_pgseat@nitw.ac.in</a>',
              "Sri. Md. Afzal",
              "M.Tech. StipendsNo Objection Certificate, Study &amp; Conduct certificate, Transfer and Migration Certificate, Custodian Certifcate, No dues certificateTemporary Break of Study of PGFull-time to part-time conversionPG Admission CancellationMaintenance of Academic Records, Archiving of Academic RecordsInformation for RTI / MHRD queriesNBA and NIRF data of PG",
            ],
            [
              'Ph.D SeatPhone: +91-870-2462062&nbsp; Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">acad_phdseat@nitw.ac.in</a>',
              "Sri. Md. Jafar",
              "Ph.D fellowships (UGC, Visweswaraya, QIP, Institute)Extension and Enhancement of FellowshipsOrders of Maternity and Paternity leavesPermission for external Research workFull time to Part time conversionJRF project to Institute fellowship conversionTemporary Break of Study of Ph.DPh.D Admission CancellationProvisional, Transfer cum Migration certificatesBonafide certificateData for Convocation / Institute day / Annual reportMaintenance of Academic Records, Archiving of Academic RecordsInformation for RTI / MHRD Queries, NAD",
            ],
            [
              'NSP&amp; Institute merit Scholarships seatPhone: +91-870-2462058&nbsp; Email:&nbsp;<a href="mailto:acad_nspscholarships@nitw.ac.in">acad_nspscholarships@nitw.ac.in</a>',
              "Sri. MD. Shoukath Ali",
              "<b>NSP Scholarships</b>Providing the bonafide certificateCollecting the NSP Scholarship soft and hard copies.Forwarding the online scholarship application from the Institute login as per the eligibility.<b>Institute Merit Scholarships</b>&nbsp; Every year Institute gives merit Scholarship (Reimbursement of one year paid tuition fee) with a merit Scholarship certificate based on their previous year performance.",
            ],
            [
              'Other State Scholarships seatPhone: +91-870-2462055/2985&nbsp; Email:&nbsp;<a href="mailto:acad_statescholarships@nitw.ac.in">acad_statescholarships@nitw.ac.in</a>',
              "Sri. MD. Shoukath Ali",
              "<b>All State Post Matric Scholarship</b>The State Scholarships, which are not covered under NSP.Providing the bonafide certificatesStudent submit the soft and hard copies to the Institute, the applications will be forwarded through online / manually to the concerned sanction authorities of state welfare offices.The sanctioned scholarship from the welfare office will be credited in the Institute account.Collecting no dues from the accountsTaking approval of the Director’s to disbursement of scholarship to the concerned<b>Parent Departmental/ Private Scholarships</b>Providing bonafide certificateCollecting undertaking (the Student should not avail any tuition fee waiver/scholarships from any sources)Forwarding the applications",
            ],
            [
              'Foreign SeatPhone: +91-870-2462061&nbsp; Email:&nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in">acad_foreignseat@nitw.ac.in</a>',
              "Sri. S. SuryaprakashSri. Bhaskar",
              "Utilization certificatesICCRFee structure every year (B.Tech and M.Tech)Bonafide certificatesJoining reports, InvoicePerformance reportExtension lettersUtilization certificatesReimbursement of final Project expensesCourse completion and Flight ticketsStudy in India (SII)Update of data in the portal every year Providing seat matrix, Fee structure every yearVerifying the assigned student through portalSending offer letter to selected studentsSending the joining reports, Invoice to SIIProviding necessary documents and information when every requested by SIISPDC ScholarshipsFee structure every year (B.Tech and M.Tech)Bonafide certificatesInvoicePerformance reportUtilization certificatesNepal Scholarships",
            ],
          ],
        },
      },
      {
        id: "iUPi2G-N9c",
        type: "paragraph",
        data: { text: "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;" },
      },
      { id: "rZL2wspCaN", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61c5a9fc1725251ae9cf77c4",
    time: 1640344241636,
    version: "2.22.2",
    title: "Academic Team",
    url: "/academics/at",
    path: "/academics",
    createdAt: "2021-12-24T11:07:40.077Z",
    updatedAt: "2021-12-24T11:07:40.077Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "pTXiwvF7Ue",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "7oiMT-IIP8",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Organisation_and_Function_2021-11-27-10-41-31.pdf`>Organisation and Function</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "61c94b0af99de5365a9b026e",
    time: 1640581898471,
    version: "2.22.2",
    title: "Organisation and Function",
    url: "/rti2021/oaf",
    path: "/rti2021",
    createdAt: "2021-12-27T05:11:38.568Z",
    updatedAt: "2021-12-27T05:11:38.568Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "3_ONYEiQat",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "3xanUC5gca",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Budget_and_Programmes_2021-11-27-10-42-25.pdf`>Budget and Programmes</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "61c94b3ef99de5365a9b0271",
    time: 1640581950355,
    version: "2.22.2",
    title: "Budget and Programmes",
    url: "/rti2021/bap",
    path: "/rti2021",
    createdAt: "2021-12-27T05:12:30.448Z",
    updatedAt: "2021-12-27T05:12:30.448Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "HSrr36bNVy",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "pcQK84j9zb",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Publicity_and_Public_Interface_2021-11-27-10-43-24.pdf`>Publicity and Public Interface</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "61c94b7cf99de5365a9b0274",
    time: 1640582012782,
    version: "2.22.2",
    title: "Publicity and Public Interface",
    url: "/rti2021/ppi",
    path: "/rti2021",
    createdAt: "2021-12-27T05:13:32.859Z",
    updatedAt: "2021-12-27T05:13:32.859Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "jgkVZlACJT",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "ImZ9eUITUb",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/E-Governance_2021-11-27-10-44-26.pdf`>E-Governance</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "61c94bb9f99de5365a9b0277",
    time: 1640582073253,
    version: "2.22.2",
    title: "E-Governance",
    url: "/rti2021/eg",
    path: "/rti2021",
    createdAt: "2021-12-27T05:14:33.344Z",
    updatedAt: "2021-12-27T05:14:33.344Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "L0HQJVyLCr",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "R6CAAuUM8c",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Information_as_may_be_Prescribed_2021-11-27-10-45-5.pdf`>Information as may be Prescribed</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "61c94c06f99de5365a9b027a",
    time: 1640582150090,
    version: "2.22.2",
    title: "Information as may be Prescribed",
    url: "/rti2021/ip",
    path: "/rti2021",
    createdAt: "2021-12-27T05:15:50.177Z",
    updatedAt: "2021-12-27T05:15:50.177Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Xk5JtsINNX",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "E0ar2IWLMv",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Information_Disclosed_on_Own_Initiative_2021-11-27-10-46-52.pdf`>Information Disclosed on Own Initiative</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "61c94c8af99de5365a9b027d",
    time: 1640582282421,
    version: "2.22.2",
    title: "Information Disclosed on Own Initiative",
    url: "/rti2021/idoi",
    path: "/rti2021",
    createdAt: "2021-12-27T05:18:02.515Z",
    updatedAt: "2021-12-27T05:18:02.515Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "uPRaAsiQIJ",
        type: "header",
        data: {
          text: '<mark class=`${MainServerUrl}/static/files/cce_jan_22_2022_2022-0-22-16-23-23.pdf`>Click here</a>',
          level: 5,
        },
      },
    ],
    language: "En",
    _id: "61c94f2bf99de5365a9b0284",
    time: 1642848833617,
    version: "2.22.2",
    title: "Center for continuing education",
    url: "/cce/2021",
    path: "/cce",
    createdAt: "2021-12-27T05:29:15.538Z",
    updatedAt: "2022-01-22T10:53:49.660Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "ApkG8Vti4c",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/01/18/ccourse.pdf">Certificate course guidelines</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/09/07/application-form-07092020.pdf">Application form&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/06/29/honarorium-form_LlI4YmM.pdf">Honorarium form&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/06/30/certificates-template.pdf">Certificate</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/06/29/checklist_9o3PIS7.pdf">Checklist&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/08/26/feedback.pdf">Feedback Form</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/09/17/cce-account-summary.pdf">FDP summary form&nbsp;</a>&nbsp;',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c94f6df99de5365a9b0286",
    time: 1641455296769,
    version: "2.22.2",
    title: "Forms",
    url: "/cce/forms",
    path: "/cce",
    createdAt: "2021-12-27T05:30:21.736Z",
    updatedAt: "2022-01-06T07:46:26.380Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Uh8dAFRtKa",
        type: "paragraph",
        data: {
          text: '14 Jul 2018: &nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/07/14/employers-during-2017-18.pdf">Employers who hired Students through Campus Placements during 2017-18</a>',
        },
      },
      {
        id: "KieKBahkM-",
        type: "paragraph",
        data: {
          text: '14 Jul 2018: &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/07/14/employers-during-2016-17.pdf">Employers who hired Students through Campus Placements during 2016-17</a>',
        },
      },
      {
        id: "tB9KZ7ckn6",
        type: "paragraph",
        data: {
          text: '14 Jul 2018: &nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/07/14/employers-during-2015-16.pdf">Employers who hired Students through Campus Placements during 2015-16</a>',
        },
      },
      {
        id: "CVGPX3EKIQ",
        type: "paragraph",
        data: {
          text: '14 Jul 2018: &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/07/14/employers-during-2014-15.pdf">Employers who hired Students through Campus Placements during 2014-15</a>',
        },
      },
      {
        id: "-xvbTfvzKJ",
        type: "paragraph",
        data: {
          text: '14 Jul 2018: &nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/07/14/employers-during-2013-14.pdf">Employers who hired Students through Campus Placements during 2013-14</a>',
        },
      },
      {
        id: "42N0YPCDkK",
        type: "paragraph",
        data: {
          text: '14 Jul 2018: &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/07/14/placement-procedure-final.pdf">Campus Placement Procedure</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9511ff99de5365a9b028b",
    time: 1640583455125,
    version: "2.22.2",
    title: "EMPLOYERS",
    url: "/placement/EMPLOYERS",
    path: "/placement",
    createdAt: "2021-12-27T05:37:35.213Z",
    updatedAt: "2021-12-27T05:37:35.213Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "EVM64L23SR",
        type: "paragraph",
        data: {
          text: '15 Jul 2021 :&nbsp; &nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/07/15/placement-statistics-2020-2021_to-upload.pdf">Placement statistics for AY 2020-21</a>',
        },
      },
      {
        id: "eJKDgGDesK",
        type: "paragraph",
        data: {
          text: '15 Jul 2021 :&nbsp; &nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/07/15/placement-details-2020-2021_to-upload.pdf">Details of Placements for AY 2021-22</a>',
        },
      },
      {
        id: "D-2Vjje2e-",
        type: "paragraph",
        data: {
          text: '18 oct 2020 :&nbsp; &nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/10/18/2019-20-placement-details_website.pdf">PLACEMENT DETAILS 2019-20</a>',
        },
      },
      {
        id: "v3IY6lqFCb",
        type: "paragraph",
        data: {
          text: '18 oct 2020 :&nbsp; &nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/10/18/2019-20-placement-stats_website.pdf">Placement statistics for the PLACEMENT YEAR 2019-20</a>',
        },
      },
      {
        id: "lZuufe4Y9o",
        type: "paragraph",
        data: {
          text: '03 oct 2020 :&nbsp; &nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/10/03/placement-ststs_19-20_to-upload.pdf">Placement statistics of all BTech Programs during AY 2019-20</a>',
        },
      },
      {
        id: "Fj1_yWCHbr",
        type: "paragraph",
        data: {
          text: '28 oct 2019 :&nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2019/10/28/taps-pg-programs-stats-2018-19-28102019.pdf">&nbsp;Placement Statistics of Post Graduate Programs for the past Five Years</a>',
        },
      },
      {
        id: "K3JQDngtfY",
        type: "paragraph",
        data: {
          text: '28 oct 2019 :&nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2019/10/28/taps-ugprograms-statistics-2018-19-28102019.pdf">&nbsp;Placement Statistics of Under Graduate Programs for the past Five Years</a>',
        },
      },
      {
        id: "73C9iYi0tH",
        type: "paragraph",
        data: {
          text: '30 Sep 2019:&nbsp; &nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/main/placemtents/ay18-19/">Detailed Placement Statistics of all Programs during AY 2018-19</a>',
        },
      },
      {
        id: "MlhbqRDsCg",
        type: "paragraph",
        data: {
          text: '08 Aug 2018 :&nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/main/Placement/Statistics2017-18/">Detailed Placement Statistics of all Programs during AY 2017-18</a>',
        },
      },
      {
        id: "s9NoHFGoXO",
        type: "paragraph",
        data: {
          text: '07 Aug 2018 :&nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/08/07/taps-ug-programs-statistics-05082018.pdf">Placement Statistics of Under Graduate Programs for the past Four Years</a>',
        },
      },
      {
        id: "MNUmsAO1GE",
        type: "paragraph",
        data: {
          text: '07 Aug 2018 : &nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/08/07/taps-pg-programs-statistics-07082018.pdf">Placement Statistics of Post Graduate Programs for the past Four Years</a>',
        },
      },
      {
        id: "CKW-s3jOjD",
        type: "paragraph",
        data: {
          text: '6 July 2018: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/main/AY/2016-17/">Detailed Placement Statistics of all Programs during AY 2016-17</a>',
        },
      },
      {
        id: "dbtfo09WPN",
        type: "paragraph",
        data: {
          text: '3 July 2018:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/main/Placement/AY2015-16/">Detailed Placement Statistics of all Programs during AY 2015-16</a>',
        },
      },
      {
        id: "vB601QoG01",
        type: "paragraph",
        data: {
          text: '1 July 2018:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/main/Placement/Statistics/">Detailed Placement Statistics of all Programs during AY 2014-15</a>',
        },
      },
      {
        id: "iuuL1bP2WP",
        type: "paragraph",
        data: {
          text: '18 jun 2018:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/06/18/taps-pg-programs-statistics.pdf">Placement Statistics of PG Programs for the Past Three Years</a>',
        },
      },
      {
        id: "SX2pEAv-v_",
        type: "paragraph",
        data: {
          text: '&nbsp; 2 jun 2018:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2018/06/12/taps-statistics_YJHpScZ.pdf">Placement Statistics of Under Graduate Programs for the Past Three Years</a>',
        },
      },
    ],
    language: "En",
    _id: "61c95171f99de5365a9b028d",
    time: 1640583537329,
    version: "2.22.2",
    title: "PLACEMENTS STATISTICS",
    url: "/placement/PLACEMENTSSTATISTICS",
    path: "/placement",
    createdAt: "2021-12-27T05:38:57.429Z",
    updatedAt: "2021-12-27T05:38:57.429Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "fyGvLrGDLy",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/2018-19-student-volunteers-for-upload.pdf">25 Jun 2018 : Campus Placement Student Volunteers for 2018-19</a>',
        },
      },
    ],
    language: "En",
    _id: "61c951aaf99de5365a9b028f",
    time: 1640583594414,
    version: "2.22.2",
    title: "STUDENTS LIST",
    url: "/placement/STUDENTSLIST",
    path: "/placement",
    createdAt: "2021-12-27T05:39:54.506Z",
    updatedAt: "2021-12-27T05:39:54.506Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "YGFh_PfXEF",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Urjanet-CCPD-Single-CampusPlacementSelections-Announcement-20032020.pdf">20 Mar 2020 : &nbsp; &nbsp; Campus Placement Offer from Urjanet Energy Solutions Private Limited, Chennai</a>',
        },
      },
      {
        id: "HwxI-ZjEyG",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/NeulandLabs-CCPD-Single-CampusPlacementSelections-Announcement-19032020.pdf">19 Mar 2020 : &nbsp; &nbsp; Campus Placement Offer from Neuland Laboratories Limited</a>',
        },
      },
      {
        id: "tczMAhI1wH",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ResonanceEduventures-CCPD-CampusPlacementSelections-Announcement-18032020.pdf">18 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from Resonance Eduventures Limited</a>',
        },
      },
      {
        id: "wjcM5e2mJp",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Rexnord-CCPD-CampusPlacementSelections-Announcement-17032020.pdf">17 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from Rexnord Corporation</a>',
        },
      },
      {
        id: "3ctzkEa6Sy",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MicroChip-CCPD-CampusPlacementSelections-Announcement-16032020.pdf">16 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from MICROCHIP Technology India Private Limited</a>',
        },
      },
      {
        id: "o3lISDmdw8",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/F5Networks-CCPD-CampusPlacementSelections-Announcement-13032020.pdf">13 Mar 2020 : &nbsp; &nbsp; Campus Campus Placement Offers from F5 Networks</a>',
        },
      },
      {
        id: "0SI0IjH96R",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AshirvadPipes-CCPD-CampusPlacementSelections-Announcement-12032020.pdf">13 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from Ashirvad Pipes Private Limited, Bangalore</a>',
        },
      },
      {
        id: "DejFob5sfz",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/TigerAnalytics-CCPD-CampusPlacementSelections-Announcement-11032020.pdf">11 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from TIGER Analytics</a>',
        },
      },
      {
        id: "ReoIqkyAWU",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ATCTires-CCPD-Single-CampusPlacementSelections-Announcement-10032020.pdf">10 Mar 2020 : &nbsp; &nbsp; Campus Placement Offer from ATC Tires Private Limited</a>',
        },
      },
      {
        id: "fP9Ee5S9SU",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AdityaBirla-CCPD-CampusPlacementSelections-Announcement-09032020.pdf">09 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from Aditya Birla Management Corporation Private Limited</a>',
        },
      },
      {
        id: "EeRCOQ6Wno",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ADPPrivateLimited-CCPD-CampusPlacementSelections-Announcement-07032020.pdf">07 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from ADP Private Limited</a>',
        },
      },
      {
        id: "UaWOWmL_SO",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Redpine-CCPD-CampusPlacementSelections-Announcement-06032020.pdf">06 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from Redpine Signals India Private Limited</a>',
        },
      },
      {
        id: "G8afQjqGi3",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Fiat-Single-PPO-Selections-Announcement-05032020.pdf">05 Mar 2020 : &nbsp; &nbsp; Campus Placement Offer from FCA Engineering India Private Limited</a>',
        },
      },
      {
        id: "vEzg6DraIu",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Fiat-CCPD-CampusPlacementSelections-Announcement-05032020.pdf">05 Mar 2020 : &nbsp; &nbsp; Campus Placement Offers from FCA Engineering India Private Limited</a>',
        },
      },
      {
        id: "_5YodxlLB7",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Synchrony-CCPD-CampusPlacementSelections-Announcement-04032020.pdf">04 Mar 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Synchrony International Services Private Limited, Hyderabad</a>',
        },
      },
      {
        id: "bJhRMrMBHH",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/TataProjectsLimited-CCPD-CampusPlacementSelections-Announcement-03032020.pdf">03 Mar 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Tata Projects Limited, Hyderabad</a>',
        },
      },
      {
        id: "zBMYM-Sz_x",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Q2EBanking-CCPD-Single-CampusPlacementSelections-Announcement-02032020.pdf">02 Mar 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offer from Q2 Ebanking, Bangalore</a>',
        },
      },
      {
        id: "v_zMD78EF_",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Aakash-CCPD-CampusPlacementSelections-Announcement-28022020.pdf">28 Feb 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Aakash Educational Services Limited</a>',
        },
      },
      {
        id: "38IAO8s_Nl",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/RelianceIndustriesLimited-CCPD-CampusPlacementSelections-Announcement-27022020.pdf">27 Feb 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Reliance Industries Limited</a>',
        },
      },
      {
        id: "H5m4O03bhg",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Delhivery-CCPD-CampusPlacementSelections-Announcement-26022020.pdf">26 Feb 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Delhivery</a>',
        },
      },
      {
        id: "ZlgIND3DRg",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Factset-Single-CampusPlacementSelections-Announcement-25022020.pdf">25 Feb 2020 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from FactSet Systems India Private Limited</a>',
        },
      },
      {
        id: "gurB2scBej",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ITCInfotech-CampusPlacementSelections-Announcement-24022020.pdf">25 Feb 2020 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from ITC Infotech</a>',
        },
      },
      {
        id: "Vs78rFE0bW",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ITILimited-CampusPlacementSelections-Announcement-06022020.pdf">05 Feb 2020 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from Indian Telephone Industries (ITI) Limited, Bangalore</a>',
        },
      },
      {
        id: "UnfwTeJWha",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Synopsys-CampusPlacementSelections-Announcement-05022020.pdf">05 Feb 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Synopsys India Private Limited, Hyderabad</a>',
        },
      },
      {
        id: "8f1DdepeWo",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/DeloitteIndia-CampusPlacementSelections-Announcement-03022020.pdf">03 Feb 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Deloitte India</a>',
        },
      },
      {
        id: "qdp7R9YoFq",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/TCE-CampusPlacementSelections-Announcement-31012020.pdf">31 Jan 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Tata Consulting Engineers (TCE) Limited</a>',
        },
      },
      {
        id: "lRzAAi_nUX",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/L&amp;TLimited-CampusPlacementSelections-Announcement-30102020%20(1).pdf">31 Jan 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Larsen &amp; Toubro Limited</a>',
        },
      },
      {
        id: "bQk3s3onEB",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/HPCL-CampusPlacementSelections-Announcement-28012020.pdf">29 Jan 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Hindustan Petroleum Corporation Limited (HPCL)</a>',
        },
      },
      {
        id: "Vjc1a7_orl",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GAIL-CampusPlacementSelections-Announcement-27012020.pdf">27 Jan 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Gas Authority of India Limited (GAIL)</a>',
        },
      },
      {
        id: "DwNsccgGX5",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Byjus-CampusPlacementSelections-Announcement-25012020.pdf">25 Jan 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from BYJUS Learning App</a>',
        },
      },
      {
        id: "SqNp5cdKyh",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Capgemeni-CampusPlacementSelections-Announcement-23012020.pdf">23 Jan 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Capgemini Technology Services India Limited</a>',
        },
      },
      {
        id: "m9i0D_AUzt",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Shapoorji-CampusPlacementSelections-Announcement-22012020.pdf">21 Jan 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Shapoorji Pallonji And Company Limited</a>',
        },
      },
      {
        id: "ZHUU07jrl4",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/HUL-CampusPlacementSelections-Announcement-21012020.pdf">21 Jan 2020 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from Hindustan Unilever Limited</a>',
        },
      },
      {
        id: "9TYPO6Ptk_",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/BakerHughes-InternshipSelections-Announcement-17012020.pdf">17 Jan 2020 : &nbsp; &nbsp; &nbsp;Internship Offers from Baker Hughes GE, Bangalore</a>',
        },
      },
      {
        id: "9Z8Hht9OWK",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GainCredit-Single-CampusPlacementSelections-Announcement-16012020.pdf">16 Jan 2020 : &nbsp; &nbsp; &nbsp;Campus Placement Offer from Gain Credit</a>',
        },
      },
      {
        id: "wsgn_vdoJ4",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/CipherCloud-CampusPlacementSelections-Announcement-10012020.pdf">10 Jan 2020 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from Cipher Cloud India Private Limited</a>',
        },
      },
      {
        id: "8oplwxeu9n",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/VerityKnowledgeSolutions-CampusPlacementSelections-Announcement-09012020.pdf">09 Jan 2020 :&nbsp; &nbsp; &nbsp;&nbsp;Campus Placement Offers from Verity Knowledge Solutions</a>',
        },
      },
      {
        id: "Q3-2n2fXp4",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/VMware-Single-PPO-Selections-Announcement-07012020.pdf">08 Jan 2020 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from VMware India</a>',
        },
      },
      {
        id: "34spJMplv8",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Accenture-CampusPlacementSelections-Announcement-06012020.docx.pdf">08 Jan 2020 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from Accenture</a>',
        },
      },
      {
        id: "AVMZxhTAzf",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/TechnipFMC-CampusPlacementSelections-Announcement-08012020.pdf">08 Jan 2020 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from TechnipFMC</a>',
        },
      },
      {
        id: "_DTS1kInbU",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ImpactAnalytics-CampusPlacementSelections-Announcement-13122019.pdf">12 Dec 2019 &nbsp;: &nbsp; &nbsp; Campus Placement Offers from Impact Analytics</a>',
        },
      },
      {
        id: "VgaH981bjg",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Honeywell-CampusPlacementSelections-Announcement-12122019.pdf">11 Dec 2019 &nbsp;: &nbsp; &nbsp; Campus Placement Offers from Honeywell</a>',
        },
      },
      {
        id: "wvV8fIcy7D",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/63Infolabs-CampusPlacementSelections-Announcement-11122019.pdf">10 Dec 2019 &nbsp;: &nbsp; &nbsp; Campus Placement Offers from 63Ideas Infolabs Private Limited</a>',
        },
      },
      {
        id: "0QdJ3Z0Xqz",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Edgeverve-CampusPlacementSelections-Announcement-10122019.pdf">10 Dec 2019 &nbsp;:&nbsp; &nbsp; &nbsp;Campus Placement Offers from Edgeverve Systems Limited</a>',
        },
      },
      {
        id: "_zKYXHo5Hy",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Edgeverve-InternshipSelections-Announcement-10122019.pdf">10 Dec 2019 &nbsp;: &nbsp; &nbsp; Internship Offers from Edgeverve Systems Limited</a>',
        },
      },
      {
        id: "SKAkgFKFBI",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/L&amp;TConstruction-CampusPlacementSelections-Announcement-09122019.pdf">08 Dec 2019 &nbsp;:&nbsp; &nbsp; &nbsp;Campus Placement Offers from L&amp;T Construction</a>',
        },
      },
      {
        id: "26hbi0bZR6",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/PublicisSapient-InternshipSelections-Announcement-06122019.pdf">06 Dec 2019 &nbsp;:&nbsp; &nbsp; &nbsp;Internship Offers from Publicis Sapient</a>',
        },
      },
      {
        id: "TTXs4labiW",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/PublicisSapient-CampusPlacementSelections-Announcement-06122019.pdf">06 Dec 2019 &nbsp;:&nbsp; &nbsp; &nbsp;Campus Placement Offers from Publicis Sapient</a>',
        },
      },
      {
        id: "xxPWmsB7B3",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/FractalAnalytics-CampusPlacementSelections-Announcement-04102019.pdf">05 Dec 2019 &nbsp;: &nbsp; &nbsp; Campus Placement Offers from Fractal Analytics</a>',
        },
      },
      {
        id: "154qeL-Hd6",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/OYORooms-CampusPlacementSelections-Announcement-04122019.pdf">04 Dec 2019 &nbsp;: &nbsp; &nbsp; Campus Placement Offers from OYO Rooms</a>',
        },
      },
      {
        id: "1rdIwlB-VM",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GodrejAgrovet-Single-CampusPlacementSelections-Announcement-03122019.pdf">03 Dec 2019 &nbsp;: &nbsp; &nbsp; Campus Placement Offer from Godrej Agrovet Limited</a><a href="http://172.20.0.248:9000/media/GodrejAgrovet-InternshipSelections-Announcement-03122019.pdf">03 Dec 2019 &nbsp;: &nbsp; &nbsp; Internship Offers from Godrej Agrovet Limited</a>',
        },
      },
      {
        id: "qnQ3olVHpN",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Siemens-Single-InternshipSelections-Announcement-02122019.pdf">2 Dec 2019 :&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Internship Offer from Siemens Technology and Services Private Limited</a>',
        },
      },
      {
        id: "fmlojI3j0p",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SamsungR&amp;D-InternshipSelections-Announcement-29112019.pdf">29 Nov 2019 :&nbsp; &nbsp; &nbsp; Internship Offers from Samsung R&amp;D Institute India</a>',
        },
      },
      {
        id: "zhavhVQTFt",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ServiceNow-CampusPlacementSelections-Announcement-28112019.pdf">28 Nov 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Service Now</a>',
        },
      },
      {
        id: "9J7ERvWd6C",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Visa-InternshipSelections-Announcement-27112019.pdf">27 Nov 2019 :&nbsp; &nbsp;&nbsp;&nbsp;Internship Offers from Visa Inc.</a>',
        },
      },
      {
        id: "pyoAZeTS92",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Visa-CampusPlacementSelections-Announcement-27112019.pdf">27 Nov 2019 : &nbsp; &nbsp; Campus Placement Offers from Visa Inc.</a>',
        },
      },
      {
        id: "NBUKEyC0z5",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Oppo-CampusPlacementSelections-Announcement-26112019.pdf">26 Nov 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from OPPO Mobiles India Private Limited</a>',
        },
      },
      {
        id: "FqpYXuTHSf",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AxisBank-InternshipSelections-Announcement-25112019.pdf">25 Nov 2019 :&nbsp; &nbsp;&nbsp;&nbsp;Internship Offers from Axis Bank Limited</a>',
        },
      },
      {
        id: "5dZSiFH0De",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AxisBank-CampusPlacementSelections-Announcement-25112019.pdf">25 Nov 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Axis Bank Limited</a>',
        },
      },
      {
        id: "kXcGSJw6H8",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Wipro-CampusPlacementSelections-Announcement-22112019.pdf">21 Nov 2019 : &nbsp; &nbsp; Campus Placement Offers from Wipro Limited</a>',
        },
      },
      {
        id: "u85E21Xbv9",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ZSAssociates-CampusPlacementSelections-Announcement-20112019.pdf">21 Nov 2019 : &nbsp; &nbsp; Campus Placement Offers from ZS Associates India Private Limited</a>',
        },
      },
      {
        id: "gkteJZwTC9",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Citicorp-CampusPlacementSelections-Announcement-20112019.pdf">20 Nov 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Citicorp Services India Private Limited</a>',
        },
      },
      {
        id: "tgQe1ePisU",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Schneider-Single-CampusPlacementSelections-Announcement-19112019.pdf">20 Nov 2019 :&nbsp; &nbsp;&nbsp;&nbsp;Campus Placement Offer from Schneider Electric</a>',
        },
      },
      {
        id: "JsU0B5zMVm",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/HyundaiMobis-CampusPlacementSelections-Announcement-18112019.pdf">18 Nov 2019 : &nbsp; &nbsp; Campus Placement Offers from Mobis Technical Centre of India</a>',
        },
      },
      {
        id: "fcRKGPgyZe",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Novartis-CampusPlacementSelections-Announcement-15112019.pdf">18 Nov 2019 : &nbsp; &nbsp; Campus Placement Offers from Novartis Healthcare Private Limited</a>',
        },
      },
      {
        id: "O7z34AK1dS",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/DrReddys-CampusPlacementSelections-Announcement-14112019.pdf">14 Nov 2019 : &nbsp; &nbsp; Campus Placement Offers from Dr. Reddy\'s Laboratories Limited</a>',
        },
      },
      {
        id: "oSDoEUMZDf",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/DellEMC-CampusPlacementSelections-Announcement-13112019.pdf">13 Nov 2019 : &nbsp; &nbsp;&nbsp;Campus Placement Offers from Dell EMC</a>',
        },
      },
      {
        id: "7IpyWSWuRh",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/DellEMC-InternshipSelections-Announcement-13112019%20(1).pdf">13 Nov 2019 : &nbsp; &nbsp;&nbsp;Internship Offers from Dell EMC</a>',
        },
      },
      {
        id: "xFi6dBgLX4",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/CocaCola-CampusPlacementSelections-Announcement-11112019.pdf">11 Nov 2019 : &nbsp; &nbsp;Campus Placement Offers from Coca Cola India</a>',
        },
      },
      {
        id: "RYUTm3yM6Q",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ABInBev-Single-InternshipSelections-Announcement-08112019.pdf">8 Nov 2019 :&nbsp; &nbsp; &nbsp; Internship Offers from AB InBev</a>',
        },
      },
      {
        id: "1Gecj5t8dE",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ABInBev-CampusPlacementSelections-Announcement-08112019.pdf">8 Nov 2019 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from AB InBev</a>',
        },
      },
      {
        id: "kJ8FN1AqFb",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AmericanExpress-InternshipSelections-Announcement-05112019.pdf">8 Nov 2019 :&nbsp; &nbsp; &nbsp; Internship Offers from American Express</a>',
        },
      },
      {
        id: "n3ul_aCv5z",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AmericanExpress-CampusPlacementSelections-Announcement-05112019.pdf">8 Nov 2019 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from American Express</a>',
        },
      },
      {
        id: "v8WDPh-eHW",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/CollinsAerospace-CampusPlacementSelections-Announcement-07112019.pdf">7 Nov 2019 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from Collins Aerospace</a>',
        },
      },
      {
        id: "ZPsdZcuK3P",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ISRO-CampusPlacementSelections-Announcement-06112019.pdf">6 Nov 2019 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from ISRO</a>',
        },
      },
      {
        id: "3Gc2vAlN_o",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Micron-CampusPlacementSelections-Announcement-04112019.pdf">4 Nov 2019 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from Micron Technology India Limited Liability Partnership</a>',
        },
      },
      {
        id: "wmuSEt3-FU",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Zauba-InternshipSelections-Announcement-01112019.pdf">1 Nov 2019 :&nbsp; &nbsp; &nbsp; Internship Offers from Zauba Technologies</a>',
        },
      },
      {
        id: "IkpO93hGf9",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Mathworks-Single-CampusPlacementSelections-Announcement-31102019.pdf">31 oct 2019 : &nbsp; &nbsp; Campus Placement Offer from Mathworks</a>',
        },
      },
      {
        id: "2eCZAos289",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Mathworks-InternshipSelections-Announcement-31102019.pdf">31 oct 2019 : &nbsp; &nbsp; Internship Offers from JP Mathworks</a>',
        },
      },
      {
        id: "94Fqof6ykP",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/JPMorganChase-CampusPlacementSelections-Announcement-30102019.pdf">30 oct 2019 : &nbsp; &nbsp; Campus Placement Offers from JP Morgan Chase &amp; Co</a>',
        },
      },
      {
        id: "8JCqdfMZVj",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/JPMorganChase-InternshipSelections-Announcement-30102019.pdf">30 oct 2019 : &nbsp; &nbsp; Internship Offers from JP Morgan Chase &amp; Co.</a>',
        },
      },
      {
        id: "exuHPgNXFB",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Chronus-InternshipSelections-Announcement-29102019.pdf">29 oct 2019 :&nbsp; &nbsp; &nbsp;Internship Offers from Chronus Software India Private Limited</a>',
        },
      },
      {
        id: "I30S3o74M-",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Chronus-CampusPlacementSelections-Announcement-29102019.pdf">29 oct 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Chronus Software India Private Limited</a>',
        },
      },
      {
        id: "8WLmN9iLaV",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MakeMyTrip-CampusPlacementSelections-Announcement-28102019.pdf">28 oct 2019 : &nbsp; &nbsp; Campus Placement Offers from GO-MMT</a>',
        },
      },
      {
        id: "qOd3tBOca2",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Xilinx-CampusPlacementSelections-Announcement-25102019.pdf">25 oct 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Xilinx</a>',
        },
      },
      {
        id: "ojlH3X_Jit",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MorganStanley-InternshipSelections-Announcement-24102019.pdf">24 oct 2019 : &nbsp; &nbsp; Internship Offers from Morgan Stanley</a>',
        },
      },
      {
        id: "OYwtXt7AmQ",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SAPLabs-CampusPlacementSelections-Announcement-23102019.pdf">22 oct 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from SAP Labs</a>',
        },
      },
      {
        id: "AOUWZwfM54",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SAPLabs-InternshipSelections-Announcement-23102019.pdf">22 oct 2019 : &nbsp; &nbsp; Internship Offers from SAP Labs</a>',
        },
      },
      {
        id: "57JrbVScRl",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/FidelityInvestments-CampusPlacementSelections-Announcement-22102019.pdf">22 oct 2019 : &nbsp; &nbsp; Campus Placement Offers from Fidelity Investments</a>',
        },
      },
      {
        id: "kGA6rvJJRU",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/FidelityInvestments-InternshipSelections-Announcement-22102019.pdf">22 oct 2019 : &nbsp; &nbsp; Internship Offers from Fidelity Investments</a>',
        },
      },
      {
        id: "Oa97L6k0Oe",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/OracleIndiaPvtLtd-InternshipSelections-Announcement-21102019.pdf">21 oct 2019 : &nbsp; &nbsp; Internship Offers from Oracle India Private Limited</a>',
        },
      },
      {
        id: "k-6kT9r3_l",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/OracleIndiaPvtLtd-CampusPlacementSelections-Announcement-21102019.pdf">21 oct 2019 : &nbsp; &nbsp; Campus Placement Offers from Oracle India Private Limited</a>',
        },
      },
      {
        id: "YCc80S2xC3",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Ansys-CampusPlacementSelections-Announcement-17102019.pdf">17 oct 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Ansys Software Private Limited</a>',
        },
      },
      {
        id: "LzQsQEYFvT",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Udan-CampusPlacementSelections-Announcement-16102019.pdf">16 oct 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Udaan</a>',
        },
      },
      {
        id: "GZyjkWSCgE",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GEIndiaTC-InternshipSelections-Announcement-15102019.pdf">15&nbsp;oct 2019 : &nbsp; &nbsp; Internship Offers from GE India Technology Centre</a>',
        },
      },
      {
        id: "PuHuLq8ftI",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GEIndiaTC-CampusPlacementSelections-Announcement-15102019.pdf">15&nbsp;oct 2019 : &nbsp; &nbsp; Campus Placement Offers from GE India Technology Centre</a>',
        },
      },
      {
        id: "w_wnEKeF9K",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Citrix-InternshipSelections-Announcement-14102019.pdf">14 oct 2019 :&nbsp; &nbsp; &nbsp; Internship Offers from Citrix R&amp;D India Private Limited</a>',
        },
      },
      {
        id: "pkK6Sgwk5v",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Citrix-CampusPlacementSelections-Announcement-14102019.pdf">14 oct 2019 :&nbsp; &nbsp; &nbsp;&nbsp;Campus Placement Offers from Citrix R&amp;D India Private Limited</a>',
        },
      },
      {
        id: "FuvRWqgAMx",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Arcesium-CampusPlacementSelections-Announcement-11102019.pdf">11 oct 2019 :&nbsp; &nbsp;&nbsp; &nbsp;Campus Placement Offers from Arcesium India Private Limited</a>',
        },
      },
      {
        id: "wVHmoxOE9W",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Arcesium-InternshipSelections-Announcement-11102019.pdf">11 oct 2019 : &nbsp; &nbsp; &nbsp;Internship Offers from Arcesium India Private Limited</a>',
        },
      },
      {
        id: "-_qeuklQwQ",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SalesForce-InternshipSelections-Announcement-10102019.pdf">10 oct 2019 : &nbsp; &nbsp; &nbsp;Internship Offers from Sales Force</a>',
        },
      },
      {
        id: "Yj2SGZYu65",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SalesForce-CampusPlacementSelections-Announcement-10102019.pdf">10 oct 2019 : &nbsp; &nbsp; &nbsp;Campus Placement Offers from Sales Force</a>',
        },
      },
      {
        id: "O3zjlogr_a",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/BlackBuck-CampusPlacementSelections-Announcement-04102019.pdf">4 oct 2019 : &nbsp; &nbsp; &nbsp; &nbsp;Campus Placement Offers from Black Buck</a>',
        },
      },
      {
        id: "69ADhpUbGi",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Qualcomm-CampusPlacementSelections-Announcement-03102019.pdf">4 oct 2019 :&nbsp; &nbsp; &nbsp; &nbsp; Campus Placement Offers from Qualcomm</a>',
        },
      },
      {
        id: "BdWqb1uT-d",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Qualcomm-InternshipSelections-Announcement-03102019.pdf">4 oct 2019 : &nbsp; &nbsp; &nbsp; &nbsp; Internship Offers from Qualcomm</a>',
        },
      },
      {
        id: "FW8RzRVN3J",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Almabase-Single-InternshipSelections-Announcement-01102019.pdf">1 oct 2019 :&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Internship Offer from Almabase India Private Limited</a>',
        },
      },
      {
        id: "Hnga8wygox",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Microsoft-InternshipSelections-Announcement-30092019.pdf">30 sept 2019 :&nbsp; &nbsp; &nbsp;Internship Offers from Microsoft India Private Limited</a>',
        },
      },
      {
        id: "KZN11hIM3m",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Microsoft-CampusPlacementSelections-Announcement-30092019.pdf">30 sept 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Microsoft India Private Limited</a>',
        },
      },
      {
        id: "Y--anzPaFE",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ITC-InternshipSelections-Announcement-27092019.pdf">26 sept 2019 : &nbsp; &nbsp; Internship Offers from ITC Limited</a>',
        },
      },
      {
        id: "7SjwWREiyj",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GoldmanSachs-InternshipSelections-Announcement-26092019.pdf">26 sept 2019 : &nbsp; &nbsp; Internship Offers from Goldman Sachs</a>',
        },
      },
      {
        id: "3oWawAAtUl",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ExxonMobil-CampusPlacementSelections-Announcement-25092019.pdf">25 sept 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offers from Exxon Mobil Services and Technology Private Limited</a>',
        },
      },
      {
        id: "PAc8AyoYph",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Uber-Single-CampusPlacementSelections-Announcement-24092019.pdf">25 sept 2019 :&nbsp; &nbsp; &nbsp;Campus Placement Offer from Uber India Systems Private Limited</a>',
        },
      },
      {
        id: "nLYv5ZW2LJ",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Uber-InternshipSelections-Announcement-24092019.pdf">25 sept 2019 : &nbsp; &nbsp; &nbsp;Internship Offers from Uber India Systems Private Limited</a>',
        },
      },
      {
        id: "XlY1yTPznP",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/DE%20Shaw-InternshipSelections-Announcement-23092019.pdf">23 sept 2019 : &nbsp; &nbsp; &nbsp;Internship Offers from DE Shaw India Private Limited</a>',
        },
      },
      {
        id: "IDggzDwGoy",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/BajajAuto-InternshipSelections-Announcement-20092019.pdf">20 sept 2019 : &nbsp; &nbsp; &nbsp;Internship Offers from Bajaj Auto Limited</a>',
        },
      },
      {
        id: "2Zzwip3OJ0",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/BajajAutoLtd-CampusPlacementSelections-Announcement-20092019.pdf">20 sept 2019 :&nbsp; &nbsp; &nbsp; Campus Placement Offers from Bajaj Auto Limited</a>',
        },
      },
      {
        id: "oaQ0xGDDAf",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Ford-PPO-Selections-Announcement-19092019.pdf">19 sept 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offers from Ford</a>',
        },
      },
      {
        id: "sWFM1JPqkY",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ITC-PPO-Selections-Announcement-18092019.pdf">18 sept 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offers from ITC Limited</a>',
        },
      },
      {
        id: "8e0yi0ZQ0y",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Wipro-Single-PPO-Selections-Announcement-17092019.pdf">17 sept 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offer from Wipro Limited</a>',
        },
      },
      {
        id: "GcjBzPxZ1B",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GoldmanSachs-PPO-Selections-Announcement-13092019.pdf">13 sept 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offers from Goldman Sachs</a>',
        },
      },
      {
        id: "-0L92GvWOL",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Amazon-Single-PPO-Selections-Announcement-12092019.pdf">12 sept 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offer from Amazon</a>',
        },
      },
      {
        id: "7zsQXYU_-V",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AdityaBirla-PPO-Selections-Announcement-11092019.pdf">11 sept 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offers from Aditya Birla Group</a>',
        },
      },
      {
        id: "LsC99dho0I",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Microsoft-PPO-Selections-Announcement-09092019%20v2.pdf">09 sept 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offers from Microsoft India Private Limited</a>',
        },
      },
      {
        id: "BI4psNgrGU",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/RelianceIndustriesLimited-PPO-Selections-Announcement-06092019.pdf">06 sept 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offers from Reliance Industries Limited</a>',
        },
      },
      {
        id: "qx6mAqZu9Z",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Qualcomm-PPO-Selections-Announcement-05092019.pdf">05 sept 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offers from Qualcomm</a>',
        },
      },
      {
        id: "ALkBR2e0Di",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/WesternDigital-PPO-Selections-Announcement-04092019.pdf">04 sept 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offers from Western Digital</a>',
        },
      },
      {
        id: "l6UDC0HjOB",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Pegasystems-PPO-Selections-Announcement-03092019.pdf">03 sept 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offers from Pegasystems</a>',
        },
      },
      {
        id: "q0-ZcXTI7r",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Microland-Single-PPO-Selections-Announcement-30082019.pdf">29 Aug 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offer from Microland Limited</a>',
        },
      },
      {
        id: "6ouWmQMkZh",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Visa-PPO-Selections-Announcement-29082019.pdf">29 Aug 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offers from Visa</a>',
        },
      },
      {
        id: "hmOFIiTCss",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Thermax-Single-PPO-Selections-Announcement-28082019.pdf">28&nbsp;Aug 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offer from Thermax Limited</a>',
        },
      },
      {
        id: "ZHMT_LzQu-",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/DEShaw-PPO-Selections-Announcement-27082019.pdf">27 Aug 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offers from DE Shaw Group</a>',
        },
      },
      {
        id: "ORPgxemYkn",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Optum-PPO-Selections-Announcement-26082019.pdf">26 Aug 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offers from Optum</a>',
        },
      },
      {
        id: "DM1Sg3fcqH",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Rupeek-Single-PPO-Selections-Announcement-22082019.pdf">22 Aug 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offer from Rupeek Fintech Private Limited</a>',
        },
      },
      {
        id: "N23qMaVduc",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Salesforce-PPO-Selections-Announcement-21082019.pdf">21 Aug 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offers from Salesforce &nbsp;</a>',
        },
      },
      {
        id: "7HqkfsFYQz",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/TexasInstruments-Single-PPO-Selections-Announcement-20082019.pdf">20 Aug 2019 : &nbsp; &nbsp; &nbsp;Pre Placement Offer from Texas Instruments</a>',
        },
      },
      {
        id: "A-6wcPf_R-",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/JPMorganChase-PPO-Selections-Announcement-19082019.pdf">19 Aug 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offers from JP Morgan Chase &amp; Co</a>',
        },
      },
      {
        id: "hpEooJZbjz",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SAP%20Labs-PPO-Selections-Announcement-16082019.pdf">16 Aug 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offer from SAP Labs India Private Limited</a>',
        },
      },
      {
        id: "VAA9e_ucx2",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MorganStanley-PPO-Selections-Announcement-14082019.pdf">14 Aug 2019 :&nbsp; &nbsp; &nbsp; Pre Placement Offer from Morgan Stanley</a>',
        },
      },
      {
        id: "n92aKzhu0L",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Arcesium-PPO-Selections-Announcement-13082019.pdf">9 Aug 2019 :&nbsp; &nbsp; &nbsp; &nbsp; Pre Placement Offers from Arcesium India Private Limited</a>',
        },
      },
      {
        id: "qIN92P_qpX",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/FidelityInvestments-PPO-Selections-Announcement-09082019.pdf">9 Aug 2019 :&nbsp; &nbsp; &nbsp; &nbsp; Pre Placement Offers from Fidelity Investments</a>',
        },
      },
      {
        id: "WbkpCWhBIp",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SamsungRandD-PPO-Selections-Announcement-08082019.pdf">8 Aug 2019 :&nbsp; &nbsp; &nbsp; &nbsp; Pre Placement Offer from Samsung R and D Institute India</a>',
        },
      },
      {
        id: "Bq_3Wn9YwM",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/GEP-PPO-Selections-Announcement-07082019.pdf">7 Aug 2019 : &nbsp; &nbsp; &nbsp; &nbsp;Pre Placement Offer from GEP Solutions</a>',
        },
      },
      {
        id: "doR1Okg00v",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Sprinklr-PPO-Selections-Announcement-06082019.pdf">6 Aug 2019 :&nbsp; &nbsp; &nbsp; &nbsp; Pre Placement Offer from Sprinklr</a>',
        },
      },
      {
        id: "I23OCQJaM3",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Oracle-CampusPlacementSelections-Announcement-17082018.pdf">27 Aug 2018 : &nbsp; &nbsp; &nbsp;Oracle India Private Limited Bangalore Campus Placement Offers</a>',
        },
      },
      {
        id: "Ps0BNcg1CM",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Qualcomm-CampusPlacementSelections-Announcement-16082018.pdf">27 Aug 2018 :&nbsp; &nbsp; &nbsp; Qualcomm India Pvt. Ltd. Hyderabad Campus Placement Offers</a>',
        },
      },
      {
        id: "yr7wRdji1c",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Nutanix-CampusPlacementSelections-Announcement-14082018.pdf">27 Aug 2018 : &nbsp; &nbsp; &nbsp;Nutanix Technologies Bangalore Campus Placement Offers</a>',
        },
      },
      {
        id: "E8r9Gbuhy-",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Salesforce-CampusPlacementSelections-Announcement-14082018.pdf">27 Aug 2018 : &nbsp; &nbsp; &nbsp;Salesforce Hyderabad Campus Placement Offer</a>',
        },
      },
      {
        id: "RrjCJf3UAu",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Sprinklr-CampusPlacementSelections-Announcement-11082018.pdf">27 Aug 2018 : &nbsp; &nbsp; &nbsp;Sprinklr India Pvt. Ltd. Campus Placement Offer</a>',
        },
      },
      {
        id: "XyQNDEJVzx",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Goldman%20Sachs-CampusPlacementSelections-Announcement-12082018.pdf">13 Aug 2018 : &nbsp; &nbsp; &nbsp;Goldman Sachs Campus Placement Offer</a>',
        },
      },
      {
        id: "qqMSUE1ivr",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/DEShaw-CampusPlacementSelections-Announcement-10082018.pdf">13 AUG 2018: &nbsp; &nbsp; &nbsp;DE Shaw India Private Limited Campus Placement Offer</a>',
        },
      },
      {
        id: "Q1JEcuqnAJ",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/MicroSoft-CampusPlacementSelections-Announcement-10082018.pdf">10 Aug 2018 : &nbsp; &nbsp; &nbsp;Microsoft Campus Placement Offer</a>',
        },
      },
      {
        id: "_OKMu9WXef",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/BajajAuto-CampusPlacementSelections-Announcement-08082018.pdf">08 Aug 2018 : &nbsp; &nbsp; &nbsp;Bajaj Auto Ltd. Campus Placement Offer</a>',
        },
      },
      {
        id: "jTv5uKc1uE",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/L&amp;TImmersionProgram-PPO-Selections-Announcement.pdf">05 Aug 2018 : &nbsp; &nbsp; &nbsp;L&amp;T Immersion Program Pre-Placement Offer</a>',
        },
      },
      {
        id: "xCbv6rB1OZ",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/ITTIAM-PPO-Selections-Announcement.pdf">05 Aug 2018 :&nbsp; &nbsp; &nbsp;&nbsp;ITTIAM Systems Pre-Placement Offer</a>',
        },
      },
      {
        id: "1rEQg3qclz",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Qualcomm-PPO-Selections-Announcement.pdf">05 Aug 2018 : &nbsp; &nbsp; &nbsp;Qualcomm Pre-Placement Offer</a>',
        },
      },
      {
        id: "M7qTCtexbI",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/WesternDigital-PPO-Selections-Announcement.pdf">05 Aug 2018 :&nbsp; &nbsp; &nbsp; Western Digital Pre-Placement Offer</a>',
        },
      },
      {
        id: "Ozw6p3s_TE",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Visa-PPO-Selections-Announcement.pdf">05 Aug 2018 : &nbsp; &nbsp; &nbsp;Visa Pre-Placement Offer</a>',
        },
      },
      {
        id: "tLDeVfzWn0",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Goldman%20Sachs-PPO-Selections-Announcement.pdf">05 Aug 2018 : &nbsp; &nbsp; &nbsp;Goldman Sachs Pre-Placement Offer</a>',
        },
      },
      {
        id: "_8-x_oG9kG",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Arcesium-PPO-Selections-Announcement.pdf">05 Aug 2018 :&nbsp; &nbsp; &nbsp; Arcesium Pre-Placement Offer</a>',
        },
      },
      {
        id: "hE1bsL0m80",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Samsung%20R&amp;D%20Institute-PPO-Selections-Announcement-draft.pdf">04 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;Samsung R&amp;D Institute Pre-Placement Offer</a>',
        },
      },
      {
        id: "svZuH0HkMv",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Fidelity%20Investments-PPO-Selections-Announcement.pdf">04 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;Fidelity Investments Pre-Placement Offer</a>',
        },
      },
      {
        id: "2iIzwAoM5d",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AristaNetworkPvtLtd-PPO-Selections-Announcement-draft.pdf">04 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;Arista Networks Pvt.Ltd. Pre-Placement Offer</a>',
        },
      },
      {
        id: "QI1g5pRt25",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Uber-PPO-Selection-Announcement.pdf">04 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;Uber Pre-Placement Offer</a>',
        },
      },
      {
        id: "swhaeqzDv7",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/DEShaw-PPO-Selections-Announcement.pdf">04 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;D E Shaw Group Pre-Placement Offer</a>',
        },
      },
      {
        id: "h7vNlZ0Wl3",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Microsoft-PPO-Selections-Announcement.pdf">04 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;Microsoft Full Time PPO</a>',
        },
      },
    ],
    language: "En",
    _id: "61c951d2f99de5365a9b0291",
    time: 1640583633959,
    version: "2.22.2",
    title: "SELECTIONS",
    url: "/placement/SELECTIONS",
    path: "/placement",
    createdAt: "2021-12-27T05:40:34.053Z",
    updatedAt: "2021-12-27T05:40:34.053Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "n4HRMYV81w",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2019/11/19/sbi-icollect-procedure-18112019.pdf">19 nov 2019 :&nbsp; &nbsp;SBI I Collect Procedure for payment to TAPS Fund</a>',
        },
      },
      {
        id: "qqbkLYpHSq",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSefAL6H1242zXCbGDf4FJKi2gvukLXquWAzkX2SDl4DIIl8RQ/viewform">30 Jul 2019 :&nbsp; &nbsp; PhD Online Registration Form for Campus Placement Drive 2019-20-20</a>',
        },
      },
      {
        id: "5SfjBp6s4W",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSesbJbXDJRIwc6XZuQVl-tYpMpi4F62kHqwfpZiwimfn3SUCQ/viewform">30 Jul 2019 :&nbsp; &nbsp; MTech Online Registration Form for Campus Placement Drive 2019-20</a>',
        },
      },
      {
        id: "0tM3eReSEf",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSdG93z3pz6qmIwlcCrsa54dJOeuZzqI2upVVZAU2YrKCg5LKQ/viewform">30 Jul 2019 :&nbsp; &nbsp; MSc (Tech) Online Registration Form for Campus Placement Drive</a>',
        },
      },
      {
        id: "7gKVaGEn6l",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSe7DTt6d0WG08_SKRmZe5Hb6HM7jKaKiJMPwJev_Tf1VPZ6zg/viewform">30 Jul 2019 :&nbsp; &nbsp; MSc Online Registration Form for Campus Placement Drive 2019-20</a>',
        },
      },
      {
        id: "A1j-IUBYvX",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSeO9QtITYS8lfY1P3_Jrfg88c7dWRKRk32nv_Yp32Bf0kXC1w/viewform">30 Jul 2019 :&nbsp; &nbsp; MCA Online Registration Form for Campus Placement Drive 2019-20</a>',
        },
      },
      {
        id: "nKYRwP3I5L",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSf6dGoiOUJR7ujLVIZ6MkpgryWMqHAA6mkJ3aWlfu--7fwuWQ/viewform">30 Jul 2019 :&nbsp; &nbsp; MBA Online Registration Form for Campus Placement Drive 2019-20</a>',
        },
      },
      {
        id: "S27hE39Lp6",
        type: "paragraph",
        data: {
          text: '<a href="https://forms.gle/YWVzpsXnoNSmLYNeA">30 Jul 2019 :&nbsp; &nbsp; BTech Online Registration Form for Campus Placement Drive 2019-20</a>&nbsp;',
        },
      },
      {
        id: "DSi0JvXqRY",
        type: "paragraph",
        data: {
          text: '<a href="https://goo.gl/forms/SiZGEQtgbnK6j6lC3">06 Feb 2019:&nbsp; &nbsp;MSc Program Students Who received Placement through Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "pHxgy31QKj",
        type: "paragraph",
        data: {
          text: '<a href="https://goo.gl/forms/7sAvSS5y8akeKWEC3">06 Feb 2019:&nbsp; MSc(Tech) Program Students Who received Placement through Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "fk14Vud5Dw",
        type: "paragraph",
        data: {
          text: '<a href="https://goo.gl/forms/Prb2UtgL6CqPdsZF2">06 Feb 2019:&nbsp; MBA Program Students Who received Placement through Campus Placement Drive 2018-19</a><a href="https://goo.gl/forms/1GxzUe2eTH88paRj1">06 Feb 2019:&nbsp; MCA Program Students Who received Placement through Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "l953Ti5R8L",
        type: "paragraph",
        data: {
          text: '<a href="https://goo.gl/forms/6Vi0q1DwACClywZk1">06 Feb 2019:&nbsp; MTech Program Students Who received Placement through Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "foCbKYlUI7",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSeY1GiqdWvMAwksNPkEupfcYFDEBFX9lLBSOEVbKVqV_a1rNA/viewform">06 Feb 2019:&nbsp; BTech Program Students Who received Placement through Campus Placement Drive 2018-19.</a>',
        },
      },
      {
        id: "qXJVoj5dVj",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSd6v6sVhsfPKCvU8Ia2dXVrVJvK39KaIkZHt273CJP-4v1qVw/viewform">30 Jul 2018:&nbsp; &nbsp;MBA Online Registration Form for Campus Placement Drive 2018-19.</a>',
        },
      },
      {
        id: "i8sbQ81CyJ",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLScO1MFPHILaO4L1Q0DLeZQxWkmY-80fG6X6zsZzjorPEcWKrw/viewform">30 Jul 2018:&nbsp; &nbsp;MCA Online Registration Form for Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "8ujYVFLlLB",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSds47aRwYSwR7Cfg_HZbX3kQKF-BXqNUued36vl9eajRh-xNQ/viewform">30 Jul 2018:&nbsp; &nbsp;M.Sc. Online Registration Form for Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "U8Dl2Y6jsa",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSdxJ8bgWPS_n2p4u-8he-SOXbwI6dSnAXWoOjEJZ1T9zJV-KA/viewform">30 Jul 2018:&nbsp; &nbsp;M.Sc. (Tech.) Online Registration Form for Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "rjHLviPw84",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSd36C9CvD4yKtvyurOONewc_z9sGgJN7VsoiUQYkvkHalKvjQ/viewform">30 Jul 2018: &nbsp;&nbsp;M.Tech. Online Registration Form for Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "YRYnEzCNM6",
        type: "paragraph",
        data: {
          text: '<a href="https://docs.google.com/forms/d/e/1FAIpQLSerryxle3AhEc1xNeYUC-MtQWq7hEO7GKrsML9D6kMOb0lbGg/viewform">30 Jul 2018: &nbsp; B.Tech Online Registration Form for Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "oK3CH28xLi",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/UES_ADVT_2019.doc">26 Jul 2018: &nbsp; INDIAN NAVY - UNIVERSITY ENTRY SCHEME</a>',
        },
      },
      {
        id: "nK9aRAodwI",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/InvitationToStudents.pdf">18 Jul 2018:&nbsp; &nbsp;Welcome to Training and Placement Section Campus Placement Drive 2018-19</a>',
        },
      },
      {
        id: "42ZHt1XdtX",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/SBCollectProcedure.pdf">16 Jul 2018:&nbsp; SBCollect TAPS FUND Payment Procedure</a>',
        },
      },
      {
        id: "EbfgobKFuy",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Placement%20Procedure%20Final.pdf">13 Jul 2018:&nbsp; Placement Procedure&nbsp;</a>',
        },
      },
    ],
    language: "En",
    _id: "61c951fcf99de5365a9b0293",
    time: 1640583675923,
    version: "2.22.2",
    title: "TnP ANNOUNCEMENTS",
    url: "/placement/TnPANNOUNCEMENTS",
    path: "/placement",
    createdAt: "2021-12-27T05:41:16.011Z",
    updatedAt: "2021-12-27T05:41:16.011Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "oEirZk122X",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/07/24/humanities-and-social-sciences-nitw-presentation-24072018.pptx">24 Jul 2018: &nbsp; Department of Humanities and Social Sciences</a>',
        },
      },
      {
        id: "Fbj6_AfQzZ",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/07/24/chemistry-nitw-presentation-24072019.ppt">24 Jul 2018:&nbsp; &nbsp;Department of Chemistry</a>',
        },
      },
      {
        id: "PysYwMeq74",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/07/22/som-nitw-presentation-22072018.pptx">22 Jul 2018:&nbsp; &nbsp;School of Management</a>',
        },
      },
    ],
    language: "En",
    _id: "61c95218f99de5365a9b0295",
    time: 1640583703940,
    version: "2.22.2",
    title: "Departments Profile",
    url: "/placement/DepartmentsProfile",
    path: "/placement",
    createdAt: "2021-12-27T05:41:44.023Z",
    updatedAt: "2021-12-27T05:41:44.023Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "BsBPc1EqdW",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Barklays-Competion-14082018.pdf">14 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;Barclays India Hiring Challenge for 2019 Graduates</a>',
        },
      },
      {
        id: "bwKwz7fTU6",
        type: "paragraph",
        data: {
          text: '<a href="https://www.hackerrank.com/interview/interview-preparation-kit?utm_source=National+Institute+of+Technology+Warangal&amp;utm_medium=email&amp;utm_campaign=interviewprep_college_mail">04 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;Hacker Rank Interview Preparation Kit for Campus Placements</a>',
        },
      },
      {
        id: "ZWVD0jeXZT",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Philips%20Data%20Science%20Hackathon.pdf">04 Aug 2018 :&nbsp;&nbsp;&nbsp;&nbsp; Philips Data Science Hackathon</a>',
        },
      },
      {
        id: "JLwsvPr5ac",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/RIVIGO%20Coding%20Championship%202018.pdf">02 Aug 2018 : &nbsp; &nbsp; RIVIGO Coding Championship 2018</a>',
        },
      },
      {
        id: "dWWogfTkWG",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/TCS-GlobalCodeContest-01082018.pdf">01 Aug 2018 : &nbsp;&nbsp;&nbsp;&nbsp;TCS Global Coding Contest CodeVita Season VII</a>',
        },
      },
      {
        id: "SUbwSGghTl",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/EntrepreneuroftheMonth.pdf">27 Jul 2018: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;STUDENT ENTREPRENEUR OF THE MONTH CHALLENGE</a>',
        },
      },
      {
        id: "v0p1fwoPWI",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/AsianBootCamp.pdf">27 Jul 2018:&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;Aspiring and Budding Entrepreneurs, this one is for you! Presenting to you, The Asian Entrepreneurship Boot-Camp 2018</a>',
        },
      },
      {
        id: "Z8FNgbJV7O",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/BloomBerg-Quint.pdf">24 Jul 2018:&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;Competitive Initiative by Boomberg|Quint Campus@BQ for MBA Students</a>',
        },
      },
      {
        id: "WhyyUDxNxk",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Google-Women-Techmakers-Program-2018.pdf">23 Jul 2018:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google India Women Techmakers Program 2018</a>',
        },
      },
      {
        id: "RXp_Oxmv8A",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/NOVARTIS%20BIO%20Camp%202018.pdf">22 Jul 2018:&nbsp; &nbsp; &nbsp; &nbsp;Novartis Biotechnology Leadership Camp (BioCamp)</a>',
        },
      },
      {
        id: "WAIZbD1rNt",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/Google-Code%20Jams%20Kick%20Start%20Competiton-Announcement.pdf">21 Jul 2018:&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google Code Jam\'s Kick Start Online Program</a>',
        },
      },
      {
        id: "hudn6wH4NU",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/TESCO-Competiton-Announcement.pdf">21 Jul 2018:&nbsp; &nbsp; &nbsp; &nbsp;TESCO Technology Bengaluru Presents Codeathon 2018</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9522ff99de5365a9b0297",
    time: 1640583727007,
    version: "2.22.2",
    title: "Student Competitions",
    url: "/placement/StudentCompetitions",
    path: "/placement",
    createdAt: "2021-12-27T05:42:07.103Z",
    updatedAt: "2021-12-27T05:42:07.103Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "mALcrLzNiy",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2019/08/22/brochure_msc_msc.pdf">22 Aug 2019 : &nbsp; &nbsp;MSc Program Brochure on Mathematics and Scientific Computing</a>',
        },
      },
    ],
    language: "En",
    _id: "61c95257f99de5365a9b0299",
    time: 1640583767064,
    version: "2.22.2",
    title: "Program Brochures",
    url: "/placement/ProgramBrochures",
    path: "/placement",
    createdAt: "2021-12-27T05:42:47.155Z",
    updatedAt: "2021-12-27T05:42:47.155Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "VJ3QJMJRTp",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/CCPD-AuditReport-26052020.PDF">26 May 2020 :&nbsp; &nbsp; Audit Report of &nbsp;the Centre for Career Planning and Developement (Formerly TAPS) for the year 2019-20</a><br><br>',
        },
      },
      {
        id: "VIbrABdeDb",
        type: "paragraph",
        data: {
          text: '<a href="http://172.20.0.248:9000/media/TAPS%20AuditedStatement%202018-19%20oct%203-converted.pdf">3 oct 2019 :&nbsp; &nbsp; &nbsp; &nbsp;Audit Report of Training and Placement Section for the year 2018-19</a>',
        },
      },
    ],
    language: "En",
    _id: "61c95270f99de5365a9b029b",
    time: 1640583792288,
    version: "2.22.2",
    title: "Audit Reports",
    url: "/placement/AuditReports",
    path: "/placement",
    createdAt: "2021-12-27T05:43:12.375Z",
    updatedAt: "2021-12-27T05:43:12.375Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "YCjrC-9tBW",
        type: "header",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Dept_wise_Research_Funds_2021-11-27-15-5-35.pdf`>Click here</a>',
          level: 6,
        },
      },
    ],
    language: "En",
    _id: "61c988efc410df627c707bcb",
    time: 1641453088839,
    version: "2.22.2",
    title: "Dept wise Research Funds",
    url: "/rd/dwrf",
    path: "/rd",
    createdAt: "2021-12-27T09:35:43.179Z",
    updatedAt: "2022-01-06T07:09:38.444Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "VaxhOlGn80",
        type: "header",
        data: { text: "R&amp;D Downloads", level: 3 },
      },
      {
        id: "s-QsciD9rc",
        type: "list",
        data: {
          style: "ordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/documents-for-proprietary-items.pdf">Documents for Proprietary items</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-1-basic-approval.doc">FORM FOR BASIC APPROVAL&nbsp; FOR&nbsp; PROCUREMENT OF NCS</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-2-calling-sealed-quoations1-to-open-in-dpc.doc">Format for calling sealed quoations1</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-2-calling-sealed-quoations2-to-open-in-spc.doc">Format for calling sealed quoations2&nbsp;</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-3-opening-of-sealed-quotation-in-dpc.doc">Format for &nbsp;opening of sealed quotation in DPC</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-3-opening-of-sealed-quotation-in-spc.doc">Format for opening of sealed quotation in SPC</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-4-purchase-proposal.doc">Format for Purchase Proposal</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-5-audit-vetting.doc">Format for Audit Vetting</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-6-purchase-order1-within-warangal-jurisdiction.doc">Format for Purchase Order&nbsp;(Within Warangal Jurisdiction)</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-6-purchase-order3-for-foreign-items.doc">Format for &nbsp;Purchase Order&nbsp;(Outside Warangal &amp; Within India)</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-6-purchase-order3-for-foreign-items_6qqrMqe.doc">Format for Purchase Order3 (for Foreign Items)</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-7-proprietory-nature-certificate-from-deptfor-proprietory-items.doc">Format for Tender Document-Tender Schedule (Price Bid)</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-8-tender-document-tender-schedule-price-bid_geXZldp.doc">Format for &nbsp;Tender Document-Tender Schedule (Price Bid)</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-9-tender-document.doc">Format for Tender Document</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-10-conditions-for-supply-of-items.doc">Format for Conditions for supply of items</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-11-registration-of-firms-for-supply-of-items.doc">Format for Registration of firms for supply of items</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-12-registration-of-the-firm-intimation.doc">Format for Registration of the firm-Intimation</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-13-justification-to-write-off-the-items.doc">Format for Justification to write-off the items</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-14-suppliers-registration-form.doc">Format for Supplier\'s Registration Form</a>',
            '<a href="https://nitw.ac.in/media/uploads/2017/01/04/format-for-15-suppliers-renewal-registration-form.doc">Format for Supplier\'s Renewal Registration Form</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c98974c410df627c707bcf",
    time: 1642496617719,
    version: "2.22.2",
    title: "R&D Downloads",
    url: "/RD2021/Downloads",
    path: "/RD2021",
    createdAt: "2021-12-27T09:37:56.003Z",
    updatedAt: "2022-01-18T09:03:37.204Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9XAJFskaQs",
        type: "header",
        data: { text: "Statutory Policies", level: 3 },
      },
      {
        id: "TXt7HrkQrs",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Statutory_Policies_2021-11-27-15-19-6.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c98c19c410df627c707bd7",
    time: 1642445011778,
    version: "2.22.2",
    title: "Statutory Policies",
    url: "/ad/StatutoryPolicies",
    path: "/ad",
    createdAt: "2021-12-27T09:49:13.286Z",
    updatedAt: "2022-01-17T18:43:32.449Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "0rVBaaxJaE",
        type: "header",
        data: { text: "Organization Chart", level: 3 },
      },
      {
        id: "2lxBmnQgJb",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Organization_Chart_2021-11-27-15-21-34.pdf`>Click Here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c98d16c410df627c707bdc",
    time: 1642444258064,
    version: "2.22.2",
    title: "Organization Chart",
    url: "/oc/OrganizationChart",
    path: "/oc",
    createdAt: "2021-12-27T09:53:26.653Z",
    updatedAt: "2022-01-17T18:30:58.656Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "_qqkc7p0mF",
        type: "header",
        data: { text: "Institute Annual Report", level: 3 },
      },
      {
        id: "mX5UAsYtJ5",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2020/06/16/nitw-_english.pdf">Annual Report 2018-19 (English)</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/06/16/nitw-_-hindi.PDF">Annual Report 2018-19 (Hindi)</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2019/03/15/annual-report-english-2017-18.pdf">Annual Report : 2017-2018</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2018/01/05/annual-report-2016-17.pdf">Annual Report : 2016-2017</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/annual-report-2015-20161.pdf">Annual Report : 2015-2016</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/annual-report-2014-20151.pdf">Annual Report : 2014-2015</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/annual-report-2013-2014-web.pdf">Annual Report : 2013-2014</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/pdfs/Annual%20Report%20and%20Annual%20Accounts-2012-2013.pdf" target="_blank">Annual Report : 2012-2013</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/pdfs/AnnualReport2011-12.pdf" target="_blank">Annual Report : 2011-2012</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/pdfs/AnnualReport2010-11.pdf" target="_blank">Annual Report : 2010-2011</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/pdfs/AnnualReport2009-10.pdf" target="_blank">Annual Report : 2009-2010</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c98e0cc410df627c707be0",
    time: 1642443933412,
    version: "2.22.2",
    title: "Institute Annual Report",
    url: "/IAR/2021",
    path: "/IAR",
    createdAt: "2021-12-27T09:57:32.050Z",
    updatedAt: "2022-01-17T18:25:34.052Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "joE_itLviu",
        type: "header",
        data: { text: "Former Principals and Directors&nbsp;", level: 3 },
      },
      {
        id: "_mX_r_aRnv",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Former_Principals_and_Directors_2021-11-27-15-36-25.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9903fc410df627c707be7",
    time: 1642444595286,
    version: "2.22.2",
    title: "Former Principals and Directors",
    url: "/FPD/2021",
    path: "/FPD",
    createdAt: "2021-12-27T10:06:55.005Z",
    updatedAt: "2022-01-17T18:36:35.896Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "YQaOivUIm9",
        type: "header",
        data: { text: "Former Chairman", level: 3 },
      },
      {
        id: "lJPZccUUtP",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/fc_2021-11-27-15-47-57.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9927fc410df627c707bf4",
    time: 1642444461309,
    version: "2.22.2",
    title: "Former Chairman",
    url: "/fc/2021",
    path: "/fc",
    createdAt: "2021-12-27T10:16:31.382Z",
    updatedAt: "2022-01-17T18:34:21.925Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "whsAeBPEQX", type: "header", data: { text: "--", level: 2 } },
    ],
    language: "En",
    _id: "61c9937cc410df627c707bfb",
    time: 1640600444268,
    version: "2.22.2",
    title: "Administrative Officers",
    url: "/ao/2021",
    path: "/ao",
    createdAt: "2021-12-27T10:20:44.349Z",
    updatedAt: "2021-12-27T10:20:44.349Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "DUOcgsQhog",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Registrar",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Sri S. Goverdhan Rao</a>',
              "",
            ],
          ],
        },
      },
      { id: "auQXCGreVM", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61c993f2c410df627c707bfd",
    time: 1640600562390,
    version: "2.22.2",
    title: "Registrar",
    url: "/ao/Registrar",
    path: "/ao",
    createdAt: "2021-12-27T10:22:42.476Z",
    updatedAt: "2021-12-27T10:22:42.476Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "KY-_OTqH5L", type: "paragraph", data: { text: "<br>" } },
      {
        id: "VKR39hoVEO",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Chairman (Admissions)",
              '<a href="https://www.nitw.ac.in/faculty/id/16324/">Prof. Anand Kishore Kola&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</a>',
              "8332969703",
            ],
            [
              "2",
              '<editorjs-style style="display: block;\nwhite-space: pre;">Vice Chairman</editorjs-style>(Admissions)',
              '<a href="https://www.nitw.ac.in/faculty/id/16215/">Dr. Venkaiah Chowdary</a>',
              "---",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9947bc410df627c707bff",
    time: 1641452342248,
    version: "2.22.2",
    title: "Admission Officer",
    url: "/ao/AdmissionOfficer",
    path: "/ao",
    createdAt: "2021-12-27T10:24:59.471Z",
    updatedAt: "2022-01-06T06:57:11.848Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "lvXxPxeTiQ",
        type: "paragraph",
        data: {
          text: '<b>Other Officers</b>&nbsp;- <a href=`${MainServerUrl}/static/files/other_officers_jan_27_2022_2022-0-27-14-13-39.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9962bc410df627c707c02",
    time: 1643273073349,
    version: "2.22.2",
    title: "Other Officers",
    url: "/ao/OtherOfficers",
    path: "/ao",
    createdAt: "2021-12-27T10:32:11.553Z",
    updatedAt: "2022-01-27T08:44:31.577Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "1YoILEvYR3",
        type: "header",
        data: { text: "Director", level: 4 },
      },
      {
        id: "-sxuAbBhNU",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Director",
              '<a href="https://www.nitw.ac.in/main/administration/director/" target="_blank">Prof. N.V. Ramana Rao&nbsp; &nbsp;&nbsp;</a>',
              "--",
            ],
          ],
        },
      },
      { id: "76EbCZSPLq", type: "header", data: { text: "Deans", level: 4 } },
      {
        id: "CUnJ-1YFoA",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            [
              "1.",
              "Dean(IR &amp; AA)",
              '<a href="https://nitw.ac.in/faculty/id/16228/" target="_blank">Dr. N. Subrahmanyam</a>',
              "94910 65002",
            ],
            [
              "2.",
              "Dean (Research &amp; Consultancy)",
              '<a href="https://www.nitw.ac.in/faculty/id/16378/" target="_blank">Dr. V. Rajeswar Rao</a>',
              "94901 65364",
            ],
            [
              "3.",
              "Dean(Faculty Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16260/" target="_blank">Dr. G. Amba Prasad Rao</a>',
              "94901 64973",
            ],
            [
              "4.",
              "Dean(Academic)",
              '<a href="https://nitw.ac.in/faculty/id/16389/" target="_blank">Dr. K.N.S. Kasi Viswanadham</a>',
              "94901 65361",
            ],
            [
              "5.",
              "Dean(Student Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16264/" target="_blank">Dr. P. Ravi Kumar</a>',
              "94901 65362",
            ],
            [
              "6.",
              "Dean(Planning &amp; Development)",
              '<a href="https://www.nitw.ac.in/faculty/id/16257/" target="_blank">Dr. P. Bangaru Babu</a>',
              "94901 65363",
            ],
          ],
        },
      },
      {
        id: "uYOmvGaIzU",
        type: "header",
        data: { text: "Advisors", level: 4 },
      },
      {
        id: "6P7PUVDRRb",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            ["1.", "Advisor to the Director (Special Programmes)", "", "--"],
            [
              "2.",
              "Advisor to the Director&nbsp;(Corporate Relations &amp; Resource Mobilization)",
              '<a href="https://www.nitw.ac.in/faculty/id/16200/">Dr. C.S.R.K. Prasad</a>',
              "--",
            ],
            [
              "3.",
              "Advisor to the Director&nbsp;(Quality Assurance and Academic Audit)",
              '<a href="https://www.nitw.ac.in/faculty/id/16248/">Dr. S. Srinivasa Rao</a>',
              "--",
            ],
          ],
        },
      },
      { id: "QMmOqgpTQO", type: "header", data: { text: "Heads", level: 4 } },
      {
        id: "5PX1l80f_d",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            [
              "1.",
              "Head (Civil Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16209/" target="_blank">Dr. P. Rathish Kumar</a>',
              "94901 65341",
            ],
            [
              "2.",
              "Head (Electrical Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16234/" target="_blank">Dr. M. Sailaja Kumari</a>',
              "94901 65342",
            ],
            [
              "3.",
              "Head (Mechanical Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16278/" target="_blank">Dr. Adepu Kumar&nbsp;</a>',
              "94901 65343",
            ],
            [
              "4.",
              "Head(Electronic &amp; Comm. Engg)",
              '<a href="https://www.nitw.ac.in/faculty/id/16301/">Dr. P. Sreehari Rao</a>',
              "94901 65344",
            ],
            [
              "5.",
              "Head (Metallurgy &amp; Materials Engg.)",
              '<a href="https://www.nitw.ac.in/faculty/id/16922/" target="_blank">Dr. T. Mahesh Kumar</a>',
              "94901 65345",
            ],
            [
              "6.",
              "Head (Chemical Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16325/">Dr. S. Srinath</a>',
              "94901 65346",
            ],
            [
              "7.",
              "Head (Computer Science Engineering)",
              '<a href="https://www.nitw.ac.in/faculty/id/16934/https://www.nitw.ac.in/faculty/id/16342/" target="_blank">Dr. S. Ravi CHandra</a>',
              "94901 65347",
            ],
            [
              "8.",
              "Head (Biotechnology)",
              '<a href="https://www.nitw.ac.in/faculty/id/16351/">Dr.Korrapati Narasimhulu</a>',
              "94901 65348",
            ],
            [
              "9.",
              "Head (School of Management)",
              '<a href="https://nitw.ac.in/faculty/id/16407/">Dr. M. Ravindar Reddy</a>',
              "9490165349",
            ],
            [
              "10.",
              "Head (Mathematics)",
              '<a href="https://nitw.ac.in/faculty/id/16396/">Dr. Hari Ponnamma Rani</a>',
              "94901 65350",
            ],
            [
              "11.",
              "Head (Physics)",
              '<a href="https://www.nitw.ac.in/faculty/id/16364/" target="_blank">Dr. D. Dinakar</a>',
              "94901 65351",
            ],
            [
              "12.",
              "Head (Chemistry)",
              '<a href="https://www.nitw.ac.in/faculty/id/16382/" target="_blank">Dr. Vishnu Shanker</a>',
              "94901 65352",
            ],
            [
              "13.",
              "Head (Humanities &amp; Social Sciences)",
              '<a href="https://www.nitw.ac.in/faculty/id/16617/" target="_blank">Dr. K. Madhavi</a>',
              "94901 64798",
            ],
            [
              "14.",
              "Head (Computer Centre)",
              '<a href="https://www.nitw.ac.in/faculty/id/16307/" target="_blank">Dr. V.V. Mani</a>',
              "94901 64790",
            ],
            [
              "15.",
              "Head (Physical Education)",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Dr. P. Ravikumar</a>',
              "94910 85287",
            ],
          ],
        },
      },
      { id: "ojgCn-aKVY", type: "paragraph", data: { text: "<br>" } },
      {
        id: "LD5rrnNA3L",
        type: "header",
        data: { text: "Associate Deans", level: 4 },
      },
      {
        id: "iDsyRWP5tP",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            [
              "1.",
              "Associate Dean (Faculty Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16277/" target="_blank">Dr. G. Naga Srinivasulu</a>',
              "9491296456",
            ],
            [
              "2.",
              "Associate Dean (Student Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16213/" target="_blank">Dr. M. Heera Lal</a>&nbsp; &nbsp;&nbsp;<a href="https://nitw.ac.in/faculty/id/16345/" target="_blank">Dr. R. Padmavathy</a>',
              "8332969517&nbsp; 9491211159",
            ],
            [
              "3.",
              "Associate Dean&nbsp; (Academic - PG &amp;  Ph.D.)&nbsp;",
              '<a href="https://www.nitw.ac.in/faculty/id/16283/">Dr. V. Rajesh Khana Raju</a>',
              "--",
            ],
            [
              "4.",
              "Associate Dean&nbsp; (Academic - UG)",
              "Dr. V. Hari Kumar",
              "--",
            ],
            [
              "5.",
              "Associate Dean&nbsp; (Academic Audit) &nbsp;",
              '<a href="https://www.nitw.ac.in/faculty/id/16280/">Dr. K. Srikanth</a>',
              "8332969720",
            ],
            [
              "6.",
              "Associate Dean (Planning &amp;&nbsp;Development) ",
              '<a href="https://nitw.ac.in/faculty/id/16614/">Dr. P. Venkateswara Rao</a>',
              "9491210789",
            ],
            [
              "7.",
              "Associate Dean&nbsp; (International Relations)  ",
              '<a href="https://nitw.ac.in/faculty/id/16435/">Dr.&nbsp;&nbsp;Jayakrishna</a>',
              "--",
            ],
            [
              "8.",
              "Associate Dean&nbsp; (Alumni Affairs) ",
              '<a href="https://www.nitw.ac.in/faculty/id/16367/">Dr.&nbsp;&nbsp;Abdul Azeem P</a>',
              "8332969473",
            ],
            [
              "9.",
              "Associate Dean&nbsp; (Research and Consultancy) &nbsp;",
              '<a href="https://nitw.ac.in/faculty/id/16217/">Dr. S. Venkateswara Rao</a>',
              "9491085293",
            ],
            [
              "10.",
              "Associate Dean (Library)",
              '<a href="https://www.nitw.ac.in/faculty/id/16237/" target="_blank">Dr. S. Porpandiselvi</a>',
              "9491085289",
            ],
            [
              "11.",
              "Associate Dean&nbsp; (Transportation)",
              '<a href="https://nitw.ac.in/faculty/id/16522/" target="_blank">Dr. P. Vamsi Krishna</a>',
              "8332969491",
            ],
            [
              "12.",
              "Associate Dean (Media Relations) &nbsp;",
              '<a href="https://www.nitw.ac.in/faculty/id/16419/">Dr. K. Kiran Kumar</a>',
              "9491085293",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9982fc410df627c707c0a",
    time: 1643022429264,
    version: "2.22.2",
    title: "Academic Administration",
    url: "/aa/2021",
    path: "/aa",
    createdAt: "2021-12-27T10:40:47.441Z",
    updatedAt: "2022-01-24T11:07:10.197Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "FZlCtVv6HL",
        type: "paragraph",
        data: {
          text: "There shall be a Building &amp; Works Committee for each of the Institute, consisting of the following members namely:",
        },
      },
      {
        id: "Zg_U6kO4ys",
        type: "paragraph",
        data: { text: "(i) the Director, ex-officio Chairman;" },
      },
      {
        id: "BtAtajOpWA",
        type: "paragraph",
        data: {
          text: "(ii) one member nominated by the Central Government nor below the rank of Director or Deputy Secretary;",
        },
      },
      {
        id: "0H5uRIzdeT",
        type: "paragraph",
        data: { text: "(iii) one member nominated by the Board of Governors;" },
      },
      {
        id: "Mh6HS_5OU-",
        type: "paragraph",
        data: { text: "(iv) Registrar, ex-officio, Member Secretary;" },
      },
      {
        id: "79i8u-tsEw",
        type: "paragraph",
        data: {
          text: "(v) Dean, planning &amp; development or similar position - member, and",
        },
      },
      {
        id: "s6MRKkYbs1",
        type: "paragraph",
        data: {
          text: "(vi) One expert each from Civil and Electrical Engineering Wing of Central or State Government or any autonomous body of repute - Member.",
        },
      },
      {
        id: "upbu7FqGLK",
        type: "paragraph",
        data: {
          text: "(vii) Three Special Invitees as per the administrative instructions of the M.H.R.D., GoI., New Delhi. (Out of which, one is from IFD of M.H.R.D., and two experts from State R&amp;B or from CPWD if R&amp;B experts represent the BWC as Members under sub-statute 1(vi) of Statute 12 of the First Statutes of NITs.",
        },
      },
      {
        id: "xFRwv2jNuk",
        type: "paragraph",
        data: {
          text: "At present the following are the Chairman and the Members are representing the Building &amp; Works Committee of the Institute.",
        },
      },
      {
        id: "ZGsEFn2T-w",
        type: "paragraph",
        data: {
          text: "<b>DETAILS OF CHAIRMAN AND MEMBERS OF THE BUILDING AND WORKS COMMITTEE OF N.I.T. WARANGAL AS PER STATUTE 12 OF THE FIRST STATUTES OF NITs &amp; ADMIN. INSTRUCTIONS OF MHRD VIDE Lr.No.F.23-14/2010-TS.III, DATED 23-08-2010</b>",
        },
      },
      {
        id: "ApHf_IkgoZ",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "SL. NO.",
              "RULE NO",
              "NAME, DESIGNATION &amp; ADDRESS",
              "POSITION",
              "E-MAIL ID, PHONE &amp;&nbsp;FAX NOS.",
            ],
            [
              "1",
              "&nbsp;12 (1) (i)",
              '<a href="https://www.nitw.ac.in/main/administration/director/"><editorjs-style style="\n">Prof. N.V. Ramana Rao</editorjs-style></a><editorjs-style style="display: block;\nwhite-space: pre;">Director </editorjs-style>NIT Warangal - 506 004.',
              "Ex-officio Chairman",
              "<editorjs-style>director@nitw.ac.in</editorjs-style>",
            ],
            [
              "2",
              "&nbsp; 12 (1) (ii)",
              '<a href="https://www.nitw.ac.in/main/administration/bogbwcfc/"><editorjs-style style="">Shri Madan Mohan</editorjs-style></a><editorjs-style style="display: block;\nwhite-space: pre;">DDG (HE) </editorjs-style>Department of&nbsp; Higher&nbsp; Education, M.H.R.D., GoI,&nbsp; New Delhi',
              "Member",
              "<editorjs-style>mmohan.edu@nic.in&nbsp;</editorjs-style>Phone: 011-23381484 Mobile: 997103555",
            ],
            ["3", "12 (1) (iii)", "", "Member", ""],
            [
              "4",
              "12 (1) (iv)",
              '<a href="https://nitw.ac.in/main/" target="_blank"><editorjs-style style="">Shri S. Goverdhan Rao</editorjs-style></a><editorjs-style style="display: block;\nwhite-space: pre;">&nbsp;Registrar </editorjs-style>&nbsp;,N.I.T.,&nbsp; Warangal.&nbsp;&nbsp;',
              "Member Secretary",
              "registrar@nitw.ac.in",
            ],
            [
              "5",
              "&nbsp;12 (1) (v)",
              "Dr. P. Bangaru Babu Dean (Planning &amp; Development) N.I.T., Warangal – 506 004. Telangana &nbsp; &nbsp;",
              "Member",
              "bangaru@nitw.ac.in",
            ],
            [
              "6",
              "&nbsp;12 (1) (vi)",
              "Superintending Engineer (Civil), Vijayawada Central Circle, Central Public Works Dept.  Vijayawada  .&nbsp;",
              "Member",
              "",
            ],
            [
              "7",
              "&nbsp; -do-",
              "Superintending Engineer (Electical), Cental Public Works Dept., Nirman  Bhawan, Sultan Bazar, Hyderabad – 500 095.&nbsp; &nbsp;",
              "Member",
              "",
            ],
            [
              "8",
              "&nbsp;Admin. instructions of MHRD&nbsp; &nbsp;&nbsp;",
              "",
              "Special Invitee",
              "",
            ],
            ["9", "Admin. instructions of MHRD", "", "Special Invitee.", ""],
            ["10", "Admin. instructions of MHRD", "", "Special Invitee.", ""],
          ],
        },
      },
      {
        id: "Hggz9ZdtgI",
        type: "paragraph",
        data: {
          text: "Statute 12 of the First Statutes of National Institutes of Technology.",
        },
      },
      { id: "xgCGHvMzKt", type: "paragraph", data: { text: "<br>" } },
      {
        id: "TWJYXCowlp",
        type: "header",
        data: {
          text: "POWERS AND FUNCTIONS OF THE BUILDING AND WORKS COMMITTEE&nbsp;:",
          level: 6,
        },
      },
      {
        id: "epfxjNSrOD",
        type: "paragraph",
        data: { text: "(1) The Building and Works Committee shall:-" },
      },
      {
        id: "b5p8gR5JFB",
        type: "paragraph",
        data: {
          text: "i. under the directions of the Board shall carry on construction of all major works, after the necessary administrative approval and expenditure sanction from the Board;",
        },
      },
      {
        id: "AhGn-23ILf",
        type: "paragraph",
        data: {
          text: "ii. have the power to give the necessary administrative approval and expenditure sanction for minor works and works pertaining to repair and maintenance within the approved budgetary provision of the Institute;",
        },
      },
      {
        id: "ov9qNiCgoC",
        type: "paragraph",
        data: {
          text: "iii. cause to prepare estimates of cost of buildings and other capital works minor works, repairs, maintenance and the like;",
        },
      },
      {
        id: "Z4EEedeam2",
        type: "paragraph",
        data: {
          text: "iv. be responsible for making technical scrutiny of the design, estimates and specifications of the material as may be considered necessary;",
        },
      },
      {
        id: "re_cxLwScb",
        type: "paragraph",
        data: {
          text: "v. have the power to settle rates not covered by tender and settle claims and disputes with contractors;",
        },
      },
      {
        id: "7fFMSRbYKp",
        type: "paragraph",
        data: {
          text: "(2) If, in the opinion on the Chairman of the Building and Works Committee, any emergency has arisen which required immediate action to be taken, he shall take such action and report the same to the Building and Works Committee and the Board at their next meeting.",
        },
      },
      {
        id: "vmCH5n5OCS",
        type: "paragraph",
        data: {
          text: "(3)The Building and Works Committee shall also perform such function and exercise such powers as may be entrusted by the Board, from time to time.",
        },
      },
      { id: "XG6J0nzweY", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61c999e6c410df627c707c0f",
    time: 1642052858970,
    version: "2.22.2",
    title: "Building & Works Committee",
    url: "/aa/bwc",
    path: "/aa",
    createdAt: "2021-12-27T10:48:06.980Z",
    updatedAt: "2022-01-13T05:47:39.948Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "3e6lW437Z8",
        type: "header",
        data: { text: "Director", level: 2 },
      },
      {
        id: "jZ9u9t7DWk",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Director",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Prof. N.V. Ramana Rao</a>',
              "--",
            ],
          ],
        },
      },
      { id: "P0cNBxlPhu", type: "header", data: { text: "Deans", level: 2 } },
      {
        id: "IuXZQ-gEe3",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAMES", "PHONE NO."],
            [
              "1",
              "Dean(IR &amp; AA)",
              '<a href="https://www.nitw.ac.in/faculty/id/16261/" target="_blank">Dr. N. Selvaraj</a>',
              "94910 65002",
            ],
            [
              "2",
              "Dean (Research &amp; Consultancy)",
              '<a href="https://www.nitw.ac.in/faculty/id/16378/" target="_blank">Dr. V. Rajeswar Rao</a>',
              "94901 65364",
            ],
            [
              "3",
              "Dean(Faculty Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16260/" target="_blank">Dr. G. Amba Prasad Rao</a>',
              "94901 64973",
            ],
            [
              "4",
              "Dean(Academic)",
              '<a href="https://www.nitw.ac.in/faculty/id/16258/" target="_blank">Dr. A. Venugopal</a>',
              "94901 65361",
            ],
            [
              "5",
              "Dean(Student Welfare)",
              '<a href="https://www.nitw.ac.in/faculty/id/16391/" target="_blank">Dr. J.V. Ramana Murthy</a>',
              "94901 65362",
            ],
            [
              "6",
              "Dean(Planning &amp; Development)",
              '<a href="https://www.nitw.ac.in/faculty/id/16257/" target="_blank">Dr. P. Bangaru Babu</a>',
              "94901 65363",
            ],
          ],
        },
      },
      {
        id: "YPi0QXrRj-",
        type: "header",
        data: { text: "Registrar", level: 2 },
      },
      {
        id: "wzG5GU_rGK",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Registrar",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Sri S. Goverdhan Rao</a>',
              "",
            ],
          ],
        },
      },
      {
        id: "AX13SYiRiy",
        type: "header",
        data: { text: "Director's Office", level: 2 },
      },
      {
        id: "samWssc3AY",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "PA to Director",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">S Surya Prakash</a>',
              "&nbsp;2462001",
            ],
          ],
        },
      },
      {
        id: "Zejw2vin6O",
        type: "header",
        data: { text: "Chief Vigilance Officer", level: 2 },
      },
      {
        id: "D-Ilw1eb1x",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Chief Vigilance Officer",
              '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Sri. S. Goverdhan Rao</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Registrar',
              "Ph: 0870 2468510&nbsp; Fax: 0870 2459547 / 2459119&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Email : cvo_nitw@nitw.ac.in",
            ],
          ],
        },
      },
      {
        id: "cXu5cH1N9C",
        type: "header",
        data: { text: "Engineering &amp; &nbsp;Maintenance Unit", level: 2 },
      },
      {
        id: "iswDD_A2ll",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            [
              "1",
              "Executive Engineer",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. G. Ramesh</a>',
              "83329 69671",
            ],
            [
              "2",
              "Assistant Engineer",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. M. Sardar Singh</a>',
              "94910 65007",
            ],
            [
              "3",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. K. Ravinder</a>',
              "94910 85292",
            ],
            [
              "4",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. S. Venu Gopal</a>',
              "99893 53535",
            ],
            [
              "5",
              "Supervisor",
              '<a href="https://www.nitw.ac.in/main/ENGINEERINGANDMAINTENANCEUNIT/facilities/">Sri. K. Mahendra Kumar</a>',
              "83329 69714",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61c99aa2c410df627c707c12",
    time: 1640602274514,
    version: "2.22.2",
    title: "Central Administration",
    url: "/aa/CentralAdministration",
    path: "/aa/",
    createdAt: "2021-12-27T10:51:14.609Z",
    updatedAt: "2021-12-27T10:51:14.609Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Wq79GtO7X-",
        type: "paragraph",
        data: {
          text: "There shall be a Finance Committee for each Institute consisting of the following members, namely:&nbsp;",
        },
      },
      {
        id: "7Zt-ltUkcb",
        type: "paragraph",
        data: {
          text: "(i) the Chairperson Board of Governors, ex-officio Chairman;",
        },
      },
      {
        id: "Tgd5y51ObS",
        type: "paragraph",
        data: { text: "(ii) The Director, ex-officio member;" },
      },
      {
        id: "zeTR1Ricz_",
        type: "paragraph",
        data: {
          text: "(iii) Joint Secretary dealing with National Institutes of Technology or his nominee and Financial Advisor (Human Resource Development) or his nominee members;",
        },
      },
      {
        id: "ffZzh9YVpC",
        type: "paragraph",
        data: {
          text: "(iv) Two persons nominated by the Board from amongst its members; and",
        },
      },
      {
        id: "1LsbLgay-v",
        type: "paragraph",
        data: { text: "(v) The Registrar, ex-officio, Member-Secretary." },
      },
      {
        id: "fuR8G6r45Z",
        type: "paragraph",
        data: {
          text: "Provided that in addition to the above, the Chairman may, in consultation with the Director, co-opt a member as and when found necessary.",
        },
      },
      {
        id: "KnIEsyqMOM",
        type: "paragraph",
        data: {
          text: "The following table shows the details of present Chairman and Members of Finance Committee of the Institute:",
        },
      },
      {
        id: "6UWKfkq-Lr",
        type: "header",
        data: {
          text: "DETAILS OF THE CHAIRMAN AND THE MEMBERS OF THE FINANCE COMMITTEE OF NATIONAL INSTITUTE OF TECHNOLOGY, WARANGAL",
          level: 6,
        },
      },
      {
        id: "lJN1tA9BOt",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "SL.NO.",
              "POSITION HELD",
              "NAME, DESIGNATION &amp; ADDRESS",
              "E-MAIL ID, PHONE &amp; FAX NOS.",
            ],
            [
              "1",
              "Chairman(Statute 10 (1) (i) )",
              '<b><editorjs-style style="">Prof. N.V. Ramana Rao&nbsp;</editorjs-style></b>Chairman In-Charge,N.I.T., Warangal - 506004.',
              "<editorjs-style>director@mail.nitw.ac.in</editorjs-style>",
            ],
            [
              "2",
              "Member(Statute 10 (1) (ii) )",
              '<b><editorjs-style style="">Prof. N.V. Ramana Rao&nbsp;</editorjs-style></b>D<editorjs-style>irector ,</editorjs-style>NIT., Warangal - 506 021.',
              "director@mail.nitw.ac.in",
            ],
            [
              "3",
              "Member(Statute 10 (1) (iii) )",
              '<b><editorjs-style style="">Dr. S. S. Sandhu, IAS&nbsp;</editorjs-style></b>Additional Secretary (TE) &amp; CVODept. of Higher Education,Ministry of Human Resource Development,Govt. of India, Shastri Bhavan,New Delhi-110 015.&nbsp; /<b>Shri Madan mohan</b>Addl. Director General (TE)',
              "",
            ],
            [
              "4",
              "Member(Statute 10 (1) (iii) )",
              '<b><editorjs-style style="">Ms Darshana M Dabral&nbsp;</editorjs-style></b>Jt. Secretary &amp; Financial Advisor,&nbsp; Dept. of Higher Education,Ministry of Human Resource Development,&nbsp;&nbsp;&nbsp; Govt. of India, Shastri Bhavan,&nbsp;New Delhi - 110 015.&nbsp;&nbsp;',
              "",
            ],
            ["5", "Member(Statute 10 (1) (iv)", "", ""],
            [
              "6",
              "Member(Statute 10 (1) (iv)",
              '<b><editorjs-style style="">Dr. N.V. Umamahesh&nbsp;</editorjs-style></b>Professor,Department of Civil Engineering,National Institute of Technology,Warangal - 506 004',
              "mahesh@nitw.ac.in",
            ],
            [
              "7",
              "Member Secretary(Statute 10 (1) (v)",
              '<editorjs-style style=""><b>Sri S. Goverdhan Rao</b></editorjs-style>&nbsp;Registrar,N.I.T., Warangal.&nbsp;&nbsp;<b></b>',
              "registrar@nitw.ac.in",
            ],
          ],
        },
      },
      {
        id: "iOYdkW5PL1",
        type: "paragraph",
        data: {
          text: "Sub-Statute (1) of Statute 10 of the First Statutes of National Institutes of Technology.",
        },
      },
      {
        id: "uYzlVDqOfh",
        type: "header",
        data: { text: "POWERS OF THE FINANCE COMMITTEE :", level: 5 },
      },
      {
        id: "nmesPCl7QA",
        type: "paragraph",
        data: { text: "The Finance Committee shall have power to:-" },
      },
      {
        id: "n7T6OoYM0G",
        type: "paragraph",
        data: {
          text: "(i) examine and scrutinize the annual budget of the Institute prepared by the Director and make recommendations to the Board; and",
        },
      },
      {
        id: "DeDTYYRWcE",
        type: "paragraph",
        data: {
          text: "(ii) give its views and make its recommendations on any financial question affecting the Institute to the Board either on the initiative of the Board or of the Director, or on its own motion.",
        },
      },
      { id: "_uhd5erN-h", type: "paragraph", data: { text: "&nbsp;" } },
      { id: "-4jErxlOfY", type: "paragraph", data: { text: "<br>" } },
      { id: "Whr5qDQh0x", type: "paragraph", data: { text: "<br>" } },
      { id: "AXiMTSbk8H", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61c99d4ac410df627c707c16",
    time: 1642053567304,
    version: "2.22.2",
    title: "FINANCE COMMITTEE",
    url: "/aa/fc2021",
    path: "/aa",
    createdAt: "2021-12-27T11:02:34.909Z",
    updatedAt: "2022-01-13T05:59:28.185Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "NsrVi1qmab",
        type: "paragraph",
        data: {
          text: "The Board of every Institute shall consist of the members as laid down under section 11 of the NITs Act, 2007. At present the following are the members of the Institute:-",
        },
      },
      {
        id: "MFRvuqol3l",
        type: "header",
        data: {
          text: "DETAILS OF THE CHAIRMAN AND THE MEMBERS OF THE BOARD OF&nbsp;GOVERNORS OF NATIONAL INSTITUTE OF TECHNOLOGY, WARANGAL.",
          level: 6,
        },
      },
      {
        id: "jdx9bRnCPJ",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "SL. NO.",
              "POSITION",
              "NAME, DESIGNATION &amp; ADDRESS",
              "E-MAIL ID, PHONE &amp; FAX NOS.",
            ],
            [
              "1",
              "Chairman(Sec. 11 Cl. (a) )",
              '<b><editorjs-style style="">Prof. N.V. Ramana Rao&nbsp;</editorjs-style></b>Chairman In-charge,N.I.T., Warangal - 506004.',
              "director@nitw.ac.in",
            ],
            [
              "2",
              "Member(Sec. 11 Cl. (b) )",
              '<b><editorjs-style style="">Prof. N.V. Ramana Rao&nbsp;</editorjs-style></b>Director,N.I.T., Warangal - 506004.<b></b>',
              "director@nitw.ac.in",
            ],
            [
              "3",
              "Member(Sec. 11 Cl. (c) )",
              '<b><editorjs-style style="">Dr. Sukhbir Singh Sandhu&nbsp;</editorjs-style></b>Addl. Secretary, Dept. of Higher Education, Ministry of Human Resource Development, Govt. of India, Shastri Bhavan, New Delhi-110 015.&nbsp; &nbsp;/<b>Shri Madan mohan&nbsp;</b>Addl. Director General (TE)<b></b>',
              "sandhu.edu@nic.in&nbsp;Phone:011-23381097 (O); Fax:011-23386903 ;",
            ],
            [
              "4",
              "Member(Sec. 11 Cl. (c) )",
              '<b><editorjs-style style="">Ms. Darshana M Dabral</editorjs-style></b>&nbsp;Jt. Secretary &amp; Financial Advisor,Dept. of Higher Education,Ministry of Human Resource Development,Govt. of India, Shastri Bhavan,New Delhi-110 015.&nbsp;&nbsp;<b></b>',
              "Jsfa.edu@gov.in dm.dabral@nic.in&nbsp;Phone: 011-23382696; Phone: 011-24101299 ;",
            ],
            ["5", "Member(Sec. 11 Cl. (d) )", "", ""],
            [
              "6",
              "Member(Sec. 11 Cl. (d) )",
              '<b><editorjs-style style="">Dr. Ravi Kumar Puli&nbsp;</editorjs-style></b>Member Secretary Telangana State Council of science &amp; technology, (TSCOST)4th Floor, Aranya Bhavan, Saifabad, Hyderabad - 500 004<b></b>',
              "pravi@nitw.ac.in",
            ],
            ["7", "Member(Sec. 11 Cl. (e) )", "", ""],
            ["8", "Member(Sec. 11 Cl. (e) )", "", ""],
            [
              "9",
              "Member(Sec. 11 Cl. (f) )",
              '<b><editorjs-style style="">Dr. N.V. Umamahesh&nbsp;</editorjs-style></b>Professor,Department of Civil Engineering,National Institute of Technology, Warangal - 506 004.',
              "mahesh@nitw.ac.in&nbsp;Phone:Mobile:  8332969241&nbsp;",
            ],
            [
              "10",
              "Member(Sec. 11 Cl. (f) )",
              '<b><editorjs-style style="">Dr. K. Ramesh&nbsp;</editorjs-style></b>Associate Professor,Dept. of CSE,National Institute of Technology, Warangal - 506 004.',
              "srirameesh@yahoo.com&nbsp;Phone:Mobile: 9108702462712",
            ],
            [
              "11",
              "Member(Sec. 11 Cl. (g) )",
              '<b><editorjs-style style="\nwhite-space: pre;">Director</editorjs-style></b>&nbsp;Indian Institute of Technology Hyderabad,Ordinance Factory Estate,Yeddumailaram-502 205.',
              "director@iith.ac.in&nbsp; Phone: 040-2301-6002; Phone: 040-2301-6003 ;",
            ],
            [
              "12",
              "Secretary(Sec. 18. Cl. (2) )",
              '<b><editorjs-style style="">Sri S. Goverdhan Rao&nbsp;</editorjs-style></b>Registrar,National Institute of Technology, Warangal - 506 004.<b></b>',
              "registrar@nitw.ac.in",
            ],
          ],
        },
      },
      {
        id: "qOO13dkxwm",
        type: "header",
        data: { text: "POWERS OF BOARD OF BOARD :", level: 6 },
      },
      {
        id: "dc3PppvGFJ",
        type: "paragraph",
        data: {
          text: "In addition to the powers provided under sub-section (1) of section 13 of the NITs Act, 2007, the Board shall be empowered:",
        },
      },
      {
        id: "jqAIzmDKGN",
        type: "paragraph",
        data: {
          text: "i. to abolish, re-designate or change the nomenclature of any post in the Institute.",
        },
      },
      {
        id: "qvbtWsCHpX",
        type: "paragraph",
        data: {
          text: "ii. To make, modify or cancel the statutes with the approval of the Visitor from time to time. Provided that the new Statute, additions or amendments of existing Statutes shall be applicable only after the assent of the Visitor; and",
        },
      },
      {
        id: "pZMso0gdpr",
        type: "paragraph",
        data: {
          text: "iii. To make, modify and cancel all or any ordinances on the recommendation of the Finance Committee or Senate of the Institute subject to the condition that making, modification and cancellation shall not be in contravention of the Act and (or) Statutes.<br><br>",
        },
      },
      {
        id: "4RtD06F39d",
        type: "header",
        data: {
          text: "POWERS OF THE CHAIRPERSON, BOARD OF GOVERNORS:",
          level: 6,
        },
      },
      {
        id: "3x3vakwh1v",
        type: "paragraph",
        data: {
          text: "In addition to the powers provided in the Act, the Chairperson of the Board of Governors shall have the following powers as laid down under Statute 14 of the First Statutes of NITs, namely:-",
        },
      },
      {
        id: "BBmQ9h0nH-",
        type: "paragraph",
        data: {
          text: "(i) he shall have the power to fix, on the recommendations of the Selection Committee, the initial pay of an incumbent at a stage higher than the minimum of the scale in respect of posts to which the appointments can be made by the Board under the provisions of the Act:",
        },
      },
      {
        id: "KWgmw1Awcw",
        type: "paragraph",
        data: {
          text: "(ii) he shall have the power to send members of the staff except the Director of the Institute for training or for a course of instruction outside India subject to such terms and conditions as may be laid down by the Board from time to time. Incidentally the visit abroad by the Director shall be approved by the Chairman, National Institutes of Technology Council:-",
        },
      },
      {
        id: "crDQ8eRzP_",
        type: "paragraph",
        data: {
          text: "(iii) he shall execute the contract of service between the Institute and the Director or Deputy Director on behalf of the Central Government, but he shall not be personally liable of anything under such contract; and",
        },
      },
      {
        id: "ymrYv7PeuR",
        type: "paragraph",
        data: {
          text: "In emergent cases, the Chairperson may exercise the powers of the Board and inform the Board of the action taken by him for confirmation and ratification.",
        },
      },
    ],
    language: "En",
    _id: "61c99fe1c410df627c707c18",
    time: 1642054099163,
    version: "2.22.2",
    title: "Board of Governers",
    url: "/aa/bog",
    path: "/aa",
    createdAt: "2021-12-27T11:13:37.404Z",
    updatedAt: "2022-01-13T06:08:20.086Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "w_DdTdpz1E",
        type: "paragraph",
        data: {
          text: "As per Section 14 of the NITs Act, 1007, the Senate of every Institute shall consist of the following persons, namely:",
        },
      },
      {
        id: "ipLyThDtde",
        type: "paragraph",
        data: {
          text: "(a) the Director, ex-officio, who shall be the Chairman of the Senate;",
        },
      },
      {
        id: "U6WiyTQDET",
        type: "paragraph",
        data: { text: "(b) the Deputy Director, ex-officio;" },
      },
      {
        id: "8-vQ476Sxf",
        type: "paragraph",
        data: {
          text: "(c) the Professors appointed or recognized as such by the Institute for the purpose of imparting instructions in the Institute;",
        },
      },
      {
        id: "VvRlMNjQ2L",
        type: "paragraph",
        data: {
          text: "(d) three persons, one of whom shall be woman, not being employees of the Institute, to be nominated by the Chairperson in consultation with the Director, from amongst educationalists of repute, one each from the field of science, engineering and humanities; and",
        },
      },
      {
        id: "KF7VqwWcee",
        type: "paragraph",
        data: {
          text: "(e) such other members of the staff as may be laid down in the Statutes.",
        },
      },
      {
        id: "ce6vz7tkFx",
        type: "header",
        data: {
          text: "DETAILS OF THE CHAIRMAN AND THE EXTERNAL MEMBERS OF SENATE OF NATIONAL INSTITUTE OF TECHNOLOGY, WARANGAL",
          level: 6,
        },
      },
      {
        id: "eeoKV7AFDN",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "S.NO",
              "SECTION",
              "NAME, DES. &amp; ADDRESS",
              "POSITION HELD",
              "E-MAIL ID, PHONE &amp; FAX NOS",
            ],
            [
              "1",
              "14 (a)",
              '<a href="https://www.nitw.ac.in/main/administration/director/"></a><editorjs-style><b><a href="https://www.nitw.ac.in/main/administration/director/">Prof. N.V. Ramana Rao</a>&nbsp;</b></editorjs-style> Director,&nbsp; National Institute of&nbsp;Technology,&nbsp;Warangal - 506 004',
              "Chairman",
              '<editorjs-style style="">director@nitw.ac.in</editorjs-style>',
            ],
            [
              "2",
              "14 (d)",
              '<a href="https://www.nitw.ac.in/main/Academic%20Administration/nitw/" target="_blank"></a><editorjs-style><editorjs-style><b><a href="https://www.nitw.ac.in/main/Academic%20Administration/nitw/" target="_blank">Prof. Sujata Patel</a>&nbsp;</b></editorjs-style></editorjs-style>\n\nPresident, Indian Sociological Society (2016-17) Professor, Department of Sociology, University of Hyderabad,&nbsp; Hyderabad\n\n',
              "External Member",
              '<editorjs-style style="">Patel.sujata09@gmail.com</editorjs-style>',
            ],
            [
              "3",
              "14 (d)",
              '<b><a href="https://www.nitw.ac.in/main/Academic%20Administration/nitw/">Prof. O.R. Jaiswal</a>&nbsp;</b> &nbsp; &nbsp; &nbsp;Dept of Applied Mechanics, VNIT, Nagpur\n\n',
              "External Member",
              "<editorjs-style>orjaiswal@apm.vnit.ac.in</editorjs-style>",
            ],
            [
              "4",
              "14(d)",
              '<b><a href="https://www.nitw.ac.in/main/Academic%20Administration/nitw/">Prof. P.S. Roy</a>&nbsp;</b>&nbsp;\n\nNASI Senior Scientist Platinum Jubilee, Fellow Center for Earth &amp; Space Sciences University of Hyderabad,&nbsp; Hyderabad\n\n',
              "External Member",
              "Psroy13@gmail.com",
            ],
          ],
        },
      },
      {
        id: "eG4qluzOJM",
        type: "paragraph",
        data: {
          text: "Section 14 of the National Institutes of Technology Act, 2007 (Act 29 of 2007).",
        },
      },
      {
        id: "q5LU5wr_mq",
        type: "header",
        data: { text: "SENATE - FUNCTIONS &amp; POWERS&nbsp;:", level: 6 },
      },
      {
        id: "fJHF5R08u6",
        type: "paragraph",
        data: {
          text: "(A)&nbsp;FUNCTIONS: The Senate of an Institute shall have the control and general regulation, and be responsible for the maintenance of standards of instruction, education and examination in the Institute and shall exercise such other powers and perform such other duties as may be conferred or imposed upon it by the Statutes.",
        },
      },
      {
        id: "qAX7nHDoHh",
        type: "paragraph",
        data: {
          text: "(B)&nbsp;POWERS: The Senate shall have the powers to:-",
        },
      },
      {
        id: "9WL3zCTIb8",
        type: "paragraph",
        data: {
          text: "(i) frame and revise curricula and syllabi for the course of studies for the various Departments and Centres;",
        },
      },
      {
        id: "C0c7PvVpdW",
        type: "paragraph",
        data: {
          text: "(ii) make arrangements for the conduct of examinations, appointment of examiners, moderators, tabulators and other matters relating to the examinations;",
        },
      },
      {
        id: "dxWLqj0gm4",
        type: "paragraph",
        data: {
          text: "(iii) declare the results of the examinations or to appoint Committees or Officers to do so and to make recommendations to the Board regarding conferment or grant of degrees, diplomas and other academic distinctions or titles;",
        },
      },
      {
        id: "xANCXslkNe",
        type: "paragraph",
        data: {
          text: "(iv) appoint Advisory Committees or Expert Committees or both for the Departments or Centres of the Institute to make recommendations on academic matters connected with the working of the Departments or Centres;",
        },
      },
      {
        id: "OAPheGq66m",
        type: "paragraph",
        data: {
          text: "(v) appoint Committees from amongst the members of the Senate, other teachers of the Institute and experts from outside to advise on such specific and important academic matters as may be referred to any such Committee by the Senate.",
        },
      },
      {
        id: "DBX8aZnIK3",
        type: "paragraph",
        data: {
          text: "(vi) consider the recommendations of the Advisory Committees attached to various Departments or Centres and that of Experts and other Committees and take such action (including the making of recommendations to the Board) as warranted by each case)",
        },
      },
      {
        id: "cZ3AkhtylH",
        type: "paragraph",
        data: {
          text: "(vii) make periodical review of the activities of the Departments or Centres and take appropriate action (including the making of recommendations to the Board);",
        },
      },
      {
        id: "a2q-Zt6BP1",
        type: "paragraph",
        data: {
          text: "(viii) supervise the working of the Library of the Institute.",
        },
      },
      {
        id: "WYTuuhIPii",
        type: "paragraph",
        data: {
          text: "(ix) promote research and academic development or activity within the Institute and seek reports on such research or academic development or activity from the persons engaged therein;",
        },
      },
      {
        id: "2y-DO4bXSO",
        type: "paragraph",
        data: {
          text: "(x) provide for the inspection of the class rooms, Laboratories, Library and the Residential Hostels;",
        },
      },
      {
        id: "GjK70dsO3c",
        type: "paragraph",
        data: {
          text: "(xi) plan co-curricular activities of the students of the Institute.",
        },
      },
      {
        id: "QQmkj4MNVj",
        type: "paragraph",
        data: {
          text: "(xii) award stipends, scholarships, medals and prizes and make other awards in accordance with such conditions as may be attached to the awards;",
        },
      },
      {
        id: "psngSOX6vu",
        type: "paragraph",
        data: {
          text: "(xiii) make recommendations to the Board with regard to the creation or restructuring of Departments or Programmes or Centres and the abolition of existing Departments or centres thereof;",
        },
      },
      {
        id: "OoUtXe4Nm7",
        type: "paragraph",
        data: {
          text: "(xiv) make recommendations to the Board of disseminate knowledge through distance learning mode to various parts of the State or country or abroad; and",
        },
      },
      {
        id: "YRZVKu8odf",
        type: "paragraph",
        data: {
          text: "(xv) invite upto two student representatives during discussion of general nature nor involving policy or disciplinary matters in the Senate meetings.",
        },
      },
      {
        id: "nWjnVvd3x_",
        type: "header",
        data: {
          text: "CHAIRMAN OF THE SENATE TO EXERCISE POWERS IN EMERGENCY&nbsp;:",
          level: 6,
        },
      },
      {
        id: "1iUCxuvhsL",
        type: "paragraph",
        data: {
          text: "If, in the opinion of the Chairman of the Senate, any emergency has arise which requires immediate action, he may take such action as he deems necessary and shall report the same for approval to the Senate in its next meeting.",
        },
      },
    ],
    language: "En",
    _id: "61c9a0adc410df627c707c1a",
    time: 1642405565905,
    version: "2.22.2",
    title: "Senate",
    url: "/aa/Senate",
    path: "/aa",
    createdAt: "2021-12-27T11:17:01.876Z",
    updatedAt: "2022-01-17T07:46:06.339Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "gbuu_Vi_hV",
        type: "header",
        data: {
          text: "Public Information Officers &amp; Appellate Authority (PIO&amp;AA)",
          level: 5,
        },
      },
      {
        id: "h1H8cwjB_3",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Public_Information_Officers_&amp;_Appellate_Authority_2021-11-27-16-11-56.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9a16ec410df627c707c1f",
    time: 1641452601874,
    version: "2.22.2",
    title: "PIO&AA",
    url: "/aa/PIO2021",
    path: "/aa",
    createdAt: "2021-12-27T11:20:14.190Z",
    updatedAt: "2022-01-06T07:01:31.475Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "nYdfdbkX9j",
        type: "image",
        data: {
          file: {
            url: "https://www.nitw.ac.in/media/uploads/2018/11/02/e-and-mu_Iiyot8d.jpg",
          },
          caption: "Engineering And Maintenance Unit",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "ZnlO3v7R5J",
        type: "header",
        data: {
          text: "ENGINEERING AND MAINTENANCE UNIT&nbsp;::&nbsp; NIT WARANGAL&nbsp;",
          level: 6,
        },
      },
      {
        id: "_PZ_sQfilG",
        type: "paragraph",
        data: {
          text: "The Engineering and Maintenance Unit (E &amp; MU) is one of the important service units which play a vital role providing the requisite infrastructure facilities and all other amenities in the campus.&nbsp;",
        },
      },
      {
        id: "itH2Vef1p2",
        type: "paragraph",
        data: {
          text: "The office of&nbsp;E &amp; MU&nbsp;is responsible for construction and maintenance of buildings (Academic, Residential, Hostel, Guest House, Student Activity Centers etc;), Water Supply System, Roads, Gardens, Electrical Supply, Sports Grounds and Waste Disposal Management. The E &amp; MU Office prepares the proposals, estimates, presentation to the Buildings and Works Committee, tendering and executes the work based on departments/centers/hostels/residential requirements of the institute. The unit is also responsible for allotment of campus residences.",
        },
      },
      {
        id: "7DNViQ2ket",
        type: "paragraph",
        data: {
          text: "The Faculty In-charge is the Head of Engineering Unit who reports to Director / Dean (P&amp;D). Under this unit, One Executive Engineer, One Assistant Engineer and Three Supervisors are working and supporting the Faculty In-charge. One Project Engineer on deputation from state government will look after the day to day activities of Engineering and Maintenance Unit.",
        },
      },
      {
        id: "T0CIa5J7fb",
        type: "header",
        data: { text: "Faculty Incharge:", level: 6 },
      },
      {
        id: "ZA8tz4LUcv",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16259/">Dr. A. Neelakanteswara Rao</a>',
        },
      },
      {
        id: "lVe9cY-yEu",
        type: "paragraph",
        data: {
          text: "Associate ProfessorCivil Engineering Department<br><br>",
        },
      },
      { id: "5L-yASEBoS", type: "header", data: { text: "Staff", level: 5 } },
      {
        id: "yf-PNTpxdH",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.NO", "DESIGNATION", "NAME", "PHONE NO."],
            ["1", "Executive Engineer", "Sri. G. Ramesh", "83329 69671"],
            ["2", "Assistant Engineer", "Sri. M. Sardar Singh", "94910 65007"],
            ["3", "Supervisor", "Sri. K. Ravinder", "94910 85292"],
            ["4", "Supervisor", "Sri. S. Venu Gopal", "99893 53535"],
            ["5", "Supervisor", "Sri. K. Mahendra Kumar", "83329 69714"],
          ],
        },
      },
      {
        id: "FmrQeJ8y1n",
        type: "paragraph",
        data: { text: "Contact:&nbsp; 0870 2462080" },
      },
      {
        id: "QnEFkEbT1P",
        type: "paragraph",
        data: {
          text: '<a href="mailto:eandmu@gmail.com">E- Mail:&nbsp; &nbsp;eandmu@gmail.com</a>',
        },
      },
      { id: "s87R26LAyF", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61c9a210c410df627c707c22",
    time: 1641452277053,
    version: "2.22.2",
    title: "E&MU",
    url: "/aa/emu2021",
    path: "/aa",
    createdAt: "2021-12-27T11:22:56.972Z",
    updatedAt: "2022-01-06T06:56:06.654Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "wFbqG21t0i",
        type: "header",
        data: {
          text: "Procedure for Academic Verification for Genuineness&nbsp;",
          level: 6,
        },
      },
      {
        id: "ajo6HyL1xx",
        type: "paragraph",
        data: {
          text: "Companies or Employers who wish to obtain academic verification done by the Institute may obtain the same by sending an email requisition.&nbsp;",
        },
      },
      {
        id: "Mxs5jm4DvF",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="\n\n"><code class="inline-code"><editorjs-style><editorjs-style>Verification fee of Rs. 500/-(Rupees Five hundred only)&nbsp;</editorjs-style></editorjs-style></code></editorjs-style>',
        },
      },
      {
        id: "Z8JpG62wSd",
        type: "paragraph",
        data: {
          text: "The payment can be made either (i) by Bank demand draft in favour of “Director, NIT Warangal”, payable at Warangal or (ii) by online payment.&nbsp; The details for online payment are",
        },
      },
      {
        id: "ZtyeLhCZZ1",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Account Number", "62114635443"],
            ["Name", "DIRECTOR-FEE- ACCOUNT"],
            ["Name of the Bank", "State Bank of India (SBI)"],
            ["Branch", "NIT Warangal Branch"],
            ["IFSC Code", "SBIN0020149"],
          ],
        },
      },
      {
        id: "qYrx_0mQfq",
        type: "paragraph",
        data: {
          text: "The scanned copy of the payment receipt or original Demand Draft along with complete details of the candidate’s qualifications scanned copies of the certificates. The mail should be addressed to&nbsp;enquiry_exam@nitw.ac.in (or) ad_exam@nitw.ac.in&nbsp;",
        },
      },
      {
        id: "3w0UCNAJS5",
        type: "paragraph",
        data: {
          text: '<mark class="cdx-marker">The academic verification for genuineness takes 4 to 5 working days.</mark>',
        },
      },
    ],
    language: "En",
    _id: "61c9a502c410df627c707c24",
    time: 1640604930387,
    version: "2.22.2",
    title: "Academic Verification for Genuineness",
    url: "/eb/AVGenuineness",
    path: "/eb/",
    createdAt: "2021-12-27T11:35:30.454Z",
    updatedAt: "2021-12-27T11:35:30.454Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "HDN5BLThnE",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2020/07/14/cgpa-conversion.pdf">CGPA to percentage conversation certificate</a>&nbsp;- <a href="https://www.nitw.ac.in/media/uploads/2020/07/14/cgpa-conversion.pdf">Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9a58ec410df627c707c26",
    time: 1641454333642,
    version: "2.22.2",
    title: "CGPA to percentage conversation certificate",
    url: "/eb/cgpa",
    path: "/eb",
    createdAt: "2021-12-27T11:37:50.496Z",
    updatedAt: "2022-01-06T07:30:23.260Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9r3z7K_dIQ",
        type: "paragraph",
        data: {
          text: '<br><a href="https://nitw.ac.in/faculty/id/16393/">Prof D. Srinivasacharya</a><br><br>Professor in charge - Examinations&nbsp;',
        },
      },
      {
        id: "ImfDac1GLS",
        type: "paragraph",
        data: { text: "National Institute of Technology," },
      },
      {
        id: "tRsmXZbaXR",
        type: "paragraph",
        data: { text: "Warangal- 506 004, TS, India" },
      },
      {
        id: "j1Zheq-qdc",
        type: "paragraph",
        data: { text: "Tel : 0870-2462022, 2025,2021" },
      },
      {
        id: "F11XWy9dGD",
        type: "paragraph",
        data: {
          text: 'email:&nbsp;<a href="mailto:ad_exam@nitw.ac.in">ad_exam@nitw.ac.in</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9a5c3c410df627c707c28",
    time: 1640605123119,
    version: "2.22.2",
    title: "Contact Details",
    url: "/eb/contactdetails",
    path: "/eb",
    createdAt: "2021-12-27T11:38:43.186Z",
    updatedAt: "2021-12-27T11:38:43.186Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "7RzGP2EP7t",
        type: "header",
        data: {
          text: "Procedure For Obtaining Duplicate Certificates&nbsp;",
          level: 6,
        },
      },
      {
        id: "FLa1qyoNhJ",
        type: "paragraph",
        data: {
          text: "The duplicate certificate is issued ONLY for genuine cases (if the original degree certificate is lost or stolen) by the examination branch",
        },
      },
      {
        id: "-m-Hpswfs1",
        type: "paragraph",
        data: {
          text: "1. The duplicate grade sheet / consolidated grade sheet/degree certificate shall be issued only on submission of an application along with&nbsp;",
        },
      },
      {
        id: "g1q-OmClO8",
        type: "paragraph",
        data: {
          text: "a)&nbsp; A copy of FIR (First Information Report ) in the police station indicating the place of loss,b) A copy of a Newspaper of repute (National dailies) in which the loss/theft of the certificate is published in ‘Lost Column’ mentioning the city where documents have been lost.&nbsp;",
        },
      },
      {
        id: "ql25rA_qBe",
        type: "paragraph",
        data: {
          text: "c)&nbsp; A non-traceable certificate issued by the Police official, duly signed by the Inspector or Sub-Inspector with round seal (from the area in which the candidate lost the certificate) with dated)&nbsp;Attested copy of the grade sheets/degree certificate / consolidated grade sheet, SSC or 10th&nbsp;class mark sheet and a valid photo identity",
        },
      },
      {
        id: "nTKHtY9Dy8",
        type: "paragraph",
        data: { text: "2. Word ‘Duplicate’ shall be written on top." },
      },
      {
        id: "eBgad_YuZA",
        type: "paragraph",
        data: {
          text: "3. A) The fee for duplicate consolidated grade sheet or degree certificate shall be Rs. 5000/- (Five thousand only).",
        },
      },
      {
        id: "KOWjg3WC8R",
        type: "paragraph",
        data: {
          text: "B) The fee for a duplicate grade sheet is Rs. 500/- (five hundred only) per grade sheet.",
        },
      },
      {
        id: "n_XYem_C3o",
        type: "paragraph",
        data: {
          text: "4. The degree certificate may be sent by post for passed out students at the additional cost for the postal charges on completion of requirements at (1) above.&nbsp;",
        },
      },
      {
        id: "QnCmWQRQlr",
        type: "paragraph",
        data: {
          text: "The payment can be made either (i) by Bank demand draft in favour of “Director, NIT Warangal”, payable at Warangal or (ii) by online payment.&nbsp; The details for online payment are",
        },
      },
      {
        id: "WuG9PWyY8k",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Account Number", "62114635443"],
            ["Name", "DIRECTOR-FEE- ACCOUNT"],
            ["Name of the Bank", "State Bank of India (SBI)"],
            ["Branch", "NIT Warangal Branch"],
            ["IFSC Code", "SBIN0020149"],
          ],
        },
      },
      {
        id: "1LKyyFN6jg",
        type: "paragraph",
        data: {
          text: "All the documents given in (1) along with the DD/transaction receipt or bank statement showing the transaction (in case of online transfer) are to be submitted for processing in the examination branch.",
        },
      },
      {
        id: "L6nbKL4iBp",
        type: "paragraph",
        data: {
          text: "Also, send the scanned copies of the documents in (1) to email&nbsp;",
        },
      },
      {
        id: "a4rJEtBQ6Y",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp;i. ug_exam@nitw.ac.in (for duplicate BTech Certificates)&nbsp;",
        },
      },
      {
        id: "d9jZFikhBf",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; ii. pgphd_exam@nitw.ac.in (for duplicate MTech/MSc/MCA/MBA/PhD certificates)&nbsp;",
        },
      },
      {
        id: "JY-78k2m9B",
        type: "paragraph",
        data: { text: "&nbsp; &nbsp;iii. A copy to ad_exam@nitw.ac.in" },
      },
      {
        id: "bF7yJP6v6s",
        type: "paragraph",
        data: {
          text: "The issue of duplicate grade sheets usually takes 1 to 2 months&nbsp;",
        },
      },
      {
        id: "28wtOjtVE8",
        type: "paragraph",
        data: {
          text: "The issue of duplicate consolidated grade sheet / Degree certificateusually takes 4 to 5 months",
        },
      },
    ],
    language: "En",
    _id: "61c9a628c410df627c707c2a",
    time: 1640605241520,
    version: "2.22.2",
    title: "Duplicate Certificates",
    url: "/eb/DuplicateCertificates",
    path: "/eb",
    createdAt: "2021-12-27T11:40:24.751Z",
    updatedAt: "2021-12-27T11:40:41.622Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "bITm-r99GB",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/Makeup_tie_tables_Final.pdf">Makeup/Re-end Examinations - July 2021 time table and BTech I year End semester and Summer quarter timetables</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/04/27/end-exam-time-tables-may-2021.pdf">Time tables for End Semester Examinations - May 2021 of II / III / IV Yr II Sem BTech, &nbsp; I Yr II Sem MTech, I / II Yr II Sem MSc, II Yr II Sem MCA and End quarter Examinations of MBA I Year IV Qrtr</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/02/26/ugand-pg-mid-semster-exams-time-tables_kk5o3e3.pdf">Time tables for Mid Semester Examinations - March 2021 of BTech II / III / IV Yr II Sem, MTech I Yr II Sem, MSc I / II Yr II Sem, MCA II Yr II Sem and End quarter Examinations of MBA I/II Yr III/VII Qrtr</a>&nbsp;',
          ],
        },
      },
      {
        id: "P51MP3eoJH",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/02/08/mca-mid-sem-exams-time-table.pdf">MCA I yr I Sem Mid Semester examinations time table- February&nbsp; 2021</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/02/05/mba-mid-quarter.pdf">MBA Mid Quarter examinations February&nbsp; 2021 time table&nbsp;for III and VII quarters</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/01/19/b-tech-i-year-mid-time-table.pdf">Mid Semester Examination time tables for&nbsp; BTech, I yr&nbsp; I semester of the AY 20-21</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/12/30/pg-i-sem-end-exams-time-tables.pdf">M. Tech/MSc I year I semester and MBA 2nd quarter&nbsp; End examination time table for the AY 2020-21</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/12/28/b-tech-i-year-minor-i-time-table.pdf">B. Tech I year I semester Minor - I examination time table for the AY 2020-21</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/12/07/end-exams-time-table.pdf">End Semester examination time tables for odd semester of &nbsp;II/III/IV yr BTech, II yr &nbsp;MTech/MSc/MCA , III yr MSc(Tech)/MCA, VI Quarter MBA and Mid Exams of II Quarter MBA</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9a652c410df627c707c2d",
    time: 1640605266190,
    version: "2.22.2",
    title: "Exam Schedules & Time Tables",
    url: "/eb/ESTT",
    path: "/eb",
    createdAt: "2021-12-27T11:41:06.232Z",
    updatedAt: "2021-12-27T11:41:06.232Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "1OLb84YY_H",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Medals_&amp;_Honours_2021-11-27-17-13-29.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9a721c410df627c707c30",
    time: 1641454311551,
    version: "2.22.2",
    title: "Medals & Honours",
    url: "/eb/mh",
    path: "/eb",
    createdAt: "2021-12-27T11:44:33.573Z",
    updatedAt: "2022-01-06T07:30:01.168Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "cMbjzHomdl",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Medium_of_Instruction_2021-11-27-17-16-3.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61c9a781c410df627c707c33",
    time: 1641454268832,
    version: "2.22.2",
    title: "Medium of Instruction",
    url: "/eb/moi",
    path: "/eb",
    createdAt: "2021-12-27T11:46:09.681Z",
    updatedAt: "2022-01-06T07:29:18.442Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "NbtuJiPkdw",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2020/12/05/exam-mode-registrations-dec-2020.pdf">Ïnstructions for the Registration of Backlog Subjects for the Odd Semester of AY 2020-21</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/12/05/orietation_btech1st-year_lms.pdf">PPT of Orientation on LMS of NTW</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/12/05/lms_mid-exam_instructions_students_laptop_ver2.pdf">Instructions for Students to attempt Exam through LMS (Laptop/Desktop)</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/12/05/lms_mid-exam_instructions_students_mobile_ver2.pdf">Instructions for Students to attempt Exam through LMS (Mobile</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9a7bcc410df627c707c35",
    time: 1640605628090,
    version: "2.22.2",
    title: "Notices and Circulars",
    url: "/eb/nac",
    path: "/eb",
    createdAt: "2021-12-27T11:47:08.114Z",
    updatedAt: "2021-12-27T11:47:08.114Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Ld6zzmIYdR",
        type: "header",
        data: {
          text: "The procedure for obtaining Original Certificates&nbsp;",
          level: 6,
        },
      },
      {
        id: "vXNVOhQlQx",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "The semester wise grade sheets shall be issued by the concerned departments after one month of the declaration of the semester results. The grade sheets shall be issued in person only.&nbsp;",
            "Provisional Degree certificate and consolidated grade sheet shall be sent by post by the examination branch on submission of “No Dues Certificate” and stamped self-addressed envelope on completion of degree requirements.&nbsp;&nbsp;",
            "The original degree certificate shall be awarded during the convocation on completion of degree requirements.&nbsp;&nbsp;",
            "If the student is unable to attend the convocation (in absentia), the original degree certificate shall be sent by post to the address given by the candidate or student shall be allowed to collect it in person from the examination branch.&nbsp;",
            "The Transfer cum Migration certificate and the study cum character certificate shall be issued by the Academic section on completion of degree requirements and submission of No dues certificate along with a copy of the provisional certificate.",
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9a7e5c410df627c707c37",
    time: 1640605669882,
    version: "2.22.2",
    title: "Original certificates/Grade sheets",
    url: "/eb/ocgs",
    path: "/eb",
    createdAt: "2021-12-27T11:47:49.908Z",
    updatedAt: "2021-12-27T11:47:49.908Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "PUAg59o9Fi",
        type: "header",
        data: { text: "The Procedure for Obtaining Transcripts", level: 6 },
      },
      {
        id: "HELnr_vrh-",
        type: "paragraph",
        data: {
          text: "Transcripts from National Institute of Technology,Warangal contains the marks sheets of each semester from first to last semester, consolidate grade sheet (if available) and degree certificate.&nbsp;",
        },
      },
      {
        id: "PB92eB1kw_",
        type: "paragraph",
        data: {
          text: "Transcripts are usually used by candidates to apply for permanent residency, higher education, visa, H1B, immigration etc. If you are applying for WES evaluation (ECA) for job, education or Canada PR, you need to apply for transcripts.&nbsp;",
        },
      },
      {
        id: "BBGrQrhs-9",
        type: "paragraph",
        data: { text: "Fee for Transcripts&nbsp;" },
      },
      {
        id: "2JJAtLZwaM",
        type: "paragraph",
        data: {
          text: "Processing fee for the issue of transcripts (10 sets):",
        },
      },
      {
        id: "Yi9ZkVR3iG",
        type: "paragraph",
        data: {
          text: "Rs. 1500/- (within India) / Rs. 2000/- (outside India)&nbsp;",
        },
      },
      {
        id: "BY8X80_PTk",
        type: "paragraph",
        data: {
          text: "The applicants can make the payments either (i) by Bank demand draft in favour of “Director, NIT Warangal”, payable at Warangal or (ii) by online payment.&nbsp; The details for online payment are",
        },
      },
      {
        id: "iSGaO0SZqn",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Account Number", "62114635443"],
            ["Name", "DIRECTOR-FEE- ACCOUNT"],
            ["Name of the Bank", "State Bank of India (SBI),"],
            ["Branch", "NIT Warangal Branch"],
            ["IFSC Code", "SBIN0020149"],
          ],
        },
      },
      {
        id: "-8Q3bj0iXm",
        type: "paragraph",
        data: { text: "<b>Documents Required for Transcripts&nbsp;</b>" },
      },
      {
        id: "MgEn7uY26t",
        type: "paragraph",
        data: {
          text: "The applicant has to submit the copies of the following documents either in person or scanned copies through mail to&nbsp;enquiry_exam@nitw.ac.in (or) ad_exam@nitw.ac.in&nbsp;",
        },
      },
      {
        id: "-0TmiDul3S",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "All original Grade sheets (Including any failed/re-attempt/summer quarter/makeup grade sheets)&nbsp;",
            "Original Consolidated Grade Report&nbsp;",
            "Original Degree certificate&nbsp;",
            "Original DD or Online Payment Receipt&nbsp;",
            "Address to which the transcripts are to be mailed (or) WES reference number&nbsp;",
          ],
        },
      },
      {
        id: "BdvzoKKP1Q",
        type: "paragraph",
        data: { text: "Processing of Transcripts&nbsp;" },
      },
      {
        id: "sY-U_xiLge",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "The fee paid by the applicant will be verified by accounts section and then the copies of the documents submitted will be verified by the examination section.&nbsp;&nbsp;",
            "The verified copies of the certificates (10 sets) will be sent to the Registrar’s Office for the signature by the authorized signatory&nbsp;",
            "The 10 sets of the transcripts (each set in a separate sealed and stamped/signed envelop) in a cover will be sent to the address given by the applicant by post.&nbsp;&nbsp;",
            "The transcripts will be mailed to the addresses of universities if requested in the application.&nbsp;",
            "The transcripts will be sent electronically to WESif requested with the WES reference in the application.&nbsp;",
          ],
        },
      },
      {
        id: "8yR6o7ykb4",
        type: "paragraph",
        data: {
          text: "<b>The processing/issue of transcripts usually takes 10 to 15 working days.</b>",
        },
      },
    ],
    language: "En",
    _id: "61c9a868c410df627c707c39",
    time: 1642441952322,
    version: "2.22.2",
    title: "Transcripts",
    url: "/eb/Transcripts",
    path: "/eb",
    createdAt: "2021-12-27T11:50:00.530Z",
    updatedAt: "2022-01-17T17:52:32.956Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "LwQGZFdkij",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/06/23/viii-sem-grade-report-nitw-policy.pdf">VIII Semester Grade Reports - Policy of the Institute</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9a89ac410df627c707c3b",
    time: 1640605850058,
    version: "2.22.2",
    title: "VIII Semester Grade Reports",
    url: "/eb/sgp",
    path: "/eb",
    createdAt: "2021-12-27T11:50:50.142Z",
    updatedAt: "2021-12-27T11:50:50.142Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "mGL6hmIe1l",
        type: "paragraph",
        data: {
          text: '<b>Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16324/">Prof. Anand Kishore Kola&nbsp;</a>&nbsp;(8332969703)',
        },
      },
      {
        id: "wePXDlFJ1v",
        type: "paragraph",
        data: {
          text: '<b>Vice Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16215/">Dr. Venkaiah Chowdary&nbsp;</a>(--)&nbsp;<br><br><br>',
        },
      },
      {
        id: "Ax0j9TXE_l",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/DASASPOTROUND/2021ADMISSIONS/" target="_blank">20 Dec 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;RESULTS OF DASA SPOT ROUND ADMISSIONS</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/main/BTech/MScIntegrated/">23 Nov 2021 :&nbsp;&nbsp; &nbsp; &nbsp;B.Tech/M.Sc. (Integrated) First Year- 2021</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/I_Year_B._Tech_SII_Admissions_-_Online_Reporting_Schedule_and_Instructions_qCIhKVV.pdf">09 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; I Year B. Tech SII Admissions - Online Reporting Schedule and Instructions&nbsp;&nbsp;</a>&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/I_Year_B._Tech_ICCR_Admissions_-_Online_Reporting_Schedule_and_Instructions.pdf" target="_blank">09 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; I Year B. Tech ICCR Admissions - Online Reporting Schedule and Instructions</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/I_Year_B._Tech_DASA_Admissions_2021-22_-_Instructions_For_Online_Reporting_8OMqq_B2RNAK2.pdf">08 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; I Year B. Tech DASA Admissions 2021-22 - Instructions For Online Reporting&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/B._Tech_and_5_Year_Integrated_MSc_Admissions_through_JoSAA_2021_-_Online_Reporti_cmNzvWY.pdf">08 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; JoSAA\'2021 B. Tech and 5 Year Integrated MSc Admissions - Online Institute Reporting Schedule and&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Instructions&nbsp;&nbsp;&nbsp;</a>',
          ],
        },
      },
      {
        id: "KyrAutOhL8",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/Curriculum_Revision_2020-21_Salient_Points.pdf" target="_blank">29 Sep 2021&nbsp;:&nbsp; &nbsp; &nbsp; Note to Prospective Students- Curriculum Revision, 2021</a>&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Opening_and_Closing_ranks_2020_2.pdf" target="_blank">20 Sep 2021&nbsp;:&nbsp; &nbsp; &nbsp; B.TECH OPENING AND CLOSING RANKS FOR NIT WARANGAL FOR THE ACADEMIC YEAR</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="https://nitw.ac.in/media/uploads/Opening_and_Closing_ranks_2020_2.pdf" target="_blank">2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Fee_Payment_and_Registration-_II_Sem_of_I_B.Tech_and_MCA-_March_2021_2pX9AG5.pdf">28 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;Fee payment and Registration Procedure for II Semester of I Year B.Tech. and I Year MCA students-&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; March 2021</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_to_I_B.Tech.pdf" target="_blank">10 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First year B.Tech. students, who are provisionally admitted in 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/BTechFirstYear/2020/">B.Tech First Year - 2020</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Report_of_DASA_candidates_after_sliding_NIT_Warangal.pdf" target="_blank">02 Dec 2020&nbsp;:&nbsp; &nbsp; &nbsp; Result of DASA 2020 internal sliding for first year B.Tech</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/B.Tech_admissions_link_information.pdf" target="_blank">29 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; B.Tech. Admissions website link information</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2019_JEE_rank_list.pdf" target="_blank">14 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; Opening and Closing ranks of (CRL) of B.Tech. Admissions in 2019</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Stucture-1-converted.pdf" target="_blank">17 Dec 2019&nbsp;:&nbsp; &nbsp; &nbsp; FEE STRUCTURE FOR THE YEAR 2019-20 II SEMESTER FOR ALL CATEGORIES(B.Tech., M.Tech., M.Sc., M.Sc.Tech.,MCA, &amp; MBA)</a>',
            '<a href="https://www.nitw.ac.in/main/FeeRemission/2019/" target="_blank">28 Aug 2019&nbsp;:&nbsp; &nbsp; &nbsp;Fee Remission to the B.Tech. Students</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/I-B.TechAll-14-SectionsTimeTable.pdf" target="_blank">02 Aug 2019&nbsp;:&nbsp; &nbsp; &nbsp;I - B.Tech (All-14 Sections)TimeTable</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/LIST_OF_STUDENTS_WHO_GOT_CHANGE_OF_BRANCH-AY19-20.pdf" target="_blank">25 Jul 2019&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;List of students for change of branch for AY 2019 - 2020</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/B.Tech-Iyr-Instructions__fee_structure-2019-20AY-converted.pdf">12 Jul 2019&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;B.Tech first year reporting dates and details of the fee to be paid</a>',
            '<a href="https://www.nitw.ac.in/main/InductionProgram/2019/" target="_blank">10 Jul 2019&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Induction Program for First Year B.Tech Students (24th July to 4th August, 2019)</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Reporting_schedule_of_DASA_candidates_and_documents_to_be_submitted.pdf" target="_blank">01 Jul 2019&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Reporting_schedule_of_DASA_candidates_and_documents_to_be_submitted.pdf" target="_blank">&nbsp; &nbsp; &nbsp; Reporting schedule of DASA candidates and documents to be submitted</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/web_Copy_-_checklist-a4.pdf" target="_blank">27 Jun 2019&nbsp;:</a><a href="https://www.nitw.ac.in/media/uploads/web_Copy_-_checklist-a4.pdf" target="_blank">&nbsp; &nbsp; &nbsp; Documents to be submitted at the time of reporting for JoSAA candidates</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PUBLIC_NOTICE.pdf" target="_blank">29 May 2019&nbsp;:&nbsp; &nbsp; Public Notice for B.Tech. Admissions</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/academic/cal2018/" target="_blank">30 Apr 2019&nbsp;:</a><a href="https://www.nitw.ac.in/main/academic/cal2018/" target="_blank">&nbsp; &nbsp; &nbsp;Academic calendars for 2019 - 2020</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/001.jpg" target="_blank">29 Apr 2019&nbsp;:</a><a href="https://www.nitw.ac.in/media/uploads/001.jpg" target="_blank">&nbsp; &nbsp; &nbsp;Opening and Closing ranks of B.Tech. Admissions 2018</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/sembtech1/2018/" target="_blank">31 Dec 2018&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/main/sembtech1/2018/" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;First Year B.Tech Time Tables -SECOND Semester 2018-2019</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/1st_year_2nd_sem_faculty_advisors.pdf" target="_blank">22 Dec 2018&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/1st_year_2nd_sem_faculty_advisors.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;list of faculty advisors for registration of first year students</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">21 Dec 2018</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated_1.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;UG - PG - PHD Even semester fee payment details &amp; procedure</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">20 Dec 2018&nbsp;:</a><a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp; Fee Structure for II Semester -2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/USD_DOLLAR_PAYMENT.pdf" target="_blank">20 Dec 2018&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/USD_DOLLAR_PAYMENT.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;Fee payment details for DASA/NONDASA/SAARC/NON-SAARC/CIWG student</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/ECE-AICTE-UG-Practicals-Time_Table.pdf" target="_blank">15 Dec 2018&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/ECE-AICTE-UG-Practicals-Time_Table.pdf" target="_blank">&nbsp; &nbsp; &nbsp;ECE AICTE-UG-Practicals Time Table</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Civil_Engg-AICTE-UG-_Practicals_schedule_1.pdf" target="_blank">15 Dec 2018&nbsp;:&nbsp; &nbsp; &nbsp;Civil Engg-AICTE-UG- Practicals schedule</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Electrical_Engg-AICTE-UG-Practicals.pdf" target="_blank">15 Dec 2018&nbsp;:&nbsp; &nbsp; &nbsp;Electrical Engg-AICTE-UG-Practicals</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/16th/regconvocation/" target="_blank">07 Aug 2018&nbsp;: &nbsp;&nbsp;&nbsp;UG, PG and Ph. D list for 16th convocation registration</a>',
            '<a href="https://www.nitw.ac.in/main/B.Tech/Timetable2018/" target="_blank">04 Aug 2018&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;Ist Year B.Tech Time table for I semester AY 2018 - 2019 for all sections.</a>',
            '<a href="https://www.nitw.ac.in/main/2018Evening/Classes/" target="_blank">03 Aug 2018&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;Evening Classes</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/rotated.pdf" target="_blank">27 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;DASA Internal Sliding List</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/WebNote2Induction_1_q8seHor.pdf" target="_blank">24 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Induction Program for First Year B.Tech Students (23rd July to 4th August, 2018).</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/ALL_THE_I.pdf" target="_blank">23 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Regarding Induction Program for I B.Tech Students</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/first_year_registration_v1_rotated.pdf" target="_blank">22 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Faculty advisers and schedule of registration for B Tech I Year courses</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2018/07/18/notice-josaa-candidates-special-round-v1_yALBAP6.pdf" target="_blank">18 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;CSAB-2018 special rounds: Fee payment for Hostel Allotment</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/revised_instructions_to_students_taking_B.Tech._admission_through_JoSAA-18.pdf" target="_blank">16 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Revised instructions to students taking B.Tech. Admission through JoSAA-18</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/B.Tech_FEE_structure_for_DASA_-2018-19.pdf" target="_blank">09 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Fee structure for DASA students of I B.tech 2018 - 19.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2018/07/09/institute-fee-2018-19-uploaded.pdf" target="_blank">09 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Instructions to B.Tech. Ist year students - for the academic year 2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/payment_procedure.pdf" target="_blank">04 Jul 2018&nbsp;:&nbsp; &nbsp; &nbsp; SB COLLECT payment procedure</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/web_copy_of_B.Tech._I_Yr.fee_structure-2018-19.pdf" target="_blank">26 Jun 2018&nbsp;:&nbsp; &nbsp; &nbsp;B.Tech. Ist year fee structure 2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/note_for_web_site.pdf" target="_blank">22 Jun 2018&nbsp;:&nbsp; &nbsp; &nbsp;Change of dates for registration and for commencement of classwork</a>',
            '<a href="https://nitw.ac.in/media/uploads/JEE_MAIN-2017-18_Opening__Closing_Ranks.pdf" target="_blank">01 May 2018&nbsp;:&nbsp; &nbsp; JEE MAIN 2017 Opening &amp; Closing Ranks at NITW.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/application_for_change_of_branch.pdf" target="_blank">05 Apr 2018&nbsp;:&nbsp; &nbsp; &nbsp;Application for change of Branch for 2017-18</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/B.Tech_rules_and_Regulations-2017-18.pdf" target="_blank">22 Jan 2018&nbsp;:&nbsp; &nbsp; &nbsp;Rules and Regulations of Bachelor of Technology (B.Tech) Program (With effect from 2017 - 2018).</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/CONVOCATION/2017/" target="_blank">28 Oct 2017&nbsp;:&nbsp; &nbsp; &nbsp;List of eligible candidates for 15th CONVOCATION (UG)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/INSTRUCTIONS_TO_STUDENTS_JOIING_B.Tech_program_under_DASA_scheme.pdf" target="_blank">11 Jul 2017&nbsp;:&nbsp; &nbsp; &nbsp;&nbsp;Instructions to students joining B.Tech program under DASA scheme.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/DASA_AND_ICCR_2017-18.pdf" target="_blank">07 Jul 2017&nbsp;:&nbsp; &nbsp; &nbsp;&nbsp;Fee structure for DASA/ICCR students of I B.tech 2017-18.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/07/17/instructions_to_ist_btech_students_to_be_admitted_to_nitw_through_josaa.pdf" target="_blank">21 Jun 2017&nbsp;:&nbsp; &nbsp;&nbsp;&nbsp;Instructions to B.Tech 1st Year students to be admitted to NITW through JOSAA.</a>',
            '<a href="http://www.nitw.ac.in/main/home/#" target="_blank">15 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;change of commencement of class work for 1st &nbsp;B.Tech, 1st &nbsp;M.Tech and 1st &nbsp;M.C.A - reg: The commencement of class work for 1st &nbsp;B.Tech, 1st &nbsp;M.Tech and 1st &nbsp;M.C.A. students is from 24/7/2017.</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/dean_approval-change_of_branch-16-17-net_copy.jpg" target="_blank">14 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;List of students who got change of branch - with effect from the Academic Year 2017-18.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/jee-cut-off-ranks-at-nitw-for-2016-admissions.pdf">8-May-2017 :&nbsp;</a>&nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/jee-cut-off-ranks-at-nitw-for-2016-admissions_Pooqun6.pdf">JEE Cut Off Ranks at NITW For 2016 Admissions</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/change-of-branch-application-form.pdf">24-Mar-2017&nbsp;:&nbsp; &nbsp;Application for change of Branch for 2016-17</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/dasa-ciwg-fee-payment-details-december-2016-session.pdf">12 Dec 2016&nbsp;</a>:&nbsp; &nbsp; B.Tech. DASA - CIWG - Fee payment details<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/dasa-ciwg-fee-payment-details-december-2016-session.pdf">&nbsp;</a>(updated)&nbsp;',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/dasa-payment-procedure.PDF">08 Dec 2016&nbsp;:&nbsp; &nbsp; DASA PAYMENT PROCEDURE</a>',
            '<a href="http://www.nitw.ac.in/main/pay/fee/">08 Dec 2016 :&nbsp; &nbsp; Payment Procedure</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-payment-dates.PDF">08 Dec 2016 :&nbsp; &nbsp;&nbsp;Fee payment dates</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-payment-dates.PDF">08 Dec 2016&nbsp;</a>:&nbsp; &nbsp;&nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-waiver-for-obc-ebc-general-students-payment-details.PDF">Fee waiver for OBC-EBC &amp; General students payment details&nbsp;</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/dasa-payment-procedure.PDF">08 Dec 2016&nbsp;</a>:&nbsp; &nbsp;&nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-waiver-for-sc-st-pwd-students-payment-details.PDF">Fee waiver for SC-ST-PWD students payment details&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/main/academics/feestructure/Btech/" target="_blank">29 Nov&nbsp;2016&nbsp;:&nbsp; &nbsp;Fee Structure For B.Tech Students</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/REMISSION.pdf" target="_blank">28 Sep 2016&nbsp;:&nbsp; &nbsp; Fee Remission for B.Tech I- year students</a>',
          ],
        },
      },
      {
        id: "e-atKuNwJ4",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2016/09/11/scan-dasa-sliding.pdf" target="_blank">22 Jul&nbsp;2016: &nbsp;</a>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2016/09/11/scan-dasa-sliding.pdf" target="_blank">Result of Internal Sliding for DASA 2016 students&nbsp;</a>',
            '<a href="http://nitw.ac.in/nitw-old/announcements/2016/Admissions/DASA%20Details%20of%20certificates%20and%20fee%20particulars_Revised.pdf" target="_blank">12 Jul&nbsp;2016:&nbsp;</a>&nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2016/10/25/dasa-details-of-certificates-and-fee-particulars_revised.pdf" target="_blank">DASA Admissions 2016&nbsp;(Revised)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/09/11/jee-main-2016-details-of-certificates-and-fee-particulars_o8f1AYZ.pdf" target="_blank">JEE Main-2016:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/2016/09/11/jee-main-2016-details-of-certificates-and-fee-particulars_o8f1AYZ.pdf" target="_blank">Candidates allotted to NIT Warangal</a>',
            'For more information on admission to B.Tech Programs(JEE/CSAB/DASA), click&nbsp;<a href="https://www.nitw.ac.in/main/academics/admissions/btechadmissions2016">here</a>&nbsp;',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9a95fc410df627c707c3f",
    time: 1640606047554,
    version: "2.22.2",
    title: "B.Tech",
    url: "/ad2021/BTech",
    path: "/ad2021",
    createdAt: "2021-12-27T11:54:07.625Z",
    updatedAt: "2021-12-27T11:54:07.625Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "EdSAxUq0BW",
        type: "paragraph",
        data: {
          text: '<b>Chairman(Admissions):&nbsp;</b>&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16324/">Prof. Anand Kishore Kola&nbsp;</a>&nbsp;(8332969703)',
        },
      },
      {
        id: "UXg9FUmr8t",
        type: "paragraph",
        data: {
          text: '<b>Vice Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16215/">Dr. Venkaiah Chowdary&nbsp;</a>(--)&nbsp;<br><br><br><br>',
        },
      },
      {
        id: "_sOd9xhIEt",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/M.Tech_-_CCMT-SRNSR_2021_Admissions_-_instructions_for_online_reporting_by_the_c_Oc0HUUP.pdf" target="_blank">14 Sep 2021&nbsp;:&nbsp; &nbsp; &nbsp; M.Tech - CCMT-SRNSR 2021 Admissions - instructions for online reporting by the candidates</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/M._Tech_Sponsored__NFTDC_2021_Admissionv1.pdf">21 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;M. Tech (Sponsored by Army &amp; NFTDC) 2021 Admissions - instructions for online reporting by the&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/M._Tech__Self-Finance_2021_Admissions_-_instructions_for_online_reporting_by_the_v1.pdf">21 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;M. Tech (Self-Finance) 2021 Admissions - instructions for online reporting by the candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/ICCR_2021_Admissions_-_Instructions_for_online_reporting_by_the_candidates_v1.pdf">21 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;ICCR 2021 Admissions - Instructions for online reporting by the candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/CCMT_-_MTech_2021_Admissions_-_instructions_for_online_reporting_by_the_candidates_v1.pdf">20 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;CCMT - MTech 2021 Admissions - instructions for online reporting during 26-27 August by the&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;candidates&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/M._Tech__Self-Finance_2021_Admissions_-_instructions_for_online_reporting_by_the_CLO67Iy.pdf" target="_blank">20 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;M. Tech ( Self-Finance) 2021 Admissions - instructions for online reporting by the candidates</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/MTech_Makeup_reend_results.pdf" target="_blank">20 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;M.Tech Makeup/Re-end results</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/M.Tech2021_Admissions_-_instructions_for_online_reporting_by_the_candidates_1.pdf">14 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;M.Tech 2021 Admissions - instructions for online reporting by all types of M.Tech candidates&nbsp;&nbsp;</a>&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/M._Tech_Sponsored_-_List_of_Provisionally_Selected_Armed_Forces_Officers_2.pdf" target="_blank">14 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;M. Tech 2021 (Sponsored) Admissions - List of Provisionally Selected Armed Forces Officers</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/CCMT_MTech2021_Admissions_-_instructions_for_online_reporting_by_the_candidates.pdf" target="_blank">14 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;CCMT MTech 2021 Admissions - instructions for online reporting by the candidates</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/M.Tech_Self-Finance_-_List_of_Provisionally_Selected_Candidates_and_Instructions.pdf" target="_blank">14 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;M.Tech (Self-Finance) - List of Provisionally Selected Candidates and Instructions</a>&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/CED_MT_Website_Interview_v1.pdf" target="_blank">06 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;Shortlisted Candidates for Interview- M.Tech Self Finance in Civil Engineering</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/ECE_-_List_of_candidates_shortlisted_for_interview.pdf" target="_blank">05 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp; ECE - List of eligible candidates for M. Tech (Self-finance) admissions interview</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Instructions_to_ICCR_-_M.Tech_and_PhD_Students_of_2021-22_Admissions.pdf" target="_blank">28 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Instructions to ICCR - M.Tech and PhD Students of 2021-22 Admissions</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PG_I_sem_Mid_Exam_Time_Table.pdf" target="_blank">10 Nov 2020&nbsp;:&nbsp; &nbsp; &nbsp;Time Tables for Mid-Semester examinations for MTech/MSc I year I semester, Mid exam for MBA VI quarter and end exams for MBA I quarter</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Details_of_fee_to_be_paid_by_MTech_candidates_Revised.pdf" target="_blank">27 Oct 2020&nbsp;:&nbsp; &nbsp; &nbsp;Details of the fee to be paid by M.Tech. candidates (Revised)</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Studnet_volunteers_to_help_newly_admitted_PG_students-1.pdf">20 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp;List of Student Volunteers to help the newly admitted PG &amp; Ph.D students&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_Faculty_Advisers_of_various_PG_programs_at_NIT_Warangal_1.pdf">20 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp;List of Faculty Advisers of different PG programs at NIT Warangal.&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/09/18/step-by-step-instructions-for-wsdc-account-creation.pdf" target="_blank">18 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp;Step-by-step instructions for account creation in WSDC portal for M.Tech, MSc and MBA students admitting in 2020</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/MTech_2020_Online_reporting_instructions_to_the_newly_admitting_students_1.pdf">16 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp;Online reporting instructions to the MTech students admitting in 2020&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Announcement_to_MTech_1.pdf">23 Aug 2020&nbsp;:&nbsp; &nbsp; &nbsp;Announcement to MTech students admitting in 2020&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Details_of_fee_to_be_paid_by_MTech_candidates.pdf" target="_blank">12 Jun 2020&nbsp;:&nbsp; &nbsp; &nbsp;Details of fee particulars for MTech students</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/M.Tech_admissions_link.pdf" target="_blank">27 Apr 2020&nbsp;:&nbsp; &nbsp; &nbsp;M.Tech admissions link</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Stucture-1-converted.pdf" target="_blank">17 Dec 2019&nbsp;:&nbsp; &nbsp; &nbsp;FEE STRUCTURE FOR THE YEAR 2019-20 II SEMESTER FOR ALL CATEGORIES(B.Tech., M.Tech., M.Sc., M.Sc.Tech.,MCA, &amp; MBA)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/M.Tech._internal_sliding_list.pdf" target="_blank">16 Aug 2019&nbsp;:&nbsp; &nbsp;&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/M.Tech._internal_sliding_list.pdf" target="_blank">M.Tech. internal sliding list</a>&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2019/07/26/instructions_to_nsr_candidates_and_details_of_the_fee_to_be_paid_v6_1Hp6iUv.docx">22 Jul 2019&nbsp;:&nbsp; &nbsp; &nbsp; Instructions to CCMT-NSR candidates and details of the fee to be paid&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2019/07/26/procedure-for-internal-sliding-ccmt-v1.pdf">03 Jul 2019&nbsp;: &nbsp; &nbsp; &nbsp;Procedure to be followed for the Internal Sliding students of M.Tech.&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2019/07/08/refund-policy-for-mtech-studets-admitted-in-nit-warangal.pdf">27 Jun 2019&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;Refund Policy for MTech students admitted in NIT Warangal&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Details_of_the_fee_to_be_paid_at_the_time_of_reporting.pdf" target="_blank">13 Jun 2019&nbsp;:</a><a href="https://www.nitw.ac.in/media/uploads/Details_of_the_fee_to_be_paid_at_the_time_of_reporting.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp; Details of payment of balance fee and certificates to be submitted by M.Tech. candidates admitted through CCMT</a>&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/M.Tech._GATE_SCORE_CUT_OFF_admitted_at_NITW.pdf" target="_blank">09 Apr 2019&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/M.Tech._GATE_SCORE_CUT_OFF_admitted_at_NITW.pdf" target="_blank">&nbsp;&nbsp;&nbsp;Opening and Closing GATE scores for M.Tech. admissions for the year 2018-19</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_particulars_for_M.Tech._Admissions__new_.pdf">05 Apr 2019&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;Fee particulars for M.Tech. Admissions&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">21 Dec 2018</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated_1.pdf" target="_blank">&nbsp; &nbsp; UG - PG - PHD Even semester fee payment details &amp; procedure</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">20 Dec 2018&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;Fee Structure for II Semester -2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_candidates_who_get_refund_for_MTech_admission_cancellations.pdf" target="_blank">28 Sep 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;List of candidates who get refund for M.Tech admission cancellation - 2018</a>',
            '<a href="https://www.nitw.ac.in/main/16th/regconvocation/" target="_blank">07 Aug 2018&nbsp;: &nbsp;&nbsp;&nbsp;UG, PG and Ph. D list for 16th convocation registration</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/VACANCY_SEAT_MATRIX_FOR_INSTITUTE_SPOT_ROUND_ON_09.08.2018.pdf" target="_blank">06 Aug 2018&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;Vacancy seat matrix for Institute spot round on 09.08.2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/M.Tech_Modified_Internal_Sliding_List_-_2018.pdf" target="_blank">05 Aug 2018&nbsp;: &nbsp;&nbsp;&nbsp;Updated 1 Year M.Tech Internal Sliding List- August 2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Institute_Spot_Round_for_M.Tech_Admissions__on_09.08.2018.pdf" target="_blank">03 Aug 2018&nbsp;: &nbsp;&nbsp;&nbsp;Institute Spot Round for M.Tech Admissions on 09.08.2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/I_M.Tech_Internal_sliding_application_form.pdf" target="_blank">27 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;M.Tech Internal Sliding Application form (last date 30-07-2018)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Instructions_to_the_M.TechNational_Spot_Round_candidates_taking_admission_throug_WBfCxSF.pdf" target="_blank">05 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Instructions to the M.Tech(National Spot Round) candidates taking admission through CCMT-2018.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Dates_of_Physical_Reporting_and_Registration_of_PG_Programs_for_the_Academic_Yea_Vj2A3GH.pdf" target="_blank">04 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Dates of Physical Reporting and Registration of PG Programs for the Academic Year 2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/payment_procedure.pdf" target="_blank">04 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;SB COLLECT payment procedure</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Structure_CCMT-2018_for_three_rounds_only.pdf" target="_blank">19 Jun 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;CCMT-2018 INSTITUTE Fee Details</a>',
            '<a href="https://nitw.ac.in/media/uploads/PG_FEE_STRUCTURE_FOR_THE_ACADEMIC_YEAR_2018-19.pdf" target="_blank">06 Jun 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;PG Fee structure for the Academic Year 2018-19.</a>',
            '<a href="https://www.nitw.ac.in/main/CONVOCATION/2017/" target="_blank">28 Oct 2017&nbsp;:&nbsp; &nbsp; &nbsp;List of eligible candidates for 15th CONVOCATION (PG)</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/M.TECH_FINAL_SLIDING_LIST_FOR_WEBSITE_NEW.pdf" target="_blank">05 Aug 2017&nbsp;:&nbsp; &nbsp; List of 1st year M.Tech students and allotted branches after internal sliding</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/Sliding_option_form.pdf" target="_blank">03 Aug 2017&nbsp;:&nbsp; &nbsp; M.Tech internal sliding option notice</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/instructions_to_M.Tech_students_taking_admission_in_NSR_through_CCMT2017.pdf" target="_blank">24 Jul 2017&nbsp;:&nbsp; &nbsp; &nbsp; Instructions to students taking M.Tech admission in NSR through CCMT2017.</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/Fee_details_of_other_semesters_for_PG_students_admitted_during_July_2017_session_IeOzaYl.pdf" target="_blank">20 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;Fee details of other semesters for PG students admitted in July 2017.</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/fee_structure_to_be_paid_at_the_time_of_phyusical_reporting__of_m.tech.pdf" target="_blank">16 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;Instructions for M.Tech students reporting at NITW from 26th to 29th June 2017.</a>',
            '<a href="http://www.nitw.ac.in/main/home/#" target="_blank">15 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;change of commencement of class work for 1st &nbsp;B.Tech, 1st &nbsp;M.Tech and 1st &nbsp;M.C.A - reg: The commencement of class work for 1st &nbsp;B.Tech, 1st &nbsp;M.Tech and 1st &nbsp;M.C.A. students is from 24/7/2017.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/11/fee-structure-for-pg-programs.pdf">11 May 2017:&nbsp; &nbsp; &nbsp;Fee Details for candidates admitted into M.Tech(India, Ethiopia and ICCR) for the academic year 2017-18</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/gate-score-cutoff-scores-for-mtech-admissions-in-2016.pdf">08 May 2017:&nbsp; &nbsp; &nbsp;GATE score cutoff scores For M.Tech Admissions In 2016</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/mtech-msc-fees-payment-details.PDF">08 Dec 2016&nbsp;:&nbsp; &nbsp; &nbsp;M.Tech - M.Sc fees payment details</a>',
            '<a href="http://www.nitw.ac.in/main/pay/fee/">08 Dec 2016 :&nbsp; &nbsp; &nbsp;Payment Procedure</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/mtech-msc-fees-payment-details.PDF">08 Dec 2016&nbsp;:</a>&nbsp; &nbsp; &nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-payment-dates.PDF">Fee payment dates</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/mtech-msc-fees-payment-details.PDF">08 Dec 2016&nbsp;:</a>&nbsp; &nbsp; &nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-waiver-for-sc-st-pwd-students-payment-details.PDF">Fee waiver for SC-ST-PWD students payment details&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/main/academics/feestructure/Mtech/" target="_blank">29 Nov&nbsp;2016&nbsp;:&nbsp; &nbsp; &nbsp;Fee Structure For M.Tech&nbsp;Students</a>',
            '<a href="http://nitw.ac.in/nitw-old/index.php/component/content/article/903" target="_blank">04&nbsp;Aug&nbsp;2016:&nbsp;</a>&nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2016/10/25/mtech-seat-matrix-for-spot-round-4thaugust.pdf" target="_blank">M.TECH SEAT MATRIX FOR spot round&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/09/11/sliding-list-2016-ss.pdf" target="_blank">01 Aug&nbsp;2016:&nbsp;</a>&nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2016/10/25/mtech-spot-round-2016-announcement-details-ok.pdf" target="_blank">M.Tech spot round -Announcement, details</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/09/11/sliding-list-2016-ss.pdf" target="_blank">01 Aug&nbsp;2016:&nbsp; &nbsp; &nbsp; Result of M.Tech internal sliding&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/main/academics/admissions/mtechadmissions2016" target="_blank">For M.Tech. Admissions 2016</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/10/25/total-ccmt-2016-fee-structure.pdf">M.Tech (CCMT 2016) candidates allotted to NIT Warangal.</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9a984c410df627c707c41",
    time: 1640606084523,
    version: "2.22.2",
    title: "M.Tech",
    url: "/ad2021/MTech",
    path: "/ad2021",
    createdAt: "2021-12-27T11:54:44.603Z",
    updatedAt: "2021-12-27T11:54:44.603Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "WFldcRM_gf",
        type: "paragraph",
        data: {
          text: '<b>Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16324/">Prof. Anand Kishore Kola&nbsp;</a>&nbsp;(8332969703)',
        },
      },
      {
        id: "k4scQTwzCJ",
        type: "paragraph",
        data: {
          text: '<b>Vice Chairman(Admissions):&nbsp;</b>&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16215/">Dr. Venkaiah Chowdary&nbsp;</a>(--)&nbsp;<br><br><br><br>',
        },
      },
      {
        id: "3FnY7LIxq3",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/MCA_2021_Admissions_-_instructions_for_online_reporting_by_the_newly_admitting_s_RyFYIuD.pdf">27 Sep 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;MCA 2021 Admissions - instructions for online reporting by the newly admitting students&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/ICCR_2021_Admissions_-_Instructions_for_online_reporting_by_the_candidates_v1.pdf">21&nbsp;Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp; ICCR 2021 Admissions - Instructions for online reporting by the candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Fee_Payment_and_Registration-_II_Sem_of_I_B.Tech_and_MCA-_March_2021_2pX9AG5.pdf">28 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp; Fee payment and Registration Procedure for II Semester of I Year B.Tech. and I Year MCA students-&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;March 2021</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_to_MScMBAMCA.pdf" target="_blank">10 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First year M.Sc. / M.Sc.(Tech.) / MCA / MBA students, who are provisionally admitted in 2020</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/MCA_2020_Online_reporting_instructions_to_the_newly_admitting_students__3_.pdf" target="_blank">23 Dec 2020&nbsp;:&nbsp; &nbsp; &nbsp; MCA online reporting instructions for taking admission in December 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Details_of_fee_to_be_paid_by_MCAcandidates.pdf" target="_blank">12 Dec 2020&nbsp;:&nbsp; &nbsp; &nbsp; Details of fee to be paid by MCA students taking admission in 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Announcement_to_MCA_students_admitting_in_Dec_2020.pdf" target="_blank">12 Dec 2020&nbsp;:&nbsp; &nbsp; &nbsp; Announcement to MCA students admitting in December 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Stucture-1-converted.pdf" target="_blank">17 Dec 2019&nbsp;:&nbsp; &nbsp; &nbsp; FEE STRUCTURE FOR THE YEAR 2019-20 II SEMESTER FOR ALL CATEGORIES(B.Tech., M.Tech., M.Sc., M.Sc.Tech.,MCA, &amp; MBA)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/MCA_reporting_dates_details_of_the_balance_fee_to_be_paid_2Lsboqg.pdf">05 Jul 2019&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MCA reporting dates and details of the balance fee to be paid&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_particulars_for_MCA_Admissions.pdf" target="_blank">25 Apr 2019&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Fee_particulars_for_MCA_Admissions.pdf" target="_blank">&nbsp; &nbsp; &nbsp;Fee particulars for MCA 2019 Admissions</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">21 Dec 2018</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated_1.pdf" target="_blank">&nbsp; &nbsp; &nbsp;UG - PG - PHD Even semester fee payment details &amp; procedure</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">20 Dec 2018&nbsp;:</a><a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">&nbsp; &nbsp; &nbsp; Fee Structure for II Semester -2018-19</a>',
            '<a href="https://www.nitw.ac.in/main/16th/regconvocation/" target="_blank">07 Aug 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;UG, PG and Ph. D list for 16th convocation registration</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/VACANCY_SEAT_MATRIX_FOR_INSTITUTE_SPOT_ROUND_ON_09.08.2018.pdf" target="_blank">06 Aug 2018&nbsp;:&nbsp; &nbsp; &nbsp;Vacancy seat matrix for Institute spot round on 09.08.2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Institute_Spot_Round_for_MCA_Admissions__on_09.08.2018.pdf" target="_blank">03 Aug 2018&nbsp;:&nbsp; &nbsp; &nbsp;Institute Spot Round Admissions for MCA on 09.08.2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Dates_of_Physical_Reporting_and_Registration_of_PG_Programs_for_the_Academic_Yea_Vj2A3GH.pdf" target="_blank">04 Jul 2018&nbsp;:&nbsp; &nbsp; &nbsp; Dates of Physical Reporting and Registration of PG Programs for the Academic Year 2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Instructions_to_students_taking_admission_through_NIMCET-2018_sOD3H44.pdf" target="_blank">04 Jul 2018&nbsp;:&nbsp; &nbsp; &nbsp; Instructions to students taking admission through NIMCET-2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/payment_procedure.pdf" target="_blank">04 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;SB COLLECT payment procedure</a>',
            '<a href="https://nitw.ac.in/media/uploads/PG_FEE_STRUCTURE_FOR_THE_ACADEMIC_YEAR_2018-19.pdf" target="_blank">06 Jun 2018&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp; PG Fee structure for the Academic Year 2018-19.</a>',
            '<a href="https://www.nimcet.in/" target="_blank">22 Feb 2018&nbsp;:&nbsp; &nbsp; &nbsp;NIMCET-2018: For admission into MCA for the academic year 2018-19.</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/CONVOCATION/2017/" target="_blank">28 Oct 2017&nbsp;:&nbsp; &nbsp; &nbsp;List of eligible candidates for 15th CONVOCATION (PG)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Structure_NIMCET-2017.pdf" target="_blank">05 Jul 2017&nbsp;:&nbsp; &nbsp; &nbsp; Instructions to candidates seeking M.C.A admissions, July 2017 session.</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/Fee_details_of_other_semesters_for_PG_students_admitted_during_July_2017_session_IeOzaYl.pdf" target="_blank">20 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;Fee details of other semesters for PG students admitted in July 2017.</a>',
            '<a href="http://www.nitw.ac.in/main/home/#" target="_blank">15 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;change of commencement of class work for 1st &nbsp;B.Tech, 1st &nbsp;M.Tech and 1st &nbsp;M.C.A - reg: The commencement of class work for 1st &nbsp;B.Tech, 1st &nbsp;M.Tech and 1st &nbsp;M.C.A. students is from 24/7/2017.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/11/fee-structure-for-pg-programs.pdf">11 May 2017:&nbsp; &nbsp; &nbsp;Fee Details for candidates admitted into MCA for the academic year 2017-18</a>&nbsp;',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/mtech-msc-fees-payment-details.PDF">08 Dec 2016&nbsp;:</a>&nbsp; &nbsp; &nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/msc-tech-mba-mca-phd-fee-payment-detaisl.PDF">MSC Tech-MBA-MCA &amp; PhD Fee payment detaisl</a>',
            '<a href="http://www.nitw.ac.in/main/pay/fee/">08 Dec 2016 :&nbsp; &nbsp; &nbsp;Payment Procedure</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/mtech-msc-fees-payment-details.PDF">08 Dec 2016&nbsp;:</a>&nbsp; &nbsp; &nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-payment-dates.PDF">Fee payment dates</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/mtech-msc-fees-payment-details.PDF">08 Dec 2016&nbsp;:</a>&nbsp; &nbsp; &nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-waiver-for-sc-st-pwd-students-payment-details.PDF">Fee waiver for SC-ST-PWD students payment details&nbsp;</a>',
            '<a href="http://www.nitw.ac.in/main/academics/feestructure/Mca/">29 Nov 2016</a>&nbsp;:&nbsp; &nbsp; &nbsp;<a href="http://www.nitw.ac.in/main/academics/feestructure/Mca/">Fee Structure For &nbsp;M.C.A&nbsp;&nbsp;Students</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/08/14/fee-structure-nimcet-2016.pdf" target="_blank">Fee and documents for admission into I year MCA</a>',
            '<a href="https://www.nitw.ac.in/main/academics/admissions/mcaadmissions2016" target="_blank">Entrance Test and Admission into MCA program of NITs for the academic year 2016-17</a>',
            '<a href="https://www.nitw.ac.in/main/academics/feestructure/Mca/" target="_blank">Fee Structure</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9a9ebc410df627c707c43",
    time: 1640606187144,
    version: "2.22.2",
    title: "M.C.A",
    url: "/ad2021/MCA",
    path: "/ad2021",
    createdAt: "2021-12-27T11:56:27.169Z",
    updatedAt: "2021-12-27T11:56:27.169Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "uxZsRGh_Gk",
        type: "paragraph",
        data: {
          text: '<b>Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16324/">Prof. Anand Kishore Kola&nbsp;</a>&nbsp;(8332969703)',
        },
      },
      {
        id: "CRprt8q_c4",
        type: "paragraph",
        data: {
          text: '<b>Vice Chairman(Admissions):&nbsp;</b>&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16215/">Dr. Venkaiah Chowdary&nbsp;</a>(--)&nbsp;<br><br><br><br>',
        },
      },
      {
        id: "pmPIetYknz",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/MBA_VI_quarter_Timetable.pdf" target="_blank">29 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; MBA VI quarter end examinations timetable December 2021</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/MBA_Mid_Quarter_fXjnTX8.pdf">25 Oct 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;MBA II Year VI Quarter Mid Examinations Timetable, October/November – 2021&nbsp;&nbsp;</a>&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/ICCR_2021_Admissions_-_Instructions_for_online_reporting_by_the_candidates_v1.pdf">21&nbsp;Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp; ICCR 2021 Admissions - Instructions for online reporting by the candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/MBA_2021_-_instructions_for_online_reporting_by_the_candidates_v1.pdf">20 Aug 2021 :&nbsp;&nbsp;&nbsp; &nbsp; MBA 2021 Admissions - instructions for online reporting by the candidates&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/MBA_2021_Admissions_-_instructions_for_online_reporting_by_the_candidates.pdf" target="_blank">14 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp; MBA 2021 Admissions - instructions for online reporting by the candidates</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_provisionally_selected_candidates_for_MBA_-_2021_Admissions_0tVNFP8.pdf" target="_blank">09 Apr 2021&nbsp;:&nbsp; &nbsp; &nbsp; List of provisionally selected candidates for MBA - 2021 Admissions and the instructions to be followed</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/List_of_provisionally_selected_candidates_for_MBA_-_2021_Admissions_0tVNFP8.pdf" target="_blank">by the selected candidates</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/List_of_eligible_candidates_for_GD__PI__MBA_2021_Revised.pdf" target="_blank">15 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;List of Eligible Candidates for GD &amp; PI of MBA Admissions 2021</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_to_MScMBAMCA.pdf" target="_blank">10 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First year M.Sc. / M.Sc.(Tech.) / MCA / MBA students, who are provisionally admitted in 2020</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/main/Advertisement2021/MBA/" target="_blank">24 Feb 2021&nbsp;:&nbsp; &nbsp; &nbsp; Extension of last date for submission of filled-in applications for MBA admissions -2021</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/main/Advertisement2021/MBA/" target="_blank">25 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp; Advertisement for Admission into MBA Programme for the Academic Year 2021-22</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PG_I_sem_Mid_Exam_Time_Table.pdf" target="_blank">10 Nov 2020&nbsp;:&nbsp; &nbsp;Time Tables for Mid-Semester examinations for MTech/MSc I year I semester, Mid exam for MBA VI quarter and end exams for MBA I quarter</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Details_of_fee_structure_for_MBA_candidates_admitted_in_2020.pdf" target="_blank">03 Oct 2020&nbsp;:&nbsp; &nbsp; &nbsp; Details of the fee structure for MBA candidates admitted in 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Studnet_volunteers_to_help_newly_admitted_PG_students-1.pdf">20 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; List of Student Volunteers to help the newly admitted PG &amp; Ph.D students&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_Faculty_Advisers_of_various_PG_programs_at_NIT_Warangal_1.pdf">20 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; List of Faculty Advisers of different PG programs at NIT Warangal.&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/09/18/step-by-step-instructions-for-wsdc-account-creation.pdf" target="_blank">18 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; Step-by-step instructions for account creation in WSDC portal for M.Tech, MSc and MBA students admitting in 2020</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/MBA_2020_Online_reporting_instructions_to_the_newly_admitting_students_1.pdf">16 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; Online reporting instructions to the MBA students admitting in 2020&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Announcement_to_MBA_1.pdf">23 Aug 2020&nbsp;:&nbsp; &nbsp; &nbsp;Announcement to MBA students admitting in 2020&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Rescheduling_of_Physical_Reporting_for_MBA_2020_admissions.pdf" target="_blank">14 Aug 2020&nbsp;:&nbsp; &nbsp; &nbsp;Rescheduling of Physical Reporting dates for MBA 2020 admissions</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/MBA_2020_admissions_list__second_list_.pdf" target="_blank">23 Jun 2020&nbsp;:&nbsp; &nbsp; &nbsp;Second list of provisionally selected candidates for MBA 2020 admissions</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/MBA_2020_admissions_list.pdf" target="_blank">26 May 2020&nbsp;:&nbsp; &nbsp; List of selected candidates for MBA 2020 Admissions</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/MBA_Admissions_-2020_Information_regarding_personal_interview.pdf" target="_blank">12 May 2020&nbsp;:&nbsp; &nbsp; MBA Admissions 2020 - Information regarding Personal Interview</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_eligible_candidates_for_GD__PI__MBA_2020_.pdf" target="_blank">09 Mar 2020&nbsp;:&nbsp; &nbsp; MBA Admissions 2020 - List of eligible candidates for Group Discussion and Personal Interview &amp; Schedule</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/MBA%20_Admissions%20_%202020" target="_blank">20 Jan 2020&nbsp;:&nbsp; &nbsp; &nbsp;MBA Admissions 2020 - Last date of submitting the filled application is 4th March 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Stucture-1-converted.pdf" target="_blank">17 Dec 2019&nbsp;:&nbsp; &nbsp; &nbsp;FEE STRUCTURE FOR THE YEAR 2019-20 II SEMESTER FOR ALL CATEGORIES(B.Tech., M.Tech., M.Sc., M.Sc.Tech.,MCA, &amp; MBA)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Second_list_of_MBA_selected_candidates_sent_to_website_rS7lqUO.pdf">05 May 2019</a>&nbsp;:&nbsp;<a href="https://www.nitw.ac.in/media/uploads/Second_list_of_MBA_selected_candidates_sent_to_website.pdf" target="_blank">&nbsp;&nbsp;Second list of MBA candidates selected for admission for the year 2019-20</a><a href="https://www.nitw.ac.in/media/uploads/Second_list_of_MBA_selected_candidates_sent_to_website_rS7lqUO.pdf">&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_MBA_2019_selected_candidates_for_admission__sent_to_website__qY04O5w.pdf" target="_blank">03 Apr 2019&nbsp;:&nbsp; &nbsp; List of MBA 2019 selected candidates for admissions</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/main/mba2019/shortlisted/" target="_blank">06 Mar 2019&nbsp;:&nbsp;</a><a href="https://nitw.ac.in/main/mba2019/shortlisted/" target="_blank">&nbsp; MBA Admissions 2019 - List of shortlisted candidates for Group Discussion/Personal Interview</a>',
            '<a href="https://www.nitw.ac.in/main/MBAAdmissions/2019/" target="_blank">17 Jan 2019&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/main/MBAAdmissions/2019/" target="_blank">&nbsp; &nbsp;MBA Admissions 2019 - Last date of submitting the filled application is 25th February 2019</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">21 Dec 2018</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated_1.pdf" target="_blank">&nbsp;&nbsp;&nbsp;UG - PG - PHD Even semester fee payment details &amp; procedure</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">20 Dec 2018&nbsp;:</a><a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;Fee Structure for II Semester -2018-19</a>',
            '<a href="https://www.nitw.ac.in/main/16th/regconvocation/" target="_blank">07 Aug 2018&nbsp;: &nbsp;&nbsp;UG, PG and Ph. D list for 16th convocation registration</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/SPOT_ROUND_FOR_MBA__ADMISSIONS__FOR_THE_ACADEMIC_YEAR_2018-19.pdf" target="_blank">12 Jul 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;Spot Round for MBA Admissions for the Academic Year 2018-19.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Dates_of_Physical_Reporting_and_Registration_of_PG_Programs_for_the_Academic_Yea_Vj2A3GH.pdf" target="_blank">04 Jul 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;Dates of Physical Reporting and Registration of PG Programs for the Academic Year 2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/payment_procedure.pdf" target="_blank">04 Jul 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;SB COLLECT payment procedure</a>',
            '<a href="https://nitw.ac.in/media/uploads/PG_FEE_STRUCTURE_FOR_THE_ACADEMIC_YEAR_2018-19.pdf" target="_blank">06 Jun 2018&nbsp;: &nbsp;&nbsp; PG Fee structure for the Academic Year 2018-19.</a>',
            '<a href="https://nitw.ac.in/media/uploads/MBA_-_2018_3rd__Round_Counseling.pdf" target="_blank">04 May 2018&nbsp;:&nbsp;&nbsp; List of candidates called for the Third Round of Counselling for MBA-2018 Admissions.</a>',
            '<a href="https://nitw.ac.in/media/uploads/2nd_Round_Counseling_MBA-2018.pdf" target="_blank">16 Apr 2018&nbsp;:&nbsp;&nbsp; &nbsp;List of candidates called for the Second Round of Counselling for MBA-2018 Admissions.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/FEE_STRUCTURE.pdf" target="_blank">06 Apr 2018&nbsp;:&nbsp;&nbsp;&nbsp; Instructions to students reporting for MBA Admissions during 10th and 11th April 2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_candidates_called_for_1st_round_of_MBA_counselling_at_NITW.pdf" target="_blank">03 Apr 2018&nbsp;:&nbsp; &nbsp;&nbsp;List of candidates called for 1st Round of Counselling for MBA-2018 Admissions</a>',
            '<a href="https://nitw.ac.in/media/uploads/List_of_eligible_candidates_and_schedule_MBA_2018_v1.pdf" target="_blank">27 Feb 2018&nbsp;:&nbsp; &nbsp; List of candidates shortlisted for Group Discussion and Interview for admission to MBA-2018.</a>',
            '<a href="https://www.nitw.ac.in/main/mba/adv2018/" target="_blank">12 Jan 2018&nbsp;:&nbsp; &nbsp; Advertisement for MBA Admissions for the Academic Year 2018-19</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/CONVOCATION/2017/" target="_blank">28 Oct 2017&nbsp;:&nbsp; &nbsp; List of eligible candidates for 15th CONVOCATION (PG)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/web_publishing_of_MBA_last___ROUND_OF_COUNSELLING_6th_July_2017.pdf" target="_blank">06 Jul 2017&nbsp;:&nbsp; &nbsp; &nbsp;Last round counseling for MBA Admissions July 2017 session.</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/Fee_details_of_other_semesters_for_PG_students_admitted_during_July_2017_session_IeOzaYl.pdf" target="_blank">20 Jun 2017&nbsp;:&nbsp; &nbsp; Fee details of other semesters for PG students admitted in July 2017.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/11/fee-structure-for-pg-programs.pdf">11 May 2017:&nbsp; &nbsp; Fee Details for candidates admitted into MBA for the academic year 2017-18</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/web_publishing_of_mba_3rd___round_of_counselling_24th_april_2017_GdzAvEj.pdf" target="_blank">24 Apr 2017&nbsp;:&nbsp; &nbsp;3rd round counseling for MBA Admissions-2017</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/web_publishing_of_mba_2nd__round_of_counselling_12th_april_2017_L7HSBK2.pdf" target="_blank">12 Apr 2017&nbsp;:&nbsp; &nbsp;2nd Round Counseling for MBA Admissions-2017</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/04/12/web_publishing_of_mba_1st_round_of_counselling_30th_march_2017_new_v143QCX.pdf">30-Mar-2017:&nbsp;</a>&nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/04/12/web_publishing_of_mba_1st_round_of_counselling_30th_march_2017_new.pdf">1st List of candidates to Report for MBA admissions-2017</a>',
            '<a href="http://www.nitw.ac.in/main/home/#" target="_blank">24 Mar 2017&nbsp;:&nbsp; &nbsp;List of candidates to be called for 1st round of counselling for MBA Admissions-2017 will be released on 26th March 2017</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/schedule_of_gd_and_pi_to_institute_website.pdf" target="_blank">02 Mar 2017&nbsp;:&nbsp; &nbsp;M.B.A Admissions 2017 -&nbsp; Schedule of Group Discussion and Personal Interview</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/02/23/mba_admission-2017_sruitiny_list2_.pdf" target="_blank">17 Feb 2017&nbsp;:&nbsp; &nbsp; M.B.A 2017 - List of candidates called for Group Discussion (GD) and Personal Interview (PI)</a>',
            "03 Feb 2017 : MBA Admissions 2017, the last date for receipt of filled-in applications by the institute is extended to 13th Feb 2017&nbsp;",
            '<a href="http://www.nitw.ac.in/main/MBA/2017" target="_blank">19 Dec 2016&nbsp;:&nbsp; &nbsp; M.B.A Admission 2017</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/msc-tech-mba-mca-phd-fee-payment-detaisl.PDF">08 Dec 2016&nbsp;:</a>&nbsp; &nbsp;&nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/msc-tech-mba-mca-phd-fee-payment-detaisl.PDF">MSC Tech-MBA-MCA &amp; PhD Fee payment detaisl</a>',
            '<a href="http://www.nitw.ac.in/main/pay/fee/">08 Dec 2016 :&nbsp; &nbsp; Payment Procedure</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/msc-tech-mba-mca-phd-fee-payment-detaisl.PDF">08 Dec 2016:&nbsp; &nbsp; &nbsp;</a><a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-payment-dates.PDF">Fee payment dates</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/msc-tech-mba-mca-phd-fee-payment-detaisl.PDF">08 Dec 2016&nbsp;:</a>&nbsp; &nbsp;&nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-waiver-for-sc-st-pwd-students-payment-details.PDF">Fee waiver for SC-ST-PWD students payment details&nbsp;</a>',
            '<a href="http://www.nitw.ac.in/main/academics/feestructure/Mba/" target="_blank">29 Nov 2016 :&nbsp; &nbsp;Fee Structure For &nbsp;M.B.A &nbsp;Students</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/08/14/mba-spot-rounds.pdf" target="_blank">&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/2016/08/14/mba-spot-rounds.pdf" target="_blank">MBA spot round of admissions&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/main/academics/admissions/mbaadmissions2016" target="_blank">M.B.A Admission - 2016</a>',
            '<a href="https://www.nitw.ac.in/main/academics/feestructure/Mba/" target="_blank">Fee Structure</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9aa12c410df627c707c45",
    time: 1640606226378,
    version: "2.22.2",
    title: "M.B.A",
    url: "/ad2021/MBA",
    path: "/ad2021",
    createdAt: "2021-12-27T11:57:06.414Z",
    updatedAt: "2021-12-27T11:57:06.414Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "ZRd3ZTD76j",
        type: "paragraph",
        data: {
          text: '<b>Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16324/">Prof. Anand Kishore Kola&nbsp;</a>&nbsp;(8332969703)',
        },
      },
      {
        id: "STqJjN4chl",
        type: "paragraph",
        data: {
          text: '<b>Vice Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16215/">Dr. Venkaiah Chowdary&nbsp;</a>(--)&nbsp;<br><br><br>',
        },
      },
      {
        id: "ZJbP-intfr",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "25 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; 5 Year Integrated Msc Students - Special Instructions For Online Reporting&nbsp;&nbsp;&nbsp;&nbsp;",
            '<a href="https://www.nitw.ac.in/media/uploads/MSc_-_CCMN-SRNSR_2021_Admissions_-_instructions_for_online_reporting_by_the_candidates.pdf" target="_blank">14 Sep 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;MSc - CCMN-SRNSR 2021 Admissions - instructions for online reporting by the candidates</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/ICCR_2021_Admissions_-_Instructions_for_online_reporting_by_the_candidates_v1.pdf">21&nbsp;Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp; ICCR 2021 Admissions - Instructions for online reporting by the candidates</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/CCMN_-_MSc_2021_admissions_-_instructions_for_online_reporting_by_the_candidatesv1.pdf">20 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp; CCMN - MSc/MSc(Tech)\'2021 admissions - instructions for online reporting by the candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/CCMN_-_MSc_2021_Admissions_-_instructions_for_online_reporting_by_the_candidates.pdf" target="_blank">14 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp; CCMN - MSc 2021 Admissions - instructions for online reporting by the candidates</a>&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_to_MScMBAMCA.pdf" target="_blank">10 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp; Notice to First year M.Sc. / M.Sc.(Tech.) / MCA / MBA students, who are provisionally admitted in</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/Notice_to_MScMBAMCA.pdf" target="_blank">2020</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PG_I_sem_Mid_Exam_Time_Table.pdf" target="_blank">10 Nov 2020&nbsp;:&nbsp; &nbsp; &nbsp; Time Tables for Mid-Semester examinations for MTech/MSc I year I semester, Mid exam for MBA VI quarter and end exams for MBA I quarter</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Studnet_volunteers_to_help_newly_admitted_PG_students-1.pdf">20 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;List of Student Volunteers to help the newly admitted PG &amp; Ph.D students&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_Faculty_Advisers_of_various_PG_programs_at_NIT_Warangal_1.pdf">20 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;List of Faculty Advisers of different PG programs at NIT Warangal.&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2020/09/18/step-by-step-instructions-for-wsdc-account-creation.pdf" target="_blank">18 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Step-by-step instructions for account creation in WSDC portal for M.Tech, MSc and MBA students admitting in 2020</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/MSc_2020_Online_reporting_instructions_to_the_newly_admitting_students_1.pdf">16 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Online reporting instructions to the MSc/Msc(Tech) students admitting in 2020&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Announcement_to_MSc_1.pdf">23 Aug 2020&nbsp;:&nbsp; &nbsp; &nbsp; Announcement to MSc/MSc(Tech) students admitting in 2020&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Details_of_fee_to_be_paid_by_MSc_candidates.pdf" target="_blank">01 Aug 2020&nbsp;:&nbsp; &nbsp; &nbsp;&nbsp;Details of fee particulars for M.Sc. candidates</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/M.Sc_or_M.Sc_Tech__admissions_link.pdf" target="_blank">27 Apr 2020&nbsp;:&nbsp; &nbsp; &nbsp; M.Sc/M.Sc(Tech) admissions link</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Stucture-1-converted.pdf" target="_blank">17 Dec 2019&nbsp;:&nbsp; &nbsp; &nbsp; FEE STRUCTURE FOR THE YEAR 2019-20 II SEMESTER FOR ALL CATEGORIES(B.Tech., M.Tech., M.Sc., M.Sc.Tech.,MCA, &amp; MBA)</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/M.Sc._internal_sliding_list.pdf" target="_blank">16 Aug 2019&nbsp;:&nbsp; &nbsp; &nbsp;M.Sc. internal sliding list</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/M.Sc._or_M.Sc._Tech__reporting_dates_details_of_the_balance_fee_to_be_paid.pdf" target="_blank">05 Jul 2019&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/M.Sc._or_M.Sc._Tech__reporting_dates_details_of_the_balance_fee_to_be_paid.pdf" target="_blank">&nbsp; &nbsp; &nbsp; M.Sc./M.Sc(Tech) reporting dates and details of balance fee to be paid</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_particulars_for_M.Sc._Admissions.pdf" target="_blank">25 Apr 2019&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Fee_particulars_for_M.Sc._Admissions.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;Fee particulars for M.Sc. 2019 Admissions</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">21 Dec 2018</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated_1.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;UG - PG - PHD Even semester fee payment details &amp; procedure</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">20 Dec 2018&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;Fee Structure for II Semester -2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Qualifying_Entrance_Exam_for_M.Sc._courses_in_NIT_Warangal.pdf" target="_blank">01 Oct 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;Qualifying Exam for M.Sc. courses in NIT Warangal</a>',
            '<a href="https://www.nitw.ac.in/main/16th/regconvocation/" target="_blank">07 Aug 2018&nbsp;: &nbsp; &nbsp;UG, PG and Ph. D list for 16th convocation registration</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/VACANCY_SEAT_MATRIX_FOR_INSTITUTE_SPOT_ROUND_ON_09.08.2018.pdf" target="_blank">06 Aug 2018&nbsp;:&nbsp; &nbsp; Vacancy seat matrix for Institute spot round on 09.08.2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Institute_Spot_Round_for_M.Sc_M.ScTech_Admissions__on_09.08.2018.pdf" target="_blank">3 Aug 2018&nbsp;: &nbsp; &nbsp; &nbsp;Institute Spot Round Admissions for M.Sc/M.Sc(Tech) on 09.08.2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/CCMN_SLIDING_FORM.pdf" target="_blank">30 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;Inviting options for Internal sliding in M.Sc programs</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Dates_of_Physical_Reporting_and_Registration_of_PG_Programs_for_the_Academic_Yea_Vj2A3GH.pdf" target="_blank">04 Jul 2018&nbsp;:&nbsp; &nbsp; &nbsp; Dates of Physical Reporting and Registration of PG Programs for the Academic Year 2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Instructions_to_students_taking_admissions_through_CCMN-2018.pdf" target="_blank">04 Jul 2018&nbsp;:&nbsp; &nbsp; &nbsp; Instructions to students taking admissions through CCMN-2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/payment_procedure.pdf" target="_blank">04 Jul 2018&nbsp;:&nbsp; &nbsp; &nbsp; SB COLLECT payment procedure</a>',
            '<a href="https://nitw.ac.in/media/uploads/PG_FEE_STRUCTURE_FOR_THE_ACADEMIC_YEAR_2018-19.pdf" target="_blank">06 Jun 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;PG Fee structure for the Academic Year 2018-19.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2018/05/08/msc-courses-fee-structure-2018-v1.pdf" target="_blank">08 May 2018&nbsp;:&nbsp; &nbsp; M.Sc. courses Fee structure - 2018</a>',
            '<a href="http://www.ccmn.in/" target="_blank">28 Apr 2018&nbsp;:&nbsp; &nbsp; CCMN 2018: Students aspiring for M.Sc. admissions in various NITs and qualified in JAM can visit www.ccmn.in to register for comon counseling. Registration in the portal has commenced.</a>',
            '<a href="https://www.nitw.ac.in/main/CONVOCATION/2017/" target="_blank">28 Oct 2017&nbsp;:&nbsp; &nbsp; &nbsp;List of eligible candidates for 15th CONVOCATION (PG)</a>',
            '<a href="http://www.nitw.ac.in/main/home/#" target="_blank">02 Oct 2017&nbsp;:&nbsp; &nbsp; "Admissions into various MSc programs at NITW for 2018 academic year wil be based on JAM 2018 test being conducted by IITB. (jam.iitb.ac.in). Hence the aspirants for MSc program at NITW are required to register and take part in JAM 2018."</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/M.Sc._BRANCH_SLIDING_RESULTS.pdf" target="_blank">03 Aug 2017&nbsp;:&nbsp; &nbsp; Results of Internal Sliding of 1st Year M.Sc program</a>',
            '<a href="https://nitw.ac.in/media/uploads/Fee_Structure_CCMN-2017.pdf" target="_blank">30 Jun 2017&nbsp;:&nbsp; &nbsp; Instructions to the students joining into M.Sc. program.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/06/20/fee-details-of-other-semesters-for-pg-students-admitted-during-july-2017-session_UtkjMWb.pdf" target="_blank">20 Jun 2017&nbsp;:&nbsp; &nbsp; Fee details of other semesters for PG students admitted in July 2017.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/11/fee-structure-for-pg-programs.pdf">11 May 2017:&nbsp; &nbsp; Fee Details for candidates admitted into M.Sc/ M.Sc(Tech) for the academic year 2017-18</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/phd-fee-payment-details.PDF">08 Dec 2016&nbsp;</a>:&nbsp; &nbsp;&nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/mtech-msc-fees-payment-details.PDF">M.Tech - M.Sc fees payment details</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/msc-tech-mba-mca-phd-fee-payment-detaisl.PDF">08 Dec 2016&nbsp;:&nbsp; &nbsp; MSC Tech-MBA-MCA &amp; PhD Fee payment detaisl</a>',
            '<a href="http://www.nitw.ac.in/main/pay/fee/">08 Dec 2016 :&nbsp; &nbsp; Payment Procedure</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/phd-fee-payment-details.PDF">08 Dec 2016 :</a>&nbsp; &nbsp;&nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-payment-dates.PDF">Fee payment dates</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/phd-fee-payment-details.PDF">08 Dec 2016 :</a>&nbsp; &nbsp;&nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-waiver-for-sc-st-pwd-students-payment-details.PDF">Fee waiver for SC-ST-PWD students payment details&nbsp;</a>',
            '<a href="http://www.nitw.ac.in/main/academics/feestructure/Msc/">29 Nov 2016: &nbsp;</a>&nbsp; &nbsp;<a href="http://www.nitw.ac.in/main/academics/feestructure/Msc/">Fee Structure For M.Sc. Students</a>',
            '2 Aug 2016&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2016/10/22/msc-sliding-list-ss.pdf" target="_blank">Result of M.Sc internal sliding&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/10/22/msc-admissions-2016-fee-june-24-2016.pdf" target="_blank">CCMN 2016 Candidates allotted to NIT Warangal.</a>',
            'Note:&nbsp;Admission will be based on JAM score. Candidates are informed to follow the website&nbsp;<a href="http://www.ccmn.in/" target="_blank">www.ccmn.in</a>&nbsp;for further details and registration.',
            '<a href="https://www.nitw.ac.in/main/admissions/msc-mtech/" target="_blank">Admission to M.Sc, M.Sc(Tech) Programs for the year 2016</a>',
            '<a href="https://www.nitw.ac.in/main/academics/feestructure/Msc/" target="_blank">Fee Structure</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9aa32c410df627c707c47",
    time: 1640606258937,
    version: "2.22.2",
    title: "M.Sc.",
    url: "/ad2021/MSc",
    path: "/ad2021",
    createdAt: "2021-12-27T11:57:38.982Z",
    updatedAt: "2021-12-27T11:57:38.982Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "uFpjr0aOkP",
        type: "paragraph",
        data: {
          text: '<b>Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16324/">Prof. Anand Kishore Kola&nbsp;</a>&nbsp;(8332969703)',
        },
      },
      {
        id: "gy1cclQPQ-",
        type: "paragraph",
        data: {
          text: '<b>Vice Chairman(Admissions):</b>&nbsp;&nbsp;<a href="https://www.nitw.ac.in/faculty/id/16215/">Dr. Venkaiah Chowdary&nbsp;</a>(--)&nbsp;<br><br><br><br>',
        },
      },
      {
        id: "qOdfGFb2CW",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href=`${MainServerUrl}/static/files/Second_List_of_PhD_December_2021_Admissions_2021-11-30-10-18-3.pdf`>30 Dec 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Second List of PhD December 2021 Admissions - Instructions and Schedule for Online Reporting</a><br>',
            '<a href="https://nitw.ac.in/main/PaymentLinks/PhD2021admissions/" target="_blank">21 Dec 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Payment Links For PhD Admissions December-2021 Session</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PhD_December_2021_Admissions_-_Instructions_for_online_reporting_by_all_types_of_PhD_v1.pdf">20 Dec 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;PhD December 2021 Admissions - Instructions for online reporting by PhD Candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/PhD_Admissions_December_2021_Session_-_Provisional_Selection_List_of_the_Candidates.pdf">20 Dec 2021&nbsp;:&nbsp; &nbsp; &nbsp; PhD Admissions December 2021 Session - Provisional Selection List of the Candidates&nbsp;</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/PhD_admissions_of_December_2021_ZpkuwEc.pdf">10 Dec 2021&nbsp;:&nbsp; &nbsp; &nbsp; PhD Admissions of December\' 2021 session - List of shortlisted candidates for the Written test and&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Guidelines&nbsp;&nbsp;</a>&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PhD_Admissions_December_2021_-_Date_of_Online_Written_Test_cum_Guidelines_and_In_nG8QGgK.pdf" target="_blank">07 Dec 2021&nbsp;:&nbsp; &nbsp; &nbsp; PhD Admissions December 2021 - Date of Online Written Test cum Guidelines and Interview Dates</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/Extension/PhD2021/" target="_blank">29 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; Extension of last date to submit the PhD applications online</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/PAYMENTLINKS/PHD2021/" target="_blank">16 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; APPLICATION AND PAYMENT LINKS FOR PhD ADMISSIONS - DECEMBER 2021 SESSION</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/brochure_phd_admissions_december__2021_12112021_oct6asi.pdf">15 Nov 2021&nbsp;:&nbsp; &nbsp; &nbsp; Advertisement For PhD Admissions - December 2021 Session&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/QIP_-_PhD_Admissions_2021_-_instructions_for_selected_candidates.pdf" target="_blank">23 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;QIP - PhD Admissions 2021 - instructions for selected candidates</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/ICCR_2021_Admissions_-_Instructions_for_online_reporting_by_the_candidates_v1.pdf">21&nbsp;Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;ICCR 2021 Admissions - Instructions for online reporting by the candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/PhD_July_2021_Admissions_-_Instructions_for_online_reporting_by_all_types_of_PhD_v1.pdf">20 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;PhD July&nbsp;2021 Admissions - instructions for online reporting by all types of PhD candidates&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PhD_Admissions_July2021_Session_-_List_of_provisionally_selected_candidates_and__WX051Aa.pdf" target="_blank">19 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;PhD Admissions July 2021 Session - List of provisionally selected candidates and instructions to be</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/PhD_Admissions_July2021_Session_-_List_of_provisionally_selected_candidates_and__WX051Aa.pdf" target="_blank">followed by the candidates</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PhD_July_2021_Admissions_-_Instructions_for_online_reporting_by_the_candidates.pdf" target="_blank">16 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;PhD July 2021 Admissions - Instructions for online reporting by the candidates</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/List_of_Candidates_shortlisted_for_Interview_of_PhD_admissions_of_July2021_sessi_mev3TgE.pdf">09 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;List of candidates shortlisted for Interview - PhD admissions July 2021 session&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/main/home/#" target="_blank">04 Aug 2021&nbsp;:&nbsp; &nbsp; &nbsp;List of candidates shortlisted for the Written test of PhD admissions of July-2021 session</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Instructions_to_ICCR_-_M.Tech_and_PhD_Students_of_2021-22_Admissions.pdf" target="_blank">28 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; Instructions to ICCR - M.Tech and PhD Students of 2021-22 Admissions</a>&nbsp;&nbsp;&nbsp;&nbsp;',
          ],
        },
      },
      {
        id: "Jm_AgkIJxc",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/Shortlisted_Candidates_for_PhD_Interviews_under_QIP_2021-22.pdf" target="_blank">04 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; Shortlisted Candidates for PhD Interviews under QIP 2021-22</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PhD_2020_Online_reporting_instructions_-Dec_2020_session.pdf" target="_blank">16 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Online reporting instructions to Ph.D students selected in the December 2020 session</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Selected_PhD_list_December_2020-announcement_1v.pdf">12 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Provisional selected PhD candidates list - December 2020 session&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/SHORTLISTED_CANDIDATES_FOR_Interview_678_Jan_2021-_DECEMBER_2020_SESSION.pdf" target="_blank">05 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;PhD shortlisted candidates for interview scheduled on 6th, 7th, 8th January 2021</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PhD_Shortlisted_candidates_for_interviews_on_5th_January_2021.pdf" target="_blank">04 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;List of shortlisted candidates for PhD interviews scheduled on 5th January 2021</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Schedule_of_interviews_for_PhD_admissions_-_December_2020_session.pdf" target="_blank">01 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Schedule of interviews for PhD admissions - December 2020 session</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/SHORTLISTED_CANDIDATES_FOR_WRITTEN_TEST_-_DECEMBER_2020_SESSION.pdf" target="_blank">29 Dec 2020&nbsp;:&nbsp; &nbsp; &nbsp;List of Shortlisted Candidates for written test for PhD admissions December 2020 session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph_D_portal_link_FJEEvzN.pdf" target="_blank">01 Dec 2020&nbsp;:&nbsp; &nbsp; &nbsp;Ph.D. Admissions - December 2020 session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Studnet_volunteers_to_help_newly_admitted_PG_students-1.pdf">20 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp;List of Student Volunteers to help the newly admitted PG &amp; Ph.D students&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Selected_PhD_list_July_2020-announcement.pdf" target="_blank">11 Sep 2020&nbsp;:&nbsp; &nbsp; &nbsp;List of provisionally selected PhD candidates for July 2020 session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/Procedure/PhDRegistration/" target="_blank">31 Aug 2020&nbsp;:&nbsp; &nbsp; Procedure for PhD Registration</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Revised_SHORTLISTED_CANDIDATES_FOR_INTERVIEW_JULY_2020.pdf" target="_blank">21 Aug 2020&nbsp;:&nbsp; &nbsp; Revised schedule and list of shortlisted candidates for the PhD admission interviews for July 2020 session</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Rescheduling_of_PhD_interviews_for_July_2020_session.pdf" target="_blank">07 Aug 2020&nbsp;:&nbsp; &nbsp; Rescheduling of PhD interviews for July 2020 session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/SHORTLISTED_CANDIDATES_FOR_INTERVIEW_JULY_2020.pdf" target="_blank">24 Jul 2020&nbsp;:&nbsp; &nbsp; &nbsp; List of shortlisted candidates for PhD admission interviews for July 2020 session</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph_D_portal_link_DJ1KlVD.pdf" target="_blank">09 Jun 2020&nbsp;:&nbsp; &nbsp; &nbsp;Ph D Admissions - July 2020 Session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Stucture-1-converted.pdf" target="_blank">17 Dec 2019&nbsp;:&nbsp; &nbsp; &nbsp;FEE STRUCTURE FOR THE YEAR 2019-20 II SEMESTER FOR ALL CATEGORIES(B.Tech., M.Tech., M.Sc., M.Sc.Tech.,MCA, &amp; MBA,Ph.D)</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/website_List_of_PhD__selected_candidates_for_December_2019.pdf" target="_blank">05 Dec 2019&nbsp;:&nbsp; &nbsp; &nbsp;List of provisionally selected PhD candidates for December 2019</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Schedule_of_written_test_and_interview_for_PhD_admissions_of_December_2019_session.pdf" target="_blank">14 Nov 2019&nbsp;:&nbsp; &nbsp; Schedule of written and interview for PhD admissions - December 2019 session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/LIST_OF_SHORTLISTED_PHD_CANDIDATES_FOR__WRITTEN_TEST__AND_INTERVIEW.pdf" target="_blank">13 Nov 2019&nbsp;:&nbsp; &nbsp;&nbsp;List of Shortlisted Ph.D. candidates for written test and interview for December 2019 session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph_D_portal_link_lf8FZ5a.pdf" target="_blank">18 Oct 2019&nbsp;:&nbsp; &nbsp; &nbsp;Ph D Admissions - December 2019 Session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Selected_candidate_for_walkin_interview_in_Chemical_Engineering.pdf" target="_blank">26 Aug 2019&nbsp;:&nbsp; &nbsp; Selected candidate for walkin interview for Ph.D. in Chemical Engineering Department</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Walk_in_interview_for_chemical_engineering.pdf" target="_blank">16 Aug 2019&nbsp;:&nbsp; &nbsp; Walk in interview for PhD program in Chemical Engineering</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/website_List_of_PhD__selected_candidates_for_July_2019_fzvDUCJ.pdf">11 Jul 2019&nbsp;:&nbsp; &nbsp; &nbsp; List of provisionally selected PhD candidates for July 2019 session&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph.D._Written_test_and_Interview_schedule_for_July_2019_session.pdf" target="_blank">18 Jun 2019&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Ph.D._Written_test_and_Interview_schedule_for_July_2019_session.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;Ph.D. written test/Interview schedule for July 2019 session</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_Ph.D._short_listed_candidates_written_test_and_interview_for_July_2019_session.pdf" target="_blank">14 Jun 2019&nbsp;:</a><a href="https://www.nitw.ac.in/media/uploads/List_of_Ph.D._short_listed_candidates_written_test_and_interview_for_July_2019_session.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp; List of Ph.D. shortlisted candidates for written test/interview for July 2019 session</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph_D_portal_link_XVox3Ta.pdf">23 Apr 2019&nbsp;:&nbsp; &nbsp; &nbsp;Ph.D. Admissions July 2019 session&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">21 Dec 2018</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated.pdf">&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/Tuition_fee_details_for_December_2018session_-_UG-PG-PHD_updated_1.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;UG - PG - PHD Even semester fee payment details &amp; procedure</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">20 Dec 2018&nbsp;:</a><a href="https://www.nitw.ac.in/media/uploads/fee_structure__II_sem_2018-19.pdf" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp; Fee Structure for II Semester -2018-19</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/ONLINE_PAYMENT_INSTRUCTIONS_TO_THE_PhD.SCHOLARS_FOR_DECEMBER_2018-06.12.2018.pdf">12 Dec 2018&nbsp;: &nbsp; &nbsp; PhD Admissions - December 2018: Hostel allotment&nbsp;&nbsp;&nbsp;</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/website_List_of_PhD__selected_candidates_for_Dec_2018_DEH6Jw4.pdf">05 Dec 2018&nbsp;:</a>&nbsp;<a href="https://www.nitw.ac.in/media/uploads/website_List_of_PhD__selected_candidates_for_Dec_2018.pdf" target="_blank">&nbsp;&nbsp;&nbsp;Selected candidates in Ph.D. interviews - December 2018 session</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph.D._Written_test_and_interview_scheduled_for_December_2018_session_GeSvWYH.pdf" target="_blank">14 Nov 2018&nbsp;: &nbsp;&nbsp;&nbsp;Schedule for Ph.D. written test and interview for December 2018 session</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/List_of_Ph.D._short_listed_candidates_for_December_2018_session.pdf" target="_blank">06 Nov 2018&nbsp;: &nbsp;&nbsp;&nbsp;Provisional List of Shortlisted Ph.D. Candidates for December 2018 session</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/PHD_october_2018_-_payment_procedure-converted_1.pdf" target="_blank">15 Oct 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;Ph.D Registration fee procedure</a>',
            '<a href="https://admissions.nitw.ac.in/" target="_blank">12 Oct 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;Ph.D. Admissions December 2018 session</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph.D_Brochure__December_2018_oct_12_uploaded.pdf" target="_blank">10 Oct 2018&nbsp;:&nbsp; &nbsp; "Ph.D. admissions December 2018. Application format online is available from 12th October 2018."</a>',
            '<a href="https://www.nitw.ac.in/main/16th/regconvocation/" target="_blank">07 Aug 2018&nbsp;: &nbsp;&nbsp;&nbsp;UG, PG and Ph. D list for 16th convocation registration</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/payment_procedure.pdf" target="_blank">04 Jul 2018&nbsp;: &nbsp; &nbsp; &nbsp;SB COLLECT payment procedure</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Fee_Structure_for_Ph.D._2018.pdf" target="_blank">03 Jul 2018&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Fee Structure for Ph.D. 2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph.D__List_of__recommended_candidates_for_Admission___28.06.2018_1.pdf" target="_blank">28 Jun 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;List of candidates selected for Ph.D Program – July 2018 session</a>',
            '<a href="https://nitw.ac.in/media/uploads/PhD_Admission_-CED-Written_test_Syllabus.pdf" target="_blank">12 Jun 2018&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;PhD Admission in Civil Engineering Department- July 2018 Session, Syllabus for Written Test for selecting candidates for Interview (Oral Exam).</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph.D._schedule_of_Written_Test_and_Interview_for_July_2018_session_1.pdf" target="_blank">06 Jun 2018&nbsp;:&nbsp; &nbsp; &nbsp;Schedule of written test/ Interview for Ph.D admissions - July 2018 session</a>',
            '<a href="https://nitw.ac.in/media/uploads/Ph.D._Admissions_Eligible_candidates__list_July-2018.pdf" target="_blank">04 Jun 2018&nbsp;:&nbsp; &nbsp; &nbsp;List of candidates shortlisted for written test and interview for Ph.D.&nbsp; Admissions, July 2018-19.</a>',
            '<a href="https://nitw.ac.in/main/phd/2018/" target="_blank">12 Apr 2018&nbsp;:&nbsp; &nbsp; &nbsp;Ph.D. Admissions July 2018</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/Ph.D.__Fee_Structure-December_2017___30.11.pdf" target="_blank">01 Dec 2017&nbsp;:&nbsp; &nbsp; &nbsp;Instructions to candidates selected for Ph.D admissions December 2017 Session.</a>',
            '<a href="https://www.nitw.ac.in/main/phd/selected%202017/" target="_blank">29 Nov 2017&nbsp;:&nbsp; &nbsp; &nbsp;List of candidates selected for Ph.D admission for Dec 2017 Session – Part Time, Full Time, JRF category.</a>',
            '<a href="https://www.nitw.ac.in/main/phd/2017" target="_blank">17 Nov 2017&nbsp;:&nbsp; &nbsp; Ph.D. admissions December 2017</a>',
            '<a href="https://www.nitw.ac.in/main/CONVOCATION/2017/" target="_blank">28 Oct 2017&nbsp;:&nbsp; &nbsp; &nbsp;List of eligible candidates for 15th CONVOCATION ( Ph.D)</a>',
            '<a href="https://admissions.nitw.ac.in/" target="_blank">17 Oct 2017&nbsp;:&nbsp; &nbsp; &nbsp;PhD Admissions - October 2017</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2018/12/04/phd-rules-2017-18.pdf" target="_blank">14 Aug 2017&nbsp;:&nbsp; &nbsp; Rules and Regulations of Ph.D program (with effect from 2017 - 2018).</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/Ph.D.__Fee_Structure-2017.pdf" target="_blank">23 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;Instructions to students taking ph.d admission for July 2017 session.</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/PhD_-_List_of_selected_candidates_.pdf" target="_blank">17 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;List of candidates selected for Ph.D&nbsp;Program – July 2017</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/department_wise_schedule_of_written_test_and_interview_for_ph.d_admissions.pdf" target="_blank">09 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;Phd.Admissions - July 2017 session - Department wise schedule of reporting for written test and Interview.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/06/06/updated-eligible-list-_06062017.pdf" target="_blank">06 Jun 2017&nbsp;:&nbsp; &nbsp;&nbsp;&nbsp;Ph.D admission for July 2017 session Department wise shortlisted candidates for written test/ interview.</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/Interview_Schedule.pdf" target="_blank">03 Jun 2017&nbsp;:&nbsp; &nbsp; &nbsp;Ph.D. Admissions July 2017 - Proposed schedule of Ph.D Interviews and Written Test.</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/eligible_list__02.06.2017_latest_1_B4xmoJa.pdf" target="_blank">02 Jun 2017&nbsp;:&nbsp; &nbsp; list of shortlisted candidates for Ph.D. in various departments for July 2017 session.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/latest_updated_phd_brochure_-_july_17_session_25th_april2017_tlo5kl9.pdf" target="_blank">17 Apr 2017&nbsp;:&nbsp; &nbsp; Ph.D Admission notification for July 2017 session</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/phd-fee-payment-details.PDF">08 Dec 2016 :&nbsp; &nbsp; PhD fee payment details</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/phd-fee-payment-details.PDF">08 Dec 2016 :&nbsp;</a>&nbsp; &nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/msc-tech-mba-mca-phd-fee-payment-detaisl.PDF">MSC Tech-MBA-MCA &amp; PhD Fee payment detaisl</a>',
            '<a href="http://www.nitw.ac.in/main/pay/fee/">08 Dec 2016 :&nbsp; &nbsp; Payment Procedure</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/phd-fee-payment-details.PDF">08 Dec 2016 :</a>&nbsp; &nbsp;&nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-payment-dates.PDF">Fee payment dates</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/phd-fee-payment-details.PDF">08 Dec 2016 :&nbsp;</a>&nbsp; &nbsp;<a href="http://www.nitw.ac.in/media/uploads/2016/12/08/fee-waiver-for-sc-st-pwd-students-payment-details.PDF">Fee waiver for SC-ST-PWD students payment details&nbsp;</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/selected_list_of__PhD_candidates_for_WEB_-__December_2016_01.12.2016.pdf" target="_blank">01 Dec 2016&nbsp;:&nbsp; &nbsp; Ph.D Admissions - December 2016 - List of selected candidates and Instructions</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/PhD_admissions_December_2016_-_Eligible_candidates_ift63JO.pdf" target="_blank">11 Nov 2016&nbsp;:&nbsp; &nbsp; Ph.D admissions December 2016 - Eligible candidates</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/PhD_admissions_December_2016_-_Instructions_to_the_candidates.pdf" target="_blank">11 Nov 2016&nbsp;:&nbsp; &nbsp; Ph.D admissions December 2016 - Instructions to the candidates</a>',
            '<a href="http://www.nitw.ac.in/media/uploads/Ph.D_brochure_December_2016_.pdf" target="_blank">01 Nov 2016&nbsp;:&nbsp; &nbsp; Ph.D admissions - December 2016 session- extension of last date- Tuition fee waiver to SC / ST students</a>',
            '3 Oct 2016:&nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/Ph.D_brochure_December_2016_ATT2ypI.pdf">Ph.D admissions December 2016 Session</a>',
            '24 June 2016:&nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2016/10/22/selected-list-of-phd-candidates-for-web-july-2016-2306.pdf" target="_blank">Provisionally Selected for Ph.D&nbsp;Admissions into various Departments, July 2016.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/10/22/phd-admissions-july-2016-instructions-to-the-candidates.pdf">PhD admissions July 2016 - Instructions to the candidates.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/10/22/phd-admissions-july-2016-shortlisted-candidates.pdf">PhD admissions July 2016 Shortlisted candidates.</a>',
            '<a href="https://www.nitw.ac.in/media/uploads/2016/10/22/phd_brochure-july-2016_new.pdf" target="_blank">Ph.D&nbsp;Admissions July 2016-Notification</a>',
            '<a href="https://www.nitw.ac.in/main/academics/feestructure/Phd/" target="_blank">Fee Structure</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61c9aa52c410df627c707c49",
    time: 1640840635599,
    version: "2.22.2",
    title: "Ph. D",
    url: "/ad2021/PhD",
    path: "/ad2021",
    createdAt: "2021-12-27T11:58:10.315Z",
    updatedAt: "2021-12-30T05:03:56.132Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "KxdmZ0pbT6",
        type: "header",
        data: { text: "Academic Programs", level: 3 },
      },
      {
        id: "Zy_hM0HgWh",
        type: "list",
        data: {
          style: "unordered",
          items: [
            'B.Tech<br><a href=`${MainServerUrl}/static/files/open_elective_2022-0-24-13-23-17.pdf`>Open Elective Courses</a><br>',
          ],
        },
      },
      {
        id: "0mLuwBfyBf",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="http://daa.nitw.ac.in:8081/path/?dept=/NewMTechCurriculum">M.Tech</a>',
          ],
        },
      },
      {
        id: "of8QA77KwO",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="http://daa.nitw.ac.in:8081/path/?dept=/academicphd">Ph.D</a>',
          ],
        },
      },
      {
        id: "WOZsuzDSi6",
        type: "list",
        data: { style: "unordered", items: ["PG"] },
      },
      {
        id: "NWyoTv8hj1",
        type: "paragraph",
        data: {
          text: '<a href="http://daa.nitw.ac.in:8081/path/?dept=/academicmsc">M.Sc./M.Sc. (Tech.)/Integrated M.Sc. </a><br><a href="http://daa.nitw.ac.in:8081/path/?dept=/academicmba">MBA</a><br><a href="http://daa.nitw.ac.in:8081/path/?dept=/academicmca">MCA</a><br>',
        },
      },
      {
        id: "0FxldPSQHf",
        type: "list",
        data: { style: "unordered", items: ["PG Dilpoma"] },
      },
    ],
    language: "En",
    _id: "61caa0d43587e96828b40e89",
    time: 1643010809089,
    version: "2.22.2",
    title: "Academic Programs",
    url: "/academicprgm/list",
    path: "/academicprgm",
    createdAt: "2021-12-28T05:29:56.013Z",
    updatedAt: "2022-01-24T07:53:30.105Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "nDhXmx1Kkp",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Scheme and Syllabi"],
            [
              "1",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/10/23/01_btech-civil.pdf">Civil Engineering</a>',
            ],
            [
              "2",
              '<a href="https://nitw.ac.in/media/uploads/2022/01/24/02_btech_eee.pdf">Electrical Engineering</a>',
            ],
            [
              "3",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/10/23/03_btech-in-mechanical-engineering.pdf">Mechanical Engineering</a>',
            ],
            [
              "4",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/10/23/04_btech-ece.pdf">Electronics and&nbsp;Communication Engineering</a>',
            ],
            [
              "5",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/10/23/05_btech-mmed.pdf">Metallurgical and Materials Engineering</a>',
            ],
            [
              "6",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/10/23/06_btech-chemical.pdf">Chemical Engineering</a>',
            ],
            [
              "7",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/10/23/07_btech-in-cse.pdf">Computer Science and Engineering</a>',
            ],
            [
              "8",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/10/23/08_btech_biotechnology.pdf">Biotechnology</a>',
            ],
          ],
        },
      },
      { id: "yvbSiEuD4D", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61caa2893587e96828b40e90",
    time: 1643010243421,
    version: "2.22.2",
    title: "New B.Tech Curriculum",
    url: "/NewBTechCurriculum/BTech",
    path: "/NewBTechCurriculum",
    createdAt: "2021-12-28T05:37:13.962Z",
    updatedAt: "2022-01-24T07:44:04.069Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "KbBTCyo0P5",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Scheme and Syllabi"],
            [
              "1",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/06/28/btech_ced_syllabus-2017_01.pdf">Civil Engineering</a>',
            ],
            [
              "2",
              '<a href="https://www.nitw.ac.in/media/uploads/2018/09/25/btech_eed_-syllabus-2017.pdf">Electrical Engineering</a>',
            ],
            [
              "3",
              '<a href="https://www.nitw.ac.in/media/uploads/2018/10/12/btech_mech_syllabus_2017.pdf">Mechanical Engineering</a>',
            ],
            [
              "4",
              '<a href="https://www.nitw.ac.in/media/uploads/2018/09/25/btech_ece_syllabus_2017.pdf">Electronics and&nbsp;Communication Engineering</a>',
            ],
            [
              "5",
              '<a href="https://www.nitw.ac.in/media/uploads/2018/09/25/btech_mme_-syllabus-2017.pdf">Metallurgical and Materials Engineering</a>',
            ],
            [
              "6",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/01/18/btech_chemical_final-2018-aug-2020-new.pdf">Chemical Engineering</a>',
            ],
            [
              "7",
              '<a href="https://www.nitw.ac.in/media/uploads/2018/09/25/btech_cse_syllabus_2017.pdf">Computer Science and Engineering</a>',
            ],
            [
              "8",
              '<a href="https://www.nitw.ac.in/media/uploads/2020/09/22/btech-biotech-syllabus-1892020.pdf">Biotechnology</a>',
            ],
          ],
        },
      },
      { id: "L-PtXjY41V", type: "paragraph", data: { text: "<br>" } },
      { id: "Dc44WeJqP0", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61caa2fd3587e96828b40e92",
    time: 1640669949035,
    version: "2.22.2",
    title: "Old B.Tech Curriculum",
    url: "/NewBTechCurriculum/oldBTech",
    path: "/NewBTechCurriculum",
    createdAt: "2021-12-28T05:39:09.163Z",
    updatedAt: "2021-12-28T05:39:09.163Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "BalpDmknSr",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Department", "Scheme &amp; Syllabi"],
            [
              "1",
              "Civil Engineering",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_construction-technology-and-management.pdf">Construction Technology and Management</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_engineering-structures.pdf">Engineering Structures</a>&nbsp;3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/3_environmental-engineering.pdf">Environmental Engineering</a>&nbsp; 4.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/4_geotechnical-engineering.pdf">Geotechnical Engineering</a>&nbsp; 5.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/5_remote-sensing-gis.pdf">Remote Sensing &amp; GIS</a>&nbsp; 6.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/6_transportation-engineering.pdf">Transportation Engineering</a>&nbsp; 7.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/7_water-resources-engineering.pdf">Water Resources Engineering</a>&nbsp; 8.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/8_waste-managemnet.pdf">Waste Management</a>',
            ],
            [
              "2",
              "Electrical Engineering",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/09/27/mtech-ped-complete-syllabi.pdf">Power Electronics and Drives</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/09/27/mtech-pse-complete-syllabi.pdf">Power Systems Engineering</a>&nbsp; 3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/09/27/mtech-seg-complete-syllabi.pdf">Smart Electric Grid</a>',
            ],
            [
              "3",
              "Mechanical Engineering",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_thermal-engineering.pdf">Thermal Engineering</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_manufacturing-engineering.pdf">Manufacturing Engineering</a>&nbsp; 3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/3_computer-integraed-manufacturing.pdf">Computer Integraed Manufacturing</a>&nbsp; 4.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/4_machine-design.pdf">Machine Design</a>&nbsp; 5.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/5_automobile-engineering.pdf">Automobile Engineering</a>&nbsp; 6.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/6_materials-and-systems-engineering-design.pdf">Materials and Systems&nbsp;Engineering Design</a>&nbsp; 7.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/7_additive-manufacturing.pdf">Additive Manufacturing</a>',
            ],
            [
              "4",
              "Electronics and Communication Engineering",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_electronic-instrumentation-and-embedded-systems.pdf">Electronic Instrumentation and Embedded Systems</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_vlsi-system-design.pdf">VLSI-System Design</a>&nbsp; 3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/3_advanced-communication-systems.pdf">Advanced Communication Systems</a>',
            ],
            [
              "5",
              "Metallurgical and Materials Engg.",
              '&nbsp; 1.<a href="https://www.nitw.ac.in/media/uploads/2021/08/30/1_industrial-metallurgy.pdf">Industrial Metallurgy</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_materials-technology.pdf">Materials Technology</a>',
            ],
            [
              "6",
              "Chemical Engineering",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_chemical-engineering.pdf">Chemical Engineering</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_systems-and-control-engineering.pdf">Systems and Control Engineering</a>',
            ],
            [
              "7",
              "Computer Science and Engineering",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/09/16/re-reivsed-m-tech-cse-syllabus-20-8-21v10.pdf">Computer Science and Engineering</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/09/16/re-revise-mtech-csis-syllabus-august-20-2021.pdf">Computer Science and Information Security</a>',
            ],
            [
              "8",
              "Biotechnology",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/10/26/m-tech_curriculum-18-10-21-1.pdf">Biotechnology</a>',
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61caa6ed3587e96828b40e9e",
    time: 1640670957787,
    version: "2.22.2",
    title: "New M.Tech Curriculum",
    url: "/NewMTechCurriculum/MTech",
    path: "/NewMTechCurriculum",
    createdAt: "2021-12-28T05:55:57.894Z",
    updatedAt: "2021-12-28T05:55:57.894Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "-3SltFVyI2",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Department", "Scheme &amp; Syllabi"],
            [
              "1",
              "Civil Engineering",
              '&nbsp; 1.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/m-tech_es_scheme-and-syllabus.pdf">Engineering Structures</a>&nbsp; 2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/mtech-gte-syllabus-2019.pdf">Geotechnical Engineering</a>&nbsp; 3.<a href="https://nitw.ac.in/media/uploads/2019/09/05/mtte_2019.pdf">&nbsp;Transportation Engineering</a>&nbsp; 4.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/mtechsyllabus-wre-2019.pdf">Water Resources Engineering</a>&nbsp; 5.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/rsgiss_revised_syllabus_ay-19-20_220719_postdsc.pdf">Remote Sensing and GIS</a>&nbsp; 6.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/mtech_ee_scheme-and-syllabus.pdf">Environmental Engineering</a>&nbsp; 7.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/ctm-curriculum_2019.pdf">Construction Technology and Mgmt.</a>&nbsp; 8.&nbsp;<a href="https://nitw.ac.in/media/uploads/2020/05/13/mtech_waste-management-civil.pdf">Waste Management</a>',
            ],
            [
              "2",
              "Electrical Engineering",
              '&nbsp; 1.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/mtech_pse_scheme-and-syllabus.pdf">Power Systems Engineering</a>&nbsp; 2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/mtech_ped_scheme-and-syllabus.pdf">Power Electronics and Drives</a>&nbsp; 3.&nbsp;<a href="https://nitw.ac.in/media/uploads/2020/05/13/m-tech_smart-electric-grid-electrical.pdf">Smart Electric Grid</a>',
            ],
            [
              "3",
              "Mechanical Engineering",
              '&nbsp; 1.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/10/te.pdf">Thermal Engineering</a>&nbsp; 2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/10/me.pdf">Manufacturing Engineering</a>&nbsp; 3.<a href="https://nitw.ac.in/media/uploads/2019/09/10/cim.pdf">&nbsp;Computer Integrated Manufacturing</a>&nbsp; 4.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/10/md.pdf">Machine Design</a>&nbsp; 5.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/10/ae.pdf">Automobile Engineering</a>&nbsp; 6.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/10/msed_D7alCq1.pdf">Materials and System Engineering Design</a>&nbsp; 7.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/10/am.pdf">Additive Manufacturing</a>',
            ],
            [
              "4",
              "Electronics and Communication Engineering",
              '&nbsp; 1.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/10/16/mteceiembedded-systems_new_syll_senate-1.pdf">Electronic Instrumentation &amp; Embedded Systems</a>&nbsp; 2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/10/16/syllabus_mtech_vlsi_updated-1.pdf">VLSI System Design</a>&nbsp; 3.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/10/16/m-tech-acs-syllabus-2019-2020.pdf">Advanced Communication Systems</a>',
            ],
            [
              "5",
              "Metallurgical and Materials Engg.",
              '&nbsp; 1.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/revised-syllabus-im-2019-22072019.pdf">Industrial Metallurgy</a>&nbsp; 2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/revised-syllabus-mt-2019-22072019.pdf">Materials Technology</a>',
            ],
            [
              "6",
              "Chemical Engineering",
              '&nbsp; 1.<a href="https://nitw.ac.in/media/uploads/2020/08/26/mtech_process_control_august2019-aug-2020.pdf">&nbsp;Process Control</a>&nbsp; 2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2020/08/26/scheme-and-syllabus-format-mtech-chemical-aug-2020.pdf">Chemical Engineering</a>',
            ],
            [
              "7",
              "Computer Science and Engineering",
              '&nbsp; 1.<a href="https://nitw.ac.in/media/uploads/2019/09/05/mtech-cse.pdf">&nbsp;Computer Science &amp; Engg.</a>&nbsp; 2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2019/09/05/mtech-csis.pdf">Computer Science and Information Security</a>',
            ],
            [
              "8",
              "Biotechnology",
              '&nbsp; 1.&nbsp;<a href="https://nitw.ac.in/media/uploads/2020/09/22/mtech-biotech-syllabus-1892020.pdf">Biotechnology</a>',
            ],
          ],
        },
      },
      { id: "KLntUP6gdu", type: "paragraph", data: { text: "<br>" } },
      { id: "O6jeehFIgA", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61caa8a33587e96828b40ea0",
    time: 1640671395279,
    version: "2.22.2",
    title: "Old M.Tech Curriculum",
    url: "/NewMTechCurriculum/oldMTech",
    path: "/NewMTechCurriculum",
    createdAt: "2021-12-28T06:03:15.397Z",
    updatedAt: "2021-12-28T06:03:15.397Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9dOUJ49c87",
        type: "header",
        data: {
          text: "Ph.D is offered in all the academic departments",
          level: 5,
        },
      },
      {
        id: "sau4T2g42W",
        type: "paragraph",
        data: { text: "1. Civil Engineering" },
      },
      {
        id: "M-ikOPeUIb",
        type: "paragraph",
        data: { text: "2. Electrical Engineering" },
      },
      {
        id: "L8_5nqd7gv",
        type: "paragraph",
        data: { text: "3.&nbsp;Mechanical Engineering" },
      },
      {
        id: "GhV8YS-tPR",
        type: "paragraph",
        data: { text: "4.&nbsp;Electronics &amp; communication Engineering" },
      },
      {
        id: "l7DcNQd_NR",
        type: "paragraph",
        data: { text: "5.&nbsp;Metallurgical and Materials Engineering" },
      },
      {
        id: "1hKcGbV5Qz",
        type: "paragraph",
        data: { text: "6.&nbsp;&nbsp;Chemical Engineering&nbsp;" },
      },
      {
        id: "QyCv2u7IUX",
        type: "paragraph",
        data: { text: "7.&nbsp;&nbsp;Computer Science &amp; Engineering" },
      },
      {
        id: "U-UwvrzcHu",
        type: "paragraph",
        data: { text: "8.&nbsp;Biotechnology" },
      },
      {
        id: "eX29UJWyEI",
        type: "paragraph",
        data: { text: "9.&nbsp;Mathematics" },
      },
      {
        id: "_5X9BMqSYs",
        type: "paragraph",
        data: { text: "10.&nbsp;Physics" },
      },
      {
        id: "Uqrc-Lwvtg",
        type: "paragraph",
        data: { text: "11.&nbsp;Chemistry" },
      },
      {
        id: "os_4m3nLC_",
        type: "paragraph",
        data: { text: "12.&nbsp;School Of Management" },
      },
      {
        id: "YAEviY6Uy5",
        type: "paragraph",
        data: { text: "13.&nbsp;Humanities &amp; Social Science" },
      },
    ],
    language: "En",
    _id: "61caa9933587e96828b40ea6",
    time: 1640671635421,
    version: "2.22.2",
    title: "Ph.D",
    url: "/academicphd/PhD",
    path: "/academicphd",
    createdAt: "2021-12-28T06:07:15.538Z",
    updatedAt: "2021-12-28T06:07:15.538Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "rbb3Ro809P",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Department", "Scheme &amp; Syllabi"],
            [
              "1",
              "Mathematics",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_applied-mathematics.pdf">M.Sc. (</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_applied-mathematics.pdf">Applied Mathematics</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_applied-mathematics.pdf">)</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_mathematics-scientific-computing.pdf">M.Sc. (</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_mathematics-scientific-computing.pdf">Mathematics &amp; Scientific&nbsp;Computing</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_mathematics-scientific-computing.pdf">)</a>&nbsp; 3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/10/19/int-msc-mathematics.pdf">Integrated M.Sc.&nbsp;Mathematics</a>',
            ],
            [
              "2",
              "Physics",
              '&nbsp; 1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_msctech-engineering-physics.pdf">M.Sc.(Tech) Engineering Physics</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/10/20/integrated-msc-physics-v1.pdf">Integrated M.Sc.&nbsp;Physics</a>',
            ],
            [
              "3",
              "Chemistry",
              '&nbsp; 1.<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_analytical-chemistry.pdf">&nbsp;M.Sc. (</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_analytical-chemistry.pdf">Analytical Chemistry</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/1_analytical-chemistry.pdf">)</a>&nbsp; 2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_organic-chemistry.pdf">M.Sc. (</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_organic-chemistry.pdf">Organic Chemistry</a><a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_organic-chemistry.pdf">)</a>&nbsp; 3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/10/19/int-msc-chemistry.pdf">Integrated M.Sc.&nbsp;Chemistry</a>',
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61caadea3587e96828b40ead",
    time: 1640672746416,
    version: "2.22.2",
    title: "M.Sc./M.Sc.(Tech.)/Integrated M.Sc",
    url: "/academicmsc/MSccurriculum",
    path: "/academicmsc",
    createdAt: "2021-12-28T06:25:46.552Z",
    updatedAt: "2021-12-28T06:25:46.552Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Qx3NdCDBya",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Department", "Scheme &amp; Syllabi"],
            [
              "1",
              "Physics",
              '1.&nbsp;<a href="https://nitw.ac.in/media/uploads/2018/09/26/new_final-1st_year_pg-syllabus-july-2016-1.pdf">M. Sc. (Tech.) Engineering Physics semesters I &amp; 2</a>2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2018/10/05/3rd_4th_5th-sem-_detailed-slyb_engphys.pdf">3rd, 4th, 5th semesters</a>',
            ],
            [
              "2",
              "Chemistry",
              '1.&nbsp;<a href="https://nitw.ac.in/media/uploads/2018/09/27/msc-analchem-syllabus-arc-2017-18-for-website.pdf">M.Sc. Analytical Chemistry</a>2.&nbsp;<a href="https://nitw.ac.in/media/uploads/2018/09/27/msc-orgchem-syllabus-pvsl-2017-18-for-website.pdf">M.Sc Organic Chemistry</a>',
            ],
            [
              "3",
              "Mathematics",
              '1. <a href="https://nitw.ac.in/media/uploads/2018/10/03/msc-scheme-and-syllabus_2017-mathematics.pdf">M.Sc. (Applied Mathematics)</a>2. <a href="https://nitw.ac.in/media/uploads/2018/10/03/msc-scheme-and-syllabus_2017-mathematics.pdf">M.Sc. (Mathematics and Scientific Computing)</a>3. <a href="https://nitw.ac.in/media/uploads/2017/09/04/new_msc_matheematics_scheme-_2017.pdf">M.Sc. (Applied Mathematics) &amp; M.Sc. (Mathematics &amp; Scientific Computing) (Effective from July, 2017 )</a>',
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61caaf1b3587e96828b40eaf",
    time: 1640673051037,
    version: "2.22.2",
    title: "Old Curriculum",
    url: "/academicmsc/OldCurriculum",
    path: "/academicmsc",
    createdAt: "2021-12-28T06:30:51.180Z",
    updatedAt: "2021-12-28T06:30:51.180Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "1a9FqzTEL2",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Department", "Scheme &amp; Syllabi"],
            [
              "1",
              "School of Management",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/master-of-business-administration.pdf">Master of Business Management(MBA)</a>',
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61caafea3587e96828b40eb5",
    time: 1640673258005,
    version: "2.22.2",
    title: "New MBA Curriculum",
    url: "/academicmba/MBA",
    path: "/academicmba",
    createdAt: "2021-12-28T06:34:18.139Z",
    updatedAt: "2021-12-28T06:34:18.139Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "nmkDqm1ikQ",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Department", "Scheme &amp; Syllabi"],
            [
              "1",
              "School of Management",
              '<a href="https://nitw.ac.in/media/uploads/2018/09/26/mbasyllabus.pdf">Master of Business Management(MBA)</a>',
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab01c3587e96828b40eb7",
    time: 1640673308807,
    version: "2.22.2",
    title: "Old MBA Curriculum",
    url: "/academicmba/oldMBA",
    path: "/academicmba",
    createdAt: "2021-12-28T06:35:08.943Z",
    updatedAt: "2021-12-28T06:35:08.943Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "W57QQf3rGI",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Department", "Scheme &amp; Syllabi"],
            [
              "1",
              "Computer Science and Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/master-of-computer-applications.pdf">Master of Computer Applications (MCA)</a>',
            ],
          ],
        },
      },
      { id: "yIuZ2sWOaC", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61cab0a23587e96828b40ebc",
    time: 1640673442192,
    version: "2.22.2",
    title: "New MCA Curriculum",
    url: "/academicmca/MCA",
    path: "/academicmca",
    createdAt: "2021-12-28T06:37:22.327Z",
    updatedAt: "2021-12-28T06:37:22.327Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "dL_oX60qUM",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No", "Department", "Scheme &amp; Syllabi"],
            [
              "1",
              "Computer Science and Engineering",
              '<a href="https://nitw.ac.in/media/uploads/2018/09/26/mca.pdf">Master of Computer Applications (MCA)</a>',
            ],
          ],
        },
      },
      { id: "Fwuvdd5Aok", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61cab0e43587e96828b40ebe",
    time: 1640673508531,
    version: "2.22.2",
    title: "Old MCA Curriculum",
    url: "/academicmca/oldMCA",
    path: "/academicmca",
    createdAt: "2021-12-28T06:38:28.668Z",
    updatedAt: "2021-12-28T06:38:28.668Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "g0v4Ujub8E",
        type: "header",
        data: { text: "B.Tech Program", level: 4 },
      },
      {
        id: "7o7TFEVdEy",
        type: "header",
        data: { text: "Current&nbsp;", level: 5 },
      },
      {
        id: "_0iyrw8OUj",
        type: "paragraph",
        data: {
          text: '<editorjs-style style=`${MainServerUrl}/static/files/B.Tech_Program_2022-0-17-11-39-13.pdf`>Click here</a>',
        },
      },
      { id: "U4kOTCGGiz", type: "header", data: { text: "Old", level: 5 } },
      {
        id: "ziCiuyTekl",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2019-20</editorjs-style> - <a href="https://www.nitw.ac.in/media/uploads/2019/08/27/btech_rules_and_regulations-2019-20.pdf">Click here</a>',
        },
      },
      {
        id: "PFPeqbsxtF",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;\n">Year 2017-18</editorjs-style> -<a href="https://www.nitw.ac.in/media/uploads/2019/08/27/btech_rules_and_regulations-2017-18_-modified-upto-101-senate-meeting.pdf"> Click here</a><br><br><br>',
        },
      },
      {
        id: "ELpEFdpHly",
        type: "header",
        data: { text: "M.Tech Program", level: 4 },
      },
      { id: "GeOl2TLAXu", type: "header", data: { text: "Current", level: 5 } },
      {
        id: "3dzqhO6bT8",
        type: "paragraph",
        data: {
          text: '<editorjs-style style=`${MainServerUrl}/static/files/M.Tech_Program_2022-0-17-11-39-42.pdf`>Click here</a>',
        },
      },
      { id: "SWqnkPvGYV", type: "header", data: { text: "Old", level: 5 } },
      {
        id: "J3jjPqcC3j",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;\n">Year 2019-20 </editorjs-style>- <a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2019_20.pdf">Click here</a>',
        },
      },
      {
        id: "q_KQeVhlBc",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2017-18 (1)</editorjs-style>-<a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2017_18_modified-upto-101-senate-meeting.pdf"> Click here</a>',
        },
      },
      {
        id: "tSaLT9LGWP",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2017-18(2) </editorjs-style>- <a href="https://www.nitw.ac.in/media/uploads/2017/08/04/mtech-rules-2017_18_formatted.pdf">Click here</a><br><br>',
        },
      },
      {
        id: "c6TOyFT-Py",
        type: "header",
        data: { text: "Ph.D Program", level: 4 },
      },
      { id: "G07xvEju7f", type: "header", data: { text: "Current", level: 5 } },
      {
        id: "GXn04lFVuU",
        type: "paragraph",
        data: {
          text: '<editorjs-style style=`${MainServerUrl}/static/files/Ph.D_Program_2022-0-17-11-40-11.pdf`>Click here</a>',
        },
      },
      { id: "2sCaFN5pUn", type: "header", data: { text: "Old", level: 5 } },
      {
        id: "G_eMpHXhNw",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;\n">Year 2019-20 </editorjs-style>- <a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2019_20.pdf">Click here</a>',
        },
      },
      {
        id: "le1zH3o2Ax",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2017-18 (1)</editorjs-style>-<a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2017_18_modified-upto-101-senate-meeting.pdf"> Click here</a>',
        },
      },
      {
        id: "CrzOgsV5uV",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2017-18(2) </editorjs-style>- <a href="https://www.nitw.ac.in/media/uploads/2017/08/04/mtech-rules-2017_18_formatted.pdf">Click here</a><br><br><br>',
        },
      },
      {
        id: "0xewX0lT2E",
        type: "header",
        data: { text: "PG Programs", level: 4 },
      },
      { id: "DlAEsF5Jwv", type: "header", data: { text: "Current", level: 5 } },
      {
        id: "QVEusHmDG1",
        type: "paragraph",
        data: {
          text: '<editorjs-style style=`${MainServerUrl}/static/files/PG_Programs_2022-0-17-11-41-22.pdf`>Click here</a>',
        },
      },
      { id: "QX-ykAd8HJ", type: "header", data: { text: "Old", level: 5 } },
      {
        id: "xu_dpuTpXf",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;\n">Year 2019-20 </editorjs-style>- <a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2019_20.pdf">Click here</a>',
        },
      },
      {
        id: "HQRWLAwpc4",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2017-18 (1)</editorjs-style>-<a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2017_18_modified-upto-101-senate-meeting.pdf"> Click here</a>',
        },
      },
      {
        id: "9nsRlMvS-_",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2017-18(2) </editorjs-style>- <a href="https://www.nitw.ac.in/media/uploads/2017/08/04/mtech-rules-2017_18_formatted.pdf">Click here</a><br><br><br>',
        },
      },
      {
        id: "_Ub5MZVHgZ",
        type: "header",
        data: { text: "PG Diploma", level: 4 },
      },
      { id: "eEoobgqBtc", type: "header", data: { text: "Current", level: 5 } },
      {
        id: "SeQMYiX2yJ",
        type: "paragraph",
        data: {
          text: '<editorjs-style style=`${MainServerUrl}/static/files/PG_Diploma_2022-0-17-11-41-52.pdf`>Click here</a>',
        },
      },
      { id: "MXO6qRUGHb", type: "header", data: { text: "Old", level: 5 } },
      {
        id: "qob_VX5Ofl",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;\n">Year 2019-20 </editorjs-style>- <a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2019_20.pdf">Click here</a>',
        },
      },
      {
        id: "8r4VsjfT7L",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2017-18 (1)</editorjs-style>-<a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2017_18_modified-upto-101-senate-meeting.pdf"> Click here</a>',
        },
      },
      {
        id: "l2k_868j7y",
        type: "paragraph",
        data: {
          text: '<editorjs-style style="margin:10px;">Year 2017-18(2) </editorjs-style>- <a href="https://www.nitw.ac.in/media/uploads/2017/08/04/mtech-rules-2017_18_formatted.pdf">Click here</a><br><br><br>',
        },
      },
      {
        id: "22pfSH9iYQ",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="http://daa.nitw.ac.in:8081/path/?dept=/CurrentPhD">Ph.D Program</a>',
          ],
        },
      },
      {
        id: "An54SETsG_",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="http://daa.nitw.ac.in:8081/path/?dept=/PGProgramsrar">PG Programs</a>',
          ],
        },
      },
      {
        id: "bYbdH0Aa16",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="http://daa.nitw.ac.in:8081/path/?dept=/PGDiploma">PG Dilpoma</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab3623587e96828b40ec4",
    time: 1642399922629,
    version: "2.22.2",
    title: "Rules and Regulations",
    url: "/RulesandRegulations/list",
    path: "/RulesandRegulations",
    createdAt: "2021-12-28T06:49:06.425Z",
    updatedAt: "2022-01-17T06:12:02.815Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "lRZBITEexh",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/08/27/btech_rules-and-regulations-2021-22.pdf">&nbsp;2021 - 22 Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab4ac3587e96828b40ecd",
    time: 1640674476764,
    version: "2.22.2",
    title: "Current B.Tech Regulations",
    url: "/RAR/BTech",
    path: "/RAR",
    createdAt: "2021-12-28T06:54:36.896Z",
    updatedAt: "2021-12-28T06:54:36.896Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "KLk5J1tPuz",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2019/08/27/btech_rules_and_regulations-2019-20.pdf">2019 - 20&nbsp; Regulations</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2019/08/27/btech_rules_and_regulations-2017-18_-modified-upto-101-senate-meeting.pdf">2017 - 18&nbsp; Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab4c33587e96828b40ecf",
    time: 1640674499772,
    version: "2.22.2",
    title: "Old B.Tech Regulations",
    url: "/RAR/oldBTech",
    path: "/RAR",
    createdAt: "2021-12-28T06:54:59.903Z",
    updatedAt: "2021-12-28T06:54:59.903Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "yayvSvus4Q",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/08/27/m-tech_rules-and-regulations-2021-22.pdf">2021 - 22 Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab64a3587e96828b40ed4",
    time: 1640674890540,
    version: "2.22.2",
    title: "Current M.Tech Regulations",
    url: "/RARMTECH/M.Tech",
    path: "/RARMTECH",
    createdAt: "2021-12-28T07:01:30.698Z",
    updatedAt: "2021-12-28T07:01:30.698Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "mPBY_ivfLl",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2019_20.pdf">2019 - 20&nbsp; Regulations</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2019/08/28/m-tech-rules-2017_18_modified-upto-101-senate-meeting.pdf">2017 - 18&nbsp; Regulations&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/08/04/mtech-rules-2017_18_formatted.pdf" target="_blank">2017 - 18&nbsp; Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab6663587e96828b40ed6",
    time: 1640674918842,
    version: "2.22.2",
    title: "Old M.Tech Regulations",
    url: "/RARMTECH/OldM.Tech",
    path: "/RARMTECH",
    createdAt: "2021-12-28T07:01:58.980Z",
    updatedAt: "2021-12-28T07:01:58.980Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "7vQix5ZgYH",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/pg-diploma_rules-and-regulations-2021-22.pdf">PG Diploma Rules and Regulations (2021-22)</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab7133587e96828b40edc",
    time: 1640675091203,
    version: "2.22.2",
    title: "Current PG Diploma Regulations",
    url: "/PGDiploma/PGDiplomaRegulations",
    path: "/PGDiploma",
    createdAt: "2021-12-28T07:04:51.339Z",
    updatedAt: "2021-12-28T07:04:51.339Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "k40I0ei7tV",
        type: "header",
        data: {
          text: "Rules and Regulations of M.Sc/M.Sc(Tech)/Integrated M.Sc/MCA/MBA&nbsp;Programs",
          level: 6,
        },
      },
      {
        id: "1IqtBQaA52",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/08/30/pg-msc-int-rules-and-regulations-2021-22.pdf">2021 - 22 Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab7c13587e96828b40ee1",
    time: 1640675265150,
    version: "2.22.2",
    title: "Current PG Programs Regulations",
    url: "/PGProgramsrar/PGPrograms",
    path: "/PGProgramsrar",
    createdAt: "2021-12-28T07:07:45.288Z",
    updatedAt: "2021-12-28T07:07:45.288Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "UvVxnaWXTJ",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/PG_rules_2017-18_Sciences_MCA_and_MBA-1_1.pdf" target="_blank">2017 - 18&nbsp; Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab7dc3587e96828b40ee3",
    time: 1640675292317,
    version: "2.22.2",
    title: "Old PG Programs Regulations",
    url: "/PGProgramsrar/oldpg",
    path: "/PGProgramsrar",
    createdAt: "2021-12-28T07:08:12.448Z",
    updatedAt: "2021-12-28T07:08:12.448Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "BnnLoolJjL",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/08/27/phd-rules-and-regulations-2021-22.pdf">2021 - 22&nbsp; Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab8683587e96828b40ee8",
    time: 1640675432549,
    version: "2.22.2",
    title: "Current Ph.D Regulations",
    url: "/CurrentPhD/PhDRegulations",
    path: "/CurrentPhD",
    createdAt: "2021-12-28T07:10:32.677Z",
    updatedAt: "2021-12-28T07:10:32.677Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "fBJz4wovdB",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2019/09/10/phd-rules-2017-18modified-2019.pdf">2019&nbsp; Regulations</a>&nbsp;',
          ],
        },
      },
      {
        id: "eWi3_LtAd-",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2018/12/04/phd-rules-2017-18.pdf">2017 - 2018&nbsp; Regulations</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/Scheme&amp;Syllabus-2012/Ph.D1.pdf" target="_blank">2012 - 2013&nbsp; Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab8983587e96828b40eea",
    time: 1640675480631,
    version: "2.22.2",
    title: "Old Ph.D Regulations",
    url: "/CurrentPhD/OldPhD",
    path: "/CurrentPhD",
    createdAt: "2021-12-28T07:11:20.957Z",
    updatedAt: "2021-12-28T07:11:20.957Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "fBJz4wovdB",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2019/09/10/phd-rules-2017-18modified-2019.pdf">2019&nbsp; Regulations</a>&nbsp;',
          ],
        },
      },
      {
        id: "eWi3_LtAd-",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2018/12/04/phd-rules-2017-18.pdf">2017 - 2018&nbsp; Regulations</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/Scheme&amp;Syllabus-2012/Ph.D1.pdf" target="_blank">2012 - 2013&nbsp; Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab90d3587e96828b40ef3",
    time: 1640675477831,
    version: "2.22.2",
    title: "Old Ph.D Regulations",
    url: "/CurrentPhD/OldPhD",
    path: "/CurrentPhD",
    createdAt: "2021-12-28T07:13:17.960Z",
    updatedAt: "2021-12-28T07:13:17.960Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "fBJz4wovdB",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2019/09/10/phd-rules-2017-18modified-2019.pdf">2019&nbsp; Regulations</a>&nbsp;',
          ],
        },
      },
      {
        id: "eWi3_LtAd-",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2018/12/04/phd-rules-2017-18.pdf">2017 - 2018&nbsp; Regulations</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/Scheme&amp;Syllabus-2012/Ph.D1.pdf" target="_blank">2012 - 2013&nbsp; Regulations</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cab9fd3587e96828b40ef7",
    time: 1640675477831,
    version: "2.22.2",
    title: "Old Ph.D Regulations",
    url: "/CurrentPhD/OldPhD",
    path: "/CurrentPhD",
    createdAt: "2021-12-28T07:17:17.983Z",
    updatedAt: "2021-12-28T07:17:17.983Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "28eFKv1D1v",
        type: "header",
        data: { text: "Click on the below links to view", level: 4 },
      },
      {
        id: "JatZP-zfyp",
        type: "header",
        data: {
          text: '&nbsp;<a href="http://daa.nitw.ac.in:8081/path/?dept=/TT2021">Even Semester</a>',
          level: 4,
        },
      },
      {
        id: "aUxbiCJNFQ",
        type: "header",
        data: {
          text: '<a href="http://daa.nitw.ac.in:8081/path/?dept=/TT202">Odd Semester</a>',
          level: 4,
        },
      },
      { id: "nB41X2tStn", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61cad9243587e96828b40f00",
    time: 1641454162121,
    version: "2.22.2",
    title: "Time-Tables",
    url: "/TimeTable/list",
    path: "/TimeTable",
    createdAt: "2021-12-28T09:30:12.768Z",
    updatedAt: "2022-01-06T07:27:31.733Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "ss43a1fAbd",
        type: "header",
        data: {
          text: "Time-Table for II Semester of I B.Tech students&nbsp;",
          level: 6,
        },
      },
      {
        id: "p2Cfxbznhn",
        type: "paragraph",
        data: {
          text: '* Sections, A, B, C, D, E, F, and &nbsp;P [<a href="https://www.nitw.ac.in/media/uploads/2021/03/30/physics-cycle-i-btec-a-f-p-ii-sem-20-21.pdf">View/Download</a>]<br>* Sections, G, H, K, L, M, N, and Q&nbsp; [<a href="https://www.nitw.ac.in/media/uploads/2021/03/30/chemistry-cycle-i-btec-g-n-q-ii-sem-20-21-with-facultynames.pdf">View/Download</a>]<br><br>',
        },
      },
      {
        id: "QejzePE1nM",
        type: "header",
        data: {
          text: "II, III &amp; IV B.Tech and &nbsp;I Year&nbsp;PG Program",
          level: 6,
        },
      },
      {
        id: "VCRgoE7Eld",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "S.No",
              "Department",
              "&nbsp; Timetable(B.Tech)&nbsp;&nbsp;",
              "&nbsp; Timetable(M.Tech/PG)&nbsp;&nbsp;",
              "",
            ],
            [
              "1",
              "Civil Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/02/civil-btech-tt.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/31/mtech-civil-i-yr-ii-semester-jan-2022-time-table.pdf">View/Download</a>',
              "",
            ],
            [
              "2",
              "Electrical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/03/electrical-btech-tt.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/02/02_eed_tt_pg_dec-2021_01-01-2022_0730am.pdf">View/Download</a>',
              "",
            ],
            [
              "3",
              "Mechanical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/03/mech-btech_tt.pdf">View/Download</a>',
              '<a href=`${MainServerUrl}/static/files/Mechanical_Engineering_2022-0-3-15-46-26.pdf`>View/Download</a>',
              "",
            ],
            [
              "4",
              "Electronics and Communication Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/03/ece-btech-tt.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/03/ece-mtech-tt.pdf">View/Download</a>',
              "",
            ],
            [
              "5",
              "Metallurgical and Materials Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/31/mme-btech-tt.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/31/mme-mtech-tt.pdf">View/Download</a>',
              "",
            ],
            [
              "6",
              "Chemical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/03/chemical-btech-tt.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/03/chemical-mtech-tt.pdf">View/Download</a>',
              "",
            ],
            [
              "7",
              "Computer Science and Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/31/cse-btech-tt.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/31/cse-mtech-tt.pdf">View/Download</a>',
              "",
            ],
            [
              "8",
              "Bio-Technology",
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/02/biotech-btech-tt.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/31/biotech-mtech-tt.pdf">View/Download</a>',
              "",
            ],
            ["9", "Physics", "", "View/Download", ""],
            ["10", "Chemistry", "", "View/Download", ""],
            [
              "11",
              "School of Management",
              "",
              '<a href=`${MainServerUrl}/static/files/School_of_Management_2022-0-3-15-45-54.pdf`>View/Download</a>',
              "",
            ],
            [
              "12",
              "Mathematics",
              "",
              '<a href="https://www.nitw.ac.in/media/uploads/2022/01/03/maths-msc-tt.pdf">View/Download</a>',
              "",
            ],
            [
              "13",
              "MCA",
              "",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/31/cse-mca-tt.pdf">View/Download</a>',
              "",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61cad9813587e96828b40f02",
    time: 1641205111102,
    version: "2.22.2",
    title: "Even Semester",
    url: "/TT2021/EvenSemester",
    path: "/TT2021",
    createdAt: "2021-12-28T09:31:45.165Z",
    updatedAt: "2022-01-03T10:16:35.711Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "qJsYiVGmpz",
        type: "header",
        data: {
          text: "Timetable I Year B.Tech and &nbsp;I Year M.Sc. (5 years Integrated) Program - AY 2021-22 &nbsp;I Semester (online classwork)",
          level: 6,
        },
      },
      {
        id: "hqfQQA298j",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["S.No.", "Program Name", "Timetable with names of the faculty"],
            [
              "1",
              "B.Tech. in Civil Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/ced-i-year-i-sem-2021.pdf">Link to the PDF</a>',
            ],
            [
              "2",
              "B.Tech. in Electrical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/eee-tt.pdf">Link to the PDF</a>',
            ],
            [
              "3",
              "B.Tech. in Mechanical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/13/med-ug-21-22-i-yr-sem-1-timetable.pdf">Link to the PDF</a>',
            ],
            [
              "4",
              "B.Tech. in Electronics and Communication Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/ece_timetables_2021-22_sem_i.pdf">Link to the PDF</a>',
            ],
            [
              "5",
              "B.Tech. in Metallurgical and Materials Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/mme-2021-2022.pdf">Link to the PDF</a>',
            ],
            [
              "6",
              "B.Tech. in Chemical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/chemical-engineering-2021-odd-29-11-2021.pdf">Link to the PDF</a>',
            ],
            [
              "7",
              "B.Tech. in Computer Science and Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/cse-i-year-i-sem-2021.pdf">Link to the PDF</a>',
            ],
            [
              "8",
              "B.Tech. in Bio-Technology",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/biotechnology-i-year-i-sem-2021.pdf">Link to the PDF</a>',
            ],
            [
              "9",
              "5-year Integrated M.Sc.",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/12/02/integrated-msc-timetable-faculty-allotment_odd-sem-21-22-1.pdf">Link to the PDF</a>',
            ],
          ],
        },
      },
      {
        id: "4T2abPszWY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            'Classwork and examinations will be held online for the I Semester of the I year (Dec ’21-March ’22) as per the&nbsp;<a href="https://www.nitw.ac.in/main/OddSemester/nitw/">academic calendar</a>.&nbsp;',
            "The online meeting link (Google Meet/WebEx/Any other) will be shared by the faculty with the students through the official email addresses.&nbsp;",
            "All the students are required to note their official email address through WSDC portal.&nbsp;",
            "Also, students will be provided with access to LMS (Learning Management System) through which all the material will be shared, and examinations will be held. An email regarding the same will be sent after the classes commence.&nbsp;",
            'For any query, please contact the&nbsp;<a href="https://docs.google.com/spreadsheets/d/1WldxPvUqSvxVFpChtbc7zJsqO8yLc-LI/edit?usp=sharing&amp;ouid=113057180815940385929&amp;rtpof=true&amp;sd=true">faculty advisors of the corresponding department</a>&nbsp;(accessible through NITW account only).&nbsp;',
            "A good quality internet connection and a laptop may be procured by the students for the online classes, examinations, and other academic activities.&nbsp;",
            'For any queries related to the academic section, please&nbsp;<a href="https://www.nitw.ac.in/main/AcademicServicesforStudents/nitw/">see webpage</a>&nbsp;',
            "Any issue related to the student activities, contact Dean (Students Welfare).&nbsp;",
            "Any issue related to the hostel and stay on campus, contact the Chief Warden.&nbsp;",
            "Any issue related to the fee or any financial matter, contact the Accounts Section.",
          ],
        },
      },
      {
        id: "3Ab9MrtCRb",
        type: "header",
        data: {
          text: "II, III &amp; IV B.Tech and &nbsp;I/II/III&nbsp;Year&nbsp;PG Program -&nbsp;AY 2021-22&nbsp; I Semester",
          level: 6,
        },
      },
      {
        id: "gRLynRTgfq",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "S.No",
              "Department",
              "&nbsp; Timetable(B.Tech)&nbsp;&nbsp;",
              "&nbsp; Timetable(I M.Tech/PG)&nbsp;&nbsp;",
              "&nbsp; Timetable(II/III&nbsp; PG)&nbsp;&nbsp;",
            ],
            [
              "1",
              "Civil Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/06/ced_timetables_2021-22_sem_i.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/28/mtech-civil-timetable-aug-2021-1.pdf">View/Download</a>',
              "",
            ],
            [
              "2",
              "Electrical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/07/eed_timetables_2021-22_sem_i.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/classes-time-table-mtech-ped-pse-seg-i-sem-2021-22_22-08-2021-3.pdf">View/Download</a>',
              "",
            ],
            [
              "3",
              "Mechanical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/08/ug-ii-iii-and-iv-21-22-sem-1-timetable.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/28/mechanical-pg-21-22-sem-1-timetable.pdf">View/Download</a>',
              "",
            ],
            [
              "4",
              "Electronics and Communication Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/06/ece_timetables_2021-22_sem_i.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/27/mtech_timetables_2021-22_sem_i.pdf">View/Download</a>',
              "",
            ],
            [
              "5",
              "Metallurgical and Materials Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/09/04/tt-mme-btech-aug-2021-v06.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/28/tt-mme-aug-2021-mtech-v03.pdf">View/Download</a>',
              "",
            ],
            [
              "6",
              "Chemical Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/08/timetable-2021-odd-semester-aug-dec.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/25/i-year-mtech-chemical-odd-sep2021.pdf">View/Download</a>',
              "",
            ],
            [
              "7",
              "Computer Science and Engineering",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/07/cse_timetables_2021-22_sem_i.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/28/mtech-time-table-28-08-21-cse.pdf">View/Download</a>',
              "",
            ],
            [
              "8",
              "Bio-Technology",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/08/bt_timetables_2021-22_sem_i-v1.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/26/mtech-biotechnology-time-table-2021-22-odd-semester.pdf">View/Download</a>',
              "",
            ],
            [
              "9",
              "Physics",
              "",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/25/tt_1st-year-msc-tech.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/08/tt_2021_aug.pdf">View/Download</a>',
            ],
            [
              "10",
              "Chemistry",
              "",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/30/i-msc-timetable_2021_odd-semsester_final.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/08/timetable_odd-sem_2021-22_iii-sem_v2.pdf">View/Download</a>',
            ],
            [
              "11",
              "School of Management",
              "",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/26/time-table-mba-ist-year-2021.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/09/mba-v-quarter-time-table-2021.pdf">View/Download</a>',
            ],
            [
              "12",
              "Mathematics",
              "",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/25/i-year-msc-mathematics-time-table-odd-sem-ay2021-22.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/08/iii-sem-msc-maths.pdf">View/Download</a>',
            ],
            [
              "13",
              "MCA",
              "",
              '<a href="https://www.nitw.ac.in/media/uploads/2021/09/30/mca_i-year-i-sem_time-table.pdf">View/Download</a>',
              '<a href="https://www.nitw.ac.in/media/uploads/2021/08/09/mca-ii-iii-time-table.pdf">View/Download</a>',
            ],
          ],
        },
      },
      { id: "Kj0PNoetSK", type: "paragraph", data: { text: "<br>" } },
      { id: "--g4nREeiu", type: "paragraph", data: { text: "<br>" } },
      { id: "oPtdJ-5zwb", type: "paragraph", data: { text: "<br>" } },
      { id: "wNfDl5fKLz", type: "paragraph", data: { text: "<br>" } },
      { id: "rraCBVF2ay", type: "paragraph", data: { text: "<br>" } },
      { id: "b08I9SHQQA", type: "paragraph", data: { text: "<br>" } },
      { id: "tBh_J0kqnJ", type: "paragraph", data: { text: "<br>" } },
      { id: "mAnFySWhzw", type: "paragraph", data: { text: "<br>" } },
    ],
    language: "En",
    _id: "61cadbd03587e96828b40f04",
    time: 1640684496227,
    version: "2.22.2",
    title: "Odd Semester",
    url: "/TT202/OddSemester",
    path: "/TT202",
    createdAt: "2021-12-28T09:41:36.358Z",
    updatedAt: "2021-12-28T09:41:36.358Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "MG0zYexeWk",
        type: "paragraph",
        data: {
          text: '1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/02/02/1-form-for-doctoral-scrutiny-committe.doc">Form for Doctoral Scrutiny Committe</a>',
        },
      },
      {
        id: "Rob0MKaTK5",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; 1A.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/1a-form-for-doctoral-scrutiny-committe.doc">Form for change of Doctoral Scrutiny Committee</a>&nbsp;',
        },
      },
      {
        id: "sS4KBiQW74",
        type: "paragraph",
        data: {
          text: '2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/2-dsc-meeting-first-minutes.doc">DSC Meeting - First Minutes</a>&nbsp;',
        },
      },
      {
        id: "jHDgvvMmD6",
        type: "paragraph",
        data: {
          text: '3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/3-registration-form.doc">Registration Form</a>&nbsp;',
        },
      },
      {
        id: "UMJjIbEYR5",
        type: "paragraph",
        data: {
          text: '4.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/02/18/4-progress-review-form.docx">Progress Review Form</a>',
        },
      },
      {
        id: "womrS22ufz",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; 4A.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/4a-comprehensive-examination-report-form.docx">Comprehensive Examination Report Form</a>&nbsp;',
        },
      },
      {
        id: "eSqGYkbyNA",
        type: "paragraph",
        data: {
          text: '5.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/02/18/5-synopsis-submission-form.doc">Synopsis &nbsp;Submission Form</a>',
        },
      },
      {
        id: "izleK7K55G",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; 5A.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/02/18/5a-list-of-publications-form.doc">List of Publications Form</a>',
        },
      },
      {
        id: "OXSzMPxF4s",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; 5B.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/5bschedule-c.docx">Schedule C</a>',
        },
      },
      {
        id: "vIOwK0ttKH",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; 5C.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/5cschedule-d.docx">Schedule D</a>',
        },
      },
      {
        id: "NZ0tlLISuE",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp;&nbsp;5D.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/11/13/5d-no-dues-certifcate.docx">No Dues Certificate</a>&nbsp;',
        },
      },
      {
        id: "p41Zx12gua",
        type: "paragraph",
        data: {
          text: '6.&nbsp;<a href=`${MainServerUrl}/static/files/Form_for_List_of_External-Examiners_2022-0-17-11-38-12.doc`>Form for List of External-Examiners</a>&nbsp;',
        },
      },
      {
        id: "5GHD3wVMCo",
        type: "paragraph",
        data: {
          text: '7.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/04/24/7-thesis-submission-form.doc">Thesis Submission Form</a>',
        },
      },
      {
        id: "JuF4UseMUC",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp;&nbsp;&nbsp; 7A.<a href="https://www.nitw.ac.in/media/uploads/2021/04/24/7a-thesis-submission-certificate.doc">&nbsp;Thesis Submission Certificate&nbsp;</a>',
        },
      },
      {
        id: "LONxQXJzFp",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp;7B.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/11/13/7b-no-dues-certifcate.docx">No Dues Certificate</a>&nbsp;',
        },
      },
      {
        id: "TAkaasDZhU",
        type: "paragraph",
        data: {
          text: '8.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/02/18/8-compliance-report-dsc-minutes-form.doc">Compliance Report - DSC Minutes Form</a>',
        },
      },
      {
        id: "NVM8R9HBLA",
        type: "paragraph",
        data: {
          text: '9.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/9-report-of-oral-examination-for-phdthesis-defense.doc">Report of Oral-Examination for Ph.D.Thesis Defense</a>&nbsp;',
        },
      },
      {
        id: "z-5bGmdVea",
        type: "paragraph",
        data: {
          text: '10.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/11/22/10-request-for-provisional-degree-certificate-by-the-scholar.doc">Request for Provisional Degree Certificate by the Scholar</a>&nbsp;',
        },
      },
      {
        id: "w6XSksSoOT",
        type: "paragraph",
        data: {
          text: '11.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/11request-for-transfer-certificate.doc">Request for Transfer Certificate</a>&nbsp;',
        },
      },
      {
        id: "Np9pRmS2C_",
        type: "paragraph",
        data: {
          text: "12. Guidelines for preparation of Synopsis -&nbsp;Refer section 4.4 in&nbsp;",
        },
      },
      {
        id: "wXsugm1S1a",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2019/09/10/phd-rules-2017-18modified-2019.pdf" target="_blank">https://www.nitw.ac.in/media/uploads/2019/09/10/phd-rules-2017-18modified-2019.pdf</a>&nbsp;',
        },
      },
      {
        id: "aPcLAiyN64",
        type: "paragraph",
        data: {
          text: '13.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/27/phd_thesis-format.pdf">Guidelines for preparation of Thesis</a>&nbsp;',
        },
      },
      {
        id: "ZQyUZKczbK",
        type: "paragraph",
        data: {
          text: '14.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/10/02/phd_indian-examiner-bill-claim-form.docx">PhD Indian &nbsp;Examiner remuneration form</a>&nbsp;',
        },
      },
      {
        id: "LNY_lXdG74",
        type: "paragraph",
        data: {
          text: 'M1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/m1form-for-converting-full-time-to-part-time.docx">Form for Converting Full Time to Part Time</a>',
        },
      },
      {
        id: "_Zd4oH-ybe",
        type: "paragraph",
        data: {
          text: 'M2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/m2request-for-change-of-research-supervisor.doc">Request for Change of Research Supervisor</a>',
        },
      },
      {
        id: "13kgNNU7xM",
        type: "paragraph",
        data: {
          text: 'M3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/m3application-for-temporary-break.doc">Application for Temporary Break</a>',
        },
      },
      {
        id: "SQ3Sc4GkFI",
        type: "paragraph",
        data: {
          text: 'M4.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/m4student-leave-application-form-phd.doc">Student Leave Application Form (PhD)</a>',
        },
      },
      {
        id: "8TJr3lpHQG",
        type: "paragraph",
        data: {
          text: 'M5.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/m5form-for-cancellation.docx">Form for Cancellation</a>',
        },
      },
      {
        id: "-EMkxxx4oS",
        type: "paragraph",
        data: {
          text: 'M6.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/m6permission-to-carryout-part-of-phd-work-or-data-collection-outside-the-institute.docx">Permission to Carryout part of PhD work or Data Collection outside the Institute</a>',
        },
      },
      {
        id: "0H0AGfxU0e",
        type: "paragraph",
        data: {
          text: 'M7.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/12/27/m7-request-for-presenting-paper-in-conference.docx">Request for presenting paper in conference</a>',
        },
      },
      {
        id: "mBt6lZbnTq",
        type: "paragraph",
        data: {
          text: 'M8.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/11/13/m8-library-institute-deposit-refun-form.docx">Library-Institute Deposit Refund Form</a>',
        },
      },
    ],
    language: "En",
    _id: "61caddaf3587e96828b40f0f",
    time: 1642399704567,
    version: "2.22.2",
    title: "Ph.D",
    url: "/nitwForms/PhDforms",
    path: "/nitwForms",
    createdAt: "2021-12-28T09:49:35.552Z",
    updatedAt: "2022-01-17T06:08:24.791Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Zh2wJaCoHL",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/08/27/pg_dissertation-format.pdf">Guidelines for preparation of Dissertation Report</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cade243587e96828b40f11",
    time: 1640685091979,
    version: "2.22.2",
    title: "PG Forms",
    url: "/nitwForms/PGforms",
    path: "/nitwForms",
    createdAt: "2021-12-28T09:51:32.122Z",
    updatedAt: "2021-12-28T09:51:32.122Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "38Ld8XYG4M",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/08/27/ug_project-report-format_55vW5pL.pdf">Guidelines for preparation of Project Report</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cade533587e96828b40f13",
    time: 1640685139086,
    version: "2.22.2",
    title: "UG Forms",
    url: "/nitwForms/UGforms",
    path: "/nitwForms",
    createdAt: "2021-12-28T09:52:19.150Z",
    updatedAt: "2021-12-28T09:52:19.150Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "vMLjn-E6NZ",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021-22_FEE_ESTIMATION_CERTIFICATE_-_ACCOUNTS.pdf" target="_blank">15 Sep 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2021-22 FEE Estimation certificate for UG &amp; PG courses</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Details_of_fee_to_be_paid_by_MCAcandidates.pdf" target="_blank">12 Dec 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Details of fee to be paid by MCA students taking admission in 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/BTECH_MTECH_MBA_MCA_MSC_FEE_ESTIMATE.pdf" target="_blank">10 Nov 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Fees Estimation Certificate for various course in Academic Year 2020-21</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Details_of_fee_to_be_paid_by_MTech_candidates_Revised.pdf" target="_blank">27 Oct 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Details of the fee to be paid by M.Tech. candidates (Revised)</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Details_of_fee_structure_for_MBA_candidates_admitted_in_2020.pdf" target="_blank">03 Oct 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Details of the fee structure for MBA candidates admitted in 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PhD_Fee_Stucture.pdf" target="_blank">25 Aug 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; FEE STRUCTURE FOR THE YEAR 2020-21, I-SEMESTER FOR ALL CATEGORIES (PhD Scholars)</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/UG_PG_Fee_Stucture.pdf" target="_blank">24 Aug 2020&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; FEE STRUCTURE FOR THE YEAR 2020-21, I-SEMESTER FOR ALL CATEGORIES (B.Tech., M.Tech., M.Sc., M.Sc.Tech., MCA, &amp; MBA)</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cadfc13587e96828b40f26",
    time: 1640685504987,
    version: "2.22.2",
    title: "Fee Structure",
    url: "/FeeStructure/FS",
    path: "/FeeStructure",
    createdAt: "2021-12-28T09:58:25.142Z",
    updatedAt: "2021-12-28T09:58:25.142Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "jtoctTOOTt", type: "header", data: { text: "Dean", level: 2 } },
      {
        id: "CM9-1oGT-f",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Dean", "Name"],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Dean (Academic)</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462014,2017(office)&nbsp;</editorjs-style> Email:<a href="mailto:dean_acad@nitw.ac.in">dean_acad@nitw.ac.in</a>',
              '<editorjs-style style="display: block;\n            white-space: pre;">Dr. K.N.S. Kasi Viswanadham</editorjs-style> <editorjs-style style="display: block;\nwhite-space: pre;">Professor&nbsp;&nbsp;\nDepartment of Mathematics</editorjs-style>',
            ],
          ],
        },
      },
      {
        id: "q4ogReV8yT",
        type: "header",
        data: { text: "Academic Staff", level: 2 },
      },
      {
        id: "WiW1o_84R3",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Associate Deans", "Name", "Roles and Responsibilities"],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Associate Dean </editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">(Academic-UG)</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462059</editorjs-style>&nbsp;&nbsp;Email:&nbsp;<a href="mailto:ad_acad.ug@nitw.ac.in">ad_acad.ug@nitw.ac.in</a>',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Dr. V. Hari Kumar</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Associate Professor</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Mechanical Engineering</editorjs-style>',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Course registration\n</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Time Tables\n</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Curriculum Development,</editorjs-style>\n <editorjs-style style="display: block;\nwhite-space: pre;">Board of Studies</editorjs-style>\n<editorjs-style style="display: block;\nwhite-space: pre;">NBA and NIRF data of UG</editorjs-style>\n<editorjs-style style="display: block;\nwhite-space: pre;">Branch change</editorjs-style>',
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">&nbsp; Associate Dean</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">(Academic - PG&amp;R)</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462985&nbsp;</editorjs-style>\n Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">ad_acad.pg@nitw.ac.in</a>&nbsp;&nbsp;',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Dr. V. Rajesh Khana Raju</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Associate Professor</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Mechanical Engineering</editorjs-style>',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Course Registration</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Curriculum Development</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">&nbsp;Board of Studies</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Preparation of Plagiarism report</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">NBA and NIRF data of PG &amp; Ph.D</editorjs-style>',
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Professor-In-Charge</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">&nbsp;(Examinations)</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462021, 22, 25&nbsp;</editorjs-style>&nbsp;Email:&nbsp;<a href="mailto:ad_exam@nitw.ac.in">ad_exam@nitw.ac.in</a>',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Dr. D. Srinivasacharya</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Professor</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Mathematics</editorjs-style>',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Examination schedules</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Results and Grades</editorjs-style><editorjs-style style="white-space: pre;">Co-ordination for the conduct of Institute day / Convocation</editorjs-style>Data for Convocation / <editorjs-style style="white-space: pre;">Institute day / Annual report</editorjs-style><editorjs-style style="white-space: pre;">Issue of semester grade</editorjs-style> <editorjs-style style="white-space: pre;">sheets and consolidated grade sheet</editorjs-style><editorjs-style style="white-space: pre;">Issue of Original degree certificates</editorjs-style><editorjs-style style="white-space: pre;">Transcripts</editorjs-style><editorjs-style style="white-space: pre;">Academic Genuineness</editorjs-style>',
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Chairman</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Admissions</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462060</editorjs-style>Email: nitwadm@gmail.com&nbsp;',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Prof. Anand Kishore Kola</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Professor</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Dept. of Chemical Engineering</editorjs-style>',
              "Admissions of all the UG, PG and Ph.D programmes",
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Vice Chairman, </editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Admissions&nbsp; </editorjs-style>Phone: ....Email:&nbsp;',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Dr. Venkaiah Chowdary</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Associate Professor</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Dept. of Civil Engineering</editorjs-style>',
              "Admissions of all the UG, PG and Ph.D programmes",
            ],
          ],
        },
      },
      {
        id: "0rd1Jo1S8I",
        type: "header",
        data: { text: "Academic Staff", level: 2 },
      },
      {
        id: "wFQ4GlyhUz",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Academic Staff", "Name", "Roles &amp; Responsibilities"],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Dy. Registrar</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">&nbsp;(Academic)</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462055</editorjs-style>&nbsp;Email:<a href="mailto:ad_acad.phd@nitw.ac.in">&nbsp;dr_acad@nitw.ac.in</a>',
              "Sri. Soumen Karmakar",
              "Coordination with the staff of Academic section and Examination section.Leave permissions to the staff in academic section.General day to day activities in the Academic section.Coordinating and looking after the following activities:Scholarships for B.Tech.ICCRSPDCStudy In India (SII)Procurement of infrastructure or any other itemsAnnual Report DataData requested by MHRD during parliament sessionsBonafide Certificate and NOC for existing studentsStudy &amp; Conduct, TC &amp; Migration CertificatesCharacter and Bonafide certificatesUtilization certificateIncome certificateNo duesRTI and MHRD queriesSenate mattersM.Tech. StipendsPh.D fellowships (UGC, Visweswaraya, QIP, Institute)",
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Superintendent</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462063&nbsp;</editorjs-style>&nbsp;Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">acad_supdt@nitw.ac.in</a>',
              "Sri. S. Suryaprakash",
              "Coordinating with all the seats in Academic sectionForeign seat responsibilities",
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">UG seat</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462056</editorjs-style>&nbsp;Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">acad_ugseat@nitw.ac.in</a>',
              "Sri. B. Mangilal",
              "Temporary Break of Study of UGUG Admission CancellationIncome certificateBranch changeNo Objection Certificate, Study &amp; Conduct certificate,  Transfer and Migration Certificate, Custodian Certifcate, No dues certificateMaintenance of Academic Records, Archiving of Academic RecordsNBA and NIRF data for UGInformation for RTI / MHRD queries",
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">PG Seat</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462057&nbsp;</editorjs-style>&nbsp;Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">acad_pgseat@nitw.ac.in</a>',
              "Sri. Md. Afzal",
              "M.Tech. StipendsNo Objection Certificate, Study &amp; Conduct certificate, Transfer and Migration Certificate, Custodian Certifcate, No dues certificateTemporary Break of Study of PGFull-time to part-time conversionPG Admission CancellationMaintenance of Academic Records, Archiving of Academic RecordsInformation for RTI / MHRD queriesNBA and NIRF data of PG",
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Ph.D Seat</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462062</editorjs-style>&nbsp; Email:&nbsp;<a href="mailto:ad_acad.phd@nitw.ac.in">acad_phdseat@nitw.ac.in</a>',
              "Sri. Mahesh",
              "Ph.D fellowships (UGC, Visweswaraya, QIP, Institute)Extension and Enhancement of FellowshipsOrders of Maternity and Paternity leavesPermission for external Research workFull time to Part time conversionJRF project to Institute fellowship conversionTemporary Break of Study of Ph.DPh.D Admission CancellationProvisional, Transfer cum Migration certificatesBonafide certificateData for Convocation / Institute day / Annual reportMaintenance of Academic Records, Archiving of Academic RecordsInformation for RTI / MHRD Queries, NAD",
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">NSP &amp; Institute </editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">merit Scholarships seat</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462058&nbsp;</editorjs-style>&nbsp;Email:&nbsp;<a href="mailto:acad_nspscholarships@nitw.ac.in">acad_nspscholarships@nitw.ac.in</a>',
              "Sri. MD. Jafar",
              "<b>NSP Scholarships</b>Providing the bonafide certificateCollecting the NSP Scholarship soft and hard copies.Forwarding the online scholarship application from the Institute login as per the eligibility.<b>Institute Merit Scholarships</b>&nbsp; Every year Institute gives merit Scholarship (Reimbursement of one year paid tuition fee) with a merit Scholarship certificate based on their previous year performance.",
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Other State</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">&nbsp;Scholarships&nbsp;seat</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone:</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">+91-8702462055/2985&nbsp;</editorjs-style>&nbsp;Email:&nbsp;<a href="mailto:acad_statescholarships@nitw.ac.in">acad_statescholarships@nitw.ac.in</a>',
              "Sri. MD. Jafar",
              "<b>All State Post Matric Scholarship</b>The State Scholarships, which are not covered under NSP.Providing the bonafide certificatesStudent submit the soft and hard copies to the Institute, the applications will be forwarded through online / manually to the concerned sanction authorities of state welfare offices.The sanctioned scholarship from the welfare office will be credited in the Institute account.Collecting no dues from the accountsTaking approval of the Director’s to disbursement of scholarship to the concerned<b>Parent Departmental/ Private Scholarships</b>Providing bonafide certificateCollecting undertaking (the Student should not avail any tuition fee waiver/scholarships from any sources)Forwarding the applications",
            ],
            [
              '<editorjs-style style="display: block;\nwhite-space: pre;">Foreign Seat</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Phone: +91-870-2462061&nbsp;</editorjs-style>&nbsp;Email:&nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in">acad_foreignseat@nitw.ac.in</a>',
              '<editorjs-style style="display: block;\nwhite-space: pre;">Sri. MD. Shoukath Ali</editorjs-style><editorjs-style style="display: block;\nwhite-space: pre;">Sri. Bhaskar</editorjs-style>',
              "Utilization certificatesICCRFee structure every year (B.Tech and M.Tech)Bonafide certificatesJoining reports, InvoicePerformance reportExtension lettersUtilization certificatesReimbursement of final Project expensesCourse completion and Flight ticketsStudy in India (SII)Update of data in the portal every year Providing seat matrix, Fee structure every yearVerifying the assigned student through portalSending offer letter to selected studentsSending the joining reports, Invoice to SIIProviding necessary documents and information when every requested by SIISPDC ScholarshipsFee structure every year (B.Tech and M.Tech)Bonafide certificatesInvoicePerformance reportUtilization certificatesNepal Scholarships",
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61cae08b3587e96828b40f2a",
    time: 1643022325717,
    version: "2.22.2",
    title: "Academic Team",
    url: "/AcademicTeam/2021",
    path: "/AcademicTeam",
    createdAt: "2021-12-28T10:01:47.833Z",
    updatedAt: "2022-01-24T11:05:26.653Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "1GZBwxc17w",
        type: "header",
        data: { text: "DOs:&nbsp;", level: 4 },
      },
      {
        id: "Afs43rIAMT",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "All the students are to send the request/application/form by email to the concerned seat&nbsp;through proper channel.&nbsp;",
            "All requests will be handled by the concerned seat.&nbsp;",
            "Minimum 5 working days are required to address the request.&nbsp;",
            "Applicants should provide complete details required for the request including contact details.&nbsp;",
            'Please write emails from official email address (<a href="mailto:xxxx@student.nitw.ac.in">xxxx@student.nitw.ac.in</a>) only. The requests from personal email address will not be entertained due to authenticity issue.&nbsp;',
            "Always plan ahead of time and give sufficient time to process applications. Urgent requests are difficult to accommodate.&nbsp;",
            "Other than the bonafide certificate, TC and MC, Study cum Conduct certificates, Scholarships for UG students, a recommendation from the head of the department is required. In all such cases, student can write an email to their Head and request to send the recommendation to academic section. This is responsibility of the student.&nbsp;",
            "Contact Student Fee Seat of Accounts Section for any issues related to fee payment, refund, fee receipt, fee estimate, loans and any other financial/money matter. Contact details are given at the end of the page&nbsp;",
          ],
        },
      },
      {
        id: "ryOOCY7WOJ",
        type: "header",
        data: { text: "DON’Ts&nbsp;", level: 4 },
      },
      {
        id: "5BWOqNWdTP",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "There is no need to make a CC of the email to higher authorities like Associate Dean, Dean, Registrar, Director, which is also not appropriate.&nbsp;",
            "Do not send reminders before 5 working days from the date of submission of the request.&nbsp;",
            "Do not call any officials through phone unless there is an emergency.",
          ],
        },
      },
      {
        id: "-6HWF_0tIs",
        type: "paragraph",
        data: { text: "Contact Information:&nbsp;" },
      },
      {
        id: "eS36_4_FGa",
        type: "list",
        data: {
          style: "ordered",
          items: [
            '<b>Examinations</b>All exam related issues:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;<a href="https://www.nitw.ac.in/main/ContactDetails/NITW/">Contact Details</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2.&nbsp;<a href="https://www.nitw.ac.in/main/AcademicVerification/NITW/">Academic Verification for Genuineness</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/11/16/medium-of-instruction.pdf">Medium of Instruction</a>4.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2020/07/14/cgpa-conversion.pdf">CGPA to percentage conversion certificate</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.<a href="https://www.nitw.ac.in/main/Originalcertificates/NITW/">&nbsp;Original certificates/Grade sheets</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.&nbsp;<a href="https://www.nitw.ac.in/main/DuplicateCertificates/NITW/">Duplicate Certificates</a>7.&nbsp;<a href="https://www.nitw.ac.in/main/ExaminationTimetables/NITW/">Exam Schedules &amp; Time Tables</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.&nbsp;<a href="https://www.nitw.ac.in/main/2018/MEDALS/">Medals and Awards</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.&nbsp;<a href="https://www.nitw.ac.in/main/NoticesandCirculars/nitw/">Notices and Circulars</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;10.&nbsp;<a href="https://www.nitw.ac.in/main/Transcripts/NITW/">Transcripts</a>',
          ],
        },
      },
      {
        id: "gPDcv5M598",
        type: "header",
        data: { text: "Under-Graduate", level: 4 },
      },
      {
        id: "dW7fKq9IGg",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "All UG matters except related to examination section:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;acad_ugseat@nitw.ac.in&nbsp;(Certificates like Bonafide, TC cum MC, Study and Conduct (Character),NOC for an internship, income certificate related, leave etc)&nbsp;&nbsp;",
            'NSP &amp; Institute merit Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_nspscholarships@nitw.ac.in">acad_nspscholarships@nitw.ac.in</a>&nbsp;',
            'Academic Other State Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_statescholarships@nitw.ac.in">acad_statescholarships@nitw.ac.in</a>&nbsp;',
            'Academic Foreign Seat:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>',
          ],
        },
      },
      {
        id: "71aD4SEjIZ",
        type: "header",
        data: { text: "\n\nPost-Graduate", level: 4 },
      },
      {
        id: "Py7Nv7eF0L",
        type: "list",
        data: {
          style: "unordered",
          items: [
            'All PG matters except related to examination section:&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_pgseat@nitw.ac.in" target="_blank">acad_pgseat@nitw.ac.in</a>&nbsp;(Certificates like Bonafide, Transfer and Migration certificates,Study cum conduct certificates, Temporary withdrawal, full timeto part time conversion, cancellation etc.)&nbsp;',
            'Academic Foreign Seat:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>&nbsp;',
            'NSP &amp; Institute merit Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="mailto:acad_nspscholarships@nitw.ac.in">acad_nspscholarships@nitw.ac.in</a>&nbsp;',
            'Academic Other State Scholarships:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="mailto:acad_statescholarships@nitw.ac.in">acad_statescholarships@nitw.ac.in</a>',
          ],
        },
      },
      {
        id: "JDPxiFy5D_",
        type: "header",
        data: { text: "\n\nPh.D.", level: 4 },
      },
      {
        id: "MLU28Ketqe",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "All PhD matter:&nbsp; acad_phdseat@nitw.ac.in&nbsp;",
            'Academic Foreign Seat:&nbsp; <a href="mailto:acad_foreignseat@nitw.ac.in" target="_blank">acad_foreignseat@nitw.ac.in</a>&nbsp;',
          ],
        },
      },
      {
        id: "3AE_xyNXdN",
        type: "paragraph",
        data: { text: "<b>Contact phone numbers:</b>" },
      },
      {
        id: "zcqIHycUYu",
        type: "paragraph",
        data: { text: "<b>UG Issues&nbsp;:</b>&nbsp;+91-870-2462056" },
      },
      {
        id: "Bwn2UCDGoF",
        type: "paragraph",
        data: { text: "<b>PG Issues&nbsp;:</b>&nbsp;+91-870-2462057" },
      },
      {
        id: "RVz9qYukPF",
        type: "paragraph",
        data: { text: "<b>PhD Issues&nbsp;</b>:&nbsp;&nbsp;+91-870-2462062" },
      },
      {
        id: "olSc-5wYOD",
        type: "paragraph",
        data: {
          text: "<b>NSP&amp; Institute merit Scholarships issues:</b>&nbsp;+91-870-2462058",
        },
      },
      {
        id: "Q60ocyFhHE",
        type: "paragraph",
        data: { text: "<b>Other State Scholarships</b>:&nbsp;+91-870-2462985" },
      },
      {
        id: "oE0Eti89uh",
        type: "paragraph",
        data: { text: "<b>Foreign Student Issues:</b>&nbsp;+91-870-2462061" },
      },
      {
        id: "S_IgkwXXK4",
        type: "paragraph",
        data: {
          text: "<b>Superintendent of Academic Section</b>:&nbsp;+91-870-2462063",
        },
      },
      {
        id: "B8-Au0_nF_",
        type: "paragraph",
        data: { text: "<b>Dy. Registrar (Academic)</b>:&nbsp;+91-870-2462055" },
      },
      {
        id: "W6B-6YxqWo",
        type: "paragraph",
        data: {
          text: "Student Fee Seat <b>(Accounts Section)</b><br><b>Email:</b>&nbsp;studentfee@nitw.ac.in&nbsp;&nbsp;Phone:&nbsp;0870 246 2065 &nbsp;",
        },
      },
    ],
    language: "En",
    _id: "61cae44a3587e96828b40f35",
    time: 1641381789816,
    version: "2.22.2",
    title: "Academic Services",
    url: "/AcademicServices/2021",
    path: "/AcademicServices",
    createdAt: "2021-12-28T10:17:46.658Z",
    updatedAt: "2022-01-05T11:23:10.704Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "w1OdCURCkO",
        type: "header",
        data: { text: "Announcements", level: 4 },
      },
      {
        id: "gG3m24Ixza",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/website_notice-JoSAA-2021.pdf" target="_blank">14 Oct 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Online Awareness Program for JoSAA 2021 Aspirants by NIT Warangal on 17-10-2021 (Sunday) at 10 AM</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Notice_for_calling_back_PhD_and_PG_students-Oct2021_1.pdf">14 Oct 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Notice to PhD (Full-time) scholars except first year and PG final year students for calling back to the campus by 1st November&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2021</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PG__B.Tech_Dues_List.pdf" target="_blank">28 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; PG &amp; B.Tech Dues List</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Extension_30.07.2021.pdf" target="_blank">26 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; Submission of applications online for claiming the tuition fee remission based on the family Income</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Circular_for_CautionDeposit_and_JoSAA_CSAB_regund.pdf" target="_blank">23 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; Refund of Caution Deposit of outgoing batch and B.Tech., JoSAA/ CSAB refund</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Letter_to_college_Dean_or_Principal_.pdf" target="_blank">23 Jul 2021 :&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;Tribal Welfare Department - Awareness to ST students about financial assistance of Rs.20.00 lakhs under “Dr.B.R.Ambedkar</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/Letter_to_college_Dean_or_Principal_.pdf" target="_blank">Overseas Vidya&nbsp;Nidhi Scheme”</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Institute_Merit_Scholarship-2020-21.pdf" target="_blank">19 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;Institute Merit Scholarship 2020-21</a>&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Second_Online_verification_Notice_-_I_Year_M.Tech._Students.pdf" target="_blank">24 Mar 2021 :&nbsp; &nbsp; &nbsp;Second announcement for online verification of documents for I year M.Tech. students, who could not attend the verification during</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="https://nitw.ac.in/media/uploads/Second_Online_verification_Notice_-_I_Year_M.Tech._Students.pdf" target="_blank">Jan-Feb, 2021</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_to_MScMBAMCA.pdf" target="_blank">10 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First year M.Sc. / M.Sc.(Tech.) / MCA / MBA students, who are provisionally admitted in 2020</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_to_I_B.Tech.pdf" target="_blank">10 Mar 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First year B.Tech. students, who are provisionally admitted in 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Notice-M.Tech.-I_year-not_reported_to_online_verification-1.pdf">25 Feb 2021 :&nbsp; &nbsp; &nbsp;Notice with second semester tuition fee payment link to First Year M.Tech. students who did not report to online verification of&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;documents - Last date 2nd March 2021.&nbsp; &nbsp;</a>&nbsp;',
            '<a href="https://forms.gle/VbcAQ6HQmq3TTRJa8" target="_blank">10 Feb 2021 :&nbsp;&nbsp; &nbsp; The last date to fill the form for Physical Reporting of newly admitted PwD students of UG/PG/PhD programs is 13-02-2021.</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="https://forms.gle/VbcAQ6HQmq3TTRJa8" target="_blank">Online form link: https://forms.gle/VbcAQ6HQmq3TTRJa8</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/Online_verification_Notice_-_I_Year_Ph.D_Students_v2_fT2zlJ6.pdf">05 Feb 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to First Year Ph.D scholars (provisionally admitted in Sep 2020 and Jan 2021) regarding online verification.&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/PwD_Reporting.pdf" target="_blank">02 Feb 2021&nbsp;:&nbsp; &nbsp; &nbsp;Note to the newly admitted PwD category students of UG, PG and PHD programs- Physical Verification</a>&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Online_verification_Notice_-_I_Year_M.Tech._Students.pdf">13 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Notice to M.Tech. I year students (provisionally admitted in Sep 2020) regarding online verification.</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/January2021_fee_payment_instructions_to_students_2.pdf">11 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;UG, PG, PH.D fee payment for January 2021&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/IC_Reject_NOTICE.pdf" target="_blank">07 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Attention II, III and IV B.Tech students: Tuition fee dues payment. Deadline 10-01-2021</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/main/home/#" target="_blank">07 Jan 2021&nbsp;:&nbsp; &nbsp; &nbsp;Attention III B.Tech students: Open elective course choice can be exercised using the WSDC portal. Deadline: 10-01-2021</a>&nbsp;&nbsp;',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cae4923587e96828b40f39",
    time: 1641382370328,
    version: "2.22.2",
    title: "Announcements",
    url: "/Announcements/2021",
    path: "/Announcements",
    createdAt: "2021-12-28T10:18:58.525Z",
    updatedAt: "2022-01-05T11:32:51.696Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "uMgO7Lx3U8",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/11/11/i-btech-int-msc-ii-sem.pdf">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;I B.Tech. &amp; Int. MSc - II Sem</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/11/11/i-mca.pdf">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;I -&nbsp;MCA</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/11/11/i-msc-i-mtech.pdf">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;I- M.Sc. &amp; I-M.Tech.</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/11/11/ii_iii_iv-ug-ii_iii-pg-except-mba-phd.pdf">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;II_III_IV-UG &amp; II_III-PG (Except MBA) &amp; PhD</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/ACADEMICCALENDER/MBA/">12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;MBA Academic Calendars</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cae6cb3587e96828b40f49",
    time: 1640687307067,
    version: "2.22.2",
    title: "Even Semester",
    url: "/AcademicCalender/EvenSemester",
    path: "/AcademicCalender",
    createdAt: "2021-12-28T10:28:27.246Z",
    updatedAt: "2021-12-28T10:28:27.246Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9ejrpX3lTp",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '12 Nov 2021 :&nbsp; &nbsp; &nbsp; &nbsp;<a href="https://nitw.ac.in/media/uploads/2021/11/11/i-btech-int-msc-i-sem.pdf">I B.Tech. &amp; Int. MSc - I Sem</a>&nbsp;',
            '30 Sep 2021 :&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/09/30/academic-calendar-2021-22-i-yr-mca-odd-semester.pdf">Academic calendar (MCA I year - I Sem)</a>&nbsp;',
            '25&nbsp;Aug&nbsp;2021&nbsp;:&nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/main/MBAAcademic/Calendars/">&nbsp;MBA Academic Calendars &nbsp;</a>&nbsp;',
            '17&nbsp;Aug&nbsp;2021&nbsp;:&nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/08/17/academic-calendar-2021-22-i-yr-pg-odd-semester.pdf">&nbsp;Academic Calendar (I Yr. PG except MCA)&nbsp;</a>&nbsp;',
            '30 Jul 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/07/30/academic-calendar-2021-22.pdf">Academic Calendar (UG II / III / IV; PG II / III and PhD)&nbsp;</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cae6ef3587e96828b40f4b",
    time: 1640687343821,
    version: "2.22.2",
    title: "Odd Semester",
    url: "/AcademicCalender/OddSemester",
    path: "/AcademicCalender",
    createdAt: "2021-12-28T10:29:04.000Z",
    updatedAt: "2021-12-28T10:29:04.000Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "3d_kgwzLWg",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '22 March 2021 : &nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/03/22/acad_calendar-summer-quarter.pdf">Academic Calendar - Summer Quarter</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cae7103587e96828b40f4d",
    time: 1640687376790,
    version: "2.22.2",
    title: "Summer Quarter",
    url: "/AcademicCalender/SummerQuarter",
    path: "/AcademicCalender",
    createdAt: "2021-12-28T10:29:36.957Z",
    updatedAt: "2021-12-28T10:29:36.957Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "ML22P4Qtge", type: "header", data: { text: "2023", level: 6 } },
      { id: "ZtPXXh9mwX", type: "header", data: { text: "2022", level: 6 } },
      {
        id: "KtG7uCTa3t",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/12/31/vigyana-vani-january-202231122021-0402-pm.pdf">Vijyanavani January - 2022</a><br><br>',
          ],
        },
      },
      { id: "zSxz3dqRf6", type: "header", data: { text: "2021", level: 6 } },
      {
        id: "qr7ucPcbED",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2022/01/05/vigyanavani-aug2021.pdf">Vijyanavani August - 2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/05/vijnanavani-sept2021.pdf">Vijyanavani September - 2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/05/vijnanavani-oct2021.pdf">Vijyanavani October - 2021</a>',
          ],
        },
      },
      {
        id: "Mo7gyIK8U2",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/12/09/vijyanavani-nov-2021.pdf">Vijyanavani November - 2021</a>&nbsp;',
          ],
        },
      },
      {
        id: "xzm8oTXbae",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/12/09/vigyanavani-december-2021-final-30112021-final.pdf">Vijyanavani December - 2021</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61cc016ff278f591a4c86e16",
    time: 1641363579496,
    version: "2.22.2",
    title: "Issues",
    url: "/VIJNANAVANI/Issues",
    path: "/VIJNANAVANI",
    createdAt: "2021-12-29T06:34:23.760Z",
    updatedAt: "2022-01-05T06:17:55.701Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "28vLDa_G1u",
        type: "paragraph",
        data: {
          text: '<a href="http://nitw.euniwizarde.com/HomePage/loadSiteHomePage/hMnOs5f_-8O6VkRRC98Tmw">Click here to view</a>',
        },
      },
      {
        id: "hg3im1sxui",
        type: "paragraph",
        data: { text: '<a href="https://nitw.euniwizarde.com/">E-Tender</a>' },
      },
      {
        id: "nUfta_c8RL",
        type: "paragraph",
        data: { text: "link:&nbsp;http://nitw.euniwizarde.com/" },
      },
    ],
    language: "En",
    _id: "61cd42ac9d2ad79a09e20c33",
    time: 1640842530080,
    version: "2.22.2",
    title: "E-Tender",
    url: "/E-Tender/2021",
    path: "/E-Tender",
    createdAt: "2021-12-30T05:25:00.111Z",
    updatedAt: "2021-12-30T05:35:30.324Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "Zc7GKGWf_k", type: "header", data: { text: "Vision", level: 4 } },
      {
        id: "V_YCsAk8ti",
        type: "paragraph",
        data: {
          text: '"Towards a Global Knowledge Hub, striving continuously in pursuit of excellence in Education, Research, Entrepreneurship and Technological services to the society"',
        },
      },
    ],
    language: "En",
    _id: "61d521e6752f2720939b5155",
    time: 1641448620511,
    version: "2.22.2",
    title: "Vision",
    url: "/Vision/nitw",
    path: "/Vision",
    createdAt: "2022-01-05T04:43:18.017Z",
    updatedAt: "2022-01-06T05:55:10.122Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "RmO7Jzknfv",
        type: "header",
        data: {
          text: "NIT Warangal is well connected by rail and road.",
          level: 5,
        },
      },
      {
        id: "A0nkmrc2Ud",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Air Route: Hyderabad is the nearest Airport from Warangal. Take a train or a cab to our campus, which takes around two and half hours by&nbsp;road&nbsp;and two hours by&nbsp;rail.&nbsp;",
            "By Rail: Kazipet (3 kms) / Warangal (12 kms) are the nearest railheads. Many trains pass through Kazipet junction, which is generally preferred.&nbsp;",
            "By Road: NIT Warangal is 2½ hrs drive by cab on Hyderabad - Warangal National Highway number-202.",
          ],
        },
      },
    ],
    language: "En",
    _id: "61d522b1752f2720939b515d",
    time: 1641358104857,
    version: "2.22.2",
    title: "Visiting Nitw",
    url: "/home/visit",
    path: "/home",
    createdAt: "2022-01-05T04:46:41.021Z",
    updatedAt: "2022-01-05T04:46:41.021Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "KbX9NySTrC",
        type: "header",
        data: { text: "NIRF 2015 - 16", level: 5 },
      },
      {
        id: "zIaWJ8Eb9h",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/09/nit-data-for-nirf_1_combined.pdf">NITW NIRF DATA</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/sanctioned-approved-intake.pdf">Sanctioned (Approved) Intake</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/total-actual-studentprograms-offered-by-your-institution.pdf">Total Actual Student(programs Offered by Your Institution)</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/faculty-details.pdf">Faculty Details</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/publication-in-journals_international_nitw.pdf">PUBLICATION IN JOURNALS international_nitw</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/publication-in-journals_national_nitw.pdf">PUBLICATION IN JOURNALS national_nitw</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/01/12/no-of-publications-by-faculty-pdf-final_nitw.pdf">No of &nbsp;Publications by Faculty&nbsp;final_nitw</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/financial-resources.pdf">Financial Resources</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/09/placement.pdf">Placement_nitw</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/placment-and-higher-studies.pdf">Placement and Higher Studies</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/top-university_admissiondata.pdf">Top University_Admission Data</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/09/contunuing-education-program.pdf">Continuing Education program_nitw</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/consultancyprojectdetail.pdf">Consultancy Project &nbsp;Details_nitw&nbsp;</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/01/19/consultancy-project-details.pdf">Summary of Consultancy Project Details</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/sponsoredresearchdetail-205-16-15-14-13-final.pdf">Sponsored Research Details &nbsp;2013-14, 2014-15 &amp; 2015-16_nitw</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/presentation-inconferences-14-15-15-16_nitw.pdf">Presentation in Conferences 2014-15 &amp; 2015-16_nitw</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/ipr.pdf">IPR</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/pcs-facilties.pdf">PCS Facilities</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/sponsored-research-summary-final.pdf">Sponsored Research Summary</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/enterpreneurship-final.pdf">Entrepreneurship</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/executivedevelopmentprogram-final.pdf">Executive Development Program</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/higherstudies-final.pdf">Higher Studies</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/placement-final.pdf">Placement final</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/topuniversitydetails_3d-final.pdf">Top University Details_Nitw</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2017/05/08/nitw-alumni_in-iims.pdf">NITW Alumni_IN IIMs</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61d53a8c752f2720939b516e",
    time: 1641364211985,
    version: "2.22.2",
    title: "NIRF 2015 - 16",
    url: "/NIRF/2015-16",
    path: "/NIRF",
    createdAt: "2022-01-05T06:28:28.144Z",
    updatedAt: "2022-01-05T06:28:28.144Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "nZ5hWyPcK4",
        type: "header",
        data: { text: "NIRF 2016 - 17", level: 5 },
      },
      {
        id: "SQd6AybZ_7",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2018/01/04/all-report-mhrd-national-institutional-ranking-framework-nirf_overall.pdf">NIRF Overall 2016 - 2017&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2018/01/04/all-report-mhrd-national-institutional-ranking-framework-nirf_engineering.pdf">NIRF Engineering 2016-&nbsp; 17</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2018/01/04/final-merged-pdf-files-of-nit_2016-17.pdf">Annual Report 2016 - 17</a>',
          ],
        },
      },
      {
        id: "8CtVUNGqlU",
        type: "paragraph",
        data: {
          text: "Contact Details for NIRF Comments &amp; Feedback:&nbsp;",
        },
      },
      {
        id: "Xh3anwB0dp",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16197/">Prof.&nbsp; P.&nbsp; Anand Raj,&nbsp; Ph.D (IIT Kharagpur)</a>Dean, Planning &amp; Development',
        },
      },
      {
        id: "L35XzTs0kN",
        type: "paragraph",
        data: { text: "National Institute of Technology" },
      },
      {
        id: "VsHnCuJZ0Q",
        type: "paragraph",
        data: { text: "WARANGAL - 506 004" },
      },
      {
        id: "X1ISa7i5CW",
        type: "paragraph",
        data: { text: "Phone: +91 0872462013; 9490165363" },
      },
      {
        id: "JrmXz6cFiR",
        type: "paragraph",
        data: { text: "Email: dean_pl@nitw.ac.in" },
      },
    ],
    language: "En",
    _id: "61d53ad6752f2720939b5170",
    time: 1641364286450,
    version: "2.22.2",
    title: "NIRF 2016 - 17",
    url: "/NIRF/2016-17",
    path: "/NIRF",
    createdAt: "2022-01-05T06:29:42.608Z",
    updatedAt: "2022-01-05T06:29:42.608Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "iYW-XuDvtm",
        type: "header",
        data: { text: "NIRF 2017 - 18", level: 5 },
      },
      {
        id: "7shS8XWQ3S",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2019/01/03/nirf-overall-2017-2018.pdf">NIRF Overall 2017 - 2018&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2019/01/03/nirf_engineering-2017-2018.pdf">NIRF Engineering 2017-&nbsp; 18</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2019/03/15/annual-report-english-2017-18.pdf">Annual Report 2017 - 18</a>',
          ],
        },
      },
      {
        id: "AH88MSXyKj",
        type: "paragraph",
        data: {
          text: "Contact Details for NIRF Comments &amp; Feedback:&nbsp;",
        },
      },
      {
        id: "MCVEBrFWEA",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16197/">Prof.&nbsp; P.&nbsp; Anand Raj,&nbsp; Ph.D (IIT Kharagpur)</a>Dean, Planning &amp; Development',
        },
      },
      {
        id: "Kd3Txq_oEU",
        type: "paragraph",
        data: { text: "National Institute of Technology" },
      },
      {
        id: "6hBGIXqBWR",
        type: "paragraph",
        data: { text: "WARANGAL - 506 004" },
      },
      {
        id: "gvrO6nPE81",
        type: "paragraph",
        data: { text: "Phone: +91 0872462013; 9490165363" },
      },
      {
        id: "WcpDkVVy6i",
        type: "paragraph",
        data: { text: "Email: dean_pl@nitw.ac.in" },
      },
    ],
    language: "En",
    _id: "61d53af7752f2720939b5172",
    time: 1641364319299,
    version: "2.22.2",
    title: "NIRF 2017 - 18",
    url: "/NIRF/2017-18",
    path: "/NIRF",
    createdAt: "2022-01-05T06:30:15.458Z",
    updatedAt: "2022-01-05T06:30:15.458Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "1OZT24hRt_",
        type: "header",
        data: { text: "NIRF 2018 -19&nbsp;&nbsp;", level: 5 },
      },
      {
        id: "4kdDl7meFA",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2020/01/16/nirf-overall-2018-19.pdf">NIRF Overall 2018 - 2019&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2020/01/16/nirf-engineering-2018-19.pdf">NIRF Engineering 2018 - 2019</a>&nbsp;',
          ],
        },
      },
      {
        id: "HId0RKAbSm",
        type: "paragraph",
        data: {
          text: "Contact Details for NIRF Comments &amp; Feedback:&nbsp;",
        },
      },
      {
        id: "uCSe_5WGOz",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16257/">Prof.&nbsp; P.&nbsp; Bangarubabu</a>Dean, Planning &amp; Development',
        },
      },
      {
        id: "wg4xTxpH5t",
        type: "paragraph",
        data: { text: "National Institute of Technology" },
      },
      {
        id: "Bg48oQhj3b",
        type: "paragraph",
        data: { text: "WARANGAL - 506 004" },
      },
      {
        id: "h-j53q13kU",
        type: "paragraph",
        data: { text: "Phone: +91 0872462013; 9490165363" },
      },
      {
        id: "0F08b2zN0n",
        type: "paragraph",
        data: { text: "Email: dean_pl@nitw.ac.in" },
      },
    ],
    language: "En",
    _id: "61d53b18752f2720939b5174",
    time: 1641364352570,
    version: "2.22.2",
    title: "NIRF 2018 -19",
    url: "/NIRF/2018-19",
    path: "/NIRF",
    createdAt: "2022-01-05T06:30:48.724Z",
    updatedAt: "2022-01-05T06:30:48.724Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "OCyaA0uET4",
        type: "header",
        data: { text: "NIRF 2019 - 20", level: 5 },
      },
      {
        id: "k8IUKlbKZL",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/03/11/nirf2021-overall.pdf">NIRF Overall 2019 - 2020&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/03/11/nirf2021-engineering.pdf">NIRF Engineering 2019&nbsp;- 2020</a>&nbsp;',
          ],
        },
      },
      {
        id: "YcMiwNgqCL",
        type: "paragraph",
        data: {
          text: "Contact Details for NIRF Comments &amp; Feedback:&nbsp;",
        },
      },
      {
        id: "JRugAyQ1Bo",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16257/">Prof.&nbsp; P.&nbsp; Bangarubabu</a>Dean, Planning &amp; Development',
        },
      },
      {
        id: "px-ZvyN7EO",
        type: "paragraph",
        data: { text: "National Institute of Technology" },
      },
      {
        id: "whncOByKxd",
        type: "paragraph",
        data: { text: "WARANGAL - 506 004" },
      },
      {
        id: "0IRfV7qeY9",
        type: "paragraph",
        data: { text: "Phone: +91 0872462013; 9490165363" },
      },
      {
        id: "VvTnyJ8F9q",
        type: "paragraph",
        data: { text: "Email: dean_pl@nitw.ac.in" },
      },
    ],
    language: "En",
    _id: "61d53b3e752f2720939b5176",
    time: 1641364390421,
    version: "2.22.2",
    title: "NIRF 2019 - 20",
    url: "/NIRF/2019-20",
    path: "/NIRF",
    createdAt: "2022-01-05T06:31:26.582Z",
    updatedAt: "2022-01-05T06:31:26.582Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "glfQSXcvWS",
        type: "paragraph",
        data: {
          text: 'NIRF 2020-21 - <a href="http://daa.nitw.ac.in:8081/nirf/2021">Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61d53bfb752f2720939b517b",
    time: 1641364578968,
    version: "2.22.2",
    title: "NIRF 2020-21",
    url: "/NIRF/2020-21",
    path: "/NIRF",
    createdAt: "2022-01-05T06:34:35.130Z",
    updatedAt: "2022-01-05T06:34:35.130Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "VlP2_uAs8M",
        type: "header",
        data: { text: "Center For Advanced Material (CAM)", level: 6 },
      },
    ],
    language: "En",
    _id: "61d577b7026c1634dfc37e6a",
    time: 1641379871626,
    version: "2.22.2",
    title: "CAM",
    url: "/centres/cam",
    path: "/centres",
    createdAt: "2022-01-05T10:49:27.777Z",
    updatedAt: "2022-01-05T10:49:27.777Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "FhA-VqXWNo",
        type: "header",
        data: {
          text: "Centre For Automation And Instrumentation (CAI)",
          level: 6,
        },
      },
      {
        id: "J2qfbHZohT",
        type: "paragraph",
        data: {
          text: '1.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/10/09/cai-brochure.pdf">CAI-Brochure.</a>',
        },
      },
      {
        id: "9cFnMdF_Od",
        type: "paragraph",
        data: {
          text: '2.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/10/09/tariff.pdf">Tariff.</a>',
        },
      },
      {
        id: "r7WRD_qr2z",
        type: "paragraph",
        data: {
          text: '3.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/10/09/esr-requisition-form.pdf">ESR requisition form.</a>',
        },
      },
      {
        id: "_ahrmrOigi",
        type: "paragraph",
        data: {
          text: '4.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/10/09/lc-hrms-requisition-form.pdf">LC-HRMS requisition form.</a>',
        },
      },
      {
        id: "4ejQPgSOc-",
        type: "paragraph",
        data: {
          text: '5.&nbsp;<a href="https://www.nitw.ac.in/media/uploads/2017/10/09/pl-requisition-form.pdf">PL requisition form.</a>',
        },
      },
      {
        id: "IvwMjmd8No",
        type: "paragraph",
        data: {
          text: '6.<a href="https://www.nitw.ac.in/media/uploads/2017/10/09/uv-vis-requisition-form.pdf">&nbsp;UV-Vis requisition form.</a>',
        },
      },
      { id: "IQN03ihyQu", type: "paragraph", data: { text: "About CAI" } },
      {
        id: "ILY49wZN8Y",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; Centre for Automation and Instrumentation (CAI), formerly known as the Centre for Instrumentation Facility, was established at the National Institute of Technology, in the year 2005, with the support of the Department of Science &amp; Technology (DST), Government of India,New Delhi.&nbsp;",
        },
      },
      {
        id: "rKVyTyB7xo",
        type: "paragraph",
        data: {
          text: '&nbsp; &nbsp; &nbsp; &nbsp;CAI houses sophisticated analytical instruments which are operated and maintained by dedicated and qualified Technicians. It is an integral part of NIT.CAI does not operate to compete with any commercial laboratories but, it has a vision,&nbsp;"to be amongst the top Analytical Instrument Laboratories in the world".&nbsp;',
        },
      },
      {
        id: "5EUCI6fTmK",
        type: "paragraph",
        data: {
          text: "&nbsp; &nbsp; &nbsp; &nbsp;Instrumental methods of analyses form an indispensable aspect of any Research &amp; Development program. Keeping in view the inability of small educational institution and industries to procure and maintain sophisticated analytical instruments, the Department of Science &amp; Technology, set up Centre for Automation and Instrumentation (CAI) in different parts of the country.",
        },
      },
      {
        id: "7D5GZ7Sd8I",
        type: "paragraph",
        data: {
          text: "&nbsp;The main objectives of CAI facilities are:&nbsp;",
        },
      },
      {
        id: "lYFXma2pf4",
        type: "paragraph",
        data: {
          text: "To provide for guidance acquisition of data using Sophisticated Instruments.&nbsp;",
        },
      },
      {
        id: "9HEs5080mj",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "To organize workshops on the use and application of various spectroscopic and analytical techniques for students, teachers and personnel from other Laboratories, Universities and Industries.&nbsp;",
            "To provide facilities of sophisticated analytical instruments to scientists and other users from academic institutes, R&amp;D laboratories and industries to enable them to carry out measurements for R&amp;D work.&nbsp;&nbsp;",
            "&nbsp;Development of new measurement/analytical techniques: Efforts are made by the CAI to develop new techniques/ methods of analysis to put the instruments to their full use and offer them to the scientists for exploring new dimensions in research in various areas of science and technology.",
          ],
        },
      },
      {
        id: "PndKDACit5",
        type: "paragraph",
        data: {
          text: "Currently the following facilities are available to the users.",
        },
      },
      {
        id: "km6u4CCIIu",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "400 MHz Nuclear Magnetic Resonance (NMR) Spectrophotometer&nbsp;",
            "X-Band Electron Spin Resonance(ESR) Spectrometer&nbsp;",
            "Liquid Chromatography-High Resolution Mass Spectrometer (LC- HRMS)&nbsp;",
            "Scanning Electron Microscope (SEM)&nbsp;",
            "UV-VIS-NIR Spectrometer&nbsp;",
            "Wide Range Fluorescence Spectrophotometric Workstation&nbsp;",
            "Inductively Coupled Plasma Optical Emission Spectrophotometer (ICP-OES)&nbsp;",
            "Circular Dichroism-Optical Rotatory Dispersion (CD-ORD) Spectrometer&nbsp;",
            "Universal Testing Machine (UTM)&nbsp;",
            "Powder XRD",
          ],
        },
      },
      {
        id: "sZqkJIcmoN",
        type: "header",
        data: { text: "Faculty In-charge:", level: 4 },
      },
      {
        id: "Q7jKtXYaaQ",
        type: "paragraph",
        data: { text: "Centre for Automation and Instrumentation (CAI)," },
      },
      { id: "RnVB3Ep8gz", type: "paragraph", data: { text: "MOBILE:&nbsp;" } },
    ],
    language: "En",
    _id: "61d577dc026c1634dfc37e6c",
    time: 1643043871567,
    version: "2.22.2",
    title: "CAI",
    url: "/centres/CAI",
    path: "/centres",
    createdAt: "2022-01-05T10:50:04.171Z",
    updatedAt: "2022-01-24T17:04:32.099Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "T56-QpmEpR",
        type: "header",
        data: { text: "Centre For Educational Technology (CET)", level: 6 },
      },
      {
        id: "5vaHNTDIZ1",
        type: "paragraph",
        data: {
          text: "Centre for Educational Technology&nbsp;was established in 1987 with an objective of improving instructional methods and making learning effective.",
        },
      },
      {
        id: "Kvc-z6NUNR",
        type: "paragraph",
        data: {
          text: "The centre has a collection of about 300 video films on technical education. Its infrastructure includes over head projectors, slide projector, LCD projector, sound recording and playing system, DVD player, CD-ROM server, Computers with multimedia facility, CD writers, COMPAQ ML370 server with a Network of 10 nodes and educational CD-ROMs.",
        },
      },
      {
        id: "puuG_o5P6W",
        type: "paragraph",
        data: {
          text: "The Centre is engaged in designing and developing performance appraisal systems for the teachers and also analysis of students’ performance. Since its inception the centre has organised a number of seminars, short term training programs in Educational Technology and has helped young and junior teachers of engineering drawn from all over the country in acquiring effective instructional skills. The centre is helping students to learn better by conducting workshops on Learning Skills.",
        },
      },
      {
        id: "CceT2UhkRU",
        type: "paragraph",
        data: {
          text: "The infrastructure of the centre has been strengthened by two MHRD project, namely “Developing Networked Digital Library as an On-line Learning Resource” and “Extension of E-learning Facilities of Education Technology Laboratory”.",
        },
      },
      {
        id: "ehRey_qSv2",
        type: "paragraph",
        data: { text: "Faculty In-charge :" },
      },
      {
        id: "XWnIIwShVq",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/faculty/id/16237/" target="_blank">Dr. S. Porpandiselvi</a>',
        },
      },
      {
        id: "byM1qJS1Lx",
        type: "paragraph",
        data: { text: "Incharge Centre for Education Technology (CET)," },
      },
      {
        id: "qrYJQ4B-AX",
        type: "paragraph",
        data: { text: "MOBILE: 8332969300" },
      },
    ],
    language: "En",
    _id: "61d57825026c1634dfc37e6e",
    time: 1641454891867,
    version: "2.22.2",
    title: "CET",
    url: "/centres/CET",
    path: "/centres",
    createdAt: "2022-01-05T10:51:17.631Z",
    updatedAt: "2022-01-06T07:39:41.483Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "Z7fyd646hA",
        type: "header",
        data: {
          text: '<a href="https://nitw.ac.in/cii/">center for innovation and incubation (CII)</a>&nbsp;-&nbsp;<a href="https://nitw.ac.in/cii/">Click here</a>',
          level: 6,
        },
      },
    ],
    language: "En",
    _id: "61d5784a026c1634dfc37e70",
    time: 1641380018504,
    version: "2.22.2",
    title: "CII",
    url: "/centres/cii",
    path: "/centres",
    createdAt: "2022-01-05T10:51:54.648Z",
    updatedAt: "2022-01-05T10:51:54.648Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Academic Report", level: 4 },
      },
      {
        id: "HDN5BLThnE",
        type: "paragraph",
        data: {
          text: 'AY 2019-20 - <a href="https://www.nitw.ac.in/media/uploads/2021/03/28/latest_academic-report_23022021_for-publicaiton.pdf">Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61d58320026c1634dfc37e91",
    time: 1641382687286,
    version: "2.22.2",
    title: "Academic Report",
    url: "/academicReport/2021",
    path: "/academicReport",
    createdAt: "2022-01-05T11:38:08.519Z",
    updatedAt: "2022-01-05T11:38:08.519Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "V0d6do17-g",
        type: "header",
        data: { text: "Webinar - 1", level: 6 },
      },
      {
        id: "rcw1ieYq-s",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar1-17082021.pdf">Webinar 1-17082021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar2-23082021.pdf">Webinar 2-23082021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar3-26082021-1.pdf">Webinar 3-26082021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar4-27082021.pdf">Webinar 4-27082021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar5-30082021.pdf">Webinar 5-30082021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar6-03092021.pdf">Webinar 6-03092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar7-03092021.pdf">Webinar 7-03092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar8-06092021.pdf">Webinar 8-06092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar9-27082021.pdf">Webinar 9-27082021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar10-09092021.pdf">Webinar 10-09092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar11-11092021.pdf">Webinar 11-11092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar12-21092021.pdf">Webinar 12-21092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar13-24092021.pdf">Webinar 13-24092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar14-25092021.pdf">Webinar 14-25092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar15-25092021.pdf">Webinar 15-25092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar16-28092021.pdf">Webinar 16-28092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar17-30092021.pdf">Webinar 17-30092021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar18-01102021.pdf">Webinar 18-01102021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar19-05102021.pdf">Webinar 19-05102021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar20-26102021.pdf">Webinar 20-26102021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar21-29102021-1.pdf">Webinar 21-29102021&nbsp;</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar22-09112021.pdf">Webinar 22-09112021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar23-11112021.pdf">Webinar 23-11.11.2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar24-13112021.pdf">Webinar 24-13.11.2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar25-16112021.pdf">Webinar 25-16.11.2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar-26-18112021.pdf">Webinar 26-18.11.2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar27-20112021-dinakar.pdf">Webinar 27-20112021</a>',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar2824112021.pdf">Webinar 28-24112021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar2925112021_kashinath.pdf">Webinar 29-25112021</a>',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar-30-02122021.pdf">Webinar 30-02.12.2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar-31-08122021.pdf">Webinar 31-08.12.2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar-32-09122021.pdf">Webinar 32-09.12.2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar-3314122021bydrradhika.pdf">Webinar-33@14.12.2021</a>',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar-3416122021.pdf">Webinar-34@16.12.2021</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2022/01/17/webinar-35-06012022-purushotham.pdf">Webinar-35-06.01.2022</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61e51a0633f26ef1e95cf351",
    time: 1642417406920,
    version: "2.22.2",
    title: "Webinars",
    url: "/Webinars/Webinar-1",
    path: "/Webinars",
    createdAt: "2022-01-17T07:25:58.478Z",
    updatedAt: "2022-01-17T11:03:26.787Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "MCMMudMndY",
        type: "header",
        data: { text: "Hostels:", level: 3 },
      },
      {
        id: "SCdBeIDk_W",
        type: "paragraph",
        data: {
          text: "<editorjs-style>The Institute has excellent residential facilities for all the students admitted to the various courses. There are twenty hostel blocks (halls of residence) for men and two for women</editorjs-style>.",
        },
      },
      {
        id: "b2ztzpqRrG",
        type: "paragraph",
        data: {
          text: "<editorjs-style>Hostel administration is managed by a Chief Warden and a team of Wardens drawn from the faculty and appointed by the Director on a tenure basis. Hostel Advisory Council headed by the Director is responsible for major policy decisions with respect to the hostel administration. Hostel Executive Committee takes decisions on routine matters. Students have representation on both the administrative bodies. Hostel office has full-fledged supporting staff and other workers</editorjs-style>.",
        },
      },
      {
        id: "Oht5hWzK4_",
        type: "header",
        data: { text: "Messes&nbsp;:", level: 3 },
      },
      {
        id: "dpegQJwmjI",
        type: "paragraph",
        data: {
          text: "There are two Government non-vegetarian messes in the men’s hostels. These messes are operated on ‘no profit – no loss’ basis and cater to the taste of students from all regions of the country. There are mess representatives nominated from the students and a mess warden to supervise the smooth running of the messes.",
        },
      },
      {
        id: "Mg9kk59vPj",
        type: "paragraph",
        data: {
          text: "There are three Institute Food Courts which are running on contract basis  known as “IFC A, IFC B, IFC C,” for the men’s hostels  and “Priyadharshini Mess” for Ladies  in the Ladies hostel premises. There are mess representatives nominated from the students and a mess warden to supervise the smooth running of the messes.",
        },
      },
      {
        id: "lsBEGWjGVI",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="http://daa.nitw.ac.in:8081/page/?url=/Hostelfeestructure/nitw">20 Jan 2022 :&nbsp;UG, PG and 5 year Intregrtated M.Sc. and PhD Hostel fee structure </a>&nbsp; &nbsp;',
            '&nbsp; 13 Jan 2021&nbsp;:&nbsp;<editorjs-style style="">Hostel Fee for the students who are presently not staying in NITW</editorjs-style> <editorjs-style style=""><editorjs-style style="">Hostels For Online Registration –&nbsp; January2021 Session&nbsp;-&nbsp;<a href="https://www.nitw.ac.in/media/uploads/Hostel_fee_for_September_2020_-_who_are_presently_not_staying_in_NITW_Hostels.pdf">click here</a></editorjs-style></editorjs-style><a href="https://www.nitw.ac.in/media/uploads/Hostel_fee_for_September_2020_-_who_are_presently_not_staying_in_NITW_Hostels.pdf"><br></a>',
            '<editorjs-style>&nbsp;<a href="https://www.nitw.ac.in/media/uploads/ONLINE_PAYMENT_INSTRUCTIONS_TO_THE_STUDENTS-JANUARY_2021-not_staying_at_NITW_v1.pdf"> 13 Jan 2021&nbsp;:&nbsp;Online payment instructions to the students - January 2021 - not staying at nitw&nbsp;</a></editorjs-style>',
          ],
        },
      },
      {
        id: "GX6lX4AvyS",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/UGPGPhD21/2022/" target="_blank"><b>20 Jan 2022</b>&nbsp;: UG, PG, 5 year Intregrtated M.Sc. and PhD fee structure of even semester for the year 2021-2022</a>&nbsp;&nbsp;&nbsp;<i></i>',
          ],
        },
      },
      {
        id: "MTNx7Yj7a6",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/UGPGPhD21/2022/" target="_blank"><b>20 Jan 2022</b>&nbsp;: UG, PG, 5 year Intregrtated M.Sc. and PhD fee structure of even semester for the year 2021-2022</a>&nbsp;&nbsp;&nbsp;<i></i>',
          ],
        },
      },
      {
        id: "9_bOa79m3H",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/UGPGPhD21/2022/" target="_blank"><b>20 Jan 2022</b>&nbsp;: UG, PG, 5 year Intregrtated M.Sc. and PhD fee structure of even semester for the year 2021-2022</a>&nbsp;&nbsp;&nbsp;<i></i>',
          ],
        },
      },
      {
        id: "mvJ6wVNXdC",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/UGPGPhD21/2022/" target="_blank"><b>20 Jan 2022</b>&nbsp;: UG, PG, 5 year Intregrtated M.Sc. and PhD fee structure of even semester for the year 2021-2022</a>&nbsp;&nbsp;&nbsp;<i></i>',
          ],
        },
      },
      {
        id: "GAUjVoHPhI",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/main/HOSTELFEE/%20SEPTEMBER2020/" target="_blank">27 Aug 2020&nbsp;: Hostels: Payment fee details September 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/ONLINE_PAYMENT_INSTRUCTIONS_TO_THE_students_WZJCYCd.pdf" target="_blank">24 Aug 2020&nbsp;: Hostels: Payment for September 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/ONLINE_PAYMENT_INSTRUCTIONS_TO_THE_students.pdf" target="_blank">07 Dec 2019&nbsp;: Hostels: Payment for December 2019</a>&nbsp;&nbsp;&nbsp;&nbsp;',
          ],
        },
      },
      {
        id: "0rgJCf5PmT",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/Attention_csab.pdf" target="_blank">06 Aug 2019&nbsp;:&nbsp; Attention: Special Round students of CSAB 2019</a>&nbsp; &nbsp;&nbsp;',
          ],
        },
      },
      {
        id: "DHIbAUGJMV",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/Hostel_i-collect_payment_procedure_Seniors_v5.pdf">20 Jul 2019&nbsp;: Hostel fee payment for all students other than new admissions&nbsp;&nbsp;&nbsp;</a>&nbsp;',
            "20 Jul 2019&nbsp;: Hostel Allotment to all students (other than new admissions)&nbsp;&nbsp;&nbsp;",
            '<a href="https://nitw.ac.in/media/uploads/2019/07/20/hostel-fee-for-the-academic-year-2019-2020-other-than-i-year-18072019-v2.xlsx">18 Jul 2019&nbsp;: Revised:&nbsp;Hostel fee for the Academic year 2019-20 (other than I year)</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Notice_CSAB_candidates_special_round.pdf" target="_blank">15 Jul 2019&nbsp;: CSAB-2019 special round: Fee payment for Hostel Allotment</a>&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/#" target="_blank">14 Jul 2019&nbsp;: All the I year M.Tech students who have confirmed hostel admission (by paying hostel fee) during the reporting period June 24-27, 2019 may report at&nbsp;</a><a href="https://www.nitw.ac.in/#" target="_blank">1.8 K (ultramega) hostel (boys) and 12th block (girls).</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2019/07/31/cancellation-refund-procedure.pdf">13 Jul 2019 : Hostel admission cancellation and refund</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/main/facilities/hostels/">11 Jul 2019 : Revised:</a>&nbsp;The online hostel accommodation booking for all the 2nd and final year PG students (boys) for the academic year 2019-2020 will be open&nbsp;',
          ],
        },
      },
      {
        id: "PGxq9UEESy",
        type: "paragraph",
        data: {
          text: "&nbsp;On 13th July 2019&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11:00 am. to 1:00 pm for Final Year PG",
        },
      },
      {
        id: "-klkH49YCU",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3:00 pm to 5:00 pm for pre-final year PG&nbsp;",
        },
      },
      {
        id: "OyRhXkea6v",
        type: "paragraph",
        data: {
          text: "&nbsp;The concerned students are instructed to log into the student portal using their ID and book their hostel accommodation without any delay. If there are any issues in the online portal they can immediately bring it to the notice of the warden in charge Dr. Paul Joseph (8332969475 /&nbsp; 94910 65958).&nbsp;&nbsp;",
        },
      },
      {
        id: "zjV3tR_8KY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/OMAHA_PG__boys_-July_2019.docx" target="_blank">08 Jul 2019&nbsp;:&nbsp;</a><a href="https://www.nitw.ac.in/media/uploads/OMAHA_PG__boys_-July_2019.docx" target="_blank">Instructions for Hostel Allotment for Senior PG students – BOYS</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Hostel_sb-collect_payment_procedure_2019.pdf" target="_blank">02 Jul 2019&nbsp;: New Admissions: Instructions for Hostel fee payment</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/Hostel_Instructions_to_all_new_admissions_2019.pdf" target="_blank">02 Jul 2019&nbsp;: New Admissions: Instructions for hostel allotment</a>&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2019/07/10/hostel-fee-for-the-academic-year-2019-2020-modified-29062019.pdf">29 Jun 2019 : New Admissions: Hostel Fee for the Academic year 2019-2020</a>',
            '<a href="https://www.nitw.ac.in/main/tempacc/mtech2019/" target="_blank">13 Jun 2019&nbsp;: Temporary accommodation for students reporting for M.Tech admission</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/M.Tech_Student_Hostel_Allotment_Instructions_fS1wfyv.pdf" target="_blank">12 Jun 2019&nbsp;: New Admissions 2019 - M.Tech: Instructions for Hostel Allotment&nbsp;</a>&nbsp;&nbsp;&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2018/11/08/iccr-students-web.pdf">08 Nov 2018 : ICCR Students</a>',
            '<a href="https://nitw.ac.in/media/uploads/2018/07/12/hostel-fee-for-the-academic-year-2018-19-v1_HpkB25k.pdf" target="_blank">12&nbsp;Jul 2018&nbsp;: Hostel Fee for the Academic Year 2018-19&nbsp;</a>',
            '<a href="https://nitw.ac.in/media/uploads/2019/05/07/refund-policy.pdf">Refund policy</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61e6ce44a7d2dc1d6b6a9dce",
    time: 1642696285895,
    version: "2.22.2",
    title: "Hostel-About",
    url: "/hostel/about",
    path: "/hostel",
    createdAt: "2021-12-27T05:40:46.034Z",
    updatedAt: "2022-01-20T16:31:23.515Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "_-PCXVgjws",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "vheOXw_xdP",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Hostel_Blocks_&amp;_Capacity_2021-11-27-12-20-39.pdf`>Hostel Blocks &amp; Capacity</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e78949bf5be0365dff69",
    time: 1640587877652,
    version: "2.22.2",
    title: "Hostel Blocks & Capacity",
    url: "/hostel/hbc",
    path: "/hostel",
    createdAt: "2021-12-27T06:50:47.034Z",
    updatedAt: "2021-12-27T06:51:17.792Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "4X1vdXISNL",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "W18mI66g6V",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Hostel_Administration_2021-11-27-12-29-34.pdf`>Hostel Administration</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e7a549bf5be0365dff6a",
    time: 1640588380364,
    version: "2.22.2",
    title: "Hostel Administration",
    url: "/hostel/HostelAdministration",
    path: "/hostel",
    createdAt: "2021-12-27T06:59:40.452Z",
    updatedAt: "2021-12-27T06:59:40.452Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "YwSeHrgo7t",
        type: "header",
        data: { text: "Hostel rules and undertaking", level: 5 },
      },
      {
        id: "ahlJc8gM7j",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/11/hostel-rules-june-2018.pdf">1.&nbsp;DISCIPLINARY RULES FOR THE INMATES OF THE HOSTELS</a>',
        },
      },
      {
        id: "K_7PjDvvUX",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/media/uploads/2018/06/11/undertaking.pdf">2. Undertaking</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e7ae49bf5be0365dff6b",
    time: 1640588423517,
    version: "2.22.2",
    title: "Rules & Regulations",
    url: "/hostel/rr",
    path: "/hostel",
    createdAt: "2021-12-27T07:00:23.621Z",
    updatedAt: "2021-12-27T07:00:23.621Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "FsujS9nldq",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "5F8CsSm2GJ",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Office_Staff_2021-11-27-12-31-40.pdf`>Office Staff</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e7bb49bf5be0365dff6c",
    time: 1640588524242,
    version: "2.22.2",
    title: "Office Staff",
    url: "/hostel/os",
    path: "/hostel",
    createdAt: "2021-12-27T07:02:04.325Z",
    updatedAt: "2021-12-27T07:02:04.325Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "4hoGjQ9nL7",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "y4_mMl7vwT",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/I-Mess_2021-11-27-12-33-46.pdf`>I-Mess</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e7c449bf5be0365dff6d",
    time: 1640588633153,
    version: "2.22.2",
    title: "I-Mess",
    url: "/hostel/imess",
    path: "/hostel",
    createdAt: "2021-12-27T07:03:53.283Z",
    updatedAt: "2021-12-27T07:03:53.283Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "B_HfKhCXGw",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "7EpD9nMHRY",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/2_mess_2021-11-27-12-41-39.pdf`>II-Mess</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e7cc49bf5be0365dff6e",
    time: 1640589117242,
    version: "2.22.2",
    title: "II-Mess",
    url: "/hostel/2mess",
    path: "/hostel",
    createdAt: "2021-12-27T07:11:57.362Z",
    updatedAt: "2021-12-27T07:11:57.362Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "c86hiLsZfX",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "WLeki5gOIa",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Sanitation_2021-11-27-12-43-40.pdf`>Sanitation</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e7d549bf5be0365dff6f",
    time: 1640589226215,
    version: "2.22.2",
    title: "Sanitation",
    url: "/hostel/Sanitation",
    path: "/hostel",
    createdAt: "2021-12-27T07:13:46.304Z",
    updatedAt: "2021-12-27T07:13:46.304Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "lQqkvL5_Bn",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "jEu9-QtzdM",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Room_Allotment_2021-11-27-12-45-16.pdf`>Room Allotment</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e7f649bf5be0365dff70",
    time: 1640589322887,
    version: "2.22.2",
    title: "Room Allotment",
    url: "/hostel/RoomAllotment",
    path: "/hostel",
    createdAt: "2021-12-27T07:15:22.975Z",
    updatedAt: "2021-12-27T07:15:22.975Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "K8H8cmnJfP",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "vPxmp7om9n",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Ladies_Hostel_2021-11-27-12-46-47.pdf`>Ladies Hostel</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e7fd49bf5be0365dff71",
    time: 1640589414471,
    version: "2.22.2",
    title: "Ladies Hostel",
    url: "/hostel/lh",
    path: "/hostel",
    createdAt: "2021-12-27T07:16:54.564Z",
    updatedAt: "2021-12-27T07:16:54.564Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "BMq9PibyYN",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "pMSSWJWHug",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Halls_of_Residence_2021-11-27-12-48-21.pdf`>Halls of Residence</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e80349bf5be0365dff72",
    time: 1640589507736,
    version: "2.22.2",
    title: "Halls of Residence",
    url: "/hostel/hor",
    path: "/hostel",
    createdAt: "2021-12-27T07:18:27.827Z",
    updatedAt: "2021-12-27T07:18:27.827Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "a-UHLEzhKp",
        type: "paragraph",
        data: { text: "Click here to view" },
      },
      {
        id: "JDyLrpkqpG",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Hostels-NITW-blocks_2021-11-27-12-50-45.pdf`>Hostels-NITW-blocks</a>',
        },
      },
    ],
    language: "En",
    _id: "61e6e80c49bf5be0365dff73",
    time: 1640589657237,
    version: "2.22.2",
    title: "Hostels-NITW-blocks",
    url: "/hostel/HostelsNITWblocks",
    path: "/hostel",
    createdAt: "2021-12-27T07:20:57.332Z",
    updatedAt: "2021-12-27T07:20:57.332Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "RZr0rvrjno",
        type: "paragraph",
        data: {
          text: "As per Section 14 of the NITs Act, 1007, the Senate of every Institute shall consist of the following persons, namely:",
        },
      },
      {
        id: "botQaGJDff",
        type: "paragraph",
        data: {
          text: "(a) the Director, ex-officio, who shall be the Chairman of the Senate;",
        },
      },
      {
        id: "SBrkm65UIf",
        type: "paragraph",
        data: { text: "(b) the Deputy Director, ex-officio;" },
      },
      {
        id: "C2JjQR4HYG",
        type: "paragraph",
        data: {
          text: "(c) the Professors appointed or recognized as such by the Institute for the purpose of imparting instructions in the Institute;",
        },
      },
      {
        id: "OPZypueJNs",
        type: "paragraph",
        data: {
          text: "(d) three persons, one of whom shall be woman, not being employees of the Institute, to be nominated by the Chairperson in consultation with the Director, from amongst educationalists of repute, one each from the field of science, engineering and humanities; and",
        },
      },
      {
        id: "mVDslRoTeW",
        type: "paragraph",
        data: {
          text: "(e) such other members of the staff as may be laid down in the Statutes.",
        },
      },
      {
        id: "XEJyaOyqRJ",
        type: "header",
        data: {
          text: "DETAILS OF THE CHAIRMAN AND THE EXTERNAL MEMBERS OF SENATE OF NATIONAL INSTITUTE OF TECHNOLOGY, WARANGAL",
          level: 6,
        },
      },
      {
        id: "7NNSMQxJYt",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "SL.NO.",
              "SECTION",
              "NAME, DES. &amp; ADDRESS",
              "POSITION HELD",
              "E-MAIL ID, PHONE &amp; FAX NOS.",
            ],
            [
              "1",
              "14 (a)",
              "Prof. N.V. Ramana Rao,Director ,&nbsp;National Institute of Technology,Warangal - 506 004",
              "Chairman",
              "director@nitw.ac.in",
            ],
            [
              "2",
              "14 (d)",
              "&nbsp; Prof. Sujata PatelPresident, Indian Sociological Society (2016-17)ProfessorDepartment of SociologyUniversity of Hyderabad&nbsp;",
              "&nbsp; &nbsp; External Member",
              "Patel.sujata09@gmail.com",
            ],
            [
              "3",
              "14 (d)",
              "&nbsp;Prof. O.R. JaiswalDept of Applied MechanicsVNIT, Nagpur",
              "External Member",
              "orjaiswal@apm.vnit.ac.in",
            ],
            [
              "4",
              "14 (d)&nbsp;&nbsp;",
              "Prof. P.S. RoyNASI Senior Scientist Platinum Jubilee FellowCenter for Earth &amp; Space Sciences&nbsp;University of Hyderabad&nbsp;&nbsp;",
              "&nbsp;External Member",
              "Psroy13@gmail.com",
            ],
          ],
        },
      },
      {
        id: "kpNGXDNxuM",
        type: "paragraph",
        data: {
          text: "Section 14 of the National Institutes of Technology Act, 2007 (Act 29 of 2007).",
        },
      },
      {
        id: "JsqG_n0gxP",
        type: "header",
        data: { text: "SENATE - FUNCTIONS &amp; POWERS&nbsp;:", level: 6 },
      },
      {
        id: "DXlgTBybQL",
        type: "paragraph",
        data: {
          text: "(A)&nbsp;FUNCTIONS: The Senate of an Institute shall have the control and general regulation, and be responsible for the maintenance of standards of instruction, education and examination in the Institute and shall exercise such other powers and perform such other duties as may be conferred or imposed upon it by the Statutes.",
        },
      },
      {
        id: "7X7Qfu4clv",
        type: "paragraph",
        data: {
          text: "(B)&nbsp;POWERS: The Senate shall have the powers to:-",
        },
      },
      {
        id: "aIwhQ5YGez",
        type: "paragraph",
        data: {
          text: "(i) frame and revise curricula and syllabi for the course of studies for the various Departments and Centres;",
        },
      },
      {
        id: "ixKgJDuHYj",
        type: "paragraph",
        data: {
          text: "(ii) make arrangements for the conduct of examinations, appointment of examiners, moderators, tabulators and other matters relating to the examinations;",
        },
      },
      {
        id: "JK_3gRu6u1",
        type: "paragraph",
        data: {
          text: "(iii) declare the results of the examinations or to appoint Committees or Officers to do so and to make recommendations to the Board regarding conferment or grant of degrees, diplomas and other academic distinctions or titles;",
        },
      },
      {
        id: "HyThNoxIGK",
        type: "paragraph",
        data: {
          text: "(iv) appoint Advisory Committees or Expert Committees or both for the Departments or Centres of the Institute to make recommendations on academic matters connected with the working of the Departments or Centres;",
        },
      },
      {
        id: "N1rxjHtg4s",
        type: "paragraph",
        data: {
          text: "(v) appoint Committees from amongst the members of the Senate, other teachers of the Institute and experts from outside to advise on such specific and important academic matters as may be referred to any such Committee by the Senate.",
        },
      },
      {
        id: "_KZASY2q_T",
        type: "paragraph",
        data: {
          text: "(vi) consider the recommendations of the Advisory Committees attached to various Departments or Centres and that of Experts and other Committees and take such action (including the making of recommendations to the Board) as warranted by each case)",
        },
      },
      {
        id: "danx8RM8ZX",
        type: "paragraph",
        data: {
          text: "(vii) make periodical review of the activities of the Departments or Centres and take appropriate action (including the making of recommendations to the Board);",
        },
      },
      {
        id: "7ELSqxciob",
        type: "paragraph",
        data: {
          text: "(viii) supervise the working of the Library of the Institute.",
        },
      },
      {
        id: "DuhqDmaG0e",
        type: "paragraph",
        data: {
          text: "(ix) promote research and academic development or activity within the Institute and seek reports on such research or academic development or activity from the persons engaged therein;",
        },
      },
      {
        id: "JGvjkZFROV",
        type: "paragraph",
        data: {
          text: "(x) provide for the inspection of the class rooms, Laboratories, Library and the Residential Hostels;",
        },
      },
      {
        id: "Jdy1tsmtkO",
        type: "paragraph",
        data: {
          text: "(xi) plan co-curricular activities of the students of the Institute.",
        },
      },
      {
        id: "EKUBONBQSh",
        type: "paragraph",
        data: {
          text: "(xii) award stipends, scholarships, medals and prizes and make other awards in accordance with such conditions as may be attached to the awards;",
        },
      },
      {
        id: "UScw-GwJG1",
        type: "paragraph",
        data: {
          text: "(xiii) make recommendations to the Board with regard to the creation or restructuring of Departments or Programmes or Centres and the abolition of existing Departments or centres thereof;",
        },
      },
      {
        id: "VcgMpQPvFa",
        type: "paragraph",
        data: {
          text: "(xiv) make recommendations to the Board of disseminate knowledge through distance learning mode to various parts of the State or country or abroad; and",
        },
      },
      {
        id: "fvPPuutXBH",
        type: "paragraph",
        data: {
          text: "(xv) invite upto two student representatives during discussion of general nature nor involving policy or disciplinary matters in the Senate meetings.",
        },
      },
      {
        id: "KugYF3tFQL",
        type: "header",
        data: {
          text: "CHAIRMAN OF THE SENATE TO EXERCISE POWERS IN EMERGENCY&nbsp;:",
          level: 6,
        },
      },
      {
        id: "ATbhs5UmRy",
        type: "paragraph",
        data: {
          text: "If, in the opinion of the Chairman of the Senate, any emergency has arise which requires immediate action, he may take such action as he deems necessary and shall report the same for approval to the Senate in its next meeting.",
        },
      },
    ],
    language: "En",
    _id: "61e7b60da7d2dc1d6b6a9dec",
    time: 1642575374700,
    version: "2.22.2",
    title: "SENATE",
    url: "/administration/senate/",
    path: "/administration",
    createdAt: "2022-01-19T06:56:13.732Z",
    updatedAt: "2022-01-19T06:56:13.732Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "3VziU9xDIF",
        type: "header",
        data: { text: "Newsletters and Academic Reports", level: 3 },
      },
      {
        id: "ewkRlc8O3N",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '20 Jan &nbsp;2022:&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;<a href=`${MainServerUrl}/static/files/Institute_Newsletter_December_2021_2022-0-20-10-31-9.pdf`> Institute Newsletter December 2021</a><br>',
            '21 Nov&nbsp; 2021:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href=`${MainServerUrl}/static/files/Institute_Newsletter_October_2021_2022-0-19-14-13-39.pdf`>Institute&nbsp;Newsletter October&nbsp;2021</a>&nbsp;',
            '04 Aug 2021 : &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; <a href=`${MainServerUrl}/static/files/Institute_Newsletter_Issue_2_-_2021_2022-0-19-14-15-42.pdf`>Institute&nbsp; Newsletter Issue 2 - 2021&nbsp;</a>',
            '20 Apr 2021&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href=`${MainServerUrl}/static/files/nitwnewslettermarch2021_2022-0-19-14-16-53.pdf`>Institute Newsletter March 2021&nbsp;</a>&nbsp;&nbsp;&nbsp;',
            '28 March 2021:&nbsp;&nbsp;&nbsp; &nbsp;<a href="https://www.nitw.ac.in/media/uploads/2021/03/28/latest_academic-report_23022021_for-publicaiton.pdf">Academic Report 2019 - 20</a>&nbsp;',
            '04 Nov 2020&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href=`${MainServerUrl}/static/files/Institute_Newsletter_November_2020_2022-0-19-14-22-59.pdf`>Institute Newsletter November 2020</a>&nbsp;&nbsp;&nbsp;&nbsp;',
            '01 Aug 2020&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href=`${MainServerUrl}/static/files/Institute_Newsletter_August_2020_2022-0-19-14-24-17.pdf`>Institute Newsletter August 2020</a>&nbsp;',
          ],
        },
      },
    ],
    language: "En",
    _id: "61e7cddea7d2dc1d6b6a9df4",
    time: 1642654917538,
    version: "2.22.2",
    title: "Newsletter",
    url: "/newsletter",
    path: "/newsletter",
    createdAt: "2022-01-19T08:37:50.436Z",
    updatedAt: "2022-01-20T05:01:55.266Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "2KQViHmLRQ",
        type: "header",
        data: { text: "POSTAL ADDRESS", level: 3 },
      },
      {
        id: "9k00koYCem",
        type: "paragraph",
        data: {
          text: "National Institute of Technology,Warangal - 506004,TS, INDIA",
        },
      },
      {
        id: "npkU3rdXL8",
        type: "paragraph",
        data: { text: "Phone: +91-870-2459191 FAX : +91-870-2459547" },
      },
      {
        id: "GtguKew7PM",
        type: "header",
        data: { text: "Institute Administration", level: 3 },
      },
      {
        id: "D9Hjxp1h0i",
        type: "header",
        data: { text: "DIRECTOR:", level: 5 },
      },
      {
        id: "9PcU21UcAx",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/main/administration/director/">Prof. N.V. Ramana Rao,</a>',
        },
      },
      {
        id: "Mjuy6D5QRI",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/main/administration/director/"></a>Phone:&nbsp;+91-870-2462000',
        },
      },
      {
        id: "1sU5NuJqGY",
        type: "paragraph",
        data: { text: "FAX : +91-870-2459547" },
      },
      {
        id: "ldQV3Qxytd",
        type: "paragraph",
        data: {
          text: 'E-mail:&nbsp;<a href="mailto:director@nitw.ac.in">director@nitw.ac.in</a>',
        },
      },
      {
        id: "aYZTkSHEKn",
        type: "header",
        data: { text: "REGISTRAR:", level: 5 },
      },
      {
        id: "k_gji8PnpN",
        type: "paragraph",
        data: {
          text: '<a href="https://www.nitw.ac.in/main/home/" target="_blank">Sri S. Goverdhan Rao</a><a href="http://www.nitw.ac.in/faculty/id/16376/">,</a>',
        },
      },
      {
        id: "RFpnWrPtY6",
        type: "paragraph",
        data: {
          text: '<a href="http://www.nitw.ac.in/faculty/id/16376/"></a>Phone:&nbsp;+91-870-2462010',
        },
      },
      {
        id: "JB7V5VEgSZ",
        type: "paragraph",
        data: {
          text: 'E-mail:&nbsp;<a href="mailto:registrar@nitw.ac.in">registrar@nitw.ac.in</a>',
        },
      },
    ],
    language: "En",
    _id: "61e7d71da7d2dc1d6b6a9dfc",
    time: 1642583836669,
    version: "2.22.2",
    title: "Contact Us",
    url: "/contact-us",
    path: "/contact-us",
    createdAt: "2022-01-19T09:17:17.076Z",
    updatedAt: "2022-01-19T09:17:17.076Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "bm5KpKdHYE",
        type: "header",
        data: { text: "Covid 19", level: 3 },
      },
      {
        id: "fYqvHDpcm_",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["<b>DATE</b>", "<b>ANNOUNCEMENTS / NOTICES / NEWS&nbsp;</b>"],
            [
              "10 Aug 2020",
              '<a href=`${MainServerUrl}/static/files/ACADEMIC_CALENDAR:_2020-21_ODD_SEMESTER_2022-0-19-19-36-8.pdf`>ACADEMIC CALENDAR: 2020-21 ODD SEMESTER</a>',
            ],
            [
              "21-Jul- 2020",
              '<a href=`${MainServerUrl}/static/files/Academic_Calendar_and_Examinations_for_Terminal_Semester_Students_2022-0-19-19-41-1.pdf`>Academic Calendar and Examinations for Terminal Semester Students</a>',
            ],
            [
              "12-Jun- 2020",
              '<a href=`${MainServerUrl}/static/files/Covid_SOPNITW_Modified_05062020_Final_2022-0-19-19-37-38.pdf`>Standard Operating Procedures and Guidelines to be followed in NITW&nbsp;Campus post-Covid19 lockdown</a>',
            ],
            [
              "10-May- 2020",
              '<a href=`${MainServerUrl}/static/files/Revised_Academic_Calendar_and_Exams_Schedule_for_AY_2019-20_2022-0-22-20-53-22.pdf`>Revised Academic Calendar and Exams Schedule for AY 2019-20 along with&nbsp;Modified Rules and Regulations and&nbsp; Guidelines in&nbsp;view of the COVID-19</a>',
            ],
            [
              "29-Apr-2020",
              `${MainServerUrl}/static/files/Effective_use_of_'Aarogyasetu'_App_for_breaking_the_chain_of_transmission_of_COVID_-_19_2022-0-22-20-47-56.pdf\">Effective use of 'Aarogyasetu' App for breaking the chain of transmission&nbsp;of COVID - 19</a>`,
            ],
            [
              "30-Mar-2020&nbsp;",
              '<a href=`${MainServerUrl}/static/files/Directors_Message_2022-0-19-19-46-41.pdf`>Director\'s Message - In wake of Covid-19 outbreak</a>',
            ],
            [
              "30-Mar-2020",
              '<a href=`${MainServerUrl}/static/files/Revised_Academic_Calendar_2022-0-19-19-47-42.pdf`>Revised Academic Schedule - In wake of Covid-19 outbreak</a>',
            ],
          ],
        },
      },
    ],
    language: "En",
    _id: "61e7da0ea7d2dc1d6b6a9dfe",
    time: 1642865030513,
    version: "2.22.2",
    title: "Covid 19 News",
    url: "/covid19/nitw",
    path: "/covid19/nitw",
    createdAt: "2022-01-19T09:29:50.760Z",
    updatedAt: "2022-01-22T15:23:51.074Z",
    __v: 0,
  },
  {
    blocks: [
      { id: "rTAUzYedI1", type: "header", data: { text: "Patents", level: 3 } },
      {
        id: "pQqZ0X_fzP",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "<b>A.Suchith Reddy</b> and <b>Prof P.Rathish Kumar</b>, Indian Patent Filed on “ A method&nbsp;and system for assessing sustainability of civil infrastructure, Application No.&nbsp;202141054686, 26th November 2021.",
            "<b>Mukul Pradhan,</b> <b>S. Rudra,</b> <b>R. Chakraborty</b> File No. 202141054738 Synthesis of&nbsp;Graphitized Carbon from Biomass using Focused Sunlight in a Newly Designed&nbsp;Reaction Setup for Energy Storage Application, 2021<br>",
            "<b>E. Suresh Babu&nbsp;</b>“An Efficient Spectral Cluster Routing Method to Enhance the&nbsp;Network Performance in Wireless Sensor Networks Publication The Patent Office&nbsp;Journal No. 12/2021 Dated 19/03/2021",
            "&nbsp;&nbsp;<b>Dr. V. Rama</b> and <b>Prof. C. B. Rama Rao </b>have been granted a patent on “A Tele&nbsp;-Health Care System and Method for Early Disease Detection” bearing patent no.&nbsp;2021105599, date: 03/11/2021<br>",
            "<b>Dr. Suresh Babu Perli</b> received Innovation patent from Australia for the title “ A&nbsp;Web-Enabled Smart Home Gateway for Automatic Device and Network&nbsp;Configuration and Automatic System Updates Via IoT”, Date of Grant: 15 Dec 2021<br>",
          ],
        },
      },
    ],
    language: "En",
    _id: "61e90953a7d2dc1d6b6a9e14",
    time: 1642692962156,
    version: "2.22.2",
    title: "Patents",
    url: "/research/patent",
    path: "/patent",
    createdAt: "2022-01-20T07:03:47.324Z",
    updatedAt: "2022-01-20T15:36:04.184Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "FJAK-I01hq",
        type: "header",
        data: {
          text: "UG, PG and 5 year Intregrtated M.Sc. and PhD Hostel fee structure",
          level: 6,
        },
      },
      {
        id: "uj4dpZifYY",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2022/01/20/btech-fee-structure-2021-22-2.pdf">B.Tech II-Sem fee structure - 2021-22</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2022/01/20/mtech-fee-structre-2021-22-1.pdf">PG &amp; Ph.D II-Sem fee structure - 2021-22</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2022/01/20/msc-5-year-intregrtated.pdf">M.Sc. 5 year Intregrtated</a>&nbsp;',
            '<a href="https://www.nitw.ac.in/media/uploads/2021/12/23/ii-sem-online-fee-instructions.pdf">II-Sem&nbsp;online Fee instructions</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61e98d8aa7d2dc1d6b6a9e2c",
    time: 1642696193793,
    version: "2.22.2",
    title: "Hostel fee structure",
    url: "/Hostelfeestructure/nitw",
    path: "/Hostelfeestructure",
    createdAt: "2022-01-20T16:27:54.460Z",
    updatedAt: "2022-01-20T16:29:51.394Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9GNc-E3tlV",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Alumni_List_2006-2010_2022-0-22-11-52-19.pdf`>Click here</a>',
        },
      },
      {
        id: "0VXLsktwqx",
        type: "paragraph",
        data: {
          text: '<a href=`${MainServerUrl}/static/files/Alumni_List_2007-2011_2022-0-22-11-53-42.pdf`> Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61eba2f572dab6d822cffca0",
    time: 1642832696707,
    version: "2.22.2",
    title: "Alumni",
    url: "/bt/AlumniList",
    path: "/bt",
    createdAt: "2022-01-22T06:23:49.727Z",
    updatedAt: "2022-01-22T06:24:52.250Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "JQEwaxXAij",
        type: "header",
        data: { text: "Academic Calendar", level: 6 },
      },
      {
        id: "wGIIqbOztL",
        type: "paragraph",
        data: {
          text: 'Even Semester&nbsp; - <a href="https://nitw.ac.in/media/uploads/2021/11/11/i-btech-int-msc-ii-sem.pdf">Click here</a>',
        },
      },
      {
        id: "xDka276bkT",
        type: "paragraph",
        data: {
          text: 'Odd Semester&nbsp; - <a href="https://nitw.ac.in/media/uploads/2021/11/11/i-btech-int-msc-i-sem.pdf">Click here</a>',
        },
      },
      {
        id: "XOlzTxeDPv",
        type: "paragraph",
        data: {
          text: 'Summer Quarter - <a href="https://nitw.ac.in/media/uploads/2021/03/22/acad_calendar-summer-quarter.pdf">Click here</a>',
        },
      },
      {
        id: "_R1TnHsqzr",
        type: "header",
        data: { text: "Time-Table&nbsp;", level: 6 },
      },
      { id: "QoxEUl722H", type: "paragraph", data: { text: "Even Semester " } },
      {
        id: "Dex8woHvga",
        type: "paragraph",
        data: {
          text: 'B.Tech - <a href="https://nitw.ac.in/media/uploads/2022/01/02/biotech-btech-tt.pdf">Click here</a>',
        },
      },
      {
        id: "Hn2jPXUTt_",
        type: "paragraph",
        data: {
          text: '&nbsp;M.Tech -&nbsp;<a href="https://nitw.ac.in/media/uploads/2021/12/31/biotech-mtech-tt.pdf">Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61eba52c72dab6d822cffca3",
    time: 1642833200716,
    version: "2.22.2",
    title: "Downloads",
    url: "/bt/AcademicCalendar",
    path: "/bt",
    createdAt: "2022-01-22T06:33:16.224Z",
    updatedAt: "2022-01-22T06:33:16.224Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "hDYGUff9dY",
        type: "paragraph",
        data: {
          text: "Placement Statistics for the academic year 2013-14&nbsp;",
        },
      },
      {
        id: "xwLmMCvmlS",
        type: "paragraph",
        data: { text: "Total Number of placements –&nbsp;38" },
      },
      {
        id: "nTtPme01Eb",
        type: "paragraph",
        data: {
          text: "Placement Statistics for the academic year 2014-15&nbsp;",
        },
      },
      {
        id: "gREKxUSh0A",
        type: "paragraph",
        data: { text: "Total Number of placements – 51 (Till Date)" },
      },
      {
        id: "hSiC1LBPAb",
        type: "paragraph",
        data: {
          text: 'List of Companies visited&nbsp;&nbsp;- <a href=`${MainServerUrl}/static/files/List_of_Companies_visited_2022-0-22-12-31-28.pdf`>Click here</a>',
        },
      },
      {
        id: "AzBfqbE_gj",
        type: "header",
        data: { text: "Internships", level: 6 },
      },
      {
        id: "lP8AtTlCjn",
        type: "paragraph",
        data: { text: "<b>International Internships (2012-13):</b>" },
      },
      {
        id: "WLXZEo-EQ_",
        type: "paragraph",
        data: { text: "Waseem–Texas A&amp;M University" },
      },
      {
        id: "qsPvTX1VwL",
        type: "paragraph",
        data: { text: "Deepti–Texas A&amp;M University" },
      },
      {
        id: "BZboVf5Vtg",
        type: "paragraph",
        data: { text: "Aditi–Texas A&amp;M University" },
      },
      {
        id: "vd5HjzrQRQ",
        type: "paragraph",
        data: { text: "M. Chanukya Patnaik-Nanyang Technological University" },
      },
      {
        id: "VbuwKjw-qp",
        type: "paragraph",
        data: { text: "D. Madhura-Max Planck University" },
      },
      {
        id: "jKQjpqj5cb",
        type: "paragraph",
        data: { text: "International Internships (2013-14):" },
      },
      {
        id: "6Jp5qdOEdB",
        type: "paragraph",
        data: { text: "E. Karthik –Texas A&amp;M University" },
      },
      {
        id: "jn4bgMJzMV",
        type: "paragraph",
        data: { text: "P. Sailoosha–Texas A&amp;M University" },
      },
      {
        id: "KKQsGFQVRA",
        type: "paragraph",
        data: { text: "S. Sahitya–Texas A&amp;M University" },
      },
      {
        id: "hWEQY3j1hX",
        type: "paragraph",
        data: { text: "Tanmay-University of Tokyo" },
      },
      {
        id: "rqgPGl9Onp",
        type: "paragraph",
        data: { text: "Vatsal Sachan-University of Montreal" },
      },
      {
        id: "NHVfSbd9cj",
        type: "paragraph",
        data: { text: "M.Shivani-Khorana Fellow" },
      },
      {
        id: "8WJj0KSXDt",
        type: "paragraph",
        data: { text: "M.Balaajhi-University of Montreal" },
      },
      {
        id: "i0pz_P5mIU",
        type: "paragraph",
        data: { text: "<b>National Internships (2012-13):</b>" },
      },
      {
        id: "SYYsB8lmnO",
        type: "paragraph",
        data: { text: "A. Sai Krishna-CCMB" },
      },
      { id: "tIGYF39Lv5", type: "paragraph", data: { text: "Areej" } },
      { id: "3OCh7tpOZ4", type: "paragraph", data: { text: "B. Abhinay" } },
      {
        id: "kEpypcrNtc",
        type: "paragraph",
        data: { text: "E. Karthik –IAS Fellow" },
      },
      {
        id: "oChmhxzoef",
        type: "paragraph",
        data: { text: "V. Satish Reddy-IAS Fellow" },
      },
      {
        id: "D3wFTR1r3P",
        type: "paragraph",
        data: { text: "K. Sahith Reddy-IIT Guwhati" },
      },
      {
        id: "YZKwulDePR",
        type: "paragraph",
        data: { text: "M. Shivani-IIT Delhi" },
      },
      {
        id: "MUNunfMPhW",
        type: "paragraph",
        data: { text: "<b>National Internships (2013-14):</b>" },
      },
      {
        id: "Td20m63QvJ",
        type: "paragraph",
        data: { text: "K.A.V.Prasad-IAS Fellow" },
      },
      {
        id: "FHLOez91hM",
        type: "paragraph",
        data: { text: "M. Jhansi-IAS Fellow" },
      },
      {
        id: "H_I2AaE0z7",
        type: "paragraph",
        data: { text: "B.Rishikesh-CCMB" },
      },
      {
        id: "H99FlDvaIk",
        type: "paragraph",
        data: { text: "C.Krshna Kshore- IIT Guwahati" },
      },
      {
        id: "PrZ3IeNYdT",
        type: "paragraph",
        data: { text: "K.Venkatesh-&nbsp;IIT Guwahati" },
      },
    ],
    language: "En",
    _id: "61ebac1972dab6d822cffca9",
    time: 1642835026829,
    version: "2.22.2",
    title: "Placements",
    url: "/bt/Placements",
    path: "/bt",
    createdAt: "2022-01-22T07:02:49.738Z",
    updatedAt: "2022-01-22T07:03:42.388Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "GVAiZMmT0N",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2021/10/23/06_btech-chemical.pdf">B.Tech Scheme and Syllabi</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/10/26/m-tech_curriculum-18-10-21-1.pdf">M.Tech Scheme and Syllabi</a>',
          ],
        },
      },
      {
        id: "hFIlG6wP0B",
        type: "header",
        data: { text: "Time-Table", level: 6 },
      },
      {
        id: "elawfdQMBu",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://nitw.ac.in/media/uploads/2022/01/02/biotech-btech-tt.pdf">B.Tech</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2021/12/31/biotech-mtech-tt.pdf">M.Tech</a>',
          ],
        },
      },
    ],
    language: "En",
    _id: "61ebaeaa72dab6d822cffcac",
    time: 1642835630744,
    version: "2.22.2",
    title: "Time-Table",
    url: "/che/Time-Table",
    path: "/che",
    createdAt: "2022-01-22T07:13:46.209Z",
    updatedAt: "2022-01-22T07:13:46.209Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "jklJTQ2Fj7",
        type: "header",
        data: { text: "Events 2021-22", level: 6 },
      },
      {
        id: "6nLFvg5uwm",
        type: "paragraph",
        data: { text: "Upcoming events" },
      },
      { id: "lELG594_p8", type: "paragraph", data: { text: "None." } },
      {
        id: "PxhHwzMZpy",
        type: "paragraph",
        data: { text: "Recently completed events" },
      },
      { id: "5qWmo_dC6u", type: "paragraph", data: { text: "None" } },
      {
        id: "pktf5M_K35",
        type: "header",
        data: { text: "Newsletters 2021-22", level: 6 },
      },
      {
        id: "Vi3DYukMQr",
        type: "paragraph",
        data: {
          text: "Newsletters generated between May 2021&nbsp;and June 2022",
        },
      },
      { id: "pNR-5ELp6s", type: "paragraph", data: { text: "Dec2021" } },
      {
        id: "7itL1idiXA",
        type: "paragraph",
        data: { text: "To view click on below embedded iFrame" },
      },
      {
        id: "ogAKNwfmVh",
        type: "paragraph",
        data: {
          text: 'Online Flipbook Link:<a href="https://online.fliphtml5.com/kyxvz/jryl/" target="_blank">https://online.fliphtml5.com/kyxvz/jryl/</a>',
        },
      },
      {
        id: "joVpgJY7vy",
        type: "header",
        data: { text: "Events 2020-2021", level: 6 },
      },
      {
        id: "muqg1qgjeD",
        type: "paragraph",
        data: { text: "Upcoming events" },
      },
      { id: "iASeyPXwLq", type: "paragraph", data: { text: "None." } },
      {
        id: "eXDRHjXbi1",
        type: "paragraph",
        data: { text: "Recently completed events" },
      },
      {
        id: "9U0o6a-TTS",
        type: "paragraph",
        data: {
          text: "1. Global Conference on Management, Innovation, and Business. 27-28 July 2021.",
        },
      },
      {
        id: "SdVAwl9SLx",
        type: "paragraph",
        data: {
          text: '2. National Conference on&nbsp;New Managmenet Paradigms in a Changing World: Innovations, dynamics &amp; future prospects (NMPCWIDF) 31Jan-1Feb 2020 - Please&nbsp;<a href="https://nitw.ac.in/media/uploads/2020/02/25/brief-report-of-conference-2020.docx">click here</a>&nbsp;for details',
        },
      },
      {
        id: "2QilhAxIrS",
        type: "paragraph",
        data: {
          text: "3. CURA 14 &amp; 15 February 2020&nbsp;- For details visit Students=CURA- CURA 2020",
        },
      },
      {
        id: "OIHBvSD89K",
        type: "header",
        data: { text: "Newsletters", level: 6 },
      },
      {
        id: "GJS4eUgxMK",
        type: "paragraph",
        data: { text: "Newsletters generated between May 2020 and June 2021" },
      },
      { id: "VMCHYKJsa1", type: "paragraph", data: { text: "Jun2021" } },
      {
        id: "gt3bFNKzNi",
        type: "paragraph",
        data: { text: "To view click on below embedded iFrame" },
      },
      {
        id: "hZlq5NMKCs",
        type: "paragraph",
        data: {
          text: 'For flipversion use -&nbsp;<a href="https://online.fliphtml5.com/kyxvz/brce/">https://online.fliphtml5.com/kyxvz/brce/</a>&nbsp;',
        },
      },
      { id: "0QFGn4DYrA", type: "paragraph", data: { text: "March 2021" } },
      {
        id: "2PI8usVHiR",
        type: "paragraph",
        data: {
          text: 'For flipversion use -&nbsp;<a href="https://online.fliphtml5.com/kyxvz/nvac/#p=1">https://online.fliphtml5.com/kyxvz/nvac/#p=1</a>',
        },
      },
      { id: "Pk4gZixi7M", type: "paragraph", data: { text: "August 2020" } },
      {
        id: "e6Gbqlnbge",
        type: "paragraph",
        data: {
          text: 'For flipversion use -&nbsp;<a href="https://tinyurl.com/SomNewsletter" target="_blank">&nbsp;https://tinyurl.com/SomNewsletter</a>',
        },
      },
      { id: "AbUdWw30OO", type: "paragraph", data: { text: "May 2020" } },
      {
        id: "CoeJorK5nk",
        type: "paragraph",
        data: {
          text: 'For flip version use -&nbsp;<a href="https://online.fliphtml5.com/kyxvz/oipj/">https://online.fliphtml5.com/kyxvz/oipj/&nbsp;</a>',
        },
      },
    ],
    language: "En",
    _id: "61ee394144ed461e139c9739",
    time: 1643002176832,
    version: "2.22.2",
    title: "Newsletters",
    url: "/sm/Newsletters",
    path: "/sm",
    createdAt: "2022-01-24T05:29:37.295Z",
    updatedAt: "2022-01-24T05:29:37.295Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "1JgKRoQ_nv",
        type: "header",
        data: { text: "PLACEMENTS &amp; INTERNSHIPS", level: 5 },
      },
      {
        id: "8kR8uuppQk",
        type: "header",
        data: { text: "Placements - School of Management", level: 6 },
      },
      {
        id: "-q4-oek7XP",
        type: "paragraph",
        data: {
          text: 'The Training and Placement Cell of the institute and the department jointly strive to achieve excellent placements of the students. The joint effort resulted in year-on-year high-quality placements. Along with other attributes of School of Management, placements with a good record is one of the reasons for the School of Management to attract bright participants. The brocure provides further details. To view&nbsp;<a href="https://nitw.ac.in/media/uploads/2018/12/12/brouchure-placements-2017-19.pdf">click here</a>.',
        },
      },
      {
        id: "bcdLzP6Oux",
        type: "paragraph",
        data: {
          text: "&nbsp;The placement cells of the departments ensure the following:",
        },
      },
      {
        id: "HUUYxvXRbh",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Participants stay connected with the industries at various levels during their academic year. Internships have been an excellent source to stay connected with industries.",
            "Institute and department perform stay connected with the corporate world through frequent visits that involve training and project guidance under Corporate Action Plan. The department also facilitates faculty development programs for other institutes.&nbsp;",
            "Frequent review placement policy on the number of opportunities, career options, etc..",
            "Engage the alumni network and arrange guest lectures, seminars, training, and workshops for the participants.",
            "Solicit feedback from prospective employers and initiate necessary corrective and preventive actions.",
            "Enhance employability of the participants by organizing periodically revising the curriculum.",
          ],
        },
      },
      {
        id: "DuSTJwLfSI",
        type: "header",
        data: { text: "Message to Recruiters", level: 6 },
      },
      {
        id: "ubJObU9P8v",
        type: "paragraph",
        data: {
          text: "The present business scenario is changing fast. The industry needs managers who are competent, diligent, creative and remain with the organizations for a considerable period of time and display their capabilities. The NIT Warangal is one of the few institutions in India which offers MBA to participants with engineering Background. Based on this at SOM, the syllabus has been carefully planned to bridge the gap between academia and industry. Apart from the syllabus, we at SOM equip our participants to take risks, learn, and contribute to the fraternity. In doing so, we believe that the participants can handle diverse demands of the industry and become Leaders of Tomorrow.&nbsp;",
        },
      },
      {
        id: "rVN4Ud-CJ_",
        type: "header",
        data: { text: "Placement Brochure &amp; Report", level: 6 },
      },
      {
        id: "nm-fCLYuk2",
        type: "paragraph",
        data: {
          text: '<b>Brochure</b> - <a href=`${MainServerUrl}/static/files/Brochure_2022-0-24-11-4-30.pdf`>Click here&nbsp;</a>',
        },
      },
      {
        id: "jUmVa660W0",
        type: "header",
        data: { text: "Placement Report", level: 6 },
      },
      {
        id: "l6o5D82vQr",
        type: "paragraph",
        data: { text: "Please &lt;&lt;TBD&gt;&gt; for the year 2020-21" },
      },
      {
        id: "vvQQB13PL7",
        type: "paragraph",
        data: {
          text: "Please &lt;&lt;TBD&gt;&nbsp;for the year 2019-20&nbsp;",
        },
      },
      {
        id: "lPk6VmBVvf",
        type: "header",
        data: { text: "Placement Contact", level: 6 },
      },
      {
        id: "-MxTyBXf7P",
        type: "header",
        data: { text: "School of Management Placement Office", level: 6 },
      },
      {
        id: "i5paz9NYhb",
        type: "paragraph",
        data: {
          text: "Dr P Ramlal Associate Professor; E-mail: ramlal@nitw.ac.in; Mobile: +919985150248; +91-8332969512",
        },
      },
      {
        id: "gDoFo3VLVq",
        type: "header",
        data: { text: "Student Placement Committee", level: 6 },
      },
      {
        id: "YvKCWBEUvT",
        type: "paragraph",
        data: {
          text: "Allada Jayavanth Kumar - 2nd year MBA; +91-95668 32217",
        },
      },
      {
        id: "twO6Naw_uC",
        type: "header",
        data: { text: "Summer Internships", level: 6 },
      },
      {
        id: "U7C-fHtsa2",
        type: "paragraph",
        data: {
          text: "School of Managment has 39&nbsp;students in its class 2020-2022&nbsp;MBA programme.",
        },
      },
      {
        id: "Wo6gf8Jn9v",
        type: "paragraph",
        data: {
          text: "This year due to COVID-19, students for their summer internship can choose either a work-from-home summer internship or a mini-project. The mini-project requires selecting a project and perform it under the guidance of a faculty assigned as a&nbsp;mentor.&nbsp;",
        },
      },
      {
        id: "gANtR_IJmQ",
        type: "paragraph",
        data: {
          text: "Both summer internship and mini-project are of 8 weeks duration.",
        },
      },
      {
        id: "cEcXwTInEz",
        type: "paragraph",
        data: {
          text: "SOM, NITW would like to express its sincere gratitude to its valued summer intersnhip recruiters for showing trust and faith in the academic rigor and quality of students at SOM, NITW.",
        },
      },
    ],
    language: "En",
    _id: "61ee3b4b44ed461e139c973f",
    time: 1643002698979,
    version: "2.22.2",
    title: "Placements",
    url: "/sm/PLACEMENTS&INTERNSHIPS",
    path: "/sm",
    createdAt: "2022-01-24T05:38:19.435Z",
    updatedAt: "2022-01-24T05:38:19.435Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "FRgLWTUo0c",
        type: "header",
        data: { text: "Abhiyan Club", level: 6 },
      },
      {
        id: "qYUmEbEaRP",
        type: "paragraph",
        data: {
          text: "The non-linearity of knowledge explosions is forcing the emergent business organization to become a borderless complex adaptive system. In order to comprehend the complexity and select the intelligent pathways, one requires not only the knowledge of technology but also the holistic perception through a unity of thoughts, vision and action. Keeping these matters in mind, Abhiyan Club an exclusive MBA student association was formed in the year 2003. Abhiyan club organizes live events like Business Plans, New Product Marketing, Virtual Stock Market, Group discussions, Personal Interviews etc. The activities in Abhiyan club occur twice/thrice in a month in the presence of faculty members. Faculty members provide inputs and assess students.",
        },
      },
      { id: "F_8R5hgYFN", type: "header", data: { text: "CURA", level: 6 } },
      {
        id: "SSr17h3pwZ",
        type: "paragraph",
        data: {
          text: 'Cura signifying "Thoughtfulness" is a platform that started in 2009 to unleash the potential of the management aspirants all over India. The aim of the event is to elucidate the major business activities through different events thus to elicit the diverse responses from the rapt and admiring students of management. Undoubtedly this event is a beacon of light for all those who can balance and blend&nbsp;their skills with palatable and innovative ideas accompanied with verve.',
        },
      },
      {
        id: "S_LDfBnGsb",
        type: "paragraph",
        data: {
          text: "CURA event was last conducted on 14-15 February 2020. Please refer events tab for details.",
        },
      },
      {
        id: "IoEopqrr17",
        type: "paragraph",
        data: {
          text: '<b>CURA 2020</b>&nbsp;- <a href=`${MainServerUrl}/static/files/CURA_2020_2022-0-24-11-11-48.pdf`>Click here</a>',
        },
      },
      {
        id: "IeHsXiRNYQ",
        type: "header",
        data: { text: "Downloads", level: 6 },
      },
      {
        id: "YHvdeAdiw-",
        type: "paragraph",
        data: {
          text: '<b>MBA Project Guidelines</b>&nbsp;- <a href=`${MainServerUrl}/static/files/MBA_Project_Guidelines_2022-0-24-11-12-41.pdf`>Click here</a>',
        },
      },
      {
        id: "0NcfRHaFvT",
        type: "paragraph",
        data: {
          text: '<b>MBA Summer Mini-Project Template</b>&nbsp;- <a href=`${MainServerUrl}/static/files/MBA_Summer_Mini-Project_Template_2022-0-24-11-13-35.pdf`>Click here</a>',
        },
      },
      {
        id: "p3Hv8-Kcv5",
        type: "paragraph",
        data: {
          text: '<b>MBA Summer Project Template&nbsp;</b>- <a href=`${MainServerUrl}/static/files/MBA_Summer_Project_Template_2022-0-24-11-14-23.pdf`>Click here</a>',
        },
      },
      {
        id: "W6BP7PLI3D",
        type: "header",
        data: { text: "MBA Students", level: 5 },
      },
      {
        id: "kQ_44V0k4j",
        type: "paragraph",
        data: {
          text: '1.<a href=`${MainServerUrl}/static/files/First_Year_Students_2022-0-24-11-16-54.pdf`>Click here</a>',
        },
      },
      {
        id: "2j8jwpyLN4",
        type: "paragraph",
        data: {
          text: '2. <a href=`${MainServerUrl}/static/files/Second_Year_Students_2022-0-24-11-17-34.pdf`>Click here</a>',
        },
      },
      {
        id: "e2OpRwQi8u",
        type: "header",
        data: { text: "PhD Participant", level: 5 },
      },
      {
        id: "ZbTD8I5_u-",
        type: "paragraph",
        data: {
          text: '1. <b>PhD Students Completed&nbsp;</b>- <a href=`${MainServerUrl}/static/files/PhD_Students_Completed_2022-0-24-11-19-17.pdf`>Click here</a>',
        },
      },
      {
        id: "Sw24r97hfS",
        type: "paragraph",
        data: {
          text: '2. <b>PhD Students InProgress</b>&nbsp;-<a href=`${MainServerUrl}/static/files/PhD_Students_InProgress_2022-0-24-11-20-20.pdf`> Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61ee3ce944ed461e139c9745",
    time: 1643003438761,
    version: "2.22.2",
    title: "Students",
    url: "/sm/Students",
    path: "/sm",
    createdAt: "2022-01-24T05:45:13.088Z",
    updatedAt: "2022-01-24T05:50:39.987Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "7K31JLByI2",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Mineral Processing Laboratory",
            '<a href="http://www.nitw.ac.in/departments/mme/index.php/foundry-technology-non-destructive-testing/">Foundry Technology Laboratory</a>',
            '<a href="http://www.nitw.ac.in/departments/mme/index.php/physical-metallurgy-laboratory/">Physical Metallurgy Laboratory</a>',
            '<a href="http://www.nitw.ac.in/departments/mme/index.php/phase-transformations-heat-treatment-laboratory/">Phase Transformations &amp; Heat Treatment Laboratory</a>',
            '<a href="http://www.nitw.ac.in/departments/mme/index.php/mechanical-metallurgy-laboratory/">Mechanical Metallurgy Laboratory</a>',
            '<a href="http://www.nitw.ac.in/departments/mme/index.php/materials-testing-laboratory/">Materials Testing Laboratory</a>',
            '<a href="http://www.nitw.ac.in/departments/mme/index.php/electro-metallurgy-and-corrosion-lab/">Electro-Metallurgy and Corrosion lab</a>',
            '<a href="http://www.nitw.ac.in/departments/mme/index.php/computer-laboratory/">Computer Laboratory</a>',
            "Workshop",
          ],
        },
      },
    ],
    language: "En",
    _id: "61ee44b944ed461e139c9757",
    time: 1643005113335,
    version: "2.22.2",
    title: "Labs",
    url: "/mme/Labs",
    path: "/mme",
    createdAt: "2022-01-24T06:18:33.739Z",
    updatedAt: "2022-01-24T06:18:33.739Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "PBJWkmc_NH",
        type: "paragraph",
        data: {
          text: '<b>X-ray Diffractometer</b>&nbsp;-<a href=`${MainServerUrl}/static/files/X-ray_Diffractometer_2022-0-24-11-53-8.pdf`> Click here</a>',
        },
      },
      {
        id: "TJuUWzpG-p",
        type: "paragraph",
        data: {
          text: '<b>Benchtop Universal Testing Machine</b> - <a href=`${MainServerUrl}/static/files/Benchtop_Universal_Testing_Machine_2022-0-24-11-55-1.pdf`>Click here</a>',
        },
      },
      {
        id: "E_Tn0V5d1b",
        type: "paragraph",
        data: {
          text: `${MainServerUrl}/static/files/Micro_Vicker's_hardness_tester_2022-0-24-11-57-38.pdf\">Click here</a>`,
        },
      },
      {
        id: "vU6iEYWl2C",
        type: "paragraph",
        data: {
          text: '<b>Pin-on Disc Wear Testing Machine</b> - <a href=`${MainServerUrl}/static/files/Pin-on_Disc_Wear_Testing_Machine_-_Click_here_2022-0-24-11-58-47.pdf`>Click here</a>',
        },
      },
      {
        id: "F5ldm6hwP_",
        type: "paragraph",
        data: {
          text: '<b>Research Metallurgical Microscope</b> - <a href=`${MainServerUrl}/static/files/Research_Metallurgical_Microscope_2022-0-24-12-0-36.pdf`>Click here</a>',
        },
      },
      {
        id: "3IeAoSKT0g",
        type: "paragraph",
        data: {
          text: '<b>Planetary Ball Mills</b> - <a href=`${MainServerUrl}/static/files/Planetary_Ball_Mills_2022-0-24-12-4-10.pdf`>Click here</a>',
        },
      },
      {
        id: "yQMYzFFsOd",
        type: "paragraph",
        data: {
          text: '<b>Vacuum Induction Melting Furnace</b> - <a href=`${MainServerUrl}/static/files/Vacuum_Induction_Melting_Furnace_2022-0-24-12-6-28.pdf`>Click here</a>',
        },
      },
      {
        id: "mcbeOB0j4q",
        type: "paragraph",
        data: {
          text: '<b>Stir Casting Machine</b> - <a href=`${MainServerUrl}/static/files/Stir_Casting_Machine_2022-0-24-12-8-1.pdf`>Click here</a>',
        },
      },
      {
        id: "kxlKe_gOC1",
        type: "paragraph",
        data: {
          text: '<b>Electrochemical Workstation</b> - <a href=`${MainServerUrl}/static/files/Electrochemical_Workstation_-_Click_here_2022-0-24-12-11-27.pdf`>Click here</a>',
        },
      },
      {
        id: "9zawmuRmIe",
        type: "paragraph",
        data: {
          text: '<b>Hydraulic Press</b> - <a href=`${MainServerUrl}/static/files/Hydraulic_Press_-_Click_here_2022-0-24-12-13-44.pdf`>Click here</a>',
        },
      },
      {
        id: "qSbnI-NLzn",
        type: "paragraph",
        data: {
          text: '<b>Compression Moulding Machine</b> - <a href=`${MainServerUrl}/static/files/Compression_Moulding_Machine_2022-0-24-12-25-48.pdf`>Click here</a>',
        },
      },
      {
        id: "vNab8o06Ys",
        type: "paragraph",
        data: {
          text: '<b>Server</b> - <a href=`${MainServerUrl}/static/files/server_2022-0-24-12-27-19.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61ee4de644ed461e139c9765",
    time: 1643007462381,
    version: "2.22.2",
    title: "FACILITIES",
    url: "/mme/FACILITIES",
    path: "/mme",
    createdAt: "2022-01-24T06:57:42.853Z",
    updatedAt: "2022-01-24T06:57:42.853Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Convocation Details Of PhD For 2021", level: 3 },
      },
      {
        id: "DC2czwTg5E",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            ["Dept", "No of Awardees"],
            ["Biotechnology", "5"],
            ["Chemistry", "11"],
            ["Chemical Engg.", "9"],
            ["Civil Engg.", "11"],
            ["Computer Science Engg.", "5"],
            ["Electrical Engg.", "9"],
            ["Electronics &amp; Comm. Engg.", "12"],
            ["Mathematics", "4"],
            ["Mechanical Engg.", "21"],
            ["Metallurgy &amp; Materials Engg.", "12"],
            ["Physics", "4"],
            ["School of Management", "2"],
            ["<b>Total</b>", "<b>105</b>"],
          ],
        },
      },
    ],
    language: "En",
    _id: "61ee500f44ed461e139c976c",
    time: 1643008416006,
    version: "2.22.2",
    title: "Convocation Details oF PhD FoR 2021",
    url: "/convocationDetaiils/phd2021",
    path: "/notNeeded",
    createdAt: "2022-01-24T07:06:55.686Z",
    updatedAt: "2022-01-24T07:13:36.426Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "99bYunr61d",
        type: "header",
        data: { text: "B. Tech.&nbsp;", level: 5 },
      },
      {
        id: "7mT2TDBQBX",
        type: "paragraph",
        data: {
          text: '<b>B. Tech. Final year</b>&nbsp;- <a href=`${MainServerUrl}/static/files/B._Tech._Final_year_2022-0-24-12-30-29.pdf`>Click here</a>',
        },
      },
      {
        id: "L73ySrqH9S",
        type: "paragraph",
        data: {
          text: '<b>B. Tech. III Year</b> - <a href=`${MainServerUrl}/static/files/B._Tech._III_Year_2022-0-24-12-31-31.pdf`>Click here</a>',
        },
      },
      {
        id: "dYlDMWlkbT",
        type: "paragraph",
        data: {
          text: '<b>B. Tech. II Year</b> - <a href=`${MainServerUrl}/static/files/B._Tech._II_Year_2022-0-24-12-32-47.pdf`>Click here</a>',
        },
      },
      { id: "t3_tOy5e3L", type: "header", data: { text: "M.Tech", level: 5 } },
      {
        id: "li52l8OyZC",
        type: "paragraph",
        data: {
          text: '<b>M.Tech Industrial Metallurgy Students&nbsp;II Year </b>- <a href=`${MainServerUrl}/static/files/M.Tech_Industrial_Metallurgy_Students_2022-0-24-12-36-3.pdf`>Click here</a>',
        },
      },
      {
        id: "EXuDoWRVUm",
        type: "paragraph",
        data: {
          text: '<b>M.Tech Industrial Metallurgy Students I Year </b>- <a href=`${MainServerUrl}/static/files/IM_I_Year_2022-0-24-12-37-5.pdf`>Click here</a>',
        },
      },
      {
        id: "9d2U6P7kPi",
        type: "header",
        data: { text: "M. Tech. Materials Technology", level: 5 },
      },
      {
        id: "AU43tex9cE",
        type: "header",
        data: { text: "M. Tech.", level: 6 },
      },
      {
        id: "Sqpch7esrp",
        type: "header",
        data: { text: "(Materials Technology)", level: 6 },
      },
      {
        id: "LQXIYZfa1O",
        type: "paragraph",
        data: {
          text: "A post-graduate programme in Materials Technology was introduced in the year 2011 with an intake of 20 students. This programme offers in depth knowledge about Physical Metallurgy, Extractive Metallurgy (Design &amp; production of Iron &amp; Steel), Manufacturing Technologies (Metal Casting, Metal Forming, Powder Metallurgy and Welding), Mechanical Metallurgy (includes Material Testing – Destructive and Non-Destructive), Corrosion Engineering, and Materials Characterization Techniques etc. The students are exposed to core and advanced areas in the first two semesters. Dissertation work is being carried out in the third and fourth semesters.",
        },
      },
      {
        id: "kXqMMc0N3E",
        type: "header",
        data: { text: "Program Educational Objectives", level: 6 },
      },
      {
        id: "Rk6w7zXxJD",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Evaluate the performance of material systems using the relationship between structure, properties and processing.&nbsp;",
            "Characterize materials and carry out research on advanced materials.&nbsp;",
            "Design and develop effective and eco-friendly materials for generic and strategic applications.&nbsp;",
            "Pursue life-long learning by enhancing knowledge and skills for professional advancement.&nbsp;",
          ],
        },
      },
      {
        id: "YCfIvTQw5V",
        type: "header",
        data: { text: "Program Outcomes", level: 6 },
      },
      {
        id: "UEhkOvPn9g",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Apply phase transformation phenomena to improve the performance of materials.&nbsp;",
            "Apply principles of deformation to modify structure and properties of materials.&nbsp;",
            "Characterize and evaluate materials for specific applications.&nbsp;",
            "Design metallurgical processes to produce products as per specifications.&nbsp;",
            "Evaluate products using non-destructive testing methods and modify processes.&nbsp;",
            "Identify mechanisms for protecting engineering materials from degradation.&nbsp;",
            "Synthesize ceramic, polymer, composite and non-ferrous materials.&nbsp;",
            "Design advanced materials for aerospace, biological, nuclear and high temperature applications.&nbsp;",
            "Apply project management techniques effectively to address issues related to metallurgical industries.&nbsp;",
            "Practice professional ethics and engage in lifelong learning for improved professional advancement, moral and human values.&nbsp;",
          ],
        },
      },
      {
        id: "xIHiwG84Dn",
        type: "header",
        data: { text: "Scheme &amp; Syllabus", level: 6 },
      },
      {
        id: "m500m63RO3",
        type: "paragraph",
        data: {
          text: "Admission Requirements: B. E. or B. Tech. in Mechanical or Metallurgical or Materials Engineering, with a First Class, and with a valid GATE score.",
        },
      },
      {
        id: "2CyfvSLp2I",
        type: "paragraph",
        data: { text: "Course Duration: 2 Years (4 Semesters)&nbsp;" },
      },
      {
        id: "6paO9_3ODa",
        type: "list",
        data: {
          style: "unordered",
          items: [
            '<a href="https://www.nitw.ac.in/media/uploads/2021/08/29/2_materials-technology.pdf">Materials Technology (Effective from 2021-22)</a>&nbsp;',
            '<a href="https://nitw.ac.in/media/uploads/2019/09/05/revised-syllabus-mt-2019-22072019.pdf">Materials Technology</a><a href="https://nitw.ac.in/media/uploads/2019/09/05/revised-syllabus-mt-2019-22072019.pdf">(Effective from 2019-20)</a>',
          ],
        },
      },
      {
        id: "z2YlagDlwG",
        type: "header",
        data: { text: "Timetable", level: 6 },
      },
      {
        id: "zNynkS6mej",
        type: "paragraph",
        data: {
          text: '<a href="https://nitw.ac.in/media/uploads/2021/08/28/tt-mme-aug-2021-mtech-v03.pdf" target="_blank">Timetable for Current Semester MT and IM (2021-22 Sem I)</a>',
        },
      },
      {
        id: "OpoelabJH4",
        type: "header",
        data: { text: "Ph. D. Scholars", level: 5 },
      },
      {
        id: "cZi7BTAhe_",
        type: "paragraph",
        data: {
          text: '<b>Ph. D. Scholars&nbsp;</b>- <a href="https://nitw.ac.in/media/uploads/2022/01/24/mme-phd.pdf">Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61ee505244ed461e139c976f",
    time: 1643008911855,
    version: "2.22.2",
    title: "Students",
    url: "/mme/students",
    path: "/mme",
    createdAt: "2022-01-24T07:08:02.139Z",
    updatedAt: "2022-01-24T07:21:52.482Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "cBiEfPEfN-",
        type: "header",
        data: { text: "Training", level: 5 },
      },
      {
        id: "KHj58FjVTV",
        type: "paragraph",
        data: {
          text: '<b>Completed Training Programmes&nbsp;</b>- <a href=`${MainServerUrl}/static/files/IM_I_Year_2022-0-24-12-37-5.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61ee552644ed461e139c9775",
    time: 1643009317747,
    version: "2.22.2",
    title: "Training",
    url: "/me/Training",
    path: "/me",
    createdAt: "2022-01-24T07:28:38.273Z",
    updatedAt: "2022-01-24T07:28:38.273Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "KN95KJ4GYu",
        type: "paragraph",
        data: {
          text: '<b>Ph.D Scholars</b>&nbsp;- <a href=`${MainServerUrl}/static/files/Ph.D_Scholars_2022-0-24-17-5-8.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61ee8f4244ed461e139c9788",
    time: 1643024194129,
    version: "2.22.2",
    title: "People",
    url: "/hss/phdscholars",
    path: "/hss",
    createdAt: "2022-01-24T11:36:34.881Z",
    updatedAt: "2022-01-24T11:36:34.881Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "VRwkdaxh6a",
        type: "header",
        data: { text: "B.Tech&nbsp;", level: 5 },
      },
      {
        id: "7SWUF3uhLc",
        type: "header",
        data: {
          text: 'B.Tech Programs&nbsp;- <a href=`${MainServerUrl}/static/files/B.Tech_Programs_2022-0-24-17-10-31.pdf`>Click here</a>',
          level: 6,
        },
      },
      {
        id: "gjxfw_omBn",
        type: "paragraph",
        data: {
          text: '<b>Minor Programs</b> - <a href=`${MainServerUrl}/static/files/Minor_Programs_-_Click_here_2022-0-24-17-13-22.pdf`>Click here</a>',
        },
      },
      {
        id: "5dlOmVJyv2",
        type: "paragraph",
        data: {
          text: '<b>Open Electives </b>- <a href=`${MainServerUrl}/static/files/Open_Electives_humanities_2022-0-24-17-15-3.pdf`>Click here</a><br><br>',
        },
      },
      {
        id: "wd0qOuh5Fp",
        type: "paragraph",
        data: {
          text: '<b>Integrated M.Sc.</b> -<a href=`${MainServerUrl}/static/files/Integrated_M.Sc.humanities_2022-0-24-17-16-14.pdf`> Click here</a><br><br>',
        },
      },
      {
        id: "h3H2xcXCm7",
        type: "paragraph",
        data: {
          text: '<b>MBA</b> - <a href=`${MainServerUrl}/static/files/MBA_-_Click_here_2022-0-24-17-18-10.pdf`>Click here</a><br><br>',
        },
      },
      {
        id: "isIi9-V1RA",
        type: "paragraph",
        data: {
          text: '<b>Ph.D</b> - <a href=`${MainServerUrl}/static/files/ph.D_-_Click_here_2022-0-24-17-19-42.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61ee928044ed461e139c9792",
    time: 1643025024128,
    version: "2.22.2",
    title: "Courses",
    url: "/hss/Courses",
    path: "/hss",
    createdAt: "2022-01-24T11:50:24.931Z",
    updatedAt: "2022-01-24T11:50:24.931Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "316v7rBZ4-",
        type: "paragraph",
        data: {
          text: '<b>MSc First Year</b> - <a href=`${MainServerUrl}/static/files/MSc_First_Year_organic_chem_2022-0-24-21-31-15.pdf`>Click here</a>',
        },
      },
      {
        id: "HDN5BLThnE",
        type: "paragraph",
        data: {
          text: '<b>MSc Second Year</b> - <a href=`${MainServerUrl}/static/files/MSc_Second_Year_-_Click_here_2022-0-24-21-32-26.pdf`>Click here&nbsp;</a>',
        },
      },
    ],
    language: "En",
    _id: "61eecda46634bf30358ffd09",
    time: 1643040164286,
    version: "2.22.2",
    title: "Students",
    url: "/cy/students",
    path: "/cy",
    createdAt: "2022-01-24T16:02:44.692Z",
    updatedAt: "2022-01-24T16:02:44.692Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "7xKBGa2gDV",
        type: "paragraph",
        data: {
          text: 'List of Ph.D. Awardees&nbsp;- <a href=`${MainServerUrl}/static/files/List_of_Ph.D._Awardees_2022-0-24-21-35-50.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61eece746634bf30358ffd0c",
    time: 1643040371716,
    version: "2.22.2",
    title: "PH.D. AWARDEES",
    url: "/cs/PH.D.AWARDEES",
    path: "/cs",
    createdAt: "2022-01-24T16:06:12.170Z",
    updatedAt: "2022-01-24T16:06:12.170Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "whsAeBPEQX",
        type: "header",
        data: { text: "Areas of Research", level: 5 },
      },
      {
        id: "HpBgHgb5On",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Bio-Informatics&nbsp;",
            "Computer Networks&nbsp;",
            "Cryptanalysis&nbsp;",
            "Database Technologies Data Mining /&nbsp;IDS&nbsp;",
            "Distributed Systems&nbsp;",
            "Information Security&nbsp;",
            "Machine Learning&nbsp;",
            "Model driven framework for automated software generation in Distributed Systems environment&nbsp;",
            "Neural Networks and Soft Computing&nbsp;",
            "Parallel Processing&nbsp;",
            "Rough and Fuzzy systems&nbsp;",
            "Software Engineering&nbsp;",
            "Secure Multi-party Computations&nbsp;",
            "Mobile Computing, Wireless Ad-hoc and Sensors Net-works, Cyber Physical Systems and Security&nbsp;",
            "Computational Neuroscience&nbsp;",
            "Computer Vision, Image Processing and Graphics and Multimedia&nbsp;",
            "Algorithms and Graph Theory",
          ],
        },
      },
      { id: "Z5YJC5plU7", type: "header", data: { text: "ISEAP", level: 5 } },
      {
        id: "-ilJRh0a0P",
        type: "paragraph",
        data: {
          text: "National Institute of Technology, Warangal is identified as Resource Centre under Information Security Education Awareness (ISEA) Project Phase II sponsored by Ministry of Electronics and Communication Technology (MeitY), Govt. of India. Objective of this resource centre is to mentor participating Institutes in the form of a cluster by providing technical support, academic handholding, conducting faculty training programs and guiding the researchers and faculties from the institutions identified as Participating Institutes under ISEA Project Phase-II. Besides this, the faculty of this Resource Centre are involved in carrying out R&amp;D and academic activities with suitable guidance from Information Security Research Development Centre (ISRDCs).",
        },
      },
      {
        id: "e3iGG-VYt0",
        type: "paragraph",
        data: {
          text: "Department of Computer Science &amp; Engineering is offering M Tech in (Computer Science &amp; Information Security) since 2008. Faculty of CSE department are also guiding doctoral students in the Information Security and its allied areas.",
        },
      },
      {
        id: "fBLMv_0Aoi",
        type: "header",
        data: { text: "Mini Center of Excellence for Big Data", level: 5 },
      },
      {
        id: "G4XVLkDrvs",
        type: "paragraph",
        data: {
          text: "Coordinators&nbsp;: Prof D V L N Somayajulu and Dr R B V Subramanyam",
        },
      },
      {
        id: "NlouyT3MQN",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Fund sanctioned by NIT-Warangal&nbsp;: so far Rs.80.0 lakhs out of proposed Rs.1.0 crore.",
          ],
        },
      },
      {
        id: "4upE3qJHEw",
        type: "paragraph",
        data: {
          text: "The following are the specifications of a high-end-Server available in the center.",
        },
      },
      {
        id: "fnRn05nMsn",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "HP make and ProLiant DL 980 Gen 7 model:",
            "8 x Intel Xeon CPU E7 -2830 (2.14 GHz/ 8 – core/ 24 MB / 105w) Processor",
            "Intel 7500 chip set with node controller",
            "512 GB RAM",
            "HP SA 410i RAID controller with 1GB",
            "6*600GB 10K HDD",
            "4 x dual port Giga Bit Ethernet NIC",
            "2 x 10GBPS Ethernet Card",
            "OS: CentOS 6.5 with Hadoop 2.2",
          ],
        },
      },
      { id: "vT7xsatsFE", type: "paragraph", data: { text: "Activities:" } },
      {
        id: "O3T7LpUiDH",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Education :&nbsp;Currently, Coordinators are offering courses (i) Data Science, (ii) Big Data Analytics to I year - I sem M.Tech. (CSE) class",
            'Training &nbsp;&nbsp; :&nbsp;1.&nbsp;One day workshop on "Big Data Analytics" conducted with C-DAC, Bangalore at Bangalore on 15-Feb-2014.',
          ],
        },
      },
      {
        id: "GG0e4sG623",
        type: "paragraph",
        data: {
          text: "&nbsp;2. ABCD-2014 - TEQIP-II sponsored A Five day workshop on Architecture, Algorithms, Analytics for Big Data using Cloud&nbsp;and Distributed Computing - a joint programme with C-DAC, Pune during 7-11 March, 2014.",
        },
      },
      {
        id: "q-gaFg2aE8",
        type: "paragraph",
        data: {
          text: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 3.&nbsp;4th&nbsp;International Conference on Big Data Analytics was held during 15-18 December, 2015.",
        },
      },
      {
        id: "BEGhNMGdxF",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Research :&nbsp;&nbsp;Currently, 4 M.Tech. students and 2 Ph.D. scholars are working on areas of Big Data Analytics.",
          ],
        },
      },
      {
        id: "4uqFBncIA6",
        type: "header",
        data: { text: "Publications", level: 5 },
      },
      {
        id: "OhYNSJ2opn",
        type: "paragraph",
        data: {
          text: '<b>List of Publications</b>&nbsp;- <a href=`${MainServerUrl}/static/files/List_of_Publications_2022-0-24-21-44-30.pdf`>Click here</a>',
        },
      },
      {
        id: "QWeDVrkxHG",
        type: "paragraph",
        data: {
          text: '<b>R&amp;D and Consultancy Projects&nbsp;</b>- <a href=`${MainServerUrl}/static/files/List_of_R&amp;D_Projects_2022-0-24-21-48-37.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61eed17c6634bf30358ffd12",
    time: 1643041148500,
    version: "2.22.2",
    title: "Research",
    url: "/cs/Research",
    path: "/cs",
    createdAt: "2022-01-24T16:19:08.934Z",
    updatedAt: "2022-01-24T16:19:08.934Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "WOPq0oKRys",
        type: "paragraph",
        data: {
          text: '<b>EEA Events </b>- <a href=`${MainServerUrl}/static/files/EEA_Events_2022-0-24-22-2-14.pdf`>Click here</a>',
        },
      },
      {
        id: "3zGD5rmYvh",
        type: "paragraph",
        data: {
          text: '<b>Executive Body of the Electrical Engineering Association (EEA) for AY 2020-21</b> - <a href=`${MainServerUrl}/static/files/Executive_Body_of_the_Electrical_Engineering_Association_(EEA)_for_AY_2020-21_2022-0-24-22-4-43.pdf`>Click here</a>',
        },
      },
      {
        id: "WnN6N6XJvv",
        type: "paragraph",
        data: {
          text: "<b>Electrical Engineering Association 2019-2020:<br>Faculty Advisor: Dr. A.V. Giridhar,&nbsp;&nbsp;<br>&nbsp;General Secretary: Ketul Mehta<br></b>",
        },
      },
      {
        id: "I1Up3ctkbu",
        type: "paragraph",
        data: { text: "<b>Events Organized for the AY 2019-20:</b>" },
      },
      {
        id: "VloT0QQalO",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "MATLAB Basics Workshop on 8.09.2019",
            "Inaugural of Electrical Engineering Association on 9.09.2019",
            "Lecture on “Passive Tracker System for SPV arrays” on 9.09.2019",
            "HAYWIRE – Online quiz between 3rd&nbsp;and 13th&nbsp;October 2019",
            "Perspicacity – Research Internship series started on 12.10.2019",
            "Lecture on “Campus Energy Monitoring System” on 16.10.2019",
            "Lecture on “PMU Applications in Smart Grid” on 17.10.2019",
            "Relay – Freshers Event on 18.10.2019",
            "Machine Learning Workshop on 20.10.2019",
            "Internship Talk on 20.10.2019",
            "See Nears – Alumni Guidance series started on 22.10.2019",
            "FLASH – Monthly Newsletter started on 23.10.2019",
            "Team TEJAS orientation on 24.10.2019",
            "Donation Drive on the occasion of Diwali on 27.10.2019",
            "Snap It Caption It – fun event on 31.10.2019",
            "Electromania during Technozion 2019 between 1st&nbsp;and 3rd&nbsp;November 2019",
            "Sneek Peek EED started on 10.11.2019",
            "Know your branch on 13.11.2019",
            "Energy conservation day awareness campaign on 14.12.2019",
            "Mock Online Test for Core (Intern &amp; Placement) on 23.12.2019",
            "Mock Online Test for Hardware (Intern &amp; Placement) on 26.12.2019",
            "Lecture on “Graduation &amp; Opportunities in USA” on 28.12.2019",
            "Integrated Circuits Workshop on 3.01.2020",
            "SIMULINK &amp; MULTISIM workshop on 4.01.2020",
            "Web Development Workshop on 5.01.2020",
            "Decipher Hunt (Part of Youth Fest) on 9.01.2020",
            "Electropreneur (Part of Youth Fest) on 10.01.2020",
            "Circuit Debugging event (Online) on 26.01.2020",
            "VERILOG Workshop on 2.02.2020",
            "Mock Gate Exam (Online) on 7.02.2020",
            "Resume Building Workshop on 7.02.2020",
            "Paraphrase (Weekly Online) started on 9.02.2020",
            "Release of DATA BANK for study resources on 13.02.2020",
            "Digital Circuit Designing Challenge (Online) on 14.02.2020",
            "Article Writing on technical topics on 20.02.2020",
            "Tech Quiz in association with Quiz club on 24.02.2020",
            "Placement Talk on 25.02.2020",
            "“Free Time with Faculty” Session 1 on 27.02.2020",
            "Mock Online Test for Software (Intern &amp; Placement) on 27.02.2020",
            "Mock Internship Interviews on 29.02.2020",
            "Mock Placement Interviews on 1.03.2020",
            "Accelerate on 1.03.2020",
            "Mock Group Discussion session on 5.03.2020",
            "Lecture on “Power Systems of the Future” on 26.02.2020",
            "EEA Sports week conducted between 1st&nbsp;and 5th&nbsp;of March 2020",
            "Mythology Quiz (Online) on 6.03.2020",
            "Essay Writing competition on 8.03.2020",
            "Senior Connect – Online guidance by final years on 14.04.2020",
            "Webinar on “Are You Industry Ready?” on 27.04.2020",
            "Webinar on “Functioning of Load Dispatch centres” on 3.05.2020",
          ],
        },
      },
      {
        id: "df-og76EaC",
        type: "header",
        data: { text: "Events Organized for the AY 2017-18:", level: 6 },
      },
      {
        id: "vRJwfKEbYG",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Inaugural of electrical engineering association on 16.10.17.",
            "Mock Gate exam on 25.01.18",
            "VLSI &amp; VHDL workshop on 27.01.18",
            "Know your branch on 02.02.18",
            "Interactive session with T&amp;P on 20.02.18",
            "Sports week during 10th&nbsp;-13th&nbsp;March 2018.",
            "TEKTRONIX workshop on 02.04.18",
            "Poster presentation on Power electronics, Power systems, smart grid and energy storage (PPSE-2018) on 04.04.18",
            "Valedictory function on 15.04.18",
          ],
        },
      },
      {
        id: "cOZ6dGZcDE",
        type: "header",
        data: { text: "Events Organized for the AY 2016-17:", level: 6 },
      },
      {
        id: "zXMSE1lk3N",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Inaugural of electrical engineering association on 20.09.16.",
            "Electrical Engineering freshers on 16.09.16.",
            "Awarness program on “softskills” on 19.11.2016",
            "MATLAB workshop for B.Tech. (III/IV) during 4th&nbsp;-5th&nbsp;Feb 2017.",
            "EEE sports week during 10th&nbsp;-13th&nbsp;March 2017.",
            "PSIM workshop on 12.03.2017",
            "Valedictory function on 07.04.2017",
          ],
        },
      },
      {
        id: "cMGCYs6vqz",
        type: "header",
        data: { text: "Events Organized for the AY 2015-16:", level: 6 },
      },
      {
        id: "SjdpxbUBzy",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "Inaugural of electrical engineering association on 23.09.15.",
            "Electrical Engineering freshers on 14.10.15.",
            "EEE sports week during&nbsp; 29th&nbsp;-30th&nbsp;March 2016.",
            "Mini project challenge and innovation ideas contest on 15.04.16.",
            "Mock Gate exam on 02.04.16",
            "Lecture on “Design, implementation and control of low voltage DC micro grid” on 22.04.16.",
            "Valedictory function on 30.04.16.",
          ],
        },
      },
      {
        id: "BRv6epO-Ey",
        type: "paragraph",
        data: {
          text: '<b>&nbsp;Events Organized So Far&nbsp;</b>-<a href=`${MainServerUrl}/static/files/Events_Organized_So_Far:_2022-0-24-22-8-38.pdf`> Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61eed6356634bf30358ffd17",
    time: 1643042357065,
    version: "2.22.2",
    title: "Students",
    url: "/eee/Students",
    path: "/eee",
    createdAt: "2022-01-24T16:39:17.501Z",
    updatedAt: "2022-01-24T16:39:17.501Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "y3covc30dE",
        type: "paragraph",
        data: {
          text: '<b>Placement&nbsp;</b>- <a href=`${MainServerUrl}/static/files/Events_Organized_So_Far:_2022-0-24-22-8-38.pdf`>Click here</a>',
        },
      },
    ],
    language: "En",
    _id: "61eed6906634bf30358ffd1a",
    time: 1643042448454,
    version: "2.22.2",
    title: "Placement",
    url: "/eee/Placement",
    path: "/eee",
    createdAt: "2022-01-24T16:40:48.870Z",
    updatedAt: "2022-01-24T16:40:48.870Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "9Gqgg4Axi4",
        type: "paragraph",
        data: { text: "<b>Research Scholars </b>- Click here" },
      },
      {
        id: "CS76WNgaGH",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/sudha.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "-DJl4K2-Up",
        type: "paragraph",
        data: { text: "Name:&nbsp;Irrinki GnanaSudha" },
      },
      {
        id: "-bnistDjXs",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "SfmMh3bypJ",
        type: "paragraph",
        data: { text: "Email:gnanasudha.i@gmail.com" },
      },
      {
        id: "NAKgs8Cvxx",
        type: "paragraph",
        data: { text: "Mobile: 9490034457" },
      },
      {
        id: "FgHzWLvQov",
        type: "paragraph",
        data: { text: "Research Area: Algebraic Coding Theory" },
      },
      {
        id: "eEpcDULRz_",
        type: "paragraph",
        data: { text: "Supervisor: Dr. R. S. Selvaraj" },
      },
      {
        id: "6zG2RXiw9a",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/pujita.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "wyE3do8zjQ",
        type: "paragraph",
        data: { text: "Name:&nbsp;Vanacharla Pujitha" },
      },
      {
        id: "oByc_tK1eq",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "SK2VWlZWMZ",
        type: "paragraph",
        data: { text: "Email:&nbsp;pujitha917@gmail.com" },
      },
      {
        id: "a0HOJJNUGu",
        type: "paragraph",
        data: { text: "Mobile:9491953898" },
      },
      {
        id: "jB90bv__8E",
        type: "paragraph",
        data: { text: "Research Area: Bio-Fluid Dynamics" },
      },
      {
        id: "G1Oz4TJBjz",
        type: "paragraph",
        data: { text: "Supervisor: Dr. P. Muthu" },
      },
      {
        id: "3fK6eCF_-e",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/naresh.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "e4CmHn1RNM",
        type: "paragraph",
        data: { text: "Name:&nbsp;Koragoni Naresh" },
      },
      {
        id: "vmrfJiZOZH",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "hWWysy7jOQ",
        type: "paragraph",
        data: { text: "Email:&nbsp;nareshsri18@gmail.com" },
      },
      {
        id: "BPRgubeow9",
        type: "paragraph",
        data: { text: "Mobile: 8099551051" },
      },
      {
        id: "6-BnTQ2JFI",
        type: "paragraph",
        data: { text: "Research Area: Boundary Layer Theory" },
      },
      {
        id: "0qlfNZ-19T",
        type: "paragraph",
        data: { text: "Supervisor: Dr.H.P.Rani" },
      },
      {
        id: "THdastVjpd",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/shiva.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "pkMEOoE-cS",
        type: "paragraph",
        data: { text: "Name:&nbsp;Gundlapally Shiva Kumar Reddy" },
      },
      {
        id: "0NZsIMWKZW",
        type: "paragraph",
        data: { text: "Type: Full Time (SRF in DST - SERB Project)" },
      },
      {
        id: "UvDJvTtzdY",
        type: "paragraph",
        data: { text: "Email:&nbsp;gshivakumarreddy913@gmail.com" },
      },
      {
        id: "m0ceSqAgKs",
        type: "paragraph",
        data: { text: "Mobile: 8500330861" },
      },
      {
        id: "0-NOdW_pyE",
        type: "paragraph",
        data: { text: "Research Area: Fluid Dynamics" },
      },
      {
        id: "MRp5-yr5vh",
        type: "paragraph",
        data: { text: "Supervisor: Dr. A. BenerjiBabu" },
      },
      {
        id: "pha7Ii5CWl",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/om-prakash.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "RuumPrdkdE",
        type: "paragraph",
        data: { text: "Name:&nbsp;Om Prakash Meena" },
      },
      {
        id: "yAavbiFw10",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "E52hnkEvg3",
        type: "paragraph",
        data: { text: "Email:&nbsp;omi.omprakash95@gmail.com" },
      },
      {
        id: "tswO9NRjVn",
        type: "paragraph",
        data: { text: "Mobile: 8947080490" },
      },
      {
        id: "PAHIPTfNFk",
        type: "paragraph",
        data: { text: "Research Area: Fluid Dynamics" },
      },
      {
        id: "vroO7AtLIO",
        type: "paragraph",
        data: { text: "Supervisor: Dr. J. Pranitha" },
      },
      {
        id: "s5Sxo2SvnS",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/dipak.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "ssAq7EaIv0",
        type: "paragraph",
        data: { text: "Name:&nbsp;Dipak Barman" },
      },
      {
        id: "eGJxeI6xs9",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "KLBTE0x2NN",
        type: "paragraph",
        data: {
          text: "Email:&nbsp;dipakbarman83@gmail.com/dipakbarman@student.nitw.ac.in",
        },
      },
      {
        id: "KcaFaGt1ka",
        type: "paragraph",
        data: { text: "Mobile: 8389045421" },
      },
      {
        id: "af6qCozCaL",
        type: "paragraph",
        data: { text: "Research Area: Computational Fluid Dynamics" },
      },
      {
        id: "rScGnui7rG",
        type: "paragraph",
        data: { text: "Supervisor: Prof. D. Srinivasacharya" },
      },
      {
        id: "PtQ-1hfz_e",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/ramana_E9PAOBS.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "mstVBK5Fez",
        type: "paragraph",
        data: { text: "Name:&nbsp;Kalakuntla Sita Ramana" },
      },
      { id: "w0Hqr5qqGL", type: "paragraph", data: { text: "Type: QIP" } },
      {
        id: "B0sbwP_-7W",
        type: "paragraph",
        data: { text: "Email:&nbsp;ksramana@gmail.com" },
      },
      {
        id: "Sv_WjKyG_J",
        type: "paragraph",
        data: { text: "Mobile:9848186946" },
      },
      {
        id: "WlcQgDuDDt",
        type: "paragraph",
        data: { text: "Research Area: Fluid Dynamics" },
      },
      {
        id: "8SGThQnV17",
        type: "paragraph",
        data: { text: "Supervisor: Prof. D. Srinivasacharya" },
      },
      {
        id: "RISuHe_HOY",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/abhinava.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "1ZyKT4U24n",
        type: "paragraph",
        data: { text: "Name:&nbsp;Abhinava Srivastav" },
      },
      {
        id: "eBUTsERrJJ",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "w2dd1iy3Br",
        type: "paragraph",
        data: { text: "Email:&nbsp;abhinava280195@gmail.com" },
      },
      {
        id: "16dfQQkBSr",
        type: "paragraph",
        data: { text: "Mobile:8858006702" },
      },
      {
        id: "VyWmV8t1Zb",
        type: "paragraph",
        data: { text: "Research Area: Computational Fluid Dynamics" },
      },
      {
        id: "n654-Njr1S",
        type: "paragraph",
        data: { text: "Supervisor: Dr. Ch. Ramreddy" },
      },
      {
        id: "NNUqOb4Uj2",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/atul.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "wJIoGHFMiw",
        type: "paragraph",
        data: { text: "Name:&nbsp;Atul Kumar Shriwastva" },
      },
      {
        id: "Os6FvgHiT8",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "PZ17nql_M-",
        type: "paragraph",
        data: { text: "Email:&nbsp;shriwastvaatul@gmail.com" },
      },
      {
        id: "_y3EzOzbPA",
        type: "paragraph",
        data: { text: "Mobile:7985508236" },
      },
      {
        id: "mxurnYg93n",
        type: "paragraph",
        data: { text: "Research Area: Algebraic Coding Theory" },
      },
      {
        id: "cxxjzlS5T_",
        type: "paragraph",
        data: { text: "Supervisor: Dr. R. S. Selvaraj" },
      },
      {
        id: "jbfEmeIQwy",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/raghavendra.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "1rWgd105HT",
        type: "paragraph",
        data: { text: "Name:&nbsp;Raghvendra Pratap Singh" },
      },
      {
        id: "UVDLIcK6au",
        type: "paragraph",
        data: { text: "Type: Full Time (Inspire)" },
      },
      {
        id: "8Tn7Q4IQ_x",
        type: "paragraph",
        data: { text: "Email:&nbsp;nitraghu7@gmail.com" },
      },
      {
        id: "Z1et2OXkSR",
        type: "paragraph",
        data: { text: "Mobile:9493992171" },
      },
      {
        id: "jzkCKZzzLu",
        type: "paragraph",
        data: { text: "Research Area: Numerical Analysis" },
      },
      {
        id: "BkCm2F3JlR",
        type: "paragraph",
        data: { text: "Supervisor: Prof.Y.N.Reddy" },
      },
      {
        id: "nCxPuM6kBU",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2018/09/14/archana.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "i-Dm5vC89J",
        type: "paragraph",
        data: { text: "Name:&nbsp;Archana Bhat" },
      },
      {
        id: "k9E9VaLZIZ",
        type: "paragraph",
        data: { text: "Type: Full Time (Inspire)" },
      },
      {
        id: "Exd4kNGP8g",
        type: "paragraph",
        data: { text: "Email:&nbsp;archanadabguli@gmail. com" },
      },
      { id: "bStsHXkAda", type: "paragraph", data: { text: "Mobile:-----" } },
      {
        id: "I1uHs9w52p",
        type: "paragraph",
        data: {
          text: "Research Area: Functional Analysis &amp;Linear Algebra",
        },
      },
      {
        id: "LtJYdZ8S93",
        type: "paragraph",
        data: { text: "Supervisor: Dr.T.Kurmayya" },
      },
      {
        id: "EftbZfsKK2",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/smriti.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "WMzW6t1hjj",
        type: "paragraph",
        data: { text: "Name:&nbsp;Smriti Tiwari" },
      },
      {
        id: "widBB4N21V",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "7eyejEOU7i",
        type: "paragraph",
        data: { text: "Email: tiwarismriti19@gmail.com" },
      },
      { id: "vOXewqbPIK", type: "paragraph", data: { text: "Mobile: -----" } },
      {
        id: "iNOaIvl1sq",
        type: "paragraph",
        data: { text: "Research Area: Partial Differential Equations" },
      },
      {
        id: "Dyla3yO1Pg",
        type: "paragraph",
        data: { text: "Supervisor: Dr. E. Satyanarayana" },
      },
      {
        id: "ZZmNmtaVf8",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/sravhan_RxcDANy.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "N1XHtZqUXr",
        type: "paragraph",
        data: { text: "Name:&nbsp;Shravan Kumar R" },
      },
      {
        id: "AU_ZdUIsFx",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "T9qki4o8Qn",
        type: "paragraph",
        data: { text: "Email:&nbsp;shravan8720@gmail.com" },
      },
      {
        id: "hAp2AvDbtb",
        type: "paragraph",
        data: { text: "Mobile: 9866948364" },
      },
      {
        id: "VS53dEycN3",
        type: "paragraph",
        data: { text: "Research Area: Fluid Dynamics" },
      },
      {
        id: "KqCW9zwVGW",
        type: "paragraph",
        data: { text: "Supervisor: Prof. D. Srinivasacharya" },
      },
      {
        id: "xqZS7j2avr",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/prithivi.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "rEje49-wiv",
        type: "paragraph",
        data: { text: "Name:&nbsp;Garaga Venkata Prithvi Raju" },
      },
      {
        id: "pRWDGfpfSb",
        type: "paragraph",
        data: { text: "Type: Full Time (JRF -NET)" },
      },
      {
        id: "uTJETDjyFv",
        type: "paragraph",
        data: { text: "Email:&nbsp;prithvideviloo7@gmail.com" },
      },
      {
        id: "iFRctdrBwS",
        type: "paragraph",
        data: { text: "Mobile: 9381265178" },
      },
      {
        id: "U6cZtTB0Fg",
        type: "paragraph",
        data: { text: "Research Area: Linear Algebra and Functional Analysis" },
      },
      {
        id: "oVuN0ss-Az",
        type: "paragraph",
        data: { text: "Supervisor: Dr. T. Kurmayya" },
      },
      {
        id: "FoCof34mrM",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/nitish.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "cL0a7_Yso2",
        type: "paragraph",
        data: { text: "Name:&nbsp;Nitish Gupta" },
      },
      {
        id: "K4CsBb2_bL",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "P99a89Runz",
        type: "paragraph",
        data: {
          text: "Email:&nbsp;guptanitish08@gmail.com/gnitish@student.nitw.ac.in",
        },
      },
      {
        id: "jWAXVdSn1-",
        type: "paragraph",
        data: { text: "Mobile: 6392285639" },
      },
      {
        id: "vpGZT1KFFJ",
        type: "paragraph",
        data: { text: "Research Area: Fluid Dynamics" },
      },
      {
        id: "Dsd_V02f_K",
        type: "paragraph",
        data: { text: "Supervisor: Dr. D. Bhargavi" },
      },
      {
        id: "tgY6KrSMqA",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/naresh.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "Bz-Sv0kC5Y",
        type: "paragraph",
        data: { text: "Name:&nbsp;Reddimalla Naresh" },
      },
      {
        id: "6Hf9RR6iQu",
        type: "paragraph",
        data: { text: "Type: Full Time (Inspire)" },
      },
      {
        id: "xWQpIGFmGI",
        type: "paragraph",
        data: { text: "Email:&nbsp;reddymallanaresh8@gmail.com" },
      },
      {
        id: "NgrkQw90hu",
        type: "paragraph",
        data: { text: "Mobile: 8096697498" },
      },
      {
        id: "3fSWiZ6ksq",
        type: "paragraph",
        data: { text: "Research Area: Fluid Mechanics" },
      },
      {
        id: "8PXqbGl4sK",
        type: "paragraph",
        data: { text: "Supervisor: Prof. J. V. Ramana Murthy" },
      },
      {
        id: "FzB4Lq3FsY",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/nidhi.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "t5RhwBkma7",
        type: "paragraph",
        data: { text: "Name:&nbsp;Nidhi Humnekar" },
      },
      {
        id: "lVU_c3Ibw3",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "H1VYjembgw",
        type: "paragraph",
        data: { text: "Email:&nbsp;nidhihumnekar17@gmail.com" },
      },
      { id: "xodUNuW3za", type: "paragraph", data: { text: "Mobile: ----" } },
      {
        id: "NExjBUr7JO",
        type: "paragraph",
        data: { text: "Research Area: Computational Fluid Dynamics" },
      },
      {
        id: "4yu7ia5YCH",
        type: "paragraph",
        data: { text: "Supervisor: &nbsp;Prof. D. Srinivasacharya" },
      },
      {
        id: "SdQg_Y5VeK",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/subrathha.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "z0LdE55cFN",
        type: "paragraph",
        data: { text: "Name:&nbsp;Subhabrata Rath" },
      },
      {
        id: "Owwdop8yV3",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "kAge6kTBJQ",
        type: "paragraph",
        data: { text: "Email:&nbsp;subha.bratarath17@gmail.com" },
      },
      {
        id: "YtOQCnj5YL",
        type: "paragraph",
        data: { text: "Mobile: 6371739705" },
      },
      {
        id: "QfpvCvwL3v",
        type: "paragraph",
        data: { text: "Research Area: Operation Research" },
      },
      {
        id: "pTEyqrapG6",
        type: "paragraph",
        data: { text: "Supervisor: &nbsp;Prof. D. Dutta" },
      },
      {
        id: "BOXydzE7gm",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/harlal.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "CdKD3rmDSv",
        type: "paragraph",
        data: { text: "Name:&nbsp;Harlal Saran" },
      },
      {
        id: "JTtcQHTPHk",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "VfOnnFn-hG",
        type: "paragraph",
        data: { text: "Email:&nbsp;harlalsaran6@gmail.com" },
      },
      {
        id: "NGv0D_g_Zu",
        type: "paragraph",
        data: { text: "Mobile: 9680003978" },
      },
      {
        id: "8xgT-4oR7m",
        type: "paragraph",
        data: { text: "Research Area: Fluid Dynamics" },
      },
      {
        id: "6j7Mya_NQs",
        type: "paragraph",
        data: { text: "Supervisor: &nbsp;&nbsp;Dr. Ch. Ramreddy" },
      },
      {
        id: "psb_69MnuP",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/sourav.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "9Nw0lRRUiQ",
        type: "paragraph",
        data: { text: "Name:&nbsp;Sourav Bera" },
      },
      {
        id: "iHJrZaqLc5",
        type: "paragraph",
        data: { text: "Type: Full Time (Inspire)" },
      },
      {
        id: "a9WlIVM02L",
        type: "paragraph",
        data: { text: "Email: berasourav561@gmail.com" },
      },
      {
        id: "eAzHGkGTIx",
        type: "paragraph",
        data: { text: "Mobile: 9007589477" },
      },
      {
        id: "nflYSjGbFd",
        type: "paragraph",
        data: { text: "Research Area: Cryptography" },
      },
      {
        id: "WmNwR0vndR",
        type: "paragraph",
        data: { text: "Supervisor: &nbsp;Dr. Y. Sreenivasa Rao" },
      },
      {
        id: "LjN-W6Taxo",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/bikash.jpg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "PuCr2c9I2P",
        type: "paragraph",
        data: { text: "Name:&nbsp;Bikash Modak" },
      },
      {
        id: "Wdv_QzCTkb",
        type: "paragraph",
        data: { text: "Type: Full Time" },
      },
      {
        id: "D61Z6CaBOs",
        type: "paragraph",
        data: { text: "Email: bikash1995modak@gmail.com" },
      },
      {
        id: "-07WQPiZpk",
        type: "paragraph",
        data: { text: "Mobile: 8910633240/8272978077" },
      },
      {
        id: "ChPzdQ_Xdx",
        type: "paragraph",
        data: { text: "Research Area: Mathematical Modeling" },
      },
      {
        id: "2tL2XEVnXE",
        type: "paragraph",
        data: { text: "Supervisor: &nbsp;Dr. P. Muthu" },
      },
      {
        id: "fUoGQzKq_u",
        type: "image",
        data: {
          file: {
            url: "https://nitw.ac.in/media/uploads/2019/08/03/rajendra.jpeg",
          },
          caption: "",
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      },
      {
        id: "URQbgCVXJV",
        type: "paragraph",
        data: { text: "Name:&nbsp;Bhanoth Rajender" },
      },
      { id: "maSpMeU6CQ", type: "paragraph", data: { text: "Type: QIP" } },
      {
        id: "DX_wXKawSM",
        type: "paragraph",
        data: { text: "Email: rajender.nitw9@gmail.com" },
      },
      {
        id: "F5t-7VzAnl",
        type: "paragraph",
        data: { text: "Mobile: 9963092060" },
      },
      {
        id: "igYW-aZEFl",
        type: "paragraph",
        data: { text: "Research Area: Fluid Dynamics" },
      },
      {
        id: "GRowpLwFUb",
        type: "paragraph",
        data: { text: "Supervisor: &nbsp;&nbsp;Prof. D. Srinivasacharya" },
      },
    ],
    language: "En",
    _id: "61eeda8a6634bf30358ffd1e",
    time: 1643043466273,
    version: "2.22.2",
    title: "Research Scholars",
    url: "/mathematics/ResearchScholars",
    path: "/mathematics",
    createdAt: "2022-01-24T16:57:46.712Z",
    updatedAt: "2022-01-24T16:57:46.712Z",
    __v: 0,
  },
  {
    blocks: [
      {
        id: "3L6T2AFm4-",
        type: "header",
        data: { text: "Website FAQ", level: 4 },
      },
      {
        id: "4fwOC4gET5",
        type: "header",
        data: { text: "How to Maximize your website experience?", level: 6 },
      },
      {
        id: "QT8yL9uVAP",
        type: "paragraph",
        data: {
          text: "This website can be best viewed on IE 9+, Mozilla Firefox, Google Chrome and Opera 11+.",
        },
      },
      {
        id: "mOXyWDiKWw",
        type: "paragraph",
        data: { text: "**Faculty pages incomplete. Will be live soon" },
      },
      {
        id: "wh3DY9tMor",
        type: "header",
        data: { text: " How do I upload my data?", level: 6 },
      },
      {
        id: "eerfN5v_yY",
        type: "paragraph",
        data: { text: "** To faculty members: " },
      },
      {
        id: "x9WkDofWf_",
        type: "paragraph",
        data: {
          text: "The framework for faculty information is undergoing completion and testing. You can see your profile and upload information once you receive your login details. The details will be shared to you once we're done",
        },
      },
      {
        id: "wNGxz6-C21",
        type: "header",
        data: { text: "The website loads slowly in my browser!", level: 6 },
      },
      {
        id: "0sjZOLiluR",
        type: "paragraph",
        data: {
          text: "The website is using optimized techniques to serve you faster. Try changing browser or connect to different WiFi/Network. If problem still persists contact us.",
        },
      },
      { id: "lZAKVBeUBL", type: "header", data: { text: "Bugs!!!", level: 6 } },
      {
        id: "dWisdtkaBm",
        type: "paragraph",
        data: {
          text: "This framework is built on a lot of new modules and practices. Content may not appear as it is defined, especially in IE7 and below. Kindly upgrade or use an alternative browser. If the problem still persists, just send a mail to the web team. We will fix it ASAP.",
        },
      },
      {
        id: "ui_iEKA8SS",
        type: "header",
        data: { text: "Incomplete data and Under construction..?", level: 6 },
      },
      {
        id: "92GRM_uHnK",
        type: "paragraph",
        data: {
          text: "Under construction pages are those which are awaiting content or being reviewed. Content will be uploaded afterwards.&nbsp;",
        },
      },
      {
        id: "EN4Cwp22NM",
        type: "header",
        data: { text: "My Language is not English! What do i do?", level: 6 },
      },
      {
        id: "NKIZiF4_HO",
        type: "paragraph",
        data: {
          text: "As of now we're just serving in English. We're working hard on making it available in other languages. Hindi and Telugu versions will be live soon. Please be patient while we are working on it.",
        },
      },
    ],
    language: "En",
    _id: "61f2760094a76943bfd2ea81",
    time: 1641449497708,
    version: "2.22.2",
    title: "FAQ",
    path: "/home",
    url: "/home/faq",
    createdAt: "2021-10-17T10:44:55.897Z",
    updatedAt: "2022-01-06T06:09:47.322Z",
    __v: 0,
  },
];
