import { useState } from "react";


const CIVDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }

    
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img src="CIVDeptAssets/images/CivilEngg_banner.jpg" alt="" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                     role="tab" aria-controls="v-pills-visionandmission" aria-selected="false">
                                    <span>About</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')}  role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')} 
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                     role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')}  role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Society </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                     role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a>

                                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected="false"> <span>Awards and Honours</span> </a>



                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')}  role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Laboratory & Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>
                            </div>
                           
                           
                            <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/ce-kvreddy"
                                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ce-kvreddy" alt="" className="img-fluid"  width="190" height="220" />
                                <h6>Prof. K Venkata Reddy</h6>
                            </a>
                                <p> Head of the Department <br /> <a href="mailto:civil_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>civil_hod@nitw.ac.in</a> <br /> 9490165341 </p>
                            </div>



                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">
                                        {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                                        <p>Civil engineering is a broad field of engineering that deals with the planning, construction, and maintenance of fixed structures, or public works, as they are related to earth, water, or civilization and their processes. Most civil engineering today deals with power plants, bridges, roads, railways, structures, water supply, irrigation, the natural environment, sewer, flood control, transportation and traffic. In essence, civil engineering may be regarded as the profession that makes the world a more agreeable place in which to live.</p>
                                        
                                    </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>
                                            <p className="mb-4">To be a knowledge nerve centre in civil engineering education, research, entrepreneurship and industry outreach services for creating sustainable infrastructure and enhancing quality of life.</p>
                                            
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">Generate a specialized cadre of civil engineers by imparting quality education and training.</p>
                                            <p className="  mb-2">Attain international standards in teaching, research and consultancy with global linkages.</p>
                                           
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardCE/civil"> Notices/Announcements</a>
                                        </h4>
                                    </div>
                                </div>
                             
                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/* <h4 className="mb-4">Academic Programmes</h4>
                                    <div className="row">
                                    <div className="col-md-12">
                                    <h3 className="mb-3">PhD</h3>
                                    </div>
                                    </div> */}
                                    <div className="row">


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>B.Tech</h3>
                                                        {/* <p>The B. Tech (Computer Science and Engineering) program was started in
                                                            the year 1983 with an intake of 20 students. The intake was
                                                            subsequently increased to 120 in 2008. Currently the strength is
                                                            177.</p> */}
                                                        
                                                    </div>
                                                    <p className="more"> <a className="more-info-link"
                                                        href="CIVDeptAssets/docs/btech-civil-engineering-final-curriculum-and-syllabus-wef-2021.pdf" target="_blank"> B.Tech Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>M.Tech </h3>
                                                        {/* <p>M. Tech (Computer Science and Engineering) program was started in
                                                            1987 with an intake of 18 and subsequently increased to 20 in 2008.
                                                            Currently the strength is 30.</p> */}
                                                        
                                                    </div>
                                                     <p className="more"> <a className=" more-info-link"
                                                        href="CIVDeptAssets/docs/Construction_Technology_and_Management_2023-10-26-16-15-18.pdf"
                                                        target="_blank"> Construction Technology And Management Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a><br/> 
                                                            </p>


                                                            <p className="more"> <a className=" more-info-link"
                                                        href="CIVDeptAssets/docs/Engineering_Structures_2023-10-26-16-15-57.pdf"
                                                        target="_blank"> Engineering Structures Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a><br/> 
                                                            </p>


                                                            <p className="more"> <a className=" more-info-link"
                                                        href="CIVDeptAssets/docs/Environmental_Engineering_2023-10-26-16-16-46.pdf"
                                                        target="_blank"> Environmental Engineering Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a><br/> 
                                                            </p>


                                                            <p className="more"> <a className=" more-info-link"
                                                        href="CIVDeptAssets/docs/Geotechnical_Engineering_2023-10-26-16-17-50.pdf"
                                                        target="_blank"> Geotechnical Engineering Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a><br/> 
                                                            </p>


                                                            <p className="more"> <a className=" more-info-link"
                                                        href="CIVDeptAssets/docs/Remote_Sensing___GIS_2023-10-26-16-18-48.pdf"
                                                        target="_blank"> Remote Sensing And Gis Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a><br/> 
                                                            </p>


                                                            <p className="more"> <a className=" more-info-link"
                                                        href="CIVDeptAssets/docs/Transportation_Engineering_2023-10-26-16-19-30.pdf"
                                                        target="_blank"> Transportation Engineering Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a><br/> 
                                                            </p>


                                                            <p className="more"> <a className=" more-info-link"
                                                        href="CIVDeptAssets/docs/Waste_Management_2023-10-26-16-22-9.pdf"
                                                        target="_blank"> Waste Management Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a><br/> 
                                                            </p>


                                                            <p className="more"> <a className=" more-info-link"
                                                        href="CIVDeptAssets/docs/Water_Resources_Engineering_2023-10-26-16-21-7.pdf"
                                                        target="_blank"> Water Resources Engineering Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a><br/> 
                                                            </p>







                                                </div>
                                            </div>
                                        </div>



                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>M.Tech in Computer Science and Information Security </h3>
                                                        <p>M. Tech (Information Security) was introduced in the year 2008 Under
                                                            ISEAP sanctioned by Ministry of Communication and Information
                                                            Technology (MCIT), DOE, GOI, New Delhi with intake of 20. Later, it
                                                            was renamed as Computer Science and Information Security and with
                                                            current intake 30.</p>
                                                        
                                                    </div>
                                                    <p className="more"> <a className="more-info-link"
                                                        href="CSEDeptAssets/docs/re-revise-mtech-csis-syllabus-august-20-2021.pdf"
                                                        target="_blank"> Scheme &amp; Syllabi of Computer Science and
                                                        Information Security <i className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div> */}




                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>MCA</h3>
                                                        <p>The Master of Computer Applications (MCA) program was started in 1986
                                                            with an intake of 30 and increased to 46 from 2008. Currently the
                                                            strength is 58.</p>
                                                       
                                                    </div>
                                                    <p className="more"> <a className="more-info-link" href="CSEDeptAssets/docs/cse-mca-tt.pdf"
                                                        target="_blank"> MCA Even Semester Timetable <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div> */}



                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D.</h3>
                                                        
                                                    </div>
                                                     <p className="more"> <a className="buttton more-info-link"
                                                        href="CIVDeptAssets/docs/Engineeringstrucures_PhD.pdf"
                                                        target="_blank"> Engineering Strucures Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                        <p className="more"> <a className="buttton more-info-link"
                                                        href="CIVDeptAssets/docs/TransportationEngineering_PhD.pdf"
                                                        target="_blank"> Transportation Engineering Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    {/* <div className="row laboratory-facilities">
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Computing Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                       <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP EliteDesk 800 g1 Small Form Factor
                                                                Systemsi7 @3.40GHz, RAM 4GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 40</p>
                                                            <li className="text-black">HP Proliant 8300 Small Form Factor systems
                                                                i7@ 3.40GHz, RAM 8GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 5</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2"> Software</h4>
                                                        <p>Dual boot with Windows 7 and Ubuntu 14.04, NetBeans 7, MSDN, Dev C++
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/comp-laboratory.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Data Engineering Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP EliteDesk 800 g1 SFF Systems i7 @3.40GHz,
                                                                4GB RAM, 500GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 50</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Cognos BIClementine Version 11.0, Oracle 11g (Unlimited user
                                                            Licenses), MSDN Academic Alliance Software, Informatica (25 user
                                                            licenses )</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/data-eng.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Open Source Development Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP Proliant 8300 Small Form Factor systems
                                                                i7@ 3.40GHz, RAM 8GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 45</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Dual boot with Windows XP and Ubuntu 12.0, NetBeans 7, Oracle Client
                                                            10</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/opensrc.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Software Engineering Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">Dell Optiplex 760Intel Core2 duo CPU E8400
                                                                @3.00GHz, 300GB HDD, 4GB RAM</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 50</p>
                                                            <li className="text-black">Acer Veriton Intel Core i7 with 1TB HDD, 8 GB
                                                                RAM</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 40</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Rational Testing Suite, MSDN, NetBeans 7, Microsoft Assembler, Dev
                                                            C++ </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/swlab.jpg" alt="Photo of sunset"
                                                    className="img-fluid" /> </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Cloud Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">i7 processor, 16GB RAM</li>

                                                            <li className="text-black">Windows OS, Ubuntu OS</li>

                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/cloud-lab.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Wireless and Security Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP Proliant 8300 Micro Tower systemsi5
                                                                @3.40GHz, RAM 2GB, 500GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 30</p>
                                                            <li className="text-black">Dell Optiplex 760Intel Core2 duo CPU E8400
                                                                @3.00GHz, 300GB HDD, 4GB RAM</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 10</p>
                                                            <li className="text-black">HCL Dual core2Intel® Core™ 2 Duo CPU
                                                                E4500@2.20 GHz, 1GB RAM, 160GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 4</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>ONE Simulator, NS3, Rational Rose Tools, MSDN, Dev C++</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/wireless.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Servers</h3>
                                            </header>
                                            <div className="row">
                                               
                                                <div className="col-md-12"> <img src="CSEDeptAssets/images/server.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div> <br />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Windows File Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360e Gen8 intel®
                                                            Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Oracle Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL160 Gen8 Intel®
                                                            Xeon® CPU E5 -2630 @2.30GHz with 16GB RAM, 2*1TB </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">McAFEE Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HCL Intel® Xeon® CPU 3.40GHz
                                                            with 4GB RAM, 3*120GB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Proxy Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>Hp Compaq Elite 8300 SFF (i5
                                                            -3470) CPU @3.40GHz, RAM 2GB, HDD 500GB </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">COGNOS Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360 Intel® Xeon®
                                                            CPU 3.20GHz, 3GB RAM, 160GB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Rational Rose Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>IBM e-server Intel® Xeon™ CPU
                                                            2.40 GHz, 1GB RAM, 40GB HDD </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Linux Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360e Gen8 intel®
                                                            Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD) </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">High Performance Computing Server (8
                                                            node Cluster)</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL380P Gen82 x
                                                            Intel® xeon® CPU E5-2640 (2.5 GHz / 6-core/15MB / 95w) Processor64
                                                            GB DDR RAM, HP SA 410i RAID controller with 1 GB FBWCHP SN1000E 16GB
                                                            Dual Port, FC HBA/1200WIntel® xeon® CPU E5-2640 (2.5 GHz /
                                                            6-core/15MB / 95w) ProcessorIntel 7500 chip set with node controller
                                                        </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Big Data Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL980 Gen78 x Intel®
                                                            xeon® CPU E7-2830 (2.13 GHz / 8-core/24MB / 105w) ProcessorIntel
                                                            7500 chip set with node controller, 512 GB DDR RAMHP SA 410i RAID
                                                            controller with 1 GB FBWC6x600GB 10K HDD, DVD + RW 12 Nos. of PCI
                                                            slots4x dual port Giga Bit Ethernet NIC and 2x 10GBPS Ethernet
                                                            CardHP SN1000E 16GB Dual Port, FC HBA/1200WRack Mountable Hot
                                                            pluggable Redundant Power Supplies and Redundant fans/ Server
                                                            Manageability SoftwareOS: CentOS 6.5 Server with Hadoop2.2 </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist"> 
                                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                        role="tab" aria-controls="nav-faculty"
                                        aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a> 



{/* <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab" 
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty / Visiting Professors</a>
                                             */}
                                        
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab" 
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a> 


                                           
                                            
                                            <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                                data-toggle="tab"  role="tab"
                                                aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">
                                            {/*<h4 className=" mb-4">Faculty</h4>*/}
                                            {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                                            {/* <section id="profile-info">
                                            <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-akpcivil"
                                                                target="_blank"> <img src="CIVDeptAssets/images/23.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-akpcivil"
                                                                            target="_blank">Prof. Ajey Kumar Patel</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702468147 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:akpcivil@nitw.ac.in">
                                                                                akpcivil@nitw.ac.in</a></p>
                                                                        <p>Computational Fluid Dynamics (CFD); Environmental Fluid Mechanics; Wastewater Engineering; Surface Aeration Systems</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-aneetha"
                                                                target="_blank"> <img src="CIVDeptAssets/images/34.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-aneetha"
                                                                            target="_blank">Prof. Aneetha V</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9489787495 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:aneetha@nitw.ac.in">aneetha@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Building Information Modelling, Road Information Modelling, Lean Construction, Virtual Reality in Construction, Construction Project Management, Sustainable construction, Infrastructure Management.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16876"
                                                                target="_blank"> <img src="CIVDeptAssets/images/19.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16876"
                                                                            target="_blank">Prof. Arif Ali Baig Moghal</a>
                                                                        </h5> <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> +91-9989677217 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:baig@nitw.ac.in">baig@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Geoenvironmental Engineering, Unsaturated Soil Mechanics, Bio-Geotechnics</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-arpan"
                                                                target="_blank"> <img src="CIVDeptAssets/images/29.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-arpan"
                                                                            target="_blank">Prof. Arpan Mehar</a>
                                                                        </h5> <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969421 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:arpan@nitw.ac.in">arpan@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Macroscopic and microscopic traffic analysis, Highway Capacity and level of service, Highway geometric design, Traffic flow simulation, Traffic pollution</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-brkadali"
                                                                target="_blank"> <img src="CIVDeptAssets/images/37.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-brkadali"
                                                                            target="_blank">Prof. B Raghuram Kadali</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8879425755 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:brkadali@nitw.ac.in">brkadali@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>• Pedestrian Cross Flow Modelling, Non-Motorized Safety, Traffic Safety, Driver Behaviour, Traffic flow modeling • Transportation Environment • Travel behavior, Sustainable Transportation • Freight Transportation</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ce-csrk"
                                                            target="_blank"> <img src="CIVDeptAssets/images/3.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-csrk"
                                                                        target="_blank">Prof. C. S. R. K. Prasad</a> </h5>
                                                                    <span>Professor (HAG)</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> +91 9440347348 (M), 9515890908 (M), 870-2468117(R) <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:csrk@nitw.ac.in">csrk@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Travel Demand Modelling, Urban & Regional Planning, Land Use Planning, Transit Oriented Development, Public Transport, Highway Network Design, Traffic Safety, Safety Audit, Traffic System Design, Transport Economics, Low Volume Roads, Project Management</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-raviprasad"
                                                                target="_blank"> <img src="CIVDeptAssets/images/24.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-raviprasad"
                                                                            target="_blank">Prof. D. Ravi Prasad</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969256 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:raviprasad@nitw.ac.in"> raviprasad@nitw.ac.in</a></p>
                                                                        <p>Sustainable construction materials, Structural health monitoring, Hybrid Fiber reinforced engineered cementatious composites</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16221"
                                                                target="_blank"> <img src="CIVDeptAssets/images/28.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16221"
                                                                            target="_blank">Prof. K. Gopikrishna</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969258 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kgopi@nitw.ac.in">kgopi@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Multi hazards performance Assessment of structures, Computational Earthquake Engineering, Wavelet Finite Element Methods, Multi-scale Modeling of structures.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16220"
                                                                target="_blank"> <img src="CIVDeptAssets/images/26.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16220"
                                                                            target="_blank">Prof. K. V. R. Ravi Shankar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969262 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ravikvr@nitw.ac.in"> ravikvr@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Safety Analysis of Vehicular Interactions;Pedestrian behavioral analysis and modeling;Crowd dynamic analysis and emergency evacuation planning;Non-lane based behavior modeling and nanoscopic model development; Capacity analysis of highways under mixed traffic conditions;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-vnkrao
"
                                                                target="_blank"> <img src="CIVDeptAssets/images/13.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-vnkrao
"
                                                                            target="_blank">Prof. Kameswara Rao V N</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702462114 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vnkrao@nitw.ac.in">vnkrao@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Fluid Mechanics and Hydraulic Machines; Hydrologic Systems Modelling; Transport Phenomena of Mass, Momentum, Energy for climate modelling; Groundwater Modelling </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16919"
                                                                target="_blank"> <img src="CIVDeptAssets/images/33.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16919"
                                                                            target="_blank">Prof. Kavitha B</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9944139989 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kavithab@nitw.ac.in">kavithab@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Engineering Seismology; Earthquake data analysis; Simulation of ground motions; Probabilistic Seismic Hazard Assessment; Earthquake Forecasting</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17037"
                                                                target="_blank"> <img src="CIVDeptAssets/images/44.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17037"
                                                                            target="_blank">Prof. Litan Kumar Ray</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8265999740 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:litan@nitw.ac.in">litan@nitw.ac.in </a>
                                                                        </p>
                                                                        <p>Stochastic hydrology, Flood & drought estimation, Real-time flood forecasting, Hydrologic Modelling, Climate change, and climate variability study, Application of Machine learning</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16207"
                                                                target="_blank"> <img src="CIVDeptAssets/images/7.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16207"
                                                                            target="_blank">Prof. M. Chandrasekhar</a> </h5>
                                                                        <span>Professor (HAG)</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 09908132001 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i>  <a
                                                                                    href="mailto:mcs@nitw.ac.in">mcs@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Water and Wastewater treatment, Air quality modelling, Water quality modelling, Solid waste Management, EIA</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-mhl"
                                                                target="_blank"> <img src="CIVDeptAssets/images/11.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-mhl"
                                                                            target="_blank">Prof. M. Heeralal</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969251 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mhl@nitw.ac.in">mhl@nitw.ac.in
                                                                            </a> </p>
                                                                        <p> Recycled Aggregate in Pavements and Geo Environmental Engineering</p>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-mshashi"
                                                                target="_blank"> <img src="CIVDeptAssets/images/22.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-mshashi"
                                                                            target="_blank">Prof. M. Shashi</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969257 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mshashi@nitw.ac.in">mshashi@nitw.ac.in
                                                                            </a> </p>
                                                                        <p>Advanced Surveying, Photogrammetry, GPS</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16471"
                                                                target="_blank"> <img src="CIVDeptAssets/images/25.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16471"
                                                                            target="_blank">Prof. M. V. N. Sivakumar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9505745340 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mvns@nitw.ac.in"> mvns@nitw.ac.in
                                                                            </a> </p>
                                                                        <p>Computational Mechanics;Fracture Mechanics Applications to Metal and Concrete Structures;Finite Element and Reliability Applications to Nuclear Reactor Components;Experimental Studies on Special Concretes;Rheology of concrete</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-manalipal"
                                                                target="_blank"> <img src="CIVDeptAssets/images/42.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-manalipal"
                                                                            target="_blank">Prof. Manali Pal</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9064198165 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:manalipal@nitw.ac.in">manalipal@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Hydrology; Climate Change; Soil Moisture Retrieval using Remote Sensing; Stochastic Modelling; Machine Learning and Artificial Intelligence.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16195"
                                                            target="_blank"> <img src="CIVDeptAssets/images/2.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16195"
                                                                        target="_blank">Prof. N. V. Umamahesh</a>
                                                                    </h5> <span>Professor (HAG)</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849730834 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mahesh@nitw.ac.in">mahesh@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Water Resources Systems; Hydrologic Modelling; Modelling impacts of climate change; Hydroclimatic Extremes; Urban Floods; Applications of Soft Computing Techniques</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-srenitw"
                                                                target="_blank"> <img src="CIVDeptAssets/images/30.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-srenitw"
                                                                            target="_blank">Prof. P Sridhar</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9100418795, 08702462151 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:srenitw@nitw.ac.in">srenitw@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Water/wastewater treatment, life cycle analysis, modelling of water/wastewater treatment, Solid waste management, bio-energy production from wastewater, wastewater sludge, and organic waste, Biodiesel, Bioplastic, Bio-flocculant</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16614"
                                                                target="_blank"> <img src="CIVDeptAssets/images/18.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16614"
                                                                            target="_blank">Prof. P Venkateswara Rao</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9420161800
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:pvenku@nitw.ac.in"> pvenku@nitw.ac.in</a> </p>
                                                                        <p>Water supply, treatment and distribution, wastewater treatment, waste management, low cost water and wastewater treatment, Anaerobic digestion of organic wastes, waste to energy technologies</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> 
                                                        <a href="https://erp.nitw.ac.in/ext/profile/ce-rajesh" target="_blank" className="img-fluid"> 
                                                        <img src="CIVDeptAssets/images/4.jpg" alt="" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-rajesh" target="_blank">Prof. Rajesh Kumar G</a> </h5>
                                                                    <span>Professor (HAG)</span>
                                                                    <p className="contact-info"> 
                                                                    <i className="fa fa-phone" aria-hidden="true"></i> 9849764752 <br /> 
                                                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:rajesh@nitw.ac.in">rajesh@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>High Strength Concrete, Prestressed Concrete, Self compacting self curing concrete</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16210"
                                                                target="_blank"> <img src="CIVDeptAssets/images/9.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16210"
                                                                            target="_blank">Prof. Ramana Murthy V</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 09492444769 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vrm_nitw@yahoo.com">vrm_nitw@yahoo.com</a>
                                                                        </p>
                                                                        <p>Expansive soils, Reinforced Earth, Utilisation of waste materials like fly ash</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-drseshu"
                                                                target="_blank"> <img src="CIVDeptAssets/images/6.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-drseshu"
                                                                            target="_blank">Prof. Ramaseshu D</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849386595 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:drseshu@nitw.ac.in">drseshu@nitw.ac.in</a>
                                                                                     </p>
                                                                        <p>New Concretes, Repair & Health Monitoring of Structures</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> 
                                                        <a href="https://erp.nitw.ac.in/ext/profile/ce-rateeshp" target="_blank"> <img src="CIVDeptAssets/images/1.jpg" alt="" className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-rateeshp"
                                                                        target="_blank">Prof. Rathish Kumar Pancharathi</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849670347 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rateeshp@nitw.ac.in">rateeshp@nitw.ac.in</a>
                                                                              
                                                                                 </p>
                                                                    <p>Sustainable Concrete Making Materials, Earthquake Engineering, Health Monitoring of Structures, Repair and Rehabilitation of structures</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16217"
                                                                target="_blank"> <img src="CIVDeptAssets/images/17.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16217"
                                                                            target="_blank">Prof. S Venkateswara Rao</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9848264985, 8332969253
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:svrao@nitw.ac.in"> svrao@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Self Compacting concrete; Special concretes and nano materials in concrete and mortar; Repair and Rehabilitation of structures; Fiber reinforced Concrete,;Multi component binder in self compacting mortar; Use of Recycled concrete aggregate in mortar and concrete.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16926"
                                                                target="_blank"> <img src="CIVDeptAssets/images/32.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16926"
                                                                            target="_blank">Prof. S. Anitha Priyadharshani</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> +91 94891 92069, +91 88375 70319 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:priyadharshanianitha@nitw.ac.in"> priyadharshanianitha@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Characterization of materials; Fiber Reinforced Polymer Composites; Stiffened panels; Experimental techniques; Finite Element Analysis; Establishment of new testing facilities, Concrete filled steel tube (CFST) columns, Concrete filled FRP tube (CFFT) columns , RCC Columns with FRP bar reinforcement, FRP and FRC Sleepers</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16609"
                                                                target="_blank"> <img src="CIVDeptAssets/images/21.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16609"
                                                                            target="_blank"> Prof. S. Shankar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849102935 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ss@nitw.ac.in">ss@nitw.ac.in</a>
                                                                                    
                                                                        </p>
                                                                        <p> Low Volume Roads, Pavement Analysis and Design, Pavement Management System, Pavement Material characterization, Geo-Synthetic Applications in Pavements and Marginal and Innovative Materials in LVRs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-sbiswas"
                                                                target="_blank"> <img src="CIVDeptAssets/images/41.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-sbiswas"
                                                                            target="_blank">Dr. Sanjit Biswas</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8130662889 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:sbiswas@nitw.ac.in">sbiswas@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Soil Dynamics; Machine Foundations; Shallow and Deep Foundations; Field and Laboratory Testing; Finite Element Analysis; Continuum Approach Modelling and Analysis; Soil Mechanics; Geoenvironmental Engineering</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-msd"
                                                                target="_blank"> <img src="CIVDeptAssets/images/14.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-msd"
                                                                            target="_blank">Prof. Sudhakar M</a>
                                                                        </h5> <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9441136655 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:msd@nitw.ac.in"> msd@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Steel fibre Reinforced Concrete</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17051"
                                                                target="_blank"> <img src="CIVDeptAssets/images/40.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17051"
                                                                            target="_blank">Dr. Sudheer Kumar Yamsani</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8486367769 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:skyamsani@nitw.ac.in"> skyamsani@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Geoenvironmental EngineeringSoil-Atmosphere InteractionReinforced soil structuresApplied Soil Mechanics</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16897"
                                                                target="_blank"> <img src="CIVDeptAssets/images/35.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16897"
                                                                            target="_blank">Prof. Sumanth Chinthala</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702462152 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:sumanthchinthala@nitw.ac.in">sumanthchinthala@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Air Pollution; Indoor Air Quality; Sustainable Development, Water and Waste water Mangement; Solid waste Management; Environmental Impact Assessment, Life Cycle Analysis</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16473"
                                                                target="_blank"> <img src="CIVDeptAssets/images/20.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16473"
                                                                            target="_blank">Prof. T. P. Tezeswi</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969423 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:tezeswi@nitw.ac.in">tezeswi[at]nitw[dot]ac[dot]in</a>
                                                                        </p>
                                                                        <p>Experimental characterization and development of novel computational modeling methodologies to predict multi-scale behavior of cementitious composite materials and structural response to shock and high strain rate loading; structural dynamics; nonlinear finite element analysis; composite materials; vibration testing; photo elastic testing; high strain rate SHPB testing; MD Simulation of glass; Multi-hazard (blast, seismic and wind) vulnerability assessment of critical infrastructure; Imaging based Structural Health Monitoring.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16904"
                                                                target="_blank"> <img src="CIVDeptAssets/images/36.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16904"
                                                                            target="_blank">Prof. Umesh B.</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08712915568<br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:umeshbcvl@gmail.com">umeshbcvl@gmail.com</a>
                                                                        </p>
                                                                        <p>My research interest includes: Numerical Methods in Civil Engineering: Finite Difference Method, Finite Element Analysis and Iso-Geometric Analysis; Classical and nonClassical Continuum theories to study the Material Deformation and internal force distribution; Damage and Fracture Mechanics to study the crack propagation and related fields.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17107"
                                                                target="_blank"> <img src="CIVDeptAssets/images/47.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17107"
                                                                            target="_blank">Prof. Vasudha Dattatraya Katare</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7276089305 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vdk@nitw.ac.in">vdk@nitw.ac.in</a>
                                                                        </p>
                                                                        <p> Construction Technology and Management, Building Information Modelling, Sustainable Construction Materials, Concrete Technology, Pozzolanic Materials</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17040"
                                                                target="_blank"> <img src="CIVDeptAssets/images/43.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17040"
                                                                            target="_blank">Prof. Vema Vamsi Krishna</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9445252205 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vvamsikr@nitw.ac.in">vvamsikr@nitw.ac.in</a>
                                                                        </p>
                                                                        <p> Hydrologic Modelling; Watershed Management; Parameterization of Watershed Models; Agricultural Water Management; Optimal Allocation of Resources; Decision Making Under Uncertainty; Application of Soft Computing Tools for Water Resources Problems;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16215"
                                                                target="_blank"> <img src="CIVDeptAssets/images/16.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16215"
                                                                            target="_blank">Prof. Venkaiah Chowdary</a>
                                                                        </h5> <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969252
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:vc@nitw.ac.in"> vc@nitw.ac.in</a> </p>
                                                                        <p>Characterization for asphalt binders and mixtures; asphalt pavement analysis, design and evaluation; quantification of roadway and railway traffic noise.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16214"
                                                                target="_blank"> <img src="CIVDeptAssets/images/12.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16214"
                                                                            target="_blank">Prof. Venkata Reddy Keesara</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9441666379 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kvreddy@nitw.ac.in">kvreddy@nitw.ac.in </a></p>
                                                                        <p>Watershed modelling and management using numerical, soft computing and geospatial methods; Climate change impact studies; Applications of geospatial technologies in rural and urban environments.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-vsr"
                                                                target="_blank"> <img src="CIVDeptAssets/images/38.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-vsr"
                                                                            target="_blank">Prof. Vishnu R</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info">  <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vsr@nitw.ac.in">vsr@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Pavement Materials, Pavement Management Systems, Pavement Design and Evaluation</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                            </section> */}
                                             <section id="profile-info">
                                            <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-akpcivil"
                                                                target="_blank"> <img src="CIVDeptAssets/images/23.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-akpcivil"
                                                                            target="_blank">Prof. Ajey Kumar Patel</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702468147 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:akpcivil@nitw.ac.in">
                                                                                akpcivil@nitw.ac.in</a></p>
                                                                        <p>Computational Fluid Dynamics (CFD); Environmental Fluid Mechanics; Wastewater Engineering; Surface Aeration Systems</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-aneetha"
                                                                target="_blank"> <img src="CIVDeptAssets/images/34.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-aneetha"
                                                                            target="_blank">Prof. Aneetha V</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9489787495 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:aneetha@nitw.ac.in">aneetha@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Building Information Modelling, Road Information Modelling, Lean Construction, Virtual Reality in Construction, Construction Project Management, Sustainable construction, Infrastructure Management.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-baig"
                                                                target="_blank"> <img src="CIVDeptAssets/images/19.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-baig"
                                                                            target="_blank">Prof. Arif Ali Baig Moghal</a>
                                                                        </h5> <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> +91-9989677217 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:baig@nitw.ac.in">baig@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Geoenvironmental Engineering, Unsaturated Soil Mechanics, Bio-Geotechnics</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-arpan"
                                                                target="_blank"> <img src="CIVDeptAssets/images/29.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-arpan"
                                                                            target="_blank">Prof. Arpan Mehar</a>
                                                                        </h5> <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969421 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:arpan@nitw.ac.in">arpan@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Macroscopic and microscopic traffic analysis, Highway Capacity and level of service, Highway geometric design, Traffic flow simulation, Traffic pollution</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-brkadali"
                                                                target="_blank"> <img src="CIVDeptAssets/images/37.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-brkadali"
                                                                            target="_blank">Prof. B Raghuram Kadali</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8879425755 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:brkadali@nitw.ac.in">brkadali@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>• Pedestrian Cross Flow Modelling, Non-Motorized Safety, Traffic Safety, Driver Behaviour, Traffic flow modeling • Transportation Environment • Travel behavior, Sustainable Transportation • Freight Transportation</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ce-csrk"
                                                            target="_blank"> <img src="CIVDeptAssets/images/3.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-csrk"
                                                                        target="_blank">Prof. C. S. R. K. Prasad</a> </h5>
                                                                    <span>Professor (HAG)</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> +91 9440347348 (M), 9515890908 (M), 870-2468117(R) <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:csrk@nitw.ac.in">csrk@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Travel Demand Modelling, Urban & Regional Planning, Land Use Planning, Transit Oriented Development, Public Transport, Highway Network Design, Traffic Safety, Safety Audit, Traffic System Design, Transport Economics, Low Volume Roads, Project Management</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-raviprasad"
                                                                target="_blank"> <img src="CIVDeptAssets/images/24.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-raviprasad"
                                                                            target="_blank">Prof. D. Ravi Prasad</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969256 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:raviprasad@nitw.ac.in"> raviprasad@nitw.ac.in</a></p>
                                                                        <p>Sustainable construction materials, Structural health monitoring, Hybrid Fiber reinforced engineered cementatious composites</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-kalyan"
                                                                target="_blank"> <img src="CIVDeptAssets/images/27.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-kalyan"
                                                                            target="_blank">Prof. G Kalyan Kumar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969265 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kalyan@nitw.ac.in">kalyan@nitw.ac.in</a> </p>
                                                                        <p>Seismic hazard Analysis; Reliability Analysis; Soil Dynamics; Soil Pile Interaction; Laterally loaded piles; Seismic Microzonation; Disaster Management; Shallow Subsurface Investigation; Pervious concrete pile; Biomodification and MICP of fine grained sands</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-gvramana"
                                                                target="_blank"> <img src="CIVDeptAssets/images/31.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-gvramana"
                                                                            target="_blank">Prof. G. V. Ramana</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9266581032 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:gvramana@nitw.ac.in">gvramana@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Rock Mechanics and Rock Engineering, In-Situ and Laboratory Rock Mechanics Investigations (Deformability characteristics, In-Situ Stress Measurements, Shear strength parameters, Pull-out test and permeability of rock mass, Engineering and Physical properties ), Design and analysis of Underground Structures, Geotechnical Engineering and Landslides mitigating measures, In-situ Geotechnical Investigations (Plate load test, Footing load test , Pile load test and field permeability test), Ground Improvement Techniques for Problematic Soils , Monitoring and Instrumentation of Geo -technical Structures,Strength and durability studies of Multi Blended Concretes, Alkali silica reaction (ASR) studies on water resources structures.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-tdg"
                                                                target="_blank"> <img src="CIVDeptAssets/images/8.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-tdg"
                                                                            target="_blank">Prof. Gunneswara Rao T D</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9441136676 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:tdg@nitw.ac.in">tdg@nitw.ac.in</a>
                                                                             </p>
                                                                        <p>Fracture Mechanics of Concrete StructuresFiber Reinforced ConcreteSustainable Construction Materials</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-phari"
                                                                target="_blank"> <img src="CIVDeptAssets/images/10.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-phari"
                                                                            target="_blank">Prof. Hari Krishna P</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9490457404 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:phari@nitw.ac.in">phari@nitw.ac.in</a>
                                                                        </p>
                                                                        <p> Granular Anchor Piles, Expansive Soils, Value based higher education</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ce-jdas"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="https://nitw.ac.in/api/static/files/Dr_Jew_Das_2023-11-6-18-26-58.jpg" alt="" className="img-fluid" width="150" height="180"/> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-jdas"
                                                                        target="_blank">Prof. Jew Das</a> </h5>
                                                                    <span>Assistant Professor </span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7382341649 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:jdas@nitw.ac.in ">jdas@nitw.ac.in </a>
                                                                    </p>
                                                                     <p className="justified-text">

                                                                    Climate Change Impact Assessment, Hydrological Extremes, Nonstationary  Analysis, Population Exposure, Uncertainty Analysis, Machine Learning Application in Water Resources, Hydrological Modelling
                                                                </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>



                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="hhttps://erp.nitw.ac.in/ext/profile/ce-kgopi"
                                                                target="_blank"> <img src="CIVDeptAssets/images/28.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-kgopi"
                                                                            target="_blank">Prof. K. Gopikrishna</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969258 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kgopi@nitw.ac.in">kgopi@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Multi hazards performance Assessment of structures, Computational Earthquake Engineering, Wavelet Finite Element Methods, Multi-scale Modeling of structures.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-ravikvr"
                                                                target="_blank"> <img src="CIVDeptAssets/images/26.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-ravikvr"
                                                                            target="_blank">Prof. K. V. R. Ravi Shankar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969262 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ravikvr@nitw.ac.in"> ravikvr@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Safety Analysis of Vehicular Interactions;Pedestrian behavioral analysis and modeling;Crowd dynamic analysis and emergency evacuation planning;Non-lane based behavior modeling and nanoscopic model development; Capacity analysis of highways under mixed traffic conditions;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-vnkrao
"
                                                                target="_blank"> <img src="CIVDeptAssets/images/13.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-vnkrao
"
                                                                            target="_blank">Prof. Kameswara Rao V N</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702462114 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vnkrao@nitw.ac.in">vnkrao@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Fluid Mechanics and Hydraulic Machines; Hydrologic Systems Modelling; Transport Phenomena of Mass, Momentum, Energy for climate modelling; Groundwater Modelling </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-kavithab"
                                                                target="_blank"> <img src="CIVDeptAssets/images/33.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-kavithab"
                                                                            target="_blank">Prof. Kavitha B</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9944139989 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kavithab@nitw.ac.in">kavithab@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Engineering Seismology; Earthquake data analysis; Simulation of ground motions; Probabilistic Seismic Hazard Assessment; Earthquake Forecasting</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-litan"
                                                                target="_blank"> <img src="CIVDeptAssets/images/44.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-litan"
                                                                            target="_blank">Prof. Litan Kumar Ray</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8265999740 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:litan@nitw.ac.in">litan@nitw.ac.in </a>
                                                                        </p>
                                                                        <p>Stochastic hydrology, Flood & drought estimation, Real-time flood forecasting, Hydrologic Modelling, Climate change, and climate variability study, Application of Machine learning</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-mcs"
                                                                target="_blank"> <img src="CIVDeptAssets/images/7.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-mcs"
                                                                            target="_blank">Prof. M. Chandrasekhar</a> </h5>
                                                                        <span>Professor (HAG)</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 09908132001 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i>  <a
                                                                                    href="mailto:mcs@nitw.ac.in">mcs@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Water and Wastewater treatment, Air quality modelling, Water quality modelling, Solid waste Management, EIA</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-mhl"
                                                                target="_blank"> <img src="CIVDeptAssets/images/11.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-mhl"
                                                                            target="_blank">Prof. M. Heeralal</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969251 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mhl@nitw.ac.in">mhl@nitw.ac.in
                                                                            </a> </p>
                                                                        <p> Recycled Aggregate in Pavements and Geo Environmental Engineering</p>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-mshashi"
                                                                target="_blank"> <img src="CIVDeptAssets/images/22.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-mshashi"
                                                                            target="_blank">Prof. M. Shashi</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969257 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mshashi@nitw.ac.in">mshashi@nitw.ac.in
                                                                            </a> </p>
                                                                        <p>Advanced Surveying, Photogrammetry, GPS</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-mvns"
                                                                target="_blank"> <img src="CIVDeptAssets/images/25.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-mvns"
                                                                            target="_blank">Prof. M. V. N. Sivakumar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9505745340 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mvns@nitw.ac.in"> mvns@nitw.ac.in
                                                                            </a> </p>
                                                                        <p>Computational Mechanics;Fracture Mechanics Applications to Metal and Concrete Structures;Finite Element and Reliability Applications to Nuclear Reactor Components;Experimental Studies on Special Concretes;Rheology of concrete</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-manalipal"
                                                                target="_blank"> <img src="CIVDeptAssets/images/42.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-manalipal"
                                                                            target="_blank">Prof. Manali Pal</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9064198165 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:manalipal@nitw.ac.in">manalipal@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Hydrology; Climate Change; Soil Moisture Retrieval using Remote Sensing; Stochastic Modelling; Machine Learning and Artificial Intelligence.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17039"
                                                                target="_blank"> <img src="CIVDeptAssets/images/39.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17039"
                                                                            target="_blank">Prof. Manish Pandey</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08704262118 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mpandey@nitw.ac.in">mpandey@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Experimental Hydraulics, Sediment Transport, River Training Works, Bridge Scour.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ce-mahesh"
                                                            target="_blank"> <img src="CIVDeptAssets/images/2.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-mahesh"
                                                                        target="_blank">Prof. N. V. Umamahesh</a>
                                                                    </h5> <span>Professor (HAG)</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849730834 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mahesh@nitw.ac.in">mahesh@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Water Resources Systems; Hydrologic Modelling; Modelling impacts of climate change; Hydroclimatic Extremes; Urban Floods; Applications of Soft Computing Techniques</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-hari"
                                                                target="_blank"> <img src="CIVDeptAssets/images/15.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-hari"
                                                                            target="_blank">Prof. P. Hari Prasada Reddy</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9885567287 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:hari@nitw.ac.in">
                                                                                hari@nitw.ac.in </a>  </p>
                                                                        <p>Solid Waste Management, Geoenvironmental Engineering, Water Treatment</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-srenitw"
                                                                target="_blank"> <img src="CIVDeptAssets/images/30.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-srenitw"
                                                                            target="_blank">Prof. P Sridhar</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9100418795, 08702462151 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:srenitw@nitw.ac.in">srenitw@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Water/wastewater treatment, life cycle analysis, modelling of water/wastewater treatment, Solid waste management, bio-energy production from wastewater, wastewater sludge, and organic waste, Biodiesel, Bioplastic, Bio-flocculant</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-pvenku"
                                                                target="_blank"> <img src="CIVDeptAssets/images/18.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-pvenku"
                                                                            target="_blank">Prof. P Venkateswara Rao</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9420161800
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:pvenku@nitw.ac.in"> pvenku@nitw.ac.in</a> </p>
                                                                        <p>Water supply, treatment and distribution, wastewater treatment, waste management, low cost water and wastewater treatment, Anaerobic digestion of organic wastes, waste to energy technologies</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> 
                                                        <a href="https://erp.nitw.ac.in/ext/profile/ce-rajesh" target="_blank" className="img-fluid"> 
                                                        <img src="CIVDeptAssets/images/4.jpg" alt="" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-rajesh" target="_blank">Prof. Rajesh Kumar G</a> </h5>
                                                                    <span>Professor (HAG)</span>
                                                                    <p className="contact-info"> 
                                                                    <i className="fa fa-phone" aria-hidden="true"></i> 9849764752 <br /> 
                                                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:rajesh@nitw.ac.in">rajesh@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>High Strength Concrete, Prestressed Concrete, Self compacting self curing concrete</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href=" https://erp.nitw.ac.in/ext/profile/ce-vramana"
                                                                target="_blank"> <img src="CIVDeptAssets/images/9.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href=" https://erp.nitw.ac.in/ext/profile/ce-vramana"
                                                                            target="_blank">Prof. Ramana Murthy V</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 09492444769 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vrm_nitw@yahoo.com">vrm_nitw@yahoo.com</a>
                                                                        </p>
                                                                        <p>Expansive soils, Reinforced Earth, Utilisation of waste materials like fly ash</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-drseshu"
                                                                target="_blank"> <img src="CIVDeptAssets/images/6.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-drseshu"
                                                                            target="_blank">Prof. Ramaseshu D</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849386595 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:drseshu@nitw.ac.in">drseshu@nitw.ac.in</a>
                                                                                     </p>
                                                                        <p>New Concretes, Repair & Health Monitoring of Structures</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> 
                                                        <a href="https://erp.nitw.ac.in/ext/profile/ce-rateeshp" target="_blank"> <img src="CIVDeptAssets/images/1.jpg" alt="" className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-rateeshp"
                                                                        target="_blank">Prof. Rathish Kumar Pancharathi</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849670347 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rateeshp@nitw.ac.in">rateeshp@nitw.ac.in</a>
                                                                              
                                                                                 </p>
                                                                    <p>Sustainable Concrete Making Materials, Earthquake Engineering, Health Monitoring of Structures, Repair and Rehabilitation of structures</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-svrao"
                                                                target="_blank"> <img src="CIVDeptAssets/images/17.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-svrao"
                                                                            target="_blank">Prof. S Venkateswara Rao</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9848264985, 8332969253
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:svrao@nitw.ac.in"> svrao@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Self Compacting concrete; Special concretes and nano materials in concrete and mortar; Repair and Rehabilitation of structures; Fiber reinforced Concrete,;Multi component binder in self compacting mortar; Use of Recycled concrete aggregate in mortar and concrete.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-priyadharshanianitha"
                                                                target="_blank"> <img src="CIVDeptAssets/images/32.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-priyadharshanianitha"
                                                                            target="_blank">Prof. S. Anitha Priyadharshani</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> +91 94891 92069, +91 88375 70319 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:priyadharshanianitha@nitw.ac.in"> priyadharshanianitha@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Characterization of materials; Fiber Reinforced Polymer Composites; Stiffened panels; Experimental techniques; Finite Element Analysis; Establishment of new testing facilities, Concrete filled steel tube (CFST) columns, Concrete filled FRP tube (CFFT) columns , RCC Columns with FRP bar reinforcement, FRP and FRC Sleepers</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-ss"
                                                                target="_blank"> <img src="CIVDeptAssets/images/21.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-ss"
                                                                            target="_blank"> Prof. S. Shankar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849102935 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ss@nitw.ac.in">ss@nitw.ac.in</a>
                                                                                    
                                                                        </p>
                                                                        <p> Low Volume Roads, Pavement Analysis and Design, Pavement Management System, Pavement Material characterization, Geo-Synthetic Applications in Pavements and Marginal and Innovative Materials in LVRs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-sbiswas"
                                                                target="_blank"> <img src="CIVDeptAssets/images/41.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-sbiswas"
                                                                            target="_blank">Dr. Sanjit Biswas</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8130662889 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:sbiswas@nitw.ac.in">sbiswas@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Soil Dynamics; Machine Foundations; Shallow and Deep Foundations; Field and Laboratory Testing; Finite Element Analysis; Continuum Approach Modelling and Analysis; Soil Mechanics; Geoenvironmental Engineering</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-msd"
                                                                target="_blank"> <img src="CIVDeptAssets/images/14.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-msd"
                                                                            target="_blank">Prof. Sudhakar M</a>
                                                                        </h5> <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9441136655 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:msd@nitw.ac.in"> msd@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Steel fibre Reinforced Concrete</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-skyamsani"
                                                                target="_blank"> <img src="CIVDeptAssets/images/40.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-skyamsani"
                                                                            target="_blank">Dr. Sudheer Kumar Yamsani</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8486367769 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:skyamsani@nitw.ac.in"> skyamsani@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Geoenvironmental EngineeringSoil-Atmosphere InteractionReinforced soil structuresApplied Soil Mechanics</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-sumanthchinthala"
                                                                target="_blank"> <img src="CIVDeptAssets/images/35.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-sumanthchinthala"
                                                                            target="_blank">Prof. Sumanth Chinthala</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702462152 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:sumanthchinthala@nitw.ac.in">sumanthchinthala@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Air Pollution; Indoor Air Quality; Sustainable Development, Water and Waste water Mangement; Solid waste Management; Environmental Impact Assessment, Life Cycle Analysis</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-tezeswi"
                                                                target="_blank"> <img src="CIVDeptAssets/images/20.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-tezeswi"
                                                                            target="_blank">Prof. T. P. Tezeswi</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969423 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:tezeswi@nitw.ac.in">tezeswi[at]nitw[dot]ac[dot]in</a>
                                                                        </p>
                                                                        <p>Experimental characterization and development of novel computational modeling methodologies to predict multi-scale behavior of cementitious composite materials and structural response to shock and high strain rate loading; structural dynamics; nonlinear finite element analysis; composite materials; vibration testing; photo elastic testing; high strain rate SHPB testing; MD Simulation of glass; Multi-hazard (blast, seismic and wind) vulnerability assessment of critical infrastructure; Imaging based Structural Health Monitoring.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-umeshb"
                                                                target="_blank"> <img src="CIVDeptAssets/images/36.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-umeshb"
                                                                            target="_blank">Prof. Umesh B.</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08712915568<br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:umeshbcvl@gmail.com">umeshbcvl@gmail.com</a>
                                                                        </p>
                                                                        <p>My research interest includes: Numerical Methods in Civil Engineering: Finite Difference Method, Finite Element Analysis and Iso-Geometric Analysis; Classical and nonClassical Continuum theories to study the Material Deformation and internal force distribution; Damage and Fracture Mechanics to study the crack propagation and related fields.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-vdk"
                                                                target="_blank"> <img src="CIVDeptAssets/images/47.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-vdk"
                                                                            target="_blank">Prof. Vasudha Dattatraya Katare</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7276089305 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vdk@nitw.ac.in">vdk@nitw.ac.in</a>
                                                                        </p>
                                                                        <p> Construction Technology and Management, Building Information Modelling, Sustainable Construction Materials, Concrete Technology, Pozzolanic Materials</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-vvamsikr"
                                                                target="_blank"> <img src="CIVDeptAssets/images/43.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-vvamsikr"
                                                                            target="_blank">Prof. Vema Vamsi Krishna</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9445252205 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vvamsikr@nitw.ac.in">vvamsikr@nitw.ac.in</a>
                                                                        </p>
                                                                        <p> Hydrologic Modelling; Watershed Management; Parameterization of Watershed Models; Agricultural Water Management; Optimal Allocation of Resources; Decision Making Under Uncertainty; Application of Soft Computing Tools for Water Resources Problems;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-vc"
                                                                target="_blank"> <img src="CIVDeptAssets/images/16.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-vc"
                                                                            target="_blank">Prof. Venkaiah Chowdary</a>
                                                                        </h5> <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969252
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:vc@nitw.ac.in"> vc@nitw.ac.in</a> </p>
                                                                        <p>Characterization for asphalt binders and mixtures; asphalt pavement analysis, design and evaluation; quantification of roadway and railway traffic noise.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-kvreddy"
                                                                target="_blank"> <img src="CIVDeptAssets/images/12.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-kvreddy"
                                                                            target="_blank">Prof. Venkata Reddy Keesara</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9441666379 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kvreddy@nitw.ac.in">kvreddy@nitw.ac.in </a></p>
                                                                        <p>Watershed modelling and management using numerical, soft computing and geospatial methods; Climate change impact studies; Applications of geospatial technologies in rural and urban environments.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ce-vsr"
                                                                target="_blank"> <img src="CIVDeptAssets/images/38.jpg" alt=""
                                                                    className="img-fluid" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ce-vsr"
                                                                            target="_blank">Prof. Vishnu R</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info">  <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vsr@nitw.ac.in">vsr@nitw.ac.in</a>
                                                                        </p>
                                                                        <p>Pavement Materials, Pavement Management Systems, Pavement Design and Evaluation</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                            
                                            </section>
                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">
                                               
                                               
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st1.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Md. Zafar</h6> <span>Senior Superintendent</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                        Office landline: <b>2101</b><br/>
                                                                        
                                                                         <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969574 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:zafar@nitw.ac.in">zafar@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st2.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. M. Sardar Singh</h6> <span>Assistant Engineer</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                    Office landline: <b>2166</b><br/>
                                                                         <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969685 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:msardarsingh80@nitw.ac.in">msardarsingh80@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st4.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. V. Ramesh</h6>
                                                                <span>Junior Assistant (UG)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                    Office landline: <b>2101</b><br/>
                                                                         <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849058554 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rajasirivjs@nitw.ac.in">rajasirivjs@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st5.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Kalumula Ramesh</h6> <span>Senior  Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                    Office landline: <b>2163</b><br/>
                                                                         <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969268 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kalumularamesh@gmail.com">kalumularamesh@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st6.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. A. Laxman</h6> <span>Senior  Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2164</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9491089595 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:almn72@nitw.ac.in">almn72@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st7.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Ms. Ramani Embari</h6> <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                    Office landline: <b>2161</b><br/>
                                                                         <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9160717742 <br /> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:embarir@nitw.ac.in">embarir@nitw.ac.in</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st8.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Gugulothu Suresh</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                    Office landline: <b>2162</b><br/>
                                                                         <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7207530424 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gugulothus@nitw.ac.in">gugulothus@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st9.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. M Hari Bharghav</h6> <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                    Office landline: <b>2163</b><br/>
                                                                         <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8328383763 <br /> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:haribharghavm@nitw.ac.in">haribharghavm@nitw.ac.in</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st10.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. K. Purushotham</h6> <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2165</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969270 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:Purushotham.kced@gmail.com">Purushotham.kced@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st11.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. A Chandra Narayana</h6> <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2161</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969273 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:addagatla1812@nitw.ac.in">addagatla1812@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st12.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Md. Abdul Gaffar</h6> <span>Senior Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2163</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969280 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mdgaffar833296@gmail.com">mdgaffar833296@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st13.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Kurra Mallikarjun</h6> <span>Senior Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2165</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9989292907 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mallik.dolly@gmail.com">mallik.dolly@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st14.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Miyyapuram Vijay Kumar</h6> <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2164</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9573171632 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:miyyapuramv@nitw.ac.in">miyyapuramv@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st15.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Sadaboina Kranthi Kumar</h6> <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2167</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9618069020 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:sadaboinak@nitw.ac.in">sadaboinak@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st16.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Shabothu Akhil</h6> <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2160</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7097568657 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:shabothua@nitw.ac.in">shabothua@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st17.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Ullengala Pavankalyan</h6> <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2164</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8187889320 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:pavanullengala@nitw.ac.in">pavanullengala@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st18.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Munigala Ravi</h6> <span>Care Taker (SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2164</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9848736632 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:munigalaravi68@gmail.com">munigalaravi68@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st19.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. B. Kumara Swamy</h6> <span>Care Taker (SG -II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2161</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969610 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kumaraswamyb@nitw.ac.in">kumaraswamyb@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CIVDeptAssets/images/st20.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. D.  Raju</h6> <span>Office Attendant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> 
                                                                    Office landline: <b>2101</b><br/>
                                                                    <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9441550468 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:davaraju@nitw.ac.in">davaraju@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>










                                            </div>

                                        </div>


                                        <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                            aria-labelledby="nav-AdjunctFaculty-tab">
                                
                                                    
                                            </div>


                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">
                                            {/*<h4 className="">Ph.D. Awardee</h4>
                    <p>
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}




   {/* START RESEARCH SCHOLORS */}

                                            <div className="row phd-std-row">



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">
                                                            <img src="CIVDeptAssets/images/phd1.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Fate of Pesticides in Black Cotton Soil: An Assessment and Control Approach</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Prasanta Majee</h6>
                                                        <span>Reg No.: 718105</span>
                                                        <hr />
                                                        <p className="contact-info">8074804585 <br />  <a href="mailto:mprasanta@student.nitw.ac.in">mprasanta@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd2.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Hydrothermal Carbonization</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Aravind Rao Takkallapalli</h6>
                                                        <span>Reg No.: 718009</span>
                                                        <hr />
                                                        <p className="contact-info">  9912543212 <br />  <a href="mailto:psudheekar@student.nitw.ac.in">psudheekar@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd3.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Hydrothermal Carbonization</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Sudheekar Reddy Periyavaram</h6>
                                                        <span>Reg No.: 719028</span>
                                                        <hr />
                                                        <p className="contact-info">8297960423
                                                            <br /><a href="mailto:psudheekar@student.nitw.ac.in" >psudheekar@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd4.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Structural Condition Monitoring Using Multispectral Aand DIC Based Methods</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Kumarapu Kumar</h6>
                                                        {/* <span>Full-Time Research Scholar</span> */}
                                                        <hr />
                                                        <p className="contact-info">  9700803661
                                                            <br />  <a href="mailto:kkumarapu@student.nitw.ac.in" >kkumarapu@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd5.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Coal Fly Ash Zeolites and their Application in Decontamination of Heavy Metal Contaminated Wastewaters </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Gaddam Venkata Sai Krishna</h6>
                                                        <span>Reg No.: 718001</span>
                                                        <hr />
                                                        <p className="contact-info">  9963232866
                                                            <br />  <a href="mailto:gvenkata1@student.nitw.ac.in" >gvenkata1@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd6.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Travel Behavioral and Travel Time Analysis</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Satagopam Padma Tejaswi</h6>
                                                        <span>Reg No.: 718005</span>
                                                        <hr />
                                                        <p className="contact-info">  8985148851
                                                            <br />  <a href="mailto:tejaswi@student.nitw.ac.in">tejaswi@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd7.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Impact of climate and land use changes on urban flooding</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Manchikatla Sagar Kumar</h6>
                                                        <span>Reg No.:718011</span>
                                                        <hr />
                                                        <p className="contact-info">  9000279995
                                                            <br />  <a href="mailto:msagar@student.nitw.ac.in">msagar@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd8.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Performance and microstructure characteristics of PLC based concrete made with different grades of limestone.</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">T Venugopala Reddy</h6>
                                                        <span>Reg No.: 718115</span>
                                                        <hr />
                                                        <p className="contact-info">  9989240842
                                                            <br />  <a href="mailto:tvgreddy@nitw.ac.in" >tvgreddy@nitw.ac.in</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd9.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geopolymer Mortars</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Poleboyana Rohit</h6>
                                                        <span>Reg No.: 718107</span>
                                                        <hr />
                                                        <p className="contact-info">  6300159989
                                                            <br />  <a href="mailto:pr718107@student.nitw.ac.in" >pr718107@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd10.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Transportation Engineering (Rail transport Acoustics) </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Boddu Sudhir Kumar</h6>
                                                        <span>Reg No.: 718106</span>
                                                        <hr />
                                                        <p className="contact-info">  7396511277
                                                            <br />  <a href="mailto:sudhir23@student.nitw.ac.in" >sudhir23@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd11.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Assessment of of water balance components in the Krishna River Basin: A perspective of climate and land use land cover change</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Suram Anil </h6>
                                                        <span>Reg No.: 718109</span>
                                                        <hr />
                                                        <p className="contact-info">  9182230890
                                                            <br />  <a href="mailto:sanil@atudent.nitw.ac.in" >sanil@atudent.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd12.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Analyzing sediment for developing the best management practices over Nagavali and Vamsadhara river basins </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Nagireddy Nageswara Reddy</h6>
                                                        <span>Reg No.:  718006</span>
                                                        <hr />
                                                        <p className="contact-info">  9550843890
                                                            <br />  <a href="mailto:nnagireddy@student.nitw.ac.in" >nnagireddy@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd13.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Aerosols </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K Tharani</h6>
                                                        <span>Reg No.: 718103</span>
                                                        <hr />
                                                        <p className="contact-info">  9642335044
                                                            <br />  <a href="mailto:tharani@student.nitw.ac.in" >tharani@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd14.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Fiber Alkali activated binders</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">M Venkateswarlu</h6>
                                                        <span>Reg No.: 718113</span>
                                                        <hr />
                                                        <p className="contact-info">  8500959055
                                                            <br />  <a href="mailto:mv718113@student.nitw.ac.in" >mv718113@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd15.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Integrated Water Resources Management </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Buri Eswar Sai</h6>
                                                        <span>Reg No.: 719016</span>
                                                        <hr />
                                                        <p className="contact-info">  7036222915
                                                            <br />  <a href="mailto:beswar@student.nitw.ac.in">beswar@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd16.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Ensemble Flood Forecasting</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K Nikhil Teja</h6>
                                                        <span>Reg No.: 719002</span>
                                                        <hr />
                                                        <p className="contact-info">9700966982
                                                        <br/> <a href="mailto:nikhilk@student.nitw.ac.in">nikhilk@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd17.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Climate change effect on streamflow and optimal operation of multi reservoir systems</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Rudraswamy G K</h6>
                                                        <span>Reg No.: 719024</span>
                                                        <hr />
                                                        <p className="contact-info">8553465071
                                                        <br/> <a href="mailto:phdwre2k19@student.nitw.ac.in">phdwre2k19@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd18.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Development of Self-Compacting Geopolymer Concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">T Malleswari Devi</h6>
                                                        <span>Reg No.: 719031 </span>
                                                        <hr />
                                                        <p className="contact-info">  9491879530 <br/> <a href="mailto:malleswari@student.nitw.ac.in">malleswari@student.nitw.ac.in</a>  </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd19.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>High strength multicomponent geopolymer concrete </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Padakanti Rakesh</h6>
                                                        <span>Reg No.: 719021 </span>
                                                        <hr />
                                                        <p className="contact-info">  9010354430
                                                            <br />  <a href="mailto:padakantirakesh@gmail.com" >padakantirakesh@gmail.com</a> </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd20.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Bond/Anchorage of Reinforcement in concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">A Saraswathi</h6>
                                                        <span>Reg No.: 719008</span>
                                                        <hr />

                                                        <p className="contact-info">  8008890973
                                                            <br />  <a href="mailto:asaraswathi@student.nitw.ac.in" >asaraswathi@student.nitw.ac.in</a> </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd21.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Safety Analysis of Horizontal Curved Sections  </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">G. Dharma Teja</h6>
                                                        <span>Reg No.: 719017</span>
                                                        <hr />
                                                        <p className="contact-info">  6301257032
                                                            <br />  <a href="mailto:godumaladteja@student.nitw.ac.in" >godumaladteja@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd22.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Removal of Flouroquinolone antibiotics from wastewater by advanced oxidation processes</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K Sandeep Kumar</h6>
                                                        <span>Reg No.: 719018</span>
                                                        <hr />
                                                        <p className="contact-info">  7893615329
                                                            <br />  <a href="mailto:Sandy173@student.nitw.ac.in">Sandy173@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd23.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Impact of climate change on agriculture productivity</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Kandula Srikanth</h6>
                                                        <span>Reg No.: 719029 </span>
                                                        <hr />

                                                        <p className="contact-info">  9177983611
                                                            <br />  <a href="mailto:ksrikanth2@student.nitw.ac.in" >ksrikanth2@student.nitw.ac.in</a> </p>

                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd24.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable Ground Improvement techniques, Environmental Geotechniques</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Aravind Goud Gaddam </h6>
                                                        <span>Reg No.: 701908</span>
                                                        <hr />
                                                        <p className="contact-info">  7660817517
                                                            <br />  <a href="mailto:garavind@student.nitw.ac.in">garavind@student.nitw.ac.in </a> </p>
                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd25.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Safety evaluation of right-turning vehicles at Unsignalized T – intersections</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Bonela Someswara Rao</h6>
                                                        <span>Reg No.: 701905</span>
                                                        <hr />

                                                        <p className="contact-info">  9502607177
                                                            <br />  <a href="mailto:so701905@student.nitw.ac.in" >so701905@student.nitw.ac.in </a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd26.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Performance of Multi Recycled Aggregate Concrete as a Building Material for Sustainable Infrastructure </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Kasulanati Madhavi Latha</h6>
                                                        <span>Reg No.: 701907 </span>
                                                        <hr />
                                                        <p className="contact-info">  8464822724
                                                            <br />  <a href="mailto:madhavi@student.nitw.ac.in" >madhavi@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd27.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Structural Health Monitoring using Machine Learning approach</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Polu Sathish</h6>
                                                        <span>Reg No.: 701903</span>
                                                        <hr />
                                                        <p className="contact-info">  9676826942
                                                            <br />  <a href="mailto:satishp@student.nitw.ac.in" >satishp@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd28.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Impact of land use land cover and climate change on water resources</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K N Loukika</h6>
                                                        <span>Reg No.: 701910</span>
                                                        <hr />
                                                        <p className="contact-info">  7382067213
                                                            <br />  <a href="mailto:loukikak@student.nitw.ac.in" >loukikak@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd29.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Landfill liners</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Aparna Gandrakoti</h6>
                                                        <span>Reg No.: 720020</span>
                                                        <hr />
                                                        <p className="contact-info">  9885197173
                                                            <br />  <a href="mailto:ag720020@nitw.ac.in" >ag720020@nitw.ac.in </a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd30.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Agricultural Drought Assessment using Satellite Soil Moisture Data</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Hussain Palagiri</h6>
                                                        <span>Reg No.: 720022</span>
                                                        <hr />
                                                        <p className="contact-info">  +91 7661999260
                                                            <br />  <a href="mailto:ph720022@student.nitw.ac.in">ph720022@student.nitw.ac.in </a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd31.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Industrial waste management </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">B. Gopal </h6>
                                                        <span>Reg No.: 720005</span>
                                                        <hr />
                                                        <p className="contact-info">  9550001819
                                                            <br />  <a href="mailto:bg720005@student.nitw.ac.in" >bg720005@student.nitw.ac.in</a> </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd32.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Lateral loaded piles</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Kiran Rathod</h6>
                                                        <span>Reg No.: 720019 </span>
                                                        <hr />

                                                        <p className="contact-info">  8332969265
                                                            <br />  <a href="mailto:kalyan@nitw.ac.in">kalyan@nitw.ac.in</a> </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd33.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Per and polyfluoroalkyl substances </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Manojkumar Y</h6>
                                                        <span>Reg No.: 720023</span>
                                                        <hr />
                                                        <p className="contact-info">  8122263533
                                                            <br />  <a href="mailto:ymanojkumar2795@gmail.com">ymanojkumar2795@gmail.com</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd34.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable Construction Materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">P. Mani Rathnam</h6>
                                                        <span>Reg No.: 720009</span>
                                                        <hr />
                                                        <p className="contact-info">  9030342265
                                                            <br />  <a href="mailto:Pmanirathnam@student.nitw.ac.in">Pmanirathnam@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd35.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Concrete filled steel tube (CFST) columns, Concrete filled FRP tube (CFFT) columns, sustainability and durability in concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">N A G K Manikanta Kopuri</h6>
                                                        <span>Reg No.: 720012 </span>
                                                        <hr />
                                                        <p className="contact-info">  9441796966
                                                            <br />  <a href="mailto:na720012@student.nitw.ac.in" >na720012@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd36.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Experimental study on FRP bar with geopolymer concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Niyazuddin</h6>
                                                        <span>Reg No.: 720016</span>
                                                        <hr />
                                                        <p className="contact-info">  9891341757
                                                            <br />  <a href="mailto:nn720016@student.nitw.ac.in" >nn720016@student.nitw.ac.in </a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd37.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Hydrological modelling for flood prediction</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Dasari Bheemaiahgari Indhu</h6>
                                                        <span>Reg No.: 720025</span>
                                                        <hr />
                                                        <p className="contact-info">  9611316333
                                                            <br />  <a href="mailto:db720025@student.nitw.ac.in" >db720025@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd38.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Effect of Built Environment on Travel behaviour</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Vasireddy Chaithanya Kumar</h6>
                                                        <span>Reg No.: 720018</span>
                                                        <hr />
                                                        <p className="contact-info">  9555541646
                                                            <br />  <a href="mailto:ckumarcivil@gmail.com" >ckumarcivil@gmail.com</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd39.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Anaerobic Digestion</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Kandagatla Nagarjuna</h6>
                                                        <span>Reg No.: 21CERER14</span>
                                                        <hr />
                                                        <p className="contact-info">  9533344279
                                                            <br />  <a href="mailto:nagarjunakandagatla@gmail.com" >nagarjunakandagatla@gmail.com</a> </p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd40.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Stability Analysis Of Mine Dumps</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Gedela Mounika</h6>
                                                        <span>Reg No.: 21CEREP03</span>
                                                        <hr />

                                                        <p className="contact-info">  7801009453
                                                            <br />  <a href="mailto:gm21cerep03@student.nitw.ac.in">gm21cerep03@student.nitw.ac.in</a> </p>

                                                    </div>
                                                </div>









                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd41.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Pavement Materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Gugulothu Ramulu</h6>
                                                        <span>Reg No.: 721005 </span>
                                                        <hr />
                                                        <p className="contact-info">  8328500254
                                                            <br />  <a href="mailto:gr721005@student.nitw.ac.in">gr721005@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd42.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geotechnical Earthquake Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Rajana Siva Raghava Siddhardha</h6>
                                                        <span>Reg No.: 721001</span>
                                                        <hr />
                                                        <p className="contact-info">  9059733637
                                                            <br />  <a href="mailto:rs721001@student.nitw.ac.in">rs721001@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd43.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Climate change analysis</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">V Manohar Reddy</h6>
                                                        <span>Reg No.: 721007</span>
                                                        <hr />
                                                        <p className="contact-info">  87547 99558
                                                            <br />  <a href="mailto:vm721007@student.nitw.ac.in">vm721007@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd44.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Air Quality Monitoring And Management</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Sarella Gowtham</h6>
                                                        <span>Reg No.: 721009</span>
                                                        <hr />
                                                        <p className="contact-info">  8096466976
                                                            <br />  <a href="mailto:sg721009@student.nitw.ac.in" >sg721009@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd45.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Eco friendly concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">S. Vivek</h6>
                                                        <span>Reg No.: 721004</span>
                                                        <hr />
                                                        <p className="contact-info">  9948148836
                                                            <br />  <a href="mailto:sv721004@student.nitw.ac.in" >sv721004@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd46.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Granite Sludge Dust Stabilization</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Kola Vikas</h6>
                                                        <span>Reg No.: 721002</span>
                                                        <hr />
                                                        <p className="contact-info">  8328397692
                                                            <br />  <a href="mailto:kvikas1@student.nitw.ac.in">kvikas1@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd47.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Light weight AAC block- Energy efficiency</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Ijjada Nandini</h6>
                                                        <span>Reg No.: 721012</span>
                                                        <hr />
                                                        <p className="contact-info">  8978726538
                                                            <br />  <a href="mailto:in721012@student.nitw.ac.in">in721012@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd48.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Crop Health Monitoring using UAV and Satellite Imagery</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Allu Ayyappa Reddy</h6>
                                                        <span>Reg No.: 721015</span>
                                                        <hr />
                                                        <p className="contact-info">  8247491581
                                                            <br />  <a href="mailto:aa721015@student.nitw.ac.in" >aa721015@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd49.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Cement Composites</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> V. Renuka </h6>
                                                        <span>Reg No.: 721008</span>
                                                        <hr />
                                                        <p className="contact-info">  9059480210
                                                            <br />  <a href="mailto:vr721008@student.nitw.ac.in" >vr721008@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd50.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Stone Columns</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> A Vittalaiah</h6>
                                                        <span>Reg No.: 21CEPJ024</span>
                                                        <hr />
                                                        <p className="contact-info">  9652981559
                                                            <br />  <a href="mailto:va712024@student.nitw.ac.in">va712024@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd51.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Hydrological Modelling</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> K Veerendra Gopi </h6>
                                                        <span>Reg No.: 21CEPJ035</span>
                                                        <hr />
                                                        <p className="contact-info">  9494770610
                                                        <br />  <a href="mailto:veerendragopik@gmail.com" >veerendragopik@gmail.com</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd52.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Mine Overburden Dumps</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Prudhviraju Vysyaraju </h6>
                                                        <span>Reg No.: 21CEPJ010</span>
                                                        <hr />

                                                        <p className="contact-info">  8331927400
                                                            <br />  <a href="mailto:pv712010@student.nitw.ac.in">pv712010@student.nitw.ac.in</a> </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd53.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Building Information Modeling (BIM) and Facility Management (FM)</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ahmad Alothman  </h6>
                                                        <span>Reg No.: 21CEIJ190</span>
                                                        <hr />
                                                        <p className="contact-info">  9789006257
                                                            <br />  <a href="mailto:aa712190@student.nitw.ac.in">aa712190@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>









                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd54.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Air Quality Monitoring and Management</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Padimala Shanmuka Sai Kumar </h6>
                                                        <span>Reg No.: 712028</span>
                                                        <hr />
                                                        <p className="contact-info"> 9553651227
                                                            <br />  <a href="mailto:sp712028@student.nitw.ac.in">sp712028@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd55.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Climate change impacts on River Basin</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Chakkaralla Mahammad Rafi  </h6>
                                                        <span>Reg No.: 712008</span>
                                                        <hr />
                                                        <p className="contact-info"> 7981363954
                                                            <br />  <a href="mailto:cm712008@student.nitw.ac.in">cm712008@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd56.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Scour around Multiple spur dikes </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> ReshmaTabassum </h6>
                                                        <span>Reg No.: 21CEPJ012 </span>
                                                        <hr />
                                                        <p className="contact-info"> 9246894959
                                                            <br />  <a href="mailto:rt712012@student.nitw.ac.in">rt712012@student.nitw.ac.in</a> </p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd57.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Building Information Modelling, underground utilities</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Rajadurai R</h6>
                                                        <span>Reg No.: 21CEFJ034</span>
                                                        <hr />
                                                        <p className="contact-info"> 8122218754
                                                            <br />  <a href="mailto:rr712034@student.nitw.ac.in" >rr712034@student.nitw.ac.in </a> </p>
                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd58.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Structural Behaviour on Circular Columns</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Abhishek Prakash </h6>
                                                        <span>Reg No.: 21CEIJ192</span>
                                                        <hr />
                                                        <p className="contact-info"> 07729975954
                                                            <br /> <a href="mailto:ap712192@student.nitw.ac.in">ap712192@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd59.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Ground Improvement using Biopolymers</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Romana Mariyam Rasheed </h6>
                                                        <span>Reg No.: 21CEPJ020</span>
                                                        <hr />
                                                        <p className="contact-info"> 9995825417
                                                            <br /> <a href="mailto:rm712020@student.nitw.ac.in">rm712020@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd60.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Seismic Vulnerability of RC Structures </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> G Srinath  </h6>
                                                        <span>Reg No.: 21CEFJ038 </span>
                                                        <hr />
                                                        <p className="contact-info"> 9908296059
                                                            <br /> <a href="mailto:gs712038@student.nitw.ac.in">gs712038@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd61.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Composites, NDT, High-Velocity Impact</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Vasamsetti Sri Harika </h6>
                                                        <span>Reg No.: 21CEFJ003</span>
                                                        <hr />
                                                        <p className="contact-info"> 9542913997
                                                            <br /> <a href="mailto:sv712003@student.nitw.ac.in" >sv712003@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd62.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Transit oriented development </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Chintakayala Lalitha Sree  </h6>
                                                        <span>Reg No.: 21CEFJ005</span>
                                                        <hr />
                                                        <p className="contact-info"> 9993290666
                                                            <br /> <a href="mailto:lc712005@gmail.com">lc712005@gmail.com</a></p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd63.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Road Safety Perception</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Basavaraj Akki  </h6>
                                                        <span>Reg No.: 21CEPJ041</span>
                                                        <hr />
                                                        <p className="contact-info"> 86609 81156
                                                            <br /> <a href="mailto:BA712041@student.nitw.ac.in">BA712041@student.nitw.ac.in</a></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd64.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Thermo-mechanical characterization of multi-component binder based FRC</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Samudrala Krishna Priya Rao</h6>
                                                        <span>Reg No.: 21CESJ021</span>
                                                        <hr />
                                                        <p className="contact-info"> 9440366775
                                                            <br /> <a href="mailto:ks712021@student.nitw.ac.in">ks712021@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd65.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Carbon - Capture - Utilization - Storage (CCUS) in Cementitious Materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> T Sunil Bhagat </h6>
                                                        <span>Reg No.: 21CEQJ043</span>
                                                        <hr />
                                                        <p className="contact-info"> +919299951555
                                                            <br /> <a href="mailto:st712043@student.nitw.ac.in">st712043@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>









                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd66.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Prediction of Heatwaves</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Bhoopathi Srikanth  </h6>
                                                        <span>Reg No.: 21CEFJ007</span>
                                                        <hr />
                                                        <p className="contact-info">  8686821371
                                                            <br /> <a href="mailto:sb712007@student.nitw.ac.in" > sb712007@student.nitw.ac.in </a></p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd67.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Structural Health Monitoring </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Tejas S. Shelgaonkar</h6>
                                                        <span>Reg No.: 21CEFJ009</span>
                                                        <hr />
                                                        <p className="contact-info">  9405670157
                                                            <br /> <a href="mailto:ts712009@student.nitw.ac.in" > ts712009@student.nitw.ac.in  </a></p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd68.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Soil dynamics and machine foundations</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Tankara Satheesh Kumar</h6>
                                                        <span>Reg No.: 21CERER07</span>
                                                        <hr />
                                                        <p className="contact-info">  9951999619
                                                            <br /> <a href="mailto:ts21cerer07@student.nitw.ac.in" > ts21cerer07@student.nitw.ac.in  </a></p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd69.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable construction materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Valluru Usha Rani</h6>
                                                        <span>Reg No.: 21CERER12</span>
                                                        <hr />
                                                        <p className="contact-info">  8985046414
                                                            <br /> <a href="mailto:vu21cerer12@student.nitw.ac.in" > vu21cerer12@student.nitw.ac.in  </a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd70.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Leaching Characteristics of Geo-polymerised Soil</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Lunavath Reddy</h6>
                                                        <span>Reg No.: 21CERER03</span>
                                                        <hr />
                                                        <p className="contact-info">  9573346184
                                                            <br /> <a href="mailto:lr21cerer03@student.nitw.ac.in" > lr21cerer03@student.nitw.ac.in  </a></p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd71.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Spatial assessment of agriculture scenario under Kaleshwaram Lift Irrigation Project (KLIP) using Geospatial Techniques</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Panjala Pranay</h6>
                                                        <span>Reg No.: 21CEREP12</span>
                                                        <hr />
                                                        <p className="contact-info">  9849804608
                                                            <br /> <a href="mailto:goudapranay@gmail.com" > goudapranay@gmail.com  </a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd72.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Structural Heath Monitoring</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Akula Prakash</h6>
                                                        <span>Reg No.: 21CEREP11</span>
                                                        <hr />
                                                        <p className="contact-info">  8686403054
                                                            <br /> <a href="mailto:ap21cerep11@student.nitw.ac.in" > ap21cerep11@student.nitw.ac.in  </a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd73.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Pavement Engineering - Marginal Materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Srikanth Talari</h6>
                                                        <span>Reg No.: 21CEREP01</span>
                                                        <hr />
                                                        <p className="contact-info">  7013283939
                                                            <br /> <a href="mailto:st21cerep01@student.nitw.ac.in" > st21cerep01@student.nitw.ac.in </a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd74.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Evaluation of Bituminous Interfaces</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Gundapaneni Deepchand</h6>
                                                        <span>Reg No.: 21CERER15</span>
                                                        <hr />
                                                        <p className="contact-info">  9542093078
                                                            <br /> <a href="mailto:gd21cerer15@student.nitw.ac.in" >gd21cerer15@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd75.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Soil Organic Carbon Mapping using Remote sensing</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Pavan Kumar Bellam</h6>
                                                        <span>Reg No.: 21CEREP13</span>
                                                        <hr />
                                                        <p className="contact-info">  9963442223
                                                            <br /> <a href="mailto:b.pavan1708@gmail.com">b.pavan1708@gmail.com</a></p>
                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd76.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Effect of cross - linked biopolymer inclusion on the geotechnical behaviour of soils exhibiting different plasticity characteristics</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> M Ashok Kumar</h6>
                                                        <span>Reg No.: 21CEREP04</span>
                                                        <hr />
                                                        <p className="contact-info">  8341038080
                                                            <br /> <a href="mailto:ma21cerep04@student.nitw.ac.in">ma21cerep04@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd77.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Environmental Engineering (phytoremediation)</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mahanti Bhargavi</h6>
                                                        <span>Reg No.: 21CEREP08</span>
                                                        <hr />
                                                        <p className="contact-info">  7013269385
                                                            <br /> <a href="mailto:mb21cerep08@student.nitw.ac.in">mb21cerep08@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd78.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>High Strength Geopolymer concert</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kampa Ravinder</h6>
                                                        <span>Reg No.: 21CERER17</span>
                                                        <hr />
                                                        <p className="contact-info">  9177597720
                                                            <br /> <a href="mailto:kr21cerer17@student.nitw.ac.in">kr21cerer17@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd79.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Hydrothermal carbonisation</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Lavakumar Uppala</h6>
                                                        <span>Reg No.: 22CER1R15</span>
                                                        <hr />
                                                        <p className="contact-info">  6301477614
                                                            <br /> <a href="mailto:ul22cer1r15@student.nitw.ac.in">ul22cer1r15@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd80.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable Construction Material</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Nenavath Ashok</h6>
                                                        <span>Reg No.: 22CER1R102</span>
                                                        <hr />
                                                        <p className="contact-info">  9505048502
                                                            <br /> <a href="mailto:na22cer1r02@student.nitw.ac.in">na22cer1r02@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd81.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Artificial intelligence in topographic mapping</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Shashivardhan Reddy P</h6>
                                                        <span>Reg No.: 22CER1P14</span>
                                                        <hr />
                                                        <p className="contact-info">  9290734260
                                                            <br /> <a href="mailto:shavarp2003@gmail.com">shavarp2003@gmail.com</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd82.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Structural fire analysis under earthquake</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Sarmili Swain </h6>
                                                        <span>Reg No.: 22CER1R05</span>
                                                        <hr />
                                                        <p className="contact-info">  8637291464
                                                            <br /> <a href="mailto:ss22cer1r05@student.nitw.ac.in">ss22cer1r05@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd83.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Anaerobic Digestion, Microalgae Cultivation</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Atchala Madhu Sudan Reddy </h6>
                                                        <span>Reg No.: 22CER1R16</span>
                                                        <hr />
                                                        <p className="contact-info">  9490897172
                                                            <br /> <a href="mailto:am22cer1r16@student.nitw.ac.in">am22cer1r16@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd84.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Safety Analysis of Roundabout Intersections</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Diwakar G.S. </h6>
                                                        <span>Reg No.: 22CER1Q01</span>
                                                        <hr />
                                                        <p className="contact-info">  9043180774
                                                            <br /> <a href="mailto:dg22cer1q01@student.nitw.ac.in">dg22cer1q01@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd85.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Optimization of Water Distribution Network</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Prachi Wakode </h6>
                                                        <span>Reg No.: 22CER1R17</span>
                                                        <hr />
                                                        <p className="contact-info">  9664103921
                                                            <br /> <a href="mailto:wp22cer1r17@student.nitw.ac.in">wp22cer1r17@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd86.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Studies on Vehicular emissions</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ch Rohith </h6>
                                                        <span>Reg No.: 22CER1R20</span>
                                                        <hr />
                                                        <p className="contact-info">  8500970790
                                                            <br /> <a href="mailto:cr22cer1r20@student.nitw.ac.in">cr22cer1r20@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd87.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Investigation and Development of Sustainable Building Materials using Raw clay and Calcined clay</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ahamed Sharif </h6>
                                                        <span>Reg No.: 22CER1R01</span>
                                                        <hr />
                                                        <p className="contact-info">  9620049054
                                                            <br /> <a href="mailto:as22cer1r01@student.nitw.ac.in">as22cer1r01@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd88.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Comparison of Fiber Reinforced Polymer Sleepers And Fiber Reinforced Concrete Sleepers</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ramakrishna Kamadani</h6>
                                                        <span>Reg No.: 22CER1P05</span>
                                                        <hr />
                                                        <p className="contact-info">  9666912383
                                                            <br /> <a href="mailto:rk22cer1p05@student.nitw.ac.in">rk22cer1p05@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd89.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geoenvironmental Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mohammad Nuruddin</h6>
                                                        <span>Reg No.: 22CER1R08</span>
                                                        <hr />
                                                        <p className="contact-info">  8688172676
                                                            <br /> <a href="mailto:mn22cer1r08@student.nitw.ac.in">mn22cer1r08@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd90.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable pavement materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Manikanta Vangari</h6>
                                                        <span>Reg No.: 22CER1P08</span>
                                                        <hr />
                                                        <p className="contact-info">  8688172676
                                                            <br /> <a href="mailto:Vm22cer1p08@student.nitw.ac.in">Vm22cer1p08@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd91.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Bamboo reinforced geopolymer concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Teshome Gebreyohannes Selamu</h6>
                                                        <span>Reg No.: 22CER1F01</span>
                                                        <hr />
                                                        <p className="contact-info">  +919515762512
                                                            <br /> <a href="mailto:tg22cer1f01@student.nitw act.in">tg22cer1f01@student.nitw act.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd92.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Engineering Seismology, Earthquake Data Analysis, Ground Motion Equations, ANN and ML </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Yarramsetty Bala Balaji</h6>
                                                        <span>Reg No.: 22CER1R03</span>
                                                        <hr />
                                                        <p className="contact-info">  9966250905
                                                            <br /> <a href="mailto:yb22cer1r03@gmail.com">yb22cer1r03@gmail.com</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd93.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Soft soil stabilization using prefabricated vertical drains </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kompala Muni Pavan</h6>
                                                        <span>Reg No.: 22CER1R19</span>
                                                        <hr />
                                                        <p className="contact-info">  9676502997
                                                            <br /> <a href="mailto:km22cer1r19@student.nitw.ac.in">km22cer1r19@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd94.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Basalt Rock fiber Reinforced concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> P. Sravani</h6>
                                                        <span>Reg No.: 22CER2P04</span>
                                                        <hr />
                                                        <p className="contact-info">  9908418946
                                                            <br /> <a href="mailto:ps22cer2p04@student.nitw.ac.in">ps22cer2p04@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd95.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Bio-cemented soils</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> S Kesav Kumar Arnepalli </h6>
                                                        <span>Reg No.: 22CER2P03</span>
                                                        <hr />
                                                        <p className="contact-info">  9716136602
                                                            <br /> <a href="mailto:sk22cer2p03@student.nitw.ac.in">sk22cer2p03@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd96.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Transportation Planning - Transit oriented development</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Biswarup Das </h6>
                                                        <span>Reg No.: 22CER2R11</span>
                                                        <hr />
                                                        <p className="contact-info">  9167519065
                                                            <br /> <a href="mailto:bd22cer2r11@student.nitw.ac.in">bd22cer2r11@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd97.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable Development Of Agriculture Using Microwave Remote Sensing</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Allu Pavan Kumar Reddy </h6>
                                                        <span>Reg No.: 22CER2R05</span>
                                                        <hr />
                                                        <p className="contact-info">  8374901464
                                                            <br /> <a href="mailto:ap22cer2r05@student.nitw.ac.in">ap22cer2r05@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd98.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Legal Affairs and Dispute Resolution in Construction Industry</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Uma Ravi Teja Macherla </h6>
                                                        <span>Reg No.: 23CER1R08</span>
                                                        <hr />
                                                        <p className="contact-info">  8332007357
                                                            <br /> <a href="mailto:mu23cer1r08@student.nitw.ac.in">mu23cer1r08@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd99.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Construction Materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Bejagama Sravankumar </h6>
                                                        <span>Reg No.: 23CER1R15</span>
                                                        <hr />
                                                        <p className="contact-info">  9985977164
                                                            <br /> <a href="mailto:bs23cer1r15@student.nitw.ac.in">bs23cer1r15@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd100.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Vulnerability assessment of building under earth quake </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mudavath Mothilal </h6>
                                                        <span>Reg No.: 23CER1R20</span>
                                                        <hr />
                                                        <p className="contact-info">  8184902334
                                                            <br /> <a href="mailto:mm23cer1r20@student.nitw.ac.in">mm23cer1r20@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd101.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>GeoPolymer Concrete </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Tallapalem Umamaheswararao </h6>
                                                        <span>Reg No.: 23CER1R05</span>
                                                        <hr />
                                                        <p className="contact-info">  9542554856
                                                            <br /> <a href="mailto:tu23cer1r05@student.nitw.ac.in">tu23cer1r05@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd102.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Remote Sensing And GIS </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Jeilani M </h6>
                                                        <span>Reg No.: 23CER1R14</span>
                                                        <hr />
                                                        <p className="contact-info">  7667448815
                                                            <br /> <a href="mailto:jm23cer1r14@student.nitw.ac.in">jm23cer1r14@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd103.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Strctural Enginering </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> SK Lal Mohiddin </h6>
                                                        <span>Reg No.: 23CER1R16</span>
                                                        <hr />
                                                        <p className="contact-info">  9985570617
                                                            <br /> <a href="mailto:sl23cer1r16@student.nitw.ac.in">sl23cer1r16@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd104.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Fracture Mechanics of Brittle Materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Shaik Shakeer Ahamed </h6>
                                                        <span>Reg No.: 23CER1R04</span>
                                                        <hr />
                                                        <p className="contact-info">  +918978226766
                                                            <br /> <a href="mailto:23cer1r04@student.nitw.ac.in">23cer1r04@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd105.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Soil Structure Interaction, Pile foundation.</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Puppala Sai Venkata Bharath</h6>
                                                        <span>Reg No.: 23CER1R03</span>
                                                        <hr />
                                                        <p className="contact-info">  8897524600
                                                            <br /> <a href="mailto:ps23cer1r03@student.nitw.ac.in">ps23cer1r03@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd106.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geotechnical Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Narlagiri Snehasree</h6>
                                                        <span>Reg No.: 23CER1R06</span>
                                                        <hr />
                                                        <p className="contact-info">  9908136538
                                                            <br /> <a href="mailto:ns23cer1r06@student.nitw.ac.in">ns23cer1r06@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd107.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Remote sensing and GIS</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Dasari Swetha</h6>
                                                        <span>Reg No.: 23CER1R17</span>
                                                        <hr />
                                                        <p className="contact-info">  9618577008
                                                            <br /> <a href="mailto:ds23cer1r17@student.nitw.ac.in">ds23cer1r17@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd108.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Anaerobic Digestion</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Sadhana Vangapalli </h6>
                                                        <span>Reg No.: 23CER1R02</span>
                                                        <hr />
                                                        <p className="contact-info">  7075360214
                                                            <br /> <a href="mailto:vs23cer1r02@student.nitw.ac.in">vs23cer1r02@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd109.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Air Pollution</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> K. Haripriya </h6>
                                                        <span>Reg No.: 23CER1R10</span>
                                                        <hr />
                                                        <p className="contact-info">  9701595596
                                                            <br /> <a href="mailto:kh23cer1r10@student.nitw.ac.in">kh23cer1r10@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd110.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geotechnical Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Boyina Manohar</h6>
                                                        <span>Reg No.: 23CER1R12</span>
                                                        <hr />
                                                        <p className="contact-info">  7702768918
                                                            <br /> <a href="mailto:bm23cer1r12@student.nitw.ac.in">bm23cer1r12@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd111.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Impact of climate change on Drought</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Jatoth Mounika</h6>
                                                        <span>Reg No.: 23CER1R19</span>
                                                        <hr />
                                                        <p className="contact-info">  7981398777
                                                            <br /> <a href="mailto:jm23cer1r19@student.nitw.ac.in">jm23cer1r19@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd112.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Pedestrian safety </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mitali Swargiary</h6>
                                                        <span>Reg No.: 21CERPR02</span>
                                                        <hr />
                                                        <p className="contact-info">  8811975734
                                                            <br /> <a href="mailto:ms21cerer02@student.nitw.ac.in">ms21cerer02@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd113.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Soil Stabilization through Geopolymer </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> K Manjula</h6>
                                                        <span>Reg No.: 21CEFJ039</span>
                                                        <hr />
                                                        <p className="contact-info">  9866405096
                                                            <br /> <a href="mailto:kmanjula@student.nitw.ac.in">kmanjula@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd114.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Studies on Hybrid Reinforcement </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> K Hemalatha</h6>
                                                        <span>Reg No.: 701915</span>
                                                        <hr />
                                                        <p className="contact-info">  8341541549
                                                            <br /> <a href="mailto:Khema@student.nitw.ac.in">Khema@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd115.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Seismic Assesment of Structures </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Jagadeeshwer Kancharla</h6>
                                                        <span>Reg No.: 23CER1902</span>
                                                        <hr />
                                                        <p className="contact-info">  7013452699
                                                            <br /> <a href="mailto:jk23cer1p02@student.nitw.ac.in">jk23cer1p02@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd116.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Signal Coordination </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> B. Suresh</h6>
                                                        <span>Reg No.: 721013</span>
                                                        <hr />
                                                        <p className="contact-info">  8143381500
                                                            <br /> <a href="mailto:bs721013@student.nitw.ac.in">bs721013@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd117.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Remote sensing application in watershed analysis with cascade system</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Koppuravuri Ramabrahmam</h6>
                                                        <span>Reg No.: 718013</span>
                                                        <hr />
                                                        <p className="contact-info">  9705511991
                                                            <br /> <a href="mailto:rambrahmam@student.nitw.ac.in">rambrahmam@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd118.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Special concretes</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Basireddy Bhavani</h6>
                                                        <span>Reg No.: 22CER1P04</span>
                                                        <hr />
                                                        <p className="contact-info">  7674024526
                                                            <br /> <a href="mailto:bb22cer1p04@student.nitw.ac.in">bb22cer1p04@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd119.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Dynamic analysis of pile foundation under machine vibration</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Deepthi Sudhi</h6>
                                                        <span>Reg No.: 720024</span>
                                                        <hr />
                                                        <p className="contact-info">  9495457311
                                                            <br /> <a href="mailto:ds720024@student.nitw.ac.in">ds720024@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd120.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Special concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Khanapuram Anand Goud</h6>
                                                        <span>Reg No.: 21CEREP14</span>
                                                        <hr />
                                                        <p className="contact-info">  7097097292
                                                            <br /> <a href="mailto:ka21cerep14@student.nitw.ac.in">ka21cerep14@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd121.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Soil Dynamics - High Speed Railways</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Gnana Prasanna G</h6>
                                                        <span>Reg No.: 21CEPJ018</span>
                                                        <hr />
                                                        <p className="contact-info">  9492283750
                                                            <br /> <a href="mailto:mp712018@student.nitw.ac.in">mp712018@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd122.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Traffic Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kiran Kumar Tottadi</h6>
                                                        <span>Reg No.: 719030</span>
                                                        <hr />
                                                        <p className="contact-info">  8179687374
                                                            <br /> <a href="mailto:kirant6666@student.nitw.ac.in">kirant6666@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd123.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable Concrete Pavement, Geopolymer Concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Nandipati S M Ravi Kumar</h6>
                                                        <span>Reg No.: 21CEFJ014</span>
                                                        <hr />
                                                        <p className="contact-info">  7981268283
                                                            <br /> <a href="mailto:ns712014@student.nitw.ac.in">ns712014@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd124.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Hydrological Modelling using Remote sensing data</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Aashi Agarwal</h6>
                                                        <span>Reg No.: 22CER1R11</span>
                                                        <hr />
                                                        <p className="contact-info">  8395850339
                                                            <br /> <a href="mailto:aa22cer1r11@student.nitw.ac.in">aa22cer1r11@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd125.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Water treatment - coagulation and flocculation</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mahesh</h6>
                                                        <span>Reg No.: 22CER1R14</span>
                                                        <hr />
                                                        <p className="contact-info">  7259804019
                                                            <br /> <a href="mailto:mm22cer1r14@student.nitw.ac.in">mm22cer1r14@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd126.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Transportation Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Anirudh Subramanyam M</h6>
                                                        <span>Reg No.: 21CERER13</span>
                                                        <hr />
                                                        <p className="contact-info">  9492203336
                                                            <br /> <a href="mailto:as21cerer13@student.nitw.ac.in">as21cerer13@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd127.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geotechnical Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Swetha Guduru</h6>
                                                        <span>Reg No.: 712031</span>
                                                        <hr />
                                                        <p className="contact-info">  9701701839
                                                            <br /> <a href="mailto:sg712031@student.nitw.ac.in">sg712031@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd128.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Flood modelling</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Neha paswan</h6>
                                                        <span>Reg No.: 22CER1R12</span>
                                                        <hr />
                                                        <p className="contact-info">  91733 06854
                                                            <br /> <a href="mailto:ng22cer1r12@student.nitw.ac.in">ng22cer1r12@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd129.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Cement Composites</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> K. Sai Padmaja</h6>
                                                        <span>Reg No.: 22CER1P03</span>
                                                        <hr />
                                                        <p className="contact-info">  91733 06854
                                                            <br /> <a href="mailto:ks22cer1p03@student.nitw.ac.in">ks22cer1p03@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd130.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Soil Dynamics</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> D.N Vaishnavi</h6>
                                                        <span>Reg No.: 22CER2R07</span>
                                                        <hr />
                                                        <p className="contact-info">  8309511309
                                                            <br /> <a href="mailto:dn22cer2r07@student.nitw.ac.in">dn22cer2r07@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd131.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geotechnical engineering : Reinforced soil walls,Geo synthetics, foundations</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Choppari Vinod</h6>
                                                        {/* <span>Reg No.: </span>
                                                        <hr />
                                                        <p className="contact-info"> 
                                                            <br /> <a href="mailto:dn22cer2r07@student.nitw.ac.in"></a></p> */}
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd132.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Retrofitting of RC Structures</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Tata Naga Seetha Bhagyasri</h6>
                                                        <span>Reg No.: 21CEFJ032</span>
                                                        <hr />
                                                        <p className="contact-info">  8499012988
                                                            <br /> <a href="mailto:tb712032@student.nitw.ac.in">tb712032@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd133.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Air Pollution</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Appireddy Srinivasa Reddy</h6>
                                                        <span>Reg No.: 21CEPJ001</span>
                                                        <hr />
                                                        <p className="contact-info">  9703725405
                                                            <br /> <a href="mailto:as712001@student.nitw.ac.in">as712001@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd134.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Air pollution control</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Gajulapalli Suryanarayanareddy</h6>
                                                        <span>Reg No.: 21CERER04</span>
                                                        <hr />
                                                        <p className="contact-info">  9491658977
                                                            <br /> <a href="mailto:GS21CERER04@student.nitw.ac.in">GS21CERER04@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd135.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geopolymer Concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Golla Swamy Yadav</h6>
                                                        <span>Reg No.: </span>
                                                        <hr />
                                                        <p className="contact-info"><a href="mailto:gs721011@student.nitw.ac.in">gs721011@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd136.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Scour caused by submerged circular jet</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Guguloth Saikumar</h6>
                                                        <span>Reg No.: 712006</span>
                                                        <hr />
                                                        <p className="contact-info"> 9542986677
                                                        <br /> <a href="mailto:gs712006@student.nitw.ac.in">gs712006@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd137.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>High strain rate response characterization of UHPC.</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Nagaraj M Tattien</h6>
                                                        <span>Reg No.: 22CER2P05</span>
                                                        <hr />
                                                        <p className="contact-info"> 9741968722
                                                        <br /> <a href="mailto:nn22cer2p05@student.nite.ac.in">nn22cer2p05@student.nite.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd138.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Bacterial Concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Anirudh Maddi</h6>
                                                        <span>Reg No.: 22CER1P06</span>
                                                        <hr />
                                                        <p className="contact-info"> 9030557363
                                                        <br /> <a href="mailto:ma22cer1p06@student.nitw.ac.in">ma22cer1p06@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd139.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Groundwater Resources</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Sree Laxmi Pavani Ch</h6>
                                                        <span>Reg No.: 719005</span>
                                                        <hr />
                                                        <p className="contact-info"> 8331099971
                                                        <br /> <a href="mailto:slpavani@student.nitw.ac.in">slpavani@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd140.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Transportation Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> M. RajiReddy</h6>
                                                        <span>Reg No.: 720027</span>
                                                        <hr />
                                                        <p className="contact-info"> 7893754499
                                                        <br /> <a href="mailto:rr720027@student.nitw.ac.in">rr720027@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd141.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Fiber reinforced light weight geopolymer concrete</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Lotla Sandeep Reddy</h6>
                                                        <span>Reg No.: 22CER1P01</span>
                                                        <hr />
                                                        <p className="contact-info"> 9030636415
                                                        <br /> <a href="mailto:ls22cer1p01@student.nitw.ac.in">ls22cer1p01@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd142.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Pavement Materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kothapalli Ravindra Babu</h6>
                                                        <span>Reg No.: 23CER1R18</span>
                                                        <hr />
                                                        <p className="contact-info"> 9381442621
                                                        <br /> <a href="mailto:kr23cer1r18@student.nitw.ac.in">kr23cer1r18@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd143.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geoenvironmental Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Sangeetha S</h6>
                                                        <span>Reg No.: 719009</span>
                                                        <hr />
                                                        <p className="contact-info"> 9849575415
                                                        <br /> <a href="mailto:mailsangeethasundar@gmail.com">mailsangeethasundar@gmail.com</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd144.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>High Velocity Impact Simulations using Peridynamics</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Bommisetty Jagadeesh</h6>
                                                        <span>Reg No.: 21CEPJ030</span>
                                                        <hr />
                                                        <p className="contact-info"> 9553532840
                                                        <br /> <a href="mailto:jb712030@student.nitw.ac.in">jb712030@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd145.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Environmental Impact Assessment</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Pinnoju Suresh Babu</h6>
                                                        <span>Reg No.: 21CEPJ011</span>
                                                        <hr />
                                                        <p className="contact-info"> 9440195816
                                                        <br /> <a href="mailto:SP712011@student.nitw.ac.in">SP712011@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd146.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Automating hyperspectral image analysis</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Peddinti Veerendra Satya Sylesh</h6>
                                                        <span>Reg No.: 719011</span>
                                                        <hr />
                                                        <p className="contact-info"> 9494201513
                                                        <br /> <a href="mailto:pveerendrasatyasylesh@student.nitw.ac.in">pveerendrasatyasylesh@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd147.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Transportation Engineering</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Dhatri. Punukollu</h6>
                                                        <span>Reg No.: 720021</span>
                                                        <hr />
                                                        <p className="contact-info"> 9866886438
                                                        <br /> <a href="mailto:dhatrikalyan@gmail.com">dhatrikalyan@gmail.com</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd148.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable Materials</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> R Harika</h6>
                                                        <span>Reg No.: 23CER1P01</span>
                                                        <hr />
                                                        <p className="contact-info"> 9550357852
                                                        <br /> <a href="mailto:rh23cer1p01@student.nitw.ac.in">rh23cer1p01@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd149.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Soil stabilization</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kadaru Ramesh</h6>
                                                        <span>Reg No.: 22CER1R06</span>
                                                        <hr />
                                                        <p className="contact-info"> 8978387339
                                                        <br /> <a href="mailto:kramesh3@student.nitw.ac.in">kramesh3@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd150.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Geotechnical Engineering - Stabilization of Soft Clays</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mrs. V. Bhavita Chowdary</h6>
                                                        <span>Reg No.: 715004</span>
                                                        <hr />
                                                        <p className="contact-info"> 9703798700
                                                        <br /> <a href="mailto:bhavitachowdary@gmail.com">bhavitachowdary@gmail.com</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd151.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Evaluation of comfort index and energy conservation</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Yarramsetty Subbarao</h6>
                                                        <span>Reg No.: 716104</span>
                                                        <hr />
                                                        <p className="contact-info"> 9705051493
                                                        <br /> <a href="mailto:sraoystp@student.nitw.ac.in">sraoystp@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd152.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>Sustainable wall envelope</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Abhishek R</h6>
                                                        <span>Reg No.: 7201010</span>
                                                        <hr />
                                                        <p className="contact-info"> 9535643887
                                                        <br /> <a href="mailto:ar721010@student.nitw.ac.in">ar721010@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="CIVDeptAssets/images/phd153.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p>
                                                                        Research Area : <b>BC Soil Stabilization through Geopolymerization Techniques</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mohammad Waheed</h6>
                                                        <span>Reg No.: 712017</span>
                                                        <hr />
                                                        <p className="contact-info"> 9535643887
                                                        <br /> <a href="mailto:mw712017@student.nitw.ac.in">mw712017@student.nitw.ac.in</a></p>
                                                    </div>
                                                </div>














                                            </div>

                                            {/* END OF RESEARCH SCHOLORS */}


    
    

                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div className="row std-society">
                                        
                                        
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16938"
                                                target="_blank"> <img src="CIVDeptAssets/images/31.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16938"
                                                    target="_blank">
                                                    <h6>Prof. G. V. Ramana</h6> <span> Faculty Adviser (CEA) </span>
                                                    <hr />
                                                </a>
                                                    <p> <i className="fa fa-phone" aria-hidden="true"></i> 9266581032 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:gvramana@nitw.ac.in"
                                                            style={{ color: "#808080",fontSize:"13px" }}>gvramana@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16897"
                                                target="_blank"> <img src="CIVDeptAssets/images/35.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16897"
                                                    target="_blank">
                                                    <h6>Prof. Sumanth Chinthala</h6> <span> Treasurer CEA </span>
                                                    <hr />
                                                </a>
                                                    <p> <i className="fa fa-phone" aria-hidden="true"></i> 9278275215 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:sumanthchinthala@nitw.ac.in"
                                                            style={{ color: "#808080",fontSize:"13px" }}>sumanthchinthala@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div>




                                        <header className="mb-0 mt-2"><h4>General Secretaries</h4></header>

                                        <ul>
                                        <li>Ashritha Gurram</li>
                                        <li>Suryansh Vikram Singh</li>
                                        </ul>
                                                   

                                      



                                       

                                        


                                        <header className="mb-0 mt-4"><h4>Secretaries</h4></header>

                                        <ul>
                                        <li>Aakash Golconda</li>
                                        <li>Lavanya Gujjula</li>
                                        <li>Navin Jagyasi</li>
                                        <li>Navya Modepalli</li>
                                        </ul>


                                    

                                        <header className="mb-0 mt-4"><h4>Additional Secretaries</h4></header>

                                        <ul>
                                        <li>Jahnavi Chinnala</li>
                                        <li>Manish</li>
                                        <li>Pamarthi Sahith </li>
                                        <li>Pidugu Shaswath</li>
                                        <li>Pinninti Preethi</li>
                                        <li>Piyush Kumar Gupta</li>
                                        <li>Piyush Yadav</li>
                                        <li>Shaik Saleem</li>
                                        </ul>






                                        <header className="mb-0 mt-4"><h4>Joint Secretaries</h4></header>
                                        <ul>
                                        <li>Akshaya Reddy</li>
                                        <li>BVNS Ram Kumar </li>
                                        <li>Ladi Gautam</li>
                                        <li>M Lavanya </li>
                                        <li>Om Mishra</li>
                                        <li>Trisha Bellana</li>
                                        <li>Sathwik  Chandra</li>
                                        <li>Vaishnavi Dwivedi</li>
                                        </ul>



                                        <header className="mb-0 mt-4"><h4>Executive Members</h4></header>
                                        <ul>
                                        <li>Ankit Kumar</li>
                                        <li>Ayush Das </li>
                                        <li>Balraj Singh</li>
                                        <li>Banoth Vishnu </li>
                                        <li>Bharti Bagaria </li>
                                        <li>Chander Nayek </li>
                                        <li>Mukul Dutta</li>
                                        <li>Palthiya Sanjay</li>
                                        <li>Pratyush Tiwari</li>
                                        <li>Ronen</li>
                                        <li>Samiksha</li>
                                        <li>Sai Harsha Poranki </li>
                                        <li>Sai Harshith</li>
                                        <li>Sai Pavan</li>
                                        <li>Saurabh Kumar</li>
                                        <li>Simran Chawla</li>
                                        <li>Sujal Prajapati </li>
                                        <li>Tadepalli Nissy</li>
                                        <li>Tangudu Srinith</li>
                                        <li>Vikas Bhardwaj</li>
                                        </ul>




                                    </div>
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


                                    <div className="row notable-alumni">
                                        
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CIVDeptAssets/images/na1.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. K.Srinivasan</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (1978)<br />
                                                        <b>Present Position :</b> IIT Madras
                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CIVDeptAssets/images/na2.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr. A.P.V.N Sharma</h6>
                                                    <p>
                                                        <b>Present Position :</b> IAS Secretary (Retd.), Ministry of Shipping, GOI
                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na3.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. V.V.Srinivas</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (1996)<br />
                                                        <b>Present Position :</b> IISc Bangalore
                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na4.jpg  " alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr. M. Mahendar Reddy</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (1984)<br />
                                                        <b>Present Position :</b> IPS, Hyderabad Police Commissioner
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na5.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. A.U.Ravishankar</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (1981)<br />
                                                        <b>Present Position :</b> NIT Suratkal
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na6.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr. Rahul Deo</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (2016)<br />
                                                        <b>Present Position :</b> IAS, Chief Executive Officer, Zilla Panchayat, Surajpur, Chhattisgarh.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na7.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. G.Madhavai Latha</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (2001)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na8.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr. Srinidhi Anatharaman</h6>
                                                    <p>
                                                        <b>Present Position :</b> CEO at Geodesic Techniques
                                                        United State (US)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na9.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Dr.Piyush Chaunsali</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (2007)<br />
                                                        <b>Present Position :</b> IIT Madras </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na10.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr.C.J.Venugopal</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (1986)<br />
                                                        <b>Present Position :</b> Additional Chief Secretary at Government of Odisha </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na11.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Dr. Alfred Jeyakar Kalyanapu</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (2003)<br />
                                                        <b>Present Position :</b> Civil & Environmental Engineering, Tennessee Tech University, USA </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na12.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr. P.R.K. Murthy</h6>
                                                    <p>
                                                        <b>Present Position :</b> Director Projects, Mumbai Metropolitan Region Development Authority (MMRDA) </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na13.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Dr. Laishram Boeing Singh</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (2009)<br />
                                                        <b>Present Position :</b> IIT Guwahati </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na14.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr. Md.Imtiyaz Ahmed</h6>
                                                    <p>
                                                        <b>Present Position :</b> IAS/ Special Secretary, Minorities Development Department, Govt. AP. </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na15.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof.Chandan Sarangi</h6>
                                                    <p>
                                                        <b>Present Position :</b> IIT Madras </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na16.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr. Pritiraj Panigrahi</h6>
                                                    <p><b>Batch :</b> B.Tech (Civil) (2007)<br />
                                                        <b>Present Position :</b> Assistant Divisional Engineer at Indian Railways </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na17.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Mr. R.K. Jha </h6>
                                                    <p>
                                                        <b>Present Position :</b> Director, GIFT Company Ltd. </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CIVDeptAssets/images/na18.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Adwaith Gupta</h6>
                                                    <p>
                                                        <b>Present Position :</b> Founder and CEO of PAANDUV </p>
                                                </div>
                                            </div>
                                        </div>





                                        <header><h4>Distinguished Alumni (Transportation Division)</h4></header>





                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Prof. BN Nagaraj</h6>
                                                    <p>
                                                        <b>Present Position :</b> Retd. Director, NIT Calicut
                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Mr. R.K. Jha</h6>
                                                    <p>
                                                        <b>Present Position :</b> Reliance Industries
                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Dr. T.S. Reddy</h6>
                                                    <p>
                                                        <b>Present Position :</b> Team Leader, CTS, Hyderabad, (LASA, New Delhi)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Dr. B.P. Chandrasekhar</h6>
                                                    <p>
                                                        <b>Present Position :</b> Advisor, Technical, APRRDA, Govt. of Andhra Pradesh (Director Technical, NRRDA -Retd.)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Dr. L.R. Kadiyali</h6>
                                                    <p>
                                                        <b>Present Position :</b> LR Kadiyali Associates, New Delhi
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Dr. K.M. Badari Nath</h6>
                                                    <p>
                                                        <b>Present Position :</b> Canada

                                                    </p>
                                                </div>
                                            </div>
                                        </div>





                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Dr. Francis Christopher</h6>
                                                    <p>
                                                        <b>Present Position :</b> US Department of Transportation

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Dr. Gummada Murthy</h6>
                                                    <p>
                                                        <b>Present Position :</b> AASHTO, Washington, USA
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Mr. P.R.K. Murthy</h6>
                                                    <p>
                                                        <b>Present Position :</b> Chief Transport & Communications, MMRDA, Mumbai
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Prof. S. Naga Bhushana Rao</h6>
                                                    <p>
                                                        <b>Present Position :</b> Director (Retd.), ESCI, Hyderabad
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    {/* <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Big Data Analytics, Artificial Intelligence / Machine Learning, Data Science
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/5.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. RBV Subramanyam</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9491346969 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rbvs66@nitw.ac.in">rbvs66@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/6.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Radhakrishna</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9704988816 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:prkrishna@nitw.ac.in">prkrishna@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/11.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Ramakrishnudu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9866876842 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:trk@nitw.ac.in">trk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/14.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.V.Kadambari</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9705460461<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kvkadambari@nitw.ac.in">kvkadambari@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/22.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. M Srinivas</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8897064421<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msv@nitw.ac.in">msv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/27.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Venkateswara Rao Kagita</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 6281746931 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:venkat.kagita@nitw.ac.in">venkat.kagita@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Security</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R Padmavathy</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9381265691 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rpadma@nitw.ac.in">rpadma@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/21.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.M.Sandhya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9394468554<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msandhya@nitw.ac.in">msandhya@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/23.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Kaveti Ilaiah</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9848916272 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ilaiahkavati@nitw.ac.in">ilaiahkavati@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Networks</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Software Engineering</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/16.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Priyanka Chawla</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7982750652 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:priyankac@nitw.ac.in">priyankac@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/18.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Manjubala Bisi</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9502940360 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">manjubalabisi@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/29.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sangharatna Godboley</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7013306805<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:cse_hod@nitw.ac.in">sanghu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Cloud & Distributed Computing, IOT</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/8.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ch Sudhakar</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440647945 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:chapram@nitw.ac.in">chapram@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/25.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sanjaya Kumar Panda</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9861126947<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sanjay@nitw.ac.in">sanjay@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Algorithms, BioInformatics, Computer Vision, Image Processing, Model-driven
                                                    Frameworks, Soft Computing</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/12.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raju Bhukya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9700553922 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raju@nitw.ac.in">raju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/15.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P V Subba Reddy</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8790590436<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsr@nitw.ac.in">pvsr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/13.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. U S N Raju</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440149146 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:usnraju@nitw.ac.in">usnraju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/19.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ijjina Earnest Paul</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9494466490<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:iep@nitw.ac.in">iep@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/24.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sujit Das</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8536009758<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sujit.das@nitw.ac.in">sujit.das@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/28.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ramalingaswamy Cherukku</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9773827143 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rmlswamy@nitw.ac.in">rmlswamy@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">

<div className="row">

<div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Rathish Kumar Pancharathi</h6>

                            <ul className="awards-ul">
                                <li>Appreciation Certificate for Excellent Teaching in NIT Warangal at UnderGraduate Level for the Year 2022-23</li>
                                <li>Appreciation Certificate for securing Significant Consultancy Grants for NIT Warangal in the Year 2022-23</li>
                                <li>Best Engineering Faculty Award presented in the year 2023, presented jointly by NIT Warangal and NITW Alumni Association in recognition of the outstanding teaching contribution in the field of Civil Engineering(For the year 2020)</li>
                            </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/12.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Venkata Reddy Keesara</h6>

                            <ul className="awards-ul">
                                <li>Selected for Teaching Staff Mobility under ERASMUS scheme (KA107) to University of Santiago De Compostela, Lugo, Spain for one week (24/04/2017 to 28/04/2017) and gave eight hours of lectures at USD, Lugo.</li>
                                <li>Best Paper Citation for our paper titled "Spatial and Temporal Variations of climate variables over a River basin", International conference on Geo-Spatial Technologies for Natural Resource Management & Climate Change, 2017, NIRDPR, Hyderabad; Authors: Naga Sowjanya. P, Venkata Reddy K. Shashi M.</li>
                                <li><b>Young Engineering Research Award -2016</b>, for the research work carried at NIT Warangal, presented by NITW and NITWAA, 2016</li>
                                <li><b>Best Paper Award</b> for paper titled <b>"Object-based and Knowledge-based classification techniques in urban areas using Hyperspectral imagery and LiDAR data "</b>, for my graduate student in  National Symposium on Recent Advances  in Remote Sensing and GIS With Special Emphasis on Mountain Ecosystems, IIRS Dehradun,7 -9, December, 2016, Authors: Prarthna Dhingra, Asfa Siddiqui, Saloni Jain, <b>K. Venkata Reddy</b> and Vinay Kumar</li>
                                <li><b>Raman Fellowship</b> for carrying out <b>post-doctoral research work</b> at Texas A&M University, USA under Indo-US 21st century knowledge initiative for six months from July 2013 to January 2014 by Government of India in May 2013</li>
                            </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>






                    <div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/19.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Arif Ali Baig Moghal</h6>

                            <ul className="awards-ul">
                                <li><b>"Reviewer of the Year 2022"</b> for Indian Geotechnical Journal. 
Recipient of <b>"IGS-Prof. A.V. Shroff Biennial Award - 2023"</b> for the paper titled <b>"Mathematical Modelling of Bioremediation of Benzene Contaminated Soil by Biopile Method"</b>, awarded during the Indian Geotechnical Conference 2023, held at IIT Roorkee on 16 Dec 2023.
</li>
<li>Listed among Stanford University's Top <b>2% Scientists in October 2023</b>. </li>
<li><b>"Springer Best Paper Award"</b> for the paper titled “Analysis of Case Studies on Landfill Failures – Lessons Learnt” for Theme – Geoenvironmental Engineering, awarded during the Indian Geotechnical Conference 2022, held at Kochi from 15 to 17 Dec 2022.</li>
<li>"Springer Best Paper Award" for the paper titled “Geotechnical Benign Characterization of Nano Amended CLS Stabilized Soil” for Theme – Soil Stabilization awarded during the Indian Geotechnical Conference 2022, held at Kochi from 15 to 17 Dec 2022.</li>
<li><b>"Reviewer of the Year 2021"</b> for Indian Geotechnical Journal. 
Listed among Stanford University's Top <b>2% Scientists in September 2022</b>. 
</li>
<li><b>"Springer Best Paper Award"</b> for the paper titled <b>“Effect of Biopolymer Inclusion and Curing Conditions on the Failure Strain and Elastic Modulus of Cohesive Soil”</b> awarded during the Indian Geotechnical Conference 2021, held at NIT Trichy from 16 to 18 Dec 2021.</li>
<li><b>"IGC 2021 Technical Team Best Performer Award"</b> during the Indian Geotechnical Conference 2021, held at NIT Trichy from 16 to 18 Dec 2021.</li>
                               </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>







                    <div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/15.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Hari Prasad Reddy P</h6>

                            <ul className="awards-ul">
                                <li><b>Young Engineer of the year 2011</b> award from Government of Andhra Pradesh and Institute of Engineers India, AP Local chapter</li>
                                <li><b>Young Engineering Researcher Award – 2017</b> from National Institute of Technology, Warangal and NITW Alumni Association</li>
                                </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/26.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. K. V. R. Ravi Shankar</h6>

                            <ul className="awards-ul">
                                <li>Young Researcher (Engineering) Award [2020] presented jointly by NIT Warangal and NITW Alumni Association </li>
                               
                                </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>







                    <div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/37.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. B Raghuram Kadali</h6>

                            <ul className="awards-ul">
                                <li>Received Best Paper Award at EASTS International Conference-2023, Sha Alam, Malaysia.</li>
                               
                                </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>





                    <div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/21.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. S. Shankar</h6>

                            <ul className="awards-ul">
                                <li>Recieved Best Paper Award at 11th International Conference on Civil Engineering and 2023 3rd Asia Environment and Resource Engineering Conference during October 20-22, 2023 in Singapore.</li>
                               
                                </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/34.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Aneetha V</h6>

                            <ul className="awards-ul">
                                <li><b>Early Career Research Award</b> from Science and Engineering Research Board (SERB), Government of India (2017)</li>
                                <li><b>Springer Best Paper Award</b> for the paper titled “Mapping the Project Complexity of Metro Rail Project using Dematel Technique” for the Advances in `Construction Materials Management Conference held at NIT Warangal December 16-17 2022</li>
                               
                                </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>







                    <div className="col-md-12 mb-3">
                      <div className="card">
                        <div className="d-flex">
                          <div>
                            <img src="CIVDeptAssets/images/22.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. M. Shashi</h6>

                            <ul className="awards-ul">
                                <li>Selected for Teaching Staff Mobility under <b>ERASMUS</b> scheme (KA107) to University of Santiago De Compostela, Lugo, Spain for one week 26-30 Nov, 2018.</li>
                               
                                </ul>
                          
                            
                            
                            <div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>





















</div>

                                </div>


                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                {/* <div className="details"> 
                                
								<h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span>
								</div> 


                                <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/> 

                                <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5"/> */}



                                
                                    
                                </div>


                               



                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </div>
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                <p>Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default CIVDeptPage;