import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import { FoundationDayData, FoundationDayDatapage } from '../constants/FoundationDayData';
import '../css/foundationDayPage.css'; // Import your custom CSS for styling

const FoundationDayPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedItem(index);
  };

  const handleMouseOut = () => {
    setSelectedItem(null);
  };

  const chiefGuest = {
    name: 'Dr. KOMAL KAPOOR',
    position: 'Chairman and Chief Executive',
    organization: 'Nuclear Fuel Complex, Hyderabad',
    image: 'https://nitw.ac.in/api/static/files/Dr._KOMAL_KAPOOR_v2_2023-10-5-12-54-11.jpg', // Replace with the actual image path
  };


  return (
    <div className="page text-center font-change-applicable">
      {PageHeader && <PageHeader dept="/FoundationDay" />}
      <div className="p-3 container" style={{ width: '100%', marginTop: '20px' }}>
        <div className="row justify-content-center">
          {FoundationDayData.map((item, index) => (
            <div
              className={`col-md-6 col-lg-4 my-3 member ${
                index === 0 ? 'first-tab' : 'other-tab'
              } ${item.name.toLowerCase()}-tab`}
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={item.link} target="_blank" rel="noreferrer">
                <div
                  className={`card custom-profile-card ${
                    selectedItem === index ? 'hovered' : ''
                  } ${item.isCircle ? 'circle-tab' : ''}`}
                >
                  <div className="circle-tab-content">
                    <img
                      src={item.image}
                      alt={`Item ${index + 1}`}
                      className={`circle-tab-image ${
                        selectedItem === index ? 'hovered' : ''
                      }`}
                    />
                   <p className={`circle-tab-label`}>
                      {item.name}
                      {index === 0 && <br /> }
                      {index === 0 && 'Chief Guest' }
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ))}


<div className="chief-guest-section">
  <div className="chief-guest-image">
    {/* Display the photo of the Chief Guest */}
    <img
      src={chiefGuest.image}
      alt={chiefGuest.name}
      style={{ width: '250px', height: '250px' }} // Set width and height inline
    />
  </div>
  <div className="chief-guest-details">
    <h2>{chiefGuest.name}</h2>
    <p>
      
        {chiefGuest.position}<br />
        {chiefGuest.organization}<br />
        will grace the occasion as the Chief Guest
      
    </p>
  </div>
</div>



</div>
  

<h4 style={{ textAlign: 'left' }}>PROGRAM SCHEDULE</h4>


        <table className="table-founddation table-bordered" style={{width: '100%', borderCollapse: 'collapse', marginTop: '20px', backgroundColor: 'white', border: '2px solid var(--border-color)'}}>
          <tbody>
          <tr>
          <th style={{ fontSize: '18px' }}>Time</th>
          <th style={{ fontSize: '18px' }}>Program Schedule</th>
            </tr>
            <tr>
              <td>10.00 AM</td>
              <td>Invocation</td>
            </tr>
            <tr>
              <td>10.05 AM</td>
              <td>Director’s Address</td>
            </tr>
            <tr>
              <td>10.15 AM</td>
              <td>Foundation Day Address by the Chief Guest</td>
            </tr>
            <tr>
              <td>10.55 AM</td>
              <td>Presentation of Awards to the Faculty</td>
            </tr>
            <tr>
              <td>11.45 AM</td>
              <td>Felicitation of the Staff and Faculty</td>
            </tr>
            <tr>
              <td>12.10 PM</td>
              <td>Presentation of Distinguished Alumni Awards</td>
            </tr>
            <tr>
              <td>12.25 PM</td>
              <td>Launching of Almashine app and Insurance</td>
            </tr>
            <tr>
              <td>12.40 PM</td>
              <td>Vote of thanks</td>
            </tr>
            <tr>
              <td>12.45 PM</td>
              <td>National Anthem</td>
            </tr>
          </tbody>
        </table>
  

{/* New section for YouTube live streaming link */}

{/* YouTube Live Streaming Section */}
<div className="youtube-section" style={{ marginTop: '40px', padding: '20px', background: '#f0f0f0' }}>
  <div className="youtube-content" style={{ display: 'flex', alignItems: 'center' }}>
    {/* Left Column */}
    <div className="left-column" style={{ flex: 1 }}>
      <h3 style={{ textAlign: 'left' }}>Live Streaming</h3>
      <p style={{ textAlign: 'left', marginBottom: '10px' }}>
        
        live streaming starts at 10:00 AM, 10<sup>th</sup> October, 2023. 
       
        <br />
        <a
          href="https://www.youtube.com/embed/RqwefYaVgsI?si=boNGlQvvnA-h06nk"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#007BFF', textDecoration: 'underline' }}
        >
          Watch Live
        </a>
        
      </p>

      <img src="https://nitw.ac.in/api/static/files/Foundation_Day_2023-10-5-11-7-46.jpg" alt="Foundation Day" width="180" height="180"></img>
    </div>
    
    {/* Right Column */}
    <div className="right-column" style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
      <div className="youtube-embed">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/daX6pgzPqhE?si=EjnshqeNeq09Gjm4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  </div>
</div>



<div className="rsvp-section" style={{ marginTop: '40px', textAlign: 'left' }}>
  <h4 style={{ textAlign: 'left' }}>RSVP</h4>
  <h5 style={{ textAlign: 'left', fontSize: '24px' }}>
    <a href="https://erp.nitw.ac.in/ext/profile/ma-dsc" target="_blank" rel="noopener noreferrer">
      Prof. D. Srinivasacharya
    </a>
  </h5>
  <p style={{  fontSize: '10px !important' }}>
    Dean, Student Welfare,<br />
    National Institute of Technology Warangal<br />
    Phone: 9490165362
  </p>
</div>





        </div>
      </div>
  
  );
};

export default FoundationDayPage;