// KeyDocuments.js
import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
import { KeyDocumentData } from '../constants/KeyDocumentsdata';
import styles from '../css/kdd.css'; // Import the CSS module

export const KeyDocuments = () => {
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedNewsletter(index);
  };

  const handleMouseOut = () => {
    setSelectedNewsletter(null);
  };

  return (
    <div className="page text-center font-change-applicable key-documents-page">
      <PageHeader dept="/KeyDocuments" />
      <div className="p-3 container" >
        <div className="row">
          {_.map(KeyDocumentData, (newsletter, index) => (
            <div
              className="col-md-3"
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={newsletter.link} target="_self" rel="noreferrer" >
              
                  <img
                    src={newsletter.image}
                    alt={newsletter.name}
                    className="mt-4"
                    style={{height: 'auto', width: '100%' }}
                  />
                
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};