export const FoundationDayData = [
    // {
    //   name: 'Padma Bhushan Dr. V. K. Saraswat',
    //   image: 'https://nitw.ac.in/api/static/files/21st_convocation_Chief_Guest_2023-7-23-13-1-55.png',
    //   link: 'https://nitw.ac.in/api/static/files/convocation_2023_2023-7-22-19-11-53.pdf'
    // },
    // {
    //     name: 'Notice',
    //     image: 'https://nitw.ac.in/api/static/files/notice_2023-7-23-16-25-31.jpg',
    //     link: 'https://www.nitw.ac.in/page/?url=/nitwConvocation2023/notice',
    //     isCircle: true
    //   },
    //   {
    //     name: 'Registration',
    //     image: 'https://nitw.ac.in/api/static/files/registration_logo_v2_2023-7-24-17-48-29.jpg',
    //     link: 'https://nitw.ac.in/registration',
    //     isCircle: true
    //   },

    //   {
    //     name: 'Notice new dated 12th Sep 2023',
    //     image: 'https://nitw.ac.in/api/static/files/NOTICE_NEW_2023-8-12-19-30-12.jpg',
    //     link: 'https://nitw.ac.in/page/?url=/convocationnew/registration',
    
    //   },

    //   {
    //     name: '',
    //     image: 'https://nitw.ac.in/api/static/files/CONVOCATION_PROGRAMME_v4_2023-8-14-12-7-58.jpg',
    //     link: 'https://nitw.ac.in/page/?url=/Convocationinvitation/Programmeschedule',
    //     isCircle: true
    
    //   },
    // Add more support centers as needed
  ];