import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
// import { DEPT_BROCHURE } from './constants/StaticDeptBrochureData';
import { DEPT_BROCHURE } from '../constants/staticDeptBrouchureData';
import '../css/DeptBrochurePage.css'; // Updated import

const DeptBrochurePage = () => {
  const [selectedDeptBrochure, setSelectedDeptBrochure] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedDeptBrochure(index);
  };

  const handleMouseOut = () => {
    setSelectedDeptBrochure(null);
  };

  return (
    <div className="dept-brochure-page">
      <PageHeader dept="/AB" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="row">
          {/* Photo with details on top */}
      
          {_.map(DEPT_BROCHURE, ({ name, link, image }, index) => ( // Updated constant name
            <div
              className="col-sm-6 col-md-3 mb-4"
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={link} target="_self" rel="noreferrer">
                <div
                  className={`card profile-card-4 ${
                    selectedDeptBrochure === index ? 'hovered' : ''
                  }`}
                >
                  <div>
                    <img src={image} alt="profile-sample1" className="rounded-image" />
                  </div>
                  {/* <div style={{ marginTop: '2px' }} className="card-content">
                    <h2 style={{ backgroundColor: '#FFBF00' }}>
                      <a href={link} target="_self" rel="noreferrer">
                        {name}
                      </a>
                    </h2>
                  </div> */}
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Additional details at the bottom */}

      {/* <div className="additional-details p-3 container">
        <div className="row">
          <div className="col-md-3">
            <h5>Contact Information:</h5>
            <p>
              <h5><a href='https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16419'>-</a></h5>
              <span className="exam-small-font">
                Professor in charge <br></br>
                National Institute of Technology, <br></br>
                Warangal- 506 004, TS, India<br></br>
                Tel:  <br></br>
                Email:
              </span>
            </p>
          </div>
     
     
        </div>
      </div> */}
    </div>
  );
};

export default DeptBrochurePage; // Updated export
