import React from 'react';
import {IMG_URL} from "../constants/TopRecruitersLogData";
import Marquee from "react-fast-marquee";
import '../css/PlacementCharts.css';

function TopRecruiters() {
  return (
    <React.Fragment>
    <div style={{paddingBottom:"20px"}}>
    <h4 className='fw-bold' style={{display:"flex",justifyContent:"center",color:"#b80924"}}>Top Recruiters</h4>
    <Marquee  pauseOnHover={true} speed={30}>
    {IMG_URL.map((item)=>{
        return(<img src={item.url} style={{width:"12vw",height:"15vh",padding:"10px"}} />)
    })}
  </Marquee>
  </div>
    </React.Fragment>
  )
}

export default TopRecruiters