import React from "react";
import "../index.css";
import { useSpeechSynthesis } from "react-speech-kit";
import "../css/removeScroll.css";
// import { announcementsDataSample } from "../constants/sampleAnnouncements";
import Blink from "react-blink-text";
import { Link } from "react-router-dom";
import "../components/rederers/css/fixes.css";
import { BaseUrl } from "../BasePath";
import { Typography } from "@mui/material";

const Announcements = (props) => {
  function compare(a, b) {
    if (a.start_date > b.start_date) {
      return -1;
    }
    if (a.start_date < b.start_date) {
      return 1;
    }
    return 0;
  }
  const title = props.title;
  const data = props.data.sort(compare);
  const { speak } = useSpeechSynthesis();
  return (
    <React.Fragment>
      <div
        className={props.fullWidth ? "col announcement-wrapper " : "announcement-wrapper col-lg-4 text-start col-sm-12"}
        style={{
          backgroundColor: "#fff",
          borderLeft: "solid",
          borderLeftColor: "#F5F7FA",
          borderLeftWidth: "1vw",
          borderTop: "solid",
          borderTopWidth: "5vh",
          borderTopColor: "#F5F7FA",
          margin: "auto",
        }}
      >
        <aside >
          <div className="offset-top-30">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h4 className="fw-bold">{title}</h4>
              <b>
                <Link to={{ pathname: `${BaseUrl}/notifications`, state: { start: props.start } }}>
                  <Blink
                    color="#060526"
                    text="Read More >>"
                    fontSize="20"
                  />
                </Link>
              </b>
            </div>
            <div className="text-subline" />
            <div
              className="text-start justify-text-class news-list"

            >
              {data.map((announcement) => {
                var newUrl = announcement.url;
                return (
                  <div className="offset-top-20">
                    <article className="widget-post">
                      <h7 className="fw-bold text-primary">
                        <a href={newUrl}>{announcement.title}</a>
                        <span
                          onClick={() =>
                            speak({
                              text: `${announcement.title}: Summary : ${announcement.summary}`,
                            })
                          }
                          className="icon mdi mdi-bullhorn text-middle icon-xs "
                        ></span>
                      </h7>
                      <div
                        className="text"
                        style={{
                          color: "black",
                          opacity: "1",
                          fontFamily: "Merriweather",
                        }}
                      >{`${announcement.summary}`}</div>
                      <p
                        className="text-dark"
                        style={{ color: "red", textAlign: "right" }}
                      >
                        {
                          <b>
                            📅{" "}
                            {announcement.start_date?.toString().substr(0, 10)}
                          </b>
                        }
                        <hr className="divider bg-madison" />
                      </p>
                    </article>
                  </div>
                );
              })}
            </div>
          </div>
        </aside>
      </div>
    </React.Fragment>
  );
};

export default Announcements;
