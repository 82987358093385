import React from "react";
import DelimiterRenderer from "./rederers/DelimiterRenderer";
import { Helmet } from "react-helmet";
import TableRenderer from "./rederers/TableRenderer";
import Output from "editorjs-react-renderer";
import ListRenderer from "./rederers/ListRenderer";
import HeaderRenderer from "./rederers/HeaderRenderer";
import ImageRenderer from "./rederers/ImageRenderer";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import "../components/Styles.css";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

const config = {
  header: {
    disableDefaultStyle: true,
  },
  image: {
    disableDefaultStyle: true,
  },
  paragraph: {
    disableDefaultStyle: true,
  },
  list: {
    disableDefaultStyle: true,
  },
  table: {
    disableDefaultStyle: true,
  },
};

const classes = {
  header: "offset-top-20",
  list: {
    container: "list list-marked",
  },
  table: {
    table: "table table-custom table-fixed bordered-table",
  },
};

const renderers = {
  header: HeaderRenderer,
  delimiter: DelimiterRenderer,
  table: TableRenderer,
  list: ListRenderer,
  image: ImageRenderer,
};
//

const EditorJSContentRenderer = (props) => {
  const data = props.data;
  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>

      <section
        className={"section text-md-start section-xl bg-default"}
        style={{ marginTop: 0, paddingTop: 0 }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-sm-10 col-xl-8 inset-xl-right-10 order-xl-1"
              style={{ width: "120%", textAlign: "justify" }}
            >
              <ThemeProvider theme={theme}>
                <Output
                  data={data}
                  classNames={classes}
                  config={config}
                  renderers={renderers}
                ></Output>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EditorJSContentRenderer;
