export default [
    {
      'title': 'Online Short-Term Training Program on Recent Trends in Smart Energy Market (AI/ML, Blockchain applications) ',
      'start': new Date(2023, 6, 3),
      'end': new Date(2023, 6, 8),
      'desc':'https://nitw.ac.in/api/static/files/AI_ML_Blockchain_applications_v2_2023-5-12-21-7-15.pdf'
    },
    {
      'title': 'International Conference on Advanced Communications and Machine Intelligence (MICA)',
      'start': new Date(2023, 9, 30),
      'allDay':true,
      'end': new Date(2023, 9, 32),
      'desc':'https://nitw.ac.in/api/static/files/MICA_2023_2023-5-8-9-56-49.pdf'
    },
    {
      'title': 'Call for registrations for Hands-on Training on Photonic devices, DFT Calculus and R&D Equipment',
      'allDay':true,
      'start': new Date(2023, 5, 22),
      'end': new Date(2023, 5, 29),
      'desc':'https://nitw.ac.in/api/static/files/Photonic_devices_DFT_Calculus_and_R&D_Equipment_2023-5-8-12-17-34.pdf '
    },
    {
      'title': 'Call for registrations for Advanced Characterization Techniques with sophisticated instruments',
      'start': new Date(2023, 5, 21),
      'end': new Date(2023, 5, 28),
      'desc':'https://nitw.ac.in/api/static/files/Material_Characterizatio_2023-5-7-13-35-23.pdf'
    },
    // {
    //   'title': 'Call for registrations for Advanced Analytical Testing for Material Characterization',
    //   'start': new Date(2023, 5, 22),
    //   'end': new Date(2023, 5, 29),
    //   'desc':'https://nitw.ac.in/api/static/files/Material_Characterizatio_2023-5-7-13-35-23.pdf'
    // },
  ]
  