import React from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../components/PageHeader";
import { useLocation } from "react-router";
import queryString from "query-string";
import GalleryCard from "../components/GalleryCard";
import { MainServerUrl } from "../BasePath";

const GalleryPage = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  if (params.dept === undefined) {
    params.dept = "/mathematics";
  }

  const galleryRenderer = [];
  const galleryData = [
    {
      key: 1,
      image: `${MainServerUrl}/static/files/cse2_2022-0-5-16-13-24.jpg`,
      caption: "CSE labs",
      datetime: "2020-01-01 10:10:10",
    },
    {
      key: 2,
      image: `${MainServerUrl}/static/files/cse_2022-0-5-16-11-0.jpg`,
      caption: "Innovation garage",
      datetime: "2020-01-01 10:10:10",
    },
    {
      key: 3,
      image: `${MainServerUrl}/static/files/CSE_Department_2021-10-14-10-2-30.jpg`,
      caption: "CSE Department",
      datetime: "2020-01-01 10:10:10",
    },
    {
      key: 4,
      image: `${MainServerUrl}/static/files/image2_2022-0-5-16-24-58.jpg`,
      caption: "Library",
      datetime: "2020-01-01 10:10:10",
    },
    {
      key: 5,
      image: `${MainServerUrl}/static/files/image_2022-0-5-16-24-14.jpg`,
      caption: "This is a test caption",
      datetime: "2020-01-01 10:10:10",
    },
    // {
    //   key: 6,
    //   image:
    //     "https://www.iucn.org/sites/dev/files/content/images/2020/shutterstock_1458128810.jpg",
    //   caption: "This is a test caption",
    //   datetime: "2020-01-01 10:10:10",
    // },
  ];

  for (var item of galleryData) {
    const dateTime = new Date(item.datetime).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    galleryRenderer.push(
      <GalleryCard
        key={item.key}
        caption={item.caption}
        image={item.image}
        time={dateTime}
      />
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>
      <div className="page text-center font-change-applicable">
        <PageHeader dept={params.dept}></PageHeader>
        <div style={{ width: "95%", margin: "auto", marginTop: "2rem" }}>
          <div className="row" style={{ justifyContent: "center" }}>
            {galleryRenderer}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GalleryPage;
