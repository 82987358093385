const imagesData = [

    {
    url: "https://nitw.ac.in/media/files/placement-slideshow-1.jpg"
  },
  
  {
    url: "https://nitw.ac.in/media/files/placement-slideshow-2.jpg"
  },
  
  {
    url: "https://nitw.ac.in/media/files/placement-slideshow-3.jpg"
  },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-4.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-5.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-6.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-7.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-8.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-9.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-10.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-11.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-12.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-13.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-14.jpg"
  // },
  // {
  //   url: "https://nitw.ac.in/media/files/placement-slideshow-15.jpg"
  // },
  
  
  ];

  export default imagesData;