export const IFData = [


    {
        name: 'Institute Health Centre ',
        image: 'https://nitw.ac.in/api/static/files/ihc_2024-5-10-16-27-0.jpg',
        link: 'https://www.nitw.ac.in/ihc'
      },


// 2
      {
        name: 'Guest House ',
        image: 'https://nitw.ac.in/api/static/files/GH_2024-5-10-11-11-6.jpg',
        link: 'https://nitw.ac.in/page/?url=/guesthouse/2021'
      },


   // 3 
      {
        name: 'Post Office ',
        image: 'https://nitw.ac.in/api/static/files/PO_2024-5-10-11-12-1.jpg',
        link: 'https://www.nitw.ac.in/page/?url=/NITWpo/PostOffice'
      },

// 4

      {
        name: 'Bank & ATM ',
        image: 'https://nitw.ac.in/api/static/files/ATM_2024-5-10-11-13-24.jpg',
        link: 'https://www.nitw.ac.in/page/?url=/NITWATM/campus'
      },

// 5
      {
        name: 'Motor Transport ',
        // image: 'https://nitw.ac.in/api/static/files/MT_SECTION_2024-5-10-16-21-58.jpg',
        image: 'https://nitw.ac.in/api/static/files/MT_Section_icon_2024-5-25-10-53-6.jpg',
        link: 'https://nitw.ac.in/page/?url=/facilities/transport'
      },
      {
        name: 'Engineering and Maintenance Unit (E & MU)',
        image: 'https://nitw.ac.in/api/static/files/E&MU_2024-5-17-18-21-23.jpg',
        link: 'https://nitw.ac.in/page/?url=/emu/2021'
      },
    // Your newsletter data here
  ];
  

