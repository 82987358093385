import React, { useState } from 'react';
import _ from 'lodash';

import PageHeader from '../components/PageHeader';

import { Padding, StarOutlineSharp } from "@mui/icons-material";
// import { useState } from "react";
import '../css/deansw.css'; // Update the CSS file name
// import React from 'react';
// import PageHeader from '../components/PageHeader';
// import './deansw.css'; // Update the CSS file name
// import { Padding, StarOutlineSharp } from "@mui/icons-material";
// import { useState } from "react";

const CfrPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <>

            <div class="container">

                <div class="row">
                    <div className="col-md-12">
                        <header>
                            {/* <h1 style={{ color: 'blue' }}><center>Startup</center></h1> */}
                        </header>
                    </div>
                </div>




                <div class="row">





                    <div className="col-md-12 mb-5">
    <div class="seminar-card" style={{ padding: '2%' , backgroundColor: '#e6e8e9' }}>
        <div className="row">

            <div class="col-md-3 p-4">

                <div class="title_arrow mb-3">
                    <p class="title">Coordinator</p>
                </div>

               
                <img src="https://nitw.ac.in/api/static/files/Prof._Anjaneyulu_2024-4-16-18-2-41.jpg" alt="" class="img-responsive img-circle" />

<p style={{ textAlign: 'justify' }}>
<a href="https://erp.nitw.ac.in/ext/profile/ec-anjan"> 
    <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. L. Anjaneyulu (EC)  </span>
    </a>
</p>
            </div>

            <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Centre of Excellence</p>
                </div>

                <header class="mt-3">
                    <h6 style={{ color: '#b80924' }}>Drones and Autonomous Vehicles  </h6>
                </header>
            </div>

            <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Members</p>
                </div>

        
                <a href="https://erp.nitw.ac.in/ext/profile/ec-patri">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}> Prof. P. Sreehari Rao (EC)</h5>    </a> <br></br>
                <a href="https://erp.nitw.ac.in/ext/profile/ec-satishm">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. M. Satish (EC)</h5></a> <br></br>
                <a href=" https://erp.nitw.ac.in/ext/profile/me-vasu">
                    <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. V. Vasu (ME)</h5></a> <br></br>
            </div>
        </div>
    </div>
</div>




<div className="col-md-12 mb-5">
    <div class="seminar-card" style={{ padding: '2%' , backgroundColor: '#e6e8e9' }}>
        <div className="row">

            <div class="col-md-3 p-4">

                <div class="title_arrow mb-3">
                    <p class="title">Coordinator</p>
                </div>

               
                <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ee-giridhar" alt="" class="img-responsive img-circle" />

<p style={{ textAlign: 'justify' }}>
<a href="https://erp.nitw.ac.in/ext/profile/ee-giridhar"> 
    <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. A. V. Giridhar (EE)  </span>
    </a>
</p>
            </div>

            <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Centre of Excellence</p>
                </div>

                <header class="mt-3">
                    <h6 style={{ color: '#b80924' }}>Sustainable Hydrogen and Fuel Cells </h6>
                </header>
            </div>

            <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Members</p>
                </div>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-blnraju">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>  Prof. B. L. Narasimharaju (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/me-gns">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. G. Naga Srinivasulu (ME)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ch-shirish">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Shirish H Sonawane (CH)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ch-pvsuresh">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. P. V. Suresh (CH)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ch-ramsagar">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. V. Ramsagar (CH)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ch-manohar">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Manohar Kakunuri (CH)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/mm-sreedeviv">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. V. Sreedevi (MM)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ch-murali">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. S. Murali Mohan (CH)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/bt-ashishp">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Ashish A Prabhu (BT)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Surajbhan Sevda (BT)</h5></a> <br></br>


            </div>
        </div>
    </div>
</div>







<div className="col-md-12 mb-5">
    <div class="seminar-card" style={{ padding: '2%' , backgroundColor: '#e6e8e9' }}>
        <div className="row">

            <div class="col-md-3 p-4">

                <div class="title_arrow mb-3">
                    <p class="title">Coordinator</p>
                </div>

               
                <img src="https://nitw.ac.in/api/static/files/Prof._Abdul_Azeem_2024-5-17-17-9-45.jpg" alt="" class="img-responsive img-circle" />

<p style={{ textAlign: 'justify' }}>
<a href="https://erp.nitw.ac.in/ext/profile/ph-drazeem2002"> 
    <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. Abdul Azeem (PH)  </span>
    </a>
</p>
            </div>

            <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Centre of Excellence</p>
                </div>

                <header class="mt-3">
                    <h6 style={{ color: '#b80924' }}>Glass Science and Technology  </h6>
                </header>
            </div>

            <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Members</p>
                </div>

                <a href="https://erp.nitw.ac.in/ext/profile/ph-syamprasad">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. P. Syam Prasad (PH)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/me-raghavendra.gujjala">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. G. Raghavendra (ME)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ma-abenerji">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. A. Benerji Babu (MA)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/me-satishben">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. B. Satish Ben (ME)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/bt-ps">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. S. Prakash (BT)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ph-tvraokmm">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. T. Venkatappa Rao (PH)</h5></a> <br></br>
            </div>
        </div>
    </div>
</div>




<div className="col-md-12 mb-5">
    <div class="seminar-card" style={{ padding: '2%' , backgroundColor: '#e6e8e9' }}>
        <div className="row">

            <div class="col-md-3 p-4">

                <div class="title_arrow mb-3">
                    <p class="title">Coordinator</p>
                </div>

               
                <img src="https://nitw.ac.in/api/static/files/Prof._V._T._Somasekhar_2024-5-17-17-10-36.jpg" alt="" class="img-responsive img-circle" />

<p style={{ textAlign: 'justify' }}>
<a href="https://erp.nitw.ac.in/ext/profile/ee-sekhar"> 
    <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. V. T. Somasekhar (EE)  </span>
    </a>
</p>
            </div>

            <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Centre of Excellence</p>
                </div>

                <header class="mt-3">
                    <h6 style={{ color: '#b80924' }}>E Mobility and Smart Grid  </h6>
                </header>
            </div>

            <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Members</p>
                </div>
                <a href="https://erp.nitw.ac.in/ext/profile/ee-dvss">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. D. V. S. S. Siva Sarma (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-sailaja">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. M. Sailaja Kumari (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-ssr">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. S. Srinivasa Rao (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ch-pvsuresh">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. P. V. Suresh (CH)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/me-gns">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. G. Naga Srinivasulu (ME)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/me-vasu">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. V. Vasu (ME)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ec-patri">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. P. Sreehari Rao (EC) </h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-rkrishan">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Ram Krishan (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-gsivakumar">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. G. Siva Kumar (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-srinudee">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. D Sreenivasarao (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-vinaykumar">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. T. Vinay Kumar (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-kiruba81">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. A. Kirubakaran (EE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-mnalam">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Mahamad Nabab Alam (EE)</h5></a> <br></br>
            </div>
        </div>
    </div>
</div>






<div className="col-md-12 mb-5">
    <div class="seminar-card" style={{ padding: '2%' , backgroundColor: '#e6e8e9' }}>
        <div className="row">

            <div class="col-md-3 p-4">

                <div class="title_arrow mb-3">
                    <p class="title">Coordinator</p>
                </div>

               
                <img src="https://nitw.ac.in/api/static/files/Prof._Vasu_2024-5-17-17-11-40.jpg" alt="" class="img-responsive img-circle" />

<p style={{ textAlign: 'justify' }}>
<a href="https://erp.nitw.ac.in/ext/profile/me-vasu"> 
    <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. Vasu (ME)  </span>
    </a>
</p>
            </div>

            <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Centre of Excellence</p>
                </div>

                <header class="mt-3">
                    <h6 style={{ color: '#b80924' }}>VR and AR for Digital Manufacturing </h6>
                </header>
            </div>

            <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Members</p>
                </div>

                <a href="https://erp.nitw.ac.in/ext/profile/ec-anjan">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}> Prof. L. Anjaneyulu (EC)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/bt-slrath">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Soumya Lipsa Rath (BT)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-ssr">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. S. Srinivasa Rao (EE)</h5></a> 

                <a href="https://erp.nitw.ac.in/ext/profile/ec-ravikumar">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Ravi Kumar J(EC)</h5> </a><br></br>
            </div>
        </div>
    </div>
</div>









<div className="col-md-12 mb-5">
    <div class="seminar-card" style={{ padding: '2%' , backgroundColor: '#e6e8e9' }}>
        <div className="row">

            <div class="col-md-3 p-4">

                <div class="title_arrow mb-3">
                    <p class="title">Coordinator</p>
                </div>

               
                <img src="https://nitw.ac.in/api/static/files/Prof._D._Vakula_2024-5-17-17-12-46.jpg" alt="" class="img-responsive img-circle" />

<p style={{ textAlign: 'justify' }}>
<a href="https://erp.nitw.ac.in/ext/profile/ec-vakula"> 
    <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. D. Vakula (EC)  </span>
    </a>
</p>
            </div>

            <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Centre of Excellence</p>
                </div>

                <header class="mt-3">
                    <h6 style={{ color: '#b80924' }}>AI and ML applications </h6>
                </header>
            </div>

            <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Members</p>
                </div>

                <a href="https://erp.nitw.ac.in/ext/profile/ec-kishoret">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}> Prof. T. Kishore Kumar (EC)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/bt-slrath">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Soumya Lipsa Rath (BT)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/cs-venkat.kagita">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Venkateswara Rao Kagita (CS)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ec-mdfarukh">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Mohammad Farukh Hashmi (EC)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/cs-msv">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}> Prof. Mettu Srinivas (CS)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ec-kprakash">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Prakash Kodali (EC)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/cs-trk">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. T. Ramakrishnudu (CS)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ec-g.arun">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. G. Arun Kumar (EC)</h5></a> <br></br>
                
                <a href="https://erp.nitw.ac.in/ext/profile/sm-sunitha">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Sunitha G (MS)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/bt-popomal">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. O. Perumal (BT)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ma-chramreddy">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Ch. Ram Reddy (MA)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ce-arpan">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Arpan Mehar (CE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ce-mshashi">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. M. Shashi (CE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ma-abenerji">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. A. Benerji Babu (MA)</h5></a> <br></br>


            </div>
        </div>
    </div>
</div>






<div className="col-md-12 mb-5">
    <div class="seminar-card" style={{ padding: '2%' , backgroundColor: '#e6e8e9' }}>
        <div className="row">

            <div class="col-md-3 p-4">

                <div class="title_arrow mb-3">
                    <p class="title">Coordinator</p>
                </div>

               
                <img src="https://nitw.ac.in/api/static/files/Prof._T._P._Tezeswi_2024-5-17-17-13-34.jpg" alt="" class="img-responsive img-circle" />

<p style={{ textAlign: 'justify' }}>
<a href="https://erp.nitw.ac.in/ext/profile/ce-tezeswi"> 
    <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. T. P. Tezeswi (CE)  </span>
    </a>
</p>
            </div>

            <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Centre of Excellence</p>
                </div>

                <header class="mt-3">
                    <h6 style={{ color: '#b80924' }}>IoT based condition monitoring of infrastructure </h6>
                </header>
            </div>

            <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Members</p>
                </div>

                <a href="https://erp.nitw.ac.in/ext/profile/ce-ravikvr">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. K. V. R. Ravi Shankar (CE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ce-aneetha">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Aneetha V (CE)</h5></a> <br></br>

                <a href="#">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Anitha (CE)</h5></a> <br></br>

           
                <a href="https://erp.nitw.ac.in/ext/profile/ce-vdk">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Vasudha Dattatraya Katare (CE)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/cs-rashrr">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Rashmi Ranjan Rout (CS)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/cs-sanjaya">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Sanjaya Kumar Panda (CS)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/cs-manjubalabisi">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Manjubala Bisi (CS)</h5></a> <br></br>

                <a href="https://erp.nitw.ac.in/ext/profile/ee-rkrishan">
                <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Ram Krishan (EE)</h5></a> <br></br>
           
            </div>
        </div>
    </div>
</div>













                </div>

            </div>

        </>
    );
}

export default CfrPage;