import React from "react";
import ReactHtmlParser from "react-html-parser";

const Paragraph = (props) => {
  let data = props.data;
  // console.log(data);
  let classname = undefined;
  if (props.data) {
    if (props.data.text) {
      if (props.data.text.includes("class=")) {
        const s = props.data.text.split('"');
        classname = s[3];
        // s[3] is the string with classname after parsing
        // console.log(classname);
      }
    }
  }
  //   return <p>{data?.text && ReactHtmlParser(data.text)}</p>;
  return classname ? (
    <p className={classname}>{data?.text && ReactHtmlParser(data.text)}</p>
  ) : (
    <p>{data?.text && ReactHtmlParser(data.text)}</p>
  );
};

export default Paragraph;
