import React from "react";
import "./Styles.css"

const GalleryCard = (props) => {
  return (
    <div
      className="col-sm-4 gallery-card"
      style={{
        overflow: props.overflow,
        backgroundColor: props.bgColor,
        ...props.style,
      }}
    >
      <div
        style={{
          marginTop: "1vw",
        }}
      >
        <img className="gallery-card-image" src={props.image} alt={props.caption}/>
      </div>
      <p className="gallery-card-title">{props.caption}</p>
      <p className="gallery-card-time">{props.time}</p>
    </div>
  );
};

export default GalleryCard;
