import { MainServerUrl } from "../BasePath";

export const navbarSampleData = [
  {
    is_internal: true,
    is_path: false,
    _id: "616c118315865900231f76f1",
    title: "Administration",
    priority: 1,
    dropdowns: [
      {
        is_internal: true,
        is_path: false,
        _id: "616c118315865900231f76f4",
        title: "Right To Information",
        route: "/administration/rightToInformation",
        priority: 11,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c98c38c410df627c707bd9",
        title: "Statutory Policies",
        route: "/ad/StatutoryPolicies",
        priority: 15,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c98d33c410df627c707bde",
        title: "Organization Chart",
        route: "/oc/OrganizationChart",
        priority: 6,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c98e1ec410df627c707be2",
        title: "Institute Annual Report",
        route: "/IAR/2021",
        priority: 4,
      },
      {
        is_internal: false,
        is_path: true,
        _id: "61c98e73c410df627c707be4",
        title: "Faculty Information System",
        route: "https://nitw.irins.org/",
        priority: 8,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c9905bc410df627c707be9",
        title: "Former Principals and Directors",
        route: "/FPD/2021",
        priority: 10,
      },
      {
        is_internal: false,
        is_path: true,
        _id: "61c990c6c410df627c707bec",
        title: "Chief Vigilance Officer",
        route:
          `${MainServerUrl}/static/files/Chief_Vigilance_Officer_2021-11-27-15-39-3.pdf`,
        priority: 2,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c99294c410df627c707bf6",
        title: "Former Chairman",
        route: "/fc/2021",
        priority: 6,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c99667c410df627c707c04",
        title: "Administrative Officers",
        route: "/ao",
        priority: 5,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c9a0e3c410df627c707c1c",
        title: "Academic Administration",
        route: "/aa",
        priority: 4,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61d57547026c1634dfc37e56",
        title: "Administrative Body",
        route: "/administration",
        priority: 1,
      },
    ],
    createdAt: "2021-10-17T12:05:23.130Z",
    updatedAt: "2022-01-05T10:39:03.726Z",
    __v: 0,
  },
  {
    is_internal: true,
    is_path: false,
    _id: "616c305ea684840023b6ef8a",
    title: "R&D",
    priority: 4,
    dropdowns: [
      {
        is_internal: true,
        is_path: false,
        _id: "616c305ea684840023b6ef8c",
        title: "Departments in Research",
        route: "/rnd/departmentInREsearch",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "616c305ea684840023b6ef8b",
        title: "Areas of Research",
        route: "/rnd/areasOfResearch",
        priority: 2,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "616c31f4a684840023b6ef91",
        title: "Research Scholars",
        route: "/rnd/phd",
        priority: 1000,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "616c3405a684840023b6ef96",
        title: "Research Centres",
        route: "/rnd/researchCentres",
        priority: 1000,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "616c3998a684840023b6ef9d",
        title: "Research Presentations",
        route: "/rnd/researchPresentations",
        priority: 1000,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "616c3a9da684840023b6efa4",
        title: "Research Publications",
        route: "/rnd/researchPublications",
        priority: 1000,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "616c3e89a684840023b6efa9",
        title: "Total Research Funds",
        route: "/rnd/totalResearchFunds",
        priority: 1000,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c98924c410df627c707bcd",
        title: "Dept wise Research Funds",
        route: "/rd",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c98997c410df627c707bd1",
        title: "R&D Downloads",
        route: "/RD2021/Downloads",
        priority: 10,
      },
    ],
    createdAt: "2021-10-17T14:17:02.251Z",
    updatedAt: "2021-12-27T09:38:31.866Z",
    __v: 0,
  },
  {
    is_internal: true,
    is_path: true,
    _id: "61c5a16f1725251ae9cf77b8",
    title: "Academics",
    priority: 2,
    dropdowns: [
      {
        is_internal: true,
        is_path: true,
        _id: "61c9a8bdc410df627c707c3d",
        title: "Examination Branch",
        route: "/eb",
        priority: 10,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61caa1983587e96828b40e8e",
        title: "Academic Programs",
        route: "/academicprgm/list",
        priority: 5,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61cadc8d3587e96828b40f0c",
        title: "Time Tables",
        route: "/TimeTable/list",
        priority: 9,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61cadf233587e96828b40f22",
        title: "Forms",
        route: "/nitwForms",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61cae0a13587e96828b40f2c",
        title: "Academic Team",
        route: "/AcademicTeam/2021",
        priority: 7,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61cae45d3587e96828b40f37",
        title: "Academic Services",
        route: "/AcademicServices/2021",
        priority: 8,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61cae4a33587e96828b40f3b",
        title: "Announcements",
        route: "/Announcements/2021",
        priority: 11,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61cae5e03587e96828b40f47",
        title: "Academic Report",
        route: "/academicReport/2021",
        priority: 12,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61cae73f87d4667dff039631",
        title: "Academic Calender",
        route: "/AcademicCalender",
        priority: 3,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61cc2988f278f591a4c86e2f",
        title: "Fee Structure",
        route: "/feestructure/2021",
        priority: 8,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61cc29e5f278f591a4c86e32",
        title: "Admissions",
        route: "/ad2021",
        priority: 3,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61cc2ad1f278f591a4c86e35",
        title: "Rules and Regulations",
        route: "/RulesandRegulations/list",
        priority: 4,
      },
    ],
    createdAt: "2021-12-24T10:31:11.220Z",
    updatedAt: "2021-12-29T09:30:57.946Z",
    __v: 0,
  },
  {
    is_internal: true,
    is_path: false,
    _id: "61f279c19294264a84609f8e",
    title: "Departments",
    priority: 3,
    dropdowns: [
      {
        is_internal: true,
        is_path: true,
        _id: "616bbddfe48fe6002357d6b4",
        title: "Electronics & Communication Engineering",
        route: "/ece",
        priority: 7,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "616bbddfe48fe6002357d6b3",
        title: "Mathematics",
        route: "/mathematics",
        priority: 9,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "616bbddfe48fe6002357d6b2",
        title: "Mechanical Engineering",
        route: "/me",
        priority: 10,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "616c2ab3a684840023b6ef78",
        title: "Metallurgical & Materials Engineering",
        route: "/mme",
        priority: 11,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "616c2b07a684840023b6ef7b",
        title: "Physical Education",
        route: "/pe",
        priority: 12,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "616c2b4ca684840023b6ef7e",
        title: "Physics",
        route: "/physics",
        priority: 13,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "616c2b73a684840023b6ef81",
        title: "School of Management ",
        route: "/sm",
        priority: 14,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "618ca1e36e250ec840a20e43",
        title: "Computer Science & Engineering",
        route: "/cs",
        priority: 5,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61a62fa3ec49cd1e4ceee801",
        title: "Biotechnology",
        route: "/bt",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61d5d5d4026c1634dfc37ea2",
        title: "Civil Engineering",
        route: "/ce",
        priority: 4,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61d5d622026c1634dfc37ea4",
        title: " Electrical Engineering",
        route: "/eee",
        priority: 6,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61d5d68f026c1634dfc37ea6",
        title: "Humanities & Social Science",
        route: "/hss",
        priority: 8,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61e3ebd184b50076806c562a",
        title: "Chemical Engineering",
        route: "/che",
        priority: 2,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61e3ec4284b50076806c562d",
        title: "Chemistry",
        route: "/cy",
        priority: 3,
      },
    ],
    createdAt: "2021-10-17T06:08:31.487Z",
    updatedAt: "2022-01-16T09:58:26.413Z",
    __v: 0,
  },
  {
    is_internal: true,
    is_path: false,
    _id: "61f279d29294264a84609f8f",
    title: "Students & Facilities",
    priority: 5,
    dropdowns: [
      {
        is_internal: true,
        is_path: false,
        _id: "618cb3696e250ec840a20e46",
        title: "Bank & ATM",
        route: "/facilities/bank-atm",
        priority: 12,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "618cdcfd6310bc5b10af9d56",
        title: "Canteen",
        route: "/facilities/canteen",
        priority: 4,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "618cdd6a601b47c867b52197",
        title: "Library",
        route: "/facilities/library",
        priority: 2,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "618cdd7c8c1c795b0fc1651d",
        title: "Post Office",
        route: "/facilities/postoffice",
        priority: 11,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "618cde50e80eacc88579dcc5",
        title: "Shopping Complex",
        route: "/facilities/shopping-complex",
        priority: 13,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "618cde9f48df11c83028d569",
        title: "Transport",
        route: "/facilities/transport",
        priority: 14,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c564271725251ae9cf775a",
        title: "Centres",
        route: "/centres",
        priority: 0,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c565f11725251ae9cf7761",
        title: "E&MU",
        route: "/emu/2021",
        priority: 15,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c569451725251ae9cf7772",
        title: "Guest House",
        route: "/guesthouse/2021",
        priority: 5,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c56a6a1725251ae9cf7776",
        title: "Hostel",
        route: "/hostel",
        priority: 3,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c56bf01725251ae9cf777e",
        title: "Institute Health Center",
        route: "/ihc",
        priority: 6,
      },
      {
        is_internal: false,
        is_path: true,
        _id: "61c56cf31725251ae9cf7782",
        title: "TEQIP-III",
        route: "https://nitw.ac.in/teqip/",
        priority: 10,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c59c441725251ae9cf779f",
        title: "Student Welfare",
        route: "/sw",
        priority: 7,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c5a1211725251ae9cf77b6",
        title: "Student Council",
        route: "/sc",
        priority: 8,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61cd46669d2ad79a09e20c47",
        title: "Placements",
        route: "/placement",
        priority: 9,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61e5ad8333f26ef1e95cf369",
        title: "Transcripts",
        route: "/eb/Transcripts",
        priority: 1,
      },
    ],
    createdAt: "2021-11-04T09:17:39.996Z",
    updatedAt: "2022-01-17T17:55:15.615Z",
    __v: 0,
  },
  {
    is_internal: true,
    is_path: false,
    _id: "61f279dc9294264a84609f90",
    title: "Portals",
    priority: 6,
    dropdowns: [
      {
        is_internal: false,
        is_path: false,
        _id: "616c0c8915865900231f76de",
        title: "TLC",
        route: "/tlc",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61a5ca475de3c249aaffee6a",
        title: "Purchase SEC",
        route: "/Portals",
        priority: 1,
      },
      {
        is_internal: false,
        is_path: true,
        _id: "61af0179ca323d49ccd5953e",
        title: "RAA",
        route: "https://www.nitw.ac.in/raa/",
        priority: 10,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61af029bbdd72d49c67024fe",
        title: "DST-VP-SCOPE",
        route: "/DSTVPSCOPE",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: false,
        _id: "61c556a21725251ae9cf771f",
        title: "NAD",
        route: "/NAD/2021",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61c95087f99de5365a9b0288",
        title: "CCE",
        route: "/cce",
        priority: 15,
      },
      {
        is_internal: false,
        is_path: true,
        _id: "61cd40e69d2ad79a09e20c27",
        title: "ALUMNI Portal",
        route: "https://www.nitwaa.in/",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61cd43e39d2ad79a09e20c3d",
        title: "E-Tender",
        route: " /E-Tender",
        priority: 1,
      },
      {
        is_internal: true,
        is_path: true,
        _id: "61d53b96752f2720939b5179",
        title: "NIRF",
        route: "/NIRF",
        priority: 4,
      },
    ],
    createdAt: "2021-10-17T11:44:09.374Z",
    updatedAt: "2022-01-20T16:29:02.582Z",
    __v: 0,
  },
];
