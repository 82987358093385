export const PicData =[
    {
        in_charge_type:'',
        incharge_name:'Guest House',
        emp_name:'Prof. Sanjit Biswas',
        emp_email:'sbiswas@nitw.ac.in',
        emp_phone_no:'8130662889',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-sbiswas',
    },
    {
        in_charge_type:'',
        incharge_name:'Security',
        emp_name:'Prof. Ravi Kumar Jatoth',
        emp_email:'ravikumar@nitw.ac.in',
        emp_phone_no:'8332969363',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ec-ravikumar',
    },
    {
        in_charge_type:'',
        incharge_name:'Electrical maintenance',
        emp_name:'Prof. B L Narasimharaju',
        emp_email:'blnraju@nitw.ac.in',
        emp_phone_no:'8332969294',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ee-blnraju',
    },
    {
        in_charge_type:'',
        incharge_name:'CCPD',
        emp_name:'Prof. V Hari Kumar',
        emp_email:'harikumar@nitw.ac.in',
        emp_phone_no:'898592910',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-harikumar',
    },
    {
        in_charge_type:'',
        incharge_name:'Coodinator (Exam)',
        emp_name:'Prof. Kota Naga Srinivasarao Batta',
        emp_email:'srinu.bkn@nitw.ac.in',
        emp_phone_no:'9800296596',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ec-srinu.bkn',
    },
    {
        in_charge_type:'',
        incharge_name:'Coodinator Center of Excellence(Digital Man and Automation)',
        emp_name:'Prof. Adepu Kumar',
        emp_email:'adepu_kumar7@nitw.ac.in',
        emp_phone_no:'9492783067',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-adepu_kumar7',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Media Relations/Public Relations Officer (PRO)',
        emp_name:'Prof. T. Rahul',
        emp_email:'rahult@nitw.ac.in',
        emp_phone_no:'8978264848',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/sm-rahult',
    },
    {
        in_charge_type:'',
        incharge_name:'NSS Program officer cum Co-ordinator',
        emp_name:'Prof. G. Siva Kumar',
        emp_email:'gsivakumar@nitw.ac.in',
        emp_phone_no:'7702716890',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ee-gsivakumar',
    },
    {
        in_charge_type:'',
        incharge_name:'NSS Program officer',
        emp_name:'Dr. K. Uday Kumar',
        emp_email:'kanapuram.udaykumar@nitw.ac.in',
        emp_phone_no:'9940107507',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ph-kanapuram.udaykumar',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Nodal Officer, Unnat Bharat Abhiyan ',
        emp_name:'Dr. M. Heeralal',
        emp_email:'mhl@nitw.ac.in ',
        emp_phone_no:'9052066114',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-mhl',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'NITW SIEMENS Centre of Excellence ',
        emp_name:'Prof. L. Krishnanand',
        emp_email:'siemenscoe_head@nitw.ac.in',
        emp_phone_no:'',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-lanka',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Air Conditioning',
        emp_name:'Prof. Prasanth Anand Kumar Lam',
        emp_email:'prasanth_anand@nitw.ac.in',
        emp_phone_no:'9840845194',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-prasanth_anand',
    },
    {
        in_charge_type:'',
        incharge_name:' NCC Officer ',
        emp_name:'Prof. Sudheer Kumar Yamsani',
        emp_email:'skyamsani@nitw.ac.in ',
        emp_phone_no:'8486367769',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-skyamsani',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Motor Transport Section ',
        emp_name:'Prof. T. Sadasiva Rao',
        emp_email:'sadasiv@nitw.ac.in',
        emp_phone_no:'9440035300',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-sadasiv',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Audio Visual Section, Seminars Halls, Old Auditorium and ALC',
        emp_name:'Prof. L. Anjaneyulu',
        emp_email:'anjan@nitw.ac.in',
        emp_phone_no:'8332969355',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ec-anjan',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Common Class Rooms and Facilities',
        emp_name:'Prof. B. Lakshmi',
        emp_email:'lakshmi@nitw.ac.in',
        emp_phone_no:'9493436845',
        emp_image:'',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ec-lakshmi',
    },
    // {
    //     in_charge_type:'',
    //     incharge_name:'',
    //     emp_name:'',
    //     emp_email:'',
    //     emp_phone_no:'',
    //     emp_image:'',
    //     emp_page_link:'',
    // },
]