// Pages/IF.js
import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
import { IFData } from '../constants/IFData';
import '../css/kdd.css'; // Import the CSS file

export const IF = () => {
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedNewsletter(index);
  };

  const handleMouseOut = () => {
    setSelectedNewsletter(null);
  };

  return (
    <div className="page text-center font-change-applicable key-documents-page">
      <PageHeader dept="/IF" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="row">
          {_.map(IFData, (newsletter, index) => (
            <div
              className="p-1 col-sm-12 col-md-3 col-lg-3 member"
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={newsletter.link} target="_self" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <div className={`card ${selectedNewsletter === index ? 'hovered' : ''}`}>
                  <img
                    src={newsletter.image}
                    alt={newsletter.name}
                    className="rounded-image"
                    style={{ maxWidth: '100%', height: 'auto', maxHeight: '200px', width: 'auto' }}
                  />
                  <div className="card-body">
                    <h4 className="card-title" style={{fontSize:'15px'}}>{newsletter.name}</h4>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};