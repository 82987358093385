import { MainServerUrl } from "../BasePath";

export const IMG_URL=[
    {
        url: `${MainServerUrl}/media/files/uber.png`,
        text: "",
    },
    {
        url: `${MainServerUrl}/media/files/microsoft.png`,
        text: "",
    },
    {
        url: `${MainServerUrl}/media/files/de_shaw_and_co.png`,
        text: "",
    },
    {
        url: `${MainServerUrl}/media/files/salesforce.png`,
        text: "",
    },
    {
        url: `${MainServerUrl}/media/files/webtec_Top_Recruiters_2023-6-31-13-20-43.png`,
        text: "",
      },
      {
        url: `${MainServerUrl}/media/files/bny_mellon_Our_Recruiters_2023-6-31-13-16-56.png`,
        text: "",
      },
      {
        url: `${MainServerUrl}/media/files/master_card_Our_Recruiters_2023-6-31-13-18-21.png`,
        text: "",
      },
      {
        url: `${MainServerUrl}/media/files/oracle_Our_Recruiters_2023-6-31-13-19-19.png`,
        text: "",
      },
      {
        url: `${MainServerUrl}/media/files/thoughtspot_Our_Recruiters_2023-6-31-13-20-15.png`,
        text: "",
      },
]