export const placement2020 =
{
	"Btech": [
                {
                    "branch_name": "Biotechnology",
                    "total_students": "100",
                    "placed": "70",
                    "maximum_CTC": "25",
                    "avg_CTC": "10"
                },
                {
                    "branch_name": "Civil",
					"total_students": "140",
					"placed": "130",
					"maximum_CTC": "52",
					"avg_CTC": "20"
                },
                {
                    "branch_name": "Chemical",
					"total_students": "140",
					"placed": "130",
					"maximum_CTC": "52",
					"avg_CTC": "20"
                },
				{
					"branch_name": "CSE",
					"total_students": "144",
					"placed": "140",
					"maximum_CTC": "52",
					"avg_CTC": "25"
				},
				{
					"branch_name": "ECE",
					"total_students": "140",
					"placed": "130",
					"maximum_CTC": "52",
					"avg_CTC": "20"
				},
				{
					"branch_name": "EEE",
					"total_students": "140",
					"placed": "130",
					"maximum_CTC": "52",
					"avg_CTC": "20"
				},
				{
					"branch_name": "Mechanical",
					"total_students": "140",
					"placed": "130",
					"maximum_CTC": "52",
					"avg_CTC": "20"
				}, 
                {
                    "branch_name": "Metallurgy",
					"total_students": "140",
					"placed": "130",
					"maximum_CTC": "52",
					"avg_CTC": "20"
                },
			],
    "Mtech Civil": [
        {
            "branch_name": "Water Resource Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Environmental Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Engineering Structures",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Geo-tech Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Transportation Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Construction Technology and Management",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Remote Sensing and GIS",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "Mtech Ch.E": [
        {
            "branch_name": "Chemical Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Process Control",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "Mtech CS & E":[
        {
            "branch_name": "Computer Science And Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "CS & Information Security",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "Mtech ECE": [
        {
            "branch_name": "Electronic Instrumentation",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "VLSI System Design",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Advanced Communication System",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "Mtech EEE":[
        {
            "branch_name": "Power Systems Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Power Electronics and Drives",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "Mtech Mechanical":[
        {
            "branch_name": "Thermal Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Manufacturing Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Computer Integrated Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Machine Design",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Automobile Engineering",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Materials and Systems Engineering Design",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Additive Manufacturing",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "Mtech MME":[
        {
            "branch_name": "Industrial Metallurgy",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Material Technology",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "M.C.A":[
        {
            "branch_name": "Master of Computer Applications",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "M.B.A":[
        {
            "branch_name": "Master Of Business Administration",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "M.Sc Maths":[
        {
            "branch_name": "Applied Mathematics",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Mathematics and Scientific Computing",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "M.Sc Physics": [
        {
            "branch_name": "Engineering Physics",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ],
    "M.Sc Chemistry": [
        {
            "branch_name": "Analytical Chemistry",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        },
        {
            "branch_name": "Organic Chemistry",
            "total_students": "140",
            "placed": "130",
            "maximum_CTC": "52",
            "avg_CTC": "20"
        }
    ]
}