import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./css/fixes.css";

const TableRenderer = ({ data }) => {
  if (!data) return "";

  let content = data.content || [];
  let columnNames = content.shift();
  if (!Array.isArray(content) || content.length < 1) return "";
  // console.log(data);
  return (
    <React.Fragment>
          <div>
              <div className="row">
                  <div className="col-md-10 col-xl-8">
              <table
                className="table table-custom table-dark-blue table-fixed"
                data-responsive="true"
                style={{tableLayout:"fixed"}}
              >
                <tbody>
                  <tr>
                    {columnNames.map((data) => {
                      return (
                        <th>
                          {/*<Typography fontFamily="Merriweather">*/}
                            {ReactHtmlParser(data)}
                          {/*</Typography>*/}
                        </th>
                      );
                    })}
                  </tr>
                  {content.map((row) => {
                    return (
                      <tr>
                        {row.map((data) => {
                          return (
                            <td style={{wordWrap:"break-word", overflowWrap:"break-word"}}>
                              {/*<Typography fontFamily="Merriweather">*/}
                                {ReactHtmlParser(data)}
                              {/*</Typography>*/}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
                  </div>
              </div>
          </div>
    </React.Fragment>
  );
};
export default TableRenderer;
