import '../css/NewsScroll.css';
import { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NewsScrollCard from './NewsScrollCard';
import '../css/NewsScroll.css'
// import '../css/NewScroll2.css'
import { dividerClasses } from '@mui/material';
const NewsScroll = (props) => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };



    return (
        <div className='flex'>        <h4 className="fw-bold subchildEventsPublication">{props.carouselTitle}</h4>
            <div className="text-subline"></div>
            <br />
            <Carousel
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={props.autoPlay}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={[]}
                deviceType={props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-20px"
                customTransition={'transform 1400ms'}
                centerMode={false}
                focusOnSelect={false}
            >
                {props.cardItems.map((item) => {
                    console.log(item)
                    if(item.isactive=='Yes'){
                    return (<NewsScrollCard title={item.title} content={item.content} url={item.url} image={item.image ? item.image : ""} />)}
                })}
            </Carousel>
        </div>

    );
}

export default NewsScroll;