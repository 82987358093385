import Chart from 'react-google-charts';
import {getGOstats} from "../../utils/nirfData";

function GO(props){
    return (
        <div>
            <Chart
                    width={'500px'}
                    height={'300px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['Task', 'Hours per Day'],
                        ['Percentage', getGOstats(props.data)],
                        ['Not Scored', 100 - getGOstats(props.data)],
                    ]}
                    options={{
                        title: 'Graduation Outcomes',
                        // Just add this option
                        is3D: true,
                        titleTextStyle:{
                            fontSize: 22,
                            italic: true
                        }
                    }}
                    rootProps={{ 'data-testid': '2' }}
            />
        </div>
    )
}

export default GO;