import { Allpages } from "../constants/initialPageData";

// export const intialSetup = (Allpages, navbarData) => {
//   const urlMap = new Map();
//   const pathMap = new Map();
//   Allpages.forEach((item) => {
//     var blocks = [];
//     item.blocks.forEach((block) => {
//       blocks.push(JSON.stringify(block));
//     });
//     item.blocks = blocks;
//     urlMap.set(item.url, item);
//     var arr = pathMap.get(item.path);
//     if (arr === undefined) {
//       arr = [];
//     }
//     arr.push(item);
//     pathMap.set(item.path, arr);
//   });
//   // console.log(pathMap.get('/cs'))
//   localStorage.wsdcPathMap = JSON.stringify(Array.from(pathMap.entries()));
//   localStorage.wsdcURLMap = JSON.stringify(Array.from(urlMap.entries()));
//   // localStorage.wsdcNav = JSON.stringify(navbarData)
//   // localStorage.setItem("wsdc-path-map", pathMap);
//   localStorage.setItem(
//     "wsdc-refresh-time",
//     new Date(new Date().setMinutes(new Date().getMinutes() + 1))
//   );
//   return { urlMap, pathMap };
// };

export const getMyDepartmentPages = (Department) => {
  const DepartmentArray = [];
  Allpages.forEach((item) => {
    if (item.path === Department) {
      DepartmentArray.push({
        tab_header: item.title,
        tab_content: item,
      });
    }
  });
  [0, 1].forEach((item) => {
    DepartmentArray.push({
      tab_header: "",
      tab_content: null,
    });
  });
  return DepartmentArray;
};
