import React from "react";
import "../css/table.css";
import { Typography } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";


let theme = createTheme({
    typography: {
      fontFamily: "Merriweather",
    },
  });
theme = responsiveFontSizes(theme);

function PlacementStats(props){
  return(
    <ThemeProvider theme = {theme}>
    <div class = "table_body">
        <div className="wrapper">
            <Typography variant = "h5">BTech Placement</Typography>
            <div className="placement_table">
                <div className="p_row header">
                    <div className="cell">
                        Branch Name
                    </div>
                    <div className="cell">
                        Total Students
                    </div>
                    <div className="cell">
                        Placed
                    </div>
                    <div className="cell">
                        Maximum CTC
                    </div>
                    <div className="cell">
                        Avg CTC
                    </div>
                </div>
            
                {props.data.Btech.map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}

            </div>
        
            <Typography variant = "h5">MTech Civil Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header blue">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["Mtech Civil"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
            
            </div>

            <Typography variant = "h5">MTech ECE Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["Mtech ECE"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>    

            <Typography variant = "h5">MTech EEE Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header blue">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["Mtech EEE"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>
            
            <Typography variant = "h5">MTech MME Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["Mtech MME"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>

            <Typography variant = "h5">MTech Mechanical Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header blue">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["Mtech Mechanical"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>
            
            <Typography variant = "h5">MTech Ch.E Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["Mtech Ch.E"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>

            <Typography variant = "h5">MTech CS & E Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header blue">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["Mtech CS & E"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>

            <Typography variant = "h5">M.C.A. Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["M.C.A"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>

            <Typography variant = "h5">M.B.A. Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header blue">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["M.B.A"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>

            <Typography variant = "h5">M.Sc Chemistry Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["M.Sc Chemistry"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>

            <Typography variant = "h5">M.Sc Maths Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header blue">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["M.Sc Maths"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
                
            </div>

            <Typography variant = "h5">M.Sc Physics Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header">
                <div className="cell">
                    Branch Name
                </div>
                <div className="cell">
                    Total Students
                </div>
                <div className="cell">
                    Placed
                </div>
                <div className="cell">
                    Maximum CTC
                </div>
                <div className="cell">
                    Avg CTC
                </div>
                </div>
                
                {props.data["M.Sc Physics"].map((item,i) => (
                    <div className="p_row" key={i}>
                        <div className="cell" data-title="Branch Name">
                            {item.branch_name}
                        </div>
                        <div className="cell" data-title="Total Students">
                            {item.total_students}
                        </div>
                        <div className="cell" data-title="Placed">
                            {item.placed}
                        </div>
                        <div className="cell" data-title="Maximum CTC">
                            {item.maximum_CTC}
                        </div>
                        <div className="cell" data-title="Avg CTC">
                            {item.avg_CTC}
                        </div>
                    </div> 
                ))}
            </div>

            <Typography variant = "h5">Placement Summary</Typography>
            <div className="placement_table">
                
                <div className="p_row header blue">
                <div className="cell">
                    Parameter
                </div>
                <div className="cell">
                    Data
                </div>
                </div>
                
                <div class = "p_row">
                    <div class = "cell" data-title = "parameter">
                        Placement Percentage
                    </div>
                    <div class = "cell" data-title = "data">
                        85.5%
                    </div>
                </div>

                <div class = "p_row">
                    <div class = "cell" data-title = "parameter">
                        No Of Recruiters
                    </div>
                    <div class = "cell" data-title = "data">
                        500
                    </div>
                </div>

                <div class = "p_row">
                    <div class = "cell" data-title = "parameter">
                        Highest CTC
                    </div>
                    <div class = "cell" data-title = "data">
                        54 Lakhs
                    </div>
                </div>

                <div class = "p_row">
                    <div class = "cell" data-title = "parameter">
                        Average CTC
                    </div>
                    <div class = "cell" data-title = "data">
                        20 Lakhs
                    </div>
                </div>

                <div class = "p_row">
                    <div class = "cell" data-title = "parameter">
                        Job Profiles Offered
                    </div>
                    <div class = "cell" data-title = "data">
                        104
                    </div>
                </div>   
            </div>
            
            <Typography variant = "h5">Specialization-Wise Placement</Typography>
            <div className="placement_table">
                
                <div className="p_row header">
                    <div className="cell">
                        Course
                    </div>
                    <div className="cell">
                        Placement Percentage
                    </div>
                </div>

                <div className= "p_row">
                    <div class = "cell" data-title = "Course">
                        B.Tech
                    </div>
                    <div class = "cell" data-title = "Placement Percentage">
                        85.5%
                    </div>
                </div>

                <div className= "p_row">
                    <div class = "cell" data-title = "Course">
                        M.Tech
                    </div>
                    <div class = "cell" data-title = "Placement Percentage">
                        50%
                    </div>
                </div>

                <div className= "p_row">
                    <div class = "cell" data-title = "Course">
                        M.C.A.
                    </div>
                    <div class = "cell" data-title = "Placement Percentage">
                        70%
                    </div>
                </div>

                <div className= "p_row">
                    <div class = "cell" data-title = "Course">
                        M.B.A.
                    </div>
                    <div class = "cell" data-title = "Placement Percentage">
                        58%
                    </div>
                </div>

                <div className= "p_row">
                    <div class = "cell" data-title = "Course">
                        M.Sc
                    </div>
                    <div class = "cell" data-title = "Placement Percentage">
                        60%
                    </div>
                </div>
            </div>
        </div>
    </div>

</ThemeProvider>
  )
}

export default PlacementStats;
