import { Grid, Typography } from "@mui/material";
import React from "react";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { LatestResearchData } from "../constants/ResearchData";
// const IMG_URL =
//   "https://previews.123rf.com/images/damianpalus/damianpalus1305/damianpalus130500113/19847479-newspaper-stack-on-white-background.jpg";

const bgurl = process.env.PUBLIC_URL + "/images/research2.jpg";
let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});

theme = responsiveFontSizes(theme);
const Research = () => {
  return (
    <Grid
      style={{
        backgroundColor: "#F5F7FA",
        backgroundImage: `url("${bgurl}")`,
        backgroundSize: "cover",
      }}
    >
      <Grid height="2vh"></Grid>
      <div style={{ width: "90vw", margin: "auto" }}>
        <ThemeProvider theme={theme}>
          <Typography
            variant="h3"
            fontWeight="bold"
            margin="auto"
            textAlign="center"
            color="white"
            paddingTop="3vh"
          >
            Research Updates
          </Typography>
          <hr className="divider bg-red" />
        </ThemeProvider>

        <Carousel
          plugins={[
            "arrows",
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 3,
              },
            },
          ]}
          breakpoints={{
            640: {
              plugins: [
                "arrows",
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 1,
                  },
                },
              ],
            },
            900: {
              plugins: [
                "arrows",
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 2,
                  },
                },
              ],
            },
          }}
        >
          {LatestResearchData.map((item) => {
            return (
              <Grid
                container
                direction="column"
                alignContent="center"
                margin="5vh"
              >
                <a href={item.link}>
                  <img src={item.img} alt="research" />
                </a>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h5"
                    fontWeight="bolder"
                    textAlign="center"
                    color="white"
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="p" textAlign="center" color="white">
                    {item.subtitle}
                  </Typography>
                </ThemeProvider>
              </Grid>
            );
          })}
        </Carousel>
      </div>
    </Grid>
  );
};

export default Research;
