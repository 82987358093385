import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import Announcements from "../components/Announcements";
import PageHeader from "../components/PageHeader";
import EditorJSContentRenderer from "../components/EditorJsContentRenderer2";
import { jsdata } from "../constants/samplePath";
import { useLocation } from "react-router";
import queryString from "query-string";
import Avatar from "../components/Avatar";
// import Announcements from "../components/Announcements";
import { getFacultyPage, getHODdetails } from "../constants/DepartmentData";
import { getMyDepartmentPages } from "../utils/pagesData";
// import DeptNotifications from "../components/DeptNotifications";
// import { Button } from "@mui/material";
import { BaseUrl, MainServerUrl } from "../BasePath";
import "../css/hod.css";

const DepartmentPage = () => {
  // const [data, setData] = useState();
  // const [news, setNews] = useState([]);
  // const news = [];
  const location = useLocation();
  // const [extract, setExtract] = useState([]);
  const params = queryString.parse(location.search);
  if (params.dept === undefined) {
    params.dept = "/mathematics";
  }

  const [DeptData, setDeptData] = useState(jsdata);
  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    const deprtData = getMyDepartmentPages(params.dept);
    // if department not exists then we'll add null depts to ensure proper linking
    // actually there is a bug if no of tabs initial < later then it won't link
    for (let i = 0; i < 20; i++) {
      deprtData.push({
        tab_header: "",
        tab_content: null,
      });
    }

    const controller = new AbortController();
    let id = setTimeout(() => controller.abort(), 10000);
    setDeptData(deprtData);
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ path: params.dept }),
      signal: controller.signal,
    };
    fetch(
      `${MainServerUrl}/api/path/view`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("response is not ok");
        }
        if (response.status !== 200) {
          throw new Error("Status code is not 200");
        }
        // console.log(response.json());
        return response.json();
      })
      .then((data) => setDeptData(data))
      .catch((err) => {
        id = setTimeout(() => controller.abort(), 10000);
        requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ path: params.dept }),
          signal: controller.signal,
        };
        fetch(`${MainServerUrl}/api/path/view`, requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error("response is not ok");
            }
            if (response.status !== 200) {
              throw new Error("Status code is not 200");
            }
            return response.json();
          })
          .then((data) => setDeptData(data))
          .catch((err) => {
            window.location.href = `${BaseUrl}`;
          });
      })
      .finally(() => clearTimeout(id));

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [params.dept]);
  const HODdetails = getHODdetails(params.dept);
  const FacultyDetils = getFacultyPage(params.dept);
  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>
      <div className="page text-center font-change-applicable">
        <PageHeader dept={params.dept}></PageHeader>

        <section
          className="section section-lg bg-default"
          style={{ paddingBottom: "1rem" }}
        >
          <div className="container">
            <div className="row justify-content-sm-center">
              <div className="col-sm-10 col-xl-12">
                <div
                  className="tabs-custom tabs-vertical tabs-line"
                  id="tabs-7"
                >
                  {/*Nav tabs*/}
                  <ul className="nav nav-tabs" style={{color:"black"}}>
                    {DeptData.map((tab, index) => {
                      // console.log(tab)
                      return (
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link text-lg-right text-md-right"
                            href={`#tabs-7-${index + 1}`}
                            data-toggle="tab"
                          >
                            {tab.tab_header}
                          </a>
                        </li>
                      );
                    })}
                    {FacultyDetils !== "" && (
                      <li className="nav-item" role="presentation">
                        {/* <a
                                      className="nav-link text-lg-right text-md-right"
                                      href='https://nitw.ac.in/department/cse/faculty/'
                                      data-toggle="tab"
                                    >
                                      Faculty
                                    </a> */}
                        <Link
                          className="nav-link text-lg-right text-md-right"
                          to={FacultyDetils}
                          onClick={() => {
                            window.open(`${FacultyDetils}`, "_blank");
                          }}
                        >
                          Faculty
                        </Link>
                      </li>
                    )}
                  </ul>
                  {/*Tab panes*/}
                  <div className="tab-content inset-lg-left-60">
                    {DeptData.map((tab, index) => {
                      // console.log(index)
                      return (
                        <div
                          className="tab-pane fade"
                          id={`tabs-7-${index + 1}`}
                          style={{color:"black"}}
                        >
                          <EditorJSContentRenderer data={tab.tab_content} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {Object.keys(HODdetails).length > 0 && (
        <div>
          {/* <div
            style={{
              width: "16rem",
              margin: "auto",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              textAlign: "center",
              backgroundColor: "rgb(137, 0, 19)",
              color: "white",
              borderRadius: "1.5rem",
            }}
          >
            <Button
              // to={`/gallery/?dept=${params.dept}`}
              style={{ fontSize: "1.4rem", fontWeight: "600", color: "white" }}
              onClick={() => {
                window.location.href = `${BaseUrl}/gallery/?dept=${params.dept}`;
              }}
            >
              IMAGE GALLERY
            </Button>
          </div> */}

          <div style={{ 
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            margin: '-3.5rem 0 1.2rem 0'
           }}>
            <div
              className="col-md-4 offset-top-20"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Avatar image={`url(${HODdetails.image})`} />
                <div className="col" style={{marginLeft: '1.1rem'}}>
                  <p style={{ fontSize: "1.1rem", fontWeight: 'bold' }}>Head of the Department</p>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "1.1rem",
                    }}
                  >
                    {HODdetails.name}
                  </p>
                  <span>
                    {HODdetails.email}
                  </span>
                  <br />
                  <span>
                    {HODdetails.phone}
                  </span>
                </div>
            </div>
            {/* <div
              className="col-md-6"
              style={{ paddingRight: "3rem", paddingLeft: "3rem" }}
            >
              <DeptNotifications
                data={news || []}
                title="Announcements"
                fullWidth={true}
              ></DeptNotifications>
            </div> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DepartmentPage;







// import React, { useState, useEffect } from "react";
// import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// // import Navbar from "../components/Navbar";
// // import Footer from "../components/Footer";
// // import Announcements from "../components/Announcements";
// import PageHeader from "../components/PageHeader";
// import EditorJSContentRenderer from "../components/EditorJsContentRenderer2";
// import { jsdata } from "../constants/samplePath";
// import { useLocation } from "react-router";
// import queryString from "query-string";
// import Avatar from "../components/Avatar";
// // import Announcements from "../components/Announcements";
// import { getFacultyPage, getHODdetails } from "../constants/DepartmentData";
// import { getMyDepartmentPages } from "../utils/pagesData";
// // import DeptNotifications from "../components/DeptNotifications";
// // import { Button } from "@mui/material";
// import { BaseUrl, MainServerUrl } from "../BasePath";
// import "../css/hod.css";

// const DepartmentPage = () => {
//   // const [data, setData] = useState();
//   // const [news, setNews] = useState([]);
//   // const news = [];
//   const location = useLocation();
//   // const [extract, setExtract] = useState([]);
//   const params = queryString.parse(location.search);
//   if (params.dept === undefined) {
//     params.dept = "/mathematics";
//   }

//   const [DeptData, setDeptData] = useState(jsdata);
//   useEffect(() => {
//     // POST request using fetch inside useEffect React hook
//     const deprtData = getMyDepartmentPages(params.dept);
//     // if department not exists then we'll add null depts to ensure proper linking
//     // actually there is a bug if no of tabs initial < later then it won't link
//     for (let i = 0; i < 20; i++) {
//       deprtData.push({
//         tab_header: "",
//         tab_content: null,
//       });
//     }

//     const controller = new AbortController();
//     let id = setTimeout(() => controller.abort(), 10000);
//     setDeptData(deprtData);
//     let requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({ path: params.dept }),
//       signal: controller.signal,
//     };
//     fetch(
//       `${MainServerUrl}/api/path/view`,
//       requestOptions
//     )
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("response is not ok");
//         }
//         if (response.status !== 200) {
//           throw new Error("Status code is not 200");
//         }
//         // console.log(response.json());
//         return response.json();
//       })
//       .then((data) => setDeptData(data))
//       .catch((err) => {
//         id = setTimeout(() => controller.abort(), 10000);
//         requestOptions = {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ path: params.dept }),
//           signal: controller.signal,
//         };
//         fetch(`${MainServerUrl}/api/path/view`, requestOptions)
//           .then((response) => {
//             if (!response.ok) {
//               throw new Error("response is not ok");
//             }
//             if (response.status !== 200) {
//               throw new Error("Status code is not 200");
//             }
//             return response.json();
//           })
//           .then((data) => setDeptData(data))
//           .catch((err) => {
//             window.location.href = `${BaseUrl}`;
//           });
//       })
//       .finally(() => clearTimeout(id));

//     // empty dependency array means this effect will only run once (like componentDidMount in classes)
//   }, [params.dept]);
//   const HODdetails = getHODdetails(params.dept);
//   const FacultyDetils = getFacultyPage(params.dept);
//   return (
//     <React.Fragment>
//       <Helmet>
//         <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
//         <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
//       </Helmet>
     
//       <div className="page text-center font-change-applicable">
//   <PageHeader dept={params.dept}></PageHeader>

//   <section className="section section-lg bg-default" style={{ paddingBottom: "1rem" }}>
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-8">
//           <div className="tabs-custom tabs-vertical tabs-line" id="tabs-7">
//             {/* Nav tabs */}
//             <ul className="nav nav-tabs">
//               {DeptData.map((tab, index) => (
//                 <li className="nav-item" role="presentation">
//                   <a className="nav-link text-lg-right text-md-right" href={`#tabs-7-${index + 1}`} data-toggle="tab">
//                     {tab.tab_header}
//                   </a>
//                 </li>
//               ))}
//               {FacultyDetils !== "" && (
//                 <li className="nav-item" role="presentation">
//                   <Link className="nav-link text-lg-right text-md-right" to={FacultyDetils} onClick={() => { window.open(`${FacultyDetils}`, "_blank"); }}>
//                     Faculty
//                   </Link>
//                 </li>
//               )}
//             </ul>
//             {/* Tab panes */}
//             <div className="tab-content">
//               {DeptData.map((tab, index) => (
//                 <div className="tab-pane fade" id={`tabs-7-${index + 1}`}>
//                   <EditorJSContentRenderer data={tab.tab_content} />
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-4">
//           {Object.keys(HODdetails).length > 0 && (
//             <div className="hod-section">
//               <Avatar image={`url(${HODdetails.image})`} />
//               <div className="hod-details">
//                 <p className="hod-title">Head of the Department</p>
//                 <p className="hod-name">{HODdetails.name}</p>
//                 <p className="hod-contact">{HODdetails.email}</p>
//                 <p className="hod-contact">{HODdetails.phone}</p>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   </section>
// </div>


//       {Object.keys(HODdetails).length > 0 && (
//         <div>
//           {/* <div
//             style={{
//               width: "16rem",
//               margin: "auto",
//               paddingTop: "1rem",
//               paddingBottom: "1rem",
//               textAlign: "center",
//               backgroundColor: "rgb(137, 0, 19)",
//               color: "white",
//               borderRadius: "1.5rem",
//             }}
//           >
//             <Button
//               // to={`/gallery/?dept=${params.dept}`}
//               style={{ fontSize: "1.4rem", fontWeight: "600", color: "white" }}
//               onClick={() => {
//                 window.location.href = `${BaseUrl}/gallery/?dept=${params.dept}`;
//               }}
//             >
//               IMAGE GALLERY
//             </Button>
//           </div> */}

//           <div style={{ 
//             display: 'flex',
//             flexDirection: 'row',
//             justifyContent: 'center',
//             margin: '-3.5rem 0 1.2rem 0'
//            }}>
//             <div
//               className="col-md-4 offset-top-20"
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "center",
//               }}
//             >
             
//             </div>
//             {/* <div
//               className="col-md-6"
//               style={{ paddingRight: "3rem", paddingLeft: "3rem" }}
//             >
//               <DeptNotifications
//                 data={news || []}
//                 title="Announcements"
//                 fullWidth={true}
//               ></DeptNotifications>
//             </div> */}
//           </div>
//         </div>
//       )}
//     </React.Fragment>
//   );
// };

// export default DepartmentPage;

