export const KeyDocumentData = [

  // 1
  
  {
    name: ' ',
    image: 'KeyDocs/AnnualReports.png',
    link: 'https://nitw.ac.in/page/?url=/NITWAR/AnnualReports'
  },
  
  
  // 2
  {
    name: ' ',
    image: 'KeyDocs/SenateMinutes.png',
    link: 'https://nitw.ac.in/page/?url=/NITWSM/SenateMinutes'
  },
  
  
  // 3 
  {
    name: ' ',
    image: 'KeyDocs/MinutesOfBOG.png',
    link: 'https://nitw.ac.in/page/?url=/NITWMoB/MinutesofBoG'
  },
  
  
  
  // 4
  
  {
    name: ' ',
    image: 'KeyDocs/MinutesOfFC.png',
    link: 'https://www.nitw.ac.in/page/?url=/NITWFC/FinanceCommittee'
  },
  
  // 5
  {
    name: ' ',
    image: 'KeyDocs/MinutesOfBWC.png',
    link: 'https://www.nitw.ac.in/page/?url=/NITBWC/BuildingsandWorksCommittee'
  },
  
  //6
  
  {
  name: ' ',
  image: 'KeyDocs/ActStatutes.png',
  link: 'https://nitw.ac.in/page/?url=/NITWAandS/ActandStatutes'
  },
  
  
  //7
  
  
  {
  name: ' ',
  image: 'KeyDocs/AcademicReports.png',
  link: 'https://nitw.ac.in/page/?url=/NITWACDR/AcademicReport'
  },
  
  
  
  
      // Your newsletter data here
    ];
    