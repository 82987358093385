import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
import '../css/Deans.css';

export const HeadsData = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const Heads = [
    {
      name: "Biotechnology",
      designation: "Prof. B Rama Raju",
      phoneNumber: "9490165348",
      email: "biotech_hod@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/bt-rrb",
      link: "https://erp.nitw.ac.in/ext/profile/bt-rrb",
    },
    {
      name: "Chemical Engineering",
      designation: "Prof. Vidyasagar Shilapuram",
      phoneNumber: "9490165346",
      email: "chemical_hod@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ch-vidyasagars",
      link: "https://erp.nitw.ac.in/ext/profile/ch-vidyasagars",
    },
    {
      name: "Chemistry",
      designation: "Prof. Santhosh Penta",
      phoneNumber: "9490165352",
      email: "chemistry_hod@nitw.ac.in",
      image: "https://www.nitw.ac.in/CHEDeptAssets/images/10.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cy-spenta",
    },
    {
      name: "Civil Engineering",
      designation: "Prof. K Venkata Reddy",
      phoneNumber: "9490165341",
      email: "civil_hod@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ce-kvreddy",
      link: "hthttps://erp.nitw.ac.in/ext/profile/ce-kvreddy",
    },
    {
      name: "Computer Science and Engineering",
      designation: "Prof. R. Padmavathy",
      phoneNumber: "9490165347",
      email: "cse_hod@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16345/16345.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cs-rpadma",
    },

    {
      name: "Electrical Engineering",
      designation: "Prof. N V Srikanth",
      phoneNumber: "9490165342",
      email: "eee_hod@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Prof._Srikanth_N_V_2024-3-7-17-38-37.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ee-nvs",
    },
    {
      name: "Electronic and Communication Engineering",
      designation: "Prof. D. Vakula",
      phoneNumber: "9490165344",
      email: "ece_hod@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16303/16303.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ec-vakula",
    },
    {
      name: "Humanities & Social Sciences",
      designation: "Prof. M Raja Vishwanathan",
      phoneNumber: "9490164798",
      email: "humanities_hod@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/hs-vishwanathan",
      link: "https://erp.nitw.ac.in/ext/profile/hs-vishwanathan",
    },
    {
      name: "Management Studies",
      designation: "Prof. G Suneetha",
      phoneNumber: "9490165349",
      email: "som_hod@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/sm-sunitha",
      link: "https://erp.nitw.ac.in/ext/profile/sm-sunitha",
    },
    {
      name: "Mathematics",
      designation: "Prof. A. Benerji Babu",
      phoneNumber: " 9490165350",
      email: "maths_hod@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16397/16397.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ma-abenerji",
    },
    {
      name: "Mechanical Engineering",
      designation: "Prof. Y Ravi Kumar",
      phoneNumber: "9490165343",
      email: "mech_hod@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/me-yrk",
      link: "https://erp.nitw.ac.in/ext/profile/me-yrk",
    },

    {
      name: "Metallurgy and Materials Engineering",
      designation: "Prof. N. Kishore Babu",
      phoneNumber: "9490165345",
      email: "mme_hod@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Prof._Kishore_Babu_Nagumothu_2024-4-25-10-39-13.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/mm-kishorebabu",
    },
    {
      name: "Physics",
      designation: "Prof. Haranath Divi",
      phoneNumber: "9490165351",
      email: "physics_hod@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/harinath_2024-7-3-17-41-34.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ph-haranath",
    },
    // {
    //   name: "",
    //   designation: "",
    //   phoneNumber: "",
    //   email: "",
    //   image: "",
    //   link: "",
    // },
    // {
    //     name: "Centre for Sports Activities ",
    //     designation: "Prof. P. Ravi Kumar",
    //     phoneNumber: "8332969528",
    //     email: "ped_hod@nitw.ac.in",
    //     image: "https://wsdc.nitw.ac.in/facultynew/media/16404/16404.jpg",
    //     link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16404",
    //   },




    // Add more Heads as needed
  ];

  const HeadsofCentres = [
    {
      name: "Centre for Career Planning and Development (CCPD)",
      designation: "Prof. V Hari Kumar",
      phoneNumber: "9490165357",
      email: "ccpd_hod@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/me-harikumar",
      link: "https://erp.nitw.ac.in/ext/profile/me-harikumar",
    },
    {
      name: "Central Research Instrumentation Facility (CRIF)",
      designation: "Prof. P. Venkata Srilakshmi",
      phoneNumber: "8332969498",
      // email: "patrisrilakshmi@nitw.ac.in",
      email: "crif_head@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16381/16381.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi",
    },
    {
      name: "Centre for Digital Infrastructure and Services (CDIS)",
      designation: "Prof. Rashmi Ranjan Rout",
      phoneNumber: "8332969418",
      email: "cdis_head@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16336/16336.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cs-rashrr",
    },
    {
      name: "Centre for Training & Learning (CTL)",
      designation: "Prof. T. Kishore Kumar",
      phoneNumber: "8332969353",
      email: "ctl_hod@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16291/16291.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ec-kishoret",
    },
    {
      name: "Centre for Sports Activities ",
      designation: "Prof. P. Ravi Kumar",
      phoneNumber: "8332969528",
      email: "ped_hod@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16404/16404.jpg",
      link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16404",
    },
    // {
    //   name: "",
    //   designation: "",
    //   phoneNumber: "",
    //   email: "",
    //   image: "",
    //   link: "",
    // },
    // {
    //   name: "Sports Activity Centre",
    //   designation: "Prof. P. Ravi Kumar",
    //   phoneNumber: "8332969528",
    //   email: "ped_hod@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16404/16404.jpg",
    //   link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16404",
    // },
    // {
    //   name: "Indian Knowledge System",
    //   designation: "Prof. P. Ravi Kumar",
    //   phoneNumber: "8332969528",
    //   email: "ped_hod@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16404/16404.jpg",
    //   link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16404",
    // },


    // Add more associate deans as needed
  ];

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    setHoveredIndex(null);
  };

  const facilitiesPerColumn = Math.ceil(Heads.length / 2);
  const associateFacilitiesPerColumn = Math.ceil(HeadsofCentres.length / 2);

  return (
    <div className="page text-left font-change-applicable static-facilities">
      <PageHeader dept="/Heads" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <h2 style={{ textAlign: 'left' }}>Heads of Departments</h2>
        <div className="columns-wrapper">
          <div className="column">
            <table className="custom-table">
              <tbody>
                {Heads.slice(0, facilitiesPerColumn).map((dean, index) => (
                  <tr
                    className={`member ${dean.link === hoveredIndex ? 'hovered' : ''}`}
                    onMouseEnter={() => handleHover(dean.link)}
                    onMouseLeave={handleHoverEnd}
                    key={dean.link}
                  >
                    <td className="custom-photo-cell">
                      <a href={dean.link} target="_self" rel="noreferrer">
                        <img className="custom-circle-image" src={dean.image} alt="profile-sample1" />
                      </a>
                    </td>
                    <td className="custom-details-cell">
                      <h2>
                        <a href={dean.link} target="_self" rel="noreferrer">
                          {dean.name}
                        </a>
                      </h2>
                      <div className="details-wrapper">
                        <div className="details-row">
                          <span className="details-label">Name: </span>
                          <span className="details-value">{dean.designation}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Phone No.: </span>
                          <span className="details-value">{dean.phoneNumber}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Email: </span>
                          <span className="details-value">{dean.email}</span>
                        </div>
                        <br></br>
                        <br></br>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="column" style={{ paddingBottom: "20%"}}>
            <table className="custom-table">
              <tbody>
                {Heads.slice(facilitiesPerColumn).map((dean, index) => (
                  <tr
                    className={`member ${index + facilitiesPerColumn === hoveredIndex ? 'hovered' : ''}`}
                    onMouseEnter={() => handleHover(index + facilitiesPerColumn)}
                    onMouseLeave={handleHoverEnd}
                    key={index}
                  >
                    <td className="custom-photo-cell">
                      <a href={dean.link} target="_self" rel="noreferrer">
                        <img className="custom-circle-image" src={dean.image} alt="profile-sample1" />
                      </a>
                    </td>
                    <td className="custom-details-cell">
                      <h2>
                        <a href={dean.link} target="_self" rel="noreferrer">
                          {dean.name}
                        </a>
                      </h2>
                      {/* <br></br> */}
                      <div className="details-wrapper">

                        <div className="details-row">
                          <span className="details-label">Name: </span>
                          <span className="details-value">{dean.designation}</span>
                        </div>

                        <div className="details-row">
                          <span className="details-label">Phone No.: </span>
                          <span className="details-value">{dean.phoneNumber}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Email: </span>
                          <span className="details-value">{dean.email}</span>
                        </div>
                        <br></br>
                        <br></br>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <h2 style={{ textAlign: 'left' }}>Heads of Centres</h2>
        <div className="columns-wrapper">
          <div className="column">
            <table className="custom-table">
              <tbody>
                {HeadsofCentres.slice(0, associateFacilitiesPerColumn).map((associateDean, index) => (
                  <tr
                    className={`member ${index === hoveredIndex ? 'hovered' : ''}`}
                    onMouseEnter={() => handleHover(index)}
                    onMouseLeave={handleHoverEnd}
                    key={index}
                  >
                    <td className="custom-photo-cell">
                      <a href={associateDean.link} target="_self" rel="noreferrer">
                        <img className="custom-circle-image" src={associateDean.image} alt="profile-sample2" />
                      </a>
                    </td>
                    <td className="custom-details-cell">
                      <h2>
                        <a href={associateDean.link} target="_self" rel="noreferrer">
                          {associateDean.name}
                        </a>
                      </h2>
                      <div className="details-wrapper">
                        <div className="details-row">
                          <span className="details-label">Name: </span>
                          <span className="details-value">{associateDean.designation}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Phone No.: </span>
                          <span className="details-value">{associateDean.phoneNumber}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Email: </span>
                          <span className="details-value">{associateDean.email}</span>
                        </div>
                        <br></br>
                        <br></br>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="column" style={{ paddingBottom: "20%"}}>
            <table className="custom-table">
              <tbody>
                {HeadsofCentres.slice(associateFacilitiesPerColumn-0).map((associateDean, index) => (
                  <tr
                    className={`member ${index + associateFacilitiesPerColumn === hoveredIndex ? 'hovered' : ''}`}
                    onMouseEnter={() => handleHover(index + associateFacilitiesPerColumn)}
                    onMouseLeave={handleHoverEnd}
                    key={index}
                  >
                    <td className="custom-photo-cell">
                      <a href={associateDean.link} target="_self" rel="noreferrer">
                        <img className="custom-circle-image" src={associateDean.image} alt="profile-sample2" />
                      </a>
                    </td>
                    <td className="custom-details-cell">
                      <h2>
                        <a href={associateDean.link} target="_self" rel="noreferrer">
                          {associateDean.name}
                        </a>
                      </h2>
                      <div className="details-wrapper">
                        <div className="details-row">
                          <span className="details-label">Name: </span>
                          <span className="details-value">{associateDean.designation}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Phone No.: </span>
                          <span className="details-value">{associateDean.phoneNumber}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Email: </span>
                          <span className="details-value">{associateDean.email}</span>
                        </div>
                        <br></br>
                        <br></br>

                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadsData;