export const TEAM_MEMBERS = [
    // {
    //     name: 'Khemraj Marathe',
    //     image: 'https://s3.amazonaws.com/hr-assets/0/1660504085-5dafad1cc1-Khemraj.jpeg',
    //     linkedin: 'https://in.linkedin.com/in/khemraj-marathe-29b4b3194',
    //     github: 'https://www.github.com/Khem112',
    //     regNo: '911804',
    //     role:'Lead Developer'
    // },
    // {
    //     name: 'Harjeet Singh',
    //     image: 'https://s3.amazonaws.com/hr-assets/0/1660504000-16882df8f4-Harjeet.jpeg',
    //     linkedin: 'https://in.linkedin.com/in/harjeet-singh-7366351a4',
    //     github: 'https://www.github.com/harry-dev98',
    //     regNo: '921818',
    //     role:'Co-Lead Developer'
    // },
    // {
    //     name: 'Priyansh Maheshwari',
    //     image: 'https://s3.amazonaws.com/hr-assets/0/1660503712-c9b1e577f1-Priyansh_Maheshwari.jpg',
    //     linkedin: 'https://in.linkedin.com/in/priyanshmaheshwari',
    //     github: 'https://www.github.com/PriyanshMaheshwari',
    //     regNo: '831854',
    //     role:'Senior Developer'
    // },
    // {
    //     name: 'Bhashkarjya Nath',
    //     image: 'https://s3.amazonaws.com/hr-assets/0/1660503496-bbf13c842b-Bhashkarjya_Nath.jpg',
    //     linkedin: 'https://in.linkedin.com/in/bhashkarjya-nath-370382189',
    //     github: 'https://github.com/Bhashkarjya',
    //     regNo: '831833',
    //     role:'Senior Developer'
    // },
    // {
    //     name: 'Pradyumn Shukla',
    //     image: 'https://s3.amazonaws.com/hr-assets/0/1660503755-45833c14b1-Pradyumn_Shukla.jpg',
    //     linkedin: '',
    //     github: 'https://github.com/pradyumn263',
    //     regNo: '811876',
    //     role:'Senior Developer'
    // },
    // {
    //     name: 'Harsh Sonkusare',
    //     image: 'https://s3.amazonaws.com/hr-assets/0/1660503226-81fe6cec00-Harsh.jpg',
    //     linkedin: 'https://in.linkedin.com/in/harsh-sonkusare-6923381a7',
    //     github: 'https://www.github.com/HarshSonkusare',
    //     regNo: '921922',
    //     role:'Lead Developer'
    // },
    // {
    //     name: 'Sufiyan Ansari',
    //     image: 'https://s3.amazonaws.com/hr-assets/0/1660478814-8bc7aa5c80-Mohd_Sufiyan_Ansari.jpg',
    //     linkedin: 'https://in.linkedin.com/in/sufiyan-ansari-nitw',
    //     github: 'https://www.github.com/suffisme',
    //     regNo: '841903',
    //     role:'Co-Lead Developer'
    // },
    {
        name: 'Divya Soni',
        image: 'https://s3.amazonaws.com/hr-assets/0/1660503550-ca65dead41-Divya_Soni.jpg',
        linkedin: 'https://www.linkedin.com/in/divya-soni14/',
        github: 'https://github.com/divya-soni-14',
        regNo: '952073',
        role:'Developer'
    },
    {
        name: 'Pratham Bande',
        image: 'https://s3.amazonaws.com/hr-assets/0/1667879233-0a3eb4b751-pratham_bande.jpeg',
        linkedin: 'https://www.linkedin.com/in/pratham-bande/',
        github: 'https://github.com/prathambande',
        regNo: '862074',
        role:'Developer'
    },
    {
        name: 'Ankan Sen',
        image: 'https://nitw.ac.in/api/static/files/Ankan_21EEB0A05_2023-8-13-10-37-55.jpg',
        linkedin: 'https://www.linkedin.com/in/ankan-sen-6814a41aa/',
        github: 'https://github.com/AnkanSen',
        regNo: '21EEB0A05',
        role:'Developer'
    },
    {
        name: 'Abhay Tiwari',
        image: 'https://nitw.ac.in/api/static/files/Abhay__21EEB0A02_2023-8-13-10-36-46.jpg',
        linkedin: 'https://www.linkedin.com/in/abhay-tiwari-0276a1252/',
        github: 'https://github.com/Canny024',
        regNo: '21EEB0A02',
        role:'Developer'
    },
]

export const BACKGROUND_IMAGE = [
    'https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
    'https://images.unsplash.com/photo-1628254747021-59531f59504b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
    'https://images.unsplash.com/photo-1628062411051-f1be1f511c7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=751&q=80',
    'https://images.unsplash.com/photo-1626338355308-124d9c727cfb?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI1fDZzTVZqVExTa2VRfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
]
