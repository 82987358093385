import React from "react";
// import { Link } from "react-router-dom";
import "./secondaryNavbar.css";

function SecondaryNavbar(props) {
  return (
    <section className="section bg-default">
      <div>
        <div className="group group-xl navbar-div">
          <a
            className="btn button-primary btn-round btn-custom-css-mobile secondary-navbar-button-color "
            href="https://wsdc.nitw.ac.in/"
          >
            {" "}
            Student Portal
          </a>
          <a
            className="btn button-primary btn-round btn-custom-css-mobile secondary-navbar-button-color"
            href="https://wsdc.nitw.ac.in/facultynew/"
          >
            {" "}
            Faculty Portal
          </a>
          <a
            className="btn button-primary btn-round btn-custom-css-mobile secondary-navbar-button-color"
            href="https://erp.nitw.ac.in/"
          >
            {" "}
            SMILE ERP
          </a>
          <a
            className="btn button-primary btn-round btn-custom-css-mobile secondary-navbar-button-color"
            href="https://oldweb.nitw.ac.in/eict/"
          >
            E&ICT Academy
          </a>
          {/* <a
            className="btn button-primary btn-round"
            href="/tlc"
          >
            TLC
          </a> */}

          {/* <Link
            className="btn button-primary btn-round btn-custom-css-mobile secondary-navbar-button-color"
            to={{ pathname: "/" }}
          >
            {" "}
            Opportunities
          </Link> */}
          <a
            className="btn button-primary btn-round btn-custom-css-mobile secondary-navbar-button-color"
            href="https://www.easytourz.com/BT-EmabedTour/all/b5f17aa2b040a89f"
          >
            {" "}
            Virtual Tour
          </a>
        </div>
      </div>
    </section>
  );
}

export default SecondaryNavbar;
