export const DEPARTMENTS = [
    {
        name: 'Biotechnology',
        // image: 'https://nitw.ac.in/api/static/files/chemical_and_biotech_images_2023-5-6-17-12-36.png',
        image: 'https://nitw.ac.in/api/static/files/Bio-Technology_2023-9-25-19-48-35.jpg',
        // link: 'https://www.nitw.ac.in/path/?dept=/bt'
        link: 'https://www.nitw.ac.in/bio'
    },
    {
        name: 'Chemical Engineering',
        // image: 'https://nitw.ac.in/media/files/chemical_2022-0-18-15-43-18.jpg',
        // image: 'https://nitw.ac.in/api/static/files/Chemical_Engineering_2023-9-24-18-30-11.jpg',
        image: 'https://nitw.ac.in/api/static/files/Chemical_Engineering_2023-10-16-11-17-30.jpg',
        // link: 'https://www.nitw.ac.in/path/?dept=/che'
        link: 'https://www.nitw.ac.in/chem'
    },
    // {
    //     name: 'Chemistry',
    //     image: 'https://nitw.ac.in/media/files/mme_2022-0-18-15-38-14.jpg',
    //     link: 'https://www.nitw.ac.in/path/?dept=/cy'
    // },
    {
        name: 'Chemistry',
        // image: 'https://nitw.ac.in/api/static/files/chemistry_image_2023-5-6-17-13-17.png',
        image: 'https://nitw.ac.in/api/static/files/Chemistry_2023-9-24-18-29-43.jpg',
        // link: 'https://www.nitw.ac.in/path/?dept=/cy'
        link: 'https://www.nitw.ac.in/che'
    },
    {
        name: 'Civil Engineering',
        image: 'https://nitw.ac.in/media/files/civil_2022-0-22-21-30-32.jpeg',
        // link: 'https://www.nitw.ac.in/path/?dept=/ce'
        link: 'https://www.nitw.ac.in/ce'
    },
    {
        name: 'Computer Science and Engineering',
        image: 'https://nitw.ac.in/media/files/cse_2022-0-22-20-13-32.jpg',
        link: 'https://www.nitw.ac.in/cse'
    },
    {
        name: 'Electrical Engineering',
        image: 'https://nitw.ac.in/media/files/electrical_2022-0-22-21-15-3.jpeg',
        // link: 'https://www.nitw.ac.in/path/?dept=/eee'
        link: 'https://www.nitw.ac.in/eee'
    },
    {
        name: 'Electronics and Communication Engineering',
        image: 'https://nitw.ac.in/media/files/ece_2022-0-22-21-19-45.jpeg',
        // link: 'https://www.nitw.ac.in/path/?dept=/ece'
        link: 'https://www.nitw.ac.in/ece'
    },
    // {
    //     name: 'Humanities and Social Science',
    //     image: 'https://nitw.ac.in/media/files/hss_2022-0-22-21-23-27.jpeg',
    //     link: 'https://www.nitw.ac.in/path/?dept=/hss'
    // },
    {
        name: 'Humanities and Social Science',
        // image: 'https://nitw.ac.in/api/static/files/Humanities_and_Social_Science_image_2023-5-6-17-15-29.png',
        image: 'https://nitw.ac.in/api/static/files/hss_2023-9-21-12-3-42.jpeg',
        // link: 'https://www.nitw.ac.in/path/?dept=/hss'
        link: 'https://www.nitw.ac.in/hss'
    },
    {
        // name: 'School of Management',
        name: 'Management Studies',
        // image: 'https://nitw.ac.in/media/files/sm_2022-0-22-21-42-52.jpeg',
        image: 'https://nitw.ac.in/api/static/files/SOM_2023-9-21-12-8-39.jpeg',
        // link: 'https://www.nitw.ac.in/path/?dept=/sm'
        link: 'https://www.nitw.ac.in/som'
    },
    {
        name: 'Mathematics',
        image: 'https://nitw.ac.in/media/files/math_2022-0-22-21-36-19.jpeg',
        // link: 'https://www.nitw.ac.in/path/?dept=/mathematics'
        link: 'https://www.nitw.ac.in/math'
    },
    {
        name: 'Mechanical Engineering',
        // image: 'https://nitw.ac.in/api/static/files/Mechanical_Engineering_image_2023-5-6-17-16-42.png',
        image: 'https://nitw.ac.in/api/static/files/Mechanical_2023-9-25-19-48-54.jpg',
        // link: 'https://www.nitw.ac.in/path/?dept=/me'
        link: 'https://www.nitw.ac.in/mec'
    },
    {
        name: 'Metallurgical and Materials Engineering',
        image: 'https://nitw.ac.in/media/files/mme_2022-0-18-15-38-14.jpg',
        // link: 'https://nitw.ac.in/path/?dept=/mme'
        link: 'https://nitw.ac.in/mme'
    },
    // {
    //     name: 'Physical Education',
    //     image: 'https://nitw.ac.in/media/files/physical_dept_2021-11-24-9-56-58.jpg',
    //     // link: 'https://nitw.ac.in/path/?dept=/pe'
    //     link: 'https://nitw.ac.in/pe'
    // },
    {
        name: 'Physics',
        image: 'https://nitw.ac.in/api/static/files/physics_dept_2023-7-22-10-47-36.jpg',
        // link: 'https://nitw.ac.in/path/?dept=/physics'
        link: 'https://nitw.ac.in/physics'
    },
    

]