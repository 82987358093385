import React from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name
import { PicData } from '../constants/PicData';

const Picpage = () => {
    return (
        <div class="container">
            <center><h4 >Professor In-charges/Co-ordinators</h4></center>
            <div class="row">
                <table className="table table-bordered table-responsive">
                    <thead>
                        
                    </thead>
                    <tbody>
                    <tr>
                            <th>
                                S.No
                            </th>
                            <th>
                                Designation
                            </th>
                            <th>
                                Names
                            </th>
                            <th>
                                Phone No. /Email ID
                            </th>
                        </tr>
                       {PicData.map((data, index) => (
                            <tr>
                            <td>
                            {index+1 }
                            </td>
                            <td>
                             {data.incharge_name}
                            </td>
                            <td>
                             <a href={data.emp_page_link} target='_self'> {data.emp_name}</a> 
                            </td>
                            <td>
                            {data.emp_phone_no}<br />
                            {data.emp_email}
                            </td>
                        </tr>
                        ))}
                        


                    </tbody>
                </table>

            </div>
        </div>
    )
}
export default Picpage;