import { MainServerUrl } from "../BasePath";

export const IMG_URL = [
  
  {
    url: `${MainServerUrl}/media/files/visa.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/uber.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/wellsfargo.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/webtec_Top_Recruiters_2023-6-31-13-20-43.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/bny_mellon_Our_Recruiters_2023-6-31-13-16-56.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/master_card_Our_Recruiters_2023-6-31-13-18-21.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/oracle_Our_Recruiters_2023-6-31-13-19-19.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/thoughtspot_Our_Recruiters_2023-6-31-13-20-15.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/microsoft.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/amazon.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/abb.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/electric.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/honeywell.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/de_shaw_and_co.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/goldsmansachs.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/bosch.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/ather.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/adobe.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/aws.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/bd.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/bajaj.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/dell.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/salesforce.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/samsung.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/piramal.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/reliance.png`,
    text: "",
  },
  {
    url: `${MainServerUrl}/media/files/micron.png`,
    text: "",
  },
  
{
  url: `${MainServerUrl}/media/files/AakashByjus_2023-7-1-19-43-18.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Accenture_2023-7-1-19-44-11.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Accolite_2023-7-1-19-45-6.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Addverb_2023-7-1-19-45-48.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Adobe_2023-7-1-19-46-40.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Afcons_2023-7-1-19-47-22.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Amadeus_2023-7-1-19-48-3.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Amazon_2023-7-1-19-48-40.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/AMD_2023-7-1-19-49-26.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Amdocs_2023-7-1-19-51-22.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/American-Express_2023-7-1-19-52-47.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Analog_Devices_2023-7-1-19-54-18.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Apollo_24-7_2023-7-1-19-55-0.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Arcadis_2023-7-1-19-56-16.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Arcesium_2023-7-1-19-56-58.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Arista_Network_2023-7-1-19-58-9.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Ather_Energy_2023-7-1-19-58-58.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/AWS_2023-7-1-20-0-22.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Axis_My_India_2023-7-1-20-1-6.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Axtria_2023-7-1-20-1-45.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Bajaj_Auto_2023-7-1-20-3-1.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Bank-of-New-York-Mellon_2023-7-1-20-3-45.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Barclays_2023-7-1-20-4-52.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Baxter_2023-7-1-20-5-50.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Becton_Dickinson_2023-7-1-20-7-18.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Biocon_2023-7-1-20-8-11.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/BizAcuity_2023-7-1-20-8-55.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Black_Buck_2023-7-1-20-11-6.png`,
  text: "",
  },
  
  {
  url: `${MainServerUrl}/media/files/Blend_360_2023-7-1-20-12-23.png`,
  text: "",
  },
  {
    url: `${MainServerUrl}/media/files/ZF.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Sprinklr.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/service now.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/PWC_India.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Publicis_Sapient.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Providence.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Piramal_Group.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Pine_Labs.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Physics_Wallah.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/PharmaACE.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Pepperfry.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Paytm.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Orient_Electric.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Oracle_Financial_Services_Software_Limited.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Oliver_Wyman.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/O9_SOLUTIONS.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/NXP.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/nutanix-logo.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/NUTANIX.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Numeros_Motors.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Novartis.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Netradyne.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Netcracker.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Natwest.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Narayana.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Morgan-Stanley.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Microsoft.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Micron.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Microchip.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Merilytics.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/MediBuddy.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/MediaTek.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/MBRDI.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/MAQ_Software.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Magicbricks.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Loyalty_Juggernaut.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Lookout.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Leoforce.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Legato.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Kubernetes.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Kagool.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Justdial.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/JP-Morgan-Chase.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/John_Deere.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/JIO.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/HSBC.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/ITC.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Iqvia.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Intuit.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Infor.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Infinity_Learn.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Incture-Technologies.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Impactanalytics.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Ikarus3D.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Idea_Forge.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/ICICI_Prudential_Life_Insurance.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/ICICI.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/IBM.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/HPCL.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Honeywell.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Hitachi_Energy.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Hindustan Unilever.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Hexagon.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Goldman_Sachs.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Global_Logic_Hitachi.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/GKN.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/GEP.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Genpact.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/GE-HealthCare.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/GE-Digital.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Ganit.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/GAIL.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Fractal.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/FischerJordan.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/FIITJEE.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/EY_GDS.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/ExxonMobil.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/EXL.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Everest.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Enphase.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Emergy.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Elucidata.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Druva.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Dr._Reddy_s_Labs.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/DP_World.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/dp world.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Deutsche_Bank.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/DEShaw.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Deloitte.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Dell.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/deliveroo.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Deliveroo(1).png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Datawrkz.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Danieli.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Daimler_Truck.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Cyient.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Cummins.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Controlytics.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Collins-Aerospace.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Coherent.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Cogoport.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Cloud_Angels.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Citrix.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Citibank.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Chronus.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Chetak_Technologies_Limited.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Ceremorphic.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/C-DOT.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/C-DAC.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Caterpillar.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Cashfree.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Cairn_Vedanta.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Cadence.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Brane-Enterprises.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/bpcl.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/Boeing.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/webtec t1.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/thoughtspot t2.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/oracle t1.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/master card t1.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/hdfc bank.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/lt.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/greenko.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/pay u.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/qualcomm.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/bosch.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/hcl.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/maruti suzuki.png`,
    text: "",
    },
  {
    url: `${MainServerUrl}/media/files/kotak.png`,
    text: "",
    },
  

  
];