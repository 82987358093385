export const SUPPORT_CENTRES = [
    // {
    //   name: 'Center for Innovation and Incubation (CII)',
    //   image: 'https://nitw.ac.in/api/static/files/cII_2023-5-23-16-41-8.jpg',
    //   link: 'https://nitw.ac.in/cii/'
    // },
    // {
    //   name: 'Central Research Instrumentation Facility (CRIF)',
    //   image: 'https://nitw.ac.in/api/static/files/crif_2023-5-24-12-55-39.jpg',
    //   link: 'https://www.nitw.ac.in/path/?dept=/nitwcentres'
    // },

    {
        name: 'Centre for Digital Infrastructure and Services (CDIS)',
        // image: 'https://nitw.ac.in/api/static/files/cdis_2023-6-18-17-47-15.jpg',
        image: 'https://nitw.ac.in/api/static/files/cDIs_2024-5-18-18-8-47.jpg',
        // link: 'https://nitw.ac.in/path/?dept=/centres'
        link: 'https://nitw.ac.in/cdis/'
      },

      {
        name: 'Centre for Career Planning and Development (CCPD)',
        image: 'https://nitw.ac.in/api/static/files/CCPD_2023-5-24-13-0-39.jpg',
        link: 'https://nitw.ac.in/path/?dept=/placement'
      },
      {
        name: 'Centre for Training & Learning (CTL)',
        image: 'https://nitw.ac.in/api/static/files/tlc_2023-5-23-16-54-56.jpg',
        link: 'https://www.nitw.ac.in/page/?url=/NITWCTL/tabs'
      },
      // {
      //   name: 'Engineering and Maintenance Unit (E & MU)',
      //   image: 'https://oldweb.nitw.ac.in/media/uploads/2018/11/02/e-and-mu_Iiyot8d.jpg',
      //   link: 'https://nitw.ac.in/page/?url=/emu/2021'
      // },
      {
        name: 'Electronics and ICT Academy (E&ICT Academy)',
        image: 'https://nitw.ac.in/api/static/files/Electronics_and_ICT_Academy_2024-2-12-18-44-31.jpg',
        link: 'https://oldweb.nitw.ac.in/eict/'
      },
          {
        name: 'Centre for Sports Activities',
        image: 'https://nitw.ac.in/media/files/physical_dept_2021-11-24-9-56-58.jpg',
        // link: 'https://nitw.ac.in/path/?dept=/pe'
        link: 'https://nitw.ac.in/pe'
    },

      // {
      //   name: 'Teaching Learning Centre (TLC)',
      //   image: 'https://nitw.ac.in/api/static/files/tlc_2023-5-23-16-54-56.jpg',
      //   link: 'https://www.nitw.ac.in/tlc/'
      // },


    // Add more support centers as needed
  ];