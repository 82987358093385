export const DEPT_BROCHURE = [
 
    {
        name: 'School of Management',
        image: 'https://nitw.ac.in/api/static/files/SOM_2023-11-30-21-6-27.png',
        link: 'https://nitw.ac.in/api/static/files/SOM_phd_2023-11-28-10-30-36.jpg'
    },
    {
        name: 'Chemical Engineering',
        image: 'https://nitw.ac.in/api/static/files/CHEMICAL_2023-11-30-21-6-4.png',
        link: 'https://nitw.ac.in/api/static/files/Chemical_PHD_2023-11-30-19-38-12.pdf'
    },
   
    {
        name: 'Electrical Engineering',
        image: 'https://nitw.ac.in/api/static/files/Electrical_Engineering_2023-12-1-12-48-42.png',
        link: 'https://nitw.ac.in/api/static/files/Electrical_Engineering_2023-12-1-12-47-19.jpg'
    },
   
    {
        name: 'Computer Science and Engineering ',
        image: 'https://nitw.ac.in/api/static/files/cse_2023-12-1-17-0-15.png',
        link: 'https://nitw.ac.in/api/static/files/CSE_2023-12-1-15-43-25.pdf'
    },
   

]