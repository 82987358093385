export const oldportaldocData = [
    {
      name: ' ',
      image: 'https://nitw.ac.in/api/static/files/Academic_2024-2-26-17-52-36.jpg',
      link: 'https://www.nitw.ac.in/page/?url=/Announcements/2021'
    },
    {
      name: ' ',
      image: 'https://nitw.ac.in/api/static/files/Old_Fee_structure_2024-2-26-17-53-20.jpg',
      link: 'https://www.nitw.ac.in/page/?url=/FeeStructure/FS'
    },
    {
      name: ' ',
      image: 'https://nitw.ac.in/api/static/files/TEQIP-III_2024-2-26-17-53-54.jpg',
      link: 'https://nitw.ac.in/teqip/'
    },
    {
      name: ' ',
      image: 'https://nitw.ac.in/api/static/files/WSDC_Faculty_Portal_2024-2-26-17-54-48.jpg',
      link: 'https://wsdc.nitw.ac.in/facultynew/'
    },
    {
      name: ' ',
      image: 'https://nitw.ac.in/api/static/files/WSDC_Student_Portal_2024-2-26-17-55-36.jpg',
      link: 'https://wsdc.nitw.ac.in/'
    }
  ];
  