import React from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name
import { Padding, StarOutlineSharp } from "@mui/icons-material";
import { useState } from "react";

const VidyaShaktiPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <>

            <div class="container">

                <div class="row">
                    <div className="col-md-12">
                        <header>
                            {/* <h1 style={{ color: 'blue' }}><center>Startup</center></h1> */}
                        </header>
                    </div>
                </div>

                <h3 align='center'>Vidya Shakti Center</h3>


                <div class="row">





                    <div className="col-md-12 mb-5">
                        <div class="seminar-card" style={{ padding: '2%', backgroundColor: '#e6e8e9' }}>
                            <div className="row">

                                <div class="col-md-6 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Coordinator (s)-NIT Warangal </p>
                                    </div>


                                    <a href="https://erp.nitw.ac.in/ext/profile/ee-rkrishan">
                                        <h5 style={{ color: '#b80924', fontSize: '1rem' }}> Prof. Ram Krishan (EE)</h5>    </a> <br></br>
                                    <a href="https://erp.nitw.ac.in/ext/profile/ee-anilnaik205">
                                        <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Prof. Kanasottu Anil Naik (EE)</h5></a> <br></br>


                                </div>

                                {/* <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                <div class="title_arrow mb-3">
                    <p class="title">Coordinator (s)-IIT Madras</p>
                </div>

                <header class="mt-3">
                    <h6 style={{ color: '#b80924' }}>Drones and Autonomous Vehicles  </h6>
                </header>
            </div> */}

                                <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Coordinator (s)-IIT Madras</p>
                                    </div>


                                    <a href="#">
                                        <h5 style={{ color: '#b80924', fontSize: '1rem' }}> Dr. Siva. S </h5>    </a> <br></br>
                                    <a href="#">
                                        <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Nagarajan. P </h5></a> <br></br>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-15 p-15">


                        <img
                            src="https://nitw.ac.in/api/static/files/Vidyashakti_2024-5-29-17-17-1.jpg"
                            alt="Image 1"
                            style={{ width: '100%', height: '400px' }} // // Customize width and height
                        />



                        <br></br><br></br>



                        <img
                            src="https://nitw.ac.in/api/static/files/Endrosements_2024-5-29-17-27-44.jpg"
                            alt="Image 1"
                            style={{ width: '100%', height: '400px' }} // // Customize width and height
                        />



                        <br></br><br></br>








                        <table className="table table-bordered">
                                                        <tbody>
                            <tr>
                                <td colspan="2" rowspan="1" >
                                    <center> <b> Vidya Shakti - Rural Interaction Centers</b></center>
                                </td>

                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b>Project Title</b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <b>Live online training to rural and poor school children in 5th to 12th gradesTeach Science, Maths and English subjects, as per the respective state board syllabus, in the respective state’s local language medium.</b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b> Target Group</b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    Around 18 crore students are studying in 5th to 12th grades across all states of India. Of this, about    60% of the students hail from villages. Most people fall under below poverty line and lower middle class in terms of income group.
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b>Project Objective</b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    Best and consistent teachers and hybrid learning model (live + online + offline + evaluation) in every village is not available. Teaching the fundamentals of languages, maths, and science at grades 5-12 is NOT happening as it is supposed to happen (a lot of surveys echo this).
                                    <br></br><br></br>
                                    Covid has created a 2-year knowledge gap between rich/urban students and poor/rural students. Pure online (recorded) material is not suitable for 90% of the students across the world. Live Online sessions with immediate doubt clearing is very essential, and that must be supported with recorded class sessions. This must be enriched with continuous micro assessments. Rural kids do not get enough chance to see live science experiments, in front of them. Due to this, they are unable to relate the concepts they learn. This initiative solves the above problem.
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b>Brief Background</b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    - <b><i>Currently the number of teachers who can provide additional coaching, after the school, at a village is very low; in most villages there is no such facility.</i> </b><br></br>
                                    - <b>Online live education</b> is the only way to reach them rather than a physical teacher being present at every place, for every grade. <br></br>
                                    - With internet facilities available in most places, this is the only viable model <br></br>
                                    - A small set of tutors can teach 1000s of students over online mode <br></br>
                                    - <b>Students in rural areas do not have financial capacity to get a laptop and internet facility on their own; hence a community-based learning center is a must.</b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b>Vidya Shakti / NITW Team Interventions</b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    1. Identify villages, check electricity and internet and infrastructure availability and safety of the premises – Vidya Shakti and NITW – in Telangana state geography<br></br>
                                    2. Identify RIC coordinators, who will keep the facility ready from evening 5pm to 745pm from Mon thru Sat. (timings can be modified as per the needs) – Vidya Shakti and NITW<br></br>
                                    3. Publish class topics, schedule, and inform respective students and RICs – Vidya ShaktiIdentify qualified teachers who can inspire and teach in the respective medium – Vidya Shakti and NITW<br></br>
                                    4. Conduct classes online, record the classes, upload to YouTube – Vidya Shakti<br></br>
                                    6. Tracking, Monitoring and Evaluation of students and RICs – Vidya Shakti<br></br>
                                    7. Documentation &amp; Reporting to sponsors – Vidya Shakti and NITW<br></br>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b> Deliverables</b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    * Live online classroom sessions, Recorded sessions of classes in YouTube channel<br></br>
                                    * Model exam papers and MCQ sets, Tips to children to face public exams<br></br>
                                    * Live demonstration of science experiments using simulation software (concepts – light, sound, magnetism, electricity, acids, bases, indicators etc.)<br></br>
                                    * Motivational sessions<br></br>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b> Infrastructure Required for each RIC</b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    * 32 inch Smart TV<br></br>
                                    * Internet connection with at least 10MBPS<br></br>
                                    * Voltage stabilizer, keyboard, mouse, spike burst<br></br>
                                    * A lighted room for 10-15 kids to sit and view the TV, located in safe place<br></br>
                                    * A local RIC coordinator who will open the facility, connect to online class, maintain attendance, take MCQ marks and send to us<br></br>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b> Financial Requirements</b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    - One time cost to each RIC will be INR 35000 to set up<br></br>
                                    - Recurring cost for local coordinator honorarium INR 3000 per month (INR 36000 per annum) per RIC<br></br>
                                    - Recurring cost for EB and internet – per month INR 1000 per RIC<br></br>
                                    - One set of teachers for grades 5 to 9 (1 math, 1 science and 1 English teacher) and one more set of teachers for grade 10 to 12 (1 math, 1 physics, 1 chemistry teacher). Grade 5-9 teachers to be paid INR 25000 per month and grade 10-12 teachers to be paid INR 35000 per month. Total INR 1.8 lacs per month, INR 21.6 lacs per annum.<br></br>
                                    - This is to be met either thru Government grants or CSR funds<br></br>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <b> Expected Outcome and Impact </b>
                                </td>
                                <td colspan="1" rowspan="1">
                                    * Better reading and writing abilities<br></br>
                                    * An increased understanding of the concepts and problem-solving skills<br></br>
                                    * Visible increase in the interest and energy levels in students towards academics<br></br>
                                    * Visible increase of minimum 5-10 marks in the academic exams/tests<br></br>
                                    * An increased analytical ability and self-confidence level are the ultimate goals, we would like to see in every student<br></br>
                                    * On a long term, this would result in lower dropout count from schools<br></br>
                                </td>
                            </tr>
                            </tbody>

                        </table>
                        <p>
                        </p>
                        <p>
                        </p>



                    </div>






                </div>

            </div>

        </>
    );
}

export default VidyaShaktiPage;