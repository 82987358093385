import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { MainServerUrl } from "../BasePath";

const Sitemap = () => {
  const [sitemapData, setSitemapData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 5000);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      signal: controller.signal,
    };
    fetch(
      `${MainServerUrl}/api/page/sitemap`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("response is not ok");
        }
        if (response.status !== 200) {
          throw new Error("Status code is not 200");
        }
        return response.json();
      })
      .then((data) => setSitemapData(data))
      .catch((err) => {
        console.log("some err occured");
      })
      .finally(() => clearTimeout(id));
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>
      <div className="page">
        <div className="text-center">
          <section className="section breadcrumb-classic context-dark">
            <div className="container">
              <h1 style={{ fontWeight: "bolder" }}>Sitemap</h1>
            </div>
          </section>
        </div>
        <div className="container pt-3">
          <ul>
            {sitemapData.map((val) => {
              return (
                <li>
                  <h5>
                    <a href={"/page/?url=" + val.url}>{val.title}</a>
                  </h5>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sitemap;
