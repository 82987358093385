import React from "react";
import {placement2021} from "../constants/placements/placements2021";
import {placement2020} from "../constants/placements/placement2020";
import {useParams} from "react-router";
import PlacementStats from "../components/PlacementStats";


function Placement(){
    const params = useParams().yr;
    if(params === "2019"){
        return (
            <PlacementStats data = {placement2021} />
        )
    }
    else if(params === "2018"){
        return (
            <PlacementStats data = {placement2020} />
        )
    }
}

export default Placement;