var newsArr = [];
var tenderArr = [];
var workshopsArr = [];
var breakingNewsArr = [];

function PushInArray(item) {
  if (
    item.contentType === "announcements_news_notices" &&
    item.is_published === true
  ) {
    newsArr.push(item);
  }
  if (item.contentType === "jobs_tenders" && item.is_published === true) {
    tenderArr.push(item);
  }
  if (
    item.contentType === "workshops_conferences" &&
    item.is_published === true
  ) {
    workshopsArr.push(item);
  }
  if (item.is_breaking_news === true) {
    breakingNewsArr.push(item);
  }
}

export const getCategoryWiseNotifications = (data) => {
  newsArr = [];
  tenderArr = [];
  workshopsArr = [];
  breakingNewsArr = [];
  data.map((item) => PushInArray(item));
  var notications = {};
  notications.news = newsArr;
  notications.tenders = tenderArr;
  notications.workshops = workshopsArr;
  notications.breakingNews = breakingNewsArr;
  return notications;
};
