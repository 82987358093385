export const jsdata = [
  {
    tab_header: "Loading...",
    tab_content: {
      blocks: [
        {
          id: "IOnLl_2mak",
          type: "header",
          data: {
            text: "WELCOME TO NITW",
            level: 3,
          },
        },
        {
          id: "dEeOnXxjLl",
          type: "paragraph",
          data: {
            text: "Please Hang On while we load the latest information for you",
          },
        },
      ],
      language: "En",
      _id: "6162ab50c843cb0023036ead",
      time: 1633856333339,
      version: "2.22.2",
      title: "Home",
      url: "/cse/home",
      path: "/cse",
      createdAt: "2021-10-10T08:58:56.678Z",
      updatedAt: "2021-10-10T08:58:56.678Z",
      __v: 0,
    },
  },
  {
    tab_header: "Loading...",
    tab_content: {
      blocks: [
        {
          id: "IOnLl_2mak",
          type: "header",
          data: {
            text: "WELCOME TO NITW",
            level: 3,
          },
        },
        {
          id: "dEeOnXxjLl",
          type: "paragraph",
          data: {
            text: "Please Hang On while we load the latest information for you",
          },
        },
      ],
      language: "En",
      _id: "6162ab50c843cb0023036ead",
      time: 1633856333339,
      version: "2.22.2",
      title: "Home",
      url: "/cse/home",
      path: "/cse",
      createdAt: "2021-10-10T08:58:56.678Z",
      updatedAt: "2021-10-10T08:58:56.678Z",
      __v: 0,
    },
  },
  {
    tab_header: "Loading...",
    tab_content: {
      blocks: [
        {
          id: "IOnLl_2mak",
          type: "header",
          data: {
            text: "WELCOME TO NITW",
            level: 3,
          },
        },
        {
          id: "dEeOnXxjLl",
          type: "paragraph",
          data: {
            text: "Please Hang On while we load the latest information for you",
          },
        },
      ],
      language: "En",
      _id: "6162ab50c843cb0023036ead",
      time: 1633856333339,
      version: "2.22.2",
      title: "Home",
      url: "/cse/home",
      path: "/cse",
      createdAt: "2021-10-10T08:58:56.678Z",
      updatedAt: "2021-10-10T08:58:56.678Z",
      __v: 0,
    },
  },
  {
    tab_header: "Loading...",
    tab_content: {
      blocks: [
        {
          id: "IOnLl_2mak",
          type: "header",
          data: {
            text: "WELCOME TO NITW",
            level: 3,
          },
        },
        {
          id: "dEeOnXxjLl",
          type: "paragraph",
          data: {
            text: "Please Hang On while we load the latest information for you",
          },
        },
      ],
      language: "En",
      _id: "6162ab50c843cb0023036ead",
      time: 1633856333339,
      version: "2.22.2",
      title: "Home",
      url: "/cse/home",
      path: "/cse",
      createdAt: "2021-10-10T08:58:56.678Z",
      updatedAt: "2021-10-10T08:58:56.678Z",
      __v: 0,
    },
  },
  {
    tab_header: "Loading...",
    tab_content: {
      blocks: [
        {
          id: "IOnLl_2mak",
          type: "header",
          data: {
            text: "WELCOME TO NITW",
            level: 3,
          },
        },
        {
          id: "dEeOnXxjLl",
          type: "paragraph",
          data: {
            text: "Please Hang On while we load the latest information for you",
          },
        },
      ],
      language: "En",
      _id: "6162ab50c843cb0023036ead",
      time: 1633856333339,
      version: "2.22.2",
      title: "Home",
      url: "/cse/home",
      path: "/cse",
      createdAt: "2021-10-10T08:58:56.678Z",
      updatedAt: "2021-10-10T08:58:56.678Z",
      __v: 0,
    },
  },
  {
    tab_header: "Loading...",
    tab_content: {
      blocks: [
        {
          id: "IOnLl_2mak",
          type: "header",
          data: {
            text: "WELCOME TO NITW",
            level: 3,
          },
        },
        {
          id: "dEeOnXxjLl",
          type: "paragraph",
          data: {
            text: "Please Hang On while we load the latest information for you",
          },
        },
      ],
      language: "En",
      _id: "6162ab50c843cb0023036ead",
      time: 1633856333339,
      version: "2.22.2",
      title: "Home",
      url: "/cse/home",
      path: "/cse",
      createdAt: "2021-10-10T08:58:56.678Z",
      updatedAt: "2021-10-10T08:58:56.678Z",
      __v: 0,
    },
  },
  {
    tab_header: "Loading...",
    tab_content: {
      blocks: [
        {
          id: "IOnLl_2mak",
          type: "header",
          data: {
            text: "WELCOME TO NITW",
            level: 3,
          },
        },
        {
          id: "dEeOnXxjLl",
          type: "paragraph",
          data: {
            text: "Please Hang On while we load the latest information for you",
          },
        },
      ],
      language: "En",
      _id: "6162ab50c843cb0023036ead",
      time: 1633856333339,
      version: "2.22.2",
      title: "Home",
      url: "/cse/home",
      path: "/cse",
      createdAt: "2021-10-10T08:58:56.678Z",
      updatedAt: "2021-10-10T08:58:56.678Z",
      __v: 0,
    },
  },
];
