import React from "react";
import NewsScrollCard2 from "./NewsScrollCard2";
import "../css/NewsScroll.css";
import "../css/NewScroll2.css";

const NewScroll2 = (props) => {

  return (
    <div className="flex">
      <h4 className="fw-bold subchildEventsPublication">{props.carouselTitle}</h4>
      <div className="text-subline"></div>
      <br />
      <div className="bar">
        {props.cardItems.map((item) => {
          return (
            <NewsScrollCard2
              title={item.title}
              content={item.Content}
              url={item.url}
              image={item.image ? item.image : ""}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NewScroll2;