import React from "react";
import NewsScrollCard2 from "./NewsScrollCard2";
import "../css/NewsScroll.css";
import "../css/NewScroll2.css";

const NewScroll2 = (props) => {

  function compare(a, b) {
    if (a.event_date > b.event_date) {
      return -1;
    }
    if (a.event_date < b.event_date) {
      return 1;
    }
    return 0;
  }
const cardItems = props.cardItems.sort(compare);
  return (
    <div className="flex">
      <h4 className="fw-bold subchildEventsPublication">{props.carouselTitle}</h4>
      <div className="text-subline"></div>
      <br />
      <div className="bar">
        {cardItems.map((item) => {
          if(item.isactive=='Yes'){
          return (
            <NewsScrollCard2
              title={item.title}
              content={item.content}
              url={item.url}
              image={item.image ? item.image : ""}
            />
          );
          }
        })}
      </div>
    </div>
  );
};

export default NewScroll2;
