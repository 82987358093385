import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import { registrationData } from '../constants/RegistrationData'; // Corrected import statement
import '../css/convocation2023.css'; // Import your custom CSS for styling
import { red } from '@mui/material/colors';

const RegistrationPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedItem(index);
  };

  const handleMouseOut = () => {
    setSelectedItem(null);
  };

  return (
    <div className="page text-center font-change-applicable">
      {PageHeader && <PageHeader dept="/registration" />}<br/>
      <div>
      
      </div>
     
      <div className="p-3 container" style={{ width: '100%', marginTop: '20px' }}>
        <div className="row justify-content-center">
        <h5 style={{color:"red", textAlign:'left'}}>
      
      <li style={{color:"red"}}>B.Tech., MCA, M.Sc.(Tech.) and Ph.D graduates contact tech_officer@nitw.ac.in for any technical queries</li>
      <li style={{color:"red"}}>M.Tech., M.Sc. and MBA graduates contact erp_suppport@nitw.ac.in for any technical queries. </li>
      <li style={{color:"red" }}>Ph.D. graduates who have not created a WSDC account are requested to create before filling the convocation registration form.</li>

      </h5>
          {registrationData.map((item, index) => (
            <div
              className={`col-md-6 col-lg-4 my-3 member ${
                index === 0 ? 'first-tab' : 'other-tab'
              } ${item.name.toLowerCase()}-tab`}
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={item.link} target="_blank" rel="noreferrer">
                <div
                  className={`card custom-profile-card ${
                    selectedItem === index ? 'hovered' : ''
                  } ${item.isCircle ? 'circle-tab' : ''}`}
                >
                  <div className="circle-tab-content">
                    <img
                      src={item.image}
                      alt={`Item ${index + 1}`}
                      className={`circle-tab-image ${
                        selectedItem === index ? 'hovered' : ''
                      }`}
                    />
                    <p className={`circle-tab-label`}>
                      {item.name}
                      {/* {index === 0 && <br />} */}
                      {/* {index === 0 && 'Chief Guest'} */}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;