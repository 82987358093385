import React from "react";
import '../css/Search.css';

function ShowSearchItems(props) {
  return (
    <>
    <a href={props.items.url}>
      <div className="search_box">
        <h1 className="head_seachbox_text">
          <span>Title:</span>
          {props.items.title}
        </h1>
      </div>
      </a>
    </>
  );
}

export default ShowSearchItems;
