export const EXAM_BRANCHES = [
    // {
    //     name: 'PIC',
    //     image: 'https://nitw.ac.in/api/static/files/PIC_2023-11-23-11-17-21.jpg',
    //     link: 'https://nitw.ac.in/path/?dept=/eb'
    // },
    {
        name: 'CGPA - percentage conversation certificate  ',
        image: 'https://nitw.ac.in/api/static/files/cgpa_2023-12-13-10-33-13.png',
        link: 'https://nitw.ac.in/api/static/files/CGPA_to_percentage_conversation_certificate_2023-10-13-10-43-53.pdf'
    },
    {
        name: 'Duplicate Certificates',
        image: 'https://nitw.ac.in/api/static/files/Duplicate_Certificates_2023-12-13-10-3-6.png',
        link: 'https://nitw.ac.in/page/?url=/NITWEB/DC'
    },
    // {
    //     name: 'Exam Schedules & Time Tables',
    //     image: 'https://nitw.ac.in/api/static/files/Exam_Schedules_&_Time_Tables_2023-12-12-12-9-14.png',
    //     link: 'https://nitw.ac.in/page/?url=/NITWES/TT'
    // },
    {
        name: 'Medals & Honours',
        image: 'https://nitw.ac.in/api/static/files/Medals_&_Honours_2023-12-12-12-10-40.png',
        link: 'https://nitw.ac.in/page/?url=/NITWMH/EB'
    },
    {
        name: 'Medium of Instruction',
        image: 'https://nitw.ac.in/api/static/files/Medium_of_instruction_2023-12-13-10-9-55.png',
        link: 'https://nitw.ac.in/api/static/files/Medium_of_Instruction_2023-10-26-12-41-40.pdf'
    },
    {
        name: 'Notices & Circulars',
        image: 'https://nitw.ac.in/api/static/files/Notices_&_Circulars_2023-12-12-11-45-13.png',
        link: 'https://www.nitw.ac.in/page/?url=/NITWNC/EB'
    },
    {
        name: 'Original certificates/Grade sheets',
        image: 'https://nitw.ac.in/api/static/files/original_certificates_2023-12-13-9-43-20.png',
        link: 'https://nitw.ac.in/page/?url=/NITWOCGS/EB'
    },
    {
        name: 'Transcripts',
        image: 'https://nitw.ac.in/api/static/files/transcripts_2023-12-13-10-31-13.png',
        link: 'https://nitw.ac.in/page/?url=/NITWOTranscripts/EB'
    },
    {
        name: 'VIII Semester Grade Reports',
        image: 'https://nitw.ac.in/api/static/files/VIII_Semester_Grade_Reports_2023-12-12-12-28-36.png',
        link: 'https://nitw.ac.in/api/static/files/VIII_Semester_Grade_Reports_-_Policy_of_the_Institute_2023-10-13-10-47-9.pdf'
    },
    {
        name: 'Academic Verification for Genuineness',
        image: 'https://nitw.ac.in/api/static/files/Academic_Verification_for_Genuineness_2023-12-12-12-35-17.png',
        link: 'https://nitw.ac.in/page/?url=/NITWAVG/EB'
    },
    // {
    //     name: 'Contact Details',
    //     image: 'https://nitw.ac.in/api/static/files/Contact_Details_2023-11-23-12-20-52.jpg',
    //     link: 'https://nitw.ac.in/path/?dept=/eb'
    // },


]