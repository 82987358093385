import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import Loading from "./components/Loading";
import HomePage from "./pages/Homepage";
import DepartmentPage from "./pages/DepartmentPage";
import GalleryPage from "./pages/GalleryPage";
import { navbarSampleData } from "./constants/sampleNavBar";
import NormalPage from "./pages/NormalPage";
import NotificationPage from "./pages/NotificationPage";
import Sitemap from "./pages/Sitemap";
import Nirf from "./pages/Nirf";
import Placement from "./pages/Placement";
import EditorJSContentRenderer from "./components/EditorJSContentRenderer";
// import { createBrowserHistory } from "history";
import { BaseUrl, MainServerUrl, OldServerUrl } from "./BasePath";
import { TeamPage } from "./pages/TeamPage";
import { StaticDepartmentPage } from "./pages/StaticDepartmentPage";
import { SupportCentresPage } from "./pages/SupportCentresPage";
import { StaticDeans } from "./pages/StaticDeans";
import HeadsData from "./pages/HeadsData";
import DeanRandC from './pages/DeanRandC'; // Update the import statement
import DeanAcademic from './pages/DeanAcademic'; // Update the import statement
import Opportunities from './pages/Opportunities'; // Update the import statement
import DeanSW from './pages/DeanSW'; // Update the import statement
import CSEDeptPage from "./pages/CSEDept";
import Convocation2023, { Convocation2023Page } from './pages/Convocation2023'
import RegistrationPage from "./pages/Registration";
import DeanRAC from "./pages/RAC";
import PhysicsDeptPage from "./pages/PhysicsDept.js";
import ECEDeptPage from "./pages/ECEDept";
import CHEDeptPage from "./pages/CHEDept";
import BIODeptPage from "./pages/BIODept";
import SOMDeptPage from "./pages/SOMDept";
import HSSDeptPage from "./pages/HSSDeptPage";
import FoundationDayPage from "./pages/FoundationDay";
import ChemicalDeptPage from "./pages/ChemicalDept";
import EEEDeptPage from "./pages/EEEDepat";
import MMEDDeptPage from "./pages/MMEDDept";
import MathsDepatPage from "./pages/MathsDept";
import PEDeptPage from "./pages/PEDept";
import MechDeptPage from "./pages/MechDept";
import CIVDeptPage from "./pages/CIVDept";
import VisionMissionPage from "./pages/VisionMission";
import Seminars from "./pages/Seminars.js";
import Senate from "./pages/Senate";
import MoU from "./pages/MoU";
import Bwcfcbog from "./pages/Bwcfcbog";
import { TTPage } from "./pages/TimeTables";
import AcademicProg from "./pages/AcademicProg";
// import DeptBrochurePage from "./pages/DeptBrochurePage";
import DeptBrochurePage from "./pages/DeptBrochurePage.js";
import ACPage from "./pages/ACPage"
import ExamBranchPage from './pages/EBPage';
import Careers from "./pages/CAREERS";

import NonTeachingPositions from "./pages/NonTeachingPositions";
import Technozion2023Page from "./pages/Technozion2023";
import ResearchScholarConfluencePage from "./pages/RSC2024";
import { NewsletterPage } from "./pages/NewsletterPage";
import DeanMHW from "./pages/DeanMHW";
import OldPortalDoc from "./pages/oldportaldoc";
import { KeyDocuments } from "./pages/KeyDocuments";
import Ioc from "./pages/Ioc";
import Seminar from "./pages/Seminars";
import SGR from "./pages/Sgr";
import Startup from "./pages/Startup";
import MilestonesTimeline from "./pages/MilestonesTimeline";
import LibraryDeptPage from "./pages/Library";
import IhcDeptPage from "./pages/Ihc.js";
import { IF } from "./pages/IF";
import CfrPage from "./pages/Cfr";
import SWDeptPage from "./pages/SwDept.js";
import RDC from "./pages/RDC";
import { Rc } from "./pages/Rc";
import AuTimeline from "./pages/Au";

import VidyaShaktiPage from "./pages/VidyaShakti";
import Resultpage from "./pages/Result1";
import ICCpage from "./pages/ICCPage";
import Picpage from "./pages/Pic";
import FacultyPositions from "./pages/FacultyPositions";
import HostelPage from "./pages/Hostel.js";
import CcpdPage from "./pages/Ccpd.js";




// const history = createBrowserHistory({ basename: "/new/" });

function App() {
  function compare(a, b) {
    if (a.priority < b.priority) {
      return -1;
    }
    if (a.priority > b.priority) {
      return 1;
    }
    return 0;
  }
  const [navbarData, setData] = useState(navbarSampleData.sort(compare));
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsReady(true);
  }, [navbarData]);
  useEffect(() => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 5000);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      signal: controller.signal,
    };
    fetch(`${MainServerUrl}/api/nav/getall`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("response is not ok");
        }
        if (response.status !== 200) {
          throw new Error("Status code is not 200");
        }
        return response.json();
      })
      .then((data) => setData(data.sort(compare)))
      .catch((err) => {
        fetch(
          "https://insti-web-backend.herokuapp.com/api/nav/getall",
          requestOptions
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("response is not ok");
            }
            if (response.status !== 200) {
              throw new Error("Status code is not 200");
            }
            return response.json();
          })
          .then((data) => setData(data.sort(compare)))
          .catch((err) => {
            console.log(err);
          });
      })
      .finally(() => clearTimeout(id));
  }, []);
  console.log(navbarData)
  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>
      {/* <Loading></Loading> */}

      <div className="page">
        <Navbar isReady={isReady} navbarData={navbarData}></Navbar>
        <Router>
          <Switch>


            <Route
              exact
              path={`${BaseUrl}/page`}
              render={(props) => <NormalPage {...props} />}
            />
            <Route
              exact
              path={`${BaseUrl}/test`}
              render={(props) => <EditorJSContentRenderer {...props} />}
            />

            <Route
              path={`${BaseUrl}/cse`}
              render={(props) => <CSEDeptPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/Physics`}
              render={(props) => <PhysicsDeptPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/HSS`}
              render={(props) => <HSSDeptPage {...props} />}
            />
            <Route path={`${BaseUrl}/ece`} render={(props) => <ECEDeptPage {...props} />} />
            <Route path={`${BaseUrl}/che`} render={(props) => <CHEDeptPage {...props} />} />
            <Route path={`${BaseUrl}/bio`} render={(props) => <BIODeptPage {...props} />} />
            <Route path={`${BaseUrl}/som`} render={(props) => <SOMDeptPage {...props} />} />
            <Route
              exact
              path={`${BaseUrl}/path`}
              render={(props) => <DepartmentPage {...props} />}
            />
            <Route exact path={`${BaseUrl}/nirf/:yr`} component={Nirf} />
            <Route
              exact
              path={`${BaseUrl}/placement/:yr`}
              component={Placement}
            />
            <Route
              exact
              path={`${BaseUrl}/notifications`}
              render={(props) => <NotificationPage {...props} />}
            />
            <Route
              exact
              path={`${BaseUrl}/gallery`}
              render={(props) => <GalleryPage {...props} />}
            />
            <Route
              exact
              path={`${BaseUrl}/sitemap`}
              render={(props) => <Sitemap {...props} />}
            />
            <Route
              path={`${BaseUrl}/e-tender`}
              component={() => {
                window.location.href =
                  "https://nitw.euniwizarde.com/HomePage/loadSiteHomePage/hMnOs5f_-8O6VkRRC98Tmw";
                return null;
              }}
            />{" "}
            <Route
              path={`${BaseUrl}/tlc`}
              component={() => {
                window.location.href = `${OldServerUrl}/tlc/`;
                return null;
              }}
            />{" "}
            {/* <Route
              path={`${BaseUrl}/alumni`}
              component={() => {
                window.location.href =
                  "https://nitw.euniwizarde.com/HomePage/loadSiteHomePage/hMnOs5f_-8O6VkRRC98Tmw";
                return null;
              }}
            /> */}
            <Route
              path={`${BaseUrl}/raa`}
              component={() => {
                window.location.href = `${OldServerUrl}/raa/`;
                return null;
              }}
            />
            <Route
              path={`${BaseUrl}/cce`}
              component={() => {
                window.location.href = `${OldServerUrl}/main/cce/nitw/`;
                return null;
              }}
            />
            <Route
              path={`${BaseUrl}/deans`}
              render={(props) => <StaticDeans {...props} />}
            />
            <Route
              path={`${BaseUrl}/Heads`}
              render={(props) => <HeadsData {...props} />}
            />
            <Route
              path={`${BaseUrl}/team-member`}
              render={(props) => <TeamPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/departments`}
              render={(props) => <StaticDepartmentPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/supportcentres`} // Adjust the path as needed
              render={(props) => <SupportCentresPage {...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/DeanRandC`}
              render={(props) => <DeanRandC {...props} />}
            />


            <Route
              path={`${BaseUrl}/DeanAcademic`}
              render={(props) => <DeanAcademic {...props} />}
            />


            {/* <Route
              path={`${BaseUrl}/Opportunities`}
              render={(props) => <Opportunities {...props} />}
            /> */}
            <Route
              path={`${BaseUrl}/DeanSW`} // Update the route path
              render={(props) => <DeanSW {...props} />} // Render the DeanSW component
            />
            <Route
              path={`${BaseUrl}/convocation2023`}
              render={(props) => <Convocation2023 {...props} />}
            />

            <Route
              path={`${BaseUrl}/registration`}
              render={(props) => <RegistrationPage {...props} />}
            />

            <Route
              path={`${BaseUrl}/RAC`}
              render={(props) => <DeanRAC {...props} />}
            />
            <Route
              path={`${BaseUrl}/FoundationDay`}
              render={(props) => <FoundationDayPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/mme`}
              render={(props) => <MMEDDeptPage {...props} />}
            />

            <Route
              path={`${BaseUrl}/eee`}
              render={(props) => <EEEDeptPage {...props} />}
            />

            <Route
              path={`${BaseUrl}/chem`}
              render={(props) => <ChemicalDeptPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/math`}
              render={(props) => <MathsDepatPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/pe`}
              render={(props) => <PEDeptPage {...props} />}
            />

            <Route
              path={`${BaseUrl}/mec`}
              render={(props) => <MechDeptPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/ce`}
              render={(props) => <CIVDeptPage {...props} />}
            />
            <Route path={`${BaseUrl}/vis`}
              render={(props) => <VisionMissionPage {...props} />}
            />

            <Route path={`${BaseUrl}/seminars`}
              render={(props) => <Seminars {...props} />}
            />


            <Route
              path={`${BaseUrl}/senate`}
              render={(props) => <Senate {...props} />}
            />
            <Route
              path={`${BaseUrl}/MoU`} // Adjust the path as needed
              render={(props) => <MoU {...props} />} // Render the new page

            />




            <Route
              path={`${BaseUrl}/bwcfcbog`}
              render={(props) => <Bwcfcbog {...props} />}
            />
            <Route
              path={`${BaseUrl}/TT`}
              render={(props) => <TTPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/ap`}
              render={(props) => <AcademicProg {...props} />}
            />
            <Route
              path={`${BaseUrl}/DB`}
              render={(props) => <DeptBrochurePage {...props} />}
            />
            <Route
              path={`${BaseUrl}/AC`}
              render={(props) => <ACPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/EB`}
              render={(props) => <ExamBranchPage {...props} />}  // Fix the component name
            />
            <Route
              path={`${BaseUrl}/careers`} // Adjust the path as needed
              render={(props) => <Careers {...props} />} // Render the new page
            />

            <Route
              path={`${BaseUrl}/faculty`} // Adjust the path as needed
              render={(props) => <FacultyPositions {...props} />} // Render the new page
            />


            <Route
              path={`${BaseUrl}/NonTeaching`} // Adjust the path as needed
              render={(props) => <NonTeachingPositions {...props} />} // Render the new page
            />

            <Route
              path={`${BaseUrl}/technozion23`}
              render={(props) => <Technozion2023Page {...props} />}
            />
            {/* <Route
              path={`${BaseUrl}/RC2024`}
              render={(props) => <ResearchScholarConfluencePage {...props} />}
            /> */}
            <Route
              path={`${BaseUrl}/RSC2024`}
              render={(props) => <ResearchScholarConfluencePage {...props} />}
            />

            <Route
              path={`${BaseUrl}/newsletter`} // Adjust the path as needed
              render={(props) => <NewsletterPage {...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/DeanMHW`} // Centre for Mental Health and Wellness
              render={(props) => <DeanMHW {...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/oldpandd`}
              render={(props) => <OldPortalDoc {...props} />}  // Fix the component name
            />
            <Route
              path={`${BaseUrl}/KeyDocuments`} // Adjust the path as needed
              render={(props) => <KeyDocuments {...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/Ioc`}
              render={(props) => <Ioc {...props} />}
            />
            <Route
              path={`${BaseUrl}/seminar`}
              render={(props) => <Seminar {...props} />}
            />
            <Route
              path={`${BaseUrl}/sgr`}
              render={(props) => <SGR {...props} />}
            />

            <Route
              path={`${BaseUrl}/startup`}
              render={(props) => <Startup {...props} />}
            />
            <Route
              path={`${BaseUrl}/MilestonesTimeline`}
              render={(props) => <MilestonesTimeline {...props} />}
            />
            <Route
              path={`${BaseUrl}/Library`}
              render={(props) => <LibraryDeptPage {...props} />}
            />
            <Route
              path={`${BaseUrl}/Ihc`} // Update the route path
              render={(props) => <IhcDeptPage{...props} />} // Render the DeanSW component
            />
            <Route
              path={`${BaseUrl}/IF`}
              render={(props) => <IF {...props} />}
            />
            <Route
              path={`${BaseUrl}/Cfr`} // Adjust the path as needed
              render={(props) => <CfrPage {...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/SW`} // Update the route path
              render={(props) => <SWDeptPage{...props} />} // Render the DeanSW component
            />
            <Route
              path={`${BaseUrl}/RDC`} // Adjust the path as needed
              render={(props) => <RDC{...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/Rc`} // Adjust the path as needed
              render={(props) => <Rc {...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/Au`} // Adjust the path as needed
              render={(props) => <AuTimeline{...props} />} // Render the new page
            />

            <Route
              path={`${BaseUrl}/VidyaShakti`} // Adjust the path as needed
              render={(props) => <VidyaShaktiPage{...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/result`} // phd student results 
              render={(props) => <Resultpage {...props} />}
            />
            <Route
              path={`${BaseUrl}/ICC`} // Internal Complaints Committee
              render={(props) => <ICCpage{...props} />} // Render the new page
            />
            <Route
              path={`${BaseUrl}/pic`} // Professors incharge
              render={(props) => <Picpage{...props} />} // Render the new page
            />

            <Route
              path={`${BaseUrl}/Hostel`} // hostel
              render={(props) => <HostelPage{...props} />} // Render the DeanSW component
            />
             <Route
              path={`${BaseUrl}/ccpd`}
              render={(props) => <CcpdPage {...props} />}
            />












            <Route
              path={`${BaseUrl}`}
              render={(props) => <HomePage {...props} />}
            />






          </Switch>
        </Router>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}

export default App;
