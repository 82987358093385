import { Typography, Grid } from "@mui/material";
import React from "react";
import '../components/rederers/css/fixes.css'

export default function AnnouncementItem(props) {
  const item = props.item;
  return (
    <Grid item container direction="row" margin="1vh">
      <Grid item xs={0.5}></Grid>
      <Grid item md={8} container direction="column">
        <Typography
          variant="h5"
          fontWeight="bold"
          className="fw-bold text-primary"
        >
          <a href={item.url}>{item.title}</a>
        </Typography>
        <Typography
          variant="h6"
          style={{
            textAlign: "justify",
            textJustify:'inter-word',
          }}
        >
          {item.summary}
        </Typography>
      </Grid>
      <Grid item md={3} xs={11} container direction="column">
        <Typography
          style={{
            width: "100%",
            margin: "auto",
            textAlign: "right",
          }}
        >
          {" "}
          📅 {item.start_date?.toString().substr(0, 10)}
        </Typography>
      </Grid>
      <Grid item xs={0.5}></Grid>
    </Grid>
  );
}
