// import {React,useState} from "react";
// import "../css/Styles.css";
// // import "../css/fonts.css";
// import DirectorImage from "../constants/logo-326x329.png";
// import Tabs from './Tabs.js';
// import DirectorMessage from "../constants/DirectorMessage";


// function DirectorsTab() {
//   const [buttonText,setButton]=useState('...read more')
//   const messages=DirectorMessage[0].message.slice(0,869)
//   const extra_message=DirectorMessage[0].message.slice(869)
//   function readSetting(){
//     const readMore=document.getElementById('moretext')
//     if(buttonText==='...read more'){
//        setButton('...read less')
//        readMore.style.display="inline"
//       }
//     if(buttonText==='...read less'){ 
//       setButton('...read more')
//       readMore.style.display="none"
//     }

//   }
//   return (
//     <>
//       <div className="wrappers">
//         <div className="director">
//           <h2 className="justifys">Director's Message</h2>

//           <img src={DirectorMessage[0].url} alt="" className="DirectorImage" />
//           <div style={{}}>
//           <p id="readMore" className="DirectorSay">
//           {messages}
//           <p id="moretext" className="DirectorSay" style={{display:"none"}}>{extra_message}</p>
//           <button style={{border:"none",background:"none",color:"#b80924"}} onClick={readSetting}>{buttonText}</button>
//           </p>
//           </div>
//           <div>
//             <p className="DirectorName">{DirectorMessage[0].name}</p>
//             <p className="Designation">Director</p>
//           </div>
//         </div>
//         <div className="ranking">
//           <h2 className="justifys">Ranking NIRF-2023</h2>
//           <div className="boxes">
//           <div className="one box">
//           <h3 className="justifys-h3">ENGINEERING</h3>
//           <h4 className="justifys-h4">21</h4>
//           </div>
//             <div className="two box">
//             <h3 className="justifys-h3">OVERALL</h3>
//             <h4 className="justifys-h4">53</h4>
//             </div>
//           </div>
//           <Tabs/>
//         </div>
//       </div>
//     </>
//   );
// }

// export default DirectorsTab;


import { React, useState } from "react";
import "../css/Styles.css";
// import "../css/fonts.css";
import DirectorImage from "../constants/logo-326x329.png";
import Tabs from './Tabs.js';
import DirectorMessage from "../constants/DirectorMessage";


function DirectorsTab() {
  const [buttonText, setButton] = useState('...read more')
  const messages = DirectorMessage[0].message.slice(0, 869)
  const extra_message = DirectorMessage[0].message.slice(869)
  function readSetting() {
    const readMore = document.getElementById('moretext')
    if (buttonText === '...read more') {
      setButton('...read less')
      readMore.style.display = "inline"
    }
    if (buttonText === '...read less') {
      setButton('...read more')
      readMore.style.display = "none"
    }

  }
  return (
    <>
      <div className="row">


        <div className="col-md-6">
          <div className="director">
            <h2 className="justifys">Director's Message</h2>
            <img src={DirectorMessage[0].url} alt="" className="DirectorImage" />
            <div style={{}}>
              <p id="readMore" className="DirectorSay">
                {messages}
                <p id="moretext" className="DirectorSay" style={{ display: "none" }}>{extra_message}</p>
                <button style={{ border: "none", background: "none", color: "#b80924" }} onClick={readSetting}>{buttonText}</button>
              </p>
            </div>
            <div>
              <p className="DirectorName">{DirectorMessage[0].name}</p>
              <p className="Designation">Director</p>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="ranking">

            <div className="row">

              <div className="col">
                <div style={{textAlign:"Center"}}>
                <img src="images/nirf.png" alt="" />
                <h2 className="justifys">Ranking NIRF-2024</h2>
                </div>
              
                <div className="boxes">
                  <div className="one box">
                    <h3 className="justifys-h3">ENGINEERING</h3>
                    <h4 className="justifys-h4">21</h4>
                  </div>
                  <div className="two box">
                    <h3 className="justifys-h3">OVERALL</h3>
                    <h4 className="justifys-h4">53</h4>
                  </div>
                </div>
              </div>


              <div className="col">
              
              
              <div style={{textAlign:"Center"}}>
              <img src="images/the.png" alt="" />
                <h2 className="justifys"><img src="" alt="" /> Times Higher Education-2024</h2>
                </div>


                <div className="boxes">
                  <div className="one box">
                    <h3 className="justifys-h3">ASIA</h3>
                    <h4 className="justifys-h4">351-400</h4>
                  </div>
                  <div className="two box">
                    <h3 className="justifys-h3">WORLD</h3>
                    <h4 className="justifys-h4">1001-1200</h4>
                  </div>
                </div>
              </div>
            </div>


            <Tabs />
          </div>
        </div>



      </div>
    </>
  );
}

export default DirectorsTab;
