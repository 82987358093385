import { useState } from "react";
// Chemistry Department

const CHEDeptPage = () => {

  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [peopleTab, setPeopleTab] = useState("Faculty");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePeopleTabClick = (tabId) => {
    setPeopleTab(tabId);
    console.log(tabId)
  }
  return (
    <><div className="dept-wrapper">
      <div className="container">
        <section className="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
            <img
              src="CHEDeptAssets/images/che-banner.jpg" className="img-fluid" />
          </header>
          <div className="row">
            <div className="col-md-3">
              {/* Tabs nav */}
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected="false">
                  <span>About</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected="false">
                  <span>Academic Programs</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                  role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                  <span>People </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                  role="tab" aria-controls="v-pills-NotableAlumni"
                  aria-selected="false">
                  <span>Notable Alumni </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                  id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                  aria-controls="v-pills-Students" aria-selected="false">
                  <span>Students Society </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                  role="tab"
                  aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                    Group</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                  role="tab" aria-controls="v-pills-Awards"
                  aria-selected="false"> <span>Awards and Honours</span> </a>
                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                  aria-controls="v-pills-labs" aria-selected="false">
                  <span>Laboratory & Facilities</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                  role="tab" aria-controls="v-pills-PhotoGallery"
                  aria-selected="false"> <span>Photo Gallery</span> </a>

              </div>


              <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/cy-spenta"
                target="_blank"> <img src="CHEDeptAssets/images/10.jpg" alt="" className="img-fluid" />
                <h6>Prof. Santhosh Penta</h6>
              </a>
                <p> Head of the Department <br /> <a href="mailto:chemistry_hod@nitw.ac.in"
                  style={{ color: "#808080" }}>chemistry_hod@nitw.ac.in</a> <br /> 9490165352, 8305654728 </p>
              </div>


            </div>
            <div className="col-md-9">
              {/* Tabs content */}
              <div className="tab-content" id="v-pills-tabContent">
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div className="intro-section">
                    {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                    <p>
                      The department of chemistry was established in the Year, 1959, as an integral part of the Regional Engineering College Warangal (RECW). Since its inception, the department is greeted as one of the most academically active departments in the Institute. The department housed 18 highly reputed faculty members, 60 research scholars and 101 M.Sc. students. Department offers chemistry course to all engineering branches and two years M.Sc. Chemistry with specialization in Organic and Analytical Chemistry. The department has been conducting cutting-edge research in contemporary topics of Organic, Inorganic, Physical and Analytical Chemistry. In the department, various state-of-the-art facilities such as 400 MHz NMR, HRMS, gas chromatography, HPLC, FTIR, UV-vis-NIR, fluorescence, electrochemical workstation, computational chemistry software, etc. are available. So far, department received several extramural research projects worth Rs ~11 crores. Recently department received FIST grants of worth 1.67 crores.As a part of continuing education and outreach activities, department organized several national and international Conferences, seminars and workshops. In addition, department is actively involved in science popularization among the school children via Inspire programme and society. So far, the department produced 130 Ph.D. students and published over 950 research articles in various well-reputed international and national journals. Most of the students are well placed in various companies and institutions with decent package.
                    </p>
                    <h3>A Brief Profile of the Department of Chemistry</h3>

                    <p>The Department of Chemistry, NIT Warangal is growing from strength to strength while keeping pace with overall development of NIT Warangal. The Department has a team of faculty, graduated from IIT’s, Central Universities and other premier Institutions of higher learning. The Department has good infrastructure and is equipped with the latest sophisticated analytical instruments. The Department has been offering two M.Sc. programmes with the specializations in: i) Organic Chemistry and ii) Analytical Chemistry.The faculty members are actively engaged in research and consultancy. The Department has produced 90 PhDs and has to its credit about 500 research publications in reputed International and National Journals. Currently 35 research scholars are working towards PhD degree.</p>

                    <h3>Srengths and Achievements</h3>


                    <ul className="listing-info">
                      <li>Highly Qualified and Experienced Faculty members</li>
                      <li>Active participation of Faculty in Administrative, Curricular and Extra-Curricular activities</li>
                      <li>Faculty members are authors of Books; Reviewers of Research Journals; Selection Committee Members; Chairpersons at National and International Symposia; Winners of Awards, Fellowships, Visiting Faculty, Ph.D. Theses Evaluators</li>
                      <li>Faculty members are invitees as Experts to specific Centers of Academic Excellence</li>
                      <li>Faculty are invited for popularization of Science and Rural Development activities</li>
                      <li>Research Projects in Cutting-Edge Areas</li>
                      <li>Sponsored Research project by funding agencies like DST, CSIR, UGC, ICMR, MHRD, AICTE, DRDO, NRB etc.</li>
                      <li>Linkages with premier R&D and Industrial Establishments such as IICT, CECRI, ARCI, GVK Bio, DRL, NATCO, SUVEN Pharma, HETERODRUGS, etc.</li>
                      <li>Novel Methods and use of AV Aids for Effective Teaching</li>
                      <li>Optimal Use of Available Infrastructure and Facilities</li>
                      <li>Excellent Student Feedback</li>
                      <li>Laboratory Manuals in Textbook format and Updated Frequently</li>
                      <li>UG and PG Curricula updated frequently with latest and advanced topics</li>
                    </ul>
                  </div>
                  <div className="row vision-mission-section">
                    <div className="col-md-6 vision-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                        alt="" /> Vision</h4>
                      <p className="  mb-4">To ensure that Chemistry  is the international expert in providing excellence in education, initiating research and inspiring environment that ignites the young minds.</p>
                      <img src="CHEDeptAssets/images/chemistry.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-6 mission-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                        alt="" /> Mission</h4>
                      <p className="  mb-2">To maintain a Department that gives equal importance in terms of our relevance of teaching and research by creating an environment that promotes</p>
                      <p className="  mb-2">Independent thought, exchange of ideas and high ethical standards,</p>
                      <p className="  mb-2">Development of innovative instructional techniques,</p>
                      <p className="  mb-2">Use of modern educational technology in lecture and laboratory courses, and</p>
                      <p className="  mb-2">Increased opportunities and greater participation by under-represented minorities.</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <h4 className="mt-2 mb-2 notice-board1">
                      <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardChem/Chemistry" >Notices/Announcements</a>
                    </h4>
                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">
                  {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                  {/*<div className="row">
                                    <div className="col-md-12">
                                    <h3 className="mb-3">PhD</h3>
                                    </div>
                                    </div>*/}
                  <div className="row">


                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>UG Courses</h3>


                            <ul className="listing-info">
                              <li>Chemistry Theory and Lab Courses (I B. Tech. All Branches)</li>
                              <li>Physical & Organic Chemistry (II B. Tech. Chemical Engg.)</li>
                              <li>Instrumental Analysis Laboratory Course (II B. Tech. MME Students)</li>
                              <li>Instrumental Methods of Chemical Analysis – Global Elective (III B.Tech. All Engg. Departments)</li>
                              <li>Chemistry of Nanomaterials – Global Elective (IV B.Tech. All Engg. Departments)</li>
                              <li>Industrial Organic Chemistry (Chemical Engg)</li>
                              <li>Polymer Technology (MME)</li>

                            </ul>

                            {/*<img src="CSEDeptAssets/images/btech.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          {/* <p className="more"> <a className="more-info-link"
                                                        href="CSEDeptAssets/docs/07_btech-in-cse.pdf" target="_blank"> B.Tech
                                                        Syllabus <i className="fa fa-angle-right"></i> </a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>PG programme</h3>
                            <p>M.Sc. in organic and analytical chemistry with intake of 30 in each stream. The admission is based on National competitive exam, JAM</p>
                            {/*<img src="CSEDeptAssets/images/mtech1.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className=" more-info-link"
                            href="CHEDeptAssets/docs/pg-msc-int-rules-and-regulations-2021-22.pdf"
                            target="_blank"> 2021 - 22: Rules and Regulations <i
                              className="fa fa-angle-right"></i> </a> </p> <br></br>



                          <table className="table table-sm">
                            <thead>
                              <tr>

                                <th scope="col">Courses Offered</th>
                                <th scope="col">Intake</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>

                                <td>M. Sc. Chemistry (Organic Chemistry Specialization) </td>
                                <td>30</td>
                                <td><a className=" more-info-link" href="CHEDeptAssets/docs/2_organic-chemistry.pdf" target="_blank"><b> Scheme & Syllabus </b><i
                                  className="fa fa-angle-right"></i> </a></td>
                              </tr>
                              <tr>

                                <td>M. Sc. Chemistry (Analytical Chemistry Specialization)  </td>
                                <td>30</td>
                                <td><a className=" more-info-link" href="CHEDeptAssets/docs/1_analytical-chemistry.pdf" target="_blank"><b> Scheme & Syllabus</b> <i
                                  className="fa fa-angle-right"></i> </a></td>
                              </tr>
                              <tr>

                                <td>Integrated M.Sc. Chemistry (5-year Integrated) </td>
                                <td>20</td>
                                <td><a className=" more-info-link" href="CHEDeptAssets/docs/int-msc-chemistry.pdf" target="_blank"><b> Scheme & Syllabus </b><i
                                  className="fa fa-angle-right"></i> </a></td>
                              </tr>
                            </tbody>
                          </table>



                        </div>
                      </div>
                    </div>



                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>PhD programme </h3>
                            <p>The Department offers both full time and external Ph.D. admissions. Financial assistance from either institute or project.</p>
                            {/*<img src="CSEDeptAssets/images/mtech2.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="more-info-link"
                            href="CHEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                            target="_blank"> 2021 - 22: Rules and Regulations <i className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>


                    {/* <div className="col-md-12 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Academic Calendar (AY 2022-2023)</h3>
                                                        
                                                    </div>
                                                     <p className="more"> <a className="more-info-link"
                                                        href="CHEDeptAssets/docs/Academic_Calendar_2022-23_2022-9-27-18-10-56.pdf"
                                                        target="_blank"> I B.Tech. & Integrated M.Sc. Programs  <i className="fa fa-angle-right"></i> </a> </p> 

<p className="more"> <a className="more-info-link" href="CHEDeptAssets/docs/academic-calendar-2022-23-pg-iyear.pdf" target="_blank"> I Year PG Programs <i className="fa fa-angle-right"></i> </a> </p>

<p className="more"> <a className="more-info-link" href="CHEDeptAssets/docs/academic-calendar-2022-23-iiyug-pg.pdf" target="_blank"> UG II and PG II and PhD <i className="fa fa-angle-right"></i> </a> </p>

                                                </div>
                                            </div>
                                        </div> */}


                    {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>MCA</h3>
                                                        <p>The Master of Computer Applications (MCA) program was started in 1986
                                                            with an intake of 30 and increased to 46 from 2008. Currently the
                                                            strength is 58.</p>
                                                        
                                                    </div>
                                                    <p className="more"> <a className="more-info-link" href="CSEDeptAssets/docs/cse-mca-tt.pdf"
                                                        target="_blank"> MCA Even Semester Timetable <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div> */}



                    {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D.</h3>
                                                       
                                                    </div>
                                                    <p className="more"> <a className="buttton more-info-link"
                                                        href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                                                        target="_blank"> Rules and Regulations of Ph.D. Program <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div> */}

                  </div>

                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                  aria-labelledby="v-pills-labs-tab">
                  <div className="row laboratory-facilities">
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Instrumentation</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className="card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            <h4 className="card-title  mb-2">Sophisticated instruments </h4>
                            <ul className="ps-0 listing-info">
                              <li>Bruker 400 MHz NMR spectrometer with solid state Probe</li>

                              <li>Liquid Nitrogen Generation Plant (120 liters per day)</li>
                              <li>BET surface analyzer</li>
                              <li>High Performance Computing Cluster</li>
                              <li>Carlo Erba (Fisons) EA 1108 CHNS-O Elemental Analyzer</li>
                              <li>Perkin Elmer 100S FTIR spectrophotometer</li>
                              <li>Shimadzu 8201 PC Fourier Transform Infrared Spectrophotometer</li>
                              <li>Shimadzu UV-1601 Ratio Recording UV-Vis-NIR Spectrophotometer</li>
                              <li>UV-Vis-NiR Spectrophotometer-Analytical Zena</li>
                              <li>EG&G PARC 264 A3 Polarographic Analyzer/Stripping Voltammeter</li>
                              <li>High temperature programmable furnace</li>
                              <li>High speed cooling centrifuge</li>
                              <li>Metrohm Electrochemical Analyzer</li>
                              <li>BAS CV 27 Voltammograph</li>
                              <li>CHI Electrochemical work station</li>
                              <li>Sartorius MC 5 Microbalance</li>
                              <li>ATI Orion 902 Expandable Ion Meter</li>
                              <li>Aimil Nucon 5765 Gas Chromatograph</li>
                              <li>Aldrich Flash Chromatograph</li>
                              <li>Mettler M3 and M5 Microbalances</li>
                              <li>Spectronic 20 Genesis Spectrophotometers</li>
                              <li>Buchi Rotavapor</li>
                              <li>Shaking Incubator</li>
                              <li>Digital polarimeter</li>
                              <li>Digital Refractometer</li>
                              <li>Digital Melting Point Apparatus</li>




                            </ul>
                            {/* <h4 className="card-title  mb-2"> Software</h4>
                                                        <p>Dual boot with Windows 7 and Ubuntu 14.04, NetBeans 7, MSDN, Dev C++
                                                        </p> */}
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="CHEDeptAssets/images/che-ins.jpg"
                          alt="" className="img-fluid" /> </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Laboratories<br /><small>(MME, Physics and Chemistry Building- 4, 5 & 6 floor)</small></h3>

                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">

                            <ul className="ps-0 listing-info">
                              <li>Sophisticated Analytical Instruments Laboratories (I & II)</li>
                              <li>Organic Chemistry Instruction Laboratory</li>
                              <li>Organic Chemistry Research  Laboratory </li>
                              <li>Inorganic & Physical Chemistry Instruction Laboratory</li>
                              <li>Inorganic & Physical Chemistry Research Laboratory</li>
                              <li>High Performance Computing Laboratory</li>
                              <li>Biology Laboratory</li>
                              <li>Engineering Chemistry Laboratory</li>


                            </ul>


                            <br></br>

                            <h4 className="card-title  mb-2">Sophisticated instruments </h4>
                            <ul className="ps-0 listing-info">
                              <li>400 MHz NMR Spectrometer with solid probe</li>
                              <li>LC-HRMS analyzer</li>
                              <li>FTIR spectrometer </li>
                              <li>ESR Spectrometer</li>
                              <li>UV-VIS Spectrometer (DRS facility)</li>
                              <li>CHNS-O Elemental Analyzer</li>
                              <li>High temperature programmable furnace</li>
                              <li>HPLC analyzer</li>
                              <li>High speed cooling centrifuge</li>
                              <li>Polarographic Analyzer/Stripping Voltammeter</li>
                              <li>Electrochemical work station</li>
                              <li>Gas Chromatography</li>
                              <li>Flash Chromatography</li>
                              <li>TGA-DTA Analyzer</li>
                              <li>Digital polarimeter</li>

                            </ul>



                          </div>
                        </div>
                        <div className="col-md-6"> <img src="CHEDeptAssets/images/che-lab.jpg"
                          alt="" className="img-fluid" /> </div>
                      </div>
                    </div>



                    {/* <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Library</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">


                                                    <h4 className="card-title  mb-2">Departmental Library</h4>
                                                        <ul className="ps-0 listing-info">
                                                            <li>Textbooks</li>
                                                            <li>Popular Science Magazines</li>
                                                            <li>Review Articles</li>
                                                            <li>Reprints</li>
                                                            <li>Student Dissertations and Seminar Papers</li>
                                                            <li>Ph.D. Theses</li>
                                                            
                                                        </ul>

<br></br>
                                                        <h4 className="card-title  mb-2">Journals Available Online</h4>
                                                        <ul className="ps-0 listing-info">
                                                            <li>All journals are available through the “INDEST” Scheme of MHRD, Govt. of India</li>
                                                            
                                                            
                                                        </ul>




                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CHEDeptAssets/images/che-lib.jpg"
                                                    alt="" className="img-fluid" /> </div>
                                            </div>
                                        </div> */}





                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                  aria-labelledby="v-pills-Faculty-tab">
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-faculty"
                      aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>

                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                      id="nav-NonTeachingStaff-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                      Staff</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                      data-toggle="tab" role="tab"
                      aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                  </div>
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                      aria-labelledby="nav-faculty-tab">
                      {/*<h4 className=" mb-4">Faculty</h4>*/}
                      {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                      {/* <section id="profile-info">


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16379"
                              target="_blank"> <img src="CHEDeptAssets/images/1.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16379"
                                    target="_blank">Prof. D. Kashinath</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> +91 8332969502, +91 7013242201 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kashinath@nitw.ac.in">kashinath@nitw.ac.in</a> </p>
                                  <p>Development of Synthetic Methods using Green Chemistry Concepts (use of water, ionic liquids, deep eutectic solvents as reaction media), Homogeneous, heterogeneous and Organocatalysis, Catalyst-free reactions, Synthesis of biologically active molecules for alpha-glucosidase inhibition (Type 2 diabetes), acetylcholinesterase and butyrylcholinesterase inhibition (Alzheimer's )</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-kvgobichem"
                              target="_blank"> <img src="CHEDeptAssets/images/2.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-kvgobichem"
                                    target="_blank">Prof. Gobi K V</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9701047107, 8332969497 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kvgobichem@nitw.ac.in">kvgobichem@nitw.ac.in</a>
                                   
                                  </p>
                                  <p>Electrochemical Biosensors, Synthesis of Carbon-Metallic Nanocomposites, Development of Electrocatalytic Materials for Fuel Cells, Molecular Imprinted Materials, Surface Plasmon Resonance Sensors</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-jugun"
                              target="_blank"> <img src="CHEDeptAssets/images/12.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-jugun"
                                    target="_blank">Prof. Jugun Prakash Chinta</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9898625503 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jugun@nitw.ac.in">jugun@nitw.ac.in
                                    </a> </p>
                                  <p>Supramolecular; Bio-Inorganic Chemistry; Medicinal Inorganic Chemistry; Bio-analytical chemistry</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16585"
                              target="_blank"> <img src="CHEDeptAssets/images/8.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16585"
                                    target="_blank">Prof. K. Hari Prasad</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9550803036 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:harikokatla@nitw.ac.in">harikokatla@nitw.ac.in </a>
                                  </p>
                                  <p>Organic Synthesis, Carbohydrate Chemistry and Medicinal Chemistry</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16896"
                              target="_blank"> <img src="CHEDeptAssets/images/11.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16896"
                                    target="_blank">Prof. M. Raghasudha</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 9550083100 <br />
                                    <i className="fa fa-envelope"
                                      aria-hidden="true"></i> <a
                                        href="mailto:raghas13chem@nitw.ac.in">raghas13chem@nitw.ac.in</a> </p>
                                  <p>Materials Chemistry; Nanoferrites and Composites; Magnetic and Electrical Study ; Photocatalytic Study; Catalytic Study, Antimicrobial study Biological study</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17055"
                              target="_blank"> <img src="CHEDeptAssets/images/14.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17055"
                                    target="_blank">Prof. Mukul Pradhan</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 09732329176 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:mukulchem@nitw.ac.in">  mukulchem@nitw.ac.in </a>
                                  </p>
                                  <p>Novel composite nanomaterials synthesis for energy harvesting(catalyst for generation of hydrogen from water), oxygen reduction reaction and storage (super-capacitor & battery) application. Synthesis of anisotropic metals, metal oxides, metal chalcogenides and their composites for SERS and electrochemical based sensing of biomolecules and toxic metal ions.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-venkatathrin"
                              target="_blank"> <img src="CHEDeptAssets/images/5.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-venkatathrin"
                                    target="_blank">Prof. N. Venkatathri</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9491319976 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:venkatathrin@nitw.ac.in">venkatathrin@nitw.ac.in</a>
                                    
                                  </p>
                                  <p>Materials Chemistry (Porous Materials, Nano Materials & Biomaterials); Heterogeneous Catalysis (Batch reactor, Gas phase reactions, Photocatalysis, Electrocatalysis & One pot multi component synthesis) and Electrochemistry (Cyclic voltammetry and Polarography).</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16902"
                              target="_blank"> <img src="CHEDeptAssets/images/9.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16902"
                                    target="_blank">Prof. Nagarajan S</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9940430715 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:snagarajan@nitw.ac.in">snagarajan@nitw.ac.in</a>
                                  </p>
                                  <p>Organic Chemistry - Organic Materials: Self-assembly; Molecular gels; Carbohydrate Chemistry; Renewable Resources.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-raghuchitta"
                              target="_blank"> <img src="CHEDeptAssets/images/6.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-raghuchitta"
                                    target="_blank">Prof. Raghu Chitta</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8290529060 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:raghuchitta@nitw.ac.in">raghuchitta@nitw.ac.in</a> </p>
                                  <p>Synthesis and Study of Organic and Organometallic Light Harvesting Compounds for Artificial Photosynthetic Systems, Dye Sensitized and Organic Solar Cells, Photo-driven Water Splitting, and Fluorescent Chemosensors</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17034"
                              target="_blank"> <img src="CHEDeptAssets/images/15.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17034"
                                    target="_blank">Prof. Rajeshkhanna Gaddam</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7092158884 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:grkhanna@nitw.ac.in">
                                      grkhanna@nitw.ac.in </a> </p>
                                  <p>Nanoscience and Nanotechnology; Materials Chemistry, Synthesis of Nanostructured Materials such as Metal Oxides, Metal Chalcogenides, Mxenes, Metal-Organic Framework-Derived materials. Carbon Materials and their Composites; Electrocatalysis, Energy Storage and Conversion Systems such as Supercapacitors (Solid-State) and Fuel Cells (MOR & ORR); Electrochemical Water Splitting (OER & HER); Electrochemical Sensors; Water Purification </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-ravinder_pawar"
                              target="_blank"> <img src="CHEDeptAssets/images/13.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-ravinder_pawar"
                                    target="_blank">Prof. Ravinder Pawar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9490917338 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ravinder_pawar@nitw.ac.in">ravinder_pawar@nitw.ac.in</a>
                                  </p>
                                  <p>Computational and Theoretical Chemistry: (1) Two-dimensional Metals and Materials (2) Non-covalent interactions (Unusual hydrogen bonding) (3) Reaction mechanism</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17092"
                              target="_blank"> <img src="CHEDeptAssets/images/10.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17092"
                                    target="_blank">Prof. Santhosh Penta</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8305654728, 9247338076 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:spenta@nitw.ac.in">spenta@nitw.ac.in</a>
                                  </p>
                                  <p> Organic Synthesis Heterocyclic Synthesis Medicinal Chemistry C-H activations (Metal and Metal free)</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-basavojusrinivas"
                              target="_blank"> <img src="CHEDeptAssets/images/7.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-basavojusrinivas"
                                    target="_blank">Prof. Srinivas Basavoju</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9703351571 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i>
                                    
                                    <a
                                      href="mailto:basavojusrinivas@nitw.ac.in"> basavojusrinivas@nitw.ac.in</a>
                                  </p>
                                  <p>Organic Synthesis, Supramolecular Chemistry and Crystal Engineering, Polymorphism, Pharmaceutical cocrystals.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17035"
                              target="_blank"> <img src="CHEDeptAssets/images/16.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17035"
                                    target="_blank">Prof. V. Rajeshkumar</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9087070774
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:rajeshv@nitw.ac.in"> rajeshv@nitw.ac.in</a> </p>
                                  <p>Organic synthesis and metal catalysis; green chemistry; synthesis of bioactive molecules for biological applications; mechanochemistry; photocatalysis ang Natural product synthesis</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi"
                              target="_blank"> <img src="CHEDeptAssets/images/3.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi"
                                    target="_blank">Prof. Venkata Srilakshmi P</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969498 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:patrisrilakshmi@nitw.ac.in">patrisrilakshmi@nitw.ac.in</a>
                                  </p>
                                  <p>Bio-organic Chemistry, Medicinal Chemistry, Drug Delivery and Gene Delivery</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-vishnu"
                              target="_blank" className="img-fluid"> <img
                                src="CHEDeptAssets/images/4.jpg" alt="" className="img-fluid" /> </a>
                            </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-vishnu"
                                    target="_blank">Prof. Vishnu Shanker</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702468675 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vishnu@nitw.ac.in">vishnu@nitw.ac.in</a>
                                  </p>
                                  <p>Materials Chemistry, Nanostructured Materials, Nanocomposites, Photocatalysis</p>
                                </div>
                              </div>
                            </div>
                          </div>





                        </div>
                      </section> */}
                      <section id="profile-info">


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-kashinath"
                              target="_blank"> <img src="CHEDeptAssets/images/1.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-kashinath"
                                    target="_blank">Prof. D. Kashinath</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> +91 8332969502, +91 7013242201 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kashinath@nitw.ac.in">kashinath@nitw.ac.in</a> </p>
                                  <p>Development of Synthetic Methods using Green Chemistry Concepts (use of water, ionic liquids, deep eutectic solvents as reaction media), Homogeneous, heterogeneous and Organocatalysis, Catalyst-free reactions, Synthesis of biologically active molecules for alpha-glucosidase inhibition (Type 2 diabetes), acetylcholinesterase and butyrylcholinesterase inhibition (Alzheimer's )</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-kvgobichem"
                              target="_blank"> <img src="CHEDeptAssets/images/2.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-kvgobichem"
                                    target="_blank">Prof. Gobi K V</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9701047107, 8332969497 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kvgobichem@nitw.ac.in">kvgobichem@nitw.ac.in</a>
                                    {/* , <a
                                                        href="mailto:drkvgobi@gmail.com">drkvgobi@gmail.com</a> */}
                                  </p>
                                  <p>Electrochemical Biosensors, Synthesis of Carbon-Metallic Nanocomposites, Development of Electrocatalytic Materials for Fuel Cells, Molecular Imprinted Materials, Surface Plasmon Resonance Sensors</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-jugun"
                              target="_blank"> <img src="CHEDeptAssets/images/12.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-jugun"
                                    target="_blank">Prof. Jugun Prakash Chinta</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9898625503 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jugun@nitw.ac.in">jugun@nitw.ac.in
                                    </a> </p>
                                  <p>Supramolecular; Bio-Inorganic Chemistry; Medicinal Inorganic Chemistry; Bio-analytical chemistry</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-harikokatla"
                              target="_blank"> <img src="CHEDeptAssets/images/8.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-harikokatla"
                                    target="_blank">Prof. K. Hari Prasad</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9550803036 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:harikokatla@nitw.ac.in">harikokatla@nitw.ac.in </a>
                                  </p>
                                  <p>Organic Synthesis, Carbohydrate Chemistry and Medicinal Chemistry</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-raghas13chem"
                              target="_blank"> <img src="CHEDeptAssets/images/11.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-raghas13chem"
                                    target="_blank">Prof. M. Raghasudha</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 9550083100 <br />
                                    <i className="fa fa-envelope"
                                      aria-hidden="true"></i> <a
                                        href="mailto:raghas13chem@nitw.ac.in">raghas13chem@nitw.ac.in</a> </p>
                                  <p>Materials Chemistry; Nanoferrites and Composites; Magnetic and Electrical Study ; Photocatalytic Study; Catalytic Study, Antimicrobial study Biological study</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-mukulchem"
                              target="_blank"> <img src="CHEDeptAssets/images/14.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-mukulchem"
                                    target="_blank">Prof. Mukul Pradhan</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 09732329176 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:mukulchem@nitw.ac.in">  mukulchem@nitw.ac.in </a>
                                  </p>
                                  <p>Novel composite nanomaterials synthesis for energy harvesting(catalyst for generation of hydrogen from water), oxygen reduction reaction and storage (super-capacitor & battery) application. Synthesis of anisotropic metals, metal oxides, metal chalcogenides and their composites for SERS and electrochemical based sensing of biomolecules and toxic metal ions.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-venkatathrin"
                              target="_blank"> <img src="CHEDeptAssets/images/5.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-venkatathrin"
                                    target="_blank">Prof. N. Venkatathri</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9491319976 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:venkatathrin@nitw.ac.in">venkatathrin@nitw.ac.in</a>
                                    {/* , <a
                                                            href="mailto:venkatathrin@yahoo.com">venkatathrin@yahoo.com</a> */}
                                  </p>
                                  <p>Materials Chemistry (Porous Materials, Nano Materials & Biomaterials); Heterogeneous Catalysis (Batch reactor, Gas phase reactions, Photocatalysis, Electrocatalysis & One pot multi component synthesis) and Electrochemistry (Cyclic voltammetry and Polarography).</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-snagarajan"
                              target="_blank"> <img src="CHEDeptAssets/images/9.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-snagarajan"
                                    target="_blank">Prof. Nagarajan S</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9940430715 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:snagarajan@nitw.ac.in">snagarajan@nitw.ac.in</a>
                                  </p>
                                  <p>Organic Chemistry - Organic Materials: Self-assembly; Molecular gels; Carbohydrate Chemistry; Renewable Resources.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-raghuchitta"
                              target="_blank"> <img src="CHEDeptAssets/images/6.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-raghuchitta"
                                    target="_blank">Prof. Raghu Chitta</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8290529060 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:raghuchitta@nitw.ac.in">raghuchitta@nitw.ac.in</a> </p>
                                  <p>Synthesis and Study of Organic and Organometallic Light Harvesting Compounds for Artificial Photosynthetic Systems, Dye Sensitized and Organic Solar Cells, Photo-driven Water Splitting, and Fluorescent Chemosensors</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-grkhanna"
                              target="_blank"> <img src="CHEDeptAssets/images/15.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-grkhanna"
                                    target="_blank">Prof. Rajeshkhanna Gaddam</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7092158884 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:grkhanna@nitw.ac.in">
                                      grkhanna@nitw.ac.in </a> </p>
                                  <p>Nanoscience and Nanotechnology; Materials Chemistry, Synthesis of Nanostructured Materials such as Metal Oxides, Metal Chalcogenides, Mxenes, Metal-Organic Framework-Derived materials. Carbon Materials and their Composites; Electrocatalysis, Energy Storage and Conversion Systems such as Supercapacitors (Solid-State) and Fuel Cells (MOR & ORR); Electrochemical Water Splitting (OER & HER); Electrochemical Sensors; Water Purification </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-ravinder_pawar"
                              target="_blank"> <img src="CHEDeptAssets/images/13.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-ravinder_pawar"
                                    target="_blank">Prof. Ravinder Pawar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9490917338 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ravinder_pawar@nitw.ac.in">ravinder_pawar@nitw.ac.in</a>
                                  </p>
                                  <p>Computational and Theoretical Chemistry: (1) Two-dimensional Metals and Materials (2) Non-covalent interactions (Unusual hydrogen bonding) (3) Reaction mechanism</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-spenta"
                              target="_blank"> <img src="CHEDeptAssets/images/10.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-spenta"
                                    target="_blank">Prof. Santhosh Penta</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8305654728, 9247338076 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:spenta@nitw.ac.in">spenta@nitw.ac.in</a>
                                  </p>
                                  <p> Organic Synthesis Heterocyclic Synthesis Medicinal Chemistry C-H activations (Metal and Metal free)</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-basavojusrinivas"
                              target="_blank"> <img src="CHEDeptAssets/images/7.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-basavojusrinivas"
                                    target="_blank">Prof. Srinivas Basavoju</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9703351571 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i>
                                    {/* <a
                                                            href="mailto:basavoju_s@yahoo.com">basavoju_s@yahoo.com</a>,  */}
                                    <a
                                      href="mailto:basavojusrinivas@nitw.ac.in"> basavojusrinivas@nitw.ac.in</a>
                                  </p>
                                  <p>Organic Synthesis, Supramolecular Chemistry and Crystal Engineering, Polymorphism, Pharmaceutical cocrystals.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-rajeshv"
                              target="_blank"> <img src="CHEDeptAssets/images/16.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-rajeshv"
                                    target="_blank">Prof. V. Rajeshkumar</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9087070774
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:rajeshv@nitw.ac.in"> rajeshv@nitw.ac.in</a> </p>
                                  <p>Organic synthesis and metal catalysis; green chemistry; synthesis of bioactive molecules for biological applications; mechanochemistry; photocatalysis ang Natural product synthesis</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi"
                              target="_blank"> <img src="CHEDeptAssets/images/3.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi"
                                    target="_blank">Prof. Venkata Srilakshmi P</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969498 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:patrisrilakshmi@nitw.ac.in">patrisrilakshmi@nitw.ac.in</a>
                                  </p>
                                  <p>Bio-organic Chemistry, Medicinal Chemistry, Drug Delivery and Gene Delivery</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cy-vishnu"
                              target="_blank" className="img-fluid"> <img
                                src="CHEDeptAssets/images/4.jpg" alt="" className="img-fluid" /> </a>
                            </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-vishnu"
                                    target="_blank">Prof. Vishnu Shanker</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702468675 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vishnu@nitw.ac.in">vishnu@nitw.ac.in</a>
                                  </p>
                                  <p>Materials Chemistry, Nanostructured Materials, Nanocomposites, Photocatalysis</p>
                                </div>
                              </div>
                            </div>
                          </div>





                        </div>
                      </section>
                    </div>
                    <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                      aria-labelledby="nav-NonTeachingStaff-tab">
                      <div className="row academic_group">


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st11.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sunil Kumar P S</h6> <span>Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 8891250975 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:sunilkps@nitw.ac.in">sunilkps@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st1.jpg" alt=""
                                className="img-fluid" />
                              </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Atanu Sahoo</h6> <span>Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i>  7980325752<br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:Atanus@nitw.ac.in"> Atanus@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st10.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shirshendu Mondal</h6> <span>Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 8436323469 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:shirshendum@nitw.ac.in"> shirshendum@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st7.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Perala Abhivardhan</h6> <span>Technician (Electrical)</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 8185805707 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:peralaa@nitw.ac.in"> peralaa@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st12.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">T KiranKumar</h6> <span>Assistant SG-I</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 8332969465 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:kirankumarthathari25@nitw.ac.in">kirankumarthathari25@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st9.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0"> Guntupalli Santhosh </h6> <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 8332969642 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:santhoshg@nitw.ac.in"> santhoshg@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st6.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">P Heerulal</h6> <span>Junior Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 9948302455 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:pheerulal@nitw.ac.in">pheerulal@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st2.jpg" alt=""
                                className="img-fluid" />
                              </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">B Sadanandam</h6> <span>Care-Taker SG-II</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 8332969607 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:bokkasadanandam@nitw.ac.in">bokkasadanandam@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st8.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Ch Ramesh Babu</h6> <span>Junior assistant (Upgraded)</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 8332969725
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st4.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Md.Shaheen begum</h6> <span>Junior Assistant (UG)</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 8332969505 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:mdshaheen@nitw.ac.in">mdshaheen@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/K._Rajaiah_2024-3-7-16-57-14.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">K. Rajaiah</h6>
                                <span>Technician (UG)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 0870-2462201/ 9985063805 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kinnerarajaiah5@gmail.com">kinnerarajaiah5@gmail.com</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>












                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CHEDeptAssets/images/st3.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Jannu Praveen</h6> <span>Lab Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    <i className="fa fa-phone" aria-hidden="true"></i> 9573206026 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:j.praveenraj4u@gmail.com">j.praveenraj4u@gmail.com</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>

                    </div>
                    <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                      aria-labelledby="nav-ResearchScholars-tab">
                      {/*<h4 className="">Ph.D. Awardee</h4>
                    <p>
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}






                      <div className="row phd-std-row">



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img ">
                              <img src="CHEDeptAssets/images/phd1.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                            <div className="std-details ">
                            <p>
                            Research Area : <b></b>
                            </p>
                            </div>
                            </div> */}
                            </div>
                            <h6 className="std-title">A. Chandra Mohan</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9676085194 <br />  <a href="mailto:ac_717168@student.nitw.ac.in">ac_717168@student.nitw.ac.in</a> </p>
                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd2.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b>Computational Neuroscience</b>
                               </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Aarti Gautam</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  8077013529 <br />  <a href="mailto:aartigautam1993@gmail.com">aartigautam1993@gmail.com</a> </p>
                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd3.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b>Cryptography</b>
                              </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Akanksha Sangolkar</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9595130850
                              <br />  <a href="mailto:ark26011_jrf@nitw.ac.in" >ark26011_jrf@nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd4.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b>Social Network Analysis</b>
                              </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Akash Kumar</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  8409866221
                              <br />  <a href="mailto:Ak712173@student.Nitw.ac.in">Ak712173@student.Nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd5.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                                Research Area : <b>Distributed Algorithms</b>
                              </p>
                            </div>
                            </div> */}
                            </div>
                            <h6 className="std-title">Amala K P</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9746727516
                              <br />  <a href="mailto:ak21cyrer08@student.nitw.ac.in">ak21cyrer08@student.nitw.ac.in</a> </p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd6.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b>Big Data Analytics</b>
                              </p>
                              </div>
                             </div> */}
                            </div>
                            <h6 className="std-title">Anindya Roy</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9475460828
                              <br />  <a href="mailto:ar720110@student.nitw.ac.in" >ar720110@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd7.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b>Computer Vision and Image Processing</b>
                              </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Ankita Parida</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9337667283
                              <br />  <a href="mailto:ap23cyr1r08@student.nitw.ac.in" >ap23cyr1r08@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd8.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b>Big Data Analytics</b>
                              </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Anshu Kumari</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9931739703
                              <br />  <a href="mailto:ak22cyr2r06@student.nitw.ac.in">ak22cyr2r06@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd9.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                                Research Area : <b>Software Defined Networks </b>
                               </p>
                               </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Apurba Borah</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  8721021510
                              <br />  <a href="mailto:ab712183@student.nitw.ac.in">ab712183@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd10.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                                <p>
                                Research Area : <b>Mobile computing and security </b>
                               </p>
                                </div>
                                </div> */}
                            </div>
                            <h6 className="std-title">Arockiaraj. M</h6>
                            <span>Full-Time Research Scholar (IDRBT)  </span>
                            <hr />
                            <p className="contact-info">  9843485106
                              <br />  <a href="mailto:am712171@student.nitw.ac.in" >am712171@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd11.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                                Research Area : <b>Privacy Preserving Data Publishing </b>
                              </p>
                              </div>
                            </div> */}
                            </div>
                            <h6 className="std-title">Avinash Sharma</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  8580413106
                              <br />  <a href="mailto:as712175@student.nitw.ac.in">as712175@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd12.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b>Machine Learning </b>
                              </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Ayushi Singh</h6>
                            <span>Full-Time Research Scholar (IDRBT) </span>
                            <hr />
                            <p className="contact-info">  8318244896
                              <br />  <a href="mailto:as23cyr1r04@student.nitw.ac.in">as23cyr1r04@student.nitw.ac.in</a> </p>
                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd13.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b>Service Oriented Architecture </b>
                              </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">B. Sravanthi</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  6303427328
                              <br />  <a href="mailto:sb718166@student.nitw.ac.in">sb718166@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd14.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                               Research Area : <b>Cryptography  </b>
                              </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Bhookya Thirupathi</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9533125661
                              <br />  <a href="mailto:bb22cyr1r05@student.nitw.ac.in" >bb22cyr1r05@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd15.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                               <p>
                                Research Area : <b>Big Data Analytics  </b>
                                 </p>
                             </div>
                            </div> */}
                            </div>
                            <h6 className="std-title">Boligorla Anjaiah</h6>
                            <span>Full-Time Research Scholar (IDRBT)    </span>
                            <hr />
                            <p className="contact-info">  7780232011
                              <br />  <a href="mailto:anjaiahchem@student.nitw.ac.in" >anjaiahchem@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd16.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                              Research Area : <b></b>
                               </p>
                              </div>
                            </div> */}
                            </div>
                            <h6 className="std-title">Ch.Shruthilaya</h6>
                            <span>Full-Time Research Scholar      </span>
                            <hr />
                            <p className="contact-info"> 8522040279 <br /><a href="mailto:cs22cyr1r02@student.nitw.ac.in" >cs22cyr1r02@student.nitw.ac.in</a> </p>
                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd17.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                              <div className="std-details ">
                              <p>
                                Research Area : <b></b>
                              </p>
                              </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Chuncha Vijaykumar</h6>
                            <span>Full-Time Research Scholar (IDRBT)</span>
                            <hr />
                            <p className="contact-info"> 9491012880 <br /><a href="mailto:cv21cyrer07@student.nitw.ac.in" >cv21cyrer07@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd18.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Graph Algorithms   </b>
                  </p>
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">G. Srinath</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  8985133843  <br /><a href="mailto:srichem@student.nitw.ac.in" >srichem@student.nitw.ac.in</a>   </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd19.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Cancellable Biometrics   </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Ganesh Sankari. S</h6>
                            <span>Full-Time Research Scholar (IDRBT)    </span>
                            <hr />
                            <p className="contact-info">  9092210100
                              <br />  <a href="mailto:gs23cyr1r01@student.nitw.ac.in" >gs23cyr1r01@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd20.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining and Machine Learning  </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Gargi Singh</h6>
                            <span>Full-Time Research Scholar       </span>
                            <hr />
                            <p className="contact-info">  9711852928
                              <br />  <a href="mailto:gs712179@student.nitw.ac.in" >gs712179@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd21.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Application Security in Computer Networks  </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Gayathri B Kurup</h6>
                            <span>Full-Time Research Scholar (IDRBT)  </span>
                            <hr />
                            <p className="contact-info">  9496101524
                              <br />  <a href="mailto:gb22cyr1r01@student.nitw.ac.in" >gb22cyr1r01@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd22.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Software Engineering and Machine Learning</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Inapanuri Madhu</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9398457851
                              <br />  <a href="mailto:im22cyr2r01@student.nitw.ac.in" >im22cyr2r01@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd23.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Bioinformatics, Big Data Analytics</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Jakke Amulya</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  9032745961
                              <br />  <a href="mailto:ja22cyr2r03@student.nitw.ac.in">ja22cyr2r03@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/noimage.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Wireless vehicular sensor networks </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Jalagam Swathi</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  9573738315
                              <br />  <a href="mailto:js720114@student.nitw.ac.in" >js720114@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd25.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Image Processing  </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">K. Madhu</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  9963796312
                              <br />  <a href="mailto:kmadhuchem@student.nitw.ac.in" >kmadhuchem@student.nitw.ac.in</a> </p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd26.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Location Based Services for Network Applications   </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Kadiyam Rama Krishna</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  8919855969
                              <br />  <a href="mailto:kr21cyrer01@student.nitw.ac.in" >kr21cyrer01@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd27.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Security & Privacy   </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Karingula Sampath</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  8970645052
                              <br />  <a href="mailto:kskumar@student.nitw.ac.in" >kskumar@student.nitw.ac.in </a>
                            </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd28.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Khushboo Agarwala</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9706434844
                              <br />  <a href="mailto:ka721113@student.nitw.ac.in" >ka721113@student.nitw.ac.in</a>
                            </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd29.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>IOT and Big Data   </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Mohmmad Faizan</h6>
                            <span>Full-Time Research Scholar      </span>
                            <hr />
                            <p className="contact-info">  7891786142
                              <br />  <a href="mailto:mf712178@student.nitw.ac.in">mf712178@student.nitw.ac.in</a>
                            </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd30.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Biometrics</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Naddi Nagaraju</h6>
                            <span>Full-Time Research Scholar (IDRBT)</span>
                            <hr />
                            <p className="contact-info">  9392131298
                              <br /> <a href="mailto:nn23cyr1r10@student.nitw.ac.in">nn23cyr1r10@student.nitw.ac.in</a>
                            </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd31.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Information Security   </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Neetika Singh</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 8423718565
                              <br />  <a href="mailto:ns22cyr1s01@student.nitw.ac.in" >ns22cyr1s01@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd32.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Image Processing   </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">P. Vijay Kumar</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  8143630910
                              <br />  <a href="mailto:pv23cyr1r03@student.nitw.ac.in" >pv23cyr1r03@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd33.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Big Data Analytics   </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Pooja</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  8827833253
                              <br />  <a href="mailto:pp720111@student.nitw.ac.in" >pp720111@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd34.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining   </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Priya Indulkar</h6>
                            <span>Full-Time Research Scholar (External)  </span>
                            <hr />
                            <p className="contact-info">  9766196433
                              <br />  <a href="mailto:pn23cyr2r02@student.nitw.ac.in" >pn23cyr2r02@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd35.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Privacy Preserving of Data in Cloud Computing    </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">R. Arun Kumar</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9912516604
                              <br />  <a href="mailto:arunpv@student.nitw.ac.in" >arunpv@student.nitw.ac.in</a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd36.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Bioinformatics</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Ramay Patra</h6>
                            <span>Full-Time Research Scholar (External)  </span>
                            <hr />
                            <p className="contact-info">  6370259998
                              <br />  <a href="mailto:rp712172@student.nitw.ac.in" >rp712172@student.nitw.ac.in </a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd37.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Ravindar Daravath</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  9618875161
                              <br />  <a href="mailto:rd22cyr2r05@student.nitw.ac.in" >rd22cyr2r05@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd38.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining with Big Data</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Sasi sree Marupalli</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  8331810790
                              <br />  <a href="mailto:sm712181@student.nitw.ac.in" >sm712181@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd39.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine learning, Data science, big data, spiking neural network </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Srikanth Bandi</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 9666311124
                              <br />  <a href="mailto:sb720113@student.nitw.ac.in">sb720113@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd40.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Image Processing</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Subir Majhi</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  8777682422
                              <br />  <a href="mailto:sm712174@student.nitw.ac.in" >sm712174@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd41.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Big Data</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Sumit</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  8930880788
                              <br />  <a href="mailto:ss22cyr1r07@student.nitw.ac.in">ss22cyr1r07@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd42.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Internet of Things and Block chain</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">T. Shirisha</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  9177409213
                              <br />  <a href="mailto:shirishat@student.nitw.ac.in" >shirishat@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd43.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Medical imaging</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Tohira Banoo</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  7006017268
                              <br />  <a href="mailto:tb721107@student.nitw.ac.in" >tb721107@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd44.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Biometrics</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">V Rukya Naik</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  7780420761
                              <br />  <a href="mailto:vr720116@student.nitw.ac.in" >vr720116@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd45.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Computer Vision and Machine Learning </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Vara Prasad Rebaka</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  9443471397
                              <br />  <a href="mailto:vara97@student.nitw.ac.in" >vara97@student.nitw.ac.in</a>
                            </p>
                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd46.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Soft Computing Techniques </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Vasikaran A</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  8148399306
                              <br />  <a href="mailto:va23cyr1r06@student.nitw.ac.in">va23cyr1r06@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd47.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Distributed Data Mining and Data Analytics  </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Vijay M</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  9600164774
                              <br />  <a href="mailto:vm712180@student.nitw.ac.in" >vm712180@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd48.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining,Big Data Analytics </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Yogendra Kumar</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  8826969537
                              <br />  <a href="mailto:yk22cyr1r06@student.nitw.ac.in" >yk22cyr1r06@student.nitw.ac.in</a> </p>
                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd49.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Image Processing</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> B Kiran Kumar</h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  9573123933
                              <br />  <a href="mailto:bk721110@student.nitw.ac.in" >bk721110@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd50.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>wireless networks</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> B Murali </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  9948515986
                              <br />  <a href="mailto:bm22cyr2p06@student.nit.ac.in" >bm22cyr2p06@student.nit.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd51.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine learning</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Chinthakunta Nagaraju  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  9908594568
                              <br />  <a href="mailto:cn22cyr2p07@student.nitw.ac.in" >cn22cyr2p07@student.nitw.ac.in </a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd52.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Software Testing and Program Analysis </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Fouzia begum </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  8390792833
                              <br />  <a href="mailto:mf23cyr1p04@student.nitw.ac.in">mf23cyr1p04@student.nitw.ac.in</a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd53.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Big Data, Internet of Things  </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> G Mahesh kumar  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  9581788030
                              <br />  <a href="mailto:gm22cyr1p02@student.nitw.ac.in" >gm22cyr1p02@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd54.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Golla Venkata Satyanarayana </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 9491252460
                              <br />  <a href="mailto:gv22cyr2p03@student.nitw.ac.in" >gv22cyr2p03@student.nitw.ac.in</a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd55.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Bioinformatics </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> H. Pallavi   </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 7680937377
                              <br />  <a href="mailto:bp720083@student.nitw.ac.in">ph721106@student.nitw.ac.in </a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd56.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Soft computing </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Jayaram Nallamudi </h6>
                            <span>Part-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info"> 9110391866
                              <br />  <a href="mailto:jj720117@student.nitw.ac.in" >jj720117@student.nitw.ac.in </a> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd57.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Big Data, High Performance Computing, Machine Learning, Deep Learning  </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Karunakar Rudra</h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info"> 9160494448
                              <br />  <a href="mailto:kr21cyrep08@student.nitw.ac.in" >kr21cyrep08@student.nitw.ac.in </a> </p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd58.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>IoT </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Kiran Aluri </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 9949379192
                              <br /> <a href="mailto:ka720119@student.nitw.ac.in">ka720119@student.nitw.ac.in</a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd59.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Cloud Computing, Fog Computing, Algorithms, Computer Networks</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> M. Bhuvaneshwari  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 9393043796
                              <br /> <a href="mailto:bm21cyrep03@student.nitw.ac.in">bm21cyrep03@student.nitw.ac.in</a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd60.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data mining, Data Analytics, Machine learning, IOT </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Medaboina Srinivas  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 9704507675
                              <br /> <a href="mailto:ms21cyrep04@student.nitw.ac.in">ms21cyrep04@student.nitw.ac.in</a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd61.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Cryptography, distributed computing, IOT </b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Poolaiah Kombaiah  </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 9164694422
                              <br /> <a href="mailto:pk712176@student.nitw.ac.in">pk712176@student.nitw.ac.in</a></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd62.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Cyber Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Rajender Ananthula  </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 8019110699
                              <br /> <a href="mailto:ra22cyr2p05@student.nitw.ac.in">ra22cyr2p05@student.nitw.ac.in</a></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd63.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Rajesh G  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 9985314429
                              <br /> <a href="mailto:rg21cyrep01@student.nitw.ac.in">rg21cyrep01@student.nitw.ac.in</a></p>
                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd64.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Soft Computing</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title">Raju Mamidi</h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 9666017804
                              <br /> <a href="mailto:rr23cyr1p03@student.nitw.ac.in">rr23cyr1p03@student.nitw.ac.in</a></p>
                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd65.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Ramesh Konakalla  </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 7981149111
                              <br /> <a href="mailto:rk22cyr1p04@student.nitw.ac.in">rk22cyr1p04@student.nitw.ac.in</a></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd66.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Soft Computing</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Sathyanarayana Arrori   </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  9491870086
                              <br /> <a href="mailto:sa22cyr2p02@student.nitw.ac.in" > sa22cyr2p02@student.nitw.ac.in </a></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd67.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Shivakumar Punna   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9951442567
                              <br /> <a href="mailto:sk22cyr1p03@student.nitw.ac.in" > sk22cyr1p03@student.nitw.ac.in  </a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd68.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Sridhar Guda  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  8179899120
                              <br /> <a href="mailto:sg21cyrep09@student.nitw.ac.in" > sg21cyrep09@student.nitw.ac.in  </a></p>
                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd69.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Sudhakar Thota  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9542677679
                              <br /> <a href="mailto:st22cyr2P09@student.nitw.ac.in" > st22cyr2P09@student.nitw.ac.in </a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd70.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Suresh babu Namana  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info"> 9849536329
                              <br /> <a href="mailto:sb720120@student.nitw.ac.in"> sb720120@student.nitw.ac.in  </a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/noimage.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Swati Gubbala   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9490720480
                              <br /> <a href="mailto:sk22cyr1p03@student.nitw.ac.in" > sk22cyr1p03@student.nitw.ac.in  </a></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd72.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> T. Madhusudhan Reddy   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9866378386
                              <br /> <a href="mailto:tm21cyrep05@student.nitw.ac.in" > tm21cyrep05@student.nitw.ac.in  </a></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd73.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Tanuja Das </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  8240320430
                              <br /> <a href="mailto:td23cyr1p02@student.nitw.ac" > td23cyr1p02@student.nitw.ac </a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd74.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Thota Preethi Yadav  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9533781812
                              <br /> <a href="mailto:tp22cyr2p08@student.nitw.ac.in"> tp22cyr2p08@student.nitw.ac.in  </a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd75.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Tobin Varkey Simson </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  8078027303
                              <br /> <a href="mailto:tv23cyr2p01@student.nitw.ac.in" > tv23cyr2p01@student.nitw.ac.in  </a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd76.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Varukolu Shanker   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  8099057042
                              <br /> <a href="mailto:vs21cyrep02@student.nitw.ac.in" > vs21cyrep02@student.nitw.ac.in  </a></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CHEDeptAssets/images/phd77.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <h6 className="std-title"> Venkateswara Rao B  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  8919186211
                              <br /> <a href="mailto:vr22cyr2p01@student.nitw.ac.in" > vr22cyr2p01@student.nitw.ac.in </a></p>
                          </div>
                        </div>




                      </div>

                      {/* <div className="row">
  <p className="more"> <a className="more-info-link" href="CHEDeptAssets/docs/organic-chemistry.pdf" target="_blank"> MSc First Year <i className="fa fa-angle-right"></i> </a> </p>
  <p className="more"> <a className="more-info-link" href="CHEDeptAssets/docs/msc-second-year.pdf" target="_blank"> MSc Second Year <i className="fa fa-angle-right"></i> </a> </p>
  </div> */}

                    </div>
                  </div>
                </div>
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                  aria-labelledby="v-pills-Students-tab">




                  <div className="row std-society">


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <a
                          href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                          target="_blank"> <img src="CHEDeptAssets/images/11.jpg" alt="" className="img-fluid" />
                        </a>
                        <div className="data"> <a
                          href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                          target="_blank">
                          <h6>Dr. M. Raghasudha</h6> <span> Faculty In-charge </span>
                          <hr />
                        </a>
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9490165347 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:cse_hod@nitw.ac.in"
                                                            style={{ color: "#808080" }}>cse_hod@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Swatej Sabbarwal</h6> <span> General Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Amartya Sanyal</h6> <span> General Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Deepshikha</h6> <span> Additional Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>




                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Aisika Chakraborty</h6> <span> Additional Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Snigdha Dutta</h6> <span> Additional Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Rajshree Bhuyal</h6> <span> Additional Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Niyas K. K.</h6> <span> Joint Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Shruti Garge</h6> <span> Joint Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Aliva Joshi</h6> <span> Joint Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Shaik Fardin</h6> <span> Joint Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Kajal Saini</h6> <span> Joint Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Akhilesh Bhandare</h6> <span> Joint Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Buddhpriya</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Debasis Satpathy</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Debendra Behera</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Kavyanjali Gorle</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Madhav Pathak</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Vaibhav Sharma</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Neilgreib Nandi</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Nimesh Kanth</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Rahul Jindal</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">
                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Krishna Kumar</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">

                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Ishika Khandelwal</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">

                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Suman Das</h6> <span> Executive Member </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/* <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a> */}
                        <div className="data">

                          {/* <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>R. Arun Kumar</h6> <span> Research scholar Secretary </span>
                                                    <hr />
                                                </a> */}
                          {/* <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p> */}
                        </div>
                      </div>
                    </div>

                    {/* 
                                        <h3>Chemistry Association Activities</h3>
<p>Chemistry association was started long back and serving the department in many ways to develop in all aspects. Association is actively involved in conducting scientific events on various occasions. In the previous year, we have conducted several programmes such as International Ozone day celebrations, invited lectures from well-known scientists from various institutions. Also we are organizing the industrial visits for M.Sc., and Ph.D., students with the help of Training and Placement section. Last year we organized scientific talk by Prof. Kaliyappan from IIT Bombay, Prof Anil kumarSaikia from IIT Guwahati, Prof. Bhaskaran from IIT Madras and Dr. Santhoshkumar from Albany molecular center, Singapore. As a part of industrial tour, we have visited Suven Life Sciences and Natcopharma industries. Very recently, we organized International Ozone Day celebrations at institute level to bring the awareness of the importance of ozone layer. Also, our M.Sc students went to the government high schools to bring the awareness among the school students.Similarly, the association will continue to organise the more events and lectures in the coming days.</p>
<p className="mb-0"><b>Additionally, we are planning for : </b></p>
<ul className="listing-info">
  <li>Industrial Tours</li>
  <li>Expert Lectures</li>
  <li>Workshops</li>
  <li>Quiz Programmes</li>
  <li>Chem. Quests</li>
  <li>Science Popularization</li>
  <li>Communication skills</li>
  <li>Personality development Programmes</li>
  </ul> */}





                  </div>






                </div>
                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                  aria-labelledby="v-pills-NotableAlumni-tab">


                  <div className="row notable-alumni">

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na1.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr. L Krishnakanth Reddy</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Senior Director, Sai Life sciences Ltd, Hyderabad
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na2.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr. Srilakshmi V Patri</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Professor, Department of Chemistry, NITW
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na3.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr. Rudrarapu Aravind</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> SERB-National Post-Doctoral Fellow (NPDF) at BITS Pilani Hyderabad Campus
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na4.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr. Arpit Agrawal</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Completed PhD (organic chemistry) from IIT Bombay
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na5.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Kurra Siva Durga</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Working as Assistant Research Officer (Chemistry) in CCRAS, M/o AYUSH, Govt of India
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na6.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Chinkit Tyagi</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing PhD at University of Rennes, France
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na7.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Naveen Kumar</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Research Scholar 2019- IIT Indore
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na8.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Saikumar M </h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Postdoctoral researcher at CATRIN-RCPTM, Palacky University
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na9.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Nikhil Srivastav</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing PhD at department of Chemistry, Institute of Science, Banaras Hindu University
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na10.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Muskan Bhatnagar</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Chemistry Faculty (JEE, NEET) Bansal Classes PVT. LTD
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na11.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sirat Kaur Gill</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing Ph.D. at DRDE, DRDO Gwalior, MP
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na12.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shubham</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> PhD scholar at IISER Bhopal, Research area:Ultrafast Spectroscopy
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na13.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Manjeet Kumar</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Research associate (R&d dept) at iol Chemical and pharmaceutical barnala punjab.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na14.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sucheta</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing Ph.D at IISER mohali
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na15.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>A Vismaya</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Assistant lecturer of Aakash
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na16.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shubham jain</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Vedantu Master teacher of chemistry neet and jee faculty
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na17.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Arzoo Nirwal</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing PhD at National Yang Ming Chiao Tung University, Taiwan
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na18.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Rubi Agrawal</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Ph.D. at Indian Institute of Science Education and Research, Kolkata  (IISER-K)
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na19.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Amit Barsiwal</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing Ph.D. in Chemical Biology at University of Nebraska-Lincoln, U.S.A.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na20.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Vanditha S</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing PhD at IIT Palakkad
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na21.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Arpit khandelwal</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Asst. Professor in FIITJEE
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na22.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Bhargava Sai</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Working as Associate Scientist in Aragen Life sciences Pvt Ltd.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na23.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Mufeeda M</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing PhD at IIT Madras
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na24.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Ayan Datta</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Working as Project Associate at Hindustan Petroleum Corporation Limited
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na25.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Prashant Pareek</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Assistant Manager at Federal Bank
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na26.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>J Manojkumar</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing Ph.D. at IIT Kanpur
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na27.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Srutashini Das</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing PhD at Texas A & M University, United States
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na28.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Subhashree Priyadarshini</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Chemistry Faculty (Working at PW vidyapeeth, bhubaneswar/ CEO - Alekh pandey )
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na29.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Anjali Garg</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Research Assistant in IIT Roorkee
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na30.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Jyothi K</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing Ph.D at IISc Bangalore in Total synthesis under prof. KR prasad in organic chemistry department.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na31.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Parimala</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Preparing for government jobs
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na32.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Ramniwas</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Teacher in Saraswati Dhaam coaching
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na33.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sonu kumar</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Chemistry Faculty ( Career Point college )
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na34.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Arindam Santra</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Preparing for UPSC CSE.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na35.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Kethavath Rani</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Preparing for government jobs
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na36.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Kanhaiya kushwaha</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Avanti fellows (teacher associate)
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na37.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Priyanshu Dawas</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Assistant professor at fiitjee institute
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na38.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Anuj Kumar Singh</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> MS PhD from University of Minnesota
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na39.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Kavita</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> MS PhD from University of Minnesota
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na40.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Karishma Bagawan</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Faculty at Aakash Educational pvt. Ltd.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na41.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shruti Pandey</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Assistant Lecturer at Aakash Educational Services Limited
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na42.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Adarsh kumar</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Ph.D. from Rowan University, New Jersey, USA (Pharmaceutical chemistry)
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na43.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Aditi Malviya</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Associate lecturer of a Sri Chaitanya institute
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na44.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Devansh Rai</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Chemistry Lecturer,
                            Narayana group of educational Institutions, Bangalore
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na45.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Amit kumar </h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Chemistry lecturer in Narayana coaching institute
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na46.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sohag Singha</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing PhD in Analytical Chemistry at the University of Rhode Island, United States of America (USA)
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CHEDeptAssets/images/na47.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Amartya Sanyal</h6>
                          <p>
                            {/* <b>Batch :</b> PhD<br /> */}
                            <b>Present Position :</b> Pursuing Ph.D. at Ariel University, Israel on the topic of "Fluorescent dyes and conjugates for biomedical applications, Targeted drug delivery, and photodynamic therapy"
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                  aria-labelledby="v-pills-AcademicResearchGroup-tab">
                  {/* <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Big Data Analytics, Artificial Intelligence / Machine Learning, Data Science
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/5.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. RBV Subramanyam</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9491346969 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rbvs66@nitw.ac.in">rbvs66@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/6.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Radhakrishna</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9704988816 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:prkrishna@nitw.ac.in">prkrishna@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/11.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Ramakrishnudu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9866876842 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:trk@nitw.ac.in">trk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/14.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.V.Kadambari</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9705460461<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kvkadambari@nitw.ac.in">kvkadambari@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/22.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. M Srinivas</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8897064421<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msv@nitw.ac.in">msv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/27.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Venkateswara Rao Kagita</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 6281746931 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:venkat.kagita@nitw.ac.in">venkat.kagita@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Security</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R Padmavathy</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9381265691 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rpadma@nitw.ac.in">rpadma@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/21.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.M.Sandhya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9394468554<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msandhya@nitw.ac.in">msandhya@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/23.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Kaveti Ilaiah</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9848916272 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ilaiahkavati@nitw.ac.in">ilaiahkavati@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Networks</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Software Engineering</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/16.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Priyanka Chawla</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7982750652 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:priyankac@nitw.ac.in">priyankac@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/18.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Manjubala Bisi</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9502940360 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">manjubalabisi@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/29.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sangharatna Godboley</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7013306805<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:cse_hod@nitw.ac.in">sanghu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Cloud & Distributed Computing, IOT</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/8.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ch Sudhakar</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440647945 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:chapram@nitw.ac.in">chapram@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/25.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sanjaya Kumar Panda</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9861126947<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sanjay@nitw.ac.in">sanjay@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Algorithms, BioInformatics, Computer Vision, Image Processing, Model-driven
                                                    Frameworks, Soft Computing</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/12.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raju Bhukya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9700553922 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raju@nitw.ac.in">raju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/15.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P V Subba Reddy</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8790590436<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsr@nitw.ac.in">pvsr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/13.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. U S N Raju</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440149146 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:usnraju@nitw.ac.in">usnraju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/19.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ijjina Earnest Paul</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9494466490<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:iep@nitw.ac.in">iep@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/24.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sujit Das</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8536009758<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sujit.das@nitw.ac.in">sujit.das@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/28.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ramalingaswamy Cherukku</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9773827143 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rmlswamy@nitw.ac.in">rmlswamy@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>


                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">
                  <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                    target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                      className="fa fa-link " aria-hidden="true"></i> </a>
                  <h4 className="">Ph.D. On Roll</h4> <a
                    href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                      className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                        className="fa fa-link " aria-hidden="true"></i> </a>
                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                  aria-labelledby="v-pills-PhotoGallery-tab">

                  {/* <div className="details"> 
                                
								<h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span>
								</div> 


                                <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/> 

                                <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5"/> */}





                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                  aria-labelledby="v-pills-Awards-tab">
                </div>



              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                <p>Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default CHEDeptPage;