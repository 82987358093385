import { useState } from "react";
import '../css/cseDeptStyle.css';
const MMEDDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img
                            src="https://nitw.ac.in/api/static/files/MMED_2023-10-4-17-0-59.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected="false">
                                    <span>About</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Society </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a>
                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Laboratory & Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected="false"> <span>Awards and Honours</span> </a>
                            </div>
                            <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/mm-kishorebabu"
                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Kishore_Babu_Nagumothu_2024-4-25-10-39-13.jpg" alt="" className="img-fluid" width="190" height="220" />
                                <h6>Prof. N. Kishore Babu</h6>
                            </a>
                                <p className="justified-text"> Head of the Department <br /> <a href="physics_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>mme_hod@nitw.ac.in</a> <br /> 9490165345 </p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">
                                        {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                                        <p className="justified-text">
                                            The Department of Metallurgical and Materials Engineering (formerly known as Department of Metallurgical Engineering) was established in the year 1965 with an intake of 30 students. A post-graduate programme in Industrial Metallurgy was introduced in the year 1983 with an intake of 10 students. The Department is also offering a Ph. D. programme since 1983. Department has also introduced another M. Tech. programme in Materials Technology from the academic year 2011-2012. In 2023-24 Academic Year, the Department started a new M.Tech. Program in Welding Technology and Non-destructive Testing.                                          </p>

                                        <p className="justified-text">
                                            The main areas of study in Metallurgical and Materials Engineering include Physical Metallurgy, Extractive Metallurgy (Iron, Steel and Non-ferrous Metal extraction), Mineral Processing, Heat Treatment of Steels, Manufacturing Technologies (Metal Casting, Metal Forming, Powder Metallurgy and Welding), Mechanical Metallurgy (includes Material Testing – Destructive and Non-Destructive), Corrosion Engineering, and Materials Characterization Techniques etc. In view of the increasing demand in materials development, the department has introduced new subjects in its curriculum with materials background in the year 2004. Accordingly, the scheme of instruction has been enriched with subjects on advanced materials like Composite Materials, Ceramics, Polymers, Nanomaterials, Computer Applications in Metallurgical and Materials Engineering etc. in addition to the above main areas. The laboratories of the Department are well furnished with the state-of-the-art equipment.


                                        </p>

                                        <img src="https://nitw.ac.in/api/static/files/Department_of_Metallurgical_and_Materials_Engineering_2023-10-27-10-31-52.jpg" alt="" className="img-fluid mb-5" />



                                    </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>
                                            <p className="  mb-4">Attain global recognition in research and training to meet challenging needs of Metallurgical and Materials Engineering with ethical and moral responsibility towards society. </p> <img src="CSEDeptAssets/images/cse.jpg" alt=""
                                                className="img-fluid" />
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">Provide outstanding technical education for analysis, design and operation of metallurgical and materials systems.</p>
                                            <p className="  mb-2">Keep abreast with rapid strides of technology and improve academic standards through innovative teaching and learning processes. </p>
                                            <p className="  mb-2">Engage in quality research in metallurgical, materials and allied engineering areas. </p>
                                            <p className="  mb-2">Develop academic linkages with leading industries for mutual benefit. </p>
                                            <p className="  mb-2">Develop linkages with leading academic, research and industrial organizations for societal benefit. </p>

                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardMME/MetallurgicalandMaterialsEngineering"> Notices/Announcements</a>
                                        </h4>
                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                                    {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div className="row">








                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>B. Tech. </h3>
                                                        <p className="justified-text">B. Tech. in Metallurgical & Materials Engineering programme is of 4 years duration (8 semesters). The course work in the first two semesters is common to all branches of engineering. The core courses are introduced from the 3rd semester onwards. Besides, elective courses are offered from 5th semester onwards. The electives permit one to choose his/her area of interest within the broad areas. The students also carry out project work during the last two semesters based on the knowledge acquired.</p>


                                                    </div>
                                                    <p className="more"> <a className="more-info-link"
                                                        href="https://oldweb.nitw.ac.in/media/uploads/2021/10/23/05_btech-mmed.pdf"
                                                        target="_blank">B.Tech Scheme and Syllabus<i className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>







                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>M. Tech. Materials Technology</h3>
                                                        <p className="justified-text">A post-graduate programme in Materials Technology was introduced in the year 2011 with an intake of 20 students. This programme offers in depth knowledge about Physical Metallurgy, Extractive Metallurgy (Design & production of Iron & Steel), Manufacturing Technologies (Metal Casting, Metal Forming, Powder Metallurgy and Welding), Mechanical Metallurgy (includes Material Testing – Destructive and Non-Destructive), Corrosion Engineering, and Materials Characterization Techniques etc. The students are exposed to core and advanced areas in the first two semesters. Dissertation work is being carried out in the third and fourth semesters.</p>
                                                        {/*<img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>*/}

                                                        <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2021/08/29/2_materials-technology.pdf"
                                                            target="_blank">Materials Technology Scheme and Syllabus <i
                                                                className="fa fa-angle-right"></i> </a> </p>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>






                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>M. Tech. Industrial Metallurgy</h3>
                                                        <p className="justified-text">A post-graduate programme in Industrial Metallurgy was introduced in the year 1983 with an intake of 10 students. This programme offers in depth knowledge about Physical Metallurgy, Extractive Metallurgy (Design & production of Iron & Steel), Manufacturing Technologies (Metal Casting, Metal Forming, Powder Metallurgy and Welding), Mechanical Metallurgy (includes Material Testing – Destructive and Non-Destructive), Corrosion Engineering, and Materials Characterization Techniques etc. In view of the increasing demand in materials development, the department has introduced new subjects in its curriculum with materials background in the year 2004. The students are exposed to core and advanced areas in the first two semesters. Dissertation work is being carried out in the third and fourth semesters.</p>


                                                        {/*<img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>*/}
                                                        <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2021/08/30/1_industrial-metallurgy.pdf"
                                                            target="_blank">Industrial Metallurgy Scheme and Syllabus <i
                                                                className="fa fa-angle-right"></i> </a> </p>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph. D.</h3>



                                                        {/* <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2022/07/06/integrated-msc-physics.pdf"
                                                        target="_blank">For detailed program structure, courses and syllabus offered <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>












                                    </div>

                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div className="row laboratory-facilities">
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Welding Metallurgy Lab</h3>
                                            </header>
                                            <img src="https://nitw.ac.in/api/static/files/Welding_Metallurgy_Lab_2023-10-27-11-35-52.png" alt="" className="img-fluid mb-5" />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card item-card card-block">


                                                        <h3 className="">UPEM Lab</h3>


                                                        <img src="https://nitw.ac.in/api/static/files/UPEM_Lab_2023-10-27-11-37-28.png" alt="" className="img-fluid mb-5" />

                                                        <img src="https://nitw.ac.in/api/static/files/UPEM_Lab_2023-10-27-11-38-10.png" alt="" className="img-fluid mb-5" />


                                                        <h3 className="">Corrosion Lab</h3>


                                                        <img src="https://nitw.ac.in/api/static/files/Corrosion_Lab_2023-10-27-11-40-2.png" alt="" className="img-fluid mb-5" />

                                                        <h3 className="">Fatigue, Creep Labs</h3>


                                                        <img src="https://nitw.ac.in/api/static/files/Fatigue,_Creep_Labs_2023-10-27-11-41-24.png" alt="" className="img-fluid mb-5" />



                                                        <h3 className="">Powder metallurgy lab</h3>


                                                        <img src="https://nitw.ac.in/api/static/files/Powder_metallurgy_lab_2023-10-27-11-42-37.png" alt="" className="img-fluid mb-5" />

                                                        <img src=" https://nitw.ac.in/api/static/files/Powder_metallurgy_lab_2023-10-27-11-43-26.png" alt="" className="img-fluid mb-5" />
                                                        <img src="  https://nitw.ac.in/api/static/files/Powder_metallurgy_lab_2023-10-27-11-44-45.png" alt="" className="img-fluid mb-5" />



                                                        <h3 className="">Physical Metallurgy Lab</h3>


                                                        <img src="https://nitw.ac.in/api/static/files/Physical_Metallurgy_Lab_2023-10-27-11-51-41.jpg" alt="" className="img-fluid mb-5" />


                                                        <img src="https://nitw.ac.in/api/static/files/Physical_Metallurgy_Lab_2023-10-27-11-53-46.jpg" alt="" className="img-fluid mb-5" />


                                                        <h3 className="">Mechanical Metallurgy Lab</h3>


                                                        <img src="https://nitw.ac.in/api/static/files/Mechanical_Metallurgy_Lab_2023-10-27-11-59-46.jpg" alt="" className="img-fluid mb-5" />

                                                        <img src="https://nitw.ac.in/api/static/files/Mechanical_Metallurgy_Lab_2023-10-27-12-4-26.jpg" alt="" className="img-fluid mb-5" />


                                                        <h3 className="">MMED Sophisticated Equipment at CRIF</h3>


                                                        <img src="https://nitw.ac.in/api/static/files/MMED_Sophisticated_Equipment_at_CRIF_2023-10-27-12-6-31.png" alt="" className="img-fluid mb-5" />

                                                    </div>
                                                </div>


                                            </div>
                                        </div>











                                    </div>

                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>







                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor </a>




                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">
                                            {/*<h4 className=" mb-4">Faculty</h4>*/}
                                            {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                                            {/* <section id="profile-info">

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16487"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Ajoy_Kumar_Pandey_2024-4-25-10-34-57.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16487"
                                                                        target="_blank">Prof. Ajoy Kumar Pandey</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969388 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ajoy@nitw.ac.in "> ajoy@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text">Bioceramics, High-temperature ceramics, Microwave processing of materials, Mechanical property evaluation of metals.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16314"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Asit_Kumar_Khanra_2024-4-25-10-19-46.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16314"
                                                                        target="_blank">Prof. Asit Kumar Khanra</a>
                                                                    </h5> <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9701578514  <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:asit@nitw.ac.in"> asit@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text">Powder Metallurgy, Processing of Advanced Ceramics, Corrosion, Biomaterials, Electrical Discharge Machining (EDM) and Deformation behaviour etc.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16488"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="https://nitw.ac.in/api/static/files/Prof._Bonta_Srinivasa_Rao_2024-4-25-10-36-40.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16488"
                                                                        target="_blank">Prof. Bonta Srinivasa Rao</a> </h5>
                                                                    <span> Associate Professor </span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969480 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bontasr@nitw.ac.in"> bontasr@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text"> Microstructure-property relationships, Phase transformations. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16486"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Brahma_Raju_Golla_2024-4-25-10-38-15.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16486"
                                                                        target="_blank">Prof. Brahma Raju Golla</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969389 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gbraju@nitw.ac.in"> gbraju@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text"> High Temperature Materials; Composite Materials; Advanced Materials Processing; Thinfilm Coatings; Tribolgy of Materials; Physical Metallurgy, Porous and Dielectric Materials </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-kishorebabu"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Kishore_Babu_Nagumothu_2024-4-25-10-39-13.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-kishorebabu"
                                                                        target="_blank">Prof. Kishore Babu Nagumothu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9121402468 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kishorebabu@nitw.ac.in "> kishorebabu@nitw.ac.in </a> </p>
                                                                    <p className="justified-text">  Metal Joining, Nanocomposites and Additive Manufacturing
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17031"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Mahesh_Kumar_Talari_2024-4-25-10-40-0.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://nitw.ac.in/api/static/files/Prof._Mahesh_Kumar_Talari_2024-4-25-10-40-0.jpg"
                                                                        target="_blank">Prof. Mahesh Kumar Talari</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8179048491 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:talari@nitw.ac.in"> talari@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text">Metal joining, hard facing, additive manufacturing, light metals, high temperature materials, functional materials and metal matrix nanocomposites.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-nn"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._N._Narasaiah_2024-4-25-10-40-57.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-nn"
                                                                        target="_blank">Prof. N. Narasaiah</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969384 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:nn@nitw.ac.in"> nn@nitw.ac.in   </a>
                                                                    </p>
                                                                    <p className="justified-text"> Mechanical Behaviour of Materials,Fatigue, Fracture Mechanics, Failure Analysis, Structure Property Correlations. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16312"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Nageswara_Rao_G_V_S_2024-4-25-10-41-48.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16312"
                                                                        target="_blank">Prof. Nageswara Rao G V S</a> </h5>
                                                                    <span>Professor (HAG)</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969382 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gvsnr@nitw.ac.in"> gvsnr@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text"> Surface Engineering; Powder Metallurgy & Processing of Ceramics; Materials Testing and Characterization; Thermo-mechanical Processing; Mechanical Metallurgy; Creep and Creep-Fatigue Interactions; Nanomaterials. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16489"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._R._Arockia_Kumar_2024-4-25-10-43-7.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16489"
                                                                        target="_blank">Prof. R. Arockia Kumar</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969479 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:arockia@nitw.ac.in">arockia@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text"> Shape Memory Alloys, Physical Metallurgy, Phase Transformations, Severe Plastic Deformation, Grain Boundary Engineering, Friction Stir Processing, Materials for high strain rate applications. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16317"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Rangadhara_Chary_Varayogi_2024-4-25-10-44-3.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16317"
                                                                        target="_blank">Prof. Rangadhara Chary Varayogi</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9177623948 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto: vrdchary@nitw.ac.in"> vrdchary@nitw.ac.in
                                                                        </a> </p>
                                                                    <p className="justified-text">Phase Transformations; Polymer Nanocomposites; Interpenetrating Phase Composites; Corrosion Studies.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-suklam"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Sukla_Mondol_2024-4-25-10-45-10.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-suklam"
                                                                        target="_blank">Prof. Sukla Mondol</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9535023200 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:suklam@nitw.ac.in ">suklam@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text">Alloy development, Microstructural characterization (TEM), Material processing and Mechanical properties of alloys and Metal matrix nano-composites, Structure Property Co-Relation.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-uma.seelam"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Uma_Maheswara_Rao_Seelam_2024-4-25-10-46-33.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-uma.seelam"
                                                                        target="_blank">Prof. Uma Maheswara Rao Seelam</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9441326641 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:uma.seelam@nitw.ac.in "> uma.seelam@nitw.ac.in   </a>
                                                                    </p>
                                                                    <p className="justified-text">Areas of Interest: Non-equilibrium processing of materials, Multiscale and multicomponent characterization, Rare-earth permanent magnets.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16315"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Vanitha_C_2024-4-25-10-47-54.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16315"
                                                                        target="_blank">Prof. Vanitha C</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969385 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto: vanitha@nitw.ac.in"> vanitha@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text"> 3D printing, Texture, Welding, Materials Characterization, Titanium alloys. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16856"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._V_Sreedevi_2024-4-25-10-49-22.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16856"
                                                                        target="_blank">Prof. V Sreedevi</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849920318
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto: sreedeviv@nitw.ac.in"> sreedeviv@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text">Synthesis and characterization of bulk nanocrystalline materials and other nanomaterials; Mechanical behavior of nanocrystalline materials; Development of Al based alloys and composites for on-demand hydrogen generation</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>





                                            </section> */}
                                            <section id="profile-info">

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-ajoy"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Ajoy_Kumar_Pandey_2024-4-25-10-34-57.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-ajoy"
                                                                        target="_blank">Prof. Ajoy Kumar Pandey</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969388 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ajoy@nitw.ac.in "> ajoy@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text">Bioceramics, High-temperature ceramics, Microwave processing of materials, Mechanical property evaluation of metals.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-asit"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Asit_Kumar_Khanra_2024-4-25-10-19-46.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-asit"
                                                                        target="_blank">Prof. Asit Kumar Khanra</a>
                                                                    </h5> <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9701578514  <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:asit@nitw.ac.in"> asit@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text">Powder Metallurgy, Processing of Advanced Ceramics, Corrosion, Biomaterials, Electrical Discharge Machining (EDM) and Deformation behaviour etc.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 profile-info-individual">
                                                        <div class="row">
                                                            <div class="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/mm-bharathb"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Bharath_Bandi_2024-4-25-11-22-44.jpg" alt=""
                                                                    class="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div class="col-md-9">
                                                                <div class="card ">
                                                                    <div class="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-bharathb"
                                                                            target="_blank">Prof. Bharath Bandi
                                                                            </a> </h5>
                                                                            <span>Assistant Professor</span>
                                                                        <p class="contact-info"> <i class="fa fa-phone"
                                                                            aria-hidden="true"></i> 9866135750 <br /> <i
                                                                                class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:bharathb@nitw.ac.in"> bharathb@nitw.ac.in  </a>
                                                                        </p>
                                                                         <p class="justified-text"> Fusion Alloys, Automotive Steels, Dissimilar metal welding, Phase Transformation, Alloy Design. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-bontasr"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="https://nitw.ac.in/api/static/files/Prof._Bonta_Srinivasa_Rao_2024-4-25-10-36-40.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-bontasr"
                                                                        target="_blank">Prof. Bonta Srinivasa Rao</a> </h5>
                                                                    <span> Associate Professor </span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969480 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bontasr@nitw.ac.in"> bontasr@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text"> Microstructure-property relationships, Phase transformations. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-gbraju"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Brahma_Raju_Golla_2024-4-25-10-38-15.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-gbraju"
                                                                        target="_blank">Prof. Brahma Raju Golla</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969389 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gbraju@nitw.ac.in"> gbraju@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text"> High Temperature Materials; Composite Materials; Advanced Materials Processing; Thinfilm Coatings; Tribolgy of Materials; Physical Metallurgy, Porous and Dielectric Materials </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-kishorebabu"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Kishore_Babu_Nagumothu_2024-4-25-10-39-13.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-kishorebabu"
                                                                        target="_blank">Prof. Kishore Babu Nagumothu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9121402468 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kishorebabu@nitw.ac.in "> kishorebabu@nitw.ac.in </a> </p>
                                                                    <p className="justified-text">  Metal Joining, Nanocomposites and Additive Manufacturing
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-talari"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Mahesh_Kumar_Talari_2024-4-25-10-40-0.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-talari"
                                                                        target="_blank">Prof. Mahesh Kumar Talari</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8179048491 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:talari@nitw.ac.in"> talari@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text">Metal joining, hard facing, additive manufacturing, light metals, high temperature materials, functional materials and metal matrix nanocomposites.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-nn"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._N._Narasaiah_2024-4-25-10-40-57.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-nn"
                                                                        target="_blank">Prof. N. Narasaiah</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969384 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:nn@nitw.ac.in"> nn@nitw.ac.in   </a>
                                                                    </p>
                                                                    <p className="justified-text"> Mechanical Behaviour of Materials,Fatigue, Fracture Mechanics, Failure Analysis, Structure Property Correlations. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-gvsnr"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Nageswara_Rao_G_V_S_2024-4-25-10-41-48.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-gvsnr"
                                                                        target="_blank">Prof. Nageswara Rao G V S</a> </h5>
                                                                    <span>Professor (HAG)</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969382 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gvsnr@nitw.ac.in"> gvsnr@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text"> Surface Engineering; Powder Metallurgy & Processing of Ceramics; Materials Testing and Characterization; Thermo-mechanical Processing; Mechanical Metallurgy; Creep and Creep-Fatigue Interactions; Nanomaterials. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-arockia"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._R._Arockia_Kumar_2024-4-25-10-43-7.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-arockia"
                                                                        target="_blank">Prof. R. Arockia Kumar</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969479 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:arockia@nitw.ac.in">arockia@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text"> Shape Memory Alloys, Physical Metallurgy, Phase Transformations, Severe Plastic Deformation, Grain Boundary Engineering, Friction Stir Processing, Materials for high strain rate applications. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-vrdchary"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Rangadhara_Chary_Varayogi_2024-4-25-10-44-3.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-vrdchary"
                                                                        target="_blank">Prof. Rangadhara Chary Varayogi</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9177623948 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto: vrdchary@nitw.ac.in"> vrdchary@nitw.ac.in
                                                                        </a> </p>
                                                                    <p className="justified-text">Phase Transformations; Polymer Nanocomposites; Interpenetrating Phase Composites; Corrosion Studies.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 profile-info-individual">
                                                        <div class="row">
                                                            <div class="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/mm-sanghamitram"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Sanghamitra_Moharana_2024-4-25-11-21-42.jpg" alt=""
                                                                    class="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div class="col-md-9">
                                                                <div class="card ">
                                                                    <div class="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-sanghamitram"
                                                                            target="_blank">Prof. Sanghamitra Moharana
                                                                            </a> </h5>
                                                                            <span>Assistant Professor</span>
                                                                        <p class="contact-info"> <i class="fa fa-phone"
                                                                            aria-hidden="true"></i> 7788980274 <br /> <i
                                                                                class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:sanghamitram@nitw.ac.in"> sanghamitram@nitw.ac.in   </a>
                                                                        </p>
                                                                         <p class="justified-text"> Energy materials, Batteries, Lithium-ion batteries, Degradation mechanism, Characterisation techniques. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-suklam"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Sukla_Mondol_2024-4-25-10-45-10.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-suklam"
                                                                        target="_blank">Prof. Sukla Mondol</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9535023200 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:suklam@nitw.ac.in ">suklam@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text">Alloy development, Microstructural characterization (TEM), Material processing and Mechanical properties of alloys and Metal matrix nano-composites, Structure Property Co-Relation.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-uma.seelam"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Uma_Maheswara_Rao_Seelam_2024-4-25-10-46-33.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-uma.seelam"
                                                                        target="_blank">Prof. Uma Maheswara Rao Seelam</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9441326641 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:uma.seelam@nitw.ac.in "> uma.seelam@nitw.ac.in   </a>
                                                                    </p>
                                                                    <p className="justified-text">Areas of Interest: Non-equilibrium processing of materials, Multiscale and multicomponent characterization, Rare-earth permanent magnets.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-vanitha"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Vanitha_C_2024-4-25-10-47-54.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-vanitha"
                                                                        target="_blank">Prof. Vanitha C</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969385 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto: vanitha@nitw.ac.in"> vanitha@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text"> 3D printing, Texture, Welding, Materials Characterization, Titanium alloys. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/mm-sreedeviv"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._V_Sreedevi_2024-4-25-10-49-22.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/mm-sreedeviv"
                                                                        target="_blank">Prof. V Sreedevi</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849920318
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto: sreedeviv@nitw.ac.in"> sreedeviv@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text">Synthesis and characterization of bulk nanocrystalline materials and other nanomaterials; Mechanical behavior of nanocrystalline materials; Development of Al based alloys and composites for on-demand hydrogen generation</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>





                                            </section>




                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sri._K_Nagender_Rao_2023-10-27-10-36-16.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. K. Nagender Rao</h6> <span>Assistant(SG-I)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969433 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:nagender@nitw.ac.in">nagender@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sri._Lukka_Venkata_Hanuma_Kumar_2023-10-27-10-39-18.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Lukka Venkata Hanuma Kumar</h6> <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7518262822 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:hanumal@nitw.ac.in">hanumal@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sri._Manda_Shankaraiah_2023-10-27-10-40-35.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Manda Shankaraiah</h6>
                                                                <span>Sr. Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969657 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:8341720121">8341720121</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sri._Akanaboina_Raju_2023-10-27-10-43-1.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Akanaboina Raju</h6>
                                                                <span>Sr. Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 6281246902 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:akanaboinar@nitw.ac.in">akanaboinar@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sri._Prakash_Chandra_Vishwakarma_2023-10-27-10-44-48.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Prakash Chandra Vishwakarma</h6> <span>Sr. Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9685278744 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:prakashc@nitw.ac.in">prakashc@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sri._Naiki_Vishnu_2023-10-27-10-46-15.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Naiki Vishnu</h6> <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9390946456 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:naikiv@nitw.ac.in">naikiv@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sri._Rakesh_Kumar_2023-10-27-10-47-42.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. Rakesh Kumar</h6> <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8434900260 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rakeshk@nitw.ac.in">rakeshk@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sri._K_Raju_2023-10-27-10-49-5.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sri. K Raju</h6>
                                                                <span>Care Taker SG-II</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9949650561 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>





                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">
                                            {/*<h4 className="">Ph.D. Awardee</h4>
                     <p className="justified-text">
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}






                                            <div className="row phd-std-row">



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/K_V_Ashish_Srivatsav_2023-10-27-18-32-11.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Alloy Development <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K V Ashish Srivatsav </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9493484744<br />
                                                            <span>
                                                                <a href="mailto:ksrivatsav@student.nitw.ac.in">ksrivatsav@student.nitw.ac.in</a></span> </p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/S_Vignesh_Raja_2023-10-27-18-31-29.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Resistance Spot Welding <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> S Vignesh Raja </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9944551173<br /> <span> <a href="mailto:vigneshrs@nitw.ac.in">vigneshrs@nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Mood_Vidhish_Naik_2023-10-27-18-30-41.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Bio-Materials <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mood Vidhish Naik </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7207130190<br /> <span> <a href="mailto:vrc45@student.nitw.ac.in">vrc45@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/C_Gopi_Krishna_2023-10-27-18-29-50.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : welding and additive manufacturing  <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> C Gopi Krishna</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9490568508<br /> <span> <a href="mailto:cgkrishna@student.nitw.ac.in">cgkrishna@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/K_Vamsi_Krishna_2023-10-27-18-29-7.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Welding  <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> K Vamsi Krishna</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8688714721<br /> <span> <a href="mailto:vamshik1@student.nitw.ac.in">vamshik1@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/P_Anil_Kumar_2023-10-27-18-28-19.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : High Temperature Materials  <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> P Anil Kumar</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9989653739<br /> <span> <a href="mailto:anilpk35@student.nitw.ac.in">anilpk35@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Gudivada_Giridhar_2023-10-27-18-27-21.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : High Temperature Materials & Alloy Development <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Gudivada Giridhar</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9440588755<br /> <span> <a href="mailto:giridhar92@student.nitw.ac.in">giridhar92@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/V_Ganesh_2023-10-27-18-26-27.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Powder Metallurgy <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> V Ganesh</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9677673054<br /> <span> <a href="mailto:ganeshv@student.nitw.ac.in">ganeshv@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Battu_Santhi_2023-10-27-18-25-40.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Powder Metallurgy <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Battu Santhi</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9652239004<br /> <span> <a href="mailto:santhi719081@student.nitw.ac.in">santhi719081@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Kiranchand_G_R_2023-10-27-18-24-53.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Oxide dispersion strengthened intermetallics <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kiranchand G R</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9503771255<br /> <span> <a href="mailto:gkiranchand@student.nitw.ac.in">gkiranchand@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/K._Abhyuday_Patel_2023-10-27-18-24-9.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Hydrogen generation <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> K. Abhyuday Patel</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9866310178<br /> <span> <a href="mailto:abhyuday_patel@student.nitw.ac.in">abhyuday_patel@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/G._Damodhar_Naidu_2023-10-27-18-23-18.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : High temperature materials, fatigue and creep-fatigue interaction  <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> G. Damodhar Naidu</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7416280080<br /> <span> <a href="mailto:gd720067@student.nitw.ac.in">gd720067@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Challa_Krishna_Teja_2023-10-27-18-22-24.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Fracture and failure analysis   <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Challa Krishna Teja</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9848576207<br /> <span> <a href="mailto:kc721074@student.nitw.ac.in">kc721074@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Mahesh_Pandiripalli_2023-10-27-18-21-1.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : High Temperature Materials & Alloy Development    <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mahesh Pandiripalli</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9491325242<br /> <span> <a href="mailto:mp721075@student.nitw.ac.in">mp721075@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Nellutla_Sahithyu_2023-10-27-18-20-15.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Alloy development    <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Nellutla Sahithyu</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8106933877<br /> <span> <a href="mailto:ns721076@student.nitw.ac.in">ns721076@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Akhil_PS_2023-10-27-18-19-20.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Rare-earth Permanent Magnets<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Akhil PS</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7558877266<br /> <span> <a href="mailto:ap712111@student.nitw.ac.in">ap712111@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Mohammed_Rafi_2023-10-27-18-18-30.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : -<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mohammed Rafi</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9995683874<br /> <span> <a href="mailto:mr712113@student.nitw.ac.in">mr712113@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Mudavath_Praveen_kumar_2023-10-27-18-16-58.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Ceramics and Composites<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mudavath Praveen kumar</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7093854967<br /> <span> <a href="mailto:mp712114@student.nitw.ac.in">mp712114@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/V_Uday_Kumar_2023-10-27-18-16-2.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Bio-Materials<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> V Uday Kumar</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9700245596<br /> <span> <a href="mailto:uv712115@student.nitw.ac.in">uv712115@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Banoth_Shivakumar_2023-10-27-18-15-13.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : High Temperature Materials<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Banoth Shivakumar</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9676766684<br /> <span> <a href="mailto:bs21mmrer02@student.nitw.ac.in">bs21mmrer02@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Md_Jawed_Quamar_2023-10-27-18-14-23.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Welding and additive Manufacturing<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Md Jawed Quamar</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8882103275<br /> <span> <a href="mailto:mj22mmr1r03@student.nitw.ac.in">mj22mmr1r03@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>







                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Shaik_Saleem_2023-10-27-18-13-32.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Recycling of Electronic waste<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Shaik Saleem</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9052833639<br /> <span> <a href="mailto:ss22mmr1r05@student.nitw.ac.in">ss22mmr1r05@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Bandaru_Dyva_Isac_Prem_Kumar_2023-10-27-18-12-43.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Corrosion of weldments<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Bandaru Dyva Isac Prem Kumar</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9700976688<br /> <span> <a href="mailto:bd23mmr1r01@student.nitw.ac.in">bd23mmr1r01@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/S_Geasin_Savio_2023-10-27-18-11-52.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Ceramics and Composites<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> S Geasin Savio</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9908574781<br /> <span> <a href="mailto:sgsavio_701950@student.nitw.ac.in">sgsavio_701950@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/M_Venu_Madhava_Rao_2023-10-27-18-10-56.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Recycling of solid Iron Waste<b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> M Venu Madhava Rao</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9701023438<br /> <span> <a href="mailto:morevenumadhav@gmail.com">morevenumadhav@gmail.com
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Pramod_Kumar_2023-10-27-18-10-4.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : PVD Coating & Thin film <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Pramod Kumar</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7331131975<br /> <span> <a href="mailto:pk720063@student.nitw.ac.in">pk720063@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Ajay_Kumar_Vaddepally_2023-10-27-18-8-47.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : High temperature Materials <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ajay Kumar Vaddepally</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9703557287<br /> <span> <a href="mailto:ak721073@studeny.nitw.ac.in">ak721073@studeny.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Antony_Harison_2023-10-27-18-7-21.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Mechanical properties of high temperature material  <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Antony Harison</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9959235991<br /> <span> <a href="mailto:am712112@student.nitw.ac.in">am712112@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Kiran_Kumar_Atyam_2023-10-27-18-6-24.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Welding Metallurgy  <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Kiran Kumar Atyam</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9494577433<br /> <span> <a href="mailto:ka712117@student.nitw.ac.in">ka712117@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/A_Ashok_Kumar_2023-10-27-18-5-17.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Welding  <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> A Ashok Kumar</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9949811238<br /> <span> <a href="mailto:aa22mmr1p01@student.nitw.ac.in">aa22mmr1p01@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img ">

                                                            <img src="https://nitw.ac.in/api/static/files/Palani_Muthu_K_2023-10-27-18-4-16.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Nano composites  <b></b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Palani Muthu K</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9441695425<br /> <span> <a href="mailto:pm22mmr1p02@student.nitw.ac.in">pm22mmr1p02@student.nitw.ac.in
                                                        </a> </span></p>

                                                    </div>
                                                </div>








                                            </div>



                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div className="row std-society">

                                        {/* <p className="justified-text"> 1. SPIE-Student chapter </p>
                                    <p className="justified-text"> 2. Physics association   </p>

                                    <p className="justified-text"> 3. OSA-Student chapter  </p> */}



                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/mm-suklam"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Sukla_Mondol_2024-4-25-10-45-10.jpg" alt="" className="img-fluid" width="150" height="180" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/mm-suklam"
                                                    target="_blank">
                                                    <h6>Prof. Sukla Mondol</h6> <span> PIC MMEA and IIMSC </span>
                                                    <hr />
                                                </a>
                                                    <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9535023200 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <span> <a
                                                            href="mailto:suklam@nitw.ac.in"
                                                            style={{ color: "#808080" }}>suklam@nitw.ac.in </a></span>  </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16856"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._V_Sreedevi_2024-4-25-10-49-22.jpg" alt="" className="img-fluid" width="150" height="180" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16856"
                                                    target="_blank">
                                                    <h6>Prof. V Sreedevi</h6> <span> Treasurer MMEA and IIMSC</span>
                                                    <hr />
                                                </a>
                                                    <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9849920318 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <span> <a
                                                            href="mailto:sreedeviv@nitw.ac.in "
                                                            style={{ color: "#808080" }}>sreedeviv@nitw.ac.in</a></span>  </p>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://nitw.ac.in/mme"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Mr._Abhayraj_Ninnekar_2023-10-27-15-22-25.jpg" alt="" className="img-fluid" width="150" height="180" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://nitw.ac.in/mme"
                                                    target="_blank">
                                                    <h6>Mr. Abhayraj Ninnekar</h6> <span> General Secretary MMEA</span>
                                                    <hr />
                                                </a>
                                                    <p className="justified-text">   <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <span> <a
                                                            href="mailto:aa862066@student.nitw.ac.in  "
                                                            style={{ color: "#808080" }}>aa862066@student.nitw.ac.in  </a> </span> </p>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://nitw.ac.in/mme"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Mr._Swapnil_Sharma_2023-10-27-15-25-9.jpg" alt="" className="img-fluid" width="150" height="180" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://nitw.ac.in/mme"
                                                    target="_blank">
                                                    <h6>Mr. Swapnil Sharma</h6> <span> General Secretary IIMSC</span>
                                                    <hr />
                                                </a>
                                                    <p className="justified-text">  <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <span><a
                                                            href="mailto:ss972052@student.nitw.ac.in"
                                                            style={{ color: "#808080" }}>ss972052@student.nitw.ac.in</a> </span> </p>
                                                </div>
                                            </div>
                                        </div>






                                    </div>
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


                                    {/* <div className="row notable-alumni">
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="https://nitw.ac.in/api/static/files/Shri.Durga_Prasad_Allada_2023-9-18-16-50-19.jpg" alt="" className="img-fluid" width="150" height="180"/>
                                                <div className="data">
                                                    <h6>Shri. Durga Prasad Allada</h6>

                                                     <p className="justified-text"><b>Batch: </b>M.Sc -Tech  (1984) - (Engineering Physics)<br />
                                                        <b>Present Position: </b>CTO & VP Tech Mahendra, Hyderabad
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="https://nitw.ac.in/api/static/files/Shri.Amith_Kumar_2023-9-18-16-53-7.jpg" alt="" className="img-fluid" width="150" height="180"/>
                                                <div className="data">
                                                    <h6>Shri. Amith Kumar</h6>

                                                     <p className="justified-text"><b>Batch :</b> MSc (Tech) (2001-2004) – Engineering Physics, Electronics Specialization<br />
                                                        <b>Present Position :</b> Analog Devices as a Staff Engineer – Field applications
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Subhash_Thota_2023-9-18-16-56-43.jpg" alt="" className="img-fluid" width="150" height="180"/>
                                                <div className="data">
                                                    <h6>Subhash Thota</h6>


                                                    <p className="justified-text"><b>Batch :</b> MSc (Tech) (2000-03) – Engineering Physics, Electronics Specialization<br />
                                                        <b>Present Position :</b> Professor, IIT-Gandhinagar 
                                                    </p>

                                                 

                                           
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Ram_Mohan_Vasu_2023-9-18-16-58-40.jpg" alt="" className="img-fluid" width="150" height="180"/>
                                                <div className="data">
                                                    <h6>Ram Mohan Vasu</h6>
                                                    <p className="justified-text"><b>Batch :</b> MSc (Tech) – Engineering Physics, Electronics Specialization<br />
                                                        <b>Present Position :</b> Professor, IISc-Bangalore  
                                                    </p>

                                              
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Veerendra_Vasamsetty_2023-9-18-17-0-42.jpg" alt="" className="img-fluid" width="150" height="180"/>
                                                <div className="data">
                                                    <h6>Veerendra Vasamsetty</h6>
                                                    <p className="justified-text"><b>Batch :</b> MSc (Tech) (1997-2000) – Engineering Physics, Electronics Specialization<br />
                                                        <b>Present Position :</b> Director R&D, Schneider Electric
                                                    </p>
                                                
                                                </div>
                                            </div>
                                        </div> 

                    


                                     

                                    </div> */}
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    {/* <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Advanced Materials
                                                </h4>
                                            </header>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Abdul Azeem</h6>  <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9490108924, 8332969473 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16364/16364.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. D. Dinakar</h6>
                                                      
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9866948560  <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:dinakar@nitw.ac.in">dinakar@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Tumu Venkatappa Rao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9248667047 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:tvraokmm@nitw.ac.in">tvraokmm@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16366/16366.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Syam Prasad</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969472<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: syamprasad@nitw.ac.in"> syamprasad@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/harinath_2024-7-3-17-41-34.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. D Haranath</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9958101115, 7678248149 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:haranath@nitw.ac.in">haranath@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16618/16618.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. V Jayalakshmi</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969477 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:jayalakshmiv@nitw.ac.in ">jayalakshmiv@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16924/16924.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Rakesh Kumar Rajaboina</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9676220574 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rakeshr@nitw.ac.in ">rakeshr@nitw.ac.in </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17030/17030.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Uday Kumar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9940107507 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kanapuram.udaykumar@nitw.ac.in ">kanapuram.udaykumar@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Energy Harvesting Materials and Devices</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/harinath_2024-7-3-17-41-34.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. D Haranath</h6><span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9958101115, 7678248149 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:haranath@nitw.ac.in">haranath@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Abdul Azeem</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9490108924, 8332969473 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Tumu Venkatappa Rao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9248667047 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:tvraokmm@nitw.ac.in">tvraokmm@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16431/16431.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. D. Paul Joseph</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969475<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:paul@nitw.ac.in">paul@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Kuppusamy Thangaraju</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969476 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ktr@nitw.ac.in "> ktr@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16924/16924.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Rakesh Kumar Rajaboina</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9676220574 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rakeshr@nitw.ac.in ">rakeshr@nitw.ac.in </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17030/17030.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Uday Kumar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9940107507 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kanapuram.udaykumar@nitw.ac.in ">kanapuram.udaykumar@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17033/17033.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Hitesh Borkar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8447287127 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bhitesh@nitw.ac.in">bhitesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Photonics and Optoelectronics</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16369/16369.jpg" alt=""
                                                        className="img-fluid" width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sourabh Roy</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969474<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sroy@nitw.ac.in ">sroy@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Abdul Azeem</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9490108924, 8332969473 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16366/16366.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Syam Prasad</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969472<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: syamprasad@nitw.ac.in"> syamprasad@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                     
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Kuppusamy Thangaraju</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969476 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ktr@nitw.ac.in "> ktr@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16431/16431.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. D. Paul Joseph</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969475<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:paul@nitw.ac.in">paul@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16618/16618.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. V Jayalakshmi</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969477 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:jayalakshmiv@nitw.ac.in ">jayalakshmiv@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16384/16384.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vijay Kumar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i>  9493330158 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vijay@nitw.ac.in ">vijay@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  
                                    </div>



                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Glass science and technology</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16366/16366.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Syam Prasad</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969472<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: syamprasad@nitw.ac.in"> syamprasad@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


   <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Abdul Azeem</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9490108924, 8332969473 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Tumu Venkatappa Rao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9248667047 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:tvraokmm@nitw.ac.in">tvraokmm@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  
                                  
                                    </div>







                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Solar photovoltaics</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Kuppusamy Thangaraju</h6><span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969476 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ktr@nitw.ac.in "> ktr@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16431/16431.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. D. Paul Joseph</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969475<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:paul@nitw.ac.in">paul@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                    </div>












                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Theoretical and Computational Physics</h4>
                                            </header>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17031/17031.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Surya K. Ghosh</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9820702782 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:skghosh@nitw.ac.in ">skghosh@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17032/17032.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Aalu Boda</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9494868704 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:abdop@nitw.ac.in ">abdop@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16924/16924.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Rakesh Kumar Rajaboina</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9676220574 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rakeshr@nitw.ac.in ">rakeshr@nitw.ac.in </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16384/16384.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vijay Kumar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i>  9493330158 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vijay@nitw.ac.in ">vijay@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  
                                      
                                    </div> */}
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">
                                </div>







                                <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                    aria-labelledby="nav-AdjunctFaculty-tab">

                                    <div className="col-md-12 profile-info-individual">
                                        <div className="row">

                                            {/* <div className="col-md-9">
                                                <div className="card">
                                                    <div className="d-flex align-items-center">

                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0">Dr. Ashwinee Kumar  </h6>
                                                            <div className=" mt-2">
                                                                <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ashwinee5687@gmail.com">ashwinee5687@gmail.com</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-md-9">
                                            <div className="card">


                                                <div className="d-flex align-items-center">
                                                <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._G._Madhusudhan_Reddy_2024-4-5-19-41-17.jpg" alt=""
                                                            className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0"> Prof. G. Madhusudhan Reddy </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:-">-</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>


                                            {/* <div className="col-md-9">
                                                <div className="card">
                                                    <div className="d-flex align-items-center">

                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. G. Madhusudhan Reddy </h6>
                                                            <div className=" mt-2">
                                                                <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rajkumarboora@gmail.com">rajkumarboora@gmail.com</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}




                                        </div>
                                    </div>
                                </div>















                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        {/* <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4> */}
                                        {/* <span>19th to 30th September 2022</span>  */}
                                    </div>


                                    {/* <img src="https://nitw.ac.in/api/static/files/Photo_Gallery1_2023-9-18-17-17-12.jpg" alt="" className="img-fluid mb-5"/> 

                                <img src="https://nitw.ac.in/api/static/files/Photo_Gallery2_2023-9-18-17-25-58.jpg" alt="" className="img-fluid mb-5"/>  */}





                                </div>






                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                 <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default MMEDDeptPage;





