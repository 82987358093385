import { useState } from "react";
import '../css/cseDeptStyle.css';
const AcademicProg = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <>


            <div className="container  mb-5 aca-pro">
                <div className="row">

                    <header className="mt-5">
                        <h3>Academic Programs - Curricula</h3>
                    </header>


                    <div className="col-md-3">
                        <div className="aca-pro-box">
                            <div className="aca-pro-title">
                                <h2>UG</h2>
                            </div>
                            <div className="aca-pro-link">
                                <a href="https://www.nitw.ac.in/path/?dept=/NewBTechCurriculum" className="c-link">B.Tech Programs</a><br />
                                <a href="https://www.nitw.ac.in/page/?url=/NITWIntMsc/IntMsc" className="c-link">Integrated M.Sc.</a><br />
                                <a href="https://nitw.ac.in/itep/" className="c-link">BSc.BEd</a><br />
                                <a href="https://www.nitw.ac.in/page/?url=/HonorsPrograms/Academic" className="c-link">Honors Programs</a> <br />
                                <a href="https://www.nitw.ac.in/page/?url=/MinorPrograms/Academic" className="c-link">Minor Programs</a> <br />
                                <a href="https://www.nitw.ac.in/page/?url=/MNC/Academic" className="c-link">Mandatory Non-Credit Courses (MNC)</a> <br />
                                <a href="https://www.nitw.ac.in/page/?url=/OpenElectiveCourses/OEC" className="c-link">Open Elective Courses</a> <br />
                            </div>
                        </div>
                    </div>



                    <div className="col-md-3">
                        <div className="aca-pro-box">
                            <div className="aca-pro-title">
                                <h2>M.Tech</h2>
                            </div>
                            <div className="aca-pro-link">
                                <a href="https://www.nitw.ac.in/path/?dept=/NewMTechCurriculum" className="c-link">M.Tech</a>

                            </div>
                        </div>
                    </div>



                    <div className="col-md-3">
                        <div className="aca-pro-box">
                            <div className="aca-pro-title">
                                <h2>PG</h2>
                            </div>
                            <div className="aca-pro-link">
                                <a href="https://www.nitw.ac.in/page/?url=/academicmsc/MSccurriculum" className="c-link">M.Sc./M.Sc. (Tech.)</a><br />
                                <a href="https://www.nitw.ac.in/path/?dept=/academicmba" className="c-link">MBA</a><br />
                                <a href="https://www.nitw.ac.in/path/?dept=/academicmca" className="c-link">MCA</a><br />

                            </div>
                        </div>
                    </div>



                    <div className="col-md-3">
                        <div className="aca-pro-box">
                            <div className="aca-pro-title">
                                <h2>PG Dilpoma</h2>
                            </div>
                            <div className="aca-pro-link">


                            </div>
                        </div>
                    </div>


                    <div className="col-md-3">
                        <div className="aca-pro-box">
                            <div className="aca-pro-title">
                                <h2>Ph.D</h2>
                            </div>
                            <div className="aca-pro-link">
                                <a href="https://www.nitw.ac.in/path/?dept=/academicphd" className="c-link">Ph.D</a>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}
export default AcademicProg;