import React from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "../BasePath";
import "./AboutUs.css";

function AboutNitw() {
  return (
    <div className="container" style={{ margin: "2rem auto" }}>
      <div className="row row-50 text-md-start align-items-md-center justify-content-md-between">
        <div className="col-md-5 col-lg-4 order-md-2">
          <Link
            to={{ pathname: `${BaseUrl}/path/?dept=/home` }}
            onClick={() => {
              window.location.href = `${BaseUrl}/path/?dept=/home`;
            }}
          >
            <img
              className="img-responsive d-inline-block"
              src={`${process.env.PUBLIC_URL}/images/logo-326x329.png`}
              width={250}
              height={250}
              alt=""
            />
          </Link>
        </div>
        <div className="col-md-7 col-lg-7 order-md-1">
          <h2 className="fw-bold">About NITW</h2>
          <hr className="divider bg-madison divider-md-0" />
          <div className="offset-top-35 offset-lg-top-20">
            <p>
              Towards a Global Knowledge Hub, striving continuously in pursuit
              of excellence in Education, Research, Entrepreneurship and
              Technological services to the society
            </p>
          </div>
          <div
            className="offset-top-50"
            style={{
              position: "relative",
            }}
          >
            <Link
              to={{ pathname: "/path/?dept=/home" }}
              onClick={() => {
                window.location.href = `${BaseUrl}/path/?dept=/home`;
              }}
              className="btn btn-icon btn-icon-right button-default learn-more-button"
            >
              <span className="icon fa fa-arrow-right" />
              <span>Learn More</span>
            </Link>
          </div>
        </div>
      
      </div>
    
    </div>
  );
}

export default AboutNitw;
