export const sampleNotificationsData = [
  {
    is_published: true,
    is_breaking_news: false,
    start_date: "2021-10-16T11:46:21.688Z",
    end_date: "2022-01-14T11:46:21.688Z",
    department: "All",
    _id: "616abd79d03c160023ff53b9",
    title: "Jai thala",
    summary: "Chennai Super Kings wins fourth IPL title",
    description:
      "Chennai Super Kings beats KKR to win fourth IPL title, faf was selected as player of the match whereas harshal from RCB won player of the series award",
    contentType: "news",
    url: "/",
    createdAt: "2021-10-16T11:54:33.463Z",
    updatedAt: "2021-10-16T14:00:56.522Z",
    __v: 0,
  },
  {
    is_published: true,
    is_breaking_news: false,
    start_date: "2021-10-16T13:51:42.955Z",
    end_date: "2022-01-14T13:51:42.955Z",
    department: "All",
    _id: "616adb2d9c5e9c002339b005",
    title: "Virat retires from captaincy",
    summary: "Chennai Super Kings wins fourth IPL title",
    description:
      "Chennai Super Kings beats KKR to win fourth IPL title, faf was selected as player of the match whereas harshal from RCB won player of the series award",
    contentType: "news",
    url: "/",
    createdAt: "2021-10-16T14:01:17.957Z",
    updatedAt: "2021-10-16T14:01:17.957Z",
    __v: 0,
  },
  {
    is_published: true,
    is_breaking_news: false,
    start_date: "2021-10-16T13:51:42.955Z",
    end_date: "2022-01-14T13:51:42.955Z",
    department: "All",
    _id: "616adb639c5e9c002339b009",
    title: "Rishabh Pant started WK Workshop",
    summary: "Chennai Super Kings wins fourth IPL title",
    description:
      "Chennai Super Kings beats KKR to win fourth IPL title, faf was selected as player of the match whereas harshal from RCB won player of the series award",
    contentType: "Workshops",
    url: "/",
    createdAt: "2021-10-16T14:02:11.066Z",
    updatedAt: "2021-10-16T14:02:11.066Z",
    __v: 0,
  },
  {
    is_published: true,
    is_breaking_news: false,
    start_date: "2021-10-16T13:51:42.955Z",
    end_date: "2022-01-14T13:51:42.955Z",
    department: "All",
    _id: "616adcf59c5e9c002339b00b",
    title: "Rishabh Pant started WK Workshop",
    summary: "Chennai Super Kings wins fourth IPL title",
    description:
      "Chennai Super Kings beats KKR to win fourth IPL title, faf was selected as player of the match whereas harshal from RCB won player of the series award",
    contentType: "workshop",
    url: "/",
    createdAt: "2021-10-16T14:08:53.457Z",
    updatedAt: "2021-10-16T14:08:53.457Z",
    __v: 0,
  },
];
