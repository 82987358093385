import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
// import './BWCFCBoG.css'; // Update the CSS file name

export const BWCFCBoG = () => {
  return (

    <div className="container">

    <div className="row">
       
        
      <div className="col-md-12 pb-2">
        <h4 className="htitle">Building & Works Committee</h4>
      </div>
      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://www.nitw.ac.in/page/?url=/Director/DirectorNITW/" target="_blank">
      <b>Prof. Bidyadhar Subudhi</b><br />
      <small>Chairperson<br></br>Director & Chairperson <br></br>National Institute of Technology Warangal.</small>
    </a>
        </div>
      </div>



      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="#" target="_blank">
      <b>Shri Ashok Kumar Khatua</b><br />
      <small>Member <br></br>Chief Engineer	<br></br> Central Public Works Dept., <br></br> Nirman Bhawan, Sultan Bazar, Hyderabad</small>
    </a>
        </div>
      </div>

      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://erp.nitw.ac.in/ext/profile/ce-rateeshp" target="_blank">
      <b>Prof. P. Rathish Kumar</b><br />
      <small>Member <br></br>Dean (Planning & Development), NIT Warangal	<br></br>dean_pl@nitw.ac.in </small>
    </a>
        </div>
      </div>


      <div class="col-md-4 senate-main">
        <div class="senate-card">
        <a href="#" target="_blank">
      <b>Shri Anil Kumar</b><br />
      <small>Member <br></br>Director, (Finance), Integrated Finance Division <br></br> Ministry of Education, Govt. of India, New Delhi. </small>
    </a>
        </div>
      </div>

      


  
      <div class="col-md-4 senate-main">
        <div class="senate-card">
        <a href="#" target="_blank">
      <b>Smt. Veena Dunga</b><br />
      <small>Member <br></br>Deputy Secretary (NIT, BP & ISBN) <br></br> Ministry of Education, Govt. of India, New Delhi. </small>
    </a>
        </div>
      </div>

      


      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="#" target="_blank">
      <b>Shri K. Kishan</b><br />
      <small>Member <br></br>Chief General Manager (Opn-II)(Electrical), <br></br>TSNPDCL, Warangal </small>
    </a>
        </div>
      </div>


      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://erp.nitw.ac.in/ext/profile/ec-sai" target="_blank">
      <b>Prof. SKLV Sai Prakash</b><br />
      <small>Member <br></br>Associate Professor<br></br>Dept. of Electronics & Communication Engineering<br/> NIT Warangal<br/> sai@nitw.ac.in</small>
    </a>
        </div>
      </div>

      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://nitw.ac.in/page/?url=/NITWRegistrar/list" target="_blank">
      <b>Prof. N. V. Umamahesh</b><br />
      <small>Member Secretary <br></br>Registrar In-charge, NIT Warangal	<br></br>registrar@nitw.ac.in </small>
    </a>
        </div>
      </div>


  







      <div className="col-md-12 pb-2">
        <h4 className="htitle">Finance Committee
</h4>
      </div>
      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://www.nitw.ac.in/page/?url=/Director/DirectorNITW/" target="_blank">
      <b>Prof. Bidyadhar Subudhi</b><br />
      <small>	Chairperson I/c <br></br>Director, NIT Warangal & Chairperson I/c, <br></br>Finance Committee, NITW  </small>
    </a>
        </div>
      </div>



      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://www.nitw.ac.in/page/?url=/Director/DirectorNITW/" target="_blank">
      <b>Prof. Bidyadhar Subudhi</b><br />
      <small>	Member <br></br>Director, NIT Warangal  </small>
    </a>
        </div>
      </div>



      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="#" target="_blank">
      <b>Ms. Saumya Gupta, IAS</b><br />
      <small>	Member <br></br>Joint Secretary (TE) <br></br>  MoE, Government of India <br></br>  Shastri Bhawan, New Delhi.</small>
    </a>
        </div>
      </div>

 

      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="#" target="_blank">
      <b>Sh. Sanjog Kapoor </b><br />
      <small>Member  <br></br>Joint Secretary & Financial Advisor<br></br>MoE, Government of India <br></br> Shastri Bhawan, New Delhi.</small>
    </a>
        </div>
      </div>
      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://erp.nitw.ac.in/ext/profile/me-madhu" target="_blank">
      <b>Prof. K. Madhu Murthy </b><br />
      <small>	Member<br></br>Professor (HAG)<br/> Department of Mechanical Engineering<br/> NIT Warangal
      <br></br>madhu@nitw.ac.in </small>
    </a>
        </div>
      </div>

      {/* <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="#" target="_blank">
      <b>Ms Darshana M Dabral  </b><br />
      <small>		Member(Statute 10 (1) (iii) ) <br></br>Jt. Secretary & Financial Advisor,    Dept. of Higher Education, Ministry of Human Resource Development,      Govt. of India,   Shastri Bhavan, New Delhi</small>
    </a>
        </div>
      </div> */}
{/* 
      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16195" target="_blank">
      <b>Prof. N. V. Umamahesh</b><br />
      <small>	Member(Statute 10 (1) (iv) <br></br>Professor, Department of Civil Engineering, NIT, Warangal<br></br>mahesh@nitw.ac.in </small>
    </a>
        </div>
      </div> */}


      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://nitw.ac.in/page/?url=/NITWRegistrar/list" target="_blank">
      <b>Prof. N.V. Umamahesh </b><br />
      <small>Member-Secretary  <br></br>Registrar In-charge <br></br>NIT Warangal</small>
    </a>
        </div>
      </div>

      <div className="col-md-12 pb-2">
        <h4 className="htitle">Board of Governors </h4>
      </div>
      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://www.nitw.ac.in/page/?url=/Director/DirectorNITW/" target="_blank">
      <b>Prof. Bidyadhar Subudhi </b><br />
      <small>	Chairperson I/c <br></br>Director, NIT Warangal & Chairperson I/c,<br></br>Board of Governors, NIT Warangal.</small>
    </a>
        </div>
      </div>

      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://www.nitw.ac.in/page/?url=/Director/DirectorNITW/" target="_blank">
      <b>Prof. Bidyadhar Subudhi </b><br />
      <small>		Member<br></br>Director, NIT Warangal</small>
    </a>
        </div>
      </div>

     <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="#" target="_blank">
      <b>Ms. Saumya Gupta, IAS </b><br />
      <small>		Member<br></br>Joint Secretary (TE) <br></br>MoE, Government of India <br></br> Shastri Bhawan, New Delhi.  </small>
    </a>
        </div>
      </div>

      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="#" target="_blank">
      <b>Sh. Sanjog Kapoor </b><br />
      <small>		Member<br></br>Joint Secretary & Financial Advisor
      <br></br>MoE, Government of India <br></br> Shastri Bhawan, New Delhi. </small>
    </a>
        </div>
      </div>

      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="#" target="_blank">
      <b>Prof. B. S. Murthy</b><br />
      <small>			Member(Sec. 11 Cl. (g) )<br></br>Director, Indian Institute of Technology Hyderabad, Ordinance Factory Estate, Yeddumailaram
      <br></br>director@iith.ac.in    </small>
    </a>
        </div>
      </div>



      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://erp.nitw.ac.in/ext/profile/me-madhu" target="_blank">
      <b>Prof. K. Madhu Murthy </b><br />
      <small>	Member(Sec. 11 Cl. (f) )<br></br>Professor (HAG)<br/> Department of Mechanical Engineering<br/> NIT Warangal
      <br></br>madhu@nitw.ac.in </small>
    </a>
        </div>
      </div>

      {/* <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16266" target="_blank">
      <b>Prof. Gupta G R K</b><br />
      <small>		Member(Sec. 11 Cl. (f) )<br></br>Associate Professor, Dept. of Mechanical Engineering, NIT, Warangal
      <br></br>gupta@nitw.ac.in   </small>
    </a>
        </div>
      </div> */}

<div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://erp.nitw.ac.in/ext/profile/ec-sai" target="_blank">
      <b>Prof. SKLV Sai Prakash</b><br />
      <small>Member <br></br>Associate Professor<br></br>Dept. of Electronics & Communication Engineering<br/> NIT Warangal<br/> sai@nitw.ac.in</small>
    </a>
        </div>
      </div>
    

      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://nitw.ac.in/page/?url=/NITWRegistrar/list" target="_blank">
      <b>Prof. N.V. Umamahesh</b><br />
      <small>		Secretary<br></br>Registrar In-charge,
      <br></br>National Institute of Technology Warangal.  </small>
    </a>
        </div>
      </div>





    </div>
    </div>
  );
};

export default BWCFCBoG;