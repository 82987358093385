export const nirf2022 = {
    "year":2022,
    "TLR": {
        "SS":{
            "score":16.00,"total":20.00
        },
        "FSR":{
            "score":22.60,"total":30.00
        },
        "FQE":{
            "score":15.00,"total":20.00
        },
        "FRU":{
            "score":14.28,"total":30.00
        }
    },
    "RP": {
        "PU":{
            "score":18.38,"total":35.00
        },
        "QP":{
            "score":18.79,"total":40.00
        },
        "IPR":{
            "score":0.00,"total":15.00
        },
        "FPPP":{
            "score":2.42,"total":10.00
        }
    },
    "GO": {
        "GPH": {
            "score":33.38,"total":40.00
        },
        "GUE":{
            "score":15.00,"total":15.00
        },
        "MS":{
            "score":17.10,"total":25.00
        },
        "GPHD":{
            "score":8.98,"total":20.00
        }
    },
    "OI": {
        "RD":{
            "score":16.03,"total":30.00
        },
        "WD":{
            "score":15.44,"total":30.00
        },
        "ESCS": {
            "score":4.04,"total":20.00
        },
        "PCS":{
            "score":20.00,"total":20.00
        }
    },
    "PR":{
        "PR":{
            "score":40.72,"total":100.00
        }
    },
    "overall":"https://nitw.ac.in/api/static/files/Overall_data_2023-1-2-10-9-33.pdf",
    "engineering":"https://nitw.ac.in/api/static/files/Engineering_data_2023-1-2-10-9-5.pdf",
    "annual_report":"https://oldweb.nitw.ac.in/media/uploads/2021/09/27/english-annual-report-2019-20-single-file-2.pdf",
}