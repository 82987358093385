import React, { useState } from "react";
import NewsScrollCard2 from "./NewsScrollCard2";
import "../css/NewsScroll.css";
import "../css/NewScroll2.css";
import "../css/NewScroll3.css";
// import { MdOutlineLocationOn } from "react-icons/md";

const NewScroll3 = (props) => {
  const [showFullContent, setShowFullContent] = useState({
    content1: false,
    content2: false,
    content3: false,
  });

  const handleToggleContent = (content) => {
    setShowFullContent((prevState) => ({
      ...prevState,
      [content]: !prevState[content],
    }));
  };

  return (

    <div className="flex">
      <h4 className="fw-bold subchildEventsPublication">{props.carouselTitle}</h4>
      <div className="text-subline"></div>
      <br />
      <div className="bar">



        <div class="row seminar-series">

          <div class="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Prof._Ashok_Jhunjhunwala_2024-5-17-13-25-3.jpg"
              alt=""
              class="img-responsive img-circle"
            />
          </div>
          <div class="col-md-8 col-xs-8 content">
            <h4 class="news-title">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details"> Topic: Making Start-ups successful in a university environment< i className="fa fa-external-link" aria-hidden="true"></i></a> </h4>
            {showFullContent.content2 ? (
              <p align="justify">
                Prof. Ashok Jhunjhunwala, Professor, IIT Madras; President, IITM Research Park: Chairman, IIIT Hyderabad


              </p>
            ) : (
              <p align="justify">
                Prof. Ashok Jhunjhunwala, Professor, IIT Madras....
                ....


                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />
          </div>
          <div class="col-md-2">
            <p class="calendar">
              11<em>May 2024</em>
            </p>
          </div>
        </div>


        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Prof_Vineet_R_Bhatt_2024-3-26-17-57-49.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details"> <h4 className="news-title">Topic: It Takes More to Building a Successful Career Than Just Earning a Degree   <i className="fa fa-external-link" aria-hidden="true"></i>  </h4> </a>
            {showFullContent.content2 ? (
              <p align="justify">

                Prof. Vineet R Bhatt is an Engineer from NIT Rourkela, a PGDBM from XLRI, Jamshedpur and certified in Global Business Leadership Programme (MBA programme in partnership with U21, Singapore, and Harvard Business School Publishing). He has close to thirty years of Rich Industry Experience, including long rewarding stints in manufacturing (TATA STEEL), IT (TECH MAHINDRA), and Education (ISB) in diverse people-facing functions and leadership roles. He is a member of the Harvard Business Review Advisory Council and is a Visiting Faculty at the Indian School of Business (ISB)  </p>
            ) : (
              <p align="justify">
                Prof. Vineet R Bhatt is an Engineer from NIT Rourkela, a PGDBM from XLRI, Jamshedpur and ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />

          </div>
          <div className="col-md-2">
            <p className="calendar">
              27 <em>March 2024</em>
            </p>
          </div>
        </div>

        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Dr._Tapan_Nayak_2024-3-15-10-42-27.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">  <h4 className="news-title">Topic: Recreating the Big Bang conditions at the CERN Large Hadron Collider     <i className="fa fa-external-link" aria-hidden="true"></i>
            </h4>  </a>
            {showFullContent.content2 ? (
              <p align="justify">

                Dr. Tapan Nayak, a senior physicist at CERN, Geneva, and Research Professor at the University of Houston, USA, previously served as Deputy Spokesperson of the ALICE collaboration at CERN. With extensive experience in high-energy physics, he led Indian groups in significant experiments at Brookhaven National Laboratory and CERN, contributing to the discovery of quark-gluon plasma (QGP). Dr. Nayak played a key role in India's membership in CERN as an Associate member.  </p>
            ) : (
              <p align="justify">
                Dr. Tapan Nayak, a senior physicist at CERN, Geneva, and Research Professor at ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />

          </div>
          <div className="col-md-2">
            <p className="calendar">
              18 <em>March 2024</em>
            </p>
          </div>
        </div>
        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Prof._Subhasish_Dey_2024-3-13-10-34-4.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details"> <h4 className="news-title">Topic: Hydrodynamics of Sediment Transport: Grain Scale to Continuum Scale     <i className="fa fa-external-link" aria-hidden="true"></i>     </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">

                Prof. Subhasish Dey is known for his research on hydrodynamics throughout the world. He is acclaimed for his contributions to developing theories and solution methodologies for various problems on applied hydrodynamics, turbulence, and sediment transport. He has published more than 220 papers in these areas. He was conferred with the Hans Albert Einstein Award from the American Society of Civil Engineers (ASCE) in 2022. He is currently a Distinguished Professor and Head of the Department of Civil and Infrastructure Engineering, Indian Institute of Technology (IIT) Jodhpur (2023–). Before that, he worked as a Professor at the Department of Civil Engineering, Indian Institute of Technology (IIT) Kharagpur (1998–2023). He is a fellow of the Indian National Science Academy (FNA), Indian Academy of Sciences (FASc), the National Academy of Sciences India (FNASc), the Indian National Academy of Engineering (FNAE) and the International Association for Hydro-Environment Engineering and Research (FIAHR). He has received the JC Bose Fellowship award in 2018.
              </p>
            ) : (
              <p align="justify">
                Prof. Subhasish Dey is known for his research on hydrodynamics throughout the world ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />

          </div>
          <div className="col-md-2">
            <p className="calendar">
              15 <em>March 2024</em>
            </p>
          </div>
        </div>
        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Dr._Manju_Sarma_2024-3-12-17-29-15.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: ISRO's Earth Observation Satellites Data and Women in Space Technologies      <i className="fa fa-external-link" aria-hidden="true"></i>     </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">

                Dr. Manju Sarma, Deputy Director, National Remote Sensing Centre, ISRO, Hyderabad, Telangana. Dr. Manju Sarma Graduated from IIT Roorkee, has 34 years of experience in Software development of Satellite Ground segment components from real time payload data acquisition, pre-processing, work flows to data dissemination web portals.

              </p>
            ) : (
              <p align="justify">
                Dr. Manju Sarma, Deputy Director, National Remote Sensing Centre, ISRO, Hyderabad  ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />

          </div>
          <div className="col-md-2">
            <p className="calendar">
              7 <em>March 2024</em>
            </p>
          </div>
        </div>
        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Dr._Nettem_Venkateswarlu_Choudary_2024-3-11-11-58-35.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: Energy Transitions - Challenges and Opportunities     <i className="fa fa-external-link" aria-hidden="true"></i>   </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Dr. Nettem Venkateswarlu Choudary is working as Distinguished Scientist at CSIR-IICT, Hyderabad since April 2022.
                He has over 35 years of Research Experience at IIT Bombay, Research Centres of IPCL Baroda, BPCL Greater Noida, and HPCL Bangalore in the areas of petroleum refining, petrochemicals, catalysis, adsorptive separations, alternative fuels and carbon capture and conversion. He along with his team developed over 40 patented technologies / products and commercialized over 25 of them immensely benefiting the industry. He was involved in setting up of Corporate R&D Centre of BPCL at Greater Noida and led a large team of scientists. He was responsible for establishing HPCL’s Green R&D Centre in Bangalore since inception with the state-of-the-art facilities. He steered a team of over 100 scientists and developed some major technologies and products and commercialized most of them in a short span of time.
                Dr. Choudary filed over 250 patents in India and abroad. Published over 100 book chapters and research papers in reputed peer reviewed journals with high impact factor and presented over 150 research papers in national and international conferences.
                He is a Fellow of Indian National Academy of Engineers (FNAE), and member of a number of other professional bodies.
                He has got several prestigious awards such as VASVIK Industrial Research Award for Chemical Science & Technology for the year 2019. Life time achievement award by Catalysis Society of India, Bangalore Chapter in Feb 2020, PetroFed Innovator of the Year –Team Award for the year 2015 and MOPNG Best Innovation awards for 2014-15, 2016-17 and 2017-18.
                He was a Brain Pool Visiting Scientist at Korea Institute of Energy Research (KIER), South Korea during 1996.
                He is a member of several expert committees such as IRRD scheme of SERB since 2018, PAC, Chemical and Environmental Engineering, SERB, Academic advisory committee, IIPE, Vizag during 2016-21.
              </p>
            ) : (
              <p align="justify">
                Dr. Nettem Venkateswarlu Choudary is working as Distinguished Scientist at CSIR-IICT, Hyderabad since  ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />

          </div>
          <div className="col-md-2">
            <p className="calendar">
              28 <em>February 2024</em>
            </p>
          </div>
        </div>

        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Prof._Indranil_Manna_2024-2-29-12-52-19.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: Science-Engineering-Technology Innovation and Synergy  <i className="fa fa-external-link" aria-hidden="true"></i>    </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Professor Manna, President, Indian National Academy of Engineering is currently the Vice Chancellor of BIT
                Mesra, Ranchi since August 2020 on lien from IIT Kharagpur. He obtained his B. E. Degree from Bengal
                Engineering College, Calcutta University in 1983, M. Tech. from IIT Kanpur in 1985, and PhD from IIT Kharagpur
                in 1990. He was a Premchand Roychand Scholar in 1992 and a Mouat Medal awardee in 1999 at Calcutta
                University. He is an educationist and materials engineer with wide-ranging research interests covering phase
                transformation, structure-property correlation and modeling in metallic alloys, nanostructured solids,
                structural ceramics, coatings by laser/plasma assisted surface engineering, additive manufacturing, and
                nano/ferrofluids. His studies on amorphous Al-alloys, laser surface engineering of
                steel/Magnecium/Aluminium alloys, boundary diffusion-controlled phase transformations, and
                thermal/magnetic properties of nano and ferrofluid are highly cited. He teaches subjects related to materials
                engineering and surface engineering. As a guest scientist, he visited renowned Institutions and Universities
                abroad such as Max Planck Institute at Stuttgart, Technical University of Clausthal, Liverpool University,
                Nanyang Technological University, NIMS-Japan, Unipress-Poland, and the University of Ulm. He has authored
                over 300 peer-reviewed publications, guided 30 doctoral theses, and conducted 35 sponsored projects worth
                over Rs 25 crore as the Principal Investigator.
                Professor Manna is a Fellow of all the national academies of science (INSA, IASc, NASI) and (INAE) in India, and
                was a JC Bose Fellow of DST from 2012 to 22. He was a DAAD fellow from 1988 to 90 and Humboldt Fellow
                from 2001 to 02 in Germany. He was honored as a distinguished alumnus by IIEST Shibpur and IIT Kharagpur.
                He is a recipient of The World Academy of Sciences (TWAS) prize for engineering sciences (2014) and was
                elected a Fellow of The World Academy of Sciences in 2015. He is also a Member of the Asia Pacific Academy
                of Materials. He received the MRSI Medal in 2000, Binani Gold Medal in 1999 and G D Birla Gold Medal in 2008
                of IIM, AICTE Career Award for Young Teachers in 1995, and INSA Medal for Young Scientist in 1992. He was
                felicitated by the Ministry of Steel with the Young Metallurgist in 1991, Metallurgist of the Year in 2002 and
                National Metallurgist Award in 2018. He was the National Coordinator of IMPacting Research INnovation and
                Technology (IMPRINT), a unique technology development initiative of MHRD during 2015-20. He has been a
                member of the Research Boards of several industries and R and D Organizations such as RINL, GAIL, BHEL, ARCI,
                Min of Steel and serves in several national level committees and boards of DST, DBT, CSIR, SERB, INSA, etc.
                Earlier, he served as an expert in NAAC and NBA. He is a former Vice President and President of the Indian
                Institute of Metals during 2013 to 2017, President of the Materials Science Section of the Indian Science
                Congress during 2009 to 10, and Vice President of the INAE from 2015 to 21. The University of Kalyani and Kazi
                Nazrul University conferred DSc (hc) on him in 2017. He served as the Director of CSIR-CGCRI in Kolkata during
                2010-2012 and of IIT Kanpur during 2012-2017. Professor Manna is now the 14 th  President of INAE since Jan 1,
                2021, and is passionately involved in making INAE a national peer body for promoting engineering and
                technological self-reliance of India by translational research, innovation, entrepreneurship, and new
                technology.

              </p>
            ) : (
              <p align="justify">
                Professor Manna, President, Indian National Academy of Engineering is currently the Vice Chancellor of  ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />

          </div>
          <div className="col-md-2">
            <p className="calendar">
              17 <em>February 2024</em>
            </p>
          </div>
        </div>
        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Professor_Ajay_Kumar_Dalai_2024-2-17-10-21-34.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details"> <h4 className="news-title">Topic: Clean Sustainable Energy Production from Biomass for Pollution Abatement Using Bioprocessing Technologies    <i className="fa fa-external-link" aria-hidden="true"></i>  </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Professor Ajay Kumar Dalai is an Exceptional Researcher, Distinguished Professor, and Canada Research Chair (Tier 1) in Bioenergy in the Department of Chemical and Biological Engineering at the University of Saskatchewan. He has served as Head of Chemical Engineering and as Associate Dean, Research and Partnership in the College of Engineering. Professor Dalai worked as Special Adviser to Vice-President Research Signature Areas of Energy and Mineral Resources. Professor Dalai’s research focus is on sustainable energy development including bioenergy, gas to liquid technologies, biodiesel production, hydrogen production, value-added products from biomass, and pollution control. Dr. Dalai has published over 700 research papers mostly in heterogeneous catalysis and catalytic processes in international journals and conference proceedings with his 150+ researchers and many national and international collaborators. He has got several patent applications. His ground-breaking research has led to over 42,500 citations of his work, H-index of 100 and i10-index of 463. He serves in Editorial Board of several prestigious journals. He has received fellowship from 10 different national and international societies. Professor Dalai is also a Fulbright Fellow from USA, DAAD Fellow from Germany and JSPS Fellow from Japan. He has received several national and international awards. Recently, he has received Indian Institute of Chemical Engineers DRC Life Time Achievement Award, Canadian Society of Chemical Engineering R.S. Jane Memorial Award for outstanding contributions to Chemical Engineering Profession, Royal Society of Canada’s Miraslow Romanoswki Medal for Outstanding Contribution to Environmental Science, University of Saskatchewan’s George Ivany Internationalization Award, and University of Saskatchewan’s Distinguished Researcher Award.               </p>
            ) : (
              <p align="justify">
                Professor Ajay Kumar Dalai is an Exceptional Researcher, Distinguished Professor, and Canada Research Chair (Tier 1) in Bioenergy in the ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />

          </div>
          <div className="col-md-2">
            <p className="calendar">
              13 <em>February 2024</em>
            </p>
          </div>
        </div>


        <div className="row seminar-series">

          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Prof._Pisupati_V_Sarma_2024-1-5-9-53-28.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: Path Towards Net-Zero Carbon Emissions: Challenges and Research Needs     <i className="fa fa-external-link" aria-hidden="true"></i>  </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Prof. Sarma V. Pisupati is a Professor of Energy and Mineral Engineering, Chemical Engineering, and Director of the Center for Critical Minerals (C2M) at The Pennsylvania State University, USA. Before forming the C2M and taking on its leadership, Dr. Pisupati established the first undergraduate energy engineering degree program in the US, led through the ABET accreditation, and served as Program Chair for eleven years until 2019. Dr. Pisupati, with chemical engineering training, has been studying and teaching energy and environmental issues for 40 years. He worked in the industry for five years before joining Penn State. Dr. Pisupati’s current areas of scientific research are coal and biomass gasification, oxy-combustion and chemical looping combustion, and extracting critical and rare earth elements from secondary sources, promoting a circular economy. His research team developed a patent-pending three-stage necessary mineral extraction process from acid mine drainage (AMD). He co-authored over 225 research publications and has one US and two provisional patents. He is a Fellow of the American Chemical Society (ACS) and Indian Institute of Chemical Engineers (IIChE) and serves on the Editorial Boards of several scientific journals. He received numerous Teaching Awards, including The George W. Atherton Award for Excellence in Teaching and the G. Montgomery and Marion Mitchell Award for Innovative Teaching, Dr. R.A. Mashelkar Medal for Innovation and Science Leadership by the Indian Institute of Chemical Engineers in 2021, and several best paper awards at professional society conferences and annual meetings, including AIChE, IIChE, ASEE, and Pittsburgh Coal Conference.

              </p>
            ) : (
              <p align="justify">
                Prof. Sarma V. Pisupati is a Professor of Energy and Mineral Engineering, Chemical Engineering, and Director of the Center for Critical Minerals (C2M)
                ....


                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />
          </div>
          <div className="col-md-2">
            <p className="calendar">
              4<em>Jan 2024</em>
            </p>
          </div>
        </div>



        <div className="row seminar-series">

          <div className="col-md-2 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Prof._Devang_V_Khakhar_2023-11-30-19-17-11.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: Fundamentals of Granular Flow, Mixing and Segregation       <i className="fa fa-external-link" aria-hidden="true"></i>   </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Prof. Devang Khakhar is currently Professor of Chemical Engineering at the Indian
                Institute of Technology Bombay. Prof. Khakhar's research interests include mechanics of granular materials
                and polymer processing. He has published more than 100 papers in these
                areas. For his research achievements, Prof. Khakhar has been accorded several
                national awards, which include the Shanti Swarup Bhatnagar Prize (1997), the
                Swarnajayanti Fellowship (1998) and the J. C. Bose Fellowship (2010). Prof.
                Khakhar is also a recipient of IIT Bombay's “Excellence in Teaching Award”
                and the “Mathur Award for Research Excellence”. He is a Fellow of all three
                science academies of India and the Indian National Academy of Engineering,
                and currently the INAE Chair Professor. Prof. Khakhar has served in several
                administrative positions at IIT Bombay and was the Director of IIT Bombay
                from January 2009 to April 2019. He has been a member of the Atomic Energy
                Regulatory Board (AERB) since 2011 and is a member of the Governing Board
                of CSIR. He is currently Vice President (Science and Society), Indian National
                Science Academy and serves as Chairman of the Governing Board of TIFAC,
                DST.

              </p>
            ) : (
              <p align="justify">
                Prof. Devang Khakhar is currently Professor of Chemical Engineering at the Indian Institute of Technology Bombay.
                ....


                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />
          </div>
          <div className="col-md-2">
            <p className="calendar">
              24<em>Nov 2023</em>
            </p>
          </div>
        </div>







        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-3 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Dr._KOMAL_KAPOOR_v2_2023-10-5-12-54-11.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-6 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: Manufacturing Capability to Build Nuclear Power Base      <i className="fa fa-external-link" aria-hidden="true"></i>  </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Dr. Komal Kapoor is the Outstanding Scientist &amp; Chief Executive of Nuclear Fuel Complex (NFC) and the Chairman of NFC Board. Dr. Kapoor is a Metallurgical Engineer from the 33 rd batch of BARC Training School, Mumbai. Subsequently, he was posted to NFC and served in various capacities for more than three decades. He has obtained Doctorate degree in Metallurgical Engineering and Materials Science from the Indian Institute of Technology (IIT), Mumbai. Dr. Kapoor is an Adjunct Professor in Homi Bhabha National Institute (HBNI), Mumbai and the Guest Faculty at the University of Hyderabad (UoH).  Dr. Kapoor possesses extensive knowledge of nuclear fuel cycles and materials. His research work has led to the development of several critical nuclear materials which are of great technical importance. Dr. Kapoor is also responsible for numerous research collaborations with various national and international institutions in Nuclear Engineering. For his invaluable contributions in this field, Dr. Kapoor has been conferred with the several prominent Awards and Honours. He has to his credit over 100 Publications in National/International Journals of repute and Symposia.
              </p>
            ) : (
              <p align="justify">
                Dr. Komal Kapoor is the Outstanding Scientist &amp; Chief Executive of Nuclear Fuel Complex (NFC) and ....
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />
            {/* <MdOutlineLocationOn size={20} /> ALC, NIT WARANGAL */}
          </div>
          <div className="col-md-2 col-3">
            <p className="calendar">
              10<em>Oct 2023</em>
            </p>
          </div>
        </div>



        <div className="row seminar-series">
          {/* Content 2 */}
          <div className="col-md-2 col-3 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Shri._Sanjeev_Kumar_Varshney_2023-9-29-13-13-45.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-6 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: International Science and Technology Cooperation      <i className="fa fa-external-link" aria-hidden="true"></i>  </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Shri. Sanjeev Kumar Varshney is the Head of International Scientific Cooperation in the Department of Science & Technology . He plays a crucial role in facilitating international scientific partnerships. Dr. Varshney holds several significant positions, including being the Indian Co-Chair for the Indo-German Science & Technology Centre and the U.S-India Science and Technology Endowment Fund, both of which promote joint industrial R&D. He is also a member of the Board of Directors of Global Innovation & Technology Alliance and the Governing Council of the International Advanced Research Centre of Powder Metallurgy.Furthermore, Dr. Varshney serves as the Indian Focal Point for the BRICS Working Group on Science & Technology and the OECD Committee on Science & Technology Policy.
              </p>
            ) : (
              <p align="justify">
                Shri. Sanjeev Kumar Varshney is the Head of International Scientific Cooperation in the Department of Science & Technology . He plays a crucial role in ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />
            {/* <MdOutlineLocationOn size={20} /> Bose Seminar Hall, NIT WARANGAL */}
          </div>
          <div className="col-md-2 col-3">
            <p className="calendar">
              27 <em>Sep 2023</em>
            </p>
          </div>
        </div>





        <div className="row seminar-series">

          {/* Content 2 */}
          <div className="col-md-2 col-3 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Dr_R_N_Sankhua_2023-9-20-11-39-7.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8  col-6 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: Envisioning Perspicuous Digital Hydraulic Infrastructure in ILR       <i className="fa fa-external-link" aria-hidden="true"></i>
              </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Dr R N Sankhua, Ph.D., FIE, CE(I), in his current role as Chief Engineer, South, Ministry of Jal Shakti, Govt of India, Dr. R.N. Sankhua provides planning, design and executive services for 16 river interlinking projects in the Peninsular component, designed to efficiently use and conserve India’s water. A published researcher in water resources, with a focus on technology-driven planning, hydroinformatics & modelling, he contributed 193 papers to International/National Journals/Int Conf and completed 25 Projects on remote sensing & GIS. He belongs to 1990 batch of UPSC Engg. Services. He obtained his B.E.(Hons) in Civil Engg from NIT-Rourkela and then continued his Masters in IIT-Kharagpur. He holds a Ph.D. in Water Resources from IIT Roorkee after his second Master’s from there. He guided 7 Ph.D. theses & 30 Master’s in water resources from IIT Mumbai & University of Pune. He has served as Director of National Water Academy, Pune & organized 60 training programs. Previously worked as Director, Basin Planning, he mentored the studies on i) Reassessment of basin wise water potential of India using space inputs, ii) Basin planning study of Brahmani-Baitarani with CSIRO, Australia and Integrated Reservoir Operation & IWRM studies. As Chief Engineer (South), he mentored the Peninsular DPRs of Godavari-Krishna, Krishna-Pennar, Pennar-Cauvery, Cauvery-Vaigai-Gundar, Almatti-Pennar, Pennar-Palar-Cauvery, Srisailam-Pennar, Damanganga-Ekdhare-Godavari (DEG), Damanganga-Vaitarna-Godavari (DVG) and Bedti-Varada-Dharma River links besides Water Balance Studies of 57 sub-basins. He specialises in hydroinformatics, basin planning, spatial, hydrological & hydraulic modelling.
              </p>
            ) : (
              <p align="justify">
                Dr R N Sankhua, Ph.D., FIE, CE(I), in his current role as Chief Engineer, South, Ministry of Jal Shakti, Govt of India, ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />
            {/* <MdOutlineLocationOn size={20} /> ALC, NIT WARANGAL */}
          </div>
          <div className="col-md-2 col-3">
            <p className="calendar">
              15 <em>Sep 2023</em>
            </p>
          </div>
        </div>





        {/* <div className="row seminar-series">
        
          <div className="col-md-2 col-3 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/Dr._Deepak_Puthal_2023-6-12-12-14-8.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-6 col-xs-8 content">
            <h4 className="news-title">
              Topic: Software-Defined Perimeter: AI-driven Solution to Securing the Systems with User Demands
            </h4>
            {showFullContent.content1 ? (
              <p>
                Dr. Deepak Puthal is an award-winning researcher and is well-known internationally for his contributions
                to research in Cyber Security, Adversarial machine learning, Blockchain, Edge Computing, and the
                Internet of Things. He has won several competitive international awards in recognition of original
                research and innovations that led to advancements in his research specialization, such as the 2022 IEEE
                HITC Award for Excellence in Hyper-Intelligence Systems (Middle Career Researcher award), 2021 IEEE
                Computer Society Smart Computing STC Early-Career Award, 2021 IEEE TEMS TC on Blockchain and Distributed
                Ledger Technologies (runner-up), 2019 Best IEEE ComSoc Young Researcher Award (For Europe, Middle East,
                and Africa Region), 2018 IEEE TCSC Award for Excellence in Scalable Computing (Early Career Researcher),
                2018 IETE Best Research Award, four IEEE best paper awards, etc. He is an IEEE Distinguished Speaker
                (IEEE CTSoc).
              </p>
            ) : (
              <p>
                Dr. Deepak Puthal is an award-winning researcher and is well-known internationally for his contributions
                to research in Cyber Security, Adversarial machine learning, Blockchain, Edge Computing, and the
                Internet of Things. ...{" "}
                <a href="#" onClick={() => handleToggleContent("content1")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />
           
          </div>
          <div className="col-md-2 col-3">
            <p className="calendar">
              13 <em>July 2023</em>
            </p>
          </div>
        </div> */}

        <div className="row seminar-series">
          <div className="col-md-2 col-3 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/c1_2023-6-11-12-14-43.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8  col-6 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: Cryogenics for Electrical Engineering     <i className="fa fa-external-link" aria-hidden="true"></i>  </h4></a>
            {showFullContent.content2 ? (
              <p align="justify">
                Prof. S. K. Sarangi, FNAE, Professorial Fellow, IIT Bhubaneswar. Professor Sunil Kumar Sarangi, a fellow
                of the National Academy of Engineering, served as Professor of Cryogenic Engineering at IIT Kharagpur.
                Indian National Academy fo Engineering has given him its Outstanding Teacher award of 2016. The Indian
                Cryogenics Council has also Honoured him with Life Time Achievement award 2017. Professor Sarangi also
                served as the Director of NIT Rourkela for three terms during the period 2023 to 2016. Under his
                stewardship, the institute established itself as one of the leading centres of engineering education. In
                addition to his academic contributions, Prof. Sarangi has published over 100 research papers and mentored
                numerous students in the field of Cryogenics.
              </p>
            ) : (
              <p align="justify">
                Prof. S. K. Sarangi, FNAE, Professorial Fellow, IIT Bhubaneswar. Professor Sunil Kumar Sarangi, a fellow
                of the National Academy of Engineering, served as Professor of Cryogenic Engineering at IIT Kharagpur.
                Indian National Academy ...{" "}
                <a href="#" onClick={() => handleToggleContent("content2")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />

          </div>
          <div className="col-md-2 col-3">
            <p className="calendar">
              19 <em>MAY 2023</em>
            </p>
          </div>
        </div>

        <div className="row seminar-series">
          {/* Content 3 */}
          <div className="col-md-2 col-3 col-xs-4 img-padding">
            <img
              src="https://nitw.ac.in/api/static/files/c2_2023-6-11-12-15-7.jpg"
              alt=""
              className="img-responsive img-circle"
            />
          </div>
          <div className="col-md-8 col-6 col-xs-8 content">
            <a href="https://www.nitw.ac.in/seminar" data-bs-toggle="tooltip" data-bs-placement="top" title="Seminar Details">
              <h4 className="news-title">Topic: National Education Policy - 2022      <i className="fa fa-external-link" aria-hidden="true"></i>  </h4></a>
            {showFullContent.content3 ? (
              <p align="justify">
                Prof. K. Umamaheshwar Rao, Director, NIT Rourkela. Prof. K. Umamaheshwar Rao was the Director of NIT
                Surtathkal. He was a full-time professor for almost one and a half decades, Chairman (Estate), Vice
                Chairman JEE and Head of the Mining Engineering Department at IIT Kharagpur. He was awarded Erudite
                fellowship from KIGAM (South Korea) and INSA-KOSEF (Korea Science and Engineering Foundation) award. He
                was Visiting Professor at the Chonnam National University (CNU), Gwangju, South Korea. He was a Visiting
                Professor in the University of British Columbia (UBC) Okanagon campus, invited by the University of
                Adelaide, Australia for delivering lectures in mining engineering. Prof. Rao has authored several
                publications and is actively involved in promoting research and development in the field of education.
              </p>
            ) : (
              <p align="justify">
                Prof. K. Umamaheshwar Rao, Director, NIT Rourkela. Prof. K. Umamaheshwar Rao was the Director of NIT
                Surtathkal. He was a full-time professor for almost one and a half decades, Chairman (Estate), Vice
                Chairman JEE and Head of the Mining....{" "}
                <a href="#" onClick={() => handleToggleContent("content3")}>
                  Continue reading
                </a>
              </p>
            )}
            <br />
            {/* <MdOutlineLocationOn size={20} /> ALC, NIT WARANGAL */}
          </div>
          <div className="col-md-2 col-3">
            <p className="calendar">
              19 <em>MAY 2023</em>
            </p>
          </div>
        </div>



      </div>
    </div>
  );
};

export default NewScroll3;
