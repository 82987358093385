import React from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name

const ICCpage = () => {
   
    return (

        <div class="container mb-3">

            <div class="row">
                <div></div>
                <br></br>
                <h3 align='center'>Internal Complaints Committee</h3>
                <p class="justified-text">
                </p>
                <p class="justified-text">
                    In accordance with the provisions of the Sexual Harassment of Women at Workplace (Prevention, Prohibition &amp; Redressal) Act, 2013, the Internal Complaints Committee of NIT Warangal, is constituted. Any complaint of Sexual Harassment may be reported to icc@nitw.ac.in / 8332969705
                </p>
                <p class="justified-text">
                </p>
                <h4>
                    Members
                </h4>

                <table class="table table-bordered">
                    <tbody>

                        <tr>
                            <td>
                                S.No.
                            </td>
                            <td>
                                Name &amp; Designation
                            </td>
                            <td>
                                Position
                            </td>
                            <td>
                                Contact Details<br></br>
                                (Mobile No. &amp; E-mail)
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                <a href="https://erp.nitw.ac.in/ext/profile/ma-hprani" target="_blank"> <b>Prof. Hari Ponnamma Rani</b></a><br></br>
                                Department of Mathematics, NITW
                            </td>
                            <td>
                                Chairperson
                            </td>
                            <td>
                                8332969705<br></br>
                                icc@nitw.ac.in
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2
                            </td>
                            <td>
                                <a href="https://erp.nitw.ac.in/ext/profile/sm-vrdevi" target="_blank"> <b> Prof. V. Rama Devi</b></a><br></br>
                                Department of Management Studies, NITW
                            </td>
                            <td>
                                Member
                            </td>
                            <td>
                                vrdevi@nitw.ac.in
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3
                            </td>
                            <td>
                                <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16306" target="_blank"><b> Prof. S. Anuradha</b></a><br></br>
                                Department of Electronics and Electrical Engineering, NITW
                            </td>
                            <td>
                                Member
                            </td>
                            <td>
                                anuradha@nitw.ac.in
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4
                            </td>
                            <td>
                                <a href="https://erp.nitw.ac.in/ext/profile/ce-phari" target="_blank"><b> Prof. P. Hari Krishna</b></a><br></br>
                                Department of Civil Engineering, NITW
                            </td>
                            <td>
                                Member
                            </td>
                            <td>
                                phari@nitw.ac.in
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                <b> Dr. Usha Rani</b><br></br>
                                Asst. Professor and Microbiologist, KMC Warangal
                            </td>
                            <td>
                                External Member
                            </td>
                            <td>
                                doc.ushapradeep@gmail.com
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6
                            </td>
                            <td>
                                <b> Three student council members</b> <br></br>
                                (from Academic, Hostel &amp; Girls) (In case of complaint pertaining to students)
                            </td>
                            <td>
                                Members
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p class="justified-text">
                </p>
                <h4>
                    The Role of the Committee:
                </h4>
                <p class="justified-text">
                    To act as Inquiry Authority on a complaint of sexual harassment.
                </p>
                <p class="justified-text">
                    To ensure that victims and witnesses are not victimised or discriminated because of their complaint.
                </p>
                <p class="justified-text">
                    To take proactive measures towards sensitization of staff, students and faculty member of NIT Warangal on gender issues.
                </p>
                <p class="justified-text">
                    To discharge any other functions as per the Act.
                </p>
                <p class="justified-text">
                </p>
                <h4>
                    What is sexual harassment?
                </h4>
                <p class="justified-text">
                    Unwelcome acts or behavior with sexual undertones (whether directly or by implication) including
                </p>
                <p class="justified-text">
                    - physical contact and advances
                </p>
                <p class="justified-text">
                    - a demand or request for sexual favors
                </p>
                <p class="justified-text">
                    - making sexually colored remarks
                </p>
                <p class="justified-text">
                    - showing pornography
                </p>
                <p class="justified-text">
                    - any other unwelcome physical, verbal, or non-verbal conduct of sexual nature
                </p>
                <p class="justified-text">
                </p>
                <h4>
                    Who can approach ICC and file a complaint?
                </h4>
                <p class="justified-text">
                    Any woman employee (faculty and staff including contractual, casual and temporary) of NIT Warangal, female students, and any women residing in NIT Warangal Campus or visiting the Campus in any capacity may file a complaint against an incident of sexual harassment.
                </p>
                <p class="justified-text">
                </p>
                <h4>
                    How and where to file a complaint?
                </h4>
                <p class="justified-text">
                    The person concerned can personally approach/telephone/write/e-mail to any member of ICC followed by the submission of a formal written complaint as a hard copy addressed to the Presiding Officer, ICC within 90 days of the incident about which the complaint is filed. The ICC is required to complete the process of inquiry and submit its report within 90 days of the filing of the complaint.
                </p>
                <p class="justified-text">
                </p>
                <h4>
                    The formal complaint must contain the following:
                </h4>
                <p class="justified-text">
                    - Date and time of the incident
                </p>
                <p class="justified-text">
                    - The place where it occurred
                </p>
                <p class="justified-text">
                    - Name and contact information of the accused
                </p>
                <p class="justified-text">
                    - Name and contact information of witnesses
                </p>
                <p class="justified-text">
                    - Detailed description of the incident
                </p>
                <p class="justified-text">
                    All information related to a complaint will be kept strictly confidential.
                </p>
                <p class="justified-text">
                </p>
                <h4>
                    Resources:
                </h4>
                <p class="justified-text">
                    1. <a href="https://wcd.nic.in/sites/default/files/Sexual-Harassment-at-Workplace-Act.pdf" target="_blank">POSH ACT 2013</a>
                </p>
                <p class="justified-text">
                    2. <a href="https://wcd.nic.in/sites/default/files/Handbook%20on%20Sexual%20Harassment%20of%20Women%20at%20Workplace.pdf" target="_blank">Handbook on POSH</a>
                </p>
                <p class="justified-text">
                    3. <a href="https://nitw.ac.in/api/static/files/Vishaka_Guidelines_2024-5-8-11-58-10.pdf" target="_blank">Vishaka Guidelines against Sexual Harassment at Workplace</a>
                </p>
                <p class="justified-text">
                    4. <a href="https://www.ugc.gov.in/pdfnews/7203627_UGC_regulations-harassment.pdf" target="_blank">The University Grants Commission (Prevention, Prohibition and Redressal of Sexual Harassment of Women Employees in Higher Educational Institutions) Regulations 2015</a></p>
                <p class="justified-text">
                    5. <a href="https://dopt.gov.in/sites/default/files/CCS_Conduct_Rules_1964_Updated_27Feb15_0.pdf" target="_blank">CENTRAL CIVIL SERVICES (CONDUCT) RULES, 1964 (Updated)</a>
                </p>
                <p class="justified-text">
                    6. <a href="https://nitw.ac.in/api/static/files/Student_Disciplinary_Manual_2024-5-8-11-58-27.pdf" target="_blank">Student Disciplinary Manual</a>
                </p>
                <p class="justified-text">
                </p>
                <h4>
                    Events:
                </h4>
                <h5><b><em>Workshop on “POSH at Workplace and for HEI”, 15th July 2024</em></b></h5>
                {/* <img src=""></img> */}
                <div class="row" 
               
                >
                <div class="col-sm-12 col-md-6 col-lg-4 mt-4 member" >
                    <div class="thumbnail">
                        
                            <img src="https://nitw.ac.in/api/static/files/staff_2024-7-29-10-31-7.jpg" alt="POSH "  />
                                <div class="caption">
                                    
                                </div>
                        
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mt-4 member">
                    <div class="thumbnail">
                        
                            <img src="https://nitw.ac.in/api/static/files/both_2024-7-29-11-11-55.jpg" alt="POSH" />
                                <div class="caption">
                                   
                                </div>
                       
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4  mt-4 member">
                    <div class="thumbnail">
                       
                            <img src="https://nitw.ac.in/api/static/files/director_2024-7-29-11-16-15.jpg" alt="POSH" />
                                <div class="caption">
                                    
                                </div>
                       
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mt-4 member">
                    <div class="thumbnail">
                       
                            <img src="https://nitw.ac.in/api/static/files/students_2024-7-29-11-19-16.jpg" alt="POSH" />
                                <div class="caption">
                                    
                                </div>
                       
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mt-4 member">
                    <div class="thumbnail">
                       
                            <img src="https://nitw.ac.in/api/static/files/Guest_2024-7-29-11-21-25.jpg" alt="POSH" />
                                <div class="caption">
                                    
                                </div>
                       
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mt-4 member">
                    <div class="thumbnail">
                       
                            <img src="https://nitw.ac.in/api/static/files/students2_2024-7-29-11-40-41.jpg" alt="POSH" />
                                <div class="caption">
                                    
                                </div>
                       
                    </div>
                </div>
            </div>

            


        </div>
        </div >
    );
};

export default ICCpage;
