import "../css/NewsScrollCard2.css";
import LaunchIcon from '@mui/icons-material/Launch';

const NewsScrollCard2 = (props) => {
  return (
    <div className="scroll-card-wrapper flex newmarquee">
      <div className="row text-center">
        {props.image ? (
          <div className="col-12">
            {" "}
            <img src={props.image} className="scroll-card-img" alt="" />{" "}
          </div>
        ) : (
          <></>
        )}

        <div className="col-12" style={{ paddingTop: "10px" }}>
          {/* {" "} */}
          <a href={props.url} className="read-more"><h4 className="news-title"> {props.title} </h4> </a>
          {/* {" "} */}
        </div>
        {/* <br /> */}
        <div className="col-12">
          <p className="read-mores">
            {props.content}
            {/* <a href={props.url} className="read-more"><LaunchIcon/></a> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewsScrollCard2;
