import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
// import { FACILITIES } from '../constants/InstituteFecilitiesData1';
import '../css/Deans.css'; // Import the styleintranet1.css file

export const StaticDeans = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const deans = [
    
    // {
    //   name: "Dean (IR & AA)",
    //   designation: "Prof. D. Ramaseshu",
    //   phoneNumber: "9491065002",
    //   email: "dean_iraa@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16198/16198.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ce-drseshu",
    // },
    // {
    //   name: "Dean (Research & Development)",
    //   designation: "Prof. V. T. Somasekhar",
    //   phoneNumber: "9490165364",
    //   email: "dean_rc@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/Prof._Somasekhar_V._T._2024-3-7-17-37-11.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ee-sekhar",
    // },
    
    {
      name: "Dean (IR & AA)",
      designation: "Prof. S Srinivasa Rao",
      phoneNumber: "9491065002",
      email: "dean_iraa@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Prof._Srinivasa_Rao_Sandepudi_2024-3-7-17-40-43.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ee-ssr",
    },
    {
      name: "Dean (Research & Development)",
      designation: "Prof. Sonawane Shirish Hari",
      phoneNumber: "9490165364",
      email: "dean_rc@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ch-shirish",
      link: "https://erp.nitw.ac.in/ext/profile/ch-shirish",
    },

    {
      name: "Dean (Faculty Welfare)",
      designation: "Prof. A. Venu Vinod",
      phoneNumber: "9490164973",
      email: "dean_fa@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16322/16322.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ch-avv",
    },

    {
      name: "Dean (Academic)",
      designation: "Prof. A. Sarath Babu",
      phoneNumber: "9490165361",
      email: "dean_acad@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ch-sarat",
    },


    {
      name: "Dean (Student Welfare)",
      designation: "Prof. D. Srinivasacharya",
      phoneNumber: "9490165362",
      email: "dean_sa@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16393/16393.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ma-dsc",
    },

    {
      name: "Dean (Planning & Development)",
      designation: "Prof. P. Rathish Kumar",
      phoneNumber: "9490165363",
      email: "dean_pl@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16209/16209.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ce-rateeshp",
    },
    // Add more deans as needed


    // Add more deans as needed


  ];

  const associateDeans = [
    // {
    //   name: "Associate Dean (Faculty Welfare)",
    //   designation: "Prof. G. Naga Srinivasulu",
    //   phoneNumber: "9440509659",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16277/16277.jpg",
    //   link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16277",
    // },
    // {
    //     name: "Associate Dean (Student Welfare)",
    //     designation: "Prof. B. Satish Ben",
    //     phoneNumber: "9848065254",
    //     image: "https://wsdc.nitw.ac.in/facultynew/media/16439/16439.jpg",
    //     link: "https://erp.nitw.ac.in/ext/profile/me-satishben",
    //   },
    //   {
    //       name: "Associate Dean (Student Welfare)",
    //       designation: "Prof. R. Padmavathy",
    //       phoneNumber: "9440173819",
    //       image: "https://wsdc.nitw.ac.in/facultynew/media/16345/16345.jpg",
    //       link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345",
    //     },
    // {
    //   name: "Associate Dean (Admissions)",
    //   designation: "Prof. Venkaiah Chowdary",
    //   phoneNumber: "8332969703",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16215/16215.jpg",
    //   link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16215",
    // },

    // {
    //   name: "Associate Dean (Academic Research Programs)",
    //   designation: "Prof. V. Rajesh Khana Raju",
    //   phoneNumber: "9492416897",
    //   email: "ad_acad.phd@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16283/16283.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/me-vrkraju",
    // },

    // {
    //   name: "Associate Dean (Academic - PG)",
    //   designation: "Prof. K. Thangaraju",
    //   phoneNumber: "8332969476",
    //   email: "ad_acad.pg@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ph-ktr",
    // },

    // {
    //   name: "Associate Dean (Academic - UG)",
    //   designation: "Prof. Vidyasagar Shilapuram",
    //   phoneNumber: "8332969710",
    //   email: "ad_acad.ug@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ch-vidyasagars",
    // },
    {
      name: "Associate Dean (Academic Research Programs)",
      designation: "Prof. K V R Ravi Shankar",
      // phoneNumber: "9492416897",
      phoneNumber: "8332969262",
      email: "ad_acad.phd@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ce-ravikvr",
      link: "https://erp.nitw.ac.in/ext/profile/ce-ravikvr",
    },

    {
      name: "Associate Dean (Academic - PG)",
      designation: "Prof. D. Bhargavi",
      // phoneNumber: "8332969262",
      phoneNumber: "8332969456",
      email: "ad_acad.pg@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ma-bhargavi",
      link: "https://erp.nitw.ac.in/ext/profile/ma-bhargavi",
    },

    {
      name: "Associate Dean (Academic - UG)",
      designation: "Prof. Syed Ismail",
      // phoneNumber: "8332969710",
      phoneNumber: "8332969367",
      email: "ad_acad.ug@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/me-syedismail7",
      link: "https://erp.nitw.ac.in/ext/profile/me-syedismail7",
    },

    // {
    //   name: "Associate Dean (Academic Audit)",
    //   designation: "Prof. K. Thangaraju",
    //   phoneNumber: "8332969476",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg",
    //   link: "https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg",
    // },

    // {
    //   name: "Associate Dean (Planning & Development)",
    //   designation: "Prof. R. Satish Babu",
    //   phoneNumber: "8332969439",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16352/16352.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/bt-satishbabu",
    // },

    {
      name: "Associate Dean (International Relations)",
      designation: "Prof. T. Mahesh Kumar",
      phoneNumber: "8179048491",
      email: "ad_ir@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Prof._Mahesh_Kumar_Talari_2024-4-25-10-40-0.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/mm-talari",
    },

    // {
    //   name: "Associate Dean (Alumni Affairs)",
    //   designation: "Prof. Abdul Azeem P",
    //   phoneNumber: "8332969473",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg",
    //   link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16367",
    // },

    // {
    //   name: "Associate Dean (Industrial Consultancy)",
    //   designation: "Prof. V. Vasu",
    //   phoneNumber: "8019789214",
    //   email: "ad_ic@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16276/16276.jpg",
    //   link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16276",
    // },
   {
    name: "Associate Dean (Industrial Consultancy)",
    designation: " Prof. P Subhash Chandra Bose",    
    phoneNumber: "8332969245",
    email: " ad_ic@nitw.ac.in ", 
    image: "https://erp.nitw.ac.in/ext/profile/getUserImage/me-subhash",
    link: "https://erp.nitw.ac.in/ext/profile/me-subhash",
  },


    // {
    //   name: "Associate Dean (Special Programmes)",
    //   designation: "Prof. D. Jaya Krishna",
    //   phoneNumber: "8332969369",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16435/16435.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/me-djayakrishna",
    // },

    // {
    //   name: "Associate Dean (Incubation Translation & Start-ups)",
    //   designation: "Prof. Sonawane Shirish Hari",
    //   phoneNumber: "8702468626",
    //   email: "ad_its@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ch-shirish",
    // },
    {
      name: "Associate Dean (Faculty Welfare)",
      designation: "Prof. K. Hari Prasad",
      phoneNumber: "9550803036",
      email: "assodean_fw@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16585/16585.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cy-harikokatla",
    },

    {
      name: "Associate Dean (Staff Recruitment & Training)",
      designation: "Prof. V. Venkata Mani",
      phoneNumber: "9705024365",
      email: "ad_srt@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16307/16307.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ec-vvmani",
    },


    // {
    //   name: "Associate Dean (Wellness)",
    //   designation: "Prof. M. Sailaja Kumari",
    //   phoneNumber: "08702462235",
    //   email: "ad_wellnesss@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16234/16234.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ee-sailaja",
    // },
    {
      name: "Associate Dean (Admissions)",
      designation: " Prof. S. Ravi Chandra",
      phoneNumber: " 8332969703",
      email: "admissions@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Prof._S._Ravi_Chandra_2024-3-4-18-30-42.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cs-ravic",
    },
    // Add more associate deans as needed

  ];


  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    setHoveredIndex(null);
  };

  const facilitiesPerColumn = Math.ceil(deans.length / 2);
  const associateFacilitiesPerColumn = Math.ceil(associateDeans.length / 2);

  return (
    <div className="page text-center font-change-applicable static-facilities">
      <PageHeader dept="/Deans" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <h2 style={{ textAlign: 'left' }}>Deans</h2>
        <div className="columns-wrapper">


          <div className="column">

            <table className="custom-table">
              <tbody>
                {deans.slice(0, facilitiesPerColumn).map((dean, index) => (
                  <tr
                    className={`member ${dean.link === hoveredIndex ? 'hovered' : ''}`}
                    onMouseEnter={() => handleHover(dean.link)}
                    onMouseLeave={handleHoverEnd}
                    key={dean.link}
                  >
                    <td className="custom-photo-cell">
                      <a href={dean.link} target="_self" rel="noreferrer">
                        <img className="custom-circle-image" src={dean.image} alt="profile-sample1" />
                      </a>
                    </td>
                    <td className="custom-details-cell">
                      <h2>
                        <a href={dean.link} target="_self" rel="noreferrer">
                          {dean.name}
                        </a>
                      </h2>
                      <div className="details-wrapper">
                        <div className="details-row">
                          <span className="details-label">Name: </span>
                          <span className="details-value">{dean.designation}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Phone No.: </span>
                          <span className="details-value">{dean.phoneNumber}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">E-Mail.: </span>
                          <span className="details-value">{dean.email}</span>
                        </div>
                        <br></br>
                        <br></br>
                        {/* <div className="details-row">
              <span className="details-label"> </span>
              <span className="details-value"></span>
            </div> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>


          </div>


          <div className="column">
            <table className="custom-table">
              <tbody>
                {deans.slice(facilitiesPerColumn).map((dean, index) => (
                  <tr
                    className={`member ${deans.length / 25 + index === hoveredIndex ? 'hovered' : ''}`}
                    onMouseEnter={() => handleHover(deans.length / 25 + index)}
                    onMouseLeave={handleHoverEnd}
                    key={dean.link}
                  >
                    <td className="custom-photo-cell">
                      <a href={dean.link} target="_self" rel="noreferrer">
                        <img className="custom-circle-image" src={dean.image} alt="profile-sample1" />
                      </a>
                    </td>
                    <td className="custom-details-cell">
                      <h2>
                        <a href={dean.link} target="_self" rel="noreferrer">
                          {dean.name}
                        </a>
                      </h2>
                      <div className="details-wrapper">
                        <div className="details-row">
                          <span className="details-label">Name: </span>
                          <span className="details-value">{dean.designation}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Phone No.: </span>
                          <span className="details-value">{dean.phoneNumber}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">E-Mail.: </span>
                          <span className="details-value">{dean.email}</span>
                        </div>
                        <br></br>
                        <br></br>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>

        <h2 style={{ textAlign: 'left' }}>Associate Deans</h2>
        <div className="columns-wrapper">
          <div className="column">
            <table className="custom-table">
              <tbody>
                {associateDeans.slice(0, associateFacilitiesPerColumn).map((associateDean, index) => (
                  <tr
                    className={`member ${index === hoveredIndex ? 'hovered' : ''}`}
                    onMouseEnter={() => handleHover(index)}
                    onMouseLeave={handleHoverEnd}
                    key={index}
                  >
                    {/* Associate Dean row content */}
                    <td className="custom-photo-cell">
                      <a href={associateDean.link} target="_self" rel="noreferrer">
                        <img className="custom-circle-image" src={associateDean.image} alt="profile-sample2" />
                      </a>
                    </td>
                    <td className="custom-details-cell">
                      <h2>
                        <a href={associateDean.link} target="_self" rel="noreferrer">
                          {associateDean.name}
                        </a>
                      </h2>
                      <div className="details-wrapper">
                        <div className="details-row">
                          <span className="details-label">Name: </span>
                          <span className="details-value">{associateDean.designation}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Phone No.: </span>
                          <span className="details-value">{associateDean.phoneNumber}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">E-Mail.: </span>
                          <span className="details-value">{associateDean.email}</span>
                        </div>
                        <br></br>
                        <br></br>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="column">
            <table className="custom-table">
              <tbody>
                {associateDeans.slice(associateFacilitiesPerColumn).map((associateDean, index) => (
                  <tr
                    className={`member ${index + associateFacilitiesPerColumn === hoveredIndex ? 'hovered' : ''}`}
                    onMouseEnter={() => handleHover(index + associateFacilitiesPerColumn)}
                    onMouseLeave={handleHoverEnd}
                    key={index}
                  >
                    {/* Associate Dean row content */}
                    <td className="custom-photo-cell">
                      <a href={associateDean.link} target="_self" rel="noreferrer">
                        <img className="custom-circle-image" src={associateDean.image} alt="profile-sample2" />
                      </a>
                    </td>
                    <td className="custom-details-cell">
                      <h2>
                        <a href={associateDean.link} target="_self" rel="noreferrer">
                          {associateDean.name}
                        </a>
                      </h2>
                      <div className="details-wrapper">
                        <div className="details-row">
                          <span className="details-label">Name: </span>
                          <span className="details-value">{associateDean.designation}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">Phone No.: </span>
                          <span className="details-value">{associateDean.phoneNumber}</span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">  </span>
                          <span className="details-value"> </span>
                        </div>
                        <div className="details-row">
                          <span className="details-label">E-Mail.: </span>
                          <span className="details-value">{associateDean.email}</span>
                        </div>
                        <br></br>
                        <br></br>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StaticDeans;