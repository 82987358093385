import React from "react";
import Ticker from "react-ticker";

const BreakingNews = (props) => {

  function compare(a, b) {
    if (a.start_date > b.start_date) {
      return -1;
    }
    if (a.start_date < b.start_date) {
      return 1;
    }
    return 0;
  }
  const breakingNewsData = props.breakingNews.sort(compare);
  if (props.breakingNews.length === 0) return (<div style={{height:'3vh'}}></div>);
  // var breakingNewsData = props.breakingNews || [
  //   {
  //     title: "No breaking News",
  //     url: "/",
  //   },
  // ];
  return (
    <Ticker mode="smooth">
      {({ index }) => (
        <>
          <div className="" style={{ marginTop: 10 , marginBottom: 10}}>
            <div>
              {breakingNewsData.map((newsItem) => {
                var newUrl = newsItem.url;
                return (
                  <a href={newUrl} style={{whiteSpace:'nowrap'}}>
                    <h6 className={"p-3"} style={{ display: "inline" }}>
                      <span className="auto-blink" style={{backgroundColor:'#ECAA32', color:'#fff', fontWeight:540,padding:'5px', border:'0px', borderRadius:'6px'}}>New</span>{`  ${newsItem.title}`}
                    </h6>
                  </a>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Ticker>
  );
};

export default BreakingNews;
